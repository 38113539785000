type TRainbowSixSiegeMaps =
| 'bank1F'
| 'bank2F'
| 'bankBF'
| 'bankRF'
| 'border1F'
| 'border2F'
| 'borderRF'
| 'chaletBF'
| 'chalet1F'
| 'chalet2F'
| 'chaletRF'
| 'clubhouseBF'
| 'clubhouse1F'
| 'clubhouse2F'
| 'clubhouseRF'
| 'coastline1F'
| 'coastline2F'
| 'coastlineRF'
| 'consulateBF'
| 'consulate1F'
| 'consulate2F'
| 'consulateRF'
| 'favela1F'
| 'favela2F'
| 'favela3F'
| 'favelaRF'
| 'fortress1F'
| 'fortress2F'
| 'fortressRF'
| 'herefordBF'
| 'hereford1F'
| 'hereford2F'
| 'hereford3F'
| 'herefordRF'
| 'houseBF'
| 'house1F'
| 'house2F'
| 'houseRF'
| 'kafe1F'
| 'kafe2F'
| 'kafe3F'
| 'kafeRF'
| 'kanal1F'
| 'kanal2F'
| 'kanal3F'
| 'kanalRF'
| 'oregonBF'
| 'oregon1F'
| 'oregon2F'
| 'oregon3F'
| 'oregonRF'
| 'outback1F'
| 'outback2F'
| 'outbackRF'
| 'plane1F'
| 'plane2F'
| 'plane3F'
| 'skyscraper1F'
| 'skyscraper2F'
| 'skyscraperRF'
| 'themepark1F'
| 'themepark2F'
| 'themeparkRF'
| 'tower1F'
| 'tower2F'
| 'villaBF'
| 'villa1F'
| 'villa2F'
| 'villaRF'
| 'yachtBF'
| 'yacht1F'
| 'yacht2F'
| 'yacht3F'
| 'yachtRF'
;
const RainbowSixSiegeMapsNS = 'rainbow-six-siege-maps';
function RainbowSixSiegeMapsF(_: TRainbowSixSiegeMaps): string { return `${RainbowSixSiegeMapsNS}:${_}`; }
export {RainbowSixSiegeMapsF,RainbowSixSiegeMapsNS,TRainbowSixSiegeMaps};
