import gql from 'graphql-tag';

export const UntagVideo_Mutation = gql`
    mutation UntagVideoMutation(
        $input: UntagVideoInput!
    ) {
        untagVideo(
            input: $input
        ) {
            video {
                id
                tags
            }
        }
    }
`;
