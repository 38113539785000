import gql from 'graphql-tag';

export const RestoreTeam_Mutation = gql`
    mutation RestoreTeamMutation(
        $input: RestoreTeamInput!
    ) {
        restoreTeam(
            input: $input
        ) {
            team {
                id
            }
        }
    }
`;
