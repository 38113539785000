import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface IFileInputOwnProps {
  id        : string;
  accept    : string;
  label     : React.ReactNode;
  fullWidth?: boolean;
  multiple? : boolean;
  onChange  : (e: React.ChangeEvent<HTMLInputElement>) => void;
}

type FileInputProps = IFileInputOwnProps;

const useStyles = makeStyles({
  fileInput: {
    display: 'none',
  },
});

export default function FileInput(props: FileInputProps) {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e);
  };

  const classes = useStyles();

  return (
    <Box>
      <input
      className={classes.fileInput}
      id={props.id}
      type='file'
      accept={props.accept}
      onChange={onChange}
      multiple={props.multiple}
      />
      <label htmlFor={props.id}>
        <Button variant='outlined' component='span' color='default' fullWidth={props.fullWidth}>
          {props.label}
        </Button>
      </label>
    </Box>
  );
}
