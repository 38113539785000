import gql from 'graphql-tag';

export const UpdateStatsMap_Mutation = gql`
    mutation UpdateStatsMapMutation(
        $input: UpdateStatsMapInput!
    ) {
        updateStatsMap(
            input: $input
        ) {
            requested
        }
    }
`;
