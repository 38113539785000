import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import { LogoSvg } from 'assets/logos';
import classnames from 'classnames';
import { createTitle } from 'helpers/createtitle';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { REGISTER_EMAIL_PATH, REGISTER_PATH } from 'routes';

import { LoginDialogContext } from './LoginDialogContext';

export interface SignupAndVerifyDialogContentLayoutOwnProps {
  className?: string;
  title: string;
}

export type SignupAndVerifyDialogContentLayoutProps = SignupAndVerifyDialogContentLayoutOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&$registerFormTitle': {
      padding: theme.spacing(1),
    },
  },
  titleText: {
    fontWeight: 600,
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  link: {
    textDecoration: 'none',
  },
  marginRightAuto: {
    marginRight: 'auto',
  },
  registerFormTitle: {},
}), { name: 'SignupAndVerifyDialogContentLayout' });

function SignupAndVerifyDialogContentLayout(props: React.PropsWithChildren<SignupAndVerifyDialogContentLayoutProps>) {
  const classes = useStyles(props);

  const { t } = useStrictTranslation('common');

  const isRegisterRoute = !!useRouteMatch(REGISTER_PATH);
  const isRegisterFormRoute = !!useRouteMatch(REGISTER_EMAIL_PATH);

  const { loginState } = useContext(LoginDialogContext);

  return (
    <Box className={props.className}>
      <Helmet>
        <title>{createTitle(t('appname'), props.title)}</title>
      </Helmet>
      <DialogTitle
      className={classnames(classes.title, {[classes.registerFormTitle]: isRegisterFormRoute})}
      disableTypography={true}
      >
        {isRegisterFormRoute && (
          <Link
          to={{ pathname: REGISTER_PATH, state: loginState }}
          className={classnames(classes.link, classes.marginRightAuto)}
          >
            <IconButton>
              <ArrowBackIosIcon fontSize='small' />
            </IconButton>
          </Link>
        )}
        <Typography
        variant='h2'
        className={classnames(classes.titleText, {[classes.marginRightAuto]: isRegisterFormRoute})}
        >
          {props.title}
        </Typography>
        {isRegisterRoute && <LogoSvg size={24} />}
      </DialogTitle>
      <DialogContent>
        {props.children}
      </DialogContent>
    </Box>
  );
}

export default React.memo(SignupAndVerifyDialogContentLayout);
