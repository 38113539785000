import gql from 'graphql-tag';

import { MatchFragment } from '../fragments';

export const RemoveMatchMetadata_Mutation = gql`
    mutation RemoveMatchMetadataMutation(
        $input: RemoveMatchMetadataInput!
    ) {
        removeMatchMetadata(
            input: $input
        ) {
            match {
                ...MatchFragment
            }
        }
    }

    ${MatchFragment}
`;
