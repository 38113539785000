import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AttackIcon } from '../../assets/overwatch-maptypes';
import StocksRemaining from '../../components/video/video-replay/ssbu/StocksRemaining';
import MapVerificationDialogContent from '../../containers/dialogs/MapVerificationDialogContent';
import { buildMapImagePath } from '../../helpers';
import { formatDuration } from '../../helpers/formatters';
import { useDialogState } from '../../hooks/useDialogState';
import { CommonF, CommonNS } from '../../locales/en/common';
import { OwmapsF, OwmapsNS, TOwmaps } from '../../locales/en/owmaps';
import { SsbuStagesF, TSsbuStages } from '../../locales/en/ssbu-stages';
import { ID, Match } from '../../types/pigeon';
import { OverwatchMap } from '../../types/pigeon/matches/generated-overwatch-match';
import { SSBUStage, TestingSSBUMatch } from '../../types/pigeon/matches/testing-generated-ssbu-match';
import FloatingText from '../floating-text/FloatingText';

export interface IMatchCardOwnProps {
  className?: string;
  match: Match;
  index: number;
  onClick?: (match: Match) => void;
  videoId: ID;
  teamId?: ID;
  selected?: boolean;
}

type Props = IMatchCardOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  matchCardRoot: {
    border: '1px solid transparent',
    position: 'relative',
    '&$selected': {
      borderColor: 'white',
    },
  },
  matchCardThumbnail: {
    opacity: .75,
    '&$selected': {
      opacity: 1,
    },
  },
  cardContent: {
    padding: theme.spacing(1),
  },
  thumbnailWrapper: {
    position: 'relative',
  },
  thumbnail: {
    paddingTop: '56.25%',
    height: 0,
    position: 'relative',
  },
  editMapIconButtonWrapper: {
    position: 'absolute',
    paddingTop: '56.25%',
    top: 0,
    right: 0,
  },
  stockStatus: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  blue: {
    color: theme.palette.overwatch.blueTeam,
    fill: theme.palette.overwatch.blueTeam,
  },
  red: {
    color: theme.palette.overwatch.redTeam,
    fill: theme.palette.overwatch.redTeam,
  },
  mapName: {
    marginRight: theme.spacing(3.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  editMapIconButton: {
    margin: theme.spacing(1),
  },
  selected: {},
}), {name: 'MatchCard'});

function MatchCard(props: Props) {
  const { match, teamId, index, onClick, videoId, selected } = props;
  const classes = useStyles(props);

  const [ isMapVerificationDialogOpen, openMapVerificationDialog, closeMapVerificationDialog ] = useDialogState();
  const { t } = useTranslation([CommonNS, OwmapsNS]);

  const [ mapName, setMapName ] = useState<SSBUStage | OverwatchMap>(match.map);
  const [ attacker, setAttacker ] = useState(match.isGeneratedOverwatchMatch() ? match.data.scoreInfo.color : 'none');
  const { image, mapTKey } = useMemo(() => {
    if (!mapName) {
      return {
        image: undefined,
        mapTKey: CommonF('n/a'),
      };
    }
    return {
      image: buildMapImagePath(
        'map-thumbnail',
        match.isGeneratedOverwatchMatch() ? 'overwatch' : 'ssbu',
        mapName,
      ),
      mapTKey: match.isGeneratedOverwatchMatch() ? OwmapsF(mapName as TOwmaps) : SsbuStagesF(mapName as TSsbuStages),
    };
  }, [mapName, match]);

  const renderMapVerificationDialog = useCallback(() => teamId && match.isGeneratedOverwatchMatch() && (
    <Dialog
    open={isMapVerificationDialogOpen}
    onClose={closeMapVerificationDialog}
    fullWidth={true}
    maxWidth={'xs'}
    >
      <MapVerificationDialogContent
      videoId={videoId}
      teamId={teamId}
      match={match}
      position={index}
      onClose={closeMapVerificationDialog}
      onMapNameUpdate={setMapName}
      onAttackerUpdate={setAttacker}
      />
    </Dialog>
  ), [closeMapVerificationDialog, index, isMapVerificationDialogOpen, match, teamId, videoId]);

  const handleCardOnClick = useCallback(() => {
    onClick?.(match);
  }, [match, onClick]);

  const renderEditMapButton = useCallback(() => (
    <div className={classes.editMapIconButtonWrapper}>
      <IconButton
      onClick={openMapVerificationDialog}
      size='small'
      className={classes.editMapIconButton}
      >
        <EditIcon fontSize='small' />
      </IconButton>
    </div>
  ), [classes.editMapIconButton, classes.editMapIconButtonWrapper, openMapVerificationDialog]);

  const renderAttackDefendIcons = useCallback((attacker:string) => {
    const attackColor = attacker === 'none' ? 'white' : attacker;
    const defendColor = attackColor === 'red' ? 'blue' : attackColor === 'blue' ? 'red' : 'white';
    return (
      <FlexSpacer>
        <FontAwesomeIcon icon={faShieldAlt} size='lg' className={classes[defendColor]}/>
        <AttackIcon width={18} height={18} className={classes[attackColor]}/>
      </FlexSpacer>
    );
  }, [classes]);

  const renderStocks = useCallback((match: TestingSSBUMatch) =>  {
    return (
      <div className={classes.stockStatus}>
        <StocksRemaining
        character={match.data.player1.char}
        stocksRemaining={match.data.player1.stocks_remaining}
        stocks={match.data.player1.stocks.length}
        />
        <StocksRemaining
        character={match.data.player2.char}
        stocksRemaining={match.data.player2.stocks_remaining}
        stocks={match.data.player2.stocks.length}
        />
      </div>
    );
  }, [classes]);

  const translatedMapName = t(mapTKey);

  const { canAggregateOverwatchStats } = useAccessControl();

  return (
    <React.Fragment>
      {renderMapVerificationDialog()}
      <Card
      draggable={false}
      title={match.name}
      classes={{
        root: classNames(
          classes.matchCardRoot,
          {[classes.selected]: selected},
        ),
      }}
      >
        <CardActionArea onClick={handleCardOnClick}>
          <CardMedia
          className={classes.thumbnail}
          image={image}
          classes={{
            root: classNames(
              classes.matchCardThumbnail,
              {[classes.selected]: selected},
            ),
          }}
          >
            <FloatingText vertical='top' horizontal='left' ml={1} mt={1}>
              {index + 1}
            </FloatingText>
            {typeof match.startTime === 'number' && (
              <FloatingText vertical='bottom' horizontal='right' mr={1} mb={1}>
                {formatDuration(match.startTime)}
              </FloatingText>
            )}
          </CardMedia>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.mapName} title={translatedMapName}>
              {translatedMapName}
            </Typography>
            {match.isTestingSSBUMatch() && renderStocks(match)}
            {
              match.isGeneratedOverwatchMatch() &&
              match.data.scoreInfo.gamemode !== 'control' &&
              renderAttackDefendIcons(attacker)
            }
          </CardContent>
        </CardActionArea>
        {match.isGeneratedOverwatchMatch() && canAggregateOverwatchStats && renderEditMapButton()}
      </Card>
    </React.Fragment>
  );
}

export default React.memo(MatchCard);
