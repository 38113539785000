import { makeProductTourSelectors } from '@insights-gaming/product-tour';
import { RootReducer } from 'rootReducer';

import { ProductTourId } from './product-tour-ids';

const getProductTourSlice = (state: RootReducer)  => state.productTourReducer;

export const productTourSelectors = makeProductTourSelectors<RootReducer, ProductTourId>(getProductTourSlice);

export const {
  getViewedProductTours,
  getViewedProductToursLoaded,
  makeGetTourIdViewed,
  makeShouldShowTourId,
} = productTourSelectors;
