import { CommentFragment } from 'apollo/fragments/types/CommentFragment';
import React from 'react';

export interface VirtualizedCommentsState {
  resizeComment: (c: CommentFragment) => void;
}

export const VirtualizedCommentsContext = React.createContext<VirtualizedCommentsState>({
  resizeComment: () => {},
});

export const {
  Provider: VirtualizedCommentsProvider,
  Consumer: VirtualizedCommentsConsumer,
} = VirtualizedCommentsContext;
