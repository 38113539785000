import React from 'react';

import { getAutoPauseSetting, getAutoPauseTyingSetting, getAutoPlayAudioSetting, getAutoscrollSetting } from './useVideoCommentMenuContext';

export interface VideoCommentMenuState {
  autoPause: boolean;
  autoPauseTyping: boolean;
  autoScroll: boolean;
  autoPlayAudio: boolean;
  toggleAutoPause: () => void;
  toggleAutoPauseTyping: () => void;
  toggleAutoScroll: () => void;
  toggleAutoPlayAudio: () => void;
};

export const VideoCommentMenuContext = React.createContext<VideoCommentMenuState>({
  autoPause: getAutoPauseSetting(),
  autoPauseTyping: getAutoPauseTyingSetting(),
  autoScroll: getAutoscrollSetting(),
  autoPlayAudio: getAutoPlayAudioSetting(),
  toggleAutoPause: () => {},
  toggleAutoPauseTyping: () => {},
  toggleAutoScroll: () => {},
  toggleAutoPlayAudio: () => {},
});

export const {
  Provider: VideoCommentMenuProvider,
  Consumer: VideoCommentMenuConsumer,
} = VideoCommentMenuContext;
