import { AsyncButton, Loader } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import update, { Spec } from 'immutability-helper';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PASSWORD_RESET_ENDPOINT } from '../../constants';
import { LoginF, LoginNS, TLogin } from '../../locales/en/login';
import TransparentDialog from '../../material/transparent-dialog/TransparentDialog';
import { signinRoute } from '../../routes';

export type IPasswordResetProps = WithTranslation & WithSnackbarProps;

interface IState {
  isValidCode?   : boolean;
  loading?       : boolean;
  success?       : boolean;
  password1?     : string;
  password2?     : string;

  password1Error?: FormError;
  password2Error?: FormError;
}

type FormError = 'PASSWORD_REQUIRED' | 'WEAK_PASSWORD' | 'PASSWORD_MISMATCH';
type ResponseError =
  | 'BAD_REQUEST'  // either code or password empty
  | 'INVALID_CODE'  // not base64
  | 'NOT_FOUND'  // does not exist
  | 'INTERNAL_SERVER_ERROR';  // everything else

class PasswordReset extends React.Component<IPasswordResetProps, IState> {
  constructor(props: IPasswordResetProps) {
    super(props);
    this.state = {};
  }

  public get formError(): boolean {
    const { password1Error, password2Error } = this.state;
    return !!(password1Error || password2Error);
  }

  public async componentDidMount() {
    const paramsString = window.location.hash.slice(1);
    const params = new URLSearchParams(paramsString);
    const code = params.get('code');
    // check to see if the is valid
    // if no code, it must be invalid
    if (!code) {
      return this.setState({isValidCode: false});
    }

    // HACK: send an empty request to see what error we get back NinoFace
    const res: Response = await fetch(PASSWORD_RESET_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `code=${encodeURIComponent(code)}`,
    });
    if (!res.ok) {
      // depending on what error we get back we can tell if the code is valid or not
      const { error } = await res.json();
      switch (error as ResponseError) {
        case 'BAD_REQUEST'          : return this.setState({isValidCode: true});   // either code or password empty
                                                                  // we know it's not empty cuz we checked up there
        case 'INVALID_CODE'         : return this.setState({isValidCode: false});  // not base64
        case 'NOT_FOUND'            : return this.setState({isValidCode: false});  // does not exist
        case 'INTERNAL_SERVER_ERROR': return this.setState({isValidCode: false});  // everything else
             default                : return this.setState({isValidCode: false});  // just in case
      }
    }
  }

  public render() {
    switch (this.state.isValidCode) {
      case true   : return this.renderResetForm();
      case false  : return this.renderInvalidCode();
           default: return this.renderLoader();
    }
  }

  private renderResetForm = () => {
    const { t } = this.props;
    const { success } = this.state;
    return (
      <Dialog open={true}>
        <DialogTitle>
          {t(LoginF('passwordreset'))}
        </DialogTitle>
        {success ? this.renderSuccessMessage() : this.renderFormFields()}
      </Dialog>
    );
  }

  private renderSuccessMessage = () => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <DialogContent>
          {t(LoginF('resetpasswordsuccess'))}
        </DialogContent>
        <DialogActions>
          <Button
          variant='contained'
          color='primary'
          component={Link}
          to={signinRoute()}
          >
            {t(LoginF('login'))}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }

  private renderFormFields = () => {
    const { t } = this.props;
    const { loading, password1, password2, password1Error, password2Error } = this.state;
    return (
      <form onSubmit={this.handleFormOnSubmit}>
        <DialogContent>
          <TextField
          id='password1'
          name='password1'
          type='password'
          required={true}
          label={t(LoginF('password'))}
          autoFocus={true}
          value={password1 || ''}
          error={!!password1Error}
          onChange={this.handleInputOnChange}
          fullWidth={true}
          />
          <TextField
          id='password2'
          name='password2'
          type='password'
          required={true}
          label={t(LoginF('confirmpw'))}
          value={password2 || ''}
          error={!!password2Error}
          onChange={this.handleInputOnChange}
          fullWidth={true}
          margin='normal'
          />
        </DialogContent>
        <DialogActions>
          <AsyncButton
          type='submit'
          variant='contained'
          color='primary'
          loading={loading}
          disabled={loading}
          >
            {t(LoginF('resetpassword'))}
          </AsyncButton>
        </DialogActions>
      </form>
    );
  }

  private getFormErrorKey = (formError: FormError): TLogin => {
    switch (formError) {
      case 'PASSWORD_MISMATCH': return 'errors.passwordmismatch';
      case 'PASSWORD_REQUIRED': return 'errors.passwordrequired';
      case 'WEAK_PASSWORD'    : return 'errors.weakpassword';
    }
  }

  private getResponseErrorKey = (resError: ResponseError): TLogin => {
    switch (resError) {
      default: return 'errors.resetfailed';
    }
  }

  private renderInvalidCode = () => {
    const { t } = this.props;
    return (
      <Dialog open={true}>
        <DialogTitle>{t(LoginF('passwordreset'))}</DialogTitle>
        <DialogContent>
          {t(LoginF('errors.invalidresetlink'))}
        </DialogContent>
      </Dialog>
    );
  }

  private renderLoader = () => {
    return (
      <TransparentDialog open={true}>
        <Loader />
      </TransparentDialog>
    );
  }

  private handleFormOnSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { loading } = this.state;
    const { t } = this.props;
    if (loading) { return; }
    this.validateForm(async () => {
      if (this.formError) {
        this.handleFormError();
        return;
      }
      const { password1 } = this.state;
      if (!password1) { return; }
      try {
        const paramsString = window.location.hash.slice(1);
        const params = new URLSearchParams(paramsString);
        const code = params.get('code');
        if (!code) { return; }
        this.setState({loading: true});
        const res: Response = await fetch(PASSWORD_RESET_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `code=${encodeURIComponent(code)}&password=${encodeURIComponent(password1)}`,
        });
        if (!res.ok) {
          const { error } = await res.json();
          throw new Error(error);
        } else {
          this.setState({success: true, loading: false});
        }
      } catch (error) {
        const key = this.getResponseErrorKey(error.message as ResponseError);
        this.props.enqueueSnackbar(t(LoginF(key)), {variant: 'error'});
        this.setState({loading : false});
      }
    });
  }

  private handleFormError = () => {
    const { t } = this.props;
    const { password1Error, password2Error } = this.state;
    const errorList = [];
    if (password1Error) {
      errorList.push(password1Error);
    }
    if (password2Error) {
      errorList.push(password2Error);
    }
    if (errorList.length === 0) {return ;}
    const keys = errorList
    .map(this.getFormErrorKey)
    .map(LoginF);
    keys.forEach(
      key => this.props.enqueueSnackbar(t(key), {variant: 'error'}),
    );
  }

  private handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState({[name]: value});
  }

  private validateForm = (cb?: () => void) => {
    const { password1, password2 } = this.state;
    const spec: Spec<IState> = {
      password1Error: {$set: undefined},
      password2Error: {$set: undefined},
    };
    if (password1) {
      if (password1.length < 6) {
        spec.password1Error = {$set: 'WEAK_PASSWORD'};
      }
    } else {
      spec.password1Error = {$set: 'PASSWORD_REQUIRED'};
    }
    if (password2 !== password1) {
      spec.password2Error = {$set: 'PASSWORD_MISMATCH'};
    }
    this.setState(state => update(state, spec), cb);
  }
}

export default withSnackbar(
  withTranslation(LoginNS)(PasswordReset),
);
