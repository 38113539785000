import gql from 'graphql-tag';

export const HideTips_Mutation = gql`
    mutation HideTipsMutation(
        $input: HideTipsInput!
    ) {
        hideTips(
            input: $input
        ) {
            names
        }
    }
`;
