import { Theme } from '@insights-gaming/theme';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { ChangeEvent, useCallback } from 'react';

interface PlanFeedbackListItemOwnProps {
  className?: string;
  reason: any;
  reasonIndex: number;
  selected?: boolean;
  otherReason: boolean;
  onClick: (e: React.MouseEvent, reason: number) => void;
  handleCommentsOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

type PlanFeedbackListItemProps = PlanFeedbackListItemOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  textField: {
    margin: 0,
    flex: 1,
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.light,
      },
    },
  },
  otherReason: {
    flex: 'none',
    marginRight: theme.spacing(1.5),
  },
}), {name: 'PlanFeedbackListItem'});

function PlanFeedbackListItem(props: PlanFeedbackListItemProps) {
  const classes = useStyles(props);
  const {
    className,
    reason,
    reasonIndex,
    selected,
    otherReason,
    onClick,
    handleCommentsOnChange,
  } = props;

  const { t } = useStrictTranslation(['dialog']);

  const handleListItemClick = useCallback((e: React.MouseEvent) => {
    onClick(e, reasonIndex);
  }, [onClick, reasonIndex]);

  return (
    <ListItem
    className={classNames(classes.root, className)}
    button={true}
    onClick={handleListItemClick}
    selected={selected}
    >
      <ListItemText className={classNames({[classes.otherReason]: otherReason})} primary={t(reason)}/>
      {otherReason && (
        <TextField
        className={classes.textField}
        margin='dense'
        variant='outlined'
        placeholder={t('dialog:planfeedback.reason.pleasedescribe')}
        onChange={handleCommentsOnChange}
        />
      )}
    </ListItem>
  );
}

export default React.memo(PlanFeedbackListItem);
