import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { IAvailablePlanData } from 'features/dashboard/billing/useAvailableMetrics';
import useTeamAvailableBundles from 'features/dashboard/billing/useTeamAvailableBundles';
import useTeamSubscription from 'features/dashboard/billing/useTeamSubscription';
import { mobilePortrait } from 'features/media-queries';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import { darkPaletteGradient } from 'helpers/color';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { DialogNS } from 'locales/en/dialog';
import React, { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { BillingInterval, ProductKind } from 'types/graphql';
import { InputQuantities } from 'types/pigeon/billing';

import BundleCustomSelector from './BundleCustomSelector';
import CustomPlan from './CustomPlan';
import PlanCarousel from './PlanCarousel';
import PlanInfo from './PlanInfo';
import ToggleIntervalButtonGroup from './ToggleIntervalButtonGroup';

interface AvailablePlansOwnProps {
  className?: string;
  hasPlan: boolean;
  team: TeamFragment;
  freeTrial?: boolean;
  checkoutQuantities: InputQuantities;
  selectedProductIndex?: number;
  customPlanQuantities?: Partial<Record<ProductKind, number>>;
  currentPlan?: IAvailablePlanData;
  bundleCustomTab: 'bundle' | 'custom';
  handleIntervalSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleProductSlideOnChange: (i: number) => void;
  handleIntervalSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleProductSelectOnChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleIntervalToggleButtonChange: (event: React.MouseEvent<HTMLElement>, newValue: BillingInterval) => void;
  handleCustomPlanSelectOnChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleBundleCustomTabChange: (event: React.ChangeEvent, newValue: 'bundle' | 'custom') => void;
  setCustomPlanQuantities: React.Dispatch<React.SetStateAction<object>>;
  setCancelPlan: (cancelPlan: boolean) => void;
  stepForward: VoidFunction;
};

type AvailablePlansProps = AvailablePlansOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  title: {
    marginBottom: theme.spacing(3),
    [mobilePortrait(theme)]: {
      marginBottom: theme.spacing(1),
    },
  },
  // tabsContainer: {
  //   display:'flex',
  //   justifyContent:'space-between',
  //   marginBottom: theme.spacing(2),
  //   flexWrap: 'wrap',
  // },
  // tabContainer: {
  //   fontSize: createRemFromPx(14),
  //   [mobilePortrait(theme)]: {
  //     flex: 1,
  //   },
  //   backgroundColor: theme.palette.background.dialog,
  // },
  // divider: {
  //   height: 'auto',
  // },
  teamBundlesText: {
    background: darkPaletteGradient.gradient2.toBottom,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  billingIntervalContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    alignItems: 'center',
  },
  mobileIntervalButton: {
    marginBottom: theme.spacing(4),
  },
  contactUsButton: {
    borderRadius: 8,
  },
}), {name: 'AvailablePlansContent'});

function AvailablePlans(props: AvailablePlansProps) {
  const classes = useStyles(props);
  const {
    className,
    hasPlan,
    team,
    checkoutQuantities,
    selectedProductIndex,
    customPlanQuantities,
    currentPlan,
    bundleCustomTab,
    handleProductSlideOnChange,
    handleIntervalSelectChange,
    handleProductSelectOnChange,
    handleIntervalToggleButtonChange,
    handleCustomPlanSelectOnChange,
    handleBundleCustomTabChange,
    setCustomPlanQuantities,
    setCancelPlan,
    stepForward,
    freeTrial,
  } = props;

  const { t } = useStrictTranslation(['dialog']);

  const [teamSubscription] = useTeamSubscription(team.id);
  const bundles = useTeamAvailableBundles(team.id);
  const isMobilePortrait = useIsMobilePortrait();

  const openDefaultPlanDetailsDialog = useCallback(() => {
    setCancelPlan(true);
    stepForward();
  }, [setCancelPlan, stepForward]);
  const openCustomPlanDetailsDialog = useCallback(() => {
    setCancelPlan(false);
    stepForward();
  }, [setCancelPlan, stepForward]);

  const contactUsButtonProps = useMemo(() => {
    return {
      href: '/contact-us',
      rel: 'noopener noreferrer',
      target: '_blank',
      component: 'a',
    };
  }, []);

  return (
    <DialogContent className={classNames(classes.content, className)}>
      <FlexSpacer flexJustifyContent='center'>
        <BundleCustomSelector onChange={handleBundleCustomTabChange} tab={bundleCustomTab} />
      </FlexSpacer>
      {!isMobilePortrait && (
        <div className={classes.billingIntervalContainer}>
          <ToggleIntervalButtonGroup
          interval={checkoutQuantities.interval}
          onChange={handleIntervalToggleButtonChange}
          />
          <Trans
          i18nKey={t('dialog:availableplans.saveupto.teambundles')}
          ns={DialogNS}
          >
            <Typography align='center' variant='h3'>
              Save up to 67% with <strong className={classes.teamBundlesText}>Team Bundles</strong>
            </Typography>
          </Trans>
          <FlexSpacer flexJustifyContent='end' flexAlignItems='center' spacing={2}>
            <Typography>
              {t('dialog:availableplans.readyforfreetrial')}
            </Typography>
            <Button className={classes.contactUsButton} variant='outlined' {...contactUsButtonProps}>
              {t('dialog:availableplans.contactus')}
            </Button>
          </FlexSpacer>
        </div>
      )}
      {isMobilePortrait && (
        <FlexSpacer orientation='vertical' flexAlignItems='center'>
          <Trans
          i18nKey={t('dialog:availableplans.saveupto.teambundles')}
          ns={DialogNS}
          >
            <Typography align='center' variant='h3'>
              Save up to 67% with <strong className={classes.teamBundlesText}>Team Bundles</strong>
            </Typography>
          </Trans>
          <ToggleIntervalButtonGroup
          className={classes.mobileIntervalButton}
          interval={checkoutQuantities.interval}
          onChange={handleIntervalToggleButtonChange}
          />
        </FlexSpacer>
      )}
      {bundleCustomTab === 'bundle' && (
        <React.Fragment>
          <PlanCarousel
          checkoutQuantities={checkoutQuantities}
          hasPlan={hasPlan}
          teamSubscription={teamSubscription}
          selectedProductIndex={selectedProductIndex}
          openDefaultPlanDetailsDialog={openDefaultPlanDetailsDialog}
          openCustomPlanDetailsDialog={openCustomPlanDetailsDialog}
          handleProductSlideOnChange={handleProductSlideOnChange}
          bundles={bundles}
          />
          <PlanInfo freeTrial={freeTrial} interval={checkoutQuantities.interval} />
        </React.Fragment>
      )}
      {bundleCustomTab === 'custom' && (
        <CustomPlan
        interval={checkoutQuantities.interval}
        openCustomPlanDetailsDialog={openCustomPlanDetailsDialog}
        teamSubscription={teamSubscription}
        bundles={bundles}
        handleCustomPlanSelectOnChange={handleCustomPlanSelectOnChange}
        customPlanQuantities={customPlanQuantities}
        setCustomPlanQuantities={setCustomPlanQuantities}
        changePlan={!!currentPlan}
        />
      )}
    </DialogContent>
  );
}

export default React.memo(AvailablePlans);
