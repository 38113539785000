import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import TeamInvitations from 'components/dashboard/team-settings/team-invitations/TeamInvitations';
import { EIntercomID } from 'constants/strings';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import InviteTeamMembersDialogContent from 'material/dialogs/invite-team-members-dialog-content/InviteTeamMembersDialogContent';
import React from 'react';

interface DashboardTeamSettingsInvitationsOwnProps {
  className?: string;
  team: TeamFragment;
}

type DashboardTeamSettingsInvitationsProps = DashboardTeamSettingsInvitationsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DashboardTeamSettingsInvitations'});

function DashboardTeamSettingsInvitations(props: DashboardTeamSettingsInvitationsProps) {
  const classes = useStyles(props);
  const { className, team } = props;
  const [
    isInviteTeamMembersDialogOpen,
    openInviteTeamMembersDialog,
    closeInviteTeamMembersDialog,
  ] = useDialogState();

  const { t } = useStrictTranslation(['dashboard-settings']);

  return (
    <FlexSpacer id={EIntercomID.INVITES} orientation='vertical'>
      <TeamInvitations
      team={team}
      className={classNames(classes.root, className)}
      openInviteTeamMembersDialog={openInviteTeamMembersDialog}
      />
      <Dialog open={isInviteTeamMembersDialogOpen} onClose={closeInviteTeamMembersDialog} fullWidth={true}>
        <InviteTeamMembersDialogContent
        teamId={team.id}
        onClose={closeInviteTeamMembersDialog}
        />
      </Dialog>
    </FlexSpacer>
  );
}

export default React.memo(DashboardTeamSettingsInvitations);
