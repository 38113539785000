import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import classNames from 'classnames';
import PagedVideoGrid from 'components/video-grid/PagedVideoGrid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchResultType } from 'types/graphql';
import { ID } from 'types/pigeon';

import CommentSearchFetcher from './CommentSearchFetcher';
import PagedCommentResult from './PagedCommentResult';
import { ITeamSearchState } from './search-slice';
import SearchCommentResultSkeleton from './SearchCommentResultSkeleton';
import SearchNotFound from './SearchNotFound';
import SearchVideoResult from './SearchVideoResult';
import VideoSearchFetcher from './VideoSearchFetcher';

interface SearchResultsListOwnProps {
  className?: string;
  teamSearchQuery: ITeamSearchState;
  teamId: ID;
}

type SearchResultsListProps = SearchResultsListOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flex: 1,
    margin: theme.spacing(2, 0),
    overflowY: 'auto',
    width: '100%',
  },
  videoPage: {
    width: '100%',
  },
}), {name: 'SearchResultsList'});

function SearchResultsList(props: SearchResultsListProps) {
  const classes = useStyles(props);
  const { className, teamSearchQuery, teamId } = props;

  const { tab, params: query, videoResultsRecords, videoCommentResultsRecords, error } = teamSearchQuery;

  const [ videoPage, setVideoPage ] = useState<number>(0);
  const [ commentPage, setCommentPage ] = useState<number>(0);

  useEffect(() => {
    if (query) {
      setCommentPage(0);
      setVideoPage(0);
    }
  }, [query]);

  const renderVideoCard = useCallback((video: VideoFragment, i: number) => {
    return <SearchVideoResult video={video}/>;
  }, []);

  const displayCommentSkeleton = useMemo(() => {
    return !videoCommentResultsRecords || (
      !videoCommentResultsRecords.ids.length && videoCommentResultsRecords.forward.fetching
    );
  }, [videoCommentResultsRecords]);

  const displayVideoSkeleton = useMemo(() => {
    return !videoResultsRecords || (
      !videoResultsRecords.ids.length && videoResultsRecords.forward.fetching
    );
  }, [videoResultsRecords]);

  return (
    <div className={classNames(classes.root, className)}>
      {
        tab === SearchResultType.VIDEO ? (
          videoResultsRecords?.ids?.length === 0 && !displayVideoSkeleton ? (
            <SearchNotFound query={query.query} error={error}/>
          ) : (
            <React.Fragment>
              {videoResultsRecords && <VideoSearchFetcher query={query}/>}
              <PagedVideoGrid
              className={classes.videoPage}
              page={videoPage}
              videosRecord={videoResultsRecords}
              renderVideoCard={renderVideoCard}
              onPageChange={setVideoPage}
              />
            </React.Fragment>
          )
        ) : tab === SearchResultType.COMMENT ? (
          displayCommentSkeleton ? (
            <FlexSpacer orientation='vertical' fullWidth={true}>
              {Array.from({length: 4}).map((_, i) => <SearchCommentResultSkeleton key={i}/>)}
            </FlexSpacer>
          ) :
          !videoCommentResultsRecords?.ids?.length ? (
            <SearchNotFound query={query.query} error={error}/>
          ) : (
            <React.Fragment>
              {videoCommentResultsRecords && <CommentSearchFetcher query={query}/>}
              <PagedCommentResult
              teamId={teamId}
              record={videoCommentResultsRecords}
              page={commentPage}
              onPageChange={setCommentPage}
              />
            </React.Fragment>
          )
        ) : null
      }
    </div>
  );
}

export default React.memo(SearchResultsList);
