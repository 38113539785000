import { logoutAsyncAC } from 'features/auth/auth-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useCallback } from 'react';

export function useLogout(): (navigate: boolean) => Promise<void> {
  const promiseSagaDispatch = usePromiseSagaDispatch();
  return useCallback(
    (navigate: boolean = true) => promiseSagaDispatch(logoutAsyncAC, {navigate}),
    [promiseSagaDispatch],
  );
}
