import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { Rating } from '@material-ui/lab';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { FEEDBACK_ENDPOINT } from 'constants/index';
import { SITE_KEY } from 'constants/strings';
import { isVideoFragment } from 'helpers/isVideoFragment';
import { useNavigate } from 'hooks/useNavigate';
import { useReferPath } from 'hooks/useReferPath';
import { TKeysWithNS, useStrictTranslation } from 'hooks/useStrictTranslation';
import { useVideoBackPath } from 'hooks/useVideoBackPath';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { JOIN_SESSION_PATH, registerRoute } from 'routes';
import { getMe } from 'selectors/getMe';
import { SmolVideo } from 'types/pigeon/kmsession';

import { clearLiveSessionAC, TerminatedLiveSessionState } from './live-session-slice';

declare const grecaptcha: any;

interface LiveSessionTerminatedDialogOwnProps {
  className?: string;
  state: TerminatedLiveSessionState;
  video?: SmolVideo | VideoFragment;
}

type LiveSessionTerminatedDialogProps = LiveSessionTerminatedDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  ratingStar: {
    fontSize: createRemFromPx(40),
    color: theme.palette.primary.main,
  },
}), {name: 'LiveSessionTerminatedDialog'});

const ratingHints: Array<TKeysWithNS<'dialog'>> = [
  'dialog:sessionterminated.ratinghint.0',
  'dialog:sessionterminated.ratinghint.1',
  'dialog:sessionterminated.ratinghint.2',
  'dialog:sessionterminated.ratinghint.3',
  'dialog:sessionterminated.ratinghint.4',
  'dialog:sessionterminated.ratinghint.5',
];

function LiveSessionTerminatedDialog(props: LiveSessionTerminatedDialogProps) {
  const { state: { token, guestName }, video } = props;
  const { t } = useStrictTranslation(['common', 'dialog']);
  const classes = useStyles(props);
  const onNavigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [ rating, setRating ] = useState<number>(0);
  const [ hoverRating, setHoverRating ] = useState<number>(0);
  const [ comment, setComment ] = useState<string>();
  const [ feedbackLoading, setFeedbackLoading ] = useState<boolean>();
  const [ feedbackSent, setFeedbackSent ] = useState<boolean>();

  const me = useSelector(getMe);
  const videoBackPath = useVideoBackPath(isVideoFragment(video) ? video : undefined);
  const isSessionRoute = !!useRouteMatch(JOIN_SESSION_PATH);

  const referPath = useReferPath();
  const backPath = useMemo(() => referPath || me ? videoBackPath : registerRoute(), [referPath, me, videoBackPath]);

  const handleRatingOnChange = useCallback((ev: ChangeEvent<HTMLElement>, value: number) => {
    setRating(value);
  }, []);

  const handleRatingOnChangeActive = useCallback((ev: ChangeEvent<HTMLElement>, value: number) => {
    setHoverRating(value);
  }, []);

  const handleCommentsOnChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;
    setComment(value);
  }, []);

  const onClose = useCallback(() => {
    dispatch(clearLiveSessionAC());
    if (isSessionRoute) {
      onNavigate(backPath);
    }
  }, [backPath, dispatch, isSessionRoute, onNavigate]);

  const handleFormSubmit = useCallback(async () => {
    if (feedbackLoading || feedbackSent || rating === 0) { return; }
    setFeedbackLoading(true);
    try {
      const recaptchaToken: string = await grecaptcha.execute(SITE_KEY, {action: 'feedback'});
      const res = await fetch(FEEDBACK_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          'g-recaptcha-response': recaptchaToken,
          type: 'livesession',
          token,
          rating: rating.toString() || '',
          comment: comment || '',
          name: me?.alias || guestName || 'no guest name',
        }),
      });
      const { error } = await res.json();
      if (error) {
        throw error;
      }
      setFeedbackLoading(false);
      setFeedbackSent(true);
      onClose();
      enqueueSnackbar(t('dialog:sessionterminated.feedbacksuccess'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('dialog:sessionterminated.feedbackfailure'), {variant: 'error'});
      setFeedbackLoading(false);
    }
  }, [comment, enqueueSnackbar, feedbackLoading, feedbackSent, guestName, me, rating, onClose, t, token]);

  const ratingHintKey = ratingHints[hoverRating !== -1 ? hoverRating : rating];

  return (
    <Dialog open={true} onClose={!isSessionRoute ? onClose : undefined} fullWidth={true}>
      <BetterDialogTitle>{t('dialog:sessionterminated.title')}</BetterDialogTitle>
      <DialogContent>
        <FlexSpacer orientation='vertical' flexAlignItems='center'>
          <Typography variant='h6'>
            {t('dialog:sessionterminated.ratinglabel')}
          </Typography>
          <Box alignItems='center'>
            <Rating
            name='rating'
            value={rating}
            onChange={handleRatingOnChange}
            onChangeActive={handleRatingOnChangeActive}
            size='large'
            emptyIcon={<StarBorderIcon fontSize='inherit' />}
            className={classes.ratingStar}
            />
            <Box textAlign='center'>
              <Typography variant='caption'>
                {t(ratingHintKey)}
              </Typography>
            </Box>
          </Box>
          <TextField
          variant='outlined'
          name='comment'
          fullWidth={true}
          placeholder={t('dialog:sessionterminated.commentplaceholder')}
          rows={4}
          multiline={true}
          value={comment || ''}
          margin='dense'
          onChange={handleCommentsOnChange}
          />
        </FlexSpacer>
      </DialogContent>
      <DialogActions>
        <Button
        variant='outlined'
        onClick={onClose}
        >
          {t(me ? 'common:404_back' : 'dialog:sessionterminated.createaccount')}
        </Button>
        <AsyncButton
        variant='contained'
        color='primary'
        loading={feedbackLoading}
        disabled={feedbackLoading || feedbackSent || rating === 0}
        onClick={handleFormSubmit}
        >
          {t('dialog:sessionterminated.confirm')}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(LiveSessionTerminatedDialog);
