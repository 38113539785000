import Box from '@material-ui/core/Box';
import { BoxProps } from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import update from 'immutability-helper';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { ChangeEvent, useState } from 'react';
import { AutoControlled, AutoControlledManager } from 'react-auto-controlled';

import { EKeyCode } from '../../types/EKeyCode';
import { createPaginationItems, IPaginationOptions } from './createPaginationItems';
import { IPageItem } from './itemFactory';
import PaginationItem from './PaginationItem';

export interface IPaginationOwnProps {
  activePage?       : number;
  defaultActivePage?: number;
  totalPages        : number;
  // firstItem?        : React.ReactNode | false;
  // prevItem?         : React.ReactNode | false;
  // nextItem?         : React.ReactNode | false;
  // lastItem?         : React.ReactNode | false;
  BoxProps?         : BoxProps;

  onPageChange?: (event: React.SyntheticEvent, activePage: number) => void;
}

export interface IPaginationDefaultProps {
  siblingRange : number;
  boundaryRange: number;
  hideEllipsis : boolean;
}

export type PaginationProps = IPaginationOwnProps &
  Partial<IPaginationDefaultProps> &
  BoxProps;

type AutoControlledState = Required<
  Pick<IPaginationOwnProps, 'activePage'>
>;

const paginationAutoControlledManager = new AutoControlledManager(
  ['activePage'],
  {
    getInitialAutoControlledState(): State {
      return {activePage: 0};
    },
  },
);

type State = AutoControlledState;

class Pagination extends React.Component<PaginationProps, State> implements AutoControlled<AutoControlledState> {
  public static defaultProps: IPaginationDefaultProps = {
    siblingRange : 1,
    boundaryRange: 1,
    hideEllipsis : false,
  };

  public static getDerivedStateFromProps = paginationAutoControlledManager.getDerivedStateFromProps;
  public state = paginationAutoControlledManager.getInitialAutoControlledStateFromProps(this.props);
  public trySetState = paginationAutoControlledManager.trySetState;

  public get disablePrev(): boolean {
    return this.state.activePage <= 1;
  }

  public get disableNext(): boolean {
    return this.state.activePage >= this.props.totalPages;
  }

  public render() {
    const {
      siblingRange,
      boundaryRange,
      hideEllipsis,
      totalPages,
      BoxProps: boxProps = {},
    } = this.props;
    const { activePage } = this.state;

    const items: Array<IPageItem | false> = createPaginationItems({
      activePage,
      boundaryRange,
      hideEllipsis,
      siblingRange,
      totalPages,
    } as IPaginationOptions);

    return (
      <Box display='flex' {...boxProps}>
        {items.map(this.renderPageItem)}
      </Box>
    );
  }

  private renderPageItem = (item: IPageItem) => {
    const key = this.getItemKey(item);
    if (item.type !== 'ellipsisItem') {
      return (
        <PaginationItem key={key} {...item} onClick={this.handleOnClick(item)} />
      );
    }

    return (
      <PopupState key={key} variant='popover'>
        {(popupState) => (
          <EllipsisItem
          popupState={popupState}
          item={item}
          activePage={this.state.activePage}
          handlePageChange={this.handlePageChange}
          />
        )}
      </PopupState>
    );
  }

  private getItemKey = (item: IPageItem) => {
    switch (item.type) {
      // case 'ellipsisItem': return '...';
      case 'firstItem'   :
      case 'lastItem'    :
      case 'prevItem'    :
      case 'nextItem'    : return item.type;
           default       : return `${item.type}-${item.value}`;
    }
  }

  private handleOnClick = (item: IPageItem) => (e: React.SyntheticEvent) => {
    if (item.type !== 'ellipsisItem') {
      this.handleItemClick(e, item);
    }
  }

  private handleItemClick = (e: React.SyntheticEvent, item: IPageItem) => {
    this.handlePageChange(e, item.value);
  }

  private handlePageChange = (e: React.SyntheticEvent, value: number) => {
    this.trySetState({activePage: value});
    if (this.props.onPageChange) {
      this.props.onPageChange(e, value);
    }
  }
}

interface IEllipsisItemProps {
  item: IPageItem;
  popupState: PopupState.InjectedProps;
  activePage: number;
  handlePageChange: any;
}

function EllipsisItem(props: IEllipsisItemProps) {
  const { item, popupState, handlePageChange, activePage } = props;

  const [state, setState] = useState({
    value: activePage,
  });

  const trigger = bindTrigger(popupState);
  const menu = bindMenu(popupState);

  const handleChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setState(update(state, {[name]: {$set: event.target.value}}));
  };

  const handleGoTo = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    handlePageChange(e, state.value);
    menu.onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.keyCode) {
      case EKeyCode.ENTER:
        handlePageChange(e, state.value);
        menu.onClose();
        break;
    }
  };

  const notNumber: boolean = isNaN(state.value) || !Number.isInteger(+state.value);

  return (
    <React.Fragment>
      <PaginationItem {...item} {...trigger} />
      <Menu {...menu}>
        <Box display='flex'>
          <TextField
          label='page number'
          value={state.value}
          error={notNumber}
          onChange={handleChange('value')}
          onKeyDown={handleKeyDown}
          />
          <Button value={state.value} onClick={handleGoTo} disabled={notNumber}>go</Button>
        </Box>
      </Menu>
    </React.Fragment>
  );
}

export default Pagination;
