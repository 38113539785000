import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import React from 'react';

import DirectoryCard from './DirectoryCard';

interface DirectoryCardSkeletonOwnProps {
  className?: string;
}

type DirectoryCardSkeletonProps = DirectoryCardSkeletonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  title: {
    flex: 1,
  },
}), {name: 'DirectoryCardSkeleton'});

function DirectoryCardSkeleton(props: DirectoryCardSkeletonProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <DirectoryCard
    className={classNames(classes.root, className)}
    classes={{title: classes.title}}
    disabled={true}
    name={(
      <Skeleton variant='text' />
    )}
    />
  );
}

export default React.memo(DirectoryCardSkeleton);
