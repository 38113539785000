import gql from 'graphql-tag';

import { CardFragment,TeamFragment } from '../fragments';

export const CreateTeamSubscription_Mutation = gql`
    mutation CreateTeamSubscriptionMutation(
        $input: CreateSubscriptionInput!
    ) {
        createTeamSubscription(
            input: $input
        ) {
            invoiceStatus
            secret
            team {
                ...TeamFragment
            }
            card {
                ...CardFragment
            }
        }
    }
    ${TeamFragment}
    ${CardFragment}
`;
