import gql from 'graphql-tag';

import { SavedCardFragment } from '../fragments';

export const AddTeamCard_Mutation = gql`
    mutation AddTeamCardMutation(
        $input: AddTeamCardInput!
    ) {
        addTeamCard(
            input: $input
        ) {
            card {
                ...SavedCardFragment
            }
        }
    }
    ${SavedCardFragment}
`;
