import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles,makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { EIntercomID } from 'constants/strings';
import LeaveSessionDialog from 'features/live-session/LiveSessionLeaveDialog';
import { useIsDesktop } from 'features/media-queries/hooks';
import { isVideoFragment } from 'helpers/isVideoFragment';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import LiveSymbol from 'material/live-symbol/LiveSymbol';
import React from 'react';
import { SmolVideo } from 'types/pigeon/kmsession';

interface SessionViewerHeaderOwnProps {
  video: SmolVideo;
  isHost?: boolean;
}

type SessionViewerHeaderProps = SessionViewerHeaderOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  negative: {
    borderColor: theme.palette.negative.main,
  },
}), { name: 'SessionViewerHeader' });

function SessionViewerHeader({ isHost, video }: SessionViewerHeaderProps) {
  const classes = useStyles();
  const { t } = useStrictTranslation(['dialog', 'common']);
  const [isOpen, open, onClose] = useDialogState();

  const isDesktop = useIsDesktop();

  return (
    <React.Fragment>
      <Box overflow='hidden'>
        <Typography style={{lineHeight: 'inherit'}} variant='h6' component='h2' noWrap={true} title={video.name}>
          {video.name}
        </Typography>
        <div>
          <Box position='absolute'>
            <LiveSymbol text={t('common:session.live')} />
          </Box>
        </div>
      </Box>
      <Box flexGrow='1' />
      {isDesktop && (
        <Box>
          <Button
          id={EIntercomID.LEAVE_LIVE_SESSION}
          variant='outlined'
          classes={{ root: classes.negative }}
          onClick={open}
          >
            {t(isHost ? 'dialog:leavesession.hostterminatesession' : 'dialog:leavesession.title')}
          </Button>
        </Box>
      )}
      <LeaveSessionDialog
      open={isOpen}
      onClose={onClose}
      fullWidth={true}
      isPleb={!isHost}
      video={isVideoFragment(video) ? video : undefined}
      />
    </React.Fragment>
  );
}

export default React.memo(SessionViewerHeader);
