import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import { AuthenticationMethodFragment } from 'apollo/fragments/types/AuthenticationMethodFragment';
import classNames from 'classnames';
import { registerPasskeyAC } from 'features/auth/auth-slice';
import { useDialogState } from 'hooks/useDialogState';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useSelector } from 'hooks/useSelector';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { getMe } from 'selectors/getMe';
import { AuthenticationProvider } from 'types/graphql';

import { oauthEndpoint } from '../../../../constants';
import SavePasskeyNameDialog from './SavePasskeyNameDialog';
import SigninMethodsTableRowWrapper from './SigninMethodsTableRowWrapper';

declare var PublicKeyCredential: {
    prototype: PublicKeyCredential;
    new(): PublicKeyCredential;
    isConditionalMediationAvailable(): Promise<boolean>;
    isUserVerifyingPlatformAuthenticatorAvailable(): Promise<boolean>;
};

interface PasskeyData {
  id: string;
  algo: number;
  key: string;
}

interface SigninMethodsTableOwnProps {
  className?: string;
  providers: AuthenticationMethodFragment[];
  hasPassword: boolean;
}

type SigninMethodsTableProps = SigninMethodsTableOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  connectButton: {
    color: theme.palette.text.primary,
  },
}), {name: 'SigninMethodsTable'});

async function checkCanUsePasskeys() {
  if (
    !window.PublicKeyCredential ||
    !PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable ||
    !PublicKeyCredential.isConditionalMediationAvailable
  ) {
    return false;
  }

  return (
    await Promise.all([
      PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
      PublicKeyCredential.isConditionalMediationAvailable(),
    ])
  ).every(Boolean);
}

function useCanUsePasskeys() {
  const [canUsePasskeys, setCanUsePasskeys] = useState(false);

  useEffect(() => {
    checkCanUsePasskeys().then(setCanUsePasskeys);
  }, []);

  return canUsePasskeys;
}

const defaultPasskeyName = 'Unnamed Passkey';

function SigninMethodsTable(props: SigninMethodsTableProps) {
  const classes = useStyles(props);
  const { className, providers, hasPassword } = props;
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const [isSavePasskeyNameDialogOpen, openSavePasskeyNameDialog, closeSavePasskeyNameDialog] = useDialogState();

  const [creatingPasskey, setCreatingPasskey] = useState(false);
  const [savingPasskey, setSavingPasskey] = useState(false);
  const [passkeyData, setPasskeyData] = useState<PasskeyData>();

  const canUsePasskeys = useCanUsePasskeys();
  const me = useSelector(getMe);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useStrictTranslation(['common', 'settings']);

  const handleAddPasskey = useCallback(async () => {
    if (!me?.email) {
      return;
    }

    try {
      setCreatingPasskey(true);

      const result = await navigator.credentials.create({
        publicKey: {
          challenge: new ArrayBuffer(0),
          rp: {
            name: 'Insights.gg',
            id: window.location.hostname,
          },
          user: {
            id: Uint8Array.from(me.id, c => c.charCodeAt(0)),
            name: me.email,
            displayName: me.alias,
          },
          pubKeyCredParams: [{alg: -7, type: 'public-key'}, {alg: -257, type: 'public-key'}],
          excludeCredentials: await Promise.all(
            providers.filter((p) => p.name === AuthenticationProvider.WEBAUTHN).map(async (p) => ({
              type: 'public-key' as any,
              id: await (
                // HACK: base64 decode key id by fetching it as a data url
                await fetch(
                  'data:application/octet-stream;base64,' + p.externalId.replace(/_/g, '/').replace(/-/g, '+'),
                )
              ).arrayBuffer(),
            })),
          ),
          authenticatorSelection: {
          },
        },
      });

      if (
        !(result instanceof PublicKeyCredential) ||
        !result.response ||
        !(result.response instanceof AuthenticatorAttestationResponse)
      ) {
        // not a valid response
        return;
      }

      const keyBytes = result.response.getPublicKey();
      if (!keyBytes) {
        // didn't get a key?
        return;
      }

      setPasskeyData({
        id: result.id,
        algo: result.response.getPublicKeyAlgorithm(),
        key: btoa(String.fromCharCode.apply(null, new Uint8Array(keyBytes))),
      });

      openSavePasskeyNameDialog();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      setCreatingPasskey(false);
    }
  }, [me, providers, enqueueSnackbar, openSavePasskeyNameDialog]);

  const handlePasskeyNameClose = useCallback(() => {
    setPasskeyData(undefined);
    setCreatingPasskey(false);
    closeSavePasskeyNameDialog();
  }, [closeSavePasskeyNameDialog]);

  const handlePasskeyNameSave = useCallback(async (customName: string | undefined) => {
    if (!passkeyData) {
      return;
    }

    try {
      setSavingPasskey(true);
      await promiseSagaDispatch(registerPasskeyAC, {
        ...passkeyData,
        name: customName || defaultPasskeyName,
      });
      handlePasskeyNameClose();
    } catch(err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setSavingPasskey(false);
    }
  }, [passkeyData, promiseSagaDispatch, handlePasskeyNameClose, enqueueSnackbar]);

  return (
    <FlexSpacer
    className={classNames(classes.root, className)}
    orientation='vertical'
    spacing={2}
    >
      <FlexSpacer flexJustifyContent='end'>
        <Button
        component={Link}
        href={oauthEndpoint('google-oauth2', { referPath: window.location.pathname }, true)}
        disabled={creatingPasskey}
        startIcon={<AddIcon />}
        className={classes.connectButton}
        >
          {t('settings:signinmethods.addnewgoogle')}
        </Button>
        <Button
        component={Link}
        href={oauthEndpoint('discordapp', { referPath: window.location.pathname }, true)}
        disabled={creatingPasskey}
        startIcon={<AddIcon />}
        className={classes.connectButton}
        >
          {t('settings:signinmethods.addnewdiscord')}
        </Button>
        {canUsePasskeys && (
          <AsyncButton
          disabled={creatingPasskey}
          loading={creatingPasskey}
          onClick={handleAddPasskey}
          startIcon={<AddIcon />}
          >
            {t('settings:signinmethods.addpasskey')}
          </AsyncButton>
        )}
      </FlexSpacer>
      <Table className={classNames(classes.root, className)} size='small'>
        <TableHead>
          <TableRow>
            <FixedTableCell width={200}>{t('settings:signinmethods.provider')}</FixedTableCell>
            <FixedTableCell width={150}>{t('settings:signinmethods.date')}</FixedTableCell>
            <FixedTableCell width={250}>{t('settings:signinmethods.id')}</FixedTableCell>
            <FixedTableCell width={180}>{t('settings:signinmethods.name')}</FixedTableCell>
            <FixedTableCell width={150}>{t('settings:signinmethods.action')}</FixedTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {providers.map((provider, i) => (
            <SigninMethodsTableRowWrapper
            key={i}
            provider={provider}
            preventDelete={!hasPassword && providers.length <= 1}
            />
          ))}
        </TableBody>
      </Table>
      <SavePasskeyNameDialog
      open={isSavePasskeyNameDialogOpen}
      onClose={handlePasskeyNameClose}
      handleSave={handlePasskeyNameSave}
      loading={savingPasskey}
      defaultName={defaultPasskeyName}
      />
    </FlexSpacer>
  );
}

export default React.memo(SigninMethodsTable);
