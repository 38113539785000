import gql from 'graphql-tag';

import { TeamFragment } from '../fragments';

export const UpdateTeam_Mutation = gql`
    mutation UpdateTeamMutation(
        $input: UpdateTeamInput!
    ) {
        updateTeam(
            input: $input
        ) {
            team {
                ...TeamFragment
            }
        }
    }
    ${TeamFragment}
`;
