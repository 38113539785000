import { useCreateSelector } from '@insights-gaming/redux-utils';
import { UploadQueueItemProps } from '@insights-gaming/upload-queue';
import { makeGetVideoById } from 'features/dashboard/video/dashboard-video-selector';
import { deleteVideoAC } from 'features/dashboard/video/dashboard-video-slice';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import React, { forwardRef, Ref, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { videoRoute } from 'routes';

import { makeGetUploadedVideoByVideoUuid, makeGetUploadQueueByVideoUuid } from '../resumable-uploads-selector';
import { getCurrentUpload, makeGetUploadByVideoUuid } from '../upload-selector';
import { dequeueVideoUploadAC, promoteVideoUploadAC } from '../upload-slice';
import AbortUploadDialog from './AbortUploadDialog';


export function withUploadQueueItem<P extends UploadQueueItemProps>(
  Component: React.ComponentType<P>,
) {
  const WithUploadQueueItem = forwardRef(function WithUploadQueueItem(props: P, forwardedRef: Ref<HTMLElement | null>) {
    const { uuid } = props;

    const dispatch = useDispatch();
    const promisifiedSagaDispatch = usePromiseSagaDispatch();
    const onNavigate = useNavigate();

    const uploadedVideo = useCreateSelector(makeGetUploadedVideoByVideoUuid, { videoUuid: uuid });

    const uploadQueueVideo = useCreateSelector(makeGetUploadQueueByVideoUuid, { videoUuid: uuid });

    const video = useCreateSelector(makeGetVideoById, uploadedVideo?.videoId);

    const startedVideoUpload = useCreateSelector(makeGetUploadByVideoUuid, { videoUuid: uuid });

    const currentUpload = useSelector(getCurrentUpload);

    const [isAbortUploadDialogOpen, openAbortUploadDialog, closeAbortUploadDialog] = useDialogState(false);

    const handlePromoteUpload = useCallback(() => {
      if (!uploadQueueVideo || !uuid) {
        return;
      }
      dispatch(promoteVideoUploadAC.started({ uuid }));
    }, [dispatch, uploadQueueVideo, uuid]);

    const handleAbortUpload = useCallback(() => {
      if (startedVideoUpload) {
        promisifiedSagaDispatch(deleteVideoAC, {id: startedVideoUpload.id});
        closeAbortUploadDialog();
      } else if (uploadQueueVideo) {
        dispatch(dequeueVideoUploadAC.started({ video: uploadQueueVideo }));
        closeAbortUploadDialog();
      }
    }, [closeAbortUploadDialog, dispatch, promisifiedSagaDispatch, startedVideoUpload, uploadQueueVideo]);

    const redirect = useCallback(() => {
      if (uploadedVideo) {
        onNavigate(videoRoute(uploadedVideo.videoId));
      }
    }, [onNavigate, uploadedVideo]);

    const metadata = useMemo(() => {
      return video
        ? {
          name: video.name,
          size: video.filesize,
        }
        : uploadQueueVideo
        ? {
          name: uploadQueueVideo.file.name,
          size: uploadQueueVideo.file.size,
        }
        : currentUpload
        ? {
          name: currentUpload.video.file.name,
          size: currentUpload.video.file.size,
        }
        : undefined;
    }, [currentUpload, uploadQueueVideo, video]);

    return (
      <Component
      ref={forwardedRef}
      {...props}
      openAbortUploadDialog={openAbortUploadDialog}
      handlePromoteUpload={handlePromoteUpload}
      videoMetadata={metadata}
      abortUploadDialog={(
        <AbortUploadDialog
        open={isAbortUploadDialogOpen}
        onClose={closeAbortUploadDialog}
        videoNames={[metadata?.name || '']}
        onAbort={handleAbortUpload}
        />
      )}
      redirectOnClick={redirect}
      />
    );
  });
  WithUploadQueueItem.displayName = `WithUploadQueueItem(${Component.displayName || ''})`;

  return WithUploadQueueItem;
}
