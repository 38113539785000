import gql from 'graphql-tag';

import { MatchFragment } from '../fragments';

export const UpdateMatch_Mutation = gql`
    mutation UpdateMatchMutation(
        $input: UpdateMatchInput!
    ) {
        updateMatch(
            input: $input
        ) {
            match {
                ...MatchFragment
            }
        }
    }
    ${MatchFragment}
`;
