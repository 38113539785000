import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

interface FailureDialogOwnProps {
  className?: string;
  onClose: VoidFunction;
  open: boolean;
}

type FailureDialogProps = FailureDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
  },
  icon: {
    fontSize: 96,
    color: theme.palette.error.main,
  },
  message: {
    margin: theme.spacing(1, 2),
  },
}), {name: 'FailureDialogContent'});

function FailureDialog(props: FailureDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <FailureDialogContent {...props} />
    </Dialog>
  );
}

function FailureDialogContent(props: FailureDialogProps) {
  const classes = useStyles(props);
  const { className, onClose } = props;
  const { t } = useStrictTranslation(['dialog']);
  return (
    <DialogContent className={classNames(classes.root, className)}>
      <CancelRoundedIcon className={classes.icon}/>
      <Typography variant='h4'>
        {t('dialog:paymentfailure.ohno')}
      </Typography>
      <Typography>
        {t('dialog:paymentfailure.paymentfailed')}
      </Typography>
      <Typography>
        {t('dialog:paymentfailure.tryagain')}
      </Typography>
    </DialogContent>
  );
}

export default React.memo(FailureDialog);
