import { ASSETS_ENDPOINT, CDN_ENDPOINT } from '../../constants';

export type Mahou = 'stickerfy';

interface Options {
  mahou: Mahou;
}

function sliceCdnPath(url: string): boolean {
  return url.startsWith(CDN_ENDPOINT) || url.startsWith(ASSETS_ENDPOINT);
}

export function makeCdnImagePathMagical(cdnImagePath: string, {mahou}: Options): string {
  if (cdnImagePath.startsWith(CDN_ENDPOINT)) {
    cdnImagePath = cdnImagePath.slice(CDN_ENDPOINT.length + 1);
    cdnImagePath = [
      // window.location.origin,
      '~mahou',
      mahou,
      'cdn',
      cdnImagePath,
    ].join('/');
  } else if (cdnImagePath.startsWith(ASSETS_ENDPOINT)) {
    cdnImagePath = cdnImagePath.slice(ASSETS_ENDPOINT.length + 1);
    cdnImagePath = [
      // window.location.origin,
      '~mahou',
      mahou,
      'assets',
      cdnImagePath,
    ].join('/');
  }
  return '/' + cdnImagePath;
}

export interface StickerfyOptions {
  color      : string;
  background?: string;
  stroke?    : number;
}

export type MahouOptions<M extends Mahou> = M extends 'stickerfy' ? (
  StickerfyOptions
) : never;

export function createStickerfySearchParams(
  { color, background, stroke }: StickerfyOptions,
  params?: URLSearchParams,
): URLSearchParams {
  params = new URLSearchParams(params);
  params.set('color', color);
  if (background) {
    params.set('background', background);
  }
  if (stroke) {
    params.set('stroke', stroke.toString());
  }
  return new URLSearchParams({ mahou: params.toString() });
}
