import { FlexSpacer } from '@insights-gaming/material-components';
import { GoldIcon, WardIcon } from '@insights-gaming/material-icons';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { LoLHelper, Riot } from '@insights-gaming/statistics';
import { Theme } from '@insights-gaming/theme';
import { createShortDurationTextWithZeros } from '@insights-gaming/utils';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { makeGetLeagueAssetsByGameVersion } from '../common';

interface LeagueOfLegendUserStatisticsOwnProps {
  className?: string;
  userStats: Riot.League.RawDataInfoParticipant;
  win: boolean;
  gameDuration: number;
  totalUserTeamKills: number;
  gameVersion: string;
}

type LeagueOfLegendUserStatisticsProps = LeagueOfLegendUserStatisticsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.dialog,
  },
  userCharacter: {
    width: theme.spacing(6.5),
    height: theme.spacing(6.5),
    border: `1px solid ${theme.palette.overwatch.blueTeam}`,
  },
  summonerSkills: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, auto)',
    gap: theme.spacing(0.5),
  },
  spellIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  dataConatiner: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, 1fr)',
    justifyItems: 'center',
    alignItems: 'center',
  },
  gameResult: {
    color: ({ win }: LeagueOfLegendUserStatisticsProps) =>
      win ? '#00C92C' : theme.palette.overwatch.redTeam,
  },
  header: {
    opacity: 0.75,
  },
}), { name: 'LeagueOfLegendUserStatistics' });

function LeagueOfLegendUserStatistics(props: LeagueOfLegendUserStatisticsProps) {
  const classes = useStyles(props);
  const { className, userStats, gameDuration, totalUserTeamKills, win, gameVersion } = props;

  const assets: Riot.League.Assets | undefined = useCreateSelector(
    makeGetLeagueAssetsByGameVersion,
    { gameVersion },
  );

  const perkStyles = assets?.perkStyles;
  const summonerSpells = assets?.summonerSpells;

  const { t } = useStrictTranslation(['common', 'statistics']);
  const { i18n } = useTranslation();

  const getPerkIconPath = useCallback((perkId: number) => {
    const path = perkStyles?.find(p => p.id === perkId)?.iconPath || '';
    return LoLHelper.getIconAsset(path, gameVersion);
  }, [perkStyles, gameVersion]);

  const getSummonerSpellIconPath = useCallback((spellId: number) => {
    const path = summonerSpells?.find(s => s.id === spellId)?.iconPath || '';
    return LoLHelper.getIconAsset(path, gameVersion);
  }, [summonerSpells, gameVersion]);

  const killStreak = useMemo(() => {
    if (userStats.pentaKills) {
      return t('statistics:stats.lol.pentakill');
    } else if (userStats.quadraKills) {
      return t('statistics:stats.lol.quadrakill');
    } else if (userStats.tripleKills) {
      return t('statistics:stats.lol.triplekill');
    } else if (userStats.doubleKills) {
      return t('statistics:stats.lol.doublekill');
    } else {
      return null;
    }
  }, [t, userStats]);

  const kdaStats = useMemo(() => {
    return [
      {
        id: 'kills',
        label: t('statistics:stats.lol.kill'),
        data: userStats.kills,
      },
      {
        id: 'deaths',
        label: t('statistics:stats.lol.death'),
        data: userStats.deaths,
      },
      {
        id: 'assists',
        label: t('statistics:stats.lol.assist'),
        data: userStats.assists,
      },
      {
        id: 'cs',
        label: t('statistics:stats.lol.creepscorelabel'),
        data: t('statistics:stats.lol.creepscorepermin', {
          creepScore: userStats.totalMinionsKilled + userStats.neutralMinionsKilled,
          creepScorePerMin: (
            (userStats.totalMinionsKilled + userStats.neutralMinionsKilled) /
            LoLHelper.getGameDurationInMin(gameDuration)
          ).toFixed(1),
        }),
      },
    ];
  }, [userStats, t, gameDuration]);

  const killParticipation = useMemo(() => {
    return (((userStats.kills + userStats.assists) / totalUserTeamKills) * 100).toFixed(0) + '%';
  }, [userStats, totalUserTeamKills]);

  const dmgStats = useMemo(() => {
    return [
      {
        id: 'dmgdealt',
        label: (
          <div className={classes.header}>
            {t('statistics:stats.lol.dmgdealt')}
          </div>
        ),
        data: userStats.totalDamageDealtToChampions.toLocaleString(i18n.language),
      },
      {
        id: 'dmgtaken',
        label: (
          <div className={classes.header}>
            {t('statistics:stats.lol.dmgtaken')}
          </div>
        ),
        data: userStats.totalDamageTaken.toLocaleString(i18n.language),
      },
      {
        id: 'killparticipation',
        label: (
          <div className={classes.header}>
            {t('statistics:stats.lol.killparticipation')}
          </div>
        ),
        data: killParticipation,
      },
      {
        id: 'ward',
        label: <WardIcon htmlColor='#00C92C' />,
        data: userStats.wardsPlaced,
      },
      {
        id: 'gold',
        label: <GoldIcon htmlColor='#FFCD1A' />,
        data: userStats.goldEarned.toLocaleString(i18n.language),
      },
    ];
  }, [userStats, killParticipation, t, classes.header, i18n]);

  return (
    <FlexSpacer className={classNames(classes.root, className)} orientation='vertical'>
      <FlexSpacer flexJustifyContent='space-between' flexAlignItems='center'>
        <FlexSpacer flexAlignItems='center' spacing={0.5}>
          <Typography className={classes.gameResult}>
            {win ? t('statistics:stats.lol.victory') : t('statistics:stats.lol.defeat')}
          </Typography>
          <Typography>
            <Trans
            t={t as TFunction}
            i18nKey='statistics:stats.lol.gameinfo'
            values={{
              championName: userStats.championName,
              level: userStats.champLevel,
              gameDuration: createShortDurationTextWithZeros(gameDuration*1000),
            }}
            />
          </Typography>
        </FlexSpacer>
        {killStreak && <Chip label={killStreak} variant='outlined' color='primary' size='small' />}
      </FlexSpacer>
      <FlexSpacer spacing={1}>
        <Avatar
        className={classes.userCharacter}
        src={LoLHelper.getChampionIconSrc(userStats.championId, gameVersion)}
        />
        <div className={classes.summonerSkills}>
          <Avatar
          className={classes.spellIcon}
          src={getSummonerSpellIconPath(userStats.summoner1Id)}
          />
          <Avatar
          className={classes.spellIcon}
          src={getSummonerSpellIconPath(userStats.summoner2Id)}
          />
          <Avatar
          className={classes.spellIcon}
          src={getPerkIconPath(userStats.perks.styles[0].style)}
          />
          <Avatar
          className={classes.spellIcon}
          src={getPerkIconPath(userStats.perks.styles[1].style)}
          />
        </div>
        <FlexSpacer fullWidth={true} flexAlignItems='center' flexJustifyContent='space-evenly'>
          {kdaStats.map((s) => {
            return (
              <div key={s.id} className={classes.dataConatiner}>
                <div className={classes.header}>{s.label}</div>
                <Typography variant='h5'>{s.data}</Typography>
              </div>
            );
          })}
        </FlexSpacer>
      </FlexSpacer>
      <FlexSpacer fullWidth={true} flexJustifyContent='space-evenly' spacing={0}>
        {dmgStats.map((ds) => {
          return (
            <div key={ds.id} className={classes.dataConatiner}>
              <div>{ds.label}</div>
              <Typography variant='h5'>{ds.data}</Typography>
            </div>
          );
        })}
      </FlexSpacer>
    </FlexSpacer>
  );
}

export default React.memo(LeagueOfLegendUserStatistics);
