import { Theme } from '@insights-gaming/theme';
import { createStyles, fade, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import classNames from 'classnames';
import { desktop, mobilePortrait } from 'features/media-queries';
import { darkPaletteGradient } from 'helpers/color';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { DialogNS } from 'locales/en/dialog';
import React from 'react';
import { Trans } from 'react-i18next';
import { BillingInterval } from 'types/graphql';

interface ToggleIntervalButtonGroupOwnProps {
  className?: string;
  interval: BillingInterval;
  onChange: (event: React.MouseEvent<HTMLElement>, newValue: BillingInterval) => void;
}

type ToggleIntervalButtonGroupProps = ToggleIntervalButtonGroupOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  toggleButtonGroup: {
    [mobilePortrait(theme)]: {
      borderRadius: 12,
      backgroundColor: fade(theme.palette.background.note, 0.5),
    },
  },
  toggleButtonText: {
    '&$selected': {
      background: darkPaletteGradient.gradient2.toBottom,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
  selected: {},
}), { name: 'ToggleIntervalButtonGroup' });

const useToggleButtonStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: 115,
    margin: 0,
    padding: theme.spacing(1),
    borderRadius: 8,
    [mobilePortrait(theme)]: {
      borderColor: 'transparent',
      borderRadius: 12,
      padding: theme.spacing(0.5),
    },
    '&:not($selected)': {
      color: theme.palette.text.deemphasized,
      backgroundColor: theme.palette.background.container,
      [mobilePortrait(theme)]: {
        backgroundColor: 'transparent',
      },
    },
    [desktop(theme)]: {
      '&&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.text.primary,
      },
    },
    '&$initial': {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.text.primary,
    },
    '&$selected': {
      backgroundColor: fade(theme.palette.secondary.main, 0.16),
      [mobilePortrait(theme)]: {
        borderRadius: 12,
        '&:hover': {
          backgroundColor: fade(theme.palette.secondary.main, 0.16),
        },
      },
    },
    // [mobilePortrait(theme)]: {
    //   '&:not(:last-child)': {
    //     borderTopRightRadius: theme.shape.borderRadius,
    //     borderBottomRightRadius: theme.shape.borderRadius,
    //   },
    //   '&:not(:first-child)': {
    //     borderTopLeftRadius: theme.shape.borderRadius,
    //     borderBottomLeftRadius: theme.shape.borderRadius,
    //     marginLeft: theme.spacing(1),
    //     borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
    //   },
    // },
  },
  initial: {},
  selected: {},
}), {name: 'ToggleButton'});

function ToggleIntervalButtonGroup(props: ToggleIntervalButtonGroupProps) {
  const classes = useStyles(props);
  const { className, interval, onChange } = props;

  const toggleButtonClasses = useToggleButtonStyles();

  const { t } = useStrictTranslation(['dialog']);

  return (
    <div className={classNames(classes.root, className)}>
      <ToggleButtonGroup
      value={interval}
      onChange={onChange}
      exclusive={true}
      className={classes.toggleButtonGroup}
      >
        <ToggleButton
        key={BillingInterval.MONTHLY}
        aria-label='monthly'
        value={BillingInterval.MONTHLY}
        classes={toggleButtonClasses}
        >
          <strong
          className={classNames(classes.toggleButtonText, { [classes.selected]: interval === BillingInterval.MONTHLY })}
          >
            {t('dialog:availableplans.monthly')}
          </strong>
        </ToggleButton>
        <ToggleButton
        key={BillingInterval.YEARLY}
        aria-label='annually'
        value={BillingInterval.YEARLY}
        classes={toggleButtonClasses}
        >
          <Trans
          i18nKey={t('dialog:availableplans.yearly')}
          ns={DialogNS}
          >
            <div style={{ lineHeight: 'initial'}}>
              <strong
              className={classNames(
                classes.toggleButtonText,
                { [classes.selected]: interval === BillingInterval.YEARLY },
              )}
              >
                Yearly
              </strong>
              <br />
              <Typography style={{ textTransform: 'capitalize' }} variant='caption'>
                Save up to 30%
              </Typography>
            </div>
          </Trans>
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export default React.memo(ToggleIntervalButtonGroup);
