import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import BackButton, { IBackButtonDispatch } from '../subcomponents/buttons/back-button/BackButton';
import { onNavigateFactory } from '../types/dispatch';

function mapDispatchToProps(dispatch: Dispatch): IBackButtonDispatch {
  return {
    onNavigate: onNavigateFactory(dispatch),
  };
}

export default connect<void, IBackButtonDispatch>(null, mapDispatchToProps)(BackButton);
