import { Theme } from '@insights-gaming/theme';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import classNames from 'classnames';
import VideoCardSkeleton from 'components/video-card/VideoCardSkeleton';
import { useFetchVideoIfNecessary } from 'features/dashboard/video/useFetchVideoIfNecessary';
import EmptyState from 'features/empty-state/EmptyState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import EmptyStateImage from 'subcomponents/empty-state-image/EmptyStateImage';
import { ID } from 'types/pigeon';

interface VideoGridOwnProps {
  className?: string;
  videoIds: ID[];
  displaySkeleton?: boolean;
  renderVideoCard: (video: VideoFragment, i: number) => React.ReactElement;
}

export type VideoGridProps = VideoGridOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    overflow: 'hidden',
  },
}), {name: 'VideoGrid'});

function VideoContainer(props: Pick<VideoGridProps, 'renderVideoCard'> & { id: ID, i: number }) {
  const [video] = useFetchVideoIfNecessary(props.id);

  if (!video) {
    return <VideoCardSkeleton />;
  }

  return props.renderVideoCard(video, props.i);
}

const MemoizedVideoContainer = React.memo(VideoContainer);

function VideoGrid(props: VideoGridProps) {
  const classes = useStyles(props);
  const { t } = useStrictTranslation(['dashboard']);
  const { className, displaySkeleton = false, videoIds, renderVideoCard } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <Grid container={true} spacing={2}>
        {displaySkeleton ? (
          Array.from({length: 2}).map((_, i) => (
            <Grid key={i} item={true} xs={6} sm={6} md={4} lg={3} xl={2}>
              <VideoCardSkeleton />
            </Grid>
          ))
        ) : !videoIds.length ? (
          <EmptyState
          mainTitle={t('dashboard:novideos')}
          subTitle={t('dashboard:uploadvideo')}
          img={<EmptyStateImage url='no-video' />}
          />
        ) : (
          videoIds.map((videoId, i) => (
            <Grid key={videoId} item={true} xs={6} sm={6} md={4} lg={3} xl={2}>
              <MemoizedVideoContainer id={videoId} i={i} renderVideoCard={renderVideoCard} />
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
}

export default React.memo(VideoGrid);
