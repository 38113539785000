import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';
import { makeGetTeamInvoiceRecordsByTeamId } from 'features/dashboard/billing/dashboard-billing.selectors';
import { fetchTeamInvoicesAC } from 'features/dashboard/billing/dashboard-billing-slice';

export const useFetchTeamInvoices = createBidirectionalAutoFetcher({
  actionCreators: fetchTeamInvoicesAC,
  selectorFactory: makeGetTeamInvoiceRecordsByTeamId,
  paramsTransform: fetchId => ({teamId: fetchId}),
  pageSize: 12,
});
