import { FlexSpacer } from '@insights-gaming/material-components';
import { LeagueMetadata, LeagueOfLegendsTeamOverview, LoLHelper, Riot } from '@insights-gaming/statistics';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import fromPairs from 'lodash/fromPairs';
import groupBy from 'lodash/groupBy';
import React, { useMemo } from 'react';

import LeagueOfLegendsTeamStatsTable from './LeagueOfLegendsTeamStatsTable';
import LeagueOfLegendUserStatistics from './LeagueOfLegendUserStatistics';
import { useFetchLeagueOfLegendsAssets } from './useFetchLeagueOfLegendsAssets';

interface LeagueStatisticsOwnProps {
  className?: string;
  lolStats: Riot.League.RawDataInfo;
  gameMetadata: LeagueMetadata;
}

type LeagueStatisticsProps = LeagueStatisticsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    overflow: 'auto',
  },
}), { name: 'LeagueStatistics' });

function LeagueStatistics(props: LeagueStatisticsProps) {
  const classes = useStyles(props);
  const { className, lolStats, gameMetadata } = props;

  const gameVersion = useMemo(() => {
    return LoLHelper.extractGameVersion(lolStats.gameVersion);
  }, [lolStats]);

  useFetchLeagueOfLegendsAssets(gameVersion);

  const participantStats = useMemo(() => {
    return groupBy(lolStats.participants, (p) => p.teamId);
  }, [lolStats]);

  const teamStats = useMemo(() => {
    return fromPairs(lolStats.teams.map((team) => [team.teamId, team]));
  }, [lolStats]);

  const userStats = useMemo(() => {
    const championName = gameMetadata.champion;
    return lolStats.participants.find(
      p => championName === p.championName,
    );
  }, [lolStats.participants, gameMetadata]);

  const mostDmgDoneToChamp = useMemo(() => {
    return lolStats.participants.reduce((max, p) => {
      return p.totalDamageDealtToChampions > max ? p.totalDamageDealtToChampions : max;
    }, 0);
  }, [lolStats]);

  const totalUserTeamKills = useMemo(() => {
    return userStats && participantStats[userStats.teamId].reduce((total, p) => {
      return total + p.kills;
    }, 0);
  }, [participantStats, userStats]);

  return userStats && totalUserTeamKills !== undefined ? (
    <FlexSpacer
    className={classNames(classes.root, className)}
    orientation='vertical'
    flexJustifyContent='space-evenly'
    >
      <LeagueOfLegendUserStatistics
      gameVersion={gameVersion}
      userStats={userStats}
      win={teamStats[userStats.teamId].win}
      gameDuration={lolStats.gameDuration}
      totalUserTeamKills={totalUserTeamKills}
      />
      <FlexSpacer orientation='vertical'>
        {Object.keys(teamStats).map(k => (
          <LeagueOfLegendsTeamOverview
          key={k}
          teamStats={teamStats[k]}
          participantStats={participantStats[k]}
          isUserTeam={teamStats[k].teamId === userStats.teamId}
          />
        ))}
      </FlexSpacer>
      <FlexSpacer orientation='vertical'>
        {Object.keys(teamStats).map(k => (
          <LeagueOfLegendsTeamStatsTable
          key={k}
          gameVersion={gameVersion}
          participantStats={participantStats[k]}
          mostDmgDoneToChamp={mostDmgDoneToChamp}
          isUserTeam={teamStats[k].teamId === userStats.teamId}
          />
        ))}
      </FlexSpacer>
    </FlexSpacer>
  ) : null;
}

export default React.memo(LeagueStatistics);
