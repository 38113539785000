import { UndraggableAvatar } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import CreateTeamDialog from 'features/dashboard/team/create-team-dialog/CreateTeamDialog';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback } from 'react';

import TeamListItem from './TeamListItem';

interface TeamSelectorOwnProps {
  className?: string;
  teams: TeamFragment[];
  stepForward: VoidFunction;
  setSelectedTeam: (team: TeamFragment) => void;
  selectedTeamId?: string;
  totalTeams?: number;
}

type TeamSelectorProps = TeamSelectorOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  header: {
    margin: theme.spacing(2.5, 0),
  },
  container: {
    width: createRemFromPx(450),
    maxWidth: createRemFromPx(450),
    maxHeight: createRemFromPx(288),
    alignSelf: 'center',
    overflowY: 'scroll',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },

}), {name: 'TeamSelector'});

function TeamSelector(props: TeamSelectorProps) {
  const classes = useStyles(props);
  const { className, teams, selectedTeamId, stepForward, setSelectedTeam, totalTeams } = props;
  const { t } = useStrictTranslation(['dialog', 'common']);

  const nextOnClick = useCallback(() => {
    stepForward();
  }, [stepForward]);

  const [isCreateTeamDialogOpen, openCreateTeamDialog, closeCreateTeamDialog] = useDialogState();

  return (
    <React.Fragment>
      <DialogContent className={classNames(classes.root, className)}>
        {
          (teams && typeof totalTeams === 'number' && totalTeams > 0) && (
            <Typography align='center' variant='body1'>
              {t('dialog:selectteam.nteamsareupgradable', { count: teams.length, total: totalTeams })}
            </Typography>
          )
        }
        <div className={classes.container}>
          <List disablePadding={true}>
            {teams.map(team => {
              return (
                <TeamListItem
                key={team.id}
                setSelectedTeam={setSelectedTeam}
                active={team.id === selectedTeamId}
                team={team}
                />
              );
            })}
          </List>
        </div>
        <ListItem button={true} onClick={openCreateTeamDialog}>
          <UndraggableAvatar size='sm' className={classes.avatar}>
            <AddIcon />
          </UndraggableAvatar>
          <ListItemText primary={t('common:createteam')} />
        </ListItem>
      </DialogContent>
      <DialogActions>
        <Button
        variant='contained'
        color='primary'
        disabled={!selectedTeamId}
        onClick={nextOnClick}
        >
          {t('common:next')}
        </Button>
      </DialogActions>
      <CreateTeamDialog open={isCreateTeamDialogOpen} onClose={closeCreateTeamDialog} redirect={false}/>
    </React.Fragment>
  );
}

export default React.memo(TeamSelector);
