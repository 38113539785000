import gql from 'graphql-tag';

export const TagVideo_Mutation = gql`
    mutation TagVideoMutation(
        $input: TagVideoInput!
    ) {
        tagVideo(
            input: $input
        ) {
            video {
                id
                tags
            }
        }
    }
`;
