import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetTeamVideoRecordsByTeamId } from './dashboard-video-selector';
import { fetchTeamVideosAC } from './dashboard-video-slice';

export const useFetchTeamVideos = createBidirectionalAutoFetcher({
  actionCreators: fetchTeamVideosAC,
  selectorFactory: makeGetTeamVideoRecordsByTeamId,
  paramsTransform: fetchId => ({teamId: fetchId}),
  pageSize: 24,
});
