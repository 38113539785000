type TpubgMaps =
|'camp_jackal'
|'erangel'
|'haven'
|'karakin'
|'miramar'
|'paramo'
|'sanhok'
|'vikendi'
;
const pubgMapsNS = 'pubg-maps';
function pubgMapsF(_: TpubgMaps): string { return `${pubgMapsNS}:${_}`; }
export {pubgMapsF,pubgMapsNS,TpubgMaps};
