import IconButton from '@material-ui/core/IconButton';
import BackArrowIcon from '@material-ui/icons/ArrowBackIos';
import React from 'react';

import { OnNavigate } from '../../../types/dispatch';

export interface IBackButtonOwnProps {
  route: string;
}

export interface IBackButtonDispatch {
  onNavigate: OnNavigate;
}

export type IBackButtonProps = IBackButtonOwnProps & IBackButtonDispatch;

export default class BackButton extends React.Component<IBackButtonProps> {
  public render() {
    return (
      <IconButton aria-label='go-back' onClick={this.handleOnClick}>
        <BackArrowIcon />
      </IconButton>
    );
  }

  private handleOnClick = () => {
    const { route, onNavigate } = this.props;
    onNavigate(route);
  }
}
