import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import LiveSessionButton from 'features/dashboard/dashboard-header/LiveSessionButton';
import { TeamHelper } from 'features/dashboard/team/team-helper';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootReducer } from 'rootReducer';
import { ID, TeamInterface } from 'types/pigeon';

import { GetUserProfileQuery_me } from '../../apollo/queries/types/GetUserProfileQuery';
import { EIntercomID } from '../../constants/strings';
import { IWithUserMenuDispatch, mapDispatchToUserMenuProps, WithUserMenu, withUserMenu } from '../../hoc/withUserMenu';
import { CommonF, CommonNS } from '../../locales/en/common';
import { DialogNS } from '../../locales/en/dialog';
import UserAvatar from '../../material/user-avatar/UserAvatar';
import IntercomButton from '../../subcomponents/intercom-button/IntercomButton';
import FeedbackButton from './FeedbackButton';
import NotificationsButton from './NotificationsButton';

export interface IStatusBarOwnProps {
  me: GetUserProfileQuery_me;
  menuItems?: React.ReactNode[];
  team?: TeamFragment | TeamInterface;
  directoryId?: ID;
}

export type StatusBarProps = IStatusBarOwnProps &
  WithStyles<typeof styles> &
  WithTranslation &
  WithUserMenu;

const styles = (theme: Theme) => createStyles({
  root: {
    gridColumnStart: 3,
    justifyContent: 'flex-end',
    display: 'flex',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
      alignItems: 'center',
    },
  },
  menuList: {
    padding: 0,
  },
  iconButton: {
    padding: theme.spacing(0.5, 1),
  },
});

class StatusBar extends React.Component<StatusBarProps> {

  public render() {
    const { classes, me, t, team, directoryId } = this.props;
    return (
      <Box className={classes.root}>
        <Box className={classes.sectionDesktop}>
          {team && directoryId && TeamHelper.isTeamFragment(team) && (
            <LiveSessionButton team={team} directoryId={directoryId}/>
          )}
          <NotificationsButton />
          <FeedbackButton />
          <Tooltip title={t('common:intercom') || ''}>
            <IntercomButton />
          </Tooltip>
          <IconButton id={EIntercomID.PROFILE} onClick={this.props.openMenu}>
            <UserAvatar user={me} size='xs' />
          </IconButton>
        </Box>

        <Box className={classes.sectionMobile}>
          <PopupState variant='popover'>
            {profilePopupState => (
              <React.Fragment>
                <IconButton {...bindTrigger(profilePopupState)} className={classes.iconButton}>
                  <AccountBoxIcon />
                </IconButton>
                <Menu
                {...bindMenu(profilePopupState)}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                getContentAnchorEl={null}
                >
                  <MenuItem button={true} onClick={this.props.openMenu}>
                    <ListItemIcon>
                      <UserAvatar user={me} size='xs' />
                    </ListItemIcon>
                    <ListItemText primary={t(CommonF('profile'))} />
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>

          <PopupState variant='popover'>
            {menuPopupState => (
              <React.Fragment>
                <IconButton {...bindTrigger(menuPopupState)} className={classes.iconButton}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                {...bindMenu(menuPopupState)}
                classes={{
                  list: classes.menuList,
                }}
                >
                  {this.props.menuItems && (
                    this.props.menuItems
                  )}
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Box>
      </Box>
    );
  }
}

export default
connect<void, IWithUserMenuDispatch, IStatusBarOwnProps, RootReducer>(null, mapDispatchToUserMenuProps)(
  withTranslation([CommonNS, DialogNS])(
    withUserMenu(
      withStyles(styles)(StatusBar),
    ),
  ),
);
