import { fabric } from 'fabric';

import { ID } from '../types/pigeon';
import {
  ICanvasActivatedAction,
  ICanvasClearedAction,
  ICanvasDeactivatedAction,
  IColorAction,
  IControlHostTerminateAction,
  IControlHostTransferAction,
  IControlUserKickAction,
  IControlVideoLoadAction,
  IGrantAction,
  IMessageEvent,
  IObjectAddedAction,
  IObjectModifiedAction,
  IObjectsRemovedAction,
  IPauseVideoAction,
  IPingVideoAction,
  IPlayVideoAction,
  IQueryAction,
  ISeekVideoAction,
  ISpeedVideoAction,
  KMSessionOutgoingAction,
} from '../types/pigeon/kmsession';

let counter: number = 0;
const eventHistory: Map<number, KMSessionOutgoingAction> = new Map();

export interface IHasCounter {id: number;}

export const fabricExtras = ['uuid', 'imagetype', 'imagekey'];

function injectCounter<T extends KMSessionOutgoingAction>(ev: T): IHasCounter & T {
  ++counter;
  eventHistory.set(counter, ev);
  return {...ev, id: counter};
}

export function getEventFromHistory(id: number): KMSessionOutgoingAction | undefined {
  return eventHistory.get(id);
}

export function clearEventHistory(): void {
  return eventHistory.clear();
}

export function createPlayVideoAction(position: number): IPlayVideoAction & IHasCounter {
  return injectCounter<IPlayVideoAction>({
    type: 'video',
    action: 'play',
    position,
  });
}

export function createPauseVideoAction(position: number): IPauseVideoAction & IHasCounter {
  return injectCounter<IPauseVideoAction>({
    type: 'video',
    action: 'pause',
    position,
  });
}

export function createSeekVideoAction(position: number): ISeekVideoAction & IHasCounter {
  return injectCounter<ISeekVideoAction>({
    type: 'video',
    action: 'seek',
    position,
  });
}

export function createSpeedVideoAction(speed: number): ISpeedVideoAction & IHasCounter {
  return injectCounter<ISpeedVideoAction>({
    type: 'video',
    action: 'speed',
    speed,
  });
}

export function createMessageAction(message: string): IMessageEvent {
  return injectCounter<IMessageEvent>({
    type: 'message',
    message,
  });
}

export function createObjectAddedAction(obj: fabric.Object | fabric.Object[]): IObjectAddedAction {
  const object = Array.isArray(obj) ? obj.map(o => o.toJSON(fabricExtras)) : obj.toJSON(fabricExtras);
  return injectCounter<IObjectAddedAction>({
    type: 'annotation',
    action: 'object:added',
    object,
  });
}

export function createObjectModifiedAction(obj: fabric.Object | fabric.Object[]): IObjectModifiedAction {
  const object = Array.isArray(obj) ? obj.map(o => o.toJSON(fabricExtras)) : obj.toJSON(fabricExtras);
  return injectCounter<IObjectModifiedAction>({
    type: 'annotation',
    action: 'object:modified',
    object,
  });
}

export function createObjectsRemovedAction(obj: fabric.Object | fabric.Object[]): IObjectsRemovedAction {
  const uuids = Array.isArray(obj) ? obj.map(o => o.uuid) : [obj.uuid];
  return injectCounter<IObjectsRemovedAction>({
    type: 'annotation',
    action: 'objects:removed',
    uuids,
  });
}

export function createCanvasClearedAction(): ICanvasClearedAction {
  return injectCounter<ICanvasClearedAction>({
    type: 'annotation',
    action: 'canvas:clear',
  });
}

export function createCanvasActivatedAction(): ICanvasActivatedAction {
  return injectCounter<ICanvasActivatedAction>({
    type: 'annotation',
    action: 'canvas:activate',
  });
}

export function createCanvasDeactivatedAction(): ICanvasDeactivatedAction {
  return injectCounter<ICanvasDeactivatedAction>({
    type: 'annotation',
    action: 'canvas:deactivate',
  });
}

export function createColorChangedAction(color: string): IColorAction {
  return injectCounter<IColorAction>({
    type: 'color',
    color,
  });
}

export function createControlVideoLoadAction(videoId: ID): IControlVideoLoadAction {
  return injectCounter<IControlVideoLoadAction>({
    type  : 'control',
    action: 'video:load',
    target: videoId as string,
  });
}

export function createControlHostTransferAction(userId: ID): IControlHostTransferAction & IHasCounter {
  return injectCounter<IControlHostTransferAction>({
    type  : 'control',
    action: 'host:transfer',
    target: userId as string,
  });
}

export function createControlUserKickAction(userId: ID): IControlUserKickAction {
  return injectCounter<IControlUserKickAction>({
    type  : 'control',
    action: 'user:kick',
    target: userId as string,
  });
}

export function createPingAction(x: number, y: number): IPingVideoAction & IHasCounter {
  return injectCounter<IPingVideoAction>({
    type: 'ping',
    x,
    y,
  });
}

export function createControlTerminateEvent(): IControlHostTerminateAction & IHasCounter {
  return injectCounter<IControlHostTerminateAction>({
    type  : 'control',
    action: 'terminate',
  });
}

export function createQueryStateAction(): IQueryAction & IHasCounter {
  return injectCounter<IQueryAction>({
    type : 'query',
    query: 'state',
  });
}

export function createQueryGrantsAction(): IQueryAction & IHasCounter {
  return injectCounter<IQueryAction>({
    type : 'query',
    query: 'grants',
  });
}

export function createGrantAction({
  action,
  targets,
  capabilities,
}: Pick<IGrantAction, 'action' | 'capabilities' | 'targets'>): IGrantAction & IHasCounter {
  return injectCounter<IGrantAction>({
    type: 'grant',
    action,
    targets,
    capabilities,
  });
}
