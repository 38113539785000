import { Theme } from '@insights-gaming/theme';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import { SavedCardFragment } from 'apollo/fragments/types/SavedCardFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { IBillingCycle } from 'types/pigeon/billing';

import CardForm from '../CardForm';
import CurrentCycle from '../CurrentCycle';
import NoCard from './NoCard';
import PaymentCardSelector from './PaymentCardSelector';
import { usePaymentCardSelectorHandlers } from './usePaymentCardSelectorHandlers';

interface PaymentMethodOwnProps {
  className?: string;
  team: TeamFragment;
  billingCycle: IBillingCycle | undefined;
  teamCards: SavedCardFragment[] | undefined;
  hasPlan: boolean;
  displaySkeleton: boolean;
}

type PaymentMethodProps = PaymentMethodOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  glue: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    margin: theme.spacing(1, 0),
  },
  headerText: {
    margin: theme.spacing(2, 0),
    fontWeight: 600,
    marginTop: theme.spacing(1),
  },
  greyButton: {
    minWidth: 105,
    height: 30,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}), {name: 'PaymentMethod'});

function PaymentMethod(props: PaymentMethodProps) {
  const classes = useStyles(props);
  const {
    className,
    team,
    billingCycle,
    teamCards,
    hasPlan, // hasPlan is true if the team has a stripe-managed plan
    displaySkeleton,
  } = props;

  const { t } = useStrictTranslation(['dashboard']);

  const isDesktop = useIsDesktop();

  const {
    selectedCard,
    handleAddCard,
    handleChangeCard,
    renderMobileCardDetails,
    renderMobileAddCard,
    renderTail,
    renderEnd,
    switchCardDialog,
    removeCardDialog,
    showInputs,
    handleEnableShowInputs,
    disableShowInputs,
  } = usePaymentCardSelectorHandlers(team.id, teamCards);

  return (
    <div className={classNames(classes.root, className)}>
      {displaySkeleton ? (
        <Skeleton variant='text' height={80} width={isDesktop ?  416 : '100%'}/>
      ) : (hasPlan || team.enterprise) ? (
        <CurrentCycle
        teamId={team.id}
        billingCycle={billingCycle}

        // NOTE: the "enterprise" flag here actually indicates whether the plan is
        // managed off of stripe or not (i.e. with directly assigned bundles)
        enterprise={team.enterprise && !hasPlan}
        />
      ) : !(teamCards && teamCards.length) && (
        <React.Fragment>
          <Typography variant='h4' className={classes.headerText}>
            {t('dashboard:billing.addpaymentcard')}
          </Typography>
          <CardForm onSubmit={handleAddCard} fullWidth={true} bordered={true}/>
        </React.Fragment>
      )}
      <Divider className={classes.divider}/>
      {(!team.enterprise || hasPlan) && (
        <React.Fragment>
          <Typography variant='h4' className={classes.headerText}>
            {t('dashboard:billing.paymentcards')}
          </Typography>
          {(teamCards?.length || hasPlan) ? (
            <PaymentCardSelector
            cards={teamCards || []}
            card={selectedCard}
            onChange={handleChangeCard}
            onAddCard={handleAddCard}
            renderMobileCardDetails={renderMobileCardDetails}
            renderMobileAddCard={renderMobileAddCard}
            renderTail={renderTail}
            renderEnd={renderEnd}
            showInputs={showInputs}
            handleEnableShowInputs={handleEnableShowInputs}
            disableShowInputs={disableShowInputs}
            />
          ) : <NoCard/>}
        </React.Fragment>
      )}
      {switchCardDialog()}
      {removeCardDialog()}
    </div>
  );
}

export default React.memo(PaymentMethod);
