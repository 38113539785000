import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { OverwatchPlayerMapStatsFragment } from 'apollo/fragments/overwatch-statistics/v2/types/OverwatchPlayerMapStatsFragment';
import FileSaver from 'file-saver';
import update from 'immutability-helper';
import json2csv, { parse } from 'json2csv';
import memoizeOne from 'memoize-one';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import sanitize from 'sanitize-filename';

import {
  GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics_OverwatchStatistics,
  GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics_OverwatchStatistics_primaryRosterV2,
  GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics_OverwatchStatistics_primaryRosterV2_maps,
  GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics_OverwatchStatistics_primaryRosterV2_maps_comps,
} from '../../../../../apollo/queries/types/GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query';
import { addIconsFromCompKey, CSV_LINE_BREAK, isTruthy } from '../../../../../helpers';
import { normalizer } from '../../../../../helpers/math';
import { OwmapsF, TOwmaps } from '../../../../../locales/en/owmaps';
import { OwstatsF, OwstatsNS } from '../../../../../locales/en/owstats';
import {
  ICData,
  IExpandedContentOptions,
  mapColumn,
  VirtualizedTable,
} from '../../../../../material/virtualized-table/VirtualizedTable';
import { INormalized } from '../../../../../types';
import { CsvOverallOption } from '../../../../../types/stat-csv-types';

type ProcessedData = INormalized<
  GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics_OverwatchStatistics_primaryRosterV2
>;

type CsvOnlyData = OverwatchPlayerMapStatsFragment;
type RowData =
  GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics_OverwatchStatistics_primaryRosterV2_maps;
type ChildRowData =
  GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics_OverwatchStatistics_primaryRosterV2_maps_comps &
  {heroIcons: string[]};
type CSVOnlyRowData = OverwatchPlayerMapStatsFragment;
type Column = ICData<RowData>;
type ChildColumn = ICData<ChildRowData>;
type CsvOnlyColumns = ICData<CSVOnlyRowData>;

const commonColumns: Array<ICData<RowData> | ICData<ChildRowData>> = [
  {
    tKey     : OwstatsF('teamfightstats.firsttfwinsabbr'),
    tDetailedKey : OwstatsF('teamfightstats.firsttfwins'),
    dataKey  : 'firstTfWins',
    numeric: true,
    displayAsPercent: true,
  },
  {
    tKey   : OwstatsF('teamfightstats.teamfightwinabbr'),
    tDetailedKey : OwstatsF('teamfightstats.teamfightwin'),
    dataKey: 'wins',
    numeric: true,
    displayAsPercent: true,
  },
  {
    tKey   : OwstatsF('teamfightsabbr'),
    tDetailedKey : OwstatsF('teamfights'),
    dataKey: 'teamfights',
    numeric: true,
  },
  {
    tKey     : OwstatsF('killsperfightabbr'),
    tDetailedKey : OwstatsF('killsperfight'),
    dataKey  : 'kills',
    numeric  : true,
    precision: 2,
  },
  {
    tKey     : OwstatsF('deathsperfightabbr'),
    tDetailedKey : OwstatsF('deathsperfight'),
    dataKey  : 'deaths',
    numeric  : true,
    precision: 2,
  },
  {
    tKey     : OwstatsF('ultsperfightabbr'),
    tDetailedKey : OwstatsF('ultsperfight'),
    dataKey  : 'ults',
    numeric  : true,
    precision: 2,
  },
  {
    tKey     : OwstatsF('firstkillsperfightabbr'),
    tDetailedKey : OwstatsF('firstkillsperfight'),
    dataKey  : 'firstKills',
    numeric  : true,
    precision: 2,
    displayAsPercent: true,
  },
  {
    tKey     : OwstatsF('firstdeathsperfightabbr'),
    tDetailedKey : OwstatsF('firstdeathsperfight'),
    dataKey  : 'firstDeaths',
    numeric  : true,
    precision: 2,
    displayAsPercent: true,
  },
  {
    tKey     : OwstatsF('firstultsperfightabbr'),
    tDetailedKey : OwstatsF('firstultsperfight'),
    dataKey  : 'firstUlts',
    numeric  : true,
    precision: 2,
    displayAsPercent: true,
  },
];

const columns: Column[] = [
  {
    tKey      : OwstatsF('map'),
    dataKey   : 'map',
    width     : 320,
    flexGrow  : 1,
    flexShrink: 0,
    includeT  : true,
  },
];

const childColumns: ChildColumn[] = [
  {
    tKey       : OwstatsF('teamcompositionstats.heroes'),
    dataKey    : 'heroIcons',
    width      : 320,
    flexGrow   : 1,
    flexShrink : 0,
    avatarArray: true,
  },
];

const csvOnlyColumns: CsvOnlyColumns[] = [
  {
    tKey       : OwstatsF('playerstats.damagedone'),
    dataKey    : 'damageDone',
  },
  {
    tKey       : OwstatsF('playerstats.damagetaken'),
    dataKey    : 'damageTaken',
  },
  {
    tKey       : OwstatsF('playerstats.healingdone'),
    dataKey    : 'healingDone',
  },
];

const normalizeTeamFightMapStats = (
  teams: GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics_OverwatchStatistics_primaryRosterV2[],
): ProcessedData[] => {
  return teams.map(team => ({
    normalized: update(team, {maps: maps => maps.map(normalizeMapStats)}),
  }));
};

const normalizeMapStats = (
  mapStats: RowData,
) => {
  const n = normalizer(1, mapStats.teamfights);
  const r = normalizer(1, mapStats.rounds);
  return update(mapStats, {
    map: (map: TOwmaps) => OwmapsF(map),
    kills : n,
    deaths: n,
    ults  : n,
    firstTfWins: r,
    wins  : n,
    firstKills  : n,
    firstDeaths : n,
    firstUlts   : n,
    comps : comps => comps.map(comp => {
      const nn = normalizer(1, comp.teamfights);
      return update(comp, {
        kills : nn,
        deaths: nn,
        ults  : nn,
        wins  : nn,
        firstKills  : nn,
        firstDeaths : nn,
        firstUlts   : nn,
      });
    }),
  });
};

// const addTKeysToMapStats = (
//   teams: GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics_OverwatchStatistics_primaryRosterV2[],
// ) => {
//   return teams.map(team => update(team, {
//     maps: mapStats => mapStats.map(addMapTKey),
//   }));
// };

export interface IOverwatchPrimaryRosterTeamFightMapStatisticsTableOwnProps {
  statistics: GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics_OverwatchStatistics;
  csvName   : string;
}

export type OverwatchPrimaryRosterTeamFightMapStatisticsTableProps =
  IOverwatchPrimaryRosterTeamFightMapStatisticsTableOwnProps &
  WithStyles<typeof styles> &
  WithTranslation;

const styles = createStyles({
  statsTables: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
});

interface IState {
  columns: Array<Column | ChildColumn>;
  childColumns: Array<Column | ChildColumn>;
}

class OverwatchPrimaryRosterTeamFightMapStatisticsTable extends React.Component<
  OverwatchPrimaryRosterTeamFightMapStatisticsTableProps,
  IState
> {
  // private memoizedTeamFightMapStatsWithKeys = memoizeOne(addTKeysToMapStats);
  private memoizedNormalizedTeamFightMapStats = memoizeOne(normalizeTeamFightMapStats);
  private memoizedColumnData = memoizeOne((cc: Array<Column | ChildColumn>, language: string) => {
    return cc.map(mapColumn(this.props.t, 200));
  });
  private memoizedChildColumnData = memoizeOne((cc: Array<Column | ChildColumn>, language: string) => {
    return cc.map(mapColumn(this.props.t, 200));
  });
  private memoizedCsvFields = memoizeOne(
    (language: string) => {
      const { t } = this.props;
      const columnMap: Map<string, ICData<RowData> | ICData<ChildRowData> | ICData<CSVOnlyRowData>> = new Map();
      let allColumns: Array<ICData<RowData> | ICData<ChildRowData> | ICData<CSVOnlyRowData>> = commonColumns;
      allColumns = allColumns.concat(columns);
      allColumns = allColumns.concat(childColumns);
      allColumns = allColumns.concat(csvOnlyColumns);
      allColumns.forEach(c => {
        columnMap.set(c.dataKey, c);
      });
      const dataKeys: Array<keyof RowData | keyof ChildRowData | keyof CSVOnlyRowData> = [
        'map',
        'heroIcons',
        'firstTfWins',
        'wins',
        'teamfights',
        'kills',
        'deaths',
        'ults',
        'firstKills',
        'firstDeaths',
        'firstUlts',
        'damageDone',
        'damageTaken',
        'healingDone',
      ];
      const fields: Array<json2csv.FieldInfo<RowData>> = dataKeys.map(k => {
        const v = columnMap.get(k);
        if (!v) {
          return undefined;
        }
        const { dataKey, tKey, tOptions } = v;
        return {
          label: t(tKey, tOptions),
          value: this.getFieldValue(dataKey),
        };
      }).filter(isTruthy);
      const compFields: Array<json2csv.FieldInfo<RowData>> = dataKeys.map(k => {
        const v = columnMap.get(k);
        if (!v) {
          return undefined;
        }
        const { dataKey, tKey, tOptions } = v;
        return {
          label: t(tKey, tOptions),
          value: this.getChildFieldValue(dataKey),
        };
      }).filter(isTruthy);
      const csvOnlyFields: Array<json2csv.FieldInfo<RowData>> = dataKeys.map(k => {
        const v = columnMap.get(k);
        if (!v) {
          return undefined;
        }
        const { dataKey, tKey, tOptions } = v;
        return {
          label: t(tKey, tOptions),
          value: this.getCSVOnlyFieldValue(dataKey),
        };
      }).filter(isTruthy);
      return {
        fields,
        compFields,
        csvOnlyFields,
      };
    },
  )

  constructor(props: OverwatchPrimaryRosterTeamFightMapStatisticsTableProps) {
    super(props);
    this.state = {
      columns     : [...columns, ...commonColumns],
      childColumns: [...childColumns, ...commonColumns],
    };
  }

  public render() {
    const { statistics, t, classes } = this.props;
    return (
      <React.Fragment>
        <Box mb={1}>
          <Button onClick={this.exportCsv}>
            {t(OwstatsF('exportcsv'))}
          </Button>
        </Box>
        <Box className={classes.statsTables}>
          {this.memoizedNormalizedTeamFightMapStats(statistics.primaryRosterV2).map(this.renderRosterTable)}
        </Box>
      </React.Fragment>
    );
  }

  private renderRosterTable = (roster: ProcessedData, i: number) => {
    return (
      <VirtualizedTable
      key={i}
      variant='outer'
      data={roster.normalized.maps}
      columns={this.memoizedColumnData(this.state.columns, this.props.i18n.language)}
      renderExpandedContent={this.renderExpandedContent}
      getExpandedRowHeight={this.getRowHeight}
      />
    );
  }

  private getRowHeight = (row: RowData) => {
    return (row.comps.length + 1) * 48;
  }

  private renderExpandedContent = (row: RowData, {sort}: IExpandedContentOptions<ChildRowData>) => {
    return (
      <VirtualizedTable<ChildRowData>
      data={row.comps.map(addIconsFromCompKey)}
      columns={this.memoizedChildColumnData(this.state.childColumns, this.props.i18n.language)}
      headerHeight={0}
      sortInfo={sort}
      />
    );
  }

  private buildRosterCsv = (roster: ProcessedData, options: {
    fields: Array<json2csv.FieldInfo<RowData>>,
    compFields: Array<json2csv.FieldInfo<RowData>>,
    csvOnlyFields: Array<json2csv.FieldInfo<RowData | CSVOnlyRowData>>,
    includeOverall?: CsvOverallOption,
  }): string => {
    const { fields, compFields, csvOnlyFields, includeOverall } = options;

    let csv = '';

    csv += roster.normalized.maps.map(map => {
      let playerCsv: string = '';
      let totalRow = '';
      if (includeOverall) {
        totalRow = parse(map, {
          fields,
          header: false,
        }) + CSV_LINE_BREAK;
      }
      playerCsv += parse(map, {
        fields: compFields,
        unwind: ['comps'].concat(compFields.map(f => f.value as string)),
        header: false,
      }) + CSV_LINE_BREAK;
      playerCsv += parse(map, {
        fields: csvOnlyFields,
        unwind: ['playerMapStats'].concat(csvOnlyFields.map(f => f.value as string)),
        header: false,
      }) + CSV_LINE_BREAK;
      switch (includeOverall) {
        case 'before':
          playerCsv = totalRow + playerCsv;
          break;
        case 'after':
          playerCsv = playerCsv + totalRow;
          break;
      }
      return playerCsv;
    }).join('');

    return csv;
  }

  private exportCsv = () => {
    const { statistics, csvName } = this.props;

    const header = true;
    const includeOverall: CsvOverallOption  = 'before';

    const rosters = this.memoizedNormalizedTeamFightMapStats(statistics.primaryRosterV2);
    const { fields, compFields, csvOnlyFields } = this.memoizedCsvFields(this.props.i18n.language);

    const csvs = [
      header && (parse([], {fields}) + CSV_LINE_BREAK),
      ...rosters.map(r => this.buildRosterCsv(r, {fields, compFields, csvOnlyFields, includeOverall})),
    ].filter(isTruthy);
    const blob = new Blob(csvs, {type: 'text/csv'});
    const fileName = csvName.slice(0, 48) + '_map-stats.csv';
    FileSaver.saveAs(blob, sanitize(fileName));
  }

  private getFieldValue = (dataKey: keyof RowData | keyof ChildRowData | keyof CSVOnlyRowData) => {
    switch (dataKey) {
      case 'map'  : return (row: RowData) => this.props.t(row[dataKey]);
           default: return dataKey;
    }
  }

  private getChildFieldValue = (dataKey: keyof RowData | keyof ChildRowData | keyof CSVOnlyRowData) => {
    if (dataKey === 'map') {
      return (row: RowData) => this.props.t(row[dataKey]);
    }
    return 'comps.' + (dataKey === 'heroIcons' ? 'compKey' : dataKey);
  }

  private getCSVOnlyFieldValue = (dataKey: keyof RowData | keyof ChildRowData | keyof CSVOnlyRowData) => {
    if (dataKey === 'map') {
      return (row: RowData) => this.props.t(row[dataKey]);
    }
    return 'playerMapStats.' + (dataKey === 'heroIcons' ? 'name' : dataKey);
  }
}

export default withTranslation(OwstatsNS)(
  withStyles(styles)(OverwatchPrimaryRosterTeamFightMapStatisticsTable),
);
