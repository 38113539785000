import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetSharedDivisionRecords } from './dashboard-directory-selector';
import { fetchSharedDivisionsAC } from './dashboard-directory-slice';

export const useFetchSharedDivisions = createBidirectionalAutoFetcher({
  actionCreators: fetchSharedDivisionsAC,
  selectorFactory: makeGetSharedDivisionRecords,
  createFetchId: () => '',
  pageSize: 10,
});

