import { AsyncButton, EnhancedDialogTitle } from '@insights-gaming/material-components';
import { createRemFromPx,Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { FEEDBACK_ENDPOINT } from 'constants/index';
import { SITE_KEY } from 'constants/strings';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

interface FeedbackDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
  openConfirmation: VoidFunction;
}

type FeedbackDialogProps = FeedbackDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  form: {
    width: createRemFromPx(600),
    height: createRemFromPx(180),
    display: 'flex',
    flexDirection: 'column',
  },
  textField1: {
    flex: 1,
    margin: theme.spacing(1),
  },
  textField2: {
    flex: 2,
    margin: theme.spacing(1),
  },
}), {name: 'FeedbackDialog'});

function FeedbackDialog(props: FeedbackDialogProps) {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <FeedbackDialogContent {...props} />
    </Dialog>
  );
}

declare const grecaptcha: any;

function FeedbackDialogContent(props: FeedbackDialogProps) {
  const classes = useStyles(props);
  const { className, onClose, openConfirmation } = props;
  const { t } = useStrictTranslation(['dialog']);

  const [contact, setContactValue] = useState<string>('');
  const [comment, setCommentValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [feedbackError, setFeedbackError] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleContactOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setContactValue(e.target.value);
  },[]);
  const handleCommentOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCommentValue(e.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    const recaptchaToken: string = await grecaptcha.execute(SITE_KEY, {action: 'feedback'});

    if (!recaptchaToken) {
      // TODO: explody
      // eslint-disable-next-line
      console.log('no recaptcha token');
    }

    try {
      const res = await fetch(FEEDBACK_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          'g-recaptcha-response': recaptchaToken,
          type: 'general',
          platform: 'web',
          contact,
          comment,
        }),
      });
      const { error } = await res.json();
      if (error) {
        throw error;
      }
      onClose();
      openConfirmation();
    } catch (error) {
      setFeedbackError(true);
      enqueueSnackbar(t('dialog:sendfeedback.error'), {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [comment, contact, enqueueSnackbar, loading, onClose, openConfirmation, t]);

  return (
    <div className={classNames(classes.root, className)}>
      <EnhancedDialogTitle onClose={onClose}>
        {t('dialog:sendfeedback.title')}
      </EnhancedDialogTitle>
      <DialogContent>
        <form className={classes.form}>
          <TextField
          className={classes.textField1}
          placeholder={t('dialog:sendfeedback.contactplaceholder')}
          onChange={handleContactOnChange}
          required={true}
          focused={true}
          label='Contact'
          />
          <TextField
          className={classes.textField2}
          placeholder={t('dialog:sendfeedback.commentplaceholder')}
          rows={4}
          rowsMax={4}
          multiline={true}
          onChange={handleCommentOnChange}
          variant='outlined'
          size='medium'
          />
        </form>
      </DialogContent>
      <DialogActions>
        <AsyncButton
        variant='contained'
        color='primary'
        onClick={handleSubmit}
        disabled={loading || !comment || !contact}
        >
          {t('dialog:sendfeedback.submit')}
        </AsyncButton>
      </DialogActions>
    </div>
  );
}

export default React.memo(FeedbackDialog);
