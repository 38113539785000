import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import classNames from 'classnames';
import { downloadVideoAC } from 'features/dashboard/video/dashboard-video-slice';
import { makeDownloadFromVideo } from 'helpers/makeDownload';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

interface DownloadVideoButtonOwnProps {
  className?: string;
  video?: VideoFragment;
}

type DownloadVideoButtonProps = DownloadVideoButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DownloadVideoButton'});

function DownloadVideoButton(props: DownloadVideoButtonProps) {
  const classes = useStyles(props);
  const { className, video } = props;

  const { t } = useStrictTranslation(['common']);
  const dispatch = useDispatch();

  const download = useMemo(() => makeDownloadFromVideo(video), [video]);

  const downloadOnClick = useCallback(() => {
    dispatch(downloadVideoAC(null, {label: 'download-button'}));
  }, [dispatch]);

  return download ? (
    <Button
    className={classNames(classes.root, className)}
    variant='outlined'
    startIcon={<GetAppIcon/>}
    onClick={downloadOnClick}
    href={download.url}
    download={download.name}
    >
      {t('common:download')}
    </Button>
  ) : null;
}

export default React.memo(DownloadVideoButton);
