import { enterTheaterModeAC, exitTheaterModeAC, toggleTheaterModeAC } from 'features/video-player/video-player-slice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function useEnterTheaterMode(): VoidFunction {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(enterTheaterModeAC()), [dispatch]);
}

export function useExitTheaterMode(): VoidFunction {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(exitTheaterModeAC()), [dispatch]);
}

export function useToggleTheaterMode(): VoidFunction {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleTheaterModeAC()), [dispatch]);
}
