import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button, { ButtonProps } from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExternalLink from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import classNames from 'classnames';
import { INSIGHTS_CAPTURE_ENDPOINT } from 'constants/index';
import { EIntercomID } from 'constants/strings';
import DashboardRouting from 'features/dashboard/dashboard.routing';
import { useIsDesktop } from 'features/media-queries/hooks';
import { createUTMParams } from 'helpers/createUTMParams';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { VIDEO_PATH } from 'routes';

interface InsightsCaptureButtonOwnProps {
  className?: string;
}

type InsightsCaptureButtonProps = InsightsCaptureButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  iconButton: {
    padding: theme.spacing(0.5, 1),
  },
}), {name: 'InsightsCaptureButton'});

const buttonProps: ButtonProps = {
  variant: 'outlined',
  color: 'secondary',
  startIcon: <VideoCallIcon />,
};

const replayUTM = '?' + createUTMParams('insightsgg', 'recordgame_btn', 'videoreplay_cta');
const dashboardUTM = '?' + createUTMParams('insightsgg', 'recordgame_btn', 'dashboard_cta');

function InsightsCaptureButton(props: InsightsCaptureButtonProps) {
  const classes = useStyles(props);
  const { className } = props;
  const { t } = useStrictTranslation(['common']);

  const isDesktop = useIsDesktop();
  const isDashboardhRoute = !!useRouteMatch(DashboardRouting.paths);
  const isVideoReplayRoute = !!useRouteMatch(VIDEO_PATH);

  const href = useMemo(() => {
    if (isDashboardhRoute) {
      return INSIGHTS_CAPTURE_ENDPOINT + dashboardUTM;
    }
    if (isVideoReplayRoute) {
      return INSIGHTS_CAPTURE_ENDPOINT + replayUTM;
    }
    return INSIGHTS_CAPTURE_ENDPOINT;
  }, [isDashboardhRoute, isVideoReplayRoute]);

  return (
    <div className={classNames(classes.root, className)}>
      <Tooltip title={t('common:launchcapture')}>
        <ExternalLink
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        >
          {isDesktop ? (
            <Button id={EIntercomID.INSIGHTS_CAPTURE} {...buttonProps}>
              {t('common:recordgame')}
            </Button>
          ) : (
            <IconButton className={classes.iconButton}>
              <FlexSpacer flexAlignItems='center' orientation='vertical' spacing={0}>
                <VideoCallIcon />
                <Typography style={{ fontSize: '0.7rem' }}>
                  {t('common:record')}
                </Typography>
              </FlexSpacer>
            </IconButton>
          )}
        </ExternalLink>
      </Tooltip>
    </div>
  );
}

export default React.memo(InsightsCaptureButton);
