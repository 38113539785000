import gql from 'graphql-tag';

import { TeamFragment } from '../fragments';

export const CreateTeam_Mutation = gql`
    mutation CreateTeamMutation(
        $input: CreateTeamInput!
    ) {
        createTeam(
            input: $input
        ) {
            team {
                ...TeamFragment
            }
        }
    }
    ${TeamFragment}
`;
