import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { Elite } from 'assets';
import classNames from 'classnames';
import useTeamResourceLimits from 'features/dashboard/billing/useTeamResourceLimits';
import DashboardTeamSettingsRouting from 'features/dashboard/team/dashboard-team-settings.routing';
import { isSeatedFeature } from 'helpers/hasSeats';
import { useNavigate } from 'hooks/useNavigate';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo } from 'react';
import { TeamFeature } from 'types/graphql';

interface SuccessDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
  update: boolean;
  team: TeamFragment;
}

type SuccessDialogProps = SuccessDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
  },
  icon: {
    fontSize: 96,
    color: theme.palette.success.main,
    margin: theme.spacing(1, 2),
  },
  headerText: {
    fontWeight: 600,
    margin: theme.spacing(1, 2),
  },
  message: {
    margin: theme.spacing(0, 2),
  },
  bold: {
    fontWeight: 700,
  },
  button: {
    marginTop: theme.spacing(3),
  },
  image: {
    width: 160,
    height: 200,
  },
}), {name: 'SuccessDialogContent'});

function SuccessDialog(props: SuccessDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <SuccessDialogContent {...props} />
    </Dialog>
  );
}

function SuccessDialogContent(props: SuccessDialogProps) {
  const classes = useStyles(props);
  const { className, onClose, update, team } = props;
  const { t } = useStrictTranslation(['dialog']);

  const onNavigate = useNavigate();

  const assignKeysOnClick = useCallback(() => {
    return team ? onNavigate(DashboardTeamSettingsRouting.createUrl(
      team.id,
      DashboardTeamSettingsRouting.Tab.MEMBERS,
    )) : undefined;
  }, [onNavigate, team]);

  const adFreeFeature = useMemo(
    () => team?.features?.filter(isSeatedFeature).find(({feature}) => feature === TeamFeature.ADFREE),
    [team?.features],
  );

  const keyCount = useMemo(() => adFreeFeature?.limit ?? 0, [adFreeFeature]);

  const resourceLimits = useTeamResourceLimits(team.id);

  return (
    <DialogContent className={classNames(classes.root, className)}>
      <Typography variant='h4' className={classes.headerText}>
        {t('dialog:paymentsuccess.title')}
      </Typography>
      <FlexSpacer>
        <Elite className={classes.image} />
        <FlexSpacer orientation='vertical' flexJustifyContent='center'>
          <Typography>
            {t('dialog:paymentsuccess.thankyou')}
          </Typography>
          {!update && (
            <Typography variant='body1'>
              {t('dialog:paymentsuccess.successfullyupgraded')}
            </Typography>
          )}
          {update ? (
            <Typography className={classes.message}>
              {t('dialog:paymentsuccess.cardupdatesuccess')}
            </Typography>
          ) : (
            <React.Fragment>
              <Typography>
                {t('dialog:paymentsuccess.yourteambundlesinclude')}
              </Typography>
              {keyCount > 1 && (
                <Typography className={classes.message}>
                  {t('dialog:paymentsuccess.keycountavailable', { count: keyCount })}
                </Typography>
              )}
              {resourceLimits.storage > 0 && (
                <Typography className={classes.message}>
                  {t(
                    'dialog:paymentsuccess.hourscountstorage',
                    { count: resourceLimits.storage },
                  )}
                </Typography>
              )}
              {resourceLimits.storageBytes > 0 && (
                <Typography className={classes.message}>
                  {t(
                  'dialog:paymentsuccess.gbscountstorage',
                  { count: Math.floor(resourceLimits.storageBytes / (2 ** 30)) },
                )}
                </Typography>
              )}
              <Typography className={classes.bold}>
                {t('dialog:paymentsuccess.adfreeapplied')}
              </Typography>
            </React.Fragment>
          )}
        </FlexSpacer>
      </FlexSpacer>
      {keyCount > 1 && (
        <FlexSpacer>
          <Button
          variant='outlined'
          onClick={onClose}
          className={classes.button}
          >
            {t('dialog:paymentsuccess.maybelater')}
          </Button>
          <Button
          variant='contained'
          color='primary'
          onClick={assignKeysOnClick}
          className={classes.button}
          >
            {t('dialog:paymentsuccess.assignkeys')}
          </Button>
        </FlexSpacer>
      )}
    </DialogContent>
  );
}

export default React.memo(SuccessDialog);
