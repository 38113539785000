import { Theme } from '@insights-gaming/theme';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import React from 'react';

import TeamMemberPicker from './TeamMemberPicker';

interface AddTeamMemberDialogContentOwnProps {
  className?: string;
  onClose: VoidFunction;
  directory: DirectoryFragment;
  team: TeamFragment;
}

type AddTeamMemberDialogContentProps = AddTeamMemberDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'AddTeamMemberDialogContent'});

function AddTeamMemberDialogContent(props: AddTeamMemberDialogContentProps) {
  const classes = useStyles(props);
  const { className, directory, onClose, team } = props;

  const { t } = useStrictTranslation(['dialog']);

  return (
    <div className={classNames(classes.root, className)}>
      <BetterDialogTitle>
        {t('dialog:invitechannel.userselect')}
      </BetterDialogTitle>
      <DialogContent>
        <Typography>
          {t('dialog:invitechannel.giveteammembers')}
        </Typography>
        <TeamMemberPicker directory={directory} onClose={onClose} team={team}/>
      </DialogContent>
    </div>
  );
}

export default React.memo(AddTeamMemberDialogContent);
