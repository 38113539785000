import { Theme, WithMakeStyles } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { getLiveSessionColor, getLiveSessionGuestName, getLiveSessionHostId, makeGetLiveSessionUser } from 'features/live-session/live-session-selector';
import { useCreateSelector } from 'hooks/useCreateSelector';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors/getMe';
import { KMMessageLogEvent } from 'types/pigeon/kmsession';

import ChatMessage from './ChatMessage';

interface ChatlogEventOwnProps {
  className?: string;
  event: KMMessageLogEvent;
}

type ChatlogEventProps = ChatlogEventOwnProps & WithMakeStyles<typeof useStyles>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  message: {
    wordBreak: 'break-word',
  },
  activity: {
    color: theme.palette.overwatch.activityLog,
    wordBreak: 'break-word',
    fontStyle: 'italic',
  },
}), {name: 'ChatlogEvent'});

function userIdFromLogEvent(event: KMMessageLogEvent): string | undefined {
  switch (event.type) {
    case 'message':
      return event.user;

    case 'connected':
      return event.user.id;

    case 'disconnected':
      return event.user;

    case 'video_load':
      return event.user;

    case 'host_change':
      return event.host;
  }
}

function ChatlogEvent(props: ChatlogEventProps) {
  const classes = useStyles(props);
  const { event } = props;

  const me = useSelector(getMe);
  const profile = useCreateSelector(makeGetLiveSessionUser, userIdFromLogEvent(event));
  const guestName = useSelector(getLiveSessionGuestName);
  const color = useSelector(getLiveSessionColor);
  const hostId = useSelector(getLiveSessionHostId);
  const alias = useMemo(() => profile?.alias || me?.alias || guestName, [guestName, me, profile]);

  switch (event.type) {
    case 'message':
      return (
        <ChatMessage
        alias={alias!}
        profile={profile}
        displayCrown={profile?.id === hostId || (!profile && hostId === me?.id)}
        message={event.message}
        time={event.time}
        className={classNames(classes.root, classes.message)}
        color={color}
        you={!profile}
        />
      );

    case 'connected':
      return (
        <div className={classNames(classes.root, classes.activity)}>
          <Trans ns='video' i18nKey='replay.userconnected' values={{ alias }}>
            <strong>{alias}</strong> has joined the session
          </Trans>
        </div>
      );

    case 'disconnected':
      return (
        <div className={classNames(classes.root, classes.activity)}>
          <Trans ns='video' i18nKey='replay.userdisconnected' values={{ alias }}>
            <strong>{alias}</strong> has left the session
          </Trans>
        </div>
      );

    case 'video_load':
      return (
        <div className={classNames(classes.root, classes.activity)}>
          <Trans ns='video' i18nKey='replay.videoloaded' values={{alias, videoName: event.video.name}}>
            <strong>{alias}</strong> has changed the video to <strong>{event.video.name}</strong>
          </Trans>
        </div>
      );

    case 'host_change':
      return (
        <div className={classNames(classes.root, classes.activity)}>
          <Trans ns='video' i18nKey='replay.hostchanged' values={{ alias }}>
            <strong>{alias}</strong> is now the host of the session
          </Trans>
        </div>
      );
  }
}

export default React.memo(ChatlogEvent);
