import { DrawingTool, SerializedCanvas, SerializedObject } from '@insights-gaming/drawing-canvas';
import { EventArgs, EventEmitter, EventMap, NormalizedEventArgs } from 'helpers/event-emitter';
import { useMemo } from 'react';

export enum EToolSize {
  SMALL = 1,
  MEDIUM = 3,
  LARGE = 5,
}

interface DrawingEventMap {
  setTool: DrawingTool;
  setColor: string;
  setSize: EToolSize;
  redo: void;
  undo: void;
  clear: void;
  reset: void;
  deselectAll: void;
  addSticker: EventArgs<[string, number, string]>;
  setImageUnderlay: EventArgs<[string, string]>;
  renderPing: EventArgs<[number, number] | [number, number, string]>;
  import: SerializedCanvas;
  export: (canvas: SerializedCanvas) => void;
  silentlyReplaceObjects: SerializedObject[];
  silentlyRemoveObjects: string[];
  silentlyClearCanvas: void;
}

type EventEmitHelpers<T extends EventMap> = { [K in keyof T]: (...args: NormalizedEventArgs<T[K]>) => void };

type EventEmitterAndHelpers<T extends EventMap> = EventEmitter<T> & EventEmitHelpers<T>;

export interface DrawingOverlayController extends EventEmitterAndHelpers<DrawingEventMap> {
  export(): Promise<SerializedCanvas>;
}

export default function useDrawingOverlayController(): DrawingOverlayController {
  const emitter = useMemo(() => new EventEmitter<DrawingEventMap>(), []);

  return useMemo(() => {
    const cache: Record<string, any> = {};
    return new Proxy(Object.assign(emitter, {
      export: () => new Promise<SerializedCanvas>((resolve, reject) => {
        emitter.emit('export', resolve);
        reject(new Error('export failed'));
      }),
    }), {
      get: (target: EventEmitter<DrawingEventMap>, p: PropertyKey, receiver: any): any => {
        const ret = Reflect.get(target, p, receiver);
        if (ret) {
          return ret;
        }

        if (typeof p === 'string') {
          return cache[p] || (cache[p] = (...args: any) => {
            emitter.emit(p, ...args);
          });
        }
      },
    }) as DrawingOverlayController;
  }, [emitter]);
}
