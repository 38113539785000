import { GameClassId, LeagueGameEvent } from '@insights-gaming/game-events';
import { GameMetadata } from '@insights-gaming/statistics';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { ID } from 'types/pigeon';

import LeagueOfLegendsStatisticsWrapper from './LeagueOfLegendsStatisticsWrapper';

interface StatisticsOwnProps {
  className?: string;
  videoId: ID;
  owInfo: GameMetadata;
}

type StatisticsProps = StatisticsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'Statistics'});

function typedRenderers<A extends GameClassId[]>(
  ...params: {[I in keyof A]: [A[I], React.ComponentType<{ gameMetadata: GameMetadata, videoId: ID }>]}
) {
  return params;
}

const statsRenderers = Object.fromEntries(typedRenderers(
  [LeagueGameEvent.CLASS_ID, LeagueOfLegendsStatisticsWrapper],
));

export function canRenderGameID(gameClassId: GameClassId): boolean {
  return !!statsRenderers[gameClassId];
}

function Statistics(props: StatisticsProps) {
  const classes = useStyles(props);
  const { className, videoId, owInfo } = props;

  const gameClassId = useMemo(() => owInfo.gameClassId || LeagueGameEvent.CLASS_ID, [owInfo.gameClassId]);

  const Component = statsRenderers[gameClassId];

  return Component ? (
    <Component gameMetadata={owInfo} videoId={videoId} />
  ) : null;
}

export default React.memo(Statistics);
