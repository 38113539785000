import { Loader } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { useSelector } from 'react-redux';

import { getUserNotificationSettingsState } from './notificaion-settings-selector';
import NotificationSettingsTable from './notification-settings-table/NotificationSettingsTable';
import UserNotificationSettingsFetcher from './UserNotificationSettingsFetcher';

interface NotificationSettingsOwnProps {
  className?: string;
}

type NotificationSettingsProps = NotificationSettingsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'NotificationSettings'});

function NotificationSettings(props: NotificationSettingsProps) {
  const classes = useStyles(props);
  const { className } = props;

  const { t } = useStrictTranslation(['common']);

  const { loading, settings } = useSelector(getUserNotificationSettingsState);

  return (
    <div className={classNames(classes.root, className)}>
      <UserNotificationSettingsFetcher />
      {loading ? (
        <Loader>{t('common:loading.generic')}</Loader>
      ) : settings && (
        <NotificationSettingsTable
        userNotificationSettings={settings}
        />
      )}
    </div>
  );
}

export default React.memo(NotificationSettings);
