import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { ID, ITag } from 'types/pigeon';

import TagListItemSkeleton from './TagListItemSkeleton';
import TagListItemWrapper from './TagListItemWrapper';

export interface TagListOwnProps {
  className?: string;
  displaySkeleton?: boolean;
  showCheck?  : boolean;

  tags: ITag[];
  selectedTagIds?: Set<ID>;
  checkedTagIds?: Set<ID>;
  indeterminateTagIds?: Set<ID>;
  loadingTagIds?: Set<ID>;

  onTagClick?: (e: React.SyntheticEvent, tag: ITag) => void;
  onTagCheckBoxChange?: (e: React.ChangeEvent<HTMLInputElement>, tag: ITag) => void;
}

type Props = TagListOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  noTags: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}), {name: 'TagList'});

function TagList(props: Props) {
  const classes = useStyles(props);
  const { t } = useStrictTranslation(['common']);
  const {
    checkedTagIds,
    className,
    displaySkeleton = false,
    indeterminateTagIds,
    loadingTagIds,
    onTagCheckBoxChange,
    onTagClick,
    selectedTagIds,
    showCheck,
    tags,
  } = props;

  return (
    <Box className={classNames(classes.root, className)}>
      <List disablePadding={true}>
        {displaySkeleton ? (
          Array.from({length: 2}).map((_, i) => (
            <TagListItemSkeleton key={i} />
          ))
        ) : !tags.length ? (
          <div className={classes.noTags}>
            <em><Typography variant='caption'>{t('common:notags')}</Typography></em>
          </div>
        ) : (
          tags.map(tag => (
            <TagListItemWrapper
            key={tag.id}
            tag={tag}
            showCheck={showCheck}
            checked={checkedTagIds?.has(tag.id)}
            indeterminate={indeterminateTagIds?.has(tag.id)}
            selected={selectedTagIds?.has(tag.id)}
            loading={loadingTagIds?.has(tag.id)}
            onTagClick={onTagClick}
            onTagCheckBoxChange={onTagCheckBoxChange}
            />
          ))
        )}
      </List>
    </Box>
  );
}

export default React.memo(TagList);
