import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

import { CDN_ENDPOINT } from '../../constants';

interface SearchNotFoundOwnProps {
  className?: string;
  query: string;
  error?: string;
}

type SearchNotFoundProps = SearchNotFoundOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: 'inherit',
  },
  notFound: {
    width: createRemFromPx(274),
    height: createRemFromPx(222),
    backgroundSize: 'cover',
    backgroundImage: `url(${CDN_ENDPOINT}/assets/empty-state/search-not-found.svg)`,
  },
}), {name: 'SearchNotFound'});

function SearchNotFound(props: SearchNotFoundProps) {
  const classes = useStyles(props);
  const { className, query, error } = props;
  const { t } = useStrictTranslation(['search']);

  return (
    <FlexSpacer orientation='vertical' flexAlignItems='center' spacing={4} className={classes.root}>
      <Typography variant='h2'>
        {t(error ? 'search:error' : 'search:noresultsfor', {query})}
      </Typography>
      <div className={classes.notFound}/>
    </FlexSpacer>
  );
}

export default React.memo(SearchNotFound);
