import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ID } from 'types/pigeon';

import { DirectoryHelper } from './dashboard-directory-helper';
import { getDirectoryDict, getSharedDirectoryDict, makeGetSharedDivisionByDirectoryId } from './dashboard-directory-selector';
import { useFetchDirectoryIfNecessary } from './useFetchDirectoryIfNecessary';

export function useFetchDirectoryTree(directoryId?: ID) {
  const dict = useSelector(getDirectoryDict);
  const sharedDict = useSelector(getSharedDirectoryDict);
  const sharedDivision = useCreateSelector(makeGetSharedDivisionByDirectoryId, directoryId);
  const directory = useMemo(() => {
    if (directoryId && directoryId in dict) {
      return dict[directoryId];
    }
    return sharedDivision;
  }, [dict, directoryId, sharedDivision]);
  const [dirs, ancestorId] = useMemo(() => {
    let current = directory;
    const traversedDirectories = new Set<ID>();
    const dirs: DirectoryFragment[] = [];
    while (true) {
      if (!current) {
        break;
      }
      if (traversedDirectories.has(current.id)) { // check for cyclic graph
        break;
      }
      traversedDirectories.add(current.id);

      dirs.unshift(current);

      const parentId = DirectoryHelper.tryGetParentId(current);
      if (!parentId) {
        break;
      }

      const parent = parentId in dict ? dict[parentId] : sharedDict[parentId];
      if (!parent) {
        break;
      }
      current = parent;
    }
    const ancestorId = current && DirectoryHelper.tryGetParentId(current);
    return [dirs, ancestorId];
  }, [dict, directory, sharedDict]);

  const [ancestor, ,status] = useFetchDirectoryIfNecessary(ancestorId);
  return [dirs, ancestor, status] as const;
}
