import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UnalignedContainer from 'components/unaligned-container/UnalignedContainer';
import VideoPickerDialog from 'features/dashboard/video-picker-dialog/VideoPickerDialog';
import { VideoHelper } from 'features/video-library/video-helpers';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import update, { Spec } from 'immutability-helper';
import Message from 'material/message/Message';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Action } from 'redux';
import { makeGetOverwatchAdvancedByTeamId } from 'selectors/overwatch-advanced-query';
import actionCreatorFactory, { isType } from 'typescript-fsa';

import {
  RosterPlayersFragment,
  RosterPlayersFragment_players,
} from '../../../../../apollo/fragments/types/RosterPlayersFragment';
import { isExistent } from '../../../../../helpers';
import { getUuid } from '../../../../../helpers/uuidgenerator';
import { useCreateOverwatchAdvancedQuery } from '../../../../../hooks/dispatch/overwatch-advanced-query';
import { useCreateSelector } from '../../../../../hooks/useCreateSelector';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { OwstatsNS } from '../../../../../locales/en/owstats';
import { makeGetTeamRosterCache } from '../../../../../selectors/statistic';
import { AdvancedQueryResultType, OverwatchAdvancedQueryFilter } from '../../../../../types/graphql';
import { ID } from '../../../../../types/pigeon';
import QueryResultNotFound from '../result-not-found/QueryResultNotFound';
import FilterCondition from './FilterCondition';
import { IFilterValue } from './QueryOptions';
import QueryResults from './QueryResults';

export interface ICreateQueryOwnProps {
  teamId: ID;
  rosterId: ID;
}

type Props = ICreateQueryOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  createQueryHeader: {
    marginBottom: theme.spacing(2),
  },
  queryNameField: {
    width: '50%',
  },
  query: {
    display: 'flex',
    alignItems: 'center',
  },
  queryConditions: {
    width: '100%',
  },
  queryActionButtonsGrid: {
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
  },
  queryActionButtonsContainer: {
    gridColumnStart: 2,
  },
  queryForm: {
    overflow: 'auto',
  },
}), {name: 'CreateQuery'});

function CreateQuery(props: Props) {
  const { teamId, rosterId } = props;
  const classes = useStyles(props);
  const { t } = useStrictTranslation([OwstatsNS]);

  const createOverwatchAdvancedQuery = useCreateOverwatchAdvancedQuery();
  const { isOpen, closeDialog, openDialog } = useDialogState();
  const [displayFilter, setDisplayFilter] = useState(true);

  const rosterCache = useCreateSelector(makeGetTeamRosterCache, teamId);

  const nonPrimaryRosters: RosterPlayersFragment_players[] = useMemo(() => {
    if (!rosterCache) { return []; }
    return Object.values(rosterCache).filter(r => r.id !== rosterId)
      .map((roster: RosterPlayersFragment) => roster.players).flat();
  }, [rosterId, rosterCache]);

  const { result, latestQuery, loading: pending, error } = useCreateSelector(makeGetOverwatchAdvancedByTeamId, teamId);

  useEffect(() => {
    setDisplayFilter(!result);
  }, [result]);

  const [videoIds, setVideoIds] = useState<ID[]>([]);

  const [ {queryName, conditionIds, conditionMap}, queryDispatch ] = useReducer(queryReducer, latestQuery);

  const conditions = useMemo(() => {
    return conditionIds.map((id: ID): [ID, OverwatchAdvancedQueryFilter] | null => {
      const value = conditionMap.get(id);
      if (!value) { return null; }
      return [id, value];
    }).filter(isExistent);
  }, [conditionIds, conditionMap]);

  const disableSaveAndButton = useMemo(() => {
    if (conditions.length === 0 || !queryName) { return true; }
    return conditions.find(([id, value]: [string, OverwatchAdvancedQueryFilter]) => (
      !value.variablePath || !value.value
    ));
  }, [conditions, queryName]);

  const handleCollapseFilterOnClick = useCallback(() => {
    setDisplayFilter(!displayFilter);
  }, [displayFilter]);

  const handleQueryNameOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    queryDispatch(queryNameChangedAC(e.target));
  }, []);

  const handleAddOnClick = useCallback(() => {
    queryDispatch(queryConditionAddedAC());
  }, []);

  const handleDeleteRowOnClick = useCallback((e: React.MouseEvent) => {
    queryDispatch(queryConditionRemovedAC(e.currentTarget.id));
  }, []);

  const handleConditionParameterChanged = useCallback((filterValue: IFilterValue) => {
    queryDispatch(queryConditionParameterChangedAC(filterValue));
  }, []);

  const handleConditionValueChanged = useCallback((filterValue: IFilterValue) => {
    queryDispatch(queryConditionValueChangedAC(filterValue));
  }, []);

  const handleVideoSelectedOnConfirm = useCallback((videoIds: ID[]) => {
    setVideoIds(videoIds || []);
    closeDialog();
  }, [closeDialog]);

  const handleSaveAndApplyOnClick = useCallback(() => {
    const filters = conditions.map(([id, value]: [ID, OverwatchAdvancedQueryFilter]) => value);
    createOverwatchAdvancedQuery({
      filters,
      queryName,
      resultType: AdvancedQueryResultType.TEAMFIGHT,
      rosterId,
      teamId,
      videoIds,
    });
  }, [createOverwatchAdvancedQuery, teamId, conditions, queryName, rosterId, videoIds]);

  const renderHeader = useCallback(() => {
    return (
      <div className={classes.createQueryHeader}>
        <Typography>{t('owstats:queries.createadvancedquery')}</Typography>
        <Typography variant='caption'>{t('owstats:queries.createquerycaption')}</Typography>
      </div>
    );
  }, [t, classes.createQueryHeader]);

  const renderCondition = useCallback(([id, value]: [ID, OverwatchAdvancedQueryFilter], i: number) => {
    return (
      <FilterCondition
      key={id}
      id={id}
      filter={value}
      i={i}
      handleConditionParameterChanged={handleConditionParameterChanged}
      handleConditionValueChanged={handleConditionValueChanged}
      handleDeleteRowOnClick={handleDeleteRowOnClick}
      />
    );
  }, [
      handleDeleteRowOnClick,
      handleConditionParameterChanged,
      handleConditionValueChanged,
    ],
  );

  const renderQueryForm = useCallback(() => {
    return (
      <FlexSpacer orientation='vertical' className={classes.queryForm}>
        <TextField
        value={queryName}
        required={true}
        label={t('owstats:queries.queryname')}
        onChange={handleQueryNameOnChange}
        className={classes.queryNameField}
        />
        <Collapse in={displayFilter}>
          <div className={classes.query}>
            <FlexSpacer orientation='vertical' spacing={1} className={classes.queryConditions}>
              {conditions.map(renderCondition)}
              <div className={classes.queryActionButtonsGrid}>
                <div className={classes.queryActionButtonsContainer}>
                  <Button onClick={handleAddOnClick} variant='outlined'>
                    {t('owstats:queries.addparameter')}
                  </Button>
                </div>
              </div>
            </FlexSpacer>
          </div>
        </Collapse>
        <div className={classes.queryActionButtonsGrid}>
          <FlexSpacer spacing={0} flexJustifyContent='space-between' className={classes.queryActionButtonsContainer}>
            <FlexSpacer>
              <Button onClick={handleCollapseFilterOnClick} size='small'>
                <FlexSpacer>
                  {displayFilter ? <ExpandLessIcon fontSize='small' /> : <ExpandMoreIcon fontSize='small' />}
                  {displayFilter ? t('owstats:queries.showless') : t('owstats:queries.showmore')}
                </FlexSpacer>
              </Button>
            </FlexSpacer>
            <FlexSpacer>
              <Button variant='outlined' onClick={openDialog}>
                {!videoIds.length
                  ? t('owstats:queries.selectvideos')
                  : t('owstats:queries.videoselected', {count: videoIds.length})
                }
              </Button>
              <AsyncButton
              variant='contained'
              color='primary'
              onClick={handleSaveAndApplyOnClick}
              loading={pending}
              disabled={!!disableSaveAndButton || pending}
              >
                {t('owstats:queries.saveandapply')}
              </AsyncButton>
            </FlexSpacer>
          </FlexSpacer>
        </div>
        <Typography variant='caption' align='right'>{t('owstats:queries.selectvideocaption')}</Typography>
      </FlexSpacer>
    );
  }, [
      classes,
      displayFilter,
      conditions,
      disableSaveAndButton,
      handleAddOnClick,
      handleCollapseFilterOnClick,
      handleSaveAndApplyOnClick,
      handleQueryNameOnChange,
      openDialog,
      pending,
      queryName,
      renderCondition,
      videoIds,
      t,
    ],
  );

  const renderVideoSelectorDialog = useCallback(() => {
    return (
      <VideoPickerDialog
      teamId={teamId}
      open={isOpen}
      onClose={closeDialog}
      multiple={true}
      filterFn={VideoHelper.hasCompleteAnalysis}
      onConfirm={handleVideoSelectedOnConfirm}
      />
    );
  }, [teamId, isOpen, closeDialog, handleVideoSelectedOnConfirm]);

  const renderQueryResults = useCallback(() => {
    if (error) { return <Message message={error.message} variant='error' />; }
    if (!result) { return null; }
    if (result.teamFights.length <= 0) { return <QueryResultNotFound />; }
    return <QueryResults result={result} rosterCache={rosterCache} primaryRosterId={rosterId} />;
  }, [error, result, rosterCache, rosterId]);

  return (
    <FlexSpacer orientation='vertical'>
      <UnalignedContainer>
        {renderHeader()}
        {renderQueryForm()}
        {renderVideoSelectorDialog()}
      </UnalignedContainer>
      {renderQueryResults()}
    </FlexSpacer>
  );
}

export default React.memo(CreateQuery);

const actionCreator = actionCreatorFactory();
const queryNameChangedAC      = actionCreator<HTMLInputElement>('QUERY_NAME_CHANGED');
const queryConditionParameterChangedAC = actionCreator<IFilterValue>('QUERY_CONDITION_PARAMETER_CHANGED');
const queryConditionValueChangedAC     = actionCreator<IFilterValue>('QUERY_CONDITION_VALUE_CHANGED');
const queryConditionAddedAC   = actionCreator('QUERY_CONDITION_ADDED');
const queryConditionRemovedAC = actionCreator<ID>('QUERY_CONDITION_REMOVED');

interface QueryState {
  queryName : string;
  conditionIds: ID[];
  conditionMap: Map<ID, OverwatchAdvancedQueryFilter>;
}

function queryReducer(state: QueryState, action: Action): QueryState {
  if (isType(action, queryNameChangedAC)) {
    const { value } = action.payload;
    return update(state, {queryName: {$set: value}});
  }
  if (isType(action, queryConditionAddedAC)) {
    const id = getUuid();
    return update(state, {
      conditionIds: {$push: [id]},
      conditionMap: {$add: [[id, {
        variablePath: '',
        value: '',
        operator: '=',
      }]]},
    });
  }
  if (isType(action, queryConditionParameterChangedAC)) {
    const { value, id } = action.payload;
    const entry = state.conditionMap.get(id);

    if (!entry) { return state; }
    const spec: Spec<OverwatchAdvancedQueryFilter> = {};
    spec.variablePath = {$set: value};
    spec.value = {$set: ''};
    const updatedEntry = update(entry, spec);
    return update(state, {
      conditionMap: {$add: [[id, updatedEntry]]},
    });
  }
  if (isType(action, queryConditionValueChangedAC)) {
    const { value, id } = action.payload;
    const entry = state.conditionMap.get(id);

    if (!entry) { return state; }
    const spec: Spec<OverwatchAdvancedQueryFilter> = {};
    spec.value = {$set: value};
    const updatedEntry = update(entry, spec);
    return update(state, {
      conditionMap: {$add: [[id, updatedEntry]]},
    });
  }
  if (isType(action, queryConditionRemovedAC)) {
    const id = action.payload;
    return update(state, {
      conditionIds: (ids: ID[]) => ids.filter(i => i !== id),
      conditionMap: {$remove: [id]},
    });
  }
  return state;
}
