import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexSpacer, SuspenseLoader, UndraggableAvatar,VerticalScroll, VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import classNames from 'classnames';
import Events from 'components/video/video-replay/events/Events';
import MatchTeams from 'components/video/video-replay/match-teams/MatchTeams';
import { defaultIconOptions,overwatchHeroIconPath } from 'helpers';
import { compareNumberAscending } from 'helpers/comparators';
import { formatDuration } from 'helpers/formatters';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import update from 'immutability-helper';
import camelCase from 'lodash/camelCase';
import compact from 'lodash/compact';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import React, { Suspense, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { GeneratedOverwatchMatch } from 'types/pigeon';
import { IEvent, IEventTime, IOverwatchMatchDataNonTeamfightKillEventDetail, IOverwatchMatchDataPlayerHero, IOverwatchMatchDataTeamfightKillEvent, IOverwatchMatchDataTeamfightResEvent, IOverwatchMatchDataTeamfightUltEvent,ITeamfight, OverwatchMatchDataTeamfightEvent, TimelineEvent } from 'types/pigeon/overwatch-match-data';

interface TimelinePanelOwnProps {
  className?: string;
  gameMatch: GeneratedOverwatchMatch;
  onExportKillfeed?: VoidFunction;
}

type TimelinePanelProps = TimelinePanelOwnProps;

interface IFilter {
  index: number;
  team: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  matchTools: {
    margin: theme.spacing(1),
  },
}), {name: 'TimelinePanel'});

function TimelinePanel(props: TimelinePanelProps) {
  const classes = useStyles(props);
  const { className, gameMatch, onExportKillfeed } = props;

  const { t } = useStrictTranslation(['video', 'common', 'heroes']);
  const wt = t as TFunction;
  const {
    state: {
      progress: {
        playedSeconds,
      },
    },
    seekTo,
  } = useContext(VideoReplayContext);

  const [filters, setFilters] = useState<IFilter[]>([]);

  const timelineRef = useRef<HTMLDivElement>(null);
  const timelineIgnoreNextScrollRef = useRef(false);

  const [autoScrollTimeline, setAutoScrollTimeline] = useState(false);

  const handleTimelineScroll = useCallback(() => {
    const { current: timelineIgnoreNextScroll } = timelineIgnoreNextScrollRef;
    if (!timelineIgnoreNextScroll) {
      setAutoScrollTimeline(false);
    } else {
      timelineIgnoreNextScrollRef.current = false;
    }
  }, []);

  const combinedEvents = useMemo(() => {
    const { data: { nonTeamfightEvents, teamfights = [] } } = gameMatch;
    const events: TimelineEvent[] = nonTeamfightEvents.map((event): IEvent => ({
      type: 'event',
      time: event.time,
      formattedTime: formatDuration(event.time),
      event,
    }));

    const teamfightEvents: TimelineEvent[] = teamfights.map((teamfight): ITeamfight => ({
      type: 'teamfight',
      time: teamfight.startTime,
      events: teamfight.events.map((event :OverwatchMatchDataTeamfightEvent) =>
        ({ event, type: event.type, time: formatDuration(event.time) })),
      endTime: teamfight.endTime,
    }));

    const combined: TimelineEvent[] = events
      .concat(teamfightEvents)
      .sort((a, b) => compareNumberAscending(a.time, b.time));

    return combined;
  }, [gameMatch]);

  const jumpToTime = useCallback((time: number) => {
    seekTo({type: 'seconds', amount: time, seekCommitted: true});
  }, [seekTo]);

  const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAutoScrollTimeline(checked);
  }, []);

  const handleScrollEventIntoView = useCallback((element: HTMLElement | null) => {
    if (element && timelineRef.current && autoScrollTimeline) {
      timelineIgnoreNextScrollRef.current = true;
      const windowY = window.scrollY;
      const bodyY = document.body.scrollTop;
      element.scrollIntoView({ block: 'center' });
      window.scrollTo(0, windowY);
      document.body.scrollTop = bodyY;
    }
  }, [autoScrollTimeline]);

  const filterEvents = useCallback((filter: IFilter) => {
    setFilters(filters => {
      const i = findIndex(filters, f => isEqual(f, filter));
      if (i < 0) {
        return update(filters, {$push: [filter]});
      } else {
        return update(filters, {$splice: [[i, 1]]});
      }
    });
  }, []);

  const handleFilterPlayer = useCallback((index: number, team: string) => {
    filterEvents({index, team});
  }, [filterEvents]);

  const heroReducer = useCallback((
    p: IOverwatchMatchDataPlayerHero | undefined,
    h: IOverwatchMatchDataPlayerHero,
  ): (IOverwatchMatchDataPlayerHero | undefined) => {
    // Player has switched to this hero
    if (h.startTime < playedSeconds) {
      // Switch time is the default value or is fresher than previous
      if (!p || p.startTime < h.startTime ) {
        return h;
      } else {
        return p;
      }
    } else {
      return p;
    }
  }, [playedSeconds]);

  const fHero = useCallback((f: IFilter): (string | undefined) => {
    if (!(gameMatch && gameMatch.isGeneratedOverwatchMatch())) {
      return undefined;
    }
    const { players } = gameMatch;
    const player = players[f.index];

    const reduced = player.heroes.reduce(heroReducer, undefined);
    return reduced ? reduced.name : reduced;
  }, [gameMatch, heroReducer]);

  const filterKills = useCallback((
    event: IOverwatchMatchDataTeamfightKillEvent | IOverwatchMatchDataNonTeamfightKillEventDetail,
  ): boolean => {
    return !!filters.find((f: IFilter) => {
      const hero = fHero(f);
      return (event.killer.hero === hero && event.killer.color === f.team) ||
        (event.killee.hero === hero && event.killer.color !== f.team);
    });
  }, [fHero, filters]);

  const filterResAndUlts = useCallback((
    event: IOverwatchMatchDataTeamfightResEvent | IOverwatchMatchDataTeamfightUltEvent,
  ): boolean => {
    return !!filters.find((f: IFilter) => event.hero === fHero(f) && event.team === f.team);
  }, [fHero, filters]);

  const filterMechKills = useCallback((
    event: IOverwatchMatchDataTeamfightKillEvent | IOverwatchMatchDataNonTeamfightKillEventDetail,
  ): boolean => {
    // Let it through if filtering for that team's dva or a filter killed the mech
    return !!filters.find((f: IFilter) => {
      const hero = fHero(f);
      return (event.killer.hero === hero && event.killer.color === f.team) ||
        (hero === 'dva' && event.killer.color !== f.team);
    });
  }, [fHero, filters]);

  const teamfightEventPassesFilters = useCallback((e: IEventTime): boolean => {
    switch (e.event.type) {
      case 'kill':
      case 'obj_kill':
        return filterKills(e.event);
      case 'res':
      case 'ult':
        return filterResAndUlts(e.event);
      case 'mech_kill':
        return filterMechKills(e.event);
      case 'status':
        return true;
      default:
        return false;
    }
  }, [filterKills, filterMechKills, filterResAndUlts]);

  const eventFilter = useCallback((events: IEventTime[]) => {
    return events.filter(teamfightEventPassesFilters);
  }, [teamfightEventPassesFilters]);

  const eventsToFilteredEvents = useCallback((event: TimelineEvent): TimelineEvent | null => {
    if (event.type !== 'teamfight') { return null; }

    const filteredEvents = update(event, {events: eventFilter});
    return filteredEvents;
  }, [eventFilter]);

  const filteredGameEvents = useMemo(() => {
    if (filters.length > 0) {
      return compact(combinedEvents.map(eventsToFilteredEvents));
    }
    return combinedEvents;
  }, [combinedEvents, eventsToFilteredEvents, filters.length]);

  const onDelete = useCallback((f: IFilter) => {
    filterEvents(f);
  }, [filterEvents]);

  const renderFilters = useCallback((f: IFilter) => {
    const hero = fHero(f);
    if (hero) {
      return (
        <Box m={0.5} key={f.index + f.team + hero}>
          <Chip
          size='small'
          avatar={
            <UndraggableAvatar
            size='xs'
            src={overwatchHeroIconPath(hero, defaultIconOptions)}
            />
          }
          label={wt(`heroes:${camelCase(hero)}`)}
          className={classes[f.team]}
          onDelete={onDelete}
          />
        </Box>
      );
    } else {
      // Hero not picked yet
      return (
        <Box m={0.5} key={f.index + f.team}>
          <Chip
          size='small'
          label='...'
          className={classes[f.team]}
          onDelete={onDelete}
          />
        </Box>
      );
    }
  }, [classes, fHero, onDelete, wt]);

  // Restores default of all events shown
  const clearEventsFilters = useCallback(() => {
    setFilters([]);
  }, []);

  return (
    <FlexSpacer orientation='vertical' className={classNames(classes.root, className)}>
      <div>
        <MatchTeams match={gameMatch} time={playedSeconds} onPlayerClick={handleFilterPlayer} />
        <FlexSpacer flexJustifyContent='space-between' flexAlignItems='center' className={classes.matchTools}>
          <FormControlLabel
          control={(
            <Checkbox checked={autoScrollTimeline} onChange={handleCheckboxChange} />
          )}
          label={t('video:timeline.autoscroll')}
          />
          <div>
            <Button
            variant='outlined'
            startIcon={<GetAppIcon />}
            size='small'
            onClick={onExportKillfeed}
            >
              {t('video:replay.exportkillfeed')}
            </Button>
          </div>
        </FlexSpacer>
      </div>
      <FlexSpacer flexAlignItems='center'>
        {filters.length > 0 && (
          <FontAwesomeIcon icon={faFilter} />
        )}
        <Box display='flex' flexWrap='wrap'>
          {filters.map(renderFilters)}
          {filters.length > 0 && (
            <Box m={0.5}>
              <Chip
              size='small'
              label={t('common:clear')}
              key='clear all filters'
              onDelete={clearEventsFilters}
              />
            </Box>
          )}
        </Box>
      </FlexSpacer>
      <Suspense fallback={<SuspenseLoader delay={200} />}>
        <VerticalScroll ref={timelineRef} onScroll={handleTimelineScroll}>
          <Events
          md={filteredGameEvents}
          time={playedSeconds}
          jumpToTime={jumpToTime}
          scrollIntoView={handleScrollEventIntoView}
          />
        </VerticalScroll>
      </Suspense>
    </FlexSpacer>
  );
}

export default React.memo(TimelinePanel);
