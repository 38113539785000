import gql from 'graphql-tag';

import { MemberFragment, TeamFragment } from '../fragments';

export const UpdateTeamFeatureAssignments_Mutation = gql`
    mutation UpdateTeamFeatureAssignmentsMutation(
        $input: UpdateTeamFeatureAssignmentsInput!
    ) {
        updateTeamFeatureAssignments(
            input: $input
        ) {
            members {
                ...MemberFragment
            }
            team {
                ...TeamFragment
            }
        }
    }
    ${MemberFragment}
    ${TeamFragment}
`;
