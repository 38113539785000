import { AvatarWithLetterFallback, FlexSpacer } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import classNames from 'classnames';
import LabelButton from 'components/video/video-replay/video-comment/comment-label/applied-tag-labels/LabelButton';
import TimestampButton from 'components/video/video-replay/video-comment/timestamp-button/TimestampButton';
import { makeGetCommentTagsByCommentId, makeGetVideoCommentById } from 'components/video/video-replay/video-comment/video-comment-selector';
import { fetchVideoCommentByIdWithVideoAC } from 'components/video/video-replay/video-comment/video-comment-slice';
import { convertFromRaw } from 'draft-js';
import { makeGetTeamCommentLabelsByTeamId } from 'features/dashboard/tag/dashboard-tag-selector';
import { makeGetVideoById } from 'features/dashboard/video/dashboard-video-selector';
import { mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { formatDuration } from 'helpers/formatters';
import { useNavigate } from 'hooks/useNavigate';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { videoRoute } from 'routes';
import { ID } from 'types/pigeon';

import { makeGetCommentHitById } from './search-selectors';
import { searchTrackingAC } from './search-slice';
import SearchCommentResultLayout from './SearchCommentResultLayout';
import SearchCommentResultSkeleton from './SearchCommentResultSkeleton';

interface SearchCommentResultOwnProps {
  className?: string;
  commentId: ID;
  teamId: ID;
}

type SearchCommentResultProps = SearchCommentResultOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  rangedTimestamp: {
    borderColor: theme.palette.tiers.gold,
  },
  mobileVideoName: {
    marginTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
  userName: {
    whiteSpace: 'nowrap',
    flex: 1,
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    fontWeight : 'bold',
    textOverflow: 'ellipsis',
    width: createRemFromPx(100),
  },
  tags: {
    display: 'flex',
    alignItems: 'center',
  },
  labelButton: {
    [mobilePortrait(theme)]: {
      '& > div > button': {
        paddingRight: theme.spacing(0.5),
      },
    },
  },
  date: {
    marginRight: createRemFromPx(32),
  },
  message: {
    marginTop: theme.spacing(1.5),
    maxHeight: createRemFromPx(64),
    overflow: 'hidden',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  expanded: {
    maxHeight: 'fit-content',
  },
  moreLabelGrid: {
    padding: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: theme.spacing(0.5),
  },
  full: {
    width: '100%',
  },
  half: {
    width: '50%',
  },
}), {name: 'SearchCommentResult'});

function SearchCommentResult(props: SearchCommentResultProps) {
  const classes = useStyles(props);
  const { className, commentId, teamId } = props;
  const navigate = useNavigate<{ comment?: CommentFragment_VideoComment }>();
  const { t } = useStrictTranslation(['video', 'common', 'search']);

  const [ readMore, setReadMore ] = useState(false);
  const [ displayReadMore, setDisplayReadMore ] = useState(false);

  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();

  const hit = useCreateSelector(makeGetCommentHitById, teamId, commentId);
  const [comment] = useCreateSelector(makeGetVideoCommentById, commentId);
  const video = useCreateSelector(makeGetVideoById, hit?.video?.id);
  const commentLabelTags = useCreateSelector(makeGetTeamCommentLabelsByTeamId, teamId);
  const commentTags = useCreateSelector(makeGetCommentTagsByCommentId, { commentId: comment?.id });
  const commentTagIds = useMemo(() => commentTags.map(c => c.id), [commentTags]);

  const filteredCommentLabelTags = useMemo(
    () => commentLabelTags.filter((t) => commentTagIds.includes(t.id)),
    [commentLabelTags, commentTagIds],
  );

  useEffect(() => {
    if (!comment && hit?.video) {
      dispatch(fetchVideoCommentByIdWithVideoAC.started({ commentId: hit.id, videoId: hit.video.id }));
    }
  }, [comment, dispatch, hit]);

  const time = useMemo(() => {
    if (comment) {
      return comment.time;
    }
    return;
  }, [comment]);

  const timeEnd = useMemo(() => {
    if (comment) {
      return comment.timeEnd;
    }
    return;
  }, [comment]);

  const message = useMemo(() => {
    if (comment?.message) {
      try {
        const contentState = convertFromRaw(JSON.parse(comment.message));
        return contentState.getPlainText();
      } catch (err) {
        return comment.message;
      }
    }
    return;
  }, [comment]);

  const redirect = useCallback(() => {
    if (comment && hit) {
      dispatch(searchTrackingAC(null, {label: 'search-comment-result'}));
      navigate(videoRoute(hit.video.id), { state: { comment } });
    }
    return;
  }, [comment, dispatch, hit, navigate]);

  const ref = useRef<HTMLParagraphElement>(null);

  const readMoreOnClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setReadMore(true);
    setDisplayReadMore(false);
  }, []);

  useEffect(() => {
    if (ref && ref.current) {
      if (ref.current.offsetHeight < ref.current.scrollHeight) {
        setDisplayReadMore(true);
      };
    }
  }, []);

  if (!comment) {
    return (
      <SearchCommentResultSkeleton/>
    );
  }

  return (
    <SearchCommentResultLayout
    text={(
      <React.Fragment>
        {!isDesktop && <Typography className={classes.mobileVideoName}>{video?.name}</Typography>}
        <Typography ref={ref} className={classNames(classes.message, {[classes.expanded]: readMore})}>
          {message}
        </Typography>
        {displayReadMore && (
          <Button
          variant='text'
          size='small'
          startIcon={<ArrowDropDownIcon/>}
          onClick={readMoreOnClick}
          >
            {t('search:readmore')}
          </Button>
        )}
      </React.Fragment>
    )}
    filteredCommentLabelTags={(
      <FlexSpacer className={classes.tags}>
        {filteredCommentLabelTags.map((t, i) => {
            return i < (isDesktop ? 3 : 1) && (
              <LabelButton key={t.id} appliedCommentLabel={t} className={classes.labelButton} />
            );
          },
        )}
        {(filteredCommentLabelTags.length ?? 0) > (isDesktop ? 3 : 1) && (
          <Typography variant='caption'>
            {t('common:extranumbernotext', {
              number: filteredCommentLabelTags.length ?? (isDesktop ? 3 : 1) - (isDesktop ? 3 : 1),
            })}
          </Typography>
        )}
      </FlexSpacer>
    )}
    onClick={redirect}
    >
      {time !== undefined ? (
        <TimestampButton
        time={time}
        className={timeEnd ? classes.rangedTimestamp : undefined}
        disabled={true}
        >
          <FlexSpacer spacing={0.5}>
            <span>
              {formatDuration(time)}
            </span>
            {isDesktop && timeEnd && (
              <React.Fragment>
                <span>-</span><span>{formatDuration(timeEnd)}</span>
              </React.Fragment>
            )}
          </FlexSpacer>
        </TimestampButton>
      ) : null}
      <FlexSpacer flexAlignItems='center'>
        <AvatarWithLetterFallback name={comment.user.alias} size='xs' src={comment.user.picture}/>
        <Typography className={classes.userName}>
          {comment.user.alias}
        </Typography>
      </FlexSpacer>
      {isDesktop && (
        <React.Fragment>
          <Typography variant='caption' className={classes.date}>
            {t('video:replay.messagereltime', {date: comment.modified || comment.created})}
          </Typography>
          {video ? (
            <Typography>
              {video.name}
            </Typography>
          ) : null}
        </React.Fragment>
      )}
    </SearchCommentResultLayout>
  );
}

export default React.memo(SearchCommentResult);
