import { FlexSpacer } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import InvitationTable from 'components/invitation-list/InvitationTable';
import { EIntercomID } from 'constants/strings';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { makeGetUnexpiredTeamInvitationsByTeamId } from 'features/dashboard/invitation/dashboard-invitation.selector';
import { useFetchTeamInvitations } from 'features/dashboard/invitation/useFetchInvitations';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

interface TeamInvitationsOwnProps {
  className?: string;
  team: TeamFragment;
  openInviteTeamMembersDialog?: VoidFunction;
}

type TeamInvitationProps = TeamInvitationsOwnProps;

function TeamInvitations(props: TeamInvitationProps) {
  const { className, team, openInviteTeamMembersDialog } = props;

  const { t } = useStrictTranslation(['dashboard-settings']);

  const [invitationsForwardFetchStatus] = useFetchTeamInvitations(team.id);
  const { canCreateTeamInvitationLink } = useAccessControl();

  const unexpiredInvitations = useCreateSelector(makeGetUnexpiredTeamInvitationsByTeamId, team.id);

  const displaySkeleton = invitationsForwardFetchStatus.fetching && !invitationsForwardFetchStatus.cursor;

  return (
    <FlexSpacer className={className} orientation='vertical' flexAlignItems='start'>
      {canCreateTeamInvitationLink && (
        <Button
        id={EIntercomID.ADD_MEMBERS}
        variant='outlined'
        startIcon={<AddIcon />}
        onClick={openInviteTeamMembersDialog}
        size='small'
        >
          {t('dashboard-settings:invites.createinvite')}
        </Button>
      )}
      <InvitationTable
      teamId={team.id}
      unexpiredInvitations={unexpiredInvitations}
      displaySkeleton={displaySkeleton}
      />
      {!unexpiredInvitations.length && !displaySkeleton && (
        <FlexSpacer
        style={{marginTop: 0}}
        fullWidth={true}
        orientation='vertical'
        flexAlignItems='center'
        >
          <Typography>
            {t('dashboard-settings:invites.noactiveinvitelinks')}
          </Typography>
        </FlexSpacer>
      )}
    </FlexSpacer>
  );
}

export default TeamInvitations;
