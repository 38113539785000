import { Theme } from '@insights-gaming/theme';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface NotificationListItemOwnProps {
  className?: string;
  avatar: React.ReactNode;
  message: React.ReactNode
  contextMenu?: React.ReactNode;
  detail?: React.ReactNode;
  onClick?: VoidFunction;
}

type NotificationListItemProps = NotificationListItemOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },
}), {name: 'NotificationListItem'});

function NotificationListItem(props: NotificationListItemProps) {
  const classes = useStyles(props);
  const { className, avatar, message, contextMenu, detail, onClick } = props;
  return (
    <div className={classes.root}>
      <ListItem
      dense={true}
      className={className}
      button={true}
      onClick={onClick}
      >
        {avatar}
        {message}
        {detail}
      </ListItem>
      {contextMenu}
    </div>
  );
}

export default React.memo(NotificationListItem);
