import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { ISessionProfile } from 'types/pigeon/kmsession';

interface ChatMessageOwnProps {
  className?: string;
  displayCrown?: boolean;
  alias: string;
  message: string;
  profile?: ISessionProfile;
  time?: string;
  color?: string;
  you: boolean;
}

type ChatMessageProps = ChatMessageOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  crown: {
    color: '#F2C94C',
  },
}), {name: 'ChatMessage'});

function ChatMessage(props: ChatMessageProps) {
  const classes = useStyles(props);
  const { t } = useStrictTranslation(['video']);
  const { className, alias, displayCrown, message, profile, color, you } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <span style={{color: profile?.color || color}}>
        <strong>
          {displayCrown && (
            <span><FontAwesomeIcon icon={faCrown} size='xs' className={classes.crown} /></span>
          )}
          {alias}
          {you && ` (${t('video:comment.you')})`}
        </strong>
      </span>
      <span>
        : {message}
      </span>
    </div>
  );
}

export default React.memo(ChatMessage);
