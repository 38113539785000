import { FlexSpacer } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsDesktop } from '../../../../../features/media-queries/hooks';
import { HeroesNS } from '../../../../../locales/en/heroes';
import { OverwatchNS } from '../../../../../locales/en/overwatch';
import { OwmapsNS } from '../../../../../locales/en/owmaps';
import { OwstatsF, OwstatsNS } from '../../../../../locales/en/owstats';
import { OverwatchAdvancedQueryFilter } from '../../../../../types/graphql';
import { ID } from '../../../../../types/pigeon';
import {
  gameModes,
  heroesOptions,
  IFilterOption,
  IFilterValue,
  mapsOptions,
  parameterOptions,
  teamOptions,
} from './QueryOptions';

export interface IFilterConditionOwnProps {
  id: ID;
  filter: OverwatchAdvancedQueryFilter;
  i: number;
  handleConditionParameterChanged: (value: IFilterValue) => void;
  handleConditionValueChanged: (value: IFilterValue) => void;
  handleDeleteRowOnClick: (e: React.MouseEvent) => void;
}

type Props = IFilterConditionOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  queryConditionContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
  },
  addText: {
    width: '50px',
    alignSelf: 'center',
    textAlign: 'center',
  },
  queryCondition: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    borderBottom: `1px solid ${theme.palette.background.paper}`,
    padding: theme.spacing(1),
  },
  formControl: {
    minWidth: '200px',
  },
}), {name: 'FilterCondition'});

function FilterCondition(props: Props) {
  const {
    id,
    filter,
    i,
    handleConditionParameterChanged,
    handleConditionValueChanged,
    handleDeleteRowOnClick,
  } = props;

  const classes = useStyles(props);
  const isDesktop = useIsDesktop();
  const { t } = useTranslation([OwstatsNS, OwmapsNS, OverwatchNS, HeroesNS]);

  const renderDeleteRowButton = useCallback(() => {
    if (i === 0 && !filter.value) { return null; }
    return (
      <Button id={id} onClick={handleDeleteRowOnClick}>
        <DeleteIcon />
        {isDesktop && t(OwstatsF('queries.deleterow'))}
      </Button>
    );
  }, [id, isDesktop, handleDeleteRowOnClick, t, filter, i]);

  const renderOptionLabel = useCallback((option: IFilterOption) => {
    return t(option.label);
  }, [t]);

  const renderParameterInput = useCallback((params: AutocompleteRenderInputParams) => {
    return <TextField {...params} label={t(OwstatsF('queries.parameter'))} />;
  }, [t]);

  const renderValueInput = useCallback((params: AutocompleteRenderInputParams) => {
    return <TextField {...params} label={t(OwstatsF('queries.value'))} />;
  }, [t]);

  const handleValueOnChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>, value: IFilterOption) => {
    const filterValue: IFilterValue = {
      value: value ? value.value : '',
      id,
    };
    handleConditionValueChanged(filterValue);
  }, [handleConditionValueChanged, id]);

  const handleParameterOnChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>, parameter: IFilterOption) => {
    const filterValue: IFilterValue = {
      value: parameter ? parameter.value : '',
      id,
    };
    handleConditionParameterChanged(filterValue);
  }, [handleConditionParameterChanged, id]);

  const valueOptions = useMemo(() => {
    switch (filter.variablePath) {
      case 'Gamemode':
        return gameModes;
      case 'Map':
        return mapsOptions;
      case 'Attack':
      case 'Defend':
      case 'Teamfight.FirstUlt.Color':
      case 'Teamfight.FirstKill.Color':
      case 'Teamfight.FirstDeath.Color':
        return teamOptions;
      case 'Teamfight.FirstUlt.Hero':
      case 'Teamfight.FirstKill.Hero':
      case 'Teamfight.FirstDeath.Hero':
      case 'Teamfight.ColorHeroes':
      case 'Teamfight.ColorUlts.Hero':
        return heroesOptions;
      default:
        return [];
    }
  }, [filter.variablePath]);

  const value = useMemo(() => valueOptions.find(v => v.value === filter.value), [filter, valueOptions]);
  const param = useMemo(() => parameterOptions.find(v => v.value === filter.variablePath), [filter]);

  return (
    <div className={classes.queryConditionContainer}>
      <div className={classes.addText}>{i > 0 && t(OwstatsF('queries.and'))}</div>
      <div className={classes.queryCondition}>
        <FlexSpacer spacing={2}>
          <FormControl className={classes.formControl}>
            <Autocomplete
            id={id + 'parameter'}
            options={parameterOptions}
            getOptionLabel={renderOptionLabel}
            renderInput={renderParameterInput}
            onChange={handleParameterOnChange}
            value={param}
            />
          </FormControl>
          {filter.variablePath && (
            <FormControl className={classes.formControl}>
              <Autocomplete
              id={id + 'value'}
              options={valueOptions}
              getOptionLabel={renderOptionLabel}
              renderInput={renderValueInput}
              onChange={handleValueOnChange}
              value={value}
              />
            </FormControl>
          )}
        </FlexSpacer>
        {renderDeleteRowButton()}
      </div>
    </div>
  );
}

export default React.memo(FilterCondition);
