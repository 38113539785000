import { checkAuthAsyncAC, fetchUserProfileAsyncAC } from 'features/auth/auth-slice';
import addAsyncCases from 'helpers/addAsyncCases';
import { createSlice } from 'helpers/createSlice';
import { IErrorStoreState } from 'types';


const name = 'error';

const errorSlice = createSlice({
  name,
  initialState: {} as IErrorStoreState,
  reducers: {},
  extraReducers: builder => {
    addAsyncCases(builder, checkAuthAsyncAC, {
      failed: (state, { payload }) => {
        state.authCheckFailed = true;
      },
    });

    addAsyncCases(builder, fetchUserProfileAsyncAC, {
      failed: (state, { payload }) => {
        state.fetchUserProfileError = payload.error;
      },
    });
  },
});

export const errorReducer = errorSlice.reducer;

