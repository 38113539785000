import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useNavigate } from 'hooks/useNavigate';
import { useReferPath } from 'hooks/useReferPath';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import ButtonLink from 'material/button-link/ButtonLink';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { dashboardRoute, JOIN_SESSION_PATH, registerRoute, signinRoute } from 'routes';
import { getMe } from 'selectors/getMe';

import { clearLiveSessionAC, ErrorLiveSessionState } from './live-session-slice';

interface LiveSessionErrorDialogOwnProps {
  state?: ErrorLiveSessionState;
}

export type LiveSessionErrorDialogProps = LiveSessionErrorDialogOwnProps;

function LiveSessionErrorDialog({ state }: LiveSessionErrorDialogProps) {
  const { t } = useStrictTranslation(['common', 'dialog', 'login']);
  const onNavigate = useNavigate();
  const dispatch = useDispatch();

  const referPath = useReferPath();
  const isSessionRoute = !!useRouteMatch(JOIN_SESSION_PATH);
  const isLoggedIn = !!useSelector(getMe);

  const clearError = useCallback(() => dispatch(clearLiveSessionAC()), [dispatch]);

  const goToDashboard = useCallback(() => {
    clearError();
    if (isSessionRoute) {
      onNavigate(referPath || dashboardRoute());
    }
  }, [clearError, isSessionRoute, onNavigate, referPath]);

  // TODO: handle errors messages from state
  return (
    <Dialog open={true} onClose={!isSessionRoute ? goToDashboard : undefined}>
      <BetterDialogTitle>{t('dialog:sessionconnecterror.title')}</BetterDialogTitle>
      <DialogContent>
        {t('dialog:sessionconnecterror.reasonlabel')}
        <ul>
          <li>
            {t('dialog:sessionconnecterror.reason1')}
          </li>
          <li>
            {t('dialog:sessionconnecterror.reason2')}
          </li>
          <li>
            {t('dialog:sessionconnecterror.reason3')}
          </li>
          <li>
            {t('dialog:sessionconnecterror.reason4')}
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        {isLoggedIn ? (
          <Button
          color='primary'
          onClick={goToDashboard}
          variant='contained'
          >
            {t('common:404_back')}
          </Button>
        ) : (
          <React.Fragment>
            <ButtonLink
            color='default'
            variant='contained'
            onClick={clearError}
            to={{
              pathname: signinRoute(),
              state: {referPath: window.location.pathname + window.location.search},
            }}
            >
              {t('login:login')}
            </ButtonLink>
            <ButtonLink
            color='primary'
            variant='contained'
            onClick={clearError}
            to={{
              pathname: registerRoute(),
              state: {referPath: window.location.pathname + window.location.search},
            }}
            >
              {t('login:signup')}
            </ButtonLink>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(LiveSessionErrorDialog);
