import { AsyncButton, EnhancedDialogTitle } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { updateVideoTagsAsyncAC } from 'actions/video-actions';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { VideoHelper } from 'features/video-library/video-helpers';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import MultiSelect, { OptionType } from 'material/multi-select/MultiSelect';
import MultiValueTagChip from 'material/multi-value-tag-chip/MultiValueTagChip';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { ID, ITag, mapTagToOption } from 'types/pigeon';

interface VideoTaggerDialogOwnProps {
  onClose?: VoidFunction;
  video: VideoFragment;
  tags: ITag[];
}

type VideoTaggerDialogProps = VideoTaggerDialogOwnProps & Pick<DialogProps, 'open'>;

function VideoTaggerDialog(props: VideoTaggerDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth='xs'>
      <VideoTaggerDialogContent {...props} />
    </Dialog>
  );
}

function VideoTaggerDialogContent(props: VideoTaggerDialogProps) {
  const { tags, video, onClose } = props;

  const promiseSagaDispatch = usePromiseSagaDispatch();

  const { t } = useStrictTranslation(['common', 'dialog']);
  const { enqueueSnackbar } = useSnackbar();

  const tagOptions = useMemo(() => tags.map(mapTagToOption), [tags]);

  const [selected, setSelected] = useState<OptionType[]>(
    tags.filter(tag => video.tags.includes(tag.id)).map(mapTagToOption),
  );

  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback((value?: OptionType[]) => {
    setSelected(value || []);
    setDirty(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    const selectedTags = new Set<ID>(selected.map(option => option.value));
    const tagsToBeAdded = new Set<ID>(selectedTags);
    const tagsToBeRemoved = new Set<ID>();

    for (const tag of video.tags) {
      tagsToBeAdded.delete(tag); // already there; doesn't need to be added
      if (!selectedTags.has(tag)) { // should be removed
        tagsToBeRemoved.add(tag);
      }
    }

    const teamId = VideoHelper.getTeamId(video);
    try {
      if (!teamId) {
        throw new Error('No team id');
      }
      await promiseSagaDispatch(updateVideoTagsAsyncAC, {
        teamId,
        videoIds: [video.id],
        tagIds: Array.from(tagsToBeAdded),
        untagIds: Array.from(tagsToBeRemoved),
      });
      enqueueSnackbar(t('dialog:videotagger.success'), {variant: 'success'});
      onClose?.();
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, loading, onClose, promiseSagaDispatch, selected, t, video]);

  return (
    <React.Fragment>
      <EnhancedDialogTitle>
        {t('dialog:videotagger.title')}
      </EnhancedDialogTitle>
      <DialogContent>
        <MultiSelect
        id='tag-multi-select'
        label={t('dialog:videotagger.label')}
        placeholder={t('dialog:videotagger.placeholder')}
        value={selected}
        options={tagOptions}
        isMulti={true}
        autoFocus={true}
        onChange={handleChange}
        components={{MultiValue: MultiValueTagChip}}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <AsyncButton
        variant='contained'
        color='primary'
        loading={loading}
        disabled={loading || !dirty}
        onClick={handleSubmit}
        >
          {t('dialog:videotagger.confirm')}
        </AsyncButton>
      </DialogActions>
    </React.Fragment>
  );
}

export default React.memo(VideoTaggerDialog);
