import { FlexSpacer, Loader } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import { partition } from '@insights-gaming/utils';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import { makeGetAuthenticationMethods } from 'features/auth/auth-selector';
import { useSelector } from 'hooks/useSelector';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useMemo } from 'react';
import { getMe } from 'selectors/getMe';
import { AuthenticationProvider } from 'types/graphql';

import ChangePasswordForm from './ChangePasswordForm';
import SigninMethodsTable from './signin-methods-table/SigninMethodsTable';
import { useFetchAuthenticationMethods } from './useFetchAuthenticationMethods';

interface SigninMethodsOwnProps {
  className?: string;
}

type SigninMethodsProps = SigninMethodsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'SigninMethods'});

function SigninMethods(props: SigninMethodsProps) {
  const classes = useStyles(props);
  const { className } = props;

  const { t } = useStrictTranslation(['common', 'settings']);

  const [ fetchStatus ] = useFetchAuthenticationMethods({});
  const me = useSelector(getMe);
  const providers = useCreateSelector(makeGetAuthenticationMethods, {});

  const isAlreadyConnected = useMemo(() => {
    const paramsString = window.location.hash.slice(1);
    const params = new URLSearchParams(paramsString);
    const error = params.get('error');
    return error === 'ALREADY_CONNECTED';
  }, []);

  const [firebaseProviders, otherProviders] = useMemo(
    () => partition(providers, (provider) => provider.name === AuthenticationProvider.FIREBASE),
    [providers],
  );

  return (
    <FlexSpacer className={classNames(classes.root, className)} orientation='vertical'>
      {fetchStatus.fetching && !fetchStatus.cursor ? (
        <Loader>{t('common:loading.generic')}</Loader>
      ) : (
        <React.Fragment>
          {isAlreadyConnected && (
            <Alert severity='warning'>
              {t('settings:signinmethods.alreadyconnected')}
            </Alert>
          )}
          <ChangePasswordForm hasEmail={!!me?.email} hasPassword={!!firebaseProviders[0]} />
          <SigninMethodsTable providers={otherProviders} hasPassword={!!firebaseProviders[0]} />
        </React.Fragment>
      )}
    </FlexSpacer>
  );
}

export default React.memo(SigninMethods);
