import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { createCdnSearchParams } from 'helpers';
import { youtubeRegex } from 'helpers/urlvalidator';

import { CDN_REGEX } from '../../constants';

export function useVideoThumbnail(video: Pick<VideoFragment, 'remoteUrl' | 'thumbnail'>, width: number) {
  const { remoteUrl, thumbnail } = video;
  const youtubeId = remoteUrl
    ? remoteUrl.match(youtubeRegex)?.[1]
    : undefined;
  const fallbackThumb = youtubeId ? `https://img.youtube.com/vi/${youtubeId}/0.jpg` : undefined;
  if (!thumbnail) {
    return fallbackThumb;
  }
  if (!CDN_REGEX.test(thumbnail)) {
    return thumbnail;
  }
  const [ url, paramString = '' ] = thumbnail.split('?');
  return url + '?' + createCdnSearchParams({width, aspect_ratio: '16:9'}, new URLSearchParams(paramString));
}
