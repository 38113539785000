import { makeGetVideoBookmarksByVideoId } from 'features/dashboard/video/video-bookmark/video-bookmark-selector';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { ID } from 'types/pigeon';

import { VideoBookmarkState } from './BookmarkContext';
import { useCustomBookmarkContextValue } from './useCustomBookmarkContextValue';

export function useBookmarkContextValue(videoId: ID | undefined, containerWidth: number): VideoBookmarkState {
  const bookmarks = useCreateSelector(makeGetVideoBookmarksByVideoId, { videoId });
  return useCustomBookmarkContextValue(bookmarks, containerWidth);
}

export { isGameBookmark } from './useCustomBookmarkContextValue';
