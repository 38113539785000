import Dialog from '@material-ui/core/Dialog';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FolderIcon from '@material-ui/icons/Folder';
import GetAppIcon from '@material-ui/icons/GetApp';
import LabelIcon from '@material-ui/icons/Label';
import SearchIcon from '@material-ui/icons/Search';
import ShareIcon from '@material-ui/icons/Share';
import { LiveSession } from 'assets';
import { EIntercomID } from 'constants/strings';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import DirectoryPickerDialog from 'features/dashboard/directory-picker-dialog/DirectoryPickerDialog';
import { makeGetTeamVideoTagsByTeamId } from 'features/dashboard/tag/dashboard-tag-selector';
import VideoTaggerDialog from 'features/dashboard/tag/video-tagger-dialog/VideoTaggerDialog';
import { makeGetTeamById } from 'features/dashboard/team/dashboard-team-selector';
import { downloadVideoAC, updateVideo2AC } from 'features/dashboard/video/dashboard-video-slice';
import RenameVideoDialog from 'features/dashboard/video/rename-video-dialog/RenameVideoDialog';
import StartLiveSessionDialog from 'features/live-session/StartLiveSessionDialog';
import { useIsDesktop } from 'features/media-queries/hooks';
import { VideoHelper } from 'features/video-library/video-helpers';
import { makeDownloadFromVideo } from 'helpers/makeDownload';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import ShareVideoDialog from 'material/dialogs/share-video-dialog/ShareVideoDialog';
import { bindMenu } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCreateSelector } from '../../hooks/useCreateSelector';
import { useDialogState } from '../../hooks/useDialogState';
import CreateAnalysisRequestDialogContent from '../../material/dialogs/create-analysis-request-dialog-content/CreateAnalysisRequestDialogContent';
import { ID, Video } from '../../types/pigeon';
import DeleteVideoDialogContent from '../delete-video-dialog/DeleteVideoDialogContent';

interface VideoMenuOwnProps {
  video: Video;
}

type Props = VideoMenuOwnProps & ReturnType<typeof bindMenu>;

function VideoMenu(props: Props) {
  const { open, video, ...rest } = props;
  const { onClose } = rest;

  const dispatch = useDispatch();
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const accessControl = useAccessControl();
  const { t } = useStrictTranslation(['common', 'dialog', 'dashboard-directory']);
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useIsDesktop();

  const onTrue = useCallback(() => {
    onClose();
  }, [onClose]);

  const [
    isCreateAnalysisRequestDialogOpen,
    openCreateAnalysisRequestDialog,
    closeCreateAnalysisRequestDialog,
  ] = useDialogState(false, {onTrue});
  const [isVideoTaggerDialogOpen, openVideoTaggerDialog, closeVideoTaggerDialog] = useDialogState(false, {onTrue});
  const [isEditVideoDialogOpen, openEditVideoDialog, closeEditVideoDialog] = useDialogState(false, {onTrue});
  const [isDeleteVideoDialogOpen, openDeleteVideoDialog, closeDeleteVideoDialog] = useDialogState(false, {onTrue});
  const [isStartSessionDialogOpen, openStartSessionDialog, closeStartSessionDialog] = useDialogState(false, {onTrue});
  const [isShareVideoDialogOpen, openShareVideoDialog, closeShareVideoDialog] = useDialogState(false, {onTrue});
  const [
    isDirectoryPickerDialogOpen,
    openDirectoryPickerDialog,
    closeDirectoryPickerDialog,
  ] = useDialogState(false, {onTrue});

  const teamId = useMemo(() => video ? VideoHelper.getTeamId(video) : undefined, [video]);
  const team = useCreateSelector(makeGetTeamById, teamId);
  const tags = useCreateSelector(makeGetTeamVideoTagsByTeamId, teamId);
  const [movingVideo, setMovingVideo] = useState(false);

  const handleDirectorySelected = useCallback(async (directoryId: ID) => {
    if (movingVideo) {
      return;
    }
    setMovingVideo(true);
    try {
      if (!teamId) {
        throw new Error('No team');
      }
      await promiseSagaDispatch(updateVideo2AC, {id: video.id, teamId, directoryId});
      closeDirectoryPickerDialog();
      enqueueSnackbar(t('dashboard-directory:movevideo.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
      setMovingVideo(false);
    }
  }, [closeDirectoryPickerDialog, enqueueSnackbar, movingVideo, promiseSagaDispatch, t, teamId, video.id]);

  const download = useMemo(() => makeDownloadFromVideo(video), [video]);

  const downloadOnClick = useCallback(() => {
    dispatch(downloadVideoAC(null, {label: 'video-menu'}));
  }, [dispatch]);

  return (
    <React.Fragment>
      <Menu open={open} {...rest}>
        {isDesktop && VideoHelper.getCurrentStatus(video) !== 'uploading' && (
          <MenuItem id={EIntercomID.CONTEXT_MENU_SHARE} onClick={openShareVideoDialog}>
            <ListItemIcon>
              <ShareIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:share')} />
          </MenuItem>
        )}
        {accessControl.canUpdateVod && ([
          <MenuItem key='update' onClick={openEditVideoDialog}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary={t('dialog:renamevideo.title')} />
          </MenuItem>,
          team && <MenuItem key='move' id={EIntercomID.CONTEXT_MENU_MOVE_VIDEO} onClick={openDirectoryPickerDialog}>
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText primary={t('dialog:movevideo.title')} />
          </MenuItem>,
        ])}
        {accessControl.canStartSession
          && !team?.activeSession
          && VideoHelper.getCurrentStatus(video) !== 'uploading' && (
          <MenuItem id={EIntercomID.CONTEXT_MENU_START_SESSION} onClick={openStartSessionDialog}>
            <ListItemIcon>
              <LiveSession width={24} height={24}/>
            </ListItemIcon>
            <ListItemText primary={t('dialog:startsession.title')} />
          </MenuItem>
        )}
        {download && (
          <MenuItem component='a' href={download.url} download={download.name} onClick={downloadOnClick}>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:download')} />
          </MenuItem>
        )}
        {accessControl.canAssignTag && (
          <MenuItem onClick={openVideoTaggerDialog}>
            <ListItemIcon>
              <LabelIcon />
            </ListItemIcon>
            <ListItemText primary={t('dialog:videotagger.title')} />
          </MenuItem>
        )}
        {accessControl.canRequestSomeAnalysis && (
          <MenuItem
          id={EIntercomID.CONTEXT_MENU_ANALYZE}
          onClick={openCreateAnalysisRequestDialog}
          disabled={VideoHelper.isAnalysisBlocked(video)}
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={t('dialog:analyze.title')} />
          </MenuItem>
        )}
        {accessControl.canDeleteVod && team && (
          <MenuItem onClick={openDeleteVideoDialog}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary={t(
              VideoHelper.getCurrentStatus(video) !== 'uploading'
              ? 'dialog:deletevideo.title'
              : 'dialog:uploadabort.title',
            )}
            />
          </MenuItem>
        )}
      </Menu>
      {team && (
        <React.Fragment>
          <Dialog open={isCreateAnalysisRequestDialogOpen} onClose={closeCreateAnalysisRequestDialog}>
            <CreateAnalysisRequestDialogContent video={video} onClose={closeCreateAnalysisRequestDialog} />
          </Dialog>
          <VideoTaggerDialog
          video={video}
          tags={tags}
          open={isVideoTaggerDialogOpen}
          onClose={closeVideoTaggerDialog}
          />
          {
            video.directory && (
              <StartLiveSessionDialog
              open={isStartSessionDialogOpen}
              fullWidth={true}
              onClose={closeStartSessionDialog}
              teamId={team.id}
              directoryId={video.directory.id}
              video={video}
              analyticsSource='video-menu'
              />
            )
          }
          <Dialog open={isDeleteVideoDialogOpen} onClose={closeDeleteVideoDialog}>
            <DeleteVideoDialogContent
            video={video}
            onClose={closeDeleteVideoDialog}
            uploading={VideoHelper.getCurrentStatus(video) === 'uploading'}
            />
          </Dialog>
          <DirectoryPickerDialog
          teamId={team.id}
          initialDirectoryId={video.directory?.id}
          open={isDirectoryPickerDialogOpen}
          onClose={closeDirectoryPickerDialog}
          onConfirm={handleDirectorySelected}
          />
        </React.Fragment>
      )}
      <RenameVideoDialog video={video} open={isEditVideoDialogOpen} onClose={closeEditVideoDialog}/>
      <ShareVideoDialog
      video={video}
      onClose={closeShareVideoDialog}
      open={isShareVideoDialogOpen}
      />
    </React.Fragment>
  );
}

export default React.memo(VideoMenu);
