import { AsyncButton, EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { FEEDBACK_ENDPOINT } from 'constants/index';
import { SITE_KEY } from 'constants/strings';
import { desktop } from 'features/media-queries';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors/getMe';

import PlanFeedbackListItem from './PlanFeedbackListItem';

declare const grecaptcha: any;

interface PlanFeedbackDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
}

type PlanFeedbackDialogProps = PlanFeedbackDialogOwnProps;

const reasons = [
  'dialog:planfeedback.reason.one',
  'dialog:planfeedback.reason.two',
  'dialog:planfeedback.reason.three',
  'dialog:planfeedback.reason.four',
  'dialog:planfeedback.reason.five',
  'dialog:planfeedback.reason.other',
] as const;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  dialogContent: {
    [desktop(theme)]: {
      padding: theme.spacing(3, 8),
    },
  },
  mainDesc: {
    fontSize: createRemFromPx(16),
  },
  reason: {
    [desktop(theme)]: {
      width: createRemFromPx(500),
    },
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(1),
    '&:last-child': {
      padding: theme.spacing(1, 2),
      marginBottom: 0,
    },
    backgroundColor: theme.palette.background.note,
    '&$selected': {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  dialogAction: {
    padding: theme.spacing(2, 4),
  },
  selected: {},
}), {name: 'PlanFeedbackDialogContent'});

function PlanFeedbackDialog(props: PlanFeedbackDialogProps) {
  const isMobilePortrait = useIsMobilePortrait();
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullScreen={isMobilePortrait}>
      <PlanFeedbackDialogContent {...props} />
    </Dialog>
  );
}

function PlanFeedbackDialogContent(props: PlanFeedbackDialogProps) {
  const classes = useStyles(props);
  const { className, onClose } = props;

  const { t } = useStrictTranslation(['common', 'dialog']);
  const me = useSelector(getMe);
  const { enqueueSnackbar } = useSnackbar();

  const [ feedbackLoading, setFeedbackLoading ] = useState<boolean>();
  const [ feedbackSent, setFeedbackSent ] = useState<boolean>();
  const [ reasonIndex, setReasonIndex ] = useState<number>();
  const [ comment, setComment ] = useState<string>();
  const [ otherComment, setOtherComment ] = useState<string>();

  const handleCommentsOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOtherComment(value);
    setComment(value);
  }, []);

  const handleReasonOnClick = useCallback((e: React.MouseEvent, reasonIndex: number) => {
    if (reasonIndex === reasons.length - 1) {
      setComment(otherComment);
    } else {
      setComment(t(reasons[reasonIndex]));
    }
    setReasonIndex(reasonIndex);
  }, [otherComment, t]);

  const handleSubmit = useCallback(async () => {
    if (feedbackLoading || feedbackSent) {
      return;
    }

    if (!comment) {
      onClose();
      return;
    }

    setFeedbackLoading(true);
    try {
      const recaptchaToken: string = await grecaptcha.execute(SITE_KEY, { action: 'feedback' });
      const res = await fetch(FEEDBACK_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          'g-recaptcha-response': recaptchaToken,
          type: 'general',
          platform: 'subscription_cancel',
          contact: me?.alias || 'no name',
          comment,
        }),
      });
      const { error } = await res.json();
      if (error) {
        throw error;
      }
      setFeedbackLoading(false);
      setFeedbackSent(true);
      onClose();
      enqueueSnackbar(t('dialog:sessionterminated.feedbacksuccess'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('dialog:sessionterminated.feedbackfailure'), {variant: 'error'});
      setFeedbackLoading(false);
    }
  }, [comment, enqueueSnackbar, feedbackLoading, feedbackSent, me?.alias, onClose, t]);

  return (
    <div className={classNames(classes.root, className)}>
      <EnhancedDialogTitle onClose={onClose}>
        {t('dialog:planfeedback.title')}
      </EnhancedDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FlexSpacer fullWidth={true} orientation='vertical' flexAlignItems='center'>
          <Typography className={classes.mainDesc}>
            {t('dialog:planfeedback.beforeyougo')}
          </Typography>
          <Typography>
            {t('dialog:planfeedback.reasonforcancelling')}
          </Typography>
        </FlexSpacer>
        <List>
          {reasons.map((reason, index) => (
            <PlanFeedbackListItem
            className={classNames(classes.reason, {[classes.selected]: index === reasonIndex})}
            key={index}
            reason={reason}
            reasonIndex={index}
            onClick={handleReasonOnClick}
            selected={index === reasonIndex}
            otherReason={index === reasons.length - 1}
            handleCommentsOnChange={handleCommentsOnChange}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <AsyncButton
        variant='contained'
        color='primary'
        onClick={handleSubmit}
        >
          {t('common:done')}
        </AsyncButton>
      </DialogActions>
    </div>
  );
}

export default React.memo(PlanFeedbackDialog);
