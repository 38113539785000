import isAfter from 'date-fns/isAfter';
import { isExistent } from 'helpers';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

const threshold = 1;

const getTeamInvitationRecordsByTeamId = (state: RootReducer, teamId: ID) =>
  state.dashboardInvitation.teamInvitationRecords[teamId];

const getInvitationDict = (state: RootReducer) => state.dashboardInvitation.invitationDict;

const getDirectoryId = (state: RootReducer, props: {directoryId: ID}) => props.directoryId;

export const makeGetTeamInvitationRecordsByTeamId = () => createSelector(
  [getTeamInvitationRecordsByTeamId],
  (records) => records,
);

export const makeGetTeamInvitationsByTeamId = () => createSelector(
  [getTeamInvitationRecordsByTeamId, getInvitationDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) {
      return [];
    }
    return ids.map(id => dict[id]).filter(isExistent);
  },
);

export const makeGetUnexpiredTeamInvitationsByTeamId = () => createSelector(
  [makeGetTeamInvitationsByTeamId()],
  (invitations) => invitations.filter(
    ({limit, expiry}) => limit !== 0 && (!expiry || isAfter(new Date(expiry), new Date())),
  ),
);
