import { useCreateSelector } from '@insights-gaming/redux-utils';
import { GetTeamSubscriptionDetailQuery_getTeamSubscriptionDetail } from 'apollo/queries/types/GetTeamSubscriptionDetailQuery';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import { makeGetBillingCycleByTeamId, makeGetTeamSubscriptionByTeamId } from './dashboard-billing.selectors';
import { fetchTeamSubscriptionDetailAC } from './dashboard-billing-slice';

export default function useTeamBillingCycle(
  teamId: ID,
): [GetTeamSubscriptionDetailQuery_getTeamSubscriptionDetail | undefined, boolean] {
  const dispatch = useDispatch();
  const [billingCycle, billingCycleFetched] = useCreateSelector(makeGetBillingCycleByTeamId, teamId);
  const [teamSubscription] = useCreateSelector(makeGetTeamSubscriptionByTeamId, teamId);

  useEffect(() => {
    if (!billingCycleFetched) {
      dispatch(fetchTeamSubscriptionDetailAC.started({ input: { teamId } }));
    }
  }, [billingCycleFetched, dispatch, teamId, teamSubscription]);


  return [billingCycle, billingCycleFetched];
};
