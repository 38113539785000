import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import React from 'react';

interface InventoryMetricsOwnProps {
  className?: string;
  metricLabel?: React.ReactNode;
  metrics?: React.ReactNode;
  progressBar?: React.ReactNode;
  metricButton?: React.ReactNode;
  onClick?: VoidFunction;
  skeleton?: boolean;
  hideButton?: boolean;
}

type InventoryMetricsProps = InventoryMetricsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  tape: {
    margin: theme.spacing(1, 0),
    width: '80%',
    [mobilePortrait(theme)]: {
      width: '100%',
    },
    [mobileLandscape(theme)]: {
      width: '100%',
    },
  },
  longerTape: {
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  headerText: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  smollText: {
    marginTop: theme.spacing(0.5),
  },
  greyButton: {
    minWidth: 105,
    height: 30,
  },
}), {name: 'InventoryMetrics'});

function InventoryMetrics(props: InventoryMetricsProps) {
  const classes = useStyles(props);
  const {
    className,
    metricLabel,
    metrics,
    progressBar,
    metricButton,
    onClick,
    skeleton,
    hideButton,
  } = props;
  const isDesktop = useIsDesktop();
  return (
    <FlexSpacer
    flexJustifyContent='space-between'
    flexAlignItems='center'
    fullWidth={true}
    className={classNames(classes.root, className)}
    >
      <FlexSpacer orientation='vertical' className={hideButton ? classes.longerTape : classes.tape}>
        <FlexSpacer fullWidth={true} flexJustifyContent='space-between' flexAlignItems='center'>
          <Typography className={classes.headerText} variant='h4'>
            {metricLabel}
          </Typography>
          {/* {!hideButton && !isDesktop && (
            <Button
            onClick={onClick}
            type='button'
            variant={skeleton ? 'text' : 'outlined'}
            size='small'
            className={classes.greyButton}
            >
              {metricButton}
            </Button>
          )} */}
        </FlexSpacer>
        {progressBar}
        <Typography className={classes.smollText}>
          {metrics}
        </Typography>
      </FlexSpacer>
      {!hideButton && isDesktop && (
        <Button
        onClick={onClick}
        type='button'
        variant={skeleton ? 'text' : 'outlined'}
        size='small'
        className={classes.greyButton}
        >
          {metricButton}
        </Button>
      )}
    </FlexSpacer>
  );
}

export default React.memo(InventoryMetrics);
