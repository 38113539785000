import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { ProfileFragment } from 'apollo/fragments/types/ProfileFragment';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import UserAvatar from 'material/user-avatar/UserAvatar';
import React, { useMemo } from 'react';

import { useFetchDirectoryUsers } from '../directory/useFetchDirectoryUsers';

interface DirectoryUsersOwnProps {
  className?: string;
  directory: DirectoryFragment;
  users: ProfileFragment[];
}

type DirectoryUsersProps = DirectoryUsersOwnProps;

const maxUsersToDisplay = 3;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  users: {
    cursor: 'pointer',
  },
}), {name: 'DirectoryUsers'});

function DirectoryUsers(props: DirectoryUsersProps) {
  const classes = useStyles(props);
  const { className, directory, users } = props;

  const { t } = useStrictTranslation(['common']);

  const [usersForwardFetchStatus] = useFetchDirectoryUsers(directory.id);

  const initialUserFetch = usersForwardFetchStatus.fetching && !usersForwardFetchStatus.cursor;

  const allUsers = useMemo(() => {
    if (directory.teamDirectoryOwner) {
      return [
        directory.teamDirectoryOwner,
        ...users.filter((user) => user.id !== directory.teamDirectoryOwner?.id),
      ];
    }

    return users;
  }, [directory.teamDirectoryOwner, users]);

  return (
    <div className={classNames(classes.root, className)}>
      {initialUserFetch ? (
        <Skeleton variant='text' width={65} />
      ) : (
        <div className={classes.users}>
          <FlexSpacer spacing={0.5}>
            {allUsers.map((u, i) => i < maxUsersToDisplay && (
              <div key={u.id}>
                <UserAvatar user={u} size='xs' />
              </div>
            ))}
            {allUsers.length > maxUsersToDisplay && (
              <Typography variant='caption'>
                {t('common:extranumbernotext', {number: allUsers.length - maxUsersToDisplay})}
              </Typography>
            )}
          </FlexSpacer>
        </div>
      )}
    </div>
  );
}

export default React.memo(DirectoryUsers);
