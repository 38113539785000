import { AsyncButton, EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import { getGlobalRolesDict, makeGetGlobalRoles } from 'components/global-roles/global-role-selector';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import { EGlobalRoles } from 'material/dialogs/invite-team-members-dialog-content/role-helper';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ID } from 'types/pigeon';

import { useAccessControl } from '../access-control/useAccessControl';
import { removeCollaboratorAC } from '../directory/dashboard-directory-slice';
import { convertCollaboratorToMemberAC } from '../member/dashboard-member-slice';
import { makeGetTeamCustomRolesByTeamId } from '../role/dashboard-role-selector';
import { useFetchTeamIfNecessary } from '../team/useFetchTeamIfNecessary';

interface ConvertCollaboratorDialogOwnProps {
  className?: string;
  directoryId: ID;
  teamId: ID;
  userId: ID;
  onClose: VoidFunction;
}

type ConvertCollaboratorDialogProps = ConvertCollaboratorDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  roleSelector: {
    flex: 1,
  },
}), {name: 'ConvertCollaboratorDialog'});

function ConvertCollaboratorDialog(props: ConvertCollaboratorDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs'>
      <ConvertCollaboratorDialogContent {...props} />
    </Dialog>
  );
}

function ConvertCollaboratorDialogContent(props: ConvertCollaboratorDialogProps) {
  const classes = useStyles(props);
  const { directoryId, teamId, userId, onClose, className } = props;

  const { t } = useStrictTranslation(['common', 'dialog', 'dashboard']);
  const wt = t as TFunction;
  const dispatch = useDispatch();
  const globalRoles = useCreateSelector(makeGetGlobalRoles, '');
  const globalRolesDict = useSelector(getGlobalRolesDict);
  const customRoles = useCreateSelector(makeGetTeamCustomRolesByTeamId, {teamId});
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const [ convertLoading, setConvertLoading ] = useState(false);
  const [ team ] = useFetchTeamIfNecessary(teamId);
  const { enqueueSnackbar } = useSnackbar();

  const accessControl = useAccessControl();

  const filteredCombinedRoles = useMemo(() => {
    const filteredGlobalRoles = accessControl.filterRolesByUserPrivileges(globalRoles);
    const filteredCustomRoles = accessControl.filterRolesByUserPrivileges(customRoles);
    return [...filteredGlobalRoles, ...filteredCustomRoles];
  }, [accessControl, globalRoles, customRoles]);

  const [ role, setRole ] = useState<RoleInterfaceFragment | undefined>(
    () => globalRoles.find(r => r.name === EGlobalRoles.MEMBER),
  );

  const handleRoleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target: { value } } = e;
    const role = globalRolesDict[value] || customRoles.find(cr => cr.id === value);
    setRole(role);
  }, [globalRolesDict, customRoles]);

  const handleConvertCollaborator = useCallback(async() => {
    if (convertLoading) { return; }
    setConvertLoading(true);
    try {
      let roleField;
      if (role) {
        switch (role.__typename) {
          case 'GlobalRole':
            roleField = {
              globalRoles: [role.name],
            };
            break;
          case 'CustomRole':
            roleField = {
              roles: [role.id],
            };
            break;
          default:
            break;
        }
      }
      await promiseSagaDispatch(convertCollaboratorToMemberAC, {
        folderId: directoryId,
        teamId,
        userId,
        ...roleField,
      });
      dispatch(removeCollaboratorAC({userId, directoryId}));
      enqueueSnackbar(t('dialog:convertcollaborator.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('dialog:convertcollaborator.failure'), {variant: 'error'});
    } finally {
      setConvertLoading(false);
    }
  }, [
    convertLoading,
    promiseSagaDispatch,
    directoryId,
    userId,
    teamId,
    role,
    enqueueSnackbar,
    t,
    dispatch,
  ]);

  return (
    <React.Fragment>
      <EnhancedDialogTitle>
        {t('dialog:convertcollaborator.title', {team: team!.name})}
      </EnhancedDialogTitle>
      <DialogContent>
        <FlexSpacer orientation='vertical'>
          <Typography>
            {t('dialog:convertcollaborator.description')}
          </Typography>
          <FlexSpacer flexAlignItems='center' fullWidth={true}>
            <FormControl className={classes.roleSelector}>
              <InputLabel id='invite-link-role'>{t('dashboard:team.roles.role')}</InputLabel>
              <Select
              labelId='role'
              value={!role ? 'none' : role.__typename === 'CustomRole' ? role.id : role.name}
              onChange={handleRoleChange}
              >
                <MenuItem value='none'>{t('common:none')}</MenuItem>
                {filteredCombinedRoles.map(r => r.__typename === 'CustomRole' ? (
                  <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>
                ) : r.__typename === 'GlobalRole' ? (
                  <MenuItem key={r.name} value={r.name}>{wt(`common:roles.global.${r.name}.title`)}</MenuItem>
                ) : null)}
              </Select>
            </FormControl>
            <AsyncButton
            onClick={handleConvertCollaborator}
            variant='contained'
            color='primary'
            loading={convertLoading}
            disabled={convertLoading}
            >
              {t('dialog:convertcollaborator.confirm')}
            </AsyncButton>
          </FlexSpacer>
        </FlexSpacer>
      </DialogContent>
    </React.Fragment>
  );
}

export default React.memo(ConvertCollaboratorDialog);
