import { fileUploadAC, IFileUploadInput, ITusResumable } from 'features/upload/upload-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useCallback } from 'react';

export function useTusUpload({ bypass = false } = {}): (
  input: IFileUploadInput,
) => Promise<ITusResumable> {
  const promiseSagaDispatch = usePromiseSagaDispatch();
  return useCallback(
    (input: IFileUploadInput) => promiseSagaDispatch(fileUploadAC, { ...input, bypass }),
    [bypass, promiseSagaDispatch],
  );
}
