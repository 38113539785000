export enum EKeyCode {
  TAB = 9,
  ENTER = 13,
  ALT = 18,
  ESC = 27,
  SPACE = 32,
  END = 35,
  HOME = 36,
  LEFT_ARROW = 37,
  UP_ARROW = 38,
  RIGHT_ARROW = 39,
  DOWN_ARROW = 40,
  DEL = 46,
  ZERO = 48,
  ONE = 49,
  TWO = 50,
  THREE = 51,
  FOUR = 52,
  FIVE = 53,
  SIX = 54,
  SEVEN = 55,
  EIGHT = 56,
  NINE = 57,
  A = 65,
  B = 66,
  C = 67,
  D = 68,
  E = 69,
  F = 70,
  H = 72,
  I = 73,
  J = 74,
  K = 75,
  L = 76,
  M = 77,
  O = 79,
  P = 80,
  R = 82,
  U = 85,
  T = 84,
  V = 86,
  X = 88,
  Y = 89,
  Z = 90,
  COMMA = 188,
  PERIOD = 190,
}
