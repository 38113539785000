import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import lodashSortBy from 'lodash/sortBy';
import React from 'react';
import { AutoSizer, Column, defaultTableRowRenderer, Index, SortDirectionType, Table, TableCellRenderer, TableHeaderProps, TableRowProps } from 'react-virtualized';

import { formatDuration, formatPercentage } from '../../helpers/formatters';
import UndraggableAvatar from '../undraggable-avatar/UndraggableAvatar';

interface IASDF<T> {
  dataKey   : Extract<keyof T, string>;
  numeric?  : boolean;  // controls if it should be right aligned
  precision?: number;   // decimal places
  duration? : boolean;  // format as hh:mm:ss?
  // flexBasis?: number;
  flexGrow? : number;
  flexShrink?: number;
  displayAsPercentOfTotal?: boolean;
  displayAsPercent?: boolean;
  dataFormatter?: (cellData: any) => React.ReactNode;

  avatar?     : boolean;
  avatarArray?: boolean;

  includeT?   : boolean;

  defaultValue?: any;
}

export interface IExpandedContentOptions<T> {
  sort?: ISortInfo<T>;
}

export interface ICData<T> extends IASDF<T> {
  tKey     : string;
  tDetailedKey?: string;
  tOptions?: any;
  width?   : number;
}

export interface IColumnData<T> extends IASDF<T> {
  label: string;
  tooltip?: string;
  width: number;
  t?   : TFunction;
}

interface IRow {
  index: number;
}

export interface IVirtualizedTableOwnProps<T> {
  variant?    : Variant;
  data        : T[];
  columns     : Array<IColumnData<T>>;
  sortInfo?   : ISortInfo<T>;
  // dynamicRowHeight?: boolean;

  onRowClick?: () => void;
  getExpandedRowHeight?: (t: T) => number;
  renderExpandedContent?: (t: T, options: IExpandedContentOptions<T>) => React.ReactNode;
}

export interface IVirtualizedTableDefaultProps<T> {
  headerHeight: number;
  rowHeight   : number;
  minWidth    : number;
  totals      : Partial<T>;
}

const styles = (theme: Theme) => createStyles({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'center',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      // backgroundColor: theme.palette.grey[200],
      backgroundColor: theme.palette.background.paper,
    },
  },
  tableCell: {
    flex: 1,
    whiteSpace: 'pre',
  },
  noClick: {
    cursor: 'initial',
  },
  avatar: {
    margin: theme.spacing(0, 0.5),
  },
  expandedRows: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },
  outer: {
    marginBottom: theme.spacing(2),
  },
  // inner: {}, // if we ever need it
});

type Variant = 'outer' | 'inner';

type IVirtualizedTableStyleProps = WithStyles<typeof styles>;

export type VirtualizedTableProps<T> =
  IVirtualizedTableOwnProps<T> &
  Partial<IVirtualizedTableDefaultProps<T>> &
  IVirtualizedTableStyleProps;

interface IState<T> {
  sortBy?: Extract<keyof T, string>;
  sortDirection?: SortDirectionType;
  sortedData: T[];
}

class _VirtualizedTable<T> extends React.PureComponent<
  VirtualizedTableProps<T>,
  IState<T>
> {
  public static defaultProps: IVirtualizedTableDefaultProps<any> = {
    headerHeight: 48,
    rowHeight   : 48,
    minWidth    : 700,
    totals      : {},
  };

  private _tableRef = React.createRef<Table>();
  private _expandedRow?: number;

  constructor(props: VirtualizedTableProps<T>) {
    super(props);
    this.state = {
      sortBy: props.sortInfo && props.sortInfo.sortBy,
      sortDirection: props.sortInfo && props.sortInfo.sortDirection,
      sortedData: this.props.data.slice(),
    };
  }

  public componentDidMount() {
    const { sortBy, sortDirection } = this.state;
    if (sortBy && sortDirection) {
      this.handleSort({sortBy, sortDirection});
    }
  }

  public render() {
    const { variant, classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
    const { sortBy, sortDirection, sortedData } = this.state;
    return (
      <Box flex={1} className={classNames(variant && classes[variant])}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
            ref={this._tableRef}
            height={height}
            width={Math.max(width, this.props.minWidth || _VirtualizedTable.defaultProps.minWidth)}
            rowHeight={this.getRowHeight}
            rowGetter={this.rowGetter}
            headerHeight={headerHeight!}
            sort={this.handleSort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            rowCount={sortedData.length}
            {...tableProps}
            rowRenderer={this.rowRenderer}
            rowClassName={this.getRowClassName}
            onRowClick={this.onRowClick}
            >
              {columns.map(this.renderColumn)}
            </Table>
          )}
        </AutoSizer>
      </Box>
    );
  }

  private getRowHeight = (p: Index) => {
    const { getExpandedRowHeight, rowHeight } = this.props;
    if (this._expandedRow === p.index && getExpandedRowHeight) {
      return getExpandedRowHeight(this.rowGetter(p));
    }
    if (rowHeight) {
      return rowHeight;
    }
    return 48;
  }

  private onRowClick = ({index}: Index) => {
    this.changeExpandedRow(index);
  }

  private changeExpandedRow = (index: number) => {
    const { current } = this._tableRef;
    if (!current) { return; }
    const old = this._expandedRow;
    if (this._expandedRow === index) {
      this._expandedRow = undefined;
    } else {
      this._expandedRow = index;
    }
    let min = index;
    if (old !== undefined && old < min) {
      min = old;
    }
    current.recomputeRowHeights(min);
    current.forceUpdateGrid();
  }

  private collapseRows = () => {
    const { current } = this._tableRef;
    if (!current) { return; }
    if (this._expandedRow !== undefined) {
      const old = this._expandedRow;
      this._expandedRow = undefined;
      current.recomputeRowHeights(old);
      current.forceUpdateGrid();
    }
  }

  private getRowClassName = ({index}: IRow) => {
    const { classes, onRowClick, renderExpandedContent } = this.props;

    return classNames(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && !!(onRowClick || renderExpandedContent),
    });
  }

  private rowRenderer = (props: TableRowProps) => {
    const { renderExpandedContent, classes } = this.props;
    const { style, key, rowData } = props;
    const { sortBy, sortDirection } = this.state;
    let sort;
    if (sortBy && sortDirection) {
      sort = { sortBy, sortDirection };
    }
    if (this._expandedRow === props.index) {
      const { width, paddingRight } = style;
      return (
        <Box
        key={key}
        style={style}
        className={classes.expandedRows}
        >
          {defaultTableRowRenderer({
            ...props,
            style: {width, paddingRight, height: this.props.rowHeight},
          })}
          {renderExpandedContent && (
            renderExpandedContent(rowData, {sort})
          )}
        </Box>
      );
    }
    return defaultTableRowRenderer(props);
  }

  private renderColumn = ({ dataKey, tooltip, ...other }: IColumnData<T>, index: number) => {
    const { classes } = this.props;
    return (
      <Column
      key={dataKey}
      className={classes.flexContainer}
      headerRenderer={this.header(index, tooltip || other.label)}
      cellRenderer={this.cellRenderer}
      dataKey={dataKey}
      {...other}
      />
    ) ;
  }

  private cellRenderer: TableCellRenderer = ({ cellData, columnIndex, dataKey }) => {
    const { columns, classes, rowHeight, onRowClick, renderExpandedContent } = this.props;
    const column = columns[columnIndex];
    const { dataFormatter } = column;
    return (
      <TableCell
      component='div'
      className={classNames(classes.tableCell, classes.flexContainer, {
        [classes.noClick]: !(onRowClick || renderExpandedContent),
      })}
      variant='body'
      style={{height: rowHeight}}
      align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
      >
        {dataFormatter
          ? dataFormatter(cellData)
          : this.formatData(cellData, columnIndex, dataKey)
        }
      </TableCell>
    );
  }

  private rowGetter = ({index}: Index) => {
    return this.state.sortedData[index];
  }

  private formatData = (cellData: any, columnIndex: number, dataKey: string) => {
    const { columns, totals, classes } = this.props;
    if (columnIndex == null) { return cellData; }
    const column = columns[columnIndex];
    if (typeof cellData === undefined) {
      return column.defaultValue || '';
    }
    let cellStr = cellData;
    if ((typeof cellData === 'number') && column.duration) {
      cellStr = formatDuration(cellData);
    } else if ((typeof cellData === 'number') && column.precision) {
      cellStr = cellData.toFixed(column.precision);
    }

    if ((typeof cellData === 'number') && column.displayAsPercentOfTotal) {
      let ratio = cellData / (totals || _VirtualizedTable.defaultProps.totals)[dataKey];
      if (isNaN(ratio)) { ratio = 0; }
      const percent = formatPercentage(ratio);
      cellStr = `${cellStr} (${percent})`;
    }

    if ((typeof cellData === 'number') && column.displayAsPercent) {
      cellStr = formatPercentage(cellData);
    }

    if (column.avatarArray) {
      return (cellData as string[]).map((str: string) => (
        <UndraggableAvatar key={str} src={str} className={classes.avatar}/>
      ));
    }

    if (column.avatar) {
      return <UndraggableAvatar src={cellData} />;
    }

    if (column.t) {
      return column.t(cellStr);
    }
    return cellStr;
  }

  private handleSort = ({sortBy, sortDirection}: ISortInfo<T>) => {
    const sortedData = this.sortData(this.state.sortedData, {sortBy, sortDirection});
    this.collapseRows();
    this.setState({sortedData, sortBy, sortDirection});
  }

  private sortData = (data: T[], {sortBy, sortDirection}: ISortInfo<T>) => {
    const sorted = lodashSortBy(data, sortBy);
    switch (sortDirection) {
      case 'ASC': return sorted;
      case 'DESC': return sorted.reverse();
    }
  }

  private header =
  (index:  number, tooltip?: string) =>
  (headerProps: TableHeaderProps) => this.headerRenderer({...headerProps, columnIndex: index, tooltip})

  private headerRenderer = (headerProps: TableHeaderProps & { columnIndex: number, tooltip?: string }) => {
    const { label, tooltip, columnIndex, dataKey, disableSort } = headerProps;
    const { headerHeight, columns, classes } = this.props;
    const { sortBy, sortDirection } = this.state;
    const cell = (
      <TableCell
      component='div'
      className={classNames(
        classes.tableCell,
        classes.flexContainer,
        disableSort && classes.noClick,
      )}
      variant='head'
      style={{height: headerHeight}}
      align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        <span>{label}</span>
        {sortBy === dataKey && (
          sortDirection === 'ASC'
            ? <ArrowDropUpIcon />
            : <ArrowDropDownIcon />
        )}
      </TableCell>
    );
    return tooltip ? (
      <Tooltip title={tooltip} placement='top'>
        {cell}
      </Tooltip>
    ) : cell;
  }
};

interface _VirtualizedTableConstructor<T> {
  new(props: VirtualizedTableProps<T>): _VirtualizedTable<T>;
}

export class VirtualizedTable<T> extends React.PureComponent<
  Omit<VirtualizedTableProps<T>, keyof IVirtualizedTableStyleProps>,
  IState<T>
> {
  private readonly C = withStyles(styles)(_VirtualizedTable as _VirtualizedTableConstructor<T>);

  public render() {
    return React.createElement(this.C, this.props);
  }
}

export interface ISortInfo<T>  {
  sortBy       : Extract<keyof T, string>;
  sortDirection: SortDirectionType;
}

export function mapColumn(t: TFunction, defaultWidth: number) {
  return (c: ICData<any>): IColumnData<any> => {
    const { tKey, tDetailedKey, tOptions, includeT, ...rest } = c;
    return {
      width: defaultWidth,
      ...rest,
      label: t(tKey, tOptions),
      tooltip: tDetailedKey ? t(tDetailedKey, tOptions) : undefined,
      t: includeT ? t : undefined,
    };
  };
}
