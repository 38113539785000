import { Theme } from '@insights-gaming/theme';
import List from '@material-ui/core/List';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

import NotificationListItemSkeleton from './NotificationListItemSkeleton';
import NotificationListItemWrapper from './NotificationListItemWrapper';

interface NotificationListOwnProps {
  className?: string;
  aggregatedNotifications: NotificationFragment[][];
  displaySkeleton?: boolean;
}

type NotificationListProps = NotificationListOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  noNotifications: {
    textAlign: 'center',
  },
}), {name: 'NotificationList'});

function NotificationList(props: NotificationListProps) {
  const classes = useStyles(props);
  const { className, aggregatedNotifications, displaySkeleton } = props;

  const { t } = useStrictTranslation(['notification']);

  return (
    <List className={classNames(classes.root, className)}>
      {displaySkeleton ? (
        Array.from({length: 2}).map((_, i) => (
          <NotificationListItemSkeleton key={i}/>
        ))
      ) : !aggregatedNotifications.length ? (
        <div className={classes.noNotifications}>
          <Typography variant='caption'>
            {t(('notification:notifications.nonotifications'))}
          </Typography>
        </div>
      ) : (
        aggregatedNotifications.slice(0, 10).map((notifications, i) => {
          return (
            <NotificationListItemWrapper
            key={i}
            notifications={notifications}
            />
          );
        })
      )}
    </List>
  );
}

export default React.memo(NotificationList);
