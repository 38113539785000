import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CollaborateIcon, EditIcon, GroupReviewIcon, RecordIcon, SwordIcon, VisualizeIcon } from 'assets';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import React from 'react';

import CircleInformation from './CircleInformation';

interface SidebarInformationOwnProps {
  className?: string;
}

type SidebarInformationProps = SidebarInformationOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#353741',
    width: createRemFromPx(264),
    minHeight: createRemFromPx(500),
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px',
  },
  sidetitle: {
    fontWeight: 600,
    margin: theme.spacing(4.5, 2),
    marginBottom: theme.spacing(1.5),
  },
  flex: {
    height: '77%',
  },
  end: {
    alignSelf: 'flex-end',
  },
}), {name: 'SidebarInformation'});

const redColumn = [
  { info: 'login:register.record', icon: <RecordIcon /> },
  { info: 'login:register.review', icon: <SwordIcon /> },
  { info: 'login:register.makeclips', icon: <EditIcon /> },
];
const blueColumn = [
  { info: 'login:register.collaborate', icon: <CollaborateIcon /> },
  { info: 'login:register.visualize', icon: <VisualizeIcon /> },
  { info: 'login:register.groupreview', icon: <GroupReviewIcon /> },
];

function SidebarInformation(props: SidebarInformationProps) {
  const classes = useStyles(props);
  const { className } = props;

  const { t } = useStrictTranslation(['login']);
  const wt = t as TFunction;

  return (
    <Box className={classes.root}>
      <Typography className={classes.sidetitle} align='center'>
        {t('login:register.sidetitle')}
      </Typography>
      <FlexSpacer flexJustifyContent='space-evenly' fullWidth={true} className={classes.flex}>
        <FlexSpacer orientation='vertical' spacing={2.5}>
          {redColumn.map(
            ({ info, icon }) => <CircleInformation key={info} info={wt(info)} icon={icon} bgColor='#DE2C3510'/>,
          )}
        </FlexSpacer>
        <FlexSpacer orientation='vertical' spacing={2.5} className={classes.end}>
          {blueColumn.map(
            ({ info, icon }) => <CircleInformation key={info} info={wt(info)} icon={icon} bgColor='#5B53FF10'/>,
          )}
        </FlexSpacer>
      </FlexSpacer>
    </Box>
  );
}

export default React.memo(SidebarInformation);

