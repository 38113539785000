import gql from 'graphql-tag';

export const UpdateTeamOrder_Mutation = gql`
    mutation UpdateTeamOrderMutation(
        $input: UpdateTeamOrderInput!
    ) {
        updateTeamOrder(
            input: $input
        ) {
            teamOrder
        }
    }
`;
