import { FlexSpacer } from '@insights-gaming/material-components/FlexSpacer';
import { Theme } from '@insights-gaming/theme';
import { useBooleanState } from '@insights-gaming/utils';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AppContentBox from 'layout/app-content-wrapper/AppContentBox';
import React from 'react';
import { useMemo } from 'react';
import { Redirect, useLocation } from 'react-router';
import { dashboardRoute } from 'routes';
import EmptyStateImage from 'subcomponents/empty-state-image/EmptyStateImage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(7),
  },
  content: {
    textAlign: 'center',
  },
  errorMessage: {
    color: theme.palette.grey.A700,
    cursor: 'default',
  },
}), {name: 'LoginCallback'});

function LoginCallback() {
  const classes = useStyles();
  const { t } = useStrictTranslation(['common']);

  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash]);
  const errorMessage = useMemo(() => params.get('error'), [params]);
  const state = useMemo(() => new URLSearchParams(params.get('state') || ''), [params]);
  const referPath = useMemo(() => state.get('referPath'), [state]);
  const redirectReload = useMemo(() => state.get('reload') === '1', [state]);

  const [shouldShowError, showError] = useBooleanState(false);

  if (!errorMessage) {
    if (redirectReload && referPath) {
      window.location.href = referPath;
      return null;
    }

    return <Redirect to={referPath || dashboardRoute()} />;
  }

  return (
    <AppContentBox>
      <FlexSpacer
      className={classes.root}
      orientation='vertical'
      spacing={2.5}
      flexAlignItems='center'
      >
        <EmptyStateImage url='not-found' />
        <div className={classes.content}>
          <Typography variant='h2'>
            {t('common:authcallbackfailure.title')}
          </Typography>
          <Typography>
            {t('common:authcallbackfailure.description')}
          </Typography>
          {shouldShowError ? (
            <code className={classes.errorMessage}>
              {errorMessage}
            </code>
          ) : (
            <Typography className={classes.errorMessage} onClick={showError}>
              {t('common:authcallbackfailure.showmessage')}
            </Typography>
          )}
        </div>
      </FlexSpacer>
    </AppContentBox>
  );
}

export default React.memo(LoginCallback);
