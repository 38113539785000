import { FlexSpacer } from '@insights-gaming/material-components/FlexSpacer';
import { Loader } from '@insights-gaming/material-components/Loader';
import { VideoReplayContext } from '@insights-gaming/material-components/VideoReplayContext';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TooltipProps } from '@material-ui/core/Tooltip';
import TeamStatistics from 'components/dashboard/team-statistics/TeamStatistics';
import OverwatchMatchOverview from 'containers/OverwatchMatchOverview';
import { useMultiStepProductTour, useProductTourState } from 'features/dashboard/product-tour/product-tour-hooks';
import VideoReplayProductTour from 'features/dashboard/product-tour/VideoReplayProductTour';
import { useFetchTeamIfNecessary } from 'features/dashboard/team/useFetchTeamIfNecessary';
import { useFetchVideoIfNecessary } from 'features/dashboard/video/useFetchVideoIfNecessary';
import { desktop } from 'features/media-queries';
import { MobileDrawingToolbarProvider } from 'features/mobile-drawing-toolbar/MobileDrawingToolbarContext';
import { useMobileDrawingToolbarContext } from 'features/mobile-drawing-toolbar/useMobileDrawingToolbarContext';
import { fetchAnalysisMatchesAC } from 'features/statistics/statistics-slice';
import { makeGetTipsByName } from 'features/tips/tips-selector';
import { FIRST_TIME_REPLAY, hideTipsAsyncAC } from 'features/tips/tips-slice';
import { VideoHelper } from 'features/video-library/video-helpers';
import MatchPanel from 'features/video-replay/match-panel/MatchPanel';
import { ProvideVideoReplayContextValue } from 'features/video-replay/ProvideVideoReplayContextValue';
import VideoReplay from 'features/video-replay/VideoReplay';
import TagFetcher from 'fetchers/tag-fetcher/TagFetcher';
import { isExistent } from 'helpers';
import { useStatsFeatures } from 'hooks/useFeatureSet';
import { useKeybindings } from 'hooks/useKeybindings';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useVideoBackPath } from 'hooks/useVideoBackPath';
import ViewerLayout from 'layout/viewer-layout/ViewerLayout';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as portals from 'react-reverse-portal';
import { Redirect, Route, Switch } from 'react-router';
import { EVideoViewerTabType, VIDEO_MATCH_OVERVIEW_PATH, VIDEO_REPLAY_PATH, VIDEO_STATISTICS_PATH, videoRoute } from 'routes';
import { getMatchCache, makeGetAnalysisMatches } from 'selectors/match';
import { ProvideDrawingToolContextValue } from 'subcomponents/video-player-3/drawing-tools-2/ProvideDrawingToolContextValue';
import { ID } from 'types/pigeon';

import ChangeVideoButton from './ChangeVideoButton';
import DownloadVideoButton from './DownloadVideoButton';
import ShareVideoButton from './ShareVideoButton';
import { useOnExportKillFeed } from './useOnExportKillfeed';
import { useSubscribeToVideoEvents } from './useSubscribeToVideoEvents';
import { useTimeFromUrl } from './useTimeFromUrl';
import VideoViewerHeader from './VideoViewerHeader';

export interface IVideoViewerOwnProps {
  videoId: ID;
  tab?: EVideoViewerTabType;
}

type Props = IVideoViewerOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    [desktop(theme)]: {
      flexDirection: 'row',
    },
  },
  buttons: {
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 1),
  },
}), {name: 'VideoViewer'});

const placements: Array<TooltipProps['placement']> = ['left', 'top', 'top', 'bottom'];

function VideoViewer(props: Props) {
  const classes = useStyles(props);
  const { videoId, tab } = props;

  const promiseSagaDispatch = usePromiseSagaDispatch();

  const [video] = useFetchVideoIfNecessary(videoId);
  const teamId = useMemo(() => video && VideoHelper.getTeamId(video), [video]);
  const [team] = useFetchTeamIfNecessary(teamId);

  const dispatch = useDispatch();
  const [selectedMatchId, setSelectedMatchId] = useState<ID>();

  const matchCache = useSelector(getMatchCache);
  const selectedMatch = useMemo(() => matchCache[selectedMatchId!], [matchCache, selectedMatchId]);
  const analysisMatches = useCreateSelector(makeGetAnalysisMatches, { analysis: video?.latestAnalysis || null });
  const firstMatchId = useMemo(() => (analysisMatches || [])[0]?.id, [analysisMatches]);
  const latestAnalysisType = useMemo(() => video?.latestAnalysis?.type, [video]);

  const onExportKillfeed = useOnExportKillFeed(video, analysisMatches);

  useSubscribeToVideoEvents(videoId);

  useEffect(() => {
    if (firstMatchId) {
      setSelectedMatchId(firstMatchId);
    }
  }, [firstMatchId]);

  useEffect(() => {
    if (video?.latestAnalysis) {
      dispatch(fetchAnalysisMatchesAC({analysis: video.latestAnalysis}));
    }
  }, [dispatch, video]);

  const firstTimeReplay = useCreateSelector(makeGetTipsByName, { name: FIRST_TIME_REPLAY });

  const [ anchors, refHandlers ] = useMultiStepProductTour(4);

  const [ commentBoxRefHandler, drawingToolRefHandler, commentLabelRefHandler, liveSessionRefHandler ] = refHandlers;

  const [
    isVideoReplayProductTourOpen,
    openVideoReplayProductTour,
    closeVideoReplayProductTour,
  ] = useProductTourState(FIRST_TIME_REPLAY);

  const handleCloseVideoReplayProductTour = useCallback(() => {
    closeVideoReplayProductTour();
    promiseSagaDispatch(hideTipsAsyncAC, { names: [FIRST_TIME_REPLAY] });
  }, [closeVideoReplayProductTour, promiseSagaDispatch]);

  useEffect(() => {
    if (firstTimeReplay) {
      openVideoReplayProductTour();
    }
  }, [firstTimeReplay, openVideoReplayProductTour]);

  const portalNode = useMemo(() => portals.createHtmlPortalNode(), []);

  const { canAccess, canAggregate } = useStatsFeatures(latestAnalysisType);

  return (
    <ViewerLayout
    backPath={useVideoBackPath(video)}
    header={(
      <VideoViewerHeader
      tab={tab}
      videoId={videoId}
      video={video}
      selectedMatchId={selectedMatchId}
      liveSessionRefHandler={liveSessionRefHandler}
      />
    )}
    >
      <ProvideDrawingToolContextValue options={useKeybindings({ namespace: 'drawing.tool', bindEmitter: true })}>
        <ProvideVideoReplayContextValue>
          {teamId && <TagFetcher teamId={teamId} />}
          {video && (
            <VideoReplayContext.Consumer>
              {
                (value) => !!analysisMatches?.length && (
                  <portals.InPortal node={portalNode}>
                    <MatchPanel
                    video={video}
                    replayContext={value}
                    selectedMatchId={selectedMatchId}
                    setSelectedMatchId={setSelectedMatchId}
                    />
                  </portals.InPortal>
                )
              }
            </VideoReplayContext.Consumer>
          )}
          <Switch>
            <Route path={VIDEO_REPLAY_PATH}>
              <div className={classes.content}>
                <MobileDrawingToolbarProvider value={useMobileDrawingToolbarContext()}>
                  <VideoReplay
                  videoId={videoId}
                  gameMatch={selectedMatch}
                  portalNode={portalNode}
                  onExportKillfeed={onExportKillfeed}
                  defaultPosition={useTimeFromUrl()}
                  toolbar={(
                    <FlexSpacer>
                      <ShareVideoButton video={video}/>
                      <DownloadVideoButton video={video}/>
                      <Paper>
                        {team && (
                          <Toolbar className={classes.buttons} variant='dense' disableGutters={true}>
                            <ChangeVideoButton
                            teamId={teamId}
                            directoryId={video?.directory?.id}
                            />
                          </Toolbar>
                        )}
                      </Paper>
                    </FlexSpacer>
                  )}
                  commentBoxRefHandler={commentBoxRefHandler}
                  drawingToolRefHandler={drawingToolRefHandler}
                  commentLabelRefHandler={commentLabelRefHandler}
                  />
                  {anchors.every(isExistent) && firstTimeReplay && (
                    <VideoReplayProductTour
                    open={isVideoReplayProductTourOpen}
                    anchors={anchors}
                    placements={placements}
                    onClose={handleCloseVideoReplayProductTour}
                    />
                  )}
                </MobileDrawingToolbarProvider>
              </div>
            </Route>
            {canAccess && (
              <Route path={VIDEO_MATCH_OVERVIEW_PATH}>
                {selectedMatch?.isGeneratedOverwatchMatch() ? (
                  <Box display='flex' flex={1} minHeight={0}>
                    <portals.OutPortal node={portalNode} />
                    <OverwatchMatchOverview gameMatch={selectedMatch} />
                  </Box>
                ) : (
                  <Loader />
                )}
              </Route>
            )}
            {canAccess && canAggregate && (
              <Route path={VIDEO_STATISTICS_PATH}>
                {video && team ? (
                  <TeamStatistics source='video' team={team} videoId={video.id} />
                ) : (
                  <Loader />
                )}
              </Route>
            )}
            <Route><Redirect to={videoRoute(videoId)} /></Route>
          </Switch>
        </ProvideVideoReplayContextValue>
      </ProvideDrawingToolContextValue>
    </ViewerLayout>
  );
}

export default React.memo(VideoViewer);
