import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import React from 'react';

import DivisionListItem from './DivisionListItem';

interface DivisionListItemSkeletonOwnProps {
  className?: string;
}

type DivisionListItemSkeletonProps = DivisionListItemSkeletonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DivisionListItemSkeleton'});

function DivisionListItemSkeleton(props: DivisionListItemSkeletonProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <DivisionListItem
    className={classNames(classes.root, className)}
    name={(
      <Skeleton variant='text' />
    )}
    />
  );
}

export default React.memo(DivisionListItemSkeleton);
