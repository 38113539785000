import gql from 'graphql-tag';

import { MatchFragment } from '../fragments';

export const CreateMatch_Mutation = gql`
    mutation CreateMatchMutation(
        $input: CreateMatchInput!
    ) {
        createMatch(
            input: $input
        ) {
            match {
                ...MatchFragment
            }
        }
    }
    ${MatchFragment}
`;
