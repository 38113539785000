import { createUploadQueueControl } from '@insights-gaming/upload-queue';
import { getCurrentUpload } from 'features/upload/upload-selector';

import { makeGetStartedUploadByVideoId } from '../resumable-uploads-selector';
import { getUploadState } from '../upload-selector';
import { withUploadQueueControl } from './withUploadQueueControl';
import { withUploadQueueItem } from './withUploadQueueItem';

export const UploadQueueControl = createUploadQueueControl(
  getUploadState,
  getCurrentUpload,
  makeGetStartedUploadByVideoId,
  withUploadQueueControl,
  withUploadQueueItem,
);
