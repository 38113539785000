import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { GetUserProfileQuery_me } from 'apollo/queries/types/GetUserProfileQuery';
import classNames from 'classnames';
import UnalignedContainer from 'components/unaligned-container/UnalignedContainer';
import { MAX_NAME_LENGTH } from 'constants/numbers';
import { updateUserAsyncAC } from 'features/auth/auth-slice';
import { useDialogState } from 'hooks/useDialogState';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import isEqual from 'lodash/isEqual';
import DeleteButton from 'material/delete-button/DeleteButton';
import { useSnackbar } from 'notistack';
import React, { useCallback,useMemo,useState } from 'react';
import { UpdateUser2Input } from 'types/graphql';

import DeleteAccountDialog from './DeleteAccountDialog';
import ProfilePicture from './profile-picture/ProfilePicture';

interface ProfileSettingsOwnProps {
  className?: string;
  me: GetUserProfileQuery_me;
}

type ProfileSettingsProps = ProfileSettingsOwnProps;

interface IFormData {
  alias    : string;
  email    : string | null;
  name     : string;
  newpw    : string;
  confirmpw: string;
  picture? : File;
  marketing: boolean;
  updates  : boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: createRemFromPx(500),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  container: {
    padding: 0,
  },
  profilePicture: {
    marginBottom: theme.spacing(4),
  },
  emailContainer: {
    marginBottom: theme.spacing(3),
  },
  checkboxContainer: {
    marginBottom: theme.spacing(2),
  },
  profileInfo: {
    wordBreak: 'break-all',
  },
}), {name: 'ProfileSettings'});

function ProfileSettings(props: ProfileSettingsProps) {
  const classes = useStyles(props);
  const { className, me: { alias, updates, marketing, email, name } } = props;

  const initialFields = useMemo(() => {
    return {
      alias,
      email,
      name,
      updates,
      marketing,
      newpw: '',
      confirmpw: '',
      confirmDelete: '',
    };
  }, [alias, email, marketing, name, updates]);

  const { t } = useStrictTranslation(['settings', 'alert', 'login']);
  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const [ loading, setLoading ] = useState(false);
  const [ fields, setFields ] = useState<IFormData>(initialFields);

  const [ isDeleteAccountDialogOpen, openDeleteAccountDialog, closeDeleteAccountDialog ] = useDialogState();

  const renderProfileInfo = useCallback(() => {
    return (
      <Box className={classes.profileInfo}>
        <Typography variant='h3' component='h1'>{alias}</Typography>
        <Typography variant='h6' component='p'>{name}</Typography>
      </Box>
    );
  }, [alias, classes.profileInfo, name]);

  const getErrorMessage = useCallback((error: Error) => {
    switch (error.message) {
      default: return error.message;
    }
  }, []);

  const handleCheckboxOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFields((prevState) => ({ ...prevState, [name]: checked }));
  }, []);

  const handleFormInputOnChange = useCallback((e: React.SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setFields((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const onUpdateUser = useCallback((input: UpdateUser2Input) => {
    promiseSagaDispatch(updateUserAsyncAC, input);
  }, [promiseSagaDispatch]);

  // TODO: Update profile including email, password, and etc...
  const handleFormOnSubmit = useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { picture } = fields;
    if (loading) { return; }

    const aliasTrim = fields.alias.trim();
    const nameTrim = fields.name.trim();
    if (!aliasTrim || !nameTrim) {
      enqueueSnackbar(t('alert:emptyfield'), {variant: 'error'});
      return;
    }

    const input: UpdateUser2Input = {};
    if (fields.alias !== alias) {
      input.alias = aliasTrim.slice(0, MAX_NAME_LENGTH);
    }

    if (fields.name !== name) {
      input.name = nameTrim.slice(0, MAX_NAME_LENGTH);
    }

    if (fields.updates !== updates) {
      input.updates = fields.updates;
    }

    if (fields.marketing !== marketing) {
      input.marketing = fields.marketing;
    }

    if (fields.email !== email) {
      input.email = fields.email;
    }

    if (picture) {
      input.picture = picture;
    }

    if (Object.keys(input).length) {
      try {
        await onUpdateUser(input);
        enqueueSnackbar(t('alert:changessaved'), {variant: 'success'});
        if (input.email) {
          enqueueSnackbar(t('login:checkemail'), {variant: 'success'});
        }
      } catch (error) {
        enqueueSnackbar(getErrorMessage(error), {variant: 'error'});
      } finally {
        setLoading(false);
        setFields((prevState) => ({...prevState, picture: undefined}));
      }
    }
  }, [alias, enqueueSnackbar, fields, getErrorMessage, loading, marketing, name, onUpdateUser, t, updates, email]);

  const handleFileChange = useCallback((file: File) => {
    setFields((prevState) => ({...prevState, picture: file}));
  }, []);

  const preview = fields.picture ? URL.createObjectURL(fields.picture) : null;

  const disabled = useMemo(() => {
    return isEqual(initialFields, fields);
  }, [fields, initialFields]);

  return (
    <div className={classNames(classes.root, className)}>
      <UnalignedContainer maxWidth='md' className={classes.container}>
        <ProfilePicture
        className={classes.profilePicture}
        name={alias}
        picture={preview || props.me.picture}
        onPictureUpdate={handleFileChange}
        renderInfo={renderProfileInfo}
        disabled={loading}
        loading={loading}
        outlined={true}
        />
        <form onSubmit={handleFormOnSubmit}>
          <Box>
            <Box className={classes.formField}>
              <TextField
              id='alias'
              name='alias'
              label={t('settings:alias')}
              placeholder={t('settings:alias')}
              value={fields.alias}
              onChange={handleFormInputOnChange}
              required={true}
              fullWidth={true}
              inputProps={{
                maxLength: MAX_NAME_LENGTH,
              }}
              />
            </Box>
            <Box className={classes.formField}>
              <TextField
              id='name'
              name='name'
              label={t('settings:fullname')}
              placeholder={t('settings:fullname')}
              value={fields.name}
              onChange={handleFormInputOnChange}
              required={true}
              fullWidth={true}
              inputProps={{
                maxLength: MAX_NAME_LENGTH,
              }}
              />
            </Box>
          </Box>
          <Box className={classes.emailContainer}>
            <TextField
            id='email'
            name='email'
            label={t('settings:email')}
            placeholder={t('settings:email')}
            value={fields.email}
            onChange={handleFormInputOnChange}
            required={true}
            fullWidth={true}
            />
          </Box>
          <Box className={classes.checkboxContainer}>
            <FormControlLabel
            control={<Checkbox checked={fields.marketing} name='marketing' onChange={handleCheckboxOnChange} />}
            label={t('settings:marketing')}
            />
            <FormControlLabel
            control={<Checkbox checked={fields.updates} name='updates' onChange={handleCheckboxOnChange} />}
            label={t('settings:updates')}
            />
          </Box>
          <FlexSpacer flexJustifyContent='flex-end' spacing={2}>
            <DeleteButton
            variant='contained'
            onClick={openDeleteAccountDialog}
            >
              {t('settings:deleteAccount.title')}
            </DeleteButton>
            <AsyncButton
            type='submit'
            color='primary'
            variant='contained'
            loading={loading}
            disabled={loading || disabled}
            >
              {t('settings:saveall')}
            </AsyncButton>
          </FlexSpacer>
        </form>
        <DeleteAccountDialog open={isDeleteAccountDialogOpen} onClose={closeDeleteAccountDialog}/>
      </UnalignedContainer>
    </div>
  );
}

export default React.memo(ProfileSettings);
