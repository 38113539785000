import { AsyncButton, FlexSpacer, Loader } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RosterPlayersFragment } from '../../../../apollo/fragments/types/RosterPlayersFragment';
import RosterCard from '../../../../containers/RosterCard';
import { slicePage } from '../../../../helpers/paginate';
import { useLazyFetchTeamRosters } from '../../../../hooks/fetcher/useLazyFetchTeamRosters';
import { useCreateSelector } from '../../../../hooks/useCreateSelector';
import { useNavigate } from '../../../../hooks/useNavigate';
import { DashboardF, DashboardNS } from '../../../../locales/en/dashboard';
import Pagination from '../../../../material/pagination/Pagination';
import { buildDashboardRoute } from '../../../../routes';
import { makeGetTeamPrimaryRoster, makeGetTeamRosterCache } from '../../../../selectors/statistic';

interface ITeamRosterSelectOwnProps {
  team: TeamFragment;
}

type Props = ITeamRosterSelectOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  primaryRosterIcon: {
    fill: theme.palette.primary.main,
  },
  selectRoster: {
    overflow: 'auto',
  },
}), {name: 'TeamRosterSelect'});

function TeamRosterSelect(props: Props) {
  const classes = useStyles(props);
  const { team } = props;

  const accessControl = useAccessControl();
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ itemsPerPage, setItemsPerPage ] = useState(25);
  const onNavigate = useNavigate();

  const { t } = useTranslation(DashboardNS);

  const primaryRoster = useCreateSelector(makeGetTeamPrimaryRoster, team.id);
  const primaryRosterId = useMemo(() => primaryRoster?.id, [primaryRoster]);

  const rosterCache = useCreateSelector(makeGetTeamRosterCache, team.id);
  const nonPrimaryRosters = useMemo(() => {
    if (!rosterCache) { return []; }
    return Object.values(rosterCache).filter(r => r.id !== primaryRosterId);
  }, [primaryRosterId, rosterCache]);

  const pages = Math.ceil(nonPrimaryRosters.length / itemsPerPage);
  const rostersOnPage = useMemo(
    () => slicePage(nonPrimaryRosters, itemsPerPage, pageNumber),
    [itemsPerPage, nonPrimaryRosters, pageNumber],
  );

  useEffect(() => {
    if (!accessControl.canAccessSomeAnalysis) {
      onNavigate(buildDashboardRoute());
    }
  }, [onNavigate, accessControl.canAccessSomeAnalysis]);

  const { fetch, more, loading } = useLazyFetchTeamRosters({teamId: team.id});
  const renderRoster = useCallback((roster: RosterPlayersFragment) => (
    <RosterCard
    key={roster.id}
    teamId={team.id}
    roster={roster}
    isPrimaryRoster={primaryRosterId === roster.id}
    />
  ), [primaryRosterId, team.id]);

  const handlePageChange = useCallback((__: React.SyntheticEvent, page: number) => {
    setPageNumber(page);
  }, []);

  return (
    <div className={classes.selectRoster}>
      <Box mb={2}>
        <Typography variant='h4' component='h2'>
          {t(DashboardF('team.rosters.title'))}
        </Typography>
        {primaryRoster && (
          <FlexSpacer orientation='vertical'>
            <Box display='flex' alignItems='center'>
              {t(DashboardF('team.rosters.primaryroster'))}
              <StarIcon width={24} className={classes.primaryRosterIcon}/>
            </Box>
            {renderRoster(primaryRoster)}
          </FlexSpacer>
        )}
        {rostersOnPage.length > 0 && (
          <div>
            <Box mb={2}>
              <Typography variant='h4' component='h2'>
                {t(DashboardF('team.rosters.otherrosters'))}
              </Typography>
            </Box>
            {rostersOnPage.map(renderRoster)}
            <Collapse in={pages > 1}>
              <Pagination
              activePage={pageNumber}
              onPageChange={handlePageChange}
              totalPages={pages}
              />
            </Collapse>
          </div>
        )}
        {more && !loading && (
          <AsyncButton onClick={fetch} loading={loading} disabled={loading}>
            Load More
          </AsyncButton>
        )}
        {loading && (
          <Loader />
        )}
      </Box>
    </div>
  );
}
export default React.memo(TeamRosterSelect);
