import { FlexSpacer } from '@insights-gaming/material-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import VideocamIcon from '@material-ui/icons/Videocam';
import classNames from 'classnames';
import { desktop, mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo } from 'react';
import { VideoPublicity } from 'types/graphql';

import { PendingUpload, PendingUploadChanges } from './types';

interface UploadThingsListItemOwnProps {
  item: PendingUpload;
  onRemove: (uuid: string) => void;
  onChange: (changes: PendingUploadChanges) => void;
  isOverLimit: boolean;
}

type Props = UploadThingsListItemOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  thingListItem: {
    display: 'grid',
    [desktop(theme)]: {
      gridTemplateColumns: '1fr repeat(3, 75px)',
    },
    [mobilePortrait(theme)]: {
      gridTemplateColumns: '1fr repeat(3, 50px)',
    },
    [mobileLandscape(theme)]: {
      gridTemplateColumns: '1fr repeat(3, 50px)',
    },
  },
  thingTitleContainer: {
    overflow: 'hidden',
  },
  thingTitle: {
    whiteSpace: 'pre',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    '&$invalid': {
      opacity: 0.5,
    },
  },
  warningText: {
    color: theme.palette.error.main,
  },
  invalid: {},
}), {name: 'PendingUploadsListItem'});

function convertBytesToSize(bytes: number) {
  const baseSize = 1024;
  if (bytes < baseSize) {
    return bytes + 'bytes';
  } else if (bytes < baseSize * baseSize) {
    return (bytes / baseSize).toFixed(2) + 'KB';
  } else if (bytes < baseSize * baseSize * baseSize) {
    return (bytes / (baseSize * baseSize)).toFixed(2) + 'MB';
  } else {
    return (bytes / (baseSize * baseSize * baseSize)).toFixed(2) + 'GB';
  }
}

function PendingUploadsListItem(props: Props) {
  const { item, onRemove, onChange, isOverLimit } = props;
  const classes = useStyles(props);
  const { t } = useStrictTranslation(['common', 'dialog']);

  const deleteOnClick = useCallback(() => onRemove(item.uuid), [onRemove, item.uuid]);
  const handlePrivacyOnChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ uuid: item.uuid, publicity: videoPublicityHelper(value) });
  }, [item.uuid, onChange]);

  const title = useMemo(() => {
    switch (item.type) {
      case 'unprobed_file':
      case 'invalid_file':
      case 'file':
        return item.file.name;

      case 'remote':
        return item.title;
    }
  }, [item]);

  const invalid = useMemo(() => item.type === 'invalid_file', [item]);
  const loading = useMemo(() => item.type === 'unprobed_file', [item]);

  return (
    <React.Fragment>
      <div className={classes.thingListItem}>
        <FlexSpacer flexAlignItems='center' className={classes.thingTitleContainer}>
          <VideocamIcon classes={{root: classNames(classes.icon, {[classes.invalid]: invalid })}}/>
          <FlexSpacer flexAlignItems='center'>
            {invalid && (
              <Tooltip title={t('dialog:upload.invalidvideo')}>
                <ErrorIcon color='error' fontSize='small'/>
              </Tooltip>
            )}
            <div title={title} className={classNames(classes.thingTitle, { [classes.warningText]: isOverLimit })}>
              {title}
            </div>
          </FlexSpacer>
        </FlexSpacer>
        <div className={classes.actionButtons}>
          <Radio
          checked={item.publicity === VideoPublicity.PRIVATE}
          onChange={handlePrivacyOnChange}
          value={VideoPublicity.PRIVATE}
          size='small'
          color='primary'
          disabled={invalid}
          />
        </div>
        <div className={classes.actionButtons}>
          <Radio
          checked={item.publicity === VideoPublicity.OPEN_COMMENTS}
          onChange={handlePrivacyOnChange}
          value={VideoPublicity.OPEN_COMMENTS}
          size='small'
          color='primary'
          disabled={invalid}
          />
        </div>
        <div className={classes.actionButtons}>
          {loading ? (
            <CircularProgress variant='indeterminate' size={20}/>
          ) : (
            <IconButton onClick={deleteOnClick} size='small' title={t('common:remove')}>
              <CloseIcon fontSize='small'/>
            </IconButton>
          )}
        </div>
      </div>
      {isOverLimit && item.type === 'file' && (
        <FlexSpacer flexAlignItems='center'>
          <ErrorIcon color='error' fontSize='small' />
          <Typography variant='caption' color='error'>
            {t('dialog:upload.limitsurpassing', { size: convertBytesToSize(item.file.size) })}
          </Typography>
        </FlexSpacer>
      )}
    </React.Fragment>
  );
}

export default React.memo(PendingUploadsListItem);

function videoPublicityHelper(publicity: string) {
  switch(publicity) {
    case VideoPublicity.PRIVATE:
      return VideoPublicity.PRIVATE;
    case VideoPublicity.OPEN_COMMENTS:
      return VideoPublicity.OPEN_COMMENTS;
    default:
      return null;
  }
}
