import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Skeleton } from '@material-ui/lab';
import { NotificationFragment_CommentNotification } from 'apollo/fragments/types/NotificationFragment';
import { makeGetTeamById } from 'features/dashboard/team/dashboard-team-selector';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { ID } from 'types/pigeon';

interface CommentNotificationMessageProps {
  notifications: NotificationFragment_CommentNotification[]
}

function CommentNotificationMessage(
  { notifications }: CommentNotificationMessageProps,
) {
  const [firstNotification] = notifications;
  const { actor, comment, team: { id: teamId }, video } = firstNotification;
  const team = useCreateSelector(makeGetTeamById, teamId);

  const numberOfExtraActors = useMemo(() => {
    const userIds = new Set<ID>();
    for (const n of notifications) {
      if (n.actor) {
        userIds.add(n.actor.id);
      }
    }
    return userIds.size - 1;
  }, [notifications]);

  if (!team) {
    return <Skeleton variant='text' />;
  }

  const member = actor?.alias || 'somebody';

  return numberOfExtraActors > 0 ? (
    <Trans
    ns='notification'
    i18nKey={
      comment.__typename === 'VideoComment'
      ? 'notifications.commentsnotification'
      : 'notifications.repliesnotification'
    }
    values={{ member, comments: notifications.length, numberOfExtraActors, video: video.name }}
    >
      <strong>{member} and {numberOfExtraActors} others</strong> made comments in <strong>{video.name}</strong>
    </Trans>
  ) : (
    <Trans
    ns='notification'
    i18nKey={
      comment.__typename === 'VideoComment'
      ? 'notifications.commentnotification'
      : 'notifications.replynotification'
    }
    values={{ member, comments: notifications.length, video: video.name }}
    >
      <strong>{member}</strong> made comments in <strong>{video.name}</strong>
    </Trans>
  );
}

export default React.memo(CommentNotificationMessage);
