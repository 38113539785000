import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import { IAvailablePlanData } from 'features/dashboard/billing/useAvailableMetrics';
import useRecurringCost from 'features/dashboard/billing/useRecurringCost';
import { IResourceLimits } from 'features/dashboard/billing/useTeamResourceLimits';
import useTeamSubscription from 'features/dashboard/billing/useTeamSubscription';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import { numToDollarAmount, unixSecondsToDate } from 'helpers/formatters';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import React from 'react';
import { IBillingCycle } from 'types/pigeon/billing';

import AuthorizePaymentButton from './AuthorizePaymentButton';
import SelectPaymentMethodButton from './SelectPaymentMethodButton';

interface OverviewSummaryOwnProps {
  className?: string;
  displaySkeleton: boolean;
  hasPlan: boolean;
  resourceLimits: IResourceLimits;
  billingCycle: IBillingCycle | undefined;
  openAvailablePlansDialog: VoidFunction;
  openCancelSubsDialog: VoidFunction;
  enterprise: boolean | null;
  teamId: string;
  teamBalance?: number;
  currentPlan?: IAvailablePlanData;
}

type OverviewSummaryProps = OverviewSummaryOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  paper: {
    padding: theme.spacing(1.5, 2.5),
    maxWidth: createRemFromPx(416),
  },
  changePlanButton: {
    margin: theme.spacing(1.5, 0),
  },
  badge: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    textTransform: 'capitalize',
  },
  couponName: {
    color: theme.palette.success.main,
  },
}), {name: 'OverviewSummary'});

function OverviewSummary(props: OverviewSummaryProps) {
  const classes = useStyles(props);
  const {
    className,
    teamId,
    hasPlan,
    resourceLimits,
    displaySkeleton,
    billingCycle,
    openAvailablePlansDialog,
    openCancelSubsDialog,
    enterprise,
    teamBalance,
    currentPlan,
  } = props;

  const isMobile = useIsMobilePortrait();

  const { t } = useStrictTranslation(['dashboard', 'dialog']);
  const wt = t as TFunction;

  const [teamSubscription] = useTeamSubscription(teamId);
  const [_, displayRecurringCost] = useRecurringCost(teamId);

  const plan = enterprise
    ? t('dashboard:billing.enterprise')
    : hasPlan
      ? currentPlan?.name
        ? wt(`dialog:availableplans.${currentPlan.name}`)
        : t('dashboard:billing.legacy')
      : t('dashboard:billing.basic');

  return (
    <Box my={2} className={classNames(classes.root, className)}>
      <Paper className={classes.paper}>
        <FlexSpacer
        flexJustifyContent='space-between'
        fullWidth={true}
        orientation='vertical'
        >
          <FlexSpacer fullWidth={true} flexJustifyContent='space-between'>
            <FlexSpacer orientation='vertical' spacing={0}>
              <Typography className={classes.badge} variant='h4'>
                {displaySkeleton ? (
                  <Skeleton variant='text' width={165}/>
                ) : (
                    t('dashboard:billing.currentplan', { plan })
                )}
              </Typography>
              {billingCycle && billingCycle.coupon && (
                <Typography
                component='span'
                className={classes.couponName}
                >
                  [{billingCycle.coupon.name}]
                </Typography>
              )}
            </FlexSpacer>
            <Typography className={classes.badge} variant='h4'>
              {!enterprise && hasPlan && (displaySkeleton ? (
                <Skeleton variant='text' width={165}/>
              ) : (
                displayRecurringCost
              ))}
            </Typography>
          </FlexSpacer>
          {enterprise ? (
            <Typography variant='body2'>
              {t('dashboard:billing.enterpriseoverview')}
            </Typography>
          ) : (
            <FlexSpacer fullWidth={true} orientation='vertical'>
              {displaySkeleton ? (
                <Skeleton variant='text' width={165}/>
              ) : (
                <Typography>
                  {teamBalance !== undefined && teamBalance < 0 &&
                    t('dialog:availableplans.availablecredit', { amount: numToDollarAmount(teamBalance/-100) })
                  }
                </Typography>
              )}
              {displaySkeleton ? (
                <Skeleton variant='text' width={285}/>
              ) : (
                <Typography>
                  {hasPlan && billingCycle ? billingCycle.cancels ? t(
                    'dashboard:billing.cancelat',
                    {
                      date: unixSecondsToDate(billingCycle.cancels),
                    },
                  ) : t(
                    'dashboard:billing.currentbillingcycle',
                    {
                      start: unixSecondsToDate(billingCycle.billingCycleStart),
                      end: unixSecondsToDate(billingCycle.billingCycleEnd),
                    },
                  ) : t('dashboard:billing.unlimitedupload')}
                </Typography>
              )}
              {!displaySkeleton && teamSubscription && !teamSubscription?.active && (
                <Typography>
                  {t('dashboard:billing.paymentfailed') + (
                    billingCycle?.invoiceStatus ? '' : ' (' + t('dashboard:billing.contactsupport') + ')'
                  )}
                </Typography>
              )}
            </FlexSpacer>
          )}
        </FlexSpacer>
        {displaySkeleton ? (
          <Skeleton variant='text' width={135} height={40}/>
        ) : (
          <FlexSpacer
          flexAlignItems='center'
          orientation={isMobile ? 'vertical' : 'horizontal'}
          className={classes.changePlanButton}
          >
            {
              billingCycle?.invoiceStatus === 'requires_action' ? (
                <AuthorizePaymentButton teamId={teamId} teamSubscriptionDetails={billingCycle} />
              ) : billingCycle?.invoiceStatus === 'requires_payment_method' ? (
                <SelectPaymentMethodButton teamId={teamId} />
              ) : null
            }
            {
              !enterprise && (!teamSubscription || teamSubscription.active) && (
                <AsyncButton
                type='submit'
                variant='contained'
                color='primary'
                onClick={openAvailablePlansDialog}
                fullWidth={isMobile}
                >
                  {t('dashboard:billing.changeplan')}
                </AsyncButton>
              )
            }
            {
              (
                !enterprise &&
                billingCycle &&
                teamSubscription?.active &&
                !billingCycle?.cancels
              ) && (
                <Button
                onClick={openCancelSubsDialog}
                >
                  {t('dialog:cancelsubscription.title')}
                </Button>
              )
            }
          </FlexSpacer>
        )}
      </Paper>
    </Box>
  );
}

export default React.memo(OverviewSummary);
