import gql from 'graphql-tag';

import { Invitation2Fragment } from '../fragments';

export const CreateInvitation_Mutation = gql`
    mutation CreateInvitationMutation(
        $input: CreateInvitationInput!
    ) {
        createInvitation(
            input: $input
        ) {
            invitation {
                ...Invitation2Fragment
            }
        }
    }
    ${Invitation2Fragment}
`;
