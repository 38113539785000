import { DrawingTool } from '@insights-gaming/drawing-canvas';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useCallback, useRef } from 'react';

import ControlsGenericButton from '../controls/ControlsGenericButton';
import { DrawingState } from './useDrawingState';

export interface DrawingToolbarToolButtonProps {
  state: DrawingState;
  tool: DrawingTool;
  title: string;
  disabled: boolean;
  children?: React.ReactElement,
}

function DrawingToolbarToolButton({
  state: { tool: currentTool, setTool },
  tool,
  title,
  disabled,
  children,
}: DrawingToolbarToolButtonProps) {
  const onClick = useCallback(() => setTool(tool), [setTool, tool]);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <ControlsGenericButton disabled={disabled} selected={currentTool === tool} onClick={onClick}>
        {children && (
          <Tooltip title={title} PopperProps={{ container: ref.current }}>
            <div style={{ fontSize: 0 }}>
              {/* This div is needed for tooltips to work with fontawesome icons */}
              {children}
            </div>
          </Tooltip>
        )}
      </ControlsGenericButton>
    </div>
  );
}

export default React.memo(DrawingToolbarToolButton);
