import { Theme } from '@insights-gaming/theme';
import Radio from '@material-ui/core/Radio';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React, { useCallback } from 'react';
import { MuteLevel, NotificationType } from 'types/graphql';

import NotificationSettingsTableRow from './NotificationSettingsTableRow';

interface NotificationSettingsTableRowWrapperOwnProps {
  className?: string;
  notificationType?: NotificationType;
  handleMuteLevelOnChange: (nt: NotificationType, ml: MuteLevel) => void;
  selectedValue?: MuteLevel;
}

type NotificationSettingsTableRowWrapperProps = NotificationSettingsTableRowWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'NotificationSettingsTableRowWrapper'});

function NotificationSettingsTableRowWrapper(props: NotificationSettingsTableRowWrapperProps) {
  const classes = useStyles(props);
  const { className, notificationType, handleMuteLevelOnChange, selectedValue } = props;

  const { t } = useStrictTranslation(['settings']);
  const wt = t as TFunction;

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (notificationType) {
      handleMuteLevelOnChange(notificationType, MuteLevel[e.target.value]);
    }
  }, [notificationType, handleMuteLevelOnChange]);

  return (
    <NotificationSettingsTableRow
    notification={wt(`settings:notifications.${notificationType}`)}
    muteLevelCells={
      Object.keys(MuteLevel).map(ml => (
        <FixedTableCell key={ml} width={MuteLevel[ml] === MuteLevel.NONE ? 80 : 50} align='center'>
          <Radio
          checked={selectedValue === MuteLevel[ml]}
          onChange={handleChange}
          value={MuteLevel[ml]}
          name={MuteLevel[ml]}
          inputProps={{
            'aria-label': MuteLevel[ml],
          }}
          size='small'
          />
        </FixedTableCell>
      ))}
    />
  );
}

export default React.memo(NotificationSettingsTableRowWrapper);
