import { AsyncButton } from '@insights-gaming/material-components/AsyncButton';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { createControlTerminateEvent } from 'factories/kmsessionEventFactory';
import { liveSessionDisconnectAC, liveSessionSendActionAC } from 'features/live-session/live-session-slice';
import { isVideoFragment } from 'helpers/isVideoFragment';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useVideoBackPath } from 'hooks/useVideoBackPath';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import DeleteButton from 'material/delete-button/DeleteButton';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { JOIN_SESSION_PATH } from 'routes';
import { SmolVideo } from 'types/pigeon/kmsession';

interface LeaveLiveSessionDialogContentOwnProps {
  isPleb: boolean;
  onClose: () => void;
  video?: SmolVideo | VideoFragment;
}

export type LeaveLiveSessionDialogContentProps = LeaveLiveSessionDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  negative: {
    backgroundColor: theme.palette.negative.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    '&&:hover': {
      backgroundColor: theme.palette.negative.light,
    },
  },
}), { name: 'LeaveLiveSessionDialogContent' });

function LeaveLiveSessionDialogContent({
  isPleb,
  onClose,
  video,
}: LeaveLiveSessionDialogContentProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useStrictTranslation(['common', 'dialog']);
  const onNavigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isSessionRoute = useRouteMatch(JOIN_SESSION_PATH);
  const backPath = useVideoBackPath(isVideoFragment(video) ? video : undefined);
  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const navigateOut = useCallback(() => {
    dispatch(liveSessionDisconnectAC());
    if (isSessionRoute) {
      onNavigate(backPath);
    }
  }, [backPath, dispatch, isSessionRoute, onNavigate]);

  const handleTerminateSession = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      await promiseSagaDispatch(liveSessionSendActionAC, createControlTerminateEvent());
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, loading, promiseSagaDispatch]);

  return (
    <React.Fragment>
      <BetterDialogTitle>
        {isPleb
          ? t('dialog:leavesession.leaveconfirm')
          : t('dialog:leavesession.hostleaveconfirm')
        }
      </BetterDialogTitle>
      {!isPleb && (
        <DialogContent>
          <Typography gutterBottom={true}>
            {t('dialog:leavesession.hostleavedescription')}
          </Typography>
          {isSessionRoute && (
            <Typography>
              <em>{t('dialog:leavesession.hostleavehint')}</em>
            </Typography>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button
        type='button'
        variant='outlined'
        onClick={onClose}
        >
          {t('common:cancel')}
        </Button>
        {isPleb ? (
          <DeleteButton
          variant='contained'
          onClick={navigateOut}
          >
            {t('dialog:leavesession.confirm')}
          </DeleteButton>
        ) : (
          <AsyncButton
          variant='contained'
          classes={{ root: classes.negative }}
          loading={loading}
          disabled={loading}
          onClick={handleTerminateSession}
          >
            {t('dialog:leavesession.hostterminatesession')}
          </AsyncButton>
        )}
      </DialogActions>
    </React.Fragment>
  );
}

interface LeaveLiveSessionDialogOwnProps {}

export type LeaveLiveSessionDialogProps =
  & DialogProps
  & LeaveLiveSessionDialogOwnProps
  & LeaveLiveSessionDialogContentProps;

function LeaveLiveSessionDialog({
  isPleb,
  onClose,
  video,
  ...props
}: LeaveLiveSessionDialogProps) {
  return (
    <Dialog {...props} onClose={onClose}>
      <LeaveLiveSessionDialogContent
      isPleb={isPleb}
      onClose={onClose}
      video={video}
      />
    </Dialog>
  );
}

export default React.memo(LeaveLiveSessionDialog);
