import { UpdateNotifications_Mutation } from 'apollo/mutations';
import { GetNotificationCounts_Query, GetNotifications_Query } from 'apollo/queries';
import { GetNotificationCountsQueryVariables } from 'apollo/queries/types/GetNotificationCountsQuery';
import { watchAsyncBatchedQuery, watchAsyncMutation, watchAsyncQuery, watchBidirectionalAC2 } from 'helpers/saga/effects';
import { all } from 'redux-saga/effects';
import { NotificationState } from 'types/graphql';
import { ID } from 'types/pigeon';

import {
  fetchNotificationCountsAC,
  fetchNotificationsAC,
  fetchUnreadFolderNotificationsAC,
  fetchUnreadNotificationsAC,
  fetchUnreadTeamNotificationsAC,
  updateNotificationsAC,
} from './notifications-slice';

export default function* notificationsSaga() {
  yield all([
    watchBidirectionalAC2(fetchNotificationsAC, GetNotifications_Query, ['queryNotifications']),
    watchAsyncQuery(fetchNotificationCountsAC, GetNotificationCounts_Query, ['notificationCounts']),
    watchAsyncMutation(updateNotificationsAC, UpdateNotifications_Mutation, ['updateNotifications', 'notifications']),
    watchAsyncBatchedQuery<{ teamId?: ID; folderId?: ID } | void, GetNotificationCountsQueryVariables, number>(
      100,
      [
        fetchUnreadNotificationsAC,
        fetchUnreadTeamNotificationsAC,
        fetchUnreadFolderNotificationsAC,
      ],
      fetchNotificationCountsAC,
      (params) => ({
        filters: params.map((p) => ({
          states: [NotificationState.UNREAD, NotificationState.VIEWED],
          ...(
            // NOTE: teamIds and directoryIds are mutually exclusive.
            //       teamIds takes precedence on the backend, so teamIds must be omitted if directoryIds is present.
            p?.folderId ?
              { directoryIds: [p.folderId] } :
              p?.teamId && { teamIds: [p.teamId] }
          ),
        })),
      }),
    ),
  ]);
}
