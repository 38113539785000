import { FlexSpacer, Loader } from '@insights-gaming/material-components';
import { DirectionalFetchStatus, useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import { deleteSelfAsyncAC } from 'features/auth/auth-slice';
import { getTeamSubscriptionDict, makeGetTeamSubscriptionByTeamId } from 'features/dashboard/billing/dashboard-billing.selectors';
import { fetchTeamSubscriptionAC } from 'features/dashboard/billing/dashboard-billing-slice';
import { getTeamMemberRecords } from 'features/dashboard/member/dashboard-member-selector';
import { getTeams } from 'features/dashboard/team/dashboard-team-selector';
import MemberFetcher from 'fetchers/member-fetcher/MemberFetcher';
import { useLogout } from 'hooks/dispatch/logout';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import UndeletableTeamSelector from './UndeletableTeamSelector';

interface DeleteAccountDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
}

type DeleteAccountDialogProps = DeleteAccountDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  confirmCard: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  loader: {
    margin: theme.spacing(1),
  },
}), {name: 'DeleteAccountDialog'});

const deleteAccountConfirmation = 'DELETE';

const graphqlError = 'user owns some teams or folders';

function TeamSubscriptionFetcher(props: { teamId: string }) {
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const [ fetching, setFetching ] = useState(false);
  const [, fetched] = useCreateSelector(makeGetTeamSubscriptionByTeamId, props.teamId);

  useEffect(() => {
    if (fetching || fetched) {
      return;
    }

    setFetching(true);
    promiseSagaDispatch(fetchTeamSubscriptionAC, { teamId: props.teamId }).finally(() => setFetching(false));
  }, [fetched, fetching, promiseSagaDispatch, props.teamId]);

  return null;
}

function DeleteAccountDialog(props: DeleteAccountDialogProps) {
  const classes = useStyles(props);
  const { className, open, onClose } = props;

  const { t } = useStrictTranslation(['settings', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const onLogout = useLogout();

  const [ deleteInputValue, setDeleteInputValue ] = useState('');
  const [ deleteAccountLoading, setDeleteAccountLoading ] = useState(false);

  const handleDeleteInputOnChange = useCallback((e: React.SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    setDeleteInputValue(value);
  }, []);

  const deleteAccount = useCallback(async () => {
    if (deleteAccountLoading) { return; }
    setDeleteAccountLoading(true);
    try {
      await promiseSagaDispatch(deleteSelfAsyncAC, { reason: deleteAccountConfirmation });
      onClose();
      onLogout(true);
      window.location.href = '/account-deleted';
    } catch (error) {
      enqueueSnackbar(t('settings:deleteAccount.failure'), {variant: 'error'});
    } finally {
      setDeleteAccountLoading(false);
    }
  }, [deleteAccountLoading, enqueueSnackbar, onClose, onLogout, promiseSagaDispatch, t]);

  const renderDeleteAccountDesc = useCallback(() => {
    return (
      <FlexSpacer orientation='vertical'>
        <FlexSpacer flexAlignItems='center' className={classes.confirmCard}>
          <ErrorIcon/>
          <FlexSpacer orientation='vertical'>
            <Typography variant='h5'>
              {t('settings:deleteAccount.descriptionTitle')}
            </Typography>
            <Typography>
              {t('settings:deleteAccount.description')}
            </Typography>
          </FlexSpacer>
        </FlexSpacer>
        <Typography>
          {t('settings:deleteAccount.typeDelete')}
        </Typography>
        <TextField
        id='confirmDelete'
        name='confirmDelete'
        value={deleteInputValue}
        onChange={handleDeleteInputOnChange}
        />
      </FlexSpacer>
    );
  }, [classes.confirmCard, deleteInputValue, handleDeleteInputOnChange, t]);

  const disabled = useMemo(() => deleteInputValue.trim() !== deleteAccountConfirmation ,[deleteInputValue]);

  const teams = useSelector(getTeams);
  const teamMemberRecords = useSelector(getTeamMemberRecords);
  const teamSubscriptions = useSelector(getTeamSubscriptionDict);

  const teamsWithOwnership = useMemo(() => {
    return teams.filter((t) => t.owner.__typename === 'Self');
  }, [teams]);

  const undeletableTeams = useMemo(() => {
    if (!teamSubscriptions || !teamMemberRecords) {
      return [];
    }
    return teamsWithOwnership.filter((t) => (
      (teamMemberRecords[t.id]?.ids?.length || 0) > 1 ||
      teamSubscriptions[t.id]
    ));
  }, [teamSubscriptions, teamMemberRecords, teamsWithOwnership]);

  const renderTransferTeamOwnership = useCallback(() => {
    return (
      <FlexSpacer orientation='vertical'>
        <Typography variant='caption'>
          {t('settings:transferOwner.description')}
        </Typography>
        <FlexSpacer orientation='vertical'>
          {undeletableTeams.map((t) => (
            <UndeletableTeamSelector
            key={t.id}
            team={t}
            members={(teamMemberRecords[t.id]?.ids?.length || 0) > 1}
            subscription={!!teamSubscriptions[t.id]}
            />
          ))}
        </FlexSpacer>
      </FlexSpacer>
    );
  }, [t, undeletableTeams, teamMemberRecords, teamSubscriptions]);

  const title = useMemo(
    () => t(undeletableTeams.length ? 'settings:transferOwner.title' : 'settings:deleteAccount.confirmation'),
    [t, undeletableTeams.length],
  );

  const [
    fetchedStatuses,
    setFetchedStatuses,
  ] = useState<Dictionary<DirectionalFetchStatus>>({});

  const displayLoader = useMemo(
    () => !!teamsWithOwnership.length && (
      Object.values(fetchedStatuses).some((s) => s.fetching || s.more) ||
      teamsWithOwnership.some((t) => !(t.id in teamSubscriptions))
    ),
    [fetchedStatuses, teamSubscriptions, teamsWithOwnership],
  );

  return (
    <Dialog open={open} onClose={onClose}>
      {teamsWithOwnership.map(
        ({ id }) => <MemberFetcher key={id} teamId={id} setFetchedStatuses={setFetchedStatuses}/>,
      )}
      {teamsWithOwnership.map(({ id }) => <TeamSubscriptionFetcher key={id} teamId={id}/>)}
      {displayLoader ? (
        <Loader className={classes.loader}/>
      ) : (
        <AlertDialogContent
        titleText={title}
        description={!!undeletableTeams.length ? renderTransferTeamOwnership() : renderDeleteAccountDesc()}
        cancel={undeletableTeams.length ? undefined : {
          text: t('common:cancel'),
          action: onClose,
        }}
        confirm={undeletableTeams.length ? undefined : {
          text: t('common:confirm'),
          loading: deleteAccountLoading,
          disabled: deleteAccountLoading || disabled,
          action: deleteAccount,
          negative: true,
        }}
        />
      )}
    </Dialog>
  );
}

export default React.memo(DeleteAccountDialog);
