import ShowLoadingScreen from 'features/loading-screen/ShowLoadingScreen';
import React from 'react';

import { useFetchNotifications } from './useFetchNotification';

function NotificationsFetcher() {
  const [status] = useFetchNotifications({});
  return status.fetching && !status.cursor ? <ShowLoadingScreen/> : null;
}

export default React.memo(NotificationsFetcher);
