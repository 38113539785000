
import { useCallback, useMemo, useState } from 'react';

import { VideoCommentMenuState } from './VideoCommentMenuContext';

const AUTOPAUSE_KEY = 'autopause';
const AUTOPAUSETYPING_KEY = 'autopausetyping';
const AUTOSCROLL_KEY = 'autoscroll';
const AUTOPLAYAUDIO_KEY = 'autoplayaudio';

function useBooleanToggleState(
  defaultValue: boolean = false,
  setter: (b: boolean) => void,
): [boolean, () => void] {
  const [ value, setValue ] = useState(defaultValue);
  return [
    value,
    useCallback(() => setValue((value) => {
      setter(!value);
      return !value;
    }), [setter]),
  ];
}

export function getAutoPauseSetting(): boolean {
  return JSON.parse(localStorage.getItem(AUTOPAUSE_KEY) || 'false');
}

function setAutoPauseSetting(b: boolean) {
  localStorage.setItem(AUTOPAUSE_KEY, String(b));
}

export function getAutoPauseTyingSetting(): boolean {
  return JSON.parse(localStorage.getItem(AUTOPAUSETYPING_KEY) || 'false');
}

function setAutoPauseTypingSetting(b: boolean) {
  localStorage.setItem(AUTOPAUSETYPING_KEY, String(b));
}

export function getAutoscrollSetting(): boolean {
  return JSON.parse(localStorage.getItem(AUTOSCROLL_KEY) || 'false');
}

function setAutoscrollSetting(b: boolean) {
  localStorage.setItem(AUTOSCROLL_KEY, String(b));
}

export function getAutoPlayAudioSetting(): boolean {
  return JSON.parse(localStorage.getItem(AUTOPLAYAUDIO_KEY) || 'false');
}

function setAutoPlayAudioSetting(b: boolean) {
  localStorage.setItem(AUTOPLAYAUDIO_KEY, String(b));
}

export function useVideoCommentMenuContext(): VideoCommentMenuState {

  const [ autoPause, toggleAutoPause ] = useBooleanToggleState(
    getAutoPauseSetting(),
    setAutoPauseSetting,
  );
  const [ autoPauseTyping, toggleAutoPauseTyping ] = useBooleanToggleState(
    getAutoPauseTyingSetting(),
    setAutoPauseTypingSetting,
  );
  const [ autoScroll, toggleAutoScroll ] = useBooleanToggleState(
    getAutoscrollSetting(),
    setAutoscrollSetting,
  );
  const [ autoPlayAudio, toggleAutoPlayAudio ] = useBooleanToggleState(
    getAutoPlayAudioSetting(),
    setAutoPlayAudioSetting,
  );

  return useMemo(() => ({
    autoPause,
    autoPauseTyping,
    autoScroll,
    autoPlayAudio,
    toggleAutoPause,
    toggleAutoPauseTyping,
    toggleAutoScroll,
    toggleAutoPlayAudio,
  }), [
    autoPause,
    autoPauseTyping,
    autoPlayAudio,
    autoScroll,
    toggleAutoPause,
    toggleAutoPauseTyping,
    toggleAutoPlayAudio,
    toggleAutoScroll,
  ]);
}
