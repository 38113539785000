import { IReportGraphQLErrorInput, reportGraphQLErrorAC } from 'actions/error-actions';
import { isExistent } from 'helpers';
import { raceLogout } from 'helpers/saga/effects';
import { SagaIterator } from 'redux-saga';
import { all, call, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

function reportGraphQLErrorWorker(action: Action<IReportGraphQLErrorInput>) {
  const queryNames = Array.from(
    new Set(
      action.payload.errors
      .map(e => e.path)
      .filter(isExistent)
      .filter(a => a.length > 0)
      .map(a => a[0]),
    ),
  );
  // TODO: implement this when we have a backend for it
  // eslint-disable-next-line
  console.log(
    '%cGraphQL Error', 'background: #fff0f0; color: red',
    queryNames,
    action.payload,
  );
}

function* watchReportGraphQLError(): SagaIterator {
  yield takeEvery(reportGraphQLErrorAC, function* (action: Action<IReportGraphQLErrorInput>) {
    yield raceLogout(call(reportGraphQLErrorWorker, action));
  });
}

export default function* errorSaga() {
  yield all([
    watchReportGraphQLError(),
  ]);
}
