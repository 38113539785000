import { ConvertCollaboratorToMember_Mutation } from 'apollo/mutations';
import { GetMembers_Query } from 'apollo/queries';
import { watchAsyncMutation, watchBidirectionalAC2 } from 'helpers/saga/effects';
import { all } from 'redux-saga/effects';

import { convertCollaboratorToMemberAC, fetchTeamMembersAC } from './dashboard-member-slice';

export default function* dashboardMemberSaga() {
  yield all([
    watchBidirectionalAC2(fetchTeamMembersAC, GetMembers_Query, ['queryMembers']),
    watchAsyncMutation(
      convertCollaboratorToMemberAC,
      ConvertCollaboratorToMember_Mutation,
      ['convertCollaboratorToMember'],
    ),
  ]);
}
