import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { makeGetGlobalRoles } from 'components/global-roles/global-role-selector';
import GlobalRoleTable from 'components/global-roles/GlobalRoleTable';
import { useFetchGlobalRoles } from 'components/global-roles/useFetchGlobalRoles';
import RoleTable from 'components/role-table/RoleTable';
import { EIntercomID } from 'constants/strings';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import RoleEditorDialogContent from 'material/dialogs/role-editor-dialog-content/RoleEditorDialogContent';
import React from 'react';

import { useAccessControl } from '../access-control/useAccessControl';
import { makeGetTeamCustomRolesByTeamId } from '../role/dashboard-role-selector';
import { useFetchTeamCustomRoles } from '../role/useFetchTeamCustomRoles';

interface DashboardTeamSettingsRolesOwnProps {
  className?: string;
  team: TeamFragment;
}

type DashboardTeamSettingsRolesProps = DashboardTeamSettingsRolesOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  createRoleButton: {
    alignSelf: 'start',
  },
}), {name: 'DashboardTeamSettingsRoles'});

function DashboardTeamSettingsRoles(props: DashboardTeamSettingsRolesProps) {
  const classes = useStyles(props);
  const { className, team } = props;
  const { t } = useStrictTranslation(['dashboard-settings']);
  const [globalRolesForwardStatus] = useFetchGlobalRoles({ deprecated: false });
  const globalRoles = useCreateSelector(makeGetGlobalRoles, '');
  const [createRoleDialogOpen, openCreateRoleDialog, closeCreateRoleDialog] = useDialogState();
  const [teamCustomRolesForwardFetchStatus] = useFetchTeamCustomRoles({teamId: team.id});
  const roles = useCreateSelector(makeGetTeamCustomRolesByTeamId, {teamId: team.id});
  const displaySkeleton = teamCustomRolesForwardFetchStatus.fetching && !teamCustomRolesForwardFetchStatus.cursor;
  const globalRolesLoading = globalRolesForwardStatus.fetching && !globalRolesForwardStatus.cursor;
  const { canCreateRole } = useAccessControl();

  return (
    <FlexSpacer
    orientation='vertical'
    className={(classNames(classes.root, className))}
    >
      {canCreateRole && (
        <Button
        id={EIntercomID.CREATE_ROLE}
        className={classes.createRoleButton}
        variant='outlined'
        startIcon={<AddIcon />}
        onClick={openCreateRoleDialog}
        size='small'
        >
          {t('dashboard-settings:roles.createrole')}
        </Button>
      )}
      <FlexSpacer orientation='vertical' spacing={4}>
        {/* Global Roles */}
        <FlexSpacer orientation='vertical' flexAlignItems='flex-start'>
          <GlobalRoleTable globalRoles={globalRoles} displaySkeleton={globalRolesLoading} team={team} />
        </FlexSpacer>
        {/* Custom Roles */}
        <FlexSpacer id={EIntercomID.ROLES} orientation='vertical' flexAlignItems='flex-start'>
          <RoleTable roles={roles} displaySkeleton={displaySkeleton} team={team} />
        </FlexSpacer>
        <Dialog open={createRoleDialogOpen} onClose={closeCreateRoleDialog}>
          <RoleEditorDialogContent onClose={closeCreateRoleDialog} team={team} />
        </Dialog>
      </FlexSpacer>
    </FlexSpacer>
  );
}

export default React.memo(DashboardTeamSettingsRoles);
