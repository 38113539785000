import Menu from '@material-ui/core/Menu';
import PaletteIcon from '@material-ui/icons/Palette';
import { createColorChangedAction } from 'factories/kmsessionEventFactory';
import { liveSessionSendActionAC } from 'features/live-session/live-session-slice';
import { hexToRGBA } from 'helpers';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useCallback } from 'react';
import { ColorState, SketchPicker } from 'react-color';
import { useDispatch } from 'react-redux';

import ControlsGenericButton from '../controls/ControlsGenericButton';
import { DrawingState } from './useDrawingState';

export interface DrawingToolbarColorPickerButtonProps {
  state: DrawingState;
  disabled: boolean;
}

function DrawingToolbarColorPickerButton({
  state: { color, setColor },
  disabled,
}: DrawingToolbarColorPickerButtonProps) {
  const popupState = usePopupState({popupId: 'color-picker', variant: 'popover'});
  const dispatch = useDispatch();
  const onChange = useCallback((color: ColorState) => {
    setColor(color.hex);
    dispatch(liveSessionSendActionAC.started(createColorChangedAction(color.hex)));
  }, [dispatch, setColor]);

  return (
    <React.Fragment>
      <ControlsGenericButton disabled={disabled} {...bindTrigger(popupState)}>
        <PaletteIcon htmlColor={hexToRGBA(color, disabled ? 0.3 : 1)} />
      </ControlsGenericButton>
      <Menu
      {...bindMenu(popupState)}
      MenuListProps={{disablePadding: true}}
      disablePortal={true}
      style={{ cursor: 'default' }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      >
        <SketchPicker
        color={color}
        onChange={onChange}
        disableAlpha={true}
        />
      </Menu>
    </React.Fragment>
  );
}

export default React.memo(DrawingToolbarColorPickerButton);
