import { useCreateSelector } from '@insights-gaming/redux-utils';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchLeagueOfLegendsAssetsAC, makeGetAssetsFetchStatusByGameVersion, makeGetLeagueAssetsByGameVersion } from '../common';

export function useFetchLeagueOfLegendsAssets(gameVersion: string) {
  const assets = useCreateSelector(makeGetLeagueAssetsByGameVersion, { gameVersion });
  const status = useCreateSelector(makeGetAssetsFetchStatusByGameVersion, { gameVersion });

  const dispatch = useDispatch();

  useEffect(() => {
    if (assets) {
      return;
    }
    if (!status || (!status.fetching && !status.done && !status.error)) {
      dispatch(fetchLeagueOfLegendsAssetsAC.started({ gameVersion }));
    }
  }, [dispatch, status, assets, gameVersion]);
  return [assets, status] as const;
}
