import { Theme } from '@insights-gaming/theme';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import React from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';

import { AntiHeal, Freeze, Hack, Sleep, Stunned, UnknownStatus } from '../../../../../assets/overwatch-status';
import { ultimateFromHeroName } from '../../../../../helpers';
import { formatDuration } from '../../../../../helpers/formatters';
import {
  WithAsyncOverwatchAbilityIcons,
  withAsyncOverwatchAbilityIcons,
} from '../../../../../hoc/withAsyncOverwatchAbilityIcons';
import { HeroesF, HeroesNS } from '../../../../../locales/en/heroes';
import HeroImage from '../../../../../subcomponents/hero-image/HeroImage';
import {
  flipOverwatchTeamColor,
  IOverwatchMatchDataNonTeamfightKillEvent,
  IOverwatchMatchDataNonTeamfightResEvent,
  IOverwatchMatchDataNonTeamfightStatusEvent,
  IOverwatchMatchDataNonTeamfightSuicideEvent,
  IOverwatchMatchDataNonTeamfightUltEvent,
  IOverwatchMatchDataTeamfightKillEvent,
  IOverwatchMatchDataTeamfightResEvent,
  IOverwatchMatchDataTeamfightStatusEvent,
  IOverwatchMatchDataTeamfightSuicideEvent,
  IOverwatchMatchDataTeamfightUltEvent,
  isNonTeamFightEvent,
  isOverwatchMatchDataKillEvent,
  isOverwatchMatchDataResEvent,
  isOverwatchMatchDataSuicideEvent,
  isOverwatchMatchDataUltEvent,
  isTeamFightEvent,
  OverwatchEventType,
  OverwatchMatchDataEvent,
  OverwatchTeamColor,
  unpackOverwatchMatchDataEventType,
} from '../../../../../types/pigeon/overwatch-match-data';
import EventButton from './EventButton';

export interface IEventOwnProps {
  event: OverwatchMatchDataEvent;
  statuses: IOverwatchMatchDataTeamfightStatusEvent[];
  jumpToTime: (time: number) => void;
  startTime: number;
  eventTime: string;
  mostRelevantEvent?: React.Ref<HTMLButtonElement>;
  className?: string;
}

export type EventProps = IEventOwnProps &
  WithAsyncOverwatchAbilityIcons &
  WithStyles<typeof styles> &
  WithTranslation;

interface AbilityProps {
    width?: number;
}

const styles = (theme: Theme) => {
  return createStyles({
    blue: {
      border: `2px solid ${theme.palette.overwatch.blueTeam}`,
    },
    red: {
      border: `2px solid ${theme.palette.overwatch.redTeam}`,
    },
    heroAbilities: {
      borderRadius: '50%',
    },
    statusWrapper: {
      display: 'inline-flex',
      zIndex: 1,
      width: 'fit-content',
    },
    centerIcon: {
      margin: 'auto',
    },
    abilityIcon: {
      width: 24,
      height: 24,
    },
  });
};

class Event extends React.Component<EventProps> {

  public render() {
    const { event } = this.props;
    if (isOverwatchMatchDataKillEvent(event)) {
      const type = unpackOverwatchMatchDataEventType(event);
      if (type === OverwatchEventType.MECHKILL) {
        return this.renderObjKillEvent(event);
      }
      if (type === OverwatchEventType.KILL) {
        return this.renderKillEvent(event);
      }
      return this.renderObjKillEvent(event);
    }
    if (isOverwatchMatchDataUltEvent(event)) {
      return this.renderUltEvent(event);
    }
    if (isOverwatchMatchDataResEvent(event)) {
      return this.renderResEvent(event);
    }
    if (isOverwatchMatchDataSuicideEvent(event)) {
      return this.renderSuicideEvent(event);
    }
    return null;
  }

  private renderKillEvent = (
    event: IOverwatchMatchDataTeamfightKillEvent | IOverwatchMatchDataNonTeamfightKillEvent,
  ) => {
    const { mostRelevantEvent, statuses, classes, className, eventTime, getIconFromAbilityName } = this.props;
    const { ability, killer, killee } = this.unpackKill(event);

    const Ability = getIconFromAbilityName(ability);
    const killerHero = killer.hero;
    const killeeHero = killee.hero;
    const echoCopy = killer.currentCopy;
    const isArrowKill = ['none', 'melee'].includes(ability);
    return (
      <EventButton
      mostRelevantEvent={mostRelevantEvent}
      onClick={this.handleEventOnClick}
      className={className}
      team={killer.color}
      >
        <span>{eventTime}</span>
        <HeroImage
        hero={killerHero}
        team={killer.color}
        copy={echoCopy}
        />
        {!isArrowKill
          ? <Ability width={24} fill='white' className={classNames(classes.centerIcon, classes.abilityIcon)} />
          : <ArrowForwardIcon className={classes.centerIcon} />}
        <HeroImage
        hero={killeeHero}
        team={flipOverwatchTeamColor(killer.color)}
        />
        <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        >
          {statuses.map(this.statusToIcon)}
        </Box>
      </EventButton>
    );
  }

  private renderObjKillEvent =
  (event: IOverwatchMatchDataTeamfightKillEvent | IOverwatchMatchDataNonTeamfightKillEvent) => {
    const { mostRelevantEvent, getIconFromAbilityName, classes, className, eventTime } = this.props;
    const { ability, killer, killee } = this.unpackKill(event);
    const killeeAbilityName = this.abilityNameFromObj(killee.hero);

    const KilleeAbility = getIconFromAbilityName(killeeAbilityName);
    const Ability = getIconFromAbilityName(ability);
    const killerHero = killer.hero;
    const killeeColor = killer.color === 'blue' ? 'red' : 'blue';
    const isArrowKill = ['none', 'melee'].includes(ability);
    return (
      <EventButton
      mostRelevantEvent={mostRelevantEvent}
      onClick={this.handleEventOnClick}
      className={className}
      team={killer.color}
      >
        <span>{eventTime}</span>
        <HeroImage
        hero={killerHero}
        team={killer.color}
        />
        {!isArrowKill
          ? <Ability width={24} fill='white' className={classNames(classes.centerIcon, classes.abilityIcon)} />
          : <ArrowForwardIcon className={classes.centerIcon} />}
        {KilleeAbility &&
          <KilleeAbility
          fill='white'
          height={30}
          width={30}
          className={classNames(classes[killeeColor], classes.heroAbilities)}
          />}
      </EventButton>
    );
  }

  private renderUltEvent =
  (event: IOverwatchMatchDataTeamfightUltEvent | IOverwatchMatchDataNonTeamfightUltEvent) => {
    const { mostRelevantEvent, classes, eventTime, getIconFromAbilityName, className } = this.props;
    const { hero, team, targetHero, currentCopy } = this.unpackUltEvent(event);

    const ultName = ultimateFromHeroName(hero.toLocaleLowerCase());
    const Ultimate = getIconFromAbilityName(ultName);
    const copiedUltName = currentCopy ? ultimateFromHeroName(currentCopy) : '';
    const CopiedUlt = getIconFromAbilityName(copiedUltName);
    return (
      <EventButton
      mostRelevantEvent={mostRelevantEvent}
      onClick={this.handleEventOnClick}
      className={className}
      team={team}
      >
        <span>{eventTime}</span>
        <Box display='flex'>
          {Ultimate &&
            <Badge
            color={targetHero || currentCopy ? 'default' : 'primary'}
            showZero={true}
            badgeContent={
                targetHero || currentCopy ?
                  <HeroImage
                  hero={targetHero ? targetHero : hero}
                  team={targetHero ? flipOverwatchTeamColor(team) : team}
                  variant='circle'
                  size='xs'
                  />
                :
                  this.getUltNumber(event) || 0
              }
            >
              {
                currentCopy ?
                  <CopiedUlt
                  height={30}
                  width={30}
                  fill='white'
                  className={classNames(classes.heroAbilities, classes[team])}
                  />
                :
                  <Ultimate
                  height={30}
                  width={30}
                  fill='white'
                  className={classNames(classes.heroAbilities, classes[team])}
                  />
              }
            </Badge>
          }
        </Box>
      </EventButton>
    );
  }

  private renderResEvent =
  (event: IOverwatchMatchDataTeamfightResEvent | IOverwatchMatchDataNonTeamfightResEvent) => {
    const { mostRelevantEvent, eventTime, getIconFromAbilityName, classes, className } = this.props;
    const { team, hero } = this.unpackResEvent(event);
    const resurrect = 'resurrect';

    const Ability = getIconFromAbilityName(resurrect);
    const mercy = 'mercy';
    const resurrectedHero = hero;
    return (
      <EventButton
      mostRelevantEvent={mostRelevantEvent}
      onClick={this.handleEventOnClick}
      className={className}
      team={team as OverwatchTeamColor}
      >
        <span>{eventTime}</span>
        <HeroImage
        hero={mercy}
        team={team as OverwatchTeamColor}
        />
        {Ability && <Ability className={classNames(classes.centerIcon, classes.abilityIcon)} width={24} fill='white' />}
        <HeroImage
        hero={resurrectedHero}
        team={team as OverwatchTeamColor}
        />
      </EventButton>
    );
  }

  private renderSuicideEvent =
  (event: IOverwatchMatchDataTeamfightSuicideEvent | IOverwatchMatchDataNonTeamfightSuicideEvent) => {
    const { mostRelevantEvent, eventTime, className, classes } = this.props;
    const { team, hero } = this.unpackSuicideEvent(event);
    const heroName = hero;
    return (
      <EventButton
      mostRelevantEvent={mostRelevantEvent}
      onClick={this.handleEventOnClick}
      className={className}
      team={team}
      >
        <span>{eventTime}</span>
        <div/>
        <ArrowForwardIcon className={classes.centerIcon} />
        <HeroImage
        hero={heroName}
        team={team}
        />
      </EventButton>
    );
  }

  private unpackSuicideEvent =
  (event: IOverwatchMatchDataTeamfightSuicideEvent | IOverwatchMatchDataNonTeamfightSuicideEvent) => {
    if (isTeamFightEvent(event)) {
      return {
        team: event.team,
        hero: event.hero,
      };
    } else {
      return {
        team: event.details.color,
        hero: event.details.hero,
      };
    }
  }

  private unpackKill =
  (event: IOverwatchMatchDataTeamfightKillEvent | IOverwatchMatchDataNonTeamfightKillEvent) => {
    if (isTeamFightEvent(event)) {
      return {
        ability: event.ability,
        killer: event.killer,
        killee: event.killee,
      };
    } else {
      return {
        ability: event.details.ability,
        killer: event.details.killer,
        killee: event.details.killee,
      };
    }
  }

  private unpackUltEvent =
  (event: IOverwatchMatchDataTeamfightUltEvent | IOverwatchMatchDataNonTeamfightUltEvent) => {
    if (isTeamFightEvent(event)) {
      return {
        hero: event.hero,
        team: event.team,
        targetHero: event.targetHero,
        currentCopy: event.currentCopy,
      };
    } else {
      return {
        hero: event.details.hero,
        team: event.details.color,
        targetHero: event.details.targetHero,
        currentCopy: event.details.currentCopy,
      };
    }
  }

  private unpackResEvent =
  (event: IOverwatchMatchDataTeamfightResEvent | IOverwatchMatchDataNonTeamfightResEvent) => {
    if (isTeamFightEvent(event)) {
      return {
        team: event.team,
        hero: event.hero,
      };
    } else {
      return {
        team: event.details.killee.color,
        hero: event.details.killee.hero,
      };
    }
  }

  private getUltNumber =
  (ultEvent: IOverwatchMatchDataTeamfightUltEvent | IOverwatchMatchDataNonTeamfightUltEvent): number | undefined => {

    let event: any;
    if (isTeamFightEvent(ultEvent)) {
      event = ultEvent;
    } else if (isNonTeamFightEvent(ultEvent)) {
      event = ultEvent.details;
    } else {
      return undefined;
    }

    if (event.numTeammateDeaths || event.numTeammateDeaths === 0) {
      return event.numTeammateDeaths * -1;
    } else if (event.numHacks || event.numHacks === 0) {
      return event.numHacks;
    } else if (event.numFreezes || event.numFreezes === 0) {
      return event.numFreezes;
    } else if (event.numStuns || event.numStuns === 0) {
      return event.numStuns;
    } else if (event.numAssists || event.numAssists === 0) {
      return event.numAssists;
    } else if (event.numKills || event.numKills === 0) {
      return event.numKills;
    } else {
      return undefined;
    }
  }

  private abilityNameFromObj = (objName:string) => {
    switch (objName) {
      case 'torb_turret':
        return 'deployTurret';
      case 'dva_mech':
        return 'callMech';
      case 'super_charger':
        return 'superCharger';
      case 'duplicate':
        return 'duplicate';
      default:
        return objName;
    }
  }

  private statusToIcon =
  (statusEvent: IOverwatchMatchDataTeamfightStatusEvent | IOverwatchMatchDataNonTeamfightStatusEvent, i: number) => {
    const { classes } = this.props;
    let status: string;
    let time: number;
    if (isTeamFightEvent(statusEvent)) {
      status = statusEvent.status;
      time = statusEvent.time;
    } else if (isNonTeamFightEvent(statusEvent)) {
      status = statusEvent.details.status;
      time = statusEvent.details.time;
    } else {
      return null;
    }
    const effect = camelCase(status);
    switch (effect) {
      case 'hack':
        return (
          <Tooltip key={i} title={this.formatStatusEvent('status.hack.verb', time)}>
            <div className={classes.statusWrapper}>
              <Hack style={this.glow('128,0,128')} width={16} fill='white' />
            </div>
          </Tooltip>
        );
      case 'stunned':
        return (
          <Tooltip key={i} title={this.formatStatusEvent('status.stunned.verb', time)}>
            <div className={classes.statusWrapper}>
              <Stunned style={this.glow('255,215,0')} width={16} fill='white' />
            </div>
          </Tooltip>
        );
      case 'frozen':
        return (
          <Tooltip key={i} title={this.formatStatusEvent('status.frozen.verb', time)}>
            <div className={classes.statusWrapper}>
              <Freeze style={this.glow('0,255,255')} width={16} fill='white' />
            </div>
          </Tooltip>
        );
      case 'sleep':
        return (
          <Tooltip key={i} title={this.formatStatusEvent('status.sleep.verb', time)}>
            <div className={classes.statusWrapper}>
              <Sleep style={this.glow('0,139,139')} width={16} fill='white' />
            </div>
          </Tooltip>
        );
      case 'antiHeal':
        return (
          <Tooltip key={i} title={this.formatStatusEvent('status.antiHeal.verb', time)}>
            <div className={classes.statusWrapper}>
              <AntiHeal style={this.glow('255,0,255')} width={16} fill='white' />
            </div>
          </Tooltip>
        );
      default:
        return (
          <Tooltip key={i} title={this.formatStatusEvent('status.unknown.verb', time)}>
            <div className={classes.statusWrapper}>
              <UnknownStatus style={this.glow('255,0,0')} width={16} fill='white' />
            </div>
          </Tooltip>
        );
    }
  }

  private formatStatusEvent = (
    words: string, time: number,
  ): string => {
    const { t } = this.props;
    return `${t(HeroesF(words as any))} @ ${formatDuration(time)}`;
  }

  private glow = (color: string) => {
    return { background: `radial-gradient(ellipse at center, rgba(${color},1) 0%,transparent 69%)`};
  }

  private handleEventOnClick = () => this.props.jumpToTime(this.props.startTime);

}

export default withAsyncOverwatchAbilityIcons({})(
  withTranslation(HeroesNS)(
    withStyles(styles)(
      Event,
    ),
  ),
);
