import { Theme } from '@insights-gaming/theme';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { createStyles, fade,makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

export interface ChatMemberListControlButtonOwnProps {
  selected?: boolean;
  userColor?: string;
  title: string;
}

type ChatMemberListControlButtonProps = ChatMemberListControlButtonOwnProps & IconButtonProps;

type Props = {
  userColor?: string;
  selected?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => createStyles({
  root: {
    color: fade(theme.palette.text.primary, .5),
    '&:disabled': {
      color: fade(theme.palette.text.primary, .5),
    },
    '&:hover': {
      color: 'unset',
    },
  },
  selected: props => ({
    color: props.userColor,
    '&:disabled': {
      color: props.userColor,
    },
    '&:hover': {
      color: props.userColor,
    },
  }),
}), {name: 'ChatMemberListControlButton'});

function ChatMemberListControlButton(
  {className, selected, userColor = 'white', children, title, ...rest}: ChatMemberListControlButtonProps,
) {
  const classes = useStyles({userColor, selected});
  return (
    <Tooltip title={title} placement='top'>
      <div>
        <IconButton
        className= {selected ? classes.selected : classes.root}
        size='small'
        color='inherit'
        {...rest}
        >
          {children}
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default React.memo(ChatMemberListControlButton);
