import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import SettingsTab from 'components/settings/SettingsTab';
import SettingsTabs from 'components/settings/SettingsTabs';
import { mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { SearchResultType } from 'types/graphql';

import { makeGetTeamSearchQueryByTeamId } from './search-selectors';
import { useSearchHandlers } from './useSearchHandlers';

interface SearchTabsOwnProps {
  className?: string;
  team: TeamFragment;
}

type SearchTabsProps = SearchTabsOwnProps;

const defaultTabs: Array<{ name: SearchResultType, count?: number }> = [
  { name: SearchResultType.COMMENT },
  { name: SearchResultType.VIDEO },
  // { name: SearchResultType.FOLDER },
  // { name: SearchResultType.REPLY },
];

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  deemphasized: {
    color: theme.palette.text.deemphasized,
  },
  searchQuery: {
    [mobilePortrait(theme)]: {
      maxWidth: createRemFromPx(200),
      overflow: 'hidden',
      display: 'inline-flex',
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  label: {
    textTransform: 'uppercase',
    alignItems: 'center',
    paddingLeft: 0,
    fontWeight: 600,
  },
}), {name: 'SearchTabs'});

function SearchTabs(props: SearchTabsProps) {
  const classes = useStyles(props);
  const { className, team } = props;
  const theme = useTheme();
  const { t } = useStrictTranslation(['search']);

  const { handleTabOnClick } = useSearchHandlers(team.id);
  const isDesktop = useIsDesktop();

  const teamSearchQuery = useCreateSelector(makeGetTeamSearchQueryByTeamId, team.id);

  const tabs = useMemo(() => {
    if (!teamSearchQuery) {
      return defaultTabs;
    }

    const { videoResultsRecords, videoCommentResultsRecords } = teamSearchQuery;
    const typeCounts = Object.fromEntries(defaultTabs.map((t) => {
      if (t.name === SearchResultType.COMMENT) {
        return [SearchResultType.COMMENT, videoCommentResultsRecords?.ids.length ?? 0];
      }
      if (t.name === SearchResultType.VIDEO) {
        return [SearchResultType.VIDEO, videoResultsRecords?.ids.length ?? 0];
      }
      return [];
    }));

    return defaultTabs.map(({ name }) => ({ name, count: typeCounts[name] }));
  }, [teamSearchQuery]);

  return (
    <div className={classNames(classes.root, className)}>
      <FlexSpacer flexAlignItems='flex-end' flexJustifyContent='space-between'>
        <FlexSpacer flexAlignItems='flex-end'>
          <Typography variant='h2'>
            {t('search:searchresults')}
          </Typography>
          {teamSearchQuery && teamSearchQuery.params && (
            <Typography variant='h6' className={classes.deemphasized}>
              <Trans
              i18nKey={'search:foron'}
              ns={'search'}
              values={{query: teamSearchQuery.params.query, team: team.name}}
              >
                <strong
                className={classNames(classes.searchQuery)}
                >
                  query
                </strong>
                <strong>team</strong>
              </Trans>
            </Typography>
          )}
        </FlexSpacer>
        {isDesktop && (
          <Typography variant='caption'>
            {t('search:sortedby')}
          </Typography>
        )}
      </FlexSpacer>
      {isDesktop && (
        <Divider variant='fullWidth' className={classes.divider}/>
      )}
      <FlexSpacer
      flexJustifyContent='space-between'
      flexAlignItems='center'
      fullWidth={true}
      >
        <SettingsTabs
        indicatorColor='primary'
        value={teamSearchQuery?.tab}
        onChange={handleTabOnClick}
        orientation='horizontal'
        backgroundcolor={theme.palette.background.default}
        thickness={2}
        >
          {tabs.map(({ name, count }) => (
            <SettingsTab
            key={name}
            value={name}
            label={t(`search:tabs.${name}`, { count: count ?? '...' })}
            classes={{ wrapper: classes.label }}
            />
          ))}
        </SettingsTabs>
        {!isDesktop && (
          <Typography variant='caption'>
            {t('search:sortedby')}
          </Typography>
        )}
      </FlexSpacer>
      {isDesktop && (
        <Divider variant='fullWidth' className={classes.divider}/>
      )}
    </div>
  );
}

export default React.memo(SearchTabs);
