import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { milestones } from './milestones';
import ReferralEasterEgg from './ReferralEasterEgg';
import ReferralMilestones from './ReferralMilestones';

interface ReferralPanelOwnProps {
  className?: string;
  team: TeamFragment;
  openInviteTeamMembersDialog: VoidFunction;
}

type ReferralPanelProps = ReferralPanelOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(3),
    height: '100%',
    width: '50%',
  },
}), {name: 'ReferralPanel'});

function ReferralPanel(props: ReferralPanelProps) {
  const classes = useStyles(props);
  const { className, team, openInviteTeamMembersDialog } = props;

  const currentMilestoneIndex = useMemo(
    () => milestones.findIndex((milestone) => team.referrals < milestone.referralGoal),
    [team.referrals],
  );

  return (
    <Box className={classNames(classes.root, className)} boxShadow={3} component={Paper}>
      {currentMilestoneIndex < 0 ? (
        <ReferralEasterEgg/>
      ) : (
        <ReferralMilestones
        teamReferrals={team.referrals}
        currentMilestoneIndex={currentMilestoneIndex}
        openInviteTeamMembersDialog={openInviteTeamMembersDialog}
        />
      )}
    </Box>
  );
}

export default React.memo(ReferralPanel);
