import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import LinkIcon from '@material-ui/icons/Link';
import { EIntercomID } from 'constants/strings';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

export interface CopyInviteLinkButtonOwnProps {
  className?: string;
  link: string;
}

export type CopyInviteLinkButtonProps = CopyInviteLinkButtonOwnProps;

function CopyInviteLinkButton({ className, link }: CopyInviteLinkButtonProps) {
  const { t } = useStrictTranslation();

  const [ copied, setCopied ] = useState(false);
  const clipboardTimerRef = useRef<NodeJS.Timer>();

  const handleCopyOnClick = useCallback(() => {
    if (clipboardTimerRef.current) {
      clearTimeout(clipboardTimerRef.current);
      clipboardTimerRef.current = undefined;
    }
    setCopied(true);
    clipboardTimerRef.current = setTimeout(() => setCopied(false), 1000);
  }, []);

  const handleButtonOnClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  return (
    <CopyToClipboard
    text={link}
    onCopy={handleCopyOnClick}
    >
      <Button
      id={EIntercomID.COPY_INVITE_LINK}
      className={className}
      variant='contained'
      startIcon={copied ? <DoneIcon /> : <LinkIcon />}
      color='primary'
      onClick={handleButtonOnClick}
      >
        {t(copied ? 'common:copied' : 'common:invitelink')}
      </Button>
    </CopyToClipboard>
  );
}

export default React.memo(CopyInviteLinkButton);
