import { useCreateSelector } from 'hooks/useCreateSelector';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { makeGetSharedDivisionsTeam } from '../directory/dashboard-directory-selector';
import { makeGetTeamById, makeGetTeamFetchStatusById } from './dashboard-team-selector';
import { fetchTeamByIdAC } from './dashboard-team-slice';

export function useFetchTeamIfNecessary(teamId?: string) {
  const team = useCreateSelector(makeGetTeamById, teamId);
  const status = useCreateSelector(makeGetTeamFetchStatusById, teamId);
  const sharedDivisionTeam = useCreateSelector(makeGetSharedDivisionsTeam, {teamId});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!teamId) {
      return;
    }
    if (team || sharedDivisionTeam) {
      return;
    }
    if (!status || (!status.fetching && !status.done && !status.error)) {
      dispatch(fetchTeamByIdAC.started({id: teamId}));
    }
  }, [dispatch, status, team, teamId, sharedDivisionTeam]);

  return [team, sharedDivisionTeam, status] as const;
}
