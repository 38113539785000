import { FlexSpacer } from '@insights-gaming/material-components';
import { useBidirectionalFetchStatus } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TooltipProps } from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import TimelineIcon from '@material-ui/icons/Timeline';
import { DirectoryFragment, DirectoryFragment_Division } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import classNames from 'classnames';
import CannotAccess from 'components/cannot-access/CannotAccess';
import { CollapsibleGroup, CollapsibleGroupDetails, CollapsibleGroupSummary } from 'components/collapsible-group/CollapsibleGroup';
import DashboardLayout from 'components/dashboard-layout/DashboardLayout';
import { makeGetGlobalRoles } from 'components/global-roles/global-role-selector';
import GlobalRoleFetcher from 'components/global-roles/GlobalRoleFetcher';
import MemberList from 'components/member-list/MemberList';
import { makeGetTeamNotificationSettingsDictByTeamId } from 'components/settings/notification-settings/notificaion-settings-selector';
import { EIntercomID } from 'constants/strings';
import GuidedSetupDialog from 'features/guided-setup/GuidedSetupDialog';
import LoadingScreen from 'features/loading-screen/LoadingScreen';
import DashboardTeamSearchRouting from 'features/search/dashboard-team-search.routing';
import { makeGetTipsByName } from 'features/tips/tips-selector';
import { FIRST_TIME_DASHBOARD, FIRST_TIME_USER, hideTipsAsyncAC } from 'features/tips/tips-slice';
import UploadQueue from 'features/upload/upload-queue/UploadQueue';
import { getShouldShowUploadQueue } from 'features/upload/upload-selector';
import MemberFetcher from 'fetchers/member-fetcher/MemberFetcher';
import RoleFetcher from 'fetchers/role-fetcher/RoleFetcher';
import { isExistent } from 'helpers';
import { setLocalStorage } from 'helpers/storage';
import { useCreateSelector, useParametricSelectorFactory } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import InviteTeamMembersDialogContent from 'material/dialogs/invite-team-members-dialog-content/InviteTeamMembersDialogContent';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { ETeamDashboardTabType, teamRoute } from 'routes';
import { getMe } from 'selectors/getMe';
import { ID, ITag } from 'types/pigeon';

import { useAccessControl } from './access-control/useAccessControl';
import ContributeToTeamDialog from './contribute-to-team/ContributeToTeamDialog';
import DashboardRouting from './dashboard.routing';
import DashboardDirectoryHeader from './dashboard-header/DashboardDirectoryHeader';
import DashboardTeamHeader from './dashboard-header/DashboardTeamHeader';
import DashboardTeamContent from './DashboardTeamContent';
import { DEFAULT_DIVISION_ID } from './directory/dashboard-directory-constants';
import { makeGetDirectoryFetchStatusByDirectoryId } from './directory/dashboard-directory-selector';
import DashboardDirectoryContent from './directory/DashboardDirectoryContent';
import { useFetchDirectoryIfNecessary } from './directory/useFetchDirectoryIfNecessary';
import { makeGetTeamMemberRecordsByTeamId, makeGetTeamMembersByTeamId } from './member/dashboard-member-selector';
import { DashboardMultiSelectProvider } from './multi-select/DashboardMultiSelectContext';
import { useDashboardMultiSelectContextValue } from './multi-select/useDashboardMultiSelectContext';
import DashboardProductTour from './product-tour/DashboardProductTour';
import { useMultiStepProductTour, useProductTourState } from './product-tour/product-tour-hooks';
import { makeGetTeamCustomRolesByTeamId } from './role/dashboard-role-selector';
import MuteButton from './sidebar/MuteButton';
import Sidebar from './sidebar/Sidebar';
import { DashboardSelectedTagsProvider } from './tag-filter/DashboardSelectedTagsContext';
import { useDashboardTagFilterContext } from './tag-filter/useDashboardSelectedTagsContext';
import DashboardTeamRouting from './team/dashboard-team.routing';
import { getTeams } from './team/dashboard-team-selector';
import DashboardTeamSettingsRouting from './team/dashboard-team-settings.routing';
import DashboardTeamSettingsBillingRouting from './team/dashboard-team-settings-billing.routing';
import { selectTeamAsyncAC } from './team/dashboard-team-slice';
import { TeamHelper } from './team/team-helper';
import { useFetchTeamIfNecessary } from './team/useFetchTeamIfNecessary';
import DashboardTeamStatisticsRouting from './team-statistics/dashboard-team-statistics-routing';

interface DashboardOwnProps {
  className?: string;
  directoryId?: ID;
  teamId?: ID;
}

type DashboardProps = DashboardOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  tabIndicator: {
    borderLeft: `${createRemFromPx(4)} solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(1.5),
  },
  listWrapper: {
    maxHeight: createRemFromPx(400),
    overflow: 'auto',
  },
  sublist: {
    '@global': {
      '.MuiListItem-root': {
        paddingLeft: theme.spacing(4),
      },
    },
    '&$isNotTag': {
      '@global': {
        '.Mui-selected': {
          paddingLeft: theme.spacing(3.5),
          borderLeft: `${createRemFromPx(4)} solid ${theme.palette.primary.main}`,
        },
      },
    },
  },
  tabBorder: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
  fixedTabsWrapper: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
  isNotTag: {},
}), {name: 'Dashboard'});

const placements: Array<TooltipProps['placement']> = ['bottom', 'bottom', 'right'];

function Dashboard(props: DashboardProps) {
  const classes = useStyles(props);

  const { directoryId } = props;
  const [directory, sharedDirectory] = useFetchDirectoryIfNecessary(directoryId);
  const teamId = props.teamId || directory?.team?.id || sharedDirectory?.team.id;
  const [team, sharedDivisionTeam, status] = useFetchTeamIfNecessary(teamId);
  const teams = useSelector(getTeams);
  const me = useSelector(getMe);
  const dispatch = useDispatch();

  const [tagExpanded, setTagExpanded] = useState<boolean>(false);
  const [memberExpanded, setMemberExpanded] = useState<boolean>(false);
  const [lastVisitedDirectory, setLastVisitedDirectory] = useState<DirectoryFragment | null>(null);

  const promiseSagaDispatch = usePromiseSagaDispatch();

  const { t } = useStrictTranslation(['dashboard', 'dashboard-settings']);

  const displayUploadQueue = useSelector(getShouldShowUploadQueue);
  const firstTimeUser = useCreateSelector(makeGetTipsByName, { name: FIRST_TIME_USER });
  const firstTimeDashboard = useCreateSelector(makeGetTipsByName, { name: FIRST_TIME_DASHBOARD });

  const directoryFetchStatus = useCreateSelector(makeGetDirectoryFetchStatusByDirectoryId, directoryId);

  const closeGuidedSetupDialog = useCallback(() => {
    promiseSagaDispatch(hideTipsAsyncAC, { names: [FIRST_TIME_USER] });
  }, [promiseSagaDispatch]);

  const onNavigate = useNavigate();

  const {
    canAggregateOverwatchStats,
    canAccessOverwatchStats,
    canCreateTeamInvitationLink,
    canAccessBilling,
  } = useAccessControl();

  const handleTeamChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target: { value } } = e;
    dispatch(selectTeamAsyncAC.started(value));
    onNavigate(DashboardTeamRouting.createUrl(value));
  }, [dispatch, onNavigate]);

  const settingsRouteMatch = useRouteMatch<DashboardTeamSettingsRouting.Params>({
    path: DashboardTeamSettingsRouting.path,
  });
  const statsRouteMatch = useRouteMatch<DashboardTeamStatisticsRouting.Params>({
    path: DashboardTeamStatisticsRouting.path,
  });

  const isSearchRoute = !!useRouteMatch(DashboardTeamSearchRouting.path);

  const getTeamMemberRecords = useParametricSelectorFactory(
    makeGetTeamMemberRecordsByTeamId,
    team?.id,
  );
  const { forward: membersForwardStatus } = useBidirectionalFetchStatus(getTeamMemberRecords);
  const initialMemberFetch = membersForwardStatus.fetching && !membersForwardStatus.cursor;
  const members = useCreateSelector(makeGetTeamMembersByTeamId, team?.id);
  const customRoles = useCreateSelector(makeGetTeamCustomRolesByTeamId, {teamId: team?.id});
  const globalRoles = useCreateSelector(makeGetGlobalRoles, '');
  const teamSettingState = useCreateSelector(makeGetTeamNotificationSettingsDictByTeamId, team?.id);

  const [isInviteTeamMembersDialogOpen, openInviteTeamMembersDialog, closeInviteTeamMembersDialog] = useDialogState();
  const [isContributeToTeamDialogOpen, openContributeToTeamDialog, closeContributeToTeamDialog] = useDialogState();

  const roles: RoleInterfaceFragment[] = useMemo(() => {
    return [...globalRoles, ...customRoles];
  }, [globalRoles, customRoles]);

  const dashboardMultiSelectValue = useDashboardMultiSelectContextValue();
  const {
    folders: {
      deselectAll: deselectAllFolders,
    },
    videos: {
      deselectAll: deselectAllVideos,
    },
  } = dashboardMultiSelectValue;

  const dashboardTagFilterValue = useDashboardTagFilterContext();
  const {
    tags: {
      deselectAll: deselectAllTags,
    },
  } = dashboardTagFilterValue;

  const handleDivisionClick = useCallback((e: React.MouseEvent, division: DirectoryFragment_Division) => {
    if (division.id === DEFAULT_DIVISION_ID) {
      onNavigate(DashboardRouting.createTeamUrl(division.team.id));
    } else {
      setLastVisitedDirectory(division);
      onNavigate(DashboardRouting.createDirectoryUrl(division.id));
    }
  }, [onNavigate]);

  const actionButtons = useMemo(() => {
    return (
      teamSettingState && team && (
        <MuteButton
        teamSettingState={teamSettingState}
        teamId={team.id}
        />
      )
    );
  }, [team, teamSettingState]);

  const {
    tags: {
      selected: selectedTagIds,
      addToSelection: addToTagSelection,
      removeFromSelection: removeFromTagSelection,
    },
  } = dashboardTagFilterValue;

  const {
    pathname,
    state: { openInvite } = {},
  } = useLocation<{ pathname?: string, openInvite?: boolean }>();

  const handleTagClick = useCallback((e: React.MouseEvent, tag: ITag) => {
    if (
      lastVisitedDirectory &&
      pathname.startsWith(teamRoute(lastVisitedDirectory.team.id, ETeamDashboardTabType.SETTINGS))
    ) {
      onNavigate(DashboardRouting.createDirectoryUrl(lastVisitedDirectory.id));
    }

    if (selectedTagIds.has(tag.id)) {
      removeFromTagSelection([tag.id]);
    } else {
      addToTagSelection([tag.id]);
    }
  }, [addToTagSelection, lastVisitedDirectory, onNavigate, pathname, removeFromTagSelection, selectedTagIds]);

  const handleMemberExpansion = useCallback(() => setMemberExpanded(!memberExpanded), [memberExpanded]);
  const handleTagExpansion = useCallback(() => setTagExpanded(!tagExpanded), [tagExpanded]);

  useEffect(() => {
    deselectAllFolders();
    deselectAllVideos();
    deselectAllTags();
  }, [deselectAllFolders, deselectAllTags, deselectAllVideos, directoryId]);

  useEffect(() => {
    if (openInvite || firstTimeUser) {
      setTagExpanded(true);
      setMemberExpanded(true);
    }
  }, [firstTimeUser, openInvite]);

  useEffect(() => {
    if (openInvite) {
      openInviteTeamMembersDialog();
      onNavigate(window.location.pathname, { replaceUrl: true });
    }
  }, [onNavigate, openInvite, openInviteTeamMembersDialog]);

  useEffect(() => {
    if (directory) {
      if (team?.id !== lastVisitedDirectory?.team.id) {
        setLastVisitedDirectory(directory);
      }
      setLocalStorage('lastVisitedDirectory', directory.id);
    }
  }, [directory, lastVisitedDirectory, team]);

  const [ anchors, refHandlers ] = useMultiStepProductTour(3);

  const [ captureRefHandler, uploadRefHandler, membersRefHandler ] = refHandlers;

  const [
    isDashboardProductTourOpen,
    openDashboardProductTour,
    closeDashboardProductTour,
  ] = useProductTourState(FIRST_TIME_DASHBOARD);

  const handleCloseDashboardProductTour = useCallback(() => {
    promiseSagaDispatch(hideTipsAsyncAC, { names: [FIRST_TIME_DASHBOARD] });
    closeDashboardProductTour();
  }, [closeDashboardProductTour, promiseSagaDispatch]);

  useEffect(() => {
    if (
      firstTimeDashboard &&
      !(
        firstTimeUser &&
        teams.length === 1 &&
        (
          team?.name === 'My First Team' ||
          team?.name === `${me?.alias}'s Team`
        )
      )
    ) {
      openDashboardProductTour();
    }
  }, [firstTimeDashboard, firstTimeUser, me?.alias, openDashboardProductTour, team?.name, teams.length]);

  if (!team && !sharedDivisionTeam && !status?.fetching) {
    if (directoryFetchStatus?.fetching) {
      return (
        <LoadingScreen />
      );
    }
    return (
      <CannotAccess>
        {t('dashboard:noaccess.reason1')}
        {t('dashboard:noaccess.reason2')}
        {t('dashboard:noaccess.reason3')}
        {t('dashboard:noaccess.reason4')}
      </CannotAccess>
    );
  }

  const hasDefaultTeam = teams.length === 1 && (team?.name === 'My First Team' || team?.name === `${me?.alias}'s Team`);

  const actualTeam = team || sharedDivisionTeam;

  return (
    <DashboardMultiSelectProvider value={dashboardMultiSelectValue}>
      <DashboardSelectedTagsProvider value={dashboardTagFilterValue}>
        <DashboardLayout
        className={classNames(classes.root, props.className)}
        sidebar={actualTeam && !isSearchRoute ? (
          <React.Fragment>
            <Sidebar
            team={actualTeam}
            onDivisionClick={handleDivisionClick}
            directoryId={directoryId}
            onTeamChange={handleTeamChange}
            actionButtons={actionButtons}
            teamPicker={true}
            selectedTagIds={selectedTagIds}
            onTagClick={handleTagClick}
            tagExpanded={tagExpanded}
            handleTagExpansion={handleTagExpansion}
            >
              {(canAccessOverwatchStats && canAggregateOverwatchStats) && (
                <ListItem
                classes={{selected: classes.tabIndicator}}
                id={EIntercomID.OVERWATCH_STATISTICS}
                button={true}
                component={Link}
                selected={!!statsRouteMatch?.params.tab}
                to={DashboardTeamStatisticsRouting.createUrl(
                  actualTeam.id,
                  DashboardTeamStatisticsRouting.Game.OVERWATCH,
                  DashboardTeamStatisticsRouting.Tab.PLAYERS,
                )}
                >
                  <FlexSpacer flexAlignItems='center'>
                    <TimelineIcon />
                    <ListItemText primary={t('dashboard:team.games/overwatch/statistics.title')} />
                  </FlexSpacer>
                </ListItem>
              )}
              {TeamHelper.isTeamFragment(actualTeam) && (
                <CollapsibleGroup
                id={EIntercomID.MEMBER_SELECTOR}
                ref={membersRefHandler}
                expanded={memberExpanded}
                >
                  <CollapsibleGroupSummary expandIcon={<ExpandMoreIcon />} onClick={handleMemberExpansion}>
                    <FlexSpacer flexAlignItems='center'>
                      <PeopleAltIcon />
                      <Typography>
                        {t('dashboard:team.settings.members')} ({members.length})
                      </Typography>
                    </FlexSpacer>
                  </CollapsibleGroupSummary>
                  <CollapsibleGroupDetails className={classes.listWrapper}>
                    {canCreateTeamInvitationLink && (
                      <List id={EIntercomID.ADD_MEMBERS} disablePadding={true} className={classes.sublist}>
                        <ListItem button={true} onClick={openInviteTeamMembersDialog}>
                          <ListItemIcon>
                            <AddIcon />
                          </ListItemIcon>
                          <ListItemText primary={t('dashboard-settings:members.addmember')} />
                        </ListItem>
                      </List>
                    )}
                    <MemberList
                    team={actualTeam}
                    members={members}
                    roles={roles}
                    displaySkeleton={initialMemberFetch}
                    className={classes.sublist}
                    />
                  </CollapsibleGroupDetails>
                </CollapsibleGroup>
              )}
              <Dialog open={isInviteTeamMembersDialogOpen} onClose={closeInviteTeamMembersDialog} fullWidth={true}>
                <InviteTeamMembersDialogContent
                teamId={actualTeam.id}
                onClose={closeInviteTeamMembersDialog}
                />
              </Dialog>
              {TeamHelper.isTeamFragment(actualTeam) && (
                <ContributeToTeamDialog
                open={isContributeToTeamDialogOpen}
                onClose={closeContributeToTeamDialog}
                team={actualTeam}
                />
              )}
            </Sidebar>
            <div className={classes.fixedTabsWrapper}>
              {TeamHelper.isTeamFragment(actualTeam) && (
                <ListItem
                className={classes.tabBorder}
                classes={{selected: classes.tabIndicator}}
                id={EIntercomID.SETTING_SELECTOR}
                button={true}
                component={Link}
                selected={
                      settingsRouteMatch?.params.tab === DashboardTeamSettingsRouting.Tab.OVERVIEW ||
                      settingsRouteMatch?.params.tab === DashboardTeamSettingsRouting.Tab.MEMBERS ||
                      settingsRouteMatch?.params.tab === DashboardTeamSettingsRouting.Tab.ROLES
                    }
                to={DashboardTeamSettingsRouting.createUrl(actualTeam.id, DashboardTeamSettingsRouting.Tab.OVERVIEW)}
                >
                  <FlexSpacer flexAlignItems='center'>
                    <SettingsIcon />
                    <ListItemText primary={t('dashboard:team.settings.title')} />
                  </FlexSpacer>
                </ListItem>
              )}
              {canAccessBilling &&
                <ListItem
                className={classes.tabBorder}
                classes={{selected: classes.tabIndicator}}
                id={EIntercomID.SETTING_BILLING}
                button={true}
                component={Link}
                to={
                      DashboardTeamSettingsBillingRouting.createUrl(
                      actualTeam.id,
                      DashboardTeamSettingsBillingRouting.BillingTab.OVERVIEW)
                    }
                selected={settingsRouteMatch?.params.tab === DashboardTeamSettingsRouting.Tab.BILLING}
                >
                  <FlexSpacer flexAlignItems='center'>
                    <PaymentIcon />
                    <ListItemText primary={t('dashboard:team.settings.billing')} />
                  </FlexSpacer>
                </ListItem>
              }
            </div>
          </React.Fragment>
        ) : undefined}
        header={directoryId ? (
          <DashboardDirectoryHeader
          directoryId={directoryId}
          team={team}
          captureRefHandler={captureRefHandler}
          uploadRefHandler={uploadRefHandler}
          />
        ) : teamId ? (
          <DashboardTeamHeader team={team}/>
        ) : undefined}
        content={directoryId ? (
          <DashboardDirectoryContent directoryId={directoryId} />
        ) : teamId ? (
          <DashboardTeamContent teamId={teamId} />
        ) : undefined}
        />
        {team && (
          <React.Fragment>
            <MemberFetcher teamId={team.id} />
            <RoleFetcher teamId={team.id} />
          </React.Fragment>
        )}
        <GlobalRoleFetcher />
        {displayUploadQueue && (
          <UploadQueue/>
        )}
        {directory && (
          <GuidedSetupDialog
          open={Boolean(firstTimeUser && hasDefaultTeam)}
          onClose={closeGuidedSetupDialog}
          directory={directory}
          />
        )}
        {anchors.every(isExistent) && !(firstTimeUser && hasDefaultTeam) && (
          <DashboardProductTour
          open={isDashboardProductTourOpen}
          anchors={anchors}
          placements={placements}
          onClose={handleCloseDashboardProductTour}
          />
        )}
      </DashboardSelectedTagsProvider>
    </DashboardMultiSelectProvider>
  );
}

export default React.memo(Dashboard);
