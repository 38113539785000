import { DrawingCanvasEvent, DrawingTool, SerializedCanvas, SerializedObject } from '@insights-gaming/drawing-canvas';
import { CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import { EventEmitter } from 'events';
import useAddListener from 'hooks/useAddListener';
import { KeyCommand } from 'keybindings';
import { useCallback, useEffect, useMemo,useState } from 'react';

import { DrawingOverlayProps } from './DrawingOverlay';
import useDrawingOverlayController, { EToolSize } from './useDrawingOverlayController';

const ctlr = Symbol('controller');
const internal = Symbol('internal');

interface InternalDrawingState {
  setCanUndo(canUndo: boolean): void;
  setCanRedo(canRedo: boolean): void;
  setHasObjects(hasObjects: boolean): void;
  setReady(ready: boolean): void;
}

export interface DrawingState {
  readonly canUndo: boolean;
  readonly canRedo: boolean;
  readonly hasObjects: boolean;
  readonly enabled: boolean;
  readonly ready: boolean;

  readonly tool: DrawingTool;
  setTool(tool: DrawingTool): void;

  readonly color: string;
  setColor(color: string): void;

  readonly size: number;
  setSize(size: number): void;

  undo(): void;
  redo(): void;
  clear(): void;
  reset(): void;

  deselectAll(): void;
  disable(): void;

  addSticker(url: string, n: number, imagekey: string): void;
  setImageUnderlay(url: string, imagekey: string): void;
  renderPing(x: number, y: number, color?: string): void;

  silentlyAddObjects(objects: SerializedObject[]): void;
  silentlyModifyObjects(objects: SerializedObject[]): void;
  silentlyRemoveObjects(uuids: string[]): void;
  silentlyClearCanvas(): void;

  loadJSON(canvas: SerializedCanvas): void;
  serializedCanvas(): Promise<SerializedCanvas>;
}

export interface UseDrawingStateOptions {
  initialTool?: DrawingTool;
  initialColor?: string;
  initialSize?: number;
  commandEmitter?: EventEmitter;
}

export function useDrawingState({
  initialTool = DrawingTool.NONE,
  initialColor = '#FF0000',
  initialSize = EToolSize.MEDIUM,
  commandEmitter,
}: UseDrawingStateOptions = {}): DrawingState {
  const controller = useDrawingOverlayController();
  const [tool, setTool] = useState<DrawingTool>(initialTool);
  const [color, setColor] = useState<string>(initialColor);
  const [size, setSize] = useState<number>(initialSize);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [hasObjects, setHasObjects] = useState(false);
  const [ready, setReady] = useState(false);
  const enabled = useMemo(() => tool !== DrawingTool.NONE, [tool]);

  useEffect(() => controller.setTool(tool), [controller, tool]);
  useEffect(() => controller.setColor(color), [controller, color]);
  useEffect(() => controller.setSize(size), [controller, size]);

  useAddListener(commandEmitter, 'command', useCallback((event: KeyCommand) => {
    switch (event) {
      case 'drawing.tool.pen': return setTool(DrawingTool.PEN);
      case 'drawing.tool.eraser': return setTool(DrawingTool.ERASER);
      case 'drawing.tool.select': return setTool(DrawingTool.SELECT);
      case 'drawing.tool.rectangle': return setTool(DrawingTool.RECTANGLE);
      case 'drawing.tool.ellipse': return setTool(DrawingTool.ELLIPSE);
      case 'drawing.tool.line': return setTool(DrawingTool.LINE);
      case 'drawing.tool.arrow': return setTool(DrawingTool.ARROW);
      case 'drawing.tool.textbox': return setTool(DrawingTool.TEXTBOX);
      case 'drawing.tool.toggle': return setTool((currentTool) => {
        if (currentTool === DrawingTool.NONE) {
          return DrawingTool.DEFAULT;
        }

        controller.reset();
        setHasObjects(false);
        setCanUndo(false);
        setCanRedo(false);
        return DrawingTool.NONE;
      });
      case 'drawing.tool.delete': return controller.clear();
      case 'drawing.tool.undo': return controller.undo();
      case 'drawing.tool.redo': return controller.redo();
    }
  }, [controller]));

  const state = useMemo(() => ({
    canUndo,
    canRedo,
    hasObjects,
    enabled,
    tool,
    setTool,
    color,
    setColor,
    size,
    setSize,
    ready,
    [internal]: {
      setCanUndo,
      setCanRedo,
      setHasObjects,
      setReady,
    },
  }), [canRedo, canUndo, color, enabled, hasObjects, ready, size, tool]);

  const freeMutators = useMemo(() => ({
    undo: controller.undo,
    redo: controller.redo,
    clear: controller.clear,
    deselectAll: controller.deselectAll,
    addSticker: controller.addSticker,
    setImageUnderlay: controller.setImageUnderlay,
    renderPing: controller.renderPing,
    silentlyAddObjects: controller.silentlyReplaceObjects,
    silentlyModifyObjects: controller.silentlyReplaceObjects,
    silentlyRemoveObjects: controller.silentlyRemoveObjects,
    silentlyClearCanvas: controller.silentlyClearCanvas,
    [ctlr]: controller,
  }), [controller]);

  const reset = useCallback(() => {
    controller.reset();
    setHasObjects(false);
    setCanUndo(false);
    setCanRedo(false);
  }, [controller]);

  const mutators = useMemo(() => ({
    reset,
    loadJSON(canvas: SerializedCanvas): void {
      controller.import(canvas);
      setHasObjects(canvas.objects.length > 0);
    },
    serializedCanvas(): Promise<SerializedCanvas> {
      return controller.export();
    },
    disable(): void {
      reset();
      setTool(DrawingTool.NONE);
    },
  }), [controller, reset]);

  return useMemo(() => Object.assign(state, freeMutators, mutators), [state, freeMutators, mutators]);
}

type ObjectEvents =
| 'onObjectsAdded'
| 'onObjectsModified'
| 'onObjectsRemoved'

type BindOverlayProps =
  | ObjectEvents
  | 'state'
  | 'controller'
  | 'onUndo'
  | 'onRedo'
  | 'onCanvasLoaded'
  | 'onObjectsReplacedSilently'
  | 'onObjectsRemovedSilently'
  | 'onReadyStateChange';

type BindOverlayOptions = Pick<DrawingOverlayProps, ObjectEvents>;

function combineHandlers(
  ...handlers: Array<((e: DrawingCanvasEvent) => void) | undefined>
): (e: DrawingCanvasEvent) => void {
  return (e: DrawingCanvasEvent): void => {
    for (const handler of handlers) {
      handler?.(e);
    }
  };
}

export function useDrawingToolContextValue(options?: UseDrawingStateOptions) {
  const drawingState = useDrawingState(options);

  // wrap this so clicking the same comment works
  const [viewedComment, setViewedComment] = useState<{comment: CommentFragment_VideoComment}>();

  const resetTool = useCallback(() => {
    drawingState.disable();
    setViewedComment(undefined);
  }, [drawingState]);

  return useMemo(() => ({
    drawingState,
    viewedComment,
    setViewedComment,
    resetCanvas: drawingState.reset,
    resetTool,
  }), [drawingState, resetTool, viewedComment]);
}

export function bindDrawingOverlay(
  state: DrawingState,
  {
    onObjectsAdded,
    onObjectsModified,
    onObjectsRemoved,
  }: BindOverlayOptions = {},
): Pick<DrawingOverlayProps, BindOverlayProps> {
  const internalState: InternalDrawingState = state[internal];
  const updateUndoRedo = (e: DrawingCanvasEvent): void => {
    if (e.canvas.canUndo !== state.canUndo) {
      internalState.setCanUndo(e.canvas.canUndo);
    }
    if (e.canvas.canRedo !== state.canRedo) {
      internalState.setCanRedo(e.canvas.canRedo);
    }
  };

  const updateState = (e: DrawingCanvasEvent): void => {
    updateUndoRedo(e);

    if ((e.canvas.objects.length > 0) !== state.hasObjects) {
      internalState.setHasObjects(!state.hasObjects);
    }
  };

  return {
    state,
    controller: state[ctlr],
    onObjectsAdded: combineHandlers(updateState, onObjectsAdded),
    onObjectsModified: combineHandlers(updateState, onObjectsModified),
    onObjectsRemoved: combineHandlers(updateState, onObjectsRemoved),
    onObjectsReplacedSilently: updateState,
    onObjectsRemovedSilently: updateState,
    onCanvasLoaded: updateState,
    onUndo: updateUndoRedo,
    onRedo: updateUndoRedo,
    onReadyStateChange: internalState.setReady,
  };
}
