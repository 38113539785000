import { FlexSpacer } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { makeGetTeamCommentLabelsByTeamId } from 'features/dashboard/tag/dashboard-tag-selector';
import { useFetchTeamTags } from 'features/dashboard/tag/useFetchTeamTags';
import { mobilePortrait } from 'features/media-queries';
import { isExistent } from 'helpers';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useMemo } from 'react';
import { SearchResultType } from 'types/graphql';
import { ID } from 'types/pigeon';

import FilterDateButton from './FilterDateButton';
import { makeGetTeamSearchQueryByTeamId } from './search-selectors';
import { ESearchFilterDateOptions } from './search-slice';
import SearchLabelButtonWrapper from './SearchLabelButtonWrapper';
import { useSearchHandlers } from './useSearchHandlers';

interface MobileSearchFilterOwnProps {
  className?: string;
  teamId: ID;
}

type MobileSearchFilterProps = MobileSearchFilterOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    gridGap: theme.spacing(1),
    width: '100%',
    marginTop: theme.spacing(0.5),
  },
  selectedLabelsWrapper: {
    overflow: 'hidden',
  },
  selectedLabels: {
    height: 'inherit',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  filter: {
    flexShrink: 0,
  },
  filterBy: {
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: 0,
    paddingRight: 0,
  },
  filterPopover: {
    width: '100%',
    minHeight: createRemFromPx(200),
    borderRadius: theme.shape.borderRadius,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dateFilter: {
    '& > div > button': {
      width: createRemFromPx(120),
    },
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  labelButton: {
    margin: theme.spacing(0.5),
    [mobilePortrait(theme)]: {
      '&:not($selected) > div > button': {
        paddingRight: theme.spacing(0.5),
      },
    },
  },
  clearAllButton: {
    fontSize: '0.7rem',
  },
  selected: {},
}), {name: 'MobileSearchFilter'});

function MobileSearchFilter(props: MobileSearchFilterProps) {
  const classes = useStyles(props);
  const { className, teamId } = props;

  const { t } = useStrictTranslation(['common', 'search']);

  const popupState = usePopupState({popupId: 'search-filter', variant: 'popover'});

  useFetchTeamTags(teamId);

  const teamSearchQuery = useCreateSelector(makeGetTeamSearchQueryByTeamId, teamId);

  const commentLabelTags = useCreateSelector(makeGetTeamCommentLabelsByTeamId, teamId);

  const {
    addTagOnClick,
    removeTagOnClick,
    clearAllSelectedTags,
    handleFilterDateOnChange,
  } = useSearchHandlers(teamId);

  const allTags = useMemo(() => {
    if (!teamSearchQuery) {
      return [];
    }
    const { videoCommentTagIds } = teamSearchQuery;
    const tagDict = Object.fromEntries(commentLabelTags.map(t => [t.id, t]));

    return videoCommentTagIds.map(
      ({ tagId }) => tagId in tagDict ? tagDict[tagId] : undefined,
    ).filter(isExistent);
  }, [commentLabelTags, teamSearchQuery]);

  return teamSearchQuery && teamSearchQuery.tab === SearchResultType.COMMENT ? (
    <React.Fragment>
      <Box className={classNames(classes.root, className)}>
        <FlexSpacer className={classes.selectedLabelsWrapper} fullWidth={true} flexJustifyContent='flex-start'>
          <FlexSpacer className={classes.selectedLabels} flexAlignItems='center'>
            {allTags.map((t) => {
              return teamSearchQuery?.params?.tagIds?.includes(t.id) && (
                <SearchLabelButtonWrapper
                key={t.id}
                className={classNames(
                  classes.labelButton,
                  {[classes.selected]: teamSearchQuery?.params?.tagIds?.includes(t.id)},
                )}
                addTagOnClick={addTagOnClick}
                removeTagOnClick={removeTagOnClick}
                included={true}
                tag={t}
                />
              );
            })}
          </FlexSpacer>
          {Object.values(teamSearchQuery?.params.tagIds || []).length > 0 && (
            <Button
            className={classes.clearAllButton}
            onClick={clearAllSelectedTags}
            size='small'
            >
              {t('search:mobilesearchfilter.clear')}
            </Button>
          )}
        </FlexSpacer>
        <FlexSpacer flexAlignItems='center' flexJustifyContent='flex-end'>
          <Divider orientation='vertical' />
          <Button
          className={classes.filterBy}
          {...bindTrigger(popupState)}
          endIcon={popupState.isOpen ? <ArrowDropUpIcon fontSize='small' /> : <ArrowDropDownIcon fontSize='small' />}
          >
            <Typography variant='caption'>
              {t('search:filterby')}
            </Typography>
          </Button>
        </FlexSpacer>
      </Box>
      <Popover
      classes={{ paper: classes.filterPopover}}
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      >
        <Accordion defaultExpanded={true}>
          <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          >
            <Typography>{t('search:mobilesearchfilter.date')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FlexSpacer fullWidth={true} spacing={0.5}>
              {Object.keys(ESearchFilterDateOptions)
                .map((k: string) => ESearchFilterDateOptions[k])
                .filter(e => typeof e === 'number').map((option: number) => (
                  <FilterDateButton
                  key={option}
                  teamId={teamId}
                  option={option}
                  />
              ))}
            </FlexSpacer>
          </AccordionDetails>
        </Accordion>
        {!!allTags.length && (
          <React.Fragment>
            <Divider variant='middle'/>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              >
                <Typography>{t('search:mobilesearchfilter.commentlabels')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.tags}>
                  {allTags.map((t) => {
                    return (
                      <SearchLabelButtonWrapper
                      key={t.id}
                      className={classNames(
                        classes.labelButton,
                        {[classes.selected]: teamSearchQuery?.params?.tagIds?.includes(t.id)},
                      )}
                      addTagOnClick={addTagOnClick}
                      removeTagOnClick={removeTagOnClick}
                      included={teamSearchQuery?.params?.tagIds?.includes(t.id)}
                      tag={t}
                      />
                    );
                  })}
                  {Object.values(teamSearchQuery?.params.tagIds || []).length > 0 && (
                    <Button
                    className={classes.clearAllButton}
                    onClick={clearAllSelectedTags}
                    size='small'
                    >
                      {t('search:mobilesearchfilter.clear')}
                    </Button>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </React.Fragment>
        )}
      </Popover>
    </React.Fragment>
  ) : (
    <FlexSpacer flexAlignItems='center' className={classes.filter} flexJustifyContent='flex-end' fullWidth={true}>
      <Typography variant='caption'>
        {t('search:filterby')}
      </Typography>
      <Select
      value={teamSearchQuery?.filterDatePreset || 0}
      onChange={handleFilterDateOnChange}
      >
        {Object.keys(ESearchFilterDateOptions)
          .map((k: string) => ESearchFilterDateOptions[k])
          .filter(e => typeof e === 'number').map((option: number) => (
            <MenuItem
            key={option}
            value={option}
            >
              {t(option === 0 ? 'search:everything' : 'search:lastcountdays', { count: option })}
            </MenuItem>
          ))}
      </Select>
    </FlexSpacer>
  );
}

const Accordion = withStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    '&$expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    minHeight: theme.spacing(6),
    '&$expanded': {
      minHeight: theme.spacing(6),
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme: Theme) => ({
  root: {},
}))(MuiAccordionDetails);


export default React.memo(MobileSearchFilter);
