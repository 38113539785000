import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

type BackgroundImageProps = React.ComponentProps<'div'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(\'/login.png\')',
    backgroundPosition: 'center',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}), { name: 'BackgroundImage' });

function BackgroundImage({ className, children, ...props }: BackgroundImageProps) {
  const classes = useStyles();
  return React.createElement('div', { ...props, className: classNames(classes.root, className)}, children);
}

export default React.memo(BackgroundImage);
