import { push, replace } from 'connected-react-router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type OnNavigate<S extends LocationState = LocationState> = (path: string, config?: OnNavigateConfig<S>) => void;

interface LocationState {
  referPath?: string;
}

interface OnNavigateConfig<S extends LocationState = LocationState> {
  replaceUrl?: boolean;
  state?: S;
}

function useNavigate<S = {}>(): OnNavigate<S & LocationState> {
  const dispatch = useDispatch();
  const navigate = useCallback((path: string, {replaceUrl, state}: OnNavigateConfig<S & LocationState> = {}) => {
    if (!state) {
      state = {} as any as S & LocationState;
    }

    if (!state.referPath) {
      state.referPath = window.location.pathname + window.location.search;
    }

    const action = replaceUrl ? replace(path, state) : push(path, state);
    return dispatch(action);
  }, [dispatch]);
  return navigate;
}

export { useNavigate };
