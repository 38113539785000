import { AsyncButton, EnhancedDialogTitle } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { clearTeamBillingStateAC, retryTeamSubscriptionAC } from 'features/dashboard/billing/dashboard-billing-slice';
import useFetchTeamCards from 'features/dashboard/billing/useFetchTeamCards';
import { useDialogState } from 'hooks/useDialogState';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import PaymentCardSelector from '../PaymentMethod/PaymentCardSelector';
import { usePaymentCardSelectorHandlers } from '../PaymentMethod/usePaymentCardSelectorHandlers';
import { useSubscriptionMutationResultHandler } from '../useSubscriptionMutationResultHandler';

export interface SelectPaymentMethodButtonOwnProps {
  teamId: ID;
};

export type SelectPaymentMethodButtonProps = SelectPaymentMethodButtonOwnProps;

export default function SelectPaymentMethodButton(props: SelectPaymentMethodButtonProps) {
  const {
    teamId,
  } = props;

  const [ teamCards, teamCardsFetched ] = useFetchTeamCards(teamId);

  const [ loading, setLoading ] = useState(false);

  const [isOpen, openDialog, closeDialog] = useDialogState();

  const dispatch = useDispatch();

  const promiseSagaDispatch = usePromiseSagaDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useStrictTranslation(['dashboard']);

  const onSuccess = useCallback(() => {
    dispatch(clearTeamBillingStateAC({ teamId }));
    closeDialog();
  }, [closeDialog, dispatch, teamId]);

  const onError = useCallback((err?: { message?: string }) => {
    dispatch(clearTeamBillingStateAC({ teamId }));
    enqueueSnackbar(err?.message ?? 'failed to authorize payment', { variant: 'error' });
  }, [dispatch, enqueueSnackbar, teamId]);

  const handleSubscriptionMutationResult = useSubscriptionMutationResultHandler(teamId, onSuccess, onError);

  const {
    selectedCard,
    stagedCard,
    handleChangeCard,
    renderMobileCardDetails,
    renderMobileAddCard,
    renderTail,
    renderEnd,
    removeCardDialog,
    showInputs,
    handleEnableShowInputs,
    disableShowInputs,
  } = usePaymentCardSelectorHandlers(teamId, teamCards);

  const handleSubmit = useCallback(async (paymentId?: string | object) => {
    paymentId = (typeof paymentId === 'object' ? undefined : paymentId) ?? stagedCard?.id ?? selectedCard?.id;
    if (!paymentId) {
      return;
    }

    setLoading(true);
    promiseSagaDispatch(retryTeamSubscriptionAC, { teamId, paymentId })
      .then(handleSubscriptionMutationResult)
      .catch((error: Error) => {
        if (error) {
          enqueueSnackbar(error.message, {variant: 'error'});
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    handleSubscriptionMutationResult,
    promiseSagaDispatch,
    selectedCard?.id,
    stagedCard?.id,
    teamId,
    enqueueSnackbar,
  ]);

  return (
    <React.Fragment>
      <Button
      type='submit'
      color='primary'
      disabled={loading}
      variant='contained'
      onClick={openDialog}
      >
        {t('dashboard:billing.retrypayment')}
      </Button>
      <Dialog open={isOpen} fullWidth={true} onClose={loading ? undefined : closeDialog} maxWidth='md'>
        <EnhancedDialogTitle onClose={loading ? undefined : closeDialog}>
          {t('dashboard:billing.retrypayment')}
        </EnhancedDialogTitle>
        <DialogContent>
          <PaymentCardSelector
          cards={teamCards || []}
          card={stagedCard ?? selectedCard}
          onChange={handleChangeCard}
          renderMobileCardDetails={renderMobileCardDetails}
          renderMobileAddCard={renderMobileAddCard}
          renderTail={renderTail}
          renderEnd={renderEnd}
          showInputs={showInputs}
          handleEnableShowInputs={handleEnableShowInputs}
          onAddCard={handleSubmit}
          disableShowInputs={disableShowInputs}
          cardFormButtonText={t('dashboard:billing.paynow')}
          />
        </DialogContent>
        {!showInputs && (
          <DialogActions>
            <AsyncButton
            variant='contained'
            color='primary'
            disabled={(!stagedCard && !selectedCard) || loading}
            onClick={handleSubmit}
            loading={loading}
            >
              {t('dashboard:billing.paynow')}
            </AsyncButton>
          </DialogActions>
        )}
      </Dialog>
      {removeCardDialog()}
    </React.Fragment>
  );
}
