import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetTeamInvitationRecordsByTeamId } from './dashboard-invitation.selector';
import { fetchTeamInvitationsAC } from './dashboard-invitation-slice';

export const useFetchTeamInvitations = createBidirectionalAutoFetcher({
  selectorFactory: makeGetTeamInvitationRecordsByTeamId,
  actionCreators: fetchTeamInvitationsAC,
  paramsTransform: fetchId => ({teamId: fetchId}),
  pageSize: 10,
});
