import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { useNavigate } from 'hooks/useNavigate';
import TeamAvatar from 'material/team-avatar/TeamAvatar';
import React, { useCallback } from 'react';
import { ETeamSettingsTabType, teamSettingsRoute } from 'routes';

interface UndeletableTeamSelectorOwnProps {
  className?: string;
  team: TeamFragment;
  members?: boolean;
  subscription?: boolean;
}

type UndeletableTeamSelectorProps = UndeletableTeamSelectorOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.note,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    cursor: 'pointer',
  },
  teamName: {
    marginLeft: theme.spacing(3),
  },
  forwardIcon: {
    marginLeft: 'auto',
  },
}), {name: 'UndeletableTeamSelector'});

function UndeletableTeamSelector(props: UndeletableTeamSelectorProps) {
  const classes = useStyles(props);
  const { className, team, members, subscription } = props;

  const onNavigate = useNavigate();

  const redirectToTeamMembers = useCallback(() => {
    if (!members && subscription) {
      onNavigate(teamSettingsRoute(team.id, ETeamSettingsTabType.BILLING));
    } else {
      onNavigate(teamSettingsRoute(team.id, ETeamSettingsTabType.OVERVIEW));
    }
  }, [onNavigate, team.id, members, subscription]);

  return (
    <div className={classNames(classes.root, className)} onClick={redirectToTeamMembers}>
      <TeamAvatar team={team} size='sm'/>
      <Typography className={classes.teamName}>
        {team.name}{!members && subscription ? '*' : ''}
      </Typography>
      <ArrowForwardIosIcon className={classes.forwardIcon}/>
    </div>
  );
}

export default React.memo(UndeletableTeamSelector);
