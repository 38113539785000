import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';
import { SearchCommentQueryVariables } from 'apollo/queries/types/SearchCommentQuery';
import React from 'react';

import { makeGetVideoSearchRecordByTeamId } from './search-selectors';
import { fetchCommentSearchResultsAC } from './search-slice';

export const useFetchVideoSearchResults = createBidirectionalAutoFetcher({
  actionCreators: fetchCommentSearchResultsAC,
  selectorFactory: makeGetVideoSearchRecordByTeamId,
  createFetchId: (params) => params.teamId,
  pageSize: 120,
});

function VideoSearchFetcher(props: { query: Omit<SearchCommentQueryVariables, 'after' | 'before' | 'limit'> }) {
  useFetchVideoSearchResults(props.query);
  return null;
}

export default React.memo(VideoSearchFetcher);
