import React from 'react';
import { ID } from 'types/pigeon';

import { useFetchVideoBookmarks } from './useFetchVideoBookmarks';

interface IBookmarkFetcherOwnProps {
  videoId: ID;
}

type Props = IBookmarkFetcherOwnProps;

function BookmarkFetcher({ videoId }: Props) {
  useFetchVideoBookmarks({ videoId });
  return null;
}

export default React.memo(BookmarkFetcher);
