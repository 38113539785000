import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import last from 'lodash/last';
import React from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';

import { combineIds } from '../../../../helpers';
import HeroImage from '../../../../subcomponents/hero-image/HeroImage';
import { Optional } from '../../../../types';
import { GeneratedOverwatchMatch } from '../../../../types/pigeon/matches';
import {
  IOverwatchMatchDataPlayer,
  IOverwatchMatchDataPlayerHero,
  IOverwatchMatchDataUltimate,
} from '../../../../types/pigeon/overwatch-match-data';

export interface IMatchTeamsOwnProps {
  time: number;
  match: GeneratedOverwatchMatch;
  onPlayerClick?: (index: number, team: string) => void;
}
export type IMatchTeamsProps = IMatchTeamsOwnProps &
  WithStyles<typeof styles> &
  WithTranslation;

const styles = (theme: Theme) => {
  return createStyles({
    matchTeams: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    team: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    label: {
      display: 'block',
    },
    playerName: {
      fontSize: '12px',
      display: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });
};

class MatchTeams extends React.PureComponent<IMatchTeamsProps> {
  public render() {
    const { match, classes } = this.props;
    const renderedPlayers = match.players.map(this.renderPlayer);
    return (
      <div className={classes.matchTeams}>
        {this.renderTeam(renderedPlayers.slice(0, 6))}
        {this.renderTeam(renderedPlayers.slice(6))}
      </div>
    );
  }

  private renderTeam = (players: React.ReactNode[]) => {
    const { classes } = this.props;
    return (
      <div className={classes.team}>
        {players}
      </div>
    );
  }

  private renderPlayer = (player: Optional<IOverwatchMatchDataPlayer>, i: number) => {
    const { time, match, classes } = this.props;
    const { deaths, ultimates, statuses } = match;
    if (!player) { return null; }
    const heroes = player.heroes.filter((hero: IOverwatchMatchDataPlayerHero) => hero.name !== 'none');
    const heroToDisplay = heroes.filter((hero: IOverwatchMatchDataPlayerHero) => hero.startTime <= time);
    const status = statuses && statuses.find(s => time >= s.startTime && time < s.startTime + 1 && i === s.index);
    const heroName = heroToDisplay.length <= 0
      ? undefined
      : heroToDisplay[heroToDisplay.length - 1].name;
    return (
      <Button
      id={`${i},${heroToDisplay && last(heroToDisplay) && last(heroToDisplay)!.name}`}
      key={combineIds(player.name, i)}
      onClick={this.handleHeroClicked}
      classes={{ label: classes.label }}
      >
        <HeroImage
        hero={heroName}
        team={i < 6 ? 'blue' : 'red'}
        dead={deaths && !!deaths.find(d => time >= d.startTime && time < d.endTime && i === d.index)}
        ultimate={ultimates && ultimates[i].ults.reduce(this.newestUlt).status}
        status={status ? status.status as any : undefined}
        />
        <span title={player.name} className={classes.playerName}>
          {player.name}
        </span>
      </Button>
    );
  }

  private handleHeroClicked = (e: React.SyntheticEvent) => {
    const { onPlayerClick } = this.props;
    const idAttributes = e.currentTarget.id.split(',');
    const index = +idAttributes[0];
    const team = index < 6 ? 'blue' : 'red';
    onPlayerClick?.(index, team);
  }

  private newestUlt = (prev: IOverwatchMatchDataUltimate, cur: IOverwatchMatchDataUltimate) => {
    const { time } = this.props;
    return cur.startTime <= time ? cur : prev;
  }
}

export default withTranslation()(withStyles(styles)(MatchTeams));
