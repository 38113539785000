import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { ChangeEvent, useCallback, useContext, useMemo } from 'react';
import { CouponType } from 'types/graphql';

import { CustomCouponCodeContext } from './CustomCouponCodeContext';

interface CouponInputOwnProps {
  className?: string;
}

type CouponInputProps = CouponInputOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}), { name: 'CouponInput' });

function CouponInput(props: CouponInputProps) {
  const classes = useStyles(props);
  const { className } = props;

  const { t } = useStrictTranslation(['dashboard']);

  const {
    handleApplyClick,
    setCouponCode,
    couponCode,
    error,
    loading,
    coupon,
  } = useContext(CustomCouponCodeContext);

  const handleCouponCodeChange = useCallback(({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
    const trimmed = value.trim();
    setCouponCode(trimmed);
  }, [setCouponCode]);

  const couponAmountText = useMemo(() => {
    if (coupon) {
      if (coupon.type === CouponType.PERCENT) {
        return `${coupon.amount}%`;
      }
      if (coupon.type === CouponType.FIXED) {
        return `$${coupon.amount}`;
      }
    }
    return '';
  }, [coupon]);

  return (
    <FlexSpacer className={classNames(className, classes.root)} orientation='vertical' spacing={0}>
      <FlexSpacer>
        <FormControl>
          <FormLabel>{t('dashboard:billing.couponcode')}</FormLabel>
          <TextField
          id='couponCode'
          size='small'
          error={!!error}
          disabled={loading}
          value={couponCode || ''}
          onChange={handleCouponCodeChange}
          fullWidth={true}
          variant='outlined'
          />
        </FormControl>
        <Box className={classes.buttonContainer}>
          <AsyncButton
          variant='outlined'
          loading={loading}
          disabled={loading || !couponCode}
          onClick={handleApplyClick}
          >
            {t('dashboard:billing.apply')}
          </AsyncButton>
        </Box>
      </FlexSpacer>
      <Typography color={error ? 'error' : undefined}>
        {error
        ? t('dashboard:billing.invalidcoupon')
        : coupon
          ? t('dashboard:billing.couponapplied', { coupon: couponAmountText })
          : null}
      </Typography>
    </FlexSpacer>
  );
};

export default React.memo(CouponInput);
