import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import useAvailableMetrics from 'features/dashboard/billing/useAvailableMetrics';
import useTeamAvailableBundles from 'features/dashboard/billing/useTeamAvailableBundles';
import useTeamResourceLimits from 'features/dashboard/billing/useTeamResourceLimits';
import { numToDollarAmount } from 'helpers/formatters';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { Fragment, useContext, useMemo } from 'react';
import { BillingInterval, CouponType, ProductKind } from 'types/graphql';
import { InputQuantities } from 'types/pigeon/billing';

import { CustomCouponCodeContext } from '../CustomCouponCodeContext';

interface CheckoutSummaryOwnProps {
  className?: string;
  defaultSummary: boolean;
  checkoutProduct: InputQuantities;
  teamId: string;
  currentPlan?: string;
  teamProration?: number;
  interval: BillingInterval;
  cost: number;
  isCustomPlan?: boolean;
  customPlanQuantities?: Partial<Record<ProductKind, number>>;
}

type CheckoutSummaryProps = CheckoutSummaryOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  bold: {
    fontWeight: 700,
  },
  m1: {
    margin: theme.spacing(0.5, 0),
  },
  planTitle: {
    margin: theme.spacing(0.5, 0, 2, 0),
  },
}), {name: 'CheckoutSummary'});

function CheckoutSummary(props: CheckoutSummaryProps) {
  const classes = useStyles(props);
  const {
    className,
    defaultSummary,
    checkoutProduct,
    teamId,
    currentPlan,
    teamProration,
    interval,
    cost,
    isCustomPlan,
    customPlanQuantities,
  } = props;
  const { t } = useStrictTranslation(['dialog']);
  const bundles = useTeamAvailableBundles(teamId);
  const { metrics, customPlans } = useAvailableMetrics(bundles);

  const checkoutResourceAmount = useMemo(() => {
    if (isCustomPlan) {
      return {
        upload: undefined,
        storage: undefined,
        storageBytes: (
          (customPlanQuantities?.[ProductKind.STORAGE_LIMIT_BYTES] ?? 1) *
          (customPlans[ProductKind.STORAGE_LIMIT_BYTES]?.unit ?? 0)
        ),
        keys: (
          (customPlanQuantities?.[ProductKind.PREMIUM_SEAT] ?? 1) *
          (customPlans[ProductKind.PREMIUM_SEAT]?.unit ?? 0)
        ),
      };
    }

    return metrics.find((metric) => metric.productId === checkoutProduct.productId);
  }, [checkoutProduct.productId, customPlanQuantities, customPlans, isCustomPlan, metrics]);

  const resourceLimits = useTeamResourceLimits(teamId);

  const resourceDifference = useMemo(() => {
    if ((resourceLimits.storage === 0 && resourceLimits.storageBytes === 0) || resourceLimits.upload === 0) {
      return;
    }
    if (checkoutResourceAmount && checkoutResourceAmount.upload) {
      if (checkoutResourceAmount.storage) {
        return {
          upload: checkoutResourceAmount.upload - resourceLimits.upload,
          storage: checkoutResourceAmount.storage - resourceLimits.storage,
        };
      }
      if (checkoutResourceAmount.storageBytes) {
        return {
          upload: checkoutResourceAmount.upload - resourceLimits.upload,
          storageBytes: checkoutResourceAmount.storageBytes - resourceLimits.storageBytes,
        };
      }
    }
    return;
  }, [checkoutResourceAmount, resourceLimits.storage, resourceLimits.storageBytes, resourceLimits.upload]);

  const formattedTeamProration = useMemo(
    () => teamProration && teamProration < 0 ? teamProration/-100 : 0,
    [teamProration],
  );

  const isYearly = useMemo(() => interval === BillingInterval.YEARLY, [interval]);

  const {
    coupon,
    appliedCouponCode,
  } = useContext(CustomCouponCodeContext);

  return (
    <div className={classNames(classes.root, className)}>
      <Typography variant='body2' className={classNames(classes.bold, classes.planTitle)}>
        {t('dialog:plandetails.changingplanfrom', { plan: currentPlan })}
      </Typography>
      <div className={classes.m1}>
        <FlexSpacer fullWidth={true} flexJustifyContent='space-between'>
          <Typography variant='body2'>
            {t('dialog:availableplans.storagetotal')}
          </Typography>
          {!!checkoutResourceAmount?.storage && (
            <Typography variant='body2'>
              {t('dialog:plandetails.amounthours', { amount: defaultSummary ? 5 : checkoutResourceAmount?.storage })}
            </Typography>
          )}
          {(
            <Typography variant='body2'>
              {t('dialog:plandetails.amountgbs', {
                amount: defaultSummary ? 5 : (checkoutResourceAmount?.storageBytes || 0) / (2 ** 30),
              })}
            </Typography>
          )}
        </FlexSpacer>
        {resourceDifference && (
          <Fragment>
            {resourceDifference.storage && resourceDifference.storage !== 0 && (
              <Typography variant='body2' color={resourceDifference.storage > 0 ? 'secondary' : 'error'} align='right'>
                {resourceDifference.storage > 0
                  ? t('dialog:plandetails.addamounthr', { amount: resourceDifference.storage })
                  : t('dialog:plandetails.subtractamounthr', { amount: Math.abs(resourceDifference.storage) })
                }
              </Typography>
            )}
            {resourceDifference.storageBytes && resourceDifference.storageBytes !== 0 && (
              <Typography
              variant='body2'
              color={resourceDifference.storageBytes > 0 ? 'secondary' : 'error'}
              align='right'
              >
                {resourceDifference.storageBytes > 0
                  ? t('dialog:plandetails.addamountgb', { amount: resourceDifference.storageBytes })
                  : t('dialog:plandetails.subtractamountgb', { amount: Math.abs(resourceDifference.storageBytes) })
                }
              </Typography>
            )}
          </Fragment>
        )}
      </div>
      <div className={classes.m1}>
        <FlexSpacer fullWidth={true} flexJustifyContent='space-between'>
          <Typography variant='body2'>
            {t('dialog:plandetails.adfreecapture')}
          </Typography>
          <Typography variant='body2'>
            {t('dialog:plandetails.keycount', { count: defaultSummary ? 0 : checkoutResourceAmount?.keys })}
          </Typography>
        </FlexSpacer>
      </div>
      <FlexSpacer fullWidth={true} flexJustifyContent='space-between' className={classes.m1}>
        {isYearly ? (
          <Typography>
            {t('dialog:availableplans.billedannually')}
          </Typography>
        ) : (
          <Typography>
            {t('dialog:availableplans.billedmonthly')}
          </Typography>
        )}
        <Typography className={classes.bold}>
          {isYearly
            ? t('dialog:plandetails.totalperyear', { total: numToDollarAmount(defaultSummary ? 0 : cost) })
            : t('dialog:plandetails.totalpermonth', { total: numToDollarAmount(defaultSummary ? 0 : cost) })
          }
        </Typography>
      </FlexSpacer>
      {coupon && (
        <FlexSpacer fullWidth={true} flexJustifyContent='space-between'>
          <Typography className={classes.bold} color='primary'>
            {t('dialog:plandetails.discount')}
          </Typography>
          <Typography color='primary'>
            {t('dialog:plandetails.subtracttotal', {
                total: numToDollarAmount(
                  coupon.type === CouponType.PERCENT
                    ? cost * (1 / coupon.amount)
                    : coupon.amount),
              },
            )}
          </Typography>
        </FlexSpacer>
      )}
      {formattedTeamProration > 0 && (
        <div className={classes.m1}>
          <FlexSpacer fullWidth={true} flexJustifyContent='space-between'>
            <Typography variant='body2' className={classes.bold} color='secondary'>
              {t('dialog:plandetails.proratedcredits')}
            </Typography>
            <Typography className={classes.bold} color='secondary'>
              {t('dialog:plandetails.addtotal', { total: numToDollarAmount(formattedTeamProration) })}
            </Typography>
          </FlexSpacer>
        </div>
      )}
    </div>
  );
}

export default React.memo(CheckoutSummary);
