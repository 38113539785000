import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetVideoBookmarksRecordsByVideoId } from './video-bookmark-selector';
import { fetchVideoBookmarksAC } from './video-bookmark-slice';

export const useFetchVideoBookmarks = createBidirectionalAutoFetcher({
  actionCreators: fetchVideoBookmarksAC,
  selectorFactory: makeGetVideoBookmarksRecordsByVideoId,
  createFetchId: ({videoId}) => videoId,
  pageSize: 10,
});
