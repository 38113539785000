import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import classNames from 'classnames';
import { BorderLinearProgress } from 'components/dashboard/team-settings/team-billing/Overview/ProgressBar';
import { numToDollarAmount } from 'helpers/formatters';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import clamp from 'lodash/clamp';
import React, { useCallback, useMemo } from 'react';

import { Milestone, milestones } from './milestones';

interface ReferralMilestonesOwnProps {
  className?: string;
  teamReferrals: number;
  currentMilestoneIndex: number;
  openInviteTeamMembersDialog: VoidFunction;
}

type ReferralMilestonesProps = ReferralMilestonesOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  faded: {
    opacity: 0.5,
  },
  credit: {
    backgroundColor: theme.palette.background.note,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 64,
    height: 40,
    position: 'relative',
  },
  checked: {
    backgroundColor: theme.palette.primary.main,
  },
  semiBold: {
    fontWeight: 600,
  },
  circle: {
    width: '26px',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: '75%',
    bottom: 0,
    borderRadius: '50%',
    backgroundColor: theme.palette.success.main,
  },
  full: {
    width: '100%',
  },
  prizeProgressContainer: {
    position: 'relative',
    width: '100%',
    height: '3rem',
  },
  prizeProgressIndicator: {
    position: 'absolute',
    bottom: '-.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  start: {
    left: 'calc(var(--progress)/var(--range)*100%)',
    transform: 'translateX(-50%)',
  },
  end: {
    right: '0',
    transform: 'translateX(50%)',
  },
  bar: {
    width: 'inherit',
    margin: theme.spacing(3),
  },
  subtitle: {
    alignSelf: 'flex-start',
  },
  gold: {
    color: theme.palette.tiers.gold,
  },
}), {name: 'ReferralMilestones'});

function percentize(referrals: number, max: number) {
  return clamp(referrals / max * 100, 0, 100);
}

function ReferralMilestones(props: ReferralMilestonesProps) {
  const classes = useStyles(props);
  const { className, teamReferrals, currentMilestoneIndex, openInviteTeamMembersDialog } = props;
  const { t } = useStrictTranslation(['dashboard', 'dashboard-settings']);

  const prizeValue = useMemo(() => {
    if (currentMilestoneIndex < 0) {
      return 0;
    }
    return milestones[currentMilestoneIndex].rewardAmount;
  }, [currentMilestoneIndex]);

  const currentRange = useMemo(() => {
    if (currentMilestoneIndex < 0) {
      return 0;
    }
    if (currentMilestoneIndex === 0) {
      return milestones[currentMilestoneIndex].referralGoal;
    }
    return milestones[currentMilestoneIndex].referralGoal - milestones[currentMilestoneIndex-1].referralGoal;
  }, [currentMilestoneIndex]);

  const currentProgress = useMemo(() => {
    if (currentMilestoneIndex < 0) {
      return 0;
    }
    if (currentMilestoneIndex === 0) {
      return teamReferrals;
    }
    return teamReferrals - milestones[currentMilestoneIndex-1].referralGoal;
  }, [currentMilestoneIndex, teamReferrals]);

  const progressStyle = useMemo(() => ({
    '--range': currentRange,
    '--progress': currentProgress,
  } as React.CSSProperties), [currentProgress, currentRange]);

  const renderMilestones = useCallback((
    {referralGoal, rewardAmount}: Milestone,
    index: number,
  ) => (
    <FlexSpacer flexAlignItems='center' flexJustifyContent='space-between' fullWidth={true} key={index}>
      <div className={classes.flex}>
        <Typography className={classNames({[classes.faded]: teamReferrals >= referralGoal})}>
          {t('dashboard:referral.milestonex', { count: index+1 })}
        </Typography>
        <Typography variant='body2' className={classNames({[classes.faded]: teamReferrals >= referralGoal})}>
          {t('dashboard:referral.xsignups', { count: clamp(teamReferrals, referralGoal), max: referralGoal })}
        </Typography>
      </div>
      <Box className={classNames(classes.credit, {[classes.checked]: teamReferrals >= referralGoal})}>
        <Typography className={classes.semiBold}>
          {t('dashboard:referral.xcredits', { count: numToDollarAmount(rewardAmount) })}
        </Typography>
        {teamReferrals >= referralGoal && (
          <div className={classes.circle}>
            <CheckIcon className={classes.full} />
          </div>
        )}
      </Box>
    </FlexSpacer>
  ),[
    classes.checked,
    classes.circle,
    classes.credit,
    classes.faded,
    classes.flex,
    classes.full,
    classes.semiBold,
    t,
    teamReferrals,
  ]);

  return (
    <FlexSpacer fullWidth={true} orientation='vertical' flexJustifyContent='center' flexAlignItems='center'>
      <React.Fragment>
        <Typography variant='h4' className={classes.semiBold}>
          {t('dashboard:referral.buildyourserver')}
        </Typography>
        <Typography className={classes.full}>
          {t('dashboard:referral.bestexperience')}
        </Typography>
        <Typography>
          {t('dashboard:referral.invitemembers')}
        </Typography>
        <Button
        variant='contained'
        color='primary'
        startIcon={<AddIcon />}
        onClick={openInviteTeamMembersDialog}
        size='small'
        >
          {t('dashboard-settings:members.addmember')}
        </Button>
      </React.Fragment>
      <React.Fragment>
        <div className={classes.prizeProgressContainer} style={progressStyle}>
          <div className={classNames(classes.start, classes.prizeProgressIndicator)}>
            <Typography>{teamReferrals}</Typography>
            <ArrowDropDownIcon/>
          </div>
          <div className={classNames(classes.end, classes.prizeProgressIndicator)}>
            <Typography>{`$${prizeValue}`}</Typography>
            <EmojiEventsIcon className={classes.gold}/>
            <ArrowDropDownIcon color='primary'/>
          </div>
        </div>
        <BorderLinearProgress
        variant='determinate'
        value={percentize(currentProgress, currentRange)}
        className={classes.bar}
        />
      </React.Fragment>
      <React.Fragment>
        <Typography variant='body2' className={classes.subtitle}>
          {t('dashboard:referral.totalreferrals')}
        </Typography>
        {milestones.map(renderMilestones)}
      </React.Fragment>
    </FlexSpacer>
  );
}

export default React.memo(ReferralMilestones);
