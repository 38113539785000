import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { checkInView } from '@insights-gaming/utils';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import { EIntercomID } from 'constants/strings';
import TeamFetcher from 'fetchers/team-fetcher/TeamFetcher';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import UndraggableAvatar from 'material/undraggable-avatar/UndraggableAvatar';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ID } from 'types/pigeon';

import {makeGetSharedDivisionsDict } from '../directory/dashboard-directory-selector';
import SharedDivisionFetcher from '../directory/SharedDivisionFetcher';
import CustomDragLayer from '../dnd/CustomDragLayer';
import CreateTeamDialog from '../team/create-team-dialog/CreateTeamDialog';
import { getSortedTeams } from '../team/dashboard-team-selector';
import DragDropTeamCard from './DragDropTeamCard';
import TeamAvatarButton from './TeamAvatarButton';

interface TeamPickerOwnProps {
  className?: string;
  selectedTeamId?: string | undefined;
  onTeamClick?: (e: React.MouseEvent<HTMLButtonElement>, teamId: ID) => void;
  teamActionButtons?: React.ReactNode;
}

type TeamPickerProps = TeamPickerOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    width: theme.appSizes.drawerWidth,
    overflow: 'hidden',
  },
  outer: {
    transform: 'translate3d(0, 0, 0)',
    flex: 1,
    display: 'flex',
    overflow: 'hidden',
    width: theme.appSizes.drawerWidth+24, // hack to hide the scrollbar
  },
  inner: {
    width: 'inherit',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  iconOuter: {
    height: theme.appSizes.drawerWidth,
    width: theme.appSizes.drawerWidth,
    display: 'flex',
    padding: 12, // (drawerWidth - inner avatar image width) / 2
    justifyContent: 'center',
    alignItems: 'center',
    '&$active': {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      paddingLeft: 12 - 4, // accommodate added border
    },
    '&$isDragging': {
      opacity: 0.6,
    },
  },
  avatar: {
    display: 'flex',
  },
  logo: {
    backgroundColor: 'transparent',
  },
  tooltipContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    pointerEvents: 'none',
  },
  insightsCaptureLink: {
    fontSize: createRemFromPx(50),
    backgroundColor: 'unset',
  },
  insightsCaptureBadge: {
    top: theme.spacing(0.5),
    right: theme.spacing(2),
  },
  active: {},
  isDragging: {},
}), {name: 'TeamPicker'});

const modifiers = {
  flip: {
    enabled: false,
  },
  preventOverflow: {
    enabled: true,
    boundariesElement: 'viewport',
  },
};

const avatarButtonSize = 'sm';

function TeamPicker(props: TeamPickerProps) {
  const classes = useStyles(props);
  const {
    className,
    selectedTeamId,
    onTeamClick,
    teamActionButtons,
  } = props;

  const { t } = useStrictTranslation(['common', 'owstats']);

  const [tooltipContainer, setTooltipContainer] = useState<HTMLDivElement | null>(null);
  const [hoveredTeamIndex, setHoveredTeamIndex] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const teams = useSelector(getSortedTeams);

  const sharedDivisionsDict = useCreateSelector(makeGetSharedDivisionsDict, '');

  const sharedDivisionTeams = useMemo(() => {
    return Object.keys(sharedDivisionsDict)
      .map((k) => sharedDivisionsDict[k][0].team)
      .filter(st => !teams.find(t => t.id === st.id));
  }, [sharedDivisionsDict, teams]);

  const [isCreateTeamDialogOpen, openCreateTeamDialog, closeCreateTeamDialog] = useDialogState();

  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const handleAutoScrollRef = useCallback((el: HTMLButtonElement | null) => {
    if (el && ref) {
      if (!checkInView(ref, el)) {
        setTimeout(() => {
          el.scrollIntoView({behavior: 'smooth'});
        }, 200);
      }
    }
  }, [ref]);

  return (
    <div id={EIntercomID.TEAM_PICKER} className={classNames(classes.root, className)}>
      <TeamFetcher />
      <SharedDivisionFetcher />
      <div className={classes.outer}>
        <div ref={setRef} className={classes.inner}>
          {teamActionButtons}
          <Divider variant='fullWidth' />
          {teams.map((team, index) => {
            const active = team.id === selectedTeamId;
            return (
              <DragDropTeamCard
              key={team.id}
              tooltipContainer={tooltipContainer}
              team={team}
              teamIndex={index}
              onTeamClick={onTeamClick}
              active={active}
              handleAutoScrollRef={handleAutoScrollRef}
              teams={teams}
              hoveredTeamIndex={hoveredTeamIndex}
              setHoveredTeamIndex={setHoveredTeamIndex}
              setIsDragging={setIsDragging}
              />
            );
          })}
          {sharedDivisionTeams.map(team => {
            const active = team.id === selectedTeamId;
            return (
              <Tooltip
              key={team.id}
              title={team.name}
              placement='right'
              PopperProps={{
                container: tooltipContainer,
                modifiers,
              }}
              >
                <TeamAvatarButton
                ref={active ? handleAutoScrollRef : undefined}
                team={team}
                onClick={onTeamClick}
                size={avatarButtonSize}
                className={classNames(classes.iconOuter, {
                  [classes.active]: active,
                  [classes.isDragging]: isDragging,
                })}
                />
              </Tooltip>
            );
          })}
          <Tooltip
          title={t('common:createteam')}
          placement='right'
          PopperProps={{
            container: tooltipContainer,
            modifiers,
          }}
          >
            <ButtonBase
            id={EIntercomID.CREATE_TEAM}
            className={classNames(classes.iconOuter, {[classes.isDragging]: isDragging})}
            onClick={openCreateTeamDialog}
            >
              <UndraggableAvatar size={avatarButtonSize} className={classes.avatar}>
                <AddIcon />
              </UndraggableAvatar>
            </ButtonBase>
          </Tooltip>
        </div>
      </div>
      <CreateTeamDialog open={isCreateTeamDialogOpen} onClose={closeCreateTeamDialog} />
      <div ref={setTooltipContainer} className={classes.tooltipContainer} />
      <CustomDragLayer />
    </div>
  );
}

export default React.memo(TeamPicker);
