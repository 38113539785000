import { DrawingCanvas, DrawingCanvasEvent, DrawingTool, SerializedCanvas,SerializedObject } from '@insights-gaming/drawing-canvas';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import useAddEventListener from 'hooks/useAddEventListener';
import useAddListener from 'hooks/useAddListener';
import React, { Ref,useCallback, useEffect, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import { DrawingOverlayController, EToolSize } from './useDrawingOverlayController';
import { DrawingState } from './useDrawingState';

const useStyles = makeStyles(() => createStyles({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  container: {
    position: 'absolute', // this is necessary for some dumb reason
    top: 0,
    zIndex: 1,
  },
  unsetCursor: {
    '& .upper-canvas': {
      cursor: 'unset !important',
    },
  },
}));

export interface DrawingOverlayProps {
  initialObjects?: SerializedObject[],
  onClick?: (e: DrawingCanvasEvent) => void;
  onPing?: (e: DrawingCanvasEvent & { readonly x: number; readonly y: number }) => void;
  onObjectsAdded?: (e: DrawingCanvasEvent & { readonly objects: fabric.Object[]}) => void;
  onObjectsModified?: (e: DrawingCanvasEvent & { readonly objects: fabric.Object[]}) => void;
  onObjectsRemoved?: (e: DrawingCanvasEvent & { readonly objects: fabric.Object[]}) => void;
  onObjectsReplacedSilently?: (e: DrawingCanvasEvent & { readonly objects: fabric.Object[]}) => void;
  onObjectsRemovedSilently?: (e: DrawingCanvasEvent & { readonly objects: fabric.Object[]}) => void;
  onDrawStart?: (e: DrawingCanvasEvent) => void;
  onDrawEnd?: (e: DrawingCanvasEvent) => void;
  onUndo?: (e: DrawingCanvasEvent) => void;
  onRedo?: (e: DrawingCanvasEvent) => void;
  onTrash?: (e: DrawingCanvasEvent) => void;
  onCanvasCleared?: (e: DrawingCanvasEvent) => void;
  onCanvasLoaded?: (e: DrawingCanvasEvent) => void;
  onReadyStateChange?: (ready: boolean) => void;
  controller?: DrawingOverlayController;
  state: DrawingState;
}

function DrawingOverlay(
  {
    initialObjects,
    onClick,
    onPing,
    onObjectsAdded,
    onObjectsModified,
    onObjectsRemoved,
    onObjectsReplacedSilently,
    onObjectsRemovedSilently,
    onDrawStart,
    onDrawEnd,
    onUndo,
    onRedo,
    onTrash,
    onCanvasCleared,
    onCanvasLoaded,
    onReadyStateChange,
    controller: c,
    state: { tool, color, size },
  }: DrawingOverlayProps,
) {
  const classes = useStyles();
  const cachedCanvasElemRef = useRef<HTMLCanvasElement>();
  const cachedCanvasRef = useRef<DrawingCanvas>();

  const canvasRef = useRef<DrawingCanvas>();
  const [canvas, setCanvas] = useState<DrawingCanvas>();
  const [enabled, setEnabled] = useState(false);

  const canvasRefFunc: Ref<HTMLCanvasElement> = useCallback((canvasElem: HTMLCanvasElement | null) => {
    if (!canvasElem) {
      setCanvas(canvasRef.current = undefined);
      return;
    }

    if (cachedCanvasElemRef.current === canvasElem) {
      setCanvas(canvasRef.current = cachedCanvasRef.current);
      return;
    }

    setCanvas(cachedCanvasRef.current = canvasRef.current = new DrawingCanvas(
      cachedCanvasElemRef.current = canvasElem,
      {
        containerClass: classes.container,
        preserveObjectStacking: true,
        initialObjects,
        initialTool: tool,
      },
    ));

    canvasRef.current.setColor(color);
    canvasRef.current.toolSize = size;
  }, [classes.container, color, initialObjects, size, tool]);

  useAddListener(c, 'setTool', useCallback((tool) => {
    canvasRef.current?.setTool(tool);
    setEnabled(tool !== DrawingTool.NONE);
  }, []));
  useAddListener(c, 'setSize', useCallback((size: EToolSize) => {
    canvasRef.current?.setToolSize(size);
  }, []));
  useAddListener(c, 'setColor', useCallback((color) => {
    canvasRef.current?.setColor(color);
  }, []));
  useAddListener(c, 'redo', useCallback(() => {
    canvasRef.current?.redo();
  }, []));
  useAddListener(c, 'undo', useCallback(() => {
    canvasRef.current?.undo();
  }, []));
  useAddListener(c, 'clear', useCallback(() => {
    canvasRef.current?.trash();
  }, []));
  useAddListener(c, 'reset', useCallback(() => {
    canvasRef.current?.reset();
  }, []));
  useAddListener(c, 'deselectAll', useCallback(() => {
    canvasRef.current?.clearSelection();
  }, []));
  useAddListener(c, 'addSticker', useCallback((url: string, stickerNum: number, imagekey: string) => {
    canvasRef.current?.addStickerFromURL(url, stickerNum, imagekey);
  }, []));
  useAddListener(c, 'setImageUnderlay', useCallback((url: string, imagekey: string) => {
    canvasRef.current?.setUnderlayFromURL(url, imagekey);
  }, []));
  useAddListener(c, 'renderPing', useCallback((x: number, y: number, color?: string) => {
    canvasRef.current?.renderPing(x, y, color);
  }, []));
  useAddListener(c, 'import', useCallback((canvas: SerializedCanvas) => {
    canvasRef.current?.import(canvas);
  }, []));
  useAddListener(c, 'export', useCallback((resolve) => {
    if (canvasRef.current) {
      resolve(canvasRef.current.export());
    }
  }, []));
  useAddListener(c, 'silentlyReplaceObjects', useCallback((objs: SerializedObject[]) => {
    canvasRef.current?.replaceObjectsSilently(objs);
  }, []));
  useAddListener(c, 'silentlyRemoveObjects', useCallback((uuids: string[]) => {
    canvasRef.current?.removeObjectsSilently(uuids);
  }, []));
  useAddListener(c, 'silentlyClearCanvas', useCallback(() => {
    canvasRef.current?.clearSilently();
  }, []));

  useAddEventListener(canvas, 'click', onClick);
  useAddEventListener(canvas, 'ping', onPing);
  useAddEventListener(canvas, 'objectsadded', onObjectsAdded);
  useAddEventListener(canvas, 'objectsmodified', onObjectsModified);
  useAddEventListener(canvas, 'objectsremoved', onObjectsRemoved);
  useAddEventListener(canvas, 'objectsreplaced:silent', onObjectsReplacedSilently);
  useAddEventListener(canvas, 'objectsremoved:silent', onObjectsRemovedSilently);
  useAddEventListener(canvas, 'drawstart', onDrawStart);
  useAddEventListener(canvas, 'drawend', onDrawEnd);
  useAddEventListener(canvas, 'undo', onUndo);
  useAddEventListener(canvas, 'redo', onRedo);
  useAddEventListener(canvas, 'trash', onTrash);
  useAddEventListener(canvas, 'canvasimported', onCanvasLoaded);
  useAddEventListener(canvas, 'canvascleared', onCanvasCleared);

  const resizeCanvas = useCallback((w: number, h: number) => canvasRef.current?.setDimensions(w, h), []);
  const blurCanvas = useCallback(() => canvasRef.current?.clearSelection(), []);

  const rootRef: Ref<HTMLDivElement> = useCallback((rootElem: HTMLDivElement | null) => {
    if (!rootElem) {
      return;
    }

    const rect = rootElem.getBoundingClientRect();
    resizeCanvas(rect.width, rect.height);
  }, [resizeCanvas]);

  useEffect(() => {
    if (!onReadyStateChange) {
      return;
    }

    onReadyStateChange(!!canvas);
    return () => onReadyStateChange(false);
  }, [canvas, onReadyStateChange]);

  return (
    <ReactResizeDetector onResize={resizeCanvas} handleWidth={true} handleHeight={true} >
      <div ref={rootRef} className={classNames(classes.root, { [classes.unsetCursor]: !enabled })}>
        <ClickAwayListener onClickAway={blurCanvas} mouseEvent='onMouseDown'>
          <div>
            <canvas ref={canvasRefFunc} />
          </div>
        </ClickAwayListener>
      </div>
    </ReactResizeDetector>
  );
}

export default React.memo(DrawingOverlay);
