import { createFindEntityStrategy } from '@insights-gaming/material-components';
import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js';

import CustomLink from '../decorator-components/CustomLink';
import CustomTimestamp from '../decorator-components/CustomTimestamp';
import Mention from '../decorator-components/Mention';

export function findTimestampStrategy(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) {
  const reg = /(\d+(?::\d{2}){1,2})(?:\s|$)/g;
  findWithRegex(reg, contentBlock, callback);
}

function findWithRegex(regex: RegExp, contentBlock: ContentBlock, callback: (start: number, end: number) => void) {
  const text = contentBlock.getText();
  let result, start;
  while ((result = regex.exec(text)) !== null) {
    start = result.index;
    callback(start, start + result[1].length);
  }
}

// Decorator
export const decorator = new CompositeDecorator([
  {
    strategy: createFindEntityStrategy('LINK'),
    component: CustomLink,
  },
  {
    strategy: createFindEntityStrategy('MENTION'),
    component: Mention,
  },
  {
    strategy: findTimestampStrategy,
    component: CustomTimestamp,
  },
]);
