import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinkIcon from '@material-ui/icons/Link';
import makeStyles from '@material-ui/styles/makeStyles';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import ValidatedVideoUrlTextField from 'components/validated-video-url-text-field/ValidatedVideoUrlTextField';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { makeGetVideoById } from 'features/dashboard/video/dashboard-video-selector';
import VideoPickerDialog from 'features/dashboard/video-picker-dialog/VideoPickerDialog';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import HorizontalDivider from 'material/horizontal-divider/HorizontalDivider';
import React, { useCallback, useEffect, useState } from 'react';
import { ID } from 'types/pigeon';

interface RemoteSelectedVideo {
  type: 'remote';
  id?: undefined;
  metadata: InsightsEmbedResponse;
}

interface TeamSelectedVideo {
  type: 'team';
  id: ID;
  url?: undefined;
  video?: VideoFragment;
}

type SelectedVideo = RemoteSelectedVideo | TeamSelectedVideo;

export interface MultiTypeVideoSelectorOwnProps {
  teamId: ID;
  initialDirectoryId?: ID;
  onChange: (video: VideoFragment | InsightsEmbedResponse) => any;
  teamPicker?: boolean;
}

export type MultiTypeVideoSelectorProps = MultiTypeVideoSelectorOwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  selectVideo: {
    marginTop: theme.spacing(2),
  },
  videoUrl: {
    width: createRemFromPx(290),
    alignSelf: 'center',
  },
}));

function MultiTypeVideoSelector(
  {
    teamId,
    initialDirectoryId,
    onChange,
    teamPicker,
  }: MultiTypeVideoSelectorProps,
) {
  const classes = useStyles();

  const { t } = useStrictTranslation(['common', 'dialog']);
  const accessControl = useAccessControl();
  const { isOpen, openDialog, closeDialog } = useDialogState();
  const [ selectedVideo, setSelectedVideo ] = useState<SelectedVideo | undefined>();

  const video = useCreateSelector(makeGetVideoById, selectedVideo?.id);

  useEffect(() => {
    if (selectedVideo?.type !== 'team' || selectedVideo.video || !video) {
      return;
    }

    setSelectedVideo({ ...selectedVideo, video });
  }, [selectedVideo, video]);

  useEffect(() => {
    switch (selectedVideo?.type) {
      case 'remote':
        onChange(selectedVideo.metadata);
        break;

      case 'team':
        if (selectedVideo.video) {
          onChange(selectedVideo.video);
        }
    }
  }, [onChange, selectedVideo]);

  const handleRemoteVideoMetadata = useCallback(async (metadata: InsightsEmbedResponse) => {
    setSelectedVideo({ type: 'remote', metadata });
  }, [setSelectedVideo]);

  const handleVideoSelected = useCallback((id: ID) => {
    setSelectedVideo({ type: 'team', id });
    closeDialog();
  }, [closeDialog]);

  return (
    <React.Fragment>
      <FlexSpacer flexJustifyContent='center' className={classes.selectVideo}>
        <Button
        variant='outlined'
        onClick={openDialog}
        color='primary'
        >
          {t('dialog:startsession.selectvideo')}
        </Button>
      </FlexSpacer>
      {accessControl.canCreateVod && (
        <React.Fragment>
          <HorizontalDivider uppercase={true}>
            <Typography>
              {t('common:conjunction.or')}
            </Typography>
          </HorizontalDivider>
          <FlexSpacer className={classes.videoUrl} flexAlignItems='center'>
            <LinkIcon/>
            <ValidatedVideoUrlTextField onChange={handleRemoteVideoMetadata}/>
          </FlexSpacer>
        </React.Fragment>
      )}
      <VideoPickerDialog
      teamId={teamId}
      initialDirectoryId={initialDirectoryId}
      open={isOpen}
      onClose={closeDialog}
      onConfirm={handleVideoSelected}
      teamPicker={teamPicker}
      />
    </React.Fragment>
  );
}

export default React.memo(MultiTypeVideoSelector);
