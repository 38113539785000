import { arrayify } from 'helpers';
import getVideoDuration from 'helpers/getVideoDuration';
import { getUuid } from 'helpers/uuidgenerator';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { VideoPublicity } from 'types/graphql';

import { BasePendingUpload, FilePendingUpload, PendingUpload, PendingUploadChanges, RemoteUrlPendingUpload, UnprobedFilePendingUpload } from './types';

function basePendingUpload(): BasePendingUpload {
  return {
    uuid: getUuid(),
    publicity: VideoPublicity.PRIVATE,
  };
}

function createPendingUpload(file: File | InsightsEmbedResponse) {
  return Object.assign(
    basePendingUpload(),
    file instanceof File ? {
      type: 'unprobed_file',
      file,
    } : {
      type: 'remote',
      title: file.title,
      url: file.url,
    },
  ) as UnprobedFilePendingUpload | RemoteUrlPendingUpload;
}

function updatePendingUpload(
  items: PendingUpload[],
  { uuid, ...changes }: PendingUploadChanges,
): PendingUpload[] {
  return items.map((item) => item.uuid === uuid ? Object.assign({}, item, changes) : item);
}

function isUnprobed(item: PendingUpload): item is UnprobedFilePendingUpload {
  return item.type === 'unprobed_file';
}

function isFileOrRemote(item: PendingUpload): item is FilePendingUpload | RemoteUrlPendingUpload {
  return item.type === 'file' || item.type === 'remote';
}

export function usePendingUploadsState(initialItems?: Array<File | InsightsEmbedResponse>) {
  const [items, setItems] = useState<PendingUpload[]>([]);

  const addItems = useCallback((files: File | InsightsEmbedResponse | Array<File | InsightsEmbedResponse>) => {
    const newItems = arrayify(files).map(createPendingUpload);
    setItems((items) => items.concat(newItems));

    for (const { uuid, file } of newItems.filter(isUnprobed)) {
      getVideoDuration(file).then(
        (duration) => setItems((items) => updatePendingUpload(items, { uuid, type: 'file', duration })),
        (error) => setItems((items) => updatePendingUpload(items, { uuid, type: 'invalid_file', error })),
      );
    }
  }, []);

  useEffect(() => initialItems && addItems(initialItems), [addItems, initialItems]);

  return {
    items,
    addItems,
    addInvalid: useCallback((files: File | File[]) => {
      setItems((items) => items.concat(arrayify(files).map((file) => ({
        ...basePendingUpload(),
        type: 'invalid_file',
        file,
        error: new Error('bad file'),
      }))));
    }, []),
    updateItems: useCallback((changes: PendingUploadChanges | PendingUploadChanges[]) => {
      setItems((items) => arrayify(changes).reduce(updatePendingUpload, items));
    }, []),
    removeItems: useCallback((uuids: string | string[]) => {
      const set = new Set(arrayify(uuids));
      setItems((items) => items.filter(({ uuid }) => !set.has(uuid)));
    }, []),
    removeItemsExcept: useCallback((uuids: string | string[]) => {
      const set = new Set(arrayify(uuids));
      setItems((items) => items.filter(({ uuid }) => set.has(uuid)));
    }, []),
    ready: useMemo(() => items.every(isFileOrRemote) ? items : undefined, [items]),
  };
}
