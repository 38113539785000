import { Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { UserNotificationSettingsState } from 'components/settings/notification-settings/notification-settings-slice';
import { useDialogState } from 'hooks/useDialogState';
import React, { useMemo } from 'react';
import { MuteLevel } from 'types/graphql';
import { ID } from 'types/pigeon';

import MuteNotificationDialog from './mute-notification-dialog/MuteNotificationDialog';

interface MuteButtonOwnProps {
  className?: string;
  teamSettingState: UserNotificationSettingsState;
  teamId: ID;
}
type MuteButtonProps = MuteButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'MuteButton'});

function MuteButton(props: MuteButtonProps) {
  const classes = useStyles(props);
  const { className, teamSettingState, teamId } = props;

  const [ muteNotificaionDialogOpen, openMuteNotificationDialog, closeMuteNotificationDialog ] = useDialogState();

  const muted = useMemo(() => {
    if (!(teamSettingState.settings && teamSettingState.settings.length)) { return false; }
    return !!teamSettingState.settings.find(s => s.level === MuteLevel.ALL);
  }, [teamSettingState.settings]);

  return (
    <React.Fragment>
      <IconButton
      size='small'
      onClick={openMuteNotificationDialog}
      edge='end'
      >
        {muted ? <NotificationsOffIcon /> : <NotificationsActiveIcon />}
      </IconButton>
      <MuteNotificationDialog
      open={muteNotificaionDialogOpen}
      onClose={closeMuteNotificationDialog}
      muted={muted}
      teamId={teamId}
      />
    </React.Fragment>
  );
}

export default React.memo(MuteButton);
