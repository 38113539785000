import { AsyncButton, FlexSpacer, Loader } from '@insights-gaming/material-components';
import { useBidirectionalFetchStatus } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { EIntercomID } from 'constants/strings';
import { createControlHostTransferAction, createControlTerminateEvent } from 'factories/kmsessionEventFactory';
import { makeGetTeamMemberRecordsByTeamId, makeGetTeamMembersByTeamId } from 'features/dashboard/member/dashboard-member-selector';
import { useCreateSelector, useParametricSelectorFactory } from 'hooks/useCreateSelector';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import UndraggableAvatar from 'material/undraggable-avatar/UndraggableAvatar';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ID } from 'types/pigeon';

import { getLiveSessionNonGuestUsers } from './live-session-selector';
import { liveSessionSendActionAC } from './live-session-slice';

export interface LiveSessionHostTransferDialogOwnProps {
  open: boolean;
  onClose: VoidFunction;
  teamId: ID;
}

export type LiveSessionHostTransferDialogProps = LiveSessionHostTransferDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  memberList: {
    maxHeight: createRemFromPx(224),
    overflow: 'auto',
  },
  noMembers: {
    color: theme.palette.text.deemphasized,
    textAlign: 'center',
  },
  dividerWithText: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.text.disabled,
    '&::after, &::before': {
      content: '""',
      border: `1px solid ${theme.palette.background.note}`,
      flex: 1,
    },
    '&:not(:empty)::before': {
      marginRight: theme.spacing(1),
    },
    '&:not(:empty)::after': {
      marginLeft: theme.spacing(1),
    },
  },
  negative: {
    borderColor: theme.palette.negative.main,
  },
}), { name: 'LiveSessionHostTransferDialog' });

function LiveSessionHostTransferDialog(props: LiveSessionHostTransferDialogProps) {
  const { open, onClose, teamId } = props;
  const classes = useStyles(props);
  const { t } = useStrictTranslation(['common', 'dialog']);
  const dispatch = useDispatch();
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const nonGuestUsers = useSelector(getLiveSessionNonGuestUsers);
  const members =  useCreateSelector(makeGetTeamMembersByTeamId, teamId);
  const memberIds = useMemo(() => new Set(members.map(member => member.user.id)), [members]);
  const transferCandidates = useMemo(
    () => nonGuestUsers.filter(user => memberIds.has(user.id)),
    [memberIds, nonGuestUsers],
  );

  const getTeamMemberRecords = useParametricSelectorFactory(
    makeGetTeamMemberRecordsByTeamId,
    teamId,
  );
  const { forward: membersForwardStatus } = useBidirectionalFetchStatus(getTeamMemberRecords);
  const initialMemberFetch = membersForwardStatus.fetching && !membersForwardStatus.cursor;

  const handleHostTransfer = useCallback(({ currentTarget: { id } }: React.MouseEvent<HTMLDivElement>) => {
    dispatch(liveSessionSendActionAC.started(createControlHostTransferAction(id)));
    onClose();
  }, [onClose, dispatch]);

  const handleTerminateSession = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      await promiseSagaDispatch(liveSessionSendActionAC, createControlTerminateEvent());
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, loading, promiseSagaDispatch]);

  return (
    <Dialog
    open={open}
    onClose={onClose}
    fullWidth={true}
    >
      <BetterDialogTitle disableTypography={true}>
        <Typography variant='h4' component='h2'>
          {t('dialog:hosttransfer.title')}
        </Typography>
        <Typography variant='caption'>
          <em>{t('dialog:hosttransfer.onlymembers')}</em>
        </Typography>
      </BetterDialogTitle>
      <DialogContent>
        <List disablePadding={true} className={classes.memberList}>
          {transferCandidates.length > 0 ? (
            transferCandidates.map((user) => (
              <ListItem id={user.id} key={user.id} button={true} onClick={handleHostTransfer}>
                <ListItemAvatar>
                  <UndraggableAvatar src={user.picture || undefined} />
                </ListItemAvatar>
                <ListItemText primary={user.alias} />
              </ListItem>
            ))
          ) : initialMemberFetch ? (
            <Loader />
          ) : (
            <div className={classes.noMembers}>
              <div>{t('dialog:hosttransfer.nomembers')}</div>
            </div>
          )}
        </List>

        <div className={classes.dividerWithText}>{t('common:conjunction.or')}</div>

        <FlexSpacer fullWidth={true} flexJustifyContent='center' flexAlignItems='center'>
          <AsyncButton
          id={EIntercomID.LEAVE_LIVE_SESSION}
          variant='outlined'
          classes={{ root: classes.negative }}
          loading={loading}
          disabled={loading}
          onClick={handleTerminateSession}
          >
            {t('dialog:leavesession.hostterminatesession')}
          </AsyncButton>
        </FlexSpacer>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(LiveSessionHostTransferDialog);
