import { FlexSpacer, VerticalScroll } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import { useIsDesktop } from 'features/media-queries/hooks';
import DashboardTeamSearchRouting from 'features/search/dashboard-team-search.routing';
import { useDialogState } from 'hooks/useDialogState';
import React from 'react';
import { useRouteMatch } from 'react-router';

interface DashboardLayoutOwnProps {
  className?: string;
  sidebar?: JSX.Element;
  header?: JSX.Element;
  content?: JSX.Element;
}

type DashboardLayoutProps = DashboardLayoutOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    overflow: 'hidden',
    flex: '1 0 100vh',
    maxHeight: '100%',
  },
  sidebar: {
    backgroundColor: theme.palette.background.paper,
    width: createRemFromPx(240),
    maxWidth: createRemFromPx(240),
    flex: 1,
  },
  main: {
    flex: 1,
  },
  headerWrapper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
  },
  expandButton: {
    position: 'absolute',
    zIndex: theme.zIndex.appBar,
  },
  header: {
    flex: 1,
  },
  content: {
    display: 'flex',
    flex: 1,
    margin: theme.spacing(2),
    overflow: 'hidden',
  },
}), {name: 'DashboardLayout'});

function DashboardLayout(props: DashboardLayoutProps) {
  const classes = useStyles(props);
  const { className, sidebar, content, header } = props;

  const [isSidebarOpen, openSidebar, closeSidebar] = useDialogState();

  const isDesktop = useIsDesktop();
  const isSearchRoute = !!useRouteMatch(DashboardTeamSearchRouting.path);

  const innerSidebar = (
    <VerticalScroll className={classes.sidebar}>
      {sidebar}
    </VerticalScroll>
  );

  return (
    <div className={classNames(classes.root, className)}>
      {sidebar && (
        isDesktop ? (
          innerSidebar
        ) : (
          <SwipeableDrawer open={isSidebarOpen} onOpen={openSidebar} onClose={closeSidebar}>
            {innerSidebar}
          </SwipeableDrawer>
        )
      )}
      <FlexSpacer orientation='vertical' className={classes.main}>
        <FlexSpacer flexAlignItems='center' spacing={0} className={classes.headerWrapper}>
          {!isDesktop && !isSearchRoute && (
            <IconButton
            className={classes.expandButton}
            onClick={openSidebar}
            >
              <MenuIcon />
            </IconButton>
          )}
          {header && (
            <div className={classes.header}>
              {header}
            </div>
          )}
        </FlexSpacer>
        {content && (
          <div className={classes.content}>
            {content}
          </div>
        )}
      </FlexSpacer>
    </div>
  );
}

export default React.memo(DashboardLayout);
