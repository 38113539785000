import gql from 'graphql-tag';

export const DeleteSelf_Mutation = gql`
    mutation DeleteSelfMutation(
        $input: DeleteSelfInput!
    ) {
        deleteSelf(
            input: $input
        ) {
            deleted
        }
    }
`;
