import { uuidv4 } from './uuid';

function* uuidgen(): IterableIterator<string> {
  while (true) {
    yield uuidv4();
  }
}

function* artisanGenerator(): IterableIterator<string> {
  for (let i = 0;; yield `${++i}`);
}

const uuidGenerator = uuidgen();
export function getUuid(): string {
  return uuidGenerator.next().value;
}
