import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import React from 'react';

import VideoCard from './VideoCard';

interface VideoCardSkeletonOwnProps {
  className?: string;
}

type VideoCardSkeletonProps = VideoCardSkeletonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  thumbnailSkeleton: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}), {name: 'VideoCardSkeleton'});

function VideoCardSkeleton(props: VideoCardSkeletonProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <VideoCard
    className={classNames(classes.root, className)}
    disabled={true}
    name={(
      <React.Fragment>
        <Skeleton variant='text' />
        <Skeleton variant='text' />
      </React.Fragment>
    )}
    thumbnail={(
      <Skeleton variant='rect' className={classes.thumbnailSkeleton} />
    )}
    />
  );
}

export default React.memo(VideoCardSkeleton);
