import { ReactComponent as AssaultIcon } from './assault.svg';
import { ReactComponent as AttackIcon } from './attack.svg';
import { ReactComponent as ControlIcon } from './control.svg';
import { ReactComponent as EscortIcon } from './escort.svg';
import { ReactComponent as HybridIcon } from './hybrid.svg';

export {
  AssaultIcon,
  AttackIcon,
  ControlIcon,
  EscortIcon,
  HybridIcon,
};
