import makeVideoProbe from '@insights-gaming/video-probe';
import probeWasm from '@insights-gaming/video-probe/probe.wasm';

let cachedVideoProbe: ReturnType<typeof makeVideoProbe>;

async function probeVideo(file: Blob): Promise<ReturnType<AsyncReturnType<typeof makeVideoProbe>>> {
    return (
        await (cachedVideoProbe || (cachedVideoProbe = makeVideoProbe(
            (info, recv) => probeWasm().then((module) => WebAssembly.instantiate(module, info)).then(recv),
        )))
    )(file);
}

async function getVideoDurationNative(file: Blob, host = document.body): Promise<number> {
    const video = document.createElement('video');
    const src = URL.createObjectURL(file);
    try {
        return await new Promise<number>((resolve, reject) => {
            video.style.display = 'none';
            video.addEventListener('loadedmetadata', () => {
                resolve(video.duration);
            });
            video.addEventListener('error', () => {
                reject(video.error);
            });
            video.src = src;
            host.appendChild(video);
        });
    } finally {
        URL.revokeObjectURL(src);
        host.removeChild(video);
    }
}

async function getVideoDurationWasm(file: Blob): Promise<number> {
    return (await probeVideo(file)).duration;
}

export default async function getVideoDuration(file: Blob): Promise<number> {
    try {
        return await getVideoDurationNative(file);
    } catch (err) {
        try {
            return await getVideoDurationWasm(file);
        } catch (err2) {
            throw err;
        }
    }
}
