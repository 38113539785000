import gql from 'graphql-tag';

export const DeleteVideo_Mutation = gql`
    mutation DeleteVideoMutation(
        $input: DeleteVideoInput!
    ) {
        deleteVideo(
            input: $input
        ) {
            videoId
        }
    }
`;
