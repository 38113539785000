import { GraphQLError } from 'graphql';
import actionCreatorFactory, { Action, Failure, Success } from 'typescript-fsa';

const REPORT_GRAPHQL_ERROR = 'REPORT_GRAPHQL_ERROR';

const actionCreator = actionCreatorFactory();

export interface IReportGraphQLErrorInput {
  variables: any;
  errors   : readonly GraphQLError[];
}

export type IReportGraphQLErrorResult = any;

export const reportGraphQLErrorAC = actionCreator<IReportGraphQLErrorInput>(REPORT_GRAPHQL_ERROR);
export const reportGraphQLErrorAsyncAC = actionCreator.async<
  IReportGraphQLErrorInput,
  IReportGraphQLErrorResult,
  Error
>(REPORT_GRAPHQL_ERROR);
type ReportGraphQLError =
  | Action<IReportGraphQLErrorInput>
  | Action<Success<IReportGraphQLErrorInput, IReportGraphQLErrorResult>>
  | Action<Failure<IReportGraphQLErrorInput, Error>>;
