import { GetOverwatchAdvancedQuery_Query } from 'apollo/queries';
import { fetchOverwatchAdvancedQueryAsyncAC } from 'features/overwatch-advanced-query/overwatch-advanced-query-slice';
import { watchAsyncQuery } from 'helpers/saga/effects';
import { all } from 'redux-saga/effects';

export default function* overwatchAdvancedQuerySaga() {
  yield all([
    watchAsyncQuery(fetchOverwatchAdvancedQueryAsyncAC, GetOverwatchAdvancedQuery_Query, ['overwatchAdvancedQuery']),
  ]);
}
