import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexSpacer } from '@insights-gaming/material-components/FlexSpacer';
import { UndraggableAvatar } from '@insights-gaming/material-components/UndraggableAvatar';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BrushIcon from '@material-ui/icons/Brush';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { personPlaceholder } from 'assets';
import classNames from 'classnames';
import { createCanvasActivatedAction, createGrantAction } from 'factories/kmsessionEventFactory';
import { makeGetTeamMembersByTeamId } from 'features/dashboard/member/dashboard-member-selector';
import { getLiveSessionCanvasActivated, getLiveSessionChatMembers, getLiveSessionColor, getLiveSessionDrawingAllowedUsers, getLiveSessionGuestName, getLiveSessionHost, getLiveSessionIsHost } from 'features/live-session/live-session-selector';
import { liveSessionAnnotationEventAC, liveSessionSendActionAC } from 'features/live-session/live-session-slice';
import LiveSessionHostTransferDialog from 'features/live-session/LiveSessionHostTransferDialog';
import { CROWN_COLOR } from 'helpers/color';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from 'selectors/getMe';
import { exid, ID } from 'types/pigeon';

import ChatMemberListControlButton from './ChatMemberListControlButton';
import ChatMemberListItem from './ChatMemberListItem';

interface ChatMemberListProps {
  className?: string;
  teamId: ID | undefined;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1),
    margin: 0,
  },
  videoPrivileges: {
    marginRight: theme.spacing(2),
  },
  userName: {
    fontSize: createRemFromPx(14),
    fontWeight: 600,
  },
  longUserName: {
    fontSize: createRemFromPx(14),
    whiteSpace: 'nowrap',
    maxWidth: createRemFromPx(120),
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    fontWeight : 600,
    textOverflow: 'ellipsis',
  },
  members: {
    textTransform: 'capitalize',
  },
}), {name: 'ChatMemberList'});

function ChatMemberList({ className, teamId }: ChatMemberListProps) {
  const classes = useStyles();
  const { t } = useStrictTranslation(['common', 'video']);

  const dispatch = useDispatch();

  const liveSessionHost = useSelector(getLiveSessionHost);
  const drawingAllowedUserIds = useSelector(getLiveSessionDrawingAllowedUsers);
  const canvasActivated = useSelector(getLiveSessionCanvasActivated);
  const chatUsers = useSelector(getLiveSessionChatMembers);
  const me = useSelector(getMe);
  const myColor = useSelector(getLiveSessionColor);
  const guestName = useSelector(getLiveSessionGuestName);
  const isHost = useSelector(getLiveSessionIsHost);

  const [isHostTransferDialogOpen, openHostTransferDialog, closeHostTransferDialog] = useDialogState();

  const host = useMemo(() => liveSessionHost || me, [liveSessionHost, me]);

  const members = useCreateSelector(makeGetTeamMembersByTeamId, teamId);
  const memberIds = useMemo(() => new Set(members.map(member => member.user.id)), [members]);

  const isHostCandidateExisting = useMemo(() => chatUsers.some(user => memberIds.has(user.id)), [chatUsers, memberIds]);
  const teamMembers = chatUsers.filter(user => memberIds.has(user.id));
  const guests = chatUsers.filter(user => user.guest || !memberIds.has(user.id));

  const chatUserIds = useMemo(() => chatUsers.map(exid), [chatUsers]);

  const allUsersCanDraw = useMemo(() => {
    return chatUserIds.every(id => drawingAllowedUserIds.includes(id));
  }, [chatUserIds, drawingAllowedUserIds]);

  const handlePerUserAnnotationPermissionClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(liveSessionSendActionAC.started(createGrantAction({
      action: drawingAllowedUserIds.includes(e.currentTarget.id) ? 'revoke' : 'grant',
      targets: [e.currentTarget.id],
      capabilities: ['annotate'],
    })));
    if (canvasActivated) {
      dispatch(liveSessionSendActionAC.started(createCanvasActivatedAction()));
    } else if (!canvasActivated && !drawingAllowedUserIds.includes(e.currentTarget.id)) {
      dispatch(liveSessionAnnotationEventAC(createCanvasActivatedAction()));
      dispatch(liveSessionSendActionAC.started(createCanvasActivatedAction()));
    }
  }, [canvasActivated, dispatch, drawingAllowedUserIds]);

  const handleAllUsersAnnotationPermissionClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(liveSessionSendActionAC.started(createGrantAction({
      action: allUsersCanDraw ? 'revoke' : 'grant',
      targets: chatUserIds,
      capabilities: ['annotate'],
    })));
    if (canvasActivated) {
      dispatch(liveSessionSendActionAC.started(createCanvasActivatedAction()));
    } else if (!canvasActivated && !allUsersCanDraw) {
      dispatch(liveSessionAnnotationEventAC(createCanvasActivatedAction()));
      dispatch(liveSessionSendActionAC.started(createCanvasActivatedAction()));
    }
  }, [allUsersCanDraw, canvasActivated, chatUserIds, dispatch]);

  return (
    <FlexSpacer orientation='vertical' className={classNames(classes.root, className)} style={{ margin: 0 }}>
      <FlexSpacer orientation='vertical' style={{ overflow: 'auto' }}>
        <FlexSpacer
        key={host?.id}
        flexAlignItems='center'
        flexJustifyContent='space-between'
        fullWidth={true}
        >
          <FlexSpacer spacing={1} flexAlignItems='center' fullWidth={true}>
            <UndraggableAvatar size='sm' src={host?.picture || personPlaceholder} />
            <span
            style={{color: liveSessionHost?.color || myColor}}
            className={host === me ? classes.longUserName : classes.userName}
            >
              {host?.alias}
            </span>
            {host === me &&
              <span style={{color: liveSessionHost?.color || myColor, marginLeft: 0}}>({t('video:comment.you')})</span>
            }
            <FontAwesomeIcon icon={faCrown} color={CROWN_COLOR} />
          </FlexSpacer>

          {host === me && (
            <React.Fragment>
              <FlexSpacer
              flexAlignItems='center'
              flexJustifyContent='flex-end'
              fullWidth={true}
              >
                <ChatMemberListControlButton
                onClick={handleAllUsersAnnotationPermissionClick}
                selected={allUsersCanDraw}
                title={t('video:session.alloweveryonetodraw')}
                >
                  <BrushIcon fontSize='small'/>
                </ChatMemberListControlButton>
                <ChatMemberListControlButton
                onClick={openHostTransferDialog}
                title={t('video:session.removefromsession')}
                >
                  <ExitToAppIcon fontSize='small'/>
                </ChatMemberListControlButton>
              </FlexSpacer>
            </React.Fragment>
          )}
        </FlexSpacer>

        {host !== me && (
          <FlexSpacer
          flexAlignItems='center'
          flexJustifyContent='space-between'
          >
            <FlexSpacer spacing={1} flexAlignItems='center'>
              <UndraggableAvatar size='sm' src={me?.picture || personPlaceholder} />
              <span style={{color: myColor}}>
                {me?.alias ? me?.alias : guestName}
              </span>
              <span style={{color: myColor}}>({t('video:comment.you')})</span>
            </FlexSpacer>
          </FlexSpacer>
        )}

        {host === me ? (  // show classified chat members on host's pov
          <React.Fragment>
            {!!teamMembers.length && (
              <Typography>
                {t('video:session.teammembers')}
              </Typography>
            )}
            {teamMembers.map((member) => (
              <ChatMemberListItem
              key={member.id}
              member={member}
              host={host}
              me={me}
              drawingAllowedUserIds={drawingAllowedUserIds}
              handlePerUserAnnotationPermissionClick={handlePerUserAnnotationPermissionClick}
              isHostCandidateExisting={isHostCandidateExisting}
              memberIds={memberIds}
              />
            ))}
            {!!guests.length && (
              <Typography>
                {t('video:session.guests')}
              </Typography>
            )}
            {guests.map(guest => (
              <ChatMemberListItem
              key={guest.id}
              member={guest}
              host={host}
              me={me}
              drawingAllowedUserIds={drawingAllowedUserIds}
              handlePerUserAnnotationPermissionClick={handlePerUserAnnotationPermissionClick}
              isHostCandidateExisting={isHostCandidateExisting}
              memberIds={memberIds}
              />
            ))}
          </React.Fragment>
        ) : (
          chatUsers.map((member) => (
            <ChatMemberListItem
            key={member.id}
            member={member}
            host={host}
            me={me}
            drawingAllowedUserIds={drawingAllowedUserIds}
            handlePerUserAnnotationPermissionClick={handlePerUserAnnotationPermissionClick}
            isHostCandidateExisting={isHostCandidateExisting}
            memberIds={memberIds}
            />
          ),
        ))}
      </FlexSpacer>
      {teamId && isHost && (
        <LiveSessionHostTransferDialog
        open={isHostTransferDialogOpen}
        onClose={closeHostTransferDialog}
        teamId={teamId}
        />
      )}
    </FlexSpacer>
  );
}

export default React.memo(ChatMemberList);
