import { useCreateSelector } from '@insights-gaming/redux-utils';
import { createProductCostCalculator } from 'helpers/billing';
import { numToDollarAmount } from 'helpers/formatters';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useMemo } from 'react';
import { CouponType } from 'types/graphql';

import { makeGetBillingCycleByTeamId } from './dashboard-billing.selectors';
import useTeamAvailableBundles from './useTeamAvailableBundles';
import useTeamSubscription from './useTeamSubscription';

export default function useRecurringCost(
  teamId: string,
): [number | undefined, string | undefined] {
  const [teamSubscription] = useTeamSubscription(teamId);
  const [billingCycle] = useCreateSelector(makeGetBillingCycleByTeamId, teamId);
  // const availableBundlesArray = useSelector(getAvailableBundles);
  const { t } = useStrictTranslation(['dashboard']);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (availableBundlesArray) {
  //     return;
  //   }

  //   dispatch(fetchAvailableBundlesAC.started(undefined));
  // }, [availableBundlesArray, dispatch]);

  const bundles = useTeamAvailableBundles(teamId);

  const teamProduct = useMemo(() => {
    if (!teamSubscription) {
      return;
    }

    return teamSubscription.products.find((product) => product.kind.startsWith('RESOURCE_PLAN'));
  }, [teamSubscription]);

  const cost = useMemo((): number | undefined => {
    if (!bundles || !teamProduct) {
      return;
    }

    const price = bundles.byProductId(teamProduct.id).prices.find((price) => price.id === teamProduct.priceId);
    if (!price) {
      return;
    }

    return createProductCostCalculator(price)(1);
  }, [bundles, teamProduct]);

  const couponPrice = useMemo(() => {
    if (billingCycle && billingCycle.coupon && cost) {
      return billingCycle.coupon.type === CouponType.FIXED
        ? billingCycle.coupon.amount
        : cost * (1 / billingCycle.coupon.amount);
    }
    return 0;
  }, [billingCycle, cost]);

  const costTranslation = useMemo(() => {
    if (!cost || !teamSubscription) { return; }
    return t(
      teamSubscription.interval === 'MONTHLY'
      ? 'dashboard:billing.amountpermonth'
      : 'dashboard:billing.amountperyear',
      { amount: numToDollarAmount(cost - couponPrice) },
    );
  }, [cost, couponPrice, t, teamSubscription]);

  return [cost, costTranslation];
}
