import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { buildSSBUStockIconPath } from '../../../../helpers';
import { SsbuCharactersF, SsbuCharactersNS } from '../../../../locales/en/ssbu-characters';
import { SSBUCharacter } from '../../../../types/pigeon/matches/testing-generated-ssbu-match';
import Stock from './Stock';

export interface IStocksRemainingOwnProps {
  character: SSBUCharacter;
  stocksRemaining: number;
  stocks: number;
  reverse?: boolean;
}

type Props = IStocksRemainingOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({}), {name: 'StocksRemaining'});

function StocksRemaining(props: Props) {
  const { character, reverse, stocksRemaining, stocks } = props;
  const classes = useStyles(props);
  const { t } = useTranslation(SsbuCharactersNS);
  const imagePath = useMemo(() => buildSSBUStockIconPath(character), [character]);

  const renderStock = useCallback((unused, i: number) => {
    const remaining = reverse
      ? stocksRemaining > i
      : stocksRemaining >= (stocks - i);
    return (
      <Stock key={character + i} src={imagePath} remaining={remaining} title={t(SsbuCharactersF(character))} />
    );
  }, [character, imagePath, reverse, stocks, stocksRemaining, t]);

  return (
    <FlexSpacer flexJustifyContent='center' spacing={0.5}>
      {Array.from({length: stocks}).map(renderStock)}
    </FlexSpacer>
  );
}

export default React.memo(StocksRemaining);
