import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetCommentTagsRecordsByCommentId } from '../video-comment-selector';
import { fetchCommentTagsAC } from '../video-comment-slice';

export const useFetchCommentTags = createBidirectionalAutoFetcher({
  actionCreators: fetchCommentTagsAC,
  selectorFactory: makeGetCommentTagsRecordsByCommentId,
  createFetchId: ({videoId, commentId}) => [videoId, commentId].join(':'),
  pageSize: 10,
});
