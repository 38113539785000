import { TeamPrivilege } from '../../../types/graphql';

export interface IPrivilegeGroup {
  groupName : string;
  privileges: TeamPrivilege[];
}

const liteVideoPrivileges: IPrivilegeGroup = {
  groupName: 'common:rolegroup.videos',
  privileges: [
    TeamPrivilege.CREATE_UPLOAD_VIDEO,
    TeamPrivilege.CREATE_REMOTE_VIDEO,
    TeamPrivilege.UPDATE_VIDEO,
    TeamPrivilege.DELETE_VIDEO,
    // TeamPrivilege.ACCESS_VIDEO,
  ],
};

const collaboratorVideoPrivileges: IPrivilegeGroup = {
  groupName: 'common:rolegroup.videos',
  privileges: [
    TeamPrivilege.CREATE_UPLOAD_VIDEO,
    TeamPrivilege.CREATE_REMOTE_VIDEO,
    TeamPrivilege.UPDATE_VIDEO,
  ],
};

const collaboratorCommentPrivileges: IPrivilegeGroup = {
  groupName: 'common:rolegroup.comments',
  privileges: [
    TeamPrivilege.CREATE_VIDEO_COMMENT,
    TeamPrivilege.ACCESS_VIDEO_COMMENT,
    TeamPrivilege.UPDATE_VIDEO_COMMENT,
    TeamPrivilege.DELETE_VIDEO_COMMENT,
  ],
};

const proVideoPrivileges: IPrivilegeGroup = {
  groupName: 'common:rolegroup.videos',
  privileges: [
    TeamPrivilege.CREATE_UPLOAD_VIDEO,
    TeamPrivilege.CREATE_REMOTE_VIDEO,
    TeamPrivilege.UPDATE_VIDEO,
    TeamPrivilege.DELETE_VIDEO,
    // TeamPrivilege.SEND_VOD_INVITATION,
    TeamPrivilege.CREATE_ANALYSIS,
  ],
};

const tagPrivileges: IPrivilegeGroup = {
  groupName: 'common:rolegroup.tags',
  privileges: [
    TeamPrivilege.CREATE_VIDEO_TAG,
    TeamPrivilege.UPDATE_VIDEO_TAG,
    TeamPrivilege.DELETE_VIDEO_TAG,
    TeamPrivilege.ASSIGN_VIDEO_TAG,
    TeamPrivilege.REMOVE_VIDEO_TAG,
  ],
};

const commentPrivileges: IPrivilegeGroup = {
  groupName: 'common:rolegroup.comments',
  privileges: [
    TeamPrivilege.CREATE_VIDEO_COMMENT,
    TeamPrivilege.UPDATE_VIDEO_COMMENT,
    TeamPrivilege.DELETE_VIDEO_COMMENT,
    TeamPrivilege.CREATE_VIDEO_COMMENT_REPLY,
    TeamPrivilege.UPDATE_VIDEO_COMMENT_REPLY,
    TeamPrivilege.DELETE_VIDEO_COMMENT_REPLY,
    // TeamPrivilege.MODERATE_COMMENT,
    TeamPrivilege.ASSIGN_VIDEO_COMMENT_TAG,
    TeamPrivilege.REMOVE_VIDEO_COMMENT_TAG,
    TeamPrivilege.CREATE_VIDEO_COMMENT_TAG,
    TeamPrivilege.DELETE_VIDEO_COMMENT_TAG,
  ],
};

const sessionPrivileges: IPrivilegeGroup = {
  groupName: 'common:rolegroup.sessions',
  privileges: [
    TeamPrivilege.START_SESSION,
  ],
};

const divisionPrivileges: IPrivilegeGroup = {
  groupName: 'common:rolegroup.channel',
  privileges: [
    TeamPrivilege.CREATE_DIVISION,
    TeamPrivilege.UPDATE_DIVISION,
    TeamPrivilege.DELETE_DIVISION,
    // TeamPrivilege.ACCESS_DIVISION,
  ],
};

const folderPrivileges: IPrivilegeGroup = {
  groupName: 'common:rolegroup.folder',
  privileges: [
    TeamPrivilege.CREATE_FOLDER,
    TeamPrivilege.UPDATE_FOLDER,
    TeamPrivilege.DELETE_FOLDER,
    // TeamPrivilege.ACCESS_FOLDER,
    TeamPrivilege.CREATE_PRIVATE_FOLDER,
    TeamPrivilege.UPDATE_PRIVATE_FOLDER,
    TeamPrivilege.DELETE_PRIVATE_FOLDER,
    TeamPrivilege.ADD_PRIVATE_FOLDER_USER,
    TeamPrivilege.REMOVE_PRIVATE_FOLDER_USER,
  ],
};

const organizationPrivileges: IPrivilegeGroup = {
  groupName: 'common:rolegroup.organization',
  privileges: [
    TeamPrivilege.UPDATE_TEAM,
    TeamPrivilege.SEND_TEAM_INVITATION,
    TeamPrivilege.DELETE_INVITATION,
    TeamPrivilege.REMOVE_MEMBER,
    TeamPrivilege.CREATE_ROLE,
    TeamPrivilege.UPDATE_ROLE,
    TeamPrivilege.DELETE_ROLE,
    TeamPrivilege.ASSIGN_ROLE,
  ],
};

const billingPrivileges: IPrivilegeGroup = {
  groupName: 'common:rolegroup.billing',
  privileges: [
    TeamPrivilege.ACCESS_BILLING,
    TeamPrivilege.CANCEL_SUBSCRIPTION,
    TeamPrivilege.UPDATE_SUBSCRIPTION,
    TeamPrivilege.CREATE_SUBSCRIPTION,
  ],
};

export const proPrivilegeGroups: IPrivilegeGroup[] = [
  proVideoPrivileges,
  tagPrivileges,
  commentPrivileges,
  organizationPrivileges,
  sessionPrivileges,
  divisionPrivileges,
  folderPrivileges,
  billingPrivileges,
];

export const litePrivilegeGroups: IPrivilegeGroup[] = [
  liteVideoPrivileges,
  tagPrivileges,
  commentPrivileges,
  organizationPrivileges,
  sessionPrivileges,
  divisionPrivileges,
  folderPrivileges,
  billingPrivileges,
];

export const collaboratorPrivileges: IPrivilegeGroup[] = [
  collaboratorVideoPrivileges,
  collaboratorCommentPrivileges,
];
