import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo } from 'react';
import { ID } from 'types/pigeon';

import { makeGetTeamSearchQueryByTeamId } from './search-selectors';
import { useSearchHandlers } from './useSearchHandlers';

interface FilterDateButtonOwnProps {
  className?: string;
  teamId: ID;
  option: number;
}

type FilterDateButtonProps = FilterDateButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'capitalize',
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightLight,
    padding: theme.spacing(0.25, 0.5),
    border: '1px solid white',
    '&$selected': {
      border: 'none',
    },
  },
  selected: {},
}), {name: 'FilterDateButton'});

function FilterDateButton(props: FilterDateButtonProps) {
  const classes = useStyles(props);
  const { className, teamId, option } = props;
  const { t } = useStrictTranslation(['search']);

  const {
    handleFilterDateButtonOnClick,
  } = useSearchHandlers(teamId);

  const teamSearchQuery = useCreateSelector(makeGetTeamSearchQueryByTeamId, teamId);

  const selected = useMemo(() => {
    return teamSearchQuery?.filterDatePreset === option;
  }, [option, teamSearchQuery?.filterDatePreset]);

  const buttonText = useMemo(() => {
    return t(option === 0 ? 'search:everything' : 'search:lastcountdays', { count: option });
  }, [option, t]);

  const onClick = useCallback(() => {
    handleFilterDateButtonOnClick(option);
  }, [handleFilterDateButtonOnClick, option]);

  return (
    <Button
    className={classNames(className, classes.root, {[classes.selected]: selected})}
    onClick={onClick}
    color={selected ? 'primary' : undefined}
    variant={selected ? 'contained' : 'outlined'}
    >
      {buttonText}
    </Button>
  );
}

export default React.memo(FilterDateButton);
