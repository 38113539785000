import { Theme } from '@insights-gaming/theme';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import EllipsisListItemText from 'material/ellipsis-list-item-text/EllipsisListItemText';
import React, { forwardRef, PropsWithChildren, Ref } from 'react';

interface DivisionListItemOwnProps {
  className?: string;
  name: React.ReactNode;
  muted?: React.ReactNode;
  divisionIcon?: React.ReactNode;
  contextMenu?: React.ReactNode;
  onClick?: React.MouseEventHandler;
}

type DivisionListItemProps = PropsWithChildren<DivisionListItemOwnProps & Pick<ListItemProps, 'selected'>>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DivisionListItem'});

const DivisionListItem = forwardRef<
  HTMLDivElement,
  DivisionListItemProps
>(function DivisionListItem(props: DivisionListItemProps, ref: Ref<HTMLDivElement>) {
  const classes = useStyles(props);
  const { children, className, name, contextMenu, onClick, selected, muted, divisionIcon, ...rest } = props;
  return (
    <ListItem
    ref={ref}
    {...rest}
    className={classNames(classes.root, className)}
    button={true}
    onClick={onClick}
    selected={selected}
    >
      {divisionIcon}
      <EllipsisListItemText primary={name} />
      {muted}
      {children}
      {contextMenu && (
        <ListItemSecondaryAction>
          {contextMenu}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
});
DivisionListItem.displayName = 'DivisionListItem';

export default React.memo(DivisionListItem);
