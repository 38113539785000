import gql from 'graphql-tag';

import { MetadataEntryFragment } from '../fragments';

export const RemoveVideoMetadata_Mutation = gql`
    mutation RemoveVideoMetadataMutation(
        $input: RemoveVideoMetadataInput!
    ) {
        removeVideoMetadata(
            input: $input
        ) {
            video {
                id
                userMetadata {
                    ...MetadataEntryFragment
                }
            }
        }
    }
    ${MetadataEntryFragment}
`;
