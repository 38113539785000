import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { LocalTusUpload } from 'types';
import { ID } from 'types/pigeon';

import { getVideoUuidFromProps } from './upload-selector';

export const getResumableUploadsDict = (state: RootReducer) => state.resumableUploadsReducer.resumable;
const getVideoIdFromProps = (_: RootReducer, props: { id: ID }): ID => props.id;

export function getIsLocalTusUploadsInitialized(state: RootReducer): boolean {
  return !!state.resumableUploadsReducer.resumable;
}

const getLocalTusUpload = (state: RootReducer, videoId: ID) => {
  if (videoId in state.uploadReducer.startedUploads || !state.resumableUploadsReducer.resumable) {
    return;
  }

  return Object.values(state.resumableUploadsReducer.resumable)
    .filter((uploads): uploads is LocalTusUpload[] => Array.isArray(uploads))
    .flat()
    .find((upload) => upload.video.id === videoId);
};

export const makeGetLocalTusUpload = () => createSelector(
  [getLocalTusUpload],
  (upload) => upload,
);

function getPendingUploadByVideoId(state: RootReducer, videoId: ID) {
  return state.resumableUploadsReducer.pending[videoId];
};

export const makeGetPendingUploadByVideoId = () => createSelector(
  [getPendingUploadByVideoId],
  (upload) => upload,
);

function getStartedUploadByVideoId(state: RootReducer, videoId: ID) {
  return state.uploadReducer.startedUploads[videoId];
};

function getStartedUpload(state: RootReducer) {
  return state.uploadReducer.startedUploads;
};

function getUploadedVideos(state: RootReducer) {
  return state.uploadReducer.uploadedVideos;
};

function getUploadQueueDict(state: RootReducer) {
  const { uploadReducer: { uploadQueue } } = state;

  const entries: Array<[string, { uuid: string; file: File }]> = [];

  for (const { video: { uuid, file } } of uploadQueue) {
    entries.push([uuid, { uuid, file }]);
  }

  return Object.fromEntries(entries);
};

function getIsVideoUploaded(state: RootReducer, id: ID) {
  return !!Object.values(state.uploadReducer.uploadedVideos).find(({ videoId }) => videoId === id);
}

export const makeGetHasStartedUpload = () => createSelector(
  [getStartedUpload],
  (upload) => !!Object.keys(upload).length,
);

export const makeGetStartedUploadByVideoId = () => createSelector(
  [getStartedUpload, getVideoIdFromProps],
  (dict, id) => id ? dict[id] : undefined,
);

export const makeGetUploadedVideoByVideoUuid = () => createSelector(
  [getUploadedVideos, getVideoUuidFromProps],
  (dict, Uuid) => Uuid ? dict[Uuid] : undefined,
);

export const makeGetUploadQueueByVideoUuid = () => createSelector(
  [getUploadQueueDict, getVideoUuidFromProps],
  (dict, Uuid) => Uuid ? dict[Uuid] : undefined,
);

export const makeGetIsVideoUploaded = () => createSelector(
  [getIsVideoUploaded],
  (isVideoUploaded) => isVideoUploaded,
);
