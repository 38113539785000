import gql from 'graphql-tag';

import { AnalysisFragment } from '../fragments';

export const CreateAnalysisRequest_Mutation = gql`
    mutation CreateAnalysisRequestMutation(
        $input: CreateAnalysisRequestInput!
    ) {
        createAnalysisRequest(
            input: $input
        ) {
            analysis {
                ...AnalysisFragment
            }
        }
    }
    ${AnalysisFragment}
`;
