import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetTeamTagRecordsByTeamId } from './dashboard-tag-selector';
import { fetchTagsAC } from './dashboard-tag-slice';

export const useFetchTeamTags = createBidirectionalAutoFetcher({
  actionCreators: fetchTagsAC,
  selectorFactory: makeGetTeamTagRecordsByTeamId,
  paramsTransform: fetchId => ({teamId: fetchId}),
  pageSize: 10,
});
