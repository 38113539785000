import { Theme } from '@insights-gaming/theme';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import React from 'react';

import NotificationListItem from './NotificationListItem';

interface NotificationListItemSkeletonOwnProps {
  className?: string;
}

type NotificationListItemSkeletonProps = NotificationListItemSkeletonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'NotificationListItemSkeleton'});

function NotificationListItemSkeleton(props: NotificationListItemSkeletonProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <NotificationListItem
    className={classNames(classes.root, className)}
    avatar={(
      <ListItemAvatar>
        <Skeleton variant='circle' width={40} height={40} />
      </ListItemAvatar>
    )}
    message={(
      <ListItemText
      primary={<Skeleton variant='text' />}
      secondary={<Skeleton variant='text' />}
      />
    )}
    />
  );
}

export default React.memo(NotificationListItemSkeleton);
