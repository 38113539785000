import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import clamp from 'lodash/clamp';
import React, { useCallback, useRef, useState } from 'react';

export interface DraggableContainerProps {
  className?: string;
  disableDrag?: boolean;
  children?: JSX.Element;

  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};

const useStyles = makeStyles(() => createStyles({
  dragArea: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
  },
  container: {
    position: 'absolute',
    display: 'inline-block',
    zIndex: 5, // To be able to click and drag.
    pointerEvents: 'auto',
  },
  grabbable: {
    cursor: 'grab',
  },
  grabbing: {
    cursor: 'grabbing',
  },
}), { name: 'DraggableContainer' });

function DraggableContainer({
  disableDrag,
  children,
  className,
  top,
  right,
  bottom,
  left,
}: DraggableContainerProps) {
  const classes = useStyles();

  const rootRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement | null>();

  const refProxy: React.Ref<HTMLDivElement | null> = useCallback((elem: HTMLDivElement | null) => {
    if (elem) {
      if (typeof top === 'number') {
        elem.style.top = top + 'px';
      }

      if (typeof right === 'number') {
        elem.style.right = right + 'px';
      }

      if (typeof bottom === 'number') {
        elem.style.bottom = bottom + 'px';
      }

      if (typeof left === 'number') {
        elem.style.left = left + 'px';
      }


      ref.current = elem;
    }
  }, [bottom, left, right, top]);

  const [grabbing, setGrabbing] = useState(false);

  const onMouseDown = useCallback(({ target, clientX: startX, clientY: startY }: React.MouseEvent<HTMLDivElement>) => {
    if (!ref.current || !rootRef.current) {
      return;
    }

    const rect = ref.current.getBoundingClientRect();
    if (
      startX < rect.left || rect.left + rect.width < startX ||
      startY < rect.top || rect.top + rect.height < startY
    ) {
      return;
    }

    const targetRect = (target as Element).getBoundingClientRect();
    if (
      targetRect.left < rect.left ||
      targetRect.top < rect.top ||
      targetRect.width > rect.width ||
      targetRect.height > rect.height
    ) {
      return;
    }

    const rootRect = rootRef.current.getBoundingClientRect();

    const topOffset = rect.top || 0;
    const leftOffset = rect.left || 0;

    const dragHandler = ({ clientX: currentX, clientY: currentY }: MouseEvent) => {
      if (!ref.current || !rootRef.current) {
        return;
      }

      setGrabbing(true);

      const top  = clamp(topOffset - rootRect.top - startY + currentY, 0, rootRect.height - rect.height);
      const left = clamp(leftOffset - rootRect.left - startX + currentX, 0, rootRect.width - rect.width);

      ref.current.style.top    = top + 'px';
      ref.current.style.left   = left + 'px';
      ref.current.style.bottom = 'unset';
      ref.current.style.right  = 'unset';
    };

    const releaseHandler = (e: MouseEvent) => {
      e.preventDefault();

      setGrabbing(false);

      document.removeEventListener('mousemove', dragHandler);
      document.removeEventListener('mouseup', releaseHandler);
    };

    document.addEventListener('mousemove', dragHandler);
    document.addEventListener('mouseup', releaseHandler);
  }, [setGrabbing]);

  return (
    <div
    className={classes.dragArea}
    ref={rootRef}
    >
      <div
      className={classNames(
        {
          [classes.grabbing]: grabbing,
          [classes.grabbable]: !disableDrag && !grabbing,
        },
        classes.container,
        className,
      )}
      ref={refProxy}
      onMouseDown={disableDrag ? undefined : onMouseDown}
      >
        {children}
      </div>
    </div>
  );
}

export default React.memo(DraggableContainer);

