import gql from 'graphql-tag';

import { VideoFragment } from '../fragments';

export const UpdateVideo_Mutation = gql`
    mutation UpdateVideoMutation(
        $input: UpdateVideoInput!
    ) {
        updateVideo(
            input: $input
        ) {
            video {
                ...VideoFragment
            }
        }
    }
    ${VideoFragment}
`;
