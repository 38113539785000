import { VideoReplayContext } from '@insights-gaming/material-components';
import { useContext, useEffect, useRef, useState } from 'react';

import { CommentFragment_VideoComment } from '../../../apollo/fragments/types/CommentFragment';

export function useCommentPassed(
  time: number,
  comments: CommentFragment_VideoComment[],
  onCommentPassed?: (comment: CommentFragment_VideoComment) => void,
  isPlaying?: boolean,
) {
  const upcomingCommentRef = useRef<CommentFragment_VideoComment>();
  const fnRef = useRef(onCommentPassed);
  fnRef.current = onCommentPassed;

  const {
    pause,
    seekTo,
  } = useContext(VideoReplayContext);

  const [ hasTimeEnd, setHasTimeEnd ] = useState(false);

  useEffect(() => {
    if (isPlaying && upcomingCommentRef.current) {
      if (!hasTimeEnd && Math.ceil(time * 1000) / 1000 >= upcomingCommentRef.current.time) {
        fnRef.current?.(upcomingCommentRef.current);
        upcomingCommentRef.current = undefined;
      } else if (hasTimeEnd && upcomingCommentRef.current.timeEnd && time >= upcomingCommentRef.current.timeEnd) {
        pause();
        seekTo({amount: upcomingCommentRef.current.timeEnd, type: 'seconds', seekCommitted: true});
        upcomingCommentRef.current = undefined;
      }
    }
  }, [hasTimeEnd, isPlaying, pause, seekTo, time]);

  useEffect(() => {
    for (const comment of comments) {
      if (upcomingCommentRef.current && upcomingCommentRef.current.timeEnd) {
        if (comment.time > time && comment.time < upcomingCommentRef.current.timeEnd) {
          setHasTimeEnd(false);
          upcomingCommentRef.current = comment;
          break;
        }
        continue;
      }
      if (upcomingCommentRef.current && upcomingCommentRef.current.time < time) {
        break;
      }
      if (comment.time > time) {
        setHasTimeEnd(false);
        upcomingCommentRef.current = comment;
        break;
      } else if (comment.timeEnd && comment.timeEnd > time) {
        setHasTimeEnd(true);
        upcomingCommentRef.current = comment;
        continue;
      }
    }
  }, [comments, time]);
}
