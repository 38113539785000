import React from 'react';
import { Redirect } from 'react-router';
import { SIGNIN_PATH } from 'routes';

interface SigninRedirectOwnProps {
  code?: string;
}

type SigninRedirectProps = SigninRedirectOwnProps;

function SigninRedirect({code}: SigninRedirectProps) {
  const referPath = window.location.pathname + window.location.search + window.location.hash;

  return (
    <Redirect
    to={{
      pathname: SIGNIN_PATH,
      state: {
        referPath,
        redirectPath: referPath,
        code,
      },
    }}
    />
  );
}

export default SigninRedirect;
