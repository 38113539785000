import { AnyAction } from 'typescript-fsa';

export function withActionMetadata<A extends AnyAction & { meta?: any }>(action: A, meta: object): A {
  return {
    ...action,
    meta: {
      ...action.meta,
      ...meta,
    },
  };
}
