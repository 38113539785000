import { useVideoReplayContextValue as useVideoReplayContextValueInternal } from '@insights-gaming/material-components';
import { VIDEO_MUTED, VIDEO_VOLUME } from 'constants/strings';
import { getLocalStorageBool, getLocalStorageFloat } from 'helpers/storage';

import { useVideoReplayDrawingToolBindings } from './useVideoReplayDrawingToolBindings';

export function useVideoReplayContextValue() {
  return useVideoReplayDrawingToolBindings(
    useVideoReplayContextValueInternal({
      volume: getLocalStorageFloat(VIDEO_VOLUME, 0.5),
      muted: getLocalStorageBool(VIDEO_MUTED, false),
      playing: false,
    }),
  );
}
