import { PriceFragment } from 'apollo/fragments/types/PriceFragment';
import { useMemo } from 'react';
import { ProductInput } from 'types/graphql';
import { Billing } from 'types/pigeon';

import { getBundlePrice } from './getBundlePrice';
import useAvailableMetrics, { CustomPlanProductKind } from './useAvailableMetrics';

function createProductInput({ id, name }: PriceFragment, quantity: number): ProductInput {
  return { id, name, quantity };
}

export default function useProductInputs(
  input: Billing.InputQuantities | undefined,
  getBundle: Billing.BundleGetter | undefined,
  customPlanQuantities?: Partial<Record<CustomPlanProductKind, number>>,
): ProductInput[] | undefined {
  const { metrics, customPlans } = useAvailableMetrics(getBundle);

  const checkoutProduct = useMemo(() => {
    if (customPlanQuantities) {
      return Object.values(customPlans).map(({ plan }) => plan);
    } else if (input) {
      return metrics.find((m) => m.productId === input.productId);
    }
    return;
  }, [customPlanQuantities, customPlans, input, metrics]);

  return useMemo(() => {
    if (!input || !getBundle) {
      return;
    }

    if (Array.isArray(checkoutProduct)) {
      return checkoutProduct.map((plan) => createProductInput(
        getBundlePrice(getBundle, plan, plan.kind, input.interval),
        customPlanQuantities?.[plan.kind] ?? 1,
      ));
    }

    if (!checkoutProduct?.kind) {
      return;
    }

    return [
      createProductInput(getBundlePrice(getBundle, checkoutProduct, checkoutProduct.kind, input.interval), 1),
    ];
  }, [checkoutProduct, customPlanQuantities, getBundle, input]);
}
