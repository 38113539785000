import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { registerRoute, signinRoute } from 'routes';
import { getMe } from 'selectors/getMe';
import NotepadEditor from 'subcomponents/notepad-editor/NotepadEditor';
import { ID } from 'types/pigeon';

interface NotePanelOwnProps {
  className?: string;
  videoId: ID;
}

type NotePanelProps = NotePanelOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  noAccess: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[700]}`,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.deemphasized,
  },
}), {name: 'NotePanel'});

function NotePanel(props: NotePanelProps) {
  const classes = useStyles(props);
  const { className, videoId } = props;

  const { t } = useStrictTranslation(['video']);
  const me = useSelector(getMe);
  const { canUpdateVod } = useAccessControl();

  const renderNoPrivilege = useCallback((type?: 'note' | string) => {
    let msg: string;
    const state = {referPath: window.location.pathname};
    switch (type) {
      case 'note':
        msg = t('video:replay.notepadviewonly');
        break;
      default:
        msg = t('video:replay.viewonly');
        break;
    }
    return (
      <Typography variant='body2' component='div' className={classes.noAccess}>
        {me ? (
          msg
        ) : (
          <Trans ns='video' i18nKey='video:replay.publicviewonly'>
            Want to contribute? Please
            <Link to={{
              pathname: registerRoute(),
              state,
            }}
            >
              sign up
            </Link>
            or
            <Link to={{
              pathname: signinRoute(),
              state,
            }}
            >
              sign in
            </Link>
            to Insights.
          </Trans>
        )}
      </Typography>
    );
  }, [classes.noAccess, me, t]);

  return (
    <FlexSpacer orientation='vertical' className={classNames(classes.root, className)}>
      <NotepadEditor
      me={me}
      videoId={videoId}
      readOnly={!canUpdateVod}
      fillFullHeight={true}
      renderNoPrivilege={renderNoPrivilege}
      />
    </FlexSpacer>
  );
}

export default React.memo(NotePanel);
