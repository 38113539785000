import React from 'react';

import { createSelectState, UseMultiSelectState } from './useMultiSelectState';

export interface DashboardMultiSelectState {
  folders: UseMultiSelectState;
  videos: UseMultiSelectState;
}

export const DashboardMultiSelectContext = React.createContext<DashboardMultiSelectState>({
  folders: createSelectState(),
  videos: createSelectState(),
});

export const {
  Provider: DashboardMultiSelectProvider,
  Consumer: DashboardMultiSelectConsumer,
} = DashboardMultiSelectContext;
