import { makeStyles } from '@material-ui/core/styles';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import classNames from 'classnames';
import React from 'react';

type FixedTableCellProps = TableCellProps & {
  width?: number;
}

const useStyles = makeStyles({
  root: ({ width = 250 }: FixedTableCellProps) => ({
    width,
    minWidth: width,
    maxWidth: width,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
}, {name: 'FixedTableCell'});

function FixedTableCell(props: FixedTableCellProps) {
  const classes = useStyles(props);
  const { className, ...rest } = props;
  return (
    <TableCell {...rest} className={classNames(classes.root, className)} />
  );
}

export default FixedTableCell;
