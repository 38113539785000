import { TagFragment } from 'apollo/fragments/types/TagFragment';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { SearchResultType } from 'types/graphql';

import { ID } from '../../types/pigeon/index';
import { addSelectedTagAC, clearSearchQueryAC, clearTeamSelectedTagsAC, ESearchFilterDateOptions, handleFilterDateOnChangeAC, removeSelectedTagAC, searchInputOnChangeAC, switchSearchTabAC } from './search-slice';

interface SearchHandlers {
  clearQueryOnClick: VoidFunction;
  addTagOnClick: (t: TagFragment) => void;
  removeTagOnClick: (t: TagFragment) => void;
  handleFilterDateOnChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleFilterDateButtonOnClick: (date: number) => void;
  handleTabOnClick: (e: React.ChangeEvent, newValue: SearchResultType) => void;
  handleSearchInputOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearAllSelectedTags: VoidFunction;
}

function makeSearchFilterDateOption(v: string): ESearchFilterDateOptions {
  switch (v) {
    case '0':
      return ESearchFilterDateOptions.DEFAULT;

    case '7':
      return ESearchFilterDateOptions.SEVEN;

    case '14':
      return ESearchFilterDateOptions.FOURTEEN;

    case '30':
      return ESearchFilterDateOptions.THIRTY;

    default:
      return ESearchFilterDateOptions.DEFAULT;
  }
}

export function useSearchHandlers(teamId: ID): SearchHandlers {
  const dispatch = useDispatch();

  const addTagOnClick = useCallback((tag: TagFragment) => {
    dispatch(addSelectedTagAC({ teamId, tag }));
  }, [dispatch, teamId]);

  const removeTagOnClick = useCallback((tag: TagFragment) => {
    dispatch(removeSelectedTagAC({ teamId, tag }));
  }, [dispatch, teamId]);

  const handleFilterDateOnChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    dispatch(handleFilterDateOnChangeAC({ teamId, filterDateOption: makeSearchFilterDateOption(String(value)) }));
  }, [dispatch, teamId]);

  const handleFilterDateButtonOnClick = useCallback((date: number) => {
    dispatch(handleFilterDateOnChangeAC({ teamId, filterDateOption: makeSearchFilterDateOption(String(date)) }));
  }, [dispatch, teamId]);

  const clearQueryOnClick = useCallback(() => dispatch(clearSearchQueryAC({ teamId })), [dispatch, teamId]);
  const clearAllSelectedTags = useCallback(() => dispatch(clearTeamSelectedTagsAC({ teamId })), [dispatch, teamId]);

  const startSearchQuery = useCallback(
    (query: string) => dispatch(searchInputOnChangeAC({ teamId, query })),
    [dispatch, teamId],
  );

  const handleSearchInputOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    startSearchQuery(e.target.value);
  }, [startSearchQuery]);

  const handleTabOnClick = useCallback((e: React.ChangeEvent, newValue: SearchResultType) => {
    dispatch(switchSearchTabAC({ teamId, tab: newValue }));
  }, [dispatch, teamId]);

  return useMemo(() => ({
    handleSearchInputOnChange,
    clearQueryOnClick,
    addTagOnClick,
    removeTagOnClick,
    handleFilterDateOnChange,
    handleFilterDateButtonOnClick,
    handleTabOnClick,
    clearAllSelectedTags,
  } as const), [
    handleSearchInputOnChange,
    clearQueryOnClick,
    addTagOnClick,
    removeTagOnClick,
    handleFilterDateOnChange,
    handleFilterDateButtonOnClick,
    handleTabOnClick,
    clearAllSelectedTags,
  ]);
}
