import { Theme } from '@insights-gaming/theme';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import EllipsisListItemText from 'material/ellipsis-list-item-text/EllipsisListItemText';
import React, { forwardRef, PropsWithChildren } from 'react';

interface MemberListItemOwnProps {
  className?: string;
  name: React.ReactNode;
  avatar?: React.ReactNode;
  secondaryAction?: React.ReactNode;
}

type MemberListItemProps = PropsWithChildren<MemberListItemOwnProps>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'MemberListItem'});

const MemberListItem = forwardRef<HTMLLIElement, MemberListItemProps>(function MemberListItem(
  props: MemberListItemProps,
  ref,
) {
  const classes = useStyles(props);
  const { avatar, className, secondaryAction, name, children } = props;
  return (
    <ListItem ref={ref} className={classNames(classes.root, className)}>
      {avatar}
      <EllipsisListItemText primary={name} />
      {secondaryAction && (
        <ListItemSecondaryAction>
          {secondaryAction}
        </ListItemSecondaryAction>
      )}
      {children}
    </ListItem>
  );
});
MemberListItem.displayName = 'MemberListItem';

export default React.memo(MemberListItem);
