import { useCreateSelector } from '@insights-gaming/redux-utils';
import { GetAvailableCouponQuery_coupon } from 'apollo/queries/types/GetAvailableCouponQuery';
import { makeGetAvailableTeamCouponByTeamIdAndCode } from 'features/dashboard/billing/dashboard-billing.selectors';
import { fetchAvailableCouponAC } from 'features/dashboard/billing/dashboard-billing-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { ID } from 'types/pigeon';

const noop = () => {};

export interface CustomCouponCodeContextValue {
  coupon?: GetAvailableCouponQuery_coupon;
  couponCode?: string;
  setCouponCode: (couponCode?: string) => void;
  loading?: boolean;
  error?: Error;
  handleApplyClick: VoidFunction;
  appliedCouponCode?: string;
}

export const CustomCouponCodeContext = createContext<CustomCouponCodeContextValue>({
  setCouponCode: noop,
  handleApplyClick: noop,
});

export const { Consumer: CustomCouponCodeConsumer, Provider: CustomCouponCodeProvider } = CustomCouponCodeContext;

export function useCustomCouponCodeContext(teamId: ID, productId?: ID): CustomCouponCodeContextValue {
  const [couponCode, setCouponCode] = useState<string>();
  const [appliedCouponCode, setAppliedCouponCode] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<Error>();
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const coupon = useCreateSelector(makeGetAvailableTeamCouponByTeamIdAndCode, {
    teamId,
    couponCode: appliedCouponCode,
  });

  useEffect(() => {
    if (coupon) {
      setError(undefined);
    }
  }, [coupon]);

  useEffect(() => {
    if (!couponCode) {
      setError(undefined);
    }
  }, [couponCode]);

  const handleApplyClick = useCallback(async () => {
    if (!couponCode || loading || !productId) {
      return;
    }
    setLoading(true);
    setAppliedCouponCode(couponCode);
    try {
      await promiseSagaDispatch(fetchAvailableCouponAC, {
        code: couponCode,
        currency: 'usd',
        productIds:
        [productId],
        teamId,
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [couponCode, loading, productId, promiseSagaDispatch, teamId]);

  return useMemo(() => ({
    coupon,
    setCouponCode,
    loading,
    couponCode,
    error,
    handleApplyClick,
    appliedCouponCode,
  }), [appliedCouponCode, coupon, couponCode, error, handleApplyClick, loading]);
}
