import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

const styles = (theme: Theme) => createStyles({
  root: {
    zIndex: 2, // So the text appears on top of the indicator
    maxWidth: 'unset',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  wrapper: {
    alignItems: 'flex-start',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(1),
    fontSize: createRemFromPx(14),
    fontWeight: 400,
  },
  textColorInherit: {
    opacity: 1,
  },
});

export default withStyles(styles, {name: 'SecondaryTab'})(Tab);
