import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { useMemo } from 'react';

import { isTruthy } from '../helpers';
import { AnalysisType } from '../types/graphql';

export function useStatsFeatures(type?: AnalysisType): {
  canAccess: boolean;
  canAggregate: boolean;
} {
  const {
    canAccessOverwatchStats,
    canAggregateOverwatchStats,
    canAccessSSBUStats,
    canAggregateSSBUStats,
  } = useAccessControl();

  return useMemo(() => {
    switch (type) {
      case AnalysisType.OVERWATCH:
        return {
          canAccess: canAccessOverwatchStats,
          canAggregate: canAggregateOverwatchStats,
        };
      case AnalysisType.SSB_ULTIMATE:
        return {
          canAccess: canAccessSSBUStats,
          canAggregate: canAggregateSSBUStats,
        };
      default:
        return {
          canAccess: false,
          canAggregate: false,
        };
    }
  }, [canAccessOverwatchStats, canAccessSSBUStats, canAggregateOverwatchStats, canAggregateSSBUStats, type]);
}

export function useTeamAnalysisFeatures() {
  const { canRequestOverwatchAnalysis, canRequestSSBUAnalysis } = useAccessControl();
  return useMemo(() => {
    return [
      canRequestOverwatchAnalysis && AnalysisType.OVERWATCH,
      canRequestSSBUAnalysis && AnalysisType.SSB_ULTIMATE,
    ].filter(isTruthy);
  }, [canRequestOverwatchAnalysis, canRequestSSBUAnalysis]);
}
