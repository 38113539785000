import { EnhancedDialogTitle } from '@insights-gaming/material-components';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import TagListItem from 'components/tag-list/TagListItem';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useState } from 'react';
import { ID, ITag } from 'types/pigeon';

import DeleteTagDialog from '../delete-tag-dialog/DeleteTagDialog';
import TagEditorDialog from '../tag-editor-dialog/TagEditorDialog';

interface TagManagerDialogOwnProps {
  onClose?: VoidFunction;
  teamId: ID;
  tags: ITag[];
  isLabel?: boolean;
}

type TagManagerDialogProps = TagManagerDialogOwnProps & Pick<DialogProps, 'open'>;

function TagManagerDialog(props: TagManagerDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose} maxWidth='xs'>
      <TagManagerDialogContent {...props} />
    </Dialog>
  );
}

function TagManagerDialogContent(props: TagManagerDialogProps) {
  const { teamId, tags, onClose, isLabel } = props;
  const { t } = useStrictTranslation(['common', 'dialog']);

  const [editTag, setEditTag] = useState<ITag>();
  const [deleteTag, setDeleteTag] = useState<ITag>();
  const [isTagEditorDialogOpen, openTagEditorDialog, closeTagEditorDialog] = useDialogState();
  const [isDeleteTagDialogOpen, openDeleteTagDialog, closeDeleteTagDialog] = useDialogState();

  const { canCreateTag, canUpdateTag, canDeleteTag } = useAccessControl();

  const handleCreateTag = useCallback(() => {
    openTagEditorDialog();
    setEditTag(undefined);
  }, [openTagEditorDialog]);

  const handleEditTag = useCallback((tag: ITag) => () => {
    openTagEditorDialog();
    setEditTag(tag);
  }, [openTagEditorDialog]);

  const handleDeleteTag = useCallback((tag: ITag) => () => {
    setDeleteTag(tag);
    openDeleteTagDialog();
  }, [openDeleteTagDialog]);

  return (
    <React.Fragment>
      <EnhancedDialogTitle onClose={onClose}>
        {t(isLabel ? 'dialog:managelabels.title' : 'dialog:managetags.title')}
      </EnhancedDialogTitle>
      <DialogContent>
        <List>
          {canCreateTag && (
            <ListItem button={true} onClick={handleCreateTag}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={t(isLabel ? 'dialog:createlabel.title' :'common:createtag')} />
            </ListItem>
          )}
          {tags.map(tag => (
            <TagListItem
            key={tag.id}
            name={tag.name}
            color={tag.color}
            onClick={canUpdateTag ? handleEditTag(tag) : undefined}
            showSecondaryAction={canDeleteTag}
            secondaryAction={(
              <Tooltip title={t('dialog:deletetags.confirm')}>
                <IconButton onClick={handleDeleteTag(tag)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            />
          ))}
        </List>
      </DialogContent>
      <TagEditorDialog
      teamId={teamId}
      tag={editTag}
      open={isTagEditorDialogOpen}
      onClose={closeTagEditorDialog}
      isLabel={isLabel}
      />
      {deleteTag && (
        <DeleteTagDialog
        teamId={teamId}
        tag={deleteTag}
        open={isDeleteTagDialogOpen}
        onClose={closeDeleteTagDialog}
        isLabel={isLabel}
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(TagManagerDialog);
