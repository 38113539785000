import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Rating } from '@material-ui/lab';
import { insightsCaptureLogo } from 'assets';
import { INSIGHTS_CAPTURE_DOWNLOAD } from 'constants/index';
import { createUTMParams } from 'helpers/createUTMParams';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { Trans } from 'react-i18next';

interface GuestCommentPanelOwnProps {
  className?: string;
}

type GuestCommentPanelProps = GuestCommentPanelOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  image: {
    height: createRemFromPx(30),
  },
  paragraph: {
    textTransform: 'uppercase',
  },
  numberOfUsers: {
    fontSize: createRemFromPx(20),
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
  downloadAppButton: {
    textTransform: 'capitalize',
    margin: 0,
  },
}));

const numberOfUsers = '1.6M';

function GuestCommentPanel(props: GuestCommentPanelProps) {
  const { className } = props;
  const classes = useStyles(props);

  const { t } = useStrictTranslation(['common', 'video']);

  const downloadAppUTM = '&' + createUTMParams('insightsgg', 'video_replay_side', 'ic_download');

  return (
    <FlexSpacer flexAlignItems='center' orientation='vertical' >
      <img className={classes.image} src={insightsCaptureLogo} alt='insights-capture-logo' />

      <Typography className={classes.paragraph}>
        <Trans ns='video' i18nKey='replay.publicviewonlycommentpaneltitle'>
          Record and Review made <i>easy</i>
        </Trans>
      </Typography>

      <Typography>
        <Trans ns='video' i18nKey='replay.publicviewonlycommentpaneldesc' values={{ count: numberOfUsers }}>
          Over <strong className={classes.numberOfUsers}>{numberOfUsers}</strong> gamers
        </Trans>
      </Typography>

      <Button
      className={classes.downloadAppButton}
      color='primary'
      variant='contained'
      size='small'
      href={INSIGHTS_CAPTURE_DOWNLOAD.url + downloadAppUTM}
      download={INSIGHTS_CAPTURE_DOWNLOAD.name}
      >
        {t('video:replay.downloadapp')}
      </Button>

      <FlexSpacer orientation='vertical' flexAlignItems='center'>
        <Rating name='rating' defaultValue={5} precision={0.1} size='large' readOnly={true} />
        <Typography>
          {t('video:replay.publicviewonlyrating', { rating: 5, number: 200 })}
        </Typography>
      </FlexSpacer>
    </FlexSpacer>
  );
}

export default React.memo(GuestCommentPanel);
