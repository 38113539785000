import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { ProfileFragment } from 'apollo/fragments/types/ProfileFragment';
import classNames from 'classnames';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import UserAvatar from 'material/user-avatar/UserAvatar';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { dashboardRoute } from 'routes';
import { getMe } from 'selectors/getMe';
import { ID } from 'types/pigeon';

import { useAccessControl } from '../access-control/useAccessControl';
import { leaveDirectoryAC,removePrivateDirectoryUsersAC } from '../directory/dashboard-directory-slice';
import ConvertCollaboratorDialog from './ConvertCollaboratorDialog';

interface CollaboratorItemOwnProps {
  className?: string;
  collaborator: ProfileFragment;
  directoryId: ID;
  teamId: ID;
  hasAdminRole?: boolean;
}

type CollaboratorItemProps = CollaboratorItemOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'CollaboratorItem'});

function CollaboratorItem(props: CollaboratorItemProps) {
  const classes = useStyles(props);
  const { className, collaborator, directoryId, teamId, hasAdminRole } = props;
  const { canRemovePrivateFolderUser } = useAccessControl();
  const { t } = useStrictTranslation(['common', 'dialog', 'dashboard-directory']);
  const [ removeLoading, setRemoveLoading ] = useState(false);
  const [leaveLoading, setLeaveLoading] = useState(false);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const onNavigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const me = useSelector(getMe);

  const [
    isRemoveCollaboratorDialogOpen,
    openRemoveCollaboratorDialog,
    closeRemoveCollaboratorDialog,
  ] = useDialogState();

  const [
    isConvertCollaboratorToMemberDialogOpen,
    openConvertCollaboratorToMemberDialog,
    closeConvertCollaboratorToMemberDialog,
  ] = useDialogState();

  const [
    isLeaveDirectoryDialogOpen,
    openLeaveDirectoryDialog,
    closeLeaveDirectoryDialog,
  ] = useDialogState();

  const handleRemoveCollaborator = useCallback(async () => {
    if (removeLoading) { return; }
    setRemoveLoading(true);
    try {
      await promiseSagaDispatch(removePrivateDirectoryUsersAC, {
        directoryId,
        teamId,
        userIds: [collaborator.id],
      });
      enqueueSnackbar(t('dialog:removecollaborator.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('dialog:removecollaborator.failure'), {variant: 'error'});
    } finally {
      setRemoveLoading(false);
    }
  }, [removeLoading, enqueueSnackbar, t, promiseSagaDispatch, directoryId, teamId, collaborator]);

  const handleLeaveDirectoryOnClick = useCallback(async () => {
    if (leaveLoading) { return; }
    setLeaveLoading(true);
    try {
      await promiseSagaDispatch(leaveDirectoryAC, {id: directoryId, teamId});
      closeLeaveDirectoryDialog();
      onNavigate(dashboardRoute());
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLeaveLoading(false);
    }
  }, [leaveLoading, promiseSagaDispatch, directoryId, teamId, closeLeaveDirectoryDialog, onNavigate, enqueueSnackbar]);

  return (
    <FlexSpacer
    className={classNames(classes.root, className)}
    flexJustifyContent='space-between'
    >
      <FlexSpacer flexAlignItems='center'>
        <UserAvatar user={collaborator} size='sm' />
        <div>{collaborator.alias}</div>
      </FlexSpacer>
      <FlexSpacer spacing={0.5}>
        {hasAdminRole && (
          <Tooltip title={t('dialog:convertcollaborator.confirm')} placement='top'>
            <IconButton onClick={openConvertCollaboratorToMemberDialog}>
              <PersonAddIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        )}
        {canRemovePrivateFolderUser && (
          <Tooltip title={t('dialog:removecollaborator.title')} placement='top'>
            <IconButton onClick={openRemoveCollaboratorDialog}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        )}
        {collaborator.id === me?.id && (
          <IconButton onClick={openLeaveDirectoryDialog}>
            <ExitToAppIcon fontSize='small' />
          </IconButton>
        )}
      </FlexSpacer>
      <ConvertCollaboratorDialog
      open={isConvertCollaboratorToMemberDialogOpen}
      directoryId={directoryId}
      teamId={teamId}
      userId={collaborator.id}
      onClose={closeConvertCollaboratorToMemberDialog}
      />
      <Dialog
      open={isRemoveCollaboratorDialogOpen}
      onClose={closeRemoveCollaboratorDialog}
      >
        <AlertDialogContent
        titleText={t('dialog:removecollaborator.title')}
        description={t('dialog:removecollaborator.description')}
        confirm={{
          text: t('dialog:removecollaborator.confirm'),
          action: handleRemoveCollaborator,
          loading: removeLoading,
          disabled: removeLoading,
        }}
        cancel={{
          text: t('common:cancel'),
          action: closeRemoveCollaboratorDialog,
        }}
        />
      </Dialog>
      <Dialog
      open={isLeaveDirectoryDialogOpen}
      onClose={closeLeaveDirectoryDialog}
      >
        <AlertDialogContent
        titleText={t('dashboard-directory:leavechannel.title')}
        description={t('dashboard-directory:leavechannel.desc')}
        confirm={{
          text: t('common:confirm'),
          action: handleLeaveDirectoryOnClick,
          loading: leaveLoading,
          disabled: leaveLoading,
        }}
        cancel={{
          text: t('common:cancel'),
          action: closeLeaveDirectoryDialog,
        }}
        />
      </Dialog>
    </FlexSpacer>
  );
}

export default React.memo(CollaboratorItem);
