import { VideoHelper } from 'features/video-library/video-helpers';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { VideoProgress } from 'types/pigeon';

import GenericOverlay from './GenericOverlay';

interface VideoCurrentStatusOwnProps {
  currentStatus: VideoProgress;
  inner?: JSX.Element | null;
  eta?: string;
}

type VideoCurrentStatusProps = VideoCurrentStatusOwnProps;

function VideoCurrentStatus(props: VideoCurrentStatusProps) {
  const { currentStatus, inner, eta } = props;

  const { t } = useStrictTranslation(['common']);
  const wt = t as TFunction;

  const errorText = useMemo(() => {
    if (currentStatus === 'paused') {
      return t('common:videocard.paused.uploadpaused');
    }

    if (currentStatus === 'uploading' && eta) {
      return t('common:timeleft', { eta });
    }
    return wt(VideoHelper.getStatusTranslation(currentStatus));
  }, [currentStatus, eta, t, wt]);

  return (
    <GenericOverlay
    errorText={errorText}
    inner={inner}
    />
  );
}

export default React.memo(VideoCurrentStatus);
