import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { ITag } from 'types/pigeon';

import TagListItem from './TagListItem';

export interface TagListItemWrapperOwnProps {
  className?: string;
  tag: ITag;
  showCheck?: boolean;

  selected?: boolean;
  loading?: boolean;
  checked?: boolean;
  indeterminate?: boolean;

  onTagClick?: (e: React.SyntheticEvent, tag: ITag) => void;
  onTagCheckBoxChange?: (e: React.ChangeEvent<HTMLInputElement>, tag: ITag) => void;
}

type Props = TagListItemWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'TagListItemWrapper'});

function TagListItemWrapper(props: Props) {
  const classes = useStyles(props);
  const {
    checked,
    className,
    indeterminate,
    loading,
    onTagCheckBoxChange,
    onTagClick,
    selected,
    showCheck,
    tag,
   } = props;

  const handleCheckBoxOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onTagCheckBoxChange?.(e, tag);
  }, [onTagCheckBoxChange, tag]);

  const handleTagItemOnClick = useCallback((e: React.SyntheticEvent) => {
    onTagClick?.(e, tag);
  }, [onTagClick, tag]);

  return (
    <TagListItem
    color={tag.color}
    name={tag.name}
    showSecondaryAction={showCheck}
    selected={selected}
    onClick={handleTagItemOnClick}
    secondaryAction={loading ? (
      <CircularProgress size={20} />
    ) : (
      <Checkbox
      tabIndex={!showCheck ? -1 : undefined}
      edge='end'
      checked={checked}
      indeterminate={indeterminate}
      onChange={handleCheckBoxOnChange}
      />
    )}
    className={classNames(classes.root, className)}
    />
  );
}

export default React.memo(TagListItemWrapper);
