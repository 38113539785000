import { useCreateSelector } from '@insights-gaming/redux-utils';
import { GameMetadata } from '@insights-gaming/statistics';
import { Theme } from '@insights-gaming/theme';
import { IUpload } from '@insights-gaming/video-upload-slice';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import bytes from 'bytes';
import classNames from 'classnames';
import { makeGetIsVideoUploaded, makeGetPendingUploadByVideoId, makeGetStartedUploadByVideoId } from 'features/upload/resumable-uploads-selector';
import { PendingUpload } from 'features/upload/resumable-uploads-slice';
import { getCurrentUpload } from 'features/upload/upload-selector';
import { VideoHelper } from 'features/video-library/video-helpers';
import { formatDuration, secondsToHms } from 'helpers/formatters';
import { affineTransform1D } from 'helpers/math';
import { useGetLocalTusUpload } from 'hooks/useGetLocalTusUpload';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import FloatingText from 'material/floating-text/FloatingText';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { VideoStatus } from 'types/graphql';
import { VideoError, VideoProgress } from 'types/pigeon';

import GameKDAFloatingText from './GameKDAFLoatingText';
import AnalysisProgress from './progress/AnalysisProgress';
import Circular from './progress/Circular';
import Indeterminate from './progress/Indeterminate';
import VideoCardError from './VideoCardError';
import VideoCurrentStatus from './VideoCurrentStatus';

interface VideoCardOverlayOwnProps {
  className?: string;
  video: VideoFragment;
}

type VideoCardOverlayProps = VideoCardOverlayOwnProps;

const thumbnailAspectRatio = (9 / 16 * 100) + '%';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: thumbnailAspectRatio,
  },
  overlayWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}), {name: 'VideoCardOverlay'});

const resumableError: VideoError = {
  type: 'uploading',
  viewable: false,
  retrying: false,
  titleKey: 'common:videocard.paused.uploadpaused',
  descriptionKey: 'common:videocard.paused.fix',
  needsUpgrade: false,
};

function VideoCardOverlay(props: VideoCardOverlayProps) {
  const classes = useStyles(props);
  const { className, video } = props;

  const pendingUpload = useCreateSelector(makeGetPendingUploadByVideoId, video.id);
  const startedUpload = useCreateSelector(makeGetStartedUploadByVideoId, { id: video.id });
  const currentUpload = useSelector(getCurrentUpload);
  const isVideoUploaded = useCreateSelector(makeGetIsVideoUploaded, video.id);

  const currentlyUploading = useMemo(() => currentUpload?.resumeInfo?.videoId === video.id, [currentUpload, video.id]);

  const resumableUpload = useGetLocalTusUpload(video.id);

  const videoError = useMemo(() => {
    if (pendingUpload) {
      return;
    }

    if (resumableUpload) {
      return resumableError;
    }

    return VideoHelper.getVideoError(video);
  }, [pendingUpload, resumableUpload, video]);

  const currentStatus = useMemo(() => {
    if (video.status !== 'READY') {
      if (pendingUpload) {
        return 'verifying';
      }

      if (startedUpload) {
        if (!currentlyUploading) {
          return 'paused';
        }

        return 'uploading';
      }

      if (isVideoUploaded) {
        return 'encoding';
      }
    }

    return VideoHelper.getCurrentStatus(video);
  }, [currentlyUploading, isVideoUploaded, pendingUpload, startedUpload, video]);

  const eta = useMemo(
    () => startedUpload && startedUpload.rate > 0
      ? secondsToHms((1 - startedUpload.progress) / startedUpload.rate)
      : undefined,
    [startedUpload],
  );

  const speed = useMemo(
    () => startedUpload && bytes(startedUpload.rate * video.filesize),
    [startedUpload, video.filesize],
  );

  const { t } = useStrictTranslation(['common']);

  const gameMetadata = useMemo(
    (): GameMetadata | undefined => {
      try {
        return JSON.parse(video?.userMetadata?.find(metaData => metaData.name === '_overwolfGameInfo')?.value || '');
      } catch {
        return;
      }
    },
    [video],
  );

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.overlayWrapper}>
        {videoError ? (
          <VideoCardError
          videoError={videoError}
          teamId={VideoHelper.getTeamId(video)}
          />
        ) : currentStatus ? (
          <VideoCurrentStatus
          currentStatus={currentStatus}
          inner={InnerOverlay(currentStatus, video, startedUpload, pendingUpload)}
          eta={eta}
          />
        ) : null}
        {video.duration !== null && (
          <FloatingText
          horizontal='right'
          vertical='bottom'
          mr={1}
          mb={1}
          >
            {formatDuration(video.duration)}
          </FloatingText>
        )}
        {currentStatus === 'uploading' && startedUpload && (
          <FloatingText
          horizontal='right'
          vertical='top'
          mr={1}
          mt={1}
          >
            {t('common:uploadspeed', { speed })}
          </FloatingText>
        )}
        {video.status === VideoStatus.READY && <GameKDAFloatingText gameMetadata={gameMetadata} videoId={video.id}/>}
      </div>
    </div>
  );
}

export default React.memo(VideoCardOverlay);

function resolveEncodingProgress(video: VideoFragment): number {
  const halfwaythere = .5;
  const progress = video.progress.current;
  if (progress < halfwaythere) { // still uploading
    return affineTransform1D(progress, [0, halfwaythere], [0, 100]);
  }

  // encoding
  return affineTransform1D(progress, [halfwaythere, 1], [0, 100]);
}

function InnerOverlay(
  currentStatus: VideoProgress,
  video: VideoFragment,
  startedUpload?: IUpload,
  pendingUpload?: PendingUpload,
) {
  if (pendingUpload) {
    return <Circular progress={100 * pendingUpload.current / pendingUpload.total}/>;
  }

  if (startedUpload) {
    return <Circular progress={100 * startedUpload.progress}/>;
  }

  switch (currentStatus) {
    case 'encoding' :
      return <Circular progress={resolveEncodingProgress(video)}/>;
    case 'analyzing': {
      const ongoingAnalysis = VideoHelper.getOngoingAnalysis(video);
      return ongoingAnalysis && ongoingAnalysis.progress && <AnalysisProgress progress={ongoingAnalysis.progress} />;
    };
    case 'queued':
    case 'aggregating': return <Indeterminate />;
    default : return null;
  }
}
