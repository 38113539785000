import { LeagueGameEvent } from '@insights-gaming/game-events';
import { makeStatisticsSelectors, makeStatisticsSlice } from '@insights-gaming/statistics';
import { RootReducer } from 'rootReducer';

export const {
  allStatisticsSagas: captureGamesStatisticsSaga,
  statisticsActions,
  statisticsSagas,
  statisticsSlice,
} = makeStatisticsSlice();

export const captureGamesStatisticsReducer = statisticsSlice.reducer;

export const {
  fetchLeagueOfLegendsAssetsAC,
  fetchLeagueOfLegendsCurrentVersionAC,
  fetchLeagueOfLegendsStatsAC,
} = statisticsActions;


export const getCaptureGamesStatisticsReducer = (root: RootReducer) => root.captureGamesStatisticsReducer;

export const {
  [LeagueGameEvent.CLASS_ID]: {
    getLeagueStats,
    getLeagueStatsDict,
    getLeagueStatsAssetsDict,
    getLeagueStatsFetchStatusDict,
    getLeagueStatsAssetsFetchStatusDict,
    makeGetLeagueStatsByVideoId,
    makeGetFetchStatusByVideoId,
    makeGetLeagueAssetsByGameVersion,
    makeGetAssetsFetchStatusByGameVersion,
  },
} = makeStatisticsSelectors(getCaptureGamesStatisticsReducer);
