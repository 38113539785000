import gql from 'graphql-tag';

export const LeaveDirectory_Mutation = gql`
    mutation LeaveDirectoryMutation(
        $input: LeaveDirectoryInput!
    ) {
        leaveDirectory(
            input: $input
        ) {
            id
        }
    }
`;
