import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import TeamOverview from 'components/dashboard/team-settings/team-overview/TeamOverview';
import { EIntercomID } from 'constants/strings';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

interface DashboardTeamSettingsOverviewOwnProps {
  className?: string;
  team: TeamFragment;
}

type DashboardTeamSettingsOverviewProps = DashboardTeamSettingsOverviewOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DashboardTeamSettingsOverview'});

function DashboardTeamSettingsOverview(props: DashboardTeamSettingsOverviewProps) {
  const classes = useStyles(props);
  const { className, team } = props;
  const { t } = useStrictTranslation(['dashboard-settings']);
  return (
    <FlexSpacer id={EIntercomID.OVERVIEW} orientation='vertical'>
      <TeamOverview team={team} className={className} />
    </FlexSpacer>
  );
}

export default React.memo(DashboardTeamSettingsOverview);
