import { changeVideoLocationFiltersAC, changeVideoMapTypeFiltersAC, clearVideoFiltersAC, VideoFilterAction } from 'actions/video-filter-actions';
import { logoutAsyncAC } from 'features/auth/auth-slice';
import { IVideoFilterStoreState, IVideoLocationFilterOption, IVideoMapTypeFilterOption } from 'types';
import { isType } from 'typescript-fsa';

const initialState: () => IVideoFilterStoreState = (): IVideoFilterStoreState => {
  return {
    mapTypeFilters : [],
    locationFilters: [],
  };
};

export function videoFilterReducer(
  state : IVideoFilterStoreState = initialState(),
  action: VideoFilterAction,
): IVideoFilterStoreState {
  if (isType(action, changeVideoMapTypeFiltersAC)) {
    return changeMapTypeFilters(state, action.payload);
  }
  if (isType(action, changeVideoLocationFiltersAC)) {
    return changeLocationFilters(state, action.payload);
  }
  if (isType(action, clearVideoFiltersAC)) {
    return clearFilters(state);
  }
  if (isType(action, logoutAsyncAC.done)) {
    return logout();
  }
  return state;
}

function changeMapTypeFilters(
  state: IVideoFilterStoreState,
  payload: IVideoMapTypeFilterOption[],
): IVideoFilterStoreState {
  return {
    ...state,
    mapTypeFilters: payload,
  };
}

function changeLocationFilters(
  state: IVideoFilterStoreState,
  payload: IVideoLocationFilterOption[],
): IVideoFilterStoreState {
  return {
    ...state,
    locationFilters: payload,
  };
}

function clearFilters(state: IVideoFilterStoreState): IVideoFilterStoreState {
  return {
    ...state,
    mapTypeFilters : [],
    locationFilters: [],
  };
}

// function deleteTagSuccess(state: IVideoFilterStoreState, payload: DeleteTagInput): IVideoFilterStoreState {
//   const i = state.tagFilters.findIndex((id: ID) => id === payload.id);
//   if (i < 0) { return state; }
//   const tagFilters = [
//     ...state.tagFilters.slice(0, i),
//     ...state.tagFilters.slice(i + 1),
//   ];
//   return { ...state, tagFilters };
// }

function logout(): IVideoFilterStoreState {
  return initialState();
}
