type TValorantmaps =
|'bind'
|'breeze'
|'haven'
|'split'
|'ascent'
|'icebox'
|'fracture'
|'lotus'
;
const ValorantmapsNS = 'valorantmaps';
function ValorantmapsF(_: TValorantmaps): string { return `${ValorantmapsNS}:${_}`; }
export {TValorantmaps,ValorantmapsF,ValorantmapsNS};
