import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import React, { useCallback, useState } from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';

import { UpdateAttackerMutation_updateAttacker } from '../../../apollo/mutations/types/UpdateAttackerMutation';
import { UpdateStatsMapMutation_updateStatsMap } from '../../../apollo/mutations/types/UpdateStatsMapMutation';
import { isTruthy } from '../../../helpers';
import { CommonF, CommonNS } from '../../../locales/en/common';
import { DialogF, DialogNS } from '../../../locales/en/dialog';
import { OwmapsF, OwmapsNS, TOwmaps } from '../../../locales/en/owmaps';
import { UpdateAttackerInput, UpdateStatsMapInput } from '../../../types/graphql';
import { ID } from '../../../types/pigeon';
import { GeneratedOverwatchMatch } from '../../../types/pigeon/matches';
import { OverwatchMap } from '../../../types/pigeon/matches/generated-overwatch-match';

type AttackerColor = 'blue' | 'red';
const attackerColors: AttackerColor[] = ['blue', 'red'];

const maps: OverwatchMap[] = [
  'blizzardWorld',
  'busanDowntown',
  'busanMekaBase',
  'busanSanctuary',
  'dorado',
  'eichenwalde',
  'hanamura',
  'havana',
  'hollywood',
  'horizonLunarColony',
  'iliosLighthouse',
  'iliosRuins',
  'iliosWell',
  'junkertown',
  'kingsRow',
  'lijiangTowerControlCenter',
  'lijiangTowerGarden',
  'lijiangTowerNightMarket',
  'nepalSanctum',
  'nepalShrine',
  'nepalVillage',
  'numbani',
  'oasisCityCenter',
  'oasisGardens',
  'oasisUniversity',
  'paris',
  'rialto',
  'route66',
  'templeOfAnubis',
  'volskayaIndustries',
  'watchpointGibraltar',
];

export interface IMapVerificationDialogContentOwnProps {
  match: GeneratedOverwatchMatch;
  videoId: ID;
  teamId: ID;
  position: number;
  onClose: VoidFunction;
  onMapNameUpdate: (map: OverwatchMap) => void;
  onAttackerUpdate: (attacker: string) => void;
}

export interface IMapVerificationDialogContentDispatch {
  updateStatsMap: (input: UpdateStatsMapInput) => Promise<UpdateStatsMapMutation_updateStatsMap>;
  updateAttacker: (input: UpdateAttackerInput) => Promise<UpdateAttackerMutation_updateAttacker>;
}

export type MapVerificationDialogContentProps = IMapVerificationDialogContentOwnProps &
  IMapVerificationDialogContentDispatch &
  WithTranslation;

interface IState {
  newMapName: OverwatchMap;
  newAttacker: AttackerColor;
  loading?: boolean;
}

function MapVerificationDialogContent(props:MapVerificationDialogContentProps) {

  const [newMapName, setMapName] = useState(props.match.map as any);
  const [newAttacker, setAttacker] = useState(props.match.data.scoreInfo.color);
  const [loading, setLoading] = useState(false);

  const { t, match, onClose } = props;

  const renderMapLabel = useCallback((map: TOwmaps) => {
    return t(OwmapsF(map));
  }, [t]);

  const renderAttackerLabel = useCallback((attacker:AttackerColor) => {
    return attacker === 'red' ? 'Red' : 'Blue';
  }, []);

  const renderMapParams = useCallback((params: AutocompleteRenderInputParams) => {
    return (
      <TextField {...params} label={t(CommonF('map'))} fullWidth={true} />
    );
  }, [t]);

  const renderAttackerParams = useCallback((params: AutocompleteRenderInputParams) => {
    return (
      <TextField {...params} label={'Attacker'} fullWidth={true} />
    );
  }, []);

  const handleMapChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, value: OverwatchMap) => {
    setMapName(value);
  }, [setMapName]);

  const handleAttackerChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, value: AttackerColor) => {
    setAttacker(value);
  }, [setAttacker]);

  const handleSaveOnClick = useCallback(async () => {
    const {
      onClose,
      teamId,
      videoId,
      updateStatsMap,
      updateAttacker,
      position,
      onMapNameUpdate,
      onAttackerUpdate,
      match,
    } = props;

    const origMapName = match.map;
    const origAttacker = match.data.scoreInfo.color;

    if (loading) { return; }

    setLoading(true);

    const mapInput: UpdateStatsMapInput = {
      teamId,
      mapName: newMapName,
      videoId,
      position,
    };

    const attackerInput: UpdateAttackerInput = {
      attacker: newAttacker,
      matchIndex: position,
      teamId,
      videoId,
    };

    try {
      await Promise.all([
        (newMapName !== origMapName) && updateStatsMap(mapInput),
        (newAttacker !== origAttacker) && updateAttacker(attackerInput),
      ].filter(isTruthy));

      if (newMapName !== origMapName){
        onMapNameUpdate(newMapName);
      }
      if (newAttacker !== origAttacker){
        onAttackerUpdate(newAttacker);
      }
      onClose();
    }
    catch (e) {
      setLoading(false);
    }
  }, [loading, newAttacker, newMapName, props]);

  return (
    <React.Fragment>
      <DialogTitle>
        {t(DialogF('editmap.title'))}
      </DialogTitle>
      <DialogContent>
        <FlexSpacer orientation='vertical'>
          <Autocomplete
          value={newMapName}
          options={maps}
          getOptionLabel={renderMapLabel}
          onChange={handleMapChange}
          renderInput={renderMapParams}
          />
          <Autocomplete
          value={newAttacker}
          options={attackerColors}
          getOptionLabel={renderAttackerLabel}
          renderInput={renderAttackerParams}
          onChange={handleAttackerChange}
          />
        </FlexSpacer>
      </DialogContent>
      <DialogActions>
        <Button
        variant='outlined'
        onClick={onClose}
        >
          {t(CommonF('cancel'))}
        </Button>
        <AsyncButton
        variant='contained'
        color='primary'
        loading={loading}
        disabled={(newMapName === match.map && newAttacker === match.data.scoreInfo.color) || loading}
        onClick={handleSaveOnClick}
        >
          {t(CommonF('save'))}
        </AsyncButton>
      </DialogActions>
    </React.Fragment>
  );

}

export default withTranslation([CommonNS, DialogNS, OwmapsNS])(MapVerificationDialogContent);
