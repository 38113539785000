import VideocamIcon from '@material-ui/icons/Videocam';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { isVideoFragment } from 'helpers/isVideoFragment';
import TextEllipsisChip from 'material/text-ellipsis-chip/TextEllipsisChip';
import React, { useMemo } from 'react';

export interface SelectedVideoChipOwnProps {
  video: VideoFragment | InsightsEmbedResponse;
}

export type SelectedVideoChipProps = SelectedVideoChipOwnProps;

function SelectedVideoChip({ video }: SelectedVideoChipProps) {
  const name = useMemo(() => {
    if (isVideoFragment(video)) {
      return video.name;
    }

    return video.title;
  }, [video]);

  return (
    <TextEllipsisChip
    label={name}
    title={name}
    icon={<VideocamIcon />}
    />
  );
}

export default React.memo(SelectedVideoChip);
