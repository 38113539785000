import gql from 'graphql-tag';

export const DeleteTeam_Mutation = gql`
    mutation DeleteTeamMutation(
        $input: DeleteTeamInput!
    ) {
        deleteTeam(
            input: $input
        ) {
            teamId
        }
    }
`;
