import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import ReferralPanel from 'components/referral-panel/ReferralPanel';
import { EIntercomID } from 'constants/strings';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import EmptyState from 'features/empty-state/EmptyState';
import { useIsDesktop } from 'features/media-queries/hooks';
import { isGlobalFeature, isSeatedFeature } from 'helpers/hasSeats';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import UserAvatar from 'material/user-avatar/UserAvatar';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import EmptyStateImage from 'subcomponents/empty-state-image/EmptyStateImage';
import { TeamFeature } from 'types/graphql';

import MemberTableRowSkeleton from './MemberTableRowSkeleton';
import MemberTableRowWrapper from './MemberTableRowWrapper';

interface MemberTableOwnProps {
  className?: string;
  id?: string;
  members: MemberFragment[];
  roles: RoleInterfaceFragment[];
  team: TeamFragment;
  displaySkeleton?: boolean;
  openInviteTeamMembersDialog: VoidFunction;
}

type MemberTableProps = MemberTableOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  tableContainer: {
    width: '100%',
  },
  dimmed: {
    opacity: 0.5,
  },
  bold: {
    fontWeight: 700,
  },
}), {name: 'MemberTable'});

function MemberTable(props: MemberTableProps) {
  const classes = useStyles(props);
  const {
    className,
    id,
    members,
    roles,
    team,
    displaySkeleton,
    openInviteTeamMembersDialog,
  } = props;
  const { t } = useStrictTranslation(['dashboard']);
  const isDesktop = useIsDesktop();

  const sortedMembers = useMemo(() => {
    return members.sort(function (a, b) {
      if (a.user.id === team.owner.id) {
        return -1;
      }
      return a.user.alias.localeCompare(b.user.alias);
    });
  }, [members, team.owner.id]);

  const { canAssignFeatures } = useAccessControl();

  const globalAdFreeFeature = useMemo(
    () => team?.features?.filter(isGlobalFeature).find(({feature}) => feature === TeamFeature.ADFREE),
    [team?.features],
  );

  const seatedAdFreeFeature = useMemo(
    () => team?.features?.filter(isSeatedFeature).find(({feature}) => feature === TeamFeature.ADFREE),
    [team?.features],
  );

  const adFreeCounts = useMemo(() => {
    if (globalAdFreeFeature) {
      return { count: members.length , total: members.length };
    }
    return { count: seatedAdFreeFeature?.members.length ?? 0 , total: seatedAdFreeFeature?.limit ?? 0 };
  }, [globalAdFreeFeature, members.length, seatedAdFreeFeature?.limit, seatedAdFreeFeature?.members.length]);

  return (
    <FlexSpacer fullWidth={true}>
      <FlexSpacer orientation='vertical' className={classes.tableContainer}>
        <Table id={EIntercomID.MEMBERS_TABLE} className={classNames(classes.root, className)} size='small'>
          <TableHead>
            <TableRow>
              <FixedTableCell width={isDesktop ? 250 : 150}>{t('dashboard:team.name')}</FixedTableCell>
              <FixedTableCell width={isDesktop ? 300 : 125}>{t('dashboard:team.roles.role')}</FixedTableCell>
              {canAssignFeatures && isDesktop && (
                <FixedTableCell align='center' width={150}>
                  <Tooltip
                  title={t(
                    'dashboard:billing.adfreetooltip',
                    { count: adFreeCounts.count, total: adFreeCounts.total },
                  )}
                  placement='top-start'
                  >
                    <FlexSpacer flexJustifyContent='center'>
                      <Typography className={classes.bold}>{t('dashboard:billing.adfree')}</Typography>
                      {canAssignFeatures && (
                        <Typography className={classes.dimmed}>
                          {t(
                            'dashboard:billing.adfreecount',
                            { count: adFreeCounts.count, total: adFreeCounts.total },
                          )}
                        </Typography>
                      )}
                    </FlexSpacer>
                  </Tooltip>
                </FixedTableCell>
              )}
              <FixedTableCell width={isDesktop ? 80 : 50} align='center'></FixedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displaySkeleton ? (
              Array.from({length: 2}).map((_, i) => (
                <MemberTableRowSkeleton key={i} />
              ))
            ) : (
              sortedMembers.map(member => (
                <MemberTableRowWrapper
                key={member.id}
                avatar={<UserAvatar user={member.user} size='sm' />}
                roles={roles}
                member={member}
                team={team}
                adFreeFeature={seatedAdFreeFeature || globalAdFreeFeature}
                />
              ))
            )}
          </TableBody>
        </Table>
        {members.length === 1 && (
          <EmptyState
          mainTitle={t('dashboard:team.itsbettertogether')}
          subTitle={(
            <Trans
            ns='dashboard'
            i18nKey='team.nomembers'
            >
              <em>There are no members on this team, invite some to<br /> start collaborating!</em>
            </Trans>
          )}
          img={<EmptyStateImage url='no-members' width={isDesktop ? 400 : 300} height={isDesktop ? 340 : 255}/>}
          />
        )}
      </FlexSpacer>
      {isDesktop && team.owner.__typename === 'Self' && (
        <ReferralPanel team={team} openInviteTeamMembersDialog={openInviteTeamMembersDialog} />
      )}
    </FlexSpacer>
  );
}

export default React.memo(MemberTable);
