import { VideoReplayProvider } from '@insights-gaming/material-components';
import React from 'react';

import { useVideoReplayContextValue } from './useVideoReplayContextValue';

export function ProvideVideoReplayContextValue({ children }: React.PropsWithChildren<{}>) {
  return (
    <VideoReplayProvider value={useVideoReplayContextValue()}>
      {children}
    </VideoReplayProvider>
  );
}
