import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import TeamAvatar from 'material/team-avatar/TeamAvatar';
import React from 'react';

interface TeamCardDragPreviewOwnProps {
  className?: string;
  team: TeamFragment;
}

type TeamCardDragPreviewProps = TeamCardDragPreviewOwnProps;

function TeamCardDragPreview(props: TeamCardDragPreviewProps) {
  const { team } = props;
  return (
    <TeamAvatar
    size='sm'
    team={team}
    />
  );
};

export default React.memo(TeamCardDragPreview);
