import { createContext } from 'react';

interface LoadingScreenController {
  add: (n?: number) => void;
  done: () => void;
  auto: () => () => void;
  ready: boolean;
}

export const LoadingContext = createContext<LoadingScreenController | undefined>(undefined);
