import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { GetUserProfileQuery_me } from 'apollo/queries/types/GetUserProfileQuery';
import { ILogoutOption, logoutAsyncAC } from 'features/auth/auth-slice';
import { promisifyDispatch } from 'hooks/usePromiseSagaDispatch';
import update, { Spec } from 'immutability-helper';
import { CommonF, CommonNS } from 'locales/en/common';
import { DialogF, DialogNS } from 'locales/en/dialog';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import UserAvatar from 'material/user-avatar/UserAvatar';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { settingsRoute } from 'routes';
import { OnNavigate, onNavigateFactory } from 'types/dispatch';

export interface IWithUserMenuRequiredProps {
  me: GetUserProfileQuery_me;
}

export interface IWithUserMenuDispatch {
  onLogout  : (input: ILogoutOption) => Promise<void>;
  onNavigate: OnNavigate;
}

interface IWithUserMenu {
  openMenu : (event: React.MouseEvent<HTMLElement>) => void;
  closeMenu: VoidFunction;
}

export type WithUserMenu = IWithUserMenuDispatch & IWithUserMenu;

interface IState {
  anchorEl?: HTMLElement;

  logoutOpen?   : boolean;
  logoutLoading?: boolean;
  logoutError?  : Error;
}

export function withUserMenu<
 P extends IWithUserMenuRequiredProps &
 IWithUserMenuDispatch &
 WithTranslation
>(Component: React.ComponentType<P & IWithUserMenu>) {
  return class extends React.Component<Omit<P, keyof IWithUserMenu>, IState> {
    public static displayName = `UserMenu(${Component.displayName || Component.name})`

    private openLogoutDialog  = this.toggleDialog('logoutOpen', true);
    private closeLogoutDialog = this.toggleDialog('logoutOpen', false);

    constructor(props: P) {
      super(props);
      this.state = {};
    }

    public render() {
      const { t } = this.props;
      return (
        <React.Fragment>
          <Component
          {...this.props as P}
          openMenu={this.handleOpen}
          closeMenu={this.handleClose}
          />
          <Menu
          open={!!this.state.anchorEl}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          >
            <MenuItem onClick={this.handleProfileSettingOnClick}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={t(CommonF('settings'))} />
            </MenuItem>
            <MenuItem onClick={this.openLogoutDialog}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={t(DialogF('logout.title'))} />
            </MenuItem>
          </Menu>
          {this.renderLogoutDialog()}
        </React.Fragment>
      );
    }

    private renderLogoutDialog = () => {
      const { t } = this.props;
      const { logoutOpen, logoutLoading } = this.state;
      return (
        <Dialog open={!!logoutOpen} onClose={this.closeLogoutDialog}>
          <AlertDialogContent
          titleText={t(DialogF('logout.title'))}
          description={t(DialogF('logout.description'))}
          cancel={{
            text: t(CommonF('cancel')),
            action: this.closeLogoutDialog,
          }}
          confirm={{
            text: t(DialogF('logout.title')),
            action: this.logout,
            loading: logoutLoading,
            disabled: logoutLoading,
            negative: true,
          }}
          />
        </Dialog>
      );
    }

    private handleProfileSettingOnClick = () => {
      const { onNavigate } = this.props;
      onNavigate(settingsRoute(), {state: {referPath: window.location.pathname + window.location.search}});
    }

    private logout = async () => {
      const { onLogout } = this.props;
      const { logoutLoading } = this.state;
      if (logoutLoading) { return; }
      this.setState({logoutLoading: true});
      try {
        await onLogout({navigate: true});
      } catch (error) {
        this.setState({logoutLoading: false, logoutError: error});
      }
    }

    private handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.setState({anchorEl: event.currentTarget});
    }

    private handleClose = () => {
      this.setState({anchorEl: undefined});
    }

    private toggleDialog(dialog: keyof IState, isOpen?: boolean): VoidFunction {
      return () => {
        const spec: Spec<IState, never> = {$toggle: []};
        switch (isOpen) {
          case true   :
          case false  : spec[dialog] = {$set: isOpen};
               default: spec.$toggle = [dialog];
        }
        this.setState(state => update(state, spec), () => {
          if (this.state[dialog]) {
            this.handleClose();
          }
        });
      };
    }
  };
}

export function mapDispatchToUserMenuProps(dispatch: Dispatch): IWithUserMenuDispatch {
  const promiseSagaDispatch = promisifyDispatch(dispatch);
  return {
    onLogout: (input: ILogoutOption) => promiseSagaDispatch(logoutAsyncAC, input),
    onNavigate: onNavigateFactory(dispatch),
  };
}

class UserMenuB extends React.Component<
  IWithUserMenuRequiredProps &
    WithUserMenu &
    WithTranslation
> {
  public render() {
    const { me, openMenu } = this.props;
    return (
      <IconButton onClick={openMenu} size='small'>
        <UserAvatar user={me} />
      </IconButton>
    );
  }
}

export const UserMenuButton =
connect<void, IWithUserMenuDispatch, IWithUserMenuRequiredProps>(null, mapDispatchToUserMenuProps)(
  withTranslation([CommonNS, DialogNS])(
    withUserMenu(UserMenuB),
  ),
);
