import { Theme } from '@insights-gaming/theme';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import classNames from 'classnames';
import { RoleHelper } from 'components/role-table/role-helper';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import React, { useCallback } from 'react';

interface RoleChipOwnProps {
  className?: string;
  role: RoleInterfaceFragment;
  revokeRole: (role: RoleInterfaceFragment) => Promise<void>;
  canRevokeRole?: boolean;
  revokeLoading?: boolean;
}

type RoleChipProps = RoleChipOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'RoleChip'});

function RoleChip(props: RoleChipProps) {
  const classes = useStyles(props);
  const { className, revokeLoading, canRevokeRole, revokeRole, role } = props;

  const [isRevokeRoleDialogOpen, openRevokeRoleDialog, closeRevokeRoleDialog] = useDialogState();
  const { t } = useStrictTranslation(['common', 'dialog']);
  const wt = t as TFunction;

  const revokeRoleOnClick = useCallback(() => {
    revokeRole(role);
  }, [revokeRole, role]);

  return (
    <React.Fragment>
      <Chip
      variant={RoleHelper.isCustomRole(role) ? 'outlined' : 'default'}
      label={RoleHelper.isGlobalRole(role) ? wt(`common:roles.global.${role.name}.title`) : role.name}
      className={classNames(classes.root, className)}
      color={RoleHelper.isGlobalRole(role) ? 'primary' : undefined}
      style={{ borderColor: role.__typename === 'CustomRole' ? role.color : undefined }}
      onDelete={canRevokeRole ? openRevokeRoleDialog : undefined}
      />
      <Dialog open={isRevokeRoleDialogOpen} fullWidth={true} onClose={closeRevokeRoleDialog}>
        <AlertDialogContent
        titleText={t('dialog:revokerole.title')}
        description={t(
          'dialog:revokerole.warning',
          { role: RoleHelper.isGlobalRole(role) ? wt(`common:roles.global.${role.name}.title`) : role.name },
        )}
        cancel={{
          text  : t('common:cancel'),
          action: closeRevokeRoleDialog,
        }}
        confirm={{
          text    : t('dialog:revokerole.confirm'),
          loading : revokeLoading,
          disabled: revokeLoading,
          action  : revokeRoleOnClick,
        }}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default React.memo(RoleChip);
