export interface Milestone {
  referralGoal: number;
  rewardAmount: number;
}

export const milestones: Milestone[] = [
  {referralGoal: 5, rewardAmount: 2.5},
  {referralGoal: 10, rewardAmount: 2.5},
  {referralGoal: 20, rewardAmount: 5},
];
