import { UserNotificationSettingFragment } from 'apollo/fragments/UserNotificationSettingFragment';
import gql from 'graphql-tag';

export const UpdateUserNotificationSettings_Mutation = gql`
    mutation UpdateUserNotificationSettingsMutation(
        $input: [UpdateUserNotificationSettingsInput!]!
    ) {
        updateUserNotificationSettings(
            input: $input
        ) {
            userNotificationSettings {
                ...UserNotificationSettingFragment
            }
        }
    }
    ${UserNotificationSettingFragment}
`;
