import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import ProfilePicture from 'components/settings/profile-settings/profile-picture/ProfilePicture';
import { EIntercomID } from 'constants/strings';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import useTeamSubscription from 'features/dashboard/billing/useTeamSubscription';
import { deleteTeamAC, updateTeamAC } from 'features/dashboard/team/dashboard-team-slice';
import { useIsDesktop } from 'features/media-queries/hooks';
import { fileToBinaryString } from 'helpers';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import DeleteButton from 'material/delete-button/DeleteButton';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { dashboardRoute } from 'routes';
import { UpdateTeamInput } from 'types/graphql';

interface FormFields {
  teamName: string;
  description?: string;
}

interface TeamOverviewOwnProps {
  className?: string;
  team: TeamFragment;
}

type TeamOverviewProps = TeamOverviewOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'TeamOverview'});

function TeamOverview(props: TeamOverviewProps) {
  const classes = useStyles(props);
  const { className, team } = props;

  const { canUpdateTeam } = useAccessControl();
  const { t } = useStrictTranslation(['common', 'dashboard', 'dialog']);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const onNavigate = useNavigate();
  const isDesktop = useIsDesktop();
  const [ isDeleteTeamDialogOpen, openDeleteTeamDialog, closeDeleteTeamDialog ] = useDialogState();

  const [fields, setFields] = useState<FormFields>({
    teamName: team.name,
  });

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [teamSubscription] = useTeamSubscription(team.id);
  const hasPremiumPlan = useMemo(() => !!teamSubscription?.active, [teamSubscription]);

  const disableButton = useMemo(() => {
    if (!canUpdateTeam || loading) { return true; }
    if (fields.teamName === team.name) { return true; }
    return false;
  }, [canUpdateTeam, fields.teamName, loading, team.name]);

  const handleFileChange = useCallback(async (file: File) => {
    if (loading) { return; }

    setLoading(true);
    const input: UpdateTeamInput = { id: team.id };

    try {
      input.picture = {
        name: file.name,
        contentLength: file.size,
        contentType: file.type,
        data: await fileToBinaryString(file),
      };
      await promiseSagaDispatch(updateTeamAC, input);
      setLoading(false);
      enqueueSnackbar(t('common:updateteamsuccess'), {variant: 'success'});
    } catch {
      setLoading(false);
      enqueueSnackbar(t('common:updateteamfailure'), {variant: 'error'});
    }
  }, [enqueueSnackbar, loading, promiseSagaDispatch, t, team.id]);

  const handleFormInputOnChange = useCallback((e: React.SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setFields(fields => ({
      ...fields,
      [name]: value,
    }));
  }, []);

  const handleUpdateTeamOnClick = useCallback(async () => {
    if (loading) { return; }

    setLoading(true);
    const { teamName, description } = fields;

    const input: UpdateTeamInput = { id: team.id };
    if (teamName) { input.name = teamName; }
    if (description) { input.description = description; }

    try {
      await promiseSagaDispatch(updateTeamAC, input);
      setLoading(false);
      enqueueSnackbar(t('common:updateteamsuccess'), {variant: 'success'});
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(t('common:updateteamfailure'), {variant: 'error'});
    }
  }, [enqueueSnackbar, fields, loading, promiseSagaDispatch, t, team.id]);

  const deleteTeam = useCallback(async () => {
    if (deleteLoading) { return; }
    setDeleteLoading(true);
    try {
      await promiseSagaDispatch(deleteTeamAC, {id: team.id});
      enqueueSnackbar(t('dialog:deleteteam.success'), {variant: 'success'});
      closeDeleteTeamDialog();
      setDeleteLoading(false);
      onNavigate(dashboardRoute());
    } catch (error) {
      setDeleteLoading(false);
      enqueueSnackbar(t('dialog:deleteteam.failure'), {variant: 'error'});
      setDeleteLoading(false);
    }
  }, [closeDeleteTeamDialog, deleteLoading, enqueueSnackbar, onNavigate, promiseSagaDispatch, t, team.id]);

  const renderTeamInfo = useCallback(() => (
    <TextField
    id='teamName'
    name='teamName'
    label={t('dashboard:team.name')}
    placeholder={t('dashboard:team.name')}
    value={fields.teamName}
    onChange={handleFormInputOnChange}
    disabled={!canUpdateTeam}
    />
  ), [canUpdateTeam, fields.teamName, handleFormInputOnChange, t]);

  return (
    <FlexSpacer orientation='vertical' className={classNames(classes.root, className)}>
      <Box
      id={EIntercomID.TEAM_PHOTO}
      display='flex'
      alignItems='center'
      justifyContent={isDesktop ? 'flex-start' : 'center'}
      >
        <ProfilePicture
        name={team.name}
        picture={team.picture}
        loading={loading}
        onPictureUpdate={handleFileChange}
        renderInfo={renderTeamInfo}
        disabled={!canUpdateTeam || loading}
        outlined={true}
        />
      </Box>
      <Box display='flex' justifyContent={isDesktop ? 'flex-end' : 'flex-start'}>
        <FlexSpacer flexJustifyContent='flex-end'>
          {team.owner.__typename === 'Self' && (
            <DeleteButton
            id={EIntercomID.DELETE_TEAM}
            type='button'
            variant='contained'
            onClick={openDeleteTeamDialog}
            >
              {t('common:deleteteam')}
            </DeleteButton>
          )}
          <AsyncButton
          id={EIntercomID.UPDATE_TEAM}
          disabled={disableButton}
          type='button'
          color='primary'
          variant='contained'
          loading={loading}
          onClick={handleUpdateTeamOnClick}
          >
            {t('common:savechanges')}
          </AsyncButton>
        </FlexSpacer>
      </Box>
      <Box my={2}>
        <Divider />
      </Box>
      <Dialog open={isDeleteTeamDialogOpen} onClose={closeDeleteTeamDialog}>
        <AlertDialogContent
        titleText={t('dialog:deleteteam.title', {team: team.name})}
        description={hasPremiumPlan ? t('dialog:deleteteam.descriptionwithsub') : t('dialog:deleteteam.description')}
        cancel={{
          text: t('common:cancel'),
          action: closeDeleteTeamDialog,
        }}
        confirm={{
          text: t('dialog:deleteteam.confirm'),
          loading: deleteLoading,
          disabled: deleteLoading,
          action: deleteTeam,
          negative: true,
        }}
        />
      </Dialog>
    </FlexSpacer>
  );
}

export default React.memo(TeamOverview);
