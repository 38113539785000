import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useCallback } from 'react';

import { fetchOWLStatisticsAsyncAC, fetchOWLVideosAsyncAC } from '../../actions/public-statistics-actions';
import {
  GetOWLStatisticsQuery_owlStatistics,
  GetOWLStatisticsQueryVariables,
} from '../../apollo/queries/types/GetOWLStatisticsQuery';
import { GetOWLVideosQuery_queryOWLVideos, GetOWLVideosQueryVariables } from '../../apollo/queries/types/GetOWLVideosQuery';

export function useFetchOWLVideos(): (
  params: GetOWLVideosQueryVariables,
) => Promise<GetOWLVideosQuery_queryOWLVideos> {
  const promiseSagaDispatch = usePromiseSagaDispatch();
  return useCallback(
    (params: GetOWLVideosQueryVariables) => promiseSagaDispatch(fetchOWLVideosAsyncAC, params),
    [promiseSagaDispatch],
  );
}

export function useFetchOWLStatistics(): (
  params: GetOWLStatisticsQueryVariables,
) => Promise<GetOWLStatisticsQuery_owlStatistics> {
  const promiseSagaDispatch = usePromiseSagaDispatch();
  return useCallback(
    (params: GetOWLStatisticsQueryVariables) => promiseSagaDispatch(fetchOWLStatisticsAsyncAC, params),
    [promiseSagaDispatch],
  );
}
