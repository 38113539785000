import { SerializedObject } from '@insights-gaming/drawing-canvas';
import { FlexSpacer, VideoLayout } from '@insights-gaming/material-components';
import {
  videoReplayActions,
  VideoReplayContext,
  VideoReplayContextValue,
} from '@insights-gaming/material-components/VideoReplayContext/VideoReplayContext';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import classNames from 'classnames';
import EditDrawingPanel from 'components/edit-drawing-panel/EditDrawingPanel';
import { AudioCommentSourceContext } from 'components/video/video-replay/video-comment/audio-comment/AudioCommentSourceContext';
import VideoNotReady from 'components/video-not-ready/VideoNotReady';
import { VIDEO_MUTED, VIDEO_VOLUME } from 'constants/strings';
import { FullscreenProvider } from 'context/fullscreen/FullscreenContext';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { selectTeamAsyncAC } from 'features/dashboard/team/dashboard-team-slice';
import { useFetchVideoIfNecessary } from 'features/dashboard/video/useFetchVideoIfNecessary';
import BookmarkFetcher from 'features/dashboard/video/video-bookmark/BookmarkFetcher';
import {
  getIsJoinedLiveSession,
  getLiveSessionCanDraw,
  getLiveSessionVideo,
} from 'features/live-session/live-session-selector';
import { LoadingContext } from 'features/loading-screen/context';
import { telemetry } from 'features/telemetry/telemetry-actions';
import { VideoHelper } from 'features/video-library/video-helpers';
import { withActionMetadata } from 'helpers/withActionMetadata';
import { useHackRef } from 'hooks/useHackRef';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import clamp from 'lodash/clamp';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ReactPlayerProps } from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import * as portals from 'react-reverse-portal';
import screenfull from 'screenfull';
import { getMe } from 'selectors/getMe';
import {
  VideoBookmarksContext,
  VideoBookmarksProvider,
} from 'subcomponents/video-player-3/controls/bookmarks/BookmarkContext';
import { useBookmarkContextValue } from 'subcomponents/video-player-3/controls/bookmarks/useBookmarkContextValue';
import { useBookmarkSeekerHotKeys } from 'subcomponents/video-player-3/controls/useBookmarkSeekerHotKeys';
import { useVideoPlayerHotKeys } from 'subcomponents/video-player-3/controls/useVideoPlayerHotKeys';
import { DrawingState } from 'subcomponents/video-player-3/drawing-tools-2/useDrawingState';
import VideoPlayerDrawingOverlay from 'subcomponents/video-player-3/drawing-tools-2/VideoPlayerDrawingOverlay';
import { AnalysisType } from 'types/graphql';
import { SmolVideo } from 'types/pigeon/kmsession';

import { setLocalStorage } from '../../helpers/storage';
import { useEnterTheaterMode, useExitTheaterMode } from '../../hooks/dispatch/video-player';
import VideoPlayerControlsRetroFitted from '../../subcomponents/video-player-3/controls/VideoPlayerControlsRetroFitted';
import { GeneratedOverwatchMatch, ID, TestingSSBUMatch } from '../../types/pigeon';
import { useDrawingToolLiveSessionListeners } from '../live-session/useDrawingToolLiveSessionListeners';
import { desktop, mobileLandscape, mobilePortrait } from '../media-queries';
import { useIsDesktop, useIsMobileLandscape } from '../media-queries/hooks';
import AdaptedVideoPlayerContextProvider from './AdaptedVideoPlayerContextProvider';
import { RangedTimestampProvider } from './comment-panel/ranged-timestamp/RangedTimestampContext';
import { useRangedTimestampContextValue } from './comment-panel/ranged-timestamp/useRangedTimestampContextValue';
import { useVideoReplaySelectedLabelContext } from './comment-panel/useVideoReplaySelectedLabelContext';
import { VideoReplaySelectedLabelsProvider } from './comment-panel/VideoReplaySelectedLabelsContext';
import { DrawingToolContext } from './DrawingToolContext';
import FlashFadeIcon from './FlashFadeIcon';
import RightPanel from './right-panel/RightPanel';
import { usePlayerSeek } from './usePlayerSeek';
import { useReactPlayerRef } from './useReactPlayerRef';
import { useSeekOnReady } from './useSeekOnReady';
import { useVideoCommentMenuContext } from './useVideoCommentMenuContext';
import { useVideoReplayKeybindings } from './useVideoReplayKeybindings';
import { VideoCommentProvider } from './VideoCommentContext';
import { VideoCommentMenuProvider } from './VideoCommentMenuContext';
import { VideoNoteProvider } from './VideoNoteContext';
import WrappedReactPlayer from './WrappedReactPlayer';
;

interface VideoReplayOwnProps extends VideoReplayWrapperOwnProps, Pick<ReactPlayerProps, 'config'> {
  drawingState: DrawingState;
  handleReady?: () => void;
  _fullscreenRequested: boolean;
  theaterMode: boolean;
  displayDrawingToolbar: boolean;
  _seekOptions: VideoReplayContextValue['state']['_seekOptions'];
  controlIcon: React.ReactNode | null;
  flashControlIcon: (icon: React.ReactNode | null) => void;
  hideControls: boolean;
  shouldHideControls: boolean;
  isPleb: boolean;
  fullscreen: boolean;
  showFullscreenTip: boolean;
  hideFullscreenTip: () => void;
  fullscreenTipTimerStarted: boolean;
  playing: boolean;
  volume: number;
  muted: boolean;
  playbackRate: number;
  setBuffering: () => void;
  setBufferEnd: () => void;
  _play: () => void;
  _pause: () => void;
  setDuration: (payload: number) => void;
  setProgress: (payload: VideoReplayContextValue['state']['progress']) => void;
  initialCanvasObjects?: SerializedObject[];
  fallback?: SmolVideo;
  setHovered: (hovered: boolean) => void;
  onWidthChange?: (width: number) => void;
  onHeightChange?: (height: number) => void;
}

type VideoReplayProps = VideoReplayOwnProps;

type StyleProps = {
  commentBoxPositionY: number,
  availableWidth: number,
  availableHeight: number
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => createStyles({
  root: {
    flex: 1,
    overflow: 'hidden',
    [mobileLandscape(theme)]: {
      overflowY: 'scroll',
    },
  },

  layout: {
    overflow: 'hidden',
    [desktop(theme)]: {
      alignItems: 'flex-start',
    },
    [mobilePortrait(theme)]: {
      flexDirection: 'column',
      '&& > *': {
        marginLeft: 'unset',
      },
    },
    [mobileLandscape(theme)]: {
      flexDirection: 'column',
      height: props => props.availableHeight * 2,
      '&$fullscreen': {
        height: 'fit-content',
      },
    },
  },

  mainPanelWrapper: {
    overflow: 'visible',
    [desktop(theme)]: {
      display: 'grid',
      height: '100%',
      gridTemplateRows: 'repeat(auto-fill, minmax(0, 100%))',
      gridTemplateColumns: 'repeat(auto-fill, minmax(0, 100%))',
    },
    [mobilePortrait(theme)]: {
      flex: 'unset',
    },
  },

  videoPlayerWrapper: {
    backgroundColor: 'black',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
  },

  controlWrapper: {
    position: 'absolute',
    bottom: props => props.commentBoxPositionY,
    zIndex: theme.zIndex.modal - 200,
    '&$fullscreen': {
      bottom: 0,
    },
  },

  controls: {
    padding: theme.spacing(1),
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    '&$inlineControls,&$fullscreen': {
      position: 'absolute',
      bottom: 0,
    },
  },

  subPanel: {
    display: 'flex',
    marginTop: theme.spacing(1),
    justifyContent: 'space-between',
  },

  editingDrawing: {
    flexDirection: 'row-reverse',
  },

  rightPanelWrapper: {
    width: '100%',
    overflow: 'hidden',
    [desktop(theme)]: {
      height: '100%',
      flex: 'unset',
      width: 420,
    },
    [mobilePortrait(theme)]: {
      flex: 1,
    },
    [mobileLandscape(theme)]: {
      flex: 1,
    },
  },

  rightPanel: {
    backgroundColor: theme.palette.background.container,
    width: '100%',
  },

  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: theme.zIndex.modal - 200,
    pointerEvents: 'none',
  },

  fullscreenTip: {
    position: 'absolute',
    bottom: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    zIndex: 1,
  },

  fullscreenTipContent: {
    padding: theme.spacing(1),
  },

  fullscreenTipBar: {
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.primary.main,
    transition: 'width 5s linear',
    '&$hiding': {
      width: '0%',
    },
  },

  away: {
    cursor: 'none',
  },

  videoPlayerOverlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: theme.zIndex.modal - 300,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.dimmed,
  },

  hiding: {},
  fullscreen: {},
  inlineControls: {},
}), {name: 'VideoReplay'});

const MemoizedVideoLayout = React.memo(VideoLayout);

function VideoReplay(props: VideoReplayProps) {
  const [commentBoxPositionY, setCommentBoxPositionY] = useState<number>(0);
  const [availableWidth, setAvailableWidth] = useState(0);
  const [availableHeight, setAvailableHeight] = useState(0);
  const [landscapeScrollTop, setLandscapeScrollTop] = useState(0);
  const [windowScrollTop, setWindowScrollTop] = useState(0);

  const classes = useStyles({...props, commentBoxPositionY, availableWidth, availableHeight});

  const {
    className,
    toolbar,
    videoId,
    gameMatch,
    onExportKillfeed,
    portalNode,
    drawingState,
    handleReady,
    _fullscreenRequested,
    theaterMode,
    displayDrawingToolbar,
    _seekOptions,
    hideControls,
    shouldHideControls,
    controlIcon,
    flashControlIcon,
    isPleb,
    fullscreen,
    showFullscreenTip,
    hideFullscreenTip,
    fullscreenTipTimerStarted,
    playing,
    volume,
    muted,
    playbackRate,
    setBuffering,
    setBufferEnd,
    _play,
    _pause,
    setDuration,
    setProgress,
    initialCanvasObjects,
    fallback,
    setHovered,
    onWidthChange,
    onHeightChange,
    commentBoxRefHandler,
    drawingToolRefHandler,
    commentLabelRefHandler,
  } = props;

  const [video] = useFetchVideoIfNecessary(videoId);

  const { t } = useStrictTranslation(['videoplayer']);

  const fullscreenElRef = useRef<HTMLDivElement>(null);
  const [toolsElem, toolsRef] = useState<HTMLDivElement | null>(null);

  const { canAccessOverwatchStats, canAccessSSBUStats } = useAccessControl();

  const rangedTimestampContextValue = useRangedTimestampContextValue();

  const {
    reactPlayerRef,
    seekTo: reactPlayerSeekTo,
    onStart,
    onReady,
    qualityPickerContext,
    aspectRatio,
  } = useReactPlayerRef({ handleReady });

  const liveSessionVideo = useSelector(getLiveSessionVideo);
  const me = useSelector(getMe);
  const { canCreateVideoComment } = useAccessControl();
  const canComment = me && (video?.openComments || canCreateVideoComment);

  usePlayerSeek(_seekOptions, reactPlayerSeekTo);

  useEffect(() => {
    if (screenfull.isEnabled) {
      if (_fullscreenRequested) {
        screenfull.request(fullscreenElRef.current || undefined);
      } else {
        screenfull.exit();
      }
    }
  }, [_fullscreenRequested]);

  const canViewStats = useMemo(() => {
    if (video?.latestAnalysis?.completed) {
      switch (video.latestAnalysis.type) {
        case AnalysisType.OVERWATCH:
          return canAccessOverwatchStats;
        case AnalysisType.SSB_ULTIMATE:
          return canAccessSSBUStats;
      }
    }

    return false;
  }, [canAccessOverwatchStats, canAccessSSBUStats, video]);

  const handleDisplayBookmarkIndicator = useCallback(() => {
    setHovered(false);
  }, [setHovered]);

  const handleOverlayButton = useCallback(() => {
    if (!liveSessionVideo) {
      if (playing) {
        _pause();
      } else {
        _play();
      }
    }
  }, [_pause, _play, liveSessionVideo, playing]);

  const isDesktop = useIsDesktop();
  const isMobileLandscape = useIsMobileLandscape();

  useEffect(() => onWidthChange?.(availableWidth), [availableWidth, onWidthChange]);
  useEffect(() => onHeightChange?.(availableHeight), [availableHeight, onHeightChange]);

  const handleResize = useCallback((width: number, height: number) => {
    setAvailableWidth(width);
    setAvailableHeight(height);
  }, []);

  const showMatchPanel = canViewStats && !theaterMode && isDesktop;

  const drawingToolLiveSessionListeners = useDrawingToolLiveSessionListeners(drawingState);

  const playbackVideo = useMemo(() => video || fallback, [fallback, video]);

  const [isEditingDrawing, setIsEditingDrawing] = useState(false);
  const [comment, setComment] = useState<CommentFragment_VideoComment>();

  const onEditCommentDrawing = useCallback((comment: CommentFragment_VideoComment) => {
    setIsEditingDrawing(true);
    setComment(comment);
  }, []);

  const cancelEditing = useCallback(() => {
    setIsEditingDrawing(false);
  }, [setIsEditingDrawing]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (video) {
      dispatch(selectTeamAsyncAC.started(VideoHelper.getTeamId(video)));
    }
  }, [dispatch, video]);

  const { hovered, _setHovered } = useContext(VideoBookmarksContext);

  const [displayVideoControls, setDisplayVideoControls] = useState(true);
  const [pastInitDelay, setPastInitDelay] = useState(false);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
    if (pastInitDelay && playing) {
      _setHovered(false);
    }
  }, [_setHovered, pastInitDelay, playing, setHovered]);

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.currentTarget as HTMLDivElement;
    setLandscapeScrollTop(scrollTop);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayVideoControls(false);
      setPastInitDelay(true);
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (playing && !isDesktop && displayVideoControls) {
      setTimeout(() => {
        setDisplayVideoControls(false);
      }, 2000);
    }
  }, [displayVideoControls, isDesktop, playing]);

  useEffect(() => {
    window.addEventListener('scroll', () => setWindowScrollTop(window.scrollY));
    return () => {
      window.removeEventListener('scroll', () => setWindowScrollTop(window.scrollY));
    };
  }, []);

  useEffect(() => {
    if (isMobileLandscape) {
      setCommentBoxPositionY(clamp(
        availableHeight - window.innerHeight + 64 - landscapeScrollTop - windowScrollTop,
        0,
        canComment ? 64 : 0,
      ));
    }
    return () => {
      setCommentBoxPositionY(0);
    };
  }, [availableHeight, canComment, isMobileLandscape, landscapeScrollTop, windowScrollTop]);

  const displayControls = useMemo(() => {
    if (isEditingDrawing) {
      return false;
    }
    if (!playing) {
      if (!isDesktop) {
        setDisplayVideoControls(true);
      }
      return true;
    }
    return displayVideoControls || hovered;
  }, [displayVideoControls, hovered, isDesktop, isEditingDrawing, playing]);

  useVideoPlayerHotKeys(isPleb, isEditingDrawing, flashControlIcon);

  useBookmarkSeekerHotKeys(isPleb);

  const videoReplayLabelFilterValue = useVideoReplaySelectedLabelContext();

  const videoCommentMenuValue = useVideoCommentMenuContext();

  return (
    <FlexSpacer ref={fullscreenElRef} className={classNames(classes.root, className)} onScroll={handleScroll}>
      <AdaptedVideoPlayerContextProvider
      {...qualityPickerContext}
      video={video}
      hideControls={hideControls}
      flashControlIcon={flashControlIcon}
      >
        <FullscreenProvider value={fullscreenElRef.current}>
          <RangedTimestampProvider value={rangedTimestampContextValue}>
            <MemoizedVideoLayout
            className={classNames(classes.layout, {
              [classes.fullscreen]: fullscreen,
            })}
            classes={{
              mainPanel: classes.mainPanelWrapper,
              rightPanel: classes.rightPanelWrapper,
            }}
            fullscreen={fullscreen}
            leftPanel={useMemo(() => portalNode && showMatchPanel ? (
              <portals.OutPortal node={portalNode} />
            ) : undefined, [portalNode, showMatchPanel])}
            rightPanel={useMemo(() => (
              !isEditingDrawing ? (
                <VideoReplaySelectedLabelsProvider value={videoReplayLabelFilterValue}>
                  <VideoCommentMenuProvider value={videoCommentMenuValue}>
                    <RightPanel
                    videoId={videoId}
                    gameMatch={gameMatch}
                    onExportKillfeed={onExportKillfeed}
                    className={classes.rightPanel}
                    onEditCommentDrawing={onEditCommentDrawing}
                    commentBoxRefHandler={commentBoxRefHandler}
                    commentLabelRefHandler={commentLabelRefHandler}
                    />
                  </VideoCommentMenuProvider>
                </VideoReplaySelectedLabelsProvider>
              ) : null
            ), [
              classes.rightPanel,
              commentBoxRefHandler,
              commentLabelRefHandler,
              gameMatch,
              isEditingDrawing,
              onEditCommentDrawing,
              onExportKillfeed,
              videoCommentMenuValue,
              videoId,
              videoReplayLabelFilterValue,
            ])}
            subPanel={
              isDesktop ? (
                <div className={classNames(classes.subPanel, {[classes.editingDrawing]: isEditingDrawing})}>
                  <FlexSpacer>
                    {(videoId && isEditingDrawing) ? (
                      <EditDrawingPanel
                      videoId={videoId}
                      drawingState={drawingState}
                      comment={comment}
                      cancelEditing={cancelEditing}
                      />
                    ) : toolbar}
                  </FlexSpacer>
                  <div ref={drawingToolRefHandler}>
                    <div ref={toolsRef} />
                  </div>
                </div>
              ) : null
            }
            >
              {playbackVideo && VideoHelper.isWatchable(playbackVideo) ? (
                <React.Fragment>
                  <div
                  className={classNames(classes.videoPlayerWrapper, {[classes.away]: playing && !hovered})}
                  onMouseMove={handleDisplayBookmarkIndicator}
                  onMouseLeave={handleMouseLeave}
                  >
                    {isDesktop && controlIcon && (
                      <FlashFadeIcon className={classes.loader}>
                        {controlIcon}
                      </FlashFadeIcon>
                    )}
                    {!isDesktop && displayControls && !drawingState.enabled && (
                      <Paper className={classes.videoPlayerOverlay}>
                        <FlexSpacer
                        flexAlignItems='center'
                        fullWidth={true}
                        flexJustifyContent='center'
                        style={{ height: '100%' }}
                        >
                          <IconButton onClick={handleOverlayButton}>
                            {playing ? (
                              <PauseIcon style={{ fontSize: '6rem' }} />
                            ) : (
                              <PlayArrowIcon style={{ fontSize: '6rem' }} />
                            )}
                          </IconButton>
                        </FlexSpacer>
                      </Paper>
                    )}
                    <ReactResizeDetector handleWidth={true} handleHeight={true} onResize={handleResize} />
                    <WrappedReactPlayer
                    ref={reactPlayerRef}
                    video={playbackVideo}
                    playing={playing}
                    progressInterval={100}
                    volume={volume}
                    muted={muted}
                    playbackRate={playbackRate}
                    onStart={onStart}
                    onReady={onReady}
                    onBuffer={setBuffering}
                    onBufferEnd={setBufferEnd}
                    onPlay={_play}
                    onPause={_pause}
                    onDuration={setDuration}
                    onProgress={setProgress}
                    width={availableWidth}
                    height={availableHeight}
                    aspectRatio={aspectRatio}
                    isDesktop={isDesktop}
                    config={props.config}
                    >
                      <VideoPlayerDrawingOverlay
                      controllable={!isPleb}
                      state={drawingState}
                      alternateToolBarLocation={toolsElem}
                      initialObjects={initialCanvasObjects}
                      hideToolbar={!displayDrawingToolbar}
                      immutable={!displayDrawingToolbar}
                      disableVideoControl={isEditingDrawing}
                      {...drawingToolLiveSessionListeners}
                      />
                    </WrappedReactPlayer>
                    {displayControls && (
                      <Box
                      width={1}
                      className={classNames(classes.controlWrapper, {
                        [classes.fullscreen]: fullscreen,
                      })}
                      >
                        <Slide direction='up' in={!shouldHideControls} appear={false}>
                          <Fade in={!shouldHideControls} appear={false}>
                            <VideoPlayerControlsRetroFitted
                            video={video}
                            hideHostControls={isPleb}
                            flashControlIcon={flashControlIcon}
                            className={classNames(classes.controls, {
                              [classes.fullscreen]: fullscreen,
                            })}
                            shouldHideControls={shouldHideControls}
                            />
                          </Fade>
                        </Slide>
                      </Box>
                    )}
                  </div>
                  <Fade in={fullscreen && showFullscreenTip}>
                    <div className={classes.fullscreenTip}>
                      <FlexSpacer className={classes.fullscreenTipContent} flexAlignItems='center'>
                        <Typography variant='body2'>
                          {t(('videoplayer:player.togglecontrols'))}
                        </Typography>
                        <IconButton size='small' onClick={hideFullscreenTip}>
                          <CloseIcon />
                        </IconButton>
                      </FlexSpacer>
                      <div className={classNames(classes.fullscreenTipBar, {
                        [classes.hiding]: fullscreenTipTimerStarted,
                      })}
                      />
                    </div>
                  </Fade>
                </React.Fragment>
              ) : (
                <VideoNotReady />
              )}
            </MemoizedVideoLayout>
          </RangedTimestampProvider>
        </FullscreenProvider>
      </AdaptedVideoPlayerContextProvider>
    </FlexSpacer>
  );
}

const MemoizedVideoReplay = React.memo(VideoReplay);

interface VideoReplayWrapperOwnProps {
  className?: string;

  videoId?: ID;
  gameMatch?: GeneratedOverwatchMatch | TestingSSBUMatch;
  portalNode?: portals.HtmlPortalNode;
  toolbar?: React.ReactNode;
  controllable?: boolean;

  onExportKillfeed?: VoidFunction;

  initialState?: { position: number, playing: boolean };
  defaultPosition?: number;

  commentBoxRefHandler?: ((el: HTMLElement | null) => void);
  drawingToolRefHandler?: ((el: HTMLElement | null) => void);
  commentLabelRefHandler?: ((el: HTMLElement | null) => void);
}

type VideoReplayWrapperProps = VideoReplayWrapperOwnProps;

export default function VideoReplayWrapper(props: VideoReplayWrapperProps) {
  const liveSessionVideo = useSelector(getLiveSessionVideo);
  const isJoinedLiveSession = useSelector(getIsJoinedLiveSession);
  const canDrawInLivesession = useSelector(getLiveSessionCanDraw);

  const [width, setWidth] = useState(0);

  const isDesktop = useIsDesktop();

  const [ controlIcon, _flashControlIcon ] = useState<React.ReactNode>(null);
  const flashControlImmediateRef = useRef<NodeJS.Immediate>();

  useEffect(() => () => {
    if (flashControlImmediateRef.current) {
      clearImmediate(flashControlImmediateRef.current);
    }
  }, []);

  const flashControlIcon = useCallback((icon: React.ReactNode) => {
    _flashControlIcon(null);
    if (flashControlImmediateRef.current) {
      clearImmediate(flashControlImmediateRef.current);
    }
    flashControlImmediateRef.current = setImmediate(() => _flashControlIcon(icon));
  }, []);

  const drawingTool = useContext(DrawingToolContext);

  const drawingStateRef = useRef(drawingTool.drawingState);

  useEffect(() => {
    drawingStateRef.current = drawingTool.drawingState;
  }, [drawingTool.drawingState]);

  const videoReplay = useContext(VideoReplayContext);

  const loadingContext = useContext(LoadingContext);

  const videoReplayRef = useRef(videoReplay);

  const controllable = props.controllable ?? true;

  useEffect(() => {
    videoReplayRef.current = videoReplay;
  }, [videoReplay]);

  const {
    state: {
      fullscreen,
      muted,
      playbackRate,
      playing,
      visuallyPlaying,
      theaterMode,
      volume,
      buffering,
      _fullscreenRequested,
      _seekOptions,
    },
    setBufferEnd,
    setBuffering,
    setDuration,
    setFullscreenEntered,
    setFullscreenExited,
    setProgress,
    dispatch,
  } = videoReplay;

  const globalDispatch = useDispatch();

  useEffect(() => {
    if (!props.videoId || !buffering) {
      return;
    }

    globalDispatch(telemetry.videoBuffering({
      videoId: props.videoId,
      position: videoReplayRef.current.state.progress.playedSeconds,
    }));
  }, [buffering, globalDispatch, props.videoId]);

  const handleReady = useSeekOnReady(videoReplay, {
    initialState: props.initialState,
    defaultPosition: props.defaultPosition,
  });

  const videoBookmarkContextValue = useBookmarkContextValue(props.videoId, width);

  const [hideControls, setHideControls] = useState(false);

  useVideoReplayKeybindings(videoReplay, setHideControls, controllable);

  const onExitTheaterMode = useExitTheaterMode();
  const onEnterTheaterMode = useEnterTheaterMode();

  useEffect(() => {
    if (theaterMode) {
      onEnterTheaterMode();
    } else {
      onExitTheaterMode();
    }
  }, [onEnterTheaterMode, onExitTheaterMode, theaterMode]);

  useEffect(() => {
    const fullscreenListener = () => {
      if (screenfull.isEnabled) {
        if (screenfull.isFullscreen) {
          setFullscreenEntered();
        } else {
          setFullscreenExited();
        }
      }
    };
    if (screenfull.isEnabled) {
      screenfull.on('change', fullscreenListener);
    }
    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', fullscreenListener);
      }
    };
  }, [setFullscreenEntered, setFullscreenExited]);

  useEffect(() => {
    flashControlIcon(visuallyPlaying ? <PlayArrowIcon /> : <PauseIcon />);
  }, [flashControlIcon, visuallyPlaying]);

  useEffect(() => {
    setLocalStorage(VIDEO_VOLUME, `${volume}`);
  }, [volume]);

  useEffect(() => {
    setLocalStorage(VIDEO_MUTED, `${muted}`);
  }, [muted]);

  const displayDrawingToolbar = useMemo(
    () => !isJoinedLiveSession || canDrawInLivesession,
    [canDrawInLivesession, isJoinedLiveSession],
  );

  const shouldHideControls = hideControls && fullscreen;
  const [ showFullscreenTip, setShowFullscreenTip ] = useState(true);

  const hideFullscreenTip = useCallback(() => setShowFullscreenTip(false), [setShowFullscreenTip]);

  const fullscreenTipTimerRef = useRef<NodeJS.Timeout>();
  const [ fullscreenTipTimerStarted, setFullscreenTipTimerStarted ] = useState(false);

  useEffect(() => {
    if (fullscreenTipTimerRef.current) { return; }
    if (fullscreen && showFullscreenTip) {
      fullscreenTipTimerRef.current = setTimeout(() => setShowFullscreenTip(false), 5000);
      setFullscreenTipTimerStarted(true);
    }
    return () => {
      if (fullscreenTipTimerRef.current) {
        clearTimeout(fullscreenTipTimerRef.current);
        setShowFullscreenTip(false);
      }
    };
  }, [fullscreen, showFullscreenTip]);

  useEffect(() => {
    if (playing && !drawingStateRef.current?.enabled) {
      drawingStateRef.current?.reset();
      drawingTool.setViewedComment(undefined);
    }
  }, [drawingTool, playing]);

  const _play = useCallback(() => dispatch(withActionMetadata(videoReplayActions.play(), { raw: true })), [dispatch]);
  const _pause = useCallback(() => dispatch(withActionMetadata(videoReplayActions.pause(), { raw: true })), [dispatch]);

  const [currentComment, setCurrentComment] = useState<CommentFragment_VideoComment>();
  const [currentNote, setCurrentNote] = useState<string>('');

  const { swapPlayer } = useContext(AudioCommentSourceContext);

  const swapPlayerRef = useHackRef(swapPlayer);

  useEffect(() => {
    if (playing) {
      swapPlayerRef.current();
    }
  }, [playing, swapPlayerRef]);

  const reactPlayerConfig = useMemo(() => {
    const autoPlay = props.initialState ? props.initialState.playing : false;
    return {
      file: {
        attributes: {
          autoPlay,
        },
      },
      youtube: {
        playerVars: {
          autoplay: autoPlay ? 1 : 0,
        },
      },
    };
  }, [props.initialState]);

  return (
    <VideoCommentProvider value={{currentComment, setCurrentComment}}>
      <VideoBookmarksProvider value={videoBookmarkContextValue}>
        <VideoNoteProvider value={{currentNote, setCurrentNote}}>
          {isDesktop && props.videoId && (
            <BookmarkFetcher videoId={props.videoId} />
          )}
          <MemoizedVideoReplay
          {...props}
          drawingState={drawingTool.drawingState}
          handleReady={handleReady}
          _fullscreenRequested={_fullscreenRequested}
          theaterMode={theaterMode}
          displayDrawingToolbar={displayDrawingToolbar}
          _seekOptions={_seekOptions}
          controlIcon={controlIcon}
          flashControlIcon={flashControlIcon}
          hideControls={hideControls}
          shouldHideControls={shouldHideControls}
          isPleb={!controllable}
          fullscreen={fullscreen}
          showFullscreenTip={showFullscreenTip}
          hideFullscreenTip={hideFullscreenTip}
          fullscreenTipTimerStarted={fullscreenTipTimerStarted}
          playing={!!loadingContext?.ready && playing}
          volume={volume}
          muted={muted}
          playbackRate={playbackRate}
          setBuffering={setBuffering}
          setBufferEnd={setBufferEnd}
          _play={_play}
          _pause={_pause}
          setDuration={setDuration}
          setProgress={setProgress}
          fallback={liveSessionVideo}
          setHovered={videoBookmarkContextValue.setHovered}
          onWidthChange={setWidth}
          config={reactPlayerConfig}
          />
        </VideoNoteProvider>
      </VideoBookmarksProvider>
    </VideoCommentProvider>
  );
}
