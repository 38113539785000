import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { forwardRef, useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';

import { IntercomLogo } from '../../assets';

const IntercomButton = forwardRef<HTMLButtonElement, {}>((props, ref) => {
  const { show } = useIntercom();
  const { t } = useStrictTranslation(['common']);
  const isDesktop = useIsDesktop();

  const showIntercom = useCallback(() => {
    show();
  }, [show]);

  return (
    isDesktop ? (
      <IconButton onClick={showIntercom} {...props} ref={ref}>
        <SvgIcon>
          <IntercomLogo />
        </SvgIcon>
      </IconButton>
    ) : (
      <MenuItem onClick={showIntercom}>
        <ListItemIcon>
          <SvgIcon>
            <IntercomLogo />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText>
          {t('common:intercom')}
        </ListItemText>
      </MenuItem>
    )
  );
});
IntercomButton.displayName = 'IntercomButton';

export default IntercomButton;
