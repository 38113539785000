import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import DashboardTeamStatisticsRouting from 'features/dashboard/team-statistics/dashboard-team-statistics-routing';
import { makeGetVideosByIds } from 'features/dashboard/video/dashboard-video-selector';
import VideoPickerDialog from 'features/dashboard/video-picker-dialog/VideoPickerDialog';
import { useInvalidateTeamStatsCache } from 'features/statistics/useInvalidateTeamStatsCache';
import { VideoHelper } from 'features/video-library/video-helpers';
import { useCreateSelector } from 'hooks/useCreateSelector';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useParams } from 'react-router';

import OverwatchPrimaryRosterPlayerStatistics from '../../../containers/OverwatchPrimaryRosterPlayerStatistics';
import OverwatchPrimaryRosterTeamFightCompositionStatistics from '../../../containers/OverwatchPrimaryRosterTeamFightCompositionStatistics';
import OverwatchPrimaryRosterTeamFightMapStatistics from '../../../containers/OverwatchPrimaryRosterTeamFightMapStatistics';
import { isTruthy } from '../../../helpers';
import { useDialogState } from '../../../hooks/useDialogState';
import { useNavigate } from '../../../hooks/useNavigate';
import { OwstatsF, OwstatsNS } from '../../../locales/en/owstats';
import {
  VIDEO_COMPOSITIONS_STATS_PATH,
  VIDEO_MAP_STATS_PATH,
  VIDEO_PLAYER_STATS_PATH,
  videoStatsRoute,
} from '../../../routes';
import { ID } from '../../../types/pigeon';
import TeamRosterSelect from '../team-settings/team-roster-select/TeamRosterSelect';
import OverwatchCustomQueries from './overwatch-custom-queries/OverwatchCustomQueries';

interface TeamStatisticsVideoOwnProps {
  source: 'video';
  team: TeamFragment;
  videoId: ID;
}

interface TeamStatisticsDashboardOwnProps {
  source: 'dashboard';
  team: TeamFragment;
}

type Both = TeamStatisticsVideoOwnProps | TeamStatisticsDashboardOwnProps;

type TeamStatisticsOwnProps = Both & {
  className?: string;
}

type TeamStatisticsProps = TeamStatisticsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
    display:'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    display:'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  tabsContainer: {
    display:'flex',
    justifyContent:'space-between',
    marginBottom: theme.spacing(1),
    flexWrap: 'wrap',
  },
}), {name: 'TeamStatistics'});

function TeamStatistics(props: TeamStatisticsProps) {
  const classes = useStyles(props);
  const { className, team } = props;

  const onInvalidateTeamStatsCache = useInvalidateTeamStatsCache(team.id);

  const { t } = useTranslation(OwstatsNS);
  const { tab, game } = useParams<DashboardTeamStatisticsRouting.Params>();
  const onNavigate = useNavigate();

  const [isVideoSelectorDialogOpen, openVideoSelectorDialog, closeVideoSelectorDialog] = useDialogState();
  const [selectedVideoIds, setSelectedVideoIds] = useState<ID[]>([]);

  const videoId = props.source === 'video' ? props.videoId : undefined;
  const videoIds = useMemo(() => videoId ? [videoId] : selectedVideoIds, [selectedVideoIds, videoId]);
  const videos = useCreateSelector(makeGetVideosByIds, videoIds);

  const csvName = useMemo(() => {
    if (videos.length) {
      return videos.map(video => video.name).join('-');
    }
    return team.name;
  }, [team.name, videos]);

  const tabs = useMemo(() => {
    return [
      DashboardTeamStatisticsRouting.Tab.PLAYERS,
      DashboardTeamStatisticsRouting.Tab.TEAMCOMPS,
      DashboardTeamStatisticsRouting.Tab.MAPS,
      props.source === 'dashboard' && DashboardTeamStatisticsRouting.Tab.SELECT_ROSTER,
      props.source === 'dashboard' && DashboardTeamStatisticsRouting.Tab.CUSTOM_QUERIES,
    ].filter(isTruthy);
  }, [props.source]);

  const handleTabsOnChange = useCallback((__: React.ChangeEvent, newValue: DashboardTeamStatisticsRouting.Tab) => {
    if (props.source === 'dashboard') {
      onNavigate(DashboardTeamStatisticsRouting.createUrl(
        team.id,
        DashboardTeamStatisticsRouting.Game.OVERWATCH,
        newValue,
      ));
    } else if (videoId) {
      onNavigate(videoStatsRoute(videoId, newValue as any));
    }
  }, [onNavigate, props.source, team.id, videoId]);

  const handleVideosSelected = useCallback((videoIds: ID[]) => {
    closeVideoSelectorDialog();
    setSelectedVideoIds(videoIds);
  }, [closeVideoSelectorDialog]);

  const renderPlayerStatsTab = useCallback(() => (
    <OverwatchPrimaryRosterPlayerStatistics
    videoIds={videoIds}
    team={team}
    csvName={csvName}
    />
  ), [csvName, team, videoIds]);

  const renderCompositionStatsTab = useCallback(() => (
    <OverwatchPrimaryRosterTeamFightCompositionStatistics
    videoIds={videoIds}
    team={team}
    csvName={csvName}
    />
  ), [csvName, team, videoIds]);

  const renderMapStatsTab = useCallback(() => (
    <OverwatchPrimaryRosterTeamFightMapStatistics
    videoIds={videoIds}
    teamId={team.id}
    csvName={csvName}
    />
  ), [csvName, team.id, videoIds]);

  const renderRosterSelect = useCallback(() => (
    <TeamRosterSelect team={team} />
  ), [team]);

  const renderCustomQueries = useCallback(() => (
    <OverwatchCustomQueries team={team} />
  ), [team]);

  const renderNoTabRedirect = useCallback(() => {
    if (props.source === 'dashboard' && game) {
      return (
        <Redirect
        to={DashboardTeamStatisticsRouting.createUrl(
          team.id,
          game as DashboardTeamStatisticsRouting.Game,
          DashboardTeamStatisticsRouting.Tab.PLAYERS,
        )}
        />
      );
    } else if (videoId) {
      return (
        <Redirect to={videoStatsRoute(videoId, DashboardTeamStatisticsRouting.Tab.PLAYERS)} />
      );
    }

    return null;
  }, [game, props.source, team.id, videoId]);

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.tabsContainer}>
        <Tabs
        value={tab}
        onChange={handleTabsOnChange}
        variant='scrollable'
        >
          {tabs.map(tab => (
            <Tab key={tab} value={tab} label={t(OwstatsF(tab))} />
          ))}
        </Tabs>
        <FlexSpacer flexJustifyContent='flex-end'>
          {!videoId && (
            <Button onClick={openVideoSelectorDialog}>
              {t(OwstatsF('selectvideos'))}
            </Button>
          )}
          <Button onClick={onInvalidateTeamStatsCache}>
            {t(OwstatsF('refreshstats'))}
          </Button>
        </FlexSpacer>
      </div>
      <div className={classes.content}>
        <Switch>
          <Route path={DashboardTeamStatisticsRouting.playersPath} render={renderPlayerStatsTab} />
          <Route path={DashboardTeamStatisticsRouting.teamcompsPath} render={renderCompositionStatsTab} />
          <Route path={DashboardTeamStatisticsRouting.mapsPath} render={renderMapStatsTab} />
          <Route path={DashboardTeamStatisticsRouting.selectRosterPath} render={renderRosterSelect} />
          <Route path={DashboardTeamStatisticsRouting.customQueriesPath} render={renderCustomQueries} />
          <Route path={VIDEO_PLAYER_STATS_PATH} render={renderPlayerStatsTab} />
          <Route path={VIDEO_COMPOSITIONS_STATS_PATH} render={renderCompositionStatsTab} />
          <Route path={VIDEO_MAP_STATS_PATH} render={renderMapStatsTab} />
          <Route render={renderNoTabRedirect} />
        </Switch>
      </div>
      {props.source === 'dashboard' && (
        <VideoPickerDialog
        open={isVideoSelectorDialogOpen}
        teamId={team.id}
        multiple={true}
        initialValue={selectedVideoIds}
        filterFn={VideoHelper.hasCompleteAnalysis}
        onClose={closeVideoSelectorDialog}
        onConfirm={handleVideosSelected}
        />
      )}
    </div>
  );
}

export default React.memo(TeamStatistics);
