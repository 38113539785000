import { FlexSpacer, Loader } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { makeGetUserTokens } from 'features/auth/auth-selector';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

import ActiveSessionsTable from './active-sessions-table/ActiveSessionsTable';
import { useFetchUserTokens } from './useFetchUserTokens';

interface ActiveSessionsOwnProps {
  className?: string;
}

type ActiveSessionsProps = ActiveSessionsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'ActiveSessions'});

function ActiveSessions(props: ActiveSessionsProps) {
  const classes = useStyles(props);
  const { className } = props;

  const { t } = useStrictTranslation(['common']);

  const [ tokenFetchStatus ] = useFetchUserTokens('');
  const tokens = useCreateSelector(makeGetUserTokens, {});

  return (
    <FlexSpacer className={classNames(classes.root, className)}>
      {tokenFetchStatus.fetching && !tokenFetchStatus.cursor ? (
        <Loader>{t('common:loading.generic')}</Loader>
      ) : (
        <ActiveSessionsTable tokens={tokens} />
      )}
    </FlexSpacer>
  );
}

export default React.memo(ActiveSessions);
