import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import { invalidateTeamStatsCacheAC } from './statistics-slice';

export function useInvalidateTeamStatsCache(teamId: ID) {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(invalidateTeamStatsCacheAC({ teamId })), [dispatch, teamId]);
}
