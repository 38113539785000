import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { promisifyAction } from 'sagas/promise-saga';
import { AsyncActionCreators } from 'typescript-fsa';

export function usePromiseSagaDispatch() {
  const dispatch = useDispatch();
  return useCallback(<AC extends AsyncActionCreators<any, any, Error>>(
    asyncAC: AC,
    params: ParamsFromAsyncActionCreator<AC>,
    meta?: any,
  ) => {
    return new Promise<ResultFromAsyncActionCreator<AC>>((resolve, reject) => {
      const action = promisifyAction(asyncAC, params, {...meta, resolve, reject});
      dispatch(action);
    });
  }, [dispatch]);
}

export const promisifyDispatch = (dispatch: Dispatch) =>
<AC extends AsyncActionCreators<any, any, Error>>(
  asyncAC: AC,
  params: ParamsFromAsyncActionCreator<AC>,
  meta?: any,
) => {
  return new Promise<ResultFromAsyncActionCreator<AC>>((resolve, reject) => {
    const action = promisifyAction(asyncAC, params, {...meta, resolve, reject});
    dispatch(action);
  });
};
