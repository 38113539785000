import DashboardTeamSearchRouting from 'features/search/dashboard-team-search.routing';
import DashboardSearchMenu from 'features/search/SearchMenu';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ID } from 'types/pigeon';

import DashboardTeamSettingsRouting from './team/dashboard-team-settings.routing';
import DashboardTeamDirectories from './team/DashboardTeamDirectories';
import DashboardTeamSettings from './team/DashboardTeamSettings';
import { useFetchTeamIfNecessary } from './team/useFetchTeamIfNecessary';
import DashboardTeamStatisticsRouting from './team-statistics/dashboard-team-statistics-routing';
import DashboardTeamStatistics from './team-statistics/DashboardTeamStatistics';

interface DashboardTeamContentOwnProps {
  teamId: ID;
}

type DashboardTeamContentProps = DashboardTeamContentOwnProps;

function DashboardTeamContent(props: DashboardTeamContentProps) {
  const { teamId } = props;
  const [team, sharedTeam] = useFetchTeamIfNecessary(teamId);

  return team ? (
    <Switch>
      <Route path={DashboardTeamSearchRouting.path}>
        <DashboardSearchMenu team={team}/>
      </Route>
      <Route path={DashboardTeamStatisticsRouting.path}>
        <DashboardTeamStatistics team={team} />
      </Route>
      <Route path={DashboardTeamSettingsRouting.path}>
        <DashboardTeamSettings team={team} />
      </Route>
      <Route>
        <DashboardTeamDirectories team={team} />
      </Route>
    </Switch>
  ) : sharedTeam ? (
    <Switch>
      <Route>
        <DashboardTeamDirectories team={sharedTeam} />
      </Route>
    </Switch>
  ) : null;
}

export default React.memo(DashboardTeamContent);
