import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RosterPlayersFragment } from '../../../../../apollo/fragments/types/RosterPlayersFragment';
import { GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFights } from '../../../../../apollo/queries/types/GetOverwatchAdvancedQueryQuery';
import { combineIds } from '../../../../../helpers';
import { slicePage } from '../../../../../helpers/paginate';
import { OwstatsF, OwstatsNS } from '../../../../../locales/en/owstats';
import Pagination from '../../../../../material/pagination/Pagination';
import TableHeader, { ITableColumnHeader } from '../../../../../material/table/TableHeader';
import OverwatchAdvancedQueryTeamfightBreakdownTableRow from './OverwatchAdvancedQueryTeamfightBreakdownTableRow';

type OverwatchAdvancedQueryTeamfight = GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFights;

const columns: ITableColumnHeader[] = [
  {
    title: '',
    displayEmpty: true,
  },
  {
    title: OwstatsF('teamfightbreakdown.teamfightclip'),
    align: 'center',
  },
  {
    title: '',
    displayEmpty: true,
  },
  {
    title: OwstatsF('teamfightbreakdown.result'),
    align: 'center',
  },
  {
    title: OwstatsF('teamfightbreakdown.herocomp'),
    align: 'center',
  },
  {
    title: OwstatsF('teamfightbreakdown.kills'),
    align: 'center',
  },
  {
    title: OwstatsF('teamfightbreakdown.firstult'),
  },
  {
    title: OwstatsF('teamfightbreakdown.firstkill'),
  },
  {
    title: OwstatsF('teamfightbreakdown.firstdeath'),
  },
  {
    title: OwstatsF('teamfightbreakdown.ultsused'),
    align: 'center',
  },
];

export interface IOverwatchAdvancedQueryTeamfightBreakdownTableOwnProps {
  teamfights: GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFights[];
  primaryRoster: RosterPlayersFragment;
}

type Props = IOverwatchAdvancedQueryTeamfightBreakdownTableOwnProps;

const ROWS_PER_PAGE = 5;

const useStyles = makeStyles((theme: Theme) => createStyles({
  pagination: {
    paddingTop: theme.spacing(2),
    flex: 0,
  },
}), {name: 'OverwatchAdvancedQueryTeamfightBreakdownTable'});

function OverwatchAdvancedQueryTeamfightBreakdownTable(props: Props) {
  const { teamfights, primaryRoster } = props;
  const classes = useStyles(props);
  const { t } = useTranslation(OwstatsNS);

  const [ currPage, setCurrPage ] = useState(1);

  const pages = useMemo(() => {
    return Math.ceil(teamfights.length / ROWS_PER_PAGE);
  }, [teamfights]);

  const teamfightsToDisplay = useMemo(() => {
    return slicePage(teamfights, ROWS_PER_PAGE, currPage);
  }, [currPage, teamfights]);

  const handlePageChange = useCallback((e: React.ChangeEvent, newPage: number) => {
    setCurrPage(newPage);
  }, []);

  const renderTableRow = useCallback((tf: OverwatchAdvancedQueryTeamfight, i: number) => {
    return (
      <OverwatchAdvancedQueryTeamfightBreakdownTableRow
      key={combineIds(tf.teamFightData.start, tf.teamFightData.end)}
      teamfightData={tf.teamFightData}
      teamfightNum={i + 1}
      rosters={tf.rosters}
      videoId={tf.videoId}
      primaryRoster={primaryRoster}
      />
    );
  }, [primaryRoster]);

  const renderBody = useCallback(() => {
    return (
      <TableBody>
        {teamfightsToDisplay.map(renderTableRow)}
      </TableBody>
    );
  }, [teamfightsToDisplay, renderTableRow]);

  return (
    <React.Fragment>
      <Table>
        <TableHeader headers={columns} t={t} />
        {renderBody()}
      </Table>
      {pages > 1 && (
        <Box className={classes.pagination}>
          <Pagination
          activePage={currPage}
          onPageChange={handlePageChange}
          totalPages={pages}
          />
        </Box>
      )}
    </React.Fragment>
  );
}

export default React.memo(OverwatchAdvancedQueryTeamfightBreakdownTable);
