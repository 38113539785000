import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';

import { OwstatsF, OwstatsNS } from '../../../../locales/en/owstats';
import Message from '../../../../material/message/Message';
import { ID, OWLVideo } from '../../../../types/pigeon';
import { GeneratedOverwatchMatch } from '../../../../types/pigeon/matches';
import { IOverwatchMatchDataTeamfight } from '../../../../types/pigeon/overwatch-match-data';
import { IOWLUserMetadata } from '../../../../types/pigeon/owl-match';
import OverwatchTeamfightBreakdownTableRow from './overwatch-teamfight-breakdown-table-row/OverwatchTeamfightBreakdownTableRow';

export interface IOverwatchTeamfightBreakdownTableOwnProps {
  match: GeneratedOverwatchMatch;
  videoId: ID;
  userMetadata?: IOWLUserMetadata;
  owlVideo?: OWLVideo;
}

export type IOverwatchTeamfightBreakdownTableProps = IOverwatchTeamfightBreakdownTableOwnProps &
  WithTranslation;
class OverwatchTeamfightBreakdownTable extends React.Component<IOverwatchTeamfightBreakdownTableProps> {
  public render() {
    const { t, match } = this.props;
    if (!match.teamfights) {
      return (
        <Message message={t(OwstatsF('nodata'))} />
      );
    }
    return (
      <Table>
        {this.renderHeader()}
        <TableBody>
          {match.teamfights.map(this.renderBody)}
        </TableBody>
      </Table>
    );
  }

  private renderHeader = () => {
    const { t, userMetadata } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell align='center'>{t(OwstatsF('teamfightbreakdown.teamfightclip'))}</TableCell>
          {userMetadata && <TableCell align='center' />}
          <TableCell align='center'>{t(OwstatsF('teamfightbreakdown.result'))}</TableCell>
          <TableCell align='center'>{t(OwstatsF('teamfightbreakdown.herocomp'))}</TableCell>
          <TableCell align='center'>{t(OwstatsF('teamfightbreakdown.kills'))}</TableCell>
          <TableCell align='center'>{t(OwstatsF('teamfightbreakdown.prefightults'))}</TableCell>
          <TableCell>{t(OwstatsF('teamfightbreakdown.firstult'))}</TableCell>
          <TableCell>{t(OwstatsF('teamfightbreakdown.firstkill'))}</TableCell>
          <TableCell>{t(OwstatsF('teamfightbreakdown.firstdeath'))}</TableCell>
          <TableCell align='center'>{t(OwstatsF('teamfightbreakdown.ultsused'))}</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  private renderBody = (teamfight: IOverwatchMatchDataTeamfight, idx: number) => {
    const { videoId, match, userMetadata, owlVideo } = this.props;
    return (
      <OverwatchTeamfightBreakdownTableRow
      teamfight={teamfight}
      teamfightNum={idx}
      videoId={videoId}
      key={idx}
      match={match}
      userMetadata={userMetadata}
      owlVideo={owlVideo}
      />
    );
  }
}

export default withTranslation(OwstatsNS)(OverwatchTeamfightBreakdownTable);
