import { ID } from '../';

export interface IOverwatchMatchDataTeam {
  blueTeam   : ID;
  bluePlayers: ID[];
  redTeam    : ID;
  redPlayers : ID[];
}

export interface IOverwatchMatchDataPlayer {
  name  : string;
  heroes: IOverwatchMatchDataPlayerHero[];
}

export interface IOverwatchMatchDataPlayerHero {
  name     : string;
  startTime: number;
}

export enum OverwatchEventType {
  STATUS = 'status',
  KILL = 'kill',
  ULT = 'ult',
  RES = 'res',
  OBJKILL = 'obj_kill',
  EAT = 'eat', // Dva absorbs an ult
  MECHKILL = 'mech_kill',
  SUICIDE = 'suicide',
}

export interface IOverwatchMatchData {
  kills             : IOverwatchMatchDataKill[];
  players           : IOverwatchMatchDataPlayer[];
  stats?            : IOverwatchMatchDataStat[];
  teamfights?       : IOverwatchMatchDataTeamfight[];
  teamfightStats?   : IOverwatchMatchDataTeamfightStats;
  deaths?           : IOverwatchMatchDataDeath[];
  statuses?         : IOverwatchMatchDataStatus[];
  ultimates?        : IOverwatchMatchDataHeroUltimate[];
  nonTeamfightEvents: OverwatchMatchDataNonTeamfightEvent[];
  map               : string;
  teams?            : IOverwatchMatchDataTeam[];
  scoreInfo         : IOverwatchMatchDataScoreInfo;
}

export interface IOverwatchHybridMatchData extends IOverwatchMatchData {
  gamemode  : 'hybrid';
  leftScore : number;
  rightScore: number;
  winner    : OverwatchTeamColor;
}

export type OverwatchMatchData = IOverwatchHybridMatchData | IOverwatchMatchData;

export interface IOverwatchMatchDataKill {
  time    : number;
  ability : string;
  critical: boolean;
  killer  : IOverwatchMatchDataKillPlayer;
  killee  : IOverwatchMatchDataKillPlayer;
}

export interface IOverwatchMatchDataKillAbility {
  name: string;
  hero: string;
}

export interface IOverwatchMatchDataResPlayer {
  hero: string;
  color: string;
}

export interface IOverwatchMatchDataKillPlayer {
  hero: string;
  color: OverwatchTeamColor;
  currentCopy?: string;
}

// export interface IOverwatchMatchDataKillKiller extends IOverwatchMatchDataKillPlayer {
//   color: OverwatchTeamColor;
// }

export interface IOverwatchMatchDataStat {
  index           : number;
  averageTimeToChargeUlt: number;
  averageUltHoldTime    : number;
  averageUltKills : number;
  totalKills      : number;
  totalDeaths     : number;
  totalAssists    : number;
  totalUltsUsed   : number;
  totalUltsKills  : number;
  totalUltHoldTime: number;
  timesResurrected: number;
  heroPlaytimes   : IPlayerHeroDetails[];
}

export interface IPlayerHeroDetails {
  hero      : string;
  timePlayed: number;
  assists   : number;
  suicides  : number;
  niche     : IPlayerHeroDetailsNicheStats;
}

export interface IPlayerHeroDetailsNicheStats {
  numUltAssists?: number;
  numUltKills?  : number;
  numUltStuns?  : number;
  ultEats?      : number;
  numUltTeammateDeaths?: number;
  resurrections?: number;
  numUltFreezes?: number;
  numUltHacks?  : number;
}

export interface IOverwatchMatchDataScoreInfo {
  gamemode: 'assault' | 'hybrid' | 'control' | 'escort';
  progress: string;
  color: OverwatchTeamColor;
}

export interface IOverwatchMatchDataTeamfight {
  startTime         : number;
  endTime           : number;
  blueHeroes        : string[];
  blueTeamKills     : number;
  blueTeamUltsAfter : IOverwatchMatchDataTeamfightUltimate[];
  blueTeamUltsBefore: IOverwatchMatchDataTeamfightUltimate[];
  blueTeamUltsUsed  : IOverwatchMatchDataTeamfightUltimate[];
  redHeroes         : string[];
  redTeamKills      : number;
  redTeamUltsAfter  : IOverwatchMatchDataTeamfightUltimate[];
  redTeamUltsBefore : IOverwatchMatchDataTeamfightUltimate[];
  redTeamUltsUsed   : IOverwatchMatchDataTeamfightUltimate[];
  firstDeath        : IOverwatchMatchDataTeamfightKillorDeath;
  firstKill         : IOverwatchMatchDataTeamfightKillorDeath;
  firstUlt          : IOverwatchMatchDataTeamfightKillorDeath;
  events            : OverwatchMatchDataTeamfightEvent[];
  winner            : string;
}

export interface IOverwatchMatchDataTeamfightStats {
  averageBlueDeaths : number;
  averageBlueKills  : number;
  averageBlueUlts   : number;
  averageRedDeaths  : number;
  averageRedKills   : number;
  averageRedUlts    : number;
  numberOfTeamfights: number;
  totalBlueKills    : number;
  totalBlueDeaths   : number;
  totalBlueUlts     : number;
  totalRedKills     : number;
  totalRedDeaths    : number;
  totalRedUlts      : number;
}

export interface IOverwatchMatchDataTeamfightKillorDeath {
  team  : OverwatchTeamColor;
  player: number;
  hero  : string;
}

export interface IOverwatchMatchDataTeamfightUltimate {
  index: number;
  hero : string;
  status: 'ready' | 'using' | number;
}

export interface IOverwatchMatchDataDeath {
  index    : number;
  startTime: number;
  endTime  : number;
}

export interface IOverwatchMatchDataStatus {
  index: number;
  color: string;
  hero: string;
  startTime: number;
  status: string;
}

export interface IOverwatchMatchDataHeroUltimate {
  heroPosition: number;
  ults        : IOverwatchMatchDataUltimate[];
}

export interface IOverwatchMatchDataUltimate {
  status   : 'ready' | 'charging';
  startTime: number;
}

export interface IOverwatchMatchDataNonTeamfightKillEvent {
  eventType: 'kill' | 'obj_kill' | 'eat' | 'mech_kill';
  time: number;
  details: IOverwatchMatchDataNonTeamfightKillEventDetail;
}

export interface IOverwatchMatchDataNonTeamfightUltEvent {
  eventType: 'ult';
  time: number;
  details: IOverwatchMatchDataNonTeamfightUltEventDetail;
}

export interface IOverwatchMatchDataNonTeamfightResEvent {
  eventType: 'res';
  time: number;
  details: IOverwatchMatchDataNonTeamfightResEventDetail;
}

export interface IOverwatchMatchDataNonTeamfightResEventDetail {
  type: 'res';
  killee: IOverwatchMatchDataResPlayer;
  killer: IOverwatchMatchDataResPlayer;
  time: number;
}

export interface IOverwatchMatchDataTeamfightSuicideEvent {
  type: 'suicide';
  team: OverwatchTeamColor;
  hero: string;
  time: number;
}

export interface IOverwatchMatchDataNonTeamfightSuicideEvent {
  eventType: 'suicide';
  time: number;
  details: IOverwatchMatchDataNonTeamfightSuicideDetail;
}

export interface IOverwatchMatchDataNonTeamfightSuicideDetail {
  type: 'suicide';
  color: OverwatchTeamColor;
  hero: string;
  time: number;
}

export interface IOverwatchMatchDataNonTeamfightUltEventDetail {
  index: number;
  color: OverwatchTeamColor;
  hero: string;
  targetHero?: string;
  currentCopy?: string;
}

export interface IOverwatchMatchDataNonTeamfightKillEventDetail {
  startTime: number;
  killer: IOverwatchMatchDataKillPlayer;
  killee: IOverwatchMatchDataKillPlayer;
  ability: string;
}

export interface IOverwatchMatchDataTeamfightUltEvent {
  type  : 'ult';
  time  : number;
  team  : OverwatchTeamColor;
  hero  : string;
  player: number;
  numHacks?: number;
  numFreezes?: number;
  numStuns?: number;
  numAssists?: number;
  numKills?: number;
  numTeammateDeaths?: number;
  targetHero?: string;
  currentCopy?: string;
}

export interface IOverwatchMatchDataTeamfightKillEvent extends IOverwatchMatchDataKill {
  type: OverwatchEventType.KILL | OverwatchEventType.OBJKILL | OverwatchEventType.EAT | OverwatchEventType.MECHKILL;
  player: number;
}

export interface IOverwatchMatchDataTeamfightResEvent {
  type: OverwatchEventType.RES;
  hero: string;
  team: OverwatchTeamColor;
  time: number;
}

export interface IOverwatchMatchDataTeamfightStatusEvent {
  type: OverwatchEventType.STATUS;
  hero: string;
  team: OverwatchTeamColor;
  status: string;
  time: number;
}

export interface IOverwatchMatchDataNonTeamfightStatusEvent {
  eventType: OverwatchEventType.STATUS;
  time: number;
  details: IOverwatchMatchDataNonTeamfightStatusDetail;
}

export interface IOverwatchMatchDataNonTeamfightStatusDetail {
  type: OverwatchEventType.STATUS;
  hero: string;
  team: OverwatchTeamColor;
  status: string;
  time: number;
}

export interface ICombinedTimelineEvent {
  type: 'event' | 'teamfight';
  time: number;
}

export interface ITeamfight extends ICombinedTimelineEvent {
  type: 'teamfight';
  events: IEventTime[];
  endTime: number;
}

export interface IEventTime {
  time: string;
  type: string;
  event: OverwatchMatchDataTeamfightEvent;
}

export interface IEvent extends ICombinedTimelineEvent {
  type: 'event';
  event: OverwatchMatchDataNonTeamfightEvent;
  formattedTime: string;
}

export type TimelineEvent = ITeamfight | IEvent;

export type OverwatchMatchDataTeamfightEvent =
  | IOverwatchMatchDataTeamfightUltEvent
  | IOverwatchMatchDataTeamfightKillEvent
  | IOverwatchMatchDataTeamfightResEvent
  | IOverwatchMatchDataTeamfightStatusEvent
  | IOverwatchMatchDataTeamfightSuicideEvent;

export type OverwatchMatchDataNonTeamfightEvent =
  | IOverwatchMatchDataNonTeamfightKillEvent
  | IOverwatchMatchDataNonTeamfightUltEvent
  | IOverwatchMatchDataNonTeamfightResEvent
  | IOverwatchMatchDataNonTeamfightStatusEvent
  | IOverwatchMatchDataNonTeamfightSuicideEvent;

export type OverwatchMatchDataEvent = OverwatchMatchDataTeamfightEvent | OverwatchMatchDataNonTeamfightEvent;

export function isNonTeamFightEvent
  (event: OverwatchMatchDataEvent)
  : event is OverwatchMatchDataNonTeamfightEvent {
  return 'eventType' in event;
}

export function isTeamFightEvent
  (event: OverwatchMatchDataEvent)
  : event is OverwatchMatchDataTeamfightEvent {
  return 'type' in event;
}

export function unpackOverwatchMatchDataEventType (event: OverwatchMatchDataEvent): string {
  if (isTeamFightEvent(event)) {
    return event.type;
  } else {
    // NonTeamFightEvent
    return event.eventType;
  }
}

export function isOverwatchMatchDataKillEvent
(event: OverwatchMatchDataEvent)
: event is IOverwatchMatchDataTeamfightKillEvent | IOverwatchMatchDataNonTeamfightKillEvent {
  const type = unpackOverwatchMatchDataEventType(event);
  return (
       type === OverwatchEventType.KILL
    || type === OverwatchEventType.EAT
    || type === OverwatchEventType.OBJKILL
    || type === OverwatchEventType.MECHKILL
  );
}

export function isOverwatchMatchDataUltEvent
(event: OverwatchMatchDataEvent)
: event is IOverwatchMatchDataTeamfightUltEvent | IOverwatchMatchDataNonTeamfightUltEvent {
  const type = unpackOverwatchMatchDataEventType(event);
  return type === OverwatchEventType.ULT;
}

export function isOverwatchMatchDataResEvent
(event: OverwatchMatchDataEvent)
: event is IOverwatchMatchDataTeamfightResEvent | IOverwatchMatchDataNonTeamfightResEvent {
  const type = unpackOverwatchMatchDataEventType(event);
  return type === OverwatchEventType.RES;
}

export function isOverwatchMatchDataSuicideEvent
(event: OverwatchMatchDataEvent)
: event is IOverwatchMatchDataTeamfightSuicideEvent | IOverwatchMatchDataNonTeamfightSuicideEvent {
  const type = unpackOverwatchMatchDataEventType(event);
  return type === OverwatchEventType.SUICIDE;
}

export function isOverwatchMatchDataStatusEvent
(event: OverwatchMatchDataEvent)
: event is IOverwatchMatchDataTeamfightStatusEvent | IOverwatchMatchDataNonTeamfightStatusEvent {
  const type = unpackOverwatchMatchDataEventType(event);
  return type === OverwatchEventType.STATUS;
}

export type OverwatchTeamColor = 'red' | 'blue';

export function isOverwatchTeamColor(color: string): color is OverwatchTeamColor {
  return color === 'red' || color === 'blue';
}

export function flipOverwatchTeamColor(color: string): OverwatchTeamColor {
  return color === 'red' ? 'blue' : 'red';
}
