import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';

import { AttackIcon } from '../../../../assets/overwatch-maptypes';
import { teamNames } from '../../../../helpers';
import { OwstatsF, OwstatsNS } from '../../../../locales/en/owstats';
import Message from '../../../../material/message/Message';
import { IOverwatchMatchDataCalculatedStats } from '../../../../types/pigeon/matches/generated-overwatch-match';
import {
  IOverwatchMatchDataScoreInfo,
  IOverwatchMatchDataTeamfightStats,
} from '../../../../types/pigeon/overwatch-match-data';
import { IOWLUserMetadata } from '../../../../types/pigeon/owl-match';

export interface IOverwatchTeamfightStatsTableOwnProps {
  tfStats?: IOverwatchMatchDataTeamfightStats;
  calculatedStats: IOverwatchMatchDataCalculatedStats;
  userMetadata?: IOWLUserMetadata;
  scoreInfo?: IOverwatchMatchDataScoreInfo;
}
export type IOverwatchTeamfightStatsTableProps = IOverwatchTeamfightStatsTableOwnProps &
  WithStyles<typeof styles> &
  WithTranslation;

const styles = (theme: Theme) => {
  return createStyles({
    blueTeam: {
      color: theme.palette.overwatch.blueTeam,
    },
    redTeam: {
      color: theme.palette.overwatch.redTeam,
    },
  });
};

class OverwatchTeamfightStatsTable extends React.Component<IOverwatchTeamfightStatsTableProps> {
  public render() {
    const { tfStats, t } = this.props;
    if (!tfStats) {
      return (
        <Message message={t(OwstatsF('nodata'))} />
      );
    }
    return (
      <Table>
        {this.renderHeader()}
        <TableBody>
          {this.renderBlueTeamStats(tfStats)}
          {this.renderRedTeamStats(tfStats)}
        </TableBody>
      </Table>
    );
  }

  private renderHeader = () => {
    const { t } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell>{t(OwstatsF('teamfightstats.team'))}</TableCell>
          <TableCell>{t(OwstatsF('teamfightstats.kills'))}</TableCell>
          <TableCell>{t(OwstatsF('teamfightstats.deaths'))}</TableCell>
          <TableCell>{t(OwstatsF('teamfightstats.ults'))}</TableCell>
          <TableCell>{t(OwstatsF('teamfightstats.firstkills'))}</TableCell>
          <TableCell>{t(OwstatsF('teamfightstats.firstdeaths'))}</TableCell>
          <TableCell>{t(OwstatsF('teamfightstats.averagekills'))}</TableCell>
          <TableCell>{t(OwstatsF('teamfightstats.averagedeaths'))}</TableCell>
          <TableCell>{t(OwstatsF('teamfightstats.averageultsused'))}</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  private renderBlueTeamStats = (tfStats: IOverwatchMatchDataTeamfightStats) => {
    const { calculatedStats, classes, t, userMetadata, scoreInfo } = this.props;
    return (
      <TableRow>
        {/* TODO: Team name */}
        <TableCell className={classes.blueTeam}>
          <FlexSpacer flexAlignItems='center'>
            <div>
              {userMetadata ? t(OwstatsF(teamNames(userMetadata.team1_name))) : t(OwstatsF('teamfightstats.blueteam'))}
            </div>
            <div>
              {scoreInfo && scoreInfo.color === 'blue'
                ? <AttackIcon />
                : <FontAwesomeIcon icon={faShieldAlt} size='lg' />
              }
            </div>
          </FlexSpacer>
        </TableCell>
        <TableCell>{tfStats.totalBlueKills}</TableCell>
        <TableCell>{tfStats.totalBlueDeaths}</TableCell>
        <TableCell>{tfStats.totalBlueUlts}</TableCell>
        <TableCell>{calculatedStats.blueFirstKills}</TableCell>
        <TableCell>{calculatedStats.redFirstKills}</TableCell>
        <TableCell>{tfStats.averageBlueKills.toFixed(2)}</TableCell>
        <TableCell>{tfStats.averageBlueDeaths.toFixed(2)}</TableCell>
        <TableCell>{tfStats.averageBlueUlts.toFixed(2)}</TableCell>
      </TableRow>
    );
  }

  private renderRedTeamStats = (tfStats: IOverwatchMatchDataTeamfightStats) => {
    const { calculatedStats, classes, t, userMetadata, scoreInfo } = this.props;
    return (
      <TableRow>
        {/* TODO: Team name */}
        <TableCell className={classes.redTeam}>
          <FlexSpacer flexAlignItems='center'>
            <div>
              {userMetadata ? t(OwstatsF(teamNames(userMetadata.team2_name))) : t(OwstatsF('teamfightstats.redteam'))}
            </div>
            <div>
              {scoreInfo && scoreInfo.color === 'red'
                ?  <AttackIcon />
                : <FontAwesomeIcon icon={faShieldAlt} size='lg' />
              }
            </div>
          </FlexSpacer>
        </TableCell>
        <TableCell>{tfStats.totalRedKills}</TableCell>
        <TableCell>{tfStats.totalRedDeaths}</TableCell>
        <TableCell>{tfStats.totalRedUlts}</TableCell>
        <TableCell>{calculatedStats.redFirstKills}</TableCell>
        <TableCell>{calculatedStats.blueFirstKills}</TableCell>
        <TableCell>{tfStats.averageRedKills.toFixed(2)}</TableCell>
        <TableCell>{tfStats.averageRedDeaths.toFixed(2)}</TableCell>
        <TableCell>{tfStats.averageRedUlts.toFixed(2)}</TableCell>
      </TableRow>
    );
  }
}

export default withTranslation(OwstatsNS)(withStyles(styles)(OverwatchTeamfightStatsTable));
