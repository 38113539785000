import { isExistent } from 'helpers';
import groupBy from 'lodash/groupBy';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

import { DEFAULT_DIVISION_ID } from './dashboard-directory-constants';
import { DirectoryHelper } from './dashboard-directory-helper';

export const getTeamDirectoryRecordsByTeamId = (state: RootReducer, teamId: ID) =>
  state.dashboardDirectory.teamDirectoryRecords[teamId];
const getTeamDirectoryFolderRecordsByDirectoryId = (state: RootReducer, directoryId: ID) =>
  state.dashboardDirectory.teamDirectoryFolderRecords[directoryId];
const getTeamDirectoryUserRecordsByDirectoryId = (state: RootReducer, directoryId: ID) =>
  state.dashboardDirectory.teamDirectoryUsersRecords[directoryId];
const getDirectoryFetchStatusByDirectoryId = (state: RootReducer, directoryId: ID) =>
  state.dashboardDirectory.directoryStatus[directoryId];
const getTeamDefaultDivisionByTeamId = (state: RootReducer, teamId: ID) =>
  state.dashboardDirectory.teamDefaultDivisionDict[teamId];

const getSharedDivisionRecords = (state: RootReducer) => state.dashboardDirectory.sharedDivisionRecords;
const getTeamIdFromProps = (state: RootReducer, props: {teamId: ID}) => props.teamId;
const getSharedDivisionDict = (state: RootReducer) => state.dashboardDirectory.sharedDivisionDict;
const getSharedDivisionByDirectoryId = (state: RootReducer, directoryId: ID) =>
  state.dashboardDirectory.sharedDivisionDict[directoryId];

export const makeGetSharedDivisionRecords = () => createSelector(
  [getSharedDivisionRecords],
  (records) => records,
);

export const makeGetSharedDivisionsDict = () => createSelector(
  [getSharedDivisionDict],
  (dict) => groupBy(Object.values(dict).filter(isExistent), (d) => d.team.id),
);

export const makeGetSharedDivisionByDirectoryId = () => createSelector(
  [getSharedDivisionByDirectoryId],
  (division) => division,
);

export const makeGetSharedDivisionsByTeamId = () => createSelector(
  [getTeamIdFromProps, makeGetSharedDivisionsDict()],
  (teamId, dict) => dict[teamId],
);

export const makeGetSharedDivisionsTeam = () => createSelector(
  [getTeamIdFromProps, makeGetSharedDivisionsDict()],
  (teamId, dict) => dict[teamId] ? dict[teamId][0].team : undefined,
);

export const getSharedDirectoryDict = (state: RootReducer) => state.dashboardDirectory.sharedDivisionDict;
export const getDirectoryDict = (state: RootReducer) => state.dashboardDirectory.directoryDict;
export const getDirectoryUsersDict = (state: RootReducer) => state.dashboardDirectory.teamDirectoryUsersDict;

export const makeGetTeamDirectoryRecordsByTeamId = () => createSelector(
  [getTeamDirectoryRecordsByTeamId],
  (records) => records,
);

export const makeGetTeamDirectoryUsersRecordsByDirectoryId = () => createSelector(
  [getTeamDirectoryUserRecordsByDirectoryId],
  (records) => records,
);

export const makeGetTeamDirectoriesByTeamId = () => createSelector(
  [getTeamDirectoryRecordsByTeamId, getDirectoryDict, getTeamDefaultDivisionByTeamId],
  (records, dict, defaultDivision) => {
    const ids = records?.ids;
    if (!ids) {
      return [];
    }
    return ids
      .map(id => id !== DEFAULT_DIVISION_ID ? dict[id] : defaultDivision)
      .filter(isExistent)
      .filter(DirectoryHelper.isDivision);
  },
);

export const makeGetDirectoryFetchStatusByDirectoryId = () => createSelector(
  [getDirectoryFetchStatusByDirectoryId],
  (status) => status,
);

export const makeGetTeamDirectoryFolderRecordsByDirectoryId = () => createSelector(
  [getTeamDirectoryFolderRecordsByDirectoryId],
  (records) => records,
);

export const makeGetTeamDirectoryFoldersByDirectoryId = () => createSelector(
  [getTeamDirectoryFolderRecordsByDirectoryId, getDirectoryDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) {
      return [];
    }
    return ids.map(id => dict[id]).filter(isExistent);
  },
);

export const makeGetTeamDirectoryUsersByDirectoryId = () => createSelector(
  [getTeamDirectoryUserRecordsByDirectoryId, getDirectoryUsersDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) {
      return [];
    }
    return ids.map(id => dict[id]).filter(isExistent);
  },
);

export const makeGetTeamDirectoryByDirectoryId = () => createSelector(
  [getDirectoryDict, (state: RootReducer, directoryId: ID) => directoryId],
  (dict, directoryId) => dict[directoryId],
);
