import { DeleteSelf_Mutation, UpdateUser_Mutation } from 'apollo/mutations';
import { GetUserProfile_Query } from 'apollo/queries';
import { deleteSelfAsyncAC, fetchUserProfileAsyncAC, updateUserAsyncAC } from 'features/auth/auth-slice';
import { watchAsyncMutation, watchAsyncQuery } from 'helpers/saga/effects';
import { all } from 'redux-saga/effects';

export default function* userSaga() {
  yield all([
    watchAsyncQuery(fetchUserProfileAsyncAC, GetUserProfile_Query, ['me']),
    watchAsyncMutation(updateUserAsyncAC, UpdateUser_Mutation, ['updateUser2']),
    watchAsyncMutation(deleteSelfAsyncAC, DeleteSelf_Mutation, ['deleteSelf']),
  ]);
}
