import { VideoReplayContext } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { EIntercomID } from 'constants/strings';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import ShareVideoDialog from 'material/dialogs/share-video-dialog/ShareVideoDialog';
import React, { useCallback, useContext } from 'react';

interface ShareVideoButtonOwnProps {
  video?: VideoFragment;
}

type ShareVideoButtonProps = ShareVideoButtonOwnProps;

function ShareVideoButton({ video }: ShareVideoButtonProps) {
  const { t } = useStrictTranslation(['common']);
  const [isOpen, _open, onClose] = useDialogState();

  const { pause } = useContext(VideoReplayContext);
  const open = useCallback(() => {
    pause();
    _open();
  }, [_open, pause]);

  return (
    <React.Fragment>
      <Button
      id={EIntercomID.SHARE_VIDEO}
      startIcon={<ShareIcon />}
      onClick={open}
      color='primary'
      variant='contained'
      >
        {t('common:share')}
      </Button>
      {video && (
        <ShareVideoDialog
        video={video}
        onClose={onClose}
        open={isOpen}
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(ShareVideoButton);
