import { AsyncButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import update from 'immutability-helper';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';

import {
  RosterPlayersFragment,
  RosterPlayersFragment_players,
} from '../../../../../apollo/fragments/types/RosterPlayersFragment';
import { UpdatePlayerMutation_updatePlayer } from '../../../../../apollo/mutations/types/UpdatePlayerMutation';
import { UpdatePrimaryRosterMutation_updatePrimaryRoster_roster } from '../../../../../apollo/mutations/types/UpdatePrimaryRosterMutation';
import { UpdateRosterMutation_updateRoster } from '../../../../../apollo/mutations/types/UpdateRosterMutation';
import { DashboardF, DashboardNS } from '../../../../../locales/en/dashboard';
import UndraggableAvatar from '../../../../../material/undraggable-avatar/UndraggableAvatar';
import { AnalysisType, UpdatePlayerInput, UpdatePrimaryRosterInput, UpdateRosterInput } from '../../../../../types/graphql';

export interface IRosterCardOwnProps {
  teamId: string;
  roster: RosterPlayersFragment;
  isPrimaryRoster: boolean;
}

export interface IRosterCardDispatch {
  onUpdatePlayerName: (input: UpdatePlayerInput) => Promise<UpdatePlayerMutation_updatePlayer>;
  onUpdateRosterName: (input: UpdateRosterInput) => Promise<UpdateRosterMutation_updateRoster>;
  onUpdatePrimaryRoster: (input: UpdatePrimaryRosterInput) => Promise<
    UpdatePrimaryRosterMutation_updatePrimaryRoster_roster
  >;
}

export type IRosterCardProps = IRosterCardOwnProps &
  IRosterCardDispatch &
  WithStyles<typeof styles> &
  WithSnackbarProps &
  WithTranslation;

const styles = (theme: Theme) => {
  return createStyles({
    rosterCard: {
      border: `1px solid ${theme.palette.grey[700]}`,
      marginBottom: theme.spacing(1),
    },
    actions: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      marginTop: theme.spacing(1),
      gridGap: theme.spacing(1),
      whiteSpace: 'pre',
    },
    rosterNameEditor: {
      padding: theme.spacing(0, 1),
    },
    player: {
      padding: theme.spacing(1),
    },
    playerImage: {
      width: '100%',
      '&>img': {
        objectFit: 'unset',
      },
    },
  });
};

interface IState {
  isEditing?: boolean;
  editLoading?: boolean;
  rosterLoading?: boolean;
  rosterName: string;
  playerInputs: { [id: string]: string };
}

class RosterCard extends React.PureComponent<IRosterCardProps, IState> {
  private initialPlayers: { [id: string]: string } = {};
  constructor(props: IRosterCardProps) {
    super(props);
    const initialState: IState = {
      rosterName: props.roster.name || '',
      playerInputs: {},
    };

    props.roster.players.forEach((p: RosterPlayersFragment_players) => {
      initialState.playerInputs[p.id] = p.name || '';
      this.initialPlayers[p.id] = p.name || '';
    });

    this.state = initialState;
  }
  public render() {
    const { classes } = this.props;
    return (
      <Grid container={true} className={classes.rosterCard}>
        {this.renderRosterNameChangeForm()}
        {this.renderPlayers()}
      </Grid>
    );
  }

  private renderRosterNameChangeForm = () => {
    const { isPrimaryRoster, t, roster, classes } = this.props;
    const { rosterName, isEditing, editLoading, rosterLoading } = this.state;
    return (
      <Grid item={true} xs={12} xl={3} className={classes.rosterNameEditor}>
        {/* Roster Name */}
        <Box>
          <TextField
          label='name'
          name={roster.id}
          value={rosterName}
          onChange={this.handleInputOnChange}
          fullWidth={true}
          variant='outlined'
          margin='dense'
          disabled={editLoading || !isEditing}
          />
        </Box>
        <Box className={classes.actions}>
          {/* Roster Select button */}
          <AsyncButton
          loading={rosterLoading}
          disabled={isPrimaryRoster || rosterLoading}
          color='primary'
          variant='contained'
          fullWidth={true}
          onClick={this.handleUpdatePrimaryRoster}
          >
            {t(DashboardF('team.rosters.selectroster'))}
          </AsyncButton>
          {/* Edit or Save button */}
          {this.renderEditOrSaveButton()}
        </Box>
      </Grid>
    );
  }

  private renderEditOrSaveButton = () => {
    const { t } = this.props;
    const { isEditing, editLoading } = this.state;
    if (!isEditing) {
      return (
        <Button
        variant='outlined'
        onClick={this.handleEditNamesOnClick}
        fullWidth={true}
        >
          {t(DashboardF('team.rosters.editnames'))}
        </Button>
      );
    }
    return (
      <AsyncButton
      loading={editLoading}
      disabled={editLoading}
      fullWidth={true}
      variant='outlined'
      onClick={this.handleSaveNamesOnClick}
      >
        {t(DashboardF('team.rosters.savenames'))}
      </AsyncButton>
    );
  }

  private renderPlayers = () => {
    const { roster } = this.props;
    return (
      <Grid container={true} item={true} xs={12} xl={9}>
        {roster.players.map(this.renderPlayer)}
      </Grid>
    );
  }

  private renderPlayer = (p: RosterPlayersFragment_players) => {
    const { classes, t } = this.props;
    const { playerInputs, isEditing } = this.state;
    return (
      <Grid item={true} xs={6} sm={4} md={3} lg={2} key={p.id} className={classes.player}>
        <UndraggableAvatar src={p.image} key={p.id} className={classes.playerImage} />
        <TextField
        value={playerInputs[p.id] || ''}
        onChange={this.handleInputOnChange}
        name={p.id}
        fullWidth={true}
        placeholder={t(DashboardF('team.rosters.playername'))}
        disabled={true}
        variant='outlined'
        margin='dense'
        />
      </Grid>
    );
  }

  private handleEditNamesOnClick = () => {
    this.setState(state => update(state, {isEditing: {$set: true}}));
  }

  private handleUpdatePrimaryRoster = () => {
    const { onUpdatePrimaryRoster, roster, teamId, enqueueSnackbar, t } = this.props;
    this.setState({rosterLoading: true});
    const id = roster.id;
    onUpdatePrimaryRoster({
      id,
      teamId,
      type: AnalysisType.OVERWATCH,
    })
    .then((payload: UpdatePrimaryRosterMutation_updatePrimaryRoster_roster) => {
      this.setState({rosterLoading: false});
      enqueueSnackbar(t(DashboardF('team.rosters.rosterchangesuccessful')), {variant: 'success'});
    })
    .catch((err) => {
      // TODO: Better Error Message
      enqueueSnackbar(t(DashboardF('team.rosters.rosterchangeunsuccessful')), {variant: 'error'});
    });
  }

  private handleInputOnChange = (e: React.SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    const { roster } = this.props;
    if (name === roster.id) {
      this.setState(state => update(state, {rosterName: {$set: value}}));
    } else {
      this.setState(state => update(state, {playerInputs: {[name]: {$set: value}}}));
    }
  }

  private handleSaveNamesOnClick = () => {
    const { t, onUpdatePlayerName, onUpdateRosterName, roster, teamId, enqueueSnackbar } = this.props;
    const { editLoading, playerInputs, rosterName } = this.state;
    if (editLoading) { return; }
    const arrayOfNames: UpdatePlayerInput[] = Object.keys(playerInputs)
    .filter((id: string) => this.initialPlayers[id] !== playerInputs[id])
    .map((id: string) => ({ id, name: playerInputs[id], teamId }));

    if ((!rosterName || rosterName === roster.name) && arrayOfNames.length <= 0) {
      this.setState({ isEditing: false, rosterName: roster.name || '' });
      return;
    }

    this.setState({ editLoading: true });

    const promises: Array<Promise<UpdatePlayerMutation_updatePlayer | UpdateRosterMutation_updateRoster>> = arrayOfNames
      .map((input: UpdatePlayerInput) => {
        return onUpdatePlayerName(input);
    });

    if (rosterName && rosterName !== roster.name) {
      promises.push(
        onUpdateRosterName({ id: roster.id, name: rosterName, teamId }),
      );
    }

    Promise.all(promises)
    .then(() => {
      enqueueSnackbar(t(DashboardF('team.rosters.savesuccessful')), {variant: 'success'});
      this.setState({ editLoading: false, isEditing: false });
    })
    .catch((error: Error) => {
      if (error) {
        // TODO: Better Error Message
        enqueueSnackbar(t(DashboardF('team.rosters.saveunsuccessful')), {variant: 'error'});
      }
    });
  }
}

export default withSnackbar(withTranslation(DashboardNS)(withStyles(styles)(RosterCard)));
