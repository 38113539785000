import { ResetStats_Mutation, UpdateAttacker_Mutation,UpdatePlayer_Mutation, UpdatePrimaryRoster_Mutation,UpdateRoster_Mutation, UpdateStats_Mutation, UpdateStatsMap_Mutation } from 'apollo/mutations';
import { GetMatchesById_Query, GetOverwatchPrimaryRosterPlayerStatisticsV2_Query, GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2_Query, GetOverwatchPrimaryRosterTeamFightMapStatisticsV2_Query, GetPrimaryRoster_Query, GetTeamRosters_Query } from 'apollo/queries';
import { GetVideosByIdQuery_videos_latestAnalysis } from 'apollo/queries/types/GetVideosByIdQuery';
import { fetchAnalysisMatchesAC,fetchMatchesByIdAsyncAC } from 'features/statistics/statistics-slice';
import {
  fetchOverwatchPrimaryRosterPlayerStatisticsAsyncAC,
  fetchOverwatchPrimaryRosterTeamFightCompositionStatisticsAsyncAC,
  fetchOverwatchPrimaryRosterTeamFightMapStatisticsAsyncAC,
  fetchPrimaryRosterAsyncAC,
  fetchTeamRostersAsyncAC,
  invalidateTeamStatsCacheAC,
  resetStatsAsyncAC,
  updateAttackerAsyncAC,
  updatePlayerAsyncAC,
  updatePrimaryRosterAsyncAC,
  updateRosterAsyncAC,
  updateStatsAsyncAC,
  updateStatsMapAsyncAC,
} from 'features/statistics/statistics-slice';
import { raceLogout, watchAsyncMutation, watchAsyncQuery, watchBidirectionalAC2 } from 'helpers/saga/effects';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { exid, ID } from 'types/pigeon';
import { Action } from 'typescript-fsa';

import { isExistent } from '../../helpers';
import { statisticsStateSelector } from '../../selectors';
import { IStatisticsStoreState } from '../../types';

function* fetchAnalysisMatchesWorker(
  action: Action<{analysis: GetVideosByIdQuery_videos_latestAnalysis}>,
): SagaIterator {
  const ss: IStatisticsStoreState = yield select(statisticsStateSelector);
  const { matchCache, pendingMatchIds } = ss;
  const { result } = action.payload.analysis;
  if (!result) { return; }
  const ids = result.matches
    .filter(isExistent)
    .map(exid)
    .filter((id: ID) => !(id in matchCache) && !pendingMatchIds.includes(id));
  yield put(fetchMatchesByIdAsyncAC.started({ids}));
}

function* watchFetchAnalysisMatches(): SagaIterator {
  yield takeEvery(fetchAnalysisMatchesAC, function* (
    action: Action<{analysis: GetVideosByIdQuery_videos_latestAnalysis}>,
  ) {
    yield raceLogout(call(fetchAnalysisMatchesWorker, action));
  });
}

export default function* statisticsSaga() {
  yield all([
    watchFetchAnalysisMatches(),
    watchAsyncQuery(fetchPrimaryRosterAsyncAC, GetPrimaryRoster_Query, ['queryPrimaryRoster']),
    watchAsyncQuery(fetchMatchesByIdAsyncAC, GetMatchesById_Query, ['matches']),
    watchAsyncQuery(
      fetchOverwatchPrimaryRosterPlayerStatisticsAsyncAC,
      GetOverwatchPrimaryRosterPlayerStatisticsV2_Query,
      ['statistics'],
    ),
    watchAsyncQuery(
      fetchOverwatchPrimaryRosterTeamFightCompositionStatisticsAsyncAC,
      GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2_Query,
      ['statistics'],
    ),
    watchAsyncQuery(
      fetchOverwatchPrimaryRosterTeamFightMapStatisticsAsyncAC,
      GetOverwatchPrimaryRosterTeamFightMapStatisticsV2_Query,
      ['statistics'],
    ),
    watchAsyncMutation(updatePlayerAsyncAC, UpdatePlayer_Mutation, ['updatePlayer']),
    watchAsyncMutation(updateRosterAsyncAC, UpdateRoster_Mutation, ['updateRoster']),
    watchAsyncMutation(resetStatsAsyncAC, ResetStats_Mutation, ['resetStats']),
    watchAsyncMutation(updateStatsAsyncAC, UpdateStats_Mutation, ['updateStats']),
    watchAsyncMutation(updateStatsMapAsyncAC, UpdateStatsMap_Mutation, ['updateStatsMap']),
    watchAsyncMutation(updateAttackerAsyncAC, UpdateAttacker_Mutation, ['updateAttacker']),
    watchAsyncMutation(updatePrimaryRosterAsyncAC, UpdatePrimaryRoster_Mutation, ['updatePrimaryRoster']),
    watchBidirectionalAC2(fetchTeamRostersAsyncAC, GetTeamRosters_Query, ['queryTeamRosters']),

    takeEvery(updatePrimaryRosterAsyncAC.done, function*({ payload: { params: { teamId } } }) {
      yield put(invalidateTeamStatsCacheAC({ teamId }));
    }),
  ]);
}
