type TRainbowSixSiegeOperators =
|'ace'
|'alibi'
|'amaru'
|'ash'
|'bandit'
|'blackbeard'
|'blitz'
|'buck'
|'capitao'
|'castle'
|'caveira'
|'clash'
|'doc'
|'dokkaebi'
|'echo'
|'ela'
|'finka'
|'frost'
|'fuze'
|'glaz'
|'goyo'
|'gridlock'
|'hibana'
|'hole'
|'iana'
|'iq'
|'jackal'
|'jager'
|'kaid'
|'kali'
|'kapkan'
|'lesion'
|'lion'
|'maestro'
|'melusi'
|'mira'
|'montagne'
|'mozzie'
|'mute'
|'nokk'
|'nomad'
|'oryx'
|'pulse'
|'rook'
|'sledge'
|'smoke'
|'tachanka'
|'thatcher'
|'thermite'
|'twitch'
|'valkyrie'
|'vigil'
|'wamai'
|'warden'
|'ying'
|'zofia'
;
const RainbowSixSiegeOperatorsNS = 'rainbow-six-siege-operators';
function RainbowSixSiegeOperatorsF(_: TRainbowSixSiegeOperators): string
{ return `${RainbowSixSiegeOperatorsNS}:${_}`; }
export {RainbowSixSiegeOperatorsF,RainbowSixSiegeOperatorsNS,TRainbowSixSiegeOperators};
