import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import { ENotificationState } from 'components/settings/notifications/constants';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { bindMenu } from 'material-ui-popup-state/hooks';
import React, { useCallback, useMemo } from 'react';

interface NotificationMenuOwnProps {
  notifications: NotificationFragment[];
  handleMenuOnClick: (notification: NotificationFragment[], status: ENotificationState) => void;
}

type NotificationMenuProps = NotificationMenuOwnProps & ReturnType<typeof bindMenu>;

function NotificationMenu(props: NotificationMenuProps) {
  const { open, notifications, handleMenuOnClick, ...rest } = props;

  const { t } = useStrictTranslation(['common', 'notification']);

  const unreadNotifications = useMemo(() => {
    return notifications.filter(n => n.state !== ENotificationState.READ);
  }, [notifications]);

  const handleMarkAsRead = useCallback(() => {
    handleMenuOnClick(unreadNotifications, ENotificationState.READ);
  }, [unreadNotifications, handleMenuOnClick]);

  const handleDelete = useCallback(() => {
    handleMenuOnClick(notifications, ENotificationState.DELETED);
  }, [notifications, handleMenuOnClick]);

  const handlePropagation = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);


  return (
    <React.Fragment>
      <Menu open={open} {...rest} onClick={handlePropagation}>
        {unreadNotifications.length > 0 && (
          <MenuItem onClick={handleMarkAsRead}>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <Typography>{t('notification:notifications.markasread')}</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <Typography>{t('common:delete')}</Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default React.memo(NotificationMenu);
