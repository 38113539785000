import {
  bidirectionalActionCreatorsFactory,
  BidirectionalIDRecord,
  createDefaultRecord,
} from '@insights-gaming/redux-utils';
import { VideoBookmarkFragment } from 'apollo/fragments/types/VideoBookmarkFragment';
import {
  GetVideoBookmarksQuery_video_queryBookmarks,
  GetVideoBookmarksQueryVariables,
} from 'apollo/queries/types/GetVideoBookmarksQuery';
import { addBidirectionalCases } from 'helpers/addBidirectionalCases';
import { createSlice } from 'helpers/createSlice';
import update from 'immutability-helper';
import actionCreatorFactory from 'typescript-fsa';

const name = 'video-bookmarks';
const actionCreator = actionCreatorFactory(name);

const bidirectionalActionCreators = bidirectionalActionCreatorsFactory(actionCreator);

export const fetchVideoBookmarksAC = bidirectionalActionCreators<
  GetVideoBookmarksQueryVariables,
  GetVideoBookmarksQuery_video_queryBookmarks,
  Error
>('FETCH_VIDEO_BOOKMARKS');

export interface VideoBookmarksVideoState {
  record: BidirectionalIDRecord;
  bookmarksDict: Partial<Dictionary<VideoBookmarkFragment>>;
}

export interface VideoBookmarksState {
  videosDict: Partial<Dictionary<VideoBookmarksVideoState>>;
}

const initialState: VideoBookmarksState = {
  videosDict: {},
};

const videoBookmarksSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    addBidirectionalCases(builder, fetchVideoBookmarksAC, {
      records: (tp, { videoId }) => tp.videosDict[videoId].record,
      dict: (tp, { videoId }) => tp.videosDict[videoId].bookmarksDict,
      values: (result) => result.bookmarks,
      prepare: (state, { videoId }) => {
        if (state.videosDict[videoId]) {
          return state;
        }

        return update(state, {
          videosDict: {
            [videoId]: {
              $set: {
                record: createDefaultRecord(),
                bookmarksDict: {},
              },
            },
          },
        });
      },
    });
  },
});

export const videoBookmarksReducer = videoBookmarksSlice.reducer;
