import { AsyncButton, FlexSpacer, RichTextEditor, VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { buildKeystring, SecondsFormatter } from '@insights-gaming/utils';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { addVideoCommentAC } from 'actions/comment-actions';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import { getAudioCommentRecording } from 'components/video/video-replay/video-comment/audio-comment/audio-comment-selectors';
import {
  resetAudioRecorder,
  startAudioRecordingAC,
  stopAudioRecordingAC,
} from 'components/video/video-replay/video-comment/audio-comment/audio-comment-slice';
import AudioCommentContent from 'components/video/video-replay/video-comment/audio-comment/AudioCommentContent';
import AudioCommentTrigger from 'components/video/video-replay/video-comment/audio-comment/AudioCommentTrigger';
import MicrophoneAccessDeniedDialog from 'components/video/video-replay/video-comment/audio-comment/MicrophoneAccessDeniedDialog';
import RecordingIndicator from 'components/video/video-replay/video-comment/audio-comment/RecordingIndicator';
import CommentLabelEditor from 'components/video/video-replay/video-comment/comment-label/comment-label-editor/CommentLabelEditor';
import SelectedLabelToAdd from 'components/video/video-replay/video-comment/comment-label/comment-label-editor/SelectedLabelToAdd';
import { MAX_LABEL_TO_SHOW } from 'constants/numbers';
import { convertToRaw, Editor, EditorState } from 'draft-js';
import { makeGetTeamMembersByTeamId } from 'features/dashboard/member/dashboard-member-selector';
import { useIsDesktop } from 'features/media-queries/hooks';
import { DrawingToolContext } from 'features/video-replay/DrawingToolContext';
import { VideoCommentMenuContext } from 'features/video-replay/VideoCommentMenuContext';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useKeybindings } from 'hooks/useKeybindings';
import { useMentionAutoCompleteMatcher } from 'hooks/useMentionAutoCompleteMatcher';
import { promisifyDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import update from 'immutability-helper';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decorator } from 'subcomponents/text-editor-with-tools/strategies/strategies';
import { ID } from 'types/pigeon';

import RangedTimestamp from '../ranged-timestamp/RangedTimestamp';
import { RangedTimestampContext } from '../ranged-timestamp/RangedTimestampContext';

interface CommentBoxOwnProps {
  className?: string;
  teamId?: ID;
  videoId: ID;
  commentBoxRefHandler?: ((el: HTMLElement | null) => void);
  commentLabelRefHandler?: ((el: HTMLElement | null) => void);
}

type CommentBoxProps = CommentBoxOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  commentActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  timestampField: {
    width: 100,
  },
  moreLabelsContainer: {
    padding: theme.spacing(1),
  },
  closeIcon: {
    color: theme.palette.error.main,
  },
}), {name: 'CommentBox'});

function CommentBox(props: CommentBoxProps) {
  const classes = useStyles(props);
  const { className, teamId, videoId, commentBoxRefHandler, commentLabelRefHandler } = props;

  const editorRef = useRef<Editor | null>(null);
  const { t } = useStrictTranslation(['video', 'common']);
  const dispatch = useDispatch();
  const promiseSagaDispatch = useMemo(() => promisifyDispatch(dispatch), [dispatch]);
  const { enqueueSnackbar } = useSnackbar();
  const { addContext, removeContext } = useKeybindings('editor.text.focus');
  const popupState = usePopupState({popupId: 'more-labels', variant: 'popover'});

  const {
    pause,
    state: {
      progress: { playedSeconds },
    },
  } = useContext(VideoReplayContext);

  const {
    isTimeInvalid,
    startTimeNumericValue,
    endTimeNumericValue,
    displayEndTime,
    toggleDisplayEndTime,
    setHasMessage,
    setUserStartTimeValue,
    setIsStartTimeChanged,
  } = useContext(RangedTimestampContext);

  const {
    autoPauseTyping,
  } = useContext(VideoCommentMenuContext);

  const members = useCreateSelector(makeGetTeamMembersByTeamId, teamId);
  const memberUsers = useMemo(() => members.map(m => m.user), [members]);
  const mentionAutoCompleteMatcher = useMentionAutoCompleteMatcher(memberUsers);

  const autoCompleteMatchers = useMemo(() => {
    return [
      mentionAutoCompleteMatcher,
    ];
  }, [mentionAutoCompleteMatcher]);

  const { drawingState, resetTool } = useContext(DrawingToolContext);

  const [loading, setLoading] = useState(false);
  const [selectedLabels, setSelectedLabels] = useState<Set<TagFragment>>(new Set<TagFragment>());

  const handleAddLabel = useCallback((label: TagFragment) => {
    setSelectedLabels(selectedLabels => update(selectedLabels, {$add: [label]}));
  }, []);

  const handleRemoveLabel = useCallback((label: TagFragment) => {
    setSelectedLabels(selectedLabels => update(selectedLabels, {$remove: [label]}));
  }, []);

  const recording = useSelector(getAudioCommentRecording);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const currentContent = useMemo(() => editorState.getCurrentContent(), [editorState]);
  const msg = useMemo(() => currentContent.getPlainText(), [currentContent]);
  const emptyMessage = !msg.length || !msg.trim().length;

  const handleEditorChange = useCallback((editorState: EditorState) => {
    setEditorState(editorState);
    setHasMessage(!!msg.length);
  }, [msg.length, setHasMessage]);

  const disabled = useMemo(() => {
    if (isTimeInvalid || recording?.state === 'recording') {
      return true;
    }
    if (recording?.state === 'saved') {
      return false;
    }
    return emptyMessage;
  }, [emptyMessage, isTimeInvalid, recording]);

  const handleSendComment = useCallback(async () => {
    if (disabled || loading) {
      return;
    }

    setLoading(true);

    try {
      const serializedCanvas = await drawingState.serializedCanvas();
      const labelIds = Array.from(selectedLabels).map(label => label.id);
      await promiseSagaDispatch(addVideoCommentAC, {
        videoId,
        time: startTimeNumericValue,
        timeEnd: displayEndTime ? endTimeNumericValue : undefined,
        message: !recording ? JSON.stringify(convertToRaw(currentContent)) : undefined,
        recording: recording?.state === 'saved' ? recording.recording : undefined,
        annotation: drawingState.hasObjects ? serializedCanvas : undefined,
        tagIds: labelIds,
      });
      // Need to blur it before setting it to new Editor or else it doesn't clear it.
      editorRef.current!.blur();
      setEditorState(EditorState.createEmpty(decorator));
      resetTool();
      dispatch(resetAudioRecorder());
      if (displayEndTime) {
        toggleDisplayEndTime();
      }
      setUserStartTimeValue(SecondsFormatter.format(playedSeconds));
    } catch (error) {
      editorRef.current!.blur();
      setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty(decorator)));
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLoading(false);
      setSelectedLabels(new Set<TagFragment>());
      setIsStartTimeChanged(false);
      setHasMessage(false);
      removeContext();
    }
  }, [
    disabled,
    loading,
    drawingState,
    selectedLabels,
    promiseSagaDispatch,
    videoId,
    startTimeNumericValue,
    displayEndTime,
    endTimeNumericValue,
    recording,
    currentContent,
    resetTool,
    dispatch,
    setUserStartTimeValue,
    setIsStartTimeChanged,
    setHasMessage,
    playedSeconds,
    toggleDisplayEndTime,
    enqueueSnackbar,
    removeContext,
  ]);

  const handleEditorOnFocus = useCallback(() => {
    addContext();
    if (autoPauseTyping) {
      pause();
    }
  }, [addContext, autoPauseTyping, pause]);

  const handleEditorOnBlur = useCallback(() => {
    removeContext();
  }, [removeContext]);

  const isDesktop = useIsDesktop();

  const customKeyBindFn = useCallback((e: React.KeyboardEvent) => {
    switch (buildKeystring(e.nativeEvent)) {
      case 'ctrl+enter':
        return 'submit_comment';
    }
    return null;
  }, []);

  const customKeyCommands = useMemo(() => {
    return new Map([['submit_comment', handleSendComment]]);
  }, [handleSendComment]);

  const renderWarningMessage = useMemo(
    () => !recording && drawingState.hasObjects && emptyMessage,
    [drawingState.hasObjects, emptyMessage, recording],
  );

  const saveRecording = useCallback(() => dispatch(stopAudioRecordingAC.started()), [dispatch]);
  const discardRecording = useCallback(() => dispatch(stopAudioRecordingAC.started(true)), [dispatch]);
  const handleRecordOnClick = useCallback(() => dispatch(startAudioRecordingAC.started()), [dispatch]);
  const resetAudioRecorderHandler = useCallback(() => dispatch(resetAudioRecorder()), [dispatch]);

  return (
    <FlexSpacer orientation='vertical' ref={commentBoxRefHandler}>
      <Divider />
      {renderWarningMessage && (
        <FlexSpacer flexAlignItems='center'>
          <WarningRoundedIcon />
          <Typography variant='caption'>
            {t('video:replay.warnNoMessage')}
          </Typography>
        </FlexSpacer>
      )}
      {recording?.state === 'recording' ? (
        <RecordingIndicator saveRecording={saveRecording} discardRecording={discardRecording}/>
      ) : recording?.state === 'saved' ? (
        <AudioCommentContent
        audioSrc={recording.audioSource}
        discardRecording={resetAudioRecorderHandler}
        fill={true}
        >
          <Tooltip title={t('video:replay.discardrecording')}>
            <IconButton
            onClick={resetAudioRecorderHandler}
            size='small'
            className={classes.closeIcon}
            >
              <CloseIcon fontSize='small'/>
            </IconButton>
          </Tooltip>
        </AudioCommentContent>
      ) : (
        <RichTextEditor
        ref={editorRef}
        editorState={editorState}
        onStateChange={handleEditorChange}
        onFocus={handleEditorOnFocus}
        onBlur={handleEditorOnBlur}
        autoCompleteMatchers={autoCompleteMatchers}
        customKeyBindFn={customKeyBindFn}
        customKeyCommands={customKeyCommands}
        compactTools={!isDesktop}
        size={!isDesktop ? 'small' : undefined}
        placeholder={t('video:replay.commentplaceholder')}
        readOnly={loading}
        />
      )}
      <FlexSpacer orientation='vertical'>
        <FlexSpacer flexAlignItems='center'>
          {Array.from(selectedLabels).map((label, i) => i < MAX_LABEL_TO_SHOW && (
            <SelectedLabelToAdd
            key={label.id}
            label={label}
            handleRemoveLabel={handleRemoveLabel}
            />
          ))}
          {selectedLabels.size > MAX_LABEL_TO_SHOW && (
            <div {...bindHover(popupState)}>
              {t('common:extranumber', {number: selectedLabels.size - MAX_LABEL_TO_SHOW})}
            </div>
          )}
          <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          >
            <Paper className={classes.moreLabelsContainer}>
              <FlexSpacer orientation='vertical' flexAlignItems='flex-end'>
                {Array.from(selectedLabels).map((label, i) => i > MAX_LABEL_TO_SHOW - 1 && (
                  <SelectedLabelToAdd
                  key={label.id}
                  label={label}
                  handleRemoveLabel={handleRemoveLabel}
                  />
                ))}
              </FlexSpacer>
            </Paper>
          </Popover>
        </FlexSpacer>
        <div className={classes.commentActions}>
          <FlexSpacer flexAlignItems='center'>
            {teamId && (
              <div ref={commentLabelRefHandler}>
                <CommentLabelEditor
                teamId={teamId}
                handleAddLabel={handleAddLabel}
                selectedLabelsToAdd={selectedLabels}
                />
              </div>
            )}
            <RangedTimestamp />
          </FlexSpacer>
          <FlexSpacer flexAlignItems='center'>
            <AudioCommentTrigger
            disabled={recording?.state === 'saved' || recording?.state === 'recording'}
            handleRecordOnClick={handleRecordOnClick}
            />
            <AsyncButton
            variant='contained'
            color='primary'
            disabled={disabled || loading}
            loading={loading}
            onClick={handleSendComment}
            >
              {t('video:replay.send')}
            </AsyncButton>
          </FlexSpacer>
        </div>
      </FlexSpacer>
      <MicrophoneAccessDeniedDialog
      open={recording?.state === 'failed'}
      onClose={resetAudioRecorderHandler}
      />
    </FlexSpacer>
  );
}

export default React.memo(CommentBox);
