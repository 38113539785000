import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ProvideVideoReplayContextValue } from 'features/video-replay/ProvideVideoReplayContextValue';
import VideoReplay from 'features/video-replay/VideoReplay';
import memoizeOne from 'memoize-one';
import React from 'react';
import { useSelector } from 'react-redux';
import { getMatchCache } from 'selectors/match';
import { GeneratedOverwatchMatch,ID, Match, OWLVideo } from 'types/pigeon';

interface VideoPlayerDialogContentOwnProps {
  className?: string;
  owlVideo?: OWLVideo;
  videoId: ID;
  startTime: number;
  endTime: number;
}

type VideoPlayerDialogContentProps = VideoPlayerDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  contentWrapper: {
    display: 'flex',
    height: '90vh',
  },
}), {name: 'VideoPlayerDialogContent'});

function VideoPlayerDialogContent(props: VideoPlayerDialogContentProps) {
  const classes = useStyles(props);
  const { className, owlVideo, videoId, startTime, endTime } = props;

  const memoizedMatch = memoizeOne((matchCache: Dictionary<Match>, tfStartTime: number) => {
    return Object.values(matchCache)
    .find((match: GeneratedOverwatchMatch) => tfStartTime >= match.startTime && tfStartTime <= match.endTime);
  });

  const matchCache = useSelector(getMatchCache);

  const match = memoizedMatch(matchCache, startTime);
  return (
    <DialogContent>
      <Box className={classes.contentWrapper}>
        <ProvideVideoReplayContextValue>
          <VideoReplay
          videoId={videoId}
          gameMatch={match}
          defaultPosition={startTime}
          />
        </ProvideVideoReplayContextValue>
      </Box>
    </DialogContent>
  );
}

export default React.memo(VideoPlayerDialogContent);
