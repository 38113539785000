import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import memoizeOne from 'memoize-one';
import React from 'react';

import { combineIds } from '../../../../../helpers';
import { formatDuration } from '../../../../../helpers/formatters';
import {
  IEventTime,
  IOverwatchMatchDataTeamfightStatusEvent,
  OverwatchEventType,
  OverwatchMatchDataTeamfightEvent,
} from '../../../../../types/pigeon/overwatch-match-data';
import Event from '../event/Event';

export interface ITeamFightOwnProps {
  teamfightEvent: IEventTime[];
  endTime: number;
  startTime: number;
  mostRelevantEvent?: React.Ref<HTMLButtonElement>;
  time: number;
  jumpToTime: (time: number) => void;
}

export type ITeamFightProps = ITeamFightOwnProps &
  WithStyles<typeof styles>;

const styles = (theme: Theme) => {
  return createStyles({
    teamfight: {
      border: `2px solid ${theme.palette.primary.main}`,
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
    timestamp: {
      borderRadius: '3px 3px 0 0',
    },
    teamfightContainer: {
      gridColumn: '1 / span 2',
    },
  });
};

class TeamFight extends React.PureComponent<ITeamFightProps> {
  private memoizedFormattedTime = memoizeOne(formatDuration);

  public render() {
    const { teamfightEvent, startTime, mostRelevantEvent, classes } = this.props;
    if (teamfightEvent.filter(this.onlyVisibleEvents).length > 0) {
      const focused = mostRelevantEvent ? teamfightEvent.reduce(this.reduceMostRelevantEvent) : undefined;
      return (
        <Box mb={1} className={classes.teamfightContainer}>
          <Button
          color='primary'
          variant='contained'
          onClick={this.handleTeamfightOnClick}
          size='small'
          className={classes.timestamp}
          >
            {this.memoizedFormattedTime(startTime)}
          </Button>
          <Box className={classes.teamfight}>
            {this.renderEventWithRelevance(teamfightEvent, focused)}
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  }

  private onlyVisibleEvents = (e: IEventTime) => {
    return e.type === OverwatchEventType.ULT || e.type === OverwatchEventType.KILL || e.type === OverwatchEventType.RES;
  }

  private renderEventWithRelevance(events: IEventTime[], mostRelevant?: IEventTime) {
    const statuses = events.map(e => e.event)
      .filter(e => e.type === OverwatchEventType.STATUS) as IOverwatchMatchDataTeamfightStatusEvent[];

    return events.map((event: IEventTime, index: number) => (
      this.renderTeamfightEvent(
        event,
        this.gettingRelevantStatuses(event.event, statuses),
        event === mostRelevant,
        index,
      )
    ));
  }

  private renderTeamfightEvent = (
  eventTime: IEventTime,
  statuses: IOverwatchMatchDataTeamfightStatusEvent[],
  focused: boolean,
  index: number,
  ) => {
    const { mostRelevantEvent } = this.props;
    return (
      <Event
      key={combineIds(eventTime.time, eventTime.event.type, index)}
      event={eventTime.event}
      statuses={statuses}
      jumpToTime={this.handleTeamfightEventOnClick}
      startTime={eventTime.event.time}
      eventTime={eventTime.time}
      mostRelevantEvent={focused ? mostRelevantEvent : undefined}
      />
    );
  }

  private gettingRelevantStatuses = (
  event: OverwatchMatchDataTeamfightEvent, statuses: IOverwatchMatchDataTeamfightStatusEvent[],
  ): IOverwatchMatchDataTeamfightStatusEvent[] => {

    let relevant = Array<IOverwatchMatchDataTeamfightStatusEvent>();
    if (event.type === 'kill') {
      relevant = relevant.concat(statuses.filter( s =>
        s.time < event.time &&
        event.time - s.time < 10 &&
        s.hero === event.killee.hero &&
        s.team !== event.killer.color,
      ));
      if (event.killee.hero === 'dva') {
        const nearest = this.props.teamfightEvent.reduce((p, e) => {
          if (e.type as any === 'mech_kill' && (e.event as any).killee.team !== event.killer.color) {
            if (e.event.time > p.event.time && e.event.time < event.time + 5) {
              return e;
            } else {
              return p;
            }
          } else {
            return p;
          }
        });
        if (nearest.type as any === 'mech_kill') {
          relevant.push({
            type: 'status',
            hero: 'dva',
            team: (nearest.event as any).killee.team,
            status: 'mech_kill',
            time: nearest.event.time,
          } as IOverwatchMatchDataTeamfightStatusEvent);
        }
      }
    }
    return relevant;
  }

  private time(evt: IEventTime) {
    return evt.event.time;
  }

  private reduceMostRelevantEvent = (a: IEventTime, b: IEventTime): IEventTime => {
    const { time } = this.props;
    if (b.type !== 'status' && this.time(b) >= this.time(a) && this.time(b) <= time) {
      return b;
    } else {
      return a;
    }
  }

  private handleTeamfightEventOnClick = (time: number) => this.props.jumpToTime(time);
  private handleTeamfightOnClick = () => this.props.jumpToTime(this.props.startTime);
}

export default withStyles(styles)(TeamFight);
