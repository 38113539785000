import { AsyncButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { createAnalysisRequestAC } from 'actions/video-actions';
import { VideoHelper } from 'features/video-library/video-helpers';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { useTeamAnalysisFeatures } from '../../../hooks/useFeatureSet';
import { useNavigate } from '../../../hooks/useNavigate';
import { CommonF } from '../../../locales/en/common';
import { DialogF, DialogNS } from '../../../locales/en/dialog';
import { EVideoViewerTabType, VIDEO_PATH, videoRoute } from '../../../routes';
import AnalysisTypeSelector from '../../../subcomponents/analysis-type-selector/AnalysisTypeSelector';
import { AnalysisType, CreateAnalysisRequestInput } from '../../../types/graphql';
import { Video } from '../../../types/pigeon';
import BetterDialogTitle from '../../better-dialog-title/BetterDialogTitle';
import Requirements from './Requirements';

export interface ICreateAnalysisRequestDialogContentOwnProps {
  video: Video;
  onClose: VoidFunction;
}

type Props = ICreateAnalysisRequestDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({}), {name: 'CreateAnalysisRequestDialogContent'});

function CreateAnalysisRequestDialogContent(props: Props) {
  const classes = useStyles(props);
  const { video, onClose } = props;
  const { t } = useTranslation(DialogNS);
  const { enqueueSnackbar } = useSnackbar();

  const promiseSagaDispatch = usePromiseSagaDispatch();

  const defaultAnalysisType = AnalysisType.OVERWATCH;
  const [analysisType, setAnalysisType] = useState(video.latestAnalysis?.type ?? defaultAnalysisType);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [force, setForce] = useState<boolean>();

  const match = useRouteMatch<{videoId: string; tab?: EVideoViewerTabType}>(VIDEO_PATH);

  const onNavigate = useNavigate();

  const analysisOptions = useTeamAnalysisFeatures();

  const showForceCheckbox = useMemo(() => error?.message === 'GraphQL error: ongoing analysis', [error]);

  const hasCompleteAnalysis = useMemo(() => VideoHelper.hasCompleteAnalysis(video), [video]);
  const { titleKey, descriptionKey, confirmKey } = useMemo(() => hasCompleteAnalysis ? ({
    titleKey      : DialogF('reanalyze.title'),
    descriptionKey: DialogF('reanalyze.description'),
    confirmKey    : DialogF('reanalyze.confirm'),
  }) : ({
    titleKey      : DialogF('analyze.title'),
    descriptionKey: DialogF('analyze.description'),
    confirmKey    : DialogF('analyze.confirm'),
  }), [hasCompleteAnalysis]);

  const handleChangeAnalysisType = useCallback((id, newValue: AnalysisType) => {
    if (!newValue) { return; }
    setAnalysisType(newValue);
  }, []);

  const handleCreateAnalysisRequest = useCallback(async () => {
    if (loading) { return; }
    setLoading(true);
    const input: CreateAnalysisRequestInput = {
      type: analysisType,
      videoId: video.id,
      force,
    };
    try {
      await promiseSagaDispatch(createAnalysisRequestAC, input);
      if (match) {
        const { videoId, tab } = match.params;
        if (tab === EVideoViewerTabType.OVERVIEW) {
          onNavigate(videoRoute(videoId, 'replay')); // navigate to the video replay
          return;
        }
      }
      onClose();
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setLoading(false);
      setError(error);
    }
  }, [analysisType, enqueueSnackbar, force, loading, match, onClose, onNavigate, promiseSagaDispatch, video.id]);

  const handleForceCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setForce(event.target.checked);
  }, []);

  return (
    <React.Fragment>
      <BetterDialogTitle>
        {t(titleKey)}
      </BetterDialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(descriptionKey)}
        </DialogContentText>
        <AnalysisTypeSelector
        videoId={video.id}
        value={analysisType}
        onChange={handleChangeAnalysisType}
        options={analysisOptions}
        />
        <Requirements />
        {showForceCheckbox && (
          <FormControlLabel
          control={<Checkbox checked={force} onChange={handleForceCheckboxChange} />}
          label={t('dialog:analyze.forceanalysis')}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
        color='default'
        variant='outlined'
        onClick={onClose}
        >
          {t(CommonF('cancel'))}
        </Button>
        <AsyncButton
        color='primary'
        variant='contained'
        loading={loading}
        disabled={loading}
        onClick={handleCreateAnalysisRequest}
        >
          {t(confirmKey)}
        </AsyncButton>
      </DialogActions>
    </React.Fragment>
  );
}

export default React.memo(CreateAnalysisRequestDialogContent);
