import { useCreateSelector } from '@insights-gaming/redux-utils';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import { makeGetTeamBalanceByTeamId } from './dashboard-billing.selectors';
import { fetchTeamBalanceAC } from './dashboard-billing-slice';

export default function useTeamBalance(
  teamId?: ID,
): [number | undefined, boolean] {
  const dispatch = useDispatch();
  const [teamBalance, teamBalanceFetched] = useCreateSelector(makeGetTeamBalanceByTeamId, teamId);

  useEffect(() => {
    if (!teamBalanceFetched && teamId) {
      dispatch(fetchTeamBalanceAC.started({ teamId }));
    }
  }, [dispatch, teamBalanceFetched, teamId]);

  return [teamBalance, teamBalanceFetched];
};
