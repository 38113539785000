import LiveSessionManager from 'features/live-session/LiveSessionManager';
import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { IntercomProvider } from 'react-use-intercom';

import { GetUserProfileQuery_me } from './apollo/queries/types/GetUserProfileQuery';
import App from './App';
import Login from './components/login/Login';
import LoginCallback from './components/login-callback/LoginCallback';
import PasswordReset from './components/password-reset/PasswordReset';
import PageNotFound from './containers/PageNotFound';
import VerifyCallback from './containers/VerifyCallback';
import { FeatureFlag, FeatureFlagsProvider } from './context/feature-flags/useFeatureFlags';
import errorReporter from './errorReporter.dev';
import { setLocalStorage } from './helpers/storage';
import KeybindingManager from './managers/keybinding-manager/KeybindingManager';
import {
  APP_PATHS,
  LOGIN_CALLBACK_PATH,
  LOGIN_PATHS,
  NOT_FOUND_PATH,
  PASSWORD_RESET_PATH,
  VERIFY_CALLBACK_PATH,
} from './routes';
import { IKMSession } from './types';

const isProd = process.env.NODE_ENV === 'production' && window.location.hostname === 'insights.gg';

export interface IPreAppMappedProps {
  authCheckFailed?: boolean;
  me?             : GetUserProfileQuery_me;
  session?        : IKMSession;
}

export type PreAppProps = IPreAppMappedProps & RouteComponentProps;

interface IState {
  featureFlags: Map<FeatureFlag, boolean>;
}

class PreApp extends React.Component<PreAppProps, IState> {
  constructor(props: PreAppProps) {
    super(props);
    const flags: FeatureFlag[] = JSON.parse(localStorage.getItem('featureflags') || '[]');
    const params = new URLSearchParams(window.location.hash.slice(1));
    const featureFlags: Map<FeatureFlag, boolean> = new Map(flags.map(f => ([f, true])));
    params.forEach((v, k) => {
      switch (v) {
        case 'on' :
        case 'true':
          featureFlags.set(k, true);
          break;
        case 'off':
        case 'false':
          featureFlags.set(k, false);
          break;
      }
    });
    setLocalStorage('featureflags', JSON.stringify(
      Array.from(featureFlags.entries()).filter(([k, v]) => v).map(([k, v]) => k),
    ));
    this.state = {featureFlags};
  }

  public componentDidMount() {
    window.addEventListener('error', errorReporter);
  }

  public componentWillUnmount() {
    window.removeEventListener('error', errorReporter);
  }

  public render() {
    const { featureFlags } = this.state;
    return (
      <FeatureFlagsProvider value={featureFlags}>
        <IntercomProvider
        appId={isProd ? 'lexda98h' : 'pi7obz9c'}
        autoBoot={true}
        autoBootProps={{
          hideDefaultLauncher: true,
        }}
        shouldInitialize={true}
        >
          <LiveSessionManager />
          <KeybindingManager />
          <Switch>
            <Route path={LOGIN_PATHS} component={Login} />
            <Route path={VERIFY_CALLBACK_PATH} component={VerifyCallback} />
            <Route path={LOGIN_CALLBACK_PATH} component={LoginCallback} />
            <Route path={PASSWORD_RESET_PATH} component={PasswordReset} />
            <Route path={NOT_FOUND_PATH} component={PageNotFound} />
            <Route path={APP_PATHS} component={App} />
            <Route component={PageNotFound} />
          </Switch>
        </IntercomProvider>
      </FeatureFlagsProvider>
    );
  }
}

export default withRouter(PreApp);
