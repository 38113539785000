import React, { useMemo } from 'react';
import { ID } from 'types/pigeon';

import { useFetchCommentReplies } from './useFetchCommentReplies';

interface ReplyFetcherOwnProps {
  videoId: ID;
  commentId: ID;
  setInitialCommentReplyFetch: React.Dispatch<React.SetStateAction<boolean>>;
}

type ReplyFetcherProps = ReplyFetcherOwnProps;

function ReplyFetcher({ videoId, commentId, setInitialCommentReplyFetch }: ReplyFetcherProps) {
  const [commentReplyForwardFetchStatus] = useFetchCommentReplies({videoId, commentId});
  const initialCommentReplyFetch = commentReplyForwardFetchStatus.fetching && !commentReplyForwardFetchStatus.cursor;
  useMemo(
    () => setInitialCommentReplyFetch(initialCommentReplyFetch),
    [initialCommentReplyFetch, setInitialCommentReplyFetch],
  );
  return null;
}

export default React.memo(ReplyFetcher);
