import {
  bidirectionalActionCreatorsFactory,
  BidirectionalIDRecord,
} from '@insights-gaming/redux-utils';
import { memberAddedAC } from 'actions/event-actions';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import { ConvertCollaboratorToMemberMutation_convertCollaboratorToMember } from 'apollo/mutations/types/ConvertCollaboratorToMemberMutation';
import { GetMembersQuery_queryMembers, GetMembersQueryVariables } from 'apollo/queries/types/GetMembersQuery';
import {
  addMemberRolesAC,
  removeMemberAC,
  removeMemberRolesAC,
  updateTeamFeatureAssignmentsAC,
} from 'features/dashboard/team/dashboard-team-slice';
import { createPair } from 'helpers';
import addAsyncCases from 'helpers/addAsyncCases';
import { addBidirectionalCases } from 'helpers/addBidirectionalCases';
import { createSlice } from 'helpers/createSlice';
import update from 'immutability-helper';
import fromPairs from 'lodash/fromPairs';
import { ConvertCollaboratorToMemberInput } from 'types/graphql';
import actionCreatorFactory from 'typescript-fsa';

const name = 'dashboard-member';
const actionCreator = actionCreatorFactory(name);
const bidirectionalActionCreator = bidirectionalActionCreatorsFactory(actionCreator);

export const fetchTeamMembersAC = bidirectionalActionCreator<
  GetMembersQueryVariables,
  GetMembersQuery_queryMembers,
  Error
>('FETCH_TEAM_MEMBERS');

export const convertCollaboratorToMemberAC = actionCreator.async<
  ConvertCollaboratorToMemberInput,
  ConvertCollaboratorToMemberMutation_convertCollaboratorToMember,
  Error
>('INVITE_MEMBERS');

interface DashboardMemberState {
  teamMemberRecords: Partial<Dictionary<BidirectionalIDRecord>>;
  memberDict: Partial<Dictionary<MemberFragment>>;
}

const initialState: DashboardMemberState = {
  teamMemberRecords: {},
  memberDict: {},
};

const dashboardMemberSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    addBidirectionalCases(builder, fetchTeamMembersAC, {
      records: (tp, { teamId }) => tp.teamMemberRecords[teamId],
      dict: (tp) => tp.memberDict,
      values: (result) => result.edges,
    });

    addAsyncCases(builder, convertCollaboratorToMemberAC, {
      done: (state, action) => {
        const { params: { teamId }, result: { member } } = action.payload;
        state.teamMemberRecords = update(state.teamMemberRecords, {
          [teamId]: {ids: ids => Array.from(new Set([member.id, ...ids]))},
        });
        state.memberDict = update(state.memberDict, {
          [member.id]: {$set: member},
        });
      },
    });

    addAsyncCases(builder, removeMemberAC, { done: removeTeamMember });
    addAsyncCases(builder, addMemberRolesAC, { done: updateTeamMember });
    addAsyncCases(builder, removeMemberRolesAC, { done: updateTeamMember });

    builder.addCase(memberAddedAC, (state, action) => {
      const { memberAdded, teamId } = action.payload;
      state.teamMemberRecords = update(state.teamMemberRecords, {
        [teamId]: {ids: ids => Array.from(new Set([memberAdded.id, ...ids]))},
      });
      state.memberDict = update(state.memberDict, {
        [memberAdded.id]: {$set: memberAdded},
      });
    });

    addAsyncCases(builder, updateTeamFeatureAssignmentsAC, {
      done: (state, { payload: { result: { members } } }) => {
        return update(state, {
          memberDict: {$merge: fromPairs(members.map(createPair))},
        });
      },
    });
  },
});

export const dashboardMemberReducer = dashboardMemberSlice.reducer;

function updateTeamMember(
  state: DashboardMemberState,
  action: ReturnType<typeof addMemberRolesAC.done> | ReturnType<typeof removeMemberRolesAC.done>,
): DashboardMemberState {
  const { result: { member } } = action.payload;
  return update(state, {
    memberDict: {
      [member.id]: {$set: member},
    },
  });
}

function removeTeamMember(
  state: DashboardMemberState,
  action: ReturnType<typeof removeMemberAC.done>,
): DashboardMemberState {
  const { params: { teamId }, result: { memberId } } = action.payload;
  return update(state, {
    teamMemberRecords: {
      [teamId]: {
        ids: ids => ids.filter(id => id !== memberId),
      },
    },
    memberDict: {$unset: [memberId]},
  });
}
