import { FlexSpacer, Loader } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import dateFnsAdd from 'date-fns/add';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import { formatDate, numToDollarAmount } from 'helpers/formatters';
import { TKeysWithNS, useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useContext, useMemo } from 'react';
import { BillingInterval, CouponType } from 'types/graphql';

import { CustomCouponCodeContext } from '../CustomCouponCodeContext';

interface BottomSummaryOwnProps {
  defaultSummary: boolean;
  cost?: number;
  interval: BillingInterval;
  teamProration?: number;
  teamBalance?: number;
  freeTrial?: boolean;
}

const FREE_TRIAL_COST = 1;

type BottomSummaryProps = BottomSummaryOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  bold: {
    fontWeight: 700,
  },
  accent: {
    color: theme.palette.secondary.main,
  },
  bottomRow: {
    marginTop: theme.spacing(1),
  },
  divider: {
    height: 1,
  },
  m1: {
    margin: theme.spacing(1, 0),
    width: '100%',
  },
}), {name: 'BottomSummary'});

function BottomSummary(props: BottomSummaryProps) {
  const classes = useStyles(props);
  const { defaultSummary, cost, interval, teamBalance, teamProration, freeTrial } = props;

  const { t } = useStrictTranslation(['dashboard', 'dialog']);

  const isMobile = useIsMobilePortrait();

  const isYearly = useMemo(() => interval === BillingInterval.YEARLY, [interval]);

  const { coupon } = useContext(CustomCouponCodeContext);

  const renewDate = useMemo(() => {
    return formatDate(dateFnsAdd(new Date(), isYearly ? { years: 1 } : { months: 1 }));
  }, [isYearly]);

  const formattedTeamProration = useMemo(
    () => teamProration && teamProration < 0 ? teamProration/-100 : 0,
    [teamProration],
  );
  const formattedTeamBalance = useMemo(
    () => teamBalance && teamBalance < 0 ? teamBalance/-100 : 0,
    [teamBalance],
  );

  const appliedCredits = useMemo(
    () => cost && Math.min(cost - formattedTeamProration, formattedTeamBalance) || 0,
    [cost, formattedTeamBalance, formattedTeamProration],
  );

  const displaySubtotalSection = useMemo(
    () => formattedTeamProration > 0 || formattedTeamBalance > 0 || freeTrial,
    [formattedTeamBalance, formattedTeamProration, freeTrial],
  );

  const finalizedTotalCost = useMemo(() => {
    if (cost) {
      const discountAmount = coupon ? coupon.type === CouponType.FIXED ? coupon.amount : cost * (1 / coupon.amount) : 0;
      return cost - formattedTeamProration - appliedCredits - discountAmount;
    }
    return;
  }, [appliedCredits, cost, coupon, formattedTeamProration]);

  const creditsUsed = useMemo(
    () => formattedTeamProration + appliedCredits,
    [appliedCredits, formattedTeamProration],
  );

  const remainingCreditBalance = useMemo(
    () => appliedCredits === 0 ? formattedTeamBalance : formattedTeamBalance - appliedCredits,
    [appliedCredits, formattedTeamBalance],
  );

  const renderSubtotalDetails = useCallback(([label, cost]: [TKeysWithNS<'dialog'>, number], index: number) => {
    return !(defaultSummary && index) ? (
      <FlexSpacer fullWidth={true} flexJustifyContent='space-between' key={label}>
        <Typography color={index ? 'secondary' : 'initial'} className={classes.bold}>
          {t(label)}
        </Typography>
        <Typography color={index ? 'secondary' : 'initial'} className={classes.bold}>
          {index
            ? t('dialog:plandetails.subtracttotal', { total: numToDollarAmount(cost) })
            : t('dialog:plandetails.total', { total: numToDollarAmount(cost) })
          }
        </Typography>
      </FlexSpacer>
    ) : null;
  }, [classes.bold, defaultSummary, t]);

  const subtotalDetails = useMemo(() => {
    if (typeof cost === 'undefined') {
      return [];
    }

    const subtotal =  [
      ['dialog:plandetails.subtotal', defaultSummary ? 0 : cost],
    ];

    if (interval === BillingInterval.MONTHLY) {
      subtotal.push([
        freeTrial ? 'dialog:plandetails.firstmonth' : 'dialog:plandetails.creditsused',
        freeTrial ? cost - FREE_TRIAL_COST : creditsUsed,
      ]);
    }

    return subtotal;
  }, [cost, creditsUsed, defaultSummary, freeTrial, interval]);

  return (
    typeof cost === 'undefined' ? (
      <Loader/>
    ) : (
      <React.Fragment>
        {displaySubtotalSection && (
          <div className={classes.m1}>
            {subtotalDetails.map(renderSubtotalDetails)}
          </div>
        )}
        <Divider flexItem={true} variant='fullWidth' className={classes.divider}/>
        {typeof finalizedTotalCost !== 'undefined' && (
          <FlexSpacer fullWidth={true} flexJustifyContent='space-between' className={classes.m1}>
            <Typography className={classes.bold}>
              {t('dialog:plandetails.totalchargedtoday')}
            </Typography>
            <Typography className={classes.bold}>
              {finalizedTotalCost >= 0
                ? t(
                  'dialog:plandetails.total',
                  {total: numToDollarAmount(
                    defaultSummary ? 0 : (isYearly ? false : freeTrial) ? FREE_TRIAL_COST : finalizedTotalCost,
                  )},
                )
                : t('dialog:plandetails.subtracttotal', { total: numToDollarAmount(finalizedTotalCost) })
              }
            </Typography>
          </FlexSpacer>
        )}
        {appliedCredits > 0 || !defaultSummary && (
          <FlexSpacer fullWidth={true} flexJustifyContent='space-between' className={classes.m1}>
            <Typography>
              {t('dialog:plandetails.newcreditbalance')}
            </Typography>
            <Typography>
              {t('dialog:plandetails.total', { total: numToDollarAmount(remainingCreditBalance) })}
            </Typography>
          </FlexSpacer>
        )}
        <Typography variant={isMobile ? 'body1' : 'caption'} className={classes.bottomRow}>
          {t(defaultSummary
            ? 'dialog:plandetails.canceldisclaimer'
            : 'dialog:plandetails.disclaimerdate',
            { date: renewDate })
          }
        </Typography>
      </React.Fragment>
    )
  );
}

export default React.memo(BottomSummary);
