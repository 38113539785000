import { Theme } from '@insights-gaming/theme';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import classNames from 'classnames';
import EllipsisListItemText from 'material/ellipsis-list-item-text/EllipsisListItemText';
import React, { forwardRef, useCallback } from 'react';

interface CommentLabelListItemOwnProps {
  className?: string;
  label: TagFragment;
  handleLabelOnChange: (label: TagFragment) => void;
}

type CommentLabelListItemProps = CommentLabelListItemOwnProps & Omit<MenuItemProps, 'button'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'CommentLabelListItem'});

const CommentLabelListItem = forwardRef<
  HTMLLIElement,
  CommentLabelListItemProps
>(function CommentLabelListItem(props: CommentLabelListItemProps, ref) {
  const classes = useStyles(props);
  const { className, label, handleLabelOnChange, ...rest } = props;

  const handleLabelOnClick = useCallback(() => {
    handleLabelOnChange(label);
  }, [label, handleLabelOnChange]);

  return (
    <MenuItem ref={ref} {...rest} onClick={handleLabelOnClick} className={classNames(classes.root, className)}>
      <ListItemIcon>
        <Brightness1Icon htmlColor={label.color} />
      </ListItemIcon>
      <EllipsisListItemText primary={label.name} />
    </MenuItem>
  );
});

CommentLabelListItem.displayName = 'CommentLabelListItem';

export default React.memo(CommentLabelListItem);
