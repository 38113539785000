import { useCallback,useRef } from 'react';
import React from 'react';

interface UseCurrentAudioSourceState {
  swapPlayer: (player?: HTMLAudioElement) => void;
}

export function useCurrentAudioSource() {
  const playerRef = useRef<HTMLAudioElement>();

  const swapPlayer = useCallback((player?: HTMLAudioElement) => {
    if(playerRef.current) {
      playerRef.current.currentTime = 0;
      playerRef.current.pause();
    }

    playerRef.current = player;
  }, []);

  return { swapPlayer };
};

export const AudioCommentSourceContext = React.createContext<UseCurrentAudioSourceState>({
  swapPlayer: () => {},
});

export const {
  Provider: AudioCommentSourceProvider,
  Consumer: AudioCommentSourceConsumer,
} = AudioCommentSourceContext;
