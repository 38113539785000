import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import classNames from 'classnames';
import { useFetchTeamIfNecessary } from 'features/dashboard/team/useFetchTeamIfNecessary';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo,useState } from 'react';
import { dashboardRoute, folderRoute,teamRoute } from 'routes';

import { DirectoryHelper } from '../dashboard-directory-helper';
import { makeGetSharedDivisionsDict } from '../dashboard-directory-selector';
import { leaveDirectoryAC } from '../dashboard-directory-slice';

interface LeaveDirectoryDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
  directory: DirectoryFragment;
}

type LeaveDirectoryDialogProps = LeaveDirectoryDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'LeaveDirectoryDialog'});

function LeaveDirectoryDialog(props: LeaveDirectoryDialogProps) {
  const classes = useStyles(props);
  const { className, open, onClose, directory } = props;
  const { t } = useStrictTranslation(['common', 'dashboard-directory']);
  const wt = t as TFunction;
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [ loading, setLoading ] = useState(false);
  const onNavigate = useNavigate();
  const [team] = useFetchTeamIfNecessary(directory.team.id);

  const publicity = wt(`common:publicity.${DirectoryHelper.isPrivate(directory) ? 'private' : 'public' }`);

  const sharedDivisionsDict = useCreateSelector(makeGetSharedDivisionsDict, '');

  const fallbackChannel = useMemo(() => {
    if (sharedDivisionsDict[directory.team.id] && sharedDivisionsDict[directory.team.id].length > 1) {
      return sharedDivisionsDict[directory.team.id].filter(d => d.id !== directory.id)[0];
    }
    return;
  }, [directory.id, directory.team.id, sharedDivisionsDict]);

  const handleLeaveDirectory = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await promiseSagaDispatch(leaveDirectoryAC, {id: directory.id, teamId: directory.team.id});
      enqueueSnackbar(t('dashboard-directory:leavechannel.success'), {variant: 'success'});
      onNavigate(team
        ? teamRoute(directory.team.id)
        : !!fallbackChannel
        ? folderRoute(fallbackChannel.id)
        : dashboardRoute(),
      );
    } catch (error) {
      enqueueSnackbar(t('dashboard-directory:leavechannel.failure'), {variant: 'error'});
    } finally {
      setLoading(false);
      onClose();
    }
  }, [
    directory.id,
    directory.team.id,
    enqueueSnackbar,
    fallbackChannel,
    loading,
    onClose,
    onNavigate,
    promiseSagaDispatch,
    t,
    team,
  ]);

  return (
    <Dialog
    className={classNames(classes.root, className)}
    open={open}
    onClose={onClose}
    >
      <AlertDialogContent
      titleText={t('dashboard-directory:leavechannel.title')}
      description={t('dashboard-directory:leavechannel.desc', { publicity })}
      cancel={{
        text: t('common:cancel'),
        action: onClose,
      }}
      confirm={{
        text: t('common:confirm'),
        loading,
        disabled: loading,
        action: handleLeaveDirectory,
      }}
      />
    </Dialog>
  );
}

export default React.memo(LeaveDirectoryDialog);
