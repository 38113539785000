import { AsyncButton, FlexSpacer, RichTextEditor } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { setVideoMetadataAC } from 'actions/video-actions';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { useFetchVideoIfNecessary } from 'features/dashboard/video/useFetchVideoIfNecessary';
import { VideoHelper } from 'features/video-library/video-helpers';
import { VideoNoteContext } from 'features/video-replay/VideoNoteContext';
import { formatDate } from 'helpers/formatters';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { buildKeystring } from 'keybindings';
import { useSnackbar } from 'notistack';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { GetUserProfileQuery_me } from '../../apollo/queries/types/GetUserProfileQuery';
import { useIsDesktop } from '../../features/media-queries/hooks';
import { useKeybindings } from '../../hooks/useKeybindings';
import { ID } from '../../types/pigeon';

export interface INotepadEditorOwnProps {
  videoId: ID;
  me?: GetUserProfileQuery_me;
  fillFullHeight?: boolean;
  readOnly?: boolean;
  renderNoPrivilege: (param: string) => JSX.Element;
}

export type NotepadEditorProps = PropsWithChildren<INotepadEditorOwnProps>

const useStyles = makeStyles((theme: Theme) => createStyles({
  warning: {
    padding: theme.spacing(0, 1),
  },
}), {name: 'NotepadEditor'});

const NotepadEditor: React.FC<NotepadEditorProps> = (props: NotepadEditorProps) => {
  const {
    me,
    videoId,
    fillFullHeight,
    readOnly,
    renderNoPrivilege,
  } = props;

  const classes = useStyles();

  const { t } = useStrictTranslation(['video']);
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [video] = useFetchVideoIfNecessary(videoId);

  const { currentNote, setCurrentNote } = useContext(VideoNoteContext);

  const notepadMetadata = useMemo(() => {
    if (!video) {
      return;
    }

    return VideoHelper.getMetadata(video).find((metadataEntry) => metadataEntry.name === 'notes');
  }, [video]);

  const initialEditorState = useMemo(() => {
    if (currentNote) {
      return EditorState.createWithContent(convertFromRaw(JSON.parse(currentNote)));
    }
    if (!notepadMetadata) { return EditorState.createEmpty(); }
    const { content } = JSON.parse(notepadMetadata.value);
    if (!content) { return EditorState.createEmpty(); }
    return EditorState.createWithContent(convertFromRaw(content));
  }, [notepadMetadata, currentNote]);

  const [editorState, setEditorState] = useState<EditorState>(initialEditorState);
  const [loading, setLoading] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    setEditorState(initialEditorState);
  }, [initialEditorState, notepadMetadata]);

  const { addContext, removeContext } = useKeybindings('editor.text.focus');

  const handleEditorOnFocus = useCallback(() => {
    addContext();
  }, [addContext]);

  const handleEditorOnBlur = useCallback(() => {
    removeContext();
  }, [removeContext]);

  const handleFormOnSubmit = useCallback(async () => {
    if (loading || !me) { return; }

    setLoading(true);

    try {
      const payload = JSON.stringify({
        content: convertToRaw(editorState.getCurrentContent()),
        modified: { time: Date.now(), by: me.alias },
      });

      await promiseSagaDispatch(setVideoMetadataAC, {
        videoId,
        metadata: [{ name: 'notes', value: payload }],
      });

      enqueueSnackbar(t('video:replay.savesuccess'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
      removeContext();
    }
  }, [loading, me, editorState, promiseSagaDispatch, videoId, enqueueSnackbar, t, removeContext]);

  const isDesktop = useIsDesktop();

  const renderEditDetails = useCallback(() => {
    if (!notepadMetadata) {
      return null;
    }

    const { modified } = JSON.parse(notepadMetadata.value);
    if (!modified) {
      return null;
    }

    const formattedTime = formatDate(modified.time);
    const user = modified.by;

    return (
      <Typography variant='caption'>
        {t('video:replay.notepadeditdetails', { user, date: formattedTime })}
      </Typography>
    );
  }, [notepadMetadata, t]);

  const renderWarning = useCallback(() => {
    return (
      <FlexSpacer flexAlignItems='center' className={classes.warning}>
        <WarningRoundedIcon />
        <Typography variant='caption'>{t('video:replay.unsavedchanges')}</Typography>
      </FlexSpacer>
    );
  }, [t, classes.warning]);

  const customKeyBindFn = useCallback((e: React.KeyboardEvent) => {
    switch (buildKeystring(e.nativeEvent)) {
      case 'ctrl+enter':
        return 'submit_comment';
    }
    return null;
  }, []);

  const customKeyCommands = useMemo(() => {
    return new Map([
      ['submit_comment', handleFormOnSubmit],
    ]);
  }, [handleFormOnSubmit]);

  useEffect(() => {
    const content = convertToRaw(editorState.getCurrentContent());

    if (JSON.stringify(content) !== currentNote) {
      setCurrentNote(JSON.stringify(content));
    }
  }, [editorState, currentNote, setCurrentNote]);

  const handleLeavePage = useCallback((e) => {
    if (!notepadMetadata) {
      return;
    }

    const { content } = JSON.parse(notepadMetadata.value);

    if (JSON.stringify(content) === currentNote) {
      return;
    }

    e.preventDefault();
    return e.returnValue = true;
  }, [currentNote, notepadMetadata]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleLeavePage);
    return () => {
      window.removeEventListener('beforeunload', handleLeavePage);
    };
  }, [handleLeavePage]);

  return (
    <React.Fragment>
      <RichTextEditor
      editorState={editorState}
      onStateChange={setEditorState}
      onFocus={handleEditorOnFocus}
      onBlur={handleEditorOnBlur}
      compactTools={!isDesktop}
      size={!isDesktop ? 'small' : undefined}
      expand={fillFullHeight ? 'fill' : undefined}
      readOnly={readOnly || loading}
      customKeyBindFn={customKeyBindFn}
      customKeyCommands={customKeyCommands}
      />
      {unsavedChanges && renderWarning()}
      {readOnly ? (
        renderNoPrivilege('note')
      ) : (
        <FlexSpacer flexAlignItems='center' flexJustifyContent='flex-end'>
          {renderEditDetails()}
          <AsyncButton
          variant='contained'
          color='primary'
          disabled={loading}
          loading={loading}
          title={t('video:replay.sendshortcut')}
          onClick={handleFormOnSubmit}
          >
            {t('video:replay.save')}
          </AsyncButton>
        </FlexSpacer>
      )}
    </React.Fragment>
  );
};

export default React.memo(NotepadEditor);
