import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { IconProps } from '@material-ui/core/Icon';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React from 'react';

const styles = (theme: Theme) => {
  return createStyles({
    '@keyframes fadeInFadeOut': {
      '0%,100%': {opacity: 0},
      '50%': {opacity: 1},
    },
    liveIcon: {
      animation: '2s $fadeInFadeOut infinite',
      marginRight: theme.spacing(1),
      fontSize: 16,
      color: theme.palette.error.main,
    },
    liveText: {
      fontWeight: 'bold',
      textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
    },
  });
};

type StyleProps = WithStyles<typeof styles>;

export interface IRecordIconOwnProps {
  text?: React.ReactNode;
}

type LiveSymbolProps = IconProps & IRecordIconOwnProps & StyleProps;

class LiveSymbol extends React.Component<LiveSymbolProps> {
  public render() {
    const {
      classes,
      text,
    } = this.props;
    return (
      <Box display='flex' alignItems='center' position='absolute' zIndex='1'>
        <FiberManualRecordIcon className={classes.liveIcon}/>
        {text &&
          <Typography className={classes.liveText}>
            {text}
          </Typography>
        }
      </Box>
    );
  }
}

export default withStyles(styles)(LiveSymbol);
