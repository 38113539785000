import React from 'react';

interface MobileDrawingToolbarContextState {
  mobileToolbarElement: Element | null;
  mobileToggleElement: Element | null;
  mobileToolbarRef: (el: Element | null) => void;
  mobileToggleRef: (el: Element | null) => void;
}

const MobileDrawingToolbarContext = React.createContext<MobileDrawingToolbarContextState>({
  mobileToolbarElement: null,
  mobileToggleElement: null,
  mobileToolbarRef: () => {},
  mobileToggleRef: () => {},
});

const { Provider: MobileDrawingToolbarProvider, Consumer: MobileDrawingToolbarConsumer } = MobileDrawingToolbarContext;

export {
  MobileDrawingToolbarConsumer,
  MobileDrawingToolbarContext,
  MobileDrawingToolbarContextState,
  MobileDrawingToolbarProvider,
};
