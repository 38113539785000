import { compareStringAscending } from 'helpers/comparators';

import { HeroesF } from '../../../../../locales/en/heroes';
import { OverwatchF } from '../../../../../locales/en/overwatch';
import { OwmapsF } from '../../../../../locales/en/owmaps';
import { OwstatsF } from '../../../../../locales/en/owstats';
import { ID } from '../../../../../types/pigeon';

export interface IFilterValue {
  value: string;
  id: ID;
}

export interface IFilterOption {
  label: string;
  value: string;
  valueOption?: IFilterOption[];
  displayTeamOption?: boolean;
}

export const gameModes: IFilterOption[] = [
  {
    label: OverwatchF('gamemodes.assault'),
    value: 'assault',
  },
  {
    label: OverwatchF('gamemodes.control'),
    value: 'control',
  },
  {
    label: OverwatchF('gamemodes.escort'),
    value: 'escort',
  },
  {
    label: OverwatchF('gamemodes.hybrid'),
    value: 'hybrid',
  },
];

export const mapsOptions: IFilterOption[] = [
  {
    label: OwmapsF('blackForest'),
    value: 'blackForest',
  },
  {
    label: OwmapsF('blizzardWorld'),
    value: 'blizzardWorld',
  },
  {
    label: OwmapsF('busan'),
    value: 'busan',
  },
  {
    label: OwmapsF('busanDowntown'),
    value: 'busanDowntown',
  },
  {
    label: OwmapsF('busanMekaBase'),
    value: 'busanMekaBase',
  },
  {
    label: OwmapsF('busanSanctuary'),
    value: 'busanSanctuary',
  },
  {
    label: OwmapsF('castillo'),
    value: 'castillo',
  },
  {
    label: OwmapsF('chateauGuillard'),
    value: 'chateauGuillard',
  },
  {
    label: OwmapsF('dorado'),
    value: 'dorado',
  },
  {
    label: OwmapsF('ecopointAntarctica'),
    value: 'ecopointAntarctica',
  },
  {
    label: OwmapsF('eichenwalde'),
    value: 'eichenwalde',
  },
  {
    label: OwmapsF('hanamura'),
    value: 'hanamura',
  },
  {
    label: OwmapsF('havana'),
    value: 'havana',
  },
  {
    label: OwmapsF('hollywood'),
    value: 'hollywood',
  },
  {
    label: OwmapsF('horizonLunarColony'),
    value: 'horizonLunarColony',
  },
  {
    label: OwmapsF('ilios'),
    value: 'ilios',
  },
  {
    label: OwmapsF('iliosLighthouse'),
    value: 'iliosLighthouse',
  },
  {
    label: OwmapsF('iliosRuins'),
    value: 'iliosRuins',
  },
  {
    label: OwmapsF('iliosWell'),
    value: 'iliosWell',
  },
  {
    label: OwmapsF('junkertown'),
    value: 'junkertown',
  },
  {
    label: OwmapsF('kingsRow'),
    value: 'kingsRow',
  },
  {
    label: OwmapsF('lijiangTower'),
    value: 'lijiangTower',
  },
  {
    label: OwmapsF('lijiangTowerControlCenter'),
    value: 'lijiangTowerControlCenter',
  },
  {
    label: OwmapsF('lijiangTowerGarden'),
    value: 'lijiangTowerGarden',
  },
  {
    label: OwmapsF('lijiangTowerNightMarket'),
    value: 'lijiangTowerNightMarket',
  },
  {
    label: OwmapsF('necropolis'),
    value: 'necropolis',
  },
  {
    label: OwmapsF('nepal'),
    value: 'nepal',
  },
  {
    label: OwmapsF('nepalSanctum'),
    value: 'nepalSanctum',
  },
  {
    label: OwmapsF('nepalShrine'),
    value: 'nepalShrine',
  },
  {
    label: OwmapsF('nepalVillage'),
    value: 'nepalVillage',
  },
  {
    label: OwmapsF('numbani'),
    value: 'numbani',
  },
  {
    label: OwmapsF('oasis'),
    value: 'oasis',
  },
  {
    label: OwmapsF('oasisCityCenter'),
    value: 'oasisCityCenter',
  },
  {
    label: OwmapsF('oasisGardens'),
    value: 'oasisGardens',
  },
  {
    label: OwmapsF('oasisUniversity'),
    value: 'oasisUniversity',
  },
  {
    label: OwmapsF('paris'),
    value: 'paris',
  },
  {
    label: OwmapsF('rialto'),
    value: 'rialto',
  },
  {
    label: OwmapsF('route66'),
    value: 'route66',
  },
  {
    label: OwmapsF('templeOfAnubis'),
    value: 'templeOfAnubis',
  },
  {
    label: OwmapsF('volskayaIndustries'),
    value: 'volskayaIndustries',
  },
  {
    label: OwmapsF('watchpointGibraltar'),
    value: 'watchpointGibraltar',
  },
].sort((a, b) => compareStringAscending(a.value, b.value));

export const ultsOptions: IFilterOption[] = [
  {
    label: HeroesF('ults.amplificationmatrix'),
    value: 'amplificationmatrix',
  },
  {
    label: HeroesF('ults.barrage'),
    value: 'barrage',
  },
  {
    label: HeroesF('ults.blizzard'),
    value: 'blizzard',
  },
  {
    label: HeroesF('ults.bob'),
    value: 'bob',
  },
  {
    label: HeroesF('ults.coalescence'),
    value: 'coalescence',
  },
  {
    label: HeroesF('ults.configurationtank'),
    value: 'configurationtank',
  },
  {
    label: HeroesF('ults.deadeye'),
    value: 'deadeye',
  },
  {
    label: HeroesF('ults.deathblossom'),
    value: 'deathblossom',
  },
  {
    label: HeroesF('ults.dragonstrike'),
    value: 'dragonstrike',
  },
  {
    label: HeroesF('ults.dragonblade'),
    value: 'dragonblade',
  },
  {
    label: HeroesF('ults.duplicate'),
    value: 'duplicate',
  },
  {
    label: HeroesF('ults.earthshatter'),
    value: 'earthshatter',
  },
  {
    label: HeroesF('ults.emp'),
    value: 'emp',
  },
  {
    label: HeroesF('ults.graviticflux'),
    value: 'graviticflux',
  },
  {
    label: HeroesF('ults.gravitonsurge'),
    value: 'gravitonsurge',
  },
  {
    label: HeroesF('ults.infrasight'),
    value: 'infrasight',
  },
  {
    label: HeroesF('ults.meteorstrike'),
    value: 'meteorstrike',
  },
  {
    label: HeroesF('ults.minefield'),
    value: 'minefield',
  },
  {
    label: HeroesF('ults.moltencore'),
    value: 'moltencore',
  },
  {
    label: HeroesF('ults.nanoBoost'),
    value: 'nanoBoost',
  },
  {
    label: HeroesF('ults.photonbarrier'),
    value: 'photonbarrier',
  },
  {
    label: HeroesF('ults.primalrage'),
    value: 'primalrage',
  },
  {
    label: HeroesF('ults.pulsebomb'),
    value: 'pulsebomb',
  },
  {
    label: HeroesF('ults.rally'),
    value: 'rally',
  },
  {
    label: HeroesF('ults.riptire'),
    value: 'riptire',
  },
  {
    label: HeroesF('ults.selfdestruct'),
    value: 'selfdestruct',
  },
  {
    label: HeroesF('ults.soundbarrier'),
    value: 'soundbarrier',
  },
  {
    label: HeroesF('ults.supercharger'),
    value: 'supercharger',
  },
  {
    label: HeroesF('ults.tacticalvisor'),
    value: 'tacticalvisor',
  },
  {
    label: HeroesF('ults.transcendence'),
    value: 'transcendence',
  },
  {
    label: HeroesF('ults.valkyrie'),
    value: 'valkyrie',
  },
  {
    label: HeroesF('ults.wholehog'),
    value: 'wholehog',
  },
].sort((a, b) => compareStringAscending(a.value, b.value));

export const heroesOptions: IFilterOption[] = [
  {
    label: HeroesF('ana'),
    value: 'ana',
  },
  {
    label: HeroesF('ashe'),
    value: 'ashe',
  },
  {
    label: HeroesF('baptiste'),
    value: 'baptiste',
  },
  {
    label: HeroesF('bastion'),
    value: 'bastion',
  },
  {
    label: HeroesF('brigitte'),
    value: 'brigitte',
  },
  {
    label: HeroesF('dva'),
    value: 'dva',
  },
  {
    label: HeroesF('doomfist'),
    value: 'doomfist',
  },
  {
    label: HeroesF('echo'),
    value: 'echo',
  },
  {
    label: HeroesF('genji'),
    value: 'genji',
  },
  {
    label: HeroesF('hanzo'),
    value: 'hanzo',
  },
  {
    label: HeroesF('junkrat'),
    value: 'junkrat',
  },
  {
    label: HeroesF('lucio'),
    value: 'lucio',
  },
  {
    label: HeroesF('mccree'),
    value: 'mccree',
  },
  {
    label: HeroesF('mei'),
    value: 'mei',
  },
  {
    label: HeroesF('mercy'),
    value: 'mercy',
  },
  {
    label: HeroesF('moira'),
    value: 'moira',
  },
  {
    label: HeroesF('orisa'),
    value: 'orisa',
  },
  {
    label: HeroesF('pharah'),
    value: 'pharah',
  },
  {
    label: HeroesF('reaper'),
    value: 'reaper',
  },
  {
    label: HeroesF('reinhardt'),
    value: 'reinhardt',
  },
  {
    label: HeroesF('roadhog'),
    value: 'roadhog',
  },
  {
    label: HeroesF('sigma'),
    value: 'sigma',
  },
  {
    label: HeroesF('soldier_76'),
    value: 'soldier_76',
  },
  {
    label: HeroesF('sombra'),
    value: 'sombra',
  },
  {
    label: HeroesF('symmetra'),
    value: 'symmetra',
  },
  {
    label: HeroesF('torbjorn'),
    value: 'torbjorn',
  },
  {
    label: HeroesF('tracer'),
    value: 'tracer',
  },
  {
    label: HeroesF('widowmaker'),
    value: 'widowmaker',
  },
  {
    label: HeroesF('winston'),
    value: 'winston',
  },
  {
    label: HeroesF('wrecking_ball'),
    value: 'wrecking_ball',
  },
  {
    label: HeroesF('zarya'),
    value: 'zarya',
  },
  {
    label: HeroesF('zenyatta'),
    value: 'zenyatta',
  },
].sort((a, b) => compareStringAscending(a.value, b.value));

export const teamOptions: IFilterOption[] = [
  {
    label: 'Your Team',
    value: 'same',
  },
  {
    label: 'Enemy Team',
    value: 'other',
  },
];

export const parameterOptions: IFilterOption[] = [
  {
    label: OwstatsF('queries.parameterOptions.gamemode'),
    value: 'Gamemode',
  },
  {
    label: OwstatsF('queries.parameterOptions.map'),
    value: 'Map',
  },
  {
    label: OwstatsF('queries.parameterOptions.attack'),
    value: 'Attack',
  },
  {
    label: OwstatsF('queries.parameterOptions.defend'),
    value: 'Defend',
  },
  {
    label: OwstatsF('queries.parameterOptions.teamfightfirstultcolor'),
    value: 'Teamfight.FirstUlt.Color',
  },
  {
    label: OwstatsF('queries.parameterOptions.teamfightfirstulthero'),
    value: 'Teamfight.FirstUlt.Hero',
  },
  {
    label: OwstatsF('queries.parameterOptions.teamfightfirstkillcolor'),
    value: 'Teamfight.FirstKill.Color',
  },
  {
    label: OwstatsF('queries.parameterOptions.teamfightfirstkillhero'),
    value: 'Teamfight.FirstKill.Hero',
  },
  {
    label: OwstatsF('queries.parameterOptions.teamfightfirstdeathcolor'),
    value: 'Teamfight.FirstDeath.Color',
  },
  {
    label: OwstatsF('queries.parameterOptions.teamfightfirstdeathhero'),
    value: 'Teamfight.FirstDeath.Hero',
  },
  {
    label: OwstatsF('queries.parameterOptions.heroes'),
    value: 'Teamfight.ColorHeroes',
  },
  {
    label: OwstatsF('queries.parameterOptions.ults'),
    value: 'Teamfight.ColorUlts.Hero',
  },
];
