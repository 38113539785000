import { fileUploadAC, IFileUploadInput, resumeExistingTusUploadAC } from 'features/upload/upload-slice';
import { promisifyDispatch } from 'hooks/usePromiseSagaDispatch';
import ResumeUploadDialogContent, { IResumeUploadDialogContentDispatch } from 'material/dialogs/resume-upload-dialog-content/ResumeUploadDialogContent';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { IResumableUpload } from 'types';

function mapDispatchToProps(dispatch: Dispatch): IResumeUploadDialogContentDispatch {
  const promiseSagaDispatch = promisifyDispatch(dispatch);
  return {
    onTusUpload: (input: IFileUploadInput) => promiseSagaDispatch(fileUploadAC, { ...input, bypass: true }),
    onTusUploadResumeExisting: (upload: IResumableUpload) => dispatch(resumeExistingTusUploadAC(upload)),
  };
}

export default connect(null, mapDispatchToProps)(ResumeUploadDialogContent);
