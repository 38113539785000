import { updateAttackerAsyncAC,updateStatsMapAsyncAC } from 'features/statistics/statistics-slice';
import { promisifyDispatch } from 'hooks/usePromiseSagaDispatch';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import MapVerificationDialogContent, { IMapVerificationDialogContentDispatch } from '../../material/dialogs/map-verification-dialog-content/MapVerificationDialogContent';
import { UpdateAttackerInput,UpdateStatsMapInput } from '../../types/graphql';

export function mapDispatchToProps(dispatch: Dispatch): IMapVerificationDialogContentDispatch {
  const promiseSagaDispatch = promisifyDispatch(dispatch);
  return {
    updateStatsMap: (input: UpdateStatsMapInput) => promiseSagaDispatch(updateStatsMapAsyncAC, input),
    updateAttacker: (input: UpdateAttackerInput) => promiseSagaDispatch(updateAttackerAsyncAC, input),
  };
}

export default connect(null, mapDispatchToProps)(MapVerificationDialogContent);
