import { createRemFromPx, Theme } from '@insights-gaming/theme';
import {createStyles, makeStyles } from '@material-ui/core/styles';
import Tabs, { TabsProps } from '@material-ui/core/Tabs';
import React from 'react';
interface SecondaryTabsProps {
  thickness?: number;
  backgroundcolor?: string;
}
interface TabsPropsFixed extends Omit<TabsProps, 'onChange'> {
  onChange:
    | ((event: React.ChangeEvent<{}>, value: any) => void)
    | ((event: React.FormEvent<HTMLButtonElement>) => void);
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: ({ backgroundcolor = theme.palette.background.paper }: SecondaryTabsProps) => backgroundcolor,
  },
  indicator: {
    width: '100%',
    borderLeft: ({ thickness = 4, orientation = 'vertical' }: SecondaryTabsProps & TabsPropsFixed) =>
      orientation === 'vertical' ? `${createRemFromPx(thickness)} solid ${theme.palette.primary.main}` : 'none',
    borderBottom: ({ thickness = 4, orientation = 'vertical' }: SecondaryTabsProps & TabsPropsFixed) =>
      orientation === 'horizontal' ? `${createRemFromPx(thickness)} solid ${theme.palette.primary.main}` : 'none',
    backgroundColor: theme.palette.action.selected,
  },
}), {name: 'SecondaryTabs'});

function SecondaryTabs(props: SecondaryTabsProps & TabsPropsFixed) {
  const { ...rest } = props;
  const { root, indicator} = useStyles(props);
  return <Tabs classes={{root, indicator}} {...rest}/>;
}

export default React.memo(SecondaryTabs);
