import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { SerializedCanvas } from 'types/pigeon';

interface TimestampButtonOwnProps {
  className?: string;
  time: number;
  annotation?: SerializedCanvas;
  handleViewAnnotation?: (e: React.SyntheticEvent, t: number, annotation?: SerializedCanvas) => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

type TimestampButtonProps = TimestampButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    minWidth: 'unset',
    padding: theme.spacing(0, 0.5),
    marginTop: -1,
    marginLeft: -1,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  disabled: {
    pointerEvents: 'none',
  },
}), {name: 'TimestampButton'});

function TimestampButton(props: TimestampButtonProps) {
  const classes = useStyles(props);
  const { className, time, annotation, handleViewAnnotation, children, disabled } = props;

  const handleTimestampOnClick = useCallback((e: React.SyntheticEvent) => {
    handleViewAnnotation?.(e, time, annotation);
  }, [annotation, handleViewAnnotation, time]);

  return (
    <Button
    className={classNames(classes.root, className, disabled ? classes.disabled : undefined)}
    size='small'
    variant='contained'
    color='primary'
    onClick={handleTimestampOnClick}
    >
      {children}
    </Button>
  );
}

export default React.memo(TimestampButton);
