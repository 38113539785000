import { AsyncButtonProps, LabelButtonBase } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';

interface LabelButtonOwnProps {
  className?: string;
  appliedCommentLabel: TagFragment;
  canRemoveOwnCommentLabel?: boolean;
  loading?: boolean;
  removeLabelOnClick?: (label: TagFragment) => void;
  isRangedTimestamp?: boolean;
  isExtraLabel?: boolean;
  isAnnotated?: boolean;
  labelCount?: number;
}

type LabelButtonProps = LabelButtonOwnProps & AsyncButtonProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  disabled: {
    pointerEvents: 'none',
  },
}), {name: 'LabelButton'});

function LabelButton(props: LabelButtonProps) {
  const classes = useStyles(props);
  const {
    className,
    appliedCommentLabel,
    canRemoveOwnCommentLabel,
    size = 'small',
    variant = 'outlined',
    removeLabelOnClick,
    loading,
    isRangedTimestamp,
    isExtraLabel,
    isAnnotated,
    labelCount,
    disabled,
    ...rest
  } = props;

  const shortLabel = useMemo(() => {
    return !isExtraLabel && (
      (isRangedTimestamp && labelCount && labelCount !== 1) ||
      (appliedCommentLabel.name.length < 8) ||
      (isAnnotated && isRangedTimestamp)
    );
  }, [appliedCommentLabel.name.length, isAnnotated, isExtraLabel, isRangedTimestamp, labelCount]);

  const handleRemoveLabel = useCallback(() => {
    removeLabelOnClick?.(appliedCommentLabel);
  }, [removeLabelOnClick, appliedCommentLabel]);

  return (
    <LabelButtonBase
    className={classNames(classes.root, className, {[classes.disabled]: disabled})}
    label={appliedCommentLabel.name}
    dense={shortLabel}
    onRemove={removeLabelOnClick ? handleRemoveLabel : undefined}
    buttonColor={appliedCommentLabel.color}
    loading={loading}
    variant={variant}
    disabled={disabled}
    {...rest}
    />
  );
}

export default React.memo(LabelButton);
