import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React from 'react';

import TransferOwnerDialog from './TransferOwnerDialog';

interface MemberTableRowOwnProps {
  className?: string;
  teamId?: string;
  userId?: string;
  avatar?: React.ReactNode;
  name?: React.ReactNode;
  roles?: React.ReactNode;
  action?: React.ReactNode;
  mobileAction?: React.ReactNode;
  assignKey?: React.ReactNode;
  isOwner?: boolean;
  displayCrown?: boolean;
}

type MemberTableRowProps = MemberTableRowOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  name: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  action: {
    textAlign: 'end',
  },
  crown: {
    color: '#F2C94C',
  },
  crownless: {
    color: theme.palette.text.deemphasized,
  },
  keyless: {
    color: theme.palette.text.deemphasized,
  },
}), {name: 'MemberTableRow'});

function MemberTableRow(props: MemberTableRowProps) {
  const classes = useStyles(props);
  const {
    className,
    teamId,
    userId,
    avatar,
    name,
    action,
    mobileAction,
    assignKey,
    roles,
    isOwner,
    displayCrown,
  } = props;

  const { t } = useStrictTranslation(['dialog', 'settings']);
  const isDesktop = useIsDesktop();

  const [ isTransferOwnerDialogOpen, openTransferOwnerDialog, closeTransferOwnerDialog ] = useDialogState();

  const { canAssignFeatures } = useAccessControl();

  return (
    <TableRow className={classNames(classes.root, className)}>
      <FixedTableCell width={isDesktop ? 250 : 150}>
        <FlexSpacer flexAlignItems='center'>
          {avatar}
          <div className={classes.name}>
            {name}
          </div>
          {displayCrown && (
            <Tooltip title={!isOwner ? t('settings:transferowner.tooltip') : false}>
              <IconButton
              onClick={openTransferOwnerDialog}
              size='small'
              disabled={isOwner}
              >
                <FontAwesomeIcon
                icon={faCrown}
                className={isOwner ? classes.crown : classes.crownless}
                />
              </IconButton>
            </Tooltip>
          )}
        </FlexSpacer>
      </FixedTableCell>
      <FixedTableCell width={isDesktop ? 300 : 125}>{roles}</FixedTableCell>
      {isDesktop ? (
        <React.Fragment>
          {canAssignFeatures && <FixedTableCell align='center' width={150}>{assignKey}</FixedTableCell>}
          <FixedTableCell className={classes.action} width={80}>{action}</FixedTableCell>
        </React.Fragment>
      ) : (
        <FixedTableCell className={classes.action} width={50}>
          {mobileAction}
        </FixedTableCell>
      )}
      <TransferOwnerDialog
      open={isTransferOwnerDialogOpen}
      onClose={closeTransferOwnerDialog}
      user={name}
      teamId={teamId}
      userId={userId}
      />
    </TableRow>
  );
}

export default React.memo(MemberTableRow);
