import { EnhancedDialogTitle, FlexSpacer, VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { useBooleanState } from '@insights-gaming/utils';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SettingsIcon from '@material-ui/icons/Settings';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import PrivacySettingsDialog from 'components/privacy-settings-dialog/PrivacySettingsDialog';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { useFetchTeamIfNecessary } from 'features/dashboard/team/useFetchTeamIfNecessary';
import { useIsDesktop } from 'features/media-queries/hooks';
import { VideoHelper } from 'features/video-library/video-helpers';
import { formatDuration } from 'helpers/formatters';
import { secondsFromTimestamp } from 'helpers/math';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { videoRoute } from 'routes';
import CopyLink from 'subcomponents/copy-link/CopyLink';

import SocialMediaShare from './SocialMediaShare';

interface ShareVideoDialogOwnProps {
  onClose?: VoidFunction;
  video: VideoFragment;
}

type ShareVideoDialogProps = ShareVideoDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  copyLinkRoot: {
    gridTemplateColumns: 'auto 130px',
    flex: 1,
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0),
  },
  shareTimeCheckBoxLabel: {
    marginLeft: 0,
  },
}), {name: 'ShareVideoDialog'});

const isProbablyATimestamp = (str: string): boolean => {
  return /:/.test(str);
};

const isANumber = (str: string): boolean => {
  return /^\d+$/.test(str);
};

function ShareVideoDialog(props: ShareVideoDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <ShareVideoDialogContent {...props} />
    </Dialog>
  );
}

function ShareVideoDialogContent(props: ShareVideoDialogProps) {
  const classes = useStyles(props);
  const { onClose, video } = props;
  const { t } = useStrictTranslation(['dialog']);

  const {
    state: {
      progress: { playedSeconds },
    },
  } = useContext(VideoReplayContext);

  const [ includeTimestampInCopiedLink, setIncludeTimestampInCopiedLink ] = useState(false);
  const [ userTimestamp, setUserTimestamp ] = useState(() => formatDuration(playedSeconds));

  const [ isPrivacySettingDialogOpen, openPrivacySettingsDialog, closePrivacySettingsDialog ] = useBooleanState();

  const { canUpdateVod } = useAccessControl();
  const [ team ] = useFetchTeamIfNecessary(VideoHelper.getTeamId(video));
  const isDesktop = useIsDesktop();

  const shareUrl = useMemo(() => {
    let route = window.location.protocol + '//' + window.location.host + videoRoute(video.id);
    if (includeTimestampInCopiedLink) {
      if (userTimestamp && isProbablyATimestamp(userTimestamp)) {
        route += '?t=' + secondsFromTimestamp(userTimestamp);
      } else if (userTimestamp && isANumber(userTimestamp)) {
        route += '?t=' + parseInt(userTimestamp, 10);
      } else {
        // Just use what the video is set to
        route += '?t=' + playedSeconds;
      }
      route += 's';
    }
    return route;
  }, [includeTimestampInCopiedLink, userTimestamp, video, playedSeconds]);

  const handleChangeUserTimestamp = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setUserTimestamp(e.currentTarget.value);
  }, []);

  const resetTimeIfInvalid = useCallback(() => {
    if (secondsFromTimestamp(userTimestamp) <= 0 && !isANumber(userTimestamp)) {
      setUserTimestamp(formatDuration(playedSeconds));
    }
  }, [userTimestamp, playedSeconds]);

  const handleCheckboxOnChange = useCallback(() => {
    setIncludeTimestampInCopiedLink(includeTimestampInCopiedLink => !includeTimestampInCopiedLink);
  }, []);

  return (
    <React.Fragment>
      <EnhancedDialogTitle onClose={onClose}>
        {t('dialog:sharevideo.title')}
      </EnhancedDialogTitle>
      <DialogContent>
        {isDesktop && video.public && (
          <SocialMediaShare link={shareUrl || formatDuration(playedSeconds)} videoTitle={video.name} />
        )}
        <FlexSpacer>
          {video.public ? (
            <React.Fragment>
              <LockOpenIcon fontSize='small' />
              <Typography variant='caption'>
                {t('dialog:sharevideo.publicvideo')}
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <LockIcon fontSize='small' />
              <Typography variant='caption'>
                {t('dialog:sharevideo.privatevideo')}
              </Typography>
            </React.Fragment>
          )}
        </FlexSpacer>
        <CopyLink
        className={classes.copyLinkRoot}
        textToCopy={shareUrl || formatDuration(playedSeconds)}
        />
        {isDesktop && (
          <div className={classes.options}>
            <FormControl margin='dense'>
              <TextField
              type='text'
              value={userTimestamp}
              onBlur={resetTimeIfInvalid}
              onChange={handleChangeUserTimestamp}
              fullWidth={true}
              />
            </FormControl>
            <FormControlLabel
            className={classes.shareTimeCheckBoxLabel}
            control={
              <Checkbox
              checked={includeTimestampInCopiedLink}
              onChange={handleCheckboxOnChange}
              />
            }
            label={t('dialog:sharevideo.shareattime')}
            />
          </div>
        )}
        {canUpdateVod && team && (
          <Button
          startIcon={<SettingsIcon/>}
          onClick={openPrivacySettingsDialog}
          size='small'
          >
            {t('dialog:sharevideo.changeprivacysettings')}
          </Button>
        )}
        <PrivacySettingsDialog
        open={isPrivacySettingDialogOpen}
        video={video}
        onClose={closePrivacySettingsDialog}
        />
      </DialogContent>
    </React.Fragment>
  );
}

export default React.memo(ShareVideoDialog);
