import { FlexSpacer, Loader, UndraggableAvatar } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { LogoSvg } from 'assets/logos';
import classNames from 'classnames';
import TeamSelectorDialog, { CHECKOUT_PATH } from 'components/dashboard/team-settings/team-billing/TeamSelectorDialog/TeamSelectorDialog';
import { getUnreadNotifications } from 'components/settings/notifications/notifications-selector';
import NotificationsFetcher from 'components/settings/notifications/NotificationsFetcher';
import Settings from 'components/settings/Settings';
import SigninRedirect from 'components/signin-redirect/SigninRedirect';
import DashboardRouting from 'features/dashboard/dashboard.routing';
import DashboardHeader from 'features/dashboard/dashboard-header/DashboardHeader';
import TeamPicker from 'features/dashboard/sidebar/TeamPicker';
import CreateTeamDialog from 'features/dashboard/team/create-team-dialog/CreateTeamDialog';
import { getSortedTeams } from 'features/dashboard/team/dashboard-team-selector';
import { selectTeamAsyncAC } from 'features/dashboard/team/dashboard-team-slice';
import { getLiveSessionGuestName } from 'features/live-session/live-session-selector';
import LiveSessionPage from 'features/live-session/LiveSessionPage';
import MiniLiveSessionViewer from 'features/live-session/MiniLiveSessionViewer';
import LoadingScreen from 'features/loading-screen/LoadingScreen';
import DashboardTeamSearchRouting from 'features/search/dashboard-team-search.routing';
import { makeGetHasStartedUpload } from 'features/upload/resumable-uploads-selector';
import { getLocalStorage } from 'helpers/storage';
import { useAuthCheck } from 'hooks/useAuthCheck';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AppContentBox from 'layout/app-content-wrapper/AppContentBox';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useRouteMatch } from 'react-router';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { getMe } from 'selectors/getMe';
import { getSelectedTeamId } from 'selectors/team';
import EmptyStateImage from 'subcomponents/empty-state-image/EmptyStateImage';

import { GetUserProfileQuery_me } from './apollo/queries/types/GetUserProfileQuery';
import ClaimInvitation from './components/claim-invitation/ClaimInvitation';
import VideoPage from './components/video/VideoPage';
import DashboardTeamRouting from './features/dashboard/team/dashboard-team.routing';
import { mobilePortrait } from './features/media-queries';
import { createTitle } from './helpers';
import { BASE_DASHBOARD_PATH, CODE_PATH, dashboardRoute, JOIN_SESSION_PATH, SETTINGS_PATH, VIDEO_PATH } from './routes';
import { IKMSession } from './types';
import { OnNavigate } from './types/dispatch';
import { ID, TeamEdge } from './types/pigeon';

interface InviteParams {
  code?: string;
}
export interface IAppMappedProps {
  me?: GetUserProfileQuery_me;
  teams: TeamFragment[];
  selectedTeamEdge?: TeamEdge;
  session?: IKMSession;
}

export interface IAppDispatch {
  onNavigate: OnNavigate;
  onCheckAuth: () => void;
  onFetchUserProfile: () => void;
}

export type AppProps = RouteComponentProps<InviteParams>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  drawer: {
    width: theme.appSizes.drawerWidth + 1,
    flexShrink: 0,
  },
  main: {
    flex: 1,
    minWidth: 0,
    maxHeight: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  sectionDesktop: {
    display: 'flex',
    [mobilePortrait(theme)]: {
      display: 'none',
    },
  },
  iconOuter: {
    height: theme.appSizes.drawerWidth,
    width: theme.appSizes.drawerWidth,
    display: 'flex',
    padding: 12, // (drawerWidth - inner avatar image width) / 2
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    display: 'flex',
  },
  logo: {
    backgroundColor: 'transparent',
  },
  noTeam: {
    alignSelf: 'center',
  },
}), { name: 'AppContent' });

function App({ match }: AppProps) {
  const classes = useStyles();
  const { t } = useStrictTranslation(['common', 'dashboard']);
  const { shutdown, update, boot } = useIntercom();

  const me = useSelector(getMe);
  const isUploading = useCreateSelector(makeGetHasStartedUpload, '');
  const sortedTeams = useSelector(getSortedTeams);
  const sessionGuestName = useSelector(getLiveSessionGuestName);
  const selectedTeamId = useSelector(getSelectedTeamId);
  const isSessionRoute = !!useRouteMatch(JOIN_SESSION_PATH);
  const dispatch = useDispatch();
  const onNavigate = useNavigate();

  const { checkedAuth, isLoggedInOrFetching } = useAuthCheck();

  const [isCreateTeamDialogOpen, openCreateTeamDialog, closeCreateTeamDialog] = useDialogState();

  useEffect(() => {
    boot({hideDefaultLauncher: true});
  }, [boot]);

  useEffect(() => {
    return () => shutdown();
  }, [shutdown]);

  useEffect(() => {
    update({
      userHash: me?.intercom_hash || undefined,
      userId: me?.id,
      name: me?.name || sessionGuestName,
      email: me?.email || undefined,
    });
  }, [me, sessionGuestName, update, boot]);

  const renderBaseUrl = useCallback(() => {
    const lastVisitedDirectoryId = getLocalStorage('lastVisitedDirectory', '');
    const lastVisitedTeamId = getLocalStorage('lastVisitedTeam', '');

    if (lastVisitedDirectoryId) {
      return (
        <Redirect to={DashboardRouting.createDirectoryUrl(lastVisitedDirectoryId)} />
      );
    }

    if (lastVisitedTeamId) {
      return (
        <Redirect to={DashboardRouting.createTeamUrl(lastVisitedTeamId)} />
      );
    }

    for (const team of sortedTeams) {
      return (
        <Redirect to={DashboardRouting.createTeamUrl(team.id)} />
      );
    }

    if (!sortedTeams.length) {
      return (
        <React.Fragment>
          <DashboardHeader/>
          <FlexSpacer className={classes.noTeam} orientation='vertical' flexAlignItems='center'>
            <EmptyStateImage url='no-team' height={400} width={320}/>
            <Typography variant='h2'>
              {t('dashboard:createorjointeam')}
            </Typography>
            <Button
            variant='contained'
            color='primary'
            onClick={openCreateTeamDialog}
            >
              {t('common:createteam')}
            </Button>
          </FlexSpacer>
          <CreateTeamDialog open={isCreateTeamDialogOpen} onClose={closeCreateTeamDialog} />
        </React.Fragment>
      );
    }

    return (
      <AppContentBox>
        <Loader />
      </AppContentBox>
    );
  }, [classes.noTeam, closeCreateTeamDialog, isCreateTeamDialogOpen, openCreateTeamDialog, sortedTeams, t]);

  const authenticatedRoutes = useMemo(() => [
    <Route key='base-dashboard' exact={true} path={BASE_DASHBOARD_PATH} render={renderBaseUrl} />,
    <Route key='invite-code' path={CODE_PATH} component={ClaimInvitation} />,
    <Route key='checkout' path={CHECKOUT_PATH} component={TeamSelectorDialog} />,
    <Route key='dashboard' path={DashboardRouting.paths} component={DashboardRouting.Component} />,
    <Route key='setting' path={SETTINGS_PATH}>{me ? <Settings me={me} /> : <Loader />}</Route>,
  ], [me, renderBaseUrl]);

  const publicRoutes = useMemo(() => [
    <Route key='video' path={VIDEO_PATH} component={VideoPage} />,
    <Route key='session' path={JOIN_SESSION_PATH} component={LiveSessionPage} />,
    <Route key='sign-in'><SigninRedirect code={match.params.code} /></Route>,
  ], [match.params.code]);

  const routes = useMemo(() => {
    if (isLoggedInOrFetching) {
      return authenticatedRoutes.concat(publicRoutes);
    }

    return publicRoutes;
  }, [authenticatedRoutes, isLoggedInOrFetching, publicRoutes]);

  const notifications = useSelector(getUnreadNotifications);

  const titleTranslation = useMemo(() => {
    if (notifications > 0) {
      return t('common:appnamewithnotifications', { notifications });
    }
    return t('common:appname');
  }, [notifications, t]);

  const teamActionButtons = useMemo(() => {
    return (
      <Link className={classes.iconOuter} to={dashboardRoute()}>
        <UndraggableAvatar className={classNames(classes.avatar, classes.logo)}>
          <LogoSvg/>
        </UndraggableAvatar>
      </Link>
    );
  }, [classes.avatar, classes.iconOuter, classes.logo]);

  const handleRefresh = useCallback((e) => {
    if (isUploading) {
      e.preventDefault();
      return e.returnValue = 'Are you sure? You will lose your upload progress.';
    }
    return;
  }, [isUploading]);

  const isSearchRoute = !!useRouteMatch(DashboardTeamSearchRouting.path);

  const handleTeamClick = useCallback((e: React.MouseEvent<HTMLButtonElement>, teamId: ID) => {
    dispatch(selectTeamAsyncAC.started(teamId));
    onNavigate(teamId
      ? isSearchRoute ? DashboardTeamSearchRouting.createUrl(teamId) : DashboardTeamRouting.createUrl(teamId)
      : dashboardRoute());
  }, [dispatch, isSearchRoute, onNavigate]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleRefresh);
    return () => {
      window.removeEventListener('beforeunload', handleRefresh);
    };
  }, [handleRefresh]);

  if (!checkedAuth) {
    return null;
  }

  return (
    <LoadingScreen loading={!me && isLoggedInOrFetching}>
      <NotificationsFetcher />
      <Helmet>
        <title>{createTitle(titleTranslation)}</title>
      </Helmet>
      <Box
      id='App'
      className={classes.root}
      >
        {isLoggedInOrFetching && (
          <Switch>
            <Route path={[VIDEO_PATH, JOIN_SESSION_PATH, CODE_PATH, CHECKOUT_PATH]} />
            <Route>
              <Drawer
              className={classNames(classes.sectionDesktop, classes.drawer)}
              variant='permanent'
              anchor='left'
              >
                <TeamPicker
                selectedTeamId={selectedTeamId}
                onTeamClick={handleTeamClick}
                teamActionButtons={teamActionButtons}
                />
              </Drawer>
            </Route>
          </Switch>
        )}
        <Box className={classes.main}>
          <Box className={classes.content}>
            {!isSessionRoute && <MiniLiveSessionViewer />}
            <Switch>{routes}</Switch>
          </Box>
        </Box>
      </Box>
    </LoadingScreen>
  );
}

export default React.memo(App);
