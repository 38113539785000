import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { isVideoFragment } from 'helpers/isVideoFragment';
import { useNavigate } from 'hooks/useNavigate';
import { useReferPath } from 'hooks/useReferPath';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useVideoBackPath } from 'hooks/useVideoBackPath';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { JOIN_SESSION_PATH, registerRoute } from 'routes';
import { getMe } from 'selectors/getMe';
import { SmolVideo } from 'types/pigeon/kmsession';

import { clearLiveSessionAC, TerminatedLiveSessionState } from './live-session-slice';

interface RemovedFromLiveSessionDialogOwnProps {
  className?: string;
  state: TerminatedLiveSessionState;
  video?: SmolVideo | VideoFragment;
}

type RemovedFromLiveSessionDialogProps = RemovedFromLiveSessionDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  ratingStar: {
    fontSize: createRemFromPx(40),
    color: theme.palette.primary.main,
  },
}), {name: 'RemovedFromLiveSessionDialog'});

function RemovedFromLiveSessionDialog(props: RemovedFromLiveSessionDialogProps) {
  const { state: { token, guestName }, video } = props;
  const { t } = useStrictTranslation(['common', 'dialog']);
  const classes = useStyles(props);
  const onNavigate = useNavigate();
  const dispatch = useDispatch();

  const me = useSelector(getMe);
  const videoBackPath = useVideoBackPath(isVideoFragment(video) ? video : undefined);
  const isSessionRoute = !!useRouteMatch(JOIN_SESSION_PATH);

  const referPath = useReferPath();
  const backPath = useMemo(() => referPath || me ? videoBackPath : registerRoute(), [referPath, me, videoBackPath]);

  const onClose = useCallback(() => {
    dispatch(clearLiveSessionAC());
    if (isSessionRoute) {
      onNavigate(backPath);
    }
  }, [backPath, dispatch, isSessionRoute, onNavigate]);

  return (
    <Dialog open={true} onClose={!isSessionRoute ? onClose : undefined} fullWidth={true} maxWidth='xs'>
      <BetterDialogTitle>{t('dialog:removedfromsession.title')}</BetterDialogTitle>
      <DialogActions>
        <Button
        variant='contained'
        color='primary'
        onClick={onClose}
        >
          {t(me ? 'common:404_back' : 'dialog:sessionterminated.createaccount')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(RemovedFromLiveSessionDialog);
