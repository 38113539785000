import gql from 'graphql-tag';

export const RemoveTeamCard_Mutation = gql`
    mutation RemoveTeamCardMutation(
        $input: RemoveTeamCardInput!
    ) {
        removeTeamCard(
            input: $input
        ) {
            cardId
        }
    }
`;
