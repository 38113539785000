import { VideoFragment } from 'apollo/fragments';
import gql from 'graphql-tag';

export const CreateUploadedVideo2_Mutation = gql`
    mutation CreateUploadedVideo2Mutation(
        $input: CreateUploadedVideo2Input!
    ) {
        createUploadedVideo2(
            input: $input
        ) {
            uploadUrl
            video {
                ...VideoFragment
            }
        }
    }
    ${VideoFragment}
`;
