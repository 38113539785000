import { useMemo } from 'react';
import { Billing, ID } from 'types/pigeon';

import useAvailableMetrics from './useAvailableMetrics';
import useTeamAvailableBundles from './useTeamAvailableBundles';

export default function useCheckoutCost(checkoutProduct: Billing.InputQuantities, teamId: ID): number {
  const bundles = useTeamAvailableBundles(teamId);
  const { metrics } = useAvailableMetrics(bundles);

  return useMemo(() => {
    const selectedProduct = metrics.find((metric) => metric.productId === checkoutProduct.productId);
    if (!selectedProduct || !selectedProduct.cost) {
      return 0;
    }
    return selectedProduct.cost[checkoutProduct.interval];
  }, [checkoutProduct.interval, checkoutProduct.productId, metrics]);
};
