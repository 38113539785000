import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { ID } from 'types/pigeon';

export const ItemTypes = {
  DIRECTORY_CARD: 'directoryCard',
  VIDEO_CARD: 'videoCard',
  TEAM_CARD: 'teamCard',
} as const;

interface _CommonDragItem {
  id: ID;
  selection?: {
    videos: Set<ID>;
    folders: Set<ID>;
  };
}

export interface DirectoryCardDragItem extends _CommonDragItem {
  type: typeof ItemTypes.DIRECTORY_CARD;
  name: string;
  parentId?: ID;
  width: number;
  height: number;
}

export interface VideoCardDragItem extends _CommonDragItem {
  type: typeof ItemTypes.VIDEO_CARD;
  name: string;
  parentId?: ID;
  thumbnailUrl?: string;
  width: number;
  height: number;
}

export interface TeamCardDragItem extends _CommonDragItem {
  type: typeof ItemTypes.TEAM_CARD;
  teamIndex: number;
  team: TeamFragment;
}

export type DragItem = DirectoryCardDragItem | VideoCardDragItem | TeamCardDragItem;
