import { callAsync, watchAsync } from '@insights-gaming/saga-utils';
import { CreateVideoAPIParams, CreateVideoAPIResult, ID, IUpload, TusUploadParams, UploadingVideoState, VideoUploadOptions } from '@insights-gaming/video-upload-slice';
import { setVideoMetadataAC, subscribeToVideoUpdatesAC } from 'actions/video-actions';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { CreateUploadedVideo2_Mutation } from 'apollo/mutations';
import { createUploadedVideo2AC,deleteVideoAC } from 'features/dashboard/video/dashboard-video-slice';
import { blobChunk, blobNumChunks, hashBlob, hashChunkSize, uuidv4 } from 'helpers';
import { raceLogout, throttleCall, watchAsyncMutation } from 'helpers/saga/effects';
import { getLocalStorage, setLocalStorage } from 'helpers/storage';
import { makeResumableUpload } from 'helpers/upload';
import { SagaIterator, Task } from 'redux-saga';
import { ActionPattern, all, call, cancel, put, race, RaceEffect, SagaReturnType, select, spawn, take, TakeEffect, takeEvery } from 'redux-saga/effects';
import { IResumableUpload, LocalTusUpload } from 'types';
import { DeleteVideoInput, VideoPublicity } from 'types/graphql';
import { Action, AnyAction } from 'typescript-fsa';

import { allVideoUploadSagas, UploadVideoInput } from './common';
import { getResumableUploadsDict } from './resumable-uploads-selector';
import { addLocalTusUploadAC, addPendingUploadAC, initLocalTusUploadsAC, ITryResumeTusUpload, removeLocalTusUploadAC, removePendingUploadAC, saveLocalTusUploadsToLocalStorageAC, tryResumeTusUploadAC, updateLocalTusUploadAC, updatePendingUploadAC } from './resumable-uploads-slice';
import { getUploadState } from './upload-selector';
import { abortVideoUploadAC, enqueueVideoUploadAC, fileUploadAC, IFileUploadInput, ITusResumable, pauseVideoUploadAC, resumeExistingTusUploadAC, tusUploadProgressAC, uploadVideoAC } from './upload-slice';

const outOfBoxSagas = allVideoUploadSagas({
  *createVideoApi(
    { publicity, ...input }: CreateVideoAPIParams,
    contents: File,
  ): SagaIterator<CreateVideoAPIResult<VideoFragment>> {
    const result: ResultFromAsyncActionCreator<typeof createUploadedVideo2AC> = yield callAsync(
      createUploadedVideo2AC,
      {
        ...input,
        publicity: publicity && VideoPublicity[publicity],
      },
    );

    yield put(addLocalTusUploadAC({
      ...result,
      uuid: uuidv4(),
      teamId: input.teamId,
      size: input.contentLength,
      publicity: publicity && VideoPublicity[publicity],
      file: contents,
    }));

    return result;
  },
  *getUploadById(id: string): SagaIterator<IUpload> {
    return ((yield select(getUploadState)) as ReturnType<typeof getUploadState>).startedUploads[id];
  },
  *getCurrentUpload(): SagaIterator<UploadingVideoState<UploadVideoInput> | undefined> {
    return ((yield select(getUploadState)) as ReturnType<typeof getUploadState>).currentUpload;
  },
  *getUploadQueue(): SagaIterator<Array<VideoUploadOptions<UploadVideoInput>>> {
    return ((yield select(getUploadState)) as ReturnType<typeof getUploadState>).uploadQueue;
  },
  getDefaultDest() {
    return undefined;
  },
  getVideoFileContent: (video) => video.file,
  getVideoFileMetadata: ({ file }) => file,
  *getIsQueueEmpty(): SagaIterator<boolean> {
    return !((yield select(getUploadState)) as ReturnType<typeof getUploadState>).uploadQueue.length;
  },
  *getStartedUploads(): SagaIterator<Record<string, IUpload & { videoUuid: string }>> {
    return ((yield select(getUploadState)) as ReturnType<typeof getUploadState>).startedUploads;
  },
});

function* finishRemainingHashes(
  file: File,
  hashes: string[] = [],
): SagaIterator {
  const { payload: { file: _, ...upload } }: Action<LocalTusUpload & { file: File }> = yield take(
    (action: Action<any>) => addLocalTusUploadAC.match(action) && action.payload.file === file,
  );

  yield raceUploadVideoSuccess(
    upload.video.id,
    call(function*() {
      const chunks = blobNumChunks(file, hashChunkSize);
      for (let i = 0; i < chunks; i++) {
        if (hashes.length > i) {
          continue;
        }

        yield put(updateLocalTusUploadAC({ ...upload, hashes: Array.from(hashes) }));

        hashes.push(yield call(hashBlob, blobChunk(file, hashChunkSize, i)));
      }

      yield put(updateLocalTusUploadAC({ ...upload, hashes }));
    }),
  );
}

function* findResumableUpload(
  teamId: ID | undefined,
  file: File,
  publicity: VideoPublicity | null | undefined,
): SagaIterator<LocalTusUpload | void> {
  const hashes: string[] = [];
  if (teamId) {
    const resumableUploadsDict: Dictionary<LocalTusUpload[]> | undefined = yield select(getResumableUploadsDict);
    if (resumableUploadsDict) {
      const teamUploads = resumableUploadsDict[teamId];
      if (teamUploads) {
        const chunks = blobNumChunks(file, hashChunkSize);

        const candidates: Array<{ score: number; upload: LocalTusUpload }> = [];

      outer:
        for (const u of teamUploads) {
          if (!u.hashes || u.size !== file.size || u.video.name !== file.name || u.publicity !== publicity) {
            continue;
          }

          for (let i = 0; i < chunks; i++) {
            if (u.hashes.length <= i) {
              if (u.hashes.length > 0) {
                // save uploads with some missing, but otherwise matching hashes
                candidates.push({ score: u.hashes.length, upload: u });
              }

              continue outer;
            }

            if (hashes.length <= i) {
              hashes.push(yield call(hashBlob, blobChunk(file, hashChunkSize, i)));
            }

            if (u.hashes[i] !== hashes[i]) {
              continue outer;
            }
          }

          // all hashes matches with file
          return u;
        }

        if (candidates.length) {
          // return candidate with highest score
          return candidates.sort(({ score: a }, { score: b }) => a - b)[0].upload;
        }
      }
    }
  }

  yield spawn(finishRemainingHashes, file, hashes);
}

enum ETusUploadStatus {
  QUEUED_OR_STARTED,
  RESUMABLE,
}

function* tusUploadStatus(
  bypass: boolean | undefined,
  state: ReturnType<typeof getUploadState>,
  teamId: ID,
  file: File,
  newPublicity: VideoPublicity | null | undefined,
): SagaIterator<{ status: ETusUploadStatus, tusUpload: LocalTusUpload } | undefined> {
  if (!bypass) {
    const tusUpload: SagaReturnType<typeof findResumableUpload> = yield call(
      findResumableUpload,
      teamId,
      file,
      newPublicity,
    );

    if (tusUpload) {
      const { uploadUrl, video, publicity, uuid } = tusUpload;
      if (
        video.id in state.startedUploads ||
        state.uploadQueue.some(({ video }) => video.uuid === uuid)
      ) {
        return {
          status: ETusUploadStatus.QUEUED_OR_STARTED,
          tusUpload,
        };
      }

      if (
        uploadUrl && video &&
        file.name === video.name &&
        file.size === video.filesize &&
        newPublicity === publicity
      ) {
        return {
          status: ETusUploadStatus.RESUMABLE,
          tusUpload,
        };
      }
    }
  }

  return;
}

function* fileUploadWorker(
  { files, teamId, directoryId, bypass }: IFileUploadInput,
): SagaIterator<ITusResumable> {
  if (!files.length) {
    return {
      inProgress: [],
      resumable: [],
    };
  }

  if (!teamId || !directoryId) {
    throw new Error('missing team id or directory id for upload');
  }

  const state: ReturnType<typeof getUploadState> = yield select(getUploadState);

  const inProgress: IResumableUpload[] = [];
  const resumable: IResumableUpload[] = [];

  for (const { file, publicity } of files) {
    const status: SagaReturnType<typeof tusUploadStatus> = yield call(
      tusUploadStatus,
      bypass,
      state,
      teamId,
      file,
      publicity,
    );

    if (status?.status === ETusUploadStatus.QUEUED_OR_STARTED) {
      inProgress.push(makeResumableUpload(status.tusUpload, file));
    } else if (status?.status === ETusUploadStatus.RESUMABLE) {
      resumable.push(makeResumableUpload(status.tusUpload, file));
    } else {
      yield callAsync(enqueueVideoUploadAC, {
        video: {
          uuid: uuidv4(),
          file,
        },
        publicity: publicity ? VideoPublicity[publicity] : undefined,
        dest: {
          teamId,
          directoryId,
        },
      });
    }
  }

  return {
    inProgress,
    resumable,
  };
}

function* watchResumeExistingTusUploadAC(): SagaIterator {
  yield takeEvery(resumeExistingTusUploadAC, function* ({
    payload: { uuid, file, uploadUrl, publicity, video },
  }) {
    yield put(enqueueVideoUploadAC.started({
      video: {
        uuid,
        file,
      },
      publicity: publicity ? VideoPublicity[publicity] : undefined,
      dest: {
        teamId: (video.owner as TeamFragment).id,
        directoryId: video.directory!.id,
      },
      resumeInfo: {
        videoId: video.id,
        uploadUrl,
      },
    }));
  });
}

function* compareChunkHashes(
  blob: Blob,
  chunkSize: number,
  upload: LocalTusUpload,
): SagaIterator<boolean> {
  if (blob.size !== upload.size) {
    return false;
  }

  const chunks = blobNumChunks(blob, chunkSize);
  yield put(addPendingUploadAC({ id: upload.video.id, total: chunks }));

  const hashes = upload.hashes || [];
  for (let i = 0; i < chunks; i++) {
    if (hashes.length <= i) {
      // assume missing chunk hashes match if at least one matched
      return i > 0;
    }

    if (hashes[i] !== (yield call(hashBlob, blobChunk(blob, chunkSize, i)))) {
      return false;
    }

    yield put(updatePendingUploadAC({ id: upload.video.id, current: i + 1 }));
  }

  return true;
}

function* tryResumeTusUploadWorker({ file, upload }: ITryResumeTusUpload): SagaIterator {
  const match = yield call(compareChunkHashes, file, hashChunkSize, upload);
  if (match) {
    yield spawn(finishRemainingHashes, file, upload.hashes);
    yield put(resumeExistingTusUploadAC(makeResumableUpload(upload, file)));
  }

  yield put(removePendingUploadAC({ id: upload.video.id }));

  return match;
}

function makeUploadVideoMatcher(
  ac: typeof uploadVideoAC.done | typeof uploadVideoAC.failed,
  videoId: string,
): ActionPattern {
  return (a: AnyAction) => ac.match(a) && a.payload.params.upload.id === videoId;
}

function raceUploadVideoSuccess<T>(videoId: string, ...effects: T[]): RaceEffect<T | TakeEffect> {
  return race([
    ...effects,
    take(makeUploadVideoMatcher(uploadVideoAC.done, videoId)),
  ]);
}

function raceUploadVideoEnd<T>(videoId: string, ...effects: T[]): RaceEffect<T | TakeEffect> {
  return raceUploadVideoSuccess<T | TakeEffect>(
    videoId,
    ...effects,
    take((action: AnyAction) => pauseVideoUploadAC.match(action) && action.payload.id === videoId),
    take(makeUploadVideoMatcher(uploadVideoAC.failed, videoId)),
  );
}

function* watchUploadVideoStartedAC() {
  yield takeEvery(uploadVideoAC.started, function* ({ payload: { upload } }): SagaIterator {
    const task: Task = yield throttleCall(
      2000,
      (a: AnyAction) => tusUploadProgressAC.match(a) && a.payload.id === upload.id,
      function* ({ payload }: Action<Required<TusUploadParams>>): SagaIterator {
        try {
          yield callAsync(setVideoMetadataAC, {
            videoId: payload.id,
            metadata: [{
              name: '_uploadprogress',
              value: payload.progress.toString(),
            }],
          });
        } catch {}
      },
    );

    yield raceUploadVideoEnd(upload.id);

    yield cancel(task);
  });
}

function* updateLocalTusUploadsACWorker(action: Action<void>): SagaIterator {
  const resumableUploadsDict: ReturnType<typeof getResumableUploadsDict> = yield select(getResumableUploadsDict);
  if (!resumableUploadsDict) {
    return;
  }

  setLocalStorage('tusUploads', JSON.stringify(Object.values(resumableUploadsDict).flat()));
}

function* watchAddLocalTusUploadAC() {
  yield takeEvery(addLocalTusUploadAC, function* () {
    yield put(saveLocalTusUploadsToLocalStorageAC());
  });
}

function* watchUpdateLocalTusUploadAC() {
  yield takeEvery(updateLocalTusUploadAC, function* () {
    yield put(saveLocalTusUploadsToLocalStorageAC());
  });
}

function* watchRemoveLocalTusUploadAC() {
  yield takeEvery(removeLocalTusUploadAC, function* () {
    yield put(saveLocalTusUploadsToLocalStorageAC());
  });
}

function* watchSaveLocalTusUploadsToLocalStorageAC() {
  yield takeEvery(saveLocalTusUploadsToLocalStorageAC, function* (action: Action<void>) {
    yield raceLogout(call(updateLocalTusUploadsACWorker, action));
  });
}

function* watchUploadVideoDone() {
  yield takeEvery(uploadVideoAC.done, function* ({ payload: { params } }) {
    yield put(removeLocalTusUploadAC({ videoId: params.upload.id }));
    yield put(subscribeToVideoUpdatesAC({ id: params.upload.id }));
  });
}

function* watchVideoDeleted() {
  yield takeEvery([
    deleteVideoAC.type,
    deleteVideoAC.started.type,
  ], function* ({ payload }: Action<DeleteVideoInput>) {
    yield put(removeLocalTusUploadAC({ videoId: payload.id }));
    yield put(abortVideoUploadAC.started({ id: payload.id }));
  });
}

export default function () {
  return all([
    outOfBoxSagas,
    watchAsync(initLocalTusUploadsAC, function (input) {
      return JSON.parse(getLocalStorage('tusUploads', '[]'));
    }),
    watchAsync(fileUploadAC, fileUploadWorker),
    watchAsync(tryResumeTusUploadAC, tryResumeTusUploadWorker),
    watchAsyncMutation(createUploadedVideo2AC, CreateUploadedVideo2_Mutation, ['createUploadedVideo2']),
    watchResumeExistingTusUploadAC(),
    watchUploadVideoStartedAC(),
    watchAddLocalTusUploadAC(),
    watchUpdateLocalTusUploadAC(),
    watchRemoveLocalTusUploadAC(),
    watchSaveLocalTusUploadsToLocalStorageAC(),
    watchUploadVideoDone(),
    watchVideoDeleted(),
  ]);
}
