import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Badge from '@material-ui/core/Badge';
import ButtonBase from '@material-ui/core/ButtonBase';
import { createStyles, fade, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { MAX_NOTIFICATIONS } from 'components/settings/notifications/constants';
import { makeGetUnreadTeamNotificationsByTeamId } from 'components/settings/notifications/notifications-selector';
import TeamAvatar, { TeamAvatarProps } from 'material/team-avatar/TeamAvatar';
import UndraggableAvatar from 'material/undraggable-avatar/UndraggableAvatar';
import React, { useCallback } from 'react';
import { ID, TeamInterface } from 'types/pigeon';

interface TeamAvatarButtonOwnProps {
  className?: string;
  team: TeamInterface;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>, teamId: ID) => void;
  isDragging?: boolean;
}

type TeamAvatarButtonProps = TeamAvatarButtonOwnProps & Pick<TeamAvatarProps, 'size'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  badgeOverLimit: {
    right: theme.spacing(0.5),
  },
  placeholder: {
    backgroundColor: fade(theme.palette.background.default, 0.5),
  },
}), {name: 'TeamAvatarButton'});

const TeamAvatarButton = React.forwardRef<HTMLButtonElement, TeamAvatarButtonProps>(function TeamAvatarButton(
  props: TeamAvatarButtonProps,
  ref,
) {
  const classes = useStyles(props);
  const { className, team, onClick, size, isDragging, ...rest } = props;

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(e, team.id);
  }, [onClick, team]);

  const numOfNotifications = useCreateSelector(makeGetUnreadTeamNotificationsByTeamId, team.id);

  return (
    <React.Fragment>
      <ButtonBase
      className={classNames(classes.root, className)}
      disableRipple={isDragging}
      id={team.id}
      onClick={handleClick}
      ref={ref}
      {...rest}
      >
        {isDragging ? (
          <UndraggableAvatar
          size='sm'
          className={classes.placeholder}
          />
        ) : (
          <Badge
          classes={{
            badge: numOfNotifications && numOfNotifications > 99 ? classes.badgeOverLimit : undefined,
          }}
          badgeContent={numOfNotifications}
          color='error'
          max={MAX_NOTIFICATIONS}
          >
            <TeamAvatar team={team} size={size} />
          </Badge>
        )}
      </ButtonBase>
    </React.Fragment>
  );
});
TeamAvatarButton.displayName = 'TeamAvatarButton';

export default React.memo(TeamAvatarButton);
