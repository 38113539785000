import { GetTeamCustomRoles_Query } from 'apollo/queries';
import { watchBidirectionalAC2 } from 'helpers/saga/effects';
import { all } from 'redux-saga/effects';

import { fetchTeamCustomRolesAC } from './dashboard-role-slice';

export default function* dashboardRoleSaga() {
  yield all([
    watchBidirectionalAC2(fetchTeamCustomRolesAC, GetTeamCustomRoles_Query, ['team', 'queryCustomRoles']),
  ]);
}
