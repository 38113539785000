import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';
import { SearchCommentQueryVariables } from 'apollo/queries/types/SearchCommentQuery';
import React from 'react';

import { makeGetCommentSearchRecordByTeamId } from './search-selectors';
import { fetchCommentSearchResultsAC } from './search-slice';

export const useFetchCommentSearchResults = createBidirectionalAutoFetcher({
  actionCreators: fetchCommentSearchResultsAC,
  selectorFactory: makeGetCommentSearchRecordByTeamId,
  createFetchId: ({ teamId }) => teamId,
  pageSize: 120,
});

function CommentSearchFetcher(props: { query: Omit<SearchCommentQueryVariables, 'after' | 'before' | 'limit'> }) {
  useFetchCommentSearchResults(props.query);
  return null;
}

export default React.memo(CommentSearchFetcher);
