import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import AccessControlInjector from 'features/dashboard/access-control/AccessControlInjector';
import { useFetchVideoIfNecessary } from 'features/dashboard/video/useFetchVideoIfNecessary';
import { getLiveSessionState, getLiveSessionVideoId } from 'features/live-session/live-session-selector';
import { VideoHelper } from 'features/video-library/video-helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { SmolVideo } from 'types/pigeon/kmsession';

import { LiveSessionState } from './live-session-slice';
import LiveSessionErrorDialog from './LiveSessionErrorDialog';
import LiveSessionJoinerDialog from './LiveSessionJoinerDialog';
import LiveSessionLoadingScreen from './LiveSessionLoadingScreen';
import LiveSessionTerminatedDialog from './LiveSessionTerminatedDialog';
import LiveSessionViewer from './LiveSessionViewer';
import RemovedFromLiveSessionDialog from './RemovedFromLiveSessionDialog';

interface LiveSessionPageOwnProps {
  className?: string;
}

type LiveSessionPageProps = LiveSessionPageOwnProps;

const Components: Partial<Record<LiveSessionState['type'], React.ComponentType<{
  state?: LiveSessionState;
  video?: SmolVideo | VideoFragment;
}>>> = {
  pending: LiveSessionJoinerDialog,
  error: LiveSessionErrorDialog,
  terminated: LiveSessionTerminatedDialog,
  joined: LiveSessionViewer,
  connecting: LiveSessionLoadingScreen,
  reconnecting: LiveSessionLoadingScreen,
  kicked: RemovedFromLiveSessionDialog,
};

function LiveSessionPage(props: LiveSessionPageProps) {
  const state = useSelector(getLiveSessionState);
  const videoId = useSelector(getLiveSessionVideoId);
  const [video] = useFetchVideoIfNecessary(videoId);

  let Component: (typeof Components)[keyof typeof Components];
  if (state) {
    Component = Components[state.type];
  }

  if (!Component) {
    Component = LiveSessionErrorDialog;
  }

  return (
    <AccessControlInjector
    teamId={video && VideoHelper.getTeamId(video)}
    divisionId={video && VideoHelper.getDivisionId(video)}
    >
      <Component
      state={state}
      video={video}
      />
    </AccessControlInjector>
  );
}

export default React.memo(LiveSessionPage);
