import gql from 'graphql-tag';

export const DeleteMatch_Mutation = gql`
    mutation DeleteMatchMutation(
        $input: DeleteMatchInput!
    ) {
        deleteMatch(
            input: $input
        ) {
            matchId
        }
    }
`;
