import { AsyncButton, FlexSpacer, RichTextEditor } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import { addCommentReplyAC } from 'actions/comment-actions';
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';
import { VirtualizedCommentsContext } from 'features/video-replay/comment-panel/virtualized-comments/VirtualizedCommentsContext';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { CommentFragment } from '../../../../../apollo/fragments/types/CommentFragment';
import { GetMembersQuery_queryMembers_edges_user } from '../../../../../apollo/queries/types/GetMembersQuery';
import { useKeybindings } from '../../../../../hooks/useKeybindings';
import { useMentionAutoCompleteMatcher } from '../../../../../hooks/useMentionAutoCompleteMatcher';
import { buildKeystring } from '../../../../../keybindings';
import { decorator } from '../../../../../subcomponents/text-editor-with-tools/strategies/strategies';

export interface ICommentReplyOwnProps {
  videoId: string;
  comment: CommentFragment;
  handleCancelOnClick: VoidFunction;
  members: GetMembersQuery_queryMembers_edges_user[];
}

type Props = ICommentReplyOwnProps;

const createEditorState = (comment: CommentFragment) => {
  if (comment.__typename === 'CommentReply') {
    const contentState = ContentState.createFromText(`@${comment.user.alias}`);
    const initialEditorState = EditorState.createWithContent(contentState, decorator);
    const currentSelectionState = initialEditorState.getSelection();
    const selection = currentSelectionState.merge({
      anchorOffset: 0,
      focusOffset: contentState.getPlainText().length,
    }) as Draft.SelectionState;
    const newSelection = currentSelectionState.merge({
      anchorOffset: contentState.getPlainText().length,
      focusOffset: contentState.getPlainText().length,
    }) as Draft.SelectionState;

    const contentStateWithEntity = contentState.createEntity(
      'MENTION',
      'IMMUTABLE',
      comment.user,
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newContentState = Modifier.replaceText(
      contentStateWithEntity,
      selection,
      `@${comment.user.alias}`,
      undefined,
      entityKey,
    );

    const newContentStateAfterReplace = Modifier.insertText(
      newContentState,
      newSelection,
      ' ',
    );

    const editorStateWithEntity = EditorState.push(
      initialEditorState,
      newContentStateAfterReplace,
      'apply-entity',
    );

    const newEditorState = EditorState.forceSelection(
      editorStateWithEntity,
      newContentStateAfterReplace.getSelectionAfter(),
    );
    return newEditorState;
  }
  return EditorState.createEmpty(decorator);
};

function CommentReply(props: Props) {
  const { comment, handleCancelOnClick, members, videoId } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useStrictTranslation(['common', 'video']);
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const { resizeComment } = useContext(VirtualizedCommentsContext);

  const [editorState, setEditorState] = useState(createEditorState(comment));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    resizeComment(comment);
  }, [resizeComment, comment, editorState]);


  const handleFormOnSubmit = useCallback(async () => {
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    if (!plainText || loading) { return; }

    const commentJson = JSON.stringify(convertToRaw(contentState));
    setLoading(true);
    try {
      await promiseSagaDispatch(addCommentReplyAC, {
        videoId,
        commentId: comment.__typename === 'CommentReply'
          ? comment.parent
          : comment.id,
        message: commentJson,
      });
      setLoading(false);
      enqueueSnackbar(t('video:replay.replyaddsuccess'), {variant: 'success'});
      handleCancelOnClick();
    } catch (error) {
      enqueueSnackbar(t('video:replay.replyaddfail'), {variant: 'error'});
      setLoading(false);
    }
  }, [
    loading,
    setLoading,
    editorState,
    videoId,
    comment,
    handleCancelOnClick,
    promiseSagaDispatch,
    t,
    enqueueSnackbar,
  ]);

  const mentionAutoCompleteMatcher = useMentionAutoCompleteMatcher(members);
  const autoCompleteMatchers = useMemo(() => {
    return [
      mentionAutoCompleteMatcher,
    ];
  }, [mentionAutoCompleteMatcher]);

  const { addContext, removeContext } = useKeybindings('editor.text.focus');

  // onFocus does not get called when it first renders FeelsBadMan
  useEffect(() => {
    addContext();
    return () => removeContext();
  }, [addContext, removeContext]);

  const customKeyBindFn = useCallback((e: React.KeyboardEvent) => {
    switch (buildKeystring(e.nativeEvent)) {
      case 'ctrl+enter':
        return 'submit_comment';
    }
    return null;
  }, []);

  const customKeyCommands = useMemo(() => {
    return new Map([
      ['submit_comment', handleFormOnSubmit],
    ]);
  }, [handleFormOnSubmit]);

  return (
    <FlexSpacer orientation='vertical'>
      <RichTextEditor
      editorState={editorState}
      onStateChange={setEditorState}
      autoFocus={true}
      compactTools={true}
      onFocus={addContext}
      onBlur={removeContext}
      autoCompleteMatchers={autoCompleteMatchers}
      customKeyBindFn={customKeyBindFn}
      customKeyCommands={customKeyCommands}
      />
      <FlexSpacer flexJustifyContent='flex-end'>
        <Button
        variant='outlined'
        onClick={handleCancelOnClick}
        size='small'
        >
          {t('common:cancel')}
        </Button>
        <AsyncButton
        variant='contained'
        color='primary'
        size='small'
        loading={loading}
        disabled={loading}
        onClick={handleFormOnSubmit}
        >
          {t('video:replay.send')}
        </AsyncButton>
      </FlexSpacer>
    </FlexSpacer>
  );
}

export default React.memo(CommentReply);
