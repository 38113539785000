import { GetAvailableTeamResourceQuery_team_resources } from 'apollo/queries/types/GetAvailableTeamResourceQuery';
import { isExistent } from 'helpers';
import { compareTeamNameAscending } from 'helpers/comparators';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

const getTeamDict = (state: RootReducer) => state.dashboardTeam.teamDict;
const getTeamFetchStatusById = (state: RootReducer, teamId: ID) => state.dashboardTeam.teamStatus[teamId];

export const getTeamById = (state: RootReducer, teamId: ID) => state.dashboardTeam.teamDict[teamId];

export const getTeamRecords = (state: RootReducer) => state.dashboardTeam.teamRecords;

export const getTeamsFetchFetching = (state: RootReducer) => getTeamRecords(state).forward.fetching;

export const getTeamOrder = (state: RootReducer) => state.dashboardTeam.teamOrder;

export const getInvisibleTeamId = createSelector(
  [getTeamRecords, getTeamDict],
  (records, dict) => {
    return records.ids.find(id => dict[id]?.name === '_my_public_uploads');
  },
);

export const getTeams = createSelector(
  [getTeamRecords, getTeamDict],
  (records, dict) => {
    return records.ids.map(id => dict[id]).filter(isExistent);
  },
);

export const getSortedTeams = createSelector(
  [getTeams, getTeamOrder, getTeamDict, getInvisibleTeamId],
  (teams, teamOrder, dict, invisibleTeamId) => {
    if (teamOrder?.length) {
      return teamOrder.filter(id => id !== invisibleTeamId).map(id => dict[id]).filter(isExistent);
    }

    return teams.filter(team => team.id !== invisibleTeamId).slice(0).sort(compareTeamNameAscending);
  },
);

export const makeGetTeamById = () => createSelector(
  [getTeamById],
  (team) => team,
);

export const makeGetTeamFetchStatusById = () => createSelector(
  [getTeamFetchStatusById, getTeamRecords],
  (status, records): FetchStatus | undefined => {
    if (records?.forward?.fetching || records?.backward?.fetching) {
      return {
        done: false,
        fetching: true,
      };
    }

    return status;
  },
);

const getAvailableTeamResourceById = (
  state: RootReducer,
  teamId: ID,
): [GetAvailableTeamResourceQuery_team_resources[] | undefined, boolean] => [
  state.dashboardTeam.teamResourceDict[teamId],
  teamId in state.dashboardTeam.teamResourceDict,
];

export const makeGetAvailableTeamResourceById = () => createSelector(
  [getAvailableTeamResourceById],
  (availableTeamResource) => availableTeamResource,
);
