import gql from 'graphql-tag';

export const GiftTeamCredits_Mutation = gql`
    mutation GiftTeamCreditsMutation(
        $input: GiftTeamCreditsInput!
    ) {
        giftTeamCredits(
            input: $input
        ) {
            teamId
        }
    }
`;
