import {
  NotificationFragment,
  NotificationFragment_CommentNotification,
  NotificationFragment_MemberNotification,
  NotificationFragment_VideoNotification,
} from 'apollo/fragments/types/NotificationFragment';
import { UserNotificationSettingFragment } from 'apollo/fragments/types/UserNotificationSettingFragment';
import fromPairs from 'lodash/fromPairs';
import groupBy from 'lodash/groupBy';
import { MuteLevel, NotificationType, UpdateUserNotificationSettingsInput } from 'types/graphql';

import { ENotificationState } from '../notifications/constants';

export type NotificationInputDict = Partial<Dictionary<UpdateUserNotificationSettingsInput>>;

const initialDict: NotificationInputDict = fromPairs(Object.keys(NotificationType).map(n =>
  [
    NotificationType[n],
    {
      level: MuteLevel.NONE,
      notificationType: NotificationType[n],
      override: false,
    },
  ],
));

function createInitialNotificationSettingsInput(
  userSettings: UserNotificationSettingFragment[],
): NotificationInputDict {
  return {
    ...initialDict,
    ...fromPairs(userSettings.map(n => [
      n.notificationType,
      {
      level: n.level,
      notificationType: n.notificationType,
      override: n.override,
      },
    ])),
  };
}

function getNewState(notifications: NotificationFragment[]) {
  const [firstNotification] = notifications;
  switch (firstNotification.__typename) {
    case 'CommentNotification':
      return ENotificationState.VIEWED;
    case 'VideoNotification':
      if (notifications.length > 1) {
        return ENotificationState.VIEWED;
      }
      return ENotificationState.READ;
    case 'MemberNotification':
    default:
      return ENotificationState.READ;
  }
}

function groupNotifications<T extends NotificationFragment>(
  notifications: NotificationFragment[],
  filterFn: (n: NotificationFragment) => n is T,
  keyFn: (n: T) => string,
) {
  return Object.values(groupBy(notifications.filter(filterFn), keyFn));
}

function isCommentNotification(
  notification: NotificationFragment,
): notification is NotificationFragment_CommentNotification {
  return notification.__typename === 'CommentNotification';
}

function isMemberNotification(
  notification: NotificationFragment,
): notification is NotificationFragment_MemberNotification {
  return notification.__typename === 'MemberNotification';
}

function isVideoNotification(
  notification: NotificationFragment,
): notification is NotificationFragment_VideoNotification {
  return notification.__typename === 'VideoNotification';
}

export const NotificationsHelper = {
  createInitialNotificationSettingsInput,
  groupNotifications,
  isCommentNotification,
  isMemberNotification,
  isVideoNotification,
  getNewState,
};
