import gql from 'graphql-tag';

export const StartSession_Mutation = gql`
    mutation StartSessionMutation(
        $input: StartSessionInput!
    ) {
        startSession(
            input: $input
        ) {
            token
        }
    }
`;
