import { Theme } from '@insights-gaming/theme';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import Comment from 'components/video/video-replay/video-comment/Comment';
import VideoCommentContent from 'components/video/video-replay/video-comment/VideoCommentContent';
import { VideoCommentContext } from 'features/video-replay/VideoCommentContext';
import React, { useCallback, useContext, useMemo } from 'react';
import { Video } from 'types/pigeon';

import { SliderMarker } from '../slider-4/Slider4';
import SeekBarWithCustomMarkers from './SeekBarWithCustomMarkers';

interface SeekBarOwnProps {
  video: Video;
  comments?: CommentFragment_VideoComment[];
}

type Props = SeekBarOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  markerIndicator: {
    flex: 1,
    overflow: 'hidden',
  },
  commentSenderRoot: {
    padding: theme.spacing(1),
    '&:last-child': {
      padding: theme.spacing(1),
    },
  },
  comment: {
    overflow: 'hidden',
    display: 'box',
    lineClamp: 3,
    boxOrient: 'vertical',
  },
}), {name: 'SeekBar'});

const thumbnailWidth = 160;

function SeekBar(props: Props) {
  const { comments, video } = props;

  const classes = useStyles(props);

  const { setCurrentComment } = useContext(VideoCommentContext);

  const handleMarkerClick = useCallback((__, value: SliderMarker) => {
    const comment = comments && comments.find(c => c.id === value.id);

    if (comment) {
      setCurrentComment(comment);
    }
  }, [comments, setCurrentComment]);

  const markers: SliderMarker[] | undefined = useMemo(() => {
    if (!comments) { return undefined; }
    return comments.filter(c => c.message).map(c => {
      return {
        id: c.id,
        time: c.time,
        endTime: c.timeEnd,
      };
    });
  }, [comments]);

  const renderMarkerIndicator = useCallback(({id}: SliderMarker) => {
    const comment = comments && comments.find(c => c.id === id);
    return comment ? (
      <Paper className={classes.markerIndicator}>
        <VideoCommentContent classes={{root: classes.commentSenderRoot}} comment={comment}>
          <div className={classes.comment}>
            <Comment text={comment.message || ''} />
          </div>
        </VideoCommentContent>
      </Paper>
    ) : null;
  }, [classes.comment, classes.commentSenderRoot, classes.markerIndicator, comments]);

  return (
    <SeekBarWithCustomMarkers
    video={video}
    renderMarkerIndicator={renderMarkerIndicator}
    markers={markers}
    onMarkerClick={handleMarkerClick}
    />
  );
};

export default React.memo(SeekBar);
