import { fetchOverwatchAdvancedQueryAsyncAC } from 'features/overwatch-advanced-query/overwatch-advanced-query-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useCallback } from 'react';

import { GetOverwatchAdvancedQueryQueryVariables } from '../../apollo/queries/types/GetOverwatchAdvancedQueryQuery';

export function useCreateOverwatchAdvancedQuery(): (
  param: GetOverwatchAdvancedQueryQueryVariables,
) => void {
  const promiseSagaDispatch = usePromiseSagaDispatch();
  return useCallback(
    (params: GetOverwatchAdvancedQueryQueryVariables) => promiseSagaDispatch(
      fetchOverwatchAdvancedQueryAsyncAC,
      params,
    ),
    [promiseSagaDispatch],
  );
}
