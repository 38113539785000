import { FlexSpacer } from '@insights-gaming/material-components';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import VideoPlayerDialogContent from 'material/dialogs/video-player-dialog-content/VideoPlayerDialogContent';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { RosterPlayersFragment } from '../../../../../apollo/fragments/types/RosterPlayersFragment';
import {
  GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFights_teamFightData,
  GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFights_teamFightData_blueUlts,
  GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFights_teamFightData_redUlts,
} from '../../../../../apollo/queries/types/GetOverwatchAdvancedQueryQuery';
import { ultimateFromHeroName } from '../../../../../helpers';
import { formatDuration } from '../../../../../helpers/formatters';
import { useDialogState } from '../../../../../hooks/useDialogState';
import { useIconFromAbilityName } from '../../../../../hooks/useIconFromAbilityName';
import { OwstatsF, OwstatsNS } from '../../../../../locales/en/owstats';
import { ID } from '../../../../../types/pigeon';
import HeroIcon from './HeroIcon';

type TeamfightData = GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFights_teamFightData;
type Ult = GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFights_teamFightData_blueUlts
  | GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFights_teamFightData_redUlts;

export interface IOverwatchAdvancedQueryTeamfightBreakdownTableRowOwnProps {
  teamfightData: TeamfightData;
  teamfightNum: number;
  primaryRoster: RosterPlayersFragment;
  rosters: string[];
  videoId: ID;
}

type Props = IOverwatchAdvancedQueryTeamfightBreakdownTableRowOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  blue: {
    border: `1px solid ${theme.palette.overwatch.blueTeam}`,
  },
  red: {
    border: `1px solid ${theme.palette.overwatch.redTeam}`,
  },
  teamfightTime: {
    whiteSpace: 'pre',
  },
  ultimates: {
    display: 'flex',
    width: 'fit-content',
    margin: 'auto',
  },
  ultimate: {
    fill: theme.palette.text.primary,
  },
}), {name: 'OverwatchAdva2cedQueryTeamfightBreakdownTableRow'});

function OverwatchAdvancedQueryTeamfightBreakdownTableRow(props: Props) {
  const { teamfightData, teamfightNum, primaryRoster, rosters, videoId } = props;
  const classes = useStyles(props);
  const { t } = useTranslation(OwstatsNS);
  const getIconFromAbilityName = useIconFromAbilityName;

  const [isVideoPlayerDialogOpen, openVideoPlayerDialog, closeVideoPlayerDialog] = useDialogState();

  const renderUlts = useCallback((ult: Ult, i: number) => {
    const ability = ultimateFromHeroName(ult.hero);
    const Ultimate = getIconFromAbilityName(ability);
    return (
      <Ultimate key={ability + i} width={30} className={classes.ultimate}/>
    );
  }, [classes, getIconFromAbilityName]);

  const renderRosterName = useCallback((rosterId: string) => {
    if (primaryRoster.id !== rosterId) { return t(OwstatsF('queries.summary.enemyteam')); }
    return primaryRoster.name || t(OwstatsF('queries.summary.primaryroster'));
  }, [primaryRoster, t]);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell rowSpan={2} align='center'>{teamfightNum}</TableCell>
        <TableCell rowSpan={2} align='center'>
          <Button
          variant='outlined'
          className={classes.teamfightTime}
          onClick={openVideoPlayerDialog}
          >
            {formatDuration(teamfightData.start)} - {formatDuration(teamfightData.end)}
          </Button>
        </TableCell>
        <TableCell align='center'>
          {renderRosterName(rosters[0])}
        </TableCell>
        <TableCell align='center'>
          {teamfightData.winner === 'blue'
            ? t(OwstatsF('teamfightbreakdown.win'))
            : t(OwstatsF('teamfightbreakdown.lose'))}
        </TableCell>
        <TableCell align='center'>
          <FlexSpacer flexJustifyContent='center'>
            {teamfightData.blueHeroes.map((hero: string, i: number) => (
              <HeroIcon hero={hero} color='blue' key={hero + i} />
            ))}
          </FlexSpacer>
        </TableCell>
        <TableCell align='center'>{teamfightData.blueKills}</TableCell>
        <TableCell rowSpan={2}>
          {teamfightData.firstUlt?.hero &&
            <HeroIcon
            hero={teamfightData.firstUlt.hero}
            color={teamfightData.firstUlt.color}
            />
          }
        </TableCell>
        <TableCell rowSpan={2}>
          {teamfightData.firstKill?.hero &&
            <HeroIcon
            hero={teamfightData.firstKill.hero}
            color={teamfightData.firstKill.color}
            />
          }
        </TableCell>
        <TableCell rowSpan={2}>
          {teamfightData.firstDeath?.hero &&
            <HeroIcon
            hero={teamfightData.firstDeath.hero}
            color={teamfightData.firstDeath.color}
            />
          }
        </TableCell>
        <TableCell>
          {teamfightData.blueUlts.length > 0 && (
            <Box className={classNames(classes.ultimates, classes.blue)}>
              {teamfightData.blueUlts.map(renderUlts)}
            </Box>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align='center'>{renderRosterName(rosters[1])}</TableCell>
        <TableCell align='center'>
          {teamfightData.winner === 'red'
            ? t(OwstatsF('teamfightbreakdown.win'))
            : t(OwstatsF('teamfightbreakdown.lose'))}
        </TableCell>
        <TableCell align='center'>
          <FlexSpacer flexJustifyContent='center'>
            {teamfightData.redHeroes.map((hero: string, i: number) => (
              <HeroIcon hero={hero} color='red' key={hero + i} />
            ))}
          </FlexSpacer>
        </TableCell>
        <TableCell align='center'>{teamfightData.redKills}</TableCell>
        <TableCell>
          {teamfightData.redUlts.length > 0 && (
            <Box className={classNames(classes.ultimates, classes.red)}>
              {teamfightData.redUlts.map(renderUlts)}
            </Box>
          )}
        </TableCell>
      </TableRow>
      <Dialog
      open={isVideoPlayerDialogOpen}
      onClose={closeVideoPlayerDialog}
      fullWidth={true}
      maxWidth='xl'
      >
        <VideoPlayerDialogContent
        startTime={teamfightData.start}
        endTime={teamfightData.end}
        videoId={videoId}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default React.memo(OverwatchAdvancedQueryTeamfightBreakdownTableRow);
