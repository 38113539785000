import { DrawingTool } from '@insights-gaming/drawing-canvas';
import React from 'react';
import { DrawingState } from 'subcomponents/video-player-3/drawing-tools-2/useDrawingState';

import { CommentFragment_VideoComment } from '../../apollo/fragments/types/CommentFragment';

interface DrawingToolContextState {
  drawingState: DrawingState;
  viewedComment?: {comment: CommentFragment_VideoComment};
  setViewedComment: (wrapper: {comment: CommentFragment_VideoComment} | undefined) => void;
  resetCanvas: VoidFunction;
  resetTool: VoidFunction;
}

const noopFactory = () => () => {};

const drawingState: DrawingState = {
  canRedo: false,
  canUndo: false,
  hasObjects: false,
  enabled: false,
  ready: false,

  tool: DrawingTool.NONE,
  setTool: noopFactory(),

  color: '',
  setColor: noopFactory(),

  size: 0,
  setSize: noopFactory(),

  undo: noopFactory(),
  redo: noopFactory(),
  clear: noopFactory(),
  reset: noopFactory(),

  deselectAll: noopFactory(),
  disable: noopFactory(),

  addSticker: noopFactory(),
  setImageUnderlay: noopFactory(),
  renderPing: noopFactory(),

  silentlyAddObjects: noopFactory(),
  silentlyModifyObjects: noopFactory(),
  silentlyRemoveObjects: noopFactory(),
  silentlyClearCanvas: noopFactory(),

  loadJSON: noopFactory(),
  serializedCanvas: () => Promise.reject(),
};

const DrawingToolContext = React.createContext<DrawingToolContextState>({
  viewedComment: undefined,
  drawingState,
  setViewedComment: noopFactory(),
  resetCanvas: noopFactory(),
  resetTool: noopFactory(),
});

const { Provider: DrawingToolProvider, Consumer: DrawingToolConsumer } = DrawingToolContext;

export {
  DrawingToolConsumer,
  DrawingToolContext,
  DrawingToolContextState,
  DrawingToolProvider,
};
