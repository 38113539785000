import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { SavedCardFragment } from 'apollo/fragments/types/SavedCardFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { TeamSubscriptionFragment } from 'apollo/fragments/types/TeamSubscriptionFragment';
import classNames from 'classnames';
import { createTeamSubscriptionAC, updateTeamSubscriptionAC } from 'features/dashboard/billing/dashboard-billing-slice';
import useAvailableMetrics from 'features/dashboard/billing/useAvailableMetrics';
import useCheckoutCost from 'features/dashboard/billing/useCheckoutCost';
import useProductInputs from 'features/dashboard/billing/useProductInputs';
import useRecurringCost from 'features/dashboard/billing/useRecurringCost';
import useTeamAvailableBundles from 'features/dashboard/billing/useTeamAvailableBundles';
import { mobilePortrait } from 'features/media-queries';
import { numToDollarAmount } from 'helpers/formatters';
import { useDialogState } from 'hooks/useDialogState';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import DeleteButton from 'material/delete-button/DeleteButton';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { BillingInterval, CouponClass, ProductKind } from 'types/graphql';
import { Billing } from 'types/pigeon';

import CardForm from '../CardForm';
import ConfirmDowngradeDialog from '../ConfirmDowngradeDialog/ConfirmDowngradeDialog';
import { CustomCouponCodeContext, useCustomCouponCodeContext } from '../CustomCouponCodeContext';
import FailureDialog from '../FailureDialog/FailureDialog';
import CardDetails from '../PaymentMethod/CardDetails';
import { SubscriptionMutationResult, useSubscriptionMutationResultHandler } from '../useSubscriptionMutationResultHandler';
import BottomSummary from './BottomSummary';
import CheckoutSummary from './CheckoutSummary';

interface PlanDetailsOwnProps {
  className?: string;
  onClose?: VoidFunction; // closes the billing dialog
  onSuccess?: VoidFunction; // closes the billing dialog and open success dialog
  onCancel?: VoidFunction; // closes the cancel subscription dialog and open plan feedback
  team: TeamFragment;
  stepBackward: VoidFunction;
  stepForward: VoidFunction;
  checkoutQuantities: Billing.InputQuantities;
  cancelPlan: boolean;
  currentProduct?: Billing.InputQuantities;
  teamProration?: number;
  teamBalance?: number;
  teamSubscription?: TeamSubscriptionFragment;
  teamCards?: SavedCardFragment[];
  freeTrial?: boolean;
  source?: string;
  customPlanQuantities?: Partial<Record<ProductKind, number>>;
}

type PlanDetailsProps = PlanDetailsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3, 6),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: createRemFromPx(600),
    [mobilePortrait(theme)]: {
      width: '100%',
    },
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: createRemFromPx(100),
   },
  total: {
    fontWeight: 700,
  },
  headerText: {
    fontWeight: 600,
  },
  divider: {
    height: 1,
  },
  changecard: {
    alignSelf: 'flex-start',
  },
  action: {
    marginTop: theme.spacing(3),
  },
  paymentInfo: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(2),
  },
  payButton: {
    width: createRemFromPx(156),
  },
}), {name: 'PlanDetailsContent'});

function isCardDeclinedError(err?: { message?: string }) {
  return err?.message === 'GraphQL error: card declined';
}

function PlanDetails(props: PlanDetailsProps) {
  const classes = useStyles(props);
  const {
    className,
    team,
    stepBackward,
    stepForward,
    cancelPlan,
    checkoutQuantities,
    currentProduct,
    onClose,
    onSuccess,
    onCancel,
    teamProration,
    teamBalance,
    teamSubscription,
    teamCards,
    freeTrial,
    source,
    customPlanQuantities,
  } = props;

  const { t } = useStrictTranslation(['dashboard', 'dialog', 'settings']);
  const wt = t as TFunction;

  const bundles = useTeamAvailableBundles(team.id);
  const { metrics, customPlans } = useAvailableMetrics(bundles);

  const isCustomPlan = useMemo(
    () => customPlanQuantities && Object.values(customPlanQuantities).some((quantity) => quantity > 0),
    [customPlanQuantities],
  );

  const customCouponCodeContextValue = useCustomCouponCodeContext(team.id, checkoutQuantities.productId);

  const {
    coupon,
    appliedCouponCode,
  } = customCouponCodeContextValue;

  const [isDowngradeDialogOpen, openDowngradeDialog, closeDowngradeDialog] = useDialogState();

  const isYearly = useMemo(() => checkoutQuantities.interval === BillingInterval.YEARLY, [checkoutQuantities]);

  const wasYearly = useMemo(() => currentProduct?.interval === BillingInterval.YEARLY, [currentProduct]);

  const [recurringCost] = useRecurringCost(team.id);

  const bundleCost = useCheckoutCost(checkoutQuantities, team.id);

  const checkoutCost = useMemo(() => {
    if (isCustomPlan) {
      return Object.values(customPlans).reduce((total, { plan }) => {
        if (customPlanQuantities?.[plan.kind] && plan.cost) {
          return total + ((customPlanQuantities?.[plan.kind] ?? 1) * plan.cost[checkoutQuantities.interval]);
        }

        return total;
      }, 0);
    }

    return bundleCost;
  }, [bundleCost, checkoutQuantities.interval, customPlanQuantities, customPlans, isCustomPlan]);

  const checkoutPlanTitle = useMemo(() => {
    const plan = metrics.find((metric) => metric.productId === checkoutQuantities.productId);
    if (!plan) {
      return;
    }
    return wt(`dialog:availableplans.${plan.name}`);
  }, [checkoutQuantities.productId, metrics, wt]);

  const teamProduct = useMemo(() => {
    if (!teamSubscription) {
      return;
    }

    return teamSubscription.products.find((product) => product.kind.startsWith('RESOURCE_PLAN'));
  }, [teamSubscription]);

  const currentTeamProducts = useMemo(() => teamSubscription?.products?.map(({ priceId, kind, quantity }) => ({
    id: priceId,
    name: kind,
    quantity,
  })), [teamSubscription]);

  const currentProductId = useMemo(() => {
    if (!bundles || !teamProduct) {
      return;
    }
    return bundles.byProductId(teamProduct.id).prices.find((price) => price.id === teamProduct.priceId)?.productId;
  }, [bundles, teamProduct]);

  const currentPlanTitle = useMemo(() => {
    const plan = metrics.find((metric) => metric.productId === currentProductId);
    if (plan) {
      return wt(`dialog:availableplans.${plan.name}`);
    }

    if (teamSubscription?.products?.every((product) => customPlans[product.kind]?.plan?.productId === product.id)) {
      return wt('dialog:availableplans.custom_plan');
    }

    return wt('dashboard:billing.legacy');
  }, [currentProductId, teamSubscription, metrics, customPlans, wt]);

  const productsInput = useProductInputs(checkoutQuantities, bundles, isCustomPlan ? customPlanQuantities : undefined);

  const promiseSagaDispatch = usePromiseSagaDispatch();

  const handlePaymentWithCard = useCallback(
    (paymentId: string): Promise<SubscriptionMutationResult> | undefined => {
      if (!productsInput?.length) {
        return;
      }

      const params = {
        paymentId,
        teamId: team.id,
        products: productsInput,
      };

      if (currentTeamProducts) {
        return promiseSagaDispatch(updateTeamSubscriptionAC, params);
      }

      return promiseSagaDispatch(createTeamSubscriptionAC, {
        ...params,
        freeTrial: checkoutQuantities.interval === BillingInterval.YEARLY ? false : !!freeTrial,
        coupon: coupon && coupon.class === CouponClass.PROMOTION ? appliedCouponCode : undefined,
        partner: coupon && coupon.class === CouponClass.PARTNER ? appliedCouponCode : undefined,
        source,
      });
    },
    [
      productsInput,
      team.id,
      coupon,
      currentTeamProducts,
      promiseSagaDispatch,
      checkoutQuantities.interval,
      freeTrial,
      source,
      appliedCouponCode,
    ],
  );

  const defaultCard = useMemo(() => teamCards?.find((card) => card.default), [teamCards]);

  const [ loading, setLoading ] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [
    isFailureDialogOpen,
    openFailureDialog,
    closeFailureDialog,
  ] = useDialogState();

  const onSubscriptionSuccess = useCallback(() => {
    setLoading(false);
    onSuccess?.();
  }, [onSuccess]);

  const onSubscriptionError = useCallback((err?: { message?: string }) => {
    setLoading(false);

    if (isCardDeclinedError(err)) {
      openFailureDialog();
    } else if (err?.message) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }

    onClose?.();
  }, [enqueueSnackbar, onClose, openFailureDialog]);

  const handleSubscriptionMutationResult = useSubscriptionMutationResultHandler(
    team.id,
    onSubscriptionSuccess,
    onSubscriptionError,
  );

  const handlePayNow = useCallback(async (paymentId?: string): Promise<void> => {
    // NOTE: due to the way this function is used as an event handler, paymentId can be a Event object,
    //       so it must be explicitly type checked.
    if (typeof paymentId !== 'string') {
      paymentId = defaultCard?.id;
    }

    if (!paymentId) {
      return;
    }

    setLoading(true);

    try {
      return handleSubscriptionMutationResult(await handlePaymentWithCard(paymentId));
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    }
  }, [defaultCard?.id, enqueueSnackbar, handlePaymentWithCard, handleSubscriptionMutationResult]);

  const renderPreviousButton = useCallback(() => {
    return (
      <Button
      startIcon={<ArrowBackIosIcon/>}
      disabled={loading}
      onClick={stepBackward}
      >
        {t('dialog:plandetails.backtoplans')}
      </Button>
    );
  }, [stepBackward, t, loading]);

  return (
    <CustomCouponCodeContext.Provider value={customCouponCodeContextValue}>
      <div className={classNames(classes.root, className)}>
        <DialogContent className={classes.content}>
          <div className={classes.container}>
            <Box className={classes.banner}>
              <Typography variant='h3' className={classes.headerText}>
                {cancelPlan ? t('dashboard:billing.basic') : isCustomPlan ? 'Custom' : checkoutPlanTitle}
              </Typography>
              <Typography>
                {(cancelPlan ? wasYearly : isYearly) ? (
                  t('dialog:plandetails.newyearlytotal')
                ) : (
                  t('dialog:plandetails.newmonthlytotal')
                )}
              </Typography>
              <Typography variant='h2' className={classes.total}>
                {cancelPlan ? (
                  t('settings:free')
                ) : isYearly ? (
                  t('dialog:plandetails.totalperyear', { total: numToDollarAmount(checkoutCost) })
                ) : (
                  t('dialog:plandetails.totalpermonth', { total: numToDollarAmount(checkoutCost) })
                )}
              </Typography>
            </Box>
            <Divider flexItem={true} variant='fullWidth' className={classes.divider} />
            <CheckoutSummary
            defaultSummary={cancelPlan}
            checkoutProduct={checkoutQuantities}
            teamId={team.id}
            currentPlan={currentPlanTitle}
            teamProration={cancelPlan ? 0 : teamProration}
            interval={(cancelPlan && currentProduct?.interval) || checkoutQuantities.interval}
            cost={(cancelPlan && recurringCost) || checkoutCost}
            isCustomPlan={isCustomPlan}
            customPlanQuantities={customPlanQuantities}
            />
            <Divider flexItem={true} variant='fullWidth' className={classes.divider}/>
            <BottomSummary
            defaultSummary={cancelPlan}
            cost={(cancelPlan && recurringCost) || checkoutCost}
            interval={(cancelPlan && currentProduct?.interval) || checkoutQuantities.interval}
            teamProration={cancelPlan ? 0 : teamProration}
            teamBalance={teamBalance}
            freeTrial={freeTrial}
            />
          </div>
          {cancelPlan ? (
            <FlexSpacer
            flexAlignItems='center'
            flexJustifyContent={onCancel ? 'flex-end' : 'space-between'}
            fullWidth={true}
            className={classes.action}
            >
              {!onCancel && renderPreviousButton()}
              <DeleteButton
              variant='contained'
              onClick={openDowngradeDialog}
              >
                {t('dialog:plandetails.downgrade')}
              </DeleteButton>
            </FlexSpacer>
          ) : defaultCard ? (
            <React.Fragment>
              <Typography variant='h5' className={classes.paymentInfo}>
                {t('dashboard:billing.paymentinfo')}
              </Typography>
              <FlexSpacer fullWidth={true} flexJustifyContent='space-between' flexAlignItems='center'>
                <CardDetails
                checked={true}
                card={defaultCard}
                />
                <Button
                variant='outlined'
                startIcon={<CreditCardIcon/>}
                color='default'
                onClick={stepForward}
                >
                  {t('dashboard:billing.changecard')}
                </Button>
              </FlexSpacer>
              <FlexSpacer
              flexAlignItems='center'
              flexJustifyContent='space-between'
              fullWidth={true}
              className={classes.action}
              >
                {renderPreviousButton()}
                <AsyncButton
                variant='contained'
                color='primary'
                className={classes.changecard}
                onClick={handlePayNow}
                loading={loading}
                disabled={loading}
                buttonClasses={{root: classes.payButton}}
                >
                  {t('dashboard:billing.paynow')}
                </AsyncButton>
              </FlexSpacer>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography variant='h5' className={classes.paymentInfo}>
                {t('dashboard:billing.paymentinfo')}
              </Typography>
              <CardForm
              teamId={team.id}
              onSubmit={handlePayNow}
              buttonText={t('dashboard:billing.paynow')}
              previousButton={renderPreviousButton()}
              />
            </React.Fragment>
          )}
        </DialogContent>
        <ConfirmDowngradeDialog
        open={isDowngradeDialogOpen}
        onCancel={onCancel}
        onClose={closeDowngradeDialog}
        team={team}
        closeChangePlansDialog={onClose}
        />
        <FailureDialog
        open={isFailureDialogOpen}
        onClose={closeFailureDialog}
        />
      </div>
    </CustomCouponCodeContext.Provider>
  );
}

export default React.memo(PlanDetails);
