import gql from 'graphql-tag';

export const DeliverInvitations_Mutation = gql`
    mutation DeliverInvitationsMutation(
        $input: DeliverInvitationsInput!
    ) {
        deliverInvitations(
            input: $input
        ) {
            invitationIds
        }
    }
`;
