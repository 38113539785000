import isEqual from 'lodash/isEqual';
import { useEffect, useRef,useState } from 'react';
import { RootReducer } from 'rootReducer';
import store from 'store';

export function useSelector<R>(selector: (state: RootReducer) => R): R;
export function useSelector<P extends any[], R>(selector: (state: RootReducer, ...params: P) => R, ...params: P): R;
export function useSelector<P extends any[], R>(selector: (state: RootReducer, ...params: P) => R, ...params: P): R {
  const [value, setValue] = useState<R>(selector(store.getState(), ...params));
  const ref = useRef(value);

  useEffect(() => {
    let value = selector(store.getState(), ...params);
    if (value !== ref.current && !isEqual(value, ref.current)) {
      setValue(ref.current = value);
    }

    return store.subscribe(() => {
      const newValue = selector(store.getState(), ...params);
      if (newValue !== ref.current && !isEqual(newValue, ref.current)) {
        setValue(ref.current = newValue);
      }
    });
  }, [params, selector]);

  return value;
}
