import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';


export const getTipsDict = (state: RootReducer) => state.tipsReducer.TipsDict;
const getTipsByName = (state: RootReducer, props: { name: string }) => props.name;

export const makeGetTipsByName = () => createSelector(
  [getTipsByName, getTipsDict],
  (name, dict) => dict[name],
);
