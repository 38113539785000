import { DirectoryFragment } from 'apollo/fragments';
import gql from 'graphql-tag';

export const UpdateTeamFolder_Mutation = gql`
    mutation UpdateTeamFolderMutation(
        $input: UpdateFolderInput!
    ) {
        updateFolder(
            input: $input
        ) {
            folder {
                ...DirectoryFragment
            }
        }
    }
    ${DirectoryFragment}
`;
