import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  dots: {
    display: 'flex',
    width: '75%',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& div': {
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      animation: '$fade 0.8s ease-in-out alternate infinite',
    },
    '& div:nth-of-type(1)': {
      animationDelay: '-0.4s',
    },
    '& div:nth-of-type(2)': {
      animationDelay: '-0.2s',
    },
  },
  '@keyframes fade': {
    from: {opacity: 1},
    to: {opacity: 0},
  },
}));

const ImageLoader = () => {
  const classes = useStyles();
  return (
    <div className={classes.dots}>
      <div/>
      <div/>
      <div/>
    </div>
  );
};

export default ImageLoader;
