import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { updateTeamAC } from 'features/dashboard/team/dashboard-team-slice';
import { useFetchTeamIfNecessary } from 'features/dashboard/team/useFetchTeamIfNecessary';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

interface TransferOwnerDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
  user?: React.ReactNode;
  teamId?: string;
  userId?: string;
}

type TransferOwnerDialogProps = TransferOwnerDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'TransferOwnerDialog'});

function TransferOwnerDialog(props: TransferOwnerDialogProps) {
  const classes = useStyles(props);
  const { className, open, onClose, user, teamId, userId } = props;

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useStrictTranslation(['settings', 'common']);
  const [ loading, setLoading ] = useState(false);
  const [team] = useFetchTeamIfNecessary(teamId);

  const transferOnClick = useCallback(async () => {
    if (loading || !team || !userId) {
      return;
    }
    setLoading(true);
    try {
      await promiseSagaDispatch(updateTeamAC, {
        id: team.id,
        ownerId: userId,
      });
      enqueueSnackbar(t('settings:transferowner.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('settings:transferowner.failure'), {variant: 'error'});
    } finally {
      setLoading(false);
      onClose();
    }
  }, [enqueueSnackbar, loading, onClose, promiseSagaDispatch, t, team, userId]);

  return (
    <Dialog open={open} onClose={onClose}>
      <AlertDialogContent
      titleText={t('settings:transferowner.transferto', { user })}
      description={t('settings:transferowner.transfermessage')}
      cancel={{
        text: t('common:cancel'),
        action: onClose,
      }}
      confirm={{
        text: t('settings:transferowner.transfer'),
        loading,
        disabled: loading || !team || !userId,
        action: transferOnClick,
      }}
      />
    </Dialog>
  );
}

export default React.memo(TransferOwnerDialog);
