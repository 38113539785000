import React from 'react';
import { ID } from 'types/pigeon';

import { useFetchDirectoryFolders } from './useFetchDirectoryFolders';

interface DirectoryFolderFetcherOwnProps {
  directoryId: ID;
}

type DirectoryFolderFetcherProps = DirectoryFolderFetcherOwnProps;

function DirectoryFolderFetcher(props: DirectoryFolderFetcherProps) {
  const { directoryId } = props;

  useFetchDirectoryFolders(directoryId);

  return null;
}

export default React.memo(DirectoryFolderFetcher);
