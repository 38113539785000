import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { RoleInterfaceFragment_CustomRole } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { EIntercomID } from 'constants/strings';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React from 'react';

import RoleRowSkeleton from './RoleRowSkeleton';
import RoleTableRowWrapper from './RoleTableRowWrapper';

interface RoleTableOwnProps {
  className?: string;
  displaySkeleton?: boolean;
  roles: RoleInterfaceFragment_CustomRole[];
  team: TeamFragment;
}

type RoleTableProps = RoleTableOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'RoleTable'});

function RoleTable(props: RoleTableProps) {
  const classes = useStyles(props);
  const { className, roles, displaySkeleton, team } = props;
  const { t } = useStrictTranslation(['dashboard']);
  return (
    <Table id={EIntercomID.ROLES_TABLE} className={classNames(classes.root, className)} size='small'>
      <TableHead>
        <TableRow>
          <FixedTableCell width={100}>{t('dashboard:team.roles.customrole')}</FixedTableCell>
          <FixedTableCell width={300}>{t('dashboard:team.roles.privileges')}</FixedTableCell>
          <FixedTableCell width={100} align='center'></FixedTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {displaySkeleton ? (
          Array.from({length: 2}).map((_, i) => (
            <RoleRowSkeleton key={i} />
          ))
        ) : (
          roles.map(role => (
            <RoleTableRowWrapper
            key={role.id}
            role={role}
            team={team}
            />
          ))
        )}
      </TableBody>
    </Table>
  );
}

export default React.memo(RoleTable);
