import { AsyncButton, EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import update from 'immutability-helper';
import fromPairs from 'lodash/fromPairs';
import { collaboratorPrivileges, IPrivilegeGroup } from 'material/dialogs/role-editor-dialog-content/privilege';
import PrivilegeGroups from 'material/dialogs/role-editor-dialog-content/PrivilegeGroups';
import Message from 'material/message/Message';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { TeamPrivilege } from 'types/graphql';
import { ID } from 'types/pigeon';

import { makeGetTeamDirectoryByDirectoryId } from '../directory/dashboard-directory-selector';
import { updateDivisionPrivilegesAC } from '../directory/dashboard-directory-slice';

interface EditCollaboratorPrivilegesDialogOwnProps {
  className?: string;
  onClose: VoidFunction;
  directoryId: ID;
  teamId: ID;
}

type EditCollaboratorPrivilegesDialogProps = EditCollaboratorPrivilegesDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'EditCollaboratorPrivilegesDialog'});

function EditCollaboratorPrivilegesDialog(props: EditCollaboratorPrivilegesDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} fullWidth={true} onClose={onClose} maxWidth='xs'>
      <EditCollaboratorPrivilegesDialogContent {...props} />
    </Dialog>
  );
}

function EditCollaboratorPrivilegesDialogContent(props: EditCollaboratorPrivilegesDialogProps) {
  const { onClose, teamId, directoryId } = props;

  const { t } = useStrictTranslation(['common', 'dialog']);
  const directory = useCreateSelector(makeGetTeamDirectoryByDirectoryId, directoryId);
  const [ privileges, setPrivileges] = useState<Dictionary<boolean>>(
    () => fromPairs(directory?.privileges?.map(t => ([t, true]))),
  );

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [ loading, setLoading ] = useState(false);
  const [error, setError] = useState<Error>();

  const handleUpdatePrivileges = useCallback(async () => {
    if (loading) { return; }
    const privKeys = Object.keys(privileges);
    const priv = privKeys.filter((key) => privileges[key]) as TeamPrivilege[];

    if (priv.length === 0) {
      setError(new Error(t('dialog:updateroles.noroleselected')));
      return;
    }

    setLoading(true);
    try {
      await promiseSagaDispatch(updateDivisionPrivilegesAC, {
        id: directoryId,
        teamId,
        privileges: priv,
      });
      enqueueSnackbar(t('dialog:updateprivileges.success'), {variant: 'success'});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(t('dialog:updateprivileges.failure'), {variant: 'error'});
    }
  }, [promiseSagaDispatch, t, enqueueSnackbar, teamId, directoryId, loading, privileges]);

  const handleCheckboxOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPrivileges(update(privileges, {[e.target.name as TeamPrivilege]: {$set: e.target.checked}}));
    setError(undefined);
  }, [privileges]);

  const handleSelectAllPrivilegesOnClick = useCallback((group: IPrivilegeGroup, deselect?: boolean) => {
    if (deselect) {
      setPrivileges(privileges => {
        return update(privileges, {$merge: fromPairs(group.privileges.map(p => [p, false]))});
      });
    } else {
      setPrivileges(privileges => {
        return update(privileges, {$merge: fromPairs(group.privileges.map(p => [p, true]))});
      });
    }
  }, []);

  return (
    <React.Fragment>
      <EnhancedDialogTitle>
        {t('dialog:updateprivileges.title')}
      </EnhancedDialogTitle>
      <DialogContent>
        <FlexSpacer orientation='vertical'>
          <PrivilegeGroups
          groups={collaboratorPrivileges}
          privileges={privileges}
          handleCheckboxOnChange={handleCheckboxOnChange}
          handleSelectAllPrivileges={handleSelectAllPrivilegesOnClick}
          />
          {error && (
            <Message message={error.message} variant='error' />
          )}
        </FlexSpacer>
      </DialogContent>
      <DialogActions>
        <Button
        type='button'
        variant='outlined'
        onClick={onClose}
        >
          {t('common:cancel')}
        </Button>
        <AsyncButton
        type='submit'
        color='primary'
        loading={loading}
        disabled={loading}
        variant='contained'
        onClick={handleUpdatePrivileges}
        >
          {t('dialog:updateprivileges.confirm')}
        </AsyncButton>
      </DialogActions>
    </React.Fragment>
  );
}

export default React.memo(EditCollaboratorPrivilegesDialog);
