import { useFetchTeamCustomRoles } from 'features/dashboard/role/useFetchTeamCustomRoles';
import React from 'react';

import { ID } from '../../types/pigeon';

interface RoleFetcherOwnProps {
  teamId: ID;
}

type RoleFetcherProps = RoleFetcherOwnProps;

function RoleFetcher({ teamId }: RoleFetcherProps) {
  useFetchTeamCustomRoles({teamId});
  return null;
}

export default React.memo(RoleFetcher);
