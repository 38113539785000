import { FlexSpacer, VerticalScroll } from '@insights-gaming/material-components';
import { BidirectionalIDRecord } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Pagination from 'components/pagination/Pagination';
import React, { useCallback, useMemo, useRef } from 'react';
import { ID } from 'types/pigeon';

import SearchCommentResult from './SearchCommentResult';

interface PagedCommentResultOwnProps {
  className?: string;
  page: number;
  onPageChange?: (pageNumber: number) => void;
  record?: BidirectionalIDRecord;
  teamId: ID;
}

type PagedCommentResultProps = PagedCommentResultOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  nav: {
    alignSelf: 'flex-start',
  },
}), {name: 'PagedCommentResult'});

const commentsPerPage = 10;

function PagedCommentResult(props: PagedCommentResultProps) {
  const classes = useStyles(props);
  const { className, page, onPageChange, record, teamId } = props;

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const scrollToTop = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }
    scrollRef.current.scroll({top: 0});
  }, []);

  const handlePageChange = useCallback((value: number) => {
    onPageChange?.(value);
    scrollToTop();
  }, [onPageChange, scrollToTop]);

  const commentIds = useMemo(
    () => (record?.ids || []).slice(page * commentsPerPage).slice(0, commentsPerPage),
    [page, record?.ids],
  );

  return (
    <FlexSpacer orientation='vertical' className={classNames(classes.root, className)}>
      <VerticalScroll ref={scrollRef}>
        {commentIds.map((c) => <SearchCommentResult key={c} commentId={c} teamId={teamId}/>)}
      </VerticalScroll>
      {
        (record?.ids?.length || 0) > commentsPerPage && (
          <Pagination
          className={classes.nav}
          record={record}
          pageSize={commentsPerPage}
          page={page}
          onChange={handlePageChange}
          />
        )
      }
    </FlexSpacer>
  );
}

export default React.memo(PagedCommentResult);
