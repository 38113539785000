import { FlexSpacer } from '@insights-gaming/material-components';
import {
  VideoReplayContext,
  VideoReplayContextValue,
} from '@insights-gaming/material-components/VideoReplayContext/VideoReplayContext';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CommentFragment, CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import classNames from 'classnames';
import AutoPauser from 'components/video/video-replay/AutoPauser';
import CurrentCommentFinder from 'components/video/video-replay/CurrentCommentFinder';
import CommentsMenu from 'components/video/video-replay/video-comment/CommentsMenu';
import { useFetchComments } from 'components/video/video-replay/video-comment/useFetchComments';
import {
  getCommentTagRecords,
  makeGetNotDeletedFilteredVideoCommentsByVideoId,
  makeGetNotDeletedVideoCommentsByVideoId,
} from 'components/video/video-replay/video-comment/video-comment-selector';
import { fetchVideoCommentsAC } from 'components/video/video-replay/video-comment/video-comment-slice';
import { VideoCommentRef } from 'components/video/video-replay/video-comment/VideoComment';
import VideoCommentSkeleton from 'components/video/video-replay/video-comment/VideoCommentSkeleton';
import { COMMENT_ELEM_ID_PREFIX, EIntercomID } from 'constants/strings';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { useFetchVideoIfNecessary } from 'features/dashboard/video/useFetchVideoIfNecessary';
import { desktop, mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { VideoCommentContext } from 'features/video-replay/VideoCommentContext';
import { VideoCommentMenuContext } from 'features/video-replay/VideoCommentMenuContext';
import { useHackRef } from 'hooks/useHackRef';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import React, { MutableRefObject, Ref, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getMe } from 'selectors/getMe';
import EmptyStateImage from 'subcomponents/empty-state-image/EmptyStateImage';
import { ID } from 'types/pigeon';

import { DrawingToolContext } from '../../DrawingToolContext';
import GuestCommentPanel from '../GuestCommentPanel';
import { VideoReplaySelectedLabelsContext } from '../VideoReplaySelectedLabelsContext';
import VirtualizedComments from '../virtualized-comments/VirtualizedComments';


interface CommentListViewContentOwnProps extends CommentListViewOwnProps {
  playing: boolean;
  seekTo: VideoReplayContextValue['seekTo'];
  pause: VideoReplayContextValue['pause'];
  play: VideoReplayContextValue['play'];
  time: number,
  onEditCommentDrawing?: (comment: CommentFragment_VideoComment) => void;
}

type CommentListViewContentProps = React.PropsWithoutRef<CommentListViewContentOwnProps>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  commentsContainer: {
    flex: 1,
  },
  commentMenu: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  comments: {
    position: 'relative',
    flex: 'auto',
    height: 0,
    padding: theme.spacing(0, 2),
    [mobilePortrait(theme)]: {
      padding: 0,
    },
  },
  noComments: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    [desktop(theme)]: {
      marginTop: theme.spacing(6),
    },
  },
  viewOnly: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(20),
  },
  skeleton: {
    margin: theme.spacing(2, 0),
  },
  current: {},
}));

function useScrollToComment(
  setCurrentComment: (comment: CommentFragment_VideoComment) => void,
  ignoreNextScrollRef: MutableRefObject<boolean>,
) {
  const commentElementsRef = useRef<Dictionary<VideoCommentRef>>({});
  const scrolledCommentRef = useRef<ID>();

  const scrollToComment = useCallback(
    (commentId: ID, scroll: boolean, playAudioCallback?: (paused: boolean) => void) => {
      const comment = commentElementsRef.current[commentId];
      if (comment) {
        if (scroll) {
          comment.scrollIntoView({ block: 'center' });
        }
        ignoreNextScrollRef.current = true;
        if (playAudioCallback) {
          if (comment.playAudioRecording) {
            const cleanup = comment.playAudioRecording((paused: boolean) => {
              cleanup?.();
              playAudioCallback(paused);
            });
            return true;
          }
        }
      }
      return false;
    },
    [ignoreNextScrollRef],
  );

  return {
    scrollToComment: useCallback((
      comment?: CommentFragment_VideoComment,
      {
        force,
        playAudioCallback,
        scroll = true,
      }: { force?: boolean, playAudioCallback?: (paused: boolean) => void, scroll?: boolean } = {},
    ): boolean => {
      const scrolledComment = scrolledCommentRef.current;
      const commentId = comment?.id || scrolledComment;
      if (comment) {
        setCurrentComment(comment);
      }

      scrolledCommentRef.current = commentId;

      if (commentId && (commentId !== scrolledComment || force)) {
        return scrollToComment(commentId, scroll, playAudioCallback);
      }

      return false;
    }, [scrollToComment, setCurrentComment]),
    commentElementRef: useCallback((elem: VideoCommentRef | null) => {
      if (elem && elem.id.startsWith(COMMENT_ELEM_ID_PREFIX)) {
        commentElementsRef.current = {
          ...commentElementsRef.current,
          [elem.id.slice(COMMENT_ELEM_ID_PREFIX.length)]: elem,
        };
      }
    }, []) as Ref<VideoCommentRef>,
  };
}

function useJumpToComment(
  seekTo: VideoReplayContextValue['seekTo'],
  pause: VideoReplayContextValue['pause'],
  setCurrentComment: (comment: CommentFragment_VideoComment) => void,
  scrollToComment: (comment: CommentFragment_VideoComment) => void,
) {
  const { drawingState: { loadJSON, reset, setTool }, setViewedComment } = useContext(DrawingToolContext);
  return {
    jumpToCommentTime: useCallback((comment: CommentFragment_VideoComment) => {
      pause();
      setCurrentComment(comment);
      setViewedComment({ comment });
      scrollToComment(comment);
    }, [pause, scrollToComment, setCurrentComment, setViewedComment]),
  };
}

function useManualScroll(
  scrollToCurrentComment: () => void,
  ignoreNextScrollRef: MutableRefObject<boolean>,
) {
  const [ manualScroll, setManualScroll ] = useState(false);
  return {
    manualScroll,
    cancelManualScroll: useCallback(() => {
      ignoreNextScrollRef.current = true;
      setManualScroll(false);
      scrollToCurrentComment();
    }, [ignoreNextScrollRef, scrollToCurrentComment]),
    onScroll: useCallback(() => {
      if (ignoreNextScrollRef.current) {
        ignoreNextScrollRef.current = false;
      } else {
        setManualScroll(true);
      }
    }, [ignoreNextScrollRef]),
  };
}

function useAutoScrollState(
  playing: boolean,
  scrollToComment: (comment: CommentFragment_VideoComment | undefined) => void,
  autoScroll: boolean,
  toggleAutoScroll: () => void,
) {
  useEffect(() => {
    if (!autoScroll && playing) {
      scrollToComment(undefined);
    }
  }, [autoScroll, playing, scrollToComment]);

  return useMemo(() => ({
    autoScroll,
    toggleAutoScroll,
  }), [autoScroll, toggleAutoScroll]);
}

function CommentListViewContent(props: CommentListViewContentProps) {
  const classes = useStyles(props);
  const {
    className,
    videoId,
    labelSelector,
    playing,
    seekTo,
    pause,
    play,
    members,
    time,
    onEditCommentDrawing,
  } = props;

  const me = useSelector(getMe);
  const { canCreateVideoComment } = useAccessControl();

  const {
    labels: {
      selected: selectedLabelIds,
    },
  } = useContext(VideoReplaySelectedLabelsContext);

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const isDesktop = useIsDesktop();

  const selectedLabelIdsArray = useMemo(() => Array.from(selectedLabelIds), [selectedLabelIds]);

  const [video] = useFetchVideoIfNecessary(videoId);
  const [ fetchingFilteredComments, setFetchingFilteredComments ] = useState(false);

  const canComment = me && (video?.openComments || canCreateVideoComment);

  const { state: { comment: commentFromLocation } = {} } = useLocation<{comment?: CommentFragment}>();

  useEffect(() => {
    const fetchFilteredComments = async () => {
      if (selectedLabelIdsArray.length > 0) {
        setFetchingFilteredComments(true);
        await promiseSagaDispatch(fetchVideoCommentsAC.forward, {videoId, tagIds: selectedLabelIdsArray});
        setFetchingFilteredComments(false);
      }
    };
    fetchFilteredComments();
  }, [selectedLabelIdsArray, videoId, promiseSagaDispatch]);

  const [commentsForwardFetchStatus] = useFetchComments({ videoId });
  const comments = useCreateSelector(makeGetNotDeletedVideoCommentsByVideoId, { videoId });
  const filteredComments = useCreateSelector(makeGetNotDeletedFilteredVideoCommentsByVideoId, { videoId });
  const initialCommentFetch = commentsForwardFetchStatus.fetching && !commentsForwardFetchStatus.cursor;
  const videoCommentTagRecords = useSelector(getCommentTagRecords);

  const labelsFilter = useMemo(
    () => Object.entries(videoCommentTagRecords)
      .map((record) => [record[0], record[1]?.ids])
      .filter((r) => selectedLabelIdsArray.every((id) => r[1]?.includes(id)))
      .map((entry) => entry[0]),
    [selectedLabelIdsArray, videoCommentTagRecords],
  );

  const filteredCommentsWithLabels = useMemo(
    () => selectedLabelIdsArray.length > 0
      ? filteredComments.filter((comment) => labelsFilter.includes(comment.id))
      : filteredComments,
    [filteredComments, labelsFilter, selectedLabelIdsArray.length],
  );

  const ignoreNextScrollRef = useRef(false);

  const { currentComment, setCurrentComment } = useContext(VideoCommentContext);

  const {
    autoPause,
    autoPauseTyping,
    autoScroll: _autoScroll,
    autoPlayAudio,
    toggleAutoPause,
    toggleAutoPauseTyping,
    toggleAutoScroll: _toggleAutoScroll,
    toggleAutoPlayAudio,
  } = useContext(VideoCommentMenuContext);

  const { scrollToComment, commentElementRef } = useScrollToComment(setCurrentComment, ignoreNextScrollRef);
  const { jumpToCommentTime } = useJumpToComment(seekTo, pause, setCurrentComment, scrollToComment);
  const { autoScroll, toggleAutoScroll } = useAutoScrollState(playing, scrollToComment, _autoScroll, _toggleAutoScroll);

  const { manualScroll, cancelManualScroll, onScroll } = useManualScroll(
    useCallback(() => scrollToComment(undefined, { force: true }), [scrollToComment]),
    ignoreNextScrollRef,
  );

  const [ shouldCheckLocationState, setShouldCheckLocationState ] = useState(true);

  useEffect(() => {
    if (commentFromLocation) {
      setShouldCheckLocationState(true);
    }
  }, [commentFromLocation]);

  useEffect(() => {
    if (shouldCheckLocationState && !commentsForwardFetchStatus.more && commentFromLocation) {
      let initialComment;
      if (commentFromLocation.__typename === 'VideoComment') {
        initialComment = comments.find(c => c.id === commentFromLocation.id);
      } else {
        initialComment = comments.find(c => c.id === commentFromLocation.parent);
      }
      if (initialComment) {
        jumpToCommentTime(initialComment);
        setShouldCheckLocationState(false);
      }
    }
  }, [jumpToCommentTime, commentsForwardFetchStatus, comments, shouldCheckLocationState, commentFromLocation]);

  const currentIdx = useMemo(() => {
    if (manualScroll || !autoScroll) {
      return undefined;
    }

    return comments.findIndex(c => c.id === currentComment?.id);
  }, [comments, currentComment, manualScroll, autoScroll]);

  const handleEditDrawingOnClick = useCallback((comment: CommentFragment_VideoComment) => {
    onEditCommentDrawing?.(comment);
    jumpToCommentTime(comment);
  }, [onEditCommentDrawing, jumpToCommentTime]);

  const playRef = useHackRef(play);
  const pauseRef = useHackRef(pause);
  const autoScrollRef = useHackRef(autoScroll);
  const manualScrollRef = useHackRef(manualScroll);

  const scrollToCommentsAndPlay = useCallback(async (comments: CommentFragment_VideoComment[]) => {
    for (const comment of comments) {
      if (await new Promise<boolean>(
        (resolve) => scrollToComment(comment, {
          playAudioCallback: resolve,
          scroll: autoScrollRef.current && !manualScrollRef.current,
        }) ? pauseRef.current() : resolve(false),
      )) {
        // user paused audio clip
        return;
      }
    }
    // resume video playback
    playRef.current();
  }, [autoScrollRef, manualScrollRef, pauseRef, playRef, scrollToComment]);

  const setCurrentComments = useCallback((comments: CommentFragment_VideoComment[]) => {
    if (autoPlayAudio) {
      const audioComments = comments.filter(isAudioVideoComment);
      if (audioComments.length) {
        scrollToCommentsAndPlay(audioComments);
        return;
      }
    }

    const comment = comments[comments.length - 1];
    if (autoScroll && !manualScroll) {
      scrollToComment(comment);
    } else {
      setCurrentComment(comment);
    }
  }, [autoPlayAudio, autoScroll, manualScroll, scrollToCommentsAndPlay, scrollToComment, setCurrentComment]);

  return (
    <FlexSpacer
    orientation='vertical'
    className={classNames(classes.root, className)}
    id={EIntercomID.COMMENTS}
    >
      {isDesktop && (
        <div className={classes.commentMenu}>
          {labelSelector}
          <CommentsMenu
          autoPauseComment={autoPause}
          autoScrollComment={autoScroll}
          autoPlayAudio={autoPlayAudio}
          autoPauseTyping={autoPauseTyping}
          togglAutoPauseComment={toggleAutoPause}
          toggleAutoScrollComment={toggleAutoScroll}
          toggleAutoPlayAudio={toggleAutoPlayAudio}
          toggleAutoPauseTyping={toggleAutoPauseTyping}
          />
        </div>
      )}
      {initialCommentFetch || fetchingFilteredComments ? (
        <div className={classes.commentsContainer}>
          {Array.from({length: 2}).map((_, i) => <VideoCommentSkeleton className={classes.skeleton} key={i}/>)}
        </div>
      ) : !comments.length ? (
        canComment ? (
          <div className={classNames(classes.commentsContainer, classes.noComments)}>
            <EmptyStateImage url='no-comments' width={240} height={175}/>
            <Typography variant='caption'>
              <Trans
              i18nKey={'video:replay.nocomment'}
              >
                Send feedback using timestamped<br /> comments and drawings
              </Trans>
            </Typography>
          </div>
        ) : (
          <div className={classNames(classes.commentsContainer, classes.viewOnly)}>
            <GuestCommentPanel />
          </div>
        )
      ) : (
        <React.Fragment>
          <div
          id={EIntercomID.COMMENTS}
          className={classes.comments}
          onScroll={onScroll}
          >
            {autoPause && (
              <AutoPauser
              comments={comments}
              onPauseAtComment={jumpToCommentTime}
              />
            )}
            {playing && (
              <CurrentCommentFinder
              videoComments={comments}
              setCurrentComments={setCurrentComments}
              />
            )}
            {video && (
              <VirtualizedComments
              ref={commentElementRef}
              comments={selectedLabelIdsArray.length > 0 ? filteredCommentsWithLabels : comments}
              members={members}
              currentComment={currentComment}
              video={video}
              onChangeCurrentComment={jumpToCommentTime}
              cancelManualScroll={cancelManualScroll}
              currentIdx={currentIdx}
              displayResumeButton={manualScroll && autoScroll}
              handleEditDrawingOnClick={handleEditDrawingOnClick}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </FlexSpacer>
  );
}

const MemoizedCommentListViewContent = React.memo(CommentListViewContent);

interface CommentListViewOwnProps {
  className?: string;
  videoId: ID;
  labelSelector?: React.ReactNode;
  members: MemberFragment[];
  onEditCommentDrawing?: (comment: CommentFragment_VideoComment) => void;
}

type CommentListViewProps = React.PropsWithoutRef<CommentListViewOwnProps>;

export default function CommentListView(props: CommentListViewProps) {
  const { state: { playing, progress: { playedSeconds: time } }, seekTo, pause, play } = useContext(VideoReplayContext);

  return React.createElement(
    MemoizedCommentListViewContent,
    {
      ...props,
      playing,
      seekTo,
      pause,
      play,
      time,
    },
  );
}

function isAudioVideoComment(videoComment: CommentFragment_VideoComment): boolean {
  return !!videoComment.recordingUrl;
}
