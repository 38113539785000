import { Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { TokenFragment } from 'apollo/fragments/types/TokenFragment';
import { TIME_FORMAT } from 'constants/strings';
import dateFnsFormat from 'date-fns/format';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { WritableDraft } from 'immer/dist/internal';
import React, { useCallback } from 'react';
import { UAParser } from 'ua-parser-js';

import ActiveSessionsTableRow from './ActiveSessionsTableRow';
import RemoveSessionDialog from './RemoveSessionDialog';

interface ActiveSessionsTableRowWrapperOwnProps {
  className?: string;
  token?: WritableDraft<TokenFragment>;
}

type ActiveSessionsTableRowWrapperProps = ActiveSessionsTableRowWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  currentSession: {
    fontStyle: 'italic',
    color: theme.palette.grey[400],
  },
}), {name: 'ActiveSessionsTableRowWrapper'});

function ActiveSessionsTableRowWrapper(props: ActiveSessionsTableRowWrapperProps) {
  const classes = useStyles(props);
  const { className, token } = props;

  const { t } = useStrictTranslation(['common', 'settings']);

  const [ isRemoveSessionDialogOpen, openRemoveSessionDialog, closeRemoveSessionDialog] = useDialogState();

  const renderAction = useCallback(() => {
    return token?.current ? (
      <span
      className={classes.currentSession}
      >
        {t('settings:activesessions.currentsession')}
      </span>
    ) : (
      <Tooltip title={t('settings:activesessions.removedialog.endsession')}>
        <IconButton onClick={openRemoveSessionDialog} size='small'>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  }, [classes.currentSession, openRemoveSessionDialog, t, token]);

  const parseBrowser = useCallback((userAgent: string | null | undefined) => {
    if (!userAgent) {
      return null;
    }

    const uaParser = new UAParser.UAParser();
    try {
      uaParser.setUA(userAgent);

      return uaParser.getBrowser().name + ' ' + uaParser.getBrowser().version;
    } catch (err) {
      return null;
    }
  }, []);

  return (
    <React.Fragment>
      <ActiveSessionsTableRow
      created={dateFnsFormat(new Date(token?.created), TIME_FORMAT)}
      address={token?.address}
      browser={parseBrowser(token?.userAgent)}
      country={token?.country}
      action={renderAction()}
      />
      <RemoveSessionDialog
      open={isRemoveSessionDialogOpen}
      onClose={closeRemoveSessionDialog}
      tokenId={token?.id}
      />
    </React.Fragment>
  );
}

export default React.memo(ActiveSessionsTableRowWrapper);
