import { Editor, EditorState } from 'draft-js';
import React, { useEffect, useState } from 'react';

export interface IWYSICommentOwnProps {
  initialEditorState: EditorState;
}

type Props = IWYSICommentOwnProps;

function WYSIComment(props: Props) {
  const { initialEditorState } = props;
  const [editorState, setEditorState] = useState(initialEditorState);

  useEffect(() => setEditorState(initialEditorState), [initialEditorState]);

  return (
    <Editor
    editorState={editorState}
    onChange={setEditorState}
    readOnly={true}
    />
  );
}

export default React.memo(WYSIComment);
