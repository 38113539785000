import { Theme } from '@insights-gaming/theme';
import { createStyles, fade, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { forwardRef, HTMLProps, PropsWithChildren } from 'react';
import ReactResizeDetector from 'react-resize-detector';

interface DropIndicatorOwnProps {
  className?: string;
  isDragActive?: boolean;
  isDragAccept?: boolean;
  isDragReject?: boolean;
}

type DropIndicatorProps = PropsWithChildren<DropIndicatorOwnProps & HTMLProps<HTMLDivElement>>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    pointerEvents: 'none',
    '&&': { // increase specificity to beat FlexSpacer
      margin: 0,
    },
  },
  wrapper: {
    position: 'absolute',
  },
  circle: {
    transform: 'scale(0)',
    borderRadius: '50%',
    '&$isDragActive': {
      transform: `scale(${Math.sqrt(2)})`,
      transition: 'transform 500ms ease',
    },
    '&$isDragAccept': {
      backgroundColor: fade(theme.palette.primary.main, .5),
    },
    '&$isDragReject': {
      backgroundColor: fade(theme.palette.error.main, .5),
    },
  },
  isDragActive: {},
  isDragAccept: {},
  isDragReject: {},
}), {name: 'DropIndicator'});

const DropIndicator = forwardRef<HTMLDivElement, DropIndicatorProps>(function DropIndicator(
  props: DropIndicatorProps,
  ref,
) {
  const classes = useStyles(props);
  const {
    children,
    className,
    isDragActive,
    isDragAccept,
    isDragReject,
    ...rest
  } = props;
  return (
    <div ref={ref} {...rest} className={classNames(classes.root, className)}>
      <ReactResizeDetector handleWidth={true} handleHeight={true}>
        {(width: number, height: number) => {
          let size = Math.max(width, height);
          if (isNaN(size)) {
            size = 0;
          }
          return (
            <div className={classes.wrapper}>
              <div
              className={classNames(
                classes.circle,
                {
                  [classes.isDragActive]: isDragActive,
                  [classes.isDragAccept]: isDragAccept,
                  [classes.isDragReject]: isDragReject,
                },
              )}
              style={{width: size, height: size}}
              />
            </div>
          );
        }}
      </ReactResizeDetector>
      {children}
    </div>
  );
});
DropIndicator.displayName = 'DropIndicator';

export default React.memo(DropIndicator);
