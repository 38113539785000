import gql from 'graphql-tag';

import { DirectoryFragment } from '../fragments/dashboard/DirectoryFragment';

export const UpdateDivisionPrivileges_Mutation = gql`
    mutation UpdateDivisionPrivilegesMutation(
        $input: UpdateDivisionPrivilegesInput!
    ) {
        updateDivisionPrivileges(
            input: $input
        ) {
            division {
                ...DirectoryFragment
            }
        }
    }
    ${DirectoryFragment}
`;
