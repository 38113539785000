type TOwstats =
|'players'
|'teamcomps'
|'maps'
|'selectroster'
|'customqueries'
|'killsperten'
|'killspertenabbr'
|'deathsperten'
|'deathspertenabbr'
|'ultsperten'
|'ultspertenabbr'
|'assistsperten'
|'assistspertenabbr'
|'killsperfight'
|'killsperfightabbr'
|'deathsperfight'
|'deathsperfightabbr'
|'ultsperfight'
|'ultsperfightabbr'
|'firstkillsperfight'
|'firstkillsperfightabbr'
|'firstdeathsperfight'
|'firstdeathsperfightabbr'
|'firstultsperfight'
|'firstultsperfightabbr'
|'teamfights'
|'teamfightsabbr'
|'map'
|'nodata'
|'exportcsv'
|'selectvideos'
|'refreshstats'
|'norosterselected'
|'aggregatingstats'
|'match'
|'playerstats.title'
|'playerstats.playerName'
|'playerstats.heroName'
|'playerstats.time'
|'playerstats.firstKills'
|'playerstats.firstkillsabbr'
|'playerstats.firstDeaths'
|'playerstats.firstdeathsabbr'
|'playerstats.firstUlts'
|'playerstats.firstultsabbr'
|'playerstats.finalblows'
|'playerstats.finalblowsabbr'
|'playerstats.deaths'
|'playerstats.ults'
|'playerstats.assists'
|'playerstats.ultcharge'
|'playerstats.ultheld'
|'playerstats.heroesplayed'
|'playerstats.healingdone'
|'playerstats.damagetaken'
|'playerstats.damagedone'
|'teamcompositionstats.heroes'
|'teamfightstats.title'
|'teamfightstats.team'
|'teamfightstats.teamfights'
|'teamfightstats.blueteam'
|'teamfightstats.redteam'
|'teamfightstats.kills'
|'teamfightstats.deaths'
|'teamfightstats.ults'
|'teamfightstats.firstkills'
|'teamfightstats.firstdeaths'
|'teamfightstats.firstkillheroesabbr'
|'teamfightstats.firstkillheroes'
|'teamfightstats.firstdeathheroesabbr'
|'teamfightstats.firstdeathheroes'
|'teamfightstats.averagekills'
|'teamfightstats.averagedeaths'
|'teamfightstats.averageultsused'
|'teamfightstats.firsttfwins'
|'teamfightstats.firsttfwinsabbr'
|'teamfightstats.teamfightwin'
|'teamfightstats.teamfightwinabbr'
|'teamfightbreakdown.title'
|'teamfightbreakdown.teamfightclip'
|'teamfightbreakdown.result'
|'teamfightbreakdown.herocomp'
|'teamfightbreakdown.prefightults'
|'teamfightbreakdown.ultsused'
|'teamfightbreakdown.firstult'
|'teamfightbreakdown.firstkill'
|'teamfightbreakdown.firstdeath'
|'teamfightbreakdown.kills'
|'teamfightbreakdown.win'
|'teamfightbreakdown.lose'
|'owl.owl2020title'
|'owl.viewstats'
|'owl.nomatches'
|'owl.week'
|'owl.weeknum'
|'owl.noscore'
|'owl.localtimenotice'
|'owl.overview.team'
|'owl.overview.result'
|'owl.overview.teamfights'
|'owl.overview.kills'
|'owl.overview.deaths'
|'owl.overview.firstkill'
|'owl.overview.firstkillsperteamfight'
|'owl.overview.firstkillsperteamfightabbr'
|'owl.overview.killsperteamfight'
|'owl.overview.killsperteamfightabbr'
|'owl.overview.deathsperteamfight'
|'owl.overview.deathsperteamfightabbr'
|'owl.overview.ultsperteamfight'
|'owl.overview.ultsperteamfightabbr'
|'owl.overview.comp'
|'owlTeams.fuel'
|'owlTeams.fusion'
|'owlTeams.hunters'
|'owlTeams.outlaws'
|'owlTeams.spark'
|'owlTeams.eternal'
|'owlTeams.defiant'
|'owlTeams.titans'
|'owlTeams.justice'
|'owlTeams.reign'
|'owlTeams.uprising'
|'owlTeams.charge'
|'owlTeams.nyxl'
|'owlTeams.shock'
|'owlTeams.valiant'
|'owlTeams.gladiators'
|'owlTeams.mayhem'
|'owlTeams.dragons'
|'owlTeams.dynasty'
|'owlTeams.spitfire'
|'queries.createquery'
|'queries.createadvancedquery'
|'queries.createquerycaption'
|'queries.queryname'
|'queries.selectparameter'
|'queries.parameter'
|'queries.selectvalue'
|'queries.value'
|'queries.team'
|'queries.enemy'
|'queries.deleterow'
|'queries.and'
|'queries.addparameter'
|'queries.selectvideos'
|'queries.saveandapply'
|'queries.showmore'
|'queries.showless'
|'queries.selectvideocaption'
|'queries.videoselected'
|'queries.noqueryresult'
|'queries.nodata'
|'queries.parameterOptions.gamemode'
|'queries.parameterOptions.map'
|'queries.parameterOptions.attack'
|'queries.parameterOptions.defend'
|'queries.parameterOptions.player'
|'queries.parameterOptions.teamfightfirstultcolor'
|'queries.parameterOptions.teamfightfirstulthero'
|'queries.parameterOptions.teamfightfirstkillcolor'
|'queries.parameterOptions.teamfightfirstkillhero'
|'queries.parameterOptions.teamfightfirstdeathcolor'
|'queries.parameterOptions.teamfightfirstdeathhero'
|'queries.parameterOptions.heroes'
|'queries.parameterOptions.ults'
|'queries.summary.primaryroster'
|'queries.summary.enemyteam'
|'queries.savedqueries'
|'queries.previouslyused'
|'queries.drafts'
;
const OwstatsNS = 'owstats';
function OwstatsF(_: TOwstats): string { return `${OwstatsNS}:${_}`; }
export {OwstatsF,OwstatsNS,TOwstats};
