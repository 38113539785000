import gql from 'graphql-tag';

export const DeclineInvitation_Mutation = gql`
    mutation DeclineInvitationMutation(
        $input: DeclineInvitationInput!
    ) {
        declineInvitation(
            input: $input
        ) {
            invitationId
        }
    }
`;
