import { useCreateSelector } from '@insights-gaming/redux-utils';
import { TeamSubscriptionFragment } from 'apollo/fragments/types/TeamSubscriptionFragment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import { makeGetTeamSubscriptionByTeamId } from './dashboard-billing.selectors';
import { fetchTeamSubscriptionAC } from './dashboard-billing-slice';

export default function useTeamSubscription(
  teamId?: ID,
): [TeamSubscriptionFragment | undefined, boolean] {
  const dispatch = useDispatch();
  const [teamSubscription, isFetched] = useCreateSelector(makeGetTeamSubscriptionByTeamId, teamId);

  useEffect(() => {
    if (!isFetched && teamId) {
      dispatch(fetchTeamSubscriptionAC.started({teamId}));
    }
  }, [dispatch, isFetched, teamId]);

  return [teamSubscription, isFetched];
}
