import { FlexSpacer } from '@insights-gaming/material-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LoginF, LoginNS } from '../../locales/en/login';
import ButtonLink from '../../material/button-link/ButtonLink';
import { registerRoute, signinRoute } from '../../routes';
import IntercomButton from '../intercom-button/IntercomButton';

const GuestBar: React.FC = () => {
  const { t } = useTranslation([LoginNS]);
  return (
    <FlexSpacer flexAlignItems='center'>
      <IntercomButton />
      <ButtonLink
      size='small'
      to={{
        pathname: signinRoute(),
        state: {referPath: window.location.pathname + window.location.search},
      }}
      >
        {t(LoginF('login'))}
      </ButtonLink>
      <ButtonLink
      color='default'
      variant='outlined'
      size='small'
      to={{
        pathname: registerRoute(),
        state: {referPath: window.location.pathname + window.location.search},
      }}
      >
        {t(LoginF('signup'))}
      </ButtonLink>
    </FlexSpacer>
  );
};

export default GuestBar;
