import { IResumableUpload, LocalTusUpload } from 'types';

export function makeResumableUpload(
  { uploadUrl, video, publicity, uuid }: LocalTusUpload,
  file: File,
): IResumableUpload {
  return {
    uuid,
    video,
    uploadUrl,
    file,
    publicity,
  };
}
