import gql from 'graphql-tag';

import { CommentFragment } from '../fragments';

export const AddVideoComment_Mutation = gql`
    mutation AddVideoCommentMutation(
        $input: AddVideoCommentInput!
    ) {
        addVideoComment(
            input: $input
        ) {
            comment {
                ...CommentFragment
            }
        }
    }
    ${CommentFragment}
`;
