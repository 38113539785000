import { DrawingToolProvider } from 'features/video-replay/DrawingToolContext';
import React from 'react';

import { UseDrawingStateOptions,useDrawingToolContextValue } from './useDrawingState';


export function ProvideDrawingToolContextValue(
  { options, children }: React.PropsWithChildren<{ options?: UseDrawingStateOptions }>,
) {
  return (
    <DrawingToolProvider value={useDrawingToolContextValue(options)}>
      {children}
    </DrawingToolProvider>
  );
}
