import gql from 'graphql-tag';

import { AuthenticationMethodFragment } from '../fragments';

export const RegisterPasskey_Mutation = gql`
    mutation RegisterPasskeyMutation(
        $input: RegisterPasskeyInput!
    ) {
        registerPasskey(
            input: $input
        ) {
            authenticationMethod {
                ...AuthenticationMethodFragment
            }
        }
    }
    ${AuthenticationMethodFragment}
`;
