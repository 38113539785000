import { FlexSpacer } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { updateCommentLikedAC } from 'actions/comment-actions';
import { CommentFragment, CommentFragment_CommentReply } from 'apollo/fragments/types/CommentFragment';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import classNames from 'classnames';
import { NotificationsHelper } from 'components/settings/notification-settings/notifications-helper';
import { makeGetUnreadVideoCommentNotificationsByVideoId } from 'components/settings/notifications/notifications-selector';
import UnreadNotificationIndicator from 'components/settings/notifications/unread-notifications/UnreadNotificationIndicator';
import useUpdateNotification from 'components/settings/notifications/useUpdateNotifications';
import { VirtualizedCommentsContext } from 'features/video-replay/comment-panel/virtualized-comments/VirtualizedCommentsContext';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors/getMe';
import { Video } from 'types/pigeon';

import { CommentHelper } from '../comment-helpers';
import {
  makeGetCommentRepliesByCommentId,
  makeGetCommentRepliesRecordsByCommentId,
  makeGetSavedCommentRepliesByCommentId,
} from '../video-comment-selector';
import VideoComment from '../VideoComment';
import VideoCommentSkeleton from '../VideoCommentSkeleton';
import CommentReply from './CommentReply';
import ReplyFetcher from './ReplyFetcher';
import useFetchLikedUsers from './useFetchLikedUsers';

interface ReplyAreaOwnProps {
  className?: string;
  canReply: boolean;
  comment: CommentFragment;
  video: Video;
  members: MemberFragment[];
  replyFromNotification?: CommentFragment_CommentReply;
}

type ReplyAreaProps = ReplyAreaOwnProps;

const twelvePxInRem = createRemFromPx(12);

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  toggleButtons: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      color: theme.palette.text.deemphasized,
      fontSize: twelvePxInRem,
    },
  },
  replyBox: {
    padding: theme.spacing(0, 2),
  },
  thumbUp: {
    fontSize: twelvePxInRem,
  },
  liked: {
    color: theme.palette.primary.main,
  },
  likes: {
    margin: theme.spacing(0, 0.5),
  },
}), {name: 'ReplyArea'});

function ReplyArea(props: ReplyAreaProps) {
  const classes = useStyles(props);
  const { className, canReply, comment, video, members, replyFromNotification } = props;
  const { t } = useStrictTranslation(['video']);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const me = useSelector(getMe);

  const savedReplies = useCreateSelector(makeGetSavedCommentRepliesByCommentId, {commentId: comment.id});
  const replyIds = useCreateSelector(makeGetCommentRepliesRecordsByCommentId, {
    videoId: video.id,
    commentId: comment.id,
  });
  const replies = useCreateSelector(makeGetCommentRepliesByCommentId, {
    videoId: video.id,
    commentId: comment.id,
  });

  const { handleUpdateNotifications } = useUpdateNotification();

  const [ showReplies, setShowReplies ] = useState(
    replyFromNotification
    ? replyFromNotification.parent === comment.id
    : false,
  );

  const [ showReplyBox, setShowReplyBox ] = useState(false);

  const [ replyText, setReplyText ] = useState<string | undefined>(savedReplies);

  const {
    resizeComment,
  } = useContext(VirtualizedCommentsContext);

  const memberUsers = useMemo(() => {
    return members.map(member => member.user);
  }, [members]);

  const handleOnShowReplyBox = useCallback(() => {
    let str;
    if (CommentHelper.isCommentReply(comment)) {
      str = `@${ comment.user.id } `;
    }
    resizeComment(comment);
    setShowReplyBox(true);
    setReplyText(str);
  }, [comment, resizeComment]);

  useEffect(() => {
    if (replyFromNotification && replyFromNotification.parent === comment.id) {
      resizeComment(comment);
      setShowReplies(true);
    }
  }, [replyFromNotification, comment, resizeComment]);

  const videoCommentNotifications = useCreateSelector(
    makeGetUnreadVideoCommentNotificationsByVideoId,
    {videoId: video.id},
  );

  const replyNotifications = useMemo(() => {
    return videoCommentNotifications
      .filter(NotificationsHelper.isCommentNotification)
      .filter(n => n.comment.__typename === 'CommentReply' && n.comment.parent === comment.id);
  }, [comment, videoCommentNotifications]);

  const toggleShowReplies = useCallback(() => {
    setShowReplies(showReplies => !showReplies);
    resizeComment(comment);
  }, [resizeComment, comment]);

  useEffect(() => {
    if (showReplies && replyNotifications.length) {
      handleUpdateNotifications(replyNotifications);
    }
  }, [handleUpdateNotifications, replyNotifications, showReplies, comment]);

  const handleCancelOnClick = useCallback(() => {
    setShowReplyBox(false);
    setReplyText(undefined);
    resizeComment(comment);
  }, [resizeComment, comment]);

  const replyCount = useMemo(
    () => comment.__typename === 'VideoComment' && comment.replyCount,
    [comment],
  );

  const likeButtonOnClick = useCallback(async () => {
    if (loading) { return; }
    setLoading(true);
    const commentId = comment.id;
    const videoId = video.id;
    const liked = !comment.liked;
    try {
      await promiseSagaDispatch(updateCommentLikedAC, { commentId, videoId, liked });
      enqueueSnackbar(t(comment.liked
        ? 'video:replay.unlikesuccess'
        : 'video:replay.likesuccess'), {variant: 'success'});
    } catch (err) {
      enqueueSnackbar(t(comment.liked
        ? 'video:replay.unlikefailure'
        : 'video:replay.likefailure'), {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [comment.id, comment.liked, enqueueSnackbar, loading, promiseSagaDispatch, t, video.id]);

  const [initialCommentReplyFetch, setInitialCommentReplyFetch ] = useState(true);

  const [likedUsers, likedUsersFetched] = useFetchLikedUsers(comment, video.id);

  const likeTitleDisplay = useMemo(() => {
    if (comment.likes) {
      const otherLikedUserAlias = me
        && likedUsers
        && likedUsers.filter((user) => user.id !== me.id).map((user) => user.alias);

      if (comment.liked && otherLikedUserAlias && otherLikedUserAlias.length > 0) {
        return t('video:replay.likedbyyouand', { alias: otherLikedUserAlias.join(', ') });
      } else if (comment.liked) {
        return t('video:replay.likedbyyou');
      } else if (otherLikedUserAlias && otherLikedUserAlias.length > 0) {
        return t('video:replay.likedby', { alias: otherLikedUserAlias.join(', ') });
      }
    }

    return '';
  }, [comment.liked, comment.likes, likedUsers, me, t]);

  useEffect(() => {
    if (!initialCommentReplyFetch) {
      resizeComment(comment);
    }
  }, [initialCommentReplyFetch, resizeComment, comment]);

  return (
    <Box width={1}>
      <Box className={classes.toggleButtons}>
        <Tooltip title={likeTitleDisplay}>
          <IconButton onClick={likeButtonOnClick} disabled={loading} size='small'>
            <ThumbUpIcon className={classNames(classes.thumbUp, {[classes.liked]: comment.liked})}/>
          </IconButton>
        </Tooltip>
        {comment.likes > 0 && (
          <Typography  variant='caption' className={classes.likes} >
            {comment.likes}
          </Typography>
        )}
        {canReply && (
          <Button onClick={handleOnShowReplyBox} size='small'>
            {t('video:replay.reply')}
          </Button>
        )}
        {comment.replies && (
          <Button
          onClick={toggleShowReplies}
          size='small'
          endIcon={showReplies && replyIds && !replyIds.forward.more ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          >
            <FlexSpacer flexAlignItems='center'>
              {!!replyNotifications.length && <UnreadNotificationIndicator />}
              <div>{t('video:replay.viewcountreply', { count: replyCount })}</div>
            </FlexSpacer>
          </Button>
        )}
        {showReplies && (
          <ReplyFetcher
          videoId={video.id}
          commentId={comment.id}
          setInitialCommentReplyFetch={setInitialCommentReplyFetch}
          />
        )}
      </Box>
      <div className={classes.replyBox}>
        {(showReplyBox || replyText) && (
          <CommentReply
          members={memberUsers}
          handleCancelOnClick={handleCancelOnClick}
          comment={comment}
          videoId={video.id}
          />
        )}
        {showReplies && (initialCommentReplyFetch ? (
          <VideoCommentSkeleton />
        ) : (
          replies.map(reply => (
            <VideoComment
            key={reply.id}
            video={video}
            comment={reply}
            members={members}
            />
          ))
        ))}
      </div>
    </Box>
  );
}

export default React.memo(ReplyArea);
