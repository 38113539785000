import { createSelectState, UseMultiSelectState } from 'features/dashboard/multi-select/useMultiSelectState';
import React from 'react';

export interface VideoReplaySelectedLabelState {
  labels: UseMultiSelectState;
}

export const VideoReplaySelectedLabelsContext = React.createContext<VideoReplaySelectedLabelState>({
  labels: createSelectState(),
});

export const {
  Provider: VideoReplaySelectedLabelsProvider,
  Consumer: VideoReplaySelectedLabelsConsumer,
} = VideoReplaySelectedLabelsContext;
