import { DrawingCanvasEvent, DrawingTool } from '@insights-gaming/drawing-canvas';
import {
  createCanvasActivatedAction,
  createCanvasDeactivatedAction,
  createObjectAddedAction,
  createObjectModifiedAction,
  createObjectsRemovedAction,
  createPingAction,
} from 'factories/kmsessionEventFactory';
import { getLiveSessionCanDraw, getLiveSessionCanvasActivated, getLiveSessionCanvasObjects, getLiveSessionColor, getLiveSessionStateType } from 'features/live-session/live-session-selector';
import { liveSessionAnnotationEventAC, liveSessionGrantEventAC, liveSessionGrantsEventAC, liveSessionHostChangeEventAC, liveSessionPingEventAC, liveSessionRegisteredEventAC, liveSessionSendActionAC, liveSessionStateEventAC } from 'features/live-session/live-session-slice';
import { arrayify } from 'helpers';
import { useHackRef } from 'hooks/useHackRef';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTake } from 'store';
import { DrawingState } from 'subcomponents/video-player-3/drawing-tools-2/useDrawingState';
import { KMSessionOutgoingAction } from 'types/pigeon/kmsession';

export function useDrawingToolLiveSessionListeners(drawingState: DrawingState) {
  const dispatch = useDispatch();
  const canDraw = useSelector(getLiveSessionCanDraw);
  const canvasActivated = useSelector(getLiveSessionCanvasActivated);
  const canvasObjects = useSelector(getLiveSessionCanvasObjects);
  const sessionStateType = useSelector(getLiveSessionStateType);
  const sessionColor = useSelector(getLiveSessionColor);
  const registered = useMemo(() => sessionStateType === 'joined', [sessionStateType]);

  const canvasActivatedRef = useHackRef(canvasActivated);
  const canvasObjectsRef = useHackRef(canvasObjects);
  const canDrawRef = useHackRef(canDraw);
  const sessionColorRef = useHackRef(sessionColor);
  const drawingStateRef = useHackRef(drawingState);

  useEffect(() => {
    // only run when drawing state becomes ready
    if (drawingState.ready && canvasActivatedRef.current) {
      drawingStateRef.current.silentlyClearCanvas();
      drawingStateRef.current.silentlyAddObjects(canvasObjectsRef.current);
      if (sessionColorRef.current) {
        drawingStateRef.current.setColor(sessionColorRef.current);
      }

      if (canDrawRef.current) {
        drawingStateRef.current.setTool(DrawingTool.DEFAULT);
      }
    }
  }, [canDrawRef, canvasActivatedRef, canvasObjectsRef, drawingState.ready, drawingStateRef, sessionColorRef]);

  useEffect(() => {
    if (sessionColor) {
      drawingStateRef.current.setColor(sessionColor);
    }
  }, [drawingStateRef, sessionColor]);

  useTake(liveSessionStateEventAC, ({ payload }) => {
    if (!payload.canvas) {
      return;
    }

    drawingState.silentlyAddObjects(payload.canvas);
  }, [drawingState]);

  useTake(liveSessionRegisteredEventAC, ({ payload }) => {
    if (payload.state.canvas) {
      drawingState.silentlyClearCanvas();
      drawingState.silentlyAddObjects(payload.state.canvas);
      if (canDraw) {
        drawingState.setTool(DrawingTool.DEFAULT);
      }
    }
  }, [canDraw, drawingState]);

  useTake(liveSessionPingEventAC, ({ payload: { x, y } }) => {
    drawingState.renderPing(x, y);
  }, [drawingState]);

  useTake(liveSessionAnnotationEventAC, ({ payload }) => {
    switch (payload.action) {
      case 'object:added':
        drawingState.silentlyAddObjects(arrayify(payload.object));
        break;

      case 'object:modified':
        drawingState.silentlyModifyObjects(arrayify(payload.object));
        break;

      case 'objects:removed':
        drawingState.silentlyRemoveObjects(payload.uuids);
        break;

      case 'canvas:clear':
        drawingState.silentlyClearCanvas();
        break;

      case 'canvas:activate':
        if (canDraw) {
          drawingState.setTool(DrawingTool.DEFAULT);
        }
        break;

      case 'canvas:deactivate':
        drawingState.reset();
        drawingState.setTool(DrawingTool.NONE);
        break;
    }
  }, [canDraw, drawingState]);

  useTake(liveSessionHostChangeEventAC, () => {
    if (!canvasActivatedRef.current || canDraw) {
      return;
    }

    drawingState.setTool(DrawingTool.NONE);
  }, [canDraw]);

  useTake([liveSessionGrantEventAC, liveSessionGrantsEventAC], (action, { liveSessionReducer: { state } }) => {
    // this ensures that the drawing tool is usable after a grant event or a host change
    if (
      state?.type !== 'joined' ||
      !canvasActivatedRef.current ||
      (
        liveSessionGrantEventAC.match(action) &&
        (!action.payload.granted?.includes('annotate') || action.payload.revoked?.includes('annotate'))
      ) ||
      (
        liveSessionGrantsEventAC.match(action) &&
        Array.isArray(action.payload.grants) &&
        !action.payload.grants.includes('annotate')
      )
    ) {
      return;
    }

    drawingState.setTool(DrawingTool.DEFAULT);
  }, [canDraw, drawingState]);

  const sendAction = useCallback((event: KMSessionOutgoingAction) => {
    dispatch(liveSessionSendActionAC.started(event));
  }, [dispatch]);

  const handleCanvasEnabled = useCallback((enabled: boolean) => {
    if (!registered || !drawingStateRef.current.ready) {
      return;
    }

    if (enabled) {
      if (!canvasActivatedRef.current) {
        sendAction(createCanvasActivatedAction());
      }
    } else if (canvasActivatedRef.current) {
      sendAction(createCanvasDeactivatedAction());
    }
  }, [canvasActivatedRef, drawingStateRef, registered, sendAction]);

  const handleObjectsAdded = useCallback((e: DrawingCanvasEvent & { readonly objects: fabric.Object[]}) => {
    sendAction(createObjectAddedAction(e.objects));;
  }, [sendAction]);

  const handleObjectsModified = useCallback((e: DrawingCanvasEvent & { readonly objects: fabric.Object[]}) => {
    sendAction(createObjectModifiedAction(e.objects));
  }, [sendAction]);

  const handleObjectsRemoved = useCallback((e: DrawingCanvasEvent & { readonly objects: fabric.Object[]}) => {
    sendAction(createObjectsRemovedAction(e.objects));
  }, [sendAction]);

  const handlePing = useCallback((e: DrawingCanvasEvent & { x: number, y: number }) => {
    sendAction(createPingAction(e.x, e.y));
  }, [sendAction]);

  return {
    onCanvasEnabled: handleCanvasEnabled,
    onObjectsAdded: handleObjectsAdded,
    onObjectsModified: handleObjectsModified,
    onObjectsRemoved: handleObjectsRemoved,
    onPing: handlePing,
  };
}
