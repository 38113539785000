import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx,Theme, WithMakeStyles } from '@insights-gaming/theme';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import classNames from 'classnames';
import UnreadNotificationIndicator from 'components/settings/notifications/unread-notifications/UnreadNotificationIndicator';
import { RawDraftEntity } from 'draft-js';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors/getMe';
import { ID } from 'types/pigeon';

import { CommentFragment } from '../../../../apollo/fragments/types/CommentFragment';
import { VideoF, VideoNS } from '../../../../locales/en/video';
import UserAvatar from '../../../../material/user-avatar/UserAvatar';

export interface IVideoCommentContentOwnProps {
  comment: CommentFragment;
  commentNotification?: NotificationFragment;
}

type Props = IVideoCommentContentOwnProps & WithMakeStyles<typeof useStyles>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(0.5, 2),
    '&:last-child':{
      paddingBottom: theme.spacing(0.5),
    },
    '&$isMentioned': {
      borderRadius: createRemFromPx(3),
      backgroundColor: '#292C38',
    },
  },
  senderAvatar: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
  },
  senderRow: {
    display: 'flex',
    maxWidth: 'inherit',
    overflowX: 'hidden',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(3),
  },
  senderName: {
    whiteSpace: 'nowrap',
    flex: 1,
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    fontWeight : 'bold',
    textOverflow: 'ellipsis',
  },
  isMentioned: {},
}), {name: 'VideoCommentContent'});

function VideoCommentContent(props: PropsWithChildren<Props>) {
  const { children, comment, commentNotification } = props;
  const classes = useStyles(props);
  const { t } = useTranslation([VideoNS]);

  const me = useSelector(getMe);

  const createMentionedUserIds = useCallback((comment: CommentFragment) => {
    if (!comment.message) { return new Set(); }

    try {
      const entityMap: RawDraftEntity[] = Object.values(JSON.parse(comment.message).entityMap);
      return new Set<ID>(entityMap.map(entity => entity.type === 'MENTION' ? entity.data?.id : null));
    } catch {
      return new Set();
    }
  }, []);

  const mentionedUserIds = createMentionedUserIds(comment);

  const isMentioned = useMemo(() => mentionedUserIds.has(me?.id), [me?.id, mentionedUserIds]);

  return (
    <CardContent className={classNames(classes.root, {[classes.isMentioned]: isMentioned})}>
      <FlexSpacer className={classes.senderRow}>
        <div>
          <UserAvatar user={comment.user} size='xs' />
        </div>
        <span className={classes.senderName} title={comment.user.alias}>
          {comment.user.alias}
        </span>
        <FlexSpacer flexAlignItems='center'>
          {commentNotification && (
            <UnreadNotificationIndicator />
          )}
          <Typography variant='caption' component='span'>
            {t(VideoF('replay.messagereltime'), {date: comment.modified || comment.created})}
          </Typography>
          {!comment.deleted && comment.modified && (
            <Typography variant='caption' component='span'>
              [{t(VideoF('replay.edited'))}]
            </Typography>
          )}
        </FlexSpacer>
      </FlexSpacer>
      {children}
    </CardContent>
  );
}

export default React.memo(VideoCommentContent);
