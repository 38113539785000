type TLogin =
|'firstname'
|'lastname'
|'email'
|'password'
|'confirmpw'
|'rememberme'
|'forgotpw'
|'login'
|'signup'
|'noaccount'
|'newsletter'
|'disclaimer'
|'haveaccount'
|'accountcreated'
|'verifyemail'
|'checkemail'
|'didntreceive'
|'resendemail'
|'emailsent'
|'emailverified'
|'passwordreset'
|'passwordresetemail'
|'resetpassword'
|'resetpasswordsuccess'
|'signupwithgoogle'
|'signupwithemail'
|'signupwithdiscord'
|'signinwithgoogle'
|'signinwithdiscord'
|'goback'
|'backtologin'
|'sendlink'
|'resendlink'
|'forgotpwDescription'
|'existingemail'
|'errors.loginfailed'
|'errors.signupfailed'
|'errors.resetfailed'
|'errors.badcredentials'
|'errors.toomanyattempts'
|'errors.formincomplete'
|'errors.emailexists'
|'errors.weakpassword'
|'errors.notverified'
|'errors.invalidverifylink'
|'errors.invalidresetlink'
|'errors.passwordmismatch'
|'errors.passwordrequired'
|'errors.networkerror'
;
const LoginNS = 'login';
function LoginF(_: TLogin): string { return `${LoginNS}:${_}`; }
export {LoginF,LoginNS,TLogin};
