import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';
import { getTeamRecords } from 'features/dashboard/team/dashboard-team-selector';
import { fetchTeamsAC } from 'features/dashboard/team/dashboard-team-slice';
import ShowLoadingScreen from 'features/loading-screen/ShowLoadingScreen';
import React from 'react';

const useFetchTeams = createBidirectionalAutoFetcher({
  actionCreators: fetchTeamsAC,
  pageSize: 100,
  selectorFactory: () => getTeamRecords,
  paramsTransform: teamId => ({}),
});

function TeamFetcher() {
  const [status] = useFetchTeams('asdf');
  return status.fetching && !status.cursor ? <ShowLoadingScreen/> : null;
}

export default React.memo(TeamFetcher);
