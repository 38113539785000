import { FlexSpacer } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { LeagueOfLegendsItems, LoLHelper, Riot, StatisticsTableRow } from '@insights-gaming/statistics';
import { Theme } from '@insights-gaming/theme';
import Avatar from '@material-ui/core/Avatar';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo } from 'react';

import { makeGetLeagueAssetsByGameVersion } from '../common';


interface LeagueOfLegendsTeamStatsTableRowOwnProps {
  className?: string;
  participant: Riot.League.RawDataInfoParticipant;
  mostDmgDoneToChamp: number;
  gameVersion: string;
}

type LeagueOfLegendsTeamStatsTableRowProps = LeagueOfLegendsTeamStatsTableRowOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  championIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  items: {
    display: 'grid',
    gap: theme.spacing(0.5),
  },
  item: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    '&:nth-child(n + 4)': {
      gridRow: 2,
    },
    '&:nth-child(7)': {
      gridColumn: 4,
      gridRow: 1,
    },
  },
  noItem: {
    border: '1px dotted black',
  },
  dmgBarContainer: {
    width: 50,
    height: 4,
    backgroundColor: theme.palette.text.primary,
  },
  dmgBar: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    border: `1px solid ${theme.palette.primary.main}`,
  },
}), { name: 'LeagueOfLegendsTeamStatsTableRow' });

function LeagueOfLegendsTeamStatsTableRow(props: LeagueOfLegendsTeamStatsTableRowProps) {
  const classes = useStyles(props);
  const { className, participant, mostDmgDoneToChamp, gameVersion } = props;

  const { t } = useStrictTranslation(['statistics']);

  const itemsAssets = useCreateSelector(makeGetLeagueAssetsByGameVersion, { gameVersion })?.items;

  const renderDmgBar = useCallback(() => {
    const percent = (participant.totalDamageDealtToChampions / mostDmgDoneToChamp) * 100 + '%';
    return (
      <FlexSpacer orientation='vertical' spacing={0} flexAlignItems='center'>
        <div>{t(
          'statistics:stats.lol.dmgInThousands',
          { dmg: (participant.totalDamageDealtToChampions / 1000).toFixed(2) },
        )}</div>
        <div className={classes.dmgBarContainer}>
          <div className={classes.dmgBar} style={{ width: percent }} />
        </div>
      </FlexSpacer>
    );
  }, [mostDmgDoneToChamp, classes.dmgBar, classes.dmgBarContainer, participant, t]);

  const participantStatsData = useMemo(() => {
    return [
      {
        id: 'team',
        data: (
          <Avatar
          className={classes.championIcon}
          src={LoLHelper.getChampionIconSrc(participant.championId, gameVersion)}
          />
        ),
      },
      {
        id: 'kda',
        data: `${participant.kills}/${participant.deaths}/${participant.assists}`,
      },
      {
        id: 'cs',
        data: t('statistics:stats.lol.creepscore', {
          creepScore: participant.totalMinionsKilled + participant.neutralMinionsKilled,
        }),
      },
      {
        id: 'dmgDealt',
        data: renderDmgBar(),
      },
      {
        id: 'items',
        data: (
          <LeagueOfLegendsItems
          items={participant.items}
          itemAssets={itemsAssets || []}
          gameVersion={gameVersion}
          />
        ),
      },
    ];
  }, [
    classes.championIcon,
    participant.championId,
    participant.kills,
    participant.deaths,
    participant.assists,
    participant.totalMinionsKilled,
    participant.neutralMinionsKilled,
    participant.items,
    gameVersion,
    t,
    renderDmgBar,
    itemsAssets,
  ]);

  return (
    <StatisticsTableRow className={classNames(classes.root, className)} data={participantStatsData} />
  );
}

export default React.memo(LeagueOfLegendsTeamStatsTableRow);
