import { createSlice } from '@reduxjs/toolkit';
import { GameType } from 'helpers';
import { RootReducer } from 'rootReducer';
import { Action } from 'typescript-fsa';

const name = 'drawing-tool';

export interface IDrawingToolState {
  mapTabSelection: GameType;
  stickerTabSelection: number;
}

const initialState: IDrawingToolState = {
  mapTabSelection: 'overwatch',
  stickerTabSelection: 0,
};

const drawingToolSlice = createSlice({
  name,
  initialState,
  reducers: {
    setMapTabSelectionAC(state, { payload }: Action<GameType>) {
      return { ...state, mapTabSelection: payload };
    },
    setStickerTabSelectionAC(state, { payload }: Action<number>) {
      return { ...state, stickerTabSelection: payload };
    },
  },
});

export const drawingToolReducer = drawingToolSlice.reducer;
export const { setMapTabSelectionAC, setStickerTabSelectionAC } = drawingToolSlice.actions;

export function getMapTabSelection(state: RootReducer) {
  return state.drawingToolReducer.mapTabSelection;
}

export function getStickerTabSelection(state: RootReducer) {
  return state.drawingToolReducer.stickerTabSelection;
}
