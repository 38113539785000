import { FlexSpacer } from '@insights-gaming/material-components';
import { useBidirectionalFetchStatus } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { makeGetGlobalRoles } from 'components/global-roles/global-role-selector';
import MemberTable from 'components/member-table/MemberTable';
import { EIntercomID } from 'constants/strings';
import { useCreateSelector, useParametricSelectorFactory } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import InviteTeamMembersDialogContent from 'material/dialogs/invite-team-members-dialog-content/InviteTeamMembersDialogContent';
import React, { useMemo } from 'react';

import { useAccessControl } from '../access-control/useAccessControl';
import { makeGetTeamMemberRecordsByTeamId, makeGetTeamMembersByTeamId } from '../member/dashboard-member-selector';
import { makeGetTeamCustomRolesByTeamId } from '../role/dashboard-role-selector';

interface DashboardTeamSettingsMembersOwnProps {
  className?: string;
  team: TeamFragment;
}

type DashboardTeamSettingsMembersProps = DashboardTeamSettingsMembersOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DashboardTeamSettingsMembers'});

function DashboardTeamSettingsMembers(props: DashboardTeamSettingsMembersProps) {
  const classes = useStyles(props);
  const { className, team } = props;
  const { t } = useStrictTranslation(['dashboard-settings', 'dashboard']);

  const { canCreateTeamInvitationLink } = useAccessControl();

  const [
    isInviteTeamMembersDialogOpen,
    openInviteTeamMembersDialog,
    closeInviteTeamMembersDialog,
  ] = useDialogState();

  const getTeamMemberRecords = useParametricSelectorFactory(
    makeGetTeamMemberRecordsByTeamId,
    team.id,
  );

  const { forward: membersForwardStatus } = useBidirectionalFetchStatus(getTeamMemberRecords);

  const members = useCreateSelector(makeGetTeamMembersByTeamId, team.id);

  const customRoles = useCreateSelector(makeGetTeamCustomRolesByTeamId, {teamId: team.id});
  const globalRoles = useCreateSelector(makeGetGlobalRoles, '');

  const roles: RoleInterfaceFragment[] = useMemo(() => {
    return [...globalRoles, ...customRoles];
  }, [globalRoles, customRoles]);

  const initialMemberFetch = membersForwardStatus.fetching && !members.length;

  return (
    <FlexSpacer
    id={EIntercomID.MEMBERS}
    orientation='vertical'
    flexAlignItems='start'
    className={(classNames(classes.root, className))}
    >
      {canCreateTeamInvitationLink && (
        <Button
        id={EIntercomID.ADD_MEMBERS}
        variant='outlined'
        startIcon={<AddIcon />}
        onClick={openInviteTeamMembersDialog}
        size='small'
        >
          {t('dashboard-settings:members.addmember')}
        </Button>
      )}
      <MemberTable
      members={members}
      roles={roles}
      team={team}
      displaySkeleton={initialMemberFetch}
      openInviteTeamMembersDialog={openInviteTeamMembersDialog}
      />
      <Dialog open={isInviteTeamMembersDialogOpen} onClose={closeInviteTeamMembersDialog} fullWidth={true}>
        <InviteTeamMembersDialogContent
        teamId={team.id}
        onClose={closeInviteTeamMembersDialog}
        />
      </Dialog>
    </FlexSpacer>
  );
}

export default React.memo(DashboardTeamSettingsMembers);
