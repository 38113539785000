import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import classNames from 'classnames';
import { makeGetUnreadVideoUploadedNotificationByVideoId } from 'components/settings/notifications/notifications-selector';
import useUpdateNotification from 'components/settings/notifications/useUpdateNotifications';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { DragItem, ItemTypes } from 'features/dashboard/dnd/item-types';
import { DashboardMultiSelectContext } from 'features/dashboard/multi-select/DashboardMultiSelectContext';
import VideoMenuButton from 'features/video-menu/VideoMenuButton';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDrag, useDragLayer } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import ReactResizeDetector from 'react-resize-detector';
import { videoRoute } from 'routes';

import DetailedVideoCard from './DetailedVideoCard';
import { useVideoThumbnail } from './useVideoThumbnail';

interface DragVideoCardOwnProps {
  className?: string;
  video: VideoFragment;
  selected?: boolean;
  canDrag?: boolean;
  onClick?: (e: React.MouseEvent, video: VideoFragment) => void;
  onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>, video: VideoFragment) => void;
  selectionMode?: boolean;
  addToVideoSelection?: (ids: string[]) => void;
  removeFromVideoSelection?: (ids: string[]) => void;
  shouldHideMenu?: boolean;
}

type DragVideoCardProps = DragVideoCardOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&$isDragging': {
      opacity: .5,
    },
  },
  isDragging: {},
}), {name: 'DragVideoCard'});

function DragVideoCard(props: DragVideoCardProps) {
  const classes = useStyles(props);
  const {
    className,
    video,
    onClick,
    onCheckboxChange,
    selected = false,
    canDrag = true,
    selectionMode,
    addToVideoSelection,
    removeFromVideoSelection,
    shouldHideMenu,
  } = props;

  const { videos: videoMultiSelect, folders: folderMultiSelect } = useContext(DashboardMultiSelectContext);

  const cardRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(0);

  const { canOpenVideoMenu } = useAccessControl();

  const thumbnailUrl = useVideoThumbnail(video, width);

  const { layerIsDragging } = useDragLayer(monitor => ({
    layerIsDragging: monitor.isDragging(),
  }));

  const dragItem: DragItem = {
    type: ItemTypes.VIDEO_CARD,
    id: video.id,
    name: video.name,
    selection: {
      videos: new Set([...videoMultiSelect.selected, video.id]),
      folders: folderMultiSelect.selected,
    },
    // temporarily disabled to get staging to work
    // parentId: video.directory?.id,
    thumbnailUrl,
    width,
    height,
  };
  const [{ isDragging }, drag, preview] = useDrag({
    item: dragItem,
    canDrag,
    begin: monitor => {
      videoMultiSelect?.addToSelection([video.id]);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const uploadedVideoNotification = useCreateSelector(
    makeGetUnreadVideoUploadedNotificationByVideoId,
    {videoId: video.id},
  );

  const { handleUpdateNotifications } = useUpdateNotification();

  const handleMarkNotificationAsRead = useCallback(() => {
    handleUpdateNotifications(uploadedVideoNotification);
  }, [handleUpdateNotifications, uploadedVideoNotification]);

  const handleClick = useCallback((e: React.MouseEvent) => {
    if (selectionMode || e.ctrlKey) {
      e.preventDefault();
      e.stopPropagation();
      return selected ? removeFromVideoSelection?.([video.id]) : addToVideoSelection?.([video.id]);
    }
    onClick?.(e, video);
    handleMarkNotificationAsRead();
  }, [
    selectionMode,
    onClick,
    video,
    handleMarkNotificationAsRead,
    selected,
    removeFromVideoSelection,
    addToVideoSelection,
  ]);

  const handleCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange?.(e, video);
  }, [onCheckboxChange, video]);

  const handleResize = useCallback((width: number, height: number) => {
    setWidth(width);
    setHeight(height);
  }, []);

  drag(cardRef);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <DetailedVideoCard
    ref={cardRef}
    className={classNames(classes.root, {
      [classes.isDragging]: isDragging || layerIsDragging && videoMultiSelect.selected.has(video.id),
    }, className)}
    name={video.name}
    selected={selected}
    disabled={isDragging}
    thumbnail={thumbnailUrl}
    onClick={handleClick}
    video={video}
    contextMenu={canOpenVideoMenu && !shouldHideMenu && (
      <VideoMenuButton size='small' video={video} />
    )}
    link={onClick ? undefined : videoRoute(video.id)}
    overlay={onCheckboxChange && (
      <Checkbox checked={selected} onChange={handleCheckboxChange} />
    )}
    newVideo={!!uploadedVideoNotification}
    >
      <ReactResizeDetector handleWidth={true} handleHeight={true} skipOnMount={false} onResize={handleResize} />
    </DetailedVideoCard>
  );
}

export default React.memo(DragVideoCard);
