import React from 'react';

import { createSelectState, UseMultiSelectState } from '../multi-select/useMultiSelectState';

export interface DashboardSelectedTagState {
  tags: UseMultiSelectState;
}

export const DashboardSelectedTagsContext = React.createContext<DashboardSelectedTagState>({
  tags: createSelectState(),
});

export const {
  Provider: DashboardSelectedTagsProvider,
  Consumer: DashboardSelectedTagsConsumer,
} = DashboardSelectedTagsContext;
