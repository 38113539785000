import { AsyncButton } from '@insights-gaming/material-components/AsyncButton';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import MultiTypeVideoSelector from 'components/multi-type-video-selector/MultiTypeVideoSelector';
import SelectedVideoChip from 'components/selected-video-chip/SelectedVideoChip';
import { useRealizeSelectedVideo } from 'hooks/useRealizeSelectedVideo';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { ID } from 'types/pigeon';


interface ChangeVideoDialogContentOwnProps {
  teamId: ID;
  directoryId: ID;
  onClose: () => void;
  onVideoChange: (video: VideoFragment) => any;
  teamPicker?: boolean;
}

type ChangeVideoDialogContentProps = ChangeVideoDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}));

function ChangeVideoDialogContent(props: ChangeVideoDialogContentProps) {
  const { directoryId, onClose, onVideoChange, teamId, teamPicker } = props;

  const classes = useStyles(props);
  const { t } = useStrictTranslation(['common', 'dialog']);
  const { enqueueSnackbar } = useSnackbar();
  const realizeSelectedVideo = useRealizeSelectedVideo(teamId, directoryId);

  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState<VideoFragment | InsightsEmbedResponse>();

  const handleSubmit = useCallback(async () => {
    if (loading || !video) {
      return;
    }

    setLoading(true);

    try {
      onVideoChange(await realizeSelectedVideo(video));
      onClose();
    } catch (error) {
      enqueueSnackbar(error.message);
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    loading,
    onClose,
    onVideoChange,
    realizeSelectedVideo,
    video,
  ]);

  return (
    <React.Fragment>
      <BetterDialogTitle>
        {t('dialog:videochange.change')}
      </BetterDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <MultiTypeVideoSelector
        teamId={teamId}
        initialDirectoryId={directoryId}
        onChange={setVideo}
        />
        {video && <SelectedVideoChip video={video}/>}
      </DialogContent>
      <DialogActions>
        <Button
        type='button'
        variant='outlined'
        onClick={onClose}
        >
          {t('common:cancel')}
        </Button>
        <AsyncButton
        variant='contained'
        color='primary'
        loading={loading}
        disabled={loading || !video}
        onClick={handleSubmit}
        >
          {t('dialog:videochange.change')}
        </AsyncButton>
      </DialogActions>
    </React.Fragment>
  );
}

interface ChangeVideoDialogOwnProps {}

type ChangeVideoDialogProps = DialogProps & ChangeVideoDialogOwnProps & ChangeVideoDialogContentProps;

function ChangeVideoDialog({
  teamId,
  directoryId,
  onClose,
  onVideoChange,
  teamPicker,
  ...props
}: ChangeVideoDialogProps) {
  return (
    <Dialog {...props} onClose={onClose}>
      <ChangeVideoDialogContent
      teamId={teamId}
      directoryId={directoryId}
      onClose={onClose}
      onVideoChange={onVideoChange}
      teamPicker={teamPicker}
      />
    </Dialog>
  );
}

export default React.memo(ChangeVideoDialog);
