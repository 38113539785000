import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import classNames from 'classnames';
import { testPasswords } from 'helpers';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ESettingsTabType, settingsRoute } from 'routes';

import { PASSWORD_RESET_ENDPOINT } from '../../../constants';

interface ChangePasswordFormOwnProps {
  className?: string;
  hasEmail?: boolean;
  hasPassword?: boolean;
}

type ChangePasswordFormProps = ChangePasswordFormOwnProps;

interface IFormData {
  currentpw: string;
  newpw: string;
  confirmpw: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: createRemFromPx(500),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  container: {
    padding: 0,
  },
  profilePicture: {
    marginBottom: theme.spacing(4),
  },
  emailContainer: {
    marginBottom: theme.spacing(3),
  },
  checkboxContainer: {
    marginBottom: theme.spacing(2),
  },
  profileInfo: {
    wordBreak: 'break-all',
  },
}), {name: 'ChangePasswordForm'});

function ChangePasswordForm(props: ChangePasswordFormProps) {
  const classes = useStyles(props);
  const { className, hasEmail, hasPassword } = props;

  const initialFields = useMemo(() => {
    return {
      currentpw: '',
      newpw: '',
      confirmpw: '',
    };
  }, []);

  const { t } = useStrictTranslation(['settings', 'alert', 'login']);
  const { enqueueSnackbar } = useSnackbar();

  const [ loading, setLoading ] = useState(false);
  const [ fields, setFields ] = useState<IFormData>(initialFields);

  const getErrorMessage = useCallback((error: Error) => {
    switch (error.message) {
      default: return error.message;
    }
  }, []);

  const handleCheckboxOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFields((prevState) => ({ ...prevState, [name]: checked }));
  }, []);

  const handleFormInputOnChange = useCallback((e: React.SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setFields((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  const disabled = useMemo(
    () => isEqual(initialFields, fields) || (hasPassword && !fields.currentpw) || !fields.newpw,
    [fields, hasPassword, initialFields],
  );

  const handleFormOnSubmit = useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (loading) { return; }

    const { currentpw, newpw, confirmpw } = fields;
    if (disabled) {
      enqueueSnackbar(t('login:errors.formincomplete'), { variant: 'error' });
      return;
    }

    const msg = testPasswords(newpw, confirmpw);
    if (msg) {
      enqueueSnackbar(t(`login:${msg}`), { variant: 'error' });
      return;
    }

    try {
      const res: Response = await fetch(PASSWORD_RESET_ENDPOINT, {
        method: 'POST',
        body: new URLSearchParams({
          current_password: currentpw,
          new_password: newpw,
        }),
      });
      if (!res.ok) {
        const { error } = await res.json();
        enqueueSnackbar(error, {variant: 'error'});
      } else {
        enqueueSnackbar(t('alert:changessaved'), {variant: 'success'});
      }
    } catch (error) {
      enqueueSnackbar(getErrorMessage(error), {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, fields, getErrorMessage, disabled, loading, t]);

  return (
    <React.Fragment>
      {
        !hasEmail && (
          <Alert
          severity='info'
          action={
            <Button component={Link} to={settingsRoute(ESettingsTabType.PROFILE)} size='small'>
              {t('settings:tabs.profile')}
            </Button>
          }
          >
            {t('settings:pwneedsemail')}
          </Alert>
        )
      }
      <div className={classNames(classes.root, className)}>
        <form onSubmit={handleFormOnSubmit}>
          <Box>
            {hasPassword && (
            <Box className={classes.formField}>
              <TextField
              id='currentpw'
              name='currentpw'
              type='password'
              label={t('settings:currentpw')}
              placeholder={t('settings:currentpw')}
              value={fields.currentpw}
              onChange={handleFormInputOnChange}
              disabled={!hasEmail}
              required={true}
              fullWidth={true}
              />
            </Box>
          )}
            <Box className={classes.formField}>
              <TextField
              id='newpw'
              name='newpw'
              type='password'
              label={t(`settings:${hasPassword ? 'newpw' : 'createpw'}`)}
              placeholder={t(`settings:${hasPassword ? 'newpw' : 'createpw'}`)}
              value={fields.newpw}
              onChange={handleFormInputOnChange}
              disabled={!hasEmail}
              required={true}
              fullWidth={true}
              />
            </Box>
            <Box className={classes.formField}>
              <TextField
              id='confirmpw'
              name='confirmpw'
              type='password'
              label={t('settings:confirmpw')}
              placeholder={t('settings:confirmpw')}
              value={fields.confirmpw}
              onChange={handleFormInputOnChange}
              disabled={!hasEmail}
              required={true}
              fullWidth={true}
              />
            </Box>
          </Box>
          <FlexSpacer flexJustifyContent='flex-end' spacing={2}>
            <AsyncButton
            type='submit'
            color='primary'
            variant='contained'
            loading={loading}
            disabled={loading || disabled}
            >
              {t(`settings:${hasPassword ? 'changepw' : 'setpw'}`)}
            </AsyncButton>
          </FlexSpacer>
        </form>
      </div>
    </React.Fragment>
  );
}

export default React.memo(ChangePasswordForm);
