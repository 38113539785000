import AccessControlInjector from 'features/dashboard/access-control/AccessControlInjector';
import { useFetchVideoIfNecessary } from 'features/dashboard/video/useFetchVideoIfNecessary';
import { LoadingContext } from 'features/loading-screen/context';
import { useLoadingScreen } from 'features/loading-screen/useLoadingScreen';
import { VideoHelper } from 'features/video-library/video-helpers';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { EVideoViewerTabType } from 'routes/tabs';
import { getMe } from 'selectors/getMe';

import CannotAccess from '../cannot-access/CannotAccess';
import VideoViewer from './VideoViewer';

function VideoPage() {
  const { videoId, tab } = useParams<{videoId: string, tab?: EVideoViewerTabType}>();

  const [video, status] = useFetchVideoIfNecessary(videoId, true);
  const loadingContext = useContext(LoadingContext);
  const me = useSelector(getMe);
  useLoadingScreen(!status || status.fetching || (status.done && !loadingContext?.ready && !me));

  const { t } = useStrictTranslation(['video']);

  if (status?.error) {
    return (
      <CannotAccess>
        {t('video:noaccess.reason1')}
        {t('video:noaccess.reason2')}
        {t('video:noaccess.reason3')}
      </CannotAccess>
    );
  }

  return (
    <AccessControlInjector
    teamId={video && VideoHelper.getTeamId(video)}
    divisionId={video && VideoHelper.getDivisionId(video)}
    >
      <VideoViewer key={videoId} videoId={videoId} tab={tab as EVideoViewerTabType | undefined} />
    </AccessControlInjector>
  );
}

export default React.memo(VideoPage);
