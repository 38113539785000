import { AsyncButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { updateVideoCommentAC } from 'actions/comment-actions';
import { CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import classNames from 'classnames';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { DrawingState } from 'subcomponents/video-player-3/drawing-tools-2/useDrawingState';
import { ID } from 'types/pigeon';

interface EditDrawingPanelOwnProps {
  className?: string;
  videoId: ID;
  drawingState: DrawingState;
  comment?: CommentFragment_VideoComment;
  cancelEditing: VoidFunction;
}

type EditDrawingPanelProps = EditDrawingPanelOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}), {name: 'EditDrawingPanel'});

function EditDrawingPanel(props: EditDrawingPanelProps) {
  const classes = useStyles(props);
  const { className, drawingState, videoId, comment, cancelEditing } = props;
  const { t } = useStrictTranslation(['video']);
  const [ loading, setLoading ] = useState(false);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const disabled = !drawingState.hasObjects || !drawingState.canUndo;

  const saveDrawingOnClick = useCallback(async () => {

    if (disabled || loading || !comment) {
      return;
    }

    setLoading(true);

    try {
      const serializedCanvas = await drawingState.serializedCanvas();
      await promiseSagaDispatch(updateVideoCommentAC, {
        videoId,
        commentId: comment.id,
        time: comment.time,
        annotation: serializedCanvas,
      });
      cancelEditing();
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [cancelEditing, comment, disabled, drawingState, enqueueSnackbar, loading, promiseSagaDispatch, videoId]);

  return (
    <div className={classNames(classes.root, className)}>
      <Button variant='outlined' onClick={cancelEditing}>
        {t('video:replay.cancel')}
      </Button>
      <AsyncButton
      variant='contained'
      color='primary'
      onClick={saveDrawingOnClick}
      disabled={disabled || loading}
      className={classes.button}
      loading={loading}
      >
        {t('video:replay.savedrawing')}
      </AsyncButton>
    </div>
  );
}

export default React.memo(EditDrawingPanel);
