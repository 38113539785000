import { FlexSpacer, Loader } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Typography from '@material-ui/core/Typography';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LogoProgress } from 'assets/logos';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { CommonNS } from 'locales/en/common';
import TransparentDialog from 'material/transparent-dialog/TransparentDialog';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { LoadingContext } from './context';

interface LoadingScreenProps {
  children?: ReactNode;
  loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  message: {
    whiteSpace: 'pre-line',
  },
}), { name: 'LoadingScreen' });

function LoadingScreen(props: LoadingScreenProps) {
  const classes = useStyles(props);
  const { children, loading } = props;
  const { t } = useStrictTranslation(CommonNS);

  const [initialized, setInitialized] = useState(false);
  const init = useCallback(() => {
    if (initialized) {
      return false;
    }

    setInitialized(true);
    return true;
  }, [initialized]);

  const [count, setCount] = useState(0);
  const add = useCallback((n: number = 1) => setCount((count) => (init() || count > 0) ? count + n : count), [init]);
  const done = useCallback(() => setCount((count) => count > 0 ? count - 1 : count), []);
  const auto = useCallback(() => {
    add();
    return () => done();
  }, [add, done]);

  const ready = initialized && count <= 0;

  const controller = useMemo(() => ({ add, done, auto, ready }), [add, auto, done, ready]);

  const ref = useRef(controller);

  useEffect(() => {
    ref.current = controller;
  }, [controller]);

  useEffect(() => {
    (loading ? ref.current.add : ref.current.done)();
  }, [loading]);

  const messages = useMemo(() => {
    const icons = {
      keyboardIcon: <KeyboardIcon />,
    };
    // NOTE: object notation typing not supported by react-i18next and typed-i18next
    //       (https://www.i18next.com/translation-function/objects-and-arrays)
    const length = t('apploadmessages' as any, { returnObjects: true }).length;
    const key = `apploadmessages.${Math.floor(Math.random() * length)}`;
    return (
      <Typography key={key} className={classes.message} component={FlexSpacer} flexAlignItems='center'>
        <Trans i18nKey={key} ns={CommonNS} components={icons} />
      </Typography>
    );
  }, [classes.message, t]);

  return (
    <LoadingContext.Provider value={controller}>
      {
        !ready ? (
          <React.Fragment>
            <TransparentDialog open={true}>
              <Loader
              progress={<LogoProgress size={96} key='logoprogress'/>}
              >
                {messages}
              </Loader>
            </TransparentDialog>
            <div style={{ display: 'none' }}>{children}</div>
          </React.Fragment>
        ) : children
      }
    </LoadingContext.Provider>
  );
}

export default React.memo(LoadingScreen);
