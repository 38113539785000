import { useCreateSelector } from '@insights-gaming/redux-utils';
import { GetAvailableTeamResourceQuery_team_resources } from 'apollo/queries/types/GetAvailableTeamResourceQuery';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { TeamResource } from 'types/graphql';
import { ID } from 'types/pigeon';

import { makeGetAvailableTeamResourceById } from '../team/dashboard-team-selector';
import { fetchAvailableTeamResourceAC } from '../team/dashboard-team-slice';

export default function useTeamAvailableResource(
  teamId: ID,
): Record<TeamResource, GetAvailableTeamResourceQuery_team_resources | undefined> | undefined {
  const dispatch = useDispatch();
  const [availableTeamResource, isFetched] = useCreateSelector(makeGetAvailableTeamResourceById, teamId);

  useEffect(() => {
    if (!isFetched) {
      dispatch(fetchAvailableTeamResourceAC.started({teamId}));
    }
  }, [dispatch, isFetched, teamId]);

  return useMemo(() => {
    if (!isFetched) {
      return;
    }

    return Object.fromEntries(
      availableTeamResource?.map((resource) => [resource.resource, resource]) || [],
    )  as Record<TeamResource, GetAvailableTeamResourceQuery_team_resources>;
  }, [availableTeamResource, isFetched]);
}
