export const MAX_NOTIFICATIONS = 99;

export enum ENotificationState {
  DELETED = 'DELETED',
  READ    = 'READ',
  UNREAD  = 'UNREAD',
  VIEWED  = 'VIEWED',
}

export enum ENotificationSelect {
  UNREAD = 'UNREAD',
  ALL = 'ALL',
}
