import { DrawingTool } from '@insights-gaming/drawing-canvas';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import { EIntercomID } from 'constants/strings';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useRef } from 'react';

import ControlsGenericButton from '../controls/ControlsGenericButton';
import MapDialogContent from '../drawing-tools/map-dialog-content/MapDialogContent';
import { AnnotationMap } from '../drawing-tools/map-images';
import { DrawingState } from './useDrawingState';

export interface DrawingToolbarMapButtonProps {
  state: DrawingState;
  disabled: boolean;
}

function DrawingToolbarMapButton({
  state: { setImageUnderlay, setTool },
  disabled,
}: DrawingToolbarMapButtonProps) {
  const { t } = useStrictTranslation(['videoplayer']);
  const [ isOpen, openDialog, closeDialog ] = useDialogState();

  const selectMap = useCallback((map: AnnotationMap) => {
    setImageUnderlay(map.image, map.key);
    setTool(DrawingTool.SELECT);
  }, [setImageUnderlay, setTool]);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <ControlsGenericButton
      disabled={disabled}
      id={EIntercomID.MAP_OVERLAY}
      onClick={openDialog}
      >
        <Tooltip title={t('videoplayer:tools.map')} PopperProps={{ container: ref.current }}>
          <MapOutlinedIcon />
        </Tooltip>
      </ControlsGenericButton>
      <Dialog
      open={isOpen}
      onClose={closeDialog}
      fullWidth={true}
      maxWidth='lg'
      disablePortal={true}
      style={{ cursor: 'default' }}
      >
        <MapDialogContent
        onClose={closeDialog}
        onMapSelected={selectMap}
        />
      </Dialog>
    </div>
  );
}

export default React.memo(DrawingToolbarMapButton);
