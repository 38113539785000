import { Skeleton } from '@material-ui/lab';
import React from 'react';

import GlobalRoleTableRow from './GlobalRoleTableRow';

function GlobalRoleRowSkeleton() {
  return (
    <GlobalRoleTableRow
    name={<Skeleton variant='text' />}
    privileges={<Skeleton variant='text'/>}
    description={<Skeleton variant='text' />}
    actions={<Skeleton variant='text' />}
    />
  );
}

export default React.memo(GlobalRoleRowSkeleton);
