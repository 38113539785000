import gql from 'graphql-tag';

export const CheckCreateTeamDivision_Mutation = gql`
    mutation CheckCreateTeamDivisionMutation(
        $teamId: ID!
    ) {
        createDivision(
            input: {
                teamId: $teamId
                name: ""
            }
        ) {
            division {
                id
            }
        }
    }
`;
