import { Skeleton } from '@material-ui/lab';
import React from 'react';

import NotificationsTableRow from './NotificationsTableRow';

function NotificationsTableRowSkeleton() {
  return (
    <NotificationsTableRow
    message={<Skeleton variant='text' />}
    team={<Skeleton variant='text'/>}
    action={<Skeleton variant='text'/>}
    />
  );
}

export default React.memo(NotificationsTableRowSkeleton);
