import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

import UndraggableAvatar from '../../../../material/undraggable-avatar/UndraggableAvatar';

export interface IStockOwnProps {
  src: string;
  remaining: boolean;
  title?: string;
}

type Props = IStockOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stock: {
    '&$lostStock': {
      opacity: '0.5',
    },
  },
  lostStock: {},
}), {name: 'Stock'});

function Stock(props: Props) {
  const { remaining, src, title } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.root} title={title}>
      <UndraggableAvatar
      src={src}
      size='xs'
      className={classNames(classes.stock, {
        [classes.lostStock]: !remaining,
      })}
      />
    </div>
  );
}

export default React.memo(Stock);
