import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { GetTeamDirectoryVideosQueryVariables } from 'apollo/queries/dashboard/types/GetTeamDirectoryVideosQuery';
import { isExistent } from 'helpers';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

import { createDirectoryVideosQueryKey } from './dashboard-video-slice';

export const getVideoDict = (state: RootReducer) => state.dashboardVideo.videoDict;
const getTeamVideoRecordsByTeamId = (state: RootReducer, teamId: ID) => state.dashboardVideo.teamVideoRecords[teamId];

export const getVideoById = (state: RootReducer, videoId: ID): VideoFragment | undefined => {
  return state.dashboardVideo.videoDict[videoId];
};

export const getVideoFetchStatusById = (state: RootReducer, videoId: ID) => state.dashboardVideo.videoStatus[videoId];

export const getVideosByIds = (state: RootReducer, videoIds: ID[]) => {
  return videoIds.map(id => state.dashboardVideo.videoDict[id]).filter(isExistent);
};

export const makeGetVideoById = () => createSelector(
  [getVideoById],
  (video) => video,
);

export const makeGetVideosByIds = () => createSelector(
  [getVideosByIds],
  (videos) => videos,
);

export const makeGetVideoFetchStatusById = () => createSelector(
  [getVideoFetchStatusById],
  (status) => status,
);

export const getDirectoryVideoRecords = (
  state: RootReducer,
  params: Omit<GetTeamDirectoryVideosQueryVariables, 'limit' | 'after' | 'before'>,
) => state.dashboardVideo.directoryVideoRecords[createDirectoryVideosQueryKey(params)];

export const makeGetDirectoryVideoRecords = () => createSelector(
  [getDirectoryVideoRecords],
  (records) => records,
);

export const getTeamDirectoryVideoRecordsByDirectoryId = (state: RootReducer, directoryId: ID) =>
  state.dashboardVideo.directoryVideoRecords[directoryId];

export const makeGetTeamVideoRecordsByTeamId = () => createSelector(
  [getTeamVideoRecordsByTeamId],
  (records) => records,
);

export const makeGetTeamVideosByTeamId = () => createSelector(
  [getTeamVideoRecordsByTeamId, getVideoDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) {
      return [];
    }
    return ids.map(id => dict[id]).filter(isExistent);
  },
);

export const makeGetTeamDirectoryVideoRecordsByDirectoryId = () => createSelector(
  [getTeamDirectoryVideoRecordsByDirectoryId],
  (records) => records,
);

export const makeGetTeamDirectoryVideosByDirectoryId = () => createSelector(
  [getTeamDirectoryVideoRecordsByDirectoryId, getVideoDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) {
      return [];
    }
    return ids.map(id => dict[id]).filter(isExistent);
  },
);
