import { AsyncButton, EnhancedDialogTitle } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';

interface RenameDirectoryDialogOwnProps {
  className?: string;
  directoryName: string;
  directoryType: 'Division' | 'Folder';
  onRename?: (newName: string) => Promise<unknown>;
  onClose?: VoidFunction;
}

type RenameDirectoryDialogProps = RenameDirectoryDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'RenameDirectoryDialog'});

function RenameDirectoryDialog(props: RenameDirectoryDialogProps) {
  const classes = useStyles(props);
  const { className, directoryName, onRename, directoryType, ...rest } = props;

  const { t } = useStrictTranslation(['common', 'dashboard-directory']);

  return (
    <Dialog className={classNames(classes.root, className)} fullWidth={true} {...rest} maxWidth='xs'>
      <EnhancedDialogTitle>
        {directoryType === 'Division'
        ? t('dashboard-directory:renamechannel.title')
        : t('dashboard-directory:renamefolder.title')}
      </EnhancedDialogTitle>
      <Content {...props} />
    </Dialog>
  );
}

function Content(props: RenameDirectoryDialogProps) {
  const { onRename, onClose, directoryName, directoryType } = props;

  const [newName, setNewName] = useState(directoryName);
  const trimmed = useMemo(() => newName.trim(), [newName]);
  const [loading, setLoading] = useState(false);

  const { t } = useStrictTranslation(['common', 'dashboard-directory']);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = e;
    setNewName(value);
  }, []);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await onRename?.(trimmed);
      onClose?.();
      if (directoryType === 'Division') {
        enqueueSnackbar(t('dashboard-directory:renamechannel.success'), {variant: 'success'});
      } else {
        enqueueSnackbar(t('dashboard-directory:renamefolder.success'), {variant: 'success'});
      }
    } catch (error) {
      // todo explode
      enqueueSnackbar(error.message, {variant: 'error'});
    }
  }, [loading, onRename, trimmed, onClose, enqueueSnackbar, t, directoryType]);

  const noName = !trimmed;

  const additionalProps = useMemo(
    () => noName ? ({error: true/*, helperText: 'required' */}) : {},
    [noName],
  );

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <TextField
        name='name'
        fullWidth={true}
        value={newName}
        autoFocus={true}
        onChange={handleChange}
        {...additionalProps}
        />
      </DialogContent>
      <DialogActions>
        <Button type='button' variant='outlined' onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <AsyncButton type='submit' variant='contained' color='primary' disabled={noName || loading} loading={loading}>
          {t('dashboard-directory:renamechannel.confirm')}
        </AsyncButton>
      </DialogActions>
    </form>
  );
}

export default React.memo(RenameDirectoryDialog);
