import gql from 'graphql-tag';

import { InvitationFragment, NotificationFragment, SelfFragment, TeamFragment } from '../fragments';

export const UserEvents_Subscription = gql`
    subscription UserEventsSubscription {
        userEvents {
            __typename
            ...on SelfUpdatedEvent {
                me {
                    ...SelfFragment
                }
            }
            ...on TeamAddedEvent {
                teamAdded {
                    ...TeamFragment
                }
            }
            ...on TeamUpdatedEvent {
                teamUpdated {
                    ...TeamFragment
                }
            }
            ...on TeamDeletedEvent {
                teamDeleted
            }
            ...on InvitationAddedEvent {
                invitationAdded {
                    ...InvitationFragment
                }
            }
            ...on InvitationDeletedEvent {
                invitationDeleted
            }
            ...on NotificationAddedEvent {
                notificationAdded {
                    ...NotificationFragment
                }
            }
            ...on NotificationDeletedEvent {
                notificationDeleted
            }
            ...on NotificationUpdatedEvent {
                notificationUpdated {
                    ...NotificationFragment
                }
            }
        }
    }
    ${SelfFragment}
    ${TeamFragment}
    ${InvitationFragment}
    ${NotificationFragment}
`;
