import { FlexSpacer } from '@insights-gaming/material-components';
import { mobilePortrait,Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamSubscriptionFragment } from 'apollo/fragments/types/TeamSubscriptionFragment';
import classNames from 'classnames';
import useAvailableMetrics from 'features/dashboard/billing/useAvailableMetrics';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import React from 'react';
import { BillingInterval, ProductKind } from 'types/graphql';
import { BundleGetter } from 'types/pigeon/billing';

import CustomPlanCard from './CustomPlanCard';
import CustomPlanSummary from './CustomPlanSummary';

interface CustomPlanOwnProps {
  className?: string;
  interval: BillingInterval;
  teamSubscription?: TeamSubscriptionFragment;
  openCustomPlanDetailsDialog?: VoidFunction;
  bundles?: BundleGetter;
  handleCustomPlanSelectOnChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  customPlanQuantities?: Partial<Record<ProductKind, number>>;
  setCustomPlanQuantities: React.Dispatch<React.SetStateAction<object>>;
  changePlan?: boolean;
}

type CustomPlanProps = CustomPlanOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: theme.spacing(2, 10, 25, 10),
    [mobilePortrait(theme)]: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  },
}), {name: 'CustomPlan'});

function CustomPlan(props: CustomPlanProps) {
  const classes = useStyles(props);
  const {
    className,
    interval,
    openCustomPlanDetailsDialog,
    bundles,
    handleCustomPlanSelectOnChange,
    customPlanQuantities,
    setCustomPlanQuantities,
    teamSubscription,
    changePlan,
  } = props;

  const { customPlans } = useAvailableMetrics(bundles);

  const isMobilePortrait = useIsMobilePortrait();

  return (
    <FlexSpacer
    className={classNames(classes.root, className)}
    flexJustifyContent='center'
    spacing={1.5}
    orientation={isMobilePortrait ? 'vertical' : 'horizontal'}
    >
      <FlexSpacer orientation='vertical' flexAlignItems='center' spacing={1.5}>
        {Object.values(customPlans).map((info) => (
          <CustomPlanCard
          key={info.plan.kind}
          interval={interval}
          info={info}
          handleCustomPlanSelectOnChange={handleCustomPlanSelectOnChange}
          customPlanQuantities={customPlanQuantities}
          />
        ))}
      </FlexSpacer>

      <CustomPlanSummary
      interval={interval}
      customPlans={customPlans}
      teamSubscription={teamSubscription}
      openCustomPlanDetailsDialog={openCustomPlanDetailsDialog}
      customPlanQuantities={customPlanQuantities}
      setCustomPlanQuantities={setCustomPlanQuantities}
      changePlan={changePlan}
      />
    </FlexSpacer>
  );
}

export default React.memo(CustomPlan);
