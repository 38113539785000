import gql from 'graphql-tag';

export const RemoveAuthenticationMethod_Mutation = gql`
    mutation RemoveAuthenticationMethodMutation(
        $input: RemoveAuthenticationMethodInput!
    ) {
        removeAuthenticationMethod(
            input: $input
        ) {
            me {
                id
            }
        }
    }
`;
