import { Theme } from '@insights-gaming/theme';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import classNames from 'classnames';
import React, { useCallback, useContext } from 'react';

import { VideoReplaySelectedLabelsContext } from '../VideoReplaySelectedLabelsContext';

interface LabelSelectorListItemOwnProps {
  className?: string;
  label: TagFragment;
}

type LabelSelectorListItemProps = LabelSelectorListItemOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  labelText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}), {name: 'LabelSelectorListItem'});

function LabelSelectorListItem(props: LabelSelectorListItemProps) {
  const classes = useStyles(props);
  const { className, label } = props;

  const {
    labels: {
      selected: selectedLabelIds,
      removeFromSelection: removeLabelFromSelection,
      addToSelection: addLabelToSelection,
    },
  } = useContext(VideoReplaySelectedLabelsContext);

  const handleCheckOnChange = useCallback(() => {
    if (selectedLabelIds.has(label.id)) {
      removeLabelFromSelection([label.id]);
    } else {
      addLabelToSelection([label.id]);
    }
  }, [label, addLabelToSelection, removeLabelFromSelection, selectedLabelIds]);

  return (
    <ListItem
    className={classNames(classes.root, className)}
    onClick={handleCheckOnChange}
    key={label.id}
    button={true}
    >
      <ListItemIcon>
        <Checkbox
        edge='end'
        onChange={handleCheckOnChange}
        checked={selectedLabelIds.has(label.id)}
        disableRipple={true}
        />
      </ListItemIcon>
      <ListItemText
      primary={label.name}
      classes={{
        primary: classes.labelText,
      }}
      />
      <ListItemIcon>
        <Brightness1Icon htmlColor={label.color} />
      </ListItemIcon>
    </ListItem>
  );
}

export default React.memo(LabelSelectorListItem);
