import { isExistent } from 'helpers';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

const getVideoBookmarksRecordsByVideoId = (state: RootReducer, props: { videoId: ID }) => {
  const bookmarksState = state.videoBookmarks.videosDict[props.videoId];
  if (!bookmarksState) {
    return;
  }

  return bookmarksState.record;
};

const getVideoBookmarksByVideoId = (state: RootReducer, props: { videoId?: ID }) => {
  if (!props.videoId) {
    return [];
  }

  const bookmarksState = state.videoBookmarks.videosDict[props.videoId];
  if (!bookmarksState) {
    return [];
  }

  return Object.values(bookmarksState.bookmarksDict);
};

export const makeGetVideoBookmarksRecordsByVideoId = () => createSelector(
  [getVideoBookmarksRecordsByVideoId],
  (records) => records,
);

export const makeGetVideoBookmarksByVideoId = () => createSelector(
  [getVideoBookmarksByVideoId],
  (bookmarks) => bookmarks.filter(isExistent),
);
