import { AsyncButton, EnhancedDialogTitle } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { cancelTeamSubscriptionAC } from 'features/dashboard/billing/dashboard-billing-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

interface ConfirmDowngradeDialogOwnProps {
  className?: string;
  open: boolean;
  onCancel?: VoidFunction;
  onClose: VoidFunction;
  team: TeamFragment;
  closeChangePlansDialog?: VoidFunction;
}

type ConfirmDowngradeDialogProps = ConfirmDowngradeDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), { name: 'ConfirmDowngradeDialogContent' });

function ConfirmDowngradeDialog(props: ConfirmDowngradeDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <ConfirmDowngradeDialogContent {...props} />
    </Dialog>
  );
}

function ConfirmDowngradeDialogContent(props: ConfirmDowngradeDialogProps) {
  const classes = useStyles(props);
  const { className, onClose, onCancel, team, closeChangePlansDialog } = props;

  const { t } = useStrictTranslation(['dashboard', 'dialog']);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const teamId = team.id;
  const [loading, setLoading] = useState(false);

  const cancelSubscription = useCallback(async () => {
    setLoading(true);
    try {
      const result = await promiseSagaDispatch(cancelTeamSubscriptionAC, {
        teamId,
      });
      setLoading(false);
      closeChangePlansDialog?.();
      onCancel?.();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [promiseSagaDispatch, teamId, closeChangePlansDialog, onCancel, enqueueSnackbar]);

  return (
    <div className={classNames(classes.root, className)}>
      <EnhancedDialogTitle onClose={onClose}>
        {t('dialog:downgrade.title')}
      </EnhancedDialogTitle>
      <DialogContent>
        <Typography variant='body2'>
          {t('dialog:downgrade.content')}
        </Typography>
        <DialogActions>
          <Button variant='outlined' onClick={onClose}>
            {t('dialog:downgrade.cancel')}
          </Button>
          <AsyncButton
          variant='contained'
          color='primary'
          onClick={cancelSubscription}
          disabled={loading}
          loading={loading}
          >
            {t('dialog:downgrade.confirm')}
          </AsyncButton>
        </DialogActions>
      </DialogContent>
    </div>
  );
}

export default React.memo(ConfirmDowngradeDialog);
