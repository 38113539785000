import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React from 'react';

interface ActiveSessionsTableRowOwnProps {
  className?: string;
  created?: string;
  address?: string | null;
  browser?: string | null;
  country?: string | null;
  action?: React.ReactNode;
}

type ActiveSessionsTableRowProps = ActiveSessionsTableRowOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: createRemFromPx(48),
  },
}), {name: 'ActiveSessionsTableRow'});

function ActiveSessionsTableRow(props: ActiveSessionsTableRowProps) {
  const classes = useStyles(props);
  const { className, created, address, browser, country, action } = props;

  return (
    <TableRow className={classNames(classes.root, className)}>
      <FixedTableCell width={180}>{created}</FixedTableCell>
      <FixedTableCell width={150}>{address}</FixedTableCell>
      <FixedTableCell width={180}>{browser}</FixedTableCell>
      <FixedTableCell width={100}>{country}</FixedTableCell>
      <FixedTableCell width={150}>{action}</FixedTableCell>
    </TableRow>
  );
}

export default React.memo(ActiveSessionsTableRow);
