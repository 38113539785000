import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import classNames from 'classnames';
import { EIntercomID } from 'constants/strings';
import { useLoadingScreen } from 'features/loading-screen/useLoadingScreen';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useContext, useMemo } from 'react';
import { ID } from 'types/pigeon';

import { useAccessControl } from '../access-control/useAccessControl';
import CustomDragLayer from '../dnd/CustomDragLayer';
import DashboardMainContent from '../main-content/DashboardMainContent';
import { DashboardSelectedTagsContext } from '../tag-filter/DashboardSelectedTagsContext';
import { makeGetDirectoryVideoRecords } from '../video/dashboard-video-selector';
import DirectoryVideoFetcher from '../video/DirectoryVideoFetcher';
import CreateDirectoryDialog from './create-division-dialog/CreateDirectoryDialog';
import {
  makeGetSharedDivisionByDirectoryId,
  makeGetTeamDirectoryByDirectoryId,
  makeGetTeamDirectoryFoldersByDirectoryId,
} from './dashboard-directory-selector';
import DirectoryBreadcrumbs from './directory-breadcrumbs/DirectoryBreadcrumbs';
import { useDirectoryDropTarget } from './useDirectoryDropTarget';
import { useFetchDirectoryFolders } from './useFetchDirectoryFolders';
import { useFileDropUploader } from './useFileDropUploader';

interface DashboardDirectoryContentOwnProps {
  className?: string;
  directoryId: ID;
}

type DashboardDirectoryContentProps = DashboardDirectoryContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
}), {name: 'DashboardDirectoryContent'});

function DashboardDirectoryContent(props: DashboardDirectoryContentProps) {
  const classes = useStyles(props);
  const { className, directoryId } = props;

  const directory = useCreateSelector(makeGetTeamDirectoryByDirectoryId, directoryId);
  const sharedDirectory = useCreateSelector(makeGetSharedDivisionByDirectoryId, directoryId);

  const teamId = directory?.team?.id;

  const { canCreateFolder } = useAccessControl();
  const { t } = useStrictTranslation(['common', 'dashboard-directory', 'dialog']);

  const [foldersForwardFetchStatus] = useFetchDirectoryFolders(directoryId);

  const folders = useCreateSelector(makeGetTeamDirectoryFoldersByDirectoryId, directoryId);
  const initialFolderFetch = foldersForwardFetchStatus.fetching && !foldersForwardFetchStatus.cursor;

  const { tags: { selected: selectedTagIds } } = useContext(DashboardSelectedTagsContext);

  const directoryVideosQueryParams = useMemo(
    () => ({ directoryId, tagIds: Array.from(selectedTagIds) }),
    [directoryId, selectedTagIds],
  );

  const videosRecord = useCreateSelector(makeGetDirectoryVideoRecords, directoryVideosQueryParams);

  useLoadingScreen(false);

  const [isCreateFolderDialogOpen, openCreateFolderDialog, closeCreateFolderDialog] = useDialogState();

  const { handleItemDroppedOnDirectory } = useDirectoryDropTarget();

  const { handleFilesDroppedOnDirectory, uploadDialog } = useFileDropUploader(teamId);

  return (
    <div className={classNames(classes.root, className)}>
      <div>
        <DirectoryBreadcrumbs
        directoryId={directoryId}
        onItemDroppedOnDirectory={handleItemDroppedOnDirectory}
        onFilesDroppedOnDirectory={handleFilesDroppedOnDirectory}
        >
          {!initialFolderFetch && canCreateFolder && (
            <Button
            id={EIntercomID.CREATE_FOLDER}
            onClick={openCreateFolderDialog}
            startIcon={<CreateNewFolderIcon />}
            >
              {t('dashboard-directory:createfolder.title')}
            </Button>
          )}
        </DirectoryBreadcrumbs>
      </div>
      <DirectoryVideoFetcher {...directoryVideosQueryParams}/>
      <DashboardMainContent
      directory={directory || sharedDirectory}
      videosRecord={videosRecord}
      folders={folders}
      initialFolderFetch={initialFolderFetch}
      onItemDroppedOnDirectory={handleItemDroppedOnDirectory}
      onFilesDroppedOnDirectory={handleFilesDroppedOnDirectory}
      />
      {directory && (
        <CreateDirectoryDialog
        open={isCreateFolderDialogOpen}
        teamId={directory.team.id}
        parentId={directory.id}
        onClose={closeCreateFolderDialog}
        />
      )}
      {uploadDialog}
      <CustomDragLayer />
    </div>
  );
}

export default React.memo(DashboardDirectoryContent);
