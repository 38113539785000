import { invalidateTeamCacheAC } from 'features/dashboard/team/dashboard-team-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { fetchInvitationAsyncAC } from '../../actions/invitation-actions';
import { GetInvitationQuery_invitation, GetInvitationQueryVariables } from '../../apollo/queries/types/GetInvitationQuery';

export function useFetchInvitation(): (
  params: GetInvitationQueryVariables,
) => Promise<GetInvitationQuery_invitation> {
  const promiseSagaDispatch = usePromiseSagaDispatch();
  return useCallback(
    (params: GetInvitationQueryVariables) => promiseSagaDispatch(fetchInvitationAsyncAC, params),
    [promiseSagaDispatch],
  );
}

export function useInvalidateTeamCache(): VoidFunction {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(invalidateTeamCacheAC()), [dispatch]);
}
