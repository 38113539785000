import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Tooltip from '@material-ui/core/Tooltip';
import { createStyles, makeStyles } from '@material-ui/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { ItemTypes } from '../dnd/item-types';
import TeamAvatarButton from './TeamAvatarButton';
import { useTeamCardDrop } from './useTeamCardDrop';

interface DragDropTeamCardOwnProps {
  className?: string;
  tooltipContainer: HTMLDivElement | null;
  team: TeamFragment;
  teamIndex: number;
  onTeamClick: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, teamId: string) => void) | undefined;
  active: boolean;
  handleAutoScrollRef: (el: HTMLButtonElement | null) => void;
  teams: TeamFragment[];
  hoveredTeamIndex?: number | null;
  setHoveredTeamIndex?: React.Dispatch<React.SetStateAction<number | null>>;
  setIsDragging?: React.Dispatch<React.SetStateAction<boolean>>;
}

type DragDropTeamCardProps = DragDropTeamCardOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  iconOuter: {
    height: theme.appSizes.drawerWidth,
    width: theme.appSizes.drawerWidth,
    display: 'flex',
    padding: 12, // (drawerWidth - inner avatar image width) / 2
    justifyContent: 'center',
    alignItems: 'center',
    '&$active': {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      paddingLeft: 12 - 4, // accommodate added border
    },
  },
  insertLine: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: createRemFromPx(4),
    backgroundColor: theme.palette.secondary.main,
    '&$isTop': {
      top: createRemFromPx(-2),
    },
    '&$isBottom': {
      bottom: createRemFromPx(-2),
    },
  },
  active: {},
  isTop: {},
  isBottom: {},
}), {name: 'DragDropTeamCard'});

const modifiers = {
  flip: {
    enabled: false,
  },
  preventOverflow: {
    enabled: true,
    boundariesElement: 'viewport',
  },
};

function DragDropTeamCard(props: DragDropTeamCardProps) {
  const classes = useStyles(props);
  const {
    className,
    tooltipContainer,
    team,
    teamIndex,
    onTeamClick,
    active,
    handleAutoScrollRef,
    teams,
    hoveredTeamIndex,
    setHoveredTeamIndex,
    setIsDragging,
  } = props;

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      id: team.id,
      type: ItemTypes.TEAM_CARD,
      teamIndex,
      team,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    begin: () => {
      setIsDragging?.(true);
    },
    end: () => {
      setIsDragging?.(false);
      setHoveredTeamIndex?.(null);
    },
  });

  const {
    drop,
    cardRef,
    showAbove: shouldInsertLineTop,
    showBelow: shouldInsertLineBottom,
  } = useTeamCardDrop(teams, teamIndex, hoveredTeamIndex, setHoveredTeamIndex);

  drag(drop);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <div ref={cardRef} style={{position: 'relative'}}>
      {shouldInsertLineTop && <div className={classNames(classes.insertLine, classes.isTop)} />}
      <Tooltip
      key={team.id}
      title={isDragging ? '' : team.name}
      placement='right'
      PopperProps={{
        container: tooltipContainer,
        modifiers,
      }}
      >
        <TeamAvatarButton
        ref={active ? handleAutoScrollRef : undefined}
        team={team}
        onClick={active ? undefined : onTeamClick}
        size='sm'
        className={classNames(classes.iconOuter, {[classes.active]: active})}
        isDragging={isDragging}
        />
      </Tooltip>
      {shouldInsertLineBottom && <div className={classNames(classes.insertLine, classes.isBottom)} />}
    </div>
  );
}

export default React.memo(DragDropTeamCard);
