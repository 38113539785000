import gql from 'graphql-tag';

export const RemoveMember_Mutation = gql`
    mutation RemoveMemberMutation(
        $input: RemoveMemberInput!
    ) {
        removeMember(
            input: $input
        ) {
            memberId
        }
    }
`;
