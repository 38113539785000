import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import React from 'react';
import { Trans } from 'react-i18next';

interface AbortUploadDialogOwnProps {
  className?: string;
  onAbort: VoidFunction;
  onClose: VoidFunction;
  open: boolean;
  videoNames: string[];
}

type AbortUploadDialogProps = AbortUploadDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'AbortUploadDialog'});

function AbortUploadDialog(props: AbortUploadDialogProps) {
  const classes = useStyles(props);
  const { className, onClose, open } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <AbortUploadDialogContent {...props} />
    </Dialog>
  );
}

function AbortUploadDialogContent(props: AbortUploadDialogProps) {
  const { videoNames, onAbort, onClose } = props;
  const { t } = useStrictTranslation(['dialog', 'common']);

  return (
    <AlertDialogContent
    titleText={t('dialog:uploadabort.title', { count: videoNames.length })}
    description={(
      <DialogContentText>
        {videoNames.length === 1 ? (
          <Trans i18nKey='dialog:uploadabort.desc' values={{ videoName: videoNames[0] }} ns='dialog'>
            Are you sure you want to abort uploading <strong>{{ videoName: videoNames[0] }}</strong>?
          </Trans>
        ) : (
          <Trans i18nKey='dialog:uploadabort.descall'/>
        )}
      </DialogContentText>
    )}
    cancel={{
      text: t('common:cancel'),
      action: onClose,
    }}
    confirm={{
      text: t('common:abort'),
      action: onAbort,
    }}
    />
  );
}


export default React.memo(AbortUploadDialog);
