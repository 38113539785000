import gql from 'graphql-tag';

import { InvitationTargetFragment } from '../fragments';

export const ClaimInvitation_Mutation = gql`
    mutation ClaimInvitationMutation(
        $input: ClaimInvitationInput!
    ) {
        claimInvitation(
            input: $input
        ) {
            target {
                ...InvitationTargetFragment
            }
        }
    }
    ${InvitationTargetFragment}
`;
