import { FlexSpacer, VerticalScroll } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import StatusBar from 'components/status-bar/StatusBar';
import UnalignedContainer from 'components/unaligned-container/UnalignedContainer';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory,useParams } from 'react-router';
import { dashboardRoute } from 'routes';
import { getMe } from 'selectors/getMe';
import IntercomButton from 'subcomponents/intercom-button/IntercomButton';

import { mobilePortrait } from '../../features/media-queries';
import { useIsDesktop } from '../../features/media-queries/hooks';
import { useNavigate } from '../../hooks/useNavigate';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    [mobilePortrait(theme)]: {
      flexDirection: 'column',
    },
    '&$dense': {
      marginTop: 0,
    },
  },
  dense: {},
  content: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
  },
  fullWidthContent: {
    flex: 1,
  },
  sidebar: {
    paddingTop: theme.spacing(7),
    backgroundColor: theme.palette.background.paper,
    width: createRemFromPx(240),
    maxWidth: createRemFromPx(240),
    flex: 1,
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    '&$isDesktop': {
      paddingLeft: theme.spacing(3),
    },
  },
  backButtonText: {
    fontSize: createRemFromPx(16),
    fontWeight: 400,
  },
  isDesktop: {},
}), {
  name: 'SettingsGrid',
});

interface SettingsGridOwnProps {
  children: React.ReactChild;
  renderTabs: () => React.ReactNode;
  dense?: boolean;
  className?: string;
  contentFullWidth?: boolean;
}

type SettingsGridProps = SettingsGridOwnProps;

function SettingsGrid(props: SettingsGridProps) {
  const { dense, className, contentFullWidth } = props;
  const classes = useStyles(props);
  const isDesktop = useIsDesktop();
  const [isSidebarOpen, openSidebar, closeSidebar] = useDialogState();

  const me = useSelector(getMe);
  const { tab } = useParams<{ tab?: string }>();
  const history = useHistory();

  const onNavigate = useNavigate();

  const { t } = useStrictTranslation(['settings']);
  const wt = t as TFunction;

  const menuItems = useMemo(() => {
    return [
      <IntercomButton key='intercom' />,
    ];
  }, []);

  const handleBackButton = useCallback(() => {
    if (!history.length) {
      onNavigate(dashboardRoute());
      return;
    }
    history.goBack();
  }, [history, onNavigate]);

  const innerSidebar = (
    <VerticalScroll className={classes.sidebar}>
      {props.renderTabs()}
    </VerticalScroll>
  );

  return (
    <div className={classNames(classes.root, className, {
      [classes.dense]: dense,
    })}
    >
      {isDesktop ? (
        innerSidebar
      ) : (
        <SwipeableDrawer open={isSidebarOpen} onOpen={openSidebar} onClose={closeSidebar}>
          {innerSidebar}
        </SwipeableDrawer>
      )}
      <div className={classNames(classes.content, {
        [classes.fullWidthContent]: contentFullWidth,
      })}
      >
        <FlexSpacer
        className={classNames(classes.toolbar, className, {
          [classes.isDesktop]: isDesktop,
        })}
        flexAlignItems='center'
        flexJustifyContent='space-between'
        fullWidth={true}
        >
          <FlexSpacer>
            {!isDesktop && (
              <IconButton onClick={openSidebar}>
                <MenuIcon />
              </IconButton>
            )}
            <FlexSpacer flexAlignItems='center'>
              <IconButton aria-label='go-back' onClick={handleBackButton} size='medium'>
                <ArrowBackIosIcon fontSize='small' />
              </IconButton>
              <Box className={classes.backButtonText}>
                {wt(`settings:tabs.${tab}`)}
              </Box>
            </FlexSpacer>
          </FlexSpacer>
          {me && (
            <StatusBar me={me} menuItems={menuItems} />
          )}
        </FlexSpacer>
        <UnalignedContainer maxWidth='lg'>
          {props.children}
        </UnalignedContainer>
      </div>
    </div>
  );
}

export default React.memo(SettingsGrid);
