import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { makeStyles } from '@material-ui/styles';
import { mobilePortrait } from 'features/media-queries';
import { fetchInsightsEmbedData } from 'helpers/noembed';
import { checkForStreamUrl } from 'helpers/urlvalidator';
import { IValidationEvent, ValidateTextField } from 'hoc/withValidation';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useRef, useState } from 'react';

interface VideoUrlTextFieldOwnProps {
  onChange?: (value: InsightsEmbedResponse) => void;
}

export type VideoUrlTextFieldProps = VideoUrlTextFieldOwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > div': {
      [mobilePortrait(theme)]: {
        fontSize: createRemFromPx(12),
      },
    },
  },
}), {name: 'VideoUrlTextField'});

function VideoUrlTextField(props: VideoUrlTextFieldOwnProps) {
  const classes = useStyles(props);
  const { onChange } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useStrictTranslation(['common', 'dialog']);
  const [ url, setUrl ] = useState('');
  const checkingUrl = useRef('');

  const validateUrl = useCallback((url: string) => {
    try {
      return checkForStreamUrl(url);
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'warning'});
      return false;
    }
  }, [enqueueSnackbar]);

  const handleValidatedUrl = useCallback(async (url: string) => {
    try {
      if (!onChange || checkingUrl.current === url) {
        return;
      }

      checkingUrl.current = url;
      if (!checkForStreamUrl(url)) {
        return;
      }

      const metadata = await fetchInsightsEmbedData(url);
      if (checkingUrl.current !== url) {
        return;
      }

      onChange?.(metadata);

      setUrl('');
      checkingUrl.current = '';
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'warning'});
    }
  }, [enqueueSnackbar, onChange]);

  const handleRemoteUrlValidityChange = useCallback(async ({value, valid}: IValidationEvent) => {
    if (value && valid) {
      handleValidatedUrl(value);
    }
  }, [handleValidatedUrl]);

  const handleRemoteVideoUrlChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  }, []);

  const handleRemoteOnBlur = useCallback((e: React.SyntheticEvent) => {
    if (url) {
      if (validateUrl(url)) {
        handleValidatedUrl(url);
      }
    }
  }, [handleValidatedUrl, url, validateUrl]);

  const handleRemoteOnKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        handleRemoteOnBlur(e);
        break;
    }
  }, [handleRemoteOnBlur]);

  return (
    <ValidateTextField
    className={classes.root}
    name='remoteVideoUrl'
    value={url}
    placeholder={t('dialog:upload.pasteremoteurl')}
    fullWidth={true}
    suppressErrorOnEmptyString={true}
    validateOnEveryChange={true}
    onChange={handleRemoteVideoUrlChange}
    onKeyPress={handleRemoteOnKeyDown}
    validate={validateUrl}
    onValidityChange={handleRemoteUrlValidityChange}
    />
  );
}

export default React.memo(VideoUrlTextField);
