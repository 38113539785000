import { Theme } from '@insights-gaming/theme';
import Badge from '@material-ui/core/Badge';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DirectoryCardDragPreview from 'components/directory-card/DirectoryCardDragPreview';
import VideoCardDragPreview from 'components/video-card/VideoCardDragPreview';
import TeamCardDragPreview from 'features/dashboard/sidebar/TeamCardDragPreview';
import React from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { CSSTransition } from 'react-transition-group';

import { DragItem, ItemTypes } from './item-types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: theme.zIndex.tooltip,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
}), {name: 'CustomDragLayer'});

function CustomDragLayer() {
  const classes = useStyles();

  const {
    item,
    // itemType,
    clientOffset,
    initialOffset,
    currentOffset,
    isDragging,
  } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    // itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const delta = calculateDelta(clientOffset, currentOffset);
  const inner = useInnerStyles(delta);

  if (!isDragging) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div style={getItemStyles(initialOffset, currentOffset, item.width, item.height)}>
        <CSSTransition
        in={true}
        timeout={0}
        className={inner.root}
        classNames={{
          enter: inner.enter,
          enterActive: inner.enterActive,
          enterDone: inner.enterDone,
          exit: inner.exit,
          exitActive: inner.exitActive,
          exitDone: inner.exitDone,
        }}
        appear={true}
        >
          <Badge style={{width: '100%', height: '100%'}} color='secondary' badgeContent={getBadgeContent(item)}>
            <div style={{width: '100%', height: '100%'}}>
              {renderItem(item)}
            </div>
          </Badge>
        </CSSTransition>
      </div>
    </div>
  );
}

const calculateDelta = (mouseOffset: XYCoord | null, currentOffset: XYCoord | null): XYCoord => {
  if (!mouseOffset || !currentOffset) {
    return {x: 0, y: 0};
  }
  return {
    x: mouseOffset.x - currentOffset.x,
    y: mouseOffset.y - currentOffset.y,
  };
};

const useInnerStyles = makeStyles(({
  root: {
    transition: 'transform ease 300ms',
  },
  enter: {
    transform: ({x, y}: XYCoord) =>  `translate(${x}px, ${y}px)`,
  },
  enterActive: {
    transform: ({x, y}: XYCoord) =>  `translate(${x}px, ${y}px)`,
  },
  enterDone: {
    transform: ({x, y}: XYCoord) =>  `translate(${x}px, ${y}px)`,
  },
  exit: {
    transform: 'translate(0px, 0px)',
  },
  exitActive: {
    transform: 'translate(0px, 0px)',
  },
  exitDone: {
    transform: 'translate(0px, 0px)',
  },
}));

function getBadgeContent(item: DragItem) {
  switch (item.type) {
    case ItemTypes.DIRECTORY_CARD:
    case ItemTypes.VIDEO_CARD:
      const count = (item.selection?.folders?.size ?? 0) + (item.selection?.videos?.size ?? 0);
      if (count > 1) {
        return count;
      }
      return undefined;
    default:
      return undefined;
  }
}

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  width: number,
  height: number,
): React.CSSProperties {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    width,
    height,
  };
}

function renderItem(item: DragItem) {
  switch (item.type) {
    case ItemTypes.DIRECTORY_CARD:
      return (
        <DirectoryCardDragPreview name={item.name} />
      );
    case ItemTypes.VIDEO_CARD:
      return (
        <VideoCardDragPreview name={item.name} thumbnailUrl={item.thumbnailUrl} />
      );
    case ItemTypes.TEAM_CARD:
      return (
        <TeamCardDragPreview team={item.team} />
      );
    default:
      return null;
  }
}

export default React.memo(CustomDragLayer);
