import { ContextMenu, ContextMenuItem } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FolderIcon from '@material-ui/icons/Folder';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import DirectoryPickerDialog from 'features/dashboard/directory-picker-dialog/DirectoryPickerDialog';
import MuteNotificationDialog from 'features/dashboard/sidebar/mute-notification-dialog/MuteNotificationDialog';
import { useFetchTeamIfNecessary } from 'features/dashboard/team/useFetchTeamIfNecessary';
import { isTruthy } from 'helpers';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import InviteTeamMembersDialogContent from 'material/dialogs/invite-team-members-dialog-content/InviteTeamMembersDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors/getMe';
import { ID } from 'types/pigeon';

import { DirectoryHelper } from '../dashboard-directory-helper';
import { makeGetTeamDirectoryUsersByDirectoryId } from '../dashboard-directory-selector';
import DeleteDirectoryDialog from './DeleteDirectoryDialog';
import DirectoryPrivacyChangeDialog from './DirectoryPrivacyChangeDialog';
import LeaveDirectoryDialog from './LeaveDirectoryDialog';
import RenameDirectoryDialog from './RenameDirectoryDialog';
import { useDirectoryContextMenu } from './useDirectoryContextMenu';

interface DirectoryContextMenuOwnProps {
  className?: string;
  directory: DirectoryFragment;
  muted?: boolean;
}

type DirectoryContextMenuProps = DirectoryContextMenuOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DirectoryContextMenu'});

function DirectoryContextMenu(props: DirectoryContextMenuProps) {
  const classes = useStyles(props);
  const { className, directory, muted } = props;

  const { t } = useStrictTranslation(['common', 'dashboard-directory']);
  const { enqueueSnackbar } = useSnackbar();
  const {
    canUpdateDivision,
    canDeleteDivision,
    canUpdateFolder,
    canDeleteFolder,
    canUpdatePrivateFolder,
    canSendTeamInvitation,
  } = useAccessControl();

  const canUpdate =
    DirectoryHelper.isDivision(directory) && canUpdateDivision ||
    DirectoryHelper.isFolder(directory) && canUpdateFolder;

  const canDelete =
    DirectoryHelper.isDivision(directory) && canDeleteDivision ||
    DirectoryHelper.isFolder(directory) && canDeleteFolder;

  const {
    renameDirectory,
    updateFolder,
    deleteDirectory,
  } = useDirectoryContextMenu<DirectoryFragment>(directory);

  const [isRenameDialogOpen, openRenameDialog, closeRenameDialog] = useDialogState();
  const [isInviteDialogOpen, openInviteDialogOpen, closeInviteDialogOpen] = useDialogState();
  const [isMoveDialogOpen, openMoveDialog, closeMoveDialog] = useDialogState();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState();
  const [isMuteNotificaionDialogOpen, openMuteNotificationDialog, closeMuteNotificationDialog] = useDialogState();
  const [isLeaveDirectoryDialogOpen, openLeaveDirectoryDialog, closeLeaveDirectoryDialog] = useDialogState();
  const [
    isDirectoryPrivacyChangeDialogOpen,
    openDirectoryPrivacyChangeDialog,
    closeDirectoryPrivacyChangeDialog,
  ] = useDialogState();

  const [team] = useFetchTeamIfNecessary(directory.team.id);
  const users = useCreateSelector(makeGetTeamDirectoryUsersByDirectoryId, directory.id);
  const me = useSelector(getMe);

  const displayLeaveOption = useMemo(() => {
    if (team?.owner.__typename === 'Self') {
      return;
    }
    return team ? DirectoryHelper.isPrivate(directory) : !!users.find((u) => u.id === me?.id);
  }, [directory, me, team, users]);

  const items: ContextMenuItem[] = useMemo(() => {
    return [
      directory.__typename === 'Division' && {
        text: muted ? t('dashboard-directory:menuItems.unmute') : t('dashboard-directory:menuItems.mute'),
        icon: <NotificationsIcon />,
        onClick: openMuteNotificationDialog,
      },
      canUpdate && {
        text: t('dashboard-directory:menuItems.rename'),
        icon: <EditIcon />,
        onClick: openRenameDialog,
      },
      canSendTeamInvitation && {
        text: t('dashboard-directory:menuItems.invite'),
        icon: <PersonAddIcon />,
        onClick: openInviteDialogOpen,
      },
      canUpdatePrivateFolder && DirectoryHelper.isDivision(directory) && {
        text: t('dashboard-directory:menuItems.changeprivacy'),
        icon: DirectoryHelper.isPrivate(directory) ? <LockOpenIcon /> : <LockIcon />,
        onClick: openDirectoryPrivacyChangeDialog,
      },
      DirectoryHelper.isFolder(directory) && canUpdateFolder && {
        text: t('dashboard-directory:menuItems.move'),
        icon: <FolderIcon />,
        onClick: openMoveDialog,
      },
      displayLeaveOption && {
        text: t('dashboard-directory:menuItems.leavechannel'),
        icon: <ExitToAppIcon/>,
        onClick: openLeaveDirectoryDialog,
      },
      canDelete && {
        text: t('dashboard-directory:menuItems.delete'),
        icon: <DeleteIcon />,
        onClick: openDeleteDialog,
      },
    ].filter(isTruthy);
  }, [
    directory,
    muted,
    t,
    openMuteNotificationDialog,
    canUpdate,
    openRenameDialog,
    canSendTeamInvitation,
    openInviteDialogOpen,
    canUpdatePrivateFolder,
    openDirectoryPrivacyChangeDialog,
    canUpdateFolder,
    openMoveDialog,
    displayLeaveOption,
    openLeaveDirectoryDialog,
    canDelete,
    openDeleteDialog,
  ]);

  const filterFn = useCallback((potentialDestination: DirectoryFragment) => {
    if (potentialDestination.id === directory.id) {
      return false;
    }
    return true;
  }, [directory.id]);

  const handleConfirm = useCallback((directoryId: ID) => {
    if (directoryId === DirectoryHelper.tryGetParentId(directory)) {
      return;
    }
    updateFolder({
      folderId: directory.id,
      parentId: directoryId,
      teamId: directory.team.id,
    });
    enqueueSnackbar(t('dashboard-directory:movefolder.success'), {variant: 'success'});
  }, [directory, updateFolder, t, enqueueSnackbar]);

  return (
    <React.Fragment>
      <ContextMenu
      popupId='directory-context-menu'
      className={classNames(classes.root, className)}
      items={items}
      />
      <MuteNotificationDialog
      open={isMuteNotificaionDialogOpen}
      onClose={closeMuteNotificationDialog}
      teamId={directory.team.id}
      channelId={directory.id}
      muted={!!muted}
      />
      <Dialog open={isInviteDialogOpen} onClose={closeInviteDialogOpen} fullWidth={true}>
        <InviteTeamMembersDialogContent
        onClose={closeInviteDialogOpen}
        teamId={directory.team.id}
        directory={directory}
        />
      </Dialog>
      <RenameDirectoryDialog
      open={isRenameDialogOpen}
      directoryName={directory.name}
      directoryType={directory.__typename}
      onClose={closeRenameDialog}
      onRename={renameDirectory}
      />
      <DirectoryPickerDialog
      open={isMoveDialogOpen}
      teamId={directory.team.id}
      initialDirectoryId={DirectoryHelper.tryGetParentId(directory)}
      filterFn={filterFn}
      onClose={closeMoveDialog}
      onConfirm={handleConfirm}
      />
      <DeleteDirectoryDialog
      open={isDeleteDialogOpen}
      directoryName={directory.name}
      directoryType={directory.__typename}
      onClose={closeDeleteDialog}
      onDelete={deleteDirectory}
      directoryTeamId={directory.team.id}
      />
      <DirectoryPrivacyChangeDialog
      open={isDirectoryPrivacyChangeDialogOpen}
      directory={directory}
      onClose={closeDirectoryPrivacyChangeDialog}
      />
      <LeaveDirectoryDialog
      open={isLeaveDirectoryDialogOpen}
      onClose={closeLeaveDirectoryDialog}
      directory={directory}
      />
    </React.Fragment>
  );
}

export default React.memo(DirectoryContextMenu);
