import { useCreateSelector } from '@insights-gaming/redux-utils';
import { TeamSubscriptionFragment_products } from 'apollo/fragments/types/TeamSubscriptionFragment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ProductInput } from 'types/graphql';
import { ID } from 'types/pigeon';

import { makeGetTeamProrationByTeamId } from './dashboard-billing.selectors';
import { fetchTeamProrationAC } from './dashboard-billing-slice';
import useTeamSubscription from './useTeamSubscription';

function makeProductsInput(productsArr: TeamSubscriptionFragment_products[]): ProductInput[] {
  return productsArr.map(({ priceId, quantity }) => ({id: priceId, name: '', quantity }));
};

export default function useTeamProration(
  teamId?: ID,
): [number | undefined, boolean] {
  const dispatch = useDispatch();
  const [teamSubscription] = useTeamSubscription(teamId);
  const [teamProration, teamProrationFetched] = useCreateSelector(makeGetTeamProrationByTeamId, teamId);

  useEffect(() => {
    if (!teamSubscription) {
      return;
    }

    if (!teamProrationFetched && teamId) {
      dispatch(fetchTeamProrationAC.started(
        { input: { products: makeProductsInput(teamSubscription.products), teamId } },
      ));
    }
  }, [dispatch, teamProrationFetched, teamId, teamSubscription]);

  return [teamProration, teamProrationFetched];
};
