import { AsyncButton, EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import DashboardRouting from 'features/dashboard/dashboard.routing';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { ID } from 'types/pigeon';

import { createTeamDivisionAC, createTeamFolderAC } from '../dashboard-directory-slice';

interface CreateDirectoryDialogOwnProps {
  teamId: ID;
  parentId?: ID;
  onClose?: VoidFunction;
}

type CreateDirectoryDialogProps = CreateDirectoryDialogOwnProps & Pick<DialogProps, 'open'>;

function CreateDirectoryDialog(props: CreateDirectoryDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog maxWidth='xs' fullWidth={true} open={open} onClose={onClose}>
      <CreateDirectoryDialogContent {...props} />
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  public: {
    opacity: 0.5,
  },
  switchBase: {
    position: 'absolute',
    padding: createRemFromPx(9),
  },
}), {name: 'DashboardDirectoryContent'});

function CreateDirectoryDialogContent(props: CreateDirectoryDialogProps) {
  const { teamId, parentId, onClose } = props;
  const classes = useStyles(props);

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const onNavigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useStrictTranslation(['common', 'dashboard-directory']);

  const [name, setName] = useState('');
  const [makePrivate, setMakePrivate] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = e;
    setName(value);
  }, []);

  const handleMakePrivateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setMakePrivate(checked);
  }, []);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (loading) {
      return;
    }

    setLoading(true);
    let promise;
    if (parentId) {
      promise = promiseSagaDispatch(createTeamFolderAC, {
        teamId,
        parentId,
        name,
        private: makePrivate,
      });
    } else {
      promise = promiseSagaDispatch(createTeamDivisionAC, {
        teamId,
        name,
        private: makePrivate,
      });
    }
    try {
      const result = await promise;
      setLoading(false);
      if (parentId) {
        enqueueSnackbar(t('dashboard-directory:createfolder.success'), {variant: 'success'});
      } else {
        enqueueSnackbar(t('dashboard-directory:createchannel.success'), {variant: 'success'});
      }
      onClose?.();
      if (result.__typename === 'CreateDivisionPayload') {
        onNavigate(DashboardRouting.createDirectoryUrl(result.division.id));
      }
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
      setLoading(false);
    }
  }, [enqueueSnackbar, loading, makePrivate, name, onClose, onNavigate, parentId, promiseSagaDispatch, t, teamId]);

  const disabled = !name;

  const { canCreatePrivateFolder } = useAccessControl();

  return (
    <form onSubmit={handleSubmit}>
      <EnhancedDialogTitle>
        {t(parentId ? 'dashboard-directory:createfolder.title' : 'dashboard-directory:createchannel.title')}
      </EnhancedDialogTitle>
      <DialogContent className={classes.root}>
        <FlexSpacer orientation='vertical'>
          <TextField
          name='name'
          value={name}
          autoFocus={true}
          onChange={handleNameChange}
          fullWidth={true}
          />
          {!parentId && canCreatePrivateFolder && (
            <FlexSpacer spacing={0} orientation='vertical' flexAlignItems='flex-start'>
              <FormControlLabel
              control={
                <Switch
                checked={makePrivate}
                onChange={handleMakePrivateChange}
                name='makeprivate'
                color='primary'
                classes={{ switchBase: classes.switchBase }}
                />
              }
              label={t('dashboard-directory:makeprivate')}
              classes={{
                label: !makePrivate ? classes.public : undefined,
              }}
              />
              <Typography variant='caption'>
                {makePrivate
                  ? t('dashboard-directory:makeprivatedescription')
                  : t('dashboard-directory:makepublicdescription')
                }
              </Typography>
            </FlexSpacer>
          )}
        </FlexSpacer>
      </DialogContent>
      <DialogActions>
        <Button type='button' variant='outlined' onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <AsyncButton type='submit' variant='contained' color='primary' disabled={disabled || loading} loading={loading}>
          {t(parentId ? 'dashboard-directory:createfolder.confirm' : 'dashboard-directory:createchannel.confirm')}
        </AsyncButton>
      </DialogActions>
    </form>
  );
}

export default React.memo(CreateDirectoryDialog);
