import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

interface FeedbackConfirmationDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
}

type FeedbackConfirmationDialogProps = FeedbackConfirmationDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4),
  },
  icon: {
    color: theme.palette.primary.main,
    width: 60,
    height: 60,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  msg: {
    marginTop: theme.spacing(3),
  },
}), {name: 'FeedbackConfirmationDialog'});

function FeedbackConfirmationDialog(props: FeedbackConfirmationDialogProps) {
  const classes = useStyles(props);
  const { className, open, onClose } = props;
  const { t } = useStrictTranslation(['dialog']);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <DialogContent className={classNames(classes.root, className)}>
        <div className={classes.container}>
          <CheckCircleRoundedIcon className={classes.icon}/>
          <Typography variant='h4' className={classes.msg}>{t('dialog:sendfeedback.thankyou')}</Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(FeedbackConfirmationDialog);
