import gql from 'graphql-tag';

export const DeleteTeamDirectory_Mutation = gql`
    mutation DeleteTeamDirectoryMutation(
        $input: DeleteDirectoryInput!
    ) {
        deleteDirectory(
            input: $input
        ) {
            directoryId
        }
    }
`;
