import { Theme } from '@insights-gaming/theme';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import classNames from 'classnames';
import { EIntercomID } from 'constants/strings';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

interface CommentsMenuOwnProps {
  className?: string;
  autoPauseComment: boolean;
  autoScrollComment: boolean;
  autoPlayAudio: boolean;
  autoPauseTyping: boolean;
  togglAutoPauseComment: VoidFunction;
  toggleAutoScrollComment: VoidFunction;
  toggleAutoPlayAudio: VoidFunction;
  toggleAutoPauseTyping: VoidFunction;
}

type CommentsMenuProps = CommentsMenuOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  switchFormControlLabel: {
    margin: 0,
  },
  switchBase: {
    position: 'absolute',
  },
}), {name: 'CommentsMenu'});

function CommentsMenu(props: CommentsMenuProps) {
  const classes = useStyles(props);
  const {
    className,
    autoPauseComment,
    autoScrollComment,
    autoPlayAudio,
    autoPauseTyping,
    togglAutoPauseComment,
    toggleAutoScrollComment,
    toggleAutoPlayAudio,
    toggleAutoPauseTyping,
  } = props;
  const commentsMenuPopupState = usePopupState({popupId: 'comments-menu', variant: 'popover'});
  const { t } = useStrictTranslation(['video', 'common']);
  return (
    <div className={classNames(classes.root, className)}>
      <Tooltip title={t('common:scrollsettings')}>
        <IconButton size='small' {...bindTrigger(commentsMenuPopupState)} id={EIntercomID.COMMENTS_MENU}>
          <SettingsIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <Popover
      {...bindPopover(commentsMenuPopupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      >
        <List>
          <Tooltip title={t('video:replay.autopausetooltip')}>
            <ListItem>
              <FormControlLabel
              className={classes.switchFormControlLabel}
              control={
                <Switch
                size='small'
                checked={autoPauseComment}
                onChange={togglAutoPauseComment}
                value='commentAutoPause'
                classes={{ switchBase: classes.switchBase }}
                />
              }
              label={t('video:replay.autopausecomments')}
              />
            </ListItem>
          </Tooltip>
          <Tooltip title={t('video:replay.autopausetypingtooltip')}>
            <ListItem>
              <FormControlLabel
              className={classes.switchFormControlLabel}
              control={
                <Switch
                size='small'
                checked={autoPauseTyping}
                onChange={toggleAutoPauseTyping}
                value='commentAutoPauseTyping'
                classes={{ switchBase: classes.switchBase }}
                />
              }
              label={t('video:replay.autopausetypingcomments')}
              />
            </ListItem>
          </Tooltip>
          <Tooltip title={t('video:replay.autoscrolltooltip')}>
            <ListItem>
              <FormControlLabel
              className={classes.switchFormControlLabel}
              control={
                <Switch
                size='small'
                checked={autoScrollComment}
                onChange={toggleAutoScrollComment}
                value='commentAutoScroll'
                classes={{ switchBase: classes.switchBase }}
                />
              }
              label={t('video:replay.autoscrollcomments')}
              />
            </ListItem>
          </Tooltip>
          <Tooltip title={t('video:replay.autoplayaudiotooltip')}>
            <ListItem>
              <FormControlLabel
              className={classes.switchFormControlLabel}
              control={
                <Switch
                size='small'
                checked={autoPlayAudio}
                onChange={toggleAutoPlayAudio}
                value='commentAutoPlayAudio'
                classes={{ switchBase: classes.switchBase }}
                />
              }
              label={t('video:replay.autoplayaudio')}
              />
            </ListItem>
          </Tooltip>
        </List>
      </Popover>
    </div>
  );
}

export default React.memo(CommentsMenu);
