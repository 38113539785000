import { EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { ProfileFragment } from 'apollo/fragments/types/ProfileFragment';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AddTeamMemberDialogContent from 'material/dialogs/invite-team-members-dialog-content/AddTeamMemberDialogContent';
import InviteTeamMembersDialogContent from 'material/dialogs/invite-team-members-dialog-content/InviteTeamMembersDialogContent';
import React, { useMemo } from 'react';
import { ID } from 'types/pigeon';

import { makeGetTeamEdgeById } from '../access-control/access-control-selector';
import { useAccessControl } from '../access-control/useAccessControl';
import { DirectoryHelper } from '../directory/dashboard-directory-helper';
import { makeGetTeamDirectoryUsersByDirectoryId } from '../directory/dashboard-directory-selector';
import { makeGetTeamMembersByTeamId } from '../member/dashboard-member-selector';
import { useFetchTeamIfNecessary } from '../team/useFetchTeamIfNecessary';
import CollaboratorItem from './CollaboratorItem';
import EditCollaboratorPrivilegesDialog from './EditCollaboratorPrivilegesDialog';
import MemberItem from './MemberItem';

interface CollaboratorEditorDialogOwnProps {
  className?: string;
  onClose?: VoidFunction;
  teamId: ID;
  directoryId: ID;
  collaborators: ProfileFragment[];
  directory?: DirectoryFragment;
}

type CollaboratorEditorDialogProps = CollaboratorEditorDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  divider: {
    margin: theme.spacing(1, 0),
  },
  memberButton: {
    alignSelf: 'flex-start',
  },
  setting: {
    marginLeft: theme.spacing(1),
  },
}), {name: 'CollaboratorEditorDialog'});

function CollaboratorEditorDialog(props: CollaboratorEditorDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth='xs'>
      <CollaboratorEditorDialogContent {...props} />
    </Dialog>
  );
}

function CollaboratorEditorDialogContent(props: CollaboratorEditorDialogProps) {
  const classes = useStyles(props);
  const { teamId, collaborators, onClose, directoryId, directory } = props;
  const [team] = useFetchTeamIfNecessary(teamId);
  const members = useCreateSelector(makeGetTeamMembersByTeamId, teamId);
  const currentUsersIds = useCreateSelector(makeGetTeamDirectoryUsersByDirectoryId, directory?.id).map(c => c.id);

  const filteredMembers = useMemo(() => {
    return members.filter((m) => (
      collaborators.find(c => c.id === m.user.id) ||
      m.user.id === directory?.teamDirectoryOwner?.id
    ));
  }, [members, collaborators, directory]);

  const filteredCollaborators = useMemo(() => {
    return collaborators.filter(c => !members.find(m => m.user.id === c.id));
  }, [collaborators, members]);

  const [
    isEditCollaboratorPrivilegesDialogOpen,
    openEditCollaboratorPrivilegesDialog,
    closeEditCollaboratorPrivilegesDialog,
  ] = useDialogState();

  const [
    isInviteTeamMembersDialogOpen,
    openInviteTeamMembersDialog,
    closeInviteTeamMembersDialog,
  ] = useDialogState();

  const [
    isAddTeamMemberDialogOpen,
    openAddTeamMemberDialog,
    closeAddTeamMemberDialog,
  ] = useDialogState();

  const { t } = useStrictTranslation(['common', 'dialog']);

  const [teamEdge] = useCreateSelector(makeGetTeamEdgeById, teamId);

  const { canAddPrivateFolderUser } = useAccessControl();

  const addMemberDisabled = useMemo(
    () => members
      .filter((m) => (
        m.user.id !== team?.owner.id &&
        m.user.id !== directory?.teamDirectoryOwner?.id &&
        m.user.__typename !== 'Self'
      ))
      .every(m => currentUsersIds.includes(m.user.id)) || !(canAddPrivateFolderUser || teamEdge?.isOwner),
    [currentUsersIds, directory, canAddPrivateFolderUser, members, team, teamEdge],
  );

  return (
    <React.Fragment>
      <EnhancedDialogTitle>
        {t('dialog:collaboratoreditor.title')}
        {(canAddPrivateFolderUser || teamEdge?.isOwner) && (
          <Tooltip title={t('common:settings')} placement='top'>
            <IconButton onClick={openEditCollaboratorPrivilegesDialog} size='small' className={classes.setting}>
              <SettingsIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        )}
      </EnhancedDialogTitle>
      <DialogContent>
        {team && directory && DirectoryHelper.isPrivate(directory) && (
          <React.Fragment>
            <FlexSpacer orientation='vertical'>
              <Button
              variant='outlined'
              startIcon={<AddIcon/>}
              size='small'
              className={classes.memberButton}
              onClick={openAddTeamMemberDialog}
              disabled={addMemberDisabled}
              >
                {t('dialog:collaboratoreditor.members', {count: filteredMembers.length})}
              </Button>
              {filteredMembers.map(m => (
                <MemberItem
                key={m.id}
                member={m}
                team={team}
                ownerId={directory?.teamDirectoryOwner?.id}
                directoryId={directoryId}
                />
              ))}
            </FlexSpacer>
            <Divider className={classes.divider}/>
          </React.Fragment>
        )}
        <FlexSpacer orientation='vertical'>
          {team && (
            <FlexSpacer flexAlignItems='center'>
              <Button
              variant='outlined'
              startIcon={<AddIcon/>}
              size='small'
              onClick={openInviteTeamMembersDialog}
              disabled={!(canAddPrivateFolderUser || teamEdge?.isOwner)}
              >
                {t('dialog:collaboratoreditor.collaborators', {count: filteredCollaborators.length})}
              </Button>
            </FlexSpacer>
          )}
          {filteredCollaborators.map(c => (
            <CollaboratorItem
            key={c.id}
            collaborator={c}
            teamId={teamId}
            directoryId={directoryId}
            hasAdminRole={canAddPrivateFolderUser || teamEdge?.isOwner}
            />
          ))}
        </FlexSpacer>
      </DialogContent>
      <EditCollaboratorPrivilegesDialog
      directoryId={directoryId}
      teamId={teamId}
      onClose={closeEditCollaboratorPrivilegesDialog}
      open={isEditCollaboratorPrivilegesDialogOpen}
      />
      {directory && team && (
        <Dialog open={isAddTeamMemberDialogOpen} onClose={closeAddTeamMemberDialog} fullWidth={true}>
          <AddTeamMemberDialogContent
          onClose={closeAddTeamMemberDialog}
          directory={directory}
          team={team}
          />
        </Dialog>
      )}
      {team && (
        <Dialog open={isInviteTeamMembersDialogOpen} onClose={closeInviteTeamMembersDialog} fullWidth={true}>
          <InviteTeamMembersDialogContent
          teamId={team.id}
          onClose={closeInviteTeamMembersDialog}
          directory={directory}
          />
        </Dialog>
      )}
    </React.Fragment>
  );
}

export default React.memo(CollaboratorEditorDialog);
