import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { makeGetTeamDirectoryUsersByDirectoryId } from 'features/dashboard/directory/dashboard-directory-selector';
import { addPrivateDirectoryUsersAC } from 'features/dashboard/directory/dashboard-directory-slice';
import { makeGetTeamMembersByTeamId } from 'features/dashboard/member/dashboard-member-selector';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import MultiSelect, { OptionType } from 'material/multi-select/MultiSelect';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors/getMe';
import { ID } from 'types/pigeon';

import MultiValueMemberChip from './MultiValueMemberChip';

function mapMemberToOption(member: MemberFragment): OptionType {
  return {
    label: member.user.alias,
    value: member.user.id,
  };
}

interface TeamMemberPickerOwnProps {
  className?: string;
  directory: DirectoryFragment;
  onClose?: VoidFunction;
  team: TeamFragment;
}

type TeamMemberPickerProps = TeamMemberPickerOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'TeamMemberPicker'});

function TeamMemberPicker(props: TeamMemberPickerProps) {
  const classes = useStyles(props);
  const { className, directory, onClose, team } = props;

  const [loading, setLoading] = useState(false);
  const { canAddPrivateFolderUser } = useAccessControl();
  const me = useSelector(getMe);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useStrictTranslation(['common', 'dialog']);

  const members = useCreateSelector(
    makeGetTeamMembersByTeamId,
    directory.team.id,
  ).filter(m => m.user.id !== me?.id && m.user.id !== team.owner.id);

  const currentUsersIds = useCreateSelector(makeGetTeamDirectoryUsersByDirectoryId, directory.id).map(c => c.id);

  const userOptions = useMemo(
    () => members.filter(member => !currentUsersIds.includes(member.user.id)).map(mapMemberToOption),
    [currentUsersIds, members],
  );

  const [selected, setSelected] = useState<OptionType[]>([]);

  const disabled = useMemo(() => {
    if (!currentUsersIds.length && !selected.length || !canAddPrivateFolderUser) {
      return true;
    }
    return selected.length === currentUsersIds.length && selected.every(
      (option) => currentUsersIds.includes(option.value),
    );
  }, [canAddPrivateFolderUser, currentUsersIds, selected]);

  const handleChange = useCallback((value?: OptionType[]) => {
    setSelected(value || []);
  }, []);

  const handleAddToChannelOnClick = useCallback(async () => {
    if (loading) { return; }
    setLoading(true);

    try {
      const selectedUsers = new Set<ID>(selected.map(option => option.value));
      const usersToBeAdded = new Set<ID>(selectedUsers);

      const usersToBeAddedArray = Array.from(usersToBeAdded);

      if (usersToBeAddedArray.length) {
        await promiseSagaDispatch(addPrivateDirectoryUsersAC, {
          directoryId: directory.id,
          teamId: directory.team.id,
          userIds: usersToBeAddedArray,
        });
      }

      setLoading(false);
      enqueueSnackbar(t('dialog:invitechannel.updateteammembersssuccess'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
      setLoading(false);
    } finally {
      onClose?.();
    }
  }, [loading, selected, enqueueSnackbar, t, promiseSagaDispatch, directory.id, directory.team.id, onClose]);

  return (
    <FlexSpacer className={classNames(classes.root, className)} orientation='vertical'>
      <MultiSelect
      id='user-multi-select'
      label={t('dialog:invitechannel.userselect')}
      placeholder={t('dialog:invitechannel.userselectplaceholder')}
      value={selected}
      options={userOptions}
      isMulti={true}
      autoFocus={true}
      onChange={handleChange}
      components={{MultiValue: MultiValueMemberChip}}
      />
      <AsyncButton
      onClick={handleAddToChannelOnClick}
      disabled={disabled || loading}
      loading={loading}
      fullWidth={true}
      color='primary'
      variant='contained'
      >
        {t('dialog:invitechannel.addtochannel')}
      </AsyncButton>
    </FlexSpacer>
  );
}

export default React.memo(TeamMemberPicker);
