import { UpdateUserNotificationSettings_Mutation } from 'apollo/mutations';
import { GetDirectoryNotificationSettings_Query, GetNotificationSettings_Query, GetTeamNotificationSettings_Query } from 'apollo/queries';
import { watchAsyncMutation, watchAsyncQuery } from 'helpers/saga/effects';
import { all } from 'redux-saga/effects';

import { fetchDirectoryNotificationSettingsAC, fetchTeamNotificationSettingsAC, fetchUserNotificationSettingsAC, updateUserNotificationSettingsAC } from './notification-settings-slice';

export default function* userNotificationSettingsSaga() {
  yield all([
    watchAsyncQuery(
      fetchUserNotificationSettingsAC,
      GetNotificationSettings_Query,
      ['me', 'notificationSettings'],
    ),
    watchAsyncQuery(
      fetchTeamNotificationSettingsAC,
      GetTeamNotificationSettings_Query,
      ['me', 'teamNotificationsSettings'],
    ),
    watchAsyncQuery(
      fetchDirectoryNotificationSettingsAC,
      GetDirectoryNotificationSettings_Query,
      ['me', 'directoryNotificationsSettings'],
    ),
    watchAsyncMutation(
      updateUserNotificationSettingsAC,
      UpdateUserNotificationSettings_Mutation,
      ['updateUserNotificationSettings', 'userNotificationSettings'],
    ),
  ]);
}
