import globalRoleSaga from 'components/global-roles/global-role-saga';
import userNotificationSettingsSaga from 'components/settings/notification-settings/notification-settings-saga';
import notificationsSaga from 'components/settings/notifications/notifications-saga';
import audioRecorderSaga from 'components/video/video-replay/video-comment/audio-comment/audio-comment-saga';
import authSaga from 'features/auth/auth-saga';
import { captureGamesStatisticsSaga } from 'features/capture-games-statistics/common';
import billingSaga from 'features/dashboard/billing/dashboard-billing-saga';
import dashboardDirectorySaga from 'features/dashboard/directory/dashboard-directory-saga';
import dashboardInvitationSaga from 'features/dashboard/invitation/dashboard-invitation-saga';
import dashboardMemberSaga from 'features/dashboard/member/dashboard-member-saga';
import dashboardRoleSaga from 'features/dashboard/role/dashboard-role-saga';
import dashboardTagSaga from 'features/dashboard/tag/dashboard-tag-saga';
import dashboardTeamSaga from 'features/dashboard/team/dashboard-team-saga';
import dashboardVideoSaga from 'features/dashboard/video/dashboard-video-saga';
import videoBookmarksSaga from 'features/dashboard/video/video-bookmark/video-bookmark-saga';
import liveSessionSaga from 'features/live-session/live-session-saga';
import searchSaga from 'features/search/search-sagas';
import statisticsSaga from 'features/statistics/statistics-sagas';
import telemetrySaga from 'features/telemetry/telemetry-saga';
import tipsSaga from 'features/tips/tips-saga';
import uploadSaga from 'features/upload/upload-saga';
import { all } from 'redux-saga/effects';

import commentSaga from './comment-sagas';
import errorSaga from './error-sagas';
import eventSaga from './event-sagas';
import invitationSaga from './invitation-sagas';
import keybindingSaga from './keybinding-sagas';
import overwatchAdvancedQuerySaga from './overwatch-advanced-query-saga';
import promiseSaga from './promise-saga';
import publicStatisticsSaga from './public-statistics-saga';
import teamSaga from './team-sagas';
import userSaga from './user-sagas';
import videoPlayerSaga from './video-player-sagas';

export default function* rootSaga() {
  yield all([
    teamSaga(),
    uploadSaga(),
    authSaga(),
    tipsSaga(),
    audioRecorderSaga(),
    commentSaga(),
    invitationSaga(),
    userSaga(),
    statisticsSaga(),
    eventSaga(),
    errorSaga(),
    keybindingSaga(),
    publicStatisticsSaga(),
    videoPlayerSaga(),
    globalRoleSaga(),
    overwatchAdvancedQuerySaga(),
    dashboardDirectorySaga(),
    dashboardVideoSaga(),
    dashboardTagSaga(),
    dashboardTeamSaga(),
    dashboardMemberSaga(),
    dashboardRoleSaga(),
    dashboardInvitationSaga(),
    promiseSaga(),
    billingSaga(),
    userNotificationSettingsSaga(),
    notificationsSaga(),
    videoBookmarksSaga(),
    liveSessionSaga(),
    captureGamesStatisticsSaga(),
    searchSaga(),
    telemetrySaga(),
  ]);
}
