import { fileUploadAC, IFileUploadInput } from 'features/upload/upload-slice';
import { promisifyDispatch } from 'hooks/usePromiseSagaDispatch';
import InProgressUploadDialogContent, { IInProgressUploadDialogContentDispatch } from 'material/dialogs/in-progress-upload-dialog-content/InProgressUploadDialogContent';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

function mapDispatchToProps(dispatch: Dispatch): IInProgressUploadDialogContentDispatch {
  const promiseSagaDispatch = promisifyDispatch(dispatch);
  return {
    onTusUpload: (input: IFileUploadInput) => promiseSagaDispatch(fileUploadAC, {...input, bypass: true}),
  };
}

export default connect(null, mapDispatchToProps)(InProgressUploadDialogContent);
