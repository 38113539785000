import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx,Theme } from '@insights-gaming/theme';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';

import { DirectoryHelper } from '../dashboard-directory-helper';
import { useDirectoryContextMenu } from './useDirectoryContextMenu';

interface DirectoryPrivacyChangeDialogOwnProps {
  className?: string;
  directory: DirectoryFragment;
  onClose?: VoidFunction;
}

type DirectoryPrivacyChangeDialogProps = DirectoryPrivacyChangeDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  content: {
    width: createRemFromPx(600),
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  switchBase: {
    position: 'absolute',
  },
}), {name: 'DirectoryPrivacyChangeDialog'});

function DirectoryPrivacyChangeDialog(props: DirectoryPrivacyChangeDialogProps) {
  const classes = useStyles(props);
  const { className, open, directory, onClose } = props;

  const { t } = useStrictTranslation(['common', 'dashboard-directory']);
  const [ loading, setLoading ] = useState(false);
  const [ privateMode, setPrivateMode ] = useState(DirectoryHelper.isPrivate(directory));
  const { enqueueSnackbar } = useSnackbar();

  const disabled = privateMode === DirectoryHelper.isPrivate(directory);

  const {
    updateFolder,
    updateDivision,
  } = useDirectoryContextMenu<DirectoryFragment>(directory);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleUpdate = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      if (DirectoryHelper.isFolder(directory)) {
        await updateFolder({
          folderId: directory.id,
          teamId: directory.team.id,
          private: privateMode,
        });
      } else {
        await updateDivision({
          divisionId: directory.id,
          teamId: directory.team.id,
          private: privateMode,
        });
      }
      setLoading(false);
      onClose?.();
      if (DirectoryHelper.isDivision(directory)) {
        enqueueSnackbar(t('dashboard-directory:updatechannelprivacy.success'), {variant: 'success'});
      } else {
        enqueueSnackbar(t('dashboard-directory:updatefolderprivacy.success'), {variant: 'success'});
      }
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    }
  }, [loading, directory, onClose, updateFolder, privateMode, updateDivision, enqueueSnackbar, t]);

  const handlePrivateModeOnChange = useCallback(() => setPrivateMode((prevMode) => !prevMode), []);

  const dialogTexts = useMemo(() => {
    return {
      title: t(DirectoryHelper.isDivision(directory)
        ? 'dashboard-directory:updatechannelprivacy.title'
        : 'dashboard-directory:updatefolderprivacy.title',
      ),
      description: (
        <FlexSpacer>
          <Switch
          checked={privateMode}
          onChange={handlePrivateModeOnChange}
          name='makeprivate'
          color='primary'
          size='small'
          classes={{ switchBase: classes.switchBase }}
          />
          <FlexSpacer orientation='vertical'>
            <Typography className={classNames({[classes.disabled]: !privateMode})}>
              {t('dashboard-directory:menuItems.makeprivate')}
            </Typography>
            <Typography>
              {t(privateMode
                ? 'dashboard-directory:makeprivatedescription'
                : 'dashboard-directory:makepublicdescription',
              )}
            </Typography>
          </FlexSpacer>
        </FlexSpacer>
      ),
    };
  }, [t, directory, privateMode, handlePrivateModeOnChange, classes.switchBase, classes.disabled]);

  return (
    <Dialog
    className={classNames(classes.root, className)}
    open={open}
    classes={{ paper: classes.content }}
    onClose={onClose}
    >
      <AlertDialogContent
      titleText={dialogTexts.title}
      description={dialogTexts.description}
      cancel={{
        text: t('common:cancel'),
        action: handleClose,
      }}
      confirm={{
        text: t('common:confirm'),
        loading,
        disabled: disabled || loading,
        action: handleUpdate,
      }}
      />
    </Dialog>
  );
}

export default React.memo(DirectoryPrivacyChangeDialog);
