import { VideoReplayActions, videoReplayActions, VideoReplayContext, VideoReplayState } from '@insights-gaming/material-components';
import { ActionFromGuard, ActionGuard, ReducerChannel, useChannelTake, UseTakeFnCallback } from '@insights-gaming/use-take';
import { createPauseVideoAction, createPlayVideoAction, createSeekVideoAction, createSpeedVideoAction } from 'factories/kmsessionEventFactory';
import { getLiveSessionRawVideoState } from 'features/live-session/live-session-selector';
import { liveSessionSendActionAC } from 'features/live-session/live-session-slice';
import { withActionMetadata } from 'helpers/withActionMetadata';
import { useHackRef } from 'hooks/useHackRef';
import clamp from 'lodash/clamp';
import { DependencyList, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KMSessionOutgoingAction } from 'types/pigeon/kmsession';
import { AnyAction } from 'typescript-fsa';


type Value = React.ContextType<typeof VideoReplayContext>;

const ignore = Symbol();

// positionDisparityThreshold seconds of difference allowed between the
// video player position and live session position.
const positionDisparityThreshold = 1;

function useFilteredChannelTake<Guard extends ActionGuard<VideoReplayActions, any>>(
  channel: ReducerChannel<VideoReplayActions, VideoReplayState>,
  guard: Guard,
  callback: UseTakeFnCallback<Guard, VideoReplayState>,
  deps: DependencyList,
) {
  useChannelTake(channel, guard, (action: ActionFromGuard<Guard>, prevState: VideoReplayState) => {
    if (action.meta?.[ignore] || action.meta?.initialization || action.meta?.raw) {
      return;
    }

    callback(action, prevState);
  }, deps);
}

function usePauseHandler(
  channel: ReducerChannel<VideoReplayActions, VideoReplayState>,
  sendAction: (action: KMSessionOutgoingAction) => void,
  videoReplayPosition: number,
) {
  // NOTE: the progress in the replay state is updated after the pause/toggle
  //       is dispatched so it must be captured after that to ensure the most
  //       accurate timestamp is delivered to other session members.
  const nextProgressCaptureTimeout = useRef<NodeJS.Timeout>();

  useFilteredChannelTake(
    channel,
    videoReplayActions.setProgress,
    ({ payload }) => {
      if (typeof nextProgressCaptureTimeout.current === 'undefined') {
        return;
      }

      clearTimeout(nextProgressCaptureTimeout.current);
      nextProgressCaptureTimeout.current = undefined;

      sendAction(createPauseVideoAction(payload.playedSeconds));
    },
    [sendAction],
  );

  const positionRef = useHackRef(videoReplayPosition);
  const sendActionRef = useHackRef(sendAction);

  return useCallback(() => {
    nextProgressCaptureTimeout.current = setTimeout(() => {
      sendActionRef.current(createPauseVideoAction(positionRef.current));
      nextProgressCaptureTimeout.current = undefined;
    }, 50);
  }, [positionRef, sendActionRef]);
}

function calcActualLiveSessionPosition(
  position: number,
  playing: number | null | undefined,
  speed: number,
  duration: number,
) {
  let actualPosition = position;
  if (typeof playing === 'number') {
    actualPosition = clamp(actualPosition + (Date.now() - playing) / 1000 * speed, 0, duration);
  }

  return actualPosition;
}

export function useVideoReplayLiveSessionBindings(videoReplay: Value): Value {
  const dispatch = useDispatch();

  const {
    dispatch: _videoReplayDispatch,
    channel,
    state: {
      progress: {
        playedSeconds: videoReplayPosition,
      },
      duration: videoReplayDuration,
    },
  } = videoReplay;

  const videoReplayDispatch = useCallback(
    (action: AnyAction) => _videoReplayDispatch(withActionMetadata(action, { [ignore]: true })),
    [_videoReplayDispatch],
  );

  const sendAction = useCallback(
    (event: KMSessionOutgoingAction) => dispatch(liveSessionSendActionAC.started(event)),
    [dispatch],
  );

  const { playing, position, speed } = useSelector(getLiveSessionRawVideoState) || {};

  // const shouldResync = useRef(true);

  useEffect(() => {
    if (typeof position !== 'number' || typeof speed !== 'number') {
      return;
    }

    let actualLiveSessionPosition = calcActualLiveSessionPosition(position, playing, speed, videoReplayDuration);
    if (
      (
        typeof playing === 'number' &&
        Math.abs(videoReplayPosition - actualLiveSessionPosition) < positionDisparityThreshold
      ) ||
      (
        !playing &&
        videoReplayPosition === actualLiveSessionPosition
      )
    ) {
      return;
    }
    // if (playing) {
    //   shouldResync.current = false;
    // }

    videoReplayDispatch((playing ? videoReplayActions.play : videoReplayActions.pause)());

    videoReplayDispatch(videoReplayActions.seekTo({
      type: 'seconds',
      amount: actualLiveSessionPosition,
      seekCommitted: true,
    }));
  }, [playing, position, speed, videoReplayDispatch, videoReplayDuration, videoReplayPosition]);

  useEffect(() => {
    if (typeof playing === 'undefined') {
      return;
    }

    // TODO: prevent execution before everything is done intializing

    videoReplayDispatch((playing ? videoReplayActions.play : videoReplayActions.pause)());
  }, [videoReplayDispatch, playing]);

  useEffect(() => {
    if (typeof position !== 'number') {
      return;
    }

    videoReplayDispatch(videoReplayActions.seekTo({ type: 'seconds', amount: position, seekCommitted: true }));
  }, [videoReplayDispatch, position]);

  useEffect(() => {
    if (typeof speed !== 'number') {
      return;
    }

    videoReplayDispatch(videoReplayActions.changePlaybackRate(speed));
  }, [videoReplayDispatch, speed]);

  const handlePause = usePauseHandler(channel, sendAction, videoReplayPosition);

  useFilteredChannelTake(
    channel,
    videoReplayActions.play,
    (_, { progress: { playedSeconds }, playing }) => {
      if (!playing) {
        sendAction(createPlayVideoAction(playedSeconds));
      }
    },
    [sendAction],
  );

  useFilteredChannelTake(
    channel,
    videoReplayActions.pause,
    (_, { playing }) => {
      if (playing) {
        handlePause();
      }
    },
    [handlePause],
  );

  useFilteredChannelTake(
    channel,
    videoReplayActions.togglePlayState,
    (_, { playing, progress: { playedSeconds, played } }) => {
      if (playing) {
        handlePause();
      } else {
        if (played < 1) {
          sendAction(createPlayVideoAction(playedSeconds));
        } else {
          sendAction(createPlayVideoAction(0));
        }
      }
    },
    [sendAction, handlePause],
  );

  useFilteredChannelTake(
    channel,
    videoReplayActions.seekTo,
    ({ payload: options }, { duration, progress: { playedSeconds }, _seekOptions: prevSeek }) => {
      if (options.seekCommitted) {
        switch (options.type) {
          case 'offset':
            if (prevSeek) {
              switch (prevSeek.type) {
                case 'seconds':
                  sendAction(createSeekVideoAction(playedSeconds + prevSeek.amount + options.amount));
                  break;
                case 'fraction':
                  sendAction(createSeekVideoAction(duration * prevSeek.amount + options.amount));
                  break;
              }
            } else {
              sendAction(createSeekVideoAction(playedSeconds + options.amount));
            }
            break;
          case 'seconds':
            sendAction(createSeekVideoAction(options.amount));
            break;
          case 'fraction':
            sendAction(createSeekVideoAction(duration * options.amount));
            break;
        }
      }
    },
    [sendAction],
  );

  useFilteredChannelTake(
    channel,
    videoReplayActions.changePlaybackRate,
    ({ payload: rate }) => {
      sendAction(createSpeedVideoAction(rate));
    },
    [sendAction],
  );

  return videoReplay;
}
