import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dispatch } from 'redux';

import OverwatchMatchOverview, { IOverwatchMatchOverviewDispatch } from '../components/video/overwatch-match-overview/OverwatchMatchOverview';
import { onNavigateFactory } from '../types/dispatch';

function mapDispatchToProps(dispatch: Dispatch): IOverwatchMatchOverviewDispatch {
  return {
    onNavigate: onNavigateFactory(dispatch),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(OverwatchMatchOverview));
