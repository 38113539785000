import { AsyncButton, EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import TextField from '@material-ui/core/TextField';
import PaletteIcon from '@material-ui/icons/Palette';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { Color } from 'csstype';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { createCustomRoleAC, updateCustomRoleAC } from 'features/dashboard/team/dashboard-team-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import update from 'immutability-helper';
import fromPairs from 'lodash/fromPairs';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';

import { MAX_ROLE_NAME_LENGTH } from '../../../constants/numbers';
import { generateRandomColor, presetColors } from '../../../helpers/color';
import { CommonF, CommonNS } from '../../../locales/en/common';
import { DialogF, DialogNS } from '../../../locales/en/dialog';
import { TeamPrivilege } from '../../../types/graphql';
import Message from '../../message/Message';
import { IPrivilegeGroup, litePrivilegeGroups, proPrivilegeGroups } from './privilege';
import PrivilegeGroups from './PrivilegeGroups';

interface RoleEditorDialogContentOwnProps {
  team: TeamFragment;
  role?: RoleInterfaceFragment;
  onClose?: VoidFunction;
  isPreset?: boolean;
}

type Props = RoleEditorDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  inputWrapper: {
    flex: 1,
    overflowY: 'auto',
  },
  privilegeListWrapper: {
    marginBottom: theme.spacing(1),
    overflowY: 'auto',
  },
}), {name: 'RoleEditorDialogContent'});

function RoleEditorDialogContent(props: Props) {
  const classes = useStyles(props);
  const { onClose, team, role, isPreset } = props;

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const accessControl = useAccessControl();

  const popupState = usePopupState({popupId: 'role-color-picker', variant: 'popover'});

  const { t } = useTranslation([CommonNS, DialogNS]);
  const { enqueueSnackbar } = useSnackbar();

  const editMode = !!role && !isPreset;
  const titleKey = useMemo(() => DialogF(editMode ? 'updateroles.title' : 'createroles.title'), [editMode]);

  const [privileges, setPrivileges] = useState<Dictionary<boolean>>(() => (
    fromPairs(role?.privileges.map(t => ([t, true])))
  ));

  const [name, setName] = useState(!isPreset ? role?.name || '' : '');
  const [color, setColor] = useState(role && role.__typename === 'CustomRole' ? role.color : generateRandomColor());
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  const handleOnSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) { return; }

    const privKeys = Object.keys(privileges);
    const priv = privKeys.filter((key) => privileges[key]) as TeamPrivilege[];

    if (priv.length === 0) {
      setError(new Error(t(DialogF('updateroles.noroleselected'))));
      return;
    }

    const nameTrim = name.trim().slice(0, MAX_ROLE_NAME_LENGTH);
    if (!nameTrim) { return; }

    setLoading(true);

    try {
      let snackbarText: string;
      if (role && role.__typename === 'CustomRole'  && !isPreset) {
        await promiseSagaDispatch(updateCustomRoleAC, {
          roleId: role.id,
          name: name !== role.name ? name : undefined,
          teamId: team.id,
          color,
          privileges: priv,
        });
        snackbarText = t(DialogF('updateroles.success'));
      } else {
        await promiseSagaDispatch(createCustomRoleAC, {
          name,
          teamId: team.id,
          color,
          privileges: priv,
        });
        snackbarText = t(DialogF('createroles.success'));
      }
      enqueueSnackbar(snackbarText, {variant: 'success'});
      setLoading(false);
      onClose?.();
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
      setLoading(false);
      setError(error);
    }
  }, [color, enqueueSnackbar, loading, name, onClose, privileges, promiseSagaDispatch, role, t, team.id, isPreset]);

  const handleTextFieldOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleCheckboxOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPrivileges(update(privileges, {[e.target.name as TeamPrivilege]: {$set: e.target.checked}}));
    setError(undefined);
  }, [privileges]);

  const handleSelectAllPrivilegesOnClick = useCallback((group: IPrivilegeGroup, deselect?: boolean) => {
    if (deselect) {
      setPrivileges(privileges => {
        return update(privileges, {$merge: fromPairs(group.privileges.map(p => [p, false]))});
      });
    } else {
      setPrivileges(privileges => {
        return update(privileges, {$merge: fromPairs(group.privileges.map(p => [p, true]))});
      });
    }
  }, []);

  const handleColorChangeComplete = useCallback(({hex}: {hex: string}) => {
    setColor(hex);
  }, []);

  const handleSwatchOnClick = (c: Color) => () => setColor(c);

  const handleRandomizeColor = useCallback(() => setColor(generateRandomColor()), []);

  const renderColorCircle = useCallback((c: Color) => {
    return (
      <IconButton key={c} style={{backgroundColor: c}} onClick={handleSwatchOnClick(c)} />
    );
  }, []);

  const privilegeGroups = !(accessControl.canRequestSomeAnalysis || accessControl.canAccessSomeAnalysis)
    ? litePrivilegeGroups
    : proPrivilegeGroups;

  privilegeGroups.forEach(g => g.privileges = accessControl.filterPrivilegesByUserPrivileges(g.privileges));

  return (
    <form className={classes.root} onSubmit={handleOnSubmit}>
      <EnhancedDialogTitle>
        {t(titleKey)}
      </EnhancedDialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.inputWrapper}>
          <Box my={1}>
            <TextField
            label={t(CommonF('name'))}
            placeholder={t(CommonF('name'))}
            name='name'
            error={(!!name && !name.trim())}
            onChange={handleTextFieldOnChange}
            required={true}
            autoFocus={true}
            value={name}
            fullWidth={true}
            inputProps={{
              maxLength: MAX_ROLE_NAME_LENGTH,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SvgIcon>
                    <PermContactCalendarIcon htmlColor={color} />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            />
            <FlexSpacer flexAlignItems='center'>
              <FlexSpacer>
                {presetColors.map(renderColorCircle)}
              </FlexSpacer>
              <IconButton {...bindTrigger(popupState)}>
                <PaletteIcon />
              </IconButton>
              <Menu {...bindMenu(popupState)} MenuListProps={{disablePadding: true}}>
                <SketchPicker
                color={color}
                onChange={handleColorChangeComplete}
                disableAlpha={true}
                />
              </Menu>
              <Button onClick={handleRandomizeColor}>
                {t(DialogF('createtags.randomize'))}
              </Button>
            </FlexSpacer>
          </Box>
          <div className={classes.privilegeListWrapper}>
            <PrivilegeGroups
            groups={privilegeGroups}
            privileges={privileges}
            handleCheckboxOnChange={handleCheckboxOnChange}
            handleSelectAllPrivileges={handleSelectAllPrivilegesOnClick}
            />
          </div>
        </div>
        {error && (
          <Message message={error.message} variant='error' />
        )}
      </DialogContent>
      <DialogActions>
        <Button
        type='button'
        variant='outlined'
        onClick={onClose}
        >
          {t(CommonF('cancel'))}
        </Button>
        <AsyncButton
        type='submit'
        color='primary'
        loading={loading}
        disabled={!name || loading}
        variant='contained'
        >
          {t(titleKey)}
        </AsyncButton>
      </DialogActions>
    </form>
  );
}

export default React.memo(RoleEditorDialogContent);
