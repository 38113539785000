import { createRemFromPx, mobilePortrait, Theme } from '@insights-gaming/theme';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import classNames from 'classnames';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import { darkPaletteGradient } from 'helpers/color';
import React from 'react';

interface BundleCustomSelectorOwnProps {
  className?: string;
  onChange: (event: React.ChangeEvent | React.MouseEvent<HTMLElement>, newValue: 'bundle' | 'custom') => void
  tab: 'bundle' | 'custom';
}

type BundleCustomSelectorProps = BundleCustomSelectorOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display:'flex',
    justifyContent:'space-between',
    marginBottom: theme.spacing(2),
    flexWrap: 'wrap',
  },
  tabContainer: {
    fontSize: createRemFromPx(14),
    [mobilePortrait(theme)]: {
      flex: 1,
    },
    backgroundColor: theme.palette.background.dialog,
  },
  divider: {
    height: 'auto',
  },
  toggleButtonGroup: {
    width: '100%',
  },
  toggleButtonText: {
    '&$selected': {
      background: darkPaletteGradient.gradient2.toBottom,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
  selected: {},
}), {name: 'BundleCustomSelector'});

const useToggleButtonStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0.5),
    borderRadius: 12,
    [mobilePortrait(theme)]: {
      borderColor: theme.palette.primary.main,
    },
    '&:not($selected)': {
      color: theme.palette.text.deemphasized,
      backgroundColor: theme.palette.background.container,
    },
    '&&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text.primary,
    },
    '&$initial': {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.text.primary,
    },
    '&$selected': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text.primary,
    },
    // [mobilePortrait(theme)]: {
    //   '&:not(:last-child)': {
    //     borderTopRightRadius: theme.shape.borderRadius,
    //     borderBottomRightRadius: theme.shape.borderRadius,
    //   },
    //   '&:not(:first-child)': {
    //     borderTopLeftRadius: theme.shape.borderRadius,
    //     borderBottomLeftRadius: theme.shape.borderRadius,
    //     marginLeft: theme.spacing(1),
    //     borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
    //   },
    // },
  },
  initial: {},
  selected: {},
}), {name: 'ToggleButton'});

function BundleCustomSelector(props: BundleCustomSelectorProps) {
  const classes = useStyles(props);
  const { className, onChange, tab } = props;

  const isMobilePortrait = useIsMobilePortrait();
  const toggleButtonClasses = useToggleButtonStyles();

  if (isMobilePortrait) {
    return (
      <ToggleButtonGroup
      value={tab}
      onChange={onChange}
      exclusive={true}
      className={classes.toggleButtonGroup}
      >
        <ToggleButton
        key='bundle'
        aria-label='bundle'
        value='bundle'
        classes={toggleButtonClasses}
        >
          BUNDLE
        </ToggleButton>
        <ToggleButton
        key='custom'
        aria-label='custom'
        value='custom'
        classes={toggleButtonClasses}
        >
          CUSTOM
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }

  return (
    <Tabs
    value={tab}
    onChange={onChange}
    indicatorColor='primary'
    textColor='inherit'
    className={classNames(className, classes.root)}
    >
      <Tab
      label='BUNDLE'
      value='bundle'
      className={classes.tabContainer}
      />
      <Divider orientation='vertical' className={classes.divider} />
      <Tab
      label='CUSTOM'
      value='custom'
      className={classes.tabContainer}
      />
    </Tabs>
  );
}

export default React.memo(BundleCustomSelector);
