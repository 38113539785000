import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { UpdateTeamFolderMutation_updateFolder } from 'apollo/mutations/dashboard/types/UpdateTeamFolderMutation';
import { UpdateVideosMutation_updateVideos } from 'apollo/mutations/dashboard/types/UpdateVideosMutation';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';
import { DropTargetMonitor } from 'react-dnd';
import { ID } from 'types/pigeon';

import { DragItem, ItemTypes } from '../dnd/item-types';
import { DashboardMultiSelectContext } from '../multi-select/DashboardMultiSelectContext';
import { updateVideosAC } from '../video/dashboard-video-slice';
import { DEFAULT_DIVISION_ID } from './dashboard-directory-constants';
import { updateTeamFolderAC } from './dashboard-directory-slice';

export function useDirectoryDropTarget() {
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useStrictTranslation(['dashboard-directory']);

  const {
    folders: {
      deselectAll: deselectAllFolders,
    },
    videos: {
      deselectAll: deselectAllVideos,
    },
  } = useContext(DashboardMultiSelectContext);

  const handleItemDroppedOnDirectory = useCallback(async (directory: DirectoryFragment, item: DragItem) => {
    const videoIds = new Set<ID>(item.selection?.videos);
    const folderIds = new Set<ID>(item.selection?.folders);

    let message: string | undefined;
    if (videoIds.size && folderIds.size) {
      message = t('dashboard-directory:movevideoandfolder.success');
    } else if (videoIds.size) {
      message = t('dashboard-directory:movevideo.success');
    } else if (folderIds.size) {
      message = t('dashboard-directory:movefolder.success');
    }

    const promises: Array<Promise<UpdateVideosMutation_updateVideos | UpdateTeamFolderMutation_updateFolder>> = [
      promiseSagaDispatch(updateVideosAC, {
        teamId: directory.team.id,
        ids: Array.from(videoIds.values()),
        directoryId: directory.id,
      }),
      ...Array.from(folderIds.values()).map(id => promiseSagaDispatch(updateTeamFolderAC, {
        folderId: id,
        parentId: directory.id,
        teamId: directory.team.id,
      })),
    ];

    const promise = Promise.all(promises);

    try {
      deselectAllFolders();
      deselectAllVideos();
      await promise;
      if (message) { enqueueSnackbar(message, { variant: 'success' }); }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [deselectAllFolders, deselectAllVideos, enqueueSnackbar, promiseSagaDispatch, t]);

  return {
    handleItemDroppedOnDirectory,
  } as const;
}

export const canDropOnDirectory = (directory: DirectoryFragment) => (item: DragItem, monitor: DropTargetMonitor) => {
  if (item.id === directory.id) {
    return false;
  }
  if (directory.id === DEFAULT_DIVISION_ID) { // special case for default division
    return false;
  }
  const folders = item.selection?.folders;
  if (folders && folders.has(directory.id)) {
    return false;
  }
  if (item.type === ItemTypes.DIRECTORY_CARD) {
    return item.parentId !== directory.id;
  }
  if (item.type === ItemTypes.VIDEO_CARD) {
    return item.parentId !== directory.id;
  }
  return true;
};
