import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import classNames from 'classnames';
import { makeGetTeamMembersByTeamId } from 'features/dashboard/member/dashboard-member-selector';
import { useCreateSelector } from 'hooks/useCreateSelector';
import React, { useMemo } from 'react';
import { ID } from 'types/pigeon';

import CommentListView from './comment-list-view/CommentListView';
import LabelSelector from './label-selector/LabelSelector';

interface CommentListViewWrapperOwnProps {
  className?: string;
  videoId: ID;
  teamId?: ID;
  onEditCommentDrawing?: (comment: CommentFragment_VideoComment) => void;
}

type CommentListViewWrapperProps = CommentListViewWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
    overflow: 'hidden',
  },
}), {name: 'CommentListViewWrapper'});

function CommentListViewWrapper(props: CommentListViewWrapperProps) {
  const classes = useStyles(props);
  const { className, videoId, teamId, onEditCommentDrawing } = props;

  const labelSelector = useMemo(() => teamId ? <LabelSelector teamId={teamId}/> : undefined, [teamId]);

  const members = useCreateSelector(makeGetTeamMembersByTeamId, teamId);

  return (
    <CommentListView
    className={classNames(classes.root, className)}
    videoId={videoId}
    labelSelector={labelSelector}
    members={members}
    onEditCommentDrawing={onEditCommentDrawing}
    />
  );
}

export default React.memo(CommentListViewWrapper);
