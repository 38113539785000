type TCommon =
|'404'
|'appname'
|'overwatch'
|'ssbu'
|'valorant'
|'conjunction.or'
|'conjunction.and'
|'item'
|'item_plural'
|'itemcount'
|'itemcount_plural'
|'notification'
|'notification_plural'
|'notificationcount'
|'upload'
|'upload_plural'
|'uploadcount'
|'uploadcount_plural'
|'nouploads'
|'uploaded'
|'enqueued'
|'uploadcomplete'
|'all'
|'clear'
|'filter'
|'search'
|'save'
|'edit'
|'cancel'
|'confirm'
|'addfield'
|'none'
|'removecompleted'
|'maptypecount_none_plural'
|'maptypecount'
|'maptypecount_plural'
|'locationcount_none_plural'
|'locationcount'
|'locationcount_plural'
|'selectteam'
|'vod'
|'vod_plural'
|'shared'
|'match'
|'match_plural'
|'statistics'
|'closedalpha'
|'requestaccess'
|'teamcomparison'
|'meeting'
|'meeting_plural'
|'createteam'
|'updateteam'
|'updateteamsuccess'
|'updateteamfailure'
|'deleteteam'
|'leaveteam'
|'undo'
|'member'
|'member_plural'
|'removemember'
|'assignroles'
|'assignroles_success'
|'uploadlogo'
|'name'
|'addmembers'
|'addmembersplaceholder'
|'tag'
|'tag_plural'
|'notags'
|'createtag'
|'updatetag'
|'managetags'
|'edittags'
|'multivideoselect'
|'multivideoselect_plural'
|'multivideotag'
|'multivideotag_plural'
|'multivideodelete'
|'multivideodelete_plural'
|'selectallvideos'
|'deselectallvideos'
|'createtagplaceholder'
|'createrole'
|'updaterole'
|'createroleplaceholder'
|'settings'
|'about'
|'help'
|'profile'
|'sendfeedback'
|'faq'
|'support'
|'contact'
|'tos'
|'privacypolicy'
|'legalstuff'
|'copyright'
|'404_desc'
|'404_back'
|'session-expired'
|'copylink'
|'invitelink'
|'copied'
|'invite'
|'invitesuccess'
|'share'
|'remove'
|'delete'
|'ok'
|'add'
|'returntohome'
|'relativetime'
|'owner'
|'backtolist'
|'newmatch'
|'opponent'
|'map'
|'comments'
|'results'
|'date'
|'n/a'
|'more'
|'extranumber'
|'dismiss'
|'picexceedsizelimit'
|'session.live'
|'session.videos'
|'session.changevideo'
|'session.memberlist'
|'session.sessioninprogress'
|'session.invitefriends'
|'session.transferhost'
|'session.nomembers'
|'session.nosession'
|'session.hostleavetransfer'
|'session.hosttransferfailed'
|'session.hostterminatefailed'
|'session.disconnectconfirm'
|'session.disconnecttip'
|'session.backtosession'
|'invitations.invitemembers'
|'invitations.invited'
|'invitations.invitedby'
|'invitations.decline'
|'invitations.accept'
|'invitations.alreadyjoinedteam'
|'invitations.alreadysharedvideo'
|'rolegroup.videos'
|'rolegroup.tags'
|'rolegroup.comments'
|'rolegroup.organization'
|'rolegroup.sessions'
|'roles.CREATE_VOD'
|'roles.UPDATE_VOD'
|'roles.DELETE_VOD'
|'roles.REQUEST_ANALYSIS'
|'roles.CREATE_MATCH'
|'roles.UPDATE_MATCH'
|'roles.DELETE_MATCH'
|'roles.CREATE_COLLECTION'
|'roles.UPDATE_COLLECTION'
|'roles.DELETE_COLLECTION'
|'roles.CREATE_TAG'
|'roles.UPDATE_TAG'
|'roles.DELETE_TAG'
|'roles.ASSIGN_TAG'
|'roles.UPDATE_TEAM'
|'roles.SEND_TEAM_INVITATION'
|'roles.SEND_VOD_INVITATION'
|'roles.REVOKE_INVITATION'
|'roles.REMOVE_MEMBER'
|'roles.CREATE_ROLE'
|'roles.UPDATE_ROLE'
|'roles.DELETE_ROLE'
|'roles.ASSIGN_ROLE'
|'roles.ASSIGN_PRIVILEGE'
|'roles.REVOKE_PRIVILEGE'
|'roles.CREATE_COMMENT'
|'roles.UPDATE_COMMENT'
|'roles.DELETE_COMMENT'
|'roles.MODERATE_COMMENT'
|'roles.START_SESSION'
|'video.extracting'
|'video.awaitingvm'
|'video.executing'
|'collection.title'
|'collection.datecreated'
|'collection.videos'
|'collection.matches'
|'collection.map'
|'collection.opponent'
|'collection.result'
|'collection.video'
|'collection.typeinname'
|'collection.opponentteam'
|'collection.searchbynameorselect'
|'collection.noneselected'
|'collection.selectvideo'
|'collection.enteryourcomment'
|'collection.createconfirm'
|'collection.updateconfirm'
|'collection.createfail'
|'collection.namerequired'
|'collection.savematches'
|'collection.deletecollection'
|'texteditor.bold'
|'texteditor.italic'
|'texteditor.unorderedlist'
|'texteditor.orderedlist'
|'texteditor.blockquote'
|'units.seconds.full'
|'units.seconds.unit'
|'setstart'
|'setend'
|'authcheckfailure.title'
|'authcheckfailure.description'
|'retrying'
|'queued'
|'uploading'
|'encoding'
|'analyzing'
|'aggregating'
|'analyzed'
|'commented'
|'tagged'
|'opponentcolor'
|'blue'
|'red'
|'loading.generic'
|'loading.profile'
|'loading.videos'
|'videocard.errors.fatalerror'
|'videocard.errors.encodingerror'
|'videocard.errors.segmenttimeout'
|'videocard.fix.retryanalysis'
|'videocard.fix.contactus'
|'videocard.fix.reupload'
|'publicstats.owl.title'
|'publicstats.requestaccess'
;
const CommonNS = 'common';
function CommonF(_: TCommon): string { return `${CommonNS}:${_}`; }
export {CommonF,CommonNS,TCommon};
