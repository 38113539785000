import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID, TeamEdge } from 'types/pigeon';

export const getLegacyTeamEdgeDict = (state: RootReducer) => state.accessControl.legacyTeamEdgeDict;

const getLegacyTeamEdgeById = (state: RootReducer, teamId: ID) => state.accessControl.legacyTeamEdgeDict[teamId];

export const makeGetLegacyTeamEdgeById = () => createSelector(
  [getLegacyTeamEdgeById],
  (teamEdge) => teamEdge,
);

const getTeamEdgeById = (state: RootReducer, teamId: ID): [TeamEdge | undefined, boolean] => [
  state.accessControl.legacyTeamEdgeDict[teamId],
  teamId in state.accessControl.legacyTeamEdgeDict,
];

export const makeGetTeamEdgeById = () => createSelector(
  [getTeamEdgeById],
  (teamEdge) => teamEdge,
);
