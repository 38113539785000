import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { RoleInterfaceFragment_CustomRole } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { deleteRoleAC } from 'features/dashboard/team/dashboard-team-slice';
import { useDialogState } from 'hooks/useDialogState';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import RoleEditorDialogContent from 'material/dialogs/role-editor-dialog-content/RoleEditorDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';

import { RoleHelper } from './role-helper';
import RoleTableRow from './RoleTableRow';

interface RoleTableRowWrapperOwnProps {
  className?: string;
  role: RoleInterfaceFragment_CustomRole;
  team: TeamFragment;
}

type RoleTableRowWrapperProps = RoleTableRowWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  privileges: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}), {name: 'RoleTableRowWrapper'});

function RoleTableRowWrapper(props: RoleTableRowWrapperProps) {
  const classes = useStyles(props);
  const { className, role, team } = props;
  const accessControl = useAccessControl();
  const { t } = useStrictTranslation(['common', 'dialog', 'dashboard']);
  const wt = t as TFunction;
  const [ roleEditorDialogOpen, openRoleEditorDialog, closeRoleEditorDialog ] = useDialogState();
  const [ cloneRoleDialogOpen, openCloneRoleDialog, closeCloneRoleDialog ] = useDialogState();
  const [ deleteRoleDialogOpen, openDeleteRoleDialog, closeDeleteRoleDialog ] = useDialogState();
  const [ deleteLoading, setDeleteLoading ] = useState(false);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteRoleOnClick = useCallback(async () => {
    if (deleteLoading) { return; }
    setDeleteLoading(true);
    try {
      const input = {
        id: role.id,
        teamId: team.id,
      };
      const result = await promiseSagaDispatch(deleteRoleAC, input);
      enqueueSnackbar(t('dialog:deleterole.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setDeleteLoading(false);
    }
  }, [team, role, promiseSagaDispatch, deleteLoading, enqueueSnackbar, t]);

  const privileges = useMemo(() => RoleHelper.joinPrivileges(role, wt), [role, wt]);

  return (
    <React.Fragment>
      <RoleTableRow
      name={(
        <Chip
        variant='outlined'
        label={role.name}
        style={{ borderColor: role.color }}
        />
      )}
      privileges={(
        <Tooltip title={privileges} placement='top' arrow={true}>
          <div className={classes.privileges}>
            {privileges}
          </div>
        </Tooltip>
      )}
      actions={
        <FlexSpacer flexAlignItems='center' flexJustifyContent='center'>
          {accessControl.canUpdateRole && (
            <Tooltip title={t('common:edit')} placement='top' arrow={true}>
              <IconButton size='small' onClick={openRoleEditorDialog}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {accessControl.canCreateRole && (
            <Tooltip title={t('dashboard:team.roles.clonerole')} placement='top' arrow={true}>
              <IconButton size='small' onClick={openCloneRoleDialog}>
                <LibraryAddIcon />
              </IconButton>
            </Tooltip>
          )}
          {accessControl.canDeleteRole && (
            <Tooltip title={t('common:delete')} placement='top' arrow={true}>
              <IconButton size='small' onClick={openDeleteRoleDialog}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </FlexSpacer>
      }
      />
      <Dialog open={roleEditorDialogOpen} onClose={closeRoleEditorDialog}>
        <RoleEditorDialogContent
        onClose={closeRoleEditorDialog}
        team={team}
        role={role}
        />
      </Dialog>
      <Dialog open={cloneRoleDialogOpen} onClose={closeCloneRoleDialog}>
        <RoleEditorDialogContent
        onClose={closeCloneRoleDialog}
        team={team}
        role={role}
        isPreset={true}
        />
      </Dialog>
      <Dialog open={deleteRoleDialogOpen} fullWidth={true}>
        <AlertDialogContent
        titleText={t('dialog:deleterole.title')}
        description={t('dialog:deleterole.warning')}
        cancel={{
          text  : t('common:cancel'),
          action: closeDeleteRoleDialog,
        }}
        confirm={{
          text    : t('dialog:deleterole.confirm'),
          loading : deleteLoading,
          disabled: deleteLoading,
          action  : handleDeleteRoleOnClick,
        }}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default React.memo(RoleTableRowWrapper);
