import addAsyncCases from 'helpers/addAsyncCases';
import { createSlice } from 'helpers/createSlice';
import { Draft } from 'immer';
import actionCreatorFactory, { Action, Failure } from 'typescript-fsa';

interface CurrentRecordingState {
  state: 'recording';
  recorder: MediaRecorder;
  chunks: Blob[];
}

interface SavedRecordingState {
  state: 'saved';
  recording: Blob;
  audioSource: string;
}

interface FailedRecordingState {
  state: 'failed';
  error: Error;
}

export interface AudioRecordingState {
  recording?: CurrentRecordingState | SavedRecordingState | FailedRecordingState;
}

const name = 'audio-recorder';
const actionCreator = actionCreatorFactory(name);

export const startAudioRecordingAC = actionCreator.async<void, void, Error>('START');
export const stopAudioRecordingAC = actionCreator.async<void | boolean, void, Error>('STOP');

const initialState: AudioRecordingState = {};

const audioRecorderSlice = createSlice({
  name,
  initialState,
  reducers: {
    initCurrentRecording(state, action: Action<MediaRecorder>) {
      replaceRecording(state, {
        recorder: action.payload,
        chunks: [],
        state: 'recording',
      });
    },
    addRecordingData(state, action: Action<Blob>) {
      if (state.recording?.state !== 'recording') {
        return;
      }

      state.recording.chunks.push(action.payload);
    },
    saveRecording(state) {
      if (state.recording?.state !== 'recording') {
        return;
      }

      const { chunks } = state.recording;
      const blooob = new Blob(chunks, { type: chunks[0].type });
      replaceRecording(state, {
        state: 'saved',
        recording: blooob,
        audioSource: URL.createObjectURL(blooob),
      });
    },
    discardRecording: deleteRecording,
    resetAudioRecorder: deleteRecording,
  },
  extraReducers: (builder) => {
    addAsyncCases(builder, startAudioRecordingAC, {
      started: deleteRecording,
      failed: setError,
    });

    addAsyncCases(builder, stopAudioRecordingAC, {
      failed: setError,
    });
  },
});

function replaceRecording(state: Draft<AudioRecordingState>, recording?: AudioRecordingState['recording']) {
  switch (state.recording?.state) {
    case 'saved':
      URL.revokeObjectURL(state.recording.audioSource);
      break;

    case 'recording':
      for (const track of state.recording.recorder.stream.getTracks()) {
        track.stop();
      }

      break;
  }

  if (!recording) {
    delete state.recording;
  } else {
    state.recording = recording;
  }
}

function deleteRecording(state: Draft<AudioRecordingState>) {
  replaceRecording(state);
}

function setError(state: Draft<AudioRecordingState>, action: Action<Failure<any, Error>>) {
  replaceRecording(state, {
    state: 'failed',
    error: action.payload.error,
  });
}

export const audioRecorderReducer = audioRecorderSlice.reducer;
export const {
  addRecordingData,
  saveRecording,
  discardRecording,
  initCurrentRecording,
  resetAudioRecorder,
} = audioRecorderSlice.actions;
