import { Skeleton } from '@material-ui/lab';
import React from 'react';

import RoleTableRow from './RoleTableRow';

function RoleRowSkeleton() {
  return (
    <RoleTableRow
    name={<Skeleton variant='text' />}
    privileges={<Skeleton variant='text'/>}
    actions={<Skeleton variant='text' />}
    />
  );
}

export default React.memo(RoleRowSkeleton);
