import { bidirectionalActionCreatorsFactory, BidirectionalIDRecord } from '@insights-gaming/redux-utils';
import { deleteInvitationAC } from 'actions/invitation-actions';
import { Invitation2Fragment } from 'apollo/fragments/types/Invitation2Fragment';
import { GetInvitationsQuery_queryInvitations, GetInvitationsQueryVariables } from 'apollo/queries/types/GetInvitationsQuery';
import addAsyncCases from 'helpers/addAsyncCases';
import { addBidirectionalCases } from 'helpers/addBidirectionalCases';
import { createSlice } from 'helpers/createSlice';
import update from 'immutability-helper';
import actionCreatorFactory from 'typescript-fsa';

const name = 'dashboard-invitation';
const actionCreator = actionCreatorFactory(name);
const bidirectionalActionCreator = bidirectionalActionCreatorsFactory(actionCreator);

export const fetchTeamInvitationsAC = bidirectionalActionCreator<
  GetInvitationsQueryVariables,
  GetInvitationsQuery_queryInvitations,
  Error
>('FETCH_TEAM_INVITATIONS');

interface DashboardInvitationState {
  teamInvitationRecords: Partial<Dictionary<BidirectionalIDRecord>>;
  invitationDict: Partial<Dictionary<Invitation2Fragment>>;
}

const initialState: DashboardInvitationState = {
  teamInvitationRecords: {},
  invitationDict: {},
};

const dashboardInvitationSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    addBidirectionalCases(builder, fetchTeamInvitationsAC, {
      records: (tp, { teamId }) => tp.teamInvitationRecords[teamId],
      dict: (tp) => tp.invitationDict,
      values: (result) => result.invitations,
    });

    addAsyncCases(builder, deleteInvitationAC, {
      done: (state, action) => {
        const { params: { teamId }, result: { id: invitationId } } = action.payload;
        return update(state, {
          teamInvitationRecords: {
            [teamId]: {
              ids: ids => ids.filter(id => id !== invitationId),
            },
          },
          invitationDict: {$unset: [invitationId]},
        });
      },
    });
  },
});

export const dashboardInvitationReducer = dashboardInvitationSlice.reducer;
