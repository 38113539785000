import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { getVideoDict } from 'features/dashboard/video/dashboard-video-selector';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ID } from 'types/pigeon';

import { NotificationsHelper } from '../notification-settings/notifications-helper';
import { ENotificationState } from './constants';
import {
  fetchUnreadFolderNotificationsAC,
  fetchUnreadNotificationsAC,
  fetchUnreadTeamNotificationsAC,
  updateNotificationsAC,
} from './notifications-slice';

function* generateVideoDirectoryIds({ directory, division }: VideoFragment): Generator<ID> {
  if (directory) {
    yield directory.id;
  }

  if (division) {
    yield division.id;
  }
}

export default function useUpdateNotification() {
  const videoDict = useSelector(getVideoDict);

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const notificationVideoDirectoryIds = useCallback((notification: NotificationFragment): Array<[ID, ID]> => {
    if (NotificationsHelper.isMemberNotification(notification)) {
      return [];
    }

    const video = videoDict[notification.video.id];
    if (!video) {
      return [];
    }

    return Array.from(generateVideoDirectoryIds(video))
      .map((videoId) => [notification.team.id, videoId]);
  }, [videoDict]);

  return {
    handleUpdateNotifications: useCallback(async (
      notifications: NotificationFragment | NotificationFragment[] | undefined,
      newState: ENotificationState = ENotificationState.READ,
    ) => {
      if (!notifications) {
        return;
      }

      if (!Array.isArray(notifications)) {
        notifications = [notifications];
      }

      if (!notifications.length) {
        return;
      }

      try {
        const input = notifications
        .filter(({ state }) => state !== newState)
        .map((notification) => ({
          notificationId: notification.id,
          notificationState: newState,
        }));

        if (input.length) {
          await promiseSagaDispatch(updateNotificationsAC, input);
        }

        for (const teamId of uniq(notifications.map((notification) => notification.team.id))) {
          dispatch(fetchUnreadTeamNotificationsAC.started({teamId}));
        }

        // if (newState === ENotificationState.READ) {
        //   for (
        //     const [notificationId, videoId] of notifications
        //       .filter(NotificationsHelper.isCommentNotification)
        //       .map(({ id, video }): [ID, ID] => [id, video.id])
        //   ) {
        //     dispatch(updateUnreadVideoNotificationAC({ notificationId, videoId }));
        //   }
        // }

        for (
          const [teamId, folderId] of uniqBy(
            notifications.flatMap(notificationVideoDirectoryIds),
            ([teamId, folderId]) => teamId + ':' + folderId,
          )
        ) {
          dispatch(fetchUnreadFolderNotificationsAC.started({ teamId, folderId }));
        }
        dispatch(fetchUnreadNotificationsAC.started());
      } catch(error) {
        enqueueSnackbar('Could not update', {variant: 'error'});
      }
    }, [dispatch, notificationVideoDirectoryIds, promiseSagaDispatch, enqueueSnackbar]),
  };
}
