import gql from 'graphql-tag';

export const RevokeInvitation_Mutation = gql`
    mutation RevokeInvitationMutation(
        $input: RevokeInvitationInput!
    ) {
        revokeInvitation(
            input: $input
        ) {
            invitationId
        }
    }
`;
