import { AsyncButton } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import { INSIGHTS_CAPTURE_DOWNLOAD } from 'constants/index';
import { registerAsyncAC } from 'features/auth/auth-slice';
import { prepareEmail, testPasswords } from 'helpers';
import { createUTMParams } from 'helpers/createUTMParams';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TLogin } from 'locales/en/login';
import IconTextField from 'material/icon-text-field/IconTextField';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { SIGNIN_PATH } from 'routes';

import { LoginDialogContext } from './LoginDialogContext';
import SignupDialogContentLayout from './SignupDialogContentLayout';

export interface SignupFormDialogContentOwnProps {}

export type SignupFormDialogContentProps = SignupFormDialogContentOwnProps;

const signUpUTM = '&' + createUTMParams('insightsgg', 'signup', 'ic_download');

function errorMsg(error: Error): TLogin {
  if (error.message.indexOf('WEAK_PASSWORD') === 0) {
    return 'errors.weakpassword';
  }

  switch (error.message) {
    case 'FORM_INCOMPLETE'  : return 'errors.signupfailed';
    case 'EMAIL_EXISTS'     : return 'errors.signupfailed';
    case 'PASSWORD_MISMATCH': return 'errors.passwordmismatch';
    case 'PASSWORD_REQUIRED': return 'errors.passwordrequired';
    case 'WEAK_PASSWORD'    : return 'errors.weakpassword';
         default            : return 'errors.signupfailed';
  }
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    padding: 0,
    marginLeft: theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
  },
  readyToRecordText: {
    fontSize: createRemFromPx(16),
  },
  downloadAnchor: {
    marginLeft: theme.spacing(1),
    fontSize: createRemFromPx(16),
  },
}), {name: 'SignupFormDialogContent'});

function SignupFormDialogContent(props: SignupFormDialogContentProps) {
  const classes = useStyles(props);

  const { t } = useStrictTranslation('login');

  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const { loginState } = useContext(LoginDialogContext);

  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState<{ email?: string, password1?: string, password2?: string }>({});
  const [didOptInToMarketing, setDidOptInToMarketing] = useState(false);

  const handleFormOnSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    const { email, password1, password2 } = formFields;
    if (!email || !password1) {
      enqueueSnackbar(t('errors.formincomplete'), { variant: 'error' });
      return;
    }

    const msg = testPasswords(password1, password2);
    if (msg) {
      enqueueSnackbar(t(msg), { variant: 'error' });
      return;
    }

    setLoading(true);
    promiseSagaDispatch(registerAsyncAC, {
      email: prepareEmail(email),
      password: password1,
      marketing: didOptInToMarketing,
      referPath: loginState.referPath,
      referrer: loginState.referrer,
    })
      .catch((error: Error) => enqueueSnackbar(t(errorMsg(error)), { variant: 'error' }))
      .finally(() => setLoading(false));
  }, [
    didOptInToMarketing,
    enqueueSnackbar,
    formFields,
    loading,
    loginState.referPath,
    loginState.referrer,
    promiseSagaDispatch,
    t,
  ]);

  const handleFormInputOnChange = useCallback((e: React.SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormFields((prevState) => ({...prevState, [name]: value}));
  }, []);

  const handleCheckboxOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDidOptInToMarketing(e.currentTarget.checked);
  }, []);

  return (
    <SignupDialogContentLayout
    className={classes.root}
    >
      <form onSubmit={handleFormOnSubmit}>
        <IconTextField
        onChange={handleFormInputOnChange}
        name='email'
        type='email'
        label={t('email')}
        placeholder={t('email')}
        icon={<EmailIcon />}
        autoFocus={true}
        fullWidth={true}
        required={true}
        />
        <IconTextField
        onChange={handleFormInputOnChange}
        name='password1'
        type='password'
        label={t('password')}
        placeholder={t('password')}
        icon={<LockIcon />}
        fullWidth={true}
        required={true}
        />
        <IconTextField
        onChange={handleFormInputOnChange}
        name='password2'
        type='password'
        label={t('confirmpw')}
        placeholder={t('confirmpw')}
        icon={<LockIcon />}
        fullWidth={true}
        required={true}
        />
        <Box mt={1}>
          <FormControlLabel
          control={<Checkbox checked={didOptInToMarketing} onChange={handleCheckboxOnChange} />}
          label={t('newsletter')}
          />
        </Box>
        <Box my={1}>
          <AsyncButton
          fullWidth={true}
          type='submit'
          variant='contained'
          color='primary'
          loading={loading}
          disabled={loading}
          >
            {t('signup')}
          </AsyncButton>
        </Box>
        <Box className={classes.flex} my={2}>
          <span>{t('haveaccount')}</span>
          <Link
          to={{ pathname: SIGNIN_PATH, state: loginState }}
          className={classes.link}
          >
            <Button className={classes.button}>
              {t('login')}
            </Button>
          </Link>
        </Box>
        <Divider variant='fullWidth' />
        <Box my={1} className={classes.flex}>
          <Typography className={classes.readyToRecordText}>
            {t('readytorecord')}
          </Typography>
          <a
          className={classes.downloadAnchor}
          href={INSIGHTS_CAPTURE_DOWNLOAD.url+signUpUTM}
          download={INSIGHTS_CAPTURE_DOWNLOAD.name}
          >
            {t('download')}
          </a>
        </Box>
      </form>
    </SignupDialogContentLayout>
  );
}

export default React.memo(SignupFormDialogContent);
