import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import StarIcon from '@material-ui/icons/Star';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { CROWN_COLOR } from 'helpers/color';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import UserAvatar from 'material/user-avatar/UserAvatar';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dashboardRoute } from 'routes';
import { ID } from 'types/pigeon';

import { useAccessControl } from '../access-control/useAccessControl';
import { fetchTeamDirectoryUsersAC, leaveDirectoryAC,removePrivateDirectoryUsersAC } from '../directory/dashboard-directory-slice';

interface MemberItemOwnProps {
  className?: string;
  member: MemberFragment;
  team: TeamFragment;
  ownerId?: ID;
  directoryId: ID;
}

type MemberItemProps = MemberItemOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  star: {
    color: CROWN_COLOR,
  },
}), {name: 'MemberItem'});

function MemberItem(props: MemberItemProps) {
  const classes = useStyles(props);
  const { className, member, team, ownerId, directoryId } = props;
  const { canRemovePrivateFolderUser } = useAccessControl();

  const isOwner = ownerId === member.user.id;

  const { t } = useStrictTranslation(['common', 'dialog', 'dashboard-directory']);
  const { enqueueSnackbar } = useSnackbar();
  const onNavigate = useNavigate();

  const [removeLoading, setRemoveLoading] = useState(false);
  const [leaveLoading, setLeaveLoading] = useState(false);
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const [
    isRemoveMemberDialogOpen,
    openRemoveMemberDialog,
    closeRemoveMemberDialog,
  ] = useDialogState();

  const [
    isLeaveDirectoryDialogOpen,
    openLeaveDirectoryDialog,
    closeLeaveDirectoryDialog,
  ] = useDialogState();

  const dispatch = useDispatch();

  const handleLeaveDirectoryOnClick = useCallback(async () => {
    if (leaveLoading) { return; }
    setLeaveLoading(true);
    try {
      const result = await promiseSagaDispatch(leaveDirectoryAC, {id: directoryId, teamId: team.id});
      closeLeaveDirectoryDialog();
      onNavigate(dashboardRoute());
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLeaveLoading(false);
    }
  }, [
    leaveLoading,
    promiseSagaDispatch,
    directoryId,
    team.id,
    closeLeaveDirectoryDialog,
    onNavigate,
    enqueueSnackbar,
  ]);

  const handleRemoveMemberOnClick = useCallback(async() => {
    if (removeLoading) { return; }
    setRemoveLoading(true);
    try {
      const input = {teamId: team.id, userIds: [member.user.id], directoryId};
      await promiseSagaDispatch(removePrivateDirectoryUsersAC, input);
      dispatch(fetchTeamDirectoryUsersAC.forward.started({directoryId}));
      enqueueSnackbar(t('dialog:removemember.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('dialog:removemember.failure'), {variant: 'error'});
    } finally {
      setRemoveLoading(true);
    }
  }, [enqueueSnackbar, removeLoading, t, promiseSagaDispatch, member, team, dispatch, directoryId]);

  const renderLeaveOrRemoveItem = useCallback(() => {
    if (isOwner) { return null; }
    if (member.user.__typename === 'Self') {
      return (
        <IconButton onClick={openLeaveDirectoryDialog}>
          <ExitToAppIcon fontSize='small' />
        </IconButton>
      );
    }
    if (canRemovePrivateFolderUser) {
      return (
        <IconButton onClick={openRemoveMemberDialog}>
          <DeleteIcon fontSize='small' />
        </IconButton>
      );
    }
    return null;
  }, [isOwner, member, canRemovePrivateFolderUser, openLeaveDirectoryDialog, openRemoveMemberDialog]);

  return (
    <FlexSpacer className={classNames(classes.root, className)} flexJustifyContent='space-between'>
      <FlexSpacer flexAlignItems='center'>
        <UserAvatar user={member.user} size='sm' />
        <div>{member.user.alias}</div>
        {isOwner && <StarIcon className={classes.star} />}
      </FlexSpacer>
      {renderLeaveOrRemoveItem()}
      <Dialog
      open={isRemoveMemberDialogOpen}
      onClose={closeRemoveMemberDialog}
      >
        <AlertDialogContent
        titleText={t(('dialog:removemember.title'), {name: member.user.alias})}
        description={t('dialog:removemember.privatechanneldesc')}
        confirm={{
          text: t('dialog:removemember.confirm'),
          action: handleRemoveMemberOnClick,
          loading: removeLoading,
          disabled: removeLoading,
        }}
        cancel={{
          text: t('common:cancel'),
          action: closeRemoveMemberDialog,
        }}
        />
      </Dialog>
      <Dialog
      open={isLeaveDirectoryDialogOpen}
      onClose={closeLeaveDirectoryDialog}
      >
        <AlertDialogContent
        titleText={t('dashboard-directory:leavechannel.title')}
        description={t('dashboard-directory:leavechannel.desc')}
        confirm={{
          text: t('common:confirm'),
          action: handleLeaveDirectoryOnClick,
          loading: leaveLoading,
          disabled: leaveLoading,
        }}
        cancel={{
          text: t('common:cancel'),
          action: closeLeaveDirectoryDialog,
        }}
        />
      </Dialog>
    </FlexSpacer>
  );
}

export default React.memo(MemberItem);
