import {
  bidirectionalActionCreatorsFactory,
  BidirectionalIDRecord,
} from '@insights-gaming/redux-utils';
import { isAnyOf } from '@reduxjs/toolkit';
import { notificationAddedAC, notificationDeletedAC, notificationUpdatedAC } from 'actions/event-actions';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import { UpdateNotificationsMutation_updateNotifications_notifications } from 'apollo/mutations/types/UpdateNotificationsMutation';
import { GetNotificationCountsQueryVariables } from 'apollo/queries/types/GetNotificationCountsQuery';
import {
  GetNotificationsQuery_queryNotifications,
  GetNotificationsQueryVariables,
} from 'apollo/queries/types/GetNotificationsQuery';
import { GetUnreadFolderNotificationsQueryVariables } from 'apollo/queries/types/GetUnreadFolderNotificationsQuery';
import { GetUnreadTeamNotificationsQueryVariables } from 'apollo/queries/types/GetUnreadTeamNotificationsQuery';
import { fetchTeamsAC } from 'features/dashboard/team/dashboard-team-slice';
import addAsyncCases from 'helpers/addAsyncCases';
import { addBidirectionalCases } from 'helpers/addBidirectionalCases';
import { createSlice } from 'helpers/createSlice';
import update from 'immutability-helper';
import { UpdateNotificationInput } from 'types/graphql';
import { ID } from 'types/pigeon';
import actionCreatorFactory from 'typescript-fsa';

import { ENotificationState } from './constants';

const name = 'notifications';
const actionCreator = actionCreatorFactory(name);

const bidirectionalActionCreators = bidirectionalActionCreatorsFactory(actionCreator);

export const fetchNotificationsAC = bidirectionalActionCreators<
  GetNotificationsQueryVariables,
  GetNotificationsQuery_queryNotifications,
  Error
>('FETCH_NOTIFICATIONS');

export const fetchNotificationCountsAC = actionCreator.async<
  GetNotificationCountsQueryVariables,
  number[],
  Error
>('FETCH_NOTIFICATIONS_COUNTS');

export const fetchUnreadNotificationsAC = actionCreator.async<
  void,
  number,
  Error
>('FETCH_UNREAD_NOTIFICATIONS');

export const fetchUnreadTeamNotificationsAC = actionCreator.async<
  GetUnreadTeamNotificationsQueryVariables,
  number,
  Error
>('FETCH_UNREAD_TEAM_NOTIFICATIONS');

export const fetchUnreadFolderNotificationsAC = actionCreator.async<
  GetUnreadFolderNotificationsQueryVariables,
  number,
  Error
>('FETCH_UNREAD_DIRECTORY_NOTIFICATIONS');

export const updateNotificationsAC = actionCreator.async<
  UpdateNotificationInput[],
  UpdateNotificationsMutation_updateNotifications_notifications[],
  Error
>('UPDATE_NOTIFICATIONS');

export const resetUnreadNotificationsAC = actionCreator<void>('RESET_UNREAD_NOTIFICATIONS');
export const updateUnreadVideoNotificationAC = actionCreator<{
  notificationId: ID,
  videoId: ID,
}>('UPDATE_UNREAD_VIDEO_NOTIFICATION');

export interface NotificationsState {
  notificationsRecords?: BidirectionalIDRecord;
  notificationsDict: Partial<Dictionary<NotificationFragment>>;

  unreadTeamNotifications: Partial<Dictionary<number>>;
  unreadFolderNotifications: Partial<Dictionary<number>>;
  unreadNotifications: number;
}

const initialState: NotificationsState = {
  notificationsDict: {},
  unreadTeamNotifications: {},
  unreadFolderNotifications: {},
  unreadNotifications: 0,
};

const notificationsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    addBidirectionalCases(builder, fetchNotificationsAC, {
      records: (tp) => tp.notificationsRecords,
      dict: (tp) => tp.notificationsDict,
      values: (result) => result.notifications,
    });

    addAsyncCases(builder, fetchUnreadNotificationsAC, {
      done: (state, action) => {
        const { result } = action.payload;
        return update(state, {
          unreadNotifications: {$set: result},
        });
      },
    });

    addAsyncCases(builder, fetchUnreadTeamNotificationsAC, {
      done: (state, action) => {
        const { params: { teamId }, result } = action.payload;
        return update(state, {
          unreadTeamNotifications: {
            [teamId]: {$set: result},
          },
        });
      },
    });

    addAsyncCases(builder, fetchUnreadFolderNotificationsAC, {
      done: (state, action) => {
        const { params: { folderId }, result } = action.payload;
        return update(state, {
          unreadFolderNotifications: {
            [folderId]: {$set: result},
          },
        });
      },
    });

    addAsyncCases(builder, updateNotificationsAC, {
      done: (state, action) => {
        const { result, params } = action.payload;

        const { notificationState } = params[0];
        const notificationIds = params.map(p => p.notificationId);
        if (notificationState === ENotificationState.DELETED) {
          state.notificationsRecords = update(state.notificationsRecords, {
            ids: ids => ids.filter(id => !notificationIds.includes(id)),
          });
          state.notificationsDict = update(state.notificationsDict, {$unset: notificationIds});
        } else {
          params.forEach(param => {
            state.notificationsDict = update(state.notificationsDict, {
              [param.notificationId]: {
                state: {$set: param.notificationState},
              },
            });
          });
        }
      },
    });

    builder.addCase(notificationAddedAC, (state, action) => {
      const { payload: notification } = action;
      state.notificationsRecords = update(state.notificationsRecords, {
        ids: ids => Array.from(new Set([notification.id, ...ids])),
      });
      state.notificationsDict = update(state.notificationsDict, {
        [notification.id]: {$set: notification},
      });
    });

    builder.addCase(notificationDeletedAC, (state, action) => {
      const { payload: notificationId } = action;
      return update(state, {
        notificationsRecords: {
          ids: ids => ids.filter(id => id !== notificationId),
        },
        notificationsDict: {$unset: [notificationId]},
      });
    });

    builder.addCase(notificationUpdatedAC, (state, action) => {
      const { payload: notification } = action;
      state.notificationsDict = update(state.notificationsDict, {
        [notification.id]: {$set: notification},
      });
    });

    builder.addCase(resetUnreadNotificationsAC, (state, action) => {
      state.unreadNotifications = update(state.unreadNotifications, {$set: 0});
      state.unreadTeamNotifications = update(state.unreadTeamNotifications, {$set: {}});
      state.unreadFolderNotifications = update(state.unreadFolderNotifications, {$set: {}});
    });

    builder.addMatcher(
      isAnyOf(fetchTeamsAC.forward.done, fetchTeamsAC.backward.done),
      (state, action) => {
        for (const teamEdge of action.payload.result.teamEdges) {
          state.unreadTeamNotifications[teamEdge.team.id] = teamEdge.unreadNotifications;
        }
      },
    );
  },
});

export const notificationsReducer = notificationsSlice.reducer;
