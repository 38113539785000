import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React from 'react';

interface NotificationsTableRowOwnProps {
  className?: string;
  message: React.ReactNode;
  team   : React.ReactNode;
  action : React.ReactNode;
  onClick?: (() => void) | undefined
}

type NotificationsTableRowProps = NotificationsTableRowOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'NotificationTableRow'});

function NotificationsTableRow(props: NotificationsTableRowProps) {
  const classes = useStyles(props);
  const { className, message, team, action, onClick } = props;
  return (
    <TableRow className={classNames(classes.root, className)} onClick={onClick}>
      <FixedTableCell>{message}</FixedTableCell>
      <FixedTableCell width={100}>{team}</FixedTableCell>
      <FixedTableCell width={100} align='right'>{action}</FixedTableCell>
    </TableRow>
  );
}

export default React.memo(NotificationsTableRow);
