import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { intersect, union } from 'helpers';
import { useMemo } from 'react';
import { ID } from 'types/pigeon';

export function useTagIdsOnVideos(videos: VideoFragment[]) {
  return useMemo(() => {
    const { tags = [] } = videos[0] || {};

    let tagIdsOnAllVideos = new Set(tags);
    for (let i = 1; i < videos.length; i++) {
      tagIdsOnAllVideos = intersect(videos[i].tags, tagIdsOnAllVideos);
    }
    const tagIdsOnAtLeastOneVideo: Set<ID> = videos.reduce(
      (p: Set<ID>, c: VideoFragment) => union(c.tags, p),
      new Set(),
    );
    return { tagIdsOnAllVideos, tagIdsOnAtLeastOneVideo } as const;
  }, [videos]);
}
