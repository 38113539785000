import { SavedCardFragment } from 'apollo/fragments/types/SavedCardFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { TeamSubscriptionFragment } from 'apollo/fragments/types/TeamSubscriptionFragment';
import { clearTeamBillingStateAC } from 'features/dashboard/billing/dashboard-billing-slice';
import { IAvailablePlanData } from 'features/dashboard/billing/useAvailableMetrics';
import useCheckoutQuantities from 'features/dashboard/billing/useCheckoutQuantities';
import useFetchTeamCards from 'features/dashboard/billing/useFetchTeamCards';
import useTeamAvailableBundles from 'features/dashboard/billing/useTeamAvailableBundles';
import useTeamBalance from 'features/dashboard/billing/useTeamBalance';
import useTeamProration from 'features/dashboard/billing/useTeamProration';
import useTeamSubscription from 'features/dashboard/billing/useTeamSubscription';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BillingInterval, ProductInput, ProductKind } from 'types/graphql';
import { InputQuantities } from 'types/pigeon/billing';

import AvailablePlans from './AvailablePlansDialog/AvailablePlans';
import CancelSubscription from './CancelSubsDialog/CancelSubscription';
import PaymentCards from './PaymentMethod/PaymentCards';
import PlanDetails from './PlanDetailsDialog/PlanDetails';
import useFreeTrialElegibility from './useFreeTrialElegibility';

export interface ContentsProps {
  stepForward: VoidFunction;
  stepBackward: VoidFunction;
  step: number;
  hasPlan: boolean,
  checkoutQuantities: InputQuantities;
  handleIntervalSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleProductSlideOnChange: (i: number) => void;
  handleIntervalSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleProductSelectOnChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleIntervalToggleButtonChange: (event: React.MouseEvent<HTMLElement>, newValue: BillingInterval) => void;
  handleCustomPlanSelectOnChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleBundleCustomTabChange: (event: React.ChangeEvent, newValue: 'bundle' | 'custom') => void;
  setCustomPlanQuantities: React.Dispatch<React.SetStateAction<object>>;
  customPlanQuantities?: Partial<Record<ProductKind, number>>;
  bundleCustomTab: 'bundle' | 'custom';
  selectedProductIndex?: number;
  team: TeamFragment;
  teamSubscription?: TeamSubscriptionFragment;
  currentProduct?: InputQuantities;
  currentTeamProducts?: ProductInput[];
  cancelPlan: boolean;
  setCancelPlan: (cancelPlan: boolean) => void;
  currentPlan?: IAvailablePlanData;
  onSuccess: VoidFunction;
  onClose: VoidFunction;
  teamProration?: number;
  teamBalance?: number;
  source?: string;
  freeTrial?: boolean;
  teamCards?: SavedCardFragment[];
  teamId: string;
}

export type ContentComponentType = React.ComponentType<ContentsProps>;

const Contents: readonly ContentComponentType[] = [
  AvailablePlans,
  PlanDetails,
  PaymentCards,
];

const Titles = [
  'dialog:availableplans.title',
  'dialog:plandetails.title',
  'dashboard:billing.changecard',
] as const;

export type BillingContentsLayoutProps<T extends object | void = void> = React.PropsWithChildren<
  (T extends void ? {} : T) & {
    title: React.ReactNode;
    loading: boolean;
  }
>;

type BillingContentsOwnProps<T extends object | void> = (T extends void ? {
  layoutProps?: any,
} : {
  layoutProps: T;
}) & {
  Layout: React.ComponentType<BillingContentsLayoutProps<T>>;
  overrideTitle?: (step: number) => React.ReactNode;
  overrideContent?: (step: number) => ContentComponentType | undefined;

  step: number;
  onStepChange: (step: number) => void;

  interval?: BillingInterval | undefined;
  productId?: string | undefined;
  team: TeamFragment;
  source?: string;
  currentPlan?: IAvailablePlanData;

  onSuccess: VoidFunction;
  onClose: VoidFunction;
};

type BillingContentsProps<T extends object | void> = BillingContentsOwnProps<T>;

export default function BillingContents<T extends object | void = void>({
  Layout,
  layoutProps,
  overrideTitle,
  overrideContent,

  interval,
  productId,
  team,
  source,

  step,
  onStepChange,
  currentPlan,

  onSuccess,
  onClose,
}: BillingContentsProps<T>) {
  const { t } = useStrictTranslation(['dialog', 'dashboard']);

  const bundles = useTeamAvailableBundles(team.id);

  const {
    handleIntervalSwitchChange,
    handleProductSlideOnChange,
    handleIntervalSelectChange,
    handleProductSelectOnChange,
    handleIntervalToggleButtonChange,
    handleCustomPlanSelectOnChange,
    handleBundleCustomTabChange,
    setCustomPlanQuantities,
    checkoutQuantities,
    prevQuantities,
    selectedProductIndex,
    customPlanQuantities,
    bundleCustomTab,
  } = useCheckoutQuantities(interval, productId, bundles);

  const [teamSubscription] = useTeamSubscription(team.id);

  const hasPlan = useMemo(() => !!teamSubscription?.active, [teamSubscription]);

  const currentTeamProducts = useMemo(() => teamSubscription?.products?.map(({ priceId, kind, quantity }) => ({
    id: priceId,
    name: kind,
    quantity,
  })), [teamSubscription]);

  const [teamBalance, teamBalanceFetched] = useTeamBalance(team.id);
  const [teamProration, teamProrationFetched] = useTeamProration(team.id);
  const [teamCards, teamCardsFetched] = useFetchTeamCards(team.id);

  const freeTrial = useFreeTrialElegibility(team);

  const stepForward = useCallback(() => onStepChange(step+1), [onStepChange, step]);
  const stepBackward = useCallback(() => onStepChange(step-1), [onStepChange, step]);

  const [cancelPlan, setCancelPlan] = useState(false);

  const RealContents = useMemo(() => {
    if (!teamSubscription) {
      return Contents;
    }

    return [
      ...Contents.slice(0, 1),
      CancelSubscription, // not really cancelling, just changing the plan
      ...Contents.slice(1),
    ];
  }, [teamSubscription]);

  const Content = useMemo(
    () => overrideContent?.(step) || RealContents[step],
    [overrideContent, RealContents, step],
  );

  const loading = useMemo(() => {
    if (!teamBalanceFetched || !teamCardsFetched || !bundles) {
      return true;
    }

    if (!teamSubscription) {
      return false;
    }

    return teamProrationFetched;
  }, [bundles, teamBalanceFetched, teamCardsFetched, teamProrationFetched, teamSubscription]);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    onClose?.();
    dispatch(clearTeamBillingStateAC({ teamId: team.id }));
  }, [onClose, dispatch, team.id]);

  return React.createElement(
    Layout,
    {
      ...(layoutProps || {}),
      title: overrideTitle?.(step) || t(Titles[step]),
      onForward: stepForward,
      onBackward: stepBackward,
      loading,
    },
    Content && React.createElement(Content, {
      step,
      handleIntervalSwitchChange,
      handleProductSlideOnChange,
      handleIntervalSelectChange,
      handleProductSelectOnChange,
      handleIntervalToggleButtonChange,
      handleCustomPlanSelectOnChange,
      handleBundleCustomTabChange,
      setCustomPlanQuantities,
      customPlanQuantities,
      bundleCustomTab,
      selectedProductIndex,
      checkoutQuantities,
      currentProduct: prevQuantities,
      teamSubscription,
      teamBalance,
      teamProration,
      teamCards,
      freeTrial,
      hasPlan,
      stepForward,
      stepBackward,
      setCancelPlan,
      team,
      teamId: team.id,
      cancelPlan,
      currentPlan,
      onSuccess,
      onClose: handleClose,
      currentTeamProducts,
      source,
    }),
  );
}
