import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { AccessControlContext } from 'features/dashboard/access-control/AccessControlContext';
import { leaveTeamAC, removeMemberAC } from 'features/dashboard/team/dashboard-team-slice';
import { promisifyDispatch } from 'hooks/usePromiseSagaDispatch';
import update, { Spec } from 'immutability-helper';
import AssignRolesDialog from 'material/dialogs/assign-roles-dialog/AssignRolesDialog';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { LeaveTeamMutation_leaveTeam } from '../apollo/mutations/types/LeaveTeamMutation';
import { RemoveMemberMutation_removeMember } from '../apollo/mutations/types/RemoveMemberMutation';
import { GetMembersQuery_queryMembers_edges } from '../apollo/queries/types/GetMembersQuery';
import { CommonF, CommonNS } from '../locales/en/common';
import { DialogF, DialogNS } from '../locales/en/dialog';
import AlertDialogContent from '../material/dialogs/alert-dialog-content/AlertDialogContent';
import { dashboardRoute } from '../routes';
import { OnNavigate, onNavigateFactory } from '../types/dispatch';
import { LeaveTeamInput, RemoveMemberInput } from '../types/graphql';

export interface IWithMemberMenuRequiredProps {
  team: TeamFragment;
  member: GetMembersQuery_queryMembers_edges;
  roles: RoleInterfaceFragment[];
}

export interface IWithMemberMenuDispatch {
  onLeaveTeam: (input: LeaveTeamInput) => Promise<LeaveTeamMutation_leaveTeam>;
  onRemoveMember: (input: RemoveMemberInput) => Promise<RemoveMemberMutation_removeMember>;
  onNavigate: OnNavigate;
}

interface IWithMemberMenu {
  openMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  closeMenu: VoidFunction;
}

export type WithMemberMenu = IWithMemberMenu;

interface IState {
  anchorEl?: HTMLElement;

  assignOpen?   : boolean;
  leaveOpen?    : boolean;
  leaveLoading? : boolean;
  leaveError?   : Error;
  removeOpen?   : boolean;
  removeLoading?: boolean;
  removeError?  : Error;
}

export function withMemberMenu<
  P extends IWithMemberMenuRequiredProps &
  IWithMemberMenuDispatch &
  WithTranslation &
  WithSnackbarProps
>(Component: React.ComponentType<P & WithMemberMenu>) {
  return class extends React.Component<Omit<P, keyof WithMemberMenu>, IState>  {
    public static displayName = `MemberMenu(${Component.displayName || Component.name})`;
    public static contextType = AccessControlContext;
    public context: React.ContextType<typeof AccessControlContext>;

    private openAssignRolesDialog   = this.toggleDialog('assignOpen', true);
    private closeAssignRolesDialog  = this.toggleDialog('assignOpen', false);
    private openLeaveTeamDialog     = this.toggleDialog('leaveOpen', true);
    private closeLeaveTeamDialog    = this.toggleDialog('leaveOpen', false);
    private openRemoveMemberDialog  = this.toggleDialog('removeOpen', true);
    private closeRemoveMemberDialog = this.toggleDialog('removeOpen', false);

    constructor(props: P) {
      super(props);
      this.state = {};
    }

    public render() {
      const { t } = this.props;
      const { accessControl } = this.context;
      return (
        <Box>
          <Component
          {...this.props as P}
          openMenu={this.handleOpen}
          closeMenu={this.handleClose}
          />
          <Menu
          open={!!this.state.anchorEl}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          >
            {accessControl.canAssignRole && (
              <MenuItem onClick={this.openAssignRolesDialog}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary={t(CommonF('assignroles'))} />
              </MenuItem>
            )}
            {this.renderLeaveOrRemoveItem()}
          </Menu>
          {this.renderAssignRolesDialog()}
          {this.renderLeaveDialog()}
          {this.renderRemoveDialog()}
        </Box>
      );
    }

    private renderLeaveOrRemoveItem = () => {
      const { member, team } = this.props;
      const { accessControl } = this.context;
      if (member.user.__typename === 'Self') {
        if (team.owner.id === member.id) { return null; }
        return this.renderLeaveOption();
      }
      if (accessControl.canRemoveMember) {
        return this.renderRemoveMemberOption();
      }
      return null;
    }

    private renderLeaveOption = () => {
      const { t } = this.props;
      return (
        <MenuItem onClick={this.openLeaveTeamDialog}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t(DialogF('leaveteam.confirm'))} />
        </MenuItem>
      );
    }

    private renderRemoveMemberOption = () => {
      const { t } = this.props;
      return (
        <MenuItem onClick={this.openRemoveMemberDialog}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary={t(DialogF('removemember.confirm'))} />
        </MenuItem>
      );
    }

    private renderAssignRolesDialog = () => {
      const { team, member, roles } = this.props;
      const { assignOpen } = this.state;
      return (
        <AssignRolesDialog
        open={!!assignOpen}
        onClose={this.closeAssignRolesDialog}
        team={team}
        member={member}
        roles={roles}
        />
      );
    }

    private renderLeaveDialog = () => {
      const { team, t } = this.props;
      const { leaveOpen, leaveLoading } = this.state;
      return (
        <Dialog
        open={!!leaveOpen}
        onClose={this.closeLeaveTeamDialog}
        >
          <AlertDialogContent
          titleText={t(DialogF('leaveteam.title'), {team: team.name})}
          description={t(DialogF('leaveteam.description'))}
          confirm={{
            text: t(DialogF('leaveteam.confirm')),
            action: this.handleLeaveTeamOnClick,
            loading: leaveLoading,
            disabled: leaveLoading,
          }}
          cancel={{
            text: t(CommonF('cancel')),
            action: this.closeLeaveTeamDialog,
          }}
          />
        </Dialog>
      );
    }

    private renderRemoveDialog = () => {
      const { member, t } = this.props;
      const { removeOpen, removeLoading } = this.state;
      return (
        <Dialog
        open={!!removeOpen}
        onClose={this.closeRemoveMemberDialog}
        >
          <AlertDialogContent
          titleText={t(DialogF('removemember.title'), {name: member.user.alias})}
          description={t(DialogF('removemember.description'))}
          confirm={{
            text: t(DialogF('removemember.confirm')),
            action: this.handleRemoveMemberOnClick,
            loading: removeLoading,
            disabled: removeLoading,
          }}
          cancel={{
            text: t(CommonF('cancel')),
            action: this.closeRemoveMemberDialog,
          }}
          />
        </Dialog>
      );
    }

    private toggleDialog(dialog: keyof IState, isOpen?: boolean): VoidFunction {
      return () => {
        const spec: Spec<IState, never> = {$toggle: []};
        switch (isOpen) {
          case true   :
          case false  : spec[dialog] = {$set: isOpen};
               default: spec.$toggle = [dialog];
        }
        this.setState(state => update(state, spec), () => {
          if (this.state[dialog]) {
            this.handleClose();
          }
        });
      };
    }

    private handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.setState({anchorEl: event.currentTarget});
    }

    private handleClose = () => {
      this.setState({anchorEl: undefined});
    }

    private handleLeaveTeamOnClick = async () => {
      const { leaveLoading } = this.state;
      if (leaveLoading) { return; }
      this.setState({leaveLoading: true});

      const { team, onLeaveTeam, enqueueSnackbar, t } = this.props;
      try {
        await onLeaveTeam({teamId: team.id});
        this.setState({leaveOpen: false});
        this.props.onNavigate(dashboardRoute());
        enqueueSnackbar(t(DialogF('leaveteam.success')), {variant: 'success'});
      } catch (error) {
        this.setState({leaveError: error});
        enqueueSnackbar(t(DialogF('leaveteam.failure')), {variant: 'error'});
      } finally {
        this.setState({leaveLoading: false});
      }
    }

    private handleRemoveMemberOnClick = async () => {
      const { removeLoading } = this.state;
      const { enqueueSnackbar, t } = this.props;
      if (removeLoading) { return; }
      this.setState({removeLoading: true});

      const { team, member, onRemoveMember } = this.props;
      try {
        await onRemoveMember({teamId: team.id, memberId: member.id});
        this.setState({removeOpen: false});
        enqueueSnackbar(t(DialogF('removemember.success')), {variant: 'success'});
      } catch (error) {
        this.setState({removeError: error});
        enqueueSnackbar(t(DialogF('removemember.failure')), {variant: 'error'});
      } finally {
        this.setState({removeLoading: false});
      }
    }
  };
}

export function mapDispatchToMemberMenuProps(dispatch: Dispatch): IWithMemberMenuDispatch {
  const promiseSagaDispatch = promisifyDispatch(dispatch);
  return {
    onLeaveTeam: (input: LeaveTeamInput) => promiseSagaDispatch(leaveTeamAC, input),
    onRemoveMember: (input: RemoveMemberInput) => promiseSagaDispatch(removeMemberAC, input),
    onNavigate: onNavigateFactory(dispatch),
  };
}

class MemberMenuB extends React.Component<
  IWithMemberMenuRequiredProps &
    IWithMemberMenuDispatch &
    WithMemberMenu &
    WithTranslation &
    WithSnackbarProps
> {
  public render() {
    return (
      <IconButton onClick={this.props.openMenu} size='small'>
        <MoreVertIcon />
      </IconButton>
    );
  }
}

const MemberMenuButton =
connect<void, IWithMemberMenuDispatch, IWithMemberMenuRequiredProps>(null, mapDispatchToMemberMenuProps)(
  withSnackbar(
    withTranslation([CommonNS, DialogNS])(
      withMemberMenu(MemberMenuB),
    ),
  ),
);

export { MemberMenuButton };
