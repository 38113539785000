import { isAnyOf } from '@reduxjs/toolkit';
import addAsyncCases from 'helpers/addAsyncCases';
import { createSlice } from 'helpers/createSlice';
import update from 'immutability-helper';
import { TeamEdge } from 'types/pigeon';
import actionCreatorFactory from 'typescript-fsa';

import { fetchTeamEdgeByIdAC,fetchTeamsAC } from '../team/dashboard-team-slice';

const name = 'access-control';
const actionCreator = actionCreatorFactory(name);

interface ActionControlState {
  legacyTeamEdgeDict: Partial<Dictionary<TeamEdge>>;
}

const initialState: ActionControlState = {
  legacyTeamEdgeDict: {},
};

const accessControlSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    addAsyncCases(builder, fetchTeamEdgeByIdAC, {
      started: (state, { payload: { id } }) => {
        state.legacyTeamEdgeDict[id] = undefined;
      },
      done: (state, { payload: { result } }) => {
        state.legacyTeamEdgeDict[result.team.id] = new TeamEdge(result);
      },
    });

    builder.addMatcher(
      isAnyOf(fetchTeamsAC.forward.done, fetchTeamsAC.backward.done),
      (state, { payload: { result: { teamEdges } } }) => {
        state.legacyTeamEdgeDict = update(state.legacyTeamEdgeDict, {
          $merge: Object.fromEntries(teamEdges.map((teamEdge) => [teamEdge.team.id, new TeamEdge(teamEdge)])),
        });
      },
    );
  },
});

export const accessControlReducer = accessControlSlice.reducer;
