import { AsyncButton } from '@insights-gaming/material-components/AsyncButton';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import MultiTypeVideoSelector from 'components/multi-type-video-selector/MultiTypeVideoSelector';
import SelectedVideoChip from 'components/selected-video-chip/SelectedVideoChip';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useRealizeSelectedVideo } from 'hooks/useRealizeSelectedVideo';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import IconTextField from 'material/icon-text-field/IconTextField';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { ID } from 'types/pigeon';

import { liveSessionConnectAndRegisterAC, startSessionAsyncAC } from './live-session-slice';

interface StartLiveSessionDialogContentOwnProps {
  teamId: ID;
  directoryId: ID;
  video?: VideoFragment | InsightsEmbedResponse;
  onClose: () => void;
  analyticsSource?: string;
}

type StartLiveSessionDialogContentProps = StartLiveSessionDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  radioGroup: {
    flexDirection: 'row',
  },
  privacyDescriptionText: {
    color: theme.palette.text.deemphasized,
  },
  videoChip: {
    width: createRemFromPx(290),
    alignSelf: 'center',
  },
}));

function StartLiveSessionDialogContent(props: StartLiveSessionDialogContentProps) {
  const { directoryId, onClose, teamId, video: preselectedVideo, analyticsSource } = props;

  const classes = useStyles(props);
  const { t } = useStrictTranslation(['common', 'dialog']);
  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const realizeSelectedVideo = useRealizeSelectedVideo(teamId, directoryId);

  const [loading, setLoading] = useState(false);
  const [privacy, setPrivacy] = useState('teamonly');
  const [sessionSecret, setSessionSecret] = useState('');
  const [selectedVideo, setSelectedVideo] = useState<VideoFragment | InsightsEmbedResponse>();

  const video = useMemo(() => preselectedVideo || selectedVideo, [preselectedVideo, selectedVideo]);

  const handlePrivacyChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacy(e.target.value);
  }, []);

  const handleSessionSecretOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSessionSecret(e.target.value);
  }, []);

  const isPublic = useMemo(() => privacy === 'public', [privacy]);

  const handleSubmit = useCallback(async () => {
    if (loading || !video) {
      return;
    }

    setLoading(true);

    try {
      const videoId = (await realizeSelectedVideo(video)).id;
      const { token } = await promiseSagaDispatch(
        startSessionAsyncAC,
        (isPublic && !sessionSecret) ?
          { type: 'public', input: { teamId, videoId } } :
          { type: 'private', input: { teamId, videoId, secret: sessionSecret || undefined } },
        { analytics: { label: analyticsSource } },
      );

      await promiseSagaDispatch(liveSessionConnectAndRegisterAC, { token, registration: {} });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setLoading(false);
    }
  }, [
    analyticsSource,
    enqueueSnackbar,
    isPublic,
    loading,
    promiseSagaDispatch,
    realizeSelectedVideo,
    sessionSecret,
    teamId,
    video,
  ]);

  return (
    <React.Fragment>
      <BetterDialogTitle>
        {t('dialog:startsession.title')}
      </BetterDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>
          {t('dialog:startsession.selectvideodesc')}
        </Typography>
        {!preselectedVideo && (
          <MultiTypeVideoSelector
          teamId={teamId}
          initialDirectoryId={directoryId}
          onChange={setSelectedVideo}
          />
        )}
        <div className={classes.videoChip}>
          {video && <SelectedVideoChip video={video}/>}
        </div>
        <FormControl>
          <RadioGroup
          className={classes.radioGroup}
          name='privacy'
          onChange={handlePrivacyChange}
          value={privacy}
          >
            <FormControlLabel
            label={t('dialog:startsession.teamonlylabel')}
            value='teamonly'
            control={<Radio color='primary' />}
            />
            <FormControlLabel
            label={t('dialog:startsession.publiclabel')}
            value='public'
            control={<Radio color='primary' />}
            />
          </RadioGroup>
          <FormHelperText className={classes.privacyDescriptionText}>
            {t(isPublic ? 'dialog:startsession.publicdescription' : 'dialog:startsession.teamonlydescription')}
          </FormHelperText>
        </FormControl>
        <Collapse in={isPublic}>
          <div>
            <IconTextField
            name='sessionsecret'
            placeholder={t('dialog:startsession.secretplaceholder')}
            onChange={handleSessionSecretOnChange}
            value={sessionSecret}
            fullWidth={true}
            />
          </div>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button
        type='button'
        variant='outlined'
        onClick={onClose}
        >
          {t('common:cancel')}
        </Button>
        <AsyncButton
        variant='contained'
        color='primary'
        loading={loading}
        disabled={loading || !video}
        onClick={handleSubmit}
        >
          {t('dialog:startsession.startsession')}
        </AsyncButton>
      </DialogActions>
    </React.Fragment>
  );
}

interface StartLiveSessionDialogOwnProps {}

type StartLiveSessionDialogProps = DialogProps & StartLiveSessionDialogOwnProps & StartLiveSessionDialogContentProps;

function StartLiveSessionDialog({
  teamId,
  directoryId,
  video,
  onClose,
  analyticsSource,
  ...props
}: StartLiveSessionDialogProps) {
  return (
    <Dialog {...props} onClose={onClose}>
      <StartLiveSessionDialogContent
      teamId={teamId}
      directoryId={directoryId}
      video={video}
      onClose={onClose}
      analyticsSource={analyticsSource}
      />
    </Dialog>
  );
}

export default React.memo(StartLiveSessionDialog);
