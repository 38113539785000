import { FlexSpacer } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';
import Skeleton from '@material-ui/lab/Skeleton';
import { createStyles, makeStyles } from '@material-ui/styles';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { starsBackground } from 'assets';
import InsightsCaptureButton from 'components/status-bar/InsightsCaptureButton';
import { Time } from 'components/time/Time';
import { INSIGHTS_CAPTURE_DOWNLOAD } from 'constants/index';
import { DATE_FORMAT } from 'constants/strings';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import LiveSessionButton from 'features/dashboard/dashboard-header/LiveSessionButton';
import { useFetchTeamIfNecessary } from 'features/dashboard/team/useFetchTeamIfNecessary';
import { useIsDesktop } from 'features/media-queries/hooks';
import { VideoHelper } from 'features/video-library/video-helpers';
import VideoMenuButton from 'features/video-menu/VideoMenuButton';
import { isTruthy } from 'helpers';
import { createUTMParams } from 'helpers/createUTMParams';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { useReferPath } from 'hooks/useReferPath';
import ShareVideoDialog from 'material/dialogs/share-video-dialog/ShareVideoDialog';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EVideoViewerTabType, videoRoute } from 'routes';
import { getMe } from 'selectors/getMe';
import { makeGetMatchById } from 'selectors/match';
import { ID } from 'types/pigeon';


interface VideoViewerHeaderOwnProps {
  tab?: EVideoViewerTabType;
  videoId: ID;
  video?: VideoFragment;
  selectedMatchId?: ID;
  liveSessionRefHandler: ((el: HTMLElement | null) => void);
}

type VideoViewerHeaderProps = VideoViewerHeaderOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  downloadAppContent: {
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundImage: `url(${starsBackground})`,
    padding: theme.spacing(1),
  },
  downloadAppText: {
    textTransform: 'capitalize',
    fontSize: createRemFromPx(20),
    fontWeight: 600,
  },
  downloadAppButton: {
    textTransform: 'capitalize',
  },
}), {name: 'VideoViewer'});

function VideoViewerHeader(props: VideoViewerHeaderProps) {
  const classes = useStyles(props);
  const { selectedMatchId, tab, video, videoId, liveSessionRefHandler } = props;
  const { t } = useTranslation(['video', 'common']);

  const onNavigate = useNavigate();

  const isDesktop = useIsDesktop();

  const me = useSelector(getMe);

  const { canAccessOverwatchStats, canAggregateOverwatchStats, canStartSession } = useAccessControl();
  const [isShareVideoDialogOpen, openShareVideoDialog, closeShareVideoDialog] = useDialogState();

  const teamId = useMemo(() => video && VideoHelper.getTeamId(video), [video]);
  const [team] = useFetchTeamIfNecessary(teamId);

  const selectedMatch = useCreateSelector(makeGetMatchById, selectedMatchId);

  const tabOptions = useMemo(() => {
    const features = selectedMatch?.isGeneratedOverwatchMatch() ? [
      canAccessOverwatchStats && {
        value: EVideoViewerTabType.OVERVIEW,
        label: t('video:overview.title'),
      },
      canAggregateOverwatchStats && {
        value: EVideoViewerTabType.STATISTICS,
        label: t('video:statistics.title'),
      },
    ] : [];
    return [
      {
        value: EVideoViewerTabType.VIDEO_REPLAY,
        label: t('video:replay.title'),
      },
      ...features,
    ].filter(isTruthy);
  }, [canAccessOverwatchStats, canAggregateOverwatchStats, selectedMatch, t]);

  const downloadAppUTM = '&' + createUTMParams('insightsgg', 'video_replay_top', 'ic_download');

  const referPath = useReferPath();
  useEffect(() => {
    if (!tab) {
      onNavigate(
        videoRoute(videoId, EVideoViewerTabType.VIDEO_REPLAY),
        { replaceUrl: true, state: { referPath } },
      );
    }
  }, [onNavigate, referPath, tab, videoId]);

  const handleTabChanged = useCallback((newTab: string) => {
    onNavigate(videoRoute(videoId, newTab), { state: { referPath } });
  }, [onNavigate, referPath, videoId]);

  const handleTabClick = useCallback(
    (e: React.MouseEvent, newValue: string) => handleTabChanged(newValue),
    [handleTabChanged],
  );

  const handleSelectOnChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    handleTabChanged(value);
  }, [handleTabChanged]);

  const videoNameAndInfo = (
    <Box overflow='hidden' flexGrow={isDesktop ? null : '1'}>
      {video ? (
        <React.Fragment>
          <Typography style={{lineHeight: 'inherit'}} variant='h6' component='h2' noWrap={true} title={video.name}>
            {video.name}
          </Typography>
          <Time format={DATE_FORMAT}>{video.created}</Time>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Skeleton variant='text' />
          <Skeleton variant='text' />
        </React.Fragment>
      )}
    </Box>
  );

  if (isDesktop) {
    return (
      <React.Fragment>
        {videoNameAndInfo}
        <Box flexGrow='1' textAlign='center'>
          {tabOptions.length > 1 ? (
            <Box display='inline-block'>
              <Tabs
              onChange={handleTabClick}
              value={tab}
              indicatorColor='primary'
              scrollButtons='on'
              variant='scrollable'
              >
                {tabOptions.map(({value, label}) => (
                  <Tab key={value} value={value} label={label} />
                ))}
              </Tabs>
            </Box>
          ) : me ? (
            <InsightsCaptureButton />
          ) : (
            <FlexSpacer flexAlignItems='center' flexJustifyContent='center'>
              <div className={classes.downloadAppContent}>
                <Typography className={classes.downloadAppText}>
                  {t('video:replay.downloadappdesc')}
                </Typography>
              </div>
              <FlexSpacer flexAlignItems='center' flexJustifyContent='center'>
                <Button
                className={classes.downloadAppButton}
                color='primary'
                variant='contained'
                size='small'
                href={INSIGHTS_CAPTURE_DOWNLOAD.url + downloadAppUTM}
                download={INSIGHTS_CAPTURE_DOWNLOAD.name}
                >
                  {t('video:replay.downloadapp')}
                </Button>
              </FlexSpacer>
            </FlexSpacer>
          )}
        </Box>
        <Box>
          {team &&
          !team.activeSession &&
          canStartSession && (
            <div ref={liveSessionRefHandler}>
              <LiveSessionButton team={team} directoryId={video?.directory?.id}/>
            </div>
          )}
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {tabOptions.length > 1 ? (
        <Box flexGrow='1'>
          <Select
          value={tab}
          onChange={handleSelectOnChange}
          >
            {tabOptions.map(({value, label}) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Box>
      ) : videoNameAndInfo}
      <Box style={{ display: 'flex' }}>
        <IconButton onClick={openShareVideoDialog} size='small'>
          <ShareIcon fontSize='small' />
        </IconButton>
        {video && <VideoMenuButton video={video} size='small' />}
      </Box>
      {video && (
        <ShareVideoDialog
        video={video}
        onClose={closeShareVideoDialog}
        open={isShareVideoDialogOpen}
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(VideoViewerHeader);
