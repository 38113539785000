import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CommentFragment } from 'apollo/fragments/types/CommentFragment';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useMemo } from 'react';
import { ID } from 'types/pigeon';

import AudioCommentContent, { AudioCommentContentRef } from '../audio-comment/AudioCommentContent';
import Comment from '../Comment';
import CommentEdit from '../CommentEdit';
import VideoCommentContent from '../VideoCommentContent';

interface CommentContentOwnProps {
  className?: string;
  comment: CommentFragment;
  commentNotification?: NotificationFragment;
  editing?: boolean;
  members: MemberFragment[];
  handleToggleEditing: VoidFunction;
  videoId: ID;
}

type CommentContentProps = CommentContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1, 4),
  },
  comment: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
}), {name: 'CommentContent'});

function CommentContent(props: CommentContentProps, ref?: React.Ref<AudioCommentContentRef>) {
  const classes = useStyles(props);
  const { videoId, comment, members, editing, handleToggleEditing, commentNotification } = props;

  const { t } = useStrictTranslation(['video']);

  const memberUsers = useMemo(() => {
    return members.map(member => member.user);
  }, [members]);

  return (
    <VideoCommentContent comment={comment} commentNotification={commentNotification}>
      {editing ? (
        <CommentEdit
        members={memberUsers}
        comment={comment}
        videoId={videoId}
        handleToggleEditing={handleToggleEditing}
        />
      ) : (
        <Typography
        variant='body2'
        component='div'
        className={classNames(
          classes.comment,
        )}
        >
          {comment.__typename === 'VideoComment' && comment.recordingUrl ? (
            <AudioCommentContent audioSrc={comment.recordingUrl} ref={ref} />
          ) : (comment.deleted || !comment.message) ? (
            <Typography variant='caption'>
              {t('video:replay.deletedcomment')}
            </Typography>
          ) : (
            <Comment text={comment.message} members={members} />
          )}
        </Typography>
      )}
    </VideoCommentContent>
  );
}

export default React.memo(React.forwardRef(CommentContent));
