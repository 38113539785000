import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AssaultIcon, ControlIcon, EscortIcon, HybridIcon } from '../../../../assets/overwatch-maptypes';
import { BoardsNS } from '../../../../locales/en/boards';
import { LeagueoflegendsNS } from '../../../../locales/en/leagueoflegends';
import { OverwatchNS } from '../../../../locales/en/overwatch';
import { OwmapsNS } from '../../../../locales/en/owmaps';
import { ValorantmapsNS } from '../../../../locales/en/valorantmaps';
import { VideoplayerNS } from '../../../../locales/en/videoplayer';
import { AnnotationMap } from '../map-images';

const useStyles = makeStyles((theme: Theme) => createStyles({
  thumbnailWrapper: {
    paddingTop: '56.25%', /* 16:9 Aspect Ratio */
    height: 0,
    position: 'relative',
  },
  contentRoot: {
    padding: theme.spacing(1, 2),
    position: 'relative',
  },
}), {name: 'MapCard'});

interface MapCardProps {
  map: AnnotationMap;
  handleOnMapSelected: (map: AnnotationMap) => void;
}

function MapCard(props: MapCardProps) {
  const { map, handleOnMapSelected } = props;
  const classes = useStyles(props);
  const onClick = useCallback(() => handleOnMapSelected(map), [map, handleOnMapSelected]);
  const { t } = useTranslation([OwmapsNS, LeagueoflegendsNS, VideoplayerNS, OverwatchNS, BoardsNS, ValorantmapsNS]);

  return (
    <Grid key={map.key} item={true} xs={6} md={4} lg={4} xl={3}>
      <Card
      draggable={false}
      title={t(map.trans)}
      >
        <CardActionArea
        onClick={onClick}
        >
          <CardMedia
          image={map.thumb}
          className={classes.thumbnailWrapper}
          />
          <CardContent className={classes.contentRoot}>
            <Box display='flex' justifyContent='space-between'>
              <Typography>
                {t(map.trans)}
              </Typography>
              <SvgIcon>
                {getIconForGamemode(map.gamemode)}
              </SvgIcon>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}

export default React.memo(MapCard);

export function getIconForGamemode(gamemode: string | null) {
  switch (gamemode) {
    case 'gamemodes.assault':
      return <AssaultIcon />;
    case 'gamemodes.control':
      return <ControlIcon />;
    case 'gamemodes.escort':
      return <EscortIcon />;
    case 'gamemodes.hybrid':
      return <HybridIcon />;
    default:
      return null;
  }
}
