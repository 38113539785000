import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { GetVideosByIdQuery_videos } from 'apollo/queries/types/GetVideosByIdQuery';
import { GraphQLError } from 'graphql';

import { VideoPublicity } from './graphql';
import { ID } from './pigeon';

export interface IVideoFilterOption {
  type: VideoFilter;
  id  : string;
  name: string;
}

export interface IVideoMapTypeFilterOption extends IVideoFilterOption {
  type: MAP_TYPE_FILTER;
}

export interface IVideoLocationFilterOption extends IVideoFilterOption {
  type: LOCATION_FILTER;
}

export const MAP_TYPE_FILTER = 'MAP_TYPE_FILTER';
export type MAP_TYPE_FILTER = typeof MAP_TYPE_FILTER;

export const LOCATION_FILTER = 'LOCATION_FILTER';
export type LOCATION_FILTER = typeof LOCATION_FILTER;

export const TAG_FILTER = 'TAG_FILTER';
export type TAG_FILTER = typeof TAG_FILTER;

export type VideoFilter = MAP_TYPE_FILTER | LOCATION_FILTER | TAG_FILTER;

export const PENDING = 'PENDING';
export type PENDING = typeof PENDING;

export const PAUSED = 'PAUSED';
export type PAUSED = typeof PAUSED;

export const COMPLETE = 'COMPLETE';
export type COMPLETE = typeof COMPLETE;

export const FAILURE = 'FAILURE';
export type FAILURE = typeof FAILURE;

export type PayloadStatus = PENDING | COMPLETE | FAILURE;

export type SortDirection = 'ascending' | 'descending';

export enum EUploadStatus {
  DEFAULT = 0,
  IN_PROGRESS,
  PAUSED,
  COMPLETE,
  FAILURE,
}

export interface IResumableUpload {
  uuid     : string;
  video    : GetVideosByIdQuery_videos;
  file     : File;
  uploadUrl: string;
  publicity?: VideoPublicity | null;
}

export interface LocalTusUpload {
  teamId   : string;
  uuid     : string;
  video    : VideoFragment;
  uploadUrl: string;
  size     : number;
  hashes?  : string[];
  publicity?: VideoPublicity | null;
}

export interface IUploader {
  file: {
    name: string;
  };
  options: {
    onProgress?: ((bytesSent: number, bytesTotal: number) => void) | null;
    onChunkComplete?: ((chunkSize: number, bytesAccepted: number, bytesTotal: number) => void) | null;
    onSuccess?: (() => void) | null;
    onError?: ((error: Error) => void) | null;
  }

  start(): void;
  abort(): void;
}

export interface IUpload {
  id      : ID;
  upload  : IUploader;
  progress: number;
  status  : EUploadStatus;
}

export interface ILightUpload extends Partial<IUpload> {
  id    : ID;
  upload: IUploader; // this is so notifications can receive upload info
}

export interface IUploadChunkComplete extends ILightUpload {
  chunkSize    : number;
  bytesAccepted: number;
  bytesTotal   : number;
}

export interface IHasID {
  id: ID;
}

export interface INotification {
  id   : ID;
  icon?: any;
  time : number;
  read?: boolean;
  url? : string;

  primary: {
    key     : string,
    options?: any,
  };
  secondary?: {
    key     : string,
    options?: any,
  };
}

export type IAuthToken = string;

export type Comparator<T> = (lhs: T, rhs: T) => number;
export type Filter<T> = (n: T) => boolean;

export type Optional<T> = T | undefined;

export type GraphQLQueryRes<T> = [T, readonly GraphQLError[] | undefined];

export interface INormalized<T> {
  normalized: T;
}

export interface ITotalled<T> {
  totals: Pick<T, any>;
}

export * from './redux';
