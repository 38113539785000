import {
  bidirectionalActionCreatorsFactory,
  BidirectionalIDRecord,
  createDefaultRecord,
  fetchUpdate,
} from '@insights-gaming/redux-utils';
import { Draft, isAnyOf } from '@reduxjs/toolkit';
import {
  addCommentReplyAC,
  addVideoCommentAC,
  commentAddedAC,
  commentDeletedAC,
  commentUpdatedAC,
  deleteCommentAC,
  deleteTemporaryCommentAC,
  updateCommentLikedAC,
  updateCommentReplyAC,
  updateVideoCommentAC,
} from 'actions/comment-actions';
import { IWithVideoTarget } from 'actions/video-actions';
import {
  CommentFragment,
  CommentFragment_CommentReply,
  CommentFragment_VideoComment,
} from 'apollo/fragments/types/CommentFragment';
import { ProfileFragment } from 'apollo/fragments/types/ProfileFragment';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import {
  AddCommentReplyMutation_addCommentReply_comment_CommentReply,
  AddCommentReplyMutation_addCommentReply_comment_CommentReply_parentComment,
} from 'apollo/mutations/types/AddCommentReplyMutation';
import { UpdateCommentLikedMutation_updateCommentLiked_comment } from 'apollo/mutations/types/UpdateCommentLikedMutation';
import { GetCommentRepliesByIdsQueryVariables } from 'apollo/queries/types/GetCommentRepliesByIdsQuery';
import {
  GetCommentRepliesQuery_video_comment_queryReplies,
  GetCommentRepliesQueryVariables,
} from 'apollo/queries/types/GetCommentRepliesQuery';
import {
  GetCommentTagsQuery_video_comment_queryTags,
  GetCommentTagsQueryVariables,
} from 'apollo/queries/types/GetCommentTagsQuery';
import { GetVideoCommentsByIdsQueryVariables } from 'apollo/queries/types/GetVideoCommentsByIdsQuery';
import {
  GetVideoCommentsQuery_video_queryComments,
  GetVideoCommentsQuery_video_queryComments_comments,
  GetVideoCommentsQueryVariables,
} from 'apollo/queries/types/GetVideoCommentsQuery';
import { GetVideoCommentsWithNameQuery_video_commentsByIds } from 'apollo/queries/types/GetVideoCommentsWithNameQuery';
import { createPair } from 'helpers';
import addAsyncCases from 'helpers/addAsyncCases';
import { addBidirectionalCases } from 'helpers/addBidirectionalCases';
import { createSlice } from 'helpers/createSlice';
import update, { Spec } from 'immutability-helper';
import fromPairs from 'lodash/fromPairs';
import { exid, ID } from 'types/pigeon';
import actionCreatorFactory, { Success } from 'typescript-fsa';

import { GetLikedUsersQueryVariables } from './../../../../apollo/queries/types/GetLikedUsersQuery';

const name = 'video-comment';
const actionCreator = actionCreatorFactory(name);

const bidirectionalActionCreators = bidirectionalActionCreatorsFactory(actionCreator);

interface VideoCommentState {
  savedComments: { [id: string]: string };  // save comments that user was in process of writing
  savedReplies : { [id: string]: string };
  videoCommentRecords: Partial<Dictionary<BidirectionalIDRecord>>;
  videoCommentDict: Partial<Dictionary<CommentFragment_VideoComment>>;
  filteredVideoCommentDict: Partial<Dictionary<CommentFragment_VideoComment>>;
  videoCommentFetching: ID[];
  videoCommentRepliesRecords: Partial<Dictionary<BidirectionalIDRecord>>;
  videoCommentRepliesDict: Partial<Dictionary<CommentFragment_CommentReply>>;
  videoCommentRepliesFetching: ID[];
  videoCommentTagRecords: Partial<Dictionary<BidirectionalIDRecord>>;
  videoCommentTagDict: Partial<Dictionary<TagFragment>>;
  fetchedTagsCommentIdDict: Dictionary<boolean>;
  videoCommentLikedUsersDict: Dictionary<ProfileFragment[]>
}

const initialState: VideoCommentState = {
  savedComments: {},
  savedReplies : {},
  videoCommentRecords: {},
  filteredVideoCommentDict: {},
  videoCommentDict: {},
  videoCommentFetching: [],
  videoCommentRepliesRecords: {},
  videoCommentRepliesDict: {},
  videoCommentRepliesFetching: [],
  videoCommentTagRecords: {},
  videoCommentTagDict: {},
  fetchedTagsCommentIdDict: {},
  videoCommentLikedUsersDict: {},
};

export const fetchVideoCommentsAC = bidirectionalActionCreators<
  GetVideoCommentsQueryVariables,
  GetVideoCommentsQuery_video_queryComments,
  Error
>('FETCH_VIDEO_COMMENTS');

export const fetchCommentRepliesAC = bidirectionalActionCreators<
  GetCommentRepliesQueryVariables,
  GetCommentRepliesQuery_video_comment_queryReplies,
  Error
>('FETCH_COMMENT_REPLIES');

export const fetchCommentTagsAC = bidirectionalActionCreators<
  GetCommentTagsQueryVariables,
  GetCommentTagsQuery_video_comment_queryTags,
  Error
>('FETCH_COMMENT_TAGS');

export const fetchVideoCommentByIdAC = actionCreator.async<
  { videoId: ID, commentId: ID },
  CommentFragment_VideoComment | undefined,
  Error
>('FETCH_VIDEO_COMMENT_BY_ID');

export const fetchVideoCommentByIdWithVideoAC = actionCreator.async<
  { videoId: string, commentId: string },
  GetVideoCommentsWithNameQuery_video_commentsByIds | null,
  Error
>('FETCH_VIDEO_COMMENT_BY_ID_WITH_VIDEO');

export const fetchVideoCommentsByIdsWithVideoAC = actionCreator.async<
  { videoId: string, commentIds: string[] },
  Array<CommentFragment_VideoComment | null>,
  Error
>('FETCH_VIDEO_COMMENTS_BY_IDS_WITH_VIDEO');

export const fetchVideoCommentByIdsAC = actionCreator.async<
  GetVideoCommentsByIdsQueryVariables,
  Array<CommentFragment_VideoComment | null>,
  Error
>('FETCH_VIDEO_COMMENT_BY_IDS');

export const fetchCommentReplyByIdAC = actionCreator.async<
  { videoId: ID, commentId: ID, replyId: ID },
  CommentFragment_CommentReply | undefined,
  Error
>('FETCH_COMMENT_REPLY_BY_ID');

export const fetchCommentReplyByIdsAC = actionCreator.async<
  GetCommentRepliesByIdsQueryVariables,
  Array<CommentFragment_CommentReply | null>,
  Error
>('FETCH_COMMENT_REPLY_BY_IDS');

export const fetchLikedUsersByCommentIdAC = actionCreator.async<
  GetLikedUsersQueryVariables,
  ProfileFragment[],
  Error
>('FETCH_LIKED_USERS_BY_COMMENT_ID');

type DictSpec<T> = {
  [K in keyof T]?: Spec<T[K]>;
};

function enhanceSpec(
  updateSpec: DictSpec<Draft<VideoCommentState>>,
  comments: GetVideoCommentsQuery_video_queryComments_comments[],
): DictSpec<Draft<VideoCommentState>> {
  let records: Dictionary<Spec<BidirectionalIDRecord>> = {};
  let $merge = {};
  let fetched: Dictionary<boolean> = {};

  for (const comment of comments) {
    if (!comment.queryTags) {
      continue;
    }

    const commentId = comment.id;
    const { tags, pageInfo } = comment.queryTags;
    const ids = tags.map(exid);
    const dict = fromPairs(tags.map(createPair));
    $merge = {...$merge, ...dict};
    records[commentId] = (record: BidirectionalIDRecord) => {
      return fetchUpdate(record || createDefaultRecord(), {$forwardDone: [ids, pageInfo]});
    };

    fetched[commentId] = true;
  }

  updateSpec.videoCommentTagRecords = records;
  updateSpec.videoCommentTagDict = {$merge};
  updateSpec.fetchedTagsCommentIdDict = {$merge: fetched};

  return updateSpec;
}

const videoCommentSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    addBidirectionalCases(builder, fetchVideoCommentsAC, {
      records: (tp, { videoId }) => tp.videoCommentRecords[videoId],
      dict: (tp) => tp.videoCommentDict,
      values: (result) => result.comments.map((comment) => update(comment, { $unset: ['queryTags'] })),
    });

    addBidirectionalCases(builder, fetchCommentRepliesAC, {
      records: (tp, { commentId }) => tp.videoCommentRepliesRecords[commentId],
      dict: (tp) => tp.videoCommentRepliesDict,
      values: (result) => result.replies,
    });

    addBidirectionalCases(builder, fetchCommentTagsAC, {
      records: (tp, { commentId }) => tp.videoCommentTagRecords[commentId],
      dict: (tp) => tp.videoCommentTagDict,
      values: (result) => result.tags,
    });

    addAsyncCases(builder, addVideoCommentAC, {
      done: (state, action) => {
        const { params: { videoId }, result: { comment } } = action.payload;
        switch (comment.__typename) {
          case 'VideoComment': return _commentAdded(state, videoId, comment);
               default       : return state; // should never happen
        }
      },
    });

    addAsyncCases(builder, updateVideoCommentAC, {
      done: (state, { payload: { params: { addTagIds, removeTagIds }, result: { comment } } }) => {
        return _editComment(state, comment, addTagIds, removeTagIds);
      },
    });

    addAsyncCases(builder, updateCommentReplyAC, {
      done: (state, { payload: { params: { replyId }, result: { comment } } }) => {
        return _editReply(state, replyId, comment);
      },
    });

    addAsyncCases(builder, deleteCommentAC, {
      done: (state, action) => {
        const { params: { parentId, videoId }, result: { commentId } } = action.payload;
        switch (parentId) {
          case null:
          case undefined: return _deleteComment(state, videoId!, commentId);
                 default: return _deleteReply(state, parentId, commentId);
        }
      },
    });

    addAsyncCases(builder, addCommentReplyAC, {
      done: (state, action) => {
        const { params: { commentId, videoId }, result: { comment } } = action.payload;
        switch (comment.__typename) {
          case 'CommentReply': return _replyAdded(state, commentId, comment, videoId!);
               default       : return state; // should never happen
        }
      },
    });

    addAsyncCases(builder, updateCommentLikedAC, {
      done: (state, action) => {
        const { params: { commentId, videoId }, result: { comment } } = action.payload;
        return _updateCommentLike(state, comment, commentId);
      },
    });

    // deleteTemporaryCommentAC
    builder.addCase(deleteTemporaryCommentAC, (state, action) => {
      return deleteTemporaryComment(state, action.payload);
    });

    /* Subscription AC */

    builder.addCase(commentAddedAC, (state, action) => {
      return commentAdded(state, action.payload);
    });


    builder.addCase(commentDeletedAC, (state, action) => {
      const { params: { videoId }, result } = action.payload;
      switch (result.__typename) {
        case 'VideoComment': return _deleteComment(state, videoId, result.id);
        case 'CommentReply': return _deleteReply(state, result.parent, result.id);
      }
    });

    builder.addCase(commentUpdatedAC, (state, action) => {
      return commentUpdated(state, action.payload);
    });

    addAsyncCases(builder, fetchVideoCommentByIdAC, {
      started: (state, action) => {
        state.videoCommentFetching = Array.from(new Set([...state.videoCommentFetching, action.payload.commentId]));
      },
      done: (state, action) => {
        state.videoCommentFetching = state.videoCommentFetching.filter((id) => id !== action.payload.params.commentId);
        state.videoCommentDict[action.payload.params.commentId] = action.payload.result;
      },
      failed: (state, action) => {
        state.videoCommentFetching = state.videoCommentFetching.filter((id) => id !== action.payload.params.commentId);
      },
    });

    addAsyncCases(builder, fetchCommentReplyByIdAC, {
      started: (state, { payload }) => {
        state.videoCommentRepliesFetching = Array.from(new Set([
          ...state.videoCommentRepliesFetching,
          payload.replyId,
        ]));
      },
      done: (state, { payload: { params, result } }) => {
        state.videoCommentRepliesFetching = state.videoCommentRepliesFetching.filter((id) => id !== params.replyId);
        state.videoCommentRepliesDict[params.replyId] = result;
      },
      failed: (state, { payload: { params } }) => {
        state.videoCommentRepliesFetching = state.videoCommentRepliesFetching.filter((id) => id !== params.replyId);
      },
    });

    addAsyncCases(builder, fetchLikedUsersByCommentIdAC, {
      done: (state, { payload: { params, result } }) => {
        state.videoCommentLikedUsersDict[params.commentId] = result;
      },
    });

    addAsyncCases(builder, fetchVideoCommentByIdWithVideoAC, {
      done: (state, { payload: { result } }) => {
        if (!result) {
          return;
        }

        const { queryTags, ...comment } = result;
        if (queryTags) {
          state.videoCommentTagRecords[comment.id] = fetchUpdate(
            state.videoCommentTagRecords[comment.id] || createDefaultRecord(),
            { $forwardDone: [queryTags.tags.map(exid), queryTags.pageInfo] },
          );

          state.videoCommentTagDict = update(state.videoCommentTagDict, {
            $merge: Object.fromEntries(queryTags.tags.map(createPair)),
          });
        }

        state.videoCommentDict[result.id] = result;
      },
    });

    builder.addMatcher(
      isAnyOf(fetchVideoCommentsAC.forward.done, fetchVideoCommentsAC.backward.done),
      (state, { payload }) => {
        let records: Dictionary<Spec<BidirectionalIDRecord>> = {};
        let $merge = {};
        let fetched: Dictionary<boolean> = {};

        for (const { id: commentId, queryTags } of payload.result.comments) {
          if (!queryTags) {
            continue;
          }

          $merge = {...$merge, ...Object.fromEntries(queryTags.tags.map(createPair))};
          records[commentId] = (record: BidirectionalIDRecord) => fetchUpdate(
            record || createDefaultRecord(),
            { $forwardDone: [queryTags.tags.map(exid), queryTags.pageInfo] },
          );

          fetched[commentId] = true;
        }

        return update(state, {
          videoCommentTagRecords: records,
          videoCommentTagDict: { $merge },
          fetchedTagsCommentIdDict: { $merge: fetched },
          filteredVideoCommentDict: {
            $set: payload.params.tagIds
              ? Object.fromEntries(
                  payload.result.comments.map((comment) => update(comment, { $unset: ['queryTags'] }))
                    .map(createPair),
                )
              : {},
            },
        });
      },
    );
  },
});

export const videoCommentReducer = videoCommentSlice.reducer;

function deleteTemporaryComment(state: VideoCommentState, payload: ID): VideoCommentState {
  if (!state.savedComments[payload]) { return state; }
  const savedComments = { ...state.savedComments };
  delete savedComments[payload];
  return { ...state, savedComments };
}

function commentAdded(
  state  : VideoCommentState,
  success: Success<IWithVideoTarget, CommentFragment>,
): VideoCommentState {
  const { params, result } = success;
  const { videoId } = params;
  switch (result.__typename) {
    case 'VideoComment': return _commentAdded(state, videoId, result);
    case 'CommentReply': return _replyAdded(state, result.parent, result, videoId);
  }
}

function _commentAdded(
  state  : VideoCommentState,
  videoId: ID,
  comment: CommentFragment_VideoComment,
): VideoCommentState {
  return update(state, {
    videoCommentRecords: {
      [videoId]: {
        ids: ids => Array.from(new Set([comment.id, ...ids])),
      },
    },
    videoCommentDict: {
      [comment.id]: {$set: comment},
    },
  });
}

function commentUpdated(
  state  : VideoCommentState,
  success: Success<IWithVideoTarget, CommentFragment>,
): VideoCommentState {
  const { params, result } = success;
  const { videoId } = params;
  switch (result.__typename) {
    case 'VideoComment': return _editComment(state, result);
    case 'CommentReply': return _editReply(state, result.parent, result);
  }
}

function _editComment(
  state  : VideoCommentState,
  comment: CommentFragment_VideoComment,
  addTagIds?: ID[] | null,
  removeTagIds?: ID[] | null,
): VideoCommentState {
  return update(state, {
    videoCommentDict: {
      [comment.id]: (c: CommentFragment_VideoComment) => update(c, {$set: comment}),
    },
    videoCommentTagRecords: {
      [comment.id]: (records) => update(records || createDefaultRecord(), {
        ids: ids => {
          let filteredIds = ids;
          if (removeTagIds) {
            filteredIds = ids.filter(id => !removeTagIds.includes(id));
          }
          return addTagIds ? [...filteredIds, ...addTagIds] : filteredIds;
        },
      }),
    },
  });
}


function _deleteComment(
  state  : VideoCommentState,
  videoId: ID,
  commentId: ID,
): VideoCommentState {
  return update(state, {
    videoCommentDict: {
      [commentId]: (comment: CommentFragment_VideoComment) => update(comment, {deleted: {$set: true}}),
    },
  });
}

function _replyAdded(
  state  : VideoCommentState,
  commentId: ID,
  reply: CommentFragment_CommentReply | AddCommentReplyMutation_addCommentReply_comment_CommentReply,
  videoId: ID,
): VideoCommentState {
  const videoCommentSpec: Spec<CommentFragment_VideoComment> = {};
  if (isCommentReplyWithParentComment(reply)) {
    videoCommentSpec.replyCount = {$set: reply.parentComment.replyCount};
  }
  return update(state, {
    videoCommentDict: {
      [commentId]: (comment: CommentFragment_VideoComment) => update(comment, {
        replies: {$set: true},
        ...videoCommentSpec,
      }),
    },
    videoCommentRepliesRecords: {
      [commentId]: record => update(record || createDefaultRecord(), {
        ids: ids => Array.from(new Set([reply.id, ...ids])),
      }),
    },
    videoCommentRepliesDict: {
      [reply.id]: {$set: reply},
    },
  });
}

function _deleteReply(
  state    : VideoCommentState,
  commentId: ID,
  replyId  : ID,
): VideoCommentState {
  return update(state, {
    videoCommentRepliesDict: {
      [replyId]: (reply: CommentFragment_CommentReply) => update(reply, {deleted: {$set: true}}),
    },
    videoCommentDict: {
      [commentId]: (comment: CommentFragment_VideoComment) => {
        return update(comment, {
          replies: {$set: comment.replyCount > 1},
          replyCount: {$set: comment.replyCount - 1},
        });
      },
    },
  });
}

function _editReply(
  state    : VideoCommentState,
  commentId: ID,
  reply    : CommentFragment,
): VideoCommentState {
  return update(state, {
    videoCommentRepliesDict: {
      [reply.id]: (reply: CommentFragment_CommentReply) => update(reply, {$set: reply}),
    },
  });
}

function _updateCommentLike(
  state    : VideoCommentState,
  comment  : UpdateCommentLikedMutation_updateCommentLiked_comment,
  commentId: ID,
): VideoCommentState {
  if (comment.__typename === 'VideoComment' && commentId in state.videoCommentDict) {
    return update(state, {
      videoCommentDict: {
        [commentId]: (c: CommentFragment_VideoComment) => update(c, {
          liked: {$set: comment.liked},
          likes: {$set: comment.likes},
        }),
      },
    });
  }
  return update(state, {
    videoCommentRepliesDict: {
      [commentId]: (c: CommentFragment_CommentReply) => update(c, {
        liked: {$set: comment.liked},
        likes: {$set: comment.likes},
      }),
    },
  });
}

function isCommentReplyWithParentComment(
  comment: AddCommentReplyMutation_addCommentReply_comment_CommentReply | CommentFragment_CommentReply,
): comment is AddCommentReplyMutation_addCommentReply_comment_CommentReply {
  return !!(
    comment as { parentComment?: AddCommentReplyMutation_addCommentReply_comment_CommentReply_parentComment }
  ).parentComment;
}
