import { Theme } from '@insights-gaming/theme';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import React, { useCallback, useState } from 'react';

import { TeamPrivilege } from '../../../types/graphql';
import { IPrivilegeGroup } from './privilege';
import SelectPrivilegeGroupCheckBox from './SelectPrivilegeGroupCheckBox';

interface IPrivilegeGroupsOwnProps {
  groups: IPrivilegeGroup[];
  privileges: Dictionary<boolean>;
  handleCheckboxOnChange: React.ChangeEventHandler;
  handleSelectAllPrivileges: (g: IPrivilegeGroup, deselect?: boolean) => void;
}

type Props = IPrivilegeGroupsOwnProps;

function PrivilegeGroups({groups, privileges, handleCheckboxOnChange, handleSelectAllPrivileges}: Props) {
  const [activeGroup, setActiveGroup] = useState<{[group: string]: boolean}>({});
  const { t } = useStrictTranslation(['common', 'dialog']);
  const wt = t as TFunction;
  const accessControl = useAccessControl();

  const handleActiveGroupOnChange = useCallback((e: React.SyntheticEvent) => {
    setActiveGroup({[e.currentTarget.id]: !activeGroup[e.currentTarget.id]});
  }, [activeGroup]);

  const handleScrollIntoView = useCallback(
    (el: HTMLElement) => el.scrollIntoView({behavior: 'smooth', block: 'center'}),
    [],
  );

  const selectAllPrivileges = useCallback((g: IPrivilegeGroup, deselect?: boolean) => {
    handleSelectAllPrivileges(g, deselect);
  }, [handleSelectAllPrivileges]);

  const renderPrivilege = useCallback((p: TeamPrivilege) => (
    <div key={p}>
      <FormControlLabel
      control={
        <Checkbox
        onChange={handleCheckboxOnChange}
        color='default'
        name={p}
        checked={!!privileges[p]}
        />
      }
      label={wt(`common:roles.${TeamPrivilege[p]}`)}
      />
    </div>
  ), [handleCheckboxOnChange, privileges, wt]);

  const renderPrivilegeGroup = useCallback((g: IPrivilegeGroup) => {
    const selectedLength = g.privileges.filter((p: TeamPrivilege) => privileges[p]).length;
    return !!g.privileges.length && (
      <Accordion
      key={g.groupName}
      id={g.groupName}
      expanded={!!activeGroup[g.groupName]}
      onChange={handleActiveGroupOnChange}
      TransitionProps={{onEntered: handleScrollIntoView}}
      >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={g.groupName}
        aria-controls={g.groupName}
        >
          <SelectPrivilegeGroupCheckBox
          group={g}
          selectAllPrivileges={selectAllPrivileges}
          checked={selectedLength === g.privileges.length}
          indeterminate={selectedLength > 0}
          label={(
            <React.Fragment>
              <strong>{wt(g.groupName)}</strong>
              ({selectedLength})
            </React.Fragment>
          )}
          />
        </AccordionSummary>
        <AccordionDetails>
          {g.privileges.map(renderPrivilege)}
        </AccordionDetails>
      </Accordion>
    );
  }, [
    activeGroup,
    handleActiveGroupOnChange,
    handleScrollIntoView,
    privileges,
    renderPrivilege,
    selectAllPrivileges,
    wt,
  ]);

  return (
    <div>
      {groups.map(renderPrivilegeGroup)}
    </div>
  );
};

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    overflowX: 'hidden',
  },
  expanded: {
    margin: 0,
  },
})(MuiAccordion);

const AccordionDetails = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 2),
    flexDirection: 'column',
  },
}))(MuiAccordionDetails);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    minHeight: theme.spacing(6),
    '&$expanded': {
      minHeight: theme.spacing(6),
    },
  },
  content: {
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

export default React.memo(PrivilegeGroups);
