import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

export interface IHiddenFileInputOwnProps {
  id     : string;
  trigger: JSX.Element;
  accept?: string;
}

export type IHiddenFileInputProps = IHiddenFileInputOwnProps &
  WithStyles<typeof styles> &
  React.HtmlHTMLAttributes<HTMLInputElement>;

const styles = createStyles({
  root: {
    '& > input[type=file]': {
      position: 'absolute',
      zIndex: -1,
      width: 0,
      height: 0,
      opacity: 0,
    },
  },
});

class HiddenFileInput extends React.Component<IHiddenFileInputProps> {
  private fileRef: React.RefObject<HTMLInputElement> = React.createRef();
  public render() {
    const { id, trigger, accept, className, classes } = this.props;
    return (
      <div className={classNames(classes.root, className)}>
        {trigger}
        <input
        id={id}
        ref={this.fileRef}
        type='file'
        accept={accept}
        onChange={this.handleOnChange}
        />
      </div>
    );
  }

  private handleOnChange = (e: any) => {
    const { onChange } = this.props;
    if (onChange) { onChange(e); }
    if (this.fileRef && this.fileRef.current) {
      this.fileRef.current.value = '';
    }
  }
}

export default withStyles(styles)(HiddenFileInput);
