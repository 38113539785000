import { createRemFromPx,Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';
import ReactGoogleButton from 'react-google-button';

interface GoogleButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  type?: 'dark' | 'light';
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  googleBtn: {
    width: '100% !important',
    height: createRemFromPx(40) + ' !important',
    borderRadius: '3px !important',
    lineHeight: '2.5em !important',
    '& > div': {
      width: '0 !important',
      height: '0 !important',
      '& > svg': {
        height: createRemFromPx(38) + ' !important',
      },
    },
    '& > span': {
      fontFamily: '\'Roboto\', sans-serif',
    },
  },
}), { name: 'GoogleButton' });

function GoogleButton({ className, ...props }: GoogleButtonProps) {
  return React.createElement(ReactGoogleButton, { ...props, className: classNames(className, useStyles().googleBtn) });
}

export default React.memo(GoogleButton);
