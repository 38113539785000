import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { makeGetTeamInvoicesByTeamId } from 'features/dashboard/billing/dashboard-billing.selectors';
import useAvailableMetrics from 'features/dashboard/billing/useAvailableMetrics';
import useFetchTeamCards from 'features/dashboard/billing/useFetchTeamCards';
import { useFetchTeamInvoices } from 'features/dashboard/billing/useFetchTeamInvoices';
import useTeamAvailableBundles from 'features/dashboard/billing/useTeamAvailableBundles';
import useTeamBalance from 'features/dashboard/billing/useTeamBalance';
import useTeamBillingCycle from 'features/dashboard/billing/useTeamBillingCycle';
import useTeamProration from 'features/dashboard/billing/useTeamProration';
import useTeamSubscription from 'features/dashboard/billing/useTeamSubscription';
import DashboardTeamSettingsBillingRouting from 'features/dashboard/team/dashboard-team-settings-billing.routing';
import TeamInvoiceFetcher from 'fetchers/invoice-fetcher/InvoiceFetcher';
import { createProductCostCalculator } from 'helpers/billing';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useNavigate } from 'hooks/useNavigate';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { ETeamSettingsTabType, teamSettingsRoute } from 'routes';
import { BillingInterval, ProductKind, TeamFeature, TeamResource } from 'types/graphql';

import Overview from './Overview/Overview';
import PaymentHistory from './PaymentHistory/PaymentHistory';
import PaymentMethod from './PaymentMethod/PaymentMethod';

interface TeamBillingOwnProps {
  className?: string;
  team: TeamFragment;
}

type TeamBillingProps = TeamBillingOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  tabsContainer: {
    display:'flex',
    justifyContent:'space-between',
    marginBottom: theme.spacing(1),
    flexWrap: 'wrap',
  },
  tabContainer: {
    fontSize: createRemFromPx(14),
  },
}), {name: 'TeamBilling'});

function TeamBilling(props: TeamBillingProps) {
  const classes = useStyles(props);
  const { className, team } = props;
  const teamId = useMemo(() => team.id, [team.id]);

  const { t } = useStrictTranslation(['dashboard']);

  const onNavigate = useNavigate();

  const dispatch = useDispatch();

  const { canAccessBilling } = useAccessControl();

  const { billingTab } = useParams<DashboardTeamSettingsBillingRouting.Params>();

  const renderNoTabRedirect = useCallback(() => {
    return (
      <Redirect to={DashboardTeamSettingsBillingRouting.createUrl(
        teamId,
        DashboardTeamSettingsBillingRouting.BillingTab.OVERVIEW,
      )}
      />
    );
  }, [teamId]);

  const invoices = useCreateSelector(makeGetTeamInvoicesByTeamId, team.id);

  const [invoicesForwardFetchStatus] = useFetchTeamInvoices(team.id);

  const displayInvoiceSkeleton = invoicesForwardFetchStatus.fetching && !invoicesForwardFetchStatus.cursor;

  const handleTabsChange = (event: React.ChangeEvent, newValue: DashboardTeamSettingsBillingRouting.BillingTab) => {
    if (newValue) {
      onNavigate(DashboardTeamSettingsBillingRouting.createUrl(
        team.id,
        newValue,
      ));
    }
  };

  const bundles = useTeamAvailableBundles(team.id);
  const [ teamSubscription, teamSubscriptionFetched ] = useTeamSubscription(team.id);

  const [ billingCycle, billingCycleFetched ] = useTeamBillingCycle(team.id);
  const [ teamCards, teamCardsFetched ] = useFetchTeamCards(team.id);
  const [ teamBalance, teamBalanceFetched ] = useTeamBalance(team.id);
  const [ teamProration, teamProrationFetched ] = useTeamProration(team.id);

  const currentTeamProducts = useMemo(() => teamSubscription?.products?.map(({ priceId, kind, quantity }) => ({
    id: priceId,
    name: kind,
    quantity,
  })), [teamSubscription]);
  const { metrics: m, customPlans } = useAvailableMetrics(bundles);

  const currentPlan = useMemo(
    () => {
      if (!teamSubscription || !bundles) {
        return;
      }

      const plan = m.find((metric) => metric.productId === teamSubscription.products[0].id);
      if (plan) {
        return plan;
      }

      // build a fake plan
      const products = teamSubscription.products.map((product) => [bundles.byProductId(product.id), product] as const);
      return {
        name: (
          teamSubscription.products.length === 1 &&
            bundles.byProductId(teamSubscription.products[0].id).name.endsWith('Elite Plan')
          ? 'elite_plan'
          : teamSubscription.products.every((product) => customPlans[product.kind]?.plan?.productId === product.id)
          ? 'custom_plan'
          : 'legacy_plan'
        ),
        cost: {
          [BillingInterval.MONTHLY]: products.reduce((acc, [product, { quantity }]) => {
            const price = product.prices.find((p) => p.interval === BillingInterval.MONTHLY);
            if (price) {
              acc += createProductCostCalculator(price)(quantity);
            }

            return acc;
          }, 0),
          [BillingInterval.YEARLY]: products.reduce((acc, [product, { quantity }]) => {
            const price = product.prices.find((p) => p.interval === BillingInterval.YEARLY);
            if (price) {
              acc += createProductCostCalculator(price)(quantity);
            }

            return acc;
          }, 0),
        },
        upload: products.reduce(
          (sum, [product, { quantity }]) => {
            return (
              sum +
                product.resources
                  .filter((r) => r.resource === TeamResource.VIDEO_UPLOAD)
                  .reduce((sum, r) => sum + (r.quantity ?? Infinity), 0) *
                quantity
            );
          }, 0),
        storage: products.reduce(
          (sum, [product, { quantity }]) => {
            return (
              sum +
                product.resources
                  .filter((r) => r.resource === TeamResource.VIDEO_STORAGE)
                  .reduce((sum, r) => sum + (r.quantity ?? Infinity), 0) *
                quantity
            );
          }, 0),
        storageBytes: products.reduce(
          (sum, [product, { quantity }]) => {
            return (
              sum +
                product.resources
                  .filter((r) => r.resource === TeamResource.VIDEO_STORAGE_BYTES)
                  .reduce((sum, r) => sum + (r.quantity ?? Infinity), 0) *
                quantity
            );
          }, 0),
        keys: products.reduce(
          (sum, [product, { quantity }]) => {
            return (
              sum +
                product.features
                  .filter((f) => f.feature === TeamFeature.ADFREE)
                  .reduce((sum, r) => sum + (r.quantity ?? Infinity), 0) *
                quantity
            );
          }, 0),
        kind: ProductKind.RESOURCE_PLAN, // fake it
      };
    },
    [customPlans, m, teamSubscription, bundles],
  );

  useEffect(() => {
    if (!canAccessBilling) { onNavigate(teamSettingsRoute(teamId, ETeamSettingsTabType.OVERVIEW)); }
  }, [canAccessBilling, onNavigate, teamId]);

  const displayOverviewSkeleton = useMemo(
    ()=> !teamSubscriptionFetched || (
      currentTeamProducts ? !billingCycleFetched || !teamBalanceFetched || !bundles : !bundles
    ),
    [billingCycleFetched, bundles, currentTeamProducts, teamBalanceFetched, teamSubscriptionFetched],
  );

  const displayPaymentMethodSkeleton = useMemo(
    ()=> !teamSubscriptionFetched || (
      currentTeamProducts ? !billingCycleFetched || !teamCardsFetched || !bundles : !bundles
    ),
    [teamSubscriptionFetched, currentTeamProducts, billingCycleFetched, teamCardsFetched, bundles],
  );

  const displayPaymentHistorySkeleton = useMemo(
    ()=> !teamSubscriptionFetched || (
      currentTeamProducts ? displayInvoiceSkeleton || !billingCycleFetched : !bundles
    ),
    [billingCycleFetched, bundles, currentTeamProducts, displayInvoiceSkeleton, teamSubscriptionFetched],
  );

  const renderOverviewTab = useCallback(() => {
    return (
      <Overview
      team={team}
      billingCycle={billingCycle}
      hasPlan={!!teamSubscription}
      currentTeamProducts={currentTeamProducts}
      displaySkeleton={displayOverviewSkeleton}
      teamBalance={teamBalance}
      teamProration={teamProration}
      currentPlan={currentPlan}
      />
    );
  }, [
    team,
    billingCycle,
    teamSubscription,
    currentTeamProducts,
    displayOverviewSkeleton,
    teamBalance,
    teamProration,
    currentPlan,
  ]);

  const renderPaymentMethodTab = useCallback(() => (
    <PaymentMethod
    team={team}
    billingCycle={billingCycle}
    teamCards={teamCards}
    hasPlan={!!teamSubscription}
    displaySkeleton={displayPaymentMethodSkeleton}
    />
  ), [team, billingCycle, teamCards, teamSubscription, displayPaymentMethodSkeleton]);

  const renderPaymentHistoryTab = useCallback(() => (
    <PaymentHistory
    team={team}
    billingCycle={billingCycle}
    invoices={invoices}
    hasPlan={!!teamSubscription}
    displaySkeleton={displayPaymentHistorySkeleton}
    />
  ), [team, billingCycle, invoices, teamSubscription, displayPaymentHistorySkeleton]);

  return (
    <div className={classNames(classes.root, className)}>
      <TeamInvoiceFetcher teamId={team.id}/>
      <Tabs
      value={billingTab}
      onChange={handleTabsChange}
      indicatorColor='primary'
      textColor='inherit'
      className={classes.tabsContainer}
      variant='scrollable'
      >
        <Tab
        label={t('dashboard:billing.plandetail')}
        value={DashboardTeamSettingsBillingRouting.BillingTab.OVERVIEW}
        className={classes.tabContainer}
        />
        <Tab
        label={t('dashboard:billing.paymentmethod')}
        value={DashboardTeamSettingsBillingRouting.BillingTab.PAYMENTMETHOD}
        className={classes.tabContainer}
        />
        <Tab
        label={t('dashboard:billing.purchasehistory')}
        value={DashboardTeamSettingsBillingRouting.BillingTab.PAYMENTHISTORY}
        className={classes.tabContainer}
        />
      </Tabs>
      <Switch>
        <Route path={DashboardTeamSettingsBillingRouting.billingOverviewPath} render={renderOverviewTab} />
        <Route path={DashboardTeamSettingsBillingRouting.billingPaymentMethodPath} render={renderPaymentMethodTab} />
        <Route path={DashboardTeamSettingsBillingRouting.billingPaymentHistoryPath} render={renderPaymentHistoryTab} />
        <Route render={renderNoTabRedirect} />
      </Switch>
    </div>
  );
}

export default React.memo(TeamBilling);
