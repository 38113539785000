import { HideTips_Mutation } from 'apollo/mutations';
import { GetTips_Query } from 'apollo/queries';
import { watchAsyncMutation, watchAsyncQuery } from 'helpers/saga/effects';
import { all } from 'redux-saga/effects';

import { fetchTipsAsyncAC, hideTipsAsyncAC } from './tips-slice';

export default function* tipsSaga() {
  yield all([
    watchAsyncQuery(fetchTipsAsyncAC, GetTips_Query, ['tips']),
    watchAsyncMutation(hideTipsAsyncAC, HideTips_Mutation, ['hideTips', 'names']),
  ]);
}
