import { FlexSpacer } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import EmptyState from 'features/empty-state/EmptyState';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { Redirect } from 'react-router';
import EmptyStateImage from 'subcomponents/empty-state-image/EmptyStateImage';
import { TeamInterface } from 'types/pigeon';

import { useAccessControl } from '../access-control/useAccessControl';
import DashboardRouting from '../dashboard.routing';
import CreateDirectoryDialog from '../directory/create-division-dialog/CreateDirectoryDialog';
import { makeGetSharedDivisionsByTeamId, makeGetTeamDirectoriesByTeamId } from '../directory/dashboard-directory-selector';
import { useFetchTeamDivisions } from '../directory/useFetchTeamDivisions';

interface DashboardTeamDirectoriesOwnProps {
  className?: string;
  team: TeamFragment | TeamInterface;
}

type DashboardTeamDirectoriesProps = DashboardTeamDirectoriesOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
  },
}), {name: 'DashboardTeamDirectories'});

function DashboardTeamDirectories(props: DashboardTeamDirectoriesProps) {
  const classes = useStyles(props);
  const { className, team } = props;

  const { t } = useStrictTranslation(['dashboard-directory']);

  const [isCreateDivisionDialogOpen, openCreateDivisionDialog, closeCreateDivisionDialog] = useDialogState();

  const [divisionsForwardFetchStatus] = useFetchTeamDivisions(team.id);
  const initialDivisionFetch = divisionsForwardFetchStatus.fetching && !divisionsForwardFetchStatus.cursor;
  const divisions = useCreateSelector(makeGetTeamDirectoriesByTeamId, team.id);
  const sharedDivisions = useCreateSelector(makeGetSharedDivisionsByTeamId, {teamId: team.id});
  const firstDivision = divisions[0] || sharedDivisions && sharedDivisions[0];

  const { canCreateDivision } = useAccessControl();

  return (
    <FlexSpacer orientation='vertical' className={classNames(classes.root, className)}>
      {!initialDivisionFetch && !divisions.length && (!sharedDivisions || !sharedDivisions.length) ? (
        <EmptyState
        mainTitle={t('dashboard-directory:nochannels.title')}
        subTitle={t('dashboard-directory:nochannels.subtitle')}
        img={<EmptyStateImage url='no-channels' />}
        action={canCreateDivision && (
          <Button
          color='primary'
          variant='contained'
          onClick={openCreateDivisionDialog}
          >
            {t('dashboard-directory:createchannel.title')}
          </Button>
        )}
        />
      ) : firstDivision && (
        <Redirect to={DashboardRouting.createDirectoryUrl(firstDivision.id)} />
      )}
      <CreateDirectoryDialog teamId={team.id} open={isCreateDivisionDialogOpen} onClose={closeCreateDivisionDialog} />
    </FlexSpacer>
  );
}

export default React.memo(DashboardTeamDirectories);
