import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { useSubscribeToVideoEvents } from 'components/video/useSubscribeToVideoEvents';
import { EIntercomID } from 'constants/strings';
import { createControlVideoLoadAction } from 'factories/kmsessionEventFactory';
import { getLiveSessionIsHost, getLiveSessionVideoState } from 'features/live-session/live-session-selector';
import { VideoHelper } from 'features/video-library/video-helpers';
import VideoReplay from 'features/video-replay/VideoReplay';
import TagFetcher from 'fetchers/tag-fetcher/TagFetcher';
import { isVideoFragment } from 'helpers/isVideoFragment';
import { useDialogState } from 'hooks/useDialogState';
import { useKeybindings } from 'hooks/useKeybindings';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useVideoBackPath } from 'hooks/useVideoBackPath';
import ViewerLayout from 'layout/viewer-layout/ViewerLayout';
import ChangeVideoDialog from 'material/dialogs/change-video-dialog/ChangeVideoDialog';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProvideDrawingToolContextValue } from 'subcomponents/video-player-3/drawing-tools-2/ProvideDrawingToolContextValue';
import { SmolVideo } from 'types/pigeon/kmsession';

import { JoinedLiveSessionState, liveSessionSendActionAC } from './live-session-slice';
import LiveSessionVideoReplayContextBindings from './LiveSessionVideoReplayContextBindings';
import SessionViewerHeader from './SessionViewerHeader';

interface LiveSessionViewerOwnProps {
  className?: string;
  state: JoinedLiveSessionState;
  video?: SmolVideo | VideoFragment;
}

type LiveSessionViewerProps = LiveSessionViewerOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    minHeight: 0,
  },
  button: {
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 2),
  },
  buttons: {
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 1),
  },
  toolbar: {
    marginRight: theme.spacing(1),
    minHeight: createRemFromPx(40),
  },
}), {name: 'LiveSessionViewer'});

function LiveSessionViewer(props: LiveSessionViewerProps) {
  const classes = useStyles(props);
  const { video, state } = props;

  const { t } = useStrictTranslation(['common', 'dialog']);
  const dispatch = useDispatch();

  const teamId = useMemo(() => isVideoFragment(video) && VideoHelper.getTeamId(video), [video]);
  const directoryId = useMemo(() => isVideoFragment(video) && VideoHelper.getDivisionId(video), [video]);

  const isHost = useSelector(getLiveSessionIsHost);
  const videoState = useSelector(getLiveSessionVideoState);

  const [isChangeVideoDialogOpen, openChangeVideoDialog, closeChangeVideoDialog] = useDialogState();

  const handleVideoChange = useCallback((video: VideoFragment) => {
    dispatch(liveSessionSendActionAC.started(createControlVideoLoadAction(video.id)));
  }, [dispatch]);

  const backPath = useVideoBackPath(isVideoFragment(video) ? video : undefined);

  const keybindings = useKeybindings({ namespace: 'drawing.tool', bindEmitter: true });

  useSubscribeToVideoEvents(video?.id || '');

  return (
    <ProvideDrawingToolContextValue options={(isHost || state.canvas) ? keybindings : undefined}>
      {teamId && <TagFetcher teamId={teamId} />}
      <ViewerLayout
      backPath={backPath}
      header={<SessionViewerHeader video={video ? video : state.video} isHost={isHost} />}
      >
        <LiveSessionVideoReplayContextBindings>
          <VideoReplay
          videoId={video?.id}
          toolbar={(
            <FlexSpacer className={classes.toolbar}>
              {isHost && (
                <Paper>
                  <Toolbar className={classes.buttons} variant='dense' disableGutters={true}>
                    {
                      isVideoFragment(video) && video.directory && (
                        <Button
                        id={EIntercomID.CHANGE_VIDEOS}
                        startIcon={<SwitchVideoIcon />}
                        onClick={openChangeVideoDialog}
                        >
                          {t('common:session.changevideo')}
                        </Button>
                      )
                    }
                  </Toolbar>
                </Paper>
              )}
            </FlexSpacer>
          )}
          initialState={videoState}
          controllable={isHost}
          />
        </LiveSessionVideoReplayContextBindings>
        {teamId && directoryId && (
          <ChangeVideoDialog
          open={isChangeVideoDialogOpen}
          onClose={closeChangeVideoDialog}
          fullWidth={true}
          onVideoChange={handleVideoChange}
          teamId={teamId}
          directoryId={directoryId}
          />
        )}
      </ViewerLayout>
    </ProvideDrawingToolContextValue>
  );
}

export default React.memo(LiveSessionViewer);
