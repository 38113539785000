import Link from '@material-ui/core/Link';
import { ContentState } from 'draft-js';
import React from 'react';

export interface ICustomLinkOwnProps {
  contentState: ContentState;
  entityKey: string;
}

export type CustomLinkProps = ICustomLinkOwnProps;

class CustomLink extends React.Component<CustomLinkProps> {
  public render() {
    const { contentState, entityKey, children } = this.props;
    const href = contentState.getEntity(entityKey).getData().url;
    return (
      <Link href={href} onClick={this.handleLinkClicked} target='_blank' rel='noopener noreferrer'>
        {children}
      </Link>
    );
  }

  private handleLinkClicked = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };
}

export default CustomLink;
