import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';

import { DiscordLogo } from '../../assets';

export interface IDiscordButtonOwnProps {
  onClick: VoidFunction;
}

export type DiscordButtonProps = IDiscordButtonOwnProps & WithStyles<typeof styles>;

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.discord.main,
    textTransform: 'capitalize',
    height: '40px',
    '&:hover': {
      backgroundColor: theme.palette.discord.dark,
    },
  },
  discordLogo: {
    position: 'absolute',
    left: '10px',
  },
});

class DiscordButton extends React.Component<DiscordButtonProps> {
  public render() {
    const { classes } = this.props;
    return (
      <Button className={classes.root} onClick={this.props.onClick} fullWidth={true}>
        <DiscordLogo width={30} height={30} className={classes.discordLogo}/>
        {this.props.children}
      </Button>
    );
  }
}

export default withStyles(styles)(DiscordButton);
