import { createRemFromPx, Theme } from '@insights-gaming/theme';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useEffect, useState } from 'react';

import { makeGetTeamSearchQueryByTeamId } from './search-selectors';
import { useSearchHandlers } from './useSearchHandlers';

interface SearchBarOwnProps {
  className?: string;
  team: TeamFragment;
}

type SearchBarProps = SearchBarOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    [mobilePortrait(theme)]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    [mobileLandscape(theme)]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  searchField: {
    width: createRemFromPx(440),
    [mobilePortrait(theme)]: {
      width: '100%',
      maxWidth: createRemFromPx(440),
      marginRight: theme.spacing(2),
    },
    [mobileLandscape(theme)]: {
      maxWidth: createRemFromPx(440),
      marginRight: theme.spacing(2),
    },
  },
  input: {
    height: createRemFromPx(32),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  outlinedInput: {
    '&:hover .MuiSvgIcon-root': {
      color: theme.palette.text.primary,
    },
    '&.Mui-focused .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  deemphasized: {
    color: theme.palette.text.deemphasized,
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}), {name: 'SearchBar'});

function SearchBar(props: SearchBarProps) {
  const classes = useStyles(props);
  const { className, team } = props;

  const { t } = useStrictTranslation(['search']);

  const { clearQueryOnClick, handleSearchInputOnChange } = useSearchHandlers(team.id);

  const teamSearchQuery = useCreateSelector(makeGetTeamSearchQueryByTeamId, team.id);

  const [ inputNode, setInputNode ] = useState<HTMLInputElement | null>(null);

  const onRefChange = useCallback((node: HTMLInputElement) => {
    setInputNode(node);
  }, []);

  useEffect(() => {
    if (inputNode) {
      inputNode.focus();
    }
  }, [inputNode]);

  const handleClearQuery = useCallback(() => {
    clearQueryOnClick();
    if (inputNode) {
      inputNode.focus();
    }
  }, [clearQueryOnClick, inputNode]);

  return (
    <div className={classNames(classes.root, className)}>
      <FormControl className={classes.searchField} variant='outlined'>
        <OutlinedInput
        placeholder={t('search:searchinteam', { team: team?.name })}
        inputProps={{className: classes.input}}
        className={classes.outlinedInput}
        onChange={handleSearchInputOnChange}
        value={teamSearchQuery?.params.query}
        inputRef={onRefChange}
        endAdornment={!!teamSearchQuery?.params.query.length ? (
          <CloseIcon onClick={handleClearQuery} className={classes.closeIcon}/>
        ) : (
          <SearchIcon className={classes.deemphasized}/>
        )}
        />
      </FormControl>
    </div>
  );
}

export default React.memo(SearchBar);
