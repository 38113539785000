import Button from '@material-ui/core/Button';
import { ButtonProps } from '@material-ui/core/Button';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

type ButtonLinkProps = LinkProps &
  Pick<ButtonProps, 'color' | 'variant' | 'size' | 'fullWidth'> &
  WithStyles<typeof styles>;

const styles = createStyles({
  root: {
    textDecoration: 'none',
  },
});

class ButtonLink extends React.Component<ButtonLinkProps> {
  public render() {
    return (
      <Button component={Link} {...this.props} />
    );
  }
}

export default withStyles(styles)(ButtonLink);
