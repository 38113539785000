import { GetAvailableTeamResource_Query, GetTeamEdgeById_Query,GetTeams_Query } from 'apollo/queries';
import { watchAsyncQuery, watchBidirectionalAC2 } from 'helpers/saga/effects';
import { all } from 'redux-saga/effects';

import { fetchAvailableTeamResourceAC, fetchTeamEdgeByIdAC,fetchTeamsAC } from './dashboard-team-slice';

export default function* dashboardTeamSaga() {
  yield all([
    watchBidirectionalAC2(fetchTeamsAC, GetTeams_Query, ['queryTeams']),
    watchAsyncQuery(fetchAvailableTeamResourceAC, GetAvailableTeamResource_Query, ['team', 'resources']),
    watchAsyncQuery(fetchTeamEdgeByIdAC, GetTeamEdgeById_Query, ['teamEdge']),
  ]);
}
