import { useSelector } from 'react-redux';
import { RootReducer } from 'rootReducer';

const getIsAuthed = (state: RootReducer) => state.authReducer.isAuthed;
const getIsPastDelay = (state: RootReducer) => !!state.authReducer.isPastDelay;
const getIsLoggedInOrFetching = (state: RootReducer) =>
  Boolean(state.authReducer.isAuthed ?? state.authReducer.isChecking);
const getIsChecked = (state: RootReducer) => typeof state.authReducer.isAuthed === 'boolean';

export function useAuthCheck() {
  return {
    isLoggedIn: useSelector(getIsAuthed),
    isPastDelay: useSelector(getIsPastDelay),
    isLoggedInOrFetching: useSelector(getIsLoggedInOrFetching),
    checkedAuth: useSelector(getIsChecked),
  };
}
