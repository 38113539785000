import { Theme } from '@insights-gaming/theme';
import { ButtonBaseProps } from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import { IconButtonProps } from '@material-ui/core/IconButton';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

type TearFlavors = 'primary' | 'secondary' | 'negative';

const styles = (theme: Theme) => createStyles({
  primary: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  negative: {
    backgroundColor: theme.palette.negative.main,
    '&&:hover': {
      backgroundColor: theme.palette.negative.light,
    },
  },
});

export interface IColoredIconButtonOwnProps {
  flavor?: TearFlavors;
}

export type ColoredIconButtonProps = IColoredIconButtonOwnProps &
  ButtonBaseProps &
  IconButtonProps &
  WithStyles<typeof styles>;

class ColoredIconButton extends React.Component<ColoredIconButtonProps> {
  public render() {
    const { children, className, flavor, classes, ...rest } = this.props;
    return (
      <IconButton
      className={classNames(flavor && classes[flavor], className)}
      {...rest}
      >
        {children}
      </IconButton>
    );
  }
}

export default withStyles(styles)(ColoredIconButton);
