import { DrawingTool } from '@insights-gaming/drawing-canvas/dist/tools/tool';
import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TVideoplayer, VideoplayerNS } from 'locales/en/videoplayer';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getDerivedKeybindingState } from 'selectors/keybinding';

import { convertTitlekeyToHotkey } from './DrawingToolbar';

interface ToolbarListItemOwnProps {
  className?: string;
  titlekey: TVideoplayer;
  setTool: (tool: DrawingTool) => void;
  tool: DrawingTool;
  icon: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
}

type ToolbarListItemProps = ToolbarListItemOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'ToolbarListItem'});

function ToolbarListItem(props: ToolbarListItemProps, ref: React.MutableRefObject<HTMLDivElement>) {
  const classes = useStyles(props);
  const {
    className,
    titlekey,
    setTool: setCurrentTool,
    tool,
    icon,
    selected,
    disabled,
  } = props;
  const { t } = useStrictTranslation(VideoplayerNS);
  const { keybindingMap } = useSelector(getDerivedKeybindingState);

  const toolMenuOnClick = useCallback(() => setCurrentTool(tool), [setCurrentTool, tool]);

  return (
    <ListItem
    ref={ref}
    className={classNames(className, classes.root)}
    onClick={toolMenuOnClick}
    button={true}
    disabled={disabled}
    >
      {selected && (
        <ListItemIcon>
          <DoneIcon fontSize='small'/>
        </ListItemIcon>
      )}
      <ListItemText
      inset={!selected}
      primary={(
        <FlexSpacer fullWidth={true} flexAlignItems='center'>
          {icon}
          <Typography>
            {t(titlekey, { key: convertTitlekeyToHotkey(keybindingMap, titlekey) })}
          </Typography>
        </FlexSpacer>
      )}
      />
    </ListItem>
  );
}

export default React.memo(React.forwardRef(ToolbarListItem));
