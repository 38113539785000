import HelpIcon from '@material-ui/icons/Help';
import { useEffect, useState } from 'react';

export function useIconFromAbilityName(abilityName: string) {
  const [ abilityIcons, setAbilityIcons ] = useState<typeof import('../assets/overwatch-abilities')>();

  useEffect(() => {
    const getAbilities = async () => {
      setAbilityIcons(await import('../assets/overwatch-abilities'));
    };
    if (!abilityIcons) {
      getAbilities();
    }
  }, [abilityIcons]);
  if (!abilityIcons) {
    return HelpIcon;
  }
  const Icon = abilityIcons.allOverwatchAbilities[abilityName];
  return Icon || HelpIcon;
}
