import { useMemo } from 'react';

import { useMultiSelectState } from '../multi-select/useMultiSelectState';
import { DashboardSelectedTagState } from './DashboardSelectedTagsContext';

export function useDashboardTagFilterContext(): DashboardSelectedTagState {
  const tags = useMultiSelectState();

  return useMemo(() => ({
    tags,
  } as const), [tags]);
}
