import { useMultiSelectState } from 'features/dashboard/multi-select/useMultiSelectState';
import { useMemo } from 'react';

import { VideoReplaySelectedLabelState } from './VideoReplaySelectedLabelsContext';

export function useVideoReplaySelectedLabelContext(): VideoReplaySelectedLabelState {
  const labels = useMultiSelectState();

  return useMemo(() => ({
    labels,
  } as const), [labels]);
}
