import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import classNames from 'classnames';
import { useDialogState } from 'hooks/useDialogState';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UpdateNotificationInput } from 'types/graphql';

import { ENotificationSelect } from './constants';
import {
  makeGetAggregatedNotifications,
  makeGetAllNotifications,
  makeGetUnreadAggregatedNotifications,
  makeGetUnreadNotifications,
} from './notifications-selector';
import { resetUnreadNotificationsAC, updateNotificationsAC } from './notifications-slice';
import NotificationsTable from './notifications-table/NotificationsTable';
import { useFetchNotifications } from './useFetchNotification';

interface NotificationsOwnProps {
  className?: string;
}

type NotificationsProps = NotificationsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'Notifications'});

function Notifications(props: NotificationsProps) {
  const classes = useStyles(props);
  const { className } = props;

  const { t } = useStrictTranslation(['common', 'dialog', 'notification']);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const dispatch = useDispatch();

  const [ loading, setLoading ] = useState(false);
  const [ deleteLoading, setDeleteLoading ] = useState(false);

  const [ notificationForwardStatus ] = useFetchNotifications({});

  const [
    isDeleteNotificationsDialogOpen,
    openDeleteNotificationsDialog,
    closeDeleteNotificationsDialog,
  ] = useDialogState();

  const [ notificationSelect, setNotificationSelect ] = useState<ENotificationSelect>(ENotificationSelect.ALL);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setNotificationSelect(event.target.value as ENotificationSelect);
  }, []);

  const notifications = useCreateSelector(makeGetAllNotifications, '');
  const unreadNotifications = useCreateSelector(makeGetUnreadNotifications, '');
  const aggregatedNotifications: NotificationFragment[][] = useCreateSelector(makeGetAggregatedNotifications, '');
  const unreadAggregatedNotifications: NotificationFragment[][] = useCreateSelector(
    makeGetUnreadAggregatedNotifications,
    '',
  );

  const initialFetching = notificationForwardStatus.fetching && !notificationForwardStatus.cursor;

  const handleMarkAllAsRead = useCallback(async () => {
    if (loading) { return; }
    setLoading(true);

    try {
      const inputs: UpdateNotificationInput[] = unreadNotifications.map(n => ({
        notificationId: n.id,
        notificationState: 'READ',
      }));
      await promiseSagaDispatch(updateNotificationsAC, inputs);
      dispatch(resetUnreadNotificationsAC());
      setLoading(false);
    } catch(error) {
      setLoading(false);
    }
  }, [loading, promiseSagaDispatch, unreadNotifications, dispatch]);

  const handleDeleteAllNotifications = useCallback(async () => {
    if (loading) { return; }
    setDeleteLoading(true);

    try {
      const inputs: UpdateNotificationInput[] = notifications.map(n => ({
        notificationId: n.id,
        notificationState: 'DELETED',
      }));
      await promiseSagaDispatch(updateNotificationsAC, inputs);
      dispatch(resetUnreadNotificationsAC());
      setDeleteLoading(false);
      closeDeleteNotificationsDialog();
    } catch(error) {
      setDeleteLoading(false);
    }
  }, [loading, promiseSagaDispatch, notifications, dispatch, closeDeleteNotificationsDialog]);

  return (
    <FlexSpacer className={classNames(classes.root, className)} orientation='vertical'>
      <FlexSpacer flexJustifyContent='space-between'>
        <Select
        value={notificationSelect}
        onChange={handleChange}
        MenuProps= {{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
        >
          {Object.keys(ENotificationSelect).map((k) => (
            <MenuItem value={ENotificationSelect[k]} key={k}>{ENotificationSelect[k]}</MenuItem>
          ))}
        </Select>
        <FlexSpacer>
          <AsyncButton
          disabled={!unreadNotifications.length || loading || initialFetching}
          loading={loading}
          onClick={handleMarkAllAsRead}
          startIcon={<CheckIcon />}
          >
            {t('notification:notifications.markallasread')}
          </AsyncButton>
          <AsyncButton
          disabled={!notifications.length || initialFetching}
          onClick={openDeleteNotificationsDialog}
          startIcon={<DeleteIcon />}
          >
            {t('notification:notifications.deleteall')}
          </AsyncButton>
        </FlexSpacer>
      </FlexSpacer>
      <NotificationsTable
      className={classNames(classes.root, className)}
      displaySkeleton={initialFetching}
      aggregatedNotifications={
        notificationSelect === ENotificationSelect.ALL
        ? aggregatedNotifications
        : unreadAggregatedNotifications
      }
      />
      <Dialog open={isDeleteNotificationsDialogOpen} fullWidth={true}>
        <AlertDialogContent
        titleText={t('dialog:deletenotifications.title')}
        description={t('dialog:deletenotifications.warning')}
        cancel={{
          text  : t('common:cancel'),
          action: closeDeleteNotificationsDialog,
        }}
        confirm={{
          text    : t('dialog:deletenotifications.confirm'),
          loading : deleteLoading,
          disabled: deleteLoading,
          action  : handleDeleteAllNotifications,
        }}
        />
      </Dialog>
    </FlexSpacer>
  );
}

export default React.memo(Notifications);
