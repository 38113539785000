import { FontAwesomeIcon, Props as FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import classNames from 'classnames';
import React from 'react';

type Props = Pick<SvgIconProps, 'fontSize'> & FontAwesomeIconProps;

function FAIcon(props: Props) {
  const { className, fontSize, size, ...rest } = props;
  return (
    <FontAwesomeIcon
    className={classNames(
      'MuiSvgIcon-root',
      {
        'MuiSvgIcon-fontSizeInherit': fontSize === 'inherit',
        'MuiSvgIcon-fontSizeSmall': fontSize === 'small',
        'MuiSvgIcon-fontSizeLarge': fontSize === 'large',
      },
      className,
    )}
    {...rest}
    />
  );
}

export default React.memo(FAIcon);
