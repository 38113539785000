import { CLASS_IDS, GameClassId } from '@insights-gaming/game-events';
import { GameMetadata } from '@insights-gaming/statistics';
import React from 'react';
import { ID } from 'types/pigeon';

import LeagueKDAFloatingText from './LeagueKDAFloatingText';

interface GameKDAFloatingTextOwnProps {
  gameMetadata?: GameMetadata;
  videoId: ID;
}

type GameKDAFloatingTextProps = GameKDAFloatingTextOwnProps;

const renderers: Partial<Record<GameClassId, React.ComponentType<{ gameMetadata?: GameMetadata, videoId: ID }>>> = {
  [CLASS_IDS.LEAGUE_TFT]: LeagueKDAFloatingText,
};

function GameKDAFloatingText(props: GameKDAFloatingTextProps) {
  const { gameMetadata, videoId } = props;

  if (!gameMetadata?.gameClassId) {
    return null;
  }

  const Component = renderers[gameMetadata.gameClassId];
  if (!Component) {
    return null;
  }

  return <Component gameMetadata={gameMetadata} videoId={videoId}/>;
}

export default React.memo(GameKDAFloatingText);
