import { EnhancedDialogTitle } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { IAvailablePlanData } from 'features/dashboard/billing/useAvailableMetrics';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import React, { useState } from 'react';

import CancelSubsContents, { CancelSubsContentsLayoutProps } from '../CancelSubsContents';

interface CancelSubsDialogOwnProps {
  className?: string;
  open: boolean;
  onCancel: VoidFunction;
  onClose: VoidFunction;
  team: TeamFragment;
  currentPlan?: IAvailablePlanData;
}

type CancelSubsDialogProps = CancelSubsDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  currentPlan: {
    width: createRemFromPx(240),
    marginBottom: 0,
    pointerEvents: 'none',
  },
  basicPlan: {
    width: createRemFromPx(240),
    marginBottom: 0,
    pointerEvents: 'none',
  },
  cancelButton: {
    backgroundColor: theme.palette.negative.main,
  },
}), {name: 'CancelSubsDialog'});

function CancelSubsDialog(props: CancelSubsDialogProps) {
  const { open, onClose } = props;

  const isMobilePortrait = useIsMobilePortrait();
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullScreen={isMobilePortrait}>
      <CancelSubsDialogContent {...props} />
    </Dialog>
  );
}

interface CancelSubsDialogLayoutOwnProps {
  className?: string;
  onClose: VoidFunction;
}

function CancelSubsDialogLayout({
  title,
  children,
  className,
  onClose,
}: CancelSubsContentsLayoutProps<CancelSubsDialogLayoutOwnProps>) {
  return (
    <div className={className}>
      <EnhancedDialogTitle onClose={onClose}>
        {title}
      </EnhancedDialogTitle>
      {children}
    </div>
  );
}

function CancelSubsDialogContent(props: CancelSubsDialogProps) {
  const classes = useStyles(props);
  const {
    className,
    onCancel,
    onClose,
    team,
    currentPlan,
  } = props;

  const [ step, onStepChange ] = useState(0);

  return (
    <CancelSubsContents
    Layout={CancelSubsDialogLayout}
    layoutProps={{
      onClose,
      className: classNames(classes.root, className),
    }}
    step={step}
    onStepChange={onStepChange}
    team={team}
    onCancel={onCancel}
    onClose={onClose}
    currentPlan={currentPlan}
    />
  );
}

export default React.memo(CancelSubsDialog);
