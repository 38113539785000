import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { SavedCardFragment } from 'apollo/fragments/types/SavedCardFragment';
import classNames from 'classnames';
import { mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { ComponentProps, useCallback, useEffect } from 'react';

import CardForm from '../CardForm';
import CardDetails from './CardDetails';
import CardDetailsLayout from './CardDetailsLayout';

export interface PaymentCardSelectorOwnProps {
  className?: string;
  cards: readonly SavedCardFragment[];
  card?: SavedCardFragment;
  onChange: (card: SavedCardFragment) => void;
  onAddCard?: (paymentId: string) => void;
  emptyState?: React.ReactNode;
  renderMobileCardDetails?: (card: SavedCardFragment) => React.ReactNode;
  renderMobileAddCard?: (showInputs: boolean) => React.ReactNode;
  renderTail?: (card: SavedCardFragment) => React.ReactNode;
  renderEnd?: (card: SavedCardFragment) => React.ReactNode;
  collapsed?: boolean;
  showInputs: boolean;
  handleEnableShowInputs: VoidFunction;
  disableShowInputs: VoidFunction;
  cardFormButtonText?: string;
}

export type PaymentCardSelectorProps = PaymentCardSelectorOwnProps;

function WrappedCardDetails(
  {
    onClick,
    ...props
  }: Omit<ComponentProps<typeof CardDetails>, 'onClick' | 'card'> & {
    card: SavedCardFragment;
    onClick: (card: SavedCardFragment) => void;
  },
) {
  return (
    <CardDetails
    {...props}
    onClick={useCallback(() => onClick(props.card), [onClick, props.card])}
    />
  );
}

const MemoizedWrappedCardDetails = React.memo(WrappedCardDetails);

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1.5, 0),
  },
  cardDetailsWrapper: {
    [mobilePortrait(theme)]: {
      margin: theme.spacing(1, 0),
    },
    [mobileLandscape(theme)]: {
      margin: theme.spacing(1, 0),
    },
  },
  divider: {
    marginBottom: theme.spacing(1.5),
  },
  headerText: {
    fontWeight: 600,
    margin: theme.spacing(2, 0),
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
  },
  half: {
    width: '50%',
  },
  collapsed: {
    width: '80%',
  },
  addCard: {
    width: '35%',
  },
  addCardCollapsed: {
    width: '56%',
  },
  chip: {
    marginLeft: theme.spacing(2),
    '&$disabled': {
      opacity: 0.3,
    },
  },
  label: {
    textTransform: 'uppercase',
  },
  outlined: {
    borderColor: theme.palette.text.primary,
  },
  disabled: {},
}), {name: 'PaymentCardSelector'});

function PaymentCardSelector({
  className,
  cards,
  card: selectedCard,
  onChange,
  onAddCard,
  emptyState,
  renderMobileCardDetails,
  renderMobileAddCard,
  renderTail,
  renderEnd,
  collapsed,
  showInputs,
  handleEnableShowInputs,
  disableShowInputs,
  cardFormButtonText,
}: PaymentCardSelectorProps) {
  const classes = useStyles();
  const { t } = useStrictTranslation(['common', 'dashboard']);
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (cards.length) {
      disableShowInputs();
    }
  }, [cards.length, disableShowInputs]);

  return (
    <div className={className}>
      {cards.length ? cards.map((card) => (
        <div key={card.id}>
          <FlexSpacer
          flexJustifyContent='space-between'
          flexAlignItems='center'
          className={classes.cardDetailsWrapper}
          >
            {isDesktop ? (
              <FlexSpacer className={classNames(classes.detail, collapsed ? classes.collapsed : classes.half)}>
                <MemoizedWrappedCardDetails
                card={card}
                checked={!showInputs && card.id === selectedCard?.id}
                onClick={onChange}
                />
                {renderTail ? <div>{renderTail(card)}</div> : null}
              </FlexSpacer>
            ) : (
              renderMobileCardDetails && <div>{renderMobileCardDetails(card)}</div>
            )}
            {renderEnd ? <div>{renderEnd(card)}</div> : null}
          </FlexSpacer>
          {!isDesktop && <Divider />}
        </div>
      )) : emptyState}
      {onAddCard && (
        <React.Fragment>
          {cards.length ? (
            isDesktop ? (
              <CardDetailsLayout
              checked={showInputs}
              onClick={handleEnableShowInputs}
              className={collapsed ? classes.addCardCollapsed : classes.addCard}
              >
                <Typography variant='caption'>
                  {t('dashboard:billing.addpaymentcard')}
                </Typography>
              </CardDetailsLayout>
            ) : (
              renderMobileAddCard ? (
                <div>
                  {renderMobileAddCard(showInputs)}
                  <Divider className={classes.divider} />
                </div>
              ) : null
            )
          ) : (
            <Typography variant='h4' className={classes.headerText}>
              {t('dashboard:billing.addpaymentcard')}
            </Typography>
          )}
          {(showInputs || !cards.length) && (
            <CardForm onSubmit={onAddCard} fullWidth={true} bordered={true} buttonText={cardFormButtonText}/>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default React.memo(PaymentCardSelector);
