import { EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { LogoSvg } from 'assets/logos';
import classNames from 'classnames';
import { IAvailablePlanData } from 'features/dashboard/billing/useAvailableMetrics';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import { darkPaletteGradient } from 'helpers/color';
import React, { useState } from 'react';
import { ProductInput } from 'types/graphql';

import BillingContents, { BillingContentsLayoutProps } from '../BillingContents';

interface ChangePlansDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
  hasPlan: boolean;
  team: TeamFragment;
  currentTeamProducts: ProductInput[] | undefined;
  onSuccess: VoidFunction;
  teamProration?: number;
  teamBalance?: number;
  currentPlan?: IAvailablePlanData;
};

type ChangePlansDialogProps = ChangePlansDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogTitle: {
    background: darkPaletteGradient.gradient2.toBottom,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
}), {name: 'ChangePlansDialogContent'});

function ChangePlansDialog(props: ChangePlansDialogProps) {
  const { open, onClose } = props;

  const isMobile = useIsMobilePortrait();
  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' fullScreen={isMobile}>
      <ChangePlansDialogContent {...props} />
    </Dialog>
  );
}

interface ChangePlansDialogLayoutOwnProps {
  className?: string;
  onClose: VoidFunction;
}

function ChangePlansDialogLayout({
  title,
  children,
  className,
  onClose,
}: BillingContentsLayoutProps<ChangePlansDialogLayoutOwnProps>) {
  const classes = useStyles();
  return (
    <div className={className}>
      <EnhancedDialogTitle onClose={onClose} center={true}>
        <FlexSpacer
        flexJustifyContent='center'
        flexAlignItems='center'
        >
          <LogoSvg size={24} />
          <Typography variant='h2' className={classes.dialogTitle}>
            {title}
          </Typography>
        </FlexSpacer>
      </EnhancedDialogTitle>
      {children}
    </div>
  );
}

function ChangePlansDialogContent(props: ChangePlansDialogProps) {
  const classes = useStyles(props);
  const {
    className,
    team,
    onClose,
    onSuccess,
    currentPlan,
  } = props;

  const [ step, onStepChange ] = useState(0);

  return (
    <BillingContents
    Layout={ChangePlansDialogLayout}
    layoutProps={{
      onClose,
      className: classNames(classes.root, className),
    }}
    step={step}
    onStepChange={onStepChange}
    team={team}
    onSuccess={onSuccess}
    onClose={onClose}
    currentPlan={currentPlan}
    />
  );
}

export default React.memo(ChangePlansDialog);
