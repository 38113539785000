import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

export interface IHorizontalDividerOwnProps {
  className?: string;
  uppercase?: true;
}

export type HorizontalDividerProps = IHorizontalDividerOwnProps & WithStyles<typeof styles>;

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    position: 'relative',
    margin: theme.spacing(0.5, 0),
    textAlign: 'center',
  },
  divider: {
    width: '100%',
    height: 0,
    margin: 'auto',
    backgroundColor: theme.palette.text.deemphasized,
  },
  left: {
    marginRight: theme.spacing(1),
  },
  right: {
    marginLeft: theme.spacing(1),
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});

class HorizontalDivider extends React.Component<HorizontalDividerProps> {
  public render() {
    const { classes, className, uppercase } = this.props;
    return (
      <Box className={classNames(classes.root, uppercase && classes.uppercase, className)}>
        <div className={classNames(classes.divider, classes.left)} />
        {this.props.children}
        <div className={classNames(classes.divider, classes.right)} />
      </Box>
    );
  }
}

export default withStyles(styles)(HorizontalDivider);
