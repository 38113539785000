import gql from 'graphql-tag';

export const RemovePrivateDirectoryUsers_Mutation = gql`
    mutation RemovePrivateDirectoryUsersMutation(
        $input: RemovePrivateDirectoryUsersInput!
    ) {
        removePrivateDirectoryUsers(
            input: $input
        ) {
            users
        }
    }
`;
