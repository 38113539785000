
import { MatchFragment } from '../apollo/fragments/types/MatchFragment';
import { Match } from '../types/pigeon';
import { GeneratedOverwatchMatch, TestingSSBUMatch } from '../types/pigeon/matches';

function makeMatch(match: MatchFragment): Match {
  switch (match.__typename) {
    case 'GeneratedOverwatchMatch': { return new GeneratedOverwatchMatch(match); }
    case 'TestingGeneratedSSBUltimateMatch': { return new TestingSSBUMatch(match); }
    default: { throw Error('invalid match.__typename'); }
  }
}

export { makeMatch };
