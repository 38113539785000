import gql from 'graphql-tag';

import { VideoFragment } from '../fragments';

export const CreateUploadedVideo_Mutation = gql`
    mutation CreateUploadedVideoMutation(
        $input: CreateUploadedVideoInput!
    ) {
        createUploadedVideo(
            input: $input
        ) {
            uploadUrl
            video {
                ...VideoFragment
            }
        }
    }
    ${VideoFragment}
`;
