import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import update from 'immutability-helper';
import React, { useMemo } from 'react';

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

interface IUndraggableAvatarOwnPropsProps {
  size?: AvatarSize;
}

export type UndraggableAvatarProps = AvatarProps & IUndraggableAvatarOwnPropsProps;

const useStyles = makeStyles({
  xs: {
    width: 24,
    height: 24,
  },
  sm: {
    width: 32,
    height: 32,
  },
  default: {
    width: 40,
    height: 40,
  },
  md: {
    width: 48,
    height: 48,
  },
  lg: {
    width: 64,
    height: 64,
  },
  xl: {
    width: 128,
    height: 128,
  },
  xxl: {
    width: 150,
    height: 150,
  },
});

function UndraggableAvatar(props: UndraggableAvatarProps) {
  const { size = 'default', className, imgProps, ...rest } = props;
  const classes = useStyles();
  return (
    <Avatar
    className={classNames(classes[size], className)}
    imgProps={useMemo(() => update(imgProps || {}, {draggable: {$set: false}}), [imgProps])}
    {...rest}
    />
  );
}

export default React.memo(UndraggableAvatar);
