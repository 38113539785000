import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';
import { makeGetUserTokenRecords } from 'features/auth/auth-selector';
import { fetchUserTokensAC } from 'features/auth/auth-slice';

export const useFetchUserTokens = createBidirectionalAutoFetcher({
  actionCreators: fetchUserTokensAC,
  selectorFactory: makeGetUserTokenRecords,
  createFetchId: () => '',
  pageSize: 10,
});
