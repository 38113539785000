import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommentFragment, CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import classNames from 'classnames';
import VideoComment, { VideoCommentRef } from 'components/video/video-replay/video-comment/VideoComment';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { Ref, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { ID } from 'types/pigeon';

import { VideoReplaySelectedLabelsContext } from '../VideoReplaySelectedLabelsContext';
import { VirtualizedCommentsProvider, VirtualizedCommentsState } from './VirtualizedCommentsContext';

interface VirtualizedCommentsOwnProps {
  className?: string;
  comments: CommentFragment_VideoComment[];
  currentComment?: CommentFragment_VideoComment
  members: MemberFragment[];
  video: VideoFragment;
  onChangeCurrentComment: (c: CommentFragment_VideoComment) => void;
  cancelManualScroll: VoidFunction;
  currentIdx?: number;
  displayResumeButton: boolean;
  handleEditDrawingOnClick?: (comment: CommentFragment_VideoComment) => void;
}

type VirtualizedCommentsProps = VirtualizedCommentsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    height: '100%',
  },
  resumeAutoScroll: {
    display: 'flex',
    justifyContent: 'center',
  },
  comment: {
    margin: theme.spacing(1, 0),
    border: '1px solid',
    borderRadius: theme.shape.borderRadius,
    borderColor: 'transparent',
    '&$current': {
      borderColor: theme.palette.primary.main,
    },
  },
  current: {},
}), {name: 'VirtualizedComments'});

const cache = new CellMeasurerCache({
  fixedWidth: true,
});

const VirtualizedComments = React.forwardRef<
VideoCommentRef,
VirtualizedCommentsProps
>(function VirtualizedComments(
  props: VirtualizedCommentsProps,
  ref: Ref<VideoCommentRef>,
) {
  const classes = useStyles(props);
  const {
    className,
    comments,
    currentIdx,
    displayResumeButton,
    currentComment,
    members,
    onChangeCurrentComment,
    cancelManualScroll,
    video,
    handleEditDrawingOnClick,
  } = props;

  const { t } = useStrictTranslation(['video']);

  const {
    labels: {
      selected: selectedLabelIds,
    },
  } = useContext(VideoReplaySelectedLabelsContext);

  const listRef = useRef<List | null>(null);
  const [ resumeRef, setResumeRef ] = useState<HTMLDivElement | null>(null);

  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (listRef.current) {
      cache.clearAll();
      listRef.current.forceUpdateGrid();
    }
  }, [listRef, selectedLabelIds, comments]);

  const resizeComment = useCallback((commentId: ID) => {
    const idx = comments.findIndex(c => c.id === commentId);
    if (idx >= 0) {
      cache.clear(idx, 0);
      if (listRef.current) {
        listRef.current.forceUpdateGrid();
      }
    }
  }, [listRef, comments]);

  const handleResizeCommentByType = useCallback((c: CommentFragment) => {
    if (c.__typename === 'VideoComment') {
      resizeComment(c.id);
    } else if (c.__typename === 'CommentReply') {
      resizeComment(c.parent);
    }
  }, [resizeComment]);

  const contextValue: VirtualizedCommentsState = useMemo(() => {
    return {
      resizeComment: handleResizeCommentByType,
    };
  }, [handleResizeCommentByType]);

  const rowRenderer = useCallback(({key, index, style, parent}) => {
    const c = comments[index];
    return (
      <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={key}
      parent={parent}
      rowIndex={index}
      >
        <div style={style}>
          <VirtualizedCommentsProvider value={contextValue}>
            <VideoComment
            ref={ref}
            className={classNames(classes.comment, {
              [classes.current]: c.id === currentComment?.id,
            })}
            comment={c}
            video={video}
            time={c.time}
            timeEnd={c.timeEnd}
            annotation={c.annotation}
            members={members}
            onChangeCurrentComment={onChangeCurrentComment}
            handleEditDrawingOnClick={handleEditDrawingOnClick}
            />
          </VirtualizedCommentsProvider>
        </div>
      </CellMeasurer>
    );
  }, [
    comments,
    currentComment,
    contextValue,
    members,
    classes,
    video,
    ref,
    onChangeCurrentComment,
    handleEditDrawingOnClick,
  ]);

  const newHeight = useMemo(() => {
    if (resumeRef) {
      return resumeRef.getBoundingClientRect().height;
    }
    return 0;
  }, [resumeRef]);

  return (
    <div className={classNames(classes.root, className)}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <React.Fragment>
              <List
              ref={listRef}
              width={width}
              height={height - newHeight}
              rowCount={comments.length}
              rowHeight={cache.rowHeight}
              deferredMeasurementCache={cache}
              rowRenderer={rowRenderer}
              overscanRowCount={5}
              scrollToIndex={currentIdx}
              scrollToAlignment='center'
              />
              {isDesktop && displayResumeButton &&
                <div
                ref={setResumeRef}
                className={classes.resumeAutoScroll}
                style={{width}}
                >
                  <Button
                  onClick={cancelManualScroll}
                  color='primary'
                  variant='contained'
                  size='small'
                  >
                    {t('video:replay.resumeautoscroll')}
                  </Button>
                </div>
              }
            </React.Fragment>
          );
        }}
      </AutoSizer>
    </div>
  );
});

VirtualizedComments.displayName = 'VirtualizedComments';

export default React.memo(VirtualizedComments);
