import { push, replace } from 'connected-react-router';
import { Dispatch } from 'redux';

import { notFoundRoute } from '../routes';
import { INotification } from './';

export type OnNavigate = (path: string, config?: OnNavigateConfig) => void;
export interface OnNavigateConfig {
  replaceUrl?: boolean;
  state?: {
    referPath?: string;
  };
}

export type onSendNotification = (notification: INotification) => void;

export const onNavigateFactory = (dispatch: Dispatch<any>): OnNavigate => {
  return (path: string, config?: OnNavigateConfig) => {
    const replaceUrl = config && config.replaceUrl;
    const state = config && config.state;
    if (replaceUrl) { return dispatch(replace(path, state)); }
    else { return dispatch(push(path, state)); }
  };
};

export const on404Factory = (dispatch: Dispatch<any>): () => void => {
  return () => dispatch(replace(notFoundRoute()));
};
