import { all } from '@redux-saga/core/effects';
import { GetVideoBookmarks_Query } from 'apollo/queries';
import { watchBidirectionalAC2 } from 'helpers/saga/effects';

import { fetchVideoBookmarksAC } from './video-bookmark-slice';

export default function* videoBookmarksSaga() {
  yield all([
    watchBidirectionalAC2(fetchVideoBookmarksAC, GetVideoBookmarks_Query, ['video', 'queryBookmarks']),
  ]);
}
