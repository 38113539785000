import { AsyncButton } from '@insights-gaming/material-components';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import { IFileUploadInput, ITusResumable } from 'features/upload/upload-slice';
import update from 'immutability-helper';
import { CommonF, CommonNS } from 'locales/en/common';
import { DialogF, DialogNS } from 'locales/en/dialog';
import React from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';
import { IResumableUpload } from 'types';
import { ID } from 'types/pigeon';

export interface IResumeUploadDialogContentOwnProps {
  tusResumable: ITusResumable;
  teamId      : ID;
  directoryId : ID;
  onClose     : VoidFunction;
}

export interface IResumeUploadDialogContentDispatch {
  onTusUploadResumeExisting: (upload: IResumableUpload) => void;
  onTusUpload: (input: IFileUploadInput) => Promise<ITusResumable>;
}

export type ResumeUploadDialogContentProps = IResumeUploadDialogContentOwnProps &
  IResumeUploadDialogContentDispatch &
  WithTranslation;

interface IState {
  choices : Map<ID, string>;
  loading?: boolean;
}

class ResumeUploadDialogContent extends React.Component<ResumeUploadDialogContentProps, IState> {
  constructor(props: ResumeUploadDialogContentProps) {
    super(props);

    const { tusResumable } = this.props;
    const add = tusResumable.resumable.map((u: IResumableUpload): [ID, string] => [u.uuid, 'resume']);
    this.state = {
      choices: new Map(),
    };
    this.state = update(this.state, {
      choices: {$add: add},
    });
  }

  public render() {
    const { onClose, tusResumable, t } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <DialogTitle>
          {t(DialogF('resumeupload.title'))}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t(DialogF('resumeupload.description'))}
          </Typography>
          <Grid container={true}>
            {this.renderLabelRow()}
            {tusResumable.resumable.map(this.renderResumableUpload)}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
          variant='outlined'
          onClick={onClose}
          >
            {t(CommonF('cancel'))}
          </Button>
          <AsyncButton
          variant='contained'
          color='primary'
          loading={loading}
          disabled={loading}
          onClick={this.handleSubmit}
          >
            {t(DialogF('resumeupload.confirm'))}
          </AsyncButton>
        </DialogActions>
      </React.Fragment>
    );
  }

  private renderLabelRow = () => {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Grid item={true} xs={8} />
        <Grid item={true} xs={2}>
          <Typography>{t(DialogF('resumeupload.resume'))}</Typography>
        </Grid>
        <Grid item={true} xs={2}>
          <Typography>{t(DialogF('resumeupload.reupload'))}</Typography>
        </Grid>
      </React.Fragment>
    );
  }

  private renderResumableUpload = (upload: IResumableUpload) => {
    const { video } = upload;
    const { choices } = this.state;
    const choice = choices.get(upload.uuid);
    return (
      <React.Fragment key={upload.uuid}>
        <Grid item={true} xs={8}>
          <Box display='flex' flexDirection='column' justifyContent='center'>
            <Typography>{video.name}</Typography>
          </Box>
        </Grid>
        <Grid item={true} xs={2}>
          <Radio
          name={upload.uuid}
          onChange={this.handleRadioGroup}
          value='resume'
          checked={choice === 'resume'}
          />
        </Grid>
        <Grid item={true} xs={2}>
          <Radio
          name={upload.uuid}
          onChange={this.handleRadioGroup}
          value='reupload'
          checked={choice === 'reupload'}
          />
        </Grid>
      </React.Fragment>
    );
  }

  private handleSubmit = async () => {
    const { teamId, directoryId, tusResumable, onTusUpload, onTusUploadResumeExisting, onClose } = this.props;
    const { choices, loading } = this.state;
    if (loading) { return; }
    this.setState({loading: true});

    const reuploads = tusResumable.resumable
      .filter((upload: IResumableUpload) => choices.get(upload.uuid) === 'reupload')
      .map((upload: IResumableUpload) => ({file: upload.file, publicity: upload.publicity}));

    onTusUpload({teamId, directoryId, files: reuploads});

    tusResumable.resumable
      .filter((upload: IResumableUpload) => choices.get(upload.uuid) === 'resume')
      .map((upload: IResumableUpload) => onTusUploadResumeExisting(upload));

    onClose();
  }

  private handleRadioGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { name, value } } = e;
    this.setState(state => update(state, {
      choices: {$add: [[name, value]]},
    }));
  }
}

export default withTranslation([CommonNS, DialogNS])(ResumeUploadDialogContent);
