import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FloatingText from 'material/floating-text/FloatingText';
import React from 'react';

interface GenericOverlayOwnProps {
  inner?: JSX.Element | null;
  errorText?: React.ReactNode;
}

type GenericOverlayProps = GenericOverlayOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
}), {name: 'GenericOverlay'});

function GenericOverlay(props: GenericOverlayProps) {
  const classes = useStyles(props);
  const { inner, errorText } = props;

  return (
    <div className={classes.root}>
      {inner}
      <FloatingText
      horizontal='left'
      vertical='bottom'
      ml={1}
      mb={1}
      >
        {errorText}
      </FloatingText>
    </div>
  );
}

export default React.memo(GenericOverlay);
