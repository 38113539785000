import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TFunction } from 'i18next';
import { TeamPrivilege } from 'types/graphql';

function isGlobalRole(role: RoleInterfaceFragment) {
  return role.__typename === 'GlobalRole';
}

function isCustomRole(role: RoleInterfaceFragment) {
  return role.__typename === 'CustomRole';
}

function joinPrivileges(role: RoleInterfaceFragment, wt: TFunction) {
  return role.privileges
    .map(p => wt(`common:roles.${p}`))
    .join(', ');
};

function hasRole(privileges: TeamPrivilege[], role: RoleInterfaceFragment, isOwner?: boolean) {
  if (isOwner) {
    return true;
  }

  const privilegesNeeded = new Set(role.privileges);

  for (const p of privileges) {
    privilegesNeeded.delete(p);
  }

  return !privilegesNeeded.size;
};


export const RoleHelper = {
  joinPrivileges,
  isCustomRole,
  isGlobalRole,
  hasRole,
};
