import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexSpacer, UndraggableAvatar } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import BrushIcon from '@material-ui/icons/Brush';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { createStyles, makeStyles } from '@material-ui/styles';
import { GetUserProfileQuery_me } from 'apollo/queries/types/GetUserProfileQuery';
import { personPlaceholder } from 'assets';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import ChatMemberListHostTransferDialog from 'material/dialogs/chat-member-list-host-transfer-dialog/ChatMemberListHostTransferDialog';
import ChatMemberListRemoveMemberDialog from 'material/dialogs/chat-member-list-remove-member-dialog/ChatMemberListRemoveMemberDialog';
import React from 'react';
import { ISessionProfile } from 'types/pigeon/kmsession';

import ChatMemberListControlButton from './ChatMemberListControlButton';

interface ChatMemberListItemProps {
  member: ISessionProfile;
  host: ISessionProfile | GetUserProfileQuery_me | undefined;
  me: GetUserProfileQuery_me | undefined;
  drawingAllowedUserIds: string[];
  handlePerUserAnnotationPermissionClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isHostCandidateExisting: boolean;
  memberIds: Set<string>;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  userName: {
    fontSize: createRemFromPx(14),
    fontWeight: 600,
  },
  longUserName: {
    fontSize: createRemFromPx(14),
    whiteSpace: 'nowrap',
    maxWidth: createRemFromPx(120),
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    fontWeight : 600,
    textOverflow: 'ellipsis',
  },
}), {name: 'ChatMemberListItem'});

function ChatMemberListItem(
  {
    member,
    host,
    me,
    drawingAllowedUserIds,
    handlePerUserAnnotationPermissionClick,
    isHostCandidateExisting,
    memberIds,
  }: ChatMemberListItemProps) {
  const classes = useStyles();
  const { t } = useStrictTranslation(['common', 'video']);

  const [isHostTransferDialogOpen, openHostTransferDialog, closeHostTransferDialog] = useDialogState();
  const [isRemoveMemberDialogOpen, openRemoveMemberDialog, closeRemoveMemberDialog] = useDialogState();

  return (
    <React.Fragment key={member.id}>
      {
        member !== host && (
          <React.Fragment>
            <FlexSpacer
            flexAlignItems='center'
            flexJustifyContent='space-between'
            fullWidth={true}
            >
              <FlexSpacer spacing={1} flexAlignItems='center' style={{flexBasis: `${host === me ? '60%' : null}`}}>
                <UndraggableAvatar size='sm' src={member.picture || personPlaceholder} />
                <span style={{color: member.color}} className={host === me ? classes.longUserName : classes.userName}>
                  {member.alias}
                </span>
                {isHostCandidateExisting && host === me && (
                  <FlexSpacer
                  style={{flexBasis: '15%'}}
                  flexJustifyContent='center'
                  flexAlignItems='center'
                  >
                    {memberIds.has(member.id) &&
                      <ChatMemberListControlButton
                      id={member.id}
                      style={{width: createRemFromPx(26), height: createRemFromPx(26)}}
                      onClick={openHostTransferDialog}
                      selected={false}
                      title={t('video:session.transferhost')}
                      >
                        <FontAwesomeIcon icon={faCrown} size='xs'/>
                      </ChatMemberListControlButton>
                    }
                  </FlexSpacer>
                )}
              </FlexSpacer>
              {host === me && (
                <React.Fragment>
                  <FlexSpacer
                  flexAlignItems='center'
                  flexJustifyContent='flex-end'
                  fullWidth={true}
                  >
                    <ChatMemberListControlButton
                    id={member.id}
                    selected={drawingAllowedUserIds.includes(member.id)}
                    onClick={handlePerUserAnnotationPermissionClick}
                    userColor={member.color}
                    title={t('video:replay.allowdrawing')}
                    >
                      <BrushIcon fontSize='small' />
                    </ChatMemberListControlButton>
                    <ChatMemberListControlButton
                    id={member.id}
                    onClick={openRemoveMemberDialog}
                    title={t('video:session.removefromsession')}
                    >
                      <ExitToAppIcon fontSize='small'/>
                    </ChatMemberListControlButton>
                  </FlexSpacer>
                </React.Fragment>
              )}
            </FlexSpacer>
          </React.Fragment>
        )
      }
      <ChatMemberListHostTransferDialog
      open={isHostTransferDialogOpen}
      onClose={closeHostTransferDialog}
      member={member}
      />
      <ChatMemberListRemoveMemberDialog
      open={isRemoveMemberDialogOpen}
      onClose={closeRemoveMemberDialog}
      member={member}
      />
    </React.Fragment>
  );
}

export default React.memo(ChatMemberListItem);
