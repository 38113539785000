import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useCreateSelector } from 'hooks/useCreateSelector';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import MobileSearchFilter from './MobileSearchFilter';
import { makeGetTeamSearchQueryByTeamId } from './search-selectors';
import { initializeTeamSearchAC } from './search-slice';
import SearchFilter from './SearchFilter';
import SearchResultsList from './SearchResultsList';
import SearchTabs from './SearchTabs';

interface SearchMenuOwnProps {
  className?: string;
  team: TeamFragment;
}

type SearchMenuProps = SearchMenuOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: createRemFromPx(1600),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}), {name: 'SearchMenu'});

function SearchMenu(props: SearchMenuProps) {
  const classes = useStyles(props);
  const { className, team } = props;

  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();

  const teamSearchQuery = useCreateSelector(makeGetTeamSearchQueryByTeamId, team.id);

  useEffect(() => {
    if (!teamSearchQuery) {
      dispatch(initializeTeamSearchAC({ teamId: team.id }));
    }
  }, [dispatch, team.id, teamSearchQuery]);

  return (
    <div className={classNames(classes.root, className)}>
      <React.Fragment>
        <SearchTabs team={team}/>
        {isDesktop ? (
          <SearchFilter teamId={team.id}/>
        ) : (
          <MobileSearchFilter teamId={team.id}/>
        )}
        {teamSearchQuery && (
          <SearchResultsList teamSearchQuery={teamSearchQuery} teamId={team.id}/>
        )}
      </React.Fragment>
    </div>
  );
}

export default React.memo(SearchMenu);
