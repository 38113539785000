import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TokenFragment } from 'apollo/fragments/types/TokenFragment';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { WritableDraft } from 'immer/dist/internal';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React from 'react';

import ActiveSessionsTableRowWrapper from './ActiveSessionsTableRowWrapper';

;

interface ActiveSessionsTableOwnProps {
  className?: string;
  tokens: Array<WritableDraft<TokenFragment> | undefined>;
}

type ActiveSessionsTableProps = ActiveSessionsTableOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'ActiveSessionsTable'});

function ActiveSessionsTable(props: ActiveSessionsTableProps) {
  const classes = useStyles(props);
  const { className, tokens } = props;

  const { t } = useStrictTranslation(['common', 'settings']);

  return (
    <FlexSpacer
    className={classNames(classes.root, className)}
    orientation='vertical'
    spacing={2}
    >
      <Table className={classNames(classes.root, className)} size='small'>
        <TableHead>
          <TableRow>
            <FixedTableCell width={180}>{t('settings:activesessions.date')}</FixedTableCell>
            <FixedTableCell width={150}>{t('settings:activesessions.ipaddress')}</FixedTableCell>
            <FixedTableCell width={180}>{t('settings:activesessions.browser')}</FixedTableCell>
            <FixedTableCell width={100}>{t('settings:activesessions.location')}</FixedTableCell>
            <FixedTableCell width={150}>{t('settings:activesessions.action')}</FixedTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tokens.map((token, i) => (
            <ActiveSessionsTableRowWrapper
            key={i}
            token={token}
            />
          ))}
        </TableBody>
      </Table>
    </FlexSpacer>
  );
}

export default React.memo(ActiveSessionsTable);
