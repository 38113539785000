import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { GetUserProfileQuery_me } from 'apollo/queries/types/GetUserProfileQuery';
import classNames from 'classnames';
import { useNavigate } from 'hooks/useNavigate';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import AppContentBox from 'layout/app-content-wrapper/AppContentBox';
import AppContentWrapper from 'layout/app-content-wrapper/AppContentWrapper';
import SettingsGrid from 'layout/settings-grid/SettingsGrid';
import React, { useCallback, useEffect } from 'react';
import { Route, Switch,useLocation,useParams } from 'react-router-dom';
import { ESettingsTabType, settingsRoute } from 'routes';

import ActiveSessions from './active-sessions/ActiveSessions';
import NotificationSettings from './notification-settings/NotificationSettings';
import Notifications from './notifications/Notifications';
import ProfileSettings from './profile-settings/ProfileSettings';
import SettingsTab from './SettingsTab';
import SettingsTabs from './SettingsTabs';
import SigninMethods from './signin-methods/SigninMethods';

interface SettingsOwnProps {
  className?: string;
  me: GetUserProfileQuery_me;
}

type SettingsProps = SettingsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
  wrapper: {
    margin: 0,
  },
}), {name: 'Settings'});

function Settings(props: SettingsProps) {
  const classes = useStyles(props);
  const { className, me } = props;
  const { t } = useStrictTranslation(['settings']);
  const wt = t as TFunction;

  const { tab } = useParams<{ tab?: string }>();

  const onNavigate = useNavigate();
  const location = useLocation();

  const handleTabClick = useCallback((e: React.ChangeEvent, newValue: ESettingsTabType) => {
    if (tab === newValue) { return; }
    onNavigate(settingsRoute(newValue), {state: {referPath: location.state?.referPath}, replaceUrl: true});
  }, [location.state?.referPath, onNavigate, tab]);

  const renderTabMenu = useCallback(() => {
    return (
      <SettingsTabs
      indicatorColor='primary'
      value={tab}
      orientation='vertical'
      onChange={handleTabClick}
      >
        {Object.keys(ESettingsTabType)
          .map((k: string) => ESettingsTabType[k])
          .map(e => (
            <SettingsTab
            key={e}
            value={e}
            label={wt(`settings:tabs.${e}`)}
            />
          ))}
      </SettingsTabs>
    );
  }, [tab, handleTabClick, wt]);

  useEffect(() => {
    if (!tab) {
      onNavigate(settingsRoute());
    }
  }, [tab, onNavigate]);

  const renderProfileSettings = useCallback(() => <ProfileSettings me={me} />, [me]);

  return (
    <div className={classNames(classes.root, className)}>
      <AppContentWrapper className={classes.wrapper}>
        <AppContentBox>
          <SettingsGrid
          renderTabs={renderTabMenu}
          dense={true}
          >
            <Switch>
              <Route path={settingsRoute(ESettingsTabType.PROFILE)} component={renderProfileSettings} />
              <Route path={settingsRoute(ESettingsTabType.NOTIFICATION_SETTINGS)} component={NotificationSettings} />
              <Route path={settingsRoute(ESettingsTabType.NOTIFICATIONS)} component={Notifications} />
              <Route path={settingsRoute(ESettingsTabType.ACTIVE_SESSIONS)} component={ActiveSessions} />
              <Route path={settingsRoute(ESettingsTabType.SIGNIN_METHODS)} component={SigninMethods} />
            </Switch>
          </SettingsGrid>
        </AppContentBox>
      </AppContentWrapper>
    </div>
  );
}

export default React.memo(Settings);
