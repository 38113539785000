import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { defaultIconOptions, overwatchHeroIconPath } from '../../../../../helpers';
import UndraggableAvatar from '../../../../../material/undraggable-avatar/UndraggableAvatar';

export interface IHeroIconOwnProps {
  color?: string;
  hero: string;
}

type Props = IHeroIconOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  blue: {
    border: `1px solid ${theme.palette.overwatch.blueTeam}`,
  },
  red: {
    border: `1px solid ${theme.palette.overwatch.redTeam}`,
  },
}), {name: 'HeroIcon'});

function HeroIcon(props: Props) {
  const { hero, color } = props;
  const classes = useStyles(props);
  return (
    <UndraggableAvatar
    src={overwatchHeroIconPath(hero, defaultIconOptions)}
    size='sm'
    className={color && classes[color]}
    variant='square'
    />
  );
}

export default React.memo(HeroIcon);
