import React from 'react';

import UndraggableAvatar, { AvatarSize } from '../undraggable-avatar/UndraggableAvatar';

export interface IAvatarWithLetterFallbackOwnProps {
  alias  : string;
  picture: string | null;
  size?  : AvatarSize;
}

export type AvatarWithLetterFallbackProps = IAvatarWithLetterFallbackOwnProps;

function AvatarWithLetterFallback({alias, picture, size}: AvatarWithLetterFallbackProps) {
  switch (picture) {
    case undefined:
    case null     : return <LetterAvatar alias={alias} size={size} />;
         default  : return <PictureAvatar src={picture} size={size} />;
  }
}

export default React.memo(AvatarWithLetterFallback);

interface IPictureAvatarOwnProps {
  src  : string;
  size?: AvatarSize;
}

function PictureAvatar(props: IPictureAvatarOwnProps) {
  return (
    <UndraggableAvatar {...props} />
  );
}

interface ILetterAvatarOwnProps {
  alias: string;
  size?: AvatarSize;
}

function LetterAvatar({size, alias}: ILetterAvatarOwnProps) {
  return (
    <UndraggableAvatar size={size}>
      {alias.charAt(0).toUpperCase()}
    </UndraggableAvatar>
  );
}
