import { VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import classNames from 'classnames';
import { useBooleanState } from 'hooks/useBooleanState';
import { useCreateSelector } from 'hooks/useCreateSelector';
import MatchSelector from 'material/match-selector/MatchSelector';
import React, { ContextType, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getMatchCache, makeGetAnalysisMatches } from 'selectors/match';
import { getSelectedTeamId } from 'selectors/team';
import { ID, Video } from 'types/pigeon';

interface MatchPanelOwnProps {
  className?: string;
  video: Video;
  videoTime?: number;

  selectedMatchId?: ID;
  setSelectedMatchId: (matchId: ID) => void;

  // can't use useContext here because the InPortal is rendered above the context provider
  replayContext: ContextType<typeof VideoReplayContext>;
}

type MatchPanelProps = MatchPanelOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    maxHeight: '90vh',
  },
  matchSelector: {
    width: 220,
    flex: 1,
    overflowY: 'auto',
  },
}), {name: 'MatchPanel'});

function MatchPanel(props: MatchPanelProps) {
  const classes = useStyles(props);
  const { className, video, videoTime, replayContext: { seekTo }, setSelectedMatchId, selectedMatchId } = props;

  const selectedTeamId = useSelector(getSelectedTeamId);

  const [showMatchPicker,,, toggleMatchPicker] = useBooleanState(true);

  const matchCache = useSelector(getMatchCache);
  const analysisMatches = useCreateSelector(makeGetAnalysisMatches, {analysis: video.latestAnalysis});

  useEffect(() => {
    if (!analysisMatches) {
      return;
    }

    if (typeof videoTime !== 'number') {
      return;
    }

    const autoChangeMatch = analysisMatches.find(({startTime, endTime}) =>
      startTime <= videoTime && videoTime <= endTime,
    );
    if (autoChangeMatch) {
      setSelectedMatchId(autoChangeMatch.id);
    }
  }, [analysisMatches, setSelectedMatchId, videoTime]);

  const selectMatchAndSeekToStart = useCallback((value: ID) => {
    setSelectedMatchId(value);
    const match = matchCache[value];
    if (!match) {
      return;
    }

    if (typeof match.startTime === 'number') {
      seekTo({type: 'seconds', amount: match.startTime, seekCommitted: true});
    }
  }, [matchCache, seekTo, setSelectedMatchId]);

  const handleMatchChange = useCallback((value: ID | null) => {
    if (!value) {
      // disallow deselecting + seek to the start of the match again
      if (selectedMatchId) {
        selectMatchAndSeekToStart(selectedMatchId);
      }
    } else if (value !== selectedMatchId) {
      selectMatchAndSeekToStart(value);
    }
  }, [selectMatchAndSeekToStart, selectedMatchId]);

  return (
    <div className={classNames(classes.root, className)}>
      <div>
        <IconButton size='small' onClick={toggleMatchPicker}>
          {showMatchPicker ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </div>
      {showMatchPicker && analysisMatches && (
        <div className={classes.matchSelector}>
          <MatchSelector
          teamId={selectedTeamId}
          video={video}
          matches={analysisMatches}
          value={selectedMatchId}
          onChange={handleMatchChange}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(MatchPanel);
