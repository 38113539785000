import { FlexSpacer } from '@insights-gaming/material-components/FlexSpacer';
import { VerticalScroll } from '@insights-gaming/material-components/VerticalScroll';
import { Theme } from '@insights-gaming/theme';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { EIntercomID } from 'constants/strings';
import { getIsFreshLiveSession, getLiveSessionMessages, getLiveSessionToken } from 'features/live-session/live-session-selector';
import { liveSessionSetReadMessageCountAC } from 'features/live-session/live-session-slice';
import { desktop } from 'features/media-queries';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { joinSessionRoute } from 'routes';
import { getMe } from 'selectors/getMe';
import CopyLink from 'subcomponents/copy-link/CopyLink';
import EmptyStateImage from 'subcomponents/empty-state-image/EmptyStateImage';
import { KMMessageLogEvent } from 'types/pigeon/kmsession';

import ChatInputBox from './ChatInputBox';
import ChatlogEvent from './ChatlogEvent';

interface ChatlogViewProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  chatlog: {
    flex: 1,
  },
  noComments: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    [desktop(theme)]: {
      marginTop: theme.spacing(6),
    },
  },
  members: {
    textTransform: 'capitalize',
  },
}), {name: 'ChatlogView'});

function useManualScroll() {
  const chatRef = useRef<HTMLDivElement>(null);
  const prevScrollTopRef = useRef(0);
  const [manualScroll, setManualScroll] = useState(false);
  const manualScrollRef = useRef(manualScroll);

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const { clientHeight, scrollHeight, scrollTop } = e.target as HTMLDivElement;
    const { current: prevScrollTop } = prevScrollTopRef;
    const { current: manualScroll } = manualScrollRef;
    if (scrollTop < prevScrollTop && !manualScroll) {
      setManualScroll(true);
    } else if (scrollHeight - clientHeight === scrollTop && manualScroll) {
      setManualScroll(false);
    }
  }, []);

  const resumeAutoScroll = useCallback(() => {
    setManualScroll(false);
  }, []);

  const scrollChatToBottom = useCallback(() => {
    const { current: chat } = chatRef;
    const { current: manualScroll } = manualScrollRef;
    if (!chat || manualScroll) {
      return;
    }
    chat.scrollTop = chat.scrollHeight;
  }, []);

  useEffect(() => {
    if (!manualScroll) {
      scrollChatToBottom();
    }
  }, [manualScroll, scrollChatToBottom]);

  useEffect(() => { // scroll when new chatlog event added
    scrollChatToBottom();
  }, [scrollChatToBottom]);

  return { chatRef, handleScroll, resumeAutoScroll };
}

function ChatlogView({
  className,
}: ChatlogViewProps) {
  const classes = useStyles();
  const me = useSelector(getMe);
  const { t } = useStrictTranslation(['common', 'video']);

  const dispatch = useDispatch();

  // const { messageEvents, sendEvent, setLastReadMessage } = liveSession;

  const messages = useSelector(getLiveSessionMessages);
  const sessionToken = useSelector(getLiveSessionToken);

  useEffect(() => {
    dispatch(liveSessionSetReadMessageCountAC(Math.max(0, messages.length - 1)));
  }, [dispatch, messages.length]);

  const { chatRef, handleScroll } = useManualScroll();

  const chatIsEmpty = useSelector(getIsFreshLiveSession);

  return (
    <FlexSpacer orientation='vertical' className={classNames(classes.root, className)}>
      {
        chatIsEmpty && messages.length <= 0 && sessionToken ? (
          <div className={classes.noComments}>
            <EmptyStateImage url='no-session-members' width={230} height={190} />
            <FlexSpacer orientation='vertical'>
              <div>
                <strong>{t('common:session.sessioninprogress')}</strong>
                <div>{t('common:session.invitefriends')}</div>
              </div>
              <CopyLink
              id={EIntercomID.SESSION_INVITE_LINK}
              textToCopy={window.location.origin + joinSessionRoute(sessionToken)}
              />
            </FlexSpacer>
          </div>
        ) : (
          <React.Fragment>
            <VerticalScroll ref={chatRef} onScroll={handleScroll} className={classes.chatlog}>
              <FlexSpacer orientation='vertical'>
                {
                  messages.map((event, i) => (
                    <ChatlogEvent key={getEventKey(event, i)} event={event} />
                  ))
                }
              </FlexSpacer>
            </VerticalScroll>
            <Divider />
            <ChatInputBox />
          </React.Fragment>
        )
      }
    </FlexSpacer>
  );
}

export default React.memo(ChatlogView);

function getEventKey(event: KMMessageLogEvent, i: number) {
  switch (event.type) {
    case 'connected': return event.uuid;
    case 'disconnected': return event.uuid;
    case 'host_change': return [event.type, event.host, i].join(':');
    case 'message': return event.time;
    case 'video_load': return [event.type, event.video.id, i].join(':');
    default: return [(event as any).type, i].join(':');
  }
}
