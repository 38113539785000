import { VideoFragment } from 'apollo/fragments';
import gql from 'graphql-tag';

export const UpdateVideo2_Mutation = gql`
    mutation UpdateVideo2Mutation(
        $input: UpdateVideo2Input!
    ) {
        updateVideo2(
            input: $input
        ) {
            video {
                ...VideoFragment
            }
        }
    }
    ${VideoFragment}
`;
