import { AddPrivateDirectoryUsers_Mutation, CreateTeamDivision_Mutation, CreateTeamFolder_Mutation, DeleteTeamDirectory_Mutation, LeaveDirectory_Mutation,RemovePrivateDirectoryUsers_Mutation, UpdateDivisionPrivileges_Mutation, UpdateTeamDivision_Mutation, UpdateTeamFolder_Mutation } from 'apollo/mutations';
import { GetDirectoryById_Query,GetSharedDivisions_Query, GetTeamDirectoryFolders_Query, GetTeamDirectoryUsers_Query, GetTeamDivisions_Query } from 'apollo/queries';
import { watchAsyncMutation, watchAsyncQuery, watchBidirectionalAC2 } from 'helpers/saga/effects';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import {
  makeGetTeamDirectoryFolderRecordsByDirectoryId,
  makeGetTeamDirectoryRecordsByTeamId,
} from './dashboard-directory-selector';
import {
  addPrivateDirectoryUsersAC,
  createTeamDivisionAC,
  createTeamFolderAC,
  deleteTeamDirectoryAC,
  fetchDirectoryByIdAC,
  fetchSharedDivisionsAC,
  fetchTeamDirectoryFoldersAC,
  fetchTeamDirectoryUsersAC,
  fetchTeamDivisionsAC,
  leaveDirectoryAC,
  removePrivateDirectoryUsersAC,
  updateDivisionPrivilegesAC,
  updateTeamDivisionAC,
  updateTeamFolderAC,
} from './dashboard-directory-slice';

export default function* dashboardDirectorySaga() {
  yield all([
    watchBidirectionalAC2(fetchTeamDivisionsAC, GetTeamDivisions_Query, ['team']),
    watchBidirectionalAC2(fetchTeamDirectoryFoldersAC, GetTeamDirectoryFolders_Query, ['directory']),
    watchBidirectionalAC2(fetchTeamDirectoryUsersAC, GetTeamDirectoryUsers_Query, ['directory']),
    watchBidirectionalAC2(fetchSharedDivisionsAC, GetSharedDivisions_Query, ['querySharedDivisions']),
    watchAsyncQuery(fetchDirectoryByIdAC, GetDirectoryById_Query, ['directory']),
    watchAsyncMutation(createTeamDivisionAC, CreateTeamDivision_Mutation, ['createDivision']),
    watchAsyncMutation(createTeamFolderAC, CreateTeamFolder_Mutation, ['createFolder']),
    watchAsyncMutation(updateDivisionPrivilegesAC, UpdateDivisionPrivileges_Mutation, ['updateDivisionPrivileges']),
    watchAsyncMutation(updateTeamDivisionAC, UpdateTeamDivision_Mutation, ['updateDivision']),
    watchAsyncMutation(updateTeamFolderAC, UpdateTeamFolder_Mutation, ['updateFolder']),
    watchAsyncMutation(deleteTeamDirectoryAC, DeleteTeamDirectory_Mutation, ['deleteDirectory']),
    watchAsyncMutation(addPrivateDirectoryUsersAC, AddPrivateDirectoryUsers_Mutation, ['addPrivateDirectoryUsers']),
    watchAsyncMutation(
      removePrivateDirectoryUsersAC,
      RemovePrivateDirectoryUsers_Mutation,
      ['removePrivateDirectoryUsers'],
    ),
    watchAsyncMutation(leaveDirectoryAC, LeaveDirectory_Mutation, ['leaveDirectory']),

    takeEvery(createTeamDivisionAC.done, function* ({ payload: { params: { teamId } } }) {
      try {
        // backward fetch to get the newly created division.
        const selector: ReturnType<typeof makeGetTeamDirectoryRecordsByTeamId> = yield call(
          makeGetTeamDirectoryRecordsByTeamId,
        );
        const teamDirectoryRecords: ReturnType<typeof selector> = yield select(selector, teamId);
        const cursor = teamDirectoryRecords?.backward?.cursor;
        if (cursor) {
          yield put(fetchTeamDivisionsAC.backward.started({teamId, before: cursor, limit: 2}));
        }
      } catch {
        // do nothing
      }
    }),

    takeEvery(createTeamFolderAC.done, function* ({ payload: { params: { parentId } } }) {
      try {
        // backward fetch to get the newly created folder.
        const selector: ReturnType<typeof makeGetTeamDirectoryFolderRecordsByDirectoryId> = yield call(
          makeGetTeamDirectoryFolderRecordsByDirectoryId,
        );
        const teamDirectoryFolderRecords: ReturnType<typeof selector> = yield select(selector, parentId);
        const cursor = teamDirectoryFolderRecords?.backward?.cursor;
        if (cursor) {
          yield put(fetchTeamDirectoryFoldersAC.backward.started({
            directoryId: parentId,
            before: cursor,
            limit: 2,
          }));
        }
      } catch {
        // do nothing
      }
    }),
  ]);
}
