import { ID } from 'types/pigeon';

enum Tab {
  SETTINGS = 'settings',
  STATISTICS = 'statistics',
}

const path = '/dashboard/team/:teamid/:tab?';

const createUrl = (teamId: ID) => `/dashboard/team/${teamId}`;
const createTabUrl = (teamId: ID, tab?: Tab) => {
  return [createUrl(teamId), tab].filter(Boolean).join('/');
};

const DashboardTeamRouting = {
  Tab,
  path,
  createUrl,
  createTabUrl,
};

// eslint-disable-next-line
declare namespace DashboardTeamRouting {
  interface Params {
    teamid: ID;
    tab?: Tab;
  }
}

export default DashboardTeamRouting;
