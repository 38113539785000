import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, WithStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { VERIFY_ENDPOINT } from 'constants/index';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import { LoginF, LoginNS } from '../../locales/en/login';
import { signinRoute } from '../../routes';
import { OnNavigate } from '../../types/dispatch';

export interface IVerifyCallbackDispatch {
  onNavigate: OnNavigate;
}

export type IVerifyCallbackProps = IVerifyCallbackDispatch & WithStyles<typeof styles> & WithTranslation;

interface IState {
  verified?: boolean;
  errorMsg?: string;
}

const styles = (theme: Theme) => createStyles({
  content: {
    textAlign: 'center',
  },
});

class VerifyCallback extends React.Component<IVerifyCallbackProps, IState> {
  constructor(props: IVerifyCallbackProps) {
    super(props);
    this.state = {};
  }

  public async componentDidMount() {
    const paramsString = window.location.hash.slice(1);
    const params = new URLSearchParams(paramsString);
    const code = params.get('code');
    if (code) {
      const res: Response = await fetch(VERIFY_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `code=${code}`,
      });

      if (!res.ok) {
        const { error } = await res.json();
        this.setState({errorMsg: error});
      } else {
        this.setState({verified: true});
        const location = res.headers.get('location');
        if (location) {
          window.location.href = location;
        }
      }
    }
  }

  public render() {
    const { errorMsg, verified } = this.state;
    if (verified) {
      return <Redirect to={{ pathname: signinRoute(), state: { verified: true } }}/>;
    }

    if (errorMsg) {
      return (
        <Dialog
        open={true}
        fullWidth={true}
        >
          <DialogContent className={this.props.classes.content}>
            {this.errorMsg(errorMsg)}
          </DialogContent>
        </Dialog>
      );
    }

    return null;
  }

  private errorMsg = (msg: string) => {
    const { t } = this.props;
    switch (msg) {
      // case 'NOT_FOUND'  :
      // case 'BAD_REQUEST':
           default      : return t(LoginF('errors.invalidverifylink'));
    }
  }
}

export default withTranslation(LoginNS)(withStyles(styles)(VerifyCallback));
