import { useCreateSelector } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import classNames from 'classnames';
import { makeGetUnreadVideoCommentNotificationsLength } from 'components/settings/notifications/notifications-selector';
import { makeGetTagsByIds } from 'features/dashboard/tag/dashboard-tag-selector';
import { tryResumeTusUploadAC } from 'features/upload/resumable-uploads-slice';
import { VideoHelper } from 'features/video-library/video-helpers';
import { Path } from 'history';
import { useGetLocalTusUpload } from 'hooks/useGetLocalTusUpload';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import { extname } from 'path';
import React, { forwardRef, PropsWithChildren, Ref, useCallback, useMemo } from 'react';

import VideoCardOverlay from './overlay/VideoCardOverlay';
import VideoCard from './VideoCard';
import VideoCardContent from './VideoCardContent';

interface DetailedVideoCardOwnProps {
  className?: string;
  disabled: boolean;
  onClick?: React.MouseEventHandler;
  name: React.ReactNode;
  thumbnail?: React.ReactNode | string;
  contextMenu?: React.ReactNode;
  overlay?: React.ReactNode;
  newVideo: boolean;
  video: VideoFragment;
  selected?: boolean;
  link?: Path;
}

type DetailedVideoCardProps = PropsWithChildren<DetailedVideoCardOwnProps>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  newVideoIndicator: {
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    fontSize: createRemFromPx(12),
    textTransform: 'uppercase',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    alignSelf: 'self-start',
  },
}), {name: 'DetailedVideoCard'});

const DetailedVideoCard = forwardRef<
HTMLDivElement,
DetailedVideoCardProps
>(function DetailedVideoCard(props: DetailedVideoCardProps, cardRef: Ref<HTMLDivElement>) {
  const classes = useStyles(props);
  const {
    onClick,
    name,
    thumbnail,
    contextMenu,
    overlay,
    disabled,
    newVideo,
    video,
    selected,
    children,
    link,
  } = props;

  const resumableUpload = useGetLocalTusUpload(video.id);
  const { t } = useStrictTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const acceptedVideoFormat = extname(video.name);
  const tags = useCreateSelector(makeGetTagsByIds, {tagIds: video.tags});

  const handleFileInput = useCallback(async (event) => {
    if (event.target.files && resumableUpload) {
      if (!await promiseSagaDispatch(tryResumeTusUploadAC, {
        file: event.target.files[0],
        upload: resumableUpload,
      })) {
        enqueueSnackbar(t('common:videocard.paused.error'), { variant: 'error' });
      }
    }
  }, [enqueueSnackbar, promiseSagaDispatch, resumableUpload, t]);

  const isDisabled = useMemo(() => {
    if (disabled) {
      return true;
    }
    if (resumableUpload) {
      return false;
    }
    if (!VideoHelper.isWatchable(video)) {
      return true;
    }
    return false;
  }, [disabled, resumableUpload, video]);

  const numOfComments = useCreateSelector(
    makeGetUnreadVideoCommentNotificationsLength,
    {videoId: video.id},
  );

  return (
    <VideoCard
    ref={cardRef}
    className={classNames(classes.root)}
    name={name}
    selected={selected}
    disabled={isDisabled}
    thumbnail={thumbnail}
    onClick={onClick}
    contextMenu={contextMenu}
    overlay={overlay}
    newVideo={newVideo && (
      <div className={classes.newVideoIndicator}>
        {t('common:new')}
      </div>
    )}
    progress={
      <VideoCardOverlay video={video} />
    }
    content={
      <VideoCardContent
      owner={VideoHelper.getOwnerName(video)}
      tags={tags}
      hasAnalysis={VideoHelper.hasCompleteAnalysis(video)}
      hasComments={!!video.comments}
      createdDate={video.created}
      numOfVideoCommentNotfications={numOfComments}
      />
    }
    resumableUpload={!!resumableUpload}
    handleFileInput={handleFileInput}
    acceptedVideoFormat={acceptedVideoFormat}
    link={link}
    >
      {children}
    </VideoCard>
  );
});

DetailedVideoCard.displayName = 'DetailedVideoCard';

export default React.memo(DetailedVideoCard);
