import { getDisplayName } from '@insights-gaming/utils';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { forwardRef } from 'react';

import { ProductTourId } from './product-tour-ids';

interface CommonProps {}

interface InjectedProps extends CommonProps {
  onNext?: (current: number) => void;
  prev: string;
  next: string;
  done: string;
}

interface AdditionalProps extends CommonProps {
  tourId: ProductTourId;
}

export function withMultiStepProductTour<P extends InjectedProps, E>(
  Component: React.ComponentType<P> | React.ExoticComponent<P>,
) {
  type RemovedProps = Omit<InjectedProps, keyof CommonProps>;
  type Props = Omit<AdditionalProps & P, keyof RemovedProps>;

  const WithMultiStepProductTour = forwardRef<E, Props>(function WithMultiStepProductTour(props: Props, forwardedRef) {
    const {
      tourId,
      ...rest
    } = props;

    const { t } = useStrictTranslation(['common']);

    return (
      <Component
      ref={forwardedRef}
      {...rest as unknown as P}
      done={t('common:done')}
      next={t('common:next')}
      prev={t('common:back')}
      />
    );
  });
  WithMultiStepProductTour.displayName = `WithMultiStepProductTour(${getDisplayName(Component)})`;

  return WithMultiStepProductTour;
}
