import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import classNames from 'classnames';
import React, { useCallback } from 'react';

interface DirectoryButtonOwnProps {
  className?: string;
  directory: DirectoryFragment;
  onClick?: (e: React.MouseEvent, directory: DirectoryFragment) => void;
}

type DirectoryButtonProps = DirectoryButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'unset',
  },
  directoryName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: createRemFromPx(400),
    fontWeight: theme.typography.h5.fontWeight,
    fontSize: theme.typography.h5.fontSize,
  },
}), {name: 'DirectoryButton'});

function DirectoryButton(props: DirectoryButtonProps) {
  const classes = useStyles(props);
  const { className, directory, onClick } = props;

  const handleClick = useCallback((e: React.MouseEvent) => {
    onClick?.(e, directory);
  }, [directory, onClick]);

  return (
    <Button onClick={handleClick} className={classNames(classes.root, className)}>
      <Typography className={classes.directoryName}>
        {directory.name}
      </Typography>
    </Button>
  );
}

export default React.memo(DirectoryButton);
