import { PopupState } from 'material-ui-popup-state/core';
import { useEffect } from 'react';

import { useKeybindings } from './useKeybindings';

type PopupType = 'menu.open' | 'popover.open';

export function usePopupStateKeybindingHelper(popupState: PopupState, type: PopupType) {
  const { isOpen } = popupState;
  const { addContext, removeContext } = useKeybindings(type);
  useEffect(() => {
    if (isOpen) {
      addContext();
    } else {
      removeContext();
    }
  }, [addContext, isOpen, removeContext]);
}
