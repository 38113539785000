import { FlexSpacer } from '@insights-gaming/material-components';
import Typography from '@material-ui/core/Typography';
import UnalignedContainer from 'components/unaligned-container/UnalignedContainer';
import { OwstatsF, OwstatsNS } from 'locales/en/owstats';
import React from 'react';
import { Trans } from 'react-i18next';
import EmptyStateImage from 'subcomponents/empty-state-image/EmptyStateImage';

function QueryResultNotFound() {
  return (
    <UnalignedContainer>
      <FlexSpacer orientation='vertical' flexAlignItems='center'>
        <Typography variant='body2' align='center'>
          <Trans
          ns={OwstatsNS}
          i18nKey={OwstatsF('queries.noqueryresult')}
          >
            No results matched your query! <br/>
            Please adjust your paramaters and values or select different videos to apply your queries to.
          </Trans>
        </Typography>
        <EmptyStateImage url='no-query-result' />
      </FlexSpacer>
    </UnalignedContainer>
  );
}

export default React.memo(QueryResultNotFound);
