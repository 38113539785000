import { AsyncButton, EnhancedDialogTitle } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { VideoHelper } from 'features/video-library/video-helpers';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import { updateVideo2AC } from '../dashboard-video-slice';

interface RenameVideoDialogOwnProps {
  className?: string;
  onClose?: VoidFunction;
  video: VideoFragment;
}

type RenameVideoDialogProps = RenameVideoDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'RenameVideoDialog'});

function RenameVideoDialog(props: RenameVideoDialogProps) {
  const classes = useStyles(props);
  const { className, open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth='xs'>
      <RenameVideoDialogContent {...props} />
    </Dialog>
  );
}

function RenameVideoDialogContent(props: RenameVideoDialogProps) {
  const { onClose, className, video } = props;
  const { t } = useStrictTranslation(['common', 'dialog']);
  const [ videoName, setVideoName ] = useState(video.name);
  const [ loading, setLoading ] = useState(false);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (loading) { return; }
    setLoading(true);
    const name = videoName.trim();
    try {
      const teamId = VideoHelper.getTeamId(video);
      if (!teamId) {
        throw new Error('No team id.');
      }
      await promiseSagaDispatch(updateVideo2AC, {
        id: video.id,
        teamId,
        name,
      });
      enqueueSnackbar(t('dialog:renamevideo.success'), {variant: 'success'});
      onClose?.();
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
      setLoading(false);
    }
  }, [onClose, enqueueSnackbar, videoName, loading, t, video, promiseSagaDispatch]);

  const handleTextFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setVideoName(e.target.value);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <EnhancedDialogTitle onClose={onClose}>
        {t('dialog:renamevideo.title')}
      </EnhancedDialogTitle>
      <DialogContent>
        <TextField
        value={videoName}
        fullWidth={true}
        autoFocus={true}
        onChange={handleTextFieldChange}
        error={videoName.trim().length <= 0}
        />
      </DialogContent>
      <DialogActions>
        <Button
        type='button'
        variant='outlined'
        onClick={onClose}
        >
          {t('common:cancel')}
        </Button>
        <AsyncButton
        type='submit'
        variant='contained'
        color='primary'
        loading={loading}
        disabled={loading || videoName.trim().length <= 0}
        >
          {t('dialog:renamevideo.title')}
        </AsyncButton>
      </DialogActions>
    </form>
  );
}

export default React.memo(RenameVideoDialog);
