import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { partition } from '@insights-gaming/utils';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { createMultipleAnalysisRequestAC } from 'actions/video-actions';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import update from 'immutability-helper';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import actionCreatorFactory, { AnyAction, isType } from 'typescript-fsa';

import { useTeamAnalysisFeatures } from '../../../hooks/useFeatureSet';
import { CommonF, CommonNS } from '../../../locales/en/common';
import { DialogF } from '../../../locales/en/dialog';
import AnalysisTypeSelector from '../../../subcomponents/analysis-type-selector/AnalysisTypeSelector';
import { AnalysisType } from '../../../types/graphql';
import { ID, TeamEdge, Video } from '../../../types/pigeon';
import BetterDialogTitle from '../../better-dialog-title/BetterDialogTitle';
import Requirements from '../create-analysis-request-dialog-content/Requirements';

export interface ICreateMultipleAnalysisRequestsDialogContentOwnProps {
  teamEdge?: TeamEdge;
  videos: Video[];
  onClose?: VoidFunction;
  onAnalyze?: VoidFunction;
}

type Props = ICreateMultipleAnalysisRequestsDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  row: {
    justifyContent: 'space-between',
  },
  typography: {
    lineHeight: 'inherit',
    flex: 1,
  },
  reanalyzeButton: {
    whiteSpace: 'nowrap',
  },
}), {name: 'CreateMultipleAnalysisRequestsDialogContent'});

function CreateMultipleAnalysisRequestsDialogContent(props: Props) {
  const { onClose, onAnalyze, videos } = props;

  const [notAnalyzedVideos, analyzedVideos] = useMemo(() => partition(videos, v => !v.latestAnalysis), [videos]);

  const classes = useStyles(props);

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { t } = useTranslation([CommonNS]);
  const { enqueueSnackbar } = useSnackbar();

  const defaultAnalysisType = AnalysisType.OVERWATCH;

  const [ analysisTypesState, analysisTypeDispatch ] = useReducer(analysisTypesReducer, new Map());
  const [ reanalyzeVideosState, reanalyzeVideosDispatch ] = useReducer(reanalyzeReducer, new Set<ID>());

  const handleAnalysisTypeChange = useCallback((videoId: ID, newValue: AnalysisType) => {
    analysisTypeDispatch(changeAnalysisTypeAC({videoId, analysisType: newValue}));
  }, [analysisTypeDispatch]);

  const handleReanalyzeVideo = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    reanalyzeVideosDispatch(reanalyzeVideoAC({videoId: e.currentTarget.id}));
  }, []);

  const handleDontReanalyzeVideo = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    reanalyzeVideosDispatch(dontReanalyzeVideoAC({videoId: e.currentTarget.id}));
  }, []);

  const [ loading, setLoading ] = useState(false);

  const videosThatShouldBeAnalyzed = useMemo(() => [
    ...notAnalyzedVideos,
    ...analyzedVideos.filter(v => reanalyzeVideosState.has(v.id)),
  ], [analyzedVideos, notAnalyzedVideos, reanalyzeVideosState]);

  const handleAnalyzeVideos = useCallback(async () => {
    if (loading) { return; }
    setLoading(true);

    const analysisInput = videosThatShouldBeAnalyzed.map(v => ({
      videoId: v.id,
      type: analysisTypesState.get(v.id) || v.latestAnalysis?.type || defaultAnalysisType,
    }));
    try {
      const result = await promiseSagaDispatch(
        createMultipleAnalysisRequestAC,
        analysisInput,
      );
      if (Object.keys(result.failures).length > 0) {
        // TODO consider special handling of certain errors
        enqueueSnackbar(
          t(DialogF('analyze.failurecount'), {count: Object.keys(result.failures).length}),
          {variant: 'error'},
        );
      }
      if (result.successes.length > 0) {
        enqueueSnackbar(t(DialogF('analyze.successcount'), {count: result.successes.length}), {variant: 'success'});
      }
      onClose?.();
      onAnalyze?.();
    } catch {
      // TODO consider special handling of certain errors
    } finally {
      setLoading(false);
    }

  }, [
    loading,
    videosThatShouldBeAnalyzed,
    analysisTypesState,
    defaultAnalysisType,
    promiseSagaDispatch,
    onClose,
    onAnalyze,
    enqueueSnackbar,
    t,
  ]);

  const renderVideoName = useCallback((name: string) => (
    <Typography className={classes.typography} noWrap={true} title={name}>
      {name}
    </Typography>
  ), [classes.typography]);

  const analysisOptions = useTeamAnalysisFeatures();

  return (
    <React.Fragment>
      <BetterDialogTitle>
        {t(DialogF('analyze.title'), {count: videos.length})}
      </BetterDialogTitle>
      <DialogContent>
        <FlexSpacer
        orientation='vertical'
        >
          {notAnalyzedVideos.map(v => (
            <FlexSpacer key={v.id} className={classes.row} flexAlignItems='center' fullWidth={true}>
              {renderVideoName(v.name)}
              <AnalysisTypeSelector
              videoId={v.id}
              value={analysisTypesState.get(v.id) || v.latestAnalysis?.type || defaultAnalysisType}
              onChange={handleAnalysisTypeChange}
              options={analysisOptions}
              />
            </FlexSpacer>
          ))}
        </FlexSpacer>
        {analyzedVideos.length > 0 && (
          <Typography variant='h5'>
            {t(DialogF('analyze.alreadyanalyzed'), {count: analyzedVideos.length})}
          </Typography>
        )}
        <FlexSpacer
        orientation='vertical'
        >
          {analyzedVideos.map(v => (
            <FlexSpacer key={v.id} className={classes.row} flexAlignItems='center' fullWidth={true}>
              {renderVideoName(v.name)}
              {reanalyzeVideosState.has(v.id) ? (
                <FlexSpacer flexAlignItems='center'>
                  <AnalysisTypeSelector
                  videoId={v.id}
                  value={analysisTypesState.get(v.id) || v.latestAnalysis?.type || defaultAnalysisType}
                  onChange={handleAnalysisTypeChange}
                  options={analysisOptions}
                  />
                  <IconButton id={v.id} size='small' onClick={handleDontReanalyzeVideo}>
                    <CloseIcon />
                  </IconButton>
                </FlexSpacer>
              ) : (
                <Button id={v.id} className={classes.reanalyzeButton} onClick={handleReanalyzeVideo}>
                  {t(DialogF('reanalyze.confirm'))}
                </Button>
              )}
            </FlexSpacer>
          ))}
        </FlexSpacer>
        <Requirements />
      </DialogContent>
      <DialogActions>
        <Button
        variant='outlined'
        onClick={onClose}
        >
          {t(CommonF('cancel'))}
        </Button>
        <AsyncButton
        variant='contained'
        color='primary'
        loading={loading}
        disabled={loading || videosThatShouldBeAnalyzed.length === 0}
        onClick={handleAnalyzeVideos}
        >
          {t(DialogF('analyze.confirmcount'), {count: videosThatShouldBeAnalyzed.length})}
        </AsyncButton>
      </DialogActions>
    </React.Fragment>
  );
}

export default React.memo(CreateMultipleAnalysisRequestsDialogContent);

const actionCreator = actionCreatorFactory();
const changeAnalysisTypeAC = actionCreator<{videoId: ID, analysisType: AnalysisType}>('MULTI_ANALYSIS_CHANGE_TYPE');
function analysisTypesReducer(state: Map<ID, AnalysisType>, action: AnyAction) {
  if (isType(action, changeAnalysisTypeAC)) {
    const { videoId, analysisType } = action.payload;
    return update(state, {$add: [[videoId, analysisType]]});
  }
  return state;
}

const reanalyzeVideoAC = actionCreator<{videoId: ID}>('MULTI_ANALYSIS_REANALYZE_YES');
const dontReanalyzeVideoAC = actionCreator<{videoId: ID}>('MULTI_ANALYSIS_REANALYZE_NO');
function reanalyzeReducer(state: Set<ID>, action: AnyAction) {
  if (isType(action, reanalyzeVideoAC)) {
    return update(state, {$add: [action.payload.videoId]});
  }
  if (isType(action, dontReanalyzeVideoAC)) {
    return update(state, {$remove: [action.payload.videoId]});
  }
  return state;
}
