import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';

import { DragItem, ItemTypes } from '../dnd/item-types';
import { updateTeamOrderAC } from '../team/dashboard-team-slice';

export function useTeamCardDrop(
  teams: TeamFragment[],
  teamIndex: number,
  hoveredTeamIndex: number | undefined | null,
  setHoveredTeamIndex: ((hoveredTeamIndex: number) => void) | undefined | null,
) {
  const dispatch = useDispatch();
  const [insertIndex, setInsertIndex] = useState<number | null>(null);

  const showLines = useMemo(
    () => typeof insertIndex === 'number' && typeof hoveredTeamIndex === 'number' && hoveredTeamIndex === teamIndex,
    [hoveredTeamIndex, insertIndex, teamIndex],
  );

  const cardRef = useRef<HTMLDivElement | null>(null);

  const [, drop] = useDrop({
    accept: ItemTypes.TEAM_CARD,
    drop: useCallback((item: DragItem) => {
      if (item.type === ItemTypes.TEAM_CARD) {
        if (typeof insertIndex !== 'number') {
          return;
        }

        dispatch(updateTeamOrderAC.started({ targetId: item.team.id, destinationId: teams[insertIndex]?.id }));
      }
    }, [dispatch, insertIndex, teams]),
    hover: useCallback((_, monitor) => {
      setHoveredTeamIndex?.(teamIndex);
      if (!cardRef.current || !cardRef.current.parentElement) {
        return;
      }

      const clientOffsetY = monitor.getClientOffset()?.y;
      if (!clientOffsetY) {
        return;
      }

      const midY = (
        cardRef.current.offsetTop -
        cardRef.current.parentElement.scrollTop +
        (cardRef.current.offsetHeight / 2)
      );

      setInsertIndex(clientOffsetY < midY ? teamIndex - 1 : teamIndex);
    }, [setHoveredTeamIndex, teamIndex]),
  });

  return {
    drop: drop(cardRef),
    cardRef,
    showAbove: useMemo(() => showLines && insertIndex === teamIndex - 1, [insertIndex, showLines, teamIndex]),
    showBelow: useMemo(() => showLines && insertIndex === teamIndex, [insertIndex, showLines, teamIndex]),
  };
}

