import { Theme } from '@insights-gaming/theme';
import Grid from '@material-ui/core/Grid';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { TextFieldProps } from '@material-ui/core/TextField';
import React from 'react';

const styles = (theme: Theme) => {
  return createStyles({
    input: {
      flex: 1,
    },
  });
};

type StyleProps = WithStyles<typeof styles>;

export interface IIconTextFieldOwnProps {
  icon?: React.ReactNode;
}

type IconTextFieldProps = IIconTextFieldOwnProps & TextFieldProps & StyleProps;

class IconTextField extends React.Component<IconTextFieldProps> {
  public render() {
    const {
      classes,
      icon,
      ...rest
    } = this.props;
    return (
      <Grid
      container={true}
      spacing={1}
      alignItems='flex-end'
      >
        {icon &&
          <Grid item={true}>
            {icon}
          </Grid>
        }
        <Grid item={true} className={classes.input}>
          <TextField {...rest} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(IconTextField);
