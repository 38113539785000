import gql from 'graphql-tag';

export const CheckCreateTeamFolder_Mutation = gql`
    mutation CheckCreateTeamFolderMutation(
        $teamId: ID!
        $parentId: ID!
    ) {
        createFolder(
            input: {
                teamId: $teamId
                parentId: $parentId
                name: ""
            }
        ) {
            folder {
                id
            }
        }
    }
`;
