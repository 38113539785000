import { useMemo } from 'react';
import { ID } from 'types/pigeon';

import { DashboardMultiSelectState } from './DashboardMultiSelectContext';
import { useMultiSelectState } from './useMultiSelectState';

interface DashboardMultiStateOptions {
  initialFolders?: ID[];
  initialVideos?: ID[];
}

export function useDashboardMultiSelectContextValue(options?: DashboardMultiStateOptions): DashboardMultiSelectState {
  const { initialFolders, initialVideos } = options || {};

  const videos = useMultiSelectState(initialVideos);
  const folders = useMultiSelectState(initialFolders);

  return useMemo(() => ({
    videos,
    folders,
  } as const), [folders, videos]);
}
