import { createRemFromPx } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import DashboardRouting from 'features/dashboard/dashboard.routing';
import React from 'react';
import { Link } from 'react-router-dom';

interface DirectoryLinkButtonOwnProps {
  directoryName: string;
  directoryId: string;
  active?: boolean;
  canDrop?: boolean;
  isOver?: boolean;
}

type Props = DirectoryLinkButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'none',
    '&$active': {
      color: theme.palette.text.primary,
    },
    '&$isOver$canDrop': {
      backgroundColor: 'pink',
    },
  },
  directoryName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: createRemFromPx(400),
    fontWeight: theme.typography.h5.fontWeight,
    fontSize: theme.typography.h5.fontSize,
  },
  active: {},
  isOver: {},
  canDrop: {},
}), {name: 'DirectoryLinkButton'});

function DirectoryLinkButton(props: Props) {
  const { active, canDrop, directoryName, directoryId, isOver } = props;
  const classes = useStyles(props);
  return (
    <Button
    component={Link}
    to={DashboardRouting.createDirectoryUrl(directoryId)}
    className={classNames(
      classes.root,
      {
        [classes.active]: active,
        [classes.canDrop]: canDrop,
        [classes.isOver]: isOver,
      },
    )}
    disabled={active}
    >
      <Typography className={classes.directoryName}>
        {directoryName}
      </Typography>
    </Button>
  );
}

export default React.memo(DirectoryLinkButton);
