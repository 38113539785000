import { Theme } from '@insights-gaming/theme';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { AudioCommentSourceProvider, useCurrentAudioSource } from 'components/video/video-replay/video-comment/audio-comment/AudioCommentSourceContext';
import { ProvideVideoReplayContextValue } from 'features/video-replay/ProvideVideoReplayContextValue';
import AppContentBox from 'layout/app-content-wrapper/AppContentBox';
import AppContentWrapper from 'layout/app-content-wrapper/AppContentWrapper';
import React from 'react';
import { useSelector } from 'react-redux';
import { getIsTheaterMode } from 'selectors/video-player';

import ViewerLayoutHeader from './ViewerLayoutHeader';

interface ViewerLayoutOwnProps {
  backPath?: string;
  header?: React.ReactNode;
  children: React.ReactNode;
}

type ViewerLayoutProps = ViewerLayoutOwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    minHeight: 0,
  },
  maximized: {
    margin: 0,
  },
}), {name: 'ViewerLayout'});

function ViewerLayout(props: ViewerLayoutProps) {
  const { backPath, children, header } = props;
  const classes = useStyles(props);

  const theaterMode = useSelector(getIsTheaterMode);

  return (
    <div className={classes.root}>
      {!theaterMode && (
        <ViewerLayoutHeader backPath={backPath}>
          {header}
        </ViewerLayoutHeader>
      )}
      <AudioCommentSourceProvider value={useCurrentAudioSource()}>
        <AppContentWrapper className={classNames({ [classes.maximized]: theaterMode })}>
          <ProvideVideoReplayContextValue>
            <AppContentBox>
              {children}
            </AppContentBox>
          </ProvideVideoReplayContextValue>
        </AppContentWrapper>
      </AudioCommentSourceProvider>
    </div>
  );
}

export default React.memo(ViewerLayout);
