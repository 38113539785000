import { AsyncButton } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { UpdateStatsMutation_updateStats } from 'apollo/mutations/types/UpdateStatsMutation';
import update from 'immutability-helper';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { ResetStatsMutation_resetStats } from '../../../apollo/mutations/types/ResetStatsMutation';
import { CommonF, CommonNS } from '../../../locales/en/common';
import { DialogF, DialogNS } from '../../../locales/en/dialog';
import { ResetStatsInput, UpdateStatsInput, UpdateStatsPlayer } from '../../../types/graphql';
import { ID } from '../../../types/pigeon';
import { PrimaryRosterV2_Players } from '../../../types/pigeon/statistics';
import RosterCorrection from './RosterCorrection';

export interface ICreateCorrectionDialogContentOwnProps {
  stats: PrimaryRosterV2_Players[][];
  team: TeamFragment;
  videoIds : ID[];
  closeVerifyNameDialog: VoidFunction;
  openSubmittedVerifyNameDialog: VoidFunction;
}

export interface ICreateCorrectionDialogContentDispatch {
  onResetStats: (input: ResetStatsInput) => Promise<ResetStatsMutation_resetStats>;
  onUpdateStats: (input: UpdateStatsInput) => Promise<UpdateStatsMutation_updateStats>;
}

export type CreateCorrectionDialogContentProps = ICreateCorrectionDialogContentOwnProps &
  ICreateCorrectionDialogContentDispatch &
  WithTranslation;

interface IState {
  resetBox  : boolean;
  loading?  : boolean;
  newRosters: PrimaryRosterV2_Players[][];
}

class CreateCorrectionDialogContent extends React.Component<CreateCorrectionDialogContentProps, IState> {
  constructor(props: CreateCorrectionDialogContentProps) {
    super(props);

    this.state = {
      resetBox: false,
      newRosters: props.stats,
    };
  }

  public render() {
    const { loading, resetBox } = this.state;
    const { t, stats, closeVerifyNameDialog } = this.props;
    return (
      <form onSubmit={this.handleCreateCorrection}>
        <DialogTitle>
          {t(DialogF('editnames.editplayernames'))}
        </DialogTitle>
        <DialogContent>
          {stats.map(this.renderRosterCorrection)}
        </DialogContent>
        <DialogActions>
          <FormControlLabel
          control={
            <Checkbox
            checked={resetBox}
            value='reset'
            onChange={this.handleResetChange}
            color='primary'
            />
            }
          label={t(DialogF('editnames.revert'))}
          />
          <Button
          type='button'
          variant='outlined'
          onClick={closeVerifyNameDialog}
          >
            {t(CommonF('cancel'))}
          </Button>
          <AsyncButton
          variant='contained'
          color='primary'
          loading={loading}
          disabled={loading}
          type='submit'
          >
            {t(DialogF('editnames.save'))}
          </AsyncButton>
        </DialogActions>
      </form>
    );
  }

  private renderRosterCorrection = (
    roster: PrimaryRosterV2_Players[],
    i: number,
  ) => {
    return (
      <RosterCorrection
      key={i}
      roster={roster}
      rosterPosition={i}
      handleRosterChange={this.handleRosterOnChange}
      />
    );
  }

  private handleResetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({resetBox: e.currentTarget.checked});
  }

  private handleRosterOnChange = (
    newRoster: PrimaryRosterV2_Players[],
    rosterPosition: number,
  ) => {
    this.setState(state => update(state, {
      newRosters: {$splice: [[rosterPosition, 1, newRoster]]},
    }));
  }

  private handleCreateCorrection = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      stats,
      team,
      videoIds,
      onResetStats,
      onUpdateStats,
      closeVerifyNameDialog,
      openSubmittedVerifyNameDialog,
    } = this.props;
    const { loading, resetBox, newRosters } = this.state;
    if (loading) { return; }
    this.setState({loading: true});
    if (resetBox) {
      try {
        await onResetStats({
          teamId: team.id,
          vodIds: videoIds,
          reset: true,
        });
        closeVerifyNameDialog();
        openSubmittedVerifyNameDialog();
      } catch (error) {
        this.setState({loading: false});
      }
      return;
    }
    let playersIds: UpdateStatsPlayer[] = [];
    stats.forEach(
      (stat: PrimaryRosterV2_Players[], i: number) => {
        playersIds = playersIds.concat(this.getChangedPlayers(stat, newRosters[i]));
    });

    if (playersIds.length > 0) {
      const input: UpdateStatsInput =  {
        teamId : team.id,
        vodIds : videoIds,
        players: playersIds,
      };
      try {
        await onUpdateStats(input);
        closeVerifyNameDialog();
        openSubmittedVerifyNameDialog();
      } catch (error) {
        this.setState({loading: false});
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  private getChangedPlayers(
    teamStats: PrimaryRosterV2_Players[],
    newStats: PrimaryRosterV2_Players[],
  ) {
    const playersIds: UpdateStatsPlayer[] = [];
    for (const i in teamStats) {
      if (teamStats[i].playerId !== newStats[i].playerId) {
        let playerUpdate : UpdateStatsPlayer;
        if (newStats[i].playerId === 'other') {
          playerUpdate = {
            oldPlayerId : teamStats[i].playerId,
            newName     : newStats[i].playerName || '',
          };
        } else {
          const player = teamStats.find(stat => stat.playerId === newStats[i].playerId);
          if (player) {
            playerUpdate  = {
              oldPlayerId : teamStats[i].playerId,
              newPlayerId : newStats[i].playerId,
              newName     : player.playerName || '',
            };
          } else {
            playerUpdate  = {
              oldPlayerId : teamStats[i].playerId,
              newPlayerId : newStats[i].playerId,
              newName     : newStats[i].playerName || '',
            };
          }
        }
        playersIds.push(playerUpdate);
      }
    }
    return playersIds;
  }
}

export default withTranslation([DialogNS, CommonNS])(CreateCorrectionDialogContent);
