import { Theme } from '@insights-gaming/theme';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { updateUserNotificationSettingsAC } from 'components/settings/notification-settings/notification-settings-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import React, { useCallback, useMemo, useState } from 'react';
import { MuteLevel, NotificationType, UpdateUserNotificationSettingsInput } from 'types/graphql';
import { ID } from 'types/pigeon';

interface DialogTexts {
  title: string;
  description: string;
}

interface MuteNotificationDialogOwnProps {
  className?: string;
  onClose: VoidFunction;
  teamId: ID;
  channelId?: ID;
  muted: boolean;
}

type MuteNotificationDialogProps = MuteNotificationDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'MuteNotificationDialog'});

function MuteNotificationDialog(props: MuteNotificationDialogProps) {
  const classes = useStyles(props);
  const { className, open, onClose, muted, teamId, channelId } = props;
  const { t } = useStrictTranslation(['common', 'dialog']);

  const [ loading, setLoading ] = useState(false);

  const promiseSagaDispatch = usePromiseSagaDispatch();

  const handleMuteOrUnMute = useCallback(async () => {
    let inputs: UpdateUserNotificationSettingsInput[];
    if (loading) { return; }
    setLoading(true);
    if (muted) {
      inputs = Object.keys(NotificationType).map(n => ({
          level: MuteLevel.NONE,
          notificationType: NotificationType[n],
          teamId,
          override: false,
          folderId: channelId,
      }));
    } else {
      inputs = Object.keys(NotificationType).map(n => ({
        level: MuteLevel.ALL,
        notificationType: NotificationType[n],
        teamId,
        override: true,
        folderId: channelId,
      }));
    }
    try {
      await promiseSagaDispatch(updateUserNotificationSettingsAC, inputs);
      onClose();
    } catch(error) {
    } finally {
      setLoading(false);
    }
  }, [promiseSagaDispatch, muted, teamId, loading, onClose, channelId]);

  const dialogTexts: DialogTexts = useMemo(() => {
    if (channelId) {
      return {
        title: muted ? t('dialog:unmutenotification.unmutechannel'): t('dialog:mutenotification.mutechannel'),
        description: muted
          ? t('dialog:unmutenotification.unmutechannelnotice')
          : t('dialog:mutenotification.mutechannelnotice'),
      };
    } else {
      return {
        title: muted ? t('dialog:unmutenotification.unmuteteam'): t('dialog:mutenotification.muteteam'),
        description: muted
          ? t('dialog:unmutenotification.unmuteteamnotice')
          : t('dialog:mutenotification.muteteamnotice'),
      };
    }
  }, [muted, t, channelId]);

  return (
    <Dialog
    className={classNames(classes.root, className)}
    open={open}
    onClose={onClose}
    >
      <AlertDialogContent
      titleText={dialogTexts.title}
      description={dialogTexts.description}
      cancel={{
        text: t('common:cancel'),
        action: onClose,
      }}
      confirm={{
        text: t('common:confirm'),
        loading,
        disabled: loading,
        action: handleMuteOrUnMute,
      }}
      />
    </Dialog>
  );
}

export default React.memo(MuteNotificationDialog);
