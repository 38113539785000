import { useCreateSelector } from '@insights-gaming/redux-utils';
import { CommentFragment } from 'apollo/fragments/types/CommentFragment';
import { makeGetCommentReplyById, makeGetVideoCommentById } from 'components/video/video-replay/video-comment/video-comment-selector';
import { fetchCommentReplyByIdAC, fetchVideoCommentByIdAC } from 'components/video/video-replay/video-comment/video-comment-slice';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

export function useGetComment(videoId?: ID, commentId?: ID, replyId?: ID) {
  const dispatch = useDispatch();

  const [comment, commentFetched, commentFetching] = useCreateSelector(makeGetVideoCommentById, commentId);
  const [reply, replyFetched, replyFetching] = useCreateSelector(makeGetCommentReplyById, replyId);

  useEffect(() => {
    if (!videoId || !commentId || !replyId || replyFetched || replyFetching) {
      return;
    }

    dispatch(fetchCommentReplyByIdAC.started({ videoId, commentId, replyId }));
  }, [commentId, dispatch, replyFetched, replyFetching, replyId, videoId]);

  useEffect(() => {
    if (!videoId || !commentId || replyId || commentFetched || commentFetching) {
      return;
    }

    dispatch(fetchVideoCommentByIdAC.started({ videoId, commentId }));
  }, [commentFetched, commentFetching, commentId, dispatch, replyId, videoId]);

  return useMemo(
    (): [CommentFragment | undefined, boolean] => replyId ? [reply, replyFetched] : [comment, commentFetched],
    [comment, commentFetched, reply, replyFetched, replyId],
  );
}
