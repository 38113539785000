import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { useBooleanState } from '@insights-gaming/utils';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { SavedCardFragment } from 'apollo/fragments/types/SavedCardFragment';
import classNames from 'classnames';
import { addTeamCardAsyncAC, removeTeamCardAsyncAC, updateTeamCardAsyncAC } from 'features/dashboard/billing/dashboard-billing-slice';
import useTeamSubscription from 'features/dashboard/billing/useTeamSubscription';
import { mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useDialogState } from 'hooks/useDialogState';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { useSnackbar } from 'notistack';
import React, { ComponentProps, useCallback, useMemo, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  removeButton: {
    minWidth: createRemFromPx(105),
    height: createRemFromPx(30),
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.negative.main,
    color: theme.palette.negative.main,
  },
  chip: {
    [mobilePortrait(theme)]: {
      width: createRemFromPx(64),
    },
    [mobileLandscape(theme)]: {
      width: createRemFromPx(64),
    },
    marginRight: theme.spacing(2),
    '&$disabled': {
      opacity: 0.3,
    },
  },
  label: {
    textTransform: 'uppercase',
    [mobilePortrait(theme)]: {
      padding: theme.spacing(0, 0.5),
    },
    [mobileLandscape(theme)]: {
      padding: theme.spacing(0, 0.5),
    },
  },
  outlined: {
    borderColor: theme.palette.text.primary,
  },
  addCardWrapper: {
    margin: theme.spacing(1.5, 0),
  },
  disabled: {},
}), {name: 'usePaymentCardSelectorHandlers'});

function RemoveCardButton(
  { onClick, card, ...props }: Omit<ComponentProps<typeof Button>, 'onClick'> & {
    onClick: (card: SavedCardFragment) => void;
    card: SavedCardFragment;
  }) {
  return <Button {...props} onClick={useCallback(() => onClick(card), [card, onClick])} />;
}

export function usePaymentCardSelectorHandlers(
  teamId: string,
  teamCards?: SavedCardFragment[],
) {
  const classes = useStyles();
  const { t } = useStrictTranslation(['dashboard', 'common']);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [ isSwitchCardDialogOpen, openSwitchCardDialog, closeSwitchCardDialog ] = useDialogState();
  const [ isRemoveCardDialogOpen, openRemoveCardDialog, closeRemoveCardDialog ] = useDialogState();
  const [ showInputs, enableShowInputs, disableShowInputs ] = useBooleanState();

  const [ loading, setLoading ] = useState(false);
  const selectedCard = useMemo(() => teamCards?.find((card) => card.default), [teamCards]);
  const [ stagedCard, setStagedCard ] = useState<SavedCardFragment | undefined>();

  const [teamSubscription] = useTeamSubscription(teamId);
  const hasPremiumPlan = useMemo(() => !!teamSubscription?.active, [teamSubscription]);

  const handleCloseSwitchCardDialog = useCallback(() => {
    closeSwitchCardDialog();
    setStagedCard(undefined);
  }, [closeSwitchCardDialog]);

  const handleCloseRemoveCardDialog = useCallback(() => {
    closeRemoveCardDialog();
    setStagedCard(undefined);
  }, [closeRemoveCardDialog]);

  const handleEnableShowInputs = useCallback(() => {
    setStagedCard?.(undefined);
    enableShowInputs();
  }, [enableShowInputs, setStagedCard]);

  const handleAddCard = useCallback(
    async (paymentId: string) => {
      setLoading(true);

      try {
        await promiseSagaDispatch(addTeamCardAsyncAC, { default: true, paymentId, teamId});
        enqueueSnackbar(t('dashboard:billing.addnewcardsuccess'), {variant: 'success'});
      } catch (error) {
        enqueueSnackbar(t('dashboard:billing.addnewcardfailure'), {variant: 'error'});
      } finally {
        setLoading(false);
        disableShowInputs();
      }
    },
    [disableShowInputs, enqueueSnackbar, promiseSagaDispatch, t, teamId],
  );

  const handleChangeCard = useCallback((card: SavedCardFragment) => {
    disableShowInputs();
    if (card === selectedCard) {
      setStagedCard(undefined);
    } else if (card !== selectedCard) {
      setStagedCard(card);
      openSwitchCardDialog();
    }
  }, [disableShowInputs, openSwitchCardDialog, selectedCard]);

  const doChangeCard = useCallback(async () => {
    if (!stagedCard) {
      return;
    }

    setLoading(true);
    try {
      await promiseSagaDispatch(updateTeamCardAsyncAC, {default: true, teamId, cardId: stagedCard.id});
      enqueueSnackbar(t('dashboard:billing.updatedcardsuccess'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('dashboard:billing.updatedcardfailure'), {variant: 'error'});
    } finally {
      setLoading(false);
      handleCloseSwitchCardDialog();
      disableShowInputs();
    }
  }, [disableShowInputs, enqueueSnackbar, handleCloseSwitchCardDialog, promiseSagaDispatch, stagedCard, t, teamId]);

  const handleRemoveCard = useCallback((card: SavedCardFragment) => {
    setStagedCard(card);
    openRemoveCardDialog();
  }, [openRemoveCardDialog]);

  const doRemoveCard = useCallback(async () => {
    if (!stagedCard) {
      return;
    }

    setLoading(true);
    try {
      await promiseSagaDispatch(removeTeamCardAsyncAC, {teamId, cardId: stagedCard.id});
      enqueueSnackbar(t('dashboard:billing.removecardsuccess'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('dashboard:billing.removecardfailure'), {variant: 'error'});
    } finally {
      setLoading(false);
      handleCloseRemoveCardDialog();
    }
  }, [enqueueSnackbar, handleCloseRemoveCardDialog, promiseSagaDispatch, stagedCard, t, teamId]);

  const renderMobileCardDetails = useCallback((card: SavedCardFragment) => {
    const trim = (x: number | undefined) => {
      if (x) {
        let str = String(x);
        return str.substring(2);
      } else {
        return null;
      }
    };

    const handleOnClick = () => {
      handleChangeCard(card);
    };

    return (
      <Box display='flex' alignItems='center'>
        <Chip
        size='medium'
        variant='outlined'
        label={t('common:primary')}
        className={classNames(classes.chip, {[classes.disabled]: !card.default})}
        classes={{label: classes.label, outlined: classes.outlined}}
        onClick={handleOnClick}
        />
        <Box
        display='flex'
        justifyContent='flex-start'
        flexDirection='column'
        style={{ textTransform: 'capitalize' }}
        >
          <Typography variant='caption'>
            {t('dashboard:billing.cardending', { brand: card.brand, last4: card.last4 })}
          </Typography>
          <Typography variant='caption'>
            {t('dashboard:billing.expiresin', { month: card.month, year: trim(card.year) })}
          </Typography>
        </Box>
      </Box>
    );
  }, [classes.chip, classes.disabled, classes.label, classes.outlined, handleChangeCard, t]);

  const renderMobileAddCard = useCallback((showInputs: boolean) => {
    const toggleShowInputs = () => {
      if (showInputs) {
        disableShowInputs();
      } else {
        enableShowInputs();
      }
    };
    return (
      <Box display='flex' alignItems='center' className={classes.addCardWrapper} onClick={toggleShowInputs}>
        <Chip
        size='medium'
        variant='outlined'
        label={t('common:add')}
        className={classNames(classes.chip, {[classes.disabled]: true})}
        classes={{label: classes.label, outlined: classes.outlined}}
        />
        <Typography variant='caption'>
          {t('dashboard:billing.addpaymentcard')}
        </Typography>
      </Box>
    );
  }, [
    classes.addCardWrapper,
    classes.chip,
    classes.disabled,
    classes.label,
    classes.outlined,
    disableShowInputs,
    enableShowInputs,
    t,
  ]);

  const renderTail = useCallback((card: SavedCardFragment) => {
    if (!card.default) {
      return;
    }
    return (
      <Chip
      size='medium'
      variant='outlined'
      label={t('common:primary')}
      className={classes.chip}
      classes={{label: classes.label, outlined: classes.outlined}}
      />
    );
  }, [classes.chip, classes.label, classes.outlined, t]);

  const renderEnd = useCallback((card: SavedCardFragment) => {
    return (
      <Tooltip
      title={card.default && hasPremiumPlan ? t('dashboard:billing.removecardtooltip') : ''}
      placement='top'
      >
        <div>
          <RemoveCardButton
          card={card}
          onClick={handleRemoveCard}
          type='button'
          variant='outlined'
          size='small'
          className={classes.removeButton}
          disabled={card.default && hasPremiumPlan}
          >
            {t('dashboard:billing.removecard')}
          </RemoveCardButton>
        </div>
      </Tooltip>
    );
  }, [classes.removeButton, handleRemoveCard, hasPremiumPlan, t]);

  const switchCardDialog = useCallback(() => {
    return stagedCard ? (
      <Dialog open={isSwitchCardDialogOpen} onClose={handleCloseSwitchCardDialog}>
        <AlertDialogContent
        titleText={t('dashboard:billing.makeprimarytitle')}
        description={t(
          'dashboard:billing.makeprimarydesc',
          {card: t('dashboard:billing.cardending', { brand: stagedCard.brand, last4: stagedCard.last4 })},
        )}
        cancel={{
          text: t('common:cancel'),
          action: handleCloseSwitchCardDialog,
        }}
        confirm={{
          text: t('dashboard:billing.makeprimary'),
          loading,
          disabled: loading,
          action: doChangeCard,
        }}
        />
      </Dialog>
    ) : null;
  }, [doChangeCard, handleCloseSwitchCardDialog, isSwitchCardDialogOpen, loading, stagedCard, t]);

  const removeCardDialog = useCallback(() => {
    return stagedCard ? (
      <Dialog open={isRemoveCardDialogOpen} onClose={handleCloseRemoveCardDialog}>
        <AlertDialogContent
        titleText={t('dashboard:billing.removecard')}
        description={t('dashboard:billing.removecarddesc')}
        cancel={{
          text: t('common:cancel'),
          action: handleCloseRemoveCardDialog,
        }}
        confirm={{
          text: t('common:remove'),
          loading,
          disabled: loading,
          negative: true,
          action: doRemoveCard,
        }}
        />
      </Dialog>
    ) : null;
  }, [doRemoveCard, handleCloseRemoveCardDialog, isRemoveCardDialogOpen, loading, stagedCard, t]);

  return {
    selectedCard,
    stagedCard,
    handleAddCard,
    handleChangeCard,
    renderMobileCardDetails,
    renderMobileAddCard,
    renderTail,
    renderEnd,
    switchCardDialog,
    removeCardDialog,
    showInputs,
    handleEnableShowInputs,
    disableShowInputs,
  } as const;
}
