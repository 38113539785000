import { DirectoryFragment } from 'apollo/fragments';
import gql from 'graphql-tag';

export const CreateTeamDivision_Mutation = gql`
    mutation CreateTeamDivisionMutation(
        $input: CreateDivisionInput!
    ) {
        createDivision(
            input: $input
        ) {
            division {
                ...DirectoryFragment
            }
        }
    }
    ${DirectoryFragment}
`;
