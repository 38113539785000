import gql from 'graphql-tag';

import { MatchFragment } from '../fragments';

export const SetMatchMetadata_Mutation = gql`
    mutation SetMatchMetadataMutation(
        $input: SetMatchMetadataInput
    ) {
        setMatchMetadata(
            input: $input
        ) {
            match {
                ...MatchFragment
            }
        }
    }
    ${MatchFragment}
`;
