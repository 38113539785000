import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

import SearchCommentResultLayout from './SearchCommentResultLayout';

interface SearchCommentResultSkeletonOwnProps {
  className?: string;
}

type SearchCommentResultSkeletonProps = SearchCommentResultSkeletonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  full: {
    width: '100%',
  },
  half: {
    width: '50%',
  },
}), {name: 'SearchCommentResultSkeleton'});

function SearchCommentResultSkeleton(props: SearchCommentResultSkeletonProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <SearchCommentResultLayout
    text={(
      <React.Fragment>
        <Skeleton variant='text' className={classes.full}/>
        <Skeleton variant='text' className={classes.half}/>
      </React.Fragment>
    )}
    >
      <Skeleton variant='text' width={30}/>
      <Skeleton variant='text' width={60}/>
      <Skeleton variant='text' width={30}/>
      <Skeleton variant='text' width={30}/>
    </SearchCommentResultLayout>
  );
}

export default React.memo(SearchCommentResultSkeleton);
