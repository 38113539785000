import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import LinkIcon from '@material-ui/icons/Link';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

interface CopyLinkOwnProps {
  className?: string;
  textToCopy: string;
  id?: string;
  hideLabel?: boolean;
  iconOnly?: boolean;
  textOnly?: boolean
}

type CopyLinkProps = CopyLinkOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto max-content',
    gridGap: theme.spacing(1),
  },
}), {name: 'CopyLink'});

function CopyLink(props: CopyLinkProps) {
  const classes = useStyles(props);
  const { className, id, textToCopy, hideLabel, iconOnly, textOnly } = props;
  const { t } = useStrictTranslation(['common']);
  const [copied, setCopied] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timer>();

  const handleCopyOnClick = useCallback((e) => {
    e.preventDefault();
    if (timer) {
      clearTimeout(timer);
      setTimer(undefined);
    }
    if (!copied) {
      setCopied(true);
    }
    setTimer(setTimeout(() => setCopied(false), 1000));
  }, [copied, timer]);

  const renderButtonVersion = useCallback(() => {
    return copied ? (
      <Button
      startIcon={<CheckIcon />}
      variant='contained'
      onClick={handleCopyOnClick}
      color='primary'
      size='small'
      >
        {t('common:copied')}
      </Button>
    ) : (
      <CopyToClipboard text={textToCopy}>
        <Button
        variant='contained'
        onClick={handleCopyOnClick}
        color='primary'
        startIcon={<LinkIcon />}
        size='small'
        >
          {t('common:copylink')}
        </Button>
      </CopyToClipboard>
    );
  }, [copied, handleCopyOnClick, t, textToCopy]);

  return (
    <div className={classNames(classes.root, className)} id={id}>
      {!hideLabel && (
        <TextField
        value={textToCopy}
        InputProps={{readOnly: true}}
        />
      )}
      {iconOnly ? (
        <Tooltip title={copied ? t('common:copied') : t('common:copylink')} placement='top'>
          <CopyToClipboard text={textToCopy}>
            <IconButton
            size='small'
            onClick={handleCopyOnClick}
            >
              <Link
              href={textToCopy}
              color='inherit'
              style={{lineHeight: 0}}
              >
                <LinkIcon />
              </Link>
            </IconButton>
          </CopyToClipboard>
        </Tooltip>
      ) : textOnly ? (
        <Tooltip title={copied ? t('common:copied') : t('common:copylink')} placement='top'>
          <CopyToClipboard text={textToCopy}>
            <Typography
            onClick={handleCopyOnClick}
            >
              <Link
              href={textToCopy}
              color='inherit'
              style={{lineHeight: 0}}
              >
                {textToCopy}
              </Link>
            </Typography>
          </CopyToClipboard>
        </Tooltip>
      ) : (
        renderButtonVersion()
      )}
    </div>
  );
}

export default React.memo(CopyLink);
