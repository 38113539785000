import { isExistent } from 'helpers';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';

export const getGlobalRolesDict = (state: RootReducer) => state.globalRole.globalRoleDict;

const getGlobalRoleRecords = (state: RootReducer) => state.globalRole.globalRoleRecords;

export const makeGetGlobalRoleRecords = () => createSelector(
  [getGlobalRoleRecords],
  (records) => records,
);

export const makeGetGlobalRoles = () => createSelector(
  [getGlobalRolesDict],
  (dict) => Object.keys(dict).map(key => dict[key]).filter(isExistent),
);
