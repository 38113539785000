import { FlexSpacer, Loader } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { checkInView, fuzzyMatch } from '@insights-gaming/utils';
import ButtonBase from '@material-ui/core/ButtonBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { compareTwoStrings } from 'string-similarity';
import { getStickerTabSelection, setStickerTabSelectionAC } from 'subcomponents/video-player-3/drawing-tools-2/drawing-tool-slice';

import { desktop } from '../../../features/media-queries';
import { useIsDesktop } from '../../../features/media-queries/hooks';
import { createCdnSearchParams, defaultIconOptions } from '../../../helpers';
import { compareNumberDescending } from '../../../helpers/comparators';
import { createStickerfySearchParams, makeCdnImagePathMagical } from '../../../helpers/image-cdn/mahou';
import { calcGridIndex, calcGridRowCol } from '../../../helpers/math';
import { TVideoplayer, VideoplayerF, VideoplayerNS } from '../../../locales/en/videoplayer';
import { preload } from '../../../serviceworker/mahou/helper';
import CircleColorPicker from '../../../subcomponents/circle-color-picker/CircleColorPicker';
import SuspenseLoader from '../../../subcomponents/suspense-loader/SuspenseLoader';
import IconTextField from '../../icon-text-field/IconTextField';
import UndraggableAvatar from '../../undraggable-avatar/UndraggableAvatar';

export interface IImagePickerMenuContentOwnProps {
  stickerfy?: boolean;
  categories: CategoryInput[];
  onImageSelected?: (r: ImageRecord) => void;
  stickersPerRow?: number;
}

type Props = IImagePickerMenuContentOwnProps;

const blue   = '#2F98F4';
const red    = '#FF5159';
const yellow = '#F5BE34';
const grey   = '#C4C4C4';
const colors = [ blue, red, yellow, grey ];

const defaultStickersPerRow = 9;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minWidth: 'calc(100vw - 32px)',
    height: 400,
    maxHeight: 400,
    padding: theme.spacing(1, 1.5),
    [desktop(theme)]: {
      minWidth: ({stickersPerRow = defaultStickersPerRow}: Props) =>
        stickersPerRow * (32 + theme.spacing(1) * 2) + theme.spacing(1.5) * 2,
    },
    '&& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
  results: {
    position: 'relative',
    overflow: 'auto',
  },
  categoryTitle: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: ({stickersPerRow = defaultStickersPerRow}: Props) => `repeat(${stickersPerRow}, 1fr)`,
  },
  button: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    '&$selected': {
      backgroundColor: 'hotpink',
    },
  },
  selected: {},
  imageCategorySelect: {minWidth: '200px'},
}), {name: 'ImagePickerMenuContent'});

function addParams(url: string, params: URLSearchParams | undefined): string {
  return url + (url.includes('?') ? '&' : '?') + params;
}

const preloadedURLs = new Set<string>();

function unloadedURLs(urls: string[]): string[] {
  return urls.filter((url) => !preloadedURLs.has(url));
}

function addPreloadedURLs(urls: string[]) {
  for (const url of urls) {
    preloadedURLs.add(url);
  }
}

const cachedMahouParams: Record<string, URLSearchParams> = Object.fromEntries(colors.map(
  (color) => [color, createStickerfySearchParams({color})],
));

const cachedBlobURLs: Record<string, string> = {};
const checkForCachedBlobUrl = (url: string) => cachedBlobURLs[url] || url;

function ImagePickerMenuContent(props: Props, ref: React.RefObject<HTMLDivElement>) {
  const {
    categories,
    onImageSelected,
    stickerfy,
    stickersPerRow = defaultStickersPerRow,
  } = props;
  const classes = useStyles(props);

  const dispatch = useDispatch();

  const namespaces = useMemo(() => categories.map(c => c.tNamespace).concat(VideoplayerNS), [categories]);
  const { t, i18n } = useTranslation(namespaces);
  const [ value, setValue ] = useState('');
  const [ preloaded, setPreloaded ] = useState(false);
  const valueRef = useRef(value);
  valueRef.current = value;
  const [ hasNeedle, setHasNeedle ] = useState(false);

  const [ stickerStrokeColor, setStickerStrokeColor ] = useState(blue);

  const mahouParams = useMemo(
    () => stickerfy ? cachedMahouParams[stickerStrokeColor] : undefined,
    [stickerStrokeColor, stickerfy],
  );

  const isDesktop = useIsDesktop();

  const baseCategories = useMemo(() => {
    const defaultParams = createCdnSearchParams(defaultIconOptions);
    const smParams = createCdnSearchParams({...defaultIconOptions, width: 32, height: 32});
    const xsParams = createCdnSearchParams({...defaultIconOptions, width: 24, height: 24});
    const fullParams = createCdnSearchParams({...defaultIconOptions, width: undefined, height: undefined});
    return categories.map(({
      keyBuilder,
      categoryId,
      categoryName,
      cdnPathBuilder,
      haystack,
      tKeyBuilder,
    }): CategoryOutput => ({
      categoryId,
      categoryName,
      haystack: haystack.map(target => {
        let full = cdnPathBuilder(target);
        if (stickerfy) {
          full = makeCdnImagePathMagical(full, {mahou: 'stickerfy'});
        }
        return {
          key: keyBuilder(target),
          target,
          translated: t(tKeyBuilder(target)),
          image: {
            default: full + '?' + defaultParams,
            xs: full + '?' + xsParams,
            sm: full + '?' + smParams,
            full: full + '?' + fullParams,
          },
        };
      }),
    }));
  }, [categories, stickerfy, t]);

  useEffect(() => {
    const baseURLs = baseCategories.flatMap(
      ({ haystack }): string[] => haystack.map(({ image }): string => image.sm),
    );

    const unloaded = unloadedURLs(baseURLs);
    setPreloaded(!unloaded.length);

    if (unloaded.length) {
      (async () => {
        try {
          await preload(unloaded.flatMap((url) => colors.map(((color) => addParams(url, cachedMahouParams[color])))));
          addPreloadedURLs(unloaded);
        } catch {
          // ignore error from optional preload step
        }

        setPreloaded(true);
      })();
    }
  }, [baseCategories]);

  const processedCategories = useMemo(() => {
    return preloaded ? baseCategories.map(category => ({
      ...category,
      haystack: category.haystack.map(target => ({
        ...target,
        image: Object.fromEntries(Object.entries(target.image).map(
          ([key, value]) => [key, window.location.origin + addParams(value, mahouParams)],
        )) as ImageRecord['image'],
      })),
    })) : [];
  }, [mahouParams, baseCategories, preloaded]);

  const allCategories = useMemo(
    () => processedCategories.reduce<ImageRecord[]>((p, c) => p.concat(c.haystack), []),
    [processedCategories],
  );

  const haystack = useMemo(() => allCategories, [allCategories]);
  const [ matches, setMatches ] = useState<CategoryOutput>();
  const renderedCategories = useMemo(
    () => !hasNeedle
      ? processedCategories
      : matches
        ? [matches]
        : undefined,
    [hasNeedle, matches, processedCategories],
  );

  const [ currentCat, setCurrentCat ] = useState(0);
  const [ currentRow, setCurrentRow ] = useState(0);
  const [ currentCol, setCurrentCol ] = useState(0);
  const [ displaySelected, setDisplaySelected ] = useState(false);
  const calcIndex = useMemo(() => calcGridIndex(stickersPerRow), [stickersPerRow]);
  const calcRowCol = useMemo(() => calcGridRowCol(stickersPerRow), [stickersPerRow]);
  const currentIndex = useMemo(() => calcIndex(currentRow, currentCol), [calcIndex, currentRow, currentCol]);

  const resultsRef = useRef<HTMLDivElement>(null);
  const scrollModeRef = useRef(false);
  const scrollToTopNext = useRef(false);
  const autoScrollRef = useRef(false);
  const disableMouseLeave = useRef(false);
  const setScrollModeUp = useCallback(() => {
    scrollModeRef.current = true;
  }, []);
  const setScrollModeDown = useCallback(() => {
    scrollModeRef.current = false;
  }, []);

  const handleEnter = useCallback(() => {
    if (renderedCategories) {
      const category = renderedCategories[currentCat];
      if (category) {
        const selected = category.haystack[currentIndex];
        if (selected) {
          onImageSelected?.(selected);
        }
      }
    }
  }, [currentCat, currentIndex, onImageSelected, renderedCategories]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!renderedCategories) { return; }
    const currentCategory = renderedCategories[currentCat];
    switch (e.key) {
      case 'Enter':
        handleEnter();
        break;
      case 'ArrowUp': {
        const r = currentRow - 1;
        const n = calcIndex(r, currentCol);
        if (currentCategory.haystack[n]) {
          setScrollModeUp();
          setCurrentRow(r);
        } else {
          // prev category
          let p = currentCat - 1;
          setScrollModeUp();
          if (p < 0) {
            p += renderedCategories.length;
            setScrollModeDown();
          }
          const c = renderedCategories[p];
          const [lastRow, lastCol] = calcRowCol(c.haystack.length - 1);
          setCurrentCat(p);
          setCurrentRow(lastRow);
          setCurrentCol(Math.min(currentCol, lastCol));
        }
        break;
      }
      case 'ArrowLeft': {
        let c = currentCol - 1;
        const n = calcIndex(currentRow, c);
        if (currentCategory.haystack[n]) {
          if (c < 0) {
            c += stickersPerRow;
            setCurrentRow(currentRow - 1);
          }
          setScrollModeUp();
          setCurrentCol(c);
        } else {
          // prev category
          let p = currentCat - 1;
          setScrollModeUp();
          if (p < 0) {
            p += renderedCategories.length;
            setScrollModeDown();
          }
          const c = renderedCategories[p];
          const [lastRow, lastCol] = calcRowCol(c.haystack.length - 1);
          setCurrentCat(p);
          setCurrentRow(lastRow);
          setCurrentCol(lastCol);
        }
        break;
      }
      case 'ArrowDown': {
        const r = currentRow + 1;
        const n = calcIndex(r, currentCol);
        if (currentCategory.haystack[n]) {
          setScrollModeDown();
          setCurrentRow(r);
        } else {
          // next category
          let p = currentCat + 1;
          setScrollModeDown();
          if (p >= renderedCategories.length) {
            p = 0;
            setScrollModeUp();
          }
          const c = renderedCategories[p];
          setCurrentCat(p);
          setCurrentRow(0);
          setCurrentCol(Math.min(currentCol, c.haystack.length - 1));
        }
        break;
      }
      case 'ArrowRight': {
        let c = currentCol + 1;
        const n = calcIndex(currentRow, c);
        if (currentCategory.haystack[n]) {
          const cc = c % stickersPerRow;
          if (cc !== c) {
            c = cc;
            setCurrentRow(currentRow + 1);
          }
          setScrollModeDown();
          setCurrentCol(c);
        } else {
          // next category
          let p = currentCat + 1;
          setScrollModeDown();
          if (p >= renderedCategories.length) {
            p = 0;
            setScrollModeUp();
          }
          setCurrentCat(p);
          setCurrentRow(0);
          setCurrentCol(0);
        }
        break;
      }
      default:
        return;
    }
    autoScrollRef.current = true;
    disableMouseLeave.current = true;
    if (!displaySelected) {
      // scrollToTopNext.current = true; // commenting out seems to fix the up arrow scroll?
      setDisplaySelected(true);
      setCurrentCat(currentCat);
      setCurrentRow(0);
      setCurrentCol(0);
    }
    e.preventDefault();
    e.stopPropagation();
  }, [
    calcIndex,
    calcRowCol,
    currentCat,
    currentCol,
    currentRow,
    displaySelected,
    handleEnter,
    renderedCategories,
    setScrollModeDown,
    setScrollModeUp,
    stickersPerRow,
  ]);

  const handleValueChanged = useCallback((value: string) => {
    setValue(value);
    const needle = value.trim();
    setHasNeedle(!!needle);
    setCurrentCat(0);
    setCurrentRow(0);
    setCurrentCol(0);
    disableMouseLeave.current = true;
    if (!needle) {
      setMatches(undefined);
      setDisplaySelected(false);
      return;
    }
    const filtered = haystack.filter(record => fuzzyMatch(needle, record.translated));
    if (filtered.length === 0) {
      setMatches(undefined);
      setDisplaySelected(false);
      return;
    }
    const scored = filtered.map(record => ({
      ...record,
      rating: compareTwoStrings(needle, record.translated),
    }));
    const sorted = scored.sort((a, b) => compareNumberDescending(a.rating, b.rating));
    setMatches({
      categoryId: 'results',
      categoryName: 'Search Results',
      haystack: sorted,
    });
    setDisplaySelected(true);
  }, [haystack]);

  useEffect(() => handleValueChanged(valueRef.current), [handleValueChanged, stickerStrokeColor]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleValueChanged(e.target.value);
  }, [handleValueChanged]);

  const handleAutoScrollRef = useCallback((el: HTMLButtonElement | null) => {
    if (el && autoScrollRef.current && resultsRef.current) {
      if (!checkInView(resultsRef.current, el)) {
        el.scrollIntoView(scrollModeRef.current || scrollToTopNext.current);
        if (scrollModeRef.current || scrollToTopNext.current) {
          resultsRef.current.scrollBy(0, -24);
          scrollToTopNext.current = false;
        }
      }
    }
  }, []);

  const handleImageMouseMove = useCallback((category: number, imageIndex: number) => {
    const [row, col] = calcRowCol(imageIndex);
    autoScrollRef.current = false;
    setDisplaySelected(true);
    setCurrentCat(category);
    setCurrentRow(row);
    setCurrentCol(col);
    disableMouseLeave.current = false;
  }, [calcRowCol]);

  const handleImageMouseLeave = useCallback(() => {
    if (!disableMouseLeave.current) {
      setDisplaySelected(false);
    }
  }, []);

  const renderRecordGrid = useCallback((records: ImageRecord[], category: number) => (
    <div className={classes.grid}>
      {records.map((record, j) => {
        const selected = displaySelected && category === currentCat && j === currentIndex;
        return (
          <ImageHelper
          ref={selected ? handleAutoScrollRef : undefined}
          key={record.key}
          className={classNames(classes.button, {
            [classes.selected]: selected},
          )}
          record={record}
          category={category}
          imageIndex={j}
          onImageSelected={onImageSelected}
          onImageMouseMove={handleImageMouseMove}
          onImageMouseLeave={handleImageMouseLeave}
          />
        );
      })}
    </div>
  ), [
    classes.button,
    classes.grid,
    classes.selected,
    currentCat,
    currentIndex,
    displaySelected,
    handleImageMouseMove,
    handleImageMouseLeave,
    handleAutoScrollRef,
    onImageSelected,
  ]);

  const tab = useSelector(getStickerTabSelection);
  const categoryLabelRefs = useRef<Array<HTMLElement | null>>([]);
  const [pastInitRender, setPastInitRender] = useState(false);

  useEffect(() => {
    categoryLabelRefs.current = categoryLabelRefs.current.slice(0, categories.length);
    setPastInitRender(true);
  }, [categories.length, tab]);

  const handleLabelRef = useCallback((i: number) => (el: HTMLElement) => {
    categoryLabelRefs.current[i] = el;
  }, []);

  const tabClickedRef = useRef(false);

  const handleImageCategoryChange = useCallback((e: React.ChangeEvent<{name?: string; value: number}>) => {
    tabClickedRef.current = true;
    handleValueChanged('');
    dispatch(setStickerTabSelectionAC(e.target.value));
    setCurrentCat(e.target.value);
  }, [dispatch, handleValueChanged]);

  useEffect(() => {
    if (tabClickedRef.current) {
      if (resultsRef.current) {
        // GIGAHACK: make it so it scrolls up correctly instead of having only the sticky header in view
        resultsRef.current.scrollTo(0, 0);
      }
      categoryLabelRefs.current[tab]?.scrollIntoView(true);
      tabClickedRef.current = false;
    }
  }, [tab]);

  useEffect(() => {
    if (categoryLabelRefs.current && pastInitRender) {
      categoryLabelRefs.current[tab]?.scrollIntoView(true);
    }
  }, [pastInitRender, tab]);

  const renderSelectButton = useCallback((category: CategoryOutput, i: number) => (
    <MenuItem key={category.categoryId} value={i}>{category.categoryName}</MenuItem>
  ), []);

  const renderCategory = useCallback((category: CategoryOutput, i: number) => (
    <div key={category.categoryId}>
      <Typography className={classes.categoryTitle} ref={handleLabelRef(i)}>
        {category.categoryName}
      </Typography>
      {renderRecordGrid(category.haystack, i)}
    </div>
  ), [classes.categoryTitle, handleLabelRef, renderRecordGrid]);

  const handleScroll = useCallback(() => {
    if (resultsRef.current) {
      const container = resultsRef.current;
      const i = categoryLabelRefs.current.findIndex((el: HTMLElement) => checkInView(container, el));
      if (i >= 0) {
        dispatch(setStickerTabSelectionAC(i));
      }
    }
  }, [dispatch]);

  return preloaded ? (
    <div ref={ref} className={classes.root} onKeyDown={handleKeyDown}>
      <FlexSpacer flexAlignItems='center'>
        <Select
        className={classes.imageCategorySelect}
        value={tab}
        onChange={handleImageCategoryChange}
        >
          {processedCategories.map(renderSelectButton)}
        </Select>
        <IconTextField
        autoFocus={true}
        icon={<SearchIcon />}
        fullWidth={true}
        value={value}
        onChange={onChange}
        />
      </FlexSpacer>
      {stickerfy && (
        <CircleColorPicker colors={colors} onColorChanged={setStickerStrokeColor} />
      )}
      <div ref={resultsRef} className={classes.results} onScroll={handleScroll}>
        {renderedCategories ? (
          renderedCategories.map(renderCategory)
        ) : (
          'NO MATCHES'
        )}
      </div>
    </div>
  ) : (
    <div ref={ref} className={classes.root}>
      <SuspenseLoader delay={200}>
        <Loader>
          {Array.from({length: 5}).map((_, i) => t(VideoplayerF(`stickers.loadinghints.${i}` as TVideoplayer)))}
        </Loader>
      </SuspenseLoader>
    </div>
  );
}

export default React.memo(React.forwardRef(ImagePickerMenuContent));

interface ImageHelperProps {
  className?: string;
  record: ImageRecord;
  category: number;
  imageIndex: number;
  onImageMouseMove?: (category: number, imageIndex: number) => void;
  onImageMouseLeave?: VoidFunction;
  onImageSelected?: (record: ImageRecord) => void;
}
function _ImageHelper(props: ImageHelperProps, ref: React.RefObject<HTMLButtonElement>) {
  const { category, className, imageIndex, record, onImageSelected, onImageMouseMove, onImageMouseLeave } = props;
  const onClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    onImageSelected?.(record);
  }, [onImageSelected, record]);
  const onMouseMove = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    onImageMouseMove?.(category, imageIndex);
  }, [category, imageIndex, onImageMouseMove]);
  const onMouseLeave = useCallback(() => {
    onImageMouseLeave?.();
  }, [onImageMouseLeave]);
  const handleKeyUp = useCallback((e: React.KeyboardEvent<HTMLButtonElement>) => {
    e.preventDefault();
  }, []);
  return (
    <ButtonBase
    key={record.key}
    ref={ref}
    className={className}
    onClick={onClick}
    onMouseMove={onMouseMove}
    onMouseLeave={onMouseLeave}
    onKeyUp={handleKeyUp}
    title={record.translated}
    >
      <UndraggableAvatar size='sm' src={record.image.sm} />
    </ButtonBase>
  );
}
const ImageHelper = React.memo(React.forwardRef(_ImageHelper));

interface _Category {
  categoryId: string;
  categoryName: string;
}

export interface CategoryInput<T extends string = string> extends _Category {
  keyBuilder: (s: string) => string;
  haystack: T[];
  tNamespace: string;
  tKeyBuilder: (t: T) => string;
  cdnPathBuilder: (t: T) => string;
}

interface CategoryOutput extends _Category {
  haystack: ImageRecord[];
}

export interface ImageRecord {
  key       : string;
  target    : string;
  translated: string;
  image     : {
    default: string;
    xs: string;
    sm: string;
    full: string;
  };
}
