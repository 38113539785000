import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LabelIcon from '@material-ui/icons/Label';
import ModeCommentIcon from '@material-ui/icons/ModeComment';
import SearchIcon from '@material-ui/icons/Search';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import { MAX_NOTIFICATIONS } from 'components/settings/notifications/constants';
import { Time } from 'components/time/Time';
import { DATE_FORMAT } from 'constants/strings';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback } from 'react';

interface VideoCardContentOwnProps {
  tags: TagFragment[];
  hasAnalysis?: boolean;
  numOfVideoCommentNotfications: number;
  owner: string;
  hasComments?: boolean;
  createdDate: any;
}

type VideoCardContentProps = VideoCardContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tag: {
    whiteSpace: 'nowrap',
    margin: theme.spacing(0, 1),
  },
  customWidth: {
    maxWidth: 360,
  },
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1, 0),
  },
  owner: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}), {name: 'VideoCardContent'});

function VideoCardContent(props: VideoCardContentProps) {
  const classes = useStyles(props);
  const { hasAnalysis, owner, hasComments, createdDate, tags, numOfVideoCommentNotfications } = props;
  const { t } = useStrictTranslation(['common']);
  const renderVideoTagsTitle = useCallback(() => {
    return (
      <div className={classes.flexWrap}>
        {tags.map(tag => {
          return (
            <FlexSpacer key={tag.id} flexAlignItems='center' className={classes.tag}>
              <LabelIcon htmlColor={tag.color}/>
              <Typography align='center'>{tag.name}</Typography>
            </FlexSpacer>
          );
        })}
      </div>
    );
  },[classes.flexWrap, classes.tag, tags]);

  return (
    <Box className={classes.root}>
      <Typography variant='caption' className={classes.owner}>{owner}</Typography>
      <Box className={classes.detail}>
        <Typography variant='caption'>
          <Time format={DATE_FORMAT}>{createdDate}</Time>
        </Typography>
        <FlexSpacer flexAlignItems='center' spacing={0.5}>
          {!!tags.length && (
            <Tooltip title={renderVideoTagsTitle()} placement='bottom' classes={{ tooltip: classes.customWidth }}>
              <LabelIcon
              fontSize='small'
              titleAccess={t('common:tagged')}
              style={{ color: tags.length > 1 ? 'inherit' : tags[0].color }}
              />
            </Tooltip>
          )}
          {hasAnalysis && <SearchIcon fontSize='small' titleAccess={t('common:analyzed')}/>}
          {hasComments && (
            <Badge badgeContent={numOfVideoCommentNotfications} color='error' max={MAX_NOTIFICATIONS}>
              <ModeCommentIcon fontSize='small' titleAccess={t('common:commented')}/>
            </Badge>
          )}
        </FlexSpacer>
      </Box>
    </Box>
  );
}

export default React.memo(VideoCardContent);
