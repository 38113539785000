import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Radio from '@material-ui/core/Radio';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

export interface CardDetailsLayoutOwnProps {
  className?: string;
  checked?: boolean;
  onClick?: VoidFunction;
}

export type CardDetailsLayoutProps = React.PropsWithChildren<CardDetailsLayoutOwnProps>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexBasis: '70%',
    margin: theme.spacing(1.5, 0),
  },
  cardInfo: {
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.background.note,
    borderWidth: 1,
    borderStyle: 'solid',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '&&:hover': {
      borderColor: theme.palette.primary.main,
    },
    cursor: 'pointer',
  },
  selectedCard: {
    borderColor: theme.palette.primary.main,
  },
  radio: {
    color: theme.palette.background.note,
  },
  disabledClick: {
    pointerEvents: 'none',
  },
  selected: {
    color: `${theme.palette.primary.main} !important`,
  },
  details: {
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}), {name: 'CardDetailsLayout'});

function CardDetailsLayout(props: CardDetailsLayoutProps) {
  const classes = useStyles(props);
  const { className, checked, onClick } = props;

  return (
    <FlexSpacer
    orientation='vertical'
    flexAlignItems='flex-end'
    className={classNames(classes.root, className, {[classes.disabledClick]: checked})}
    onClick={checked ? undefined : onClick}
    >
      <div className={classNames(classes.cardInfo, {[classes.selectedCard]: checked})}>
        <Radio
        checked={checked}
        disabled={checked}
        classes={{root: classNames(classes.radio, classes.disabledClick), disabled: classes.selected}}
        />
        <div className={classes.details}>{props.children}</div>
      </div>
    </FlexSpacer>
  );
}

export default React.memo(CardDetailsLayout);
