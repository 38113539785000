import { AsyncButton } from '@insights-gaming/material-components/AsyncButton';
import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useState } from 'react';

interface SavePasskeyNameDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
  handleSave: (name: string | undefined) => Promise<void>;
  loading: boolean;
  defaultName?: string;
}

type SavePasskeyNameDialogProps = SavePasskeyNameDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'SavePasskeyNameDialog'});

function SavePasskeyNameDialog(props: SavePasskeyNameDialogProps) {
  const classes = useStyles(props);
  const { className, open, onClose, handleSave, loading } = props;
  const { t } = useStrictTranslation(['common', 'dialog']);

  const [name, setName] = useState<string | undefined>(props.defaultName);

  const saveOnClick = useCallback(async () => {
    if (!name) {
      return;
    }
    await handleSave(name);
    setName(props.defaultName);
  }, [handleSave, name, props.defaultName]);

  const handleChange = useCallback((e: React.ChangeEvent) => {
    setName((e.target as HTMLInputElement).value);
  }, [setName]);

  const handleClose = useCallback(() => {
    setName(props.defaultName);
    onClose();
  }, [onClose, props.defaultName]);

  const handleFocus = useCallback((e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.select();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='xs'>
      <DialogTitle>
        <Typography variant='h3'>
          {t('dialog:savepasskey.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
        value={name}
        fullWidth={true}
        autoFocus={true}
        onChange={handleChange}
        // eslint-disable-next-line react/jsx-no-bind
        onFocus={handleFocus}
        />
      </DialogContent>
      <DialogActions>
        <AsyncButton
        type='button'
        variant='outlined'
        onClick={handleClose}
        >
          {t('common:cancel')}
        </AsyncButton>
        <AsyncButton
        variant='contained'
        color='primary'
        onClick={saveOnClick}
        loading={loading}
        disabled={loading}
        >
          {t('common:save')}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(SavePasskeyNameDialog);
