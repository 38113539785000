import { AvatarWithLetterFallback, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { MAX_FILE_SIZE } from 'constants/numbers';
import { EIntercomID } from 'constants/strings';
import { mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import HiddenFileInput from 'subcomponents/hidden-file-input/HiddenFileInput';

interface ProfilePictureOwnProps {
  className?: string;
  name: string;
  picture: string | null;
  loading?: boolean;
  onPictureUpdate: (file: File) => void;
  renderInfo: () => React.ReactChild | null;
  disabled: boolean;
  outlined?: boolean;
}

type ProfilePictureProps = ProfilePictureOwnProps;

const INPUT_ID = 'profilepic';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    [mobilePortrait(theme)]: {
      width: '100%',
    },
    [mobileLandscape(theme)]: {
      width: '100%',
    },
  },
  mobileProfile: {
    position: 'relative',
  },
  mobileUploadPhoto: {
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(-1),
    backgroundColor: theme.palette.background.note,
    borderRadius: createRemFromPx(4),
    '&:hover': {
      backgroundColor: theme.palette.background.note,
    },
  },
  loadingBox: {
    height: '150px',
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}), {name: 'ProfilePicture'});

function ProfilePicture(props: ProfilePictureProps) {
  const classes = useStyles(props);
  const { className, name, picture, disabled, onPictureUpdate, renderInfo, loading, outlined } = props;

  const { t } = useStrictTranslation(['common', 'settings']);
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useIsDesktop();

  const handleFileChange = useCallback((event: any) => {
    if (loading) { return; }
    const file: File = event.target.files[0];
    if (!file) { return; }

    if (file.size > MAX_FILE_SIZE) {
      enqueueSnackbar(t('common:picexceedsizelimit', {bytes: MAX_FILE_SIZE}), {variant: 'warning'});
      return;
    }

    onPictureUpdate(file);
  }, [enqueueSnackbar, loading, onPictureUpdate, t]);

  return (
    <div className={classNames(classes.root, className)}>
      <Box display='flex' flexDirection={isDesktop ? 'row' : 'column'}>
        <FlexSpacer fullWidth={!isDesktop} flexJustifyContent='center'>
          <FlexSpacer orientation='vertical' flexJustifyContent='center'>
            <AvatarWithLetterFallback name={name} src={picture} size='xxl' />
            {!isDesktop && (
              <FlexSpacer fullWidth={true} flexJustifyContent='center'>
                <HiddenFileInput
                id={INPUT_ID}
                accept='image/*'
                trigger={(
                  <Button
                  id={EIntercomID.UPLOAD_PHOTO}
                  component='label'
                  htmlFor={INPUT_ID}
                  disabled={disabled}
                  color='default'
                  variant={outlined ? 'outlined' : 'contained'}
                  >
                    {t('settings:uploadphoto')}
                  </Button>
                )}
                onChange={handleFileChange}
                />
              </FlexSpacer>
            )}
          </FlexSpacer>
        </FlexSpacer>
        <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-around'
        ml={isDesktop ? 2 : 0}
        width={createRemFromPx(200)}
        >
          {renderInfo()}
          {isDesktop && (
            <HiddenFileInput
            id={INPUT_ID}
            accept='image/*'
            trigger={(
                // TODO: Change color of the button upon request?
              <Button
              id={EIntercomID.UPLOAD_PHOTO}
              component='label'
              htmlFor={INPUT_ID}
              disabled={disabled}
              color='default'
              variant={outlined ? 'outlined' : 'contained'}
              >
                {t('settings:uploadphoto')}
              </Button>
            )}
            onChange={handleFileChange}
            />
          )}
        </Box>
      </Box>
    </div>
  );
}

export default React.memo(ProfilePicture);
