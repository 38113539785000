import { ID } from 'types/pigeon';
import actionCreatorFactory from 'typescript-fsa';

const name = 'telemetry';

const actionCreator = actionCreatorFactory(name);

export const telemetry = {
  videoBuffering: actionCreator<{
    videoId: ID;
    position: number;
  }>('VIDEO_BUFFERING'),
} as const;
