import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import { PropsFor, spacing } from '@material-ui/system';
import classNames from 'classnames';
import React from 'react';

export interface IFloatingTextOwnProps {
  className? : string;
  horizontal?: 'left'/*  | 'center' */ | 'right';
  vertical?  : 'top'/*  | 'center'  */| 'bottom';
}

export type FloatingTextProps = IFloatingTextOwnProps &
  PropsFor<typeof spacing> &
  WithStyles<typeof styles>;

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    backgroundColor: theme.palette.background.dimmed,
    zIndex: 1,
    padding: theme.spacing(.5),
    borderRadius: theme.shape.borderRadius,
  },
  left: {
    left: 0,
  },
  right: {
    right: 0,
  },
  top: {
    top: 0,
  },
  bottom: {
    bottom: 0,
  },
});

class FloatingText extends React.Component<FloatingTextProps> {
  public render() {
    const { className, classes, vertical, horizontal, ...rest } = this.props;
    return (
      <Box
      className={classNames(
        classes.root,
        className,
        horizontal && classes[horizontal],
        vertical && classes[vertical],
      )}
      {...rest}
      >
        {this.props.children}
      </Box>
    );
  }
}

export default withStyles(styles)(FloatingText);
