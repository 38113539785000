import React, { useContext, useEffect,useRef } from 'react';

import { LoadingContext } from './context';

function ShowLoadingScreen() {
  const controller = useContext(LoadingContext);

  const ref = useRef(controller);

  useEffect(() => {
    ref.current = controller;
  }, [controller]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    return ref.current.auto();
  }, []);

  return null;
}

export default React.memo(ShowLoadingScreen);
