import { CreateInvitation_Mutation } from 'apollo/mutations';
import { GetInvitations_Query } from 'apollo/queries';
import { createInvitationAC } from 'features/dashboard/team/dashboard-team-slice';
import { watchAsyncMutation, watchBidirectionalAC2 } from 'helpers/saga/effects';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { makeGetTeamInvitationRecordsByTeamId } from './dashboard-invitation.selector';
import { fetchTeamInvitationsAC } from './dashboard-invitation-slice';

export default function* dashboardInvitationSaga() {
  yield all([
    watchBidirectionalAC2(fetchTeamInvitationsAC, GetInvitations_Query, ['queryInvitations']),
    watchAsyncMutation(createInvitationAC, CreateInvitation_Mutation, ['createInvitation']),

    takeEvery(createInvitationAC.done, function* ({ payload: { params: { teamId } } }) {
      try {
        // backward fetch to get the newly created invitation.
        const selector: ReturnType<typeof makeGetTeamInvitationRecordsByTeamId> = yield call(
          makeGetTeamInvitationRecordsByTeamId,
        );
        const teamInvitationRecords: ReturnType<typeof selector> = yield select(selector, teamId);
        const cursor = teamInvitationRecords?.backward?.cursor;
        if (cursor) {
          yield put(fetchTeamInvitationsAC.backward.started({teamId, before: cursor, limit: 2}));
        }
      } catch {
        // do nothing
      }
    }),
  ]);
}
