import gql from 'graphql-tag';

export const DeleteComment_Mutation = gql`
    mutation DeleteCommentMutation(
        $input: DeleteCommentInput!
    ) {
        deleteComment(
            input: $input
        ) {
            commentId
        }
    }
`;
