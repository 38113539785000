import { Theme } from '@insights-gaming/theme';
import { amber, green } from '@material-ui/core/colors';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import classNames from 'classnames';
import { VariantType } from 'notistack';
import React from 'react';

interface IMessageProps {
  variant?     : VariantType;
  message      : React.ReactNode;
  IconOverride?: React.ComponentType<SvgIconProps>;
}

export type MessageProps = IMessageProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {

  },
  default: {
    backgroundColor: theme.palette.background.paper,
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: .9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningRoundedIcon,
  error  : ErrorIcon,
  info   : InfoIcon,
};

export default function Message(props: MessageProps) {
  const { variant = 'default', message, IconOverride } = props;
  const classes = useStyles();
  const Icon = IconOverride || variantIcon[variant];
  return (
    <SnackbarContent
    className={classNames(classes.root, classes[variant])}
    message={(
      <span className={classes.message}>
        {Icon && <Icon className={classNames(classes.icon, classes.iconVariant)} />}
        {message}
      </span>
    )}
    />
  );
}
