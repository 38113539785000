import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

import DirectoryCard from './DirectoryCard';

interface DirectoryCardDragPreviewOwnProps {
  className?: string;
  name: string;
}

type DirectoryCardDragPreviewProps = DirectoryCardDragPreviewOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DirectoryCardDragPreview'});

function DirectoryCardDragPreview(props: DirectoryCardDragPreviewProps) {
  const classes = useStyles(props);
  const { className, name } = props;
  return (
    <DirectoryCard className={classNames(classes.root, className)} name={name} disabled={true} />
  );
};

export default React.memo(DirectoryCardDragPreview);
