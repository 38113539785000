import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme, WithMakeStyles } from '@insights-gaming/theme';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { updateVideoCommentAC } from 'actions/comment-actions';
import { CommentFragment } from 'apollo/fragments/types/CommentFragment';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { usePopupStateKeybindingHelper } from 'hooks/usePopupStateKeybindingHelper';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ID } from 'types/pigeon';

import CommentLabelListItem from '../comment-label-list-item/CommentLabelListItem';
import LabelButton from './LabelButton';

interface AppliedCommentLabelOwnProps {
  className?: string;
  appliedCommentLabel: TagFragment;
  commentLabels: TagFragment[];
  comment: CommentFragment;
  openLabelManagerDialog: VoidFunction;
  videoId: ID;
  isRangedTimestamp?: boolean;
  isExtraLabel?: boolean;
  isAnnotated?: boolean;
  labelCount?: number;
}

type AppliedCommentLabelProps = AppliedCommentLabelOwnProps & WithMakeStyles<typeof useStyles>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  labelMenu: {
    maxWidth: theme.spacing(25),
  },
}), {name: 'AppliedCommentLabel'});

function AppliedCommentLabel(props: AppliedCommentLabelProps) {
  const classes = useStyles(props);
  const {
    className,
    appliedCommentLabel,
    commentLabels,
    comment,
    videoId,
    openLabelManagerDialog,
    isRangedTimestamp,
    isExtraLabel,
    isAnnotated,
    labelCount,
  } = props;

  const { canUpdateVideoComment, canRemoveCommentLabel, canAssignCommentLabel} = useAccessControl();

  const { t } = useStrictTranslation(['video']);
  const popupState = usePopupState({popupId: 'change-label', variant: 'popover'});
  usePopupStateKeybindingHelper(popupState, 'menu.open');
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [ loading, setLoading ] = useState(false);

  const canUpdateOwnComment = canUpdateVideoComment && comment.user.__typename === 'Self';

  const canAssignOwnCommentLabel = canUpdateOwnComment && canAssignCommentLabel;

  const canRemoveOwnCommentLabel = canUpdateOwnComment && canRemoveCommentLabel;

  const canAssignAndRemoveOwnCommentLabel = canAssignOwnCommentLabel && canRemoveCommentLabel;

  const isMountedRef = useRef(true);

  useEffect(() => () => {
    isMountedRef.current = false;
  }, []);

  const handleChangeLabel = useCallback(async (label: TagFragment) => {
    if (loading) { return; }
    popupState.close();
    setLoading(true);
    try {
      await promiseSagaDispatch(updateVideoCommentAC, {
        commentId: comment.id,
        videoId,
        addTagIds: [label.id],
        removeTagIds: [appliedCommentLabel.id],
      });
      enqueueSnackbar(t('video:commentlabel.updatesuccess'), {variant: 'success'});
    } catch(error) {
      enqueueSnackbar(t('video:commentlabel.updatefail'), {variant: 'error'});
    } finally {
      if (isMountedRef.current) {
        setLoading(false);
      }
    }
  }, [
    appliedCommentLabel,
    popupState,
    enqueueSnackbar,
    t,
    videoId,
    comment,
    loading,
    promiseSagaDispatch,
  ]);

  const handleRemoveLabel = useCallback(async (label: TagFragment) => {
    if (loading) { return; }
    popupState.close();
    setLoading(true);
    try {
      await promiseSagaDispatch(updateVideoCommentAC, {
        commentId: comment.id,
        videoId,
        removeTagIds: [label.id],
      });
      enqueueSnackbar(t('video:commentlabel.updatesuccess'), {variant: 'success'});
    } catch(error) {
      enqueueSnackbar(t('video:commentlabel.updatefail'), {variant: 'error'});
    } finally {
      if (isMountedRef.current) {
        setLoading(false);
      }
    }
  }, [
    popupState,
    enqueueSnackbar,
    t,
    videoId,
    comment,
    loading,
    promiseSagaDispatch,
  ]);

  return (
    <FlexSpacer flexAlignItems='center' className={classNames(classes.root, className)}>
      {appliedCommentLabel && (
        <LabelButton
        loading={loading}
        appliedCommentLabel={appliedCommentLabel}
        removeLabelOnClick={handleRemoveLabel}
        canRemoveOwnCommentLabel={canRemoveOwnCommentLabel}
        onClick={canAssignAndRemoveOwnCommentLabel
          ? bindTrigger(popupState).onClick
          : undefined
        }
        isRangedTimestamp={isRangedTimestamp}
        isExtraLabel={isExtraLabel}
        isAnnotated={isAnnotated}
        labelCount={labelCount}
        />
      )}
      <Menu
      {...bindMenu(popupState)}
      classes={{
        paper: classes.labelMenu,
      }}
      >
        {commentLabels.map((c) => (
          <CommentLabelListItem
          key={c.id}
          label={c}
          handleLabelOnChange={handleChangeLabel}
          />
        ))}
        <MenuItem onClick={openLabelManagerDialog}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={t('video:commentlabel.managelabels')} />
        </MenuItem>
      </Menu>
    </FlexSpacer>
  );
}

export default React.memo(AppliedCommentLabel);
