import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FloatingText from 'material/floating-text/FloatingText';
import React from 'react';

interface CircularOwnProps {
  progress: number;
}

type CircularProps = CircularOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  percentageText: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}), {name: 'Circular'});

function Circular(props: CircularProps) {
  const classes = useStyles(props);
  const { progress } = props;

  return (
    <FloatingText>
      <CircularProgress variant='static' value={progress} />
      <Box position='absolute' className={classes.percentageText}>
        <div>
          {Math.floor(progress)}%
        </div>
      </Box>
    </FloatingText>
  );
}

export default React.memo(Circular);
