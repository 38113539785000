import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { updateVideoCommentAC } from 'actions/comment-actions';
import { CommentFragment } from 'apollo/fragments/types/CommentFragment';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { MAX_LABEL_TO_SHOW } from 'constants/numbers';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { makeGetTeamCommentLabelsByTeamId } from 'features/dashboard/tag/dashboard-tag-selector';
import TagManagerDialog from 'features/dashboard/tag/tag-manager-dialog/TagManagerDialog';
import { VideoHelper } from 'features/video-library/video-helpers';
import { useDialogState } from 'hooks/useDialogState';
import { usePopupStateKeybindingHelper } from 'hooks/usePopupStateKeybindingHelper';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { bindHover, bindMenu, bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import Popover from 'material-ui-popup-state/HoverPopover';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { makeGetCommentTagIdsByCommentId } from '../../video-comment-selector';
import CommentLabelListItem from '../comment-label-list-item/CommentLabelListItem';
import AddLabelButton from './AddLabelButton';
import AppliedCommentLabel from './AppliedCommentLabel';

interface AppliedCommentLabelsOwnProps {
  className?: string;
  comment: CommentFragment;
  video: VideoFragment;
  isAnnotated?: boolean;
  isRangedTimestamp?: boolean;
}

type AppliedCommentLabelsProps = AppliedCommentLabelsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  hideButton: {},
  labelMenu: {
    maxWidth: theme.spacing(25),
  },
  moreLabelGrid: {
    padding: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: theme.spacing(0.5),
  },
}), {name: 'AppliedCommentLabels'});

function AppliedCommentLabels(props: AppliedCommentLabelsProps) {
  const classes = useStyles(props);

  const { className, comment, video, isAnnotated, isRangedTimestamp } = props;
  const { t } = useStrictTranslation(['common', 'video']);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const popupState = usePopupState({popupId: 'add-label', variant: 'popover'});
  const moreLabelsPopupState = usePopupState({popupId: 'more-labels', variant: 'popover'});
  usePopupStateKeybindingHelper(popupState, 'menu.open');

  const { canAssignCommentLabel, canUpdateVideoComment } = useAccessControl();

  const [ isLabelManagerDialogOpen, openLabelManagerDialog, closeLabelManagerDialog] = useDialogState();

  const teamId = VideoHelper.getTeamId(video);
  const [ loading, setLoading ] = useState(false);

  const teamCommentLabels = useCreateSelector(makeGetTeamCommentLabelsByTeamId, teamId);
  const appliedCommentLabelsIds = useCreateSelector(makeGetCommentTagIdsByCommentId, {
    commentId: comment.id,
  });
  const appliedCommentLabels = useMemo(() => {
    return teamCommentLabels.filter(cl => appliedCommentLabelsIds.includes(cl.id));
  }, [teamCommentLabels, appliedCommentLabelsIds]);

  const filteredTeamCommentLabels = useMemo(() => {
    return teamCommentLabels.filter(cl => !appliedCommentLabelsIds.includes(cl.id));
  }, [teamCommentLabels, appliedCommentLabelsIds]);

  const canUpdateOwnComment = canUpdateVideoComment && comment.user.__typename === 'Self';

  const canAssignOwnCommentLabel = canUpdateOwnComment && canAssignCommentLabel;

  const handleAddLabel = useCallback(async (label: TagFragment) => {
    if (loading) { return; }
    popupState.close();
    setLoading(true);
    try {
      await promiseSagaDispatch(updateVideoCommentAC, {
        commentId: comment.id,
        videoId: video.id,
        addTagIds: [label.id],
      });
      enqueueSnackbar(t('video:commentlabel.updatesuccess'), {variant: 'success'});
    } catch(error) {
      enqueueSnackbar(t('video:commentlabel.updatefail'), {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [
    popupState,
    enqueueSnackbar,
    t,
    video,
    comment,
    loading,
    promiseSagaDispatch,
  ]);

  const manageLabelOnClick = useCallback(() => {
    popupState.close();
    openLabelManagerDialog();
  }, [openLabelManagerDialog, popupState]);

  useEffect(() => {
    // If no more labels in more label popover, close it. Popover stays open if all labels removed while mouse
    // is still over the popover. The popover shrinks and mouse leave is not triggered.
    if (appliedCommentLabels.length <= (isAnnotated ? MAX_LABEL_TO_SHOW - 1 : MAX_LABEL_TO_SHOW)) {
      moreLabelsPopupState.close();
    }
  }, [appliedCommentLabels.length, isAnnotated, moreLabelsPopupState]);

  return (
    <React.Fragment>
      {appliedCommentLabels.map((l, i) => i < (isAnnotated ? MAX_LABEL_TO_SHOW - 1 : MAX_LABEL_TO_SHOW) && (
        <AppliedCommentLabel
        key={l.id}
        videoId={video.id}
        appliedCommentLabel={l}
        commentLabels={filteredTeamCommentLabels}
        comment={comment}
        openLabelManagerDialog={openLabelManagerDialog}
        isRangedTimestamp={isRangedTimestamp}
        isAnnotated={isAnnotated}
        labelCount={appliedCommentLabels.length}
        />
      ))}
      {appliedCommentLabels.length > (isAnnotated ? MAX_LABEL_TO_SHOW - 1 : MAX_LABEL_TO_SHOW) && (
        <Typography
        variant='caption'
        {...bindHover(moreLabelsPopupState)}
        >
          {t('common:extranumbernotext', {
            number: appliedCommentLabels.length - (isAnnotated ? MAX_LABEL_TO_SHOW - 1 : MAX_LABEL_TO_SHOW),
          })}
        </Typography>
      )}
      <Popover
      {...bindPopover(moreLabelsPopupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      >
        <div className={classes.moreLabelGrid}>
          {appliedCommentLabels.map((l, i) => i > (isAnnotated ? MAX_LABEL_TO_SHOW - 2 : MAX_LABEL_TO_SHOW - 1) && (
            <AppliedCommentLabel
            key={l.id}
            videoId={video.id}
            appliedCommentLabel={l}
            commentLabels={filteredTeamCommentLabels}
            comment={comment}
            openLabelManagerDialog={openLabelManagerDialog}
            isExtraLabel={true}
            />
          ))}
        </div>
      </Popover>
      {canAssignOwnCommentLabel && (
        <AddLabelButton
        classes={{
          hideButton: className,
        }}
        onClick={bindTrigger(popupState).onClick}
        loading={loading}
        />
      )}
      <Menu
      {...bindMenu(popupState)}
      classes={{
        paper: classes.labelMenu,
      }}
      >
        {filteredTeamCommentLabels.map((c) => (
          <CommentLabelListItem
          key={c.id}
          label={c}
          handleLabelOnChange={handleAddLabel}
          />
        ))}
        <MenuItem onClick={manageLabelOnClick}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={t('video:commentlabel.managelabels')} />
        </MenuItem>
      </Menu>
      {teamId && (
        <TagManagerDialog
        open={isLabelManagerDialogOpen}
        teamId={teamId}
        tags={teamCommentLabels}
        onClose={closeLabelManagerDialog}
        isLabel={true}
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(AppliedCommentLabels);
