import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  GetMembersQuery_queryMembers_edges,
  GetMembersQuery_queryMembers_edges_user,
} from '../../apollo/queries/types/GetMembersQuery';
import { placeholder } from '../../assets';
import UndraggableAvatar from '../../material/undraggable-avatar/UndraggableAvatar';

export interface IMyLinkOwnProps {
  href: string;
  rel?: 'nofollow';
  members?: GetMembersQuery_queryMembers_edges[];
}

export type IMyLinkProps = IMyLinkOwnProps;

interface IState {
  anchorEl: HTMLElement | null;
}

export default class MyLink extends React.Component<IMyLinkProps, IState> {
  constructor(props: IMyLinkProps) {
    super(props);
    this.state = { anchorEl: null };
  }

  public get user(): GetMembersQuery_queryMembers_edges_user | undefined {
    const { children, members } = this.props;
    if (!children || !members) { return undefined; }
    const result = members.find((member: GetMembersQuery_queryMembers_edges) => member.user.id === children[1]);
    if (result) { return result.user; }
    return undefined;
  }

  public render() {
    const { children, href } = this.props;
    if (children && children[0] === '@') {
      return this.renderMentionLink();
    }
    if (href.indexOf('?t=') === 0) {
      return this.renderInternalLink();
    }
    return this.renderExternalLink();
  }

  private renderExternalLink = () => {
    const { children, href } = this.props;
    return (
      <a href={href} target='_blank' rel='noopener noreferrer'>{children}</a>
    );
  }

  private renderInternalLink = () => {
    const { children, href } = this.props;
    return (
      <Link to={href}>
        {children}
      </Link>
    );
  }

  private renderMentionLink = () => {
    const { children, href } = this.props;
    const { anchorEl } = this.state;
    const user: GetMembersQuery_queryMembers_edges_user | undefined = this.user;
    const open: boolean = !!anchorEl;
    let inner = children;
    let src: string | null = '';
    if (user) {
      inner = [ '@', user.alias ];
      src = user.picture;
    }

    return (
      <React.Fragment>
        <Link
        onMouseEnter={this.handlePopoverOpen}
        onMouseLeave={this.handlePopoverClose}
        // TODO: enable this when we have actual profile pages
        // to={href}
        to='#'
        >
          {inner}
        </Link>
        <Popover
        id='mouse-over-popover'
        open={open}
        anchorEl={anchorEl}
        style={{ pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={this.handlePopoverClose}
        >
          <Box display='flex' p={1}>
            <Box mr={1}>
              <UndraggableAvatar src={src || placeholder} />
            </Box>
            <Box display='flex' alignItems='center'>{user!.alias}</Box>
          </Box>
        </Popover>
      </React.Fragment>
    );
  }

  private handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  private handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  }
}
