import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import classNames from 'classnames';
import CopyInviteLinkButton from 'components/video/CopyInviteLinkButton';
import { getIsFreshLiveSession, getLiveSessionChatMembers, getLiveSessionMessages, getLiveSessionToken } from 'features/live-session/live-session-selector';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import clamp from 'lodash/clamp';
import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { joinSessionRoute } from 'routes';
import { ID } from 'types/pigeon';

import ChatlogView from './ChatlogView';
import ChatMemberList from './ChatMemberList';

interface ChatPanelOwnProps {
  className?: string;
  teamId: ID | undefined;
}

type ChatPanelProps = ChatPanelOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  participants: {
    textTransform: 'uppercase',
  },
  grabbing: {
    cursor: 'grabbing',
  },
  draggingIcon: {
    display: 'none',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '&$showDragIcon': {
      display: 'block',
    },
  },
  inviteButton: {
    height: '100%',
  },
  showDragIcon: {},
}), {name: 'ChatPanel'});

function ChatPanel(props: ChatPanelProps) {
  const classes = useStyles(props);
  const { className, teamId } = props;
  const { t } = useStrictTranslation(['common', 'video']);

  const chatMembers = useSelector(getLiveSessionChatMembers);

  const panelRef = useRef<HTMLDivElement | null>(null);
  const accordionRef = useRef<HTMLDivElement | null>(null);
  const detailsRef = useRef<HTMLDivElement | null>(null);
  const iconRef = useRef<HTMLDivElement | null>(null);
  const [grabbing, setGrabbing] = useState(false);
  const [showDragIcon, setShowDragIcon] = useState(false);
  const [expand, setExpand] = useState(true);

  const messages = useSelector(getLiveSessionMessages);
  const sessionToken = useSelector(getLiveSessionToken);
  const chatIsEmpty = useSelector(getIsFreshLiveSession);


  const toggleAccordion = useCallback(() => {
    setExpand((prev) => !prev);
  }, []);

  const handleScrollIntoView = useCallback(
    (el: HTMLElement) => el.scrollIntoView({behavior: 'smooth', block: 'center'}),
    [],
  );

  const onMouseMove = useCallback(({ clientY: currentY }: React.MouseEvent<HTMLDivElement>) => {
    if (!detailsRef.current || !iconRef.current || !accordionRef.current) {
      return;
    }

    const accordionRect = accordionRef.current.getBoundingClientRect();
    const detailsRect = detailsRef.current.getBoundingClientRect();

    if (currentY < detailsRect.bottom - 20 || currentY > detailsRect.bottom + 20 || !expand) {
      setShowDragIcon(false);
      return;
    }

    setShowDragIcon(true);
    iconRef.current.style.top = accordionRect.height + 3 + 'px';
  }, [expand]);

  const onMouseDown = useCallback(({ clientY: startY }: React.MouseEvent<HTMLDivElement>) => {
    if (!detailsRef.current || !iconRef.current || !panelRef.current) {
      return;
    }

    const panelRect = panelRef.current.getBoundingClientRect();
    const detailsRect = detailsRef.current.getBoundingClientRect();

    if (startY < detailsRect.bottom - 20 || startY > detailsRect.bottom + 20) {
      return;
    }

    const dragHandler = ({ clientY: currentY }: MouseEvent) => {
      if (!detailsRef.current || !iconRef.current) {
        return;
      }

      setGrabbing(true);

      const detailsHeight = clamp(detailsRect.height + (currentY - detailsRect.bottom), 50, panelRect.height * 0.5);

      detailsRef.current.style.height = detailsHeight + 'px';
      iconRef.current.style.top = detailsHeight + 52 + 'px';
    };

    const releaseHandler = (e: MouseEvent) => {
      e.preventDefault();

      setGrabbing(false);

      document.removeEventListener('mousemove', dragHandler);
      document.removeEventListener('mouseup', releaseHandler);
    };

    document.addEventListener('mousemove', dragHandler);
    document.addEventListener('mouseup', releaseHandler);
  }, []);

  return (
    <div
    ref={panelRef}
    className={classNames(classes.wrapper, {[classes.grabbing]: grabbing})}
    onMouseMove={onMouseMove}
    >
      <Accordion
      ref={accordionRef}
      TransitionProps={{onEntered: handleScrollIntoView}}
      expanded={expand}
      >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        onClick={toggleAccordion}
        >
          <FlexSpacer flexJustifyContent='space-between' flexAlignItems='center' fullWidth={true}>
            <FlexSpacer flexAlignItems='center'>
              <PeopleAltIcon />
              <Typography className={classes.participants}>
                {t('common:session.participant', { count: 1 + chatMembers.length })}
              </Typography>
            </FlexSpacer>
            {!chatIsEmpty && messages.length > 0 && sessionToken && (
              <Toolbar variant='dense' disableGutters={true}>
                <CopyInviteLinkButton
                link={window.location.origin + joinSessionRoute(sessionToken)}
                className={classes.inviteButton}
                />
              </Toolbar>
            )}
          </FlexSpacer>
        </AccordionSummary>
        <AccordionDetails
        ref={detailsRef}
        >
          <ChatMemberList
          className={classNames(classes.root, className)}
          teamId={teamId}
          />
        </AccordionDetails>
      </Accordion>
      <div
      ref={iconRef}
      className={classNames(classes.draggingIcon, {[classes.showDragIcon]: showDragIcon || grabbing})}
      onMouseDown={onMouseDown}
      >
        <DragHandleIcon fontSize='large'/>
      </div>
      <ChatlogView
      className={classNames(classes.root, className)}
      />
    </div>
  );
}

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
    overflow: 'hidden',
  },
  expanded: {
    margin: 0,
  },
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.note,
    minHeight: theme.spacing(6),
    '&$expanded': {
      minHeight: theme.spacing(6),
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
}))(MuiAccordionDetails);

export default React.memo(ChatPanel);
