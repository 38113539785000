import { uuidv4 } from 'helpers';
import { call, put, race, take, takeEvery } from 'redux-saga/effects';
import actionCreatorFactory, { Action, AsyncActionCreators } from 'typescript-fsa';

const actionCreator = actionCreatorFactory();
const promisify = actionCreator<{asyncAC: AsyncActionCreators<any, any, Error>; params: any}>('PROMISIFY');

export function promisifyAction<AC extends AsyncActionCreators<any, any, Error>>(
  asyncAC: AC,
  params: ParamsFromAsyncActionCreator<AC>,
  meta?: any,
) {
  return promisify({asyncAC, params}, meta);
}

export default function* promiseSaga() {
  yield takeEvery(promisify , function* (action) {
    const { payload: { asyncAC, params }, meta } = action;
    const { resolve, reject, ...rest } = meta || {};

    const _uuid: string = yield call(uuidv4);

    yield put(asyncAC.started(params, {...rest, _uuid}));

    const { done, failed } = yield race({
      done: take((action: Action<any>) => action.type === asyncAC.done.type && action.meta?._uuid === _uuid),
      failed: take((action: Action<any>) => action.type === asyncAC.failed.type && action.meta?._uuid === _uuid),
    });

    if (done) {
      resolve?.(done.payload.result);
    } else if (failed) {
      reject?.(failed.payload.error);
    }
  });
}
