import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationsIcon from '@material-ui/icons/Notifications';
import classNames from 'classnames';
import { MAX_NOTIFICATIONS } from 'components/settings/notifications/constants';
import { makeGetUnreadAggregatedNotifications } from 'components/settings/notifications/notifications-selector';
import { fetchUnreadNotificationsAC } from 'components/settings/notifications/notifications-slice';
import { EIntercomID } from 'constants/strings';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import NotificationsListContainer from './notifications-list/NotificationsListContainer';

interface NotificationsButtonOwnProps {
  className?: string;
}

type NotificationsButtonProps = NotificationsButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  badge: {
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
  },
  mobileBadge: {
    padding: 2,
    height: 'unset',
  },
}), {name: 'NotificationsButton'});

function NotificationsButton(props: NotificationsButtonProps) {
  const classes = useStyles(props);
  const { className } = props;
  const notificationPopupState = usePopupState({popupId: 'notifications', variant: 'popover'});
  const { t } = useStrictTranslation(['common']);
  const notifications = useCreateSelector(
    makeGetUnreadAggregatedNotifications,
    '',
  ).length;
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();

  const favicon = document.querySelector<HTMLAnchorElement>('#favicon');

  useEffect(() => {
    if (favicon && notifications > 0) {
      favicon.href = '/faviconWN.ico';
    }
  }, [notifications, favicon]);

  useEffect(() => {
    if (!notifications) {
      dispatch(fetchUnreadNotificationsAC.started());
    }
  }, [notifications, dispatch]);

  return (
    <React.Fragment>
      {isDesktop ? (
        <Badge
        className={classNames(classes.root, className)}
        classes={{
          badge: classes.badge,
        }}
        badgeContent={notifications}
        color='error'
        max={MAX_NOTIFICATIONS}
        >
          <Tooltip title={t('common:notifications')}>
            <IconButton
            id={EIntercomID.NOTIFICATIONS}
            className={classNames(classes.root, className)}
            {...bindTrigger(notificationPopupState)}
            >
              <NotificationsIcon />
            </IconButton>
          </Tooltip>
        </Badge>
      ) : (
        <MenuItem {...bindTrigger(notificationPopupState)}>
          <ListItemIcon>
            <Badge
            className={classNames(classes.root, className)}
            classes={{
              badge: classes.mobileBadge,
            }}
            badgeContent={notifications}
            color='error'
            max={MAX_NOTIFICATIONS}
            >
              <NotificationsIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText>
            {t('common:notifications')}
          </ListItemText>
        </MenuItem>
      )}
      <Popover
      {...bindPopover(notificationPopupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      >
        <NotificationsListContainer />
      </Popover>
    </React.Fragment>
  );
}

export default React.memo(React.forwardRef(NotificationsButton));
