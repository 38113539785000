import { AsyncButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { LinkProps } from 'react-router-dom';

import BetterDialogTitle from '../../better-dialog-title/BetterDialogTitle';
import ButtonLink from '../../button-link/ButtonLink';

type AA = {
  action?: VoidFunction;
  to?: never;
} | {
  action?: never;
  to: LinkProps['to'];
};

interface IAlertAction {
  text     : string;
  // action   : VoidFunction;  // is called when they click on the button
  disabled?: boolean;
  negative?: boolean;
}

interface IAsyncAlertAction extends IAlertAction {
  loading?: boolean;
}

interface IAlertDialogContentOwnProps {
  titleText? : string | React.ReactNode;
  description: string | React.ReactNode;
  cancel?    : IAsyncAlertAction & AA;
  confirm?   : IAsyncAlertAction & AA;
}

export type AlertDialogContentProps = IAlertDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  primary: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    '&&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  negative: {
    backgroundColor: theme.palette.negative.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    '&&:hover': {
      backgroundColor: theme.palette.negative.light,
    },
  },
  disabled: {},
}), { name: 'AlertDialogContent' });

/**
 * AlertDialogContent
 *
 * Useful for generic dialogs that are used for yes/no questions
 */
function AlertDialogContent(props: AlertDialogContentProps) {
  const {
    titleText,
    description,
    confirm,
    cancel,
  } = props;

  const classes = useStyles(props);

  return (
    <React.Fragment>
      {titleText && (
        <BetterDialogTitle>{titleText}</BetterDialogTitle>
      )}
      <DialogContent>
        <DialogContentText component='div'>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancel && (
          cancel.action ? (
            <AsyncButton
            loading={cancel.loading}
            disabled={cancel.disabled}
            variant='outlined'
            onClick={cancel.action}
            >
              {cancel.text}
            </AsyncButton>
          ) : cancel.to ? (
            <ButtonLink
            to={cancel.to}
            variant='outlined'
            >
              {cancel.text}
            </ButtonLink>
          ) : (
            null
          )
        )}
        {confirm && (
          confirm.action ? (
            <AsyncButton
            loading={confirm.loading}
            disabled={confirm.disabled}
            variant='contained'
            // color={confirm.negative ? 'error' : 'primary'}
            classes={{root: confirm.disabled ? classes.disabled: confirm.negative ? classes.negative : classes.primary}}
            onClick={confirm.action}
            >
              {confirm.text}
            </AsyncButton>
          ) : confirm.to ? (
            <ButtonLink
            to={confirm.to}
            // loading={confirm.loading}
            // disabled={confirm.disabled}
            color='primary'
            variant='contained'
            // classes={{ root: confirm.negative ? classes.negative : classes.primary }}
            >
              {confirm.text}
            </ButtonLink>
          ) : (
            null
          )
        )}
      </DialogActions>
    </React.Fragment>
  );
}

export default React.memo(AlertDialogContent);
