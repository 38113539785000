type TLeaguechampions =
|'aatrox'
|'ahri'
|'akali'
|'alistar'
|'amumu'
|'anivia'
|'annie'
|'aphelios'
|'ashe'
|'aurelion-sol'
|'azir'
|'bard'
|'blitzcrank'
|'blue_caster_minion'
|'blue_melee_minion'
|'blue_siege_minion'
|'blue_super_minion'
|'brand'
|'braum'
|'caitlyn'
|'camille'
|'cassiopeia'
|'chogath'
|'control-ward'
|'corki'
|'darius'
|'diana'
|'draven'
|'drmundo'
|'ekko'
|'elise'
|'evelynn'
|'ezreal'
|'farsight_alteration_ward'
|'fiddlesticks'
|'fiora'
|'fizz'
|'galio'
|'gangplank'
|'garen'
|'ghost_poro_ward'
|'gnar'
|'gragas'
|'graves'
|'hecarim'
|'heimerdinger'
|'illaoi'
|'irelia'
|'ivern'
|'janna'
|'jarvaniv'
|'jax'
|'jayce'
|'jhin'
|'jinx'
|'kai-sa'
|'kalista'
|'karma'
|'karthus'
|'kassadin'
|'katarina'
|'kayle'
|'kayn'
|'kennen'
|'kha-zix'
|'kindred'
|'kled'
|'kog-maw'
|'leblanc'
|'lee-sin'
|'leona'
|'lillia'
|'lissandra'
|'lucian'
|'lulu'
|'lux'
|'malphite'
|'malzahar'
|'maokai'
|'master-yi'
|'mega-gnar'
|'miss-fortune'
|'mordekaiser'
|'morgana'
|'nami'
|'nasus'
|'nautilus'
|'neeko'
|'nidalee'
|'nocturne'
|'nunu'
|'olaf'
|'oracle_lens_sweeper'
|'orianna'
|'ornn'
|'pantheon'
|'poppy'
|'pyke'
|'qiyana'
|'quinn'
|'rakan'
|'rammus'
|'red_caster_minion'
|'red_melee_minion'
|'red_siege_minion'
|'red_super_minion'
|'rek-sai'
|'renekton'
|'rengar'
|'rhaast'
|'riven'
|'rumble'
|'ryze'
|'sakayn'
|'samira'
|'scrying-orb'
|'sejuani'
|'senna'
|'seraphine'
|'sett'
|'shaco'
|'shen'
|'shyvana'
|'singed'
|'sion'
|'sivir'
|'skaarl'
|'skarner'
|'sona'
|'soraka'
|'stealth_ward'
|'swain'
|'sweeper'
|'sylas'
|'syndra'
|'tahm-kench'
|'taliyah'
|'talon'
|'taric'
|'teemo'
|'thresh'
|'trinket-ward'
|'tristana'
|'trundle'
|'tryndamere'
|'twisted-fate'
|'twitch'
|'udyr'
|'urgot'
|'valor'
|'varus'
|'vayne'
|'veigar'
|'vel-koz'
|'viktor'
|'vi'
|'vladimir'
|'volibear'
|'warding_totem'
|'warwick'
|'willump'
|'wukong'
|'xayah'
|'xerath'
|'xin-zhao'
|'yasuo'
|'yone'
|'yorick'
|'yuumi'
|'zac'
|'zed'
|'ziggs'
|'zilean'
|'zoe'
|'zombie_ward'
|'zyra'
;
const LeaguechampionsNS = 'leaguechampions';
function LeaguechampionsF(_: TLeaguechampions): string { return `${LeaguechampionsNS}:${_}`; }
export {LeaguechampionsF,LeaguechampionsNS,TLeaguechampions};
