import { DirectionalFetchStatus } from '@insights-gaming/redux-utils';
import { useFetchTeamMembers } from 'features/dashboard/member/useFetchTeamMember';
import React, { useEffect } from 'react';

import { ID } from '../../types/pigeon';

interface IMemberFetcherOwnProps {
  teamId: ID;
  setFetchedStatuses?: React.Dispatch<React.SetStateAction<Dictionary<DirectionalFetchStatus>>>;
}

type Props = IMemberFetcherOwnProps;

function MemberFetcher({ teamId, setFetchedStatuses }: Props) {
  const [ membersForwardFetchStatus ] = useFetchTeamMembers(teamId);
  useEffect(
    () => setFetchedStatuses?.(
      (prevState) => Object.assign({}, prevState, { [teamId]: membersForwardFetchStatus }),
    ),
    [membersForwardFetchStatus, setFetchedStatuses, teamId],
  );
  return null;
}

export default React.memo(MemberFetcher);
