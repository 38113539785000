import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React from 'react';

interface NotificationSettingsTableRowOwnProps {
  className?: string;
  notification?: React.ReactNode;
  muteLevelCells?: React.ReactNode;
}

type NotificationSettingsTableRowProps = NotificationSettingsTableRowOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'NotificationSettingsTableRow'});

function NotificationSettingsTableRow(props: NotificationSettingsTableRowProps) {
  const classes = useStyles(props);
  const { className, notification, muteLevelCells } = props;
  return (
    <TableRow className={classNames(classes.root, className)}>
      <FixedTableCell>{notification}</FixedTableCell>
      {muteLevelCells}
    </TableRow>
  );
}

export default React.memo(NotificationSettingsTableRow);
