import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import update from 'immutability-helper';
import React from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';

import { combineIds } from '../../../helpers';
import { DialogF, DialogNS } from '../../../locales/en/dialog';
import { PrimaryRosterV2_Players } from '../../../types/pigeon/statistics';
import CreateCorrectionDialogRow from './CreateCorrectionDialogRow';

export interface IRosterCorrectionOwnProps {
  roster: PrimaryRosterV2_Players[],
  rosterPosition: number;
  handleRosterChange: (
    newRoster: PrimaryRosterV2_Players[],
    rosterPosition: number,
  ) => void;
}

export type RosterCorrectionProps = IRosterCorrectionOwnProps &
  WithStyles<typeof styles> &
  WithTranslation;

interface IState {
  newRoster: PrimaryRosterV2_Players[];
}

const styles = (theme: Theme) => createStyles({
  formHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: theme.spacing(1, 0),
  },
});

class RosterCorrection extends React.Component<RosterCorrectionProps, IState> {
  constructor (props: RosterCorrectionProps) {
    super(props);
    this.state = {
      newRoster: props.roster,
    };
  }

  public render() {
    const { newRoster } = this.state;
    return (
      <div>
        {this.renderNewCurrentHeader()}
        {newRoster.map(this.renderPlayer)}
      </div>
    );
  }

  private renderNewCurrentHeader = () => {
    const { t, classes } = this.props;
    return (
      <Box className={classes.formHeader}>
        <Typography variant='h5'>{t(DialogF('editnames.current'))}</Typography>
        <Typography variant='h5'>{t(DialogF('editnames.new'))}</Typography>
      </Box>
    );
  }

  private renderPlayer = (
    player: PrimaryRosterV2_Players,
    i: number,
  ) => {
    const { roster } = this.props;
    return (
      <CreateCorrectionDialogRow
      key={combineIds(player.playerId, i)}
      player={player}
      playerOptions={roster}
      pos={i}
      onChange={this.onRowChange}
      />
    );
  }

  private onRowChange = (
    player: PrimaryRosterV2_Players,
    pos: number,
  ) => {
    const { rosterPosition } = this.props;
    this.setState(state => update(state, {
      newRoster: {$splice: [[pos, 1, player]]},
    }), () => this.props.handleRosterChange(this.state.newRoster, rosterPosition));
  }
}

export default withTranslation(DialogNS)(withStyles(styles)(RosterCorrection));
