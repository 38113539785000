import React, { useContext } from 'react';

export type FeatureFlag = string;
export const FeatureFlagsContext = React.createContext<Map<FeatureFlag, boolean>>(new Map<FeatureFlag, boolean>());
export const { Provider: FeatureFlagsProvider, Consumer: FeatureFlagsConsumer } = FeatureFlagsContext;

type UseFeatureFlags = [boolean, FeatureFlag[]];
export function useFeatureFlags(authorizedFlags: FeatureFlag[], exactFlags?: boolean): UseFeatureFlags {
  const flags = useContext(FeatureFlagsContext);
  const activeFlags = authorizedFlags.filter(f => flags.get(f));

  const matchesAllFlags = activeFlags.length === authorizedFlags.length;
  const matchesAtLeastOneFlag = !exactFlags && activeFlags.length > 0;
  const noFlagsRequired = authorizedFlags.length === 0;

  return [
    matchesAllFlags || matchesAtLeastOneFlag || noFlagsRequired,
    activeFlags,
  ];
}
