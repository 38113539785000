import gql from 'graphql-tag';

import { AnalysisFragment } from '../fragments';

export const AnalysisUpdated_Subscription = gql`
    subscription AnalysisUpdatedSubscription(
        $videoId: ID!
        $analysisId: ID!
    ) {
        analysisUpdated(
            videoId: $videoId
            analysisId: $analysisId
        ) {
            ...AnalysisFragment
        }
    }
    ${AnalysisFragment}
`;
