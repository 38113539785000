import { videoReplayActions, VideoReplayContextValue } from '@insights-gaming/material-components/VideoReplayContext/VideoReplayContext';
import { useChannelTake } from '@insights-gaming/use-take';
import { PayloadAction } from '@reduxjs/toolkit';
import { withActionMetadata } from 'helpers/withActionMetadata';
import { useCallback, useEffect, useRef } from 'react';


function useOnce(callback: () => void) {
  const called = useRef(false);
  return useCallback(() => {
    if (called.current) {
      return;
    }

    try {
      callback();
    } finally {
      called.current = true;
    }
  }, [callback]);
}

function useBruteForceSeek() {
  const timeoutRef = useRef<number>();
  useEffect(() => () => {
    window.clearTimeout(timeoutRef.current);
  }, []);

  return useCallback(function bruteForceSeek(
    abortRef: React.MutableRefObject<boolean>,
    contextRef: React.MutableRefObject<VideoReplayContextValue>,
    time: number,
    callback: () => void,
    iters: number = 0,
  ) {
    if (abortRef.current) {
      // brute force seek aborted
      return;
    }

    const { state, dispatch } = contextRef.current;
    if (Math.abs(state.progress.playedSeconds - time) < 1) {
      return callback();
    }

    if (iters > 100) {
      // brute force seek failed after 100 tries
      return callback();
    }

    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      dispatch(withActionMetadata(
        videoReplayActions.seekTo({type: 'seconds', amount: time, seekCommitted: true}),
        { initialization: true },
        ));

      bruteForceSeek(abortRef, contextRef, time, callback, iters+1);
    }, 50);
  }, []);
}

export function useSeekOnReady(
  videoReplay: VideoReplayContextValue,
  {
    initialState,
    defaultPosition = 0,
  }: {
    initialState?: { position: number, playing: boolean },
    defaultPosition?: number,
  } = {},
) {
  const videoReplayRef = useRef(videoReplay);

  useEffect(() => {
    videoReplayRef.current = videoReplay;
  }, [videoReplay]);

  const initialStateRef = useRef(initialState);

  const startTimeRef = useRef(defaultPosition);

  const abortRef = useRef(false);

  useEffect(() => () => {
    abortRef.current = true;
  }, []);

  useChannelTake(
    videoReplay.channel,
    [videoReplayActions.play, videoReplayActions.pause],
    ({ meta }: PayloadAction<undefined, string, { initialization?: boolean }>) => {
      if (!meta?.initialization) {
        return;
      }

      abortRef.current = true;
    },
    [],
  );

  const timeoutRefs = useRef<number[]>([]);

  function cleanupTimeoutRefs() {
    for (const timeoutRef of timeoutRefs.current) {
      window.clearTimeout(timeoutRef);
    }
    timeoutRefs.current.length = 0;
  }

  useEffect(() => () => {
    cleanupTimeoutRefs();
  }, []);

  const bruteForceSeek = useBruteForceSeek();

  return useOnce(useCallback(() => {
    // make the video start in the correct time and play/pause state when loading
    cleanupTimeoutRefs();
    timeoutRefs.current.push(window.setTimeout(() => {
      const { current: { state: { playing: startingPlayState } } } = videoReplayRef;
      const { current: initial } = initialStateRef;
      if (initial) {
        startTimeRef.current = initial.position;
      }

      bruteForceSeek(abortRef, videoReplayRef, startTimeRef.current, () => {
        const { dispatch } = videoReplayRef.current;
        dispatch(withActionMetadata(videoReplayActions.play(), { initialization: true }));
        if (initial && !initial.playing || !startingPlayState) {
          // HACK: play a little bit of the video to ensure buffering starts and the frame is visible
          timeoutRefs.current.push(window.setTimeout(() => {
            dispatch(withActionMetadata(videoReplayActions.pause(), { initialization: true }));
            dispatch(withActionMetadata(
              videoReplayActions.seekTo({type: 'seconds', amount: startTimeRef.current, seekCommitted: true}),
              { initialization: true },
            ));
          }, 300));
        }
      });
    }, 1000));
  }, [bruteForceSeek]));
}
