import {
  TeamFragment,
  TeamFragment_features_GlobalTeamFeature,
  TeamFragment_features_SeatedTeamFeature,
} from 'apollo/fragments/types/TeamFragment';
import { TeamFeature } from 'types/graphql';

export type Seat = readonly TeamFeature[];

export const Seat = Object.freeze({
  PREMIUM: Object.freeze([
    TeamFeature.UPLOAD_VIDEO,
    TeamFeature.UNLIMITED_FOLDERS,
  ]) as Seat,
  OVERWATCH: Object.freeze([
    TeamFeature.ACCESS_OVERWATCH_STATS,
    TeamFeature.AGGREGATE_OVERWATCH_STATS,
    TeamFeature.ANALYZE_OVERWATCH,
  ]) as Seat,
  SSB_ULTIMATE: Object.freeze([
    TeamFeature.ACCESS_SSB_ULTIMATE_STATS,
    TeamFeature.UNLIMITED_FOLDERS,
    TeamFeature.ANALYZE_SSB_ULTIMATE,
  ]) as Seat,
  ADFREE: Object.freeze([
    TeamFeature.ADFREE,
  ]) as Seat,
});

export function isSeatedFeature(
  feature: TeamFragment_features_GlobalTeamFeature | TeamFragment_features_SeatedTeamFeature,
): feature is TeamFragment_features_SeatedTeamFeature {
  return feature.__typename === 'SeatedTeamFeature';
}

export function isGlobalFeature(
  feature: TeamFragment_features_GlobalTeamFeature | TeamFragment_features_SeatedTeamFeature,
): feature is TeamFragment_features_GlobalTeamFeature {
  return feature.__typename === 'GlobalTeamFeature';
}

export function hasSeats(team: TeamFragment, seat: Seat) {
  if (!team.features || !team.features.length || !seat) {
    return false;
  }

  const seatedFeature = team.features
    .filter(isSeatedFeature)
    .filter(({ limit }) => limit > 0)
    .map((feature) => feature.feature);

  return seat.every((feature) => seatedFeature.includes(feature));
}
