import { AsyncButton } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { IFileUploadInput, ITusResumable } from 'features/upload/upload-slice';
import update, { Spec } from 'immutability-helper';
import { CommonF, CommonNS } from 'locales/en/common';
import { DialogF, DialogNS } from 'locales/en/dialog';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IResumableUpload } from 'types';
import { ID } from 'types/pigeon';

export interface IInProgressUploadDialogContentOwnProps {
  tusResumable: ITusResumable;
  teamId      : ID;
  onClose     : VoidFunction;
  directoryId : ID;
}

export interface IInProgressUploadDialogContentDispatch {
  onTusUpload: (input: IFileUploadInput) => Promise<ITusResumable>;
}

export type InProgressUploadDialogContentProps = IInProgressUploadDialogContentOwnProps &
  IInProgressUploadDialogContentDispatch &
  WithTranslation;

interface IState {
  newUploads: Set<ID>;
}

class InProgressUploadDialogContent extends React.Component<InProgressUploadDialogContentProps, IState> {
  constructor(props: InProgressUploadDialogContentProps) {
    super(props);
    this.state = {
      newUploads: new Set(),
    };
  }

  public render() {
    const { tusResumable, onClose, t } = this.props;
    return (
      <React.Fragment>
        <DialogTitle>
          {t(DialogF('inprogressupload.title'))}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t(DialogF('inprogressupload.description'))}
          </Typography>
          {tusResumable.inProgress.map(this.renderInProgressUpload)}
        </DialogContent>
        <DialogActions>
          <Button
          variant='outlined'
          onClick={onClose}
          >
            {t(CommonF('cancel'))}
          </Button>
          <AsyncButton
          variant='contained'
          color='primary'
          onClick={this.handleSubmit}
          >
            {t(DialogF('inprogressupload.confirm'))}
          </AsyncButton>
        </DialogActions>
      </React.Fragment>
    );
  }

  private renderInProgressUpload = (upload: IResumableUpload) => {
    const { video } = upload;
    const { newUploads } = this.state;
    return (
      <FormControlLabel
      key={upload.uuid}
      control={(
        <Checkbox name={upload.uuid} checked={newUploads.has(upload.uuid)} onChange={this.handleCheckboxOnChange} />
      )}
      label={video.name}
      />
    );
  }

  private handleSubmit = () => {
    const { teamId, onTusUpload, tusResumable, onClose, directoryId } = this.props;
    const { newUploads } = this.state;
    const files = tusResumable.inProgress
      .filter((upload: IResumableUpload) => newUploads.has(upload.uuid))
      .map((upload: IResumableUpload) => ({file: upload.file, publicity: upload.publicity, directoryId}));

    onTusUpload({ teamId, directoryId, files });
    onClose();
  }

  private handleCheckboxOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { name, value, checked } } = e;
    const spec: Spec<IState> = checked
      ? {newUploads: {$add: [name]}}
      : {newUploads: {$remove: [name]}};
    this.setState(state => update(state, spec));
  }
}

export default withTranslation([CommonNS, DialogNS])(InProgressUploadDialogContent);
