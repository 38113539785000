import { createAbsoluteUrl } from 'helpers/routing';
import { ID } from 'types/pigeon';

enum Tab {
  OVERVIEW = 'overview',
  MEMBERS = 'members',
  ROLES = 'roles',
  INVITATIONS = 'invitations',
  BILLING = 'billing',
}

type TabType = Tab;

const createPath = (teamid: string, tab?: string) => {
  return createAbsoluteUrl('dashboard', 'team', teamid, 'settings', tab);
};
const createTabPath = (tab?: string) => {
  return createPath(':teamid', tab);
};
const path = createTabPath(':tab?');
const overviewPath = createTabPath(Tab.OVERVIEW);
const membersPath = createTabPath(Tab.MEMBERS);
const rolesPath = createTabPath(Tab.ROLES);
const invitationsPath = createTabPath(Tab.INVITATIONS);
const billingPath = createTabPath(Tab.BILLING);

const createUrl = (teamId: ID, tab?: Tab): string => {
  return createPath(teamId, tab);
};

const DashboardTeamSettingsRouting = {
  Tab,
  path,
  overviewPath,
  membersPath,
  rolesPath,
  invitationsPath,
  billingPath,
  createUrl,
};

// eslint-disable-next-line
declare namespace DashboardTeamSettingsRouting {
  interface Params {
    teamid: ID;
    tab?: Tab;
  }
  type Tab = TabType;
}

export default DashboardTeamSettingsRouting;
