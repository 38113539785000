
import Chip from '@material-ui/core/Chip';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  root: {
    width: '100%',
  },
  label: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    textAlign: 'center',
    '& > *': { // Needed if user puts in an element as label
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

export default withStyles(styles)(Chip);
