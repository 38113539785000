import gql from 'graphql-tag';

import { CreatedInvitationPayloadFragment } from '../fragments';

export const InviteMembers_Mutation = gql`
    mutation InviteMembersMutation(
        $input: InviteMembersInput!
    ) {
        inviteMembers(
            input: $input
        ) {
            invitations {
                ...CreatedInvitationPayloadFragment
            }
        }
    }
    ${CreatedInvitationPayloadFragment}
`;
