import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { UserNotificationSettingFragment } from 'apollo/fragments/types/UserNotificationSettingFragment';
import classNames from 'classnames';
import { isExistent } from 'helpers';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import update from 'immutability-helper';
import isEqual from 'lodash/isEqual';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { MuteLevel, NotificationType } from 'types/graphql';

import { updateUserNotificationSettingsAC } from '../notification-settings-slice';
import { NotificationsHelper } from '../notifications-helper';
import NotificationSettingsTableRowWrapper from './NotificationSettingsTableRowWrapper';

interface NotificationSettingsTableOwnProps {
  className?: string;
  userNotificationSettings: UserNotificationSettingFragment[];
}

type NotificationSettingsTableProps = NotificationSettingsTableOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  saveBtn: {
    marginLeft: 'auto',
  },
}), {name: 'NotificationSettingsTable'});

function NotificationSettingsTable(props: NotificationSettingsTableProps) {
  const classes = useStyles(props);
  const { className, userNotificationSettings } = props;

  const { t } = useStrictTranslation(['common', 'settings']);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const initialNotificationSettings = NotificationsHelper
    .createInitialNotificationSettingsInput(userNotificationSettings);

  const [ userNotificationSettingsInput, setUserNotificationSettingsInput ] = useState(
    () => initialNotificationSettings,
  );
  const [ loading, setLoading ] = useState(false);

  const handleMuteLevelOnChange = useCallback((nt: NotificationType, ml: MuteLevel) => {
    setUserNotificationSettingsInput(prev => update(prev, {
      [nt]: {level: {$set: ml}},
    }));
  }, []);

  const disabled = useMemo(() => {
    return isEqual(initialNotificationSettings, userNotificationSettingsInput);
  }, [initialNotificationSettings, userNotificationSettingsInput]);

  const handleSave = useCallback(async () => {
    if (loading) { return; }
    setLoading(true);

    try {
      const input = Object.keys(NotificationType).map(k => (userNotificationSettingsInput[k])).filter(isExistent);

      await promiseSagaDispatch(updateUserNotificationSettingsAC, input);
      setLoading(false);
      enqueueSnackbar(t('settings:notification.savesuccess'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('settings:notification.updatefailed'), {variant: 'error'});
      setLoading(false);
    }
  }, [promiseSagaDispatch, loading, userNotificationSettingsInput, enqueueSnackbar, t]);

  return (
    <FlexSpacer
    className={classNames(classes.root, className)}
    orientation='vertical'
    spacing={2}
    >
      <Table className={classNames(classes.root, className)} size='small'>
        <TableHead>
          <TableRow>
            <FixedTableCell>{t('settings:notifications.title')}</FixedTableCell>
            <FixedTableCell width={50} align='center'>{t('settings:notifications.mute')}</FixedTableCell>
            <FixedTableCell width={50} align='center'>{t('settings:notifications.inapp')}</FixedTableCell>
            <FixedTableCell width={80} align='center'>{t('settings:notifications.inappandemail')}</FixedTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(NotificationType).map((key) => (
            <NotificationSettingsTableRowWrapper
            key={key}
            notificationType={userNotificationSettingsInput[key]!.notificationType}
            handleMuteLevelOnChange={handleMuteLevelOnChange}
            selectedValue={userNotificationSettingsInput[key]!.level}
            />
          ))}
        </TableBody>
      </Table>
      <div className={classes.saveBtn}>
        <AsyncButton
        color='primary'
        variant='contained'
        onClick={handleSave}
        loading={loading}
        disabled={loading || disabled}
        >
          {t('settings:notifications.savechanges')}
        </AsyncButton>
      </div>
    </FlexSpacer>
  );
}

export default React.memo(NotificationSettingsTable);
