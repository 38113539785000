import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/header/Header';
import StatusBar from 'components/status-bar/StatusBar';
import BackButton from 'containers/BackButton';
import { mobilePortrait } from 'features/media-queries';
import { useIsDesktop, useIsMobilePortrait } from 'features/media-queries/hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors/getMe';
import GuestBar from 'subcomponents/guest-bar/GuestBar';

interface ViewerLayoutHeaderOwnProps {
  backPath?: string;
  children?: React.ReactNode;
}

type ViewerLayoutHeaderProps = ViewerLayoutHeaderOwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    [mobilePortrait(theme)]: {
      height: theme.spacing(8.5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  headerContent: {
    [mobilePortrait(theme)]: {
      paddingRight: theme.spacing(1),
    },
  },
  backButton: {
    [mobilePortrait(theme)]: {
      '& > button > span > svg': {
        fontSize: '0.8rem',
      },
    },
  },
  container: {
    flexGrow: 1,
    [mobilePortrait(theme)]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'center',
    },
  },
  mobile: {},
}), {name: 'ViewerLayout'});

function ViewerLayoutHeader(props: ViewerLayoutHeaderProps) {
  const { backPath, children } = props;
  const classes = useStyles(props);

  const me = useSelector(getMe);

  const isDesktop = useIsDesktop();
  const isMobilePortrait = useIsMobilePortrait();

  if (!isDesktop && !isMobilePortrait) {
    return null;
  }

  return (
    <Header className={classes.header}>
      <FlexSpacer fullWidth={true} className={classes.headerContent}>
        {backPath && (
          <Box className={classes.backButton}>
            <BackButton route={backPath} />
          </Box>
        )}
        <FlexSpacer
        className={classes.container}
        flexAlignItems='center'
        >
          {children}
        </FlexSpacer>
        {isDesktop && (
          <Box>
            {me ? <StatusBar me={me} /> : <GuestBar />}
          </Box>
        )}
      </FlexSpacer>
    </Header>
  );
}

export default React.memo(ViewerLayoutHeader);
