import { VideoFragment } from 'apollo/fragments';
import gql from 'graphql-tag';

export const UpdateVideos_Mutation = gql`
    mutation UpdateVideosMutation(
        $input: UpdateVideosInput!
    ) {
        updateVideos(
            input: $input
        ) {
            videos {
                ...VideoFragment
            }
        }
    }
    ${VideoFragment}
`;
