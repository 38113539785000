import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import React from 'react';

interface DirectoryLinkSkeletonOwnProps {
  className?: string;
}

type DirectoryLinkSkeletonProps = DirectoryLinkSkeletonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DirectoryLinkSkeleton'});

function DirectoryLinkSkeleton(props: DirectoryLinkSkeletonProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <Button className={classNames(classes.root, className)}>
      <Skeleton variant='text' width='100%' />
    </Button>
  );
}

export default React.memo(DirectoryLinkSkeleton);
