
import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetNotificaionRecords } from './notifications-selector';
import { fetchNotificationsAC } from './notifications-slice';

export const useFetchNotifications = createBidirectionalAutoFetcher({
  actionCreators: fetchNotificationsAC,
  selectorFactory: makeGetNotificaionRecords,
  createFetchId: ({directoryIds, states, teamIds, type, videoIds}) => [
    directoryIds,
    states,
    teamIds,
    type,
    videoIds,
  ].join(':'),
  pageSize: 250,
});
