import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvatarWithLetterFallback,FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx,Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { claimInvitationAsyncAC, declineInvitationAsyncAC } from 'actions/invitation-actions';
import classNames from 'classnames';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { InvitationFragment, InvitationFragment_target } from '../../apollo/fragments/types/InvitationFragment';
import { useFetchInvitation, useInvalidateTeamCache } from '../../hooks/dispatch';
import { useNavigate } from '../../hooks/useNavigate';
import { DialogNS } from '../../locales/en/dialog';
import BetterDialogTitle from '../../material/better-dialog-title/BetterDialogTitle';
import ButtonLink from '../../material/button-link/ButtonLink';
import AlertDialogContent from '../../material/dialogs/alert-dialog-content/AlertDialogContent';
import { dashboardRoute, folderRoute, teamRoute, VERIFY_CALLBACK_PATH, videoRoute } from '../../routes';
import { getTeamCache } from '../../selectors/team';
import { InvitationInput } from '../../types/graphql';

export interface IClaimInvitationOwnProps {}

type Props = IClaimInvitationOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  target: {
    display: 'block',
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  content: {
    marginBottom: theme.spacing(-1.5),
  },
  semiBold: {
    fontWeight: 700,
    '&$channelTitle': {
      fontSize: createRemFromPx(18),
      fontWeight: 600,
    },
  },
  avatarSize: {
    fontSize: createRemFromPx(18),
  },
  channelTitle: {},
}), {name: 'ClaimInvitation'});

function ClaimInvitation(props: Props) {
  const classes = useStyles(props);
  const { code } = useParams<{code: string}>();
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const onFetchInvitation = useFetchInvitation();
  const onInvalidateTeamCache = useInvalidateTeamCache();
  const onNavigate = useNavigate();
  const { t } = useStrictTranslation(['common', 'dialog']);
  const [invitation, setInvitation] = useState<InvitationFragment>();
  const { enqueueSnackbar } = useSnackbar();
  const [declineLoading, setDeclineLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const teamCache = useSelector(getTeamCache);

  const isMemberOfTeam = useMemo(() => {
    if (!invitation || invitation.target.__typename !== 'Team') { return false; }
    return Boolean(teamCache[invitation.target.id]);
  }, [invitation, teamCache]);

  useEffect(() => {
    (async function() {
      try {
        setInvitation(await onFetchInvitation({code}));
      } catch (error) {
        if (document.referrer.endsWith(VERIFY_CALLBACK_PATH)) {
          onNavigate(dashboardRoute());
        } else {
          setError(error);
        }
      }
    })();
  }, [code, onFetchInvitation, onNavigate]);

  const handleError = useCallback((error: Error) => {
    enqueueSnackbar(error.message, {variant: 'error'});
  }, [enqueueSnackbar]);

  const declineInvitation = useCallback(async () => {
    if (declineLoading) { return; }
    if (!invitation) { return; }
    setDeclineLoading(true);
    const input: InvitationInput = {
      id: invitation.id,
    };
    switch (invitation.target.__typename) {
      case 'Team':
        input.teamId = invitation.target.id;
        break;
      case 'Video':
        input.videoId = invitation.target.id;
        break;
    }
    try {
      await promiseSagaDispatch(declineInvitationAsyncAC, {invitation: input});
      onNavigate(dashboardRoute(), {replaceUrl: true});
    } catch (error) {
      setDeclineLoading(false);
      handleError(error);
    }
  }, [declineLoading, handleError, invitation, onNavigate, promiseSagaDispatch]);

  const getRoute = useCallback((target: InvitationFragment_target) => {
    switch (target.__typename) {
      case 'Team' : return teamRoute(target.id);
      case 'Video': return videoRoute(target.id);
      case 'DirectoryInfo': return folderRoute(target.id);
    }
  }, []);

  const acceptInvitation = useCallback(async () => {
    if (acceptLoading) { return; }
    if (!invitation) { return; }
    setAcceptLoading(true);
    try {
      await promiseSagaDispatch(claimInvitationAsyncAC, {code});
      onInvalidateTeamCache();
      onNavigate(getRoute(invitation.target));
      enqueueSnackbar(
        t(('dialog:claiminvitation.acceptsuccess'), {targetname: invitation.target.name}),
        {variant: 'success'},
      );
    } catch (error) {
      setAcceptLoading(false);
      handleError(error);
    }
  }, [
    acceptLoading,
    code,
    enqueueSnackbar,
    getRoute,
    handleError,
    invitation,
    onInvalidateTeamCache,
    onNavigate,
    promiseSagaDispatch,
    t,
  ]);

  const renderClaimInvitation = useCallback(({from, target}: InvitationFragment) => {
    let titleText;
    let avatar;
    let i18nKey;
    if (target.__typename === 'DirectoryInfo') {
      titleText = t('dialog:claiminvitation.channelinvitation');
      avatar = <FontAwesomeIcon icon={faHashtag} fixedWidth={true} className={classes.avatarSize} />;
      i18nKey = ('dialog:claiminvitation.channeldesc');
    }
    if (target.__typename === 'Team') {
      titleText = t('dialog:claiminvitation.teaminvitation');
      avatar = <AvatarWithLetterFallback name={target.name}/>;
      i18nKey = ('dialog:claiminvitation.teamdesc');
    }

    let desc = (
      <div className={classes.content}>
        <FlexSpacer flexAlignItems='center' className={classes.header}>
          {avatar}
          <Typography
          className={classNames(classes.semiBold, {[classes.channelTitle]: target.__typename === 'DirectoryInfo'})}
          >
            {target.name}
          </Typography>
        </FlexSpacer>
        <Trans
        i18nKey={i18nKey}
        ns={DialogNS}
        values={{from: from?.alias ?? '', to: target.name}}
        >
          <strong>from</strong> invited you to join
          <span className={classes.target}>target</span>
        </Trans>
      </div>
    );

    return (
      <AlertDialogContent
      titleText={titleText}
      description={desc}
      cancel={{
        text: t('dialog:claiminvitation.cancel'),
        loading: declineLoading,
        disabled: declineLoading || acceptLoading,
        action: declineInvitation,
      }}
      confirm={{
        text: t('dialog:claiminvitation.confirm'),
        loading: acceptLoading,
        disabled: declineLoading || acceptLoading,
        action: acceptInvitation,
      }}
      />
    );
  }, [
    acceptInvitation,
    acceptLoading,
    classes.avatarSize,
    classes.channelTitle,
    classes.content,
    classes.header,
    classes.semiBold,
    classes.target,
    declineInvitation,
    declineLoading,
    t,
  ]);

  const renderAlreadyMember = useCallback((target: InvitationFragment_target) => (
    <AlertDialogContent
    titleText={t('dialog:claiminvitation.alreadymember.title')}
    description={(
      <Trans
      i18nKey={t('dialog:claiminvitation.alreadymember.description')}
      ns={DialogNS}
      values={{target: target.name}}
      >
        You&quot;re already a member of the team <span className={classes.target}>target</span>
      </Trans>
    )}
    confirm={{
      text: t('dialog:claiminvitation.alreadymember.confirm'),
      to: teamRoute(target.id),
    }}
    />
  ), [classes.target, t]);

  const renderInvitationFetchError = useCallback((error: Error) => (
    <React.Fragment>
      <BetterDialogTitle>
        {t('dialog:claiminvitation.notfound.title')}
      </BetterDialogTitle>
      <DialogContent>
        {t('dialog:claiminvitation.notfound.reasonlabel')}
        <ul>
          <li>{t('dialog:claiminvitation.notfound.reasons.0')}</li>
          <li>{t('dialog:claiminvitation.notfound.reasons.1')}</li>
          <li>{t('dialog:claiminvitation.notfound.reasons.2')}</li>
          <li>{t('dialog:claiminvitation.notfound.reasons.3')}</li>
          <li>{t('dialog:claiminvitation.notfound.reasons.4')}</li>
        </ul>
      </DialogContent>
      <DialogActions>
        <ButtonLink
        to={dashboardRoute()}
        color='primary'
        variant='contained'
        >
          {t('common:404_back')}
        </ButtonLink>
      </DialogActions>
    </React.Fragment>
  ), [t]);

  return (
    <Dialog open={true} fullWidth={true} maxWidth='xs'>
      {isMemberOfTeam && invitation ? (
        renderAlreadyMember(invitation.target)
      ) : invitation ? (
        renderClaimInvitation(invitation)
      ) : error ? (
        renderInvitationFetchError(error)
      ) : (
        null
      )}
    </Dialog>
  );
}

export default React.memo(ClaimInvitation);
