import json2csv, { parse } from 'json2csv';
import cloneDeep from 'lodash/cloneDeep';

import { GeneratedOverwatchMatch } from '../../types/pigeon/matches';
import { CSV_LINE_BREAK, isTruthy } from '../';

interface MatchDataKillEvent {
  type: 'kill';
}

interface MatchDataUltEvent {
  type      : 'ult'
  numAssists: number;
  numKills  : number;
  numDeaths : number;
  numHacks  : number;
  numStuns  : Number;
  numFreezes: number;
}

type MatchDataEvent = (MatchDataKillEvent | MatchDataUltEvent) & {
  event: number;
};

interface MatchDataTeamfight {
  teamfight: number;
  events: MatchDataEvent[]

}

interface MatchDataNonTeamfightEvent {
  startTime: number;
  killer: {
    player: string;
    hero  : string;
    color : string;
  }
}

interface MatchData {
  match             : number;
  map               : string;
  redteam           : string;
  blueteam          : string;
  teamfights        : MatchDataTeamfight[];
  nonTeamfightEvents: MatchDataNonTeamfightEvent[];
}

const fields: Array<json2csv.FieldInfo<MatchData>> = [{
  label: 'Match',
  value: 'match',
}, {
  label: 'Teamfight',
  value: 'teamfights.teamfight',
}, {
  label: 'Event',
  value: 'teamfights.events.event',
}, {
  label: 'Map',
  value: 'map',
}, {
  label: 'Blue Team',
  value: 'blueteam',
}, {
  label: 'Red Team',
  value: 'redteam',
}, {
  label: 'EventType',
  value: 'teamfights.events.type',
}, {
  label: 'Timestamp',
  value: 'teamfights.events.time',
}, {
  label: 'Player',
  value: 'teamfights.events.killer.player',
}, {
  label: 'Color',
  value: 'teamfights.events.killer.color',
}, {
  label: 'Killer',
  value: 'teamfights.events.killer.hero',
}, {
  label: 'Ability',
  value: 'teamfights.events.ability',
},{
  label: 'Player',
  value: 'teamfights.events.killee.player',
}, {
  label: 'Color',
  value: 'teamfights.events.killee.color',
}, {
  label: 'Killee',
  value: 'teamfights.events.killee.hero',
}, {
  label: 'Assists',
  value: 'teamfights.events.assists',
}, {
  label: '# of Assists',
  value: 'teamfights.events.numAssists',
}, {
  label: '# of Kills',
  value: 'teamfights.events.numKills',
}, {
  label: '# of Team Deaths',
  value: 'teamfights.events.numDeaths',
}, {
  label: '# of Hacks',
  value: 'teamfights.events.numHacks',
}, {
  label: '# of Stuns',
  value: 'teamfights.events.numStuns',
}, {
  label: '# of Freezes',
  value: 'teamfights.events.numFreezes',
}];

export function matches2Csv2(matches: GeneratedOverwatchMatch[]): Blob {
  const csvs = [
    parse([], {fields}) + CSV_LINE_BREAK,
    ...matches.map(matchEvents2Csv),
  ].filter(isTruthy);
  return new Blob(csvs, {type: 'text/csv;charset=UTF-16'});
}

function matchEvents2Csv(match: GeneratedOverwatchMatch, matchNumber: number = 0): string {
  const { players, teamfights, nonTeamfightEvents } = match.data;
  const data: MatchData = {
    match     : matchNumber,
    map       : match.data.map,
    blueteam  : match.data.players.slice(0, 6).map(p => p.name).join(','),
    redteam   : match.data.players.slice(6).map(p => p.name).join(','),
    teamfights: (teamfights as any).map((t: any, i: number) => ({
      ...t,
      teamfight: i,
      events: t.events.filter((e: any) => ['kill', 'ult'].includes(e.type)).map((e: any, j: number) => {
        e = cloneDeep(e);
        switch (e.type) {
          case 'ult':
            e.killer = {
              player: players[e.player].name,
              hero  : e.hero,
              color : e.team,
            };
            break;
          case 'kill':
            if (e.killer) {
              const index = (e.killer.color === 'blue' ? t.blueHeroes : t.redHeroes).indexOf(e.killer.hero);
              if (index > -1) {
                e.killer.player = players[index + (e.killer.color === 'blue' ? 0 : 6)].name;
              }
            }
            if (e.killee) {
              const index = (e.killee.color === 'blue' ? t.blueHeroes : t.redHeroes).indexOf(e.killee.hero);
              if (index > -1) {
                e.killee.player = players[index + (e.killee.color === 'blue' ? 0 : 6)].name;
              }
            }
            e.assists = (e.assists ?? []).join(',');
            break;
        }
        return ({
          ...e,
          event: j,
        });
      }),
    })),
    nonTeamfightEvents: (nonTeamfightEvents as any)
      .filter((e: any) => ['kill', 'ult'].includes(e.eventType))
      .map((e: any, i: number) => ({
        ...e,
        ...e.details,
        event: i,
        type: e.eventType,
      })),
  };
  const teamfightCsv = parse([data], {fields, unwind: ['teamfights', 'teamfights.events'], header: false});
  const f2 = fields.map(f => ({
    ...f,
    value: (f.value as string).replace('teamfights.events.', 'nonTeamfightEvents.'),
  }));
  const nonTeamfightCsv = parse(data, {
    fields: f2,
    unwind: ['nonTeamfightEvents'],
    header: false,
  });
  return teamfightCsv + CSV_LINE_BREAK + nonTeamfightCsv + CSV_LINE_BREAK;
}
