import { Theme } from '@insights-gaming/theme';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { CommonF, CommonNS } from '../../locales/en/common';
import ButtonLink from '../../material/button-link/ButtonLink';
import { dashboardRoute } from '../../routes';
import EmptyStateImage from '../../subcomponents/empty-state-image/EmptyStateImage';
import { OnNavigate } from '../../types/dispatch';

export interface IPageNotFoundDispatch {
  onNavigate: OnNavigate;
}

export type IPageNotFoundProps = IPageNotFoundDispatch &
  WithTranslation &
  WithStyles<typeof styles>;

const styles = (theme: Theme) => createStyles({
  pageNotFound: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(7),
  },
  content: {
    textAlign: 'center',
    '& > * + *': {
      marginTop: theme.spacing(2.5),
    },
  },
});

class PageNotFound extends React.Component<IPageNotFoundProps> {
  public render() {
    const { t, classes } = this.props;
    return (
      <div className={classes.pageNotFound}>
        <div>
          <div className={classes.content}>
            <EmptyStateImage url='not-found' />
            <div>
              <Typography variant='h2'><strong>{t(CommonF('404'))}</strong></Typography>
              <Typography>{t(CommonF('404_desc'))}</Typography>
            </div>
            <ButtonLink
            color='primary'
            variant='contained'
            to={dashboardRoute()}
            fullWidth={true}
            >
              {t(CommonF('404_back'))}
            </ButtonLink>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(CommonNS)(
  withStyles(styles)(PageNotFound),
);
