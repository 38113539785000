import { createRemFromPx,Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { numToDollarAmount } from 'helpers/formatters';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback } from 'react';

interface ContributeOptionButtonOwnProps {
  className?: string;
  contribution: number;
  onClick: (contribution: number) => void;
  selected: boolean;
}

type ContributeOptionButtonProps = ContributeOptionButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  options: {
    maxWidth: createRemFromPx(100),
    maxHeight: createRemFromPx(40),
    flex: '1 0 30%',
    margin: theme.spacing(1, 2),
  },
}), {name: 'ContributeOptionButton'});

function ContributeOptionButton(props: ContributeOptionButtonProps) {
  const classes = useStyles(props);
  const { className, contribution, onClick, selected } = props;
  const { t } = useStrictTranslation(['dashboard']);

  const optionOnClick = useCallback(() => onClick(contribution), [contribution, onClick]);

  return (
    <Button
    color={selected ? 'primary' : 'default'}
    variant={selected ? 'contained' : 'outlined'}
    className={classNames(classes.options, className)}
    onClick={optionOnClick}
    >
      {t('dashboard:contribute.dollaramount', { amount: numToDollarAmount(contribution/100) })}
    </Button>
  );
}

export default React.memo(ContributeOptionButton);
