import { BidirectionalIDRecord } from '@insights-gaming/redux-utils';
import { RosterPlayersFragment } from 'apollo/fragments/types/RosterPlayersFragment';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery } from 'apollo/queries/types/GetOverwatchAdvancedQueryQuery';
import { GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics } from 'apollo/queries/types/GetOverwatchPrimaryRosterPlayerStatisticsV2Query';
import { GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query_statistics } from 'apollo/queries/types/GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query';
import { GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics } from 'apollo/queries/types/GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query';
import { GetOWLStatisticsQuery_owlStatistics } from 'apollo/queries/types/GetOWLStatisticsQuery';
import { QueryForm } from 'components/dashboard/team-statistics/overwatch-custom-queries/create-query/QueryFormHelper';
import { Color } from 'csstype';
import { KeyCommand } from 'keybindings';
import { INotification, IVideoLocationFilterOption, IVideoMapTypeFilterOption, PagedID } from 'types';
import { IOWLUserMetadata } from 'types/pigeon/owl-match';

import { SystemAnnouncement } from '../announcements';
import { GeneratedOverwatchMatch, IAggregatedOverwatchStats, ID, Match, TeamEdge, Video } from '../pigeon';
import { ICry, ISessionProfile } from '../pigeon/kmsession';
import { Capability } from '../pigeon/kmsession/capabilities';
import { MatchCache, ReduxCache } from './cache';

export interface IMatchStoreState {
  matchIds       : PagedID;
  sharedMatchIds : PagedID;
  teamMatchIds   : {[id: string]: PagedID};
  matchCache     : MatchCache;
  pendingMatchIds: Set<ID>;
  failedMatchIds : Set<ID>;
}

export interface IVideoFilterStoreState {
  mapTypeFilters : IVideoMapTypeFilterOption[];
  locationFilters: IVideoLocationFilterOption[];
}

export interface INotificationStoreState {
  notifications: INotification[];
  announcements: SystemAnnouncement[];
}

export interface ITeamStoreState {
  selectedTeamId?: ID;
  teamIds        : PagedID;
  teamCache      : ReduxCache<ID, TeamEdge>;
  teamStatsCache : ReduxCache<ID, IAggregatedOverwatchStats>;
}

export interface IColorPickerStoreState {
  color?: Color;
}

export interface IVideoPlayerStoreState {
  jumpTo?: { time: number };
  isTheaterMode: boolean;
}

export interface IErrorStoreState {
  graphqlNetworkErrors  : Set<number>;
  authCheckFailed?      : boolean;
  fetchUserProfileError?: Error;
}

export interface TeamRosterStatistics<T> {
  cache: Dictionary<Dictionary<T>>;
  pending: Dictionary<ID[]>;
  error?: Error;
}

export interface IStatisticsStoreState {
  teamRosterRecords: Partial<Dictionary<BidirectionalIDRecord>>;
  teamRosterDict: Dictionary<Dictionary<RosterPlayersFragment>>;

  teamPrimaryRosterIds: Dictionary<ID>;

  playerStatistics: TeamRosterStatistics<
    GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics
  >;
  teamFightCompositionStatistics: TeamRosterStatistics<
    GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query_statistics
  >;
  teamFightMapStatistics: TeamRosterStatistics<
    GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics
  >;

  matchCache     : Dictionary<Match>;
  pendingMatchIds: ID[];
}

export interface IKMSessionStoreState {
  session?: IKMSession;
}

export interface KeybindingLocation {
  namespace: string;
  source   : string;
}

export interface IKeybindingStoreState {
  _defaultKeybindings: DefaultKeybinding[];
  _customKeybindings : CustomKeybinding[];
  keybindMode        : boolean;
  keybindingContext  : KeybindingLocation[];
}

export interface IPublicStatisticsStoreState {
  owlVideoIdsSet: Map<ID, PagedID>;
  owlVideoCache: ReduxCache<ID, VideoFragment>;

  owlVideoMatches: Map<ID, GeneratedOverwatchMatch[]>;
  owlVideoResults: Map<ID, IOWLUserMetadata>;

  pendingOwlStatistics: Set<ID>;
  owlStatisticsCache: ReduxCache<ID, GetOWLStatisticsQuery_owlStatistics>;
  owlStatisticsErrors: Map<ID, Error>;
}

export type IOverwatchAdvancedQueryStoreState = Partial<Dictionary<TeamQuery>>;

export interface TeamQuery {
  loading: boolean;
  error?: Error;
  result?: GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery;
  latestQuery: QueryForm;
}

export interface StoredCustomKeybindings {
  version    : string;
  keybindings: IKeybinding[];
}

export interface IKeybinding {
  key    : string;
  command: KeyCommand;
  when?  : string;
}

export interface CustomKeybinding extends IKeybinding {
  source: 'user';
}

export interface DefaultKeybinding extends IKeybinding {
  source: 'default';
}

export type Keybinding = CustomKeybinding | DefaultKeybinding;

export type SessionVideoFallback = Pick<Video, 'id' | 'name' | 'duration' | 'remoteUrl'> & { streamUrl?: string };

export interface IKMSession {
  ws: WebSocket;
  fallback?: SessionVideoFallback;
  host: ID;
  team: ID;
  userMap: Map<ID, ISessionProfile>;
  usersInChat: Set<ID>;
  colorIterator: IterableIterator<Color>;
  initialSessionState: ICry;
  token: string;
  guestName?: string;
  secret?: string;

  grants: Set<Capability>;
  perUserGrants?: Map<ID, Set<Capability>>;
  globalGrants?: Set<Capability>;
}

export * from './cache';
export * from './paged-set';
