import { FlexSpacer, Loader } from '@insights-gaming/material-components';
import { LeagueMetadata, LoLHelper } from '@insights-gaming/statistics';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import { fetchLeagueOfLegendsStatsAC, makeGetFetchStatusByVideoId, makeGetLeagueStatsByVideoId } from '../common';
import LeagueStatistics from './LeagueStatistics';
import StatisticsError from './StatisticsError';

interface LeagueOfLegendsStatisticsWrapperOwnProps {
  className?: string;
  videoId: ID;
  gameMetadata: LeagueMetadata;
}

type LeagueOfLegendsStatisticsWrapperProps = LeagueOfLegendsStatisticsWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'LeagueOfLegendsStatisticsWrapper'});

function LeagueOfLegendsStatisticsWrapper(props: LeagueOfLegendsStatisticsWrapperProps) {
  const classes = useStyles(props);
  const { className, videoId, gameMetadata } = props;
  const { t } = useStrictTranslation(['common']);

  const dispatch = useDispatch();

  const lolStats = useCreateSelector(makeGetLeagueStatsByVideoId, { videoId });

  useEffect(() => {
    if (videoId) {
      const region = LoLHelper.getRegion(gameMetadata.region);
      if (!region) {
        return;
      }
      const proxyUrl = LoLHelper.buildLeagueProxyUrl(window.location.origin, region, gameMetadata.matchId);
      dispatch(fetchLeagueOfLegendsStatsAC.started({url: proxyUrl, videoUuid: videoId}));
    }
  }, [dispatch, gameMetadata, videoId]);

  const fetchStatus = useCreateSelector(makeGetFetchStatusByVideoId, { videoId });

  const fetching = fetchStatus?.fetching;
  const error = fetchStatus?.error;

  return fetching ? (
    <FlexSpacer fullWidth={true} flexAlignItems='center' flexJustifyContent='center'>
      <Loader>
        {t('common:loading.generic')}
      </Loader>
    </FlexSpacer>
  ) : lolStats ? (
    <LeagueStatistics
    className={classNames(classes.root, className)}
    lolStats={lolStats}
    gameMetadata={gameMetadata}
    />
  ) : (
    <StatisticsError error={error} />
  );
}

export default React.memo(LeagueOfLegendsStatisticsWrapper);
