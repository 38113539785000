import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { useFetchDirectoryIfNecessary } from 'features/dashboard/directory/useFetchDirectoryIfNecessary';
import { useFetchTeamIfNecessary } from 'features/dashboard/team/useFetchTeamIfNecessary';
import { VideoHelper } from 'features/video-library/video-helpers';
import { useMemo } from 'react';
import { dashboardRoute, folderRoute, teamRoute } from 'routes';

import { useReferPath } from './useReferPath';

export function useVideoBackPath(video?: VideoFragment) {
  const [team, sharedDirectoryTeam] = useFetchTeamIfNecessary(video && VideoHelper.getTeamId(video));
  const teamId = team?.id || sharedDirectoryTeam?.id;

  const [teamDirectory, sharedDirectory] = useFetchDirectoryIfNecessary(video && VideoHelper.getDivisionId(video));
  const directoryId = teamDirectory?.id || sharedDirectory?.id;

  const referPath = useReferPath();
  return useMemo(() => {
    if (referPath && referPath !== window.location.pathname) {
      return referPath;
    }

    if (directoryId) {
      return folderRoute(directoryId);
    }

    if (teamId) {
      return teamRoute(teamId);
    }

    return dashboardRoute();
  }, [directoryId, referPath, teamId]);
}
