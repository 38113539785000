import { BidirectionalIDRecord } from '@insights-gaming/redux-utils';
import { RosterPlayersFragment } from 'apollo/fragments/types/RosterPlayersFragment';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';

import { ID } from '../../types/pigeon';

const getTeamRosterIds = (state: RootReducer, teamId: ID): BidirectionalIDRecord | undefined => {
  return state.statisticsReducer.teamRosterRecords[teamId];
};

const getTeamRosterCache = (state: RootReducer, teamId: ID): Dictionary<RosterPlayersFragment> | undefined => {
  return state.statisticsReducer.teamRosterDict[teamId];
};

const getTeamPrimaryRosterId = (state: RootReducer, teamId: ID): ID | undefined => {
  return state.statisticsReducer.teamPrimaryRosterIds[teamId];
};

export const makeGetTeamRosterPagedIds = () => createSelector(
  [getTeamRosterIds],
  (pagedId: BidirectionalIDRecord | undefined) => pagedId,
);

export const makeGetTeamRosterCache = () => createSelector(
  [getTeamRosterCache],
  (cache: Dictionary<RosterPlayersFragment> | undefined) => cache,
);

export const makeGetTeamPrimaryRosterId = () => createSelector(
  [getTeamPrimaryRosterId],
  (id: ID | undefined) => id,
);

export const makeGetTeamPrimaryRoster = () => createSelector(
  [getTeamRosterCache, getTeamPrimaryRosterId],
  (cache: Dictionary<RosterPlayersFragment> | undefined, id: ID | undefined) => cache?.[id!],
);
