import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { ContentState } from 'draft-js';
import React, { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getMe } from 'selectors/getMe';

import { placeholder } from '../../../assets';
import UndraggableAvatar from '../../../material/undraggable-avatar/UndraggableAvatar';

export interface IMentionOwnProps {
  contentState: ContentState;
  entityKey: string;
}
export type MentionProps = PropsWithChildren<IMentionOwnProps>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  tooltip: {
    padding: 0,
  },
  isMentioned: {
    color: '#8A88FF',
    fontWeight: 700,
  },
}), {name: 'Mention'});

const Mention: React.FC<MentionProps> = (props: MentionProps) => {
  const classes = useStyles(props);

  const { children, contentState, entityKey } = props;

  const me = useSelector(getMe);

  const isMentioned = useMemo(() => {
    const entityType = contentState.getEntity(entityKey).getType();
    return entityType === 'MENTION' && contentState.getEntity(entityKey).getData().id === me?.id;
  }, [contentState, entityKey, me?.id]);

  const user = useMemo(() => {
    const data = contentState.getEntity(entityKey).getData();
    if (data.payload) {
      return data.payload;
    }
    return data;
  }, [contentState, entityKey]);

  const title = useMemo(() => {
    return user && (
      <Paper>
        <Box display='flex' p={1}>
          <Box mr={1}>
            <UndraggableAvatar src={user.picture || placeholder} />
          </Box>
          <Box display='flex' alignItems='center'>{user.alias}</Box>
        </Box>
      </Paper>
    );
  }, [user]);

  return (
    <Tooltip
    title={title}
    placement='top'
    classes={{tooltip: classes.tooltip}}
    >
      <Link
      // TODO: enable this when we have actual profile pages
      // to={href}
      to='#'
      className={classNames({[classes.isMentioned]: isMentioned})}
      >
        {children}
      </Link>
    </Tooltip>
  );
};

export default Mention;
