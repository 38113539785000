import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetTeamDirectoryUsersRecordsByDirectoryId } from './dashboard-directory-selector';
import { fetchTeamDirectoryUsersAC } from './dashboard-directory-slice';

export const useFetchDirectoryUsers = createBidirectionalAutoFetcher({
  actionCreators: fetchTeamDirectoryUsersAC,
  selectorFactory: makeGetTeamDirectoryUsersRecordsByDirectoryId,
  paramsTransform: fetchId => ({directoryId: fetchId}),
  pageSize: 10,
});
