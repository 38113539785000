import { Theme } from '@insights-gaming/theme';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import classNames from 'classnames';
import update, { Spec } from 'immutability-helper';
import VideoPlayerDialogContent from 'material/dialogs/video-player-dialog-content/VideoPlayerDialogContent';
import memoizeOne from 'memoize-one';
import React from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';

import {
  buildOWL2020TeamLogoPath,
  combineIds,
  defaultIconOptions,
  overwatchHeroIconPath,
  teamNames,
  ultimateFromHeroName,
} from '../../../../../helpers';
import { formatDuration } from '../../../../../helpers/formatters';
import {
  WithAsyncOverwatchAbilityIcons,
  withAsyncOverwatchAbilityIcons,
} from '../../../../../hoc/withAsyncOverwatchAbilityIcons';
import { OwstatsF, OwstatsNS } from '../../../../../locales/en/owstats';
import UndraggableAvatar from '../../../../../material/undraggable-avatar/UndraggableAvatar';
import { ID, OWL2020Team, OWLVideo } from '../../../../../types/pigeon';
import { GeneratedOverwatchMatch } from '../../../../../types/pigeon/matches';
import {
  IOverwatchMatchDataTeamfight,
  IOverwatchMatchDataTeamfightUltimate,
  OverwatchTeamColor,
} from '../../../../../types/pigeon/overwatch-match-data';
import { IOWLUserMetadata } from '../../../../../types/pigeon/owl-match';
import OverwatchHeroCompImages from './OverwatchHeroCompImages';

export interface IOverwatchTeamfightBreakdownTableRowOwnProps {
  match: GeneratedOverwatchMatch;
  teamfight: IOverwatchMatchDataTeamfight;
  userMetadata?: IOWLUserMetadata;
  teamfightNum: number;
  videoId: ID;
  owlVideo?: OWLVideo;
}

export type OverwatchTeamfightBreakdownTableRowProps = IOverwatchTeamfightBreakdownTableRowOwnProps &
  WithAsyncOverwatchAbilityIcons &
  WithStyles<typeof styles> &
  WithTranslation;

const styles = (theme: Theme) => {
  return createStyles({
    blue: {
      border: `1px solid ${theme.palette.overwatch.blueTeam}`,
    },
    red: {
      border: `1px solid ${theme.palette.overwatch.redTeam}`,
    },
    ultimates: {
      display: 'flex',
      width: 'fit-content',
      margin: 'auto',
    },
    ultimate: {
      fill: theme.palette.text.primary,
    },
    teamfightTime: {
      whiteSpace: 'pre',
    },
  });
};

interface IState {
  openVideoPlayerDialog?: boolean;
}

class OverwatchTeamfightBreakdownTableRow extends React.Component<OverwatchTeamfightBreakdownTableRowProps, IState> {
  private memoizedPlayers = memoizeOne((match: GeneratedOverwatchMatch, teamColor: OverwatchTeamColor) =>
    teamColor === 'blue' ? match.players.slice(0, 6) : match.players.slice(6),
  );

  private closeVideoPlayerDialog = this.toggleDialog('openVideoPlayerDialog', false);
  private openVideoPlayerDialog  = this.toggleDialog('openVideoPlayerDialog', true);

  constructor(props: OverwatchTeamfightBreakdownTableRowProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { t, teamfight, teamfightNum, classes, userMetadata, match } = this.props;
    const blueTeamPlayers = this.memoizedPlayers(match, 'blue');
    const redTeamPlayers = this.memoizedPlayers(match, 'red');
    return (
      <React.Fragment key={teamfight.startTime}>
        <TableRow>
          <TableCell rowSpan={2} align='center'>{teamfightNum + 1}</TableCell>
          <TableCell rowSpan={2} align='center'>
            <Button
            variant='outlined'
            className={classes.teamfightTime}
            onClick={this.openVideoPlayerDialog}
            >
              {formatDuration(teamfight.startTime)} - {formatDuration(teamfight.endTime)}
            </Button>
          </TableCell>
          {/* TODO: Need Pictures here */}
          {userMetadata && (
            <TableCell align='center'>
              {this.renderTeamLogo(userMetadata.team1_name)}
            </TableCell>
          )}
          <TableCell align='center'>
            {teamfight.winner === 'blue'
              ? t(OwstatsF('teamfightbreakdown.win'))
              : t(OwstatsF('teamfightbreakdown.lose'))}
          </TableCell>
          <TableCell align='center'>
            <OverwatchHeroCompImages
            endTime={teamfight.endTime}
            heroes={teamfight.blueHeroes}
            teamColor='blue'
            events={teamfight.events}
            players={blueTeamPlayers}
            />
          </TableCell>
          <TableCell align='center'>{teamfight.blueTeamKills}</TableCell>
          <TableCell>
            <Box className={classNames(classes.ultimates, classes.blue)}>
              {teamfight.blueTeamUltsBefore.map(this.renderUlts)}
            </Box>
          </TableCell>
          <TableCell rowSpan={2}>
            {teamfight.firstUlt.hero &&
              <UndraggableAvatar
              src={overwatchHeroIconPath(teamfight.firstUlt.hero, defaultIconOptions)}
              className={classes[teamfight.firstUlt.team]}
              />
            }
          </TableCell>
          <TableCell rowSpan={2}>
            {teamfight.firstKill.hero &&
              <UndraggableAvatar
              src={overwatchHeroIconPath(teamfight.firstKill.hero, defaultIconOptions)}
              className={classes[teamfight.firstKill.team]}
              />
            }
          </TableCell>
          <TableCell rowSpan={2}>
            {teamfight.firstDeath.hero &&
              <UndraggableAvatar
              src={overwatchHeroIconPath(teamfight.firstDeath.hero, defaultIconOptions)}
              className={classes[teamfight.firstDeath.team]}
              />
            }
          </TableCell>
          <TableCell>
            {teamfight.blueTeamUltsUsed.length > 0 &&
              <Box className={classNames(classes.ultimates, classes.blue)}>
                {teamfight.blueTeamUltsUsed.map(this.renderUlts)}
              </Box>
            }
          </TableCell>
        </TableRow>
        <TableRow>
          {/* TODO: Need Pictures here */}
          {userMetadata && (
            <TableCell align='center'>
              {this.renderTeamLogo(userMetadata.team2_name)}
            </TableCell>
          )}
          <TableCell align='center'>
            {teamfight.winner === 'red'
              ? t(OwstatsF('teamfightbreakdown.win'))
              : t(OwstatsF('teamfightbreakdown.lose'))}
          </TableCell>
          <TableCell align='center'>
            <OverwatchHeroCompImages
            endTime={teamfight.endTime}
            heroes={teamfight.redHeroes}
            teamColor='red'
            events={teamfight.events}
            players={redTeamPlayers}
            />
          </TableCell>
          <TableCell align='center'>{teamfight.redTeamKills}</TableCell>
          <TableCell>
            <Box className={classNames(classes.ultimates, classes.red)}>
              {teamfight.redTeamUltsBefore.map(this.renderUlts)}
            </Box>
          </TableCell>
          <TableCell>
            {teamfight.redTeamUltsUsed.length > 0 &&
              <Box className={classNames(classes.ultimates, classes.red)}>
                {teamfight.redTeamUltsUsed.map(this.renderUlts)}
              </Box>
            }
          </TableCell>
        </TableRow>
        {this.renderVideoPlayerDialog()}
      </React.Fragment>
    );
  }

  private renderTeamLogo = (teamName: OWL2020Team) => {
    const { t } = this.props;
    const teamImage = buildOWL2020TeamLogoPath(teamName);
    return (
      <UndraggableAvatar src={teamImage} title={t(OwstatsF(teamNames(teamName)))} size='xs' />
    );
  }

  private renderVideoPlayerDialog = () => {
    const { openVideoPlayerDialog } = this.state;
    const { videoId, teamfight, match, owlVideo } = this.props;
    return (
      <Dialog
      open={!!openVideoPlayerDialog}
      onClose={this.closeVideoPlayerDialog}
      fullWidth={true}
      maxWidth='xl'
      >
        <VideoPlayerDialogContent
        videoId={videoId}
        startTime={teamfight.startTime}
        endTime={teamfight.endTime}
        owlVideo={owlVideo}
        />
      </Dialog>
    );
  }

  private renderUlts = (ultDetail: IOverwatchMatchDataTeamfightUltimate, idx: number) => {
    const { getIconFromAbilityName, classes } = this.props;
    const hero = ultDetail.hero;
    const ult = ultimateFromHeroName(hero);
    const Ultimate = getIconFromAbilityName(ult) || HelpIcon;
    const isReady = !!ultDetail.status && ultDetail.status === 'ready';
    return (
      <Badge
      key={combineIds(ultDetail.status, ultDetail.index, ultDetail.hero, idx)}
      invisible={ultDetail.status === undefined || !isReady}
      badgeContent={
        <CheckCircleIcon
        fontSize='small'
        htmlColor='cyan'
        />
      }
      >
        <Ultimate
        width={30}
        opacity={ultDetail.status === undefined || isReady ? 1 : 0.5}
        className={classes.ultimate}
        />
      </Badge>
    );
  }

  private toggleDialog(dialog: keyof IState, isOpen?: boolean): VoidFunction {
    return () => {
      const spec: Spec<IState, never> = {$toggle: []};
      switch (isOpen) {
        case true   :
        case false  : spec[dialog] = {$set: isOpen};
             default: spec.$toggle = [dialog];
      }
      this.setState(state => update(state, spec));
    };
  }
}

export default withAsyncOverwatchAbilityIcons({})(
  withTranslation([OwstatsNS])(
    withStyles(styles)(
      OverwatchTeamfightBreakdownTableRow,
    ),
  ),
);
