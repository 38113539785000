import { FlexSpacer } from '@insights-gaming/material-components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RosterPlayersFragment } from '../../../../../apollo/fragments/types/RosterPlayersFragment';
import {
  GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFightSummary,
  GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFightSummary_enemyFirstDeathHero,
  GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFightSummary_enemyFirstKillHero,
  GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFightSummary_firstDeathHero,
  GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFightSummary_firstKillHero,
} from '../../../../../apollo/queries/types/GetOverwatchAdvancedQueryQuery';
import { OwstatsF, OwstatsNS } from '../../../../../locales/en/owstats';
import TableHeader, { ITableColumnHeader } from '../../../../../material/table/TableHeader';
import HeroIcon from './HeroIcon';

type KillDeathHeroInfo = GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFightSummary_firstKillHero
  | GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFightSummary_firstDeathHero
  | GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFightSummary_enemyFirstKillHero
  | GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFightSummary_enemyFirstDeathHero;

export interface IOverwatchAdvancedQuerySummaryTableOwnProps {
  summary: GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery_teamFightSummary;
  primaryRoster: RosterPlayersFragment;
}

type Props = IOverwatchAdvancedQuerySummaryTableOwnProps;

const columns: ITableColumnHeader[] = [
  {
    title: OwstatsF('teamfightstats.team'),
  },
  {
    title: OwstatsF('teamfightstats.teamfights'),
  },
  {
    title: OwstatsF('teamfightstats.teamfightwin'),
    tooltip: {
      abbr: OwstatsF('teamfightstats.teamfightwinabbr'),
    },
  },
  {
    title: OwstatsF('teamfightstats.kills'),
  },
  {
    title: OwstatsF('teamfightstats.deaths'),
  },
  {
    title: OwstatsF('teamfightstats.firstkills'),
  },
  {
    title: OwstatsF('teamfightstats.firstdeaths'),
  },
  {
    title: OwstatsF('teamfightstats.firstkillheroes'),
    tooltip: {
      abbr: OwstatsF('teamfightstats.firstkillheroesabbr'),
    },
    align: 'center',
  },
  {
    title: OwstatsF('teamfightstats.firstdeathheroes'),
    tooltip: {
      abbr: OwstatsF('teamfightstats.firstdeathheroesabbr'),
    },
    align: 'center',
  },
];

function OverwatchAdvancedQuerySummaryTable(props: Props) {
  const { summary, primaryRoster } = props;

  const { t } = useTranslation([OwstatsNS]);

  const totalFirstKills = useMemo(() => {
    const { firstKillHero } = summary;
    let total = 0;
    for (let h of firstKillHero) {
      total += h.value;
    }
    return total;
  }, [summary]);

  const totalFirstDeaths = useMemo(() => {
    const { firstDeathHero } = summary;
    let total = 0;
    for (let h of firstDeathHero) {
      total += h.value;
    }
    return total;
  }, [summary]);

  const renderHeroWithStats = useCallback((hero: KillDeathHeroInfo, i: number) => {
    if (!hero.name) { return <div key={i}>{t(OwstatsF('queries.nodata'))}</div>; }
    return (
      <div key={hero.name + i}>
        <HeroIcon hero={hero.name} />
        <div>{hero.value}/{hero.total}</div>
      </div>
    );
  }, [t]);

  const renderBody = useCallback(() => {
    const {
      totalFights,
      wins,
      kills,
      deaths,
      firstKillHero,
      firstDeathHero,
      enemyFirstKillHero,
      enemyFirstDeathHero,
    } = summary;
    return (
      <TableBody>
        <TableRow>
          <TableCell>
            {primaryRoster.name || t(OwstatsF('queries.summary.primaryroster'))}
          </TableCell>
          <TableCell>{totalFights}</TableCell>
          <TableCell>{totalFights && (wins/totalFights).toFixed(2)}</TableCell>
          <TableCell>{kills}</TableCell>
          <TableCell>{deaths}</TableCell>
          <TableCell>{totalFirstKills}</TableCell>
          <TableCell>{totalFirstDeaths}</TableCell>
          <TableCell align='center'>
            <FlexSpacer flexJustifyContent='center'>
              {firstKillHero.map(renderHeroWithStats)}
            </FlexSpacer>
          </TableCell>
          <TableCell align='center'>
            <FlexSpacer flexJustifyContent='center'>
              {firstDeathHero.map(renderHeroWithStats)}
            </FlexSpacer>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t(OwstatsF('queries.summary.enemyteam'))}</TableCell>
          <TableCell>{totalFights}</TableCell>
          <TableCell>{totalFights && ((totalFights-wins)/totalFights).toFixed(2)}</TableCell>
          <TableCell>{deaths}</TableCell>
          <TableCell>{kills}</TableCell>
          <TableCell>{totalFirstDeaths}</TableCell>
          <TableCell>{totalFirstKills}</TableCell>
          <TableCell align='center'>
            <FlexSpacer flexJustifyContent='center'>
              {enemyFirstKillHero.map(renderHeroWithStats)}
            </FlexSpacer>
          </TableCell>
          <TableCell align='center'>
            <FlexSpacer flexJustifyContent='center'>
              {enemyFirstDeathHero.map(renderHeroWithStats)}
            </FlexSpacer>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }, [summary, t, totalFirstKills, totalFirstDeaths, primaryRoster, renderHeroWithStats]);

  return (
    <Table>
      <TableHeader headers={columns} t={t} />
      {renderBody()}
    </Table>
  );
}

export default React.memo(OverwatchAdvancedQuerySummaryTable);
