import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';

import { ID, TeamEdge } from '../../types/pigeon';


export const getTeamCache = (state: RootReducer): Partial<Dictionary<TeamEdge>> => state.dashboardTeam.teamCache;

interface ISelectedTeam {
  selectedTeamId?  : ID;
  selectedTeamEdge?: TeamEdge;
}

export const getSelectedTeamId = (state: RootReducer) => state.dashboardTeam.selectedTeamId;

export const getSelectedTeamEdge = createSelector(
  [getTeamCache, getSelectedTeamId],
  (teamCache: Dictionary<TeamEdge>, selectedTeamId?: ID): ISelectedTeam => {
    if (selectedTeamId) {
      const selectedTeamEdge = teamCache[selectedTeamId];
      if (selectedTeamEdge) {
        return {
          selectedTeamEdge,
          selectedTeamId,
        };
      }
      return {selectedTeamId};
    }
    return {};
  },
);

export const getTeamEdgeById = (state: RootReducer, teamId?: ID) => teamId
  ? getTeamCache(state)[teamId]
  : undefined;

export const makeGetTeamEdgeById = () => createSelector(
  [getTeamEdgeById],
  (teamEdge?: TeamEdge) => teamEdge,
);
