import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import React from 'react';

import InventoryMetrics from './InventoryMetrics';

interface InventoryMetricsSkeletonOwnProps {
  className?: string;
  hideButton?: boolean;
}

type InventoryMetricsSkeletonProps = InventoryMetricsSkeletonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  buttonLabel: {
    width: 'inherit',
  },
}), {name: 'InventoryMetricsSkeleton'});

function InventoryMetricsSkeleton(props: InventoryMetricsSkeletonProps) {
  const classes = useStyles(props);
  const { className, hideButton } = props;
  return (
    <InventoryMetrics
    metricLabel={<Skeleton variant='text' width={105} />}
    metrics={<Skeleton variant='text' width={105} />}
    progressBar={<Skeleton variant='text' height={10} />}
    metricButton={!hideButton && <Skeleton variant='text' className={classes.buttonLabel} />}
    skeleton={true}
    className={classNames(classes.root, className)}
    />
  );
}

export default React.memo(InventoryMetricsSkeleton);
