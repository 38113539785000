import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { Invitation2Fragment } from 'apollo/fragments/types/Invitation2Fragment';
import classNames from 'classnames';
import { createInvitationAC } from 'features/dashboard/team/dashboard-team-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { EGlobalRoles } from 'material/dialogs/invite-team-members-dialog-content/role-helper';
import React, { useCallback, useEffect, useState } from 'react';

import { ASSETS_ENDPOINT } from '../../constants';
import AllDone from './AllDone';
import FirstChannel from './FirstChannel';
import FirstTeam from './FirstTeam';
import Invite from './Invite';
import ProfilePic from './ProfilePic';
import ProgressIndicator from './ProgressIndicator';
import Username from './Username';
import Welcome from './Welcome';

interface GuidedSetupDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
  directory: DirectoryFragment;
}

type GuidedSetupDialogProps = GuidedSetupDialogOwnProps;

const makeBackgroundImage = (index: number) =>
  `url(${ASSETS_ENDPOINT}/guided-setup/background-${index}.svg)`;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: createRemFromPx(600),
    height: createRemFromPx(438),
    backgroundSize: 'cover',
  },
  action: {
    position: 'absolute',
    bottom: 24,
    right: 24,
    padding: 0,
  },
}), {name: 'GuidedSetupDialog'});

function GuidedSetupDialog(props: GuidedSetupDialogProps) {
  const { open } = props;

  return (
    <Dialog open={open} maxWidth='md'>
      <GuidedSetupDialogContent {...props} />
    </Dialog>
  );
}

const Contents: Array<React.ComponentType<{
  onClose: VoidFunction;
  stepForward: VoidFunction;
  stepBackward: VoidFunction;
  step: number;
  directory: DirectoryFragment;
  createdInvitation: Invitation2Fragment | undefined;
}>> = [
  Welcome,
  Username,
  ProfilePic,
  FirstTeam,
  FirstChannel,
  Invite,
  AllDone,
];

function GuidedSetupDialogContent(props: GuidedSetupDialogProps) {
  const classes = useStyles(props);
  const { className, onClose, directory } = props;
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const [ step, setStep ] = useState(0);

  const [ createdInvitation, setCreatedInvitation ] = useState<Invitation2Fragment>();

  const stepBackward = useCallback(() => setStep((prevStep) => prevStep === 0 ? 0 : prevStep-1), []);

  const stepForward = useCallback(() => setStep((prevStep) => prevStep === 6 ? 6 : prevStep+1), []);

  useEffect(() => {
    (async () => {
      const createdInvitation = await promiseSagaDispatch(createInvitationAC, {
        teamId: directory.team.id,
        expiry: 'P7D',
        globalRoles: [EGlobalRoles.MEMBER],
      });
      setCreatedInvitation(createdInvitation.invitation);
    })();
  }, [directory.team.id, promiseSagaDispatch]);

  return (
    <div className={classNames(classes.root, className)}
    style={{ backgroundImage: makeBackgroundImage(step+1) }}
    >
      <DialogContent>
        {React.createElement(Contents[step], {
          onClose,
          stepBackward,
          stepForward,
          step,
          directory,
          createdInvitation,
        })}
        {step > 0 && step < 6 && <ProgressIndicator step={step}/>}
      </DialogContent>
    </div>
  );
}

export default React.memo(GuidedSetupDialog);
