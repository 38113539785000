import gql from 'graphql-tag';

import { VideoFragment } from '../fragments';

export const RenameVideo_Mutation = gql`
    mutation RenameVideoMutation(
        $input: RenameVideoInput!
    ) {
        renameVideo(
            input: $input
        ) {
            video {
                ...VideoFragment
            }
        }
    }
    ${VideoFragment}
`;
