import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Badge from '@material-ui/core/Badge';
import { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { DirectoryFragment_Division } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import classNames from 'classnames';
import DropIndicator from 'components/drop-indicator/DropIndicator';
import { makeGetChannelNotificationSettingsDictByChannelId } from 'components/settings/notification-settings/notificaion-settings-selector';
import { fetchDirectoryNotificationSettingsAC } from 'components/settings/notification-settings/notification-settings-slice';
import { MAX_NOTIFICATIONS } from 'components/settings/notifications/constants';
import { makeGetUnreadFolderNotificationsByFolderId } from 'components/settings/notifications/notifications-selector';
import UnreadFolderNotificationsFetcher from 'components/settings/notifications/unread-notifications/UnreadFolderNotificationsFetcher';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { DirectoryHelper } from 'features/dashboard/directory/dashboard-directory-helper';
import DirectoryContextMenu from 'features/dashboard/directory/directory-context-menu/DirectoryContextMenu';
import { DragItem, ItemTypes } from 'features/dashboard/dnd/item-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { FolderPublicity, MuteLevel } from 'types/graphql';

import DivisionListItem from './DivisionListItem';

interface DropDivisionListItemOwnProps {
  className?: string;
  division: DirectoryFragment_Division;
  onClick?: (e: React.MouseEvent, division: DirectoryFragment_Division) => void;
  onItemDropped?: (division: DirectoryFragment_Division, item: DragItem) => void;
  onFilesDropped?: (
    directory: DirectoryFragment_Division,
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ) => void;
  shouldHideMenu?: boolean;
}

type DropDivisionListItemProps = DropDivisionListItemOwnProps & Pick<ListItemProps, 'selected'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&$isOver$canDrop': {
      backgroundColor: 'pink',
    },
  },
  dropIndicator: {
    pointerEvents: 'unset',
  },
  canDrop: {},
  isOver: {},
}), {name: 'DropDivisionListItem'});

function DropDivisionListItem(props: DropDivisionListItemProps) {
  const classes = useStyles(props);
  const { className, division, onClick, onItemDropped, onFilesDropped, selected, shouldHideMenu } = props;

  const { canOpenDirectoryMenu } = useAccessControl();
  const dispatch = useDispatch();

  const handleClick = useCallback((e: React.MouseEvent) => {
    onClick?.(e, division);
  }, [division, onClick]);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [ItemTypes.DIRECTORY_CARD, ItemTypes.VIDEO_CARD],
    canDrop: (item: DragItem) => {
      if (item.id === division.id) {
        return false;
      }
      if (item.type === ItemTypes.DIRECTORY_CARD) {
        return item.parentId !== division.id;
      }
      return true;
    },
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item: DragItem) => {
      onItemDropped?.(division, item);
    },
  });

  const handleFilesDropped = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    onFilesDropped?.(division, acceptedFiles, rejectedFiles);
  }, [division, onFilesDropped]);

  const { getRootProps, rootRef, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'video/*',
    onDrop: handleFilesDropped,
    noClick: true,
    noKeyboard: true,
  });

  drop(rootRef);

  const channelSettingsState = useCreateSelector(makeGetChannelNotificationSettingsDictByChannelId, division.id);

  useEffect(() => {
    if (!channelSettingsState) {
      dispatch(fetchDirectoryNotificationSettingsAC.started({
        teamId: division.team.id,
        directoryId: division.id,
      }));
    }
  }, [channelSettingsState, dispatch, division]);

  const muted = useMemo(() => {
    if (!(channelSettingsState && channelSettingsState.settings && channelSettingsState.settings.length)) {
      return false;
    }
    return !!channelSettingsState.settings.find(s => s.level === MuteLevel.ALL);
  }, [channelSettingsState]);

  const numOfNotifications = useCreateSelector(makeGetUnreadFolderNotificationsByFolderId, division.id);

  return (
    <React.Fragment>
      {!numOfNotifications && <UnreadFolderNotificationsFetcher teamId={division.team.id} folderId={division.id} />}
      <DivisionListItem
      // ref={itemRef}
      {...getRootProps()}
      className={classNames(
        classes.root,
        {
          [classes.canDrop]: canDrop,
          [classes.isOver]: isOver,
        },
        className,
      )}
      name={division.name}
      divisionIcon={(
        <ListItemIcon>
          <Badge badgeContent={numOfNotifications} max={MAX_NOTIFICATIONS} color='error'>
            <div>
              {division.publicity === FolderPublicity.PRIVATE ? (
                <LockIcon />
              ) : (
                <FontAwesomeIcon icon={faHashtag} fixedWidth={true} size='lg' />
              )}
            </div>
          </Badge>
        </ListItemIcon>
      )}
      muted={muted && <NotificationsOffIcon fontSize='small' />}
      onClick={handleClick}
      selected={selected}
      contextMenu={DirectoryHelper.hasProperId(division) && !shouldHideMenu && (
        <DirectoryContextMenu directory={division} muted={muted} />
      )}
      >
        <DropIndicator
        isDragActive={isDragActive}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        />
      </DivisionListItem>
    </React.Fragment>
  );
}

export default React.memo(DropDivisionListItem);
