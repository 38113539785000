import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { createStyles,withStyles } from '@material-ui/core/styles';

import { mobileLandscape } from '../../features/media-queries';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    overflow: 'hidden',
    maxHeight: 'inherit',
    [mobileLandscape(theme)]: {
      overflow: 'visible',
    },
  },
});

const AppContentBox = withStyles(styles, {name: 'AppContentBox'})(Box);
export default AppContentBox;
