import { Loader } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { LogoProgress } from 'assets/logos';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import TransparentDialog from 'material/transparent-dialog/TransparentDialog';
import React from 'react';

import { ConnectingLiveSessionState, ReconnectingLiveSessionState } from './live-session-slice';

interface LiveSessionLoadingScreenOwnProps {
  className?: string;
  state: ConnectingLiveSessionState | ReconnectingLiveSessionState;
}

type LiveSessionLoadingScreenProps = LiveSessionLoadingScreenOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
  },
}), {name: 'LiveSessionLoadingScreen'});

function LiveSessionLoadingScreen(props: LiveSessionLoadingScreenProps) {
  const classes = useStyles(props);
  const { state } = props;
  const { t } = useStrictTranslation(['video']);

  return (
    <TransparentDialog open={true}>
      <Loader
      progress={<LogoProgress key='logoprogress'/>}
      >
        {t(`video:session.loading.${state.type}`)}
      </Loader>
    </TransparentDialog>
  );
}

export default React.memo(LiveSessionLoadingScreen);
