import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';

export interface ITableColumnHeader {
  title: string;
  tooltip?: {
    abbr: string;
  }
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  displayEmpty?: boolean;
}

export interface ITableHeaderOwnProps {
  headers: ITableColumnHeader[];
  t: TFunction;
}

type Props = ITableHeaderOwnProps;

function TableHeader(props: Props) {
  const { headers, t } = props;

  const translatedHeaders = useMemo(() => {
    return headers.map((h: ITableColumnHeader) => {
      return applyTranslate(h, t);
    });
  }, [headers, t]);

  const renderHeaderColumn = useCallback((header: ITableColumnHeader, i: number) => {
    switch (header.tooltip) {
      case undefined:
      case null:
        return <TableCell key={i} align={header.align}>{!header.displayEmpty && header.title}</TableCell>;
      default:
        return (
          <Tooltip key={i} title={header.title} placement='top'>
            <TableCell align={header.align}>{header.tooltip.abbr}</TableCell>
          </Tooltip>
        );
    }
  }, []);

  return (
    <TableHead>
      <TableRow>
        {translatedHeaders.map(renderHeaderColumn)}
      </TableRow>
    </TableHead>
  );
}

export default React.memo(TableHeader);

function applyTranslate(c: ITableColumnHeader, t: TFunction) {
  return {
    title: t(c.title),
    tooltip: c.tooltip ? {abbr: t(c.tooltip.abbr)} : undefined,
    align: c.align,
  };
}
