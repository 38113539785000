import gql from 'graphql-tag';

import { SavedCardFragment } from '../fragments';

export const UpdateTeamCard_Mutation = gql`
    mutation UpdateTeamCardMutation(
        $input: UpdateTeamCardInput!
    ) {
        updateTeamCard(
            input: $input
        ) {
            card {
                ...SavedCardFragment
            }
        }
    }
    ${SavedCardFragment}
`;
