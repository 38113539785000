import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { createStyles,withStyles } from '@material-ui/core/styles';

import { desktop, mobileLandscape } from '../../features/media-queries';

const styles = (theme: Theme) => createStyles({
  root: {
    overflow: 'hidden',
    margin: theme.spacing(1),
    marginTop: theme.spacing(0),
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [desktop(theme)]: {
      margin: theme.spacing(2),
    },
    [mobileLandscape(theme)]: {
      overflow: 'visible',
    },
  },
});

const AppContentWrapper = withStyles(styles, {name: 'AppContentWrapper'})(Box);
export default AppContentWrapper;
