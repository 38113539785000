import { TeamFragment } from 'apollo/fragments/types/TeamFragment';

export default function useFreeTrialElegibility(team: TeamFragment | undefined) {
  // const me = useSelector(getMe);

  // return useMemo(() => {
  //   return team?.freeTrial && me?.freeTrial;
  // }, [me?.freeTrial, team?.freeTrial]);
  return false;
}
