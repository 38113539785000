import { VerticalScroll } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import TeamSettingsTabs from 'components/dashboard/team-settings/team-settings-tabs/TeamSettingsTabs';
import UnalignedContainer from 'components/unaligned-container/UnalignedContainer';
import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import { Redirect,Route, Switch } from 'react-router-dom';
import { teamRoute } from 'routes';

import DashboardTeamSettingsRouting from './dashboard-team-settings.routing';
import DashboardTeamSettingsBillingRouting from './dashboard-team-settings-billing.routing';
import DashboardTeamSettingsBilling from './DashboardTeamSettingsBilling';
import DashboardTeamSettingsInvitations from './DashboardTeamSettingsInvitations';
import DashboardTeamSettingsMembers from './DashboardTeamSettingsMembers';
import DashboardTeamSettingsOverview from './DashboardTeamSettingsOverview';
import DashboardTeamSettingsRoles from './DashboardTeamSettingsRoles';

interface DashboardTeamSettingsOwnProps {
  className?: string;
  team: TeamFragment;
}

type DashboardTeamSettingsProps = DashboardTeamSettingsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
  },
}), {name: 'DashboardTeamSettings'});

function DashboardTeamSettings(props: DashboardTeamSettingsProps) {
  const classes = useStyles(props);
  const { className, team } = props;

  const settingsRouteMatch = useRouteMatch<DashboardTeamSettingsRouting.Params>({
    path: DashboardTeamSettingsRouting.path,
  });

  const renderOverview = useCallback(() => (
    <React.Fragment>
      <DashboardTeamSettingsOverview team={team} />
      {team.owner.__typename === 'Self' && (
        <DashboardTeamSettingsInvitations team={team} />
      )}
    </React.Fragment>
  ), [team]);

  const renderMembers = useCallback(() => (
    <DashboardTeamSettingsMembers team={team} />
  ), [team]);

  const renderRoles = useCallback(() => (
    <DashboardTeamSettingsRoles team={team} />
  ), [team]);

  const renderBilling = useCallback(() => (
    <DashboardTeamSettingsBilling team={team} />
  ), [team]);

  return (
    <VerticalScroll className={classNames(classes.root, className)}>
      {settingsRouteMatch?.params.tab !== DashboardTeamSettingsRouting.Tab.BILLING &&
        <TeamSettingsTabs team={team} />
      }
      <UnalignedContainer maxWidth='md' disableGutters={true}>
        <Switch>
          <Route path={DashboardTeamSettingsRouting.overviewPath} render={renderOverview} />
          <Route path={DashboardTeamSettingsRouting.membersPath} render={renderMembers} />
          <Route path={DashboardTeamSettingsRouting.rolesPath} render={renderRoles} />
          <Route path={DashboardTeamSettingsBillingRouting.path} render={renderBilling} />
          <Route>
            <Redirect to={{ pathname: teamRoute(team.id) }}/>
          </Route>
        </Switch>
      </UnalignedContainer>
    </VerticalScroll>
  );
}

export default React.memo(DashboardTeamSettings);
