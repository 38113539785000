import { ContextMenu, ContextMenuItem, FlexSpacer } from '@insights-gaming/material-components';
import { StyledChip } from '@insights-gaming/material-components';
import { BidirectionalIDRecord } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import LabelIcon from '@material-ui/icons/Label';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SearchIcon from '@material-ui/icons/Search';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import classNames from 'classnames';
import DragDropDirectoryCard from 'components/directory-card/DragDropDirectoryCard';
import PagedDirectoryGrid from 'components/directory-grid/PagedDirectoryGrid';
import DropIndicator from 'components/drop-indicator/DropIndicator';
import DragVideoCard from 'components/video-card/DragVideoCard';
import PagedVideoGrid from 'components/video-grid/PagedVideoGrid';
import { EIntercomID } from 'constants/strings';
import { updateVideosAC } from 'features/dashboard/video/dashboard-video-slice';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import CreateMultipleAnalysisRequestsDialogContent from 'material/dialogs/create-multiple-analysis-requests-dialog-content/CreateMultipleAnalysisRequestsDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useParams } from 'react-router';
import { folderPageRoute } from 'routes';
import TagChip from 'subcomponents/tag-chip/TagChip';
import { ID, ITag } from 'types/pigeon';

import { useAccessControl } from '../access-control/useAccessControl';
import DashboardRouting from '../dashboard.routing';
import { makeGetTeamDirectoryUsersByDirectoryId } from '../directory/dashboard-directory-selector';
import DirectoryPickerDialog from '../directory-picker-dialog/DirectoryPickerDialog';
import { DragItem } from '../dnd/item-types';
import { DashboardMultiSelectContext } from '../multi-select/DashboardMultiSelectContext';
import { multiSelectCheckboxProps } from '../multi-select/useMultiSelectState';
import { makeGetTagsByIds, makeGetTeamVideoTagsByTeamId } from '../tag/dashboard-tag-selector';
import MultiVideoTaggerDialog from '../tag/multi-video-tagger-dialog/MultiVideoTaggerDialog';
import { DashboardSelectedTagsContext } from '../tag-filter/DashboardSelectedTagsContext';
import { makeGetVideosByIds } from '../video/dashboard-video-selector';
import CollaboratorEditorDialog from './CollaboratorEditorDialog';
import DeleteSelectedDialog from './DeleteSelectedDialog';
import DirectoryUsers from './DirectoryUsers';

interface DashboardMainContentOwnProps {
  className?: string;
  directory?: DirectoryFragment;
  videosRecord?: BidirectionalIDRecord;

  folders?: DirectoryFragment[];
  initialFolderFetch?: boolean;
  onItemDroppedOnDirectory?: (directory: DirectoryFragment, item: DragItem) => void;
  onFilesDroppedOnDirectory?: (
    directory: DirectoryFragment,
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ) => void;
}

type DashboardMainContentProps = DashboardMainContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    flex: 1,
    overflow: 'hidden',
  },
  dropIndicator: {
    opacity: 0,
    '&$isOuterDragActive': {
      pointerEvents: 'unset',
      opacity: 'unset',
    },
  },
  floating: {
    position: 'absolute',
    backgroundColor: theme.palette.background.dimmed,
    padding: theme.spacing(.5),
    borderRadius: theme.shape.borderRadius,
    pointerEvents: 'none',
  },
  isOuterDragActive: {},
  isDragActive: {},
  isDragAccept: {},
  isDragReject: {},
  selectionBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  collaboratorWrapper: {
    padding: theme.spacing(1, 0),
  },
  filterSection: {
    flex: 1,
    '&$mobile': {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.primary.main}`,
      right: 0,
      top: 0,
    },
  },
  tagFilter: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    maxWidth: '100%',
    width: '100%',
    flexWrap: 'wrap',
    '&$mobile': {
      margin: 0,
    },
  },
  tagWrapper: {
    overflowX: 'auto',
    flex: 1,
    maxWidth: createRemFromPx(320),
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  selectButton: {
    marginLeft: theme.spacing(1),
  },
  selectedItemsChip: {
    border: `${theme.palette.secondary.light} 1px solid`,
    marginRight: theme.spacing(0.5),
  },
  mobile: {},
}), {name: 'DashboardMainContent'});

function DashboardMainContent(props: DashboardMainContentProps) {
  const classes = useStyles(props);
  const {
    className,
    directory,
    folders,
    initialFolderFetch,
    videosRecord,
    onItemDroppedOnDirectory,
    onFilesDroppedOnDirectory,
  } = props;

  const teamId = directory?.team?.id;

  const { canRequestSomeAnalysis, canDeleteVod, canAssignTag, canUpdateVod } = useAccessControl();
  const { enqueueSnackbar } = useSnackbar();

  const users = useCreateSelector(makeGetTeamDirectoryUsersByDirectoryId, directory?.id);

  const { t } = useStrictTranslation(['common', 'dashboard-main', 'dashboard-directory']);
  const onNavigate = useNavigate();
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const isDesktop = useIsDesktop();

  const params = useParams<{directoryid: string, page?: string}>();
  const page = useMemo(() => (parseInt(params.page || '') || 1) - 1, [params.page]);

  const handlePageChange = useCallback((page: number) => {
    onNavigate(folderPageRoute(params.directoryid || '', (page + 1).toString()));
  }, [onNavigate, params.directoryid]);

  const {
    folders: {
      selected: selectedFolderIds,
      addToSelection: addToFolderSelection,
      removeFromSelection: removeFromFolderSelection,
      deselectAll: deselectAllFolders,
    },
    videos: {
      selected: selectedVideoIds,
      addToSelection: addToVideoSelection,
      removeFromSelection: removeFromVideoSelection,
      deselectAll: deselectAllVideos,
    },
  } = useContext(DashboardMultiSelectContext);

  const {
    tags: {
      selected: selectedTagIds,
      removeFromSelection: removeFromTagSelection,
    },
  } = useContext(DashboardSelectedTagsContext);

  // const filteredVideos = useMemo(() => {
  //   const tagIds = Array.from(selectedTagIds.values());
  //   return videos.filter(video => {
  //     return tagIds.every(tagId => video.tags.some(tag => tag === tagId));
  //   });
  // }, [selectedTagIds]);

  const handleDirectoryClick = useCallback((e: React.MouseEvent, directory: DirectoryFragment) => {
    onNavigate(DashboardRouting.createDirectoryUrl(directory.id));
  }, [onNavigate]);

  const handleCheckboxChange = useCallback((
    e: React.ChangeEvent<HTMLInputElement>,
    item: VideoFragment | DirectoryFragment,
  ) => {
    const { target: { checked } } = e;
    switch (item.__typename) {
      case 'Video':
        if (checked) {
          addToVideoSelection([item.id]);
        } else {
          removeFromVideoSelection([item.id]);
        }
        break;
      case 'Folder':
        if (checked) {
          addToFolderSelection([item.id]);
        } else {
          removeFromFolderSelection([item.id]);
        }
        break;
    }
  }, [addToFolderSelection, addToVideoSelection, removeFromFolderSelection, removeFromVideoSelection]);

  const [isMultiVideoTaggerOpen, openMultiVideoTagger, closeMultiVideoTagger] = useDialogState();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState();
  const [isMultipleAnalysisDialogOpen, openMultipleAnalysisDialog, closeMultipleAnalysisDialog] = useDialogState();
  const [
    isDirectoryPickerDialogOpen,
    openDirectoryPickerDialog,
    closeDirectoryPickerDialog,
  ] = useDialogState();

  const [
    isCollaboratorDialogOpen,
    openCollaboratorDialog,
    closeCollaboratorDialog,
  ] = useDialogState();

  const [folderPageIds, setFolderPageIds] = useState<ID[]>([]);
  const [videoPageIds, setVideoPageIds] = useState<ID[]>([]);
  const [movingVideo, setMovingVideo] = useState<boolean>(false);

  const handleDirectoryGridPageIdsChange = useCallback((pageNumber: number, ids: ID[]) => {
    setFolderPageIds(ids);
  }, []);

  const handleVideoGridPageIdsChange = useCallback((pageNumber: number, ids: ID[]) => {
    setVideoPageIds(ids);
  }, []);

  const { checked, indeterminate } = multiSelectCheckboxProps([
    [folderPageIds, selectedFolderIds],
    [videoPageIds, selectedVideoIds],
  ]);

  const handleSelectCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      removeFromVideoSelection(videoPageIds);
      removeFromFolderSelection(folderPageIds);
    } else {
      addToVideoSelection(videoPageIds);
      addToFolderSelection(folderPageIds);
    }
  }, [
    addToFolderSelection,
    addToVideoSelection,
    folderPageIds,
    removeFromFolderSelection,
    removeFromVideoSelection,
    videoPageIds,
  ]);

  const selectFoldersOnPage = useCallback(() => {
    removeFromVideoSelection(videoPageIds);
    addToFolderSelection(folderPageIds);
  }, [addToFolderSelection, folderPageIds, removeFromVideoSelection, videoPageIds]);

  const selectNoneOnPage = useCallback(() => {
    removeFromVideoSelection(videoPageIds);
    removeFromFolderSelection(folderPageIds);
  }, [folderPageIds, removeFromFolderSelection, removeFromVideoSelection, videoPageIds]);

  const selectVideosOnPage = useCallback(() => {
    removeFromFolderSelection(folderPageIds);
    addToVideoSelection(videoPageIds);
  }, [addToVideoSelection, folderPageIds, removeFromFolderSelection, videoPageIds]);

  const selectAllOnPage = useCallback(() => {
    addToVideoSelection(videoPageIds);
    addToFolderSelection(folderPageIds);
  }, [addToFolderSelection, addToVideoSelection, folderPageIds, videoPageIds]);

  const deselectFoldersAndVideos = useCallback(() => {
    deselectAllFolders();
    deselectAllVideos();
  }, [deselectAllFolders, deselectAllVideos]);

  const items: ContextMenuItem[] = useMemo(() => {
    return [{
      text: t('dashboard-main:pageselect.all'),
      onClick: selectAllOnPage,
    }, {
      text: t('dashboard-main:pageselect.none'),
      onClick: selectNoneOnPage,
    }, {
      text: t('dashboard-main:pageselect.folders'),
      onClick: selectFoldersOnPage,
    }, {
      text: t('dashboard-main:pageselect.videos'),
      onClick: selectVideosOnPage,
    }];
  }, [selectAllOnPage, selectFoldersOnPage, selectNoneOnPage, selectVideosOnPage, t]);

  const selectedCount = selectedFolderIds.size + selectedVideoIds.size;

  const tags = useCreateSelector(makeGetTeamVideoTagsByTeamId, teamId);
  const selectedVideos = useCreateSelector(makeGetVideosByIds, Array.from(selectedVideoIds));
  const selectedTags = useCreateSelector(makeGetTagsByIds, {tagIds: Array.from(selectedTagIds)});

  const handleTagOnDelete = useCallback((e: React.MouseEvent, tag: ITag) => {
    if (selectedTagIds.has(tag.id)) {
      removeFromTagSelection([tag.id]);
    }
  }, [removeFromTagSelection, selectedTagIds]);

  const handleTagOnClearAll = useCallback((e: React.MouseEvent) => {
    removeFromTagSelection(Array.from(selectedTagIds));
  }, [removeFromTagSelection, selectedTagIds]);

  const renderDirectoryCard = useCallback((directory: DirectoryFragment, i: number) => (
    <DragDropDirectoryCard
    directory={directory}
    selected={selectedFolderIds?.has(directory.id)}
    onClick={handleDirectoryClick}
    onItemDropped={onItemDroppedOnDirectory}
    onCheckboxChange={handleCheckboxChange}
    onFilesDropped={onFilesDroppedOnDirectory}
    />
  ), [
    handleCheckboxChange,
    handleDirectoryClick,
    onFilesDroppedOnDirectory,
    onItemDroppedOnDirectory,
    selectedFolderIds,
  ]);

  const renderVideoCard = useCallback((video: VideoFragment, i: number) => (
    <DragVideoCard
    video={video}
    selected={selectedVideoIds?.has(video.id)}
    onCheckboxChange={handleCheckboxChange}
    selectionMode={Boolean(selectedVideoIds.size)}
    addToVideoSelection={addToVideoSelection}
    removeFromVideoSelection={removeFromVideoSelection}
    />
  ), [addToVideoSelection, handleCheckboxChange, removeFromVideoSelection, selectedVideoIds]);

  const handleFileDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (!directory) {
      return;
    }
    onFilesDroppedOnDirectory?.(directory, acceptedFiles, rejectedFiles);
  }, [directory, onFilesDroppedOnDirectory]);

  const handleDirectorySelected = useCallback(async (directoryId: ID) => {
    if (movingVideo) {
      return;
    }
    setMovingVideo(true);
    try {
      if (!teamId) {
        throw new Error('No team');
      }
      await promiseSagaDispatch(updateVideosAC, {ids: Array.from(selectedVideoIds), teamId, directoryId});
      enqueueSnackbar(t('dashboard-directory:movevideo.success'), {variant: 'success'});
      closeDirectoryPickerDialog();
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setMovingVideo(false);
    }
  }, [closeDirectoryPickerDialog, enqueueSnackbar, movingVideo, promiseSagaDispatch, selectedVideoIds, t, teamId]);

  const { getRootProps: getOuterRootProps, isDragActive: isOuterDragActive } = useDropzone({
    accept: [],
    noClick: true,
    noKeyboard: true,
  });
  const { getRootProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'video/*',
    onDrop: handleFileDrop,
    noClick: true,
    noKeyboard: true,
  });

  const [videoGrid, setVideoGrid] = useState<HTMLDivElement | null>(null);

  return (
    <FlexSpacer
    {...getOuterRootProps()}
    orientation='vertical'
    className={classNames(classes.root, {
      [classes.isDragAccept]: isDragAccept,
    }, className)}
    >
      <div className={classes.selectionBar}>
        <FlexSpacer flexAlignItems='center' className={classes.collaboratorWrapper}>
          <Tooltip title={t('dashboard-main:channelinvite.channelcollaborator')}>
            <Button
            variant='outlined'
            endIcon={directory && <DirectoryUsers directory={directory} users={users}/>}
            onClick={openCollaboratorDialog}
            size='small'
            color='primary'
            id={EIntercomID.CHANNEL_PARTICIPANTS}
            >
              <PersonAddIcon />
            </Button>
          </Tooltip>
        </FlexSpacer>
        <FlexSpacer
        id={EIntercomID.SELECT_VIDEOS_ALL}
        flexAlignItems='center'
        flexJustifyContent='flex-end'
        spacing={0}
        className={classNames(classes.filterSection, {[classes.mobile]: !isDesktop && selectedCount > 0})}
        >
          {selectedTagIds.size > 0 && isDesktop && (
            <FlexSpacer className={classes.tagFilter} flexAlignItems='center'>
              <Typography variant='body2'>{t('common:videowith')}</Typography>
              {selectedTags.map((tag) => <TagChip tag={tag} key={tag.id} handleTagOnDelete={handleTagOnDelete}/>)}
              <Button onClick={handleTagOnClearAll}>{t('common:clear')}</Button>
            </FlexSpacer>
          )}
          <FormControlLabel
          className={classes.selectButton}
          control={
            <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            onChange={handleSelectCheckboxChange}
            />
          }
          label={t('common:select')}
          labelPlacement='start'
          />
          <ContextMenu popupId='selection-menu' icon={<ArrowDropDownIcon />} items={items} />
          {selectedCount > 0 && (
            <React.Fragment>
              {selectedVideoIds.size > 0 && (
                <React.Fragment>
                  {canUpdateVod && (
                    <Tooltip title={t('common:move')} placement='top'>
                      <IconButton size='small' onClick={openDirectoryPickerDialog}>
                        <FolderIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {canAssignTag && (
                    <Tooltip title={t('common:tag')} placement='top'>
                      <IconButton size='small' onClick={openMultiVideoTagger}>
                        <LabelIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isDesktop && canRequestSomeAnalysis && (
                    <Tooltip title={t('common:analyze')} placement='top'>
                      <IconButton size='small' onClick={openMultipleAnalysisDialog}>
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </React.Fragment>
              )}
              {canDeleteVod && (
                <Tooltip title={t('common:delete')} placement='top'>
                  <IconButton size='small' onClick={openDeleteDialog}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
              <StyledChip
              label={t('dashboard-main:pageselect.selectedCount', {count: selectedCount})}
              onDelete={deselectFoldersAndVideos}
              deleteColor='primary'
              className={classes.selectedItemsChip}
              />
            </React.Fragment>
          )}
        </FlexSpacer>
      </div>
      {selectedTagIds.size > 0 && !isDesktop && (
        <FlexSpacer
        className={classNames(classes.tagFilter, {[classes.mobile]: !isDesktop})}
        flexAlignItems='center'
        flexJustifyContent='space-between'
        >
          <FlexSpacer className={classes.tagWrapper} flexJustifyContent='flex-start' flexAlignItems='center'>
            {selectedTags.map((tag) => <TagChip tag={tag} key={tag.id} handleTagOnDelete={handleTagOnDelete}/>)}
          </FlexSpacer>
          <Button onClick={handleTagOnClearAll}>{t('common:clear')}</Button>
        </FlexSpacer>
      )}
      {folders && (
        <PagedDirectoryGrid
        directories={folders}
        displaySkeleton={initialFolderFetch}
        onPageIdsChange={handleDirectoryGridPageIdsChange}
        renderDirectoryCard={renderDirectoryCard}
        />
      )}
      <PagedVideoGrid
      ref={setVideoGrid}
      videosRecord={videosRecord}
      onPageIdsChange={handleVideoGridPageIdsChange}
      renderVideoCard={renderVideoCard}
      page={page}
      onPageChange={handlePageChange}
      />
      {teamId && (
        <MultiVideoTaggerDialog
        teamId={teamId}
        videos={selectedVideos}
        tags={tags}
        open={isMultiVideoTaggerOpen}
        onClose={closeMultiVideoTagger}
        />
      )}
      {teamId && (
        <DirectoryPickerDialog
        selectedVideoIds={selectedVideoIds}
        teamId={teamId}
        initialDirectoryId={directory?.id}
        open={isDirectoryPickerDialogOpen}
        onClose={closeDirectoryPickerDialog}
        onConfirm={handleDirectorySelected}
        />
      )}
      <Dialog open={isMultipleAnalysisDialogOpen} onClose={closeMultipleAnalysisDialog} fullWidth={true}>
        <CreateMultipleAnalysisRequestsDialogContent
        videos={selectedVideos}
        onClose={closeMultipleAnalysisDialog}
        onAnalyze={deselectFoldersAndVideos}
        />
      </Dialog>
      {directory && (
        <CollaboratorEditorDialog
        open={isCollaboratorDialogOpen}
        directoryId={directory.id}
        onClose={closeCollaboratorDialog}
        teamId={directory.team.id}
        collaborators={users}
        directory={directory}
        />
      )}
      {teamId && (
        <DeleteSelectedDialog
        teamId={teamId}
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onDelete={deselectFoldersAndVideos}
        folderIds={selectedFolderIds}
        videoIds={selectedVideoIds}
        />
      )}
      <DropIndicator
      {...getRootProps()}
      isDragActive={isDragActive}
      isDragAccept={isDragAccept}
      isDragReject={isDragReject}
      className={classNames(classes.dropIndicator, {[classes.isOuterDragActive]: isOuterDragActive})}
      style={{top: videoGrid?.offsetTop}}
      >
        {isDragAccept ? (
          <FlexSpacer orientation='vertical' flexAlignItems='center' className={classes.floating}>
            <CloudUploadIcon fontSize='large' />
            <span>
              {t('dashboard-main:filedrop.filesaccepted')}
            </span>
          </FlexSpacer>
        ) : isDragReject ? (
          <FlexSpacer orientation='vertical' flexAlignItems='center' className={classes.floating}>
            <WarningRoundedIcon fontSize='large' />
            <span>
              {t('dashboard-main:filedrop.filesrejected')}
            </span>
          </FlexSpacer>
        ) : null}
      </DropIndicator>
    </FlexSpacer>
  );
}

export default React.memo(DashboardMainContent);
