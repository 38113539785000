import { AuthenticationMethodFragment } from 'apollo/fragments/types/AuthenticationMethodFragment';
import { isTruthy } from 'helpers';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';

const getUserTokenRecords = (state: RootReducer) => state.authReducer.tokenRecords;

const getUserTokens = (state: RootReducer) => {
  if (!state.authReducer.tokenDict) {
    return [];
  }

  return Object.values(state.authReducer.tokenDict);
};

export const makeGetUserTokenRecords = () => createSelector(
  [getUserTokenRecords],
  (records) => records,
);

export const makeGetUserTokens = () => createSelector(
  [getUserTokens],
  (tokens) => tokens,
);

const getAuthenticationMethodRecords = (state: RootReducer) => state.authReducer.authnMethodRecords;

const getAuthenticationMethods = (state: RootReducer): AuthenticationMethodFragment[] => {
  if (!state.authReducer.tokenDict) {
    return [];
  }

  return Object.values(state.authReducer.authnMethodDict).filter(isTruthy);
};

export const makeGetAuthenticationMethodRecords = () => createSelector(
  [getAuthenticationMethodRecords],
  (records) => records,
);

export const makeGetAuthenticationMethods = () => createSelector(
  [getAuthenticationMethods],
  (providers) => providers,
);
