import { useEffect } from 'react';

type EventHandler<TEvent extends Event> = (event: TEvent) => void;

interface EventTargetLike<TType extends string, TEvent extends Event> {
  addEventListener(type: TType, listener: EventHandler<TEvent>): void;
  removeEventListener(type: TType, listener: EventHandler<TEvent>): void;
}

export default function useAddEventListener<TEventTarget extends EventTargetLike<any, any>>(
  eventTarget: TEventTarget | undefined,
  type: TEventTarget extends EventTargetLike<infer TType, any> ? TType : never,
  listener: (TEventTarget extends EventTargetLike<any, infer TEvent> ? EventHandler<TEvent> : never) | undefined,
): void;

export default function useAddEventListener<TEventTarget extends EventTargetLike<any, any>>(
  eventTarget: TEventTarget | undefined,
  type: string,
  listener: EventHandler<any> | undefined,
): void;

export default function useAddEventListener<TEventTarget extends EventTargetLike<any, any>>(
  eventTarget: TEventTarget | undefined,
  type: string,
  listener: EventHandler<any> | undefined,
): void {
  useEffect(() => {
    if (!eventTarget || !listener) {
      return;
    }

    eventTarget.addEventListener(type, listener);
    return () => eventTarget.removeEventListener(type, listener);
  }, [eventTarget, type, listener]);
}
