import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { updateUserAsyncAC } from 'features/auth/auth-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors/getMe';
import { UpdateUser2Input } from 'types/graphql';

import GuidedSetupActions from './GuidedSetupActions';

interface UsernameOwnProps {
  className?: string;
  onClose: VoidFunction;
  stepForward: VoidFunction;
  step: number;
}

type UsernameProps = UsernameOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  title: {
    position: 'absolute',
    top: 68,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  header2: {
    marginBottom: theme.spacing(3),
  },
  textField: {
    width: createRemFromPx(240),
    position: 'absolute',
    top: 227,
    left: 180,
  },
  skip: {
    position: 'absolute',
    bottom: 24,
    left: 24,
    cursor: 'pointer',
  },
}), {name: 'Username'});

function Username(props: UsernameProps) {
  const classes = useStyles(props);
  const { className, onClose, stepForward, step } = props;
  const me = useSelector(getMe);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { t } = useStrictTranslation(['dialog', 'alert']);
  const { enqueueSnackbar } = useSnackbar();
  const [ loading, setLoading ] = useState(false);
  const [ displayName, setDisplayName ] = useState(me?.alias || '');

  const handleDisplayNameOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(e.target.value);
  },[]);

  const saveDisplayName = useCallback(() => {
    if (!displayName.length) { return; }
    const alias = displayName.trim();
    if (!alias) {
      enqueueSnackbar(t('alert:emptyfield'), {variant: 'error'});
      return;
    }
    setLoading(true);
    const input: UpdateUser2Input = { alias };
    promiseSagaDispatch(updateUserAsyncAC, input);
    setLoading(false);
    stepForward();
  }, [displayName, enqueueSnackbar, promiseSagaDispatch, stepForward, t]);


  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.title}>
        <Typography variant='h3' className={classes.header2} align='center'>
          {t('dialog:onboarding.choosename')}
        </Typography>
        <Typography variant='h6'>
          {t('dialog:onboarding.choosenametext')}
        </Typography>
      </div>
      <TextField
      className={classes.textField}
      placeholder={t('dialog:onboarding.displayname')}
      onChange={handleDisplayNameOnChange}
      required={true}
      focused={true}
      label={t('dialog:onboarding.choosenamelabel')}
      value={displayName}
      />
      <Typography variant='caption' className={classes.skip} onClick={onClose}>
        {t('dialog:onboarding.skip')}
      </Typography>
      <GuidedSetupActions loading={loading} stepForward={saveDisplayName} step={step}/>
    </div>
  );
}

export default React.memo(Username);
