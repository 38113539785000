import { FlexSpacer } from '@insights-gaming/material-components/FlexSpacer';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { createMessageAction } from 'factories/kmsessionEventFactory';
import { liveSessionSendActionAC } from 'features/live-session/live-session-slice';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EKeyCode } from 'types/EKeyCode';

const CHAT_CHAR_LIMIT = 500;

interface ChatInputBoxProps {
  className?: string;
}

function ChatInputBox({ className }: ChatInputBoxProps) {
  const { t } = useStrictTranslation(['video']);
  const dispatch = useDispatch();

  const [message, setMessage] = useState('');
  const trimmed = useMemo(() => message.trim(), [message]);

  const handleSubmit = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!trimmed || trimmed.length > CHAT_CHAR_LIMIT) {
      return;
    }
    dispatch(liveSessionSendActionAC.started(createMessageAction(trimmed)));
    setMessage('');
  }, [dispatch, trimmed]);

  const handleNoModifiers = useCallback((e: React.KeyboardEvent) => {
    const { ctrlKey, shiftKey, altKey, keyCode } = e;
    if (ctrlKey || shiftKey || altKey) {
      return false;
    }
    switch (keyCode) {
      case EKeyCode.ENTER:
        handleSubmit(e);
        break;
      default:
        return false;
    }
    return true;
  }, [handleSubmit]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (handleNoModifiers(e)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, [handleNoModifiers]);

  const handleInput = useCallback((e: React.SyntheticEvent) => {
    if (e.target instanceof HTMLTextAreaElement) {
      setMessage(e.target.value);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} className={className}>
      <FlexSpacer orientation='vertical' flexAlignItems='flex-end'>
        <TextField
        value={message}
        rowsMax={3}
        rows={3}
        fullWidth={true}
        variant='outlined'
        multiline={true}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        />
        <Button
        type='submit'
        variant='contained'
        color='primary'
        disabled={!trimmed || trimmed.length >= CHAT_CHAR_LIMIT}
        >
          {t('video:replay.send')}
        </Button>
      </FlexSpacer>
    </form>
  );
}

export default React.memo(ChatInputBox);
