import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React from 'react';

interface RoleTableRowOwnProps {
  className?: string;
  name?: React.ReactNode;
  privileges?: React.ReactNode;
  actions?: React.ReactNode;
}

type RoleTableRowProps = RoleTableRowOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'RoleTableRow'});

function RoleTableRow(props: RoleTableRowProps) {
  const classes = useStyles(props);
  const { className, name, privileges, actions } = props;
  return (
    <TableRow className={classNames(classes.root, className)}>
      <FixedTableCell width={100}>{name}</FixedTableCell>
      <FixedTableCell width={300}>{privileges}</FixedTableCell>
      <FixedTableCell width={100}>{actions}</FixedTableCell>
    </TableRow>
  );
}

export default React.memo(RoleTableRow);
