import { CommentFragment } from 'apollo/fragments/types/CommentFragment';

function isVideoComment(comment: CommentFragment): boolean {
  return comment.__typename === 'VideoComment';
}

function isCommentReply(comment: CommentFragment): boolean {
  return comment.__typename === 'CommentReply';
}

function tryGetParentId(comment: CommentFragment): string | undefined {
  return comment.__typename === 'CommentReply' ? comment.parent : undefined;
}

export const CommentHelper = {
  isVideoComment,
  isCommentReply,
  tryGetParentId,
};
