import { FlexSpacer, VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { SecondsFormatter } from '@insights-gaming/utils';
import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import classNames from 'classnames';
import { EIntercomID } from 'constants/strings';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { RangedTimestampContext } from './RangedTimestampContext';

interface RangedTimestampOwnProps {
  className?: string;
}

type RangedTimestampProps = RangedTimestampOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  textField: {
    width: 70,
  },
}), {name: 'RangedTimestamp'});

function RangedTimestamp(props: RangedTimestampProps) {
  const classes = useStyles(props);
  const { className } = props;

  const { t } = useStrictTranslation(['video']);

  const {
    startTimeNumericValue,
    endTimeNumericValue,
    startTimeTextValue,
    endTimeTextValue,
    displayEndTime,
    setUserStartTimeValue,
    setUserEndTimeValue,
    setIsTimeInvalid,
    toggleDisplayEndTime,
    setIsStartTimeChanged,
  } = useContext(RangedTimestampContext);

  const {
    pause,
    state: {
      playing,
      duration,
    },
  } = useContext(VideoReplayContext);

  const [ isStartTimeInvalid, setIsStartTimeInvalid ] = useState(false);
  const [ isEndTimeInvalid, setIsEndTimeInvalid ] = useState(false);

  useEffect(() => {
    if (isStartTimeInvalid || isEndTimeInvalid) {
      setIsTimeInvalid(true);
    } else {
      setIsTimeInvalid(false);
    }
  }, [setIsTimeInvalid, isStartTimeInvalid, isEndTimeInvalid]);

  useEffect(() => {
    if (playing) {
      setIsStartTimeChanged(false);
    }
  }, [playing, setIsStartTimeChanged]);

  const handleStartTimeChange = useCallback(({currentTarget: {value}}: React.ChangeEvent<HTMLInputElement>) => {
    const trimmed = value.trim();
    setIsStartTimeChanged(true);
    setUserStartTimeValue(trimmed);
    if (SecondsFormatter.isValid(trimmed)) {
      setIsStartTimeInvalid(false);
    }
  }, [setIsStartTimeChanged, setUserStartTimeValue]);

  const handleStartTimeBlur = useCallback(({currentTarget: {value}}: React.FocusEvent<HTMLInputElement>) => {
    if (!value) {
      setUserStartTimeValue('');
      setIsStartTimeInvalid(false);
    } else {
      setIsStartTimeInvalid((startTimeNumericValue < 0) || (startTimeNumericValue > duration));
    }
  }, [duration, setUserStartTimeValue, startTimeNumericValue]);

  const handleEndTimeChange = useCallback(({currentTarget: {value}}: React.ChangeEvent<HTMLInputElement>) => {
    const trimmed = value.trim();
    setUserEndTimeValue(trimmed);
    if (SecondsFormatter.isValid(trimmed)) {
      setIsEndTimeInvalid(false);
    }
  }, [setUserEndTimeValue]);

  const handleEndTimeBlur = useCallback(({currentTarget: {value}}: React.FocusEvent<HTMLInputElement>) => {
    if (!value) {
      setUserEndTimeValue('');
      setIsEndTimeInvalid(false);
    } else {
      setIsEndTimeInvalid(
        endTimeNumericValue < 0
        || endTimeNumericValue < startTimeNumericValue
        || endTimeNumericValue > duration,
      );
    }
  }, [setUserEndTimeValue, endTimeNumericValue, startTimeNumericValue, duration]);

  const displayEndTimeOnClick = useCallback(() => {
    toggleDisplayEndTime();
    pause();
  }, [toggleDisplayEndTime, pause]);

  return (
    <FlexSpacer className={classNames(classes.root, className)} flexAlignItems='center' spacing={0.5}>
      <TextField
      className={classes.textField}
      variant='outlined'
      size='small'
      value={startTimeTextValue}
      onChange={handleStartTimeChange}
      onBlur={handleStartTimeBlur}
      error={isStartTimeInvalid}
      />
      {!displayEndTime ? (
        <Tooltip title={t('video:rangedtimestamp.addtimestamprange')}>
          <IconButton size='small' onClick={displayEndTimeOnClick} id={EIntercomID.RANGED_TIMESTAMP}>
            <AddCircleOutlineIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      ) : (
        <React.Fragment>
          <span>-</span>
          <TextField
          className={classes.textField}
          variant='outlined'
          size='small'
          value={endTimeTextValue}
          onChange={handleEndTimeChange}
          onBlur={handleEndTimeBlur}
          error={isEndTimeInvalid}
          />
          <Tooltip title={t('video:rangedtimestamp.removetimestamprange')}>
            <IconButton size='small' onClick={toggleDisplayEndTime}>
              <RemoveCircleOutlineIcon fontSize='small' htmlColor={red[700]} />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      )}
    </FlexSpacer>
  );
}

export default React.memo(RangedTimestamp);
