import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

import { OverwatchTeamColor } from '../../../../../types/pigeon/overwatch-match-data';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(1),
      border: '1px solid transparent',
    },
    label: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
      textAlign: 'center',
    },
    blue: {
      gridColumnStart: 1,
    },
    red: {
      gridColumnStart: 2,
    },
    focus: {
      border: `1px solid ${theme.palette.text.secondary}`,
    },
  });
};

export interface IEventButtonOwnProps {
  mostRelevantEvent?: React.Ref<HTMLButtonElement>;
  onClick: () => void;
  className?: string;
  team: OverwatchTeamColor;
}

export type IEventButtonProps = IEventButtonOwnProps &
  WithStyles<typeof styles>;

class EventButton extends React.Component<IEventButtonProps> {
  public render() {
    const { classes, mostRelevantEvent, className, team, onClick } = this.props;
    return (
      <Button
      ref={mostRelevantEvent}
      className={classNames(
        classes[team],
        mostRelevantEvent ? classes.focus : undefined,
        className,
      )}
      classes={{ root: classes.root, label: classes.label }}
      onClick={onClick}
      >
        {this.props.children}
      </Button>
    );
  }
}

export default withStyles(styles)(EventButton);
