import { VideoReplayContext } from '@insights-gaming/material-components';
import { CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import React, { useContext } from 'react';

import { useCommentPassed } from './useCommentPassed';

export interface IAutoPauserOwnProps {
  comments: CommentFragment_VideoComment[];
  onPauseAtComment: (comment: CommentFragment_VideoComment) => void;
}

type Props = IAutoPauserOwnProps;

function AutoPauser({ comments, onPauseAtComment }: Props) {
  const { state: { playing, progress: { playedSeconds: time } } } = useContext(VideoReplayContext);

  useCommentPassed(time, comments, onPauseAtComment, playing);
  return null;
}

export default React.memo(AutoPauser);
