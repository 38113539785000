import { FlexSpacer, VerticalScroll } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Pagination } from '@material-ui/lab';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import chunk from 'lodash/chunk';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import NotificationsTableRowSkeleton from './NotificationsTableRowSkeleton';
import NotificationsTableRowWrapper from './NotificationsTableRowWrapper';

interface NotificationsTableOwnProps {
  className?: string;
  displaySkeleton?: boolean;
  aggregatedNotifications: NotificationFragment[][];
}

type NotificationsTableProps = NotificationsTableOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'NotificationsTable'});

const notificationsPerPage = 10;

function NotificationsTable(props: NotificationsTableProps) {
  const classes = useStyles(props);
  const { className, displaySkeleton, aggregatedNotifications } = props;

  const { t } = useStrictTranslation(['common', 'notification']);

  const pages = useMemo(() => chunk(aggregatedNotifications, notificationsPerPage), [aggregatedNotifications]);
  const [page, setPage] = useState(1);
  const notificationsOnPage = pages[page - 1] || [];

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const scrollToTop = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }
    scrollRef.current.scroll({top: 0});
  }, []);

  const handlePageChange = useCallback((__, value: number) => {
    setPage(value);
    scrollToTop();
  }, [scrollToTop]);

  return (
    <FlexSpacer orientation='vertical'>
      <VerticalScroll ref={scrollRef}>
        <Table className={classNames(classes.root, className)} size='small'>
          <TableHead>
            <TableRow>
              <FixedTableCell>{t('notification:notifications.recent')}</FixedTableCell>
              <FixedTableCell width={100}>{t('notification:notifications.team')}</FixedTableCell>
              <FixedTableCell width={100} />
            </TableRow>
          </TableHead>
          <TableBody>
            {displaySkeleton ? (
              <React.Fragment>
                <NotificationsTableRowSkeleton/>
                <NotificationsTableRowSkeleton/>
              </React.Fragment>
            ) : (
              notificationsOnPage.map((n, i) => <NotificationsTableRowWrapper key={i} notifications={n}/>)
            )}
          </TableBody>
        </Table>
      </VerticalScroll>
      {pages.length > 1 && (
        <Pagination page={page} count={pages.length} onChange={handlePageChange} />
      )}
    </FlexSpacer>
  );
}

export default React.memo(NotificationsTable);
