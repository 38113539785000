import { watchAsync } from '@insights-gaming/saga-utils';
import { subscribeToEventsAC, unsubscribeToEventsAC } from 'actions/event-actions';
import { invalidateTeamVideoCacheAC } from 'actions/video-actions';
import { AddMemberRoles_Mutation, CreateCustomRole_Mutation, CreateTeam_Mutation, DeleteRole_Mutation, DeleteTeam_Mutation, InviteMembers_Mutation, LeaveTeam_Mutation, RemoveMember_Mutation, RemoveMemberRoles_Mutation, UpdateCustomRole_Mutation, UpdateTeam_Mutation, UpdateTeamFeatureAssignments_Mutation, UpdateTeamOrder_Mutation } from 'apollo/mutations';
import { logoutAsyncAC } from 'features/auth/auth-slice';
import { makeGetTeamCustomRoleRecordsByTeamId } from 'features/dashboard/role/dashboard-role-selector';
import { fetchTeamCustomRolesAC } from 'features/dashboard/role/dashboard-role-slice';
import { getTeamRecords } from 'features/dashboard/team/dashboard-team-selector';
import {
  addMemberRolesAC,
  createCustomRoleAC,
  createTeamAC,
  deleteRoleAC,
  deleteTeamAC,
  fetchTeamsAC,
  inviteMembersAC,
  leaveTeamAC,
  removeMemberAC,
  removeMemberRolesAC,
  selectTeamAsyncAC,
  updateCustomRoleAC,
  updateTeamAC,
  updateTeamFeatureAssignmentsAC,
  updateTeamOrderAC,
} from 'features/dashboard/team/dashboard-team-slice';
import { watchAsyncMutation } from 'helpers/saga/effects';
import { removeLocalStorage, setLocalStorage } from 'helpers/storage';
import { SagaIterator, Task } from 'redux-saga';
import { all, call, cancel, delay, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { getSelectedTeamEdge } from 'selectors/team';
import { Optional } from 'types';
import { ID } from 'types/pigeon';

export default function* teamSaga() {
  yield all([
    watchAsyncMutation(createTeamAC, CreateTeam_Mutation, ['createTeam']),
    watchAsyncMutation(updateTeamAC, UpdateTeam_Mutation, ['updateTeam']),
    watchAsyncMutation(leaveTeamAC, LeaveTeam_Mutation, ['leaveTeam']),
    watchAsyncMutation(deleteTeamAC, DeleteTeam_Mutation, ['deleteTeam']),
    watchAsyncMutation(inviteMembersAC, InviteMembers_Mutation, ['inviteMembers']),
    watchAsyncMutation(removeMemberAC, RemoveMember_Mutation, ['removeMember']),
    watchAsyncMutation(createCustomRoleAC, CreateCustomRole_Mutation, ['createCustomRole']),
    watchAsyncMutation(updateCustomRoleAC, UpdateCustomRole_Mutation, ['updateCustomRole']),
    watchAsyncMutation(deleteRoleAC, DeleteRole_Mutation, ['deleteRole2']),
    watchAsyncMutation(addMemberRolesAC, AddMemberRoles_Mutation, ['addMemberRoles']),
    watchAsyncMutation(removeMemberRolesAC, RemoveMemberRoles_Mutation, ['removeMemberRoles']),
    watchAsyncMutation(
      updateTeamFeatureAssignmentsAC,
      UpdateTeamFeatureAssignments_Mutation,
      ['updateTeamFeatureAssignments'],
    ),
    watchAsyncMutation(updateTeamOrderAC, UpdateTeamOrder_Mutation, ['updateTeamOrder']),

    watchAsync(selectTeamAsyncAC, function* (teamId) {
      const { selectedTeamId: prevTeamId } = yield select(getSelectedTeamEdge);

      if (prevTeamId === teamId) {
        return;
      }

      if (prevTeamId) { // unsubscribe to this team's videos
        const task = yield spawn(unsubscribeWorker, prevTeamId);
        teamUnsubscribeTasks.set(prevTeamId, task);
      }

      if (teamId) {
        const unsubscribeTask: Optional<Task> = teamUnsubscribeTasks.get(teamId);
        if (unsubscribeTask) { // if they reselect the team we should stop the timer to unsubscribe
          yield cancel(unsubscribeTask);
        }

        yield put(subscribeToEventsAC({ teamId }));

        setLocalStorage('lastVisitedTeam', teamId);
      }
    }),

    takeEvery(createTeamAC.done, function* (): SagaIterator {
      // backward fetch to get the newly created team.
      const teamRecords: ReturnType<typeof getTeamRecords> = yield select(getTeamRecords);
      const cursor = teamRecords.backward.cursor;
      if (cursor) {
        yield put(fetchTeamsAC.backward.started({ before: cursor, limit: 2 }));
      }
    }),
    takeEvery(leaveTeamAC.done, function* ({ payload: { params: { teamId } } }): SagaIterator {
      yield put(unsubscribeToEventsAC({ teamId }));
      removeLocalStorage('lastVisitedTeam');
      removeLocalStorage('lastVisitedDirectory');
    }),
    takeEvery(deleteTeamAC.done, function() {
      // TODO: remove this eventually
      localStorage.removeItem('redirect');
      removeLocalStorage('lastVisitedTeam');
      removeLocalStorage('lastVisitedDirectory');
    }),
    takeEvery(createCustomRoleAC.done, function*({ payload: { params: { teamId } } }) {
      // backward fetch to get the newly created role.
      const selector: ReturnType<typeof makeGetTeamCustomRoleRecordsByTeamId> = yield call(
        makeGetTeamCustomRoleRecordsByTeamId,
      );
      const teamRoleRecords: ReturnType<typeof selector> = yield select(selector, { teamId });
      const cursor = teamRoleRecords?.backward?.cursor;
      if (cursor) {
        yield put(fetchTeamCustomRolesAC.backward.started({ teamId, before: cursor, limit: 2 }));
      }
    }),

    watchLogout(),
  ]);
}

function* unsubscribeWorker(teamId: ID, timeout: number = 900000): SagaIterator {
  yield delay(timeout); // wait to unsubscribe, default 900000ms = 15 minutes
  yield put(unsubscribeToEventsAC({teamId}));
  yield put(invalidateTeamVideoCacheAC({teamId}));
}

function* watchLogout(): SagaIterator {
  yield takeEvery(logoutAsyncAC.started, function* () {
    // no longer need to keep track of these
    yield cancel(Array.from(teamUnsubscribeTasks.values()));
    teamUnsubscribeTasks.clear();
  });
}

const teamUnsubscribeTasks = new Map<ID, Task>();
