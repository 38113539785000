import { AsyncButton } from '@insights-gaming/material-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import ButtonLink from 'material/button-link/ButtonLink';
import Message from 'material/message/Message';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardRoute, signinRoute } from 'routes';
import { getFetchingProfile, getMe } from 'selectors/getMe';

import { liveSessionSendActionAC, PendingLiveSessionState } from './live-session-slice';

interface LiveSessionJoinerDialogContentOwnProps {
  state: PendingLiveSessionState;
}

export type LiveSessionJoinerDialogContentProps = LiveSessionJoinerDialogContentOwnProps;

function LiveSessionJoinerDialogContent(props: LiveSessionJoinerDialogContentProps) {
  const { state: { requiresSecret, message } } = props;
  const { t } = useStrictTranslation(['dialog']);

  const dispatch = useDispatch();

  const [guestName, setGuestName] = useState('guest');
  const [secret, setSecret] = useState<string>();
  const [loading, setLoading] = useState(false);

  const me = useSelector(getMe);
  const isFetchingProfile = useSelector(getFetchingProfile);

  // the message only updates after a failed attempt, it can be used to indicate loading completion
  useEffect(() => setLoading(false), [message]);

  const handleJoinSession = useCallback(() => {
    if (requiresSecret && !secret) {
      return;
    }

    setLoading(true);
    dispatch(liveSessionSendActionAC.started({ name: me ? undefined : guestName, token: secret }));
  }, [dispatch, guestName, me, requiresSecret, secret]);

  const handleGuestNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setGuestName(e.target.value);
  }, []);

  const handleSecretChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSecret(e.target.value);
  }, []);

  if (isFetchingProfile) {
    return null;
  }

  let autoFocus = false;
  return (
    <React.Fragment>
      <BetterDialogTitle>
        {t(requiresSecret ? 'dialog:joinsession.enterpassword' : 'dialog:joinsession.invitedlivesession')}
      </BetterDialogTitle>
      <DialogContent>
        {requiresSecret && (
          <DialogContentText>
            <span>{t('dialog:joinsession.passwordprotected')}</span>
            <span style={{display: 'inline-block'}}>{t('dialog:joinsession.contacthost')}</span>
          </DialogContentText>
        )}
        {!me && (
          <TextField
          id='name'
          label={t('dialog:joinsession.guestname')}
          value={guestName || ''}
          onChange={handleGuestNameChange}
          fullWidth={true}
          autoFocus={autoFocus = !autoFocus}
          />
        )}
        {requiresSecret &&
          <TextField
          id='secret'
          label={t('dialog:joinsession.password')}
          value={secret || ''}
          onChange={handleSecretChange}
          fullWidth={true}
          autoFocus={!autoFocus}
          placeholder={t('dialog:joinsession.password')}
          margin='normal'
          />
        }
        {message && (
          <Message variant='error' message={message} />
        )}
      </DialogContent>
      <DialogActions>
        {me ? (
          <ButtonLink to={dashboardRoute()} variant='outlined'>
            {t('dialog:joinsession.gotodashboard')}
          </ButtonLink>
        ) : (
          <ButtonLink to={signinRoute()} variant='outlined'>
            {t('dialog:joinsession.gotologin')}
          </ButtonLink>
        )}
        <AsyncButton
        type='submit'
        variant='contained'
        color='primary'
        loading={loading}
        disabled={loading}
        onClick={handleJoinSession}
        >
          {t('dialog:joinsession.title')}
        </AsyncButton>
      </DialogActions>
    </React.Fragment>
  );
}

function LiveSessionJoinerDialog(props: LiveSessionJoinerDialogContentProps) {
  return (
    <Dialog open={true}>
      <LiveSessionJoinerDialogContent {...props}/>
    </Dialog>
  );
}

export default React.memo(LiveSessionJoinerDialog);
