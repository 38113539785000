import { FlexSpacer, useVideoReplayContextValue, videoReplayActions } from '@insights-gaming/material-components';
import { createRemFromPx } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { GetUserProfileQuery_me } from 'apollo/queries/types/GetUserProfileQuery';
import classNames from 'classnames';
import DraggableContainer from 'components/draggable-container/DraggableContainer';
import { useFetchVideoIfNecessary } from 'features/dashboard/video/useFetchVideoIfNecessary';
import LeaveSessionDialog from 'features/live-session/LiveSessionLeaveDialog';
import { useVideoReplayLiveSessionBindings } from 'features/live-session/useVideoReplayLiveSessionBindings';
import { bindVideoReplay } from 'features/video-replay/bindVideoReplay';
import { DrawingToolProvider } from 'features/video-replay/DrawingToolContext';
import { usePlayerSeek } from 'features/video-replay/usePlayerSeek';
import { useReactPlayerRef } from 'features/video-replay/useReactPlayerRef';
import { useSeekOnReady } from 'features/video-replay/useSeekOnReady';
import WrappedReactPlayer from 'features/video-replay/WrappedReactPlayer';
import { withActionMetadata } from 'helpers/withActionMetadata';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import ColoredIconButton from 'material/colored-icon-button/ColoredIconButton';
import LiveSymbol from 'material/live-symbol/LiveSymbol';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { joinSessionRoute } from 'routes';
import { getMe } from 'selectors/getMe';
import DrawingOverlay from 'subcomponents/video-player-3/drawing-tools-2/DrawingOverlay';
import { bindDrawingOverlay, useDrawingToolContextValue } from 'subcomponents/video-player-3/drawing-tools-2/useDrawingState';

import { getLiveSessionHostId, getLiveSessionStateType, getLiveSessionToken, getLiveSessionVideo, getLiveSessionVideoState } from './live-session-selector';
import LiveSessionVideoReplayContextBindings from './LiveSessionVideoReplayContextBindings';
import { useDrawingToolLiveSessionListeners } from './useDrawingToolLiveSessionListeners';

export interface MiniLiveSessionViewerOwnProps {
  className?: string;
}

export type MiniLiveSessionViewerProps = MiniLiveSessionViewerOwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1200,
    pointerEvents: 'none',
    userSelect: 'none',
    padding: 1,
    background: theme.palette.text.primary,
  },
  overlay: {
    height: '100%',
    pointerEvents: 'auto',
    padding: createRemFromPx(16),
  },
}), { name: 'MiniLiveSessionViewer' });

const size = 750;

function WrappedMiniLiveSessionViewer(props: MiniLiveSessionViewerProps) {
  const me = useSelector(getMe);
  const stateType = useSelector(getLiveSessionStateType);

  // only show mini session viewer if user authenticated and has joined a session
  if (!me || stateType !== 'joined') {
    return null;
  }

  return (
    <MiniLiveSessionViewer
    {...props}
    me={me}
    />
  );
}

function MiniLiveSessionViewer(props: MiniLiveSessionViewerProps & { me: GetUserProfileQuery_me }) {
  const { className, me } = props;
  const classes = useStyles(props);
  const { t } = useStrictTranslation(['common']);
  const onNavigate = useNavigate();

  const sessionToken = useSelector(getLiveSessionToken);
  const videoState = useSelector(getLiveSessionVideoState);
  const liveSessionVideo = useSelector(getLiveSessionVideo);
  const hostId = useSelector(getLiveSessionHostId);
  const [video] = useFetchVideoIfNecessary(liveSessionVideo?.id);

  const playbackVideo = useMemo(() => video || liveSessionVideo, [liveSessionVideo, video]);

  const [ isLeaveSessionDialogOpen, openLeaveSessionDialog, closeLeaveSessionDialog ] = useDialogState();

  const replayState = useVideoReplayLiveSessionBindings(useVideoReplayContextValue());

  const { reactPlayerRef, ...playerState } = useReactPlayerRef({
    handleReady: useSeekOnReady(replayState, {
      initialState: videoState,
    }),
  });

  usePlayerSeek(replayState, playerState);

  const handleBackToSession = useCallback(() => {
    if (!sessionToken) {
      return;
    }

    onNavigate(joinSessionRoute(sessionToken), { state: { referPath: window.location.pathname } });
  }, [onNavigate, sessionToken]);

  const replayDispatch = replayState.dispatch;

  const play = useCallback(
    () => replayDispatch(withActionMetadata(videoReplayActions.play(), { raw: true })),
    [replayDispatch],
  );

  const pause = useCallback(
    () => replayDispatch(withActionMetadata(videoReplayActions.pause(), { raw: true })),
    [replayDispatch],
  );

  const drawingTool = useDrawingToolContextValue();

  const drawingToolLiveSessionListeners = useDrawingToolLiveSessionListeners(drawingTool.drawingState);

  return (
    <DrawingToolProvider value={drawingTool}>
      <LiveSessionVideoReplayContextBindings>
        <DraggableContainer className={classNames(className, classes.root)} right={0} bottom={0}>
          <WrappedReactPlayer
          ref={reactPlayerRef}
          width={size}
          height={size}
          video={playbackVideo}
          progressInterval={100}
          {...bindVideoReplay(replayState, playerState)}
          >
            <FlexSpacer className={classes.overlay}>
              <Box flexGrow={1} position='relative'>
                <LiveSymbol text={t('common:session.live')} />
              </Box>
              <Box mx={0.5}>
                <ColoredIconButton
                onClick={handleBackToSession}
                flavor='primary'
                >
                  <OpenInNewIcon />
                </ColoredIconButton>
              </Box>
              <Box mx={0.5}>
                <ColoredIconButton
                onClick={openLeaveSessionDialog}
                flavor='negative'
                >
                  <CloseIcon />
                </ColoredIconButton>
              </Box>
            </FlexSpacer>
            <DrawingOverlay
            {...drawingToolLiveSessionListeners}
            {...bindDrawingOverlay(drawingTool.drawingState, drawingToolLiveSessionListeners)}
            />
          </WrappedReactPlayer>
        </DraggableContainer>
        <LeaveSessionDialog
        open={isLeaveSessionDialogOpen}
        onClose={closeLeaveSessionDialog}
        fullWidth={true}
        isPleb={!me || me.id !== hostId}
        video={video}
        />
      </LiveSessionVideoReplayContextBindings>
    </DrawingToolProvider>
  );
}

export default React.memo(WrappedMiniLiveSessionViewer);
