import { secondsFromUrlTimestamp, urlTimestampRegex } from 'helpers/math';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

export function useTimeFromUrl() {
  const { search } = useLocation();

  return useMemo(() => {
    const queryT = new URLSearchParams(search).get('t')?.match(urlTimestampRegex)?.[0];
    if (queryT) {
      return secondsFromUrlTimestamp(queryT);
    }
    return 0;
  }, [search]);
}
