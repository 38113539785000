import { useCallback, useEffect, useMemo } from 'react';

import { makeGetTeamPrimaryRosterId, makeGetTeamRosterPagedIds } from '../../selectors/statistic';
import { AnalysisType } from '../../types/graphql';
import { ID } from '../../types/pigeon';
import { useFetchPrimaryRoster, useFetchRosterPlayers } from '../dispatch/statistic';
import { useCreateSelector } from '../useCreateSelector';

interface Options {
  teamId: ID;
  type? : AnalysisType;
}

interface UseLazyFetchTeamRosters {
  fetch  : VoidFunction;
  more   : boolean;
  loading: boolean;
  error? : Error;
}

export function useLazyFetchTeamRosters({
  teamId,
  type = AnalysisType.OVERWATCH,
}: Options): UseLazyFetchTeamRosters {
  const onFetchRosterPlayers = useFetchRosterPlayers();
  const onFetchPrimaryRoster = useFetchPrimaryRoster();
  const teamRosterIds = useCreateSelector(makeGetTeamRosterPagedIds, teamId);
  const primaryRosterId = useCreateSelector(makeGetTeamPrimaryRosterId, teamId);
  const limit = 25;
  useEffect(() => {
    if (!teamRosterIds) {
      onFetchRosterPlayers({teamId, type});
    }
  }, [onFetchRosterPlayers, teamId, teamRosterIds, type]);
  useEffect(() => {
    if (!primaryRosterId) {
      onFetchPrimaryRoster({teamId, type});
    }
  }, [onFetchPrimaryRoster, primaryRosterId, teamId, type]);
  const fetch = useCallback(() => {
    if (teamRosterIds) {
      onFetchRosterPlayers({teamId, type});
    }
  }, [onFetchRosterPlayers, teamId, teamRosterIds, type]);
  return useMemo(() => ({
    fetch,
    more: !!(teamRosterIds?.forward.more || !teamRosterIds?.forward.cursor),
    loading: !!teamRosterIds?.forward.fetching,
    error: teamRosterIds?.forward.error,
  }), [fetch, teamRosterIds]);
}
