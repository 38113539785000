import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DashboardTeamSettingsBillingRouting from 'features/dashboard/team/dashboard-team-settings-billing.routing';
import { useNavigate } from 'hooks/useNavigate';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback } from 'react';
import { ID } from 'types/pigeon';

import AlertDialogContent from '../alert-dialog-content/AlertDialogContent';

interface NoAvailableKeyDialogOwnProps {
  className?: string;
  noAvailableKeyDialogOpen: boolean;
  closeNoAvailableKeyDialog: VoidFunction;
  teamId: ID;
}

type NoAvailableKeyDialogProps = NoAvailableKeyDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'NoAvailableKeyDialog'});

function NoAvailableKeyDialog(props: NoAvailableKeyDialogProps) {
  const classes = useStyles(props);
  const {
    className,
    noAvailableKeyDialogOpen,
    closeNoAvailableKeyDialog,
    teamId,
  } = props;

  const { t } = useStrictTranslation(['common', 'dialog']);

  const onNavigate = useNavigate();

  const handleUpgradeRedirect = useCallback(() => {
    onNavigate(DashboardTeamSettingsBillingRouting.createUrl(teamId));
  }, [onNavigate, teamId]);

  return (
    <Dialog
    open={noAvailableKeyDialogOpen}
    onClose={closeNoAvailableKeyDialog}
    >
      <AlertDialogContent
      titleText={t('dialog:noavailablekey.title')}
      description={t('dialog:noavailablekey.desc')}
      confirm={{
        text: t('common:upgrade'),
        action: handleUpgradeRedirect,
      }}
      />
    </Dialog>
  );
}

export default React.memo(NoAvailableKeyDialog);
