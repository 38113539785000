import { Loader } from '@insights-gaming/material-components';
import memoizeOne from 'memoize-one';
import React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics,GetOverwatchPrimaryRosterTeamFightMapStatisticsV2QueryVariables } from '../../../../apollo/queries/types/GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query';
import { NO_ROSTER_SELECTED } from '../../../../constants/strings';
import { createVideoStatsCacheKey } from '../../../../helpers';
import { OwstatsF, OwstatsNS } from '../../../../locales/en/owstats';
import Message from '../../../../material/message/Message';
import { ETeamStatsTabType, teamOverwatchStatsRoute } from '../../../../routes';
import { ID } from '../../../../types/pigeon';
import OverwatchPrimaryRosterTeamFightMapStatisticsTable from './overwatch-primary-roster-team-fight-map-statistics-table/OverwatchPrimaryRosterTeamFightMapStatisticsTable';

export interface IOverwatchPrimaryRosterTeamFightMapStatisticsOwnProps {
  teamId   : ID;
  videoIds?: ID[];
  csvName  : string;
}

export interface IOverwatchPrimaryRosterTeamFightMapStatisticsMappedProps {
  primaryRosterTeamFightMapStatisticsCache?: Dictionary<
    GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics
  >;
  primaryRosterTeamFightMapStatisticsError?: Error;
}

export interface IOverwatchPrimaryRosterTeamFightMapStatisticsDispatch {
  onFetchOverwatchPrimaryRosterTeamFightMapStatistics: (
    params: GetOverwatchPrimaryRosterTeamFightMapStatisticsV2QueryVariables,
  ) => void;
}

export type OverwatchPrimaryRosterTeamFightMapStatisticsProps = IOverwatchPrimaryRosterTeamFightMapStatisticsOwnProps &
  IOverwatchPrimaryRosterTeamFightMapStatisticsMappedProps &
  IOverwatchPrimaryRosterTeamFightMapStatisticsDispatch &
  WithTranslation;


class OverwatchPrimaryRosterTeamFightMapStatistics extends React.Component<
  OverwatchPrimaryRosterTeamFightMapStatisticsProps
> {
  private memoizedCacheKey = memoizeOne(createVideoStatsCacheKey);

  public componentDidMount() {
    this.fetchStatistics();
  }

  public componentDidUpdate(prevProps: OverwatchPrimaryRosterTeamFightMapStatisticsProps) {
    const { videoIds, primaryRosterTeamFightMapStatisticsCache, primaryRosterTeamFightMapStatisticsError } = this.props;
    if (videoIds !== prevProps.videoIds) {
      this.fetchStatistics();
    } else if ( // cache invalidated
      (
        !primaryRosterTeamFightMapStatisticsCache ||
        !(this.memoizedCacheKey(videoIds) in primaryRosterTeamFightMapStatisticsCache)
      ) &&
      !primaryRosterTeamFightMapStatisticsError
    ) {
      this.fetchStatistics();
    }
  }

  public render() {
    const {
      videoIds,
      primaryRosterTeamFightMapStatisticsCache,
      primaryRosterTeamFightMapStatisticsError,
      csvName,
    } = this.props;
    if (primaryRosterTeamFightMapStatisticsError) {
      return this.renderError(primaryRosterTeamFightMapStatisticsError);
    }
    if (!primaryRosterTeamFightMapStatisticsCache) {
      return this.renderLoader();
    }
    const cacheKey = this.memoizedCacheKey(videoIds);
    const statistics = primaryRosterTeamFightMapStatisticsCache[cacheKey];
    switch (statistics) {
      case null     : return this.renderNoStatistics();
      case undefined: return this.renderLoader();
    }
    if (statistics.__typename !== 'OverwatchStatistics') { return this.renderNoStatistics(); }
    return (
      <OverwatchPrimaryRosterTeamFightMapStatisticsTable key={cacheKey} csvName={csvName} statistics={statistics} />
    );
  }

  private renderError = (error: Error) => {
    const { teamId } = this.props;
    // No Roster Selected
    if (error.message.includes(NO_ROSTER_SELECTED) || error.message.includes('internal system error')) {
      return (
        <div>
          <Trans ns={OwstatsNS} i18nKey={OwstatsF('norosterselected')}>
            Unexpected error, you may need to select a roster
            <Link to={teamOverwatchStatsRoute(teamId, ETeamStatsTabType.SELECT_ROSTER)}>here</Link>.
          </Trans>
        </div>
      );
    }

    return (
      <Message message={error.message} variant='error' />
    );
  }

  private renderNoStatistics = () => {
    return (
      <Message message='no statistics' />
    );
  }

  private renderLoader = () => {
    const { t } = this.props;
    return (
      <Loader key='statsloading'>
        {t(OwstatsF('aggregatingstats'))}
      </Loader>
    );
  }

  private fetchStatistics = () => {
    const { teamId, videoIds, onFetchOverwatchPrimaryRosterTeamFightMapStatistics } = this.props;
    onFetchOverwatchPrimaryRosterTeamFightMapStatistics({
      teamId,
      videoIds,
    });
  }
}

export default withTranslation(OwstatsNS)(OverwatchPrimaryRosterTeamFightMapStatistics);
