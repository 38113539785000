
import { Theme } from '@insights-gaming/theme';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { REGISTER_PATH } from 'routes';

import SignupAndVerifyDialogContentLayout from './SignupAndVerifyDialogContentLayout';

export interface SignupDialogContentLayoutOwnProps {
  className?: string;
}

export type SignupDialogContentLayoutProps = React.PropsWithChildren<SignupDialogContentLayoutOwnProps>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  description: {
    fontSize: '12px',
    textAlign: 'center',
  },
  halfOpacity: {
    color: theme.palette.text.deemphasized,
  },
  googlePolicy: {
    marginLeft: theme.spacing(0.5),
  },
  link: {
    textDecoration: 'none',
  },
}), { name: 'SignupDialogContentLayout' });

function SignupDialogContentLayout({
  children,
  className,
}: SignupDialogContentLayoutProps) {
  const classes = useStyles();

  const { t } = useStrictTranslation(['common', 'login']);

  const isRegisterRoute = !!useRouteMatch(REGISTER_PATH);

  const title = useMemo(() => {
    return isRegisterRoute ? t('login:register.title') : t('login:signup');
  }, [isRegisterRoute, t]);

  return (
    <SignupAndVerifyDialogContentLayout
    className={classNames(className, classes.root)}
    title={title}
    >
      {children}
      <div className={classes.description}>
        {/* NOTE: IF YOU GET RID OF THIS GOOGLE WILL GET ANGERY */}
        This site is protected by reCAPTCHA and the<br />
        Google <a href='https://policies.google.com/privacy'>Privacy Policy</a> and
        <a className={classes.googlePolicy} href='https://policies.google.com/terms'>Terms of Service</a> apply.
      </div>
    </SignupAndVerifyDialogContentLayout>
  );
}

export default React.memo(SignupDialogContentLayout);
