import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import classNames from 'classnames';
import LabelButton from 'components/video/video-replay/video-comment/comment-label/applied-tag-labels/LabelButton';
import React, { useCallback } from 'react';

interface SearchLabelButtonWrapperOwnProps {
  className?: string;
  tag: TagFragment;
  included?: boolean;
  addTagOnClick: (t: TagFragment) => void;
  removeTagOnClick: (t: TagFragment) => void;
}

type SearchLabelButtonWrapperProps = SearchLabelButtonWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'SearchLabelButtonWrapper'});

function SearchLabelButtonWrapper(props: SearchLabelButtonWrapperProps) {
  const classes = useStyles(props);
  const { className, tag, addTagOnClick, removeTagOnClick, included } = props;

  const addOnClick = useCallback(() => {
    if (!included) {
      return addTagOnClick(tag);
    }
    return;
  }, [addTagOnClick, included, tag]);

  const removeOnClick = useCallback(() => {
    if (included) {
      return removeTagOnClick(tag);
    }
    return;
  }, [included, removeTagOnClick, tag]);

  return (
    <LabelButton
    className={classNames(classes.root, className)}
    variant={included ? 'contained' : undefined}
    appliedCommentLabel={tag}
    canRemoveOwnCommentLabel={included}
    removeLabelOnClick={included ? removeOnClick : undefined}
    onClick={addOnClick}
    />
  );
}

export default React.memo(SearchLabelButtonWrapper);
