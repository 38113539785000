import gql from 'graphql-tag';

import { CreatedInvitationPayloadFragment } from '../fragments';

export const ShareVideo_Mutation = gql`
    mutation ShareVideoMutation(
        $input: ShareVideoInput!
    ) {
        shareVideo(
            input: $input
        ) {
            invitations {
                ...CreatedInvitationPayloadFragment
            }
        }
    }
    ${CreatedInvitationPayloadFragment}
`;
