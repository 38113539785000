import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import VerifyCallback, { IVerifyCallbackDispatch } from '../components/verify-callback/VerifyCallback';
import { onNavigateFactory } from '../types/dispatch';

function mapDispatchToProps(dispatch: Dispatch): IVerifyCallbackDispatch {
  return {
    onNavigate: onNavigateFactory(dispatch),
  };
}

export default connect<void, IVerifyCallbackDispatch>(null, mapDispatchToProps)(VerifyCallback);
