import { createSlice } from 'helpers/createSlice';
import { IVideoPlayerStoreState } from 'types';
import { Action } from 'typescript-fsa';

const name = 'video-player';

const initialState: IVideoPlayerStoreState = {
  isTheaterMode: false,
};

const videoPlayerSlice = createSlice({
  name,
  initialState,
  reducers: {
    jumpToTimeAC(state, { payload }: Action<number>) {
      return { ...state, jumpTo: { time: payload } };
    },
    enterTheaterModeAC(state) {
      state.isTheaterMode = true;
    },
    exitTheaterModeAC(state) {
      state.isTheaterMode = false;
    },
    toggleTheaterModeAC(state) {
      state.isTheaterMode = !state.isTheaterMode;
    },
  },
});

export const videoPlayerReducer = videoPlayerSlice.reducer;
export const { jumpToTimeAC, enterTheaterModeAC, exitTheaterModeAC, toggleTheaterModeAC } = videoPlayerSlice.actions;
