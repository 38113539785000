import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserNotificationSettingsState } from './notificaion-settings-selector';
import { fetchUserNotificationSettingsAC } from './notification-settings-slice';

function UserNotificationSettingsFetcher() {
  const dispatch = useDispatch();

  const { settings } = useSelector(getUserNotificationSettingsState);

  useEffect(() => {
    if (!settings) {
      dispatch(fetchUserNotificationSettingsAC.started({includeOverrides: false}));
    }
  }, [dispatch, settings]);

  return null;
}

export default React.memo(UserNotificationSettingsFetcher);
