import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SearchIcon from '@material-ui/icons/Search';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import InsightsCaptureButton from 'components/status-bar/InsightsCaptureButton';
import { EIntercomID } from 'constants/strings';
import { desktop, mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { initializeTeamSearchAC, searchTrackingAC } from 'features/search/search-slice';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import UploadDialogContent from 'material/dialogs/upload-dialog-content/UploadDialogContent';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ETeamDashboardTabType, teamRoute } from 'routes';
import { ID, TeamInterface } from 'types/pigeon';

import { useAccessControl } from '../access-control/useAccessControl';
import { useFetchDirectoryIfNecessary } from '../directory/useFetchDirectoryIfNecessary';
import DashboardHeader from './DashboardHeader';

interface DashboardDirectoryHeaderOwnProps {
  className?: string;
  directoryId: ID;
  team?: TeamFragment | TeamInterface;
  captureRefHandler?: ((el: HTMLElement | null) => void);
  uploadRefHandler?: ((el: HTMLElement | null) => void);
}

type DashboardDirectoryHeaderProps = DashboardDirectoryHeaderOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    [desktop(theme)]: {
      paddingLeft: theme.spacing(2),
    },
    [mobilePortrait(theme)]: {
      backgroundColor: theme.palette.background.paper,
    },
    [mobileLandscape(theme)]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  searchButton: {
    width: createRemFromPx(210),
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
  },
  mobileSearchButton: {
    marginLeft: theme.spacing(5),
  },
  label: {
    fontWeight: 400,
    textTransform: 'capitalize',
    color: theme.palette.text.deemphasized,
  },
  toolbarInner: {
    flex: 1,
    gridColumnStart: 2,
    alignItems: 'center',
  },
  inactive: {
    borderColor: theme.palette.text.disabled,
  },
  buttonRoot: {
    '&&:hover': {
      borderColor: theme.palette.text.primary,
    },
  },
  iconButton: {
    padding: theme.spacing(0.5, 1),
  },
}), {name: 'DashboardDirectoryHeader'});

function DashboardDirectoryHeader(props: DashboardDirectoryHeaderProps) {
  const classes = useStyles(props);
  const { className, directoryId, team, uploadRefHandler, captureRefHandler } = props;

  const [directory, sharedDirectory] = useFetchDirectoryIfNecessary(directoryId);

  const { t } = useStrictTranslation(['dialog', 'common']);

  const onNavigate = useNavigate();

  const { canCreateVod } = useAccessControl();

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialogState();

  const dispatch = useDispatch();

  const isDesktop = useIsDesktop();

  const teamId = directory
    ? directory.team.id
    : sharedDirectory
    ? sharedDirectory.team.id
    : undefined;

  const searchOnClick = useCallback(() => {
    if (team) {
      dispatch(searchTrackingAC(null, {label: 'search-button'}));
      dispatch(initializeTeamSearchAC({ teamId: team.id }));
      onNavigate(teamRoute(team.id, ETeamDashboardTabType.SEARCH));
    }
    return;
  }, [dispatch, onNavigate, team]);

  return (
    <DashboardHeader className={classNames(classes.root, className)} team={team} directoryId={directoryId}>
      {team && (
        isDesktop ? (
          <Button
          variant='outlined'
          endIcon={<SearchIcon/>}
          className={classes.searchButton}
          classes={{label: classes.label, outlined: classes.inactive, root: classes.buttonRoot}}
          onClick={searchOnClick}
          >
            {t('common:search')}
          </Button>
        ) : (
          <FlexSpacer
          className={classes.mobileSearchButton}
          flexJustifyContent='flex-start'
          flexAlignItems='center'
          >
            <IconButton onClick={searchOnClick}>
              <SearchIcon />
            </IconButton>
          </FlexSpacer>
        )
      )}
      <FlexSpacer className={classes.toolbarInner} flexJustifyContent='center'>
        {canCreateVod && (
          <div ref={uploadRefHandler}>
            {isDesktop ? (
              <Button
              id={EIntercomID.UPLOAD}
              variant='contained'
              color='primary'
              startIcon={<CloudUploadIcon />}
              onClick={openUploadDialog}
              >
                {t('dialog:upload.title')}
              </Button>
            ) : (
              <IconButton onClick={openUploadDialog} className={classes.iconButton}>
                <FlexSpacer flexAlignItems='center' orientation='vertical' spacing={0}>
                  <CloudUploadIcon />
                  <Typography style={{ fontSize: '0.7rem'}}>
                    {t('common:upload')}
                  </Typography>
                </FlexSpacer>
              </IconButton>
            )}
          </div>
        )}
        <div ref={captureRefHandler}>
          <InsightsCaptureButton/>
        </div>
        {teamId && (
          <Dialog fullWidth={true} open={isUploadDialogOpen} onClose={closeUploadDialog}>
            <UploadDialogContent
            directoryId={directoryId}
            teamId={teamId}
            droppedFiles={[]}
            onClose={closeUploadDialog}
            />
          </Dialog>
        )}
      </FlexSpacer>
    </DashboardHeader>
  );
}

export default React.memo(DashboardDirectoryHeader);
