import { watchAsync } from '@insights-gaming/saga-utils';
import { subscribeToUserEventsAC } from 'actions/event-actions';
import { createApolloClientWithoutWs, createApolloClientWithWs, IClient } from 'apollo/client';
import { RegisterPasskey_Mutation, RemoveAuthenticationMethod_Mutation, RevokeToken_Mutation } from 'apollo/mutations';
import { GetAuthenticationMethods_Query, GetTokens_Query } from 'apollo/queries';
import { push, replace } from 'connected-react-router';
import {
  AUTH_CHECK_ENDPOINT,
  LOGOUT_ENDPOINT,
  PASSWORD_RESET_ENDPOINT,
  REGISTER_ENDPOINT,
  TOKEN_ENDPOINT,
  VERIFY_ENDPOINT,
} from 'constants/index';
import { SITE_KEY } from 'constants/strings';
import { clearEventHistory } from 'factories/kmsessionEventFactory';
import { checkAuthAsyncAC, fetchAuthenticationMethodsAC, fetchUserProfileAsyncAC, fetchUserTokensAC, ILoginInput, IRegisterInput, loginAsyncAC, logoutAsyncAC, registerAsyncAC, registerPasskeyAC, removeAuthenticationMethodAC, revokeTokenAC, sendPasswordResetEmailAC, sendVerificationEmailAC } from 'features/auth/auth-slice';
import { loadKeybindingsAsyncAC } from 'features/keybinding/keybinding-slice';
import { ALL_TIPS_TO_FETCH, fetchTipsAsyncAC } from 'features/tips/tips-slice';
import { watchAsyncMutation, watchBidirectionalAC2 } from 'helpers/saga/effects';
import { removeLocalStorage } from 'helpers/storage';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, getContext, put, race, spawn, take, takeEvery } from 'redux-saga/effects';
import { BASE_DASHBOARD_PATH, REGISTER_VERIFY_PATH, signinRoute } from 'routes';
import { sagaMiddleware } from 'store';

declare const grecaptcha: any;

// i know there is a better way to do this but i just want the suffering to end
async function isLoggedIn(): Promise<boolean> {
  const res: Response = await fetch(AUTH_CHECK_ENDPOINT);
  if (!res.ok) {
    throw res;
  }
  return (await res.text()) === '1'; // 1 = logged in, 0 = not logged in
}

function* startAuthDelayTimer() {
  yield delay(200);
}

function* checkAuthWorker(): SagaIterator<boolean> {
  yield spawn(startAuthDelayTimer);

  const { result, timeout } = yield race({
    result: call(isLoggedIn),
    timeout: delay(15000),
  });

  if (result === undefined && timeout) {
    throw new Error('TIMEOUT');
  }

  return result;
}

async function getRecaptchaToken(action: string): Promise<string> {
  const recaptchaToken = await grecaptcha.execute(SITE_KEY, { action });
  if (!recaptchaToken) {
    // TODO: explody
    // eslint-disable-next-line
    console.log('no recaptcha token');
  }

  return recaptchaToken;
}

async function loginWorker(input: ILoginInput): Promise<boolean> {
  const body = new FormData();
  for (const [key, value] of Object.entries(input)) {
    if (ArrayBuffer.isView(value) || value instanceof ArrayBuffer) {
      body.append(key, new Blob([value], { type: 'application/octet-stream' }));
    } else {
      body.append(key, value);
    }
  }

  const res: Response = await fetch(TOKEN_ENDPOINT, {
    method: 'POST',
    body,
  });
  if (!res.ok) {
    throw new Error((await res.json()).error);
  }

  return true;
}

function* logoutWorker(params: { navigate: boolean }): SagaIterator<void> {
  sessionStorage.clear();
  localStorage.removeItem('access_token');
  yield call(fetch, LOGOUT_ENDPOINT, { redirect: 'manual' });
  if (params.navigate) {
    yield put(push(signinRoute()));
  }

  removeLocalStorage('lastVisitedTeam');
  removeLocalStorage('lastVisitedDirectory');
}

async function register(params: Record<string, string>): Promise<void> {
  const res: Response = await fetch(REGISTER_ENDPOINT, {
    method: 'POST',
    body: new URLSearchParams(params).toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  if (!res.ok) {
    throw new Error((await res.json()).error);
  }
}

function* registerWorker({ email, password, marketing, referrer }: IRegisterInput) {
  let shouldAttemptRegister = true;
  try {
    yield call(loginWorker, { username: email, password });
    return;
  } catch (error) {
    switch (error.message) {
      case 'NOT_VERIFIED':
        // skip registration; go to email verification
        shouldAttemptRegister = false;
        break;
    }
  }

  if (shouldAttemptRegister) {
    const recaptchaToken: string = yield call(getRecaptchaToken, 'signup');
    yield call(register, {
      email,
      password,
      marketing: marketing ? 'true' : 'false',
      referrer: referrer ?? '',
      'g-recaptcha-response': recaptchaToken,
    });

    try {
      yield call(loginWorker, { username: email, password });
      yield put(push(BASE_DASHBOARD_PATH)); // already verified
      return;
    } catch (error) {
      /* allow it to fall through */
    }
  }

  yield call(sendVerificationEmail, email);
  yield put(push(REGISTER_VERIFY_PATH));
}

export async function sendVerificationEmail(email: string): Promise<boolean> {
  const res: Response = await fetch(VERIFY_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({ email }).toString(),
  });
  if (!res.ok) {
    const { error } = await res.json();
    throw new Error(error);
  }
  return true;
}

export async function sendPasswordResetEmail(email: string): Promise<boolean> {
  const recaptchaToken: string = await getRecaptchaToken('signup');
  const res: Response = await fetch(PASSWORD_RESET_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      email,
      'g-recaptcha-response': recaptchaToken,
    }).toString(),
  });
  if (!res.ok) {
    const { error } = await res.json();
    throw new Error(error);
  }
  return true;
}

function* loginFlow(): SagaIterator {
  while (true) {
    const { alreadyLoggedIn, loginSuccess } = yield race({
      alreadyLoggedIn: take(checkAuthAsyncAC.done),
      loginSuccess: take(loginAsyncAC.done),
    });

    {
      const { client, dispose }: IClient = (yield getContext('apollo')) || { client: undefined, dispose: undefined };
      // dispose of the one that was already there
      if (dispose) {
        dispose();
      }
    }

    const apolloFactory = loginSuccess || alreadyLoggedIn.payload.result
      ? createApolloClientWithWs
      : createApolloClientWithoutWs;

    const apollo = yield call(apolloFactory);
    sagaMiddleware.setContext({ apollo });

    // TODO: do stuff that we want to do after logging in and the client is set up
    yield put(subscribeToUserEventsAC());
    yield put(loadKeybindingsAsyncAC.started());

    yield take(logoutAsyncAC.started);

    // TODO: do stuff that we want to do after logging out
    {
      const { client, dispose }: IClient = yield getContext('apollo');
      dispose();
    }

    yield call(clearEventHistory);
    // sagaMiddleware.setContext({apollo: undefined});
  }
}

export default function* authSaga() {
  yield all([
    loginFlow(),
    watchBidirectionalAC2(fetchUserTokensAC, GetTokens_Query, ['queryTokens']),
    watchBidirectionalAC2(fetchAuthenticationMethodsAC, GetAuthenticationMethods_Query, ['queryAuthenticationMethods']),
    watchAsyncMutation(revokeTokenAC, RevokeToken_Mutation, ['revokeToken']),
    watchAsyncMutation(registerPasskeyAC, RegisterPasskey_Mutation, ['registerPasskey']),
    watchAsyncMutation(
      removeAuthenticationMethodAC,
      RemoveAuthenticationMethod_Mutation,
      ['removeAuthenticationMethod'],
    ),
    watchAsync(checkAuthAsyncAC, checkAuthWorker),
    watchAsync(loginAsyncAC, loginWorker),
    watchAsync(logoutAsyncAC, logoutWorker),
    watchAsync(registerAsyncAC, registerWorker),
    watchAsync(sendVerificationEmailAC, sendVerificationEmail),
    watchAsync(sendPasswordResetEmailAC, sendPasswordResetEmail),
    takeEvery(logoutAsyncAC.done, function* ({ payload: { params: { navigate } } }) {
      if (!navigate) {
        return;
      }

      yield put(replace(BASE_DASHBOARD_PATH));
    }),
    takeEvery(checkAuthAsyncAC.done, function* ({ payload: { result } }) {
      if (result) {
        return;
      }

      yield put(logoutAsyncAC.started({ navigate: false }));
    }),
    takeEvery([checkAuthAsyncAC.done, loginAsyncAC.done], function* ({ payload: { result } }) {
      if (!result) {
        return;
      }

      yield put(fetchUserProfileAsyncAC.started());
      yield put(fetchTipsAsyncAC.started({
        names: ALL_TIPS_TO_FETCH,
      }));
    }),
    put(checkAuthAsyncAC.started()),
  ]);
}
