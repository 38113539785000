import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';
import { createTagsFilterCacheKey } from 'helpers';

import { makeGetDirectoryVideoRecords } from './dashboard-video-selector';
import { fetchTeamDirectoryVideosAC } from './dashboard-video-slice';

export const useFetchDirectoryVideos = createBidirectionalAutoFetcher({
  actionCreators: fetchTeamDirectoryVideosAC,
  selectorFactory: makeGetDirectoryVideoRecords,
  createFetchId: ({ directoryId, tagIds }) => `${directoryId}-${createTagsFilterCacheKey(tagIds)}`,
  pageSize: 60,
});
