import ListItemText from '@material-ui/core/ListItemText';
import { createStyles,withStyles } from '@material-ui/core/styles';

const styles = createStyles({
  primary: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export default withStyles(styles, {name: 'EllipsisListItemText'})(ListItemText);
