import { GetTeamRostersQueryVariables } from 'apollo/queries/types/GetTeamRostersQuery';
import { fetchPrimaryRosterAsyncAC, fetchTeamRostersAsyncAC } from 'features/statistics/statistics-slice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { GetPrimaryRosterQueryVariables } from '../../apollo/queries/types/GetPrimaryRosterQuery';

export function useFetchRosterPlayers(): (
  params: GetTeamRostersQueryVariables,
) => void {
  const dispatch = useDispatch();
  return useCallback(
    (params: GetTeamRostersQueryVariables) => dispatch(fetchTeamRostersAsyncAC.forward.started(params)),
    [dispatch],
  );
}

export function useFetchPrimaryRoster(): (
  params: GetPrimaryRosterQueryVariables,
) => void {
  const dispatch = useDispatch();
  return useCallback(
    (params: GetPrimaryRosterQueryVariables) => dispatch(fetchPrimaryRosterAsyncAC.started(params)),
    [dispatch],
  );
}
