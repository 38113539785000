import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { GetAvailableTeamResourceQuery_team_resources } from 'apollo/queries/types/GetAvailableTeamResourceQuery';
import { GetTeamSubscriptionDetailQuery_getTeamSubscriptionDetail } from 'apollo/queries/types/GetTeamSubscriptionDetailQuery';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { IAvailablePlanData } from 'features/dashboard/billing/useAvailableMetrics';
import useTeamAvailableResource from 'features/dashboard/billing/useTeamAvailableResource';
import useTeamResourceLimits from 'features/dashboard/billing/useTeamResourceLimits';
import { isExistent } from 'helpers';
import { secondsToHours } from 'helpers/formatters';
import { isSeatedFeature } from 'helpers/hasSeats';
import { useDialogState } from 'hooks/useDialogState';
import { TKeysWithNS, useStrictTranslation } from 'hooks/useStrictTranslation';
import clamp from 'lodash/clamp';
import React, { useCallback, useMemo } from 'react';
import { ProductInput, TeamFeature, TeamResource } from 'types/graphql';

import CancelSubsDialog from '../CancelSubsDialog/CancelSubsDialog';
import ChangePlansDialog from '../ChangePlansDialog/ChangePlansDialog';
import PlanFeedbackDialog from '../PlanFeedbackDialog/PlanFeedbackDialog';
import SuccessDialog from '../SuccessDialog/SuccessDialog';
import InventoryMetrics from './InventoryMetrics';
import InventoryMetricsSkeleton from './InventoryMetricsSkeleton';
import OverviewSummary from './OverviewSummary';
import { BorderLinearProgress } from './ProgressBar';

interface OverviewOwnProps {
  className?: string;
  team: TeamFragment;
  billingCycle: GetTeamSubscriptionDetailQuery_getTeamSubscriptionDetail | undefined;
  hasPlan: boolean;
  currentTeamProducts: ProductInput[] | undefined;
  displaySkeleton: boolean;
  teamBalance?: number;
  teamProration?: number;
  currentPlan?: IAvailablePlanData;
}

type OverviewProps = OverviewOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), { name: 'Overview' });

function resourceToPercent(resource: GetAvailableTeamResourceQuery_team_resources | undefined): number {
  if (typeof resource === 'undefined') {
    return 100;
  }
  if (resource.limit === null) {
    return 0;
  }
  return clamp(resource.usage/resource.limit*100, 0, 100);
}

function hourlyResourceToRenderMetricsArgs(
  resource: GetAvailableTeamResourceQuery_team_resources | undefined,
): [number, number, number] {
  return [
    secondsToHours(resource?.usage),
    secondsToHours(resource?.limit),
    resourceToPercent(resource),
  ];
}

function bytesResourceToRenderMetricsArgs(
  resource: GetAvailableTeamResourceQuery_team_resources | undefined,
): [number, number, number] {
  return [
    resource?.usage ? resource.usage / (2 ** 30) : 0,
    resource?.limit ? resource.limit / (2 ** 30) : 0,
    resourceToPercent(resource),
  ];
}

function Overview(props: OverviewProps) {
  const classes = useStyles(props);
  const {
    className,
    team,
    billingCycle,
    hasPlan,
    currentTeamProducts,
    displaySkeleton,
    teamBalance,
    teamProration,
    currentPlan,
  } = props;

  const { t } = useStrictTranslation(['dashboard']);

  const { canUpdateTeamSubscription }  = useAccessControl();

  const availableTeamResource = useTeamAvailableResource(team.id);

  const resourceLimits = useTeamResourceLimits(team.id);

  const [isAvailablePlansDialogOpen, openAvailablePlansDialog, closeAvailablePlansDialog] = useDialogState();
  const [isSuccessDialogOpen, _openSuccessDialog, closeSuccessDialog] = useDialogState();
  const [isCancelSubsDialogOpen, openCancelSubsDialog, closeCancelSubsDialog] = useDialogState();
  const [isFeedbackDialogOpen, _openFeedbackDialog, closeFeedbackDialog] = useDialogState();

  const internalDisplaySkeleton = useMemo(
    () => displaySkeleton || !availableTeamResource,
    [availableTeamResource, displaySkeleton],
  );

  const renderInventoryMetric = useCallback((
    [
      [labelKey, buttonKey],
      [count, total, percent],
    ]: [
      [TKeysWithNS<'dashboard'>, TKeysWithNS<'dashboard'>],
      [number, number, number],
    ],
    index: number,
  ) => internalDisplaySkeleton ? (
    <InventoryMetricsSkeleton key={index} hideButton={!!team.enterprise || !canUpdateTeamSubscription}/>
  ) : (
    <InventoryMetrics key={index}
    metricLabel={t(labelKey)}
    metrics={(
      !isFinite(total) ?
        t('dashboard:billing.enterpriseusage', { count }) : index === 1 ?
          t('dashboard:billing.keycount', { count, total }) : labelKey === 'dashboard:billing.storagelabel.duration' ?
          t('dashboard:billing.hourcount', { count, total }) :
          t('dashboard:billing.gbcount', {
            count: count ? count.toFixed(2) : count,
            total: Math.floor(total),
          })
    )}
    progressBar={(
      <BorderLinearProgress variant='determinate' value={percent} />
    )}
    metricButton={t(buttonKey)}
    onClick={openAvailablePlansDialog}
    hideButton={!!team.enterprise || !canUpdateTeamSubscription}
    />
  ), [canUpdateTeamSubscription, internalDisplaySkeleton, openAvailablePlansDialog, t, team.enterprise]);

  const openSuccessDialog = useCallback(() => {
    closeAvailablePlansDialog();
    _openSuccessDialog();
  }, [_openSuccessDialog, closeAvailablePlansDialog]);

  const openFeedbackDialog = useCallback(() => {
    closeCancelSubsDialog();
    _openFeedbackDialog();
  }, [_openFeedbackDialog, closeCancelSubsDialog]);

  const seatedAdFreeFeature = useMemo(
    () => team?.features?.filter(isSeatedFeature).find(({feature}) => feature === TeamFeature.ADFREE),
    [team?.features],
  );

  const adFreeCounts = useMemo(() => {
    return { count: seatedAdFreeFeature?.members.length ?? 0 , total: seatedAdFreeFeature?.limit ?? 0 };
  }, [seatedAdFreeFeature?.limit, seatedAdFreeFeature?.members.length]);

  return (
    <div className={classNames(classes.root, className)}>
      <OverviewSummary
      displaySkeleton={internalDisplaySkeleton}
      hasPlan={hasPlan}
      resourceLimits={resourceLimits}
      billingCycle={billingCycle}
      openAvailablePlansDialog={openAvailablePlansDialog}
      openCancelSubsDialog={openCancelSubsDialog}
      enterprise={team.enterprise}
      teamId={team.id}
      teamBalance={teamBalance}
      currentPlan={currentPlan}
      />
      <Divider />
      <FlexSpacer orientation='vertical'>
        {
          [
            // [
            //   ['dashboard:billing.uploadlabel', 'dashboard:billing.addhours'],
            //   resourceToRenderMetricsArgs(availableTeamResource?.[TeamResource.VIDEO_UPLOAD]),
            // ],
            availableTeamResource?.[TeamResource.VIDEO_STORAGE] && [
              ['dashboard:billing.storagelabel.duration', 'dashboard:billing.addstorage'],
              hourlyResourceToRenderMetricsArgs(availableTeamResource?.[TeamResource.VIDEO_STORAGE]),
            ],
            availableTeamResource?.[TeamResource.VIDEO_STORAGE_BYTES] && [
              ['dashboard:billing.storagelabel.bytes', 'dashboard:billing.addstorage'],
              bytesResourceToRenderMetricsArgs(availableTeamResource?.[TeamResource.VIDEO_STORAGE_BYTES]),
            ],
            [
              ['dashboard:billing.insightscapturekey', 'dashboard:billing.addcapturekey'],
              [adFreeCounts.count, adFreeCounts.total, adFreeCounts.count/adFreeCounts.total*100],
            ],
          ].filter(isExistent).map(renderInventoryMetric)
        }
      </FlexSpacer>
      <ChangePlansDialog
      open={isAvailablePlansDialogOpen}
      onClose={closeAvailablePlansDialog}
      hasPlan={hasPlan}
      team={team}
      currentTeamProducts={currentTeamProducts}
      onSuccess={openSuccessDialog}
      teamProration={teamProration}
      teamBalance={teamBalance}
      currentPlan={currentPlan}
      />
      <SuccessDialog
      open={isSuccessDialogOpen}
      onClose={closeSuccessDialog}
      update={false}
      team={team}
      />
      <CancelSubsDialog
      open={isCancelSubsDialogOpen}
      onClose={closeCancelSubsDialog}
      onCancel={openFeedbackDialog}
      team={team}
      currentPlan={currentPlan}
      />
      <PlanFeedbackDialog
      open={isFeedbackDialogOpen}
      onClose={closeFeedbackDialog}
      />
    </div>
  );
}

export default React.memo(Overview);
