import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback } from 'react';
import EmptyStateImage from 'subcomponents/empty-state-image/EmptyStateImage';

interface StatisticsErrorOwnProps {
  className?: string;
  error?: Error;
}

type StatisticsErrorProps = StatisticsErrorOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
  },
}), { name: 'StatisticsError' });

function StatisticsError(props: StatisticsErrorProps) {
  const classes = useStyles(props);
  const { className, error } = props;

  const { t } = useStrictTranslation(['statistics']);

  const renderAPIErrorMsg = useCallback((error: Error) => {
    switch (error.message) {
      case 'Data not found':
        return t('statistics:stats.error.matchnotfound');
      default:
        return t('statistics:stats.error.apierror');
    }
  }, [t]);

  return (
    <FlexSpacer
    className={classNames(classes.root, className)}
    orientation='vertical'
    flexAlignItems='center'
    fullWidth={true}
    flexJustifyContent='center'
    >
      <EmptyStateImage url='not-found' />
      {error ? (
        renderAPIErrorMsg(error)
      ) : t('statistics:stats.error.matchinfonotavailable')}
    </FlexSpacer>
  );
}

export default React.memo(StatisticsError);
