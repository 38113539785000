import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { WEBSOCKET_ENDPOINT } from '../constants';

interface IWsLink {
  wsLink : WebSocketLink;
  ack    : Promise<true>;
  dispose: VoidFunction;
}

function createWsLink(): IWsLink {
  const client = new SubscriptionClient(`${WEBSOCKET_ENDPOINT}/graphql`, {
    reconnect: process.env.NODE_ENV === 'production',
    timeout: 90000,
    lazy: true,
  });
  const ack: Promise<true> = new Promise((resolve, reject) => {
    client.onConnected(() => resolve(true));
    client.onError((error: Error) => reject(error));
  });

  return {
    wsLink: new WebSocketLink(client),
    ack,
    dispose: () => client.close(),
  };
}

export default createWsLink;
