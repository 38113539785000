import { Theme } from '@insights-gaming/theme';
import { createStyles,withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

const styles = (theme: Theme) => createStyles({
  root: {
    background: theme.palette.background.container,
  },
  indicator: {
    width: '100%',
  },
});

export default withStyles(styles, {name: 'SecondaryTabs'})(Tabs);
