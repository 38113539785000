import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { createCdnSearchParams } from 'helpers';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

import { ASSETS_ENDPOINT } from '../../constants';

interface ReferralEasterEggOwnProps {
  className?: string;
}

type ReferralEasterEggProps = ReferralEasterEggOwnProps;

interface EasterEggOwnProps {
  width: number;
  height: number;
}

type EasterEggImageProps = EasterEggOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  semiBold: {
    fontWeight: 600,
  },
  easterEgg: {
    width: 240,
    height: 190,
    backgroundSize: 'cover',
    backgroundImage: ({width = 240, height = 190}: EasterEggImageProps) =>
    `url(${ASSETS_ENDPOINT}/referral/easter-egg.png?${
      createCdnSearchParams({width, height, aspect_ratio: `${width}:${height}`})
    })`,
  },
}), {name: 'ReferralEasterEgg'});

function ReferralEasterEgg(props: ReferralEasterEggProps) {
  const classes = useStyles(props);
  const { className } = props;
  const { t } = useStrictTranslation(['dashboard']);
  return (
    <div className={classNames(classes.root, className)}>
      <Typography variant='h4' className={classes.semiBold} align='center'>
        {t('dashboard:referral.completed')}
      </Typography>
      <div className={classes.easterEgg}></div>
      <Typography align='center'>
        {t('dashboard:referral.appreciation')}
      </Typography>
    </div>
  );
}

export default React.memo(ReferralEasterEgg);
