import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import classNames from 'classnames';
import { ENotificationState } from 'components/settings/notifications/constants';
import NotificationMessage from 'components/settings/notifications/notifications-table/NotificationMessage';
import UnreadNotificationIndicator from 'components/settings/notifications/unread-notifications/UnreadNotificationIndicator';
import { useOnClickNotification } from 'components/settings/notifications/useOnClickNotification';
import { makeGetTeamById } from 'features/dashboard/team/dashboard-team-selector';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import UserAvatar from 'material/user-avatar/UserAvatar';
import React, { useMemo } from 'react';

import NotificationListItem from './NotificationListItem';
import NotificationMenuButton from './NotificationMenuButton';

interface NotificationListItemWrapperOwnProps {
  className?: string;
  notifications: NotificationFragment[];
}

const lineHeight = 1.5;
const lineClamp = 2;

type NotificationListItemWrapperProps = NotificationListItemWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'grid',
    gridGap: theme.spacing(1),
    gridTemplateColumns: 'auto 1fr',
    gridTemplateAreas: `
      "avatar   notification"
      ".        detail"
    `,
    '&$unviewed': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  avatar: {
    gridArea: 'avatar',
  },
  notificationText: {
    flex: 1,
    fontWeight: 'bold',
    display: 'box',
    paddingRight: theme.spacing(6),
    height: createRemFromPx(42), //height of two text stacked vertically
    lineHeight,
    overflow: 'hidden',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    lineClamp,
    boxOrient: 'vertical',
    position: 'relative',
    alignSelf: 'flex-start',
    gridArea: 'notification',
  },
  detail: {
    gridArea: 'detail',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  context: {
    position: 'absolute',
    right: theme.spacing(1),
    transform: 'translateY(50%)',
    top: 0,
  },
  hidden: {
    opacity: 0,
  },
  unviewed: {},
}), {name: 'NotificationListItemWrapper'});

function NotificationListItemWrapper(props: NotificationListItemWrapperProps) {
  const classes = useStyles(props);
  const { className, notifications } = props;

  const [firstNotification] = notifications;

  const numberOfUnreads = useMemo(() => {
    return notifications.filter(n => n.state !== ENotificationState.READ).length;
  }, [notifications]);

  const hasUnviewed = useMemo(() => {
    return !!notifications.find(n => n.state === ENotificationState.UNREAD);
  }, [notifications]);

  const team = useCreateSelector(makeGetTeamById, firstNotification.team.id);

  const { t } = useStrictTranslation(['notification']);

  const onClick = useOnClickNotification(notifications);

  return (
    <NotificationListItem
    key={firstNotification.id}
    className={classNames(
      classes.root,
      className,
      {[classes.unviewed]: hasUnviewed},
    )}
    avatar={
      firstNotification.actor && (
        <div className={classes.avatar}>
          <UserAvatar user={firstNotification.actor} size='sm' />
        </div>
      )
    }
    message={
      <div className={classes.notificationText}>
        <NotificationMessage notifications={notifications} />
      </div>
    }
    contextMenu={
      <FlexSpacer flexAlignItems='center' spacing={0.5} className={classes.context}>
        <UnreadNotificationIndicator
        className={classNames({
          [classes.hidden]: numberOfUnreads === 0,
        })}
        />
        <NotificationMenuButton notifications={notifications} />
      </FlexSpacer>
    }
    detail={team && (
      <div className={classes.detail}>
        <Typography variant='caption'>
          {team.name}
          {numberOfUnreads > 0 && <span>{t('notification:notifications.numberofunreads', {numberOfUnreads})}</span>}
        </Typography>
        <Typography variant='caption'>
          {t('notification:notifications.notificationrealtime', {date: firstNotification.created})}
        </Typography>
      </div>
    )}
    onClick={onClick}
    />
  );
}

export default React.memo(NotificationListItemWrapper);
