import { CardFragment, TeamFragment } from 'apollo/fragments';
import gql from 'graphql-tag';

export const RetryTeamSubscription_Mutation = gql`
    mutation RetryTeamSubscriptionMutation(
        $input: RetrySubscriptionInput!
    ) {
        retryTeamSubscription(
            input: $input
        ) {
            invoiceStatus
            secret
            team {
                ...TeamFragment
            }
            card {
                ...CardFragment
            }
        }
    }
    ${TeamFragment}
    ${CardFragment}
`;
