import { AsyncButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import formatDuration from 'date-fns/formatDuration';
import { parse as parseDuration } from 'duration-fns/dist/parse';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import React, { useCallback } from 'react';

interface LinkSettingsDialogContentOwnProps {
  className?: string;
  handleCreateInvitationLink: () => Promise<void>;
  onClose: VoidFunction;
  expiry: string;
  useLimit: number;
  handleExpiryChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleUseLimitChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

type LinkSettingsDialogContentProps = LinkSettingsDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  invitelinkOptions: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
  },
}), {name: 'LinkSettingsDialogContent'});

export const neverexpires = 'neverexpires';

export const defaultExpiryDuration = 'P1D';

const expiryOptions = [
  'P1D',
  'P2D',
  'P3D',
  'P4D',
  'P5D',
  neverexpires,
];

const useLimitOptions = [
  -1,
  1,
  5,
  10,
];

function LinkSettingsDialogContent(props: LinkSettingsDialogContentProps) {
  const classes = useStyles(props);
  const {
    className,
    handleCreateInvitationLink,
    onClose,
    expiry,
    useLimit,
    handleExpiryChange,
    handleUseLimitChange,
  } = props;

  const { t } = useStrictTranslation(['dialog', 'common']);

  const renderExpiryOption = useCallback((duration: string) => {
    if (duration === neverexpires) {
      return (
        <MenuItem key={duration} value={duration}>
          {t(('dialog:inviteteammembers.neverexpires'))}
        </MenuItem>
      );
    }
    return (
      <MenuItem key={duration} value={duration}>
        {formatDuration(parseDuration(duration))}
      </MenuItem>
    );
  }, [t]);

  const renderUseLimitOption = useCallback((limit: number) => {
    const str = limit > 0
      ? t(('dialog:inviteteammembers.uselimitcount'), {count: limit})
      : t(('dialog:inviteteammembers.nolimit'));

    return (
      <MenuItem key={limit} value={limit}>
        {str}
      </MenuItem>
    );
  }, [t]);

  return (
    <React.Fragment>
      <BetterDialogTitle>
        {t('dialog:inviteteammembers.linksettingstitle')}
      </BetterDialogTitle>
      <DialogContent>
        <div className={classes.invitelinkOptions}>
          <FormControl>
            <InputLabel htmlFor='expiry'>{t(('dialog:inviteteammembers.expiresafterlabel'))}</InputLabel>
            <Select
            inputProps={{id: 'expiry'}}
            name='expiry'
            value={expiry}
            onChange={handleExpiryChange}
            >
              {expiryOptions.map(renderExpiryOption)}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel htmlFor='uselimit'>{t('dialog:inviteteammembers.uselimitlabel')}</InputLabel>
            <Select
            inputProps={{id: 'uselimit'}}
            name='uselimit'
            value={useLimit}
            onChange={handleUseLimitChange}
            >
              {useLimitOptions.map(renderUseLimitOption)}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
        type='button'
        variant='outlined'
        onClick={onClose}
        >
          {t(('common:cancel'))}
        </Button>
        <AsyncButton
        variant='contained'
        color='primary'
        onClick={handleCreateInvitationLink}
        >
          {t(('dialog:inviteteammembers.createlink'))}
        </AsyncButton>
      </DialogActions>
    </React.Fragment>
  );
}

export default React.memo(LinkSettingsDialogContent);
