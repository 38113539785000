import { CDN_ENDPOINT } from 'constants/index';

import { sortParams } from '../../helpers/searchparams';

export const pathPrefix = '/~mahou';
export const preloadPath = pathPrefix + '/!/preload';
export const cacheNamespace = 'mahou/';
export const cacheNamePrefix = cacheNamespace + __COMMITSHA__ + '/';
export const defaultCacheName = cacheNamePrefix + 'default';
export const stickerfyMethodName = 'stickerfy';
export const stickerfyCacheName = cacheNamePrefix + stickerfyMethodName;
export const urlParamKey = 'mahou';

export type WaitUntil = (p: Promise<any>) => void;

export interface ValidPath {
    method: string;
    url: string;
}

const validPathRegexp = new RegExp(pathPrefix.replace('/', '\\/') + '\\/([\\w-]+)(\\/.+)');
export function validatePath(path: string): ValidPath | undefined {
    const match = validPathRegexp.exec(path);
    if (!match) {
        return;
    }

    let url: string | undefined;
    if (match[2].startsWith('/assets/')) {
        url = window.location.origin + match[2];
    } else if (match[2].startsWith('/cdn/')) {
        url = CDN_ENDPOINT + match[2].slice(4);
    }

    if (!url) {
        return;
    }

    return {
        method: match[1],
        url,
    };
}

// splitPath returns a tuple of the path and the query string (without the question mark)
export function splitPath(path: string): [string, string] {
    const q = path.indexOf('?');
    if (q === -1) {
        return [path, ''];
    }

    return [path.slice(0, q), path.slice(q + 1)];
}

export function normalizeCDNPath(path: string): string {
    const q = path.indexOf('?');
    if (q !== -1) {
        const params = new URLSearchParams(path.slice(q + 1));
        params.delete(urlParamKey);
        path = path.slice(0, q+1) + sortParams(params).toString();
    }

    return path;
}
