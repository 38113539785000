import { ProfileFragment } from 'apollo/fragments';
import gql from 'graphql-tag';

export const AddPrivateDirectoryUsers_Mutation = gql`
    mutation AddPrivateDirectoryUsersMutation(
        $input: AddPrivateDirectoryUsersInput!
    ) {
        addPrivateDirectoryUsers(
            input: $input
        ) {
            users {
                ...ProfileFragment
            }
        }
    }
    ${ProfileFragment}
`;
