import { isExistent } from 'helpers';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

const getTeamCustomRoleRecordsByTeamId = (state: RootReducer, props: {teamId: ID}) =>
  state.dashboardRole.teamCustomRoleRecords[props.teamId];

const getCustomRoleDict = (state: RootReducer) => state.dashboardRole.customRoleDict;

export const makeGetTeamCustomRoleRecordsByTeamId = () => createSelector(
  [getTeamCustomRoleRecordsByTeamId],
  (records) => records,
);

export const makeGetTeamCustomRolesByTeamId = () => createSelector(
  [getTeamCustomRoleRecordsByTeamId, getCustomRoleDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) {
      return [];
    }
    return ids.map(id => dict[id]).filter(isExistent);
  },
);
