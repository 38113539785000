import { Theme } from '@insights-gaming/theme';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import React from 'react';

import { combineIds } from '../../../../helpers';
import { TimelineEvent } from '../../../../types/pigeon/overwatch-match-data';
import Event from './event/Event';
import TeamFight from './teamfight/Teamfight';

export interface IEventsOwnProps {
  md: TimelineEvent[];
  time: number;
  scrollIntoView?: (element: HTMLElement | null) => void;
  jumpToTime?: (time: number) => void;
}

export type IEventsProps = IEventsOwnProps &
  WithStyles<typeof styles>;

const styles = (theme: Theme) => {
  return createStyles({
    events: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
    event: {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
  });
};

class Events extends React.PureComponent<IEventsProps> {

  private mostRelevantEvent = React.createRef<HTMLButtonElement>();

  public componentDidUpdate() {
    this.props.scrollIntoView?.(this.mostRelevantEvent.current);
  }

  public render() {
    const { md, classes } = this.props;
    const currentEvent = md.reduce(this.reduceMostRelevantEvent, undefined);
    return (
      <div className={classes.events}>
        {this.renderEventWithRelevance(md, currentEvent)}
      </div>
    );
  }

  private renderEventWithRelevance(events: TimelineEvent[], mostRelevant?: TimelineEvent) {
    return events.map(this.renderEvent(mostRelevant));
  }

  private jumpToTime = (time: number) => {
    this.props.jumpToTime?.(time);
  }

  private renderEvent = (mostRelevant?: TimelineEvent) => (event: TimelineEvent, i: number) => {
    const { time, jumpToTime, classes } = this.props;
    switch (event.type) {
      case 'teamfight':
        return (
          <TeamFight
          key={combineIds(event.time, event.type)}
          teamfightEvent={event.events}
          endTime={event.endTime}
          startTime={event.time}
          mostRelevantEvent={event === mostRelevant ? this.mostRelevantEvent : undefined}
          time={time}
          jumpToTime={this.jumpToTime}
          />
        );
      case 'event':
        return (
          <Event
          key={combineIds(event.time, event.type, event.event.eventType, event.formattedTime, i)}
          event={event.event}
          statuses={[]} // NOTE: If we insist on having ntf statuses, do it during analysis time.
          jumpToTime={this.jumpToTime}
          startTime={event.time}
          eventTime={event.formattedTime}
          mostRelevantEvent={event === mostRelevant ? this.mostRelevantEvent : undefined}
          className={classes.event}
          />
        );
      default:
        return null;
    }
  }

  private reduceMostRelevantEvent = (a?: TimelineEvent, b?: TimelineEvent): TimelineEvent | undefined => {
    const { time } = this.props;
    return this.time(b) >= this.time(a) && this.time(b) <= time ? b : a;
  }

  private time(tle?: TimelineEvent): number {
    if (tle) {
      if (tle.type === 'teamfight' && tle.events[0]) {
        return tle.events[0].event.time;
      } else if (tle.type === 'event') {
        return tle.event.time;
      }
    }
    return -1;
  }
}

export default withStyles(styles)(Events);
