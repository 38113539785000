import { VideoReplayContext } from '@insights-gaming/material-components';
import { SecondsFormatter } from '@insights-gaming/utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { RangedTimestampState } from './RangedTimestampContext';

const DEFAULT_RANGE = 10;

export function useRangedTimestampContextValue(): RangedTimestampState {
  const {
    state: {
      progress: { playedSeconds },
    },
  } = useContext(VideoReplayContext);

  const [ userStartTimeValue, setUserStartTimeValue ] = useState<string>(
    () => SecondsFormatter.format(playedSeconds, { maxDecimals: 3 }),
  );
  const [ userEndTimeValue, setUserEndTimeValue] = useState<string>(
    () => SecondsFormatter.format(playedSeconds + DEFAULT_RANGE),
  );
  const [ isTimeInvalid, setIsTimeInvalid ] = useState<boolean>(false);
  const [ displayEndTime, setDisplayEndTime ] = useState(false);

  const [hasMessage, setHasMessage] = useState(false);

  const [isStartTimeChanged, setIsStartTimeChanged] = useState<boolean>(false);

  const toggleDisplayEndTime = useCallback(() => {
    setDisplayEndTime(old => !old);
  }, []);

  const toggleDisplayEndTimeWithComment = useCallback(() => {
    if (!displayEndTime) {
      toggleDisplayEndTime();
      setUserEndTimeValue(userStartTimeValue);
    }
  }, [displayEndTime, toggleDisplayEndTime, userStartTimeValue]);

  useEffect(() => {
    if (!displayEndTime && !hasMessage && !isStartTimeChanged) {
      setUserStartTimeValue(SecondsFormatter.format(playedSeconds, { maxDecimals: 3 }));
      setUserEndTimeValue(SecondsFormatter.format(playedSeconds + DEFAULT_RANGE));
    }
  }, [displayEndTime, hasMessage, isStartTimeChanged, playedSeconds, userEndTimeValue, userStartTimeValue]);

  const [startTimeTextValue, startTimeNumericValue] = useMemo(() => {
    const formattedPlayedSeconds = SecondsFormatter.format(playedSeconds, { maxDecimals: 3 });
    return userStartTimeValue !== undefined ? [
      userStartTimeValue,
      SecondsFormatter.parse(userStartTimeValue),
    ] : [
      formattedPlayedSeconds,
      playedSeconds,
    ];
  }, [playedSeconds, userStartTimeValue]);

  const [endTimeTextValue, endTimeNumericValue] = useMemo(() => {
    const formattedPlayedSeconds = SecondsFormatter.format(playedSeconds + DEFAULT_RANGE);
    return userEndTimeValue !== undefined ? [
      userEndTimeValue,
      SecondsFormatter.parse(userEndTimeValue),
    ] : [
      formattedPlayedSeconds,
      playedSeconds + 1,
    ];
  }, [playedSeconds, userEndTimeValue]);

  return useMemo(() =>({
    isTimeInvalid,
    startTimeTextValue,
    startTimeNumericValue,
    endTimeTextValue,
    endTimeNumericValue,
    displayEndTime,
    isStartTimeChanged,
    setUserStartTimeValue,
    setUserEndTimeValue,
    setIsTimeInvalid,
    toggleDisplayEndTime,
    toggleDisplayEndTimeWithComment,
    setHasMessage,
    setIsStartTimeChanged,
  } as const), [
    isTimeInvalid,
    startTimeTextValue,
    startTimeNumericValue,
    endTimeTextValue,
    endTimeNumericValue,
    displayEndTime,
    isStartTimeChanged,
    setUserStartTimeValue,
    setUserEndTimeValue,
    setIsTimeInvalid,
    toggleDisplayEndTime,
    toggleDisplayEndTimeWithComment,
    setHasMessage,
    setIsStartTimeChanged,
  ]);
};
