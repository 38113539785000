import { AsyncButton, FlexSpacer, RichTextEditor } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import { updateCommentReplyAC, updateVideoCommentAC } from 'actions/comment-actions';
import { ContentState, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { VirtualizedCommentsContext } from 'features/video-replay/comment-panel/virtualized-comments/VirtualizedCommentsContext';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommentFragment } from '../../../../apollo/fragments/types/CommentFragment';
import { GetMembersQuery_queryMembers_edges_user } from '../../../../apollo/queries/types/GetMembersQuery';
import { useKeybindings } from '../../../../hooks/useKeybindings';
import { useMentionAutoCompleteMatcher } from '../../../../hooks/useMentionAutoCompleteMatcher';
import { buildKeystring } from '../../../../keybindings';
import { CommonF, CommonNS } from '../../../../locales/en/common';
import { VideoF, VideoNS } from '../../../../locales/en/video';
import { decorator } from '../../../../subcomponents/text-editor-with-tools/strategies/strategies';

export interface ICommentEditOwnProps {
  members : GetMembersQuery_queryMembers_edges_user[];
  comment : CommentFragment;
  videoId : string;

  handleToggleEditing: VoidFunction;
}

export type CommentEditProps = PropsWithChildren<ICommentEditOwnProps>;

const createEditorState = (comment: CommentFragment) => {
  if (!comment.message) { return EditorState.createEmpty(decorator); }

  try {
    const contentState = convertFromRaw(JSON.parse(comment.message));
    const editorState = EditorState.createWithContent(contentState, decorator);
    return editorState;
  } catch {
    const contentState = ContentState.createFromText(comment.message);
    const editorState = EditorState.createWithContent(contentState, decorator);
    return editorState;
  }
};

const CommentEdit: React.FC<CommentEditProps> = (props: CommentEditProps) => {
  const { members, comment, handleToggleEditing, videoId } = props;

  const { resizeComment } = useContext(VirtualizedCommentsContext);

  const { t } = useTranslation([CommonNS, VideoNS]);
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(createEditorState(comment));

  useEffect(() => {
    setEditorState(e => EditorState.moveFocusToEnd(e));
  }, []);

  useEffect(() => {
    resizeComment(comment);
  }, [resizeComment, comment, editorState]);

  const handleFormOnSubmit = useCallback(async () => {
    const contentState = editorState.getCurrentContent();
    const commentPlainText = contentState.getPlainText();

    if (!commentPlainText || loading) { return; }

    const commentJson = JSON.stringify(convertToRaw(contentState));
    setLoading(true);

    try {
      // TODO: Annotation editing, time range, etc
      if (comment.__typename === 'VideoComment') {
        await promiseSagaDispatch(updateVideoCommentAC, {
          commentId: comment.id,
          videoId,
          message: commentJson,
        });
      } else {
        await promiseSagaDispatch(updateCommentReplyAC, {
          commentId: comment.parent,
          replyId: comment.id,
          videoId,
          message: commentJson,
        });
      }
      setLoading(false);
      handleToggleEditing();
      resizeComment(comment);
    } catch (e) {
      // TODO: Error Handling
      setLoading(false);
    }
  }, [videoId, comment, loading, editorState, handleToggleEditing, promiseSagaDispatch, resizeComment]);

  const mentionAutoCompleteMatcher = useMentionAutoCompleteMatcher(members);
  const autoCompleteMatchers = useMemo(() => {
    return [
      mentionAutoCompleteMatcher,
    ];
  }, [mentionAutoCompleteMatcher]);

  const { addContext, removeContext } = useKeybindings('editor.text.focus');

  const customKeyBindFn = useCallback((e: React.KeyboardEvent) => {
    switch (buildKeystring(e.nativeEvent)) {
      case 'ctrl+enter':
        return 'submit_comment';
    }
    return null;
  }, []);

  const customKeyCommands = useMemo(() => {
    return new Map([
      ['submit_comment', handleFormOnSubmit],
    ]);
  }, [handleFormOnSubmit]);

  return (
    <FlexSpacer orientation='vertical'>
      <RichTextEditor
      editorState={editorState}
      onStateChange={setEditorState}
      autoFocus={true}
      compactTools={true}
      onFocus={addContext}
      onBlur={removeContext}
      autoCompleteMatchers={autoCompleteMatchers}
      customKeyBindFn={customKeyBindFn}
      customKeyCommands={customKeyCommands}
      />
      <FlexSpacer flexJustifyContent='flex-end'>
        <Button
        variant='outlined'
        size='small'
        onClick={handleToggleEditing}
        >
          {t(CommonF('cancel'))}
        </Button>
        <AsyncButton
        title={t(VideoF('replay.sendshortcut'))}
        variant='contained'
        color='primary'
        size='small'
        loading={loading}
        disabled={loading}
        onClick={handleFormOnSubmit}
        >
          {t(CommonF('save'))}
        </AsyncButton>
      </FlexSpacer>
    </FlexSpacer>
  );
};

export default CommentEdit;
