import { ContextMenu, ContextMenuItem, EnhancedDialogTitle, FlexSpacer, UndraggableAvatar, VerticalScroll } from '@insights-gaming/material-components';
import { useBidirectionalFetchStatus } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { DirectoryFragment, DirectoryFragment_Division } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import classNames from 'classnames';
import DragDropDirectoryCard from 'components/directory-card/DragDropDirectoryCard';
import PagedDirectoryGrid from 'components/directory-grid/PagedDirectoryGrid';
import DirectoryButton from 'components/directory-link/DirectoryButton';
import DirectoryLinkSkeleton from 'components/directory-link/DirectoryLinkSkeleton';
import DragVideoCard from 'components/video-card/DragVideoCard';
import PagedVideoGrid from 'components/video-grid/PagedVideoGrid';
import { EIntercomID } from 'constants/strings';
import { mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useCreateSelector, useParametricSelectorFactory } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import update from 'immutability-helper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as portals from 'react-reverse-portal';
import TagChip from 'subcomponents/tag-chip/TagChip';
import { ID, ITag } from 'types/pigeon';

import { DEFAULT_DIVISION_ID } from '../directory/dashboard-directory-constants';
import { makeGetTeamDirectoriesByTeamId, makeGetTeamDirectoryFolderRecordsByDirectoryId, makeGetTeamDirectoryFoldersByDirectoryId, makeGetTeamDirectoryRecordsByTeamId } from '../directory/dashboard-directory-selector';
import DirectoryFolderFetcher from '../directory/DirectoryFolderFetcher';
import { useFetchDirectoryTree } from '../directory/useFetchDirectoryTree';
import { DashboardMultiSelectProvider } from '../multi-select/DashboardMultiSelectContext';
import { useDashboardMultiSelectContextValue } from '../multi-select/useDashboardMultiSelectContext';
import { multiSelectCheckboxProps } from '../multi-select/useMultiSelectState';
import Sidebar from '../sidebar/Sidebar';
import TeamPicker from '../sidebar/TeamPicker';
import { makeGetTagsByIds } from '../tag/dashboard-tag-selector';
import { makeGetTeamById } from '../team/dashboard-team-selector';
import { makeGetDirectoryVideoRecords } from '../video/dashboard-video-selector';
import DirectoryVideoFetcher from '../video/DirectoryVideoFetcher';
import TeamVideoFetcher from '../video/TeamVideoFetcher';

interface VideoPickerDialogOwnProps {
  multiple?: boolean;
  teamId: ID;
  initialDirectoryId?: ID;
  initialValue?: ID[]; // only used if multiple === true
  filterFn?: (video: VideoFragment) => boolean;
  onClose?: VoidFunction;
  onConfirm?: (videoIds: ID | ID[]) => void;
  teamPicker?: boolean;
}

type VideoPickerDialogProps = VideoPickerDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    overflow: 'hidden',
    flex: '1 0 100vh',
    maxHeight: '100%',
    position: 'relative',
  },
  paper: {
    height: '100%',
  },
  main: {
    flex: 1,
  },
  header: {
    '& > :first-child': {
      width: '100%',
    },
  },
  sidebar: {
    backgroundColor: theme.palette.background.paper,
    width: createRemFromPx(240),
    maxWidth: createRemFromPx(240),
    flex: 1,
  },
  sectionDesktop: {
    display: 'flex',
    [mobilePortrait(theme)]: {
      display: 'none',
    },
  },
  drawer: {
    width: theme.appSizes.drawerWidth + 1,
    flexShrink: 0,
  },
  filterSection: {
    flex: 1,
    margin: 0,
  },
  tagFilter: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    maxWidth: '100%',
    width: '100%',
    flexWrap: 'wrap',
  },
  dialogFooter: {
    margin: theme.spacing(1, 3),
  },
  rotate180: {
    transform: 'rotate(180deg)',
    width: createRemFromPx(32),
    height: createRemFromPx(32),
  },
  avatar: {
    display: 'flex',
  },
  arrowIcon: {
    width: createRemFromPx(32),
    height: createRemFromPx(32),
  },
  collapseButton: {
    background: 'transparent',
    width: theme.appSizes.drawerWidth,
    height: theme.appSizes.drawerWidth+12,
  },
}), {name: 'VideoPickerDialog'});

function VideoPickerDialog(props: VideoPickerDialogProps) {
  const classes = useStyles(props);
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth='xl' fullWidth={true} classes={{paper: classes.paper}}>
      <VideoPickerDialogContent {...props} />
    </Dialog>
  );
}

function VideoPickerDialogContent(props: VideoPickerDialogProps) {
  const classes = useStyles(props);
  const {
    multiple,
    teamId,
    initialDirectoryId,
    initialValue,
    filterFn,
    onClose,
    onConfirm,
    teamPicker,
  } = props;

  const [dialogTeamId, setDialogTeamId] = useState(teamId);
  const [videoPageIds, setVideoPageIds] = useState<ID[]>([]);

  const isDesktop = useIsDesktop();
  const [isSidebarOpen, openSidebar, closeSidebar] = useDialogState();

  const team = useCreateSelector(makeGetTeamById, dialogTeamId);

  const { t } = useStrictTranslation(['common', 'dialog', 'dashboard-main']);

  const [isTeamPickerOpen, openTeamPicker, closeTeamPicker] = useDialogState();

  const dashboardMultiSelectValue = useDashboardMultiSelectContextValue({initialVideos: initialValue});
  const {
    videos: {
      selected: selectedVideoIds,
      addToSelection: addToVideoSelection,
      removeFromSelection: removeFromVideoSelection,
      toggleSelection: toggleVideoSelection,
      deselectAll: deselectAllVideos,
    },
  } = dashboardMultiSelectValue;

  const [selectedTagIds, setSelectedTagIds] = useState(() => new Set<ID>());

  const addToTagSelection = useCallback((ids: ID[]) => {
    setSelectedTagIds(old => update(old, {$add: ids}));
  }, []);

  const removeFromTagSelection = useCallback((ids: ID[]) => {
    setSelectedTagIds(old => update(old, {$remove: ids}));
  }, []);

  const handleDialogTagClick = useCallback((e: React.MouseEvent, tag: ITag) => {
    if (selectedTagIds.has(tag.id)) {
      removeFromTagSelection([tag.id]);
    } else {
      addToTagSelection([tag.id]);
    }
  }, [addToTagSelection, removeFromTagSelection, selectedTagIds]);

  const selectNoneOnPage = useCallback(() => {
    removeFromVideoSelection(videoPageIds);
  }, [removeFromVideoSelection, videoPageIds]);

  const selectAllOnPage = useCallback(() => {
    addToVideoSelection(videoPageIds);
  }, [addToVideoSelection, videoPageIds]);

  const items: ContextMenuItem[] = useMemo(() => {
    return [{
      text: t('dashboard-main:pageselect.all'),
      onClick: selectAllOnPage,
    }, {
      text: t('dashboard-main:pageselect.none'),
      onClick: selectNoneOnPage,
    }];
  }, [selectAllOnPage, selectNoneOnPage, t]);

  const selectedCount = selectedVideoIds.size;

  const selectedTags = useCreateSelector(makeGetTagsByIds, {tagIds: Array.from(selectedTagIds)});

  const handleVideoGridPageIdsChange = useCallback((pageNumber: number, ids: ID[]) => {
    setVideoPageIds(ids);
  }, []);

  const { checked, indeterminate } = multiSelectCheckboxProps([
    [videoPageIds, selectedVideoIds],
  ]);

  const handleSelectCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      removeFromVideoSelection(videoPageIds);
    } else {
      addToVideoSelection(videoPageIds);
    }
  }, [addToVideoSelection, removeFromVideoSelection, videoPageIds]);

  const [directoryId, setDirectoryId] = useState(initialDirectoryId);
  const getTeamDirectoryFolders = useParametricSelectorFactory(makeGetTeamDirectoryFoldersByDirectoryId, directoryId);
  const getTeamDivisions = useParametricSelectorFactory(makeGetTeamDirectoriesByTeamId, dialogTeamId);
  const teamDefaultDivisionId = useSelector(getTeamDivisions).map(division => division.id)[0];
  const directorySelector = directoryId && directoryId !== DEFAULT_DIVISION_ID
    ? getTeamDirectoryFolders
    : getTeamDivisions;
  const directories = useSelector(directorySelector);

  const getTeamDirectoryFolderRecords = useParametricSelectorFactory(
    makeGetTeamDirectoryFolderRecordsByDirectoryId,
    directoryId,
  );
  const getTeamDivisionRecords = useParametricSelectorFactory(makeGetTeamDirectoryRecordsByTeamId, dialogTeamId);
  const directoryRecordSelector = directoryId && directoryId !== DEFAULT_DIVISION_ID
    ? getTeamDirectoryFolderRecords
    : getTeamDivisionRecords;

  const { forward: foldersForwardFetchStatus } = useBidirectionalFetchStatus(directoryRecordSelector);
  const initialFolderFetch = foldersForwardFetchStatus.fetching && !foldersForwardFetchStatus.cursor;

  const directoryVideosQueryParams = useMemo(
    () => ({ directoryId: directoryId || DEFAULT_DIVISION_ID, tagIds: Array.from(selectedTagIds) }),
    [directoryId, selectedTagIds],
  );

  const videosRecord = useCreateSelector(makeGetDirectoryVideoRecords, directoryVideosQueryParams);

  const [dialogVideoPage, setDialogVideoPage] = useState(0);

  const removeAllTags = useCallback(() => {
    removeFromTagSelection(Array.from(selectedTagIds));
  }, [removeFromTagSelection, selectedTagIds]);

  const handlePageChange = useCallback((value: number) => {
    setDialogVideoPage(value);
  }, []);

  const handleDialogTeamChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target: { value } } = e;
    setDialogTeamId(value);
    setDirectoryId(undefined);
    removeAllTags();
    closeTeamPicker();
  }, [closeTeamPicker, removeAllTags]);

  const handleTeamPicker = useCallback((e: React.MouseEvent<HTMLButtonElement>, teamId: ID) => {
    setDialogTeamId(teamId);
    setDirectoryId(undefined);
    removeAllTags();
    closeTeamPicker();
  }, [closeTeamPicker, removeAllTags]);

  const handleDialogDivisionClick = useCallback((e: React.MouseEvent, division: DirectoryFragment_Division) => {
    if (division.id !== directoryId) {
      removeAllTags();
      setDirectoryId(division.id);
    }
  }, [directoryId, removeAllTags]);

  const handleCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, video: VideoFragment) => {
    const { target: { checked } } = e;
    if (checked) {
      addToVideoSelection([video.id]);
    } else {
      removeFromVideoSelection([video.id]);
    }
  }, [addToVideoSelection, removeFromVideoSelection]);

  const handleTeamClick = useCallback(() => {
    setDirectoryId(undefined);
  }, []);

  const handleDirectoryClick = useCallback((e: React.MouseEvent, directory: DirectoryFragment) => {
    setDirectoryId(directory.id);
  }, []);

  const handleVideoClick = useCallback((e: React.MouseEvent, video: VideoFragment) => {
    if (multiple) {
      toggleVideoSelection([video.id]);
    } else {
      onConfirm?.(video.id);
      onClose?.();
    }
  }, [multiple, onClose, onConfirm, toggleVideoSelection]);

  const handleConfirm = useCallback((e: React.MouseEvent) => {
    onConfirm?.(Array.from(selectedVideoIds));
    onClose?.();
  }, [onClose, onConfirm, selectedVideoIds]);

  const handleTagOnDelete = useCallback((e: React.MouseEvent, tag: ITag) => {
    if (selectedTagIds.has(tag.id)) {
      removeFromTagSelection([tag.id]);
    }
  }, [removeFromTagSelection, selectedTagIds]);

  const handleTagOnClearAll = useCallback((e: React.MouseEvent) => {
    removeFromTagSelection(Array.from(selectedTagIds));
  }, [removeFromTagSelection, selectedTagIds]);

  const renderDirectoryCard = useCallback((directory: DirectoryFragment, i: number) => (
    <DragDropDirectoryCard
    directory={directory}
    canDrag={false}
    onClick={handleDirectoryClick}
    shouldHideMenu={true}
    />
  ), [handleDirectoryClick]);

  const renderVideoCard = useCallback((video: VideoFragment, i: number) => (
    <DragVideoCard
    video={video}
    canDrag={false}
    selected={selectedVideoIds?.has(video.id)}
    onClick={handleVideoClick}
    onCheckboxChange={multiple ? handleCheckboxChange : undefined}
    shouldHideMenu={true}
    />
  ), [handleCheckboxChange, handleVideoClick, multiple, selectedVideoIds]);

  const actionButtons = useMemo(() => {
    return (
      teamPicker && (
        <IconButton
        size='small'
        edge='end'
        onClick={openTeamPicker}
        >
          <ArrowRightAltIcon className={classes.rotate180}/>
        </IconButton>
      )
    );
  }, [classes.rotate180, openTeamPicker, teamPicker]);

  const teamActionButtons = useMemo(() => {
    return (
      <UndraggableAvatar className={classNames(classes.avatar, classes.collapseButton)}>
        <IconButton
        size='small'
        edge='end'
        onClick={closeTeamPicker}
        >
          <ArrowRightAltIcon className={classes.arrowIcon}/>
        </IconButton>
      </UndraggableAvatar>
    );
  }, [classes.arrowIcon, classes.avatar, classes.collapseButton, closeTeamPicker]);


  const [dirs, ancestor, status] = useFetchDirectoryTree(directoryId);

  useEffect(() => {
    if (!directoryId) {
      setDirectoryId(teamDefaultDivisionId);
    }
  }, [directoryId, teamDefaultDivisionId]);

  useEffect(() => {
    setDialogVideoPage(0);
  }, [directoryId]);

  const portalNode = useMemo(() => portals.createHtmlPortalNode(), []);

  return (
    <DashboardMultiSelectProvider value={dashboardMultiSelectValue}>
      <div className={classes.root}>
        {team && (
          isTeamPickerOpen ? (
            <Drawer
            className={classNames(classes.sectionDesktop, classes.drawer)}
            variant='permanent'
            anchor='left'
            PaperProps={{
              style: {
                position: 'absolute',
              },
            }}
            >
              <TeamPicker
              selectedTeamId={dialogTeamId}
              onTeamClick={handleTeamPicker}
              teamActionButtons={teamActionButtons}
              />
            </Drawer>
          ) : (
            isDesktop ? (
              <VerticalScroll className={classes.sidebar}>
                <Sidebar
                team={team}
                directoryId={directoryId}
                teamPicker={teamPicker}
                onDivisionClick={handleDialogDivisionClick}
                selectedTagIds={selectedTagIds}
                onTagClick={handleDialogTagClick}
                onTeamChange={handleDialogTeamChange}
                actionButtons={actionButtons}
                shouldHideActionButtons={true}
                />
              </VerticalScroll>
            ) : (
              <SwipeableDrawer open={isSidebarOpen} onOpen={openSidebar} onClose={closeSidebar}>
                <VerticalScroll className={classes.sidebar}>
                  <Sidebar
                  team={team}
                  directoryId={directoryId}
                  onDivisionClick={handleDialogDivisionClick}
                  selectedTagIds={selectedTagIds}
                  onTagClick={handleDialogTagClick}
                  onTeamChange={handleDialogTeamChange}
                  actionButtons={actionButtons}
                  shouldHideActionButtons={true}
                  />
                </VerticalScroll>
              </SwipeableDrawer>
            )
          )
        )}
        <FlexSpacer orientation='vertical' className={classes.main}>
          <EnhancedDialogTitle onClose={onClose} className={classes.header}>
            {!isDesktop &&
              <IconButton onClick={openSidebar} size='small'>
                <ArrowRightIcon />
              </IconButton>
            }
            <Breadcrumbs>
              {team && (
                <Button onClick={handleTeamClick}>
                  {team.name}
                </Button>
              )}
              {!ancestor && !!status?.fetching && (
                <DirectoryLinkSkeleton />
              )}
              {dirs.map(directory => (
                <DirectoryButton key={directory.id} directory={directory} onClick={handleDirectoryClick} />
              ))}
            </Breadcrumbs>
          </EnhancedDialogTitle>
          <DialogContent style={{marginTop: 0, paddingTop: 0}}>
            {directoryId && directoryId !== DEFAULT_DIVISION_ID ? (
              <React.Fragment>
                <DirectoryVideoFetcher {...directoryVideosQueryParams} />
                <DirectoryFolderFetcher directoryId={directoryId} />
              </React.Fragment>
            ) : (
              <TeamVideoFetcher teamId={dialogTeamId} />
            )}
            <FlexSpacer orientation='vertical'>
              <FlexSpacer
              id={EIntercomID.SELECT_VIDEOS_ALL}
              flexAlignItems='center'
              flexJustifyContent='flex-end'
              spacing={0}
              className={classes.filterSection}
              >
                {selectedTagIds.size > 0 && (
                  <FlexSpacer className={classes.tagFilter} flexAlignItems='center'>
                    <Typography variant='body2'>{t('common:videowith')}</Typography>
                    {selectedTags.map((tag) => (
                      <TagChip tag={tag} key={tag.id} handleTagOnDelete={handleTagOnDelete}/>
                    ))}
                    <Button onClick={handleTagOnClearAll}>{t('common:clear')}</Button>
                  </FlexSpacer>
                )}
                {multiple && (
                  <React.Fragment>
                    <FormControlLabel
                    control={
                      <Checkbox
                      checked={checked}
                      indeterminate={indeterminate}
                      onChange={handleSelectCheckboxChange}
                      />
                    }
                    label={t('common:select')}
                    labelPlacement='start'
                    />
                    <ContextMenu popupId='selection-menu' icon={<ArrowDropDownIcon />} items={items} />
                    {selectedCount > 0 && (
                      <Chip
                      label={t('dashboard-main:pageselect.selectedCount', {count: selectedCount})}
                      onDelete={deselectAllVideos}
                      />
                    )}
                  </React.Fragment>
                )}
              </FlexSpacer>
              {directoryId !== DEFAULT_DIVISION_ID && (
                <PagedDirectoryGrid
                directories={directories}
                displaySkeleton={initialFolderFetch}
                renderDirectoryCard={renderDirectoryCard}
                />
              )}
              {directoryId && (
                <PagedVideoGrid
                videosRecord={videosRecord}
                onPageIdsChange={handleVideoGridPageIdsChange}
                renderVideoCard={renderVideoCard}
                page={dialogVideoPage}
                onPageChange={handlePageChange}
                paginationPortalNode={portalNode}
                />
              )}
            </FlexSpacer>
          </DialogContent>
          <FlexSpacer
          flexAlignItems='center'
          flexJustifyContent={
            videosRecord && videosRecord.ids.length > PagedVideoGrid.pageSize ? 'space-between' : 'flex-end'
          }
          className={classes.dialogFooter}
          >
            <portals.OutPortal node={portalNode} />
            {multiple && (
              <DialogActions>
                <Button variant='contained' color='primary' onClick={handleConfirm} disabled={!selectedVideoIds.size}>
                  {t('dialog:videoselector.confirmcount', {count: selectedVideoIds.size})}
                </Button>
              </DialogActions>
            )}
          </FlexSpacer>
        </FlexSpacer>
      </div>
    </DashboardMultiSelectProvider>
  );
}

export default React.memo(VideoPickerDialog);
