import gql from 'graphql-tag';

import { MemberFragment } from '../fragments';

export const RemoveMemberRoles_Mutation = gql`
    mutation RemoveMemberRolesMutation(
        $input: RemoveMemberRolesInput!
    ) {
        removeMemberRoles(
            input: $input
        ) {
            member {
                ...MemberFragment
            }
        }
    }
    ${MemberFragment}
`;
