import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { deleteInvitationAC } from 'actions/invitation-actions';
import { Invitation2Fragment } from 'apollo/fragments/types/Invitation2Fragment';
import { Time } from 'components/time/Time';
import { EIntercomID, TIME_FORMAT } from 'constants/strings';
import { makeGetTeamCustomRolesByTeamId } from 'features/dashboard/role/dashboard-role-selector';
import { useIsDesktop } from 'features/media-queries/hooks';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { invitationCodeRoute } from 'routes';
import { DeleteInvitationInput } from 'types/graphql';
import { ID } from 'types/pigeon';

import InvitationTableRowSkeleton from './InvitationTableRowSkeleton';
import InvitationTableRowWrapper from './InvitationTableRowWrapper';

interface InvitationTableOwnProps {
  className?: string;
  unexpiredInvitations: Invitation2Fragment[];
  teamId: ID;
  displaySkeleton?: boolean;
}

type InvitationTableProps = InvitationTableOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginBottom: theme.spacing(2),
  },
}), {name: 'InvitationTable'});

function InvitationTable(props: InvitationTableProps) {
  const classes = useStyles(props);
  const { className, unexpiredInvitations, teamId, displaySkeleton } = props;
  const { t } = useStrictTranslation(['common', 'dashboard', 'dialog']);
  const wt = t as TFunction;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const customRoles = useCreateSelector(makeGetTeamCustomRolesByTeamId, {teamId});
  const isDesktop = useIsDesktop();

  const handleDeleteOnClick = useCallback(async (id: ID) => {
    setLoading(true);
    try {
      const input: DeleteInvitationInput = {
        invitationId: id,
        teamId,
      };
      const result = await promiseSagaDispatch(deleteInvitationAC, input);
      enqueueSnackbar(t('dashboard:invite.deletesuccess'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('dashboard:invite.deletefailure'), {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [teamId, enqueueSnackbar, promiseSagaDispatch, t]);

  return (
    <Table id={EIntercomID.INVITES_TABLE} className={classes.root} size='small'>
      <TableHead>
        <TableRow>
          {isDesktop && <FixedTableCell width={150}>{t('dashboard:invite.title2')}</FixedTableCell>}
          <FixedTableCell width={90}>{t('dashboard:invite.role')}</FixedTableCell>
          <FixedTableCell width={100}>{t('dashboard:invite.limit')}</FixedTableCell>
          <FixedTableCell width={110}>{t('dashboard:invite.expiry')}</FixedTableCell>
          {isDesktop ? (
            <React.Fragment>
              <FixedTableCell width={70}>{t('dashboard:invite.creator')}</FixedTableCell>
              <FixedTableCell width={70} align='center'>{t('dashboard:invite.actions')}</FixedTableCell>
            </React.Fragment>
          ) : (
            <FixedTableCell width={40}></FixedTableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {displaySkeleton ? (
          Array.from({length: 2}).map((_, i) => (
            <InvitationTableRowSkeleton key={i} />
          ))
        ) : (
          unexpiredInvitations.map(invitation => {
            const { id, code, expiry, limit, roles, globalRoles, creator } = invitation;
            const regRole = customRoles.find(cr => cr.id === roles?.[0]);
            const attachedRole = regRole
              ? (
                <Chip
                variant='outlined'
                label={regRole.name}
                style={{ borderColor: regRole.color }}
                />
              ) : globalRoles && globalRoles.length ? (
                <Chip
                variant='default'
                label={wt(`common:roles.global.${globalRoles[0]}.title`)}
                color='primary'
                />
              ) : undefined;
            const link = window.location.origin + invitationCodeRoute(code);
            return (
              <InvitationTableRowWrapper
              key={id}
              invitationId={id}
              link={link}
              loading={loading}
              expiry={expiry ? (
                <Time format={TIME_FORMAT}>{expiry}</Time>
              ) : (
                t('dialog:inviteteammembers.neverexpires')
              )}
              limit={limit || t('dashboard:invite.unlimited')}
              onDeleteClicked={handleDeleteOnClick}
              attachedRole={attachedRole}
              creator={creator.alias}
              />
            );
          })
        )}
      </TableBody>
    </Table>
  );
}

export default React.memo(InvitationTable);
