import gql from 'graphql-tag';

import { MetadataEntryFragment } from '../fragments';

export const SetVideoMetadata_Mutation = gql`
    mutation SetVideoMetadataMutation(
        $input: SetVideoMetadataInput!
    ) {
        setVideoMetadata(
            input: $input
        ) {
            video {
                id
                userMetadata {
                    ...MetadataEntryFragment
                }
            }
        }
    }
    ${MetadataEntryFragment}
`;
