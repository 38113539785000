import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetTeamDirectoryRecordsByTeamId } from './dashboard-directory-selector';
import { fetchTeamDivisionsAC } from './dashboard-directory-slice';

export const useFetchTeamDivisions = createBidirectionalAutoFetcher({
  actionCreators: fetchTeamDivisionsAC,
  selectorFactory: makeGetTeamDirectoryRecordsByTeamId,
  paramsTransform: fetchId => ({teamId: fetchId}),
  pageSize: 10,
});
