import { VideoReplayContext } from '@insights-gaming/material-components';
import { useDialogState } from 'hooks/useDialogState';
import { ShortcutDialogContext } from 'material/dialogs/shortcut-dialog-content/ShortcutDialogContext';
import React, { useContext,useMemo } from 'react';
import { IVideoQuality, QualityPickerContext } from 'subcomponents/video-player-3/controls/QualityPickerContext';
import { IVideoPlayerContext, VideoPlayerContext } from 'subcomponents/video-player-3/VideoPlayerInterface';
import { Video } from 'types/pigeon';

interface AdaptVideoPlayerContextOwnProps {
  video?: Video;
  hideControls?: boolean;
  qualityLevels?: IVideoQuality[];
  qualityLevel?: string;
  setQualityLevel?: React.Dispatch<React.SetStateAction<string>>;
  flashControlIcon?: (icon: JSX.Element) => void;
  syncPlaybackPosition?: () => void;
}

export type AdaptVideoPlayerContextProps = React.PropsWithChildren<AdaptVideoPlayerContextOwnProps>;

export default function AdaptVideoPlayerContext({
  video,
  hideControls,
  children,
  qualityLevels,
  qualityLevel,
  setQualityLevel,
  flashControlIcon,
  syncPlaybackPosition,
}: AdaptVideoPlayerContextProps) {
  const {
    state,
    changeVolume,
    mute,
    unmute,
    play,
    pause,
    togglePlayState,
    seekTo,
    toggleFullscreen,
    changePlaybackRate,
  } = useContext(VideoReplayContext);
  const [ isShortcutDialogOpen, openShortcutDialog, closeShortcutDialog ] = useDialogState();
  const adaptedContext = useMemo((): IVideoPlayerContext => ({
    video,
    playing: state.playing,
    volume: state.volume,
    mute: state.muted,

    currentTime: state.progress.playedSeconds,
    duration: state.duration,

    flashControlIcon: flashControlIcon || (() => {}),
    hideControls: !!hideControls,
    setVolume: (amount: number) => changeVolume({ amount }),
    setMute: (muted: boolean) => (muted ? mute : unmute)(),
    sync: syncPlaybackPosition || (() => {}),
    play,
    pause,
    playpause: togglePlayState,
    seekTo: (amount: number) => seekTo({ type: 'seconds', amount }),
    qualityLevels: qualityLevels || [],
    qualityLevel: qualityLevel || 'auto',
    setQuality: setQualityLevel || (() => {}),
    toggleFullscreen,
    isFullscreen: state.fullscreen,
    playbackRate: state.playbackRate,
    setPlaybackRate: (rate: number) => changePlaybackRate(rate),
    isShortcutDialogOpen,
    openShortcutDialog,
    closeShortcutDialog,
  }),
  [
    video,
    state.playing,
    state.volume,
    state.muted,
    state.progress.playedSeconds,
    state.duration,
    state.fullscreen,
    state.playbackRate,
    flashControlIcon,
    hideControls,
    syncPlaybackPosition,
    play,
    pause,
    togglePlayState,
    qualityLevels,
    qualityLevel,
    setQualityLevel,
    toggleFullscreen,
    isShortcutDialogOpen,
    openShortcutDialog,
    closeShortcutDialog,
    changeVolume,
    mute,
    unmute,
    seekTo,
    changePlaybackRate,
  ]);

  return (
    <VideoPlayerContext.Provider value={adaptedContext}>
      <QualityPickerContext.Provider
      value={{
        qualityLevels: qualityLevels || [],
        qualityLevel: qualityLevel || 'auto',
        setQualityLevel: setQualityLevel || (() => {}),
      }}
      >
        <ShortcutDialogContext.Provider value={{ isShortcutDialogOpen, openShortcutDialog, closeShortcutDialog }}>
          { children }
        </ShortcutDialogContext.Provider>
      </QualityPickerContext.Provider>
    </VideoPlayerContext.Provider>
  );
}
