import { Theme } from '@insights-gaming/theme';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import FeedbackButton from 'components/status-bar/FeedbackButton';
import NotificationsButton from 'components/status-bar/NotificationsButton';
import StatusBar from 'components/status-bar/StatusBar';
import { mobilePortrait } from 'features/media-queries';
import React, { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors/getMe';
import IntercomButton from 'subcomponents/intercom-button/IntercomButton';
import { ID, TeamInterface } from 'types/pigeon';

import { TeamHelper } from '../team/team-helper';
import LiveSessionButton from './LiveSessionButton';

interface DashboardHeaderOwnProps {
  className?: string;
  team?: TeamFragment | TeamInterface;
  directoryId?: ID;
}

type DashboardHeaderProps = DashboardHeaderOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    zIndex: 0,
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: theme.spacing(0.5),
  },
  toolbarInner: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    [mobilePortrait(theme)]: {
      gridTemplateColumns: '1fr 4fr 1fr',
    },
  },
}), {name: 'DashboardHeader'});

function DashboardHeader(props: PropsWithChildren<DashboardHeaderProps>) {
  const classes = useStyles(props);
  const { children, className, directoryId, team } = props;

  const me = useSelector(getMe);

  const menuItems = useMemo(() => {
    return [
      team && directoryId && TeamHelper.isTeamFragment(team) && (
        <LiveSessionButton key='livesession' team={team} directoryId={directoryId} />
      ),
      <NotificationsButton key='notifications' />,
      <FeedbackButton key='feedback' />,
      <IntercomButton key='intercom' />,
    ];
  }, [directoryId, team]);

  return (
    <AppBar position='static' className={classNames(classes.root, className)}>
      <Toolbar
      classes={{ gutters: classes.toolbar }}
      >
        <div className={classes.toolbarInner}>
          {children}
          {me && (
            <StatusBar me={me} team={team} directoryId={directoryId} menuItems={menuItems}/>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(DashboardHeader);
