import { acceptInvitationAsyncAC, claimInvitationAsyncAC, declineInvitationAsyncAC, deleteInvitationAC, deliverInvitationsAsyncAC, fetchInvitationAsyncAC, lookupInvitationAsyncAC, revokeInvitationAsyncAC } from 'actions/invitation-actions';
import { AcceptInvitation_Mutation, ClaimInvitation_Mutation, DeclineInvitation_Mutation, DeleteInvitation_Mutation,DeliverInvitations_Mutation, RevokeInvitation_Mutation } from 'apollo/mutations';
import { GetInvitation_Query, LookupInvitation_Query } from 'apollo/queries';
import { watchAsyncMutation, watchAsyncQuery } from 'helpers/saga/effects';
import { all } from 'redux-saga/effects';

export default function* invitationSaga() {
  yield all([
    watchAsyncQuery(fetchInvitationAsyncAC, GetInvitation_Query, ['invitation']),
    watchAsyncQuery(lookupInvitationAsyncAC, LookupInvitation_Query, ['lookupInvitation']),
    watchAsyncMutation(claimInvitationAsyncAC, ClaimInvitation_Mutation, ['claimInvitation']),
    watchAsyncMutation(revokeInvitationAsyncAC, RevokeInvitation_Mutation, ['revokeInvitation']),
    watchAsyncMutation(acceptInvitationAsyncAC, AcceptInvitation_Mutation, ['acceptInvitation']),
    watchAsyncMutation(deliverInvitationsAsyncAC, DeliverInvitations_Mutation, ['deliverInvitations']),
    watchAsyncMutation(declineInvitationAsyncAC, DeclineInvitation_Mutation, ['declineInvitation']),
    watchAsyncMutation(deleteInvitationAC, DeleteInvitation_Mutation, ['deleteInvitation']),
  ]);
}
