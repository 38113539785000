import { isExistent } from 'helpers';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

export const getTeamMemberRecords = (state: RootReducer) => state.dashboardMember.teamMemberRecords;
const getTeamMemberRecordsByTeamId = (state: RootReducer, teamId: ID) =>
  state.dashboardMember.teamMemberRecords[teamId];

export const getTeamMemberDict = (state: RootReducer) => state.dashboardMember.memberDict;
export const getTeamMemberDictById = (state: RootReducer, memberId: ID) => state.dashboardMember.memberDict[memberId];

export const makeGetTeamMemberRecordsByTeamId = () => createSelector(
  [getTeamMemberRecordsByTeamId],
  (records) => records,
);

export const makeGetTeamMembersByTeamId = () => createSelector(
  [getTeamMemberRecordsByTeamId, getTeamMemberDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) {
      return [];
    }
    return ids.map(id => dict[id]).filter(isExistent);
  },
);

export const makeGetTeamMemberById = () => createSelector(
  [getTeamMemberDictById],
  (member) => member,
);
