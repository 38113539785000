import React from 'react';

import { GetMembersQuery_queryMembers_edges_user } from '../../apollo/queries/types/GetMembersQuery';
import { GetUserProfileQuery_me } from '../../apollo/queries/types/GetUserProfileQuery';
import AvatarWithLetterFallback from '../avatar-with-letter-fallback/AvatarWithLetterFallback';
import { AvatarSize } from '../undraggable-avatar/UndraggableAvatar';

export interface IUserAvatarOwnProps {
  user : GetUserProfileQuery_me | GetMembersQuery_queryMembers_edges_user;
  size?: AvatarSize;
}

export type UserAvatarProps = IUserAvatarOwnProps;

function UserAvatar({user, size}: UserAvatarProps) {
  return (
    <AvatarWithLetterFallback alias={user.alias} picture={user.picture} size={size} />
  );
}

export default React.memo(UserAvatar);
