import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createSocialMediaShareLink, SocialMedia } from '@insights-gaming/utils';
import ExternalLink from '@material-ui/core/Link';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import { FacebookCircle, RedditCircle, TwitterCircle } from 'assets/social-media';
import classNames from 'classnames';
import React from 'react';

interface SocialMediaData {
  name: SocialMedia;
  icon: React.ReactNode;
}

const socialMedias: SocialMediaData[] = [
  {
    name: 'Facebook',
    icon: <FacebookCircle />,
  },
  {
    name: 'Reddit',
    icon: <RedditCircle />,
  },
  {
    name: 'Twitter',
    icon: <TwitterCircle />,
  },
];

interface SocialMediaShareOwnProps {
  className?: string;
  link: string;
  videoTitle: string;
}

type SocialMediaShareProps = SocialMediaShareOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  link: {
    display: 'flex',
  },
  icon: {
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
}), {name: 'SocialMediaShare'});

function SocialMediaShare(props: SocialMediaShareProps) {
  const classes = useStyles(props);
  const { className, link, videoTitle } = props;
  return (
    <FlexSpacer className={classNames(classes.root, className)} flexJustifyContent='center'>
      {socialMedias.map((s) => {
        const shareLink = s.name === 'Facebook' ?
          createSocialMediaShareLink(s.name, link) :
          createSocialMediaShareLink(s.name, link, videoTitle);
        return (
          <ExternalLink
          className={classes.link}
          href={shareLink}
          target='_blank'
          rel='noopener noreferrer'
          key={s.name}
          >
            <SvgIcon className={classes.icon}>
              {s.icon}
            </SvgIcon>
          </ExternalLink>
        );
      })}
    </FlexSpacer>
  );
}

export default React.memo(SocialMediaShare);
