type TOverwatch =
|'name'
|'gamemodes.assault'
|'gamemodes.control'
|'gamemodes.escort'
|'gamemodes.hybrid'
;
const OverwatchNS = 'overwatch';
function OverwatchF(_: TOverwatch): string { return `${OverwatchNS}:${_}`; }
export {OverwatchF,OverwatchNS,TOverwatch};
