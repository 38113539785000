import { Loader } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import update, { Spec } from 'immutability-helper';
import memoizeOne from 'memoize-one';
import React from 'react';
import { Trans, WithTranslation,withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics,
  GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics_OverwatchStatistics,
  GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics_OverwatchStatistics_primaryRosterV2,
  GetOverwatchPrimaryRosterPlayerStatisticsV2QueryVariables,
} from '../../../../apollo/queries/types/GetOverwatchPrimaryRosterPlayerStatisticsV2Query';
import { NO_ROSTER_SELECTED } from '../../../../constants/strings';
import CreateCorrectionDialogContent from '../../../../containers/dialogs/CreateCorrectionDialogContent';
import { createVideoStatsCacheKey } from '../../../../helpers';
import { CommonF, CommonNS } from '../../../../locales/en/common';
import { DialogF, DialogNS } from '../../../../locales/en/dialog';
import { OwstatsF, OwstatsNS } from '../../../../locales/en/owstats';
import AlertDialogContent from '../../../../material/dialogs/alert-dialog-content/AlertDialogContent';
import Message from '../../../../material/message/Message';
import { ETeamStatsTabType, teamOverwatchStatsRoute } from '../../../../routes';
import { ID } from '../../../../types/pigeon';
import OverwatchPrimaryRosterPlayerStatisticsTable from './overwatch-primary-roster-player-statistics-table/OverwatchPrimaryRosterPlayerStatisticsTable';

export interface IOverwatchPrimaryRosterPlayerStatisticsOwnProps {
  team: TeamFragment;
  videoIds?: ID[];
  csvName: string;
}

export interface IOverwatchPrimaryRosterPlayerStatisticsMappedProps {
  primaryRosterPlayerStatisticsCache?: Dictionary<GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics>;
  primaryRosterPlayerStatisticsError?: Error;
}

export interface IOverwatchPrimaryRosterPlayerStatisticsDispatch {
  onFetchOverwatchPrimaryRosterPlayerStatistics: (
    params: GetOverwatchPrimaryRosterPlayerStatisticsV2QueryVariables,
  ) => void;
}

export type OverwatchPrimaryRosterPlayerStatisticsProps = IOverwatchPrimaryRosterPlayerStatisticsOwnProps &
  IOverwatchPrimaryRosterPlayerStatisticsMappedProps &
  WithTranslation &
  IOverwatchPrimaryRosterPlayerStatisticsDispatch;

type PrimaryRosterV2 = GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics_OverwatchStatistics_primaryRosterV2;

interface IState {
  verifyOpen?: boolean;
  submittedOpen?: boolean;
}

class OverwatchPrimaryRosterPlayerStatistics extends React.Component<
  OverwatchPrimaryRosterPlayerStatisticsProps, IState
> {
  private openVerifyNameDialog  = this.toggleDialog('verifyOpen', true);
  private closeVerifyNameDialog = this.toggleDialog('verifyOpen', false);
  private openSubmittedVerifyNameDialog  = this.toggleDialog('submittedOpen', true);
  private closeSubmittedVerifyNameDialog = this.toggleDialog('submittedOpen', false);

  private memoizedCacheKey = memoizeOne(createVideoStatsCacheKey);
  private memoizedPlayers  = memoizeOne(
    (primaryRosterV2: PrimaryRosterV2[]) => {
      return primaryRosterV2.map(roster => roster.players);
    },
  )

  public constructor(props: OverwatchPrimaryRosterPlayerStatisticsProps) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    this.fetchStatistics();
  }

  public componentDidUpdate(prevProps: OverwatchPrimaryRosterPlayerStatisticsProps) {
    const { videoIds, primaryRosterPlayerStatisticsCache, primaryRosterPlayerStatisticsError } = this.props;
    if (videoIds !== prevProps.videoIds) {
      this.fetchStatistics();
    } else if ( // cache invalidated
      (
        !primaryRosterPlayerStatisticsCache ||
        !(this.memoizedCacheKey(videoIds) in primaryRosterPlayerStatisticsCache)
      ) &&
      !primaryRosterPlayerStatisticsError
    ) {
      this.fetchStatistics();
    }
  }

  public render() {
    const {
      videoIds,
      csvName,
      primaryRosterPlayerStatisticsCache,
      primaryRosterPlayerStatisticsError,
      t,
    } = this.props;
    if (primaryRosterPlayerStatisticsError) {
      return this.renderError(primaryRosterPlayerStatisticsError);
    }
    if (!primaryRosterPlayerStatisticsCache) {
      return this.renderLoader();
    }
    const cacheKey = this.memoizedCacheKey(videoIds);
    const statistics = primaryRosterPlayerStatisticsCache[cacheKey];
    switch (statistics) {
      case null     : return this.renderNoStatistics();
      case undefined: return this.renderLoader();
    }
    if (statistics.__typename !== 'OverwatchStatistics') { return this.renderNoStatistics(); }
    return (
      <React.Fragment>
        {this.renderCreateCorrectionDialog(statistics, videoIds)}
        {this.renderSubmittedDialog()}
        <OverwatchPrimaryRosterPlayerStatisticsTable
        key={cacheKey}
        csvName={csvName}
        statistics={statistics}
        VerifyNameButton={(
          <Button
          onClick={this.openVerifyNameDialog}
          type='button'
          variant='contained'
          >
            {t(DialogF('editnames.title'))}
          </Button>
        )}
        />
      </React.Fragment>
    );
  }

  private renderCreateCorrectionDialog = (
    statistics: GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics_OverwatchStatistics,
    videoIds?: ID[],
  ) => {
    const { verifyOpen } = this.state;
    const { team } = this.props;
    const videos = videoIds || [];
    const players = this.memoizedPlayers(statistics.primaryRosterV2);
    return (
      <Dialog open={!!verifyOpen} onClose={this.closeVerifyNameDialog} maxWidth='sm' fullWidth={true}>
        <CreateCorrectionDialogContent
        stats={players}
        team={team}
        videoIds={videos}
        closeVerifyNameDialog={this.closeVerifyNameDialog}
        openSubmittedVerifyNameDialog={this.openSubmittedVerifyNameDialog}
        />
      </Dialog>
    );
  }

  private renderSubmittedDialog = () => {
    const { submittedOpen } = this.state;
    const { t } = this.props;
    return (
      <Dialog open={!!submittedOpen} onClose={this.closeSubmittedVerifyNameDialog}>
        <AlertDialogContent
        description={t(DialogF('editnames.verificationsent'))}
        confirm={{
          text: t(CommonF('ok')),
          action: this.closeSubmittedVerifyNameDialog,
        }}
        />
      </Dialog>
    );
  }

  private renderError = (error: Error) => {
    const { team } = this.props;
    // No Roster Selected
    if (error.message.includes(NO_ROSTER_SELECTED) || error.message.includes('internal system error')) {
      return (
        <div>
          <Trans ns={OwstatsNS} i18nKey={OwstatsF('norosterselected')}>
            Unexpected error, you may need to select a roster
            <Link to={teamOverwatchStatsRoute(team.id, ETeamStatsTabType.SELECT_ROSTER)}>here</Link>.
          </Trans>
        </div>
      );
    }

    return (
      <Message message={error.message} variant='error' />
    );
  }

  private renderNoStatistics = () => {
    return (
      <Message message='no statistics' />
    );
  }

  private renderLoader = () => {
    const { t } = this.props;
    return (
      <Loader key='statsloading'>
        {t(OwstatsF('aggregatingstats'))}
      </Loader>
    );
  }

  private toggleDialog(dialog: keyof IState, isOpen?: boolean): VoidFunction {
    return () => {
      const spec: Spec<IState, never> = {$toggle: []};
      switch (isOpen) {
        case true   :
        case false  : spec[dialog] = {$set: isOpen};
             default: spec.$toggle = [dialog];
      }
      this.setState(state => update(state, spec));
    };
  }

  private fetchStatistics = () => {
    const { team, videoIds, onFetchOverwatchPrimaryRosterPlayerStatistics } = this.props;
    onFetchOverwatchPrimaryRosterPlayerStatistics({
      teamId: team.id,
      videoIds,
    });
  }
}

export default withTranslation([CommonNS, DialogNS, OwstatsNS])(OverwatchPrimaryRosterPlayerStatistics);
