import { Dictionary } from '@reduxjs/toolkit';
import addAsyncCases from 'helpers/addAsyncCases';
import { createSlice } from 'helpers/createSlice';
import groupBy from 'lodash/groupBy';
import { LocalTusUpload } from 'types';
import { ID } from 'types/pigeon';
import actionCreatorFactory, { Action, Success } from 'typescript-fsa';

const name = 'resumable-uploads';

const actionCreator = actionCreatorFactory(name);

export const saveLocalTusUploadsToLocalStorageAC = actionCreator('SAVE_LOCAL_TUS_UPLOADS_TO_LOCAL_STORAGE');

export const initLocalTusUploadsAC = actionCreator.async<void, LocalTusUpload[], Error>('INITIALIZE_LOCAL_TUS_UPLOAD');

export interface ITryResumeTusUpload {
  file: File;
  upload: LocalTusUpload;
}

export const tryResumeTusUploadAC = actionCreator.async<ITryResumeTusUpload, boolean, Error>('TRY_RESUME_TUS_UPLOAD');

export interface PendingUpload {
  id: ID;
  current: number;
  total: number;
}

interface ResumableUploadsState {
  pending: Dictionary<PendingUpload>;
  resumable?: Dictionary<LocalTusUpload[]>
}

const initialState: ResumableUploadsState = {
  pending: {},
};

export const {
  actions: {
    addLocalTusUploadAC,
    updateLocalTusUploadAC,
    removeLocalTusUploadAC,
    addPendingUploadAC,
    updatePendingUploadAC,
    removePendingUploadAC,
  },
  reducer: resumableUploadsReducer,
} = createSlice({
  name,
  initialState,
  reducers: {
    addLocalTusUploadAC(state, { payload }: Action<LocalTusUpload & { file: File }>) {
      if (!state.resumable) {
        state.resumable = {};
      }

      (state.resumable[payload.teamId] = state.resumable[payload.teamId] || []).push(payload);
    },
    updateLocalTusUploadAC(state, { payload }: Action<LocalTusUpload>) {
      const uploads = state.resumable?.[payload.teamId];
      if (!uploads) {
        return;
      }

      const index = uploads.findIndex((upload) => upload.video.id === payload.video.id);
      if (index < 0) {
        return;
      }

      uploads.splice(index, 1, payload);
    },
    removeLocalTusUploadAC(state, { payload: { teamId, videoId } }: Action<{ teamId?: ID, videoId: ID }>) {
      if (!state.resumable) {
        return;
      }

      if (teamId) {
        const uploads = state.resumable[teamId];
        if (!uploads) {
          return;
        }

        const index = uploads.findIndex((upload) => upload.video.id === videoId);
        if (index < 0) {
          return;
        }

        uploads.splice(index, 1);
      } else {
        for (const uploads of Object.values(state.resumable)) {
          if (!uploads) {
            continue;
          }

          const index = uploads.findIndex((upload) => upload.video.id === videoId);
          if (index < 0) {
            continue;
          }

          uploads.splice(index, 1);
        }
      }
    },
    addPendingUploadAC(state, { payload: { id, total } }: Action<{ id: ID, total: number }>) {
      state.pending[id] = { id, total, current: 0 };
    },
    updatePendingUploadAC(state, { payload: { id, current } }: Action<{ id: ID, current: number }>) {
      const upload = state.pending[id];
      if (!upload) {
        return;
      }

      upload.current = current;
    },
    removePendingUploadAC(state, { payload: { id } }: Action<{ id: ID }>) {
      delete state.pending[id];
    },
  },
  extraReducers(builder) {
    addAsyncCases(builder, initLocalTusUploadsAC, {
      done: (state, { payload: { result } }: Action<Success<void, LocalTusUpload[]>>) => {
        if (state.resumable) {
          // don't overwrite state if it's already initialized
          return;
        }

        return {
          pending: {},
          resumable: groupBy(result, ({ teamId }) => teamId),
        };
      },
    });
  },
});
