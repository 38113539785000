import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import classNames from 'classnames';
import { makeGetUnreadVideoUploadedNotificationByVideoId } from 'components/settings/notifications/notifications-selector';
import DetailedVideoCard from 'components/video-card/DetailedVideoCard';
import { useVideoThumbnail } from 'components/video-card/useVideoThumbnail';
import { useNavigate } from 'hooks/useNavigate';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { videoRoute } from 'routes';

import { searchTrackingAC } from './search-slice';

interface SearchVideoResultOwnProps {
  className?: string;
  video: VideoFragment;
}

type SearchVideoResultProps = SearchVideoResultOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'SearchVideoResult'});

function SearchVideoResult(props: SearchVideoResultProps) {
  const classes = useStyles(props);
  const { className, video } = props;

  const dispatch = useDispatch();
  const onNavigate = useNavigate();

  const uploadedVideoNotification = useCreateSelector(
    makeGetUnreadVideoUploadedNotificationByVideoId,
    {videoId: video.id},
  );

  const redirect = useCallback(() => {
    dispatch(searchTrackingAC(null, {label: 'search-video-result'}));
    onNavigate(videoRoute(video.id));
  }, [dispatch, onNavigate, video.id]);

  const thumbnailUrl = useVideoThumbnail(video, 500);

  return (
    <DetailedVideoCard
    className={classNames(classes.root, className)}
    name={video.name}
    disabled={false}
    thumbnail={thumbnailUrl}
    onClick={redirect}
    video={video}
    newVideo={!!uploadedVideoNotification}
    >
      <ReactResizeDetector handleWidth={true} handleHeight={true} skipOnMount={false} />
    </DetailedVideoCard>
  );
}

export default React.memo(SearchVideoResult);
