const workerPath = '/sw.js';

let registered: boolean | Promise<boolean> = false;

export default async function register(): Promise<boolean> {
    let resolveRegistered: ((registered: boolean) => void) | undefined;
    if (await registered) {
        registered = new Promise((resolve) => {
            resolveRegistered = resolve;
        });
    }

    if (!('serviceWorker' in navigator)) {
        return false;
    }

    let reg = await navigator.serviceWorker.getRegistration(workerPath);
    if (reg) {
        reg.update();
    } else {
        reg = await navigator.serviceWorker.register(workerPath, { scope: '/' });
    }

    let serviceWorker: ServiceWorker;
    if (reg.installing) {
        serviceWorker = reg.installing;
    } else if (reg.waiting) {
        serviceWorker = reg.waiting;
    } else if (reg.active) {
        serviceWorker = reg.active;
    } else {
        throw new Error('failed to find service worker after registration');
    }

    if (serviceWorker.state !== 'activated') {
        await new Promise<void>((resolve) => {
            const listener = (e: Event & { target: ServiceWorker }): void => {
                if (serviceWorker.state === 'activated') {
                    resolve();
                    serviceWorker.removeEventListener('statechange', listener);
                }
            };

            serviceWorker.addEventListener('statechange', listener);
        });
    }

    resolveRegistered?.(true);

    return registered = true;
}
