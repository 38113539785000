import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics_OverwatchStatistics } from 'apollo/queries/types/GetOverwatchPrimaryRosterPlayerStatisticsV2Query';
import FileSaver from 'file-saver';
import { CSV_LINE_BREAK, defaultIconOptions, isTruthy, overwatchHeroIconPath } from 'helpers';
import { normalizer } from 'helpers/math';
import update from 'immutability-helper';
import json2csv, { parse } from 'json2csv';
import { OwstatsF, OwstatsNS } from 'locales/en/owstats';
import { ICData, IColumnData, IExpandedContentOptions, mapColumn, VirtualizedTable } from 'material/virtualized-table/VirtualizedTable';
import memoizeOne from 'memoize-one';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import sanitize from 'sanitize-filename';
import { INormalized, ITotalled } from 'types';
import { PrimaryRosterV2, PrimaryRosterV2_Players, PrimaryRosterV2_Players_Heroes } from 'types/pigeon/statistics';
import { CsvOverallOption } from 'types/stat-csv-types';

type Processed<T> = INormalized<T> & ITotalled<T>;
type ProcessedData = Processed<PrimaryRosterV2>;
type RowData = PrimaryRosterV2_Players;
type ChildRowData = PrimaryRosterV2_Players_Heroes & {heroIcon: string};
type Column = ICData<RowData>;
type ChildColumn = ICData<ChildRowData>;

const commonColumns: Array<ICData<RowData | ChildRowData>> = [
  {
    tKey    : OwstatsF('playerstats.time'),
    dataKey : 'time',
    numeric : true,
    duration: true,
  },
  {
    tKey     : OwstatsF('killspertenabbr'),
    tDetailedKey : OwstatsF('killsperten'),
    dataKey  : 'kills',
    numeric  : true,
    precision: 2,
  },
  {
    tKey     : OwstatsF('deathspertenabbr'),
    tDetailedKey : OwstatsF('deathsperten'),
    dataKey  : 'deaths',
    numeric  : true,
    precision: 2,
  },
  {
    tKey     : OwstatsF('ultspertenabbr'),
    tDetailedKey : OwstatsF('ultsperten'),
    dataKey  : 'ults',
    numeric  : true,
    precision: 2,
  },
  {
    tKey     : OwstatsF('assistspertenabbr'),
    tDetailedKey : OwstatsF('assistsperten'),
    dataKey  : 'assists',
    numeric  : true,
    precision: 2,
  },
  {
    tKey     : OwstatsF('playerstats.firstkillsabbr'),
    tDetailedKey : OwstatsF('playerstats.firstKills'),
    dataKey  : 'firstKills',
    numeric  : true,
    displayAsPercentOfTotal: true,
  },
  {
    tKey     : OwstatsF('playerstats.firstdeathsabbr'),
    tDetailedKey : OwstatsF('playerstats.firstDeaths'),
    dataKey  : 'firstDeaths',
    numeric  : true,
    displayAsPercentOfTotal: true,
  },
  {
    tKey     : OwstatsF('playerstats.firstultsabbr'),
    tDetailedKey : OwstatsF('playerstats.firstUlts'),
    dataKey  : 'firstUlts',
    numeric  : true,
    displayAsPercentOfTotal: true,
  },
];

const columns: Column[] = [
  {
    tKey   : OwstatsF('playerstats.playerName'),
    dataKey: 'playerName',
    flexGrow: 1,
  },
];

const childColumns: ChildColumn[] = [
  {
    tKey: OwstatsF('playerstats.heroName'),
    dataKey: 'heroIcon',
    flexGrow: 1,
    avatar: true,
  },
];

export interface IOverwatchPrimaryRosterPlayerStatisticsTableOwnProps {
  VerifyNameButton: React.ReactElement;
  statistics: GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics_OverwatchStatistics;
  csvName   : string;
}

export type OverwatchPrimaryRosterPlayerStatisticsTableProps = IOverwatchPrimaryRosterPlayerStatisticsTableOwnProps &
  WithStyles<typeof styles> &
  WithTranslation;

const styles = (theme: Theme) => createStyles({
  statActionButtons: {
    marginBottom: theme.spacing(1),
  },
  statsTables: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
});

const normalizePlayerForTime =
(time: number) =>
(player: PrimaryRosterV2_Players) => {
  const n = normalizer(time, player.time);
  return update(player, {
    kills  : n,
    deaths : n,
    ults   : n,
    assists: n,
    heroes: hh => hh.map(h => {
      const nn = normalizer(time, h.time);
      return update(h, {
        kills  : nn,
        deaths : nn,
        ults   : nn,
        assists: nn,
      });
    }),
  });
};

type SummedStats = Pick<PrimaryRosterV2_Players,
  | 'kills'
  | 'deaths'
  | 'ults'
  | 'firstKills'
  | 'firstDeaths'
  | 'firstUlts'
>;

const normalizeRosterPlayerStats = (
  teams: PrimaryRosterV2[],
  time: number = 600,
): ProcessedData[] => {
  const n = normalizePlayerForTime(time);
  const init: SummedStats = {
    kills      : 0,
    deaths     : 0,
    ults       : 0,
    firstKills : 0,
    firstDeaths: 0,
    firstUlts  : 0,
  };
  return teams.map(team => ({
    normalized: update(team, {players: players => players.map(n)}),
    totals: team.players.reduce(
      (
        p: SummedStats,
        c: PrimaryRosterV2_Players,
      ) => {
        Object.keys(p).forEach(k => p[k] += c[k]);
        return p;
      }, init,
    ),
  }));
};

interface IState {
  columns: Column[];
  childColumns: ChildColumn[];
}

class OverwatchPrimaryRosterPlayerStatisticsTable extends React.Component<
  OverwatchPrimaryRosterPlayerStatisticsTableProps,
  IState
> {
  private memoizedNormalizedRosterPlayerStats = memoizeOne(normalizeRosterPlayerStats);
  private memoizedColumnData = memoizeOne((cc: Column[], language: string): Array<IColumnData<RowData>> => {
    return cc.map(mapColumn(this.props.t, 200));
  });
  private memoizedChildColumnData = memoizeOne(
    (cc: ChildColumn[], language: string): Array<IColumnData<ChildRowData>> => {
      return cc.map(mapColumn(this.props.t, 200));
    },
  );
  private memoizedCsvFields = memoizeOne(
    (language: string) => {
      const { t } = this.props;
      const columnMap: Map<string, ICData<any>> = new Map();
      let allColumns: Array<ICData<any>> = commonColumns;
      allColumns = allColumns.concat(columns);
      allColumns = allColumns.concat(childColumns);
      allColumns.forEach(c => {
        columnMap.set(c.dataKey, c);
      });
      const dataKeys: Array<keyof RowData | keyof ChildRowData> = [
        'playerName',
        'heroIcon',
        'time',
        'kills',
        'deaths',
        'ults',
        'assists',
        'firstKills',
        'firstDeaths',
        'firstUlts',
      ];
      const fields: Array<json2csv.FieldInfo<RowData>> = dataKeys.map(k => {
        const v = columnMap.get(k);
        if (!v) {
          return undefined;
        }
        const { dataKey, tKey, tOptions } = v;
        return {
          label: t(tKey, tOptions),
          value: dataKey === 'heroIcon' ? 'name' : dataKey,
        };
      }).filter(isTruthy);
      const heroFields: Array<json2csv.FieldInfo<RowData>> = dataKeys.map(k => {
        const v = columnMap.get(k);
        if (!v) {
          return undefined;
        }
        const { dataKey, tKey, tOptions } = v;
        return {
          label: t(tKey, tOptions),
          value: dataKey === 'playerName'
            ? 'playerName'
            : 'heroes.' + (dataKey === 'heroIcon' ? 'name' : dataKey),
        };
      }).filter(isTruthy);
      return {
        fields,
        heroFields,
      };
    },
  )

  constructor(props: OverwatchPrimaryRosterPlayerStatisticsTableProps) {
    super(props);
    this.state = {
      columns: columns.concat(commonColumns),
      childColumns: childColumns.concat(commonColumns),
    };
  }

  public render() {
    const { statistics, VerifyNameButton, t, classes } = this.props;
    return (
      <React.Fragment>
        <FlexSpacer className={classes.statActionButtons}>
          {VerifyNameButton}
          <Button onClick={this.exportCsv}>
            {t(OwstatsF('exportcsv'))}
          </Button>
        </FlexSpacer>
        <div className={classes.statsTables}>
          {this.memoizedNormalizedRosterPlayerStats(statistics.primaryRosterV2).map(this.renderRosterTable)}
        </div>
      </React.Fragment>
    );
  }

  private renderRosterTable = (roster: ProcessedData, i: number) => {
    return (
      <VirtualizedTable<RowData>
      key={i}
      variant='outer'
      data={roster.normalized.players}
      totals={roster.totals}
      columns={this.memoizedColumnData(this.state.columns, this.props.i18n.language)}
      getExpandedRowHeight={this.getRowHeight}
      renderExpandedContent={this.renderExpandedContent}
      />
    );
  }

  private getRowHeight = (row: RowData) => {
    return (row.heroes.length + 1) * 48;
  }

  private renderExpandedContent = (t: RowData, {sort}: IExpandedContentOptions<Partial<RowData> | ChildRowData>) => {
    return (
      <VirtualizedTable<ChildRowData>
      data={t.heroes.map(h => ({...h, heroIcon: overwatchHeroIconPath(h.name, defaultIconOptions)}))}
      columns={this.memoizedChildColumnData(this.state.childColumns, this.props.i18n.language)}
      totals={t as any}
      headerHeight={0}
      sortInfo={sort}
      />
    );
  }

  private buildRosterCsv = (roster: ProcessedData, options: {
    fields: Array<json2csv.FieldInfo<RowData>>,
    heroFields: Array<json2csv.FieldInfo<RowData>>,
    includeOverall?: CsvOverallOption,
  }): string => {
    const { fields, heroFields, includeOverall } = options;

    let csv = '';

    csv += roster.normalized.players.map(player => {
      let playerCsv: string = '';
      let totalRow = '';
      if (includeOverall) {
        totalRow = parse(player, {
          fields,
          header: false,
        }) + CSV_LINE_BREAK;
      }
      playerCsv += parse(player, {
        fields: heroFields,
        unwind: ['heroes'].concat(heroFields.map(f => f.value as string)),
        header: false,
      }) + CSV_LINE_BREAK;
      switch (includeOverall) {
        case 'before':
          playerCsv = totalRow + playerCsv;
          break;
        case 'after':
          playerCsv = playerCsv + totalRow;
          break;
      }
      return playerCsv;
    }).join('');

    return csv;
  }

  private exportCsv = () => {
    const { statistics, csvName } = this.props;

    const header = true;
    const includeOverall: CsvOverallOption  = 'before';

    const rosters = this.memoizedNormalizedRosterPlayerStats(statistics.primaryRosterV2);
    const { fields, heroFields } = this.memoizedCsvFields(this.props.i18n.language);

    const csvs = [
      header && (parse([], {fields}) + CSV_LINE_BREAK),
      ...rosters.map(r => this.buildRosterCsv(r, {fields, heroFields, includeOverall})),
    ].filter(isTruthy);
    const blob = new Blob(csvs, {type: 'text/csv'});
    const fileName = csvName.slice(0, 48) + '_player-stats.csv';
    FileSaver.saveAs(blob, sanitize(fileName));
  }
}

export default withTranslation(OwstatsNS)(
  withStyles(styles)(OverwatchPrimaryRosterPlayerStatisticsTable),
);
