import { useCreateSelector } from 'hooks/useCreateSelector';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import { makeGetSharedDivisionByDirectoryId } from '../directory/dashboard-directory-selector';
import { fetchSharedDivisionsAC } from '../directory/dashboard-directory-slice';
import { fetchTeamEdgeByIdAC } from '../team/dashboard-team-slice';
import { makeGetTeamEdgeById } from './access-control-selector';
import {
  AccessControlProvider,
  mapSharedDivisionToAccessControlValue,
  mapTeamEdgeToAccessControlValue,
} from './AccessControlContext';

interface AccessControlInjectorOwnProps {
  teamId?: ID;
  children: React.ReactElement | null;
  divisionId?: ID;
}

type AccessControlInjectorProps = PropsWithChildren<AccessControlInjectorOwnProps>;

function AccessControlInjector(props: AccessControlInjectorProps) {
  const { teamId, children, divisionId } = props;
  const dispatch = useDispatch();

  const [teamEdge, fetched] = useCreateSelector(makeGetTeamEdgeById, teamId);
  const sharedDivision = useCreateSelector(makeGetSharedDivisionByDirectoryId, divisionId);

  useEffect(() => {
    if (teamId && !teamEdge && !fetched) {
      dispatch(fetchTeamEdgeByIdAC.started({ id: teamId }));
    }
  }, [dispatch, fetched, teamEdge, teamId]);

  useEffect(() => {
    if (!sharedDivision) {
      dispatch(fetchSharedDivisionsAC.forward.started({}));
    }
  }, [dispatch, sharedDivision]);

  const accessControlValue = useMemo(() => {
    return teamEdge
      ? mapTeamEdgeToAccessControlValue(teamEdge)
      : sharedDivision && sharedDivision.privileges
      ? mapSharedDivisionToAccessControlValue(sharedDivision.privileges)
      : undefined;
  }, [teamEdge, sharedDivision]);

  return accessControlValue ? (
    <AccessControlProvider value={accessControlValue}>
      {children}
    </AccessControlProvider>
  ) : (
    children
  );
}

export default AccessControlInjector;
