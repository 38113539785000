import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

interface VideoCommentLayoutOwnProps {
  className?: string;
  replyArea?: React.ReactNode;
  cardActionArea?: React.ReactNode;
  timestampArea?: React.ReactNode;
  actions?: React.ReactNode;
}

type VideoCommentLayoutProps = VideoCommentLayoutOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  commentCard: {
    backgroundColor: theme.palette.background.container,
    overflow: 'visible',
  },
  timestampRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    height: theme.spacing(3),
  },
  videoBody: {
    flex: 1,
    position: 'relative',
  },
}), {name: 'VideoCommentLayout'});

function VideoCommentLayout(props: VideoCommentLayoutProps) {
  const classes = useStyles(props);
  const { className, timestampArea, replyArea, cardActionArea, actions } = props;
  return (
    <div className={classNames(classes.root, className)}>
      <Card
      className={classes.commentCard}
      elevation={0}
      >
        <FlexSpacer className={classes.timestampRow}>
          {timestampArea}
          <Box my={1} width='100%'>
            <Divider variant='fullWidth' />
          </Box>
        </FlexSpacer>
        <div className={classes.videoBody}>
          {actions}
          {cardActionArea}
          {replyArea}
        </div>
      </Card>
    </div>
  );
}

export default React.memo(VideoCommentLayout);
