import { Theme } from '@insights-gaming/theme';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { GetMembersQuery_queryMembers_edges } from 'apollo/queries/types/GetMembersQuery';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { MemberMenuButton } from 'hoc/withMemberMenu';
import UserAvatar from 'material/user-avatar/UserAvatar';
import React from 'react';

import MemberListItem from './MemberListItem';
import MemberListItemSkeleton from './MemberListItemSkeleton';

interface MemberListOwnProps {
  className?: string;
  members: GetMembersQuery_queryMembers_edges[];
  team: TeamFragment;
  roles: RoleInterfaceFragment[];
  displaySkeleton?: boolean;
}

type MemberListProps = MemberListOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'MemberList'});

function MemberList(props: MemberListProps) {
  const classes = useStyles(props);
  const { className, members, team, roles, displaySkeleton } = props;
  const { canOpenMemberMenu } = useAccessControl();

  return (
    <List disablePadding={true} className={classNames(classes.root, className)}>
      {displaySkeleton ? (
        Array.from({length: 2}).map((_, i) => (
          <MemberListItemSkeleton key={i} />
        ))
      ) : (
        members.map(member => {
          const displayContext = canOpenMemberMenu(member) && member.user.id !== team.owner.id;
          return (
            <MemberListItem
            key={member.id}
            name={member.nickname || member.user.alias}
            avatar={(
              <ListItemAvatar>
                <UserAvatar user={member.user} size='sm' />
              </ListItemAvatar>
            )}
            secondaryAction={displayContext && (
              <MemberMenuButton team={team} member={member} roles={roles} />
            )}
            />
          );
        })
      )}
    </List>
  );
}

export default React.memo(MemberList);
