import gql from 'graphql-tag';

export const RevokeToken_Mutation = gql`
    mutation RevokeTokenMutation(
        $input: RevokeTokenInput!
    ) {
        revokeToken(
            input: $input
        ) {
            revoked
        }
    }
`;
