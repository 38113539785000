import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { useAutoControlled } from 'react-auto-controlled';
import { useTranslation } from 'react-i18next';

import { CommonF, CommonNS, TCommon } from '../../locales/en/common';
import { AnalysisType } from '../../types/graphql';
import { ID } from '../../types/pigeon';

export interface IAnalysisTypeSelectorOwnProps {
  className?: string;
  videoId: ID;
  value?: AnalysisType;
  defaultValue?: AnalysisType;
  options: AnalysisType[];
  onChange?: (videoId: ID, type: AnalysisType) => void;
}

type Props = IAnalysisTypeSelectorOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  toggleButton: {
    whiteSpace: 'nowrap',
  },
}), {name: 'AnalysisTypeSelector'});

function AnalysisTypeSelector(props: Props) {
  const { className, onChange, options, videoId } = props;
  const classes = useStyles(props);
  const [ value,, trySetValue, getDerivedValueFromProps ] = useAutoControlled(undefined, {
    prop: props.value,
    defaultProp: props.defaultValue,
  });
  getDerivedValueFromProps();
  const { t } = useTranslation(CommonNS);

  const handleValueOnChange = useCallback((e, newValue: AnalysisType) => {
    if (!newValue) { return; }
    trySetValue(newValue);
    onChange?.(videoId, newValue);
  }, [onChange, trySetValue, videoId]);

  return (
    <ToggleButtonGroup className={className} size='small' onChange={handleValueOnChange} exclusive={true} value={value}>
      {options.map((option) => (
        <ToggleButton key={option} className={classes.toggleButton} value={option}>
          {t(CommonF(asdf(option)))}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default React.memo(AnalysisTypeSelector);

function asdf(type: AnalysisType): TCommon {
  switch (type) {
    case AnalysisType.OVERWATCH   : return 'overwatch';
    case AnalysisType.SSB_ULTIMATE: return 'ssbu';
  }
}
