import { FlexSpacer } from '@insights-gaming/material-components';
import { mobilePortrait, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

interface SearchCommentResultLayoutOwnProps {
  className?: string;
  children?: React.ReactNode;
  text?: React.ReactNode;
  filteredCommentLabelTags?: React.ReactNode;
  onClick?: VoidFunction;
}

type SearchCommentResultLayoutProps = SearchCommentResultLayoutOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [mobilePortrait(theme)]: {
      padding: theme.spacing(2, 1),
    },
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.container,
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1, 0),
    '&:hover': {
      backgroundColor: theme.palette.background.note,
      cursor: 'pointer',
    },
    overflowWrap: 'anywhere',
  },
  header: {
    width: '100%',
  },
}), {name: 'SearchCommentResultLayout'});

function SearchCommentResultLayout(props: SearchCommentResultLayoutProps) {
  const classes = useStyles(props);
  const { className, children, onClick, filteredCommentLabelTags, text } = props;
  return (
    <div className={classNames(classes.root, className)} onClick={onClick}>
      <FlexSpacer flexAlignItems='center' flexJustifyContent='space-between' className={classes.header}>
        <FlexSpacer flexAlignItems='center'>
          {children}
        </FlexSpacer>
        {filteredCommentLabelTags}
      </FlexSpacer>
      {text}
    </div>
  );
}

export default React.memo(SearchCommentResultLayout);
