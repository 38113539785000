import { COMPLETE, FAILURE, PayloadStatus, PENDING } from '../';
import { ID, PageInfo } from '../pigeon';

export type PagedID = PagedSet<ID>;

export class PagedSet<T> /* implements Set<T> */ {
  public status  : PayloadStatus;
  public reasonForFailure? : Error;

  public _innerSet: Set<T>;

  private _pageInfo: PageInfo;

  constructor(entries?: readonly T[] | null) {
    this._innerSet = new Set(entries);
    this._pageInfo = { end: undefined, more: true, start: undefined };
  }

  public clone(): PagedSet<T> {
    const c = new PagedSet<T>();
    c._innerSet = new Set(this._innerSet);
    c.status = this.status;
    c.reasonForFailure = this.reasonForFailure;
    c._pageInfo = {...this._pageInfo};
    return c;
  }

  public entries(): IterableIterator<[T, T]> {
    return this._innerSet.entries();
  }

  public keys(): IterableIterator<T> {
    return this._innerSet.keys();
  }

  public values(): IterableIterator<T> {
    return this._innerSet.values();
  }

  public forEach(callbackfn: (value: T, value2: T, set: Set<T>) => void, thisArgs?: any): void {
    this._innerSet.forEach(callbackfn);
  }

  public get isComplete(): boolean { return this.status === COMPLETE; }
  public get isPending() : boolean { return this.status === PENDING; }
  public get isFailed()  : boolean { return this.status === FAILURE; }

  public get size()   : number { return this._innerSet.size; }
  public get hasAny() : boolean { return this.size > 0; }
  public get hasMore(): boolean { return !!(this.pageInfo && this.pageInfo.more); }
  public get cursor() : string | undefined { return this.pageInfo ? this.pageInfo.end : undefined; }
  public get canFetch(): boolean { return this.hasMore && !this.isPending; }
  public get shouldAutoFetch(): boolean { return this.canFetch && !this.isFailed; }

  public get pageInfo(): PageInfo { return this._pageInfo; }
  public set pageInfo(pi: PageInfo) { this._pageInfo = { ...pi }; }

  public clear(): void {
    this._innerSet.clear();
    this._pageInfo = {end: undefined, more: true, start: undefined};
  }

  public add(value: T): this {
    this._innerSet.add(value);
    return this;
  }

  public delete(value: T): boolean {
    return this._innerSet.delete(value);
  }

  public has(value: T): boolean {
    return this._innerSet.has(value);
  }

  // public [Symbol.iterator](): IterableIterator<T> {
  //   return this._innerSet[Symbol.iterator]();
  // }

  public get [Symbol.toStringTag](): string {
    return 'PagedSet';
  }
}
