import { createRemFromPx, Theme } from '@insights-gaming/theme';
import InputAdornment from '@material-ui/core/InputAdornment';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useEffect,useState } from 'react';

interface ContributeCustomTextFieldOwnProps {
  className?: string;
  error: boolean;
  cleared?: boolean;
  setCustomValue: (value: number | undefined) => void;
  onFocus?: VoidFunction;
  min?: number;
  max?: number;
}

type ContributeCustomTextFieldProps = ContributeCustomTextFieldOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  input: {
    height: createRemFromPx(38),
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input::placeholder': {
      textTransform: 'uppercase',
    },
  },
}), {name: 'ContributeCustomTextField'});

const customRegex = /^[1-9]\d*(?:\.\d{1,2})?$/;

function ContributeCustomTextField(props: ContributeCustomTextFieldProps) {
  const classes = useStyles(props);
  const { className, cleared, error, min, max, onFocus, setCustomValue } = props;
  const { t } = useStrictTranslation(['dashboard']);

  const [raw, setRaw] = useState<string>('');

  useEffect(() => {
    if (cleared) {
      setRaw('');
    }
  }, [cleared]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;
    const valid = customRegex.test(value);
    if (!value || valid) {
      setRaw(value);
      if (!valid) {
        setCustomValue(undefined);
      } else {
        const parsed = parseFloat(value);
        if (
          (min !== undefined && parsed < min) ||
          (max !== undefined && parsed > max)
        ) {
          setCustomValue(undefined);
        } else {
          setCustomValue(parsed);
        }
      }
    }
  }, [max, min, setCustomValue]);

  return (
    <TextField
    value={raw}
    placeholder={t('dashboard:contribute.custom')}
    variant='outlined'
    className={classNames(classes.root, className)}
    InputProps={{
      className: classes.input,
      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
    }}
    onChange={onChange}
    onFocus={onFocus}
    error={error}
    type='number'
    />
  );
}

export default React.memo(ContributeCustomTextField);
