import { watchAsync } from '@insights-gaming/saga-utils';
import { END, eventChannel } from 'redux-saga';
import { all, call, cancelled,put, select, spawn, take } from 'redux-saga/effects';
import { audioRecordingStateSelector } from 'selectors';

import { addRecordingData, discardRecording, initCurrentRecording, saveRecording, startAudioRecordingAC, stopAudioRecordingAC } from './audio-comment-slice';

const noValidAudioInputErr = 'No valid audio input detected';

async function initMediaStream(): Promise<MediaStream | undefined> {
  try {
    const state = (await navigator.permissions.query({ name: 'microphone' })).state;
    if (state === 'denied') {
      return;
    }
  } catch(error) {
  } finally {
    const devicesInfo = await navigator.mediaDevices.enumerateDevices();
    const validAudioInput = devicesInfo.filter(({kind}) => kind === 'audioinput');
    if (validAudioInput.length === 0) {
      throw new Error(noValidAudioInputErr);
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    if (!stream.id) {
      return;
    }

    return stream;
  }
}

function recordingDataChannel(recorder: MediaRecorder) {
  return eventChannel<Blob>((emit) => {
    recorder.ondataavailable = (e) => emit(e.data);
    recorder.onstop = () => emit(END);
    return () => {
      recorder.ondataavailable = null;
      recorder.onstop = null;
    };
  });
}

function* watchRecordingData(recorder: MediaRecorder) {
  const ch = recordingDataChannel(recorder);
  try {
    while (true) {
      const blob: Blob = yield take(ch);
      yield put(addRecordingData(blob));
    }
  } finally {
    if ((yield cancelled()) as boolean) {
      ch.close();
      recorder.stop();
    } else {
      yield put(saveRecording());
    }
  }
}

function* watchStopRecording() {
  yield watchAsync(stopAudioRecordingAC, function* (discard?: boolean) {
    const state: ReturnType<typeof audioRecordingStateSelector> = yield select(audioRecordingStateSelector);
    if (state.recording?.state !== 'recording') {
      return;
    }

    state.recording.recorder.stop();

    yield take(saveRecording);
    if (discard) {
      yield put(discardRecording());
    }
  });
}

function* watchStartRecording() {
  yield watchAsync(startAudioRecordingAC, function* () {
    const state: ReturnType<typeof audioRecordingStateSelector> = yield select(audioRecordingStateSelector);
    if (state.recording?.state === 'recording') {
      // prevent starting a recording while already recording
      return;
    }

    const mediaStream: AsyncReturnType<typeof initMediaStream> = yield call(initMediaStream);
    if (!mediaStream) {
      throw new DOMException('Permission denied');
    }

    const recorder = new MediaRecorder(mediaStream);
    yield put(initCurrentRecording(recorder));
    yield spawn(watchRecordingData, recorder);

    recorder.start();
  });
}

export default function* audioRecorderSaga() {
  yield all([
    watchStartRecording(),
    watchStopRecording(),
  ]);
}

