import Chip from '@material-ui/core/Chip';
import SvgIcon from '@material-ui/core/SvgIcon';
import CancelIcon from '@material-ui/icons/Cancel';
import LabelIcon from '@material-ui/icons/Label';
import classNames from 'classnames';
import React from 'react';
import { MultiValueProps } from 'react-select/src/components/MultiValue';

import { OptionType } from '../multi-select/MultiSelect';

export default function MultiValueTagChip(props: MultiValueProps<OptionType>) {
  return (
    <Chip
    tabIndex={-1}
    label={props.children}
    className={classNames(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused,
    })}
    onDelete={props.removeProps.onClick}
    deleteIcon={<CancelIcon {...props.removeProps} />}
    icon={(
      <SvgIcon>
        <LabelIcon htmlColor={props.data.iconColor} />
      </SvgIcon>
    )}
    />
  );
}
