import { MultiStepProductTourCommonProps, MultiStepProductTourTitle, MultiStepTitleComponentProps } from '@insights-gaming/product-tour';
import { Theme } from '@insights-gaming/theme';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { FIRST_TIME_DASHBOARD } from 'features/tips/tips-slice';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useEffect } from 'react';

import { MultiStepProductTour } from '.';

interface DashboardProductTourOwnProps {
  className?: string;
}

type DashboardProductTourProps = DashboardProductTourOwnProps & MultiStepProductTourCommonProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  hidden: {
    display: 'none',
  },
}), {name: 'DashboardProductTour'});

// This is a hack to force the tooltip component to step forward and backawrds in case the anchor element is not valid
// on initial render
let ready = false;

function SlideOne(props: MultiStepTitleComponentProps) {
  const { actions, ...rest } = props;
  const classes = useStyles(props);
  const { t } = useStrictTranslation(['common', 'tips']);

  useEffect(() => {
    if (!ready) {
      props.goToStep(1);
    }
  }, [props]);

  return (
    <div className={ready ? classes.root : classes.hidden}>
      <MultiStepProductTourTitle {...rest}>
        {t('common:launchcapture')}
      </MultiStepProductTourTitle>
      <DialogContent>
        <Typography component='div'>
          {t('tips:dashboard-tour.captureinfo')}
        </Typography>
      </DialogContent>
      {actions}
    </div>
  );
}

function SlideTwo(props: MultiStepTitleComponentProps) {
  const { actions, ...rest } = props;
  const { t } = useStrictTranslation(['tips']);

  useEffect(() => {
    if (!ready) {
      ready = true;
      props.goToStep(0);
    }
  }, [props]);

  return (
    <React.Fragment>
      <MultiStepProductTourTitle {...rest}>
        {t('tips:dashboard-tour.quickupload')}
      </MultiStepProductTourTitle>
      <DialogContent>
        <Typography>
          {t('tips:dashboard-tour.uploadinfo')}
        </Typography>
      </DialogContent>
      {actions}
    </React.Fragment>
  );
}

function SlideThree(props: MultiStepTitleComponentProps) {
  const { actions, ...rest } = props;
  const { t } = useStrictTranslation(['common', 'tips']);
  return (
    <React.Fragment>
      <MultiStepProductTourTitle {...rest}>
        {t('common:addmembers')}
      </MultiStepProductTourTitle>
      <DialogContent>
        <Typography>
          {t('tips:dashboard-tour.membersinfo')}
        </Typography>
      </DialogContent>
      {actions}
    </React.Fragment>
  );
}

const components = [SlideOne, SlideTwo, SlideThree];

function DashboardProductTour(props: DashboardProductTourProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <MultiStepProductTour
    {...props}
    tourId={FIRST_TIME_DASHBOARD}
    titleComponents={components}
    className={classNames(classes.root, className)}
    />
  );
}

export default React.memo(DashboardProductTour);
