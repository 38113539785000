import { AsyncButton, EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { placeholder } from 'assets';
import { MAX_FILE_SIZE } from 'constants/numbers';
import DashboardRouting from 'features/dashboard/dashboard.routing';
import { createTeamDivisionAC } from 'features/dashboard/directory/dashboard-directory-slice';
import { createTeamAC } from 'features/dashboard/team/dashboard-team-slice';
import { fileToBinaryString } from 'helpers';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import FileInput from 'material/file-input/FileInput';
import UndraggableAvatar from 'material/undraggable-avatar/UndraggableAvatar';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';


interface CreateTeamDialogOwnProps {
  className?: string;
  onClose?: VoidFunction;
  redirect?: boolean;
}

type CreateTeamDialogProps = CreateTeamDialogOwnProps & Pick<DialogProps, 'open'>;

function CreateTeamDialog(props: CreateTeamDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <CreateTeamDialogContent {...props} />
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
    marginLeft: theme.spacing(4),
  },
}), {name: 'CreateTeamDialog'});

function CreateTeamDialogContent(props: CreateTeamDialogProps) {
  const classes = useStyles(props);
  const { onClose, redirect = true } = props;
  const { t } = useStrictTranslation(['common', 'dialog']);
  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const onNavigate = useNavigate<{ openInvite?: boolean }>();

  const [pictureFile, setPictureFile] = useState<File>();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      const file = files.item(0);
      if (!file) {
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        enqueueSnackbar(t('common:picexceedsizelimit', {bytes: MAX_FILE_SIZE}), {variant: 'warning'});
        return;
      }
      setPictureFile(file);
    }
  }, [enqueueSnackbar, t]);

  const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const picture = pictureFile ? {
        name: pictureFile.name,
        contentLength: pictureFile.size,
        contentType: pictureFile.type,
        data: await fileToBinaryString(pictureFile),
      } : undefined;

      const { team } = await promiseSagaDispatch(createTeamAC, {
        name,
        picture,
      });

      try {
        const { division } = await promiseSagaDispatch(createTeamDivisionAC, {
          name: 'General',
          teamId: team.id,
        });
        if (redirect) {
          onNavigate(DashboardRouting.createDirectoryUrl(division.id), { state: { openInvite: true } });
        }
      } catch (error) {
        onNavigate(DashboardRouting.createTeamUrl(team.id));
      }
      enqueueSnackbar(t('dialog:createteam.success'), {variant: 'success'});
      onClose?.();
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    loading,
    name,
    redirect,
    onClose,
    onNavigate,
    pictureFile,
    promiseSagaDispatch,
    t,
  ]);

  return (
    <form onSubmit={handleSubmit}>
      <EnhancedDialogTitle onClose={onClose}>
        {t('dialog:createteam.title')}
      </EnhancedDialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <FlexSpacer orientation='vertical' flexAlignItems='center'>
            <UndraggableAvatar
            src={pictureFile ? URL.createObjectURL(pictureFile) : placeholder}
            size='xl'
            />
            <FileInput
            id='team_picture'
            fullWidth={true}
            accept='image/*'
            label={(
              <FlexSpacer>
                <ArrowUpwardIcon />
                {t('dialog:createteam.piclabel')}
              </FlexSpacer>
            )}
            onChange={handleFileChange}
            />
          </FlexSpacer>
          <div className={classes.right}>
            <Typography>
              {t('dialog:createteam.hint')}
            </Typography>
            <TextField
            name='name'
            label={t('dialog:createteam.teamname.label')}
            value={name}
            onChange={handleNameChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button type='button' variant='outlined' onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <AsyncButton type='submit' variant='contained' color='primary' loading={loading} disabled={loading || !name}>
          {t('dialog:createteam.title')}
        </AsyncButton>
      </DialogActions>
    </form>
  );
}

export default React.memo(CreateTeamDialog);
