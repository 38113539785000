export interface IPageItem {
  active: boolean;
  type  : 'ellipsisItem' | 'firstItem' | 'prevItem' | 'pageItem' | 'nextItem' | 'lastItem';
  value : number;
}

export type PageFactory = (number: number) => IPageItem;

export const createEllipsisItem = (pageNumber: number): IPageItem => ({
  active: false,
  type  : 'ellipsisItem',
  value : pageNumber,
});

export const createFirstPage = (): IPageItem => ({
  active: false,
  type: 'firstItem',
  value: 1,
});

export const createPrevItem = (activePage: number): IPageItem => ({
  active: false,
  type: 'prevItem',
  value: Math.max(1, activePage - 1),
});

export const createPageFactory = (activePage: number): PageFactory => (pageNumber: number): IPageItem => ({
  active: activePage === pageNumber,
  type: 'pageItem',
  value: pageNumber,
});

export const createNextItem = (activePage: number, totalPages: number): IPageItem => ({
  active: false,
  type: 'nextItem',
  value: Math.min(activePage + 1, totalPages),
});

export const createLastItem = (totalPages: number): IPageItem => ({
  active: false,
  type: 'lastItem',
  value: totalPages,
});
