import HelpIcon from '@material-ui/icons/Help';
import React, { SVGProps } from 'react';

interface IInjectedWithAsyncOverwatchAbilityIcons {
  //UNSAFE_abilityIcons: Required<State>['abilityIcons']; // DEPRECATED: UNSAFE
  getIconFromAbilityName: (abilityName: string) => React.ComponentClass<SVGProps<SVGElement>>;
}

export type WithAsyncOverwatchAbilityIcons = IInjectedWithAsyncOverwatchAbilityIcons;

interface WithAsyncOverwatchAbilityIconsOptions {
  DefaultIcon?: React.ComponentType,
}

const defaultOptions: Required<WithAsyncOverwatchAbilityIconsOptions> = {
  DefaultIcon: HelpIcon,
};

interface State {
  abilityIcons?: typeof import('../assets/overwatch-abilities');
};

export function withAsyncOverwatchAbilityIcons(
  options: WithAsyncOverwatchAbilityIconsOptions,
) {
  return function <P extends WithAsyncOverwatchAbilityIcons>(
    Component: React.ComponentType<P>,
  ): React.ComponentType<Omit<P, keyof WithAsyncOverwatchAbilityIcons>> {

    return class extends React.Component<P, State> {
      public static displayName = `OverwatchAbilityIcons(${Component.displayName || Component.name})`;

      constructor(props: P) {
        super(props);
        this.state = {};
      }

      public async componentDidMount() {
        const abilityIcons = await import('../assets/overwatch-abilities');
        this.setState({abilityIcons});
      }

      public render() {
        const { abilityIcons } = this.state;
        if (!abilityIcons) {
          return null;
        }
        return (
          <Component
          {...this.props}
          UNSAFE_abilityIcons={abilityIcons}
          getIconFromAbilityName={this.getIconFromAbilityName}
          />
        );
      }

      public getIconFromAbilityName = (abilityName: string) => {
        const { DefaultIcon = defaultOptions.DefaultIcon } = options;
        const { abilityIcons } = this.state;
        if (!abilityIcons) {
          return DefaultIcon;
        }
        const Icon = abilityIcons.allOverwatchAbilities[abilityName];
        return Icon || DefaultIcon;
      }
    };
  };
}
