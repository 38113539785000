import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import DashboardRouting from 'features/dashboard/dashboard.routing';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router';

import { DirectoryHelper } from '../dashboard-directory-helper';
import { deleteTeamDirectoryAC, updateTeamDivisionAC, updateTeamFolderAC } from '../dashboard-directory-slice';

interface UseDirectoryContextMenu<T> {
  renameDirectory: (newName: string) => Promise<
    ResultFromAsyncActionCreator<typeof updateTeamDivisionAC> |
    ResultFromAsyncActionCreator<typeof updateTeamFolderAC>
  >;
  updateDivision: FunctionFromAsyncActionCreator<typeof updateTeamDivisionAC>;
  updateFolder: FunctionFromAsyncActionCreator<typeof updateTeamFolderAC>;
  deleteDirectory: () => Promise<ResultFromAsyncActionCreator<typeof deleteTeamDirectoryAC>>;
}

export function useDirectoryContextMenu<T extends DirectoryFragment>(directory: T): UseDirectoryContextMenu<T> {
  const ref = useRef<T>(directory);
  useEffect(() => {
    ref.current = directory;
  }, [directory]);

  const onNavigate = useNavigate();
  const { directoryid } = useParams<DashboardRouting.Params>();
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const renameDirectory = useCallback((newName: string) => {
    const { current } = ref;
    if (DirectoryHelper.isFolder(current)) {
      return promiseSagaDispatch(updateTeamFolderAC, {
        teamId: current.team.id,
        folderId: current.id,
        name: newName,
      });
    }
    return promiseSagaDispatch(updateTeamDivisionAC, {
      teamId: current.team.id,
      divisionId: current.id,
      name: newName,
    });
  }, [promiseSagaDispatch]);

  const updateDivision = useCallback((input: ParamsFromAsyncActionCreator<typeof updateTeamDivisionAC>) => {
    return promiseSagaDispatch(updateTeamDivisionAC, input);
  }, [promiseSagaDispatch]);

  const updateFolder = useCallback((input: ParamsFromAsyncActionCreator<typeof updateTeamFolderAC>) => {
    return promiseSagaDispatch(updateTeamFolderAC, input);
  }, [promiseSagaDispatch]);

  const deleteDirectory = useCallback(async () => {
    const { current } = ref;
    const result = await promiseSagaDispatch(deleteTeamDirectoryAC, {
      teamId: current.team.id,
      id: current.id,
    });
    if (result.directoryId === directoryid) {
      onNavigate(DashboardRouting.createTeamUrl(current.team.id));
    }
    return result;
  }, [directoryid, onNavigate, promiseSagaDispatch]);

  return {
    renameDirectory,
    updateDivision,
    updateFolder,
    deleteDirectory,
  } as const;
}
