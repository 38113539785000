import { VideoReplayContextValue } from '@insights-gaming/material-components/VideoReplayContext/VideoReplayContext';

import { useReactPlayerRef } from './useReactPlayerRef';
import { WrappedReactPlayerProps } from './WrappedReactPlayer';

export function bindVideoReplay(
  {
    state: {
      muted,
      playbackRate,
      volume,
      playing,
    },
    play,
    pause,
    setBuffering,
    setBufferEnd,
    setDuration,
    setProgress,
  }: VideoReplayContextValue,
  {
    onStart,
    onReady,
    aspectRatio = 16/9,
  }: Pick<ReturnType<typeof useReactPlayerRef>, 'onStart' | 'onReady' | 'aspectRatio'>,
): Pick<
  WrappedReactPlayerProps,
  | 'aspectRatio'
  | 'volume'
  | 'muted'
  | 'playbackRate'
  | 'playing'
  | 'onStart'
  | 'onReady'
  | 'onBuffer'
  | 'onBufferEnd'
  | 'onPlay'
  | 'onPause'
  | 'onDuration'
  | 'onProgress'
> {
  return {
    aspectRatio,
    volume,
    muted,
    playbackRate,
    playing,
    onStart,
    onReady,
    onBuffer: setBuffering,
    onBufferEnd: setBufferEnd,
    onPlay: play,
    onPause: pause,
    onDuration: setDuration,
    onProgress: setProgress,
  };
}
