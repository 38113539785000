import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { TELEMETRY_ENDPOINT } from 'constants/index';
import { getVideoById } from 'features/dashboard/video/dashboard-video-selector';
import { SagaIterator } from 'redux-saga';
import { all, call, select, takeEvery } from 'redux-saga/effects';
import { getMe } from 'selectors/getMe';

import { telemetry } from './telemetry-actions';

function* sendData(type: string, data: any): SagaIterator {
  const body = new URLSearchParams();
  body.set('app', 'pigeon-detector');
  body.set('version', __COMMITSHA__);
  body.set('type', type);
  body.set('data', typeof data === 'object' ? JSON.stringify(data) : data);
  body.set('user_id', (yield select(getMe))?.id ?? 'anonymous');

  navigator.sendBeacon(TELEMETRY_ENDPOINT, body);
}

export default function* telemetrySaga() {
  yield all([
    takeEvery(telemetry.videoBuffering, function* ({ payload }): SagaIterator {
      const video: VideoFragment | undefined = yield select(getVideoById, payload.videoId);
      const extra: any = {};
      if (video) {
        if (!video.streamUrls) {
          // we don't care about buffering for videos that we don't host
          return;
        }

        if (video.duration) {
          extra.duration = video.duration;
        }

        if (video.filesize) {
          extra.filesize = video.filesize;
        }

        try {
          const firstPart = new URL(video.streamUrls[0]).pathname.slice(1).split('/')[0];
          if (firstPart === 'videos') {
            extra.storageRegion = 'legacy';
          } else {
            extra.storageRegion = firstPart;
          }
        } catch {
          // ignore
        }
      }

      yield call(sendData, 'video_buffering', {
        ...extra,
        ...payload,
      });
    }),
  ]);
}
