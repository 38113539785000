import gql from 'graphql-tag';

export const DeleteTag_Mutation = gql`
    mutation DeleteTagMutation(
        $input: DeleteTagInput!
    ) {
        deleteTag(input: $input) {
            tagId
        }
    }
`;
