import { ControlButton, FlexSpacer, VideoReplayContext } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { SecondsFormatter } from '@insights-gaming/utils';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Crop75SharpIcon from '@material-ui/icons/Crop75Sharp';
import CropLandscapeSharpIcon from '@material-ui/icons/CropLandscapeSharp';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import classNames from 'classnames';
import { makeGetNotDeletedVideoCommentsByVideoId } from 'components/video/video-replay/video-comment/video-comment-selector';
import { mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { RangedTimestampContext } from 'features/video-replay/comment-panel/ranged-timestamp/RangedTimestampContext';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useKeybindings } from 'hooks/useKeybindings';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { KeyCommand } from 'keybindings';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import screenfull from 'screenfull';
import { Video } from 'types/pigeon';

import { VideoBookmarksContext } from './bookmarks/BookmarkContext';
import BookmarkSeeker from './BookmarkSeeker';
import CombinedSettings from './CombinedSettings/CombinedSettings';
import GoToRemote from './GoToRemote';
import QualityPicker from './QualityPicker';
import SeekBarRetroFitted from './SeekBarRetroFitted';
import SessionSync from './SessionSync';
import ToggleBookmark from './ToggleBookmark';

export interface IVideoPlayerControlsOwnProps {
  hideHostControls?   : boolean;
  showSessionControls?: boolean;
  shouldHideControls? : boolean;
  video?              : Video;
  flashControlIcon?   : (icon: React.ReactNode) => void;
  hideTheaterMode?    : boolean;
}

type VideoPlayerControlsProps = IVideoPlayerControlsOwnProps & React.HTMLAttributes<HTMLDivElement>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    flexGrow: 1,
    transition: 'opacity .3s, transform .3s',
    '&$dimmed': {
      opacity: 0,
    },
    '&$hidden': {
      opacity: 0,
      transform: 'translateY(100%)',
      pointerEvents: 'none',
    },
    '&$fullscreen': {
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
    },
  },
  gradient: {
    height: createRemFromPx(100),
    backgroundImage: `linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) -0.97%,
      rgba(0, 0, 0, 0.37) 20%,
      rgba(0, 0, 0, 0.88) 100%
    )`,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    pointerEvents: 'none',
    [mobilePortrait(theme)]: {
      height: createRemFromPx(50),
    },
    [mobileLandscape(theme)]: {
      height: createRemFromPx(50),
    },
  },
  toolbar: {
    padding: theme.spacing(0, 1),
  },
  controlWrapper: {
    position: 'relative',
    backgroundColor: 'transparent',
  },
  sliderWrapper: {
    display: 'flex',
    padding: '0 6px',
  },
  volumeSlider: {
    width: 100,
  },
  hotkey: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    borderRadius: theme.shape.borderRadius,
    minWidth: theme.spacing(4),
    border: '1px solid',
    borderColor: theme.palette.primary.main,
  },
  fullscreen: {},
  dimmed: {},
  hidden: {},
}), {name: 'VideoPlayerControls'});

// eslint-disable-next-line react/display-name
const VideoPlayerControls = React.forwardRef<HTMLDivElement, VideoPlayerControlsProps>(function VideoPlayerControls(
  props: VideoPlayerControlsProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const { showSessionControls, hideHostControls, video, flashControlIcon, shouldHideControls, hideTheaterMode } = props;

  const classes = useStyles(props);
  const { t } = useStrictTranslation(['video', 'keycommand', 'common', 'videoplayer']);

  const { commandEmitter } = useKeybindings();

  const {
    state: { duration, fullscreen, muted, visuallyPlaying, progress, theaterMode, volume },
    changeVolume,
    toggleFullscreen,
    toggleMuteState,
    togglePlayState,
    toggleTheaterMode,
  } = useContext(VideoReplayContext);

  const {
    toggleDisplayEndTime,
    displayEndTime,
  } = useContext(RangedTimestampContext);

  const { displayBookmarks } = useContext(VideoBookmarksContext);

  useEffect(() => {
    commandEmitter.addListener('command', (event: KeyCommand) => {
      switch (event) {
        case 'video.exit.range': return displayEndTime ? toggleDisplayEndTime() : undefined;
      }
    });
    return () => {
      commandEmitter.removeAllListeners();
    };
  }, [commandEmitter, displayEndTime, toggleDisplayEndTime]);


  const handleVolume = useCallback((__: React.ChangeEvent<{}>, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      changeVolume({
        amount: newValue,
      });
    }
  }, [changeVolume]);

  const handleVolumeCommitted = useCallback((__: React.ChangeEvent<{}>, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      changeVolume({
        amount: newValue,
        changeCommitted: true,
      });
    }
  }, [changeVolume]);

  const comments = useCreateSelector(
    makeGetNotDeletedVideoCommentsByVideoId,
    useMemo(() => ({videoId: video?.id}), [video]),
  );

  const isDesktop = useIsDesktop();

  const formattedProgress = useMemo(() => SecondsFormatter.format(progress.playedSeconds), [progress.playedSeconds]);
  const formattedDuration = useMemo(() => SecondsFormatter.format(duration), [duration]);

  if (isDesktop) {
    return (
      <div
      ref={ref}
      className={classNames(
        classes.root,
        // {[classes.hidden]: hideControls && fullscreen},
        {[classes.fullscreen]: fullscreen},
      )}
      >
        {!shouldHideControls && <div className={classes.gradient}/>}
        <Paper className={classes.controlWrapper}>
          {!hideHostControls && video && (
            <SeekBarRetroFitted comments={comments} video={video} />
          )}
          <Toolbar className={classes.toolbar} variant='dense' disableGutters={true}>
            <FlexSpacer spacing={2.5} flexAlignItems='center' fullWidth={true}>
              {!hideHostControls && (
                <ControlButton onClick={togglePlayState}>
                  {visuallyPlaying ? (
                    <PauseIcon />
                  ) : (
                    <PlayArrowIcon />
                  )}
                </ControlButton>
              )}
              <ControlButton onClick={toggleMuteState}>
                {muted ? (
                  <VolumeOffIcon />
                ) : (
                  <VolumeUpIcon />
                )}
              </ControlButton>
              <div className={classes.sliderWrapper}>
                <Slider
                className={classes.volumeSlider}
                value={volume}
                min={0}
                max={1}
                step={0.01}
                onChange={handleVolume}
                onChangeCommitted={handleVolumeCommitted}
                />
              </div>
              <Typography noWrap={true}>
                {formattedProgress} / {formattedDuration}
              </Typography>
            </FlexSpacer>
            {!hideHostControls && video && displayBookmarks && <BookmarkSeeker videoId={video.id} />}
            <FlexSpacer spacing={2.5} flexAlignItems='center' fullWidth={true} flexJustifyContent='flex-end'>
              {displayEndTime && (
                <Button onClick={toggleDisplayEndTime} size='small'>
                  <FlexSpacer flexAlignItems='center'>
                    <div className={classes.hotkey}>ESC</div>
                    <div>{t('video:rangedtimestamp.toexitrange')}</div>
                  </FlexSpacer>
                </Button>
              )}
              {!hideHostControls && video && <ToggleBookmark videoId={video.id} />}
              {showSessionControls && <SessionSync />}
              <CombinedSettings video={video} title={t('common:settings')}/>
              <QualityPicker />
              <GoToRemote />
              {!hideTheaterMode && (
                <ControlButton onClick={toggleTheaterMode}>
                  <Tooltip title={t('keycommand:video.theatermode.toggle')}>
                    {theaterMode ? (
                      <Crop75SharpIcon />
                    ) : (
                      <CropLandscapeSharpIcon />
                    )}
                  </Tooltip>
                </ControlButton>
              )}
              {screenfull.isEnabled && (
                <ControlButton onClick={toggleFullscreen}>
                  <Tooltip title={t('videoplayer:player.fullscreen')}>
                    {fullscreen ? (
                      <FullscreenExitIcon />
                    ) : (
                      <FullscreenIcon />
                    )}
                  </Tooltip>
                </ControlButton>
              )}
            </FlexSpacer>
          </Toolbar>
        </Paper>
      </div>
    );
  }

  return (
    <div
    ref={ref}
    className={classNames(
      classes.root,
      {[classes.fullscreen]: fullscreen},
    )}
    >
      {!shouldHideControls && <div className={classes.gradient}/>}
      <Paper className={classes.controlWrapper}>
        {!hideHostControls && video && (
          <SeekBarRetroFitted comments={comments} video={video} />
        )}
        <Toolbar className={classes.toolbar} variant='dense' disableGutters={true}>
          <FlexSpacer flexAlignItems='center' fullWidth={true} flexJustifyContent='flex-start'>
            {!hideHostControls && (
              <ControlButton onClick={togglePlayState}>
                {visuallyPlaying ? (
                  <PauseIcon />
                ) : (
                  <PlayArrowIcon />
                )}
              </ControlButton>
            )}
            <ControlButton onClick={toggleMuteState}>
              {muted ? (
                <VolumeOffIcon />
              ) : (
                <VolumeUpIcon />
              )}
            </ControlButton>
          </FlexSpacer>
          <FlexSpacer flexAlignItems='center' fullWidth={true} flexJustifyContent='center'>
            <Typography noWrap={true}>
              {formattedProgress} / {formattedDuration}
            </Typography>
          </FlexSpacer>
          <FlexSpacer flexAlignItems='center' fullWidth={true} flexJustifyContent='flex-end'>
            {screenfull.isEnabled && (
              <ControlButton onClick={toggleFullscreen}>
                {fullscreen ? (
                  <FullscreenExitIcon />
                ) : (
                  <FullscreenIcon />
                )}
              </ControlButton>
            )}
          </FlexSpacer>
        </Toolbar>
      </Paper>
    </div>
  );
});

export default React.memo(VideoPlayerControls);
