import { useCreateSelector } from '@insights-gaming/redux-utils';
import { getIsLocalTusUploadsInitialized, makeGetLocalTusUpload } from 'features/upload/resumable-uploads-selector';
import { initLocalTusUploadsAC } from 'features/upload/resumable-uploads-slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ID } from 'types/pigeon';

export function useGetLocalTusUpload(videoId: ID) {
  const dispatch = useDispatch();
  const isInitialized = useSelector(getIsLocalTusUploadsInitialized);
  useEffect(() => {
    if (!isInitialized) {
      dispatch(initLocalTusUploadsAC.started());
    }
  }, [dispatch, isInitialized]);
  return useCreateSelector(makeGetLocalTusUpload, videoId);
}
