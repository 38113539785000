import { callAsync, watchAsync } from '@insights-gaming/saga-utils';
import { analysisCompletedAC, analysisProgressUpdatedAC, createAnalysisRequestAC, createMultipleAnalysisRequestAC, removeVideoMetadataAC, setVideoMetadataAC, subscribeToAnalysisProgressUpdatesAC, updateVideoAsyncAC, updateVideoTagsAsyncAC } from 'actions/video-actions';
import { IClient } from 'apollo/client';
import { CreateAnalysisRequest_Mutation, CreateRemoteVideo2_Mutation, DeleteVideo_Mutation, RemoveVideoMetadata_Mutation, SetVideoMetadata_Mutation, UpdateVideo_Mutation,UpdateVideo2_Mutation, UpdateVideos_Mutation, UpdateVideoTags_Mutation } from 'apollo/mutations';
import { CreateAnalysisRequestMutation_createAnalysisRequest } from 'apollo/mutations/types/CreateAnalysisRequestMutation';
import { GetTeamDirectoryVideos_Query,GetTeamVideos_Query, GetVideoCommentsWithName_Query, GetVideosById_Query } from 'apollo/queries';
import { GetVideosByIdQuery_videos_latestAnalysis } from 'apollo/queries/types/GetVideosByIdQuery';
import { AnalysisUpdated_Subscription } from 'apollo/subscriptions';
import { AnalysisUpdatedSubscription, AnalysisUpdatedSubscriptionVariables } from 'apollo/subscriptions/types/AnalysisUpdatedSubscription';
import { ApolloQueryResult } from 'apollo-client';
import dateFnsIsAfter from 'date-fns/isAfter';
import dateFnsSubDays from 'date-fns/subDays';
import { watchAsyncBatchedQuery, watchAsyncMutation, watchAsyncQuery, watchBidirectionalAC2 } from 'helpers/saga/effects';
import { END, EventChannel, eventChannel, SagaIterator, Task } from 'redux-saga';
import { all, call, cancel, cancelled, fork, getContext, put, select, take, takeEvery } from 'redux-saga/effects';
import { CreateAnalysisRequestInput } from 'types/graphql';
import { ID } from 'types/pigeon';
import { ISubscriptionOptions } from 'types/saga';

import { makeGetVideoById } from './dashboard-video-selector';
import { createRemoteVideo2AC, deleteVideoAC, fetchTeamDirectoryVideosAC, fetchTeamVideosAC, fetchVideoByIdWithCommentsByIdsAC, fetchVideosByIdAC, fetchVideosByIdsAC, updateVideo2AC, updateVideosAC } from './dashboard-video-slice';

function subscribeToVideoAnalyses(videos: Array<{ id: ID }> | undefined) {
  return all(videos?.map(({ id }) => fork(smartAnalysisSubscriber, id)) || []);
}

function createAnalysisProgressUpdateChannel(
  {client, variables}: ISubscriptionOptions<AnalysisUpdatedSubscriptionVariables>,
): EventChannel<GetVideosByIdQuery_videos_latestAnalysis> {
  return eventChannel<GetVideosByIdQuery_videos_latestAnalysis>(emit => {
    const obs = client.subscribe({
      query: AnalysisUpdated_Subscription,
      variables,
    });
    const sub = obs.subscribe({
      next: (result: ApolloQueryResult<AnalysisUpdatedSubscription>) => {
        emit(result.data.analysisUpdated);
      },
      complete: () => {
        emit(END);
      },
    });
    return () => {
      sub.unsubscribe();
    };
  });
}

function* subscribeAnalysisProgressUpdated(
  action: ReturnType<typeof subscribeToAnalysisProgressUpdatesAC>,
): SagaIterator {
  const { videoId, analysisId } = action.payload;
  const { client }: IClient = yield getContext('apollo');
  const chan = createAnalysisProgressUpdateChannel({client, variables: action.payload});
  while (true) {
    const analysis = yield take(chan);
    yield put(analysisProgressUpdatedAC({videoId, analysisUpdated: analysis}));
    if (analysis.completed) {
      yield put(analysisCompletedAC({videoId, analysisUpdated: analysis}));
      yield cancel();
      break;
    }
  }
  if (yield cancelled()) {
    chan.close();
  }
}

function* subscribeAnalysisProgressUpdatesWatcher(): SagaIterator {
  const tasks = new Map<ID, Task>();

  function cleanupTask(videoId: ID) {
    tasks.delete(videoId);
  }

  yield takeEvery(subscribeToAnalysisProgressUpdatesAC, function* (action) {
    const { videoId, analysisId } = action.payload;
    const combinedId = [videoId, analysisId].join(':');
    if (tasks.has(combinedId)) {
      return;
    }
    const task: Task = yield fork(function* () {
      yield call(subscribeAnalysisProgressUpdated, action);
      yield call(cleanupTask, combinedId);
    });

    tasks.set(combinedId, task);
  });
}

function* smartAnalysisSubscriber(videoId: ID, dateToCompare?: Date): SagaIterator {
  const selector: ReturnType<typeof makeGetVideoById> = yield call(makeGetVideoById);
  const video: ReturnType<typeof selector> = yield select(selector, videoId);
  if (!video) {
    return;
  }
  const latestAnalysis = video.latestAnalysis;
  if (!latestAnalysis || latestAnalysis.completed) {
    return;
  }
  if (!dateToCompare) {
    dateToCompare = dateFnsSubDays(new Date(), 1);
  }
  const analysisDate = new Date(latestAnalysis.created);
  if (dateFnsIsAfter(analysisDate, dateToCompare)) {
    yield put(subscribeToAnalysisProgressUpdatesAC({videoId, analysisId: latestAnalysis.id}));
  }
}

interface AnalysisSuccessResultWithParams {
  status: 'success';
  result: CreateAnalysisRequestMutation_createAnalysisRequest;
  input: CreateAnalysisRequestInput;
}

interface AnalysisFailedResultWithParams {
  status: 'failed';
  error: Error;
  input: CreateAnalysisRequestInput;
}

type AnalysisResultWithParams = AnalysisSuccessResultWithParams | AnalysisFailedResultWithParams;

function* createAnalysisRequestSafe(input: CreateAnalysisRequestInput): SagaIterator<AnalysisResultWithParams> {
  try {
    return { status: 'success', result: yield callAsync(createAnalysisRequestAC, input), input } as const;
  } catch (error) {
    return { status: 'failed', error, input } as const;
  }
}

export default function* dashboardVideoSaga() {
  yield all([
    watchBidirectionalAC2(fetchTeamVideosAC, GetTeamVideos_Query, ['queryVideos']),
    watchBidirectionalAC2(fetchTeamDirectoryVideosAC, GetTeamDirectoryVideos_Query, ['directory', 'queryVideos']),
    watchAsyncQuery(fetchVideosByIdsAC, GetVideosById_Query, ['videos']),
    watchAsyncQuery(fetchVideoByIdWithCommentsByIdsAC, GetVideoCommentsWithName_Query, ['video']),
    watchAsyncBatchedQuery(100, fetchVideosByIdAC, fetchVideosByIdsAC, (p) => ({ ids: p.map(({ id }) => id) })),
    watchAsyncMutation(createRemoteVideo2AC, CreateRemoteVideo2_Mutation, ['createRemoteVideo2']),
    watchAsyncMutation(updateVideo2AC, UpdateVideo2_Mutation, ['updateVideo2']),
    watchAsyncMutation(updateVideosAC, UpdateVideos_Mutation, ['updateVideos']),
    watchAsyncMutation(deleteVideoAC, DeleteVideo_Mutation, ['deleteVideo']),
    watchAsyncMutation(createAnalysisRequestAC, CreateAnalysisRequest_Mutation, ['createAnalysisRequest']),
    watchAsyncMutation(setVideoMetadataAC, SetVideoMetadata_Mutation, ['setVideoMetadata']),
    watchAsyncMutation(removeVideoMetadataAC, RemoveVideoMetadata_Mutation, ['removeVideoMetadata']),
    watchAsyncMutation(updateVideoTagsAsyncAC, UpdateVideoTags_Mutation, ['updateVideoTags']),
    watchAsyncMutation(updateVideoAsyncAC, UpdateVideo_Mutation, ['updateVideo']),

    subscribeAnalysisProgressUpdatesWatcher(),

    watchAsync(createMultipleAnalysisRequestAC, function* (params) {
      const results: AnalysisResultWithParams[] = yield all(
        params.map((input) => call(createAnalysisRequestSafe, input)),
      );

      yield all(
        results
          .filter((result): result is AnalysisSuccessResultWithParams => result.status === 'success')
          .map((r) => put(subscribeToAnalysisProgressUpdatesAC({
            videoId: r.input.videoId,
            analysisId: r.result.analysis.id,
          }))),
      );

      return results.reduce(
        (result, promiseResult) => {
          if (promiseResult.status === 'success') {
            result.successes.push(promiseResult.input.videoId);
          } else {
            result.failures[promiseResult.input.videoId] = promiseResult.error;
          }

          return result;
        },
        { successes: [], failures: {} } as { successes: ID[]; failures: Record<ID, Error> },
      );
    }),

    takeEvery([
      fetchTeamVideosAC.forward.done,
      fetchTeamVideosAC.backward.done,
    ], function* ({ payload: { result: { videos } } }) {
      yield subscribeToVideoAnalyses(videos);
    }),

    takeEvery(createAnalysisRequestAC.done, function* ({ payload: { params: { videoId }, result: { analysis } } }) {
      yield put(subscribeToAnalysisProgressUpdatesAC({ videoId, analysisId: analysis.id }));
    }),
  ]);
}

