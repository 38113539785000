import { AsyncButton, EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { updateVideo2AC } from 'features/dashboard/video/dashboard-video-slice';
import { VideoHelper } from 'features/video-library/video-helpers';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { VideoPublicity } from 'types/graphql';

interface PrivacySettingsDialogOwnProps {
  className?: string;
  onClose?: VoidFunction
  video: VideoFragment;
}

type PrivacySettingsDialogProps = PrivacySettingsDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  shareTimeCheckBoxLabel: {
    marginLeft: 0,
  },
  publicVideoCheckBoxLabel: {
    alignItems: 'center',
  },
}), {name: 'PrivacySettingsDialog'});

function PrivacySettingsDialog(props: PrivacySettingsDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <PrivacySettingsDialogContent {...props} />
    </Dialog>
  );
}

function PrivacySettingsDialogContent(props: PrivacySettingsDialogProps) {
  const classes = useStyles();
  const { video, onClose } = props;
  const { t } = useStrictTranslation(['common', 'dialog']);
  const [ isPublic, setIsPublic ] = useState(!!video.public);
  const [ openComments, setOpenComments ] = useState(video.openComments);
  const [ loading, setLoading ] = useState(false);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const teamId = VideoHelper.getTeamId(video);
  const settingsUnchanged = useMemo(
    () => (isPublic === !!video.public) && (openComments === video.openComments),
    [isPublic, openComments, video.openComments, video.public],
  );

  const handleIsPublicCheckboxOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setIsPublic(checked);
    if (!checked) {
      setOpenComments(false);
    }
  }, []);

  const handleOpenCommentsCheckboxOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (!isPublic) { return; }
    setOpenComments(checked);
  }, [isPublic]);

  const handleSaveOnClick = useCallback(async () => {
    if (loading) { return; }
    if (video.public !== isPublic || video.openComments !== openComments) {
      if (!teamId) { throw new Error('Something went wrong. Please try again later'); }
      try {
        setLoading(true);
        let publicity;
        if (!isPublic && !openComments) {
          publicity = VideoPublicity.PRIVATE;
        } else if (isPublic && !openComments) {
          publicity = VideoPublicity.VIEW_ONLY;
        } else if (isPublic && openComments) {
          publicity = VideoPublicity.OPEN_COMMENTS;
        }
        const result = await promiseSagaDispatch(updateVideo2AC, {
          id: video.id,
          publicity,
          teamId,
        });
        enqueueSnackbar(t('dialog:editvideo.success'), {variant: 'success'});
        onClose?.();
      } catch (error) {
        enqueueSnackbar(error.message, {variant: 'error'});
      } finally {
        setLoading(false);
      }
    }
  }, [t, enqueueSnackbar, promiseSagaDispatch, loading, video, openComments, isPublic, onClose, teamId]);

  return (
    <React.Fragment>
      <EnhancedDialogTitle>{t('dialog:sharevideo.changeprivacysettings')}</EnhancedDialogTitle>
      <DialogContent>
        <React.Fragment>
          <FormControlLabel
          className={classes.shareTimeCheckBoxLabel}
          control={
            <Checkbox
            name='isPublic'
            checked={isPublic}
            onChange={handleIsPublicCheckboxOnChange}
            />
          }
          label={(
            <FlexSpacer className={classes.publicVideoCheckBoxLabel}>
              <Typography>{t('dialog:editvideo.public')}</Typography>
              <Typography variant='caption'>{t('dialog:editvideo.disclaimer')}</Typography>
            </FlexSpacer>
          )}
          />
          <FormControlLabel
          control={
            <Checkbox
            name='openComments'
            checked={openComments}
            onChange={handleOpenCommentsCheckboxOnChange}
            disabled={!isPublic}
            />
          }
          label={(
            <Typography>{t('dialog:editvideo.allowcomment')}</Typography>
          )}
          />
        </React.Fragment>
      </DialogContent>
      <DialogActions>
        <React.Fragment>
          <Button
          onClick={onClose}
          variant='outlined'
          >
            {t('common:cancel')}
          </Button>
          <AsyncButton
          variant='contained'
          color='primary'
          disabled={loading || settingsUnchanged}
          loading={loading}
          onClick={handleSaveOnClick}
          >
            {t('common:save')}
          </AsyncButton>
        </React.Fragment>
      </DialogActions>
    </React.Fragment>
  );
}

export default React.memo(PrivacySettingsDialog);
