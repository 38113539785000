import gql from 'graphql-tag';

export const DeleteInvitation_Mutation = gql`
    mutation DeleteInvitationMutation(
        $input: DeleteInvitationInput!
    ) {
        deleteInvitation(
            input: $input
        ) {
            id
        }
    }
`;
