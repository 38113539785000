import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

interface FloatingTextCharacterIconOwnProps {
  className?: string;
  src: string;
  alt: string;
}

type FloatingTextCharacterIconProps = FloatingTextCharacterIconOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: 22,
    height: 22,
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.5),
  },
}), { name: 'FloatingTextCharacterIcon' });

function FloatingTextCharacterIcon(props: FloatingTextCharacterIconProps) {
  const classes = useStyles(props);
  const { className, src, alt } = props;
  return (
    <img
    className={classNames(classes.root, className)}
    src={src}
    alt={alt}
    />
  );
}

export default React.memo(FloatingTextCharacterIcon);
