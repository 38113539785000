import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetTeamCustomRoleRecordsByTeamId } from './dashboard-role-selector';
import { fetchTeamCustomRolesAC } from './dashboard-role-slice';

export const useFetchTeamCustomRoles = createBidirectionalAutoFetcher({
  actionCreators: fetchTeamCustomRolesAC,
  selectorFactory: makeGetTeamCustomRoleRecordsByTeamId,
  createFetchId: ({teamId}) => teamId,
  pageSize: 10,
});
