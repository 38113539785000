import { AsyncButton, EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ExternalLink from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LinkIcon from '@material-ui/icons/Link';
import PublishIcon from '@material-ui/icons/Publish';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { CreateRemoteVideo2Mutation_createRemoteVideo2 } from 'apollo/mutations/dashboard/types/CreateRemoteVideo2Mutation';
import { LogoSvg } from 'assets/logos';
import classNames from 'classnames';
import DropIndicator from 'components/drop-indicator/DropIndicator';
import ValidatedVideoUrlTextField from 'components/validated-video-url-text-field/ValidatedVideoUrlTextField';
import { INSIGHTS_CAPTURE_ENDPOINT } from 'constants/index';
import InProgressUploadDialogContent from 'containers/dialogs/InProgressUploadDialogContent';
import ResumeUploadDialogContent from 'containers/dialogs/ResumeUploadDialogContent';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import DashboardTeamSettingsBillingRouting from 'features/dashboard/team/dashboard-team-settings-billing.routing';
import { createRemoteVideo2AC } from 'features/dashboard/video/dashboard-video-slice';
import { mobilePortrait } from 'features/media-queries';
import { ITusResumable } from 'features/upload/upload-slice';
import { isRejected, reflect, Reflected } from 'helpers';
import { useTusUpload } from 'hooks/dispatch';
import { useDialogState } from 'hooks/useDialogState';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import groupBy from 'lodash/groupBy';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ETeamSettingsTabType, teamSettingsRoute } from 'routes';
import { getMe } from 'selectors/getMe';
import { ID } from 'types/pigeon';

import FileInput from '../../file-input/FileInput';
import PendingUploadsList from './PendingUploadsList';
import { FilePendingUpload, RemoteUrlPendingUpload } from './types';
import { useCheckResourceLimit } from './useCheckResourceLimit';
import { usePendingUploadsState } from './usePendingUploadsState';

interface IUploadDialogContentOwnProps {
  droppedFiles: File[];
  onClose: VoidFunction;
  teamId: ID;
  directoryId: ID;
}

type UploadDialogContentProps = IUploadDialogContentOwnProps;

const videoFormat: string[] = [
  '.mp4',
  '.flv',
  '.mov',
  '.mkv',
  '.avi',
  '.m2ts',
  '.mxf',
  '.lxf',
  '.gxf',
  '.3gp',
  '.webm',
  '.mpg',
];

const acceptedVideoFormat = videoFormat.join(', ');

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none',
  },
  captureLink: {
    textDecoration: 'underline',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  dropIndicator: {
    opacity: 0,
    '&$isOuterDragActive': {
      pointerEvents: 'unset',
      opacity: 'unset',
    },
  },
  floating: {
    position: 'absolute',
    backgroundColor: theme.palette.background.dimmed,
    padding: theme.spacing(.5),
    borderRadius: theme.shape.borderRadius,
    pointerEvents: 'none',
  },
  upload: {
    fontSize: createRemFromPx(82),
    [mobilePortrait(theme)]: {
      fontSize: createRemFromPx(40),
    },
    backgroundColor: 'rgba(196, 196, 196, 0.3)',
    borderRadius: '50%',
  },
  logo: {
    width: createRemFromPx(24),
  },
  uploadFromText: {
    [mobilePortrait(theme)]: {
      fontSize: createRemFromPx(12),
    },
  },
  url: {
    width: createRemFromPx(356),
    alignSelf: 'center',
    [mobilePortrait(theme)]: {
      justifyContent: 'center',
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },
  isOuterDragActive: {},
}), {name: 'UploadDialogContent'});

function UploadDialogContent(props: UploadDialogContentProps) {
  const classes = useStyles(props);
  const { droppedFiles, onClose, teamId, directoryId } = props;
  const { t } = useStrictTranslation(['common', 'dialog', 'dashboard-main']);
  const { enqueueSnackbar } = useSnackbar();

  const [ loading, setLoading] = useState(false);
  const [ tusResumable, setTusResumable ] = useState<ITusResumable>();

  const [ inProgressOpen, openInProgress, closeInProgress ] = useDialogState();
  const [ resumeOpen, openResume, closeResume ] = useDialogState();

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const onTusUpload = useTusUpload();

  const me = useSelector(getMe);
  const isWhitelisted = useMemo(() => !!me?.whitelisted, [me]);
  const { canUploadVod, canAccessBilling } = useAccessControl();
  const canUpload = canUploadVod || isWhitelisted;

  const {
    items,
    ready,
    addItems,
    addInvalid,
    updateItems,
    removeItems,
    removeItemsExcept,
  } = usePendingUploadsState(droppedFiles);
  const { isOverLimit } = useCheckResourceLimit(teamId, items, isWhitelisted);

  const handleFileInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addItems(Array.from(event.target.files));
    }
  }, [addItems]);

  const handleSubmit = useCallback(async () => {
    if (loading || !ready) { return; }
    setLoading(true);

    const urlErrors: Map<string, Error> = new Map();
    let needsPrompt = false;

    try {
      const { file: files = [], remote = [] } = groupBy(ready, ({ type }) => type) as {
        file?: FilePendingUpload[];
        remote?: RemoteUrlPendingUpload[];
      };

      for (const [uuid, result] of await Promise.all(
        remote.map(
          ({ uuid, url, publicity }) => reflect(promiseSagaDispatch(createRemoteVideo2AC, {
            remoteUrl: url,
            teamId,
            publicity,
            directoryId,
          })).then(
            (result): [string, Reflected<CreateRemoteVideo2Mutation_createRemoteVideo2>] => [uuid, result],
          ),
        ),
      )) {
        if (isRejected(result)) {
          const error = result.error;

          urlErrors.set(uuid, error);
          const needle = 'Youtube said: ';
          const i = error.message.indexOf(needle);
          let message = error.message;
          if (i >= 0) {
            message = message.slice(i + needle.length);
          }

          enqueueSnackbar(message, {variant: 'error'});
        }
      }

      if (files.length) {
        const tusResumable: ITusResumable = await onTusUpload({ files, teamId, directoryId });
        const { inProgress, resumable } = tusResumable;
        if ((needsPrompt = inProgress.length > 0 || resumable.length > 0)) {
          setTusResumable(tusResumable);
        }
        if (inProgress.length > 0) {
          openInProgress();
        }
        if (resumable.length > 0) {
          openResume();
        }
      }
    } finally {
      setLoading(false);
      if (!needsPrompt && urlErrors.size < 1) {
        onClose();
      } else if (urlErrors.size > 0) {
        removeItemsExcept(Array.from(urlErrors.keys()));
      }
    }
  }, [
    directoryId,
    enqueueSnackbar,
    loading,
    onClose,
    onTusUpload,
    openInProgress,
    openResume,
    promiseSagaDispatch,
    ready,
    removeItemsExcept,
    teamId,
  ]);

  const closeAll = useCallback(() => {
    closeResume();
    closeInProgress();
    onClose();
  }, [closeResume, closeInProgress, onClose]);

  const handleFileDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    addItems(acceptedFiles);
    addInvalid(fileRejections.map(({ file }) => file));
  }, [addInvalid, addItems]);

  const { getRootProps: getOuterRootProps, isDragActive: isOuterDragActive } = useDropzone({
    accept: [],
    noClick: true,
    noKeyboard: true,
  });
  const { getRootProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'video/*',
    onDrop: handleFileDrop,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <React.Fragment>
      <EnhancedDialogTitle>
        {t('dialog:upload.title')}
      </EnhancedDialogTitle>
      <DialogContent {...getOuterRootProps()}>
        <div className={classes.container}>
          <PendingUploadsList
          items={items}
          onRemove={removeItems}
          onChange={updateItems}
          isOverLimit={isOverLimit}
          />
          {canUpload && (
            <FlexSpacer orientation='vertical' flexAlignItems='center' spacing={2}>
              {!items.length && <PublishIcon className={classes.upload}/>}
              <Typography>
                {t('dialog:upload.dragndrop')}
              </Typography>
              <FileInput
              id='file'
              label={t('dialog:upload.browsefiles')}
              accept={acceptedVideoFormat}
              onChange={handleFileInput}
              multiple={true}
              />
              <FlexSpacer flexAlignItems='center'>
                <LogoSvg className={classes.logo} variant='capture' />
                <Typography className={classes.uploadFromText}>
                  <Trans
                  ns='dialog'
                  i18nKey='upload.uploadfromcapture'
                  >
                    Upload your recordings from&nbsp;
                    <ExternalLink
                    className={classes.captureLink}
                    href={INSIGHTS_CAPTURE_ENDPOINT}
                    target='_blank'
                    rel='noopener noreferrer'
                    >
                      Insights Capture
                    </ExternalLink>
                  </Trans>
                </Typography>
              </FlexSpacer>
            </FlexSpacer>
          )}
          <FlexSpacer flexAlignItems='center' className={classes.url}>
            <LinkIcon />
            <ValidatedVideoUrlTextField onChange={addItems}/>
          </FlexSpacer>
          {isOverLimit &&
            <Typography
            color='error'
            variant='caption'
            align='center'
            component={canAccessBilling ? Link : Typography}
            to={teamSettingsRoute(teamId, ETeamSettingsTabType.BILLING)}
            >
              {t('dialog:upload.limitreached')}
            </Typography>
          }
        </div>
        <DropIndicator
        {...getRootProps()}
        isDragActive={isDragActive}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        className={classNames(classes.dropIndicator, {[classes.isOuterDragActive]: isOuterDragActive})}
        >
          {isDragAccept ? (
            <FlexSpacer orientation='vertical' flexAlignItems='center' className={classes.floating}>
              <CloudUploadIcon fontSize='large' />
              <span>
                {t('dashboard-main:filedrop.filesaccepted')}
              </span>
            </FlexSpacer>
          ) : isDragReject ? (
            <FlexSpacer orientation='vertical' flexAlignItems='center' className={classes.floating}>
              <WarningRoundedIcon fontSize='large' />
              <span>
                {t('dashboard-main:filedrop.filesrejected')}
              </span>
            </FlexSpacer>
          ) : null}
        </DropIndicator>
      </DialogContent>
      <DialogActions>
        <Button
        type='button'
        variant='outlined'
        onClick={onClose}
        >
          {t('common:cancel')}
        </Button>
        {isOverLimit && canAccessBilling ? (
          <Link
          className={classes.link}
          to={DashboardTeamSettingsBillingRouting.createUrl(
            teamId,
            DashboardTeamSettingsBillingRouting.BillingTab.OVERVIEW,
          )}
          >
            <Button
            variant='contained'
            color='primary'
            >
              {t('common:upgrade')}
            </Button>
          </Link>
        ) : (
          <AsyncButton
          type='submit'
          variant='contained'
          color='primary'
          loading={loading}
          disabled={loading || !ready || items.length < 1 || isOverLimit}
          onClick={handleSubmit}
          >
            {t('common:upload')}
          </AsyncButton>
        )}
      </DialogActions>
      {tusResumable && (
        <React.Fragment>
          <Dialog
          open={resumeOpen}
          fullWidth={true}
          >
            <ResumeUploadDialogContent
            teamId={teamId}
            directoryId={directoryId}
            onClose={closeAll}
            tusResumable={tusResumable}
            />
          </Dialog>
          <Dialog
          open={inProgressOpen}
          fullWidth={true}
          >
            <InProgressUploadDialogContent
            teamId={teamId}
            onClose={closeAll}
            tusResumable={tusResumable}
            directoryId={directoryId}
            />
          </Dialog>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default React.memo(UploadDialogContent);
