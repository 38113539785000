import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { desktop, mobileLandscape, mobilePortrait } from 'features/media-queries';
import React, { PropsWithChildren } from 'react';

interface AudioCommentWrapperOwnProps {
  className?: string;
  coloured?: boolean;
  fill?: boolean;
}

type AudioCommentWrapperProps = AudioCommentWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  playerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 1),
    [desktop(theme)]: {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: 'solid',
    },
    [mobilePortrait(theme)]: {
      margin: createRemFromPx(2),
    },
    [mobileLandscape(theme)]: {
      margin: createRemFromPx(2),
    },
    '&$coloured': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    whiteSpace: 'nowrap',
  },
  fill: {
    [desktop(theme)]: {
      padding: theme.spacing(2, 1.5),
    },
  },
  coloured: {},
}), {name: 'AudioCommentWrapper'});

function AudioCommentWrapper(props: PropsWithChildren<AudioCommentWrapperProps>) {
  const classes = useStyles(props);
  const { children, className, coloured, fill } = props;
  return (
    <div className={classNames(
      className,
      classes.playerContainer,
      {[classes.coloured]: coloured, [classes.fill]: fill},
    )}
    >
      {children}
    </div>
  );
}

export default React.memo(AudioCommentWrapper);
