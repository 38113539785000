import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React from 'react';

interface GlobalRoleTableRowOwnProps {
  className?: string;
  name?: React.ReactNode;
  privileges?: React.ReactNode;
  description?: React.ReactNode;
  actions?: React.ReactNode;
}

type GlobalRoleTableRowProps = GlobalRoleTableRowOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'GlobalRoleTableRow'});

function GlobalRoleTableRow(props: GlobalRoleTableRowProps) {
  const classes = useStyles(props);
  const { className, name, privileges, actions, description } = props;
  return (
    <TableRow className={classNames(classes.root, className)}>
      <FixedTableCell width={100}>{name}</FixedTableCell>
      <FixedTableCell width={200}>{privileges}</FixedTableCell>
      <FixedTableCell width={100}>{description}</FixedTableCell>
      <FixedTableCell width={100} align='center'>{actions}</FixedTableCell>
    </TableRow>
  );
}

export default React.memo(GlobalRoleTableRow);
