import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import FileSaver from 'file-saver';
import update from 'immutability-helper';
import json2csv, { parse } from 'json2csv';
import memoizeOne from 'memoize-one';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import sanitize from 'sanitize-filename';

import { OverwatchTeamFightCompositionStatisticsV2Fragment } from '../../../../../apollo/fragments/overwatch-statistics/v2/types/OverwatchTeamFightCompositionStatisticsV2Fragment';
import { GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query_statistics_OverwatchStatistics } from '../../../../../apollo/queries/types/GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query';
import { addIconsFromCompKey, CSV_LINE_BREAK, isTruthy } from '../../../../../helpers';
import { normalizer } from '../../../../../helpers/math';
import { DialogNS } from '../../../../../locales/en/dialog';
import { OwstatsF } from '../../../../../locales/en/owstats';
import { ICData, mapColumn,VirtualizedTable } from '../../../../../material/virtualized-table/VirtualizedTable';
import { INormalized } from '../../../../../types';
import { TeamFightCompositionPrimaryRosterV2 } from '../../../../../types/pigeon/statistics';
import { CsvOverallOption } from '../../../../../types/stat-csv-types';

type ProcessedData = INormalized<TeamFightCompositionPrimaryRosterV2>;
type TeamFightCompositionsWithHeroIcons = OverwatchTeamFightCompositionStatisticsV2Fragment &
{heroIcons?: string[]};

type Column = ICData<TeamFightCompositionsWithHeroIcons>;

const columns: Column[] = [
  {
    tKey       : OwstatsF('teamcompositionstats.heroes'),
    dataKey    : 'heroIcons',
    width      : 320,
    flexGrow   : 1,
    flexShrink : 0,
    avatarArray: true,
  },
  {
    tKey   : OwstatsF('teamfightstats.teamfightwinabbr'),
    tDetailedKey : OwstatsF('teamfightstats.teamfightwin'),
    dataKey: 'wins',
    numeric: true,
    displayAsPercent: true,
  },
  {
    tKey   : OwstatsF('teamfightsabbr'),
    tDetailedKey : OwstatsF('teamfights'),
    dataKey: 'teamfights',
    numeric: true,
  },
  {
    tKey     : OwstatsF('killsperfightabbr'),
    tDetailedKey : OwstatsF('killsperfight'),
    dataKey  : 'kills',
    numeric  : true,
    precision: 2,
  },
  {
    tKey     : OwstatsF('deathsperfightabbr'),
    tDetailedKey : OwstatsF('deathsperfight'),
    dataKey  : 'deaths',
    numeric  : true,
    precision: 2,
  },
  {
    tKey     : OwstatsF('ultsperfightabbr'),
    tDetailedKey : OwstatsF('ultsperfight'),
    dataKey  : 'ults',
    numeric  : true,
    precision: 2,
  },
  {
    tKey     : OwstatsF('firstkillsperfightabbr'),
    tDetailedKey : OwstatsF('firstkillsperfight'),
    dataKey  : 'firstKills',
    numeric  : true,
    precision: 2,
    displayAsPercent: true,
  },
  {
    tKey     : OwstatsF('firstdeathsperfightabbr'),
    tDetailedKey : OwstatsF('firstdeathsperfight'),
    dataKey  : 'firstDeaths',
    numeric  : true,
    precision: 2,
    displayAsPercent: true,
  },
  {
    tKey     : OwstatsF('firstultsperfightabbr'),
    tDetailedKey : OwstatsF('firstultsperfight'),
    dataKey  : 'firstUlts',
    numeric  : true,
    precision: 2,
    displayAsPercent: true,
  },
];

export interface IOverwatchPrimaryRosterTeamFightCompositionStatisticsTableOwnProps {
  statistics: GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query_statistics_OverwatchStatistics;
  csvName: string;
}

export type OverwatchPrimaryRosterTeamFightCompositionStatisticsTableProps =
  IOverwatchPrimaryRosterTeamFightCompositionStatisticsTableOwnProps &
  WithStyles<typeof styles> &
  WithTranslation;

const styles = createStyles({
  statsTables: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
});


const normalizeCompositionForTeamFights =
(teamfights: number) =>
(composition: TeamFightCompositionsWithHeroIcons): TeamFightCompositionsWithHeroIcons =>
{
  const n = normalizer(teamfights, composition.teamfights);
  return update(composition, {
    kills       : n,
    deaths      : n,
    ults        : n,
    wins        : n,
    firstKills  : n,
    firstDeaths : n,
    firstUlts   : n,
  });
};

const addIconsToRosterCompositions = (
  teams: TeamFightCompositionPrimaryRosterV2[],
) => {
  return teams.map(team => update(team, {
    compositions: compositions => compositions.map(addIconsFromCompKey),
  }));
};

const normalizeRosterCompositionStats = (
  teams: TeamFightCompositionPrimaryRosterV2[],
  teamfights: number = 1,
): ProcessedData[] => {
  return teams.map(team => ({
    normalized: update(team, {
      compositions: compositions => compositions.map(normalizeCompositionForTeamFights(teamfights)),
    }),
  }));
};

interface IState {
  columns: Column[];
}

class OverwatchPrimaryRosterTeamFightCompositionStatisticsTable extends React.Component<
  OverwatchPrimaryRosterTeamFightCompositionStatisticsTableProps,
  IState
> {
  private memoizedTeamFightCompositionsWithIcons = memoizeOne(addIconsToRosterCompositions);
  private memoizedNormalizedRosterTeamFightCompositionStats = memoizeOne(normalizeRosterCompositionStats);
  private memoizedColumnData = memoizeOne((cc: Column[], language: string) => {
    return cc.map(mapColumn(this.props.t, 200));
  });
  private memoizedCsvFields = memoizeOne(
    (language: string) => {
      const { t } = this.props;
      const columnMap: Map<string, ICData<any>> = new Map();
      let allColumns: Array<ICData<any>> = columns;
      allColumns.forEach(c => {
        columnMap.set(c.dataKey, c);
      });
      const dataKeys: Array<keyof TeamFightCompositionsWithHeroIcons> = [
        'heroIcons',
        'wins',
        'teamfights',
        'kills',
        'deaths',
        'ults',
        'firstKills',
        'firstDeaths',
        'firstUlts',
      ];
      const fields: Array<json2csv.FieldInfo<TeamFightCompositionsWithHeroIcons>> = dataKeys.map(k => {
        const v = columnMap.get(k);
        if (!v) {
          return undefined;
        }
        const { dataKey, tKey, tOptions } = v;
        return {
          label: t(tKey, tOptions),
          value: dataKey === 'heroIcons' ? 'compKey' : dataKey,
        };
      }).filter(isTruthy);
      return { fields };
    },
  )

  constructor(props: OverwatchPrimaryRosterTeamFightCompositionStatisticsTableProps) {
    super(props);
    this.state = {
      columns,
    };
  }

  public render() {
    const { statistics, t, classes } = this.props;
    const withIcons = this.memoizedTeamFightCompositionsWithIcons(statistics.primaryRosterV2);
    return (
      <React.Fragment>
        <Box mb={1}>
          <Button onClick={this.exportCsv}>
            {t(OwstatsF('exportcsv'))}
          </Button>
        </Box>
        <Box className={classes.statsTables}>
          {this.memoizedNormalizedRosterTeamFightCompositionStats(withIcons).map(this.renderRosterTable)}
        </Box>
      </React.Fragment>
    );
  }

  private renderRosterTable = (roster: ProcessedData, i: number) => {
    return (
      <VirtualizedTable
      variant='outer'
      key={i}
      data={roster.normalized.compositions}
      columns={this.memoizedColumnData(this.state.columns, this.props.i18n.language)}
      />
    );
  }

  private buildRosterCsv = (roster: ProcessedData, options: {
    fields: Array<json2csv.FieldInfo<TeamFightCompositionsWithHeroIcons>>,
    includeOverall?: CsvOverallOption,
  }) => {
    const { fields, includeOverall } = options;

    let csv = '';

    csv += roster.normalized.compositions.map(composition => {
      let playerCsv: string = '';
      let totalRow = '';
      if (includeOverall) {
        totalRow = parse(composition, {
          fields,
          header: false,
        }) + CSV_LINE_BREAK;
      }
      switch (includeOverall) {
        case 'before':
          playerCsv = totalRow + playerCsv;
          break;
        case 'after':
          playerCsv = playerCsv + totalRow;
          break;
      }
      return playerCsv;
    }).join('');

    return csv;
  }

  private exportCsv = () => {
    const { statistics, csvName } = this.props;

    const header = true;
    const includeOverall: CsvOverallOption = 'before';

    const withIcons = this.memoizedTeamFightCompositionsWithIcons(statistics.primaryRosterV2);
    const rosters = this.memoizedNormalizedRosterTeamFightCompositionStats(withIcons);
    const { fields } = this.memoizedCsvFields(this.props.i18n.language);

    const csvs = [
      header && (parse([], {fields}) + CSV_LINE_BREAK),
      ...rosters.map(r => this.buildRosterCsv(r, {fields, includeOverall})),
    ].filter(isTruthy);
    const blob = new Blob(csvs, {type: 'text/csv'});
    const filename = csvName.slice(0, 48) + '_teamcomp-stats.csv';
    FileSaver.saveAs(blob, sanitize(filename));
  }
}

export default withTranslation(DialogNS)(
  withStyles(styles)(OverwatchPrimaryRosterTeamFightCompositionStatisticsTable),
);
