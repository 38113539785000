import { Loader } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { LiveSession } from 'assets';
import classNames from 'classnames';
import { getLiveSessionStateType } from 'features/live-session/live-session-selector';
import { liveSessionConnectAndRegisterAC } from 'features/live-session/live-session-slice';
import StartLiveSessionDialog from 'features/live-session/StartLiveSessionDialog';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ID } from 'types/pigeon';

import { useAccessControl } from '../access-control/useAccessControl';
import { useFetchVideoIfNecessary } from '../video/useFetchVideoIfNecessary';

interface LiveSessionButtonOwnProps {
  className?: string;
  team: TeamFragment;
  directoryId?: ID;
  id?: string;
}

type LiveSessionButtonProps = LiveSessionButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  loading: {
    opacity: 0.5,
  },
  loader: {
    position: 'absolute',
    top: '30%',
    left: '30%',
  },
}), { name: 'LiveSessionButton' });

function LiveSessionButton(props: LiveSessionButtonProps) {
  const classes = useStyles(props);
  const { team, directoryId } = props;

  const { t } = useStrictTranslation(['common', 'dialog']);
  const { canStartSession } = useAccessControl();
  const dispatch = useDispatch();

  const [isStartSessionDialogOpen, openStartSessionDialog, closeStartSessionDialog] = useDialogState();

  const { videoId } = useParams<{videoId: string}>();

  const [preselectedVideo, _] = useFetchVideoIfNecessary(videoId);

  const isDesktop = useIsDesktop();

  const state = useSelector(getLiveSessionStateType);
  const loading = useMemo(() => state === 'connecting', [state]);
  const disabled = useMemo(
    () => loading || state === 'joined' || state === 'pending' || state === 'reconnecting',
    [loading, state],
  );

  const joinActiveSession = useCallback(() => {
    if (!team.activeSession || loading) {
      return;
    }

    dispatch(liveSessionConnectAndRegisterAC.started({ token: team.activeSession, registration: {} }));
  }, [
    loading,
    dispatch,
    team.activeSession,
  ]);

  if (!team.activeSession && !canStartSession) {
    return null;
  }

  return (
    <React.Fragment>
      {isDesktop ? (
        <Box position='relative'>
          <Tooltip
          title={disabled ? '' : t(team.activeSession ? 'dialog:joinsession.title' : 'dialog:startsession.title')}
          >
            <IconButton
            onClick={team.activeSession ? joinActiveSession : openStartSessionDialog}
            disabled={disabled}
            className={classNames({ [classes.loading]: disabled })}
            >
              <SvgIcon htmlColor={loading ? undefined : red[700]}>
                <LiveSession width={24} height={24}/>
              </SvgIcon>
            </IconButton>
          </Tooltip>
          {loading && (
            <Loader className={classes.loader} size='40%' />
          )}
        </Box>
      ) : (
        <MenuItem
        onClick={team.activeSession ? joinActiveSession : openStartSessionDialog}
        >
          <ListItemIcon>
            <SvgIcon htmlColor={loading ? undefined : red[700]}>
              <LiveSession width={24} height={24}/>
            </SvgIcon>
          </ListItemIcon>
          <ListItemText>
            {t('common:groupsession')}
          </ListItemText>
        </MenuItem>
      )}
      {directoryId && (
        <StartLiveSessionDialog
        open={isStartSessionDialogOpen}
        fullWidth={true}
        onClose={closeStartSessionDialog}
        teamId={team.id}
        directoryId={directoryId}
        video={preselectedVideo}
        analyticsSource='top-bar'
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(React.forwardRef(LiveSessionButton));
