import { Theme } from '@insights-gaming/theme';
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import LabelIcon from '@material-ui/icons/Label';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import classNames from 'classnames';
import React, { useCallback } from 'react';

interface TagChipOwnProps {
  className?: string;
  tag: TagFragment;
  handleTagOnDelete: (e: React.SyntheticEvent, tag: TagFragment) => void;
}

type TagChipProps = TagChipOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: { },
}), {name: 'TagChip'});

function TagChip(props: TagChipProps) {
  const classes = useStyles(props);
  const { className, tag, handleTagOnDelete } = props;

  const handleTagItemOnClick = useCallback((e: React.SyntheticEvent) => {
    handleTagOnDelete?.(e, tag);
  }, [handleTagOnDelete, tag]);

  return (
    <Chip
    className={classNames(classes.root, className)}
    size='small'
    variant='outlined'
    label={tag.name}
    avatar={<LabelIcon style={{color: tag.color}}/>}
    onDelete={handleTagItemOnClick}
    />
  );
}

export default React.memo(TagChip);
