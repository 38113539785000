import { globalRoleReducer } from 'components/global-roles/global-role-slice';
import { userNotificationSettingsReducer } from 'components/settings/notification-settings/notification-settings-slice';
import { notificationsReducer } from 'components/settings/notifications/notifications-slice';
import { audioRecorderReducer } from 'components/video/video-replay/video-comment/audio-comment/audio-comment-slice';
import { videoCommentReducer } from 'components/video/video-replay/video-comment/video-comment-slice';
import { connectRouter } from 'connected-react-router';
import { authReducer } from 'features/auth/auth-slice';
import { captureGamesStatisticsReducer } from 'features/capture-games-statistics/common';
import { accessControlReducer } from 'features/dashboard/access-control/access-control-slice';
import { billingReducer } from 'features/dashboard/billing/dashboard-billing-slice';
import { dashboardDirectoryReducer } from 'features/dashboard/directory/dashboard-directory-slice';
import { dashboardInvitationReducer } from 'features/dashboard/invitation/dashboard-invitation-slice';
import { dashboardMemberReducer } from 'features/dashboard/member/dashboard-member-slice';
import { productTourReducer } from 'features/dashboard/product-tour/product-tour-slice';
import { dashboardRoleReducer } from 'features/dashboard/role/dashboard-role-slice';
import { dashboardTagReducer } from 'features/dashboard/tag/dashboard-tag-slice';
import { dashboardTeamReducer } from 'features/dashboard/team/dashboard-team-slice';
import { dashboardVideoReducer } from 'features/dashboard/video/dashboard-video-slice';
import { videoBookmarksReducer } from 'features/dashboard/video/video-bookmark/video-bookmark-slice';
import { errorReducer } from 'features/error/error-slice';
import { liveSessionReducer } from 'features/live-session/live-session-slice';
import { overwatchAdvancedQueryReducer } from 'features/overwatch-advanced-query/overwatch-advanced-query-slice';
import { statisticsReducer } from 'features/statistics/statistics-slice';
import { tipsReducer } from 'features/tips/tips-slice';
import { resumableUploadsReducer } from 'features/upload/resumable-uploads-slice';
import { uploadReducer } from 'features/upload/upload-slice';
import { videoPlayerReducer } from 'features/video-player/video-player-slice';
import { History } from 'history';
import { combineReducers } from 'redux';

import { searchReducer } from './features/search/search-slice';
import { keybindingReducer } from './reducers/keybinding-reducer';
import { kmsessionReducer } from './reducers/kmsession-reducer';
import { publicStatisticsReducer } from './reducers/public-statistics-reducer';
import { videoFilterReducer } from './reducers/video-filter-reducer';
import { drawingToolReducer } from './subcomponents/video-player-3/drawing-tools-2/drawing-tool-slice';

const rootReducer = (history: History) => combineReducers({
  uploadReducer,
  resumableUploadsReducer,
  authReducer,
  tipsReducer,
  audioRecorderReducer,
  videoFilterReducer,
  videoPlayerReducer,
  drawingToolReducer,
  errorReducer,
  statisticsReducer,
  kmsessionReducer,
  liveSessionReducer,
  captureGamesStatisticsReducer,
  keybindingReducer,
  publicStatisticsReducer,
  overwatchAdvancedQueryReducer,
  commentReducer: videoCommentReducer,
  dashboardDirectory: dashboardDirectoryReducer,
  dashboardVideo: dashboardVideoReducer,
  dashboardTag: dashboardTagReducer,
  dashboardTeam: dashboardTeamReducer,
  dashboardMember: dashboardMemberReducer,
  dashboardRole: dashboardRoleReducer,
  dashboardInvitation: dashboardInvitationReducer,
  accessControl: accessControlReducer,
  billing: billingReducer,
  globalRole: globalRoleReducer,
  userNotificationSettings: userNotificationSettingsReducer,
  notifications: notificationsReducer,
  videoBookmarks: videoBookmarksReducer,
  searchReducer,
  router: connectRouter(history),
  productTourReducer,
});

export type RootReducer = ReturnType<ReturnType<typeof rootReducer>>;
export default rootReducer;
