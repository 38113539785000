import { InMemoryCache, IntrospectionFragmentMatcher, NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient, { DefaultOptions } from 'apollo-client';
import { split } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { HttpLink } from 'apollo-link-http';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';

import { GRAPHQL_ENDPOINT } from '../constants';
import createWsLink from './wsLink';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: require('./fragmentTypes.json'),
});

// recursively check if any value is a Blob or File
function hasBlob(value: any) {
  if (!value) {
    return false;
  }

  if (typeof value !== 'object') {
    return false;
  }

  if (value instanceof Blob) {
    return true;
  }

  return (
    Array.isArray(value) ? value : Object.values(value)
  ).some(hasBlob);
}

const httpLinkWithUpload = split(
  ({ query, variables }) => Object.values(variables).some(hasBlob),
  createUploadLink(),
  new BatchHttpLink({
    uri: GRAPHQL_ENDPOINT,
    batchMax: -1,
  }),
);

function createLinkWithWs() {
  const { wsLink, dispose } = createWsLink();
  const link = split(
    ({ query }) => {
      const def = getMainDefinition(query);
      return def.kind === 'OperationDefinition' && def.operation === 'subscription';
    },
    wsLink,
    httpLinkWithUpload,
  );
  return {
    link: createPersistedQueryLink().concat(link),
    dispose,
  };
}

function dataIdFromObject(o: any) {
  // if (o.__typename === 'OverwatchTeamFightPlayerStatistics') { return null; }
  // return o.id
  //   ? combineIds(o.__typename, o.id)
  //   : null;
  return null;
}

const cacheOptions = {
  dataIdFromObject,
  fragmentMatcher,
};

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
  },
};

// function createApolloClient(): IClient {
//   const apolloClient = new ApolloClient({
//     cache: new InMemoryCache(cacheOptions),
//     link: httpLink,
//     defaultOptions,
//   });

//   return {
//     client: apolloClient,
//     dispose: () => {/* NOOP */},
//   };
// }

function createApolloClientWithWs(): IClient {
  const { link, dispose } = createLinkWithWs();
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(cacheOptions),
    link,
    defaultOptions,
  });

  return {
    client: apolloClient,
    dispose,
  };
}

function createApolloClientWithoutWs(): IClient {
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(cacheOptions),
    link: createPersistedQueryLink().concat(httpLinkWithUpload),
    defaultOptions,
  });

  return {
    client: apolloClient,
    dispose: () => {},
  };
}

function createSteveApolloClient(): IClient {
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(cacheOptions),
    link: new HttpLink({
      uri: 'http://100.78.135.42:8080/graphql?access_token=fakeuser',
    }),
    defaultOptions,
  });

  return {
    client: apolloClient,
    dispose: () => {},
  };
}

export interface IClient {
  client : ApolloClient<NormalizedCacheObject>;
  dispose: VoidFunction;
}

export { createApolloClientWithoutWs, createApolloClientWithWs, createSteveApolloClient };
