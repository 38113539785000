import { useCreateSelector } from '@insights-gaming/redux-utils';
import { getDisplayName } from '@insights-gaming/utils';
import React, { forwardRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ProductTourId } from './product-tour-ids';
import { makeShouldShowTourId } from './product-tour-selectors';
import { productTourViewed } from './product-tour-slice';

interface CommonProps {
  onClose?: VoidFunction;
}

interface InjectedProps extends CommonProps {}

interface AdditionalProps extends CommonProps {
  tourId: ProductTourId;
}

export function withProductTour<P extends InjectedProps, E>(
  Component: React.ComponentType<P> | React.ExoticComponent<P>,
) {
  type RemovedProps = Omit<InjectedProps, keyof CommonProps>;
  type Props = Omit<AdditionalProps & P, keyof RemovedProps>;

  const WithProductTour = forwardRef<E, Props>(function WithProductTour(props: Props, forwardedRef) {
    const {
      onClose,
      tourId,
      ...rest
    } = props;

    const dispatch = useDispatch();
    const shouldShow = useCreateSelector(makeShouldShowTourId, { tourId });

    const handleClose = useCallback(() => {
      onClose?.();
      if (shouldShow) {
        dispatch(productTourViewed(tourId));
      }
    }, [dispatch, onClose, shouldShow, tourId]);

    return (
      <Component
      ref={forwardedRef}
      {...rest as unknown as P}
      onClose={handleClose}
      />
    );
  });
  WithProductTour.displayName = `WithProductTour(${getDisplayName(Component)})`;

  return WithProductTour;
}
