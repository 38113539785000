import Tooltip from '@material-ui/core/Tooltip';
import React, { useCallback, useRef } from 'react';

import ControlsGenericButton from '../controls/ControlsGenericButton';
import { DrawingState } from './useDrawingState';

export interface DrawingToolbarActionButtonProps {
  state: DrawingState;
  action: (controller: DrawingState) => void;
  title: string;
  disabled: boolean;
  children?: React.ReactElement,
}

function DrawingToolbarActionButton({
  state,
  action,
  title,
  disabled,
  children,
}: DrawingToolbarActionButtonProps) {
  const onClick = useCallback(() => action(state), [action, state]);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <ControlsGenericButton disabled={disabled} onClick={onClick}>
        {children && (
          <Tooltip title={title} PopperProps={{ container: ref.current }}>
            {children}
          </Tooltip>
        )}
      </ControlsGenericButton>
    </div>
  );
}

export default React.memo(DrawingToolbarActionButton);
