import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MicOffIcon from '@material-ui/icons/MicOff';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

interface MicrophoneAccessDeniedDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
}

type MicrophoneAccessDeniedDialogProps = MicrophoneAccessDeniedDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(1.5),
  },
  message: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: createRemFromPx(42),
    margin: theme.spacing(1),
  },
}), {name: 'MicrophoneAccessDeniedDialog'});

function MicrophoneAccessDeniedDialog(props: MicrophoneAccessDeniedDialogProps) {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <MicrophoneAccessDeniedDialogContent />
    </Dialog>
  );
}

function MicrophoneAccessDeniedDialogContent(props: {}) {
  const classes = useStyles(props);
  const { t } = useStrictTranslation(['dialog']);
  return (
    <DialogContent className={classes.container}>
      <MicOffIcon className={classes.icon}/>
      <Typography variant='h4' className={classes.title}>
        {t('dialog:microphoneaccessdenied.title')}
      </Typography>
      <Typography className={classes.message}>
        {t('dialog:microphoneaccessdenied.message')}
      </Typography>
      <Button
      variant='contained'
      color='primary'
      href='https://insights.gg/help/en/articles/5251397-enabling-your-microphone'
      target='_blank'
      rel='noopener noreferrer'
      >
        {t('dialog:microphoneaccessdenied.showmehow')}
      </Button>
    </DialogContent>
  );
}

export default React.memo(MicrophoneAccessDeniedDialog);
