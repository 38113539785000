import gql from 'graphql-tag';

export const StartPublicSession_Mutation = gql`
    mutation StartPublicSessionMutation(
        $input: StartPublicSessionInput!
    ) {
        startPublicSession(
            input: $input
        ) {
            token
        }
    }
`;
