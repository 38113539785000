import { FlexSpacer, VerticalScroll } from '@insights-gaming/material-components';
import { BidirectionalIDRecord } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Pagination from 'components/pagination/Pagination';
import { usePage } from 'components/pagination/usePage';
import React, { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import * as portals from 'react-reverse-portal';
import { ID } from 'types/pigeon';

import VideoGrid, { VideoGridProps } from './VideoGrid';

interface PagedVideoGridOwnProps {
  className?: string;
  onPageIdsChange?: (pageNumber: number, ids: ID[]) => void;
  page: number;
  onPageChange?: (pageNumber: number) => void;
  paginationPortalNode?: ReturnType<typeof portals.createHtmlPortalNode>;
  videosRecord?: BidirectionalIDRecord;
}

type PagedVideoGridProps = PagedVideoGridOwnProps & Omit<VideoGridProps, 'videoIds' | 'displaySkeleton'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    overflow: 'hidden',
  },
}), {name: 'PagedVideoGrid'});

const videosPerPage = 24;

const PagedVideoGrid = forwardRef<HTMLDivElement, PagedVideoGridProps>(function PagedVideoGrid(
  props: PagedVideoGridProps,
  ref,
) {
  const classes = useStyles(props);
  const { className, onPageIdsChange, onPageChange, page, videosRecord, ...rest } = props;

  const pageIds = usePage(videosRecord, page, videosPerPage);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const scrollToTop = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }
    scrollRef.current.scroll({top: 0});
  }, []);

  useEffect(() => {
    onPageIdsChange?.(page, pageIds);
  }, [onPageIdsChange, page, pageIds]);

  const handlePageChange = useCallback((page: number) => {
    onPageChange?.(page);
    scrollToTop();
  }, [onPageChange, scrollToTop]);

  const pagination = useMemo(() => {
    if (!videosRecord || (videosRecord.ids.length <= videosPerPage && !videosRecord.forward.fetching)) {
      return null;
    }

    const pagination = (
      <Pagination
      page={page}
      onChange={handlePageChange}
      pageSize={videosPerPage}
      record={videosRecord}
      />
    );

    if (!props.paginationPortalNode) {
      return pagination;
    }

    return (
      <portals.InPortal node={props.paginationPortalNode}>
        {pagination}
      </portals.InPortal>
    );
  }, [handlePageChange, page, props.paginationPortalNode, videosRecord]);

  return (
    <FlexSpacer ref={ref} orientation='vertical' className={classNames(classes.root, className)}>
      <VerticalScroll ref={scrollRef}>
        <VideoGrid
        videoIds={pageIds}
        displaySkeleton={!videosRecord || (!videosRecord.ids.length && videosRecord.forward.fetching)}
        {...rest}
        />
      </VerticalScroll>
      {pagination}
    </FlexSpacer>
  );
});
PagedVideoGrid.displayName = 'PagedVideoGrid';

const MemoizePagedVideoGrid = React.memo(PagedVideoGrid);

export default Object.assign(
  MemoizePagedVideoGrid,
  {
    pageSize: videosPerPage,
  },
) as typeof MemoizePagedVideoGrid & { pageSize: number };
