import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback, useMemo } from 'react';

import { defaultIconOptions, overwatchHeroIconPath, sortByRoles } from '../../../../../helpers';
import UndraggableAvatar from '../../../../../material/undraggable-avatar/UndraggableAvatar';
import {
  IOverwatchMatchDataPlayer,
  IOverwatchMatchDataPlayerHero,
  OverwatchEventType,
  OverwatchMatchDataTeamfightEvent,
  OverwatchTeamColor,
} from '../../../../../types/pigeon/overwatch-match-data';

export interface IOverwatchHeroCompImagesOwnProps {
  events: OverwatchMatchDataTeamfightEvent[];
  endTime: number;
  heroes: string[];
  teamColor: OverwatchTeamColor;
  players: IOverwatchMatchDataPlayer[];
}

type Props = IOverwatchHeroCompImagesOwnProps;


const useStyles = makeStyles((theme: Theme) => createStyles({
  deathIcon: {
    stroke: theme.palette.overwatch.redTeam,
    strokeWidth: '2px',
    color: theme.palette.overwatch.redTeam,
  },
}), {name: 'OverwatchHeroCompImages'});

function OverwatchHeroCompImages(props: Props) {
  const { heroes, teamColor, events, players, endTime } = props;
  const classes = useStyles(props);

  const sortedHeroes: string[] = useMemo(() => heroes.sort(sortByRoles), [heroes]);

  const renderHeroImage = useCallback((hero: string, i: number) => {
    const player = players.find((player: IOverwatchMatchDataPlayer) => {
      return player.heroes.find((h: IOverwatchMatchDataPlayerHero) => {
        return h.name === hero && h.startTime < endTime;
      });
    });

    let playerName = 'N/A';
    if (player) {
      playerName = player.name;
    }

    const death = events.find((event: OverwatchMatchDataTeamfightEvent) => {
      return event.type === OverwatchEventType.KILL
        && event.killee.hero === hero
        && event.killee.color === teamColor;
    });

    return (
      <FlexSpacer key={hero} orientation='vertical' flexAlignItems='center' spacing={0.5} >
        <Tooltip title={playerName} placement='top'>
          <div>
            <UndraggableAvatar
            src={overwatchHeroIconPath(hero, defaultIconOptions)}
            size='sm'
            />
          </div>
        </Tooltip>
        {death && <CloseIcon className={classes.deathIcon} />}
      </FlexSpacer>
    );
  }, [events, classes, teamColor, players, endTime]);

  return (
    <FlexSpacer flexJustifyContent='center' spacing={2}>
      {sortedHeroes.map(renderHeroImage)}
    </FlexSpacer>
  );
}

export default React.memo(OverwatchHeroCompImages);
