import { DrawingTool } from '@insights-gaming/drawing-canvas';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import { EIntercomID } from 'constants/strings';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import { usePopupStateKeybindingHelper } from 'hooks/usePopupStateKeybindingHelper';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import ImagePickerMenuContent, { ImageRecord } from 'material/menus/image-picker-menu-content/ImagePickerMenuContent';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useCallback, useRef } from 'react';

import ControlsGenericButton from '../controls/ControlsGenericButton';
import { heroIconCategories } from '../drawing-tools/hero-icons';
import { DrawingState } from './useDrawingState';

export interface DrawingToolbarStickerButtonProps {
  state: DrawingState;
  disabled: boolean;
}

function DrawingToolbarStickerButton({
  state: { addSticker, deselectAll, setTool },
  disabled,
}: DrawingToolbarStickerButtonProps) {
  const { t } = useStrictTranslation(['videoplayer']);
  const isMobilePortrait = useIsMobilePortrait();
  const popupState = usePopupState({ popupId: 'stickers', variant: 'popover' });
  usePopupStateKeybindingHelper(popupState, 'popover.open');

  const stickersAddedRef = useRef(0);

  const { onClick: openPopup, ...triggerRest } = bindTrigger(popupState);
  const { onClose: closePopup, ...popoverRest } = bindPopover(popupState);
  const onClick = useCallback((e) => {
    deselectAll();
    openPopup(e);
  }, [deselectAll, openPopup]);

  const onClose = useCallback(() => {
    closePopup();
    stickersAddedRef.current = 0;
  }, [closePopup]);

  const _addSticker = useCallback((record: ImageRecord) => {
    addSticker(record.image.full, stickersAddedRef.current++, record.key);
    setTool(DrawingTool.SELECT);
  }, [addSticker, setTool]);

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <ControlsGenericButton
      disabled={disabled}
      id={EIntercomID.HERO_ICONS}
      onClick={onClick}
      {...triggerRest}
      >
        <Tooltip title={t('videoplayer:tools.heroicons')} PopperProps={{ container: ref.current }}>
          <FaceOutlinedIcon />
        </Tooltip>
      </ControlsGenericButton>
      <Popover
      {...popoverRest}
      onClose={onClose}
      disablePortal={true}
      style={{ cursor: 'default' }}
      >
        <ImagePickerMenuContent
        stickerfy={true}
        categories={heroIconCategories}
        stickersPerRow={isMobilePortrait ? 6 : undefined}
        onImageSelected={_addSticker}
        />
      </Popover>
    </div>
  );
}

export default React.memo(DrawingToolbarStickerButton);
