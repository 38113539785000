import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import MoreVert from '@material-ui/icons/MoreVert';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyLink from 'subcomponents/copy-link/CopyLink';
import { ID } from 'types/pigeon';

import InvitationTableRow from './InvitationTableRow';

interface InvitationTableRowWrapperOwnProps {
  className?: string;
  limit: React.ReactNode;
  expiry: React.ReactNode;
  link: string;
  attachedRole?: React.ReactNode;
  loading?: boolean;
  invitationId: ID;
  onDeleteClicked?: (id: ID) => void;
  creator?: string;
}

type InvitationTableRowWrapperProps = InvitationTableRowWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  mobileActionButton: {
    padding: 0,
  },
  link: {
    textAlign: 'start',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  menuList: {
    padding: 0,
  },
}), {name: 'InvitationTableRowWrapper'});

function InvitationTableRowWrapper(props: InvitationTableRowWrapperProps) {
  const classes = useStyles(props);
  const { className, limit, expiry, link, invitationId, onDeleteClicked, loading, attachedRole, creator } = props;

  const { t } = useStrictTranslation(['common', 'dialog']);
  const { canRevokeInvitation } = useAccessControl();
  const [ isDialogOpen, openDialog, closeDialog ] = useDialogState();

  const isDesktop = useIsDesktop();
  const moreOptionsPopupState = usePopupState({popupId: 'options', variant: 'popover'});

  const handleDeleteOnClick = useCallback(() => {
    onDeleteClicked?.(invitationId);
  }, [invitationId, onDeleteClicked]);

  return (
    <React.Fragment>
      <InvitationTableRow
      link={(
        <CopyLink textToCopy={link} hideLabel={true} textOnly={true}/>
      )}
      expiry={expiry}
      limit={limit}
      attachedRole={attachedRole || t('common:n/a')}
      creator={creator}
      mobileAction={
        <React.Fragment>
          <IconButton
          className={classes.mobileActionButton}
          {...bindTrigger(moreOptionsPopupState)}
          >
            <MoreVert />
          </IconButton>
          <Menu
          {...bindMenu(moreOptionsPopupState)}
          classes={{
            list: classes.menuList,
          }}
          >
            <CopyToClipboard text={link}>
              <MenuItem>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText>
                  {t('common:copylink')}
                </ListItemText>
              </MenuItem>
            </CopyToClipboard>
            <Divider />
            {canRevokeInvitation && (
              <MenuItem onClick={openDialog}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText>
                  {t('common:deletelink')}
                </ListItemText>
              </MenuItem>
            )}
          </Menu>
        </React.Fragment>
      }
      action={
        <FlexSpacer fullWidth={true} flexAlignItems='center' flexJustifyContent='center'>
          {canRevokeInvitation && (
            <Tooltip placement='top' title={t('common:delete')}>
              <IconButton
              onClick={openDialog}
              size='small'
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </FlexSpacer>
      }
      />
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <AlertDialogContent
        titleText={t('dialog:deleteinvitelink.title')}
        description={t('dialog:deleteinvitelink.description')}
        cancel={{
          text: t('common:cancel'),
          action: closeDialog,
        }}
        confirm={{
          text: t('common:delete'),
          action: handleDeleteOnClick,
          loading,
          disabled: loading,
        }}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default React.memo(InvitationTableRowWrapper);
