import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React, { ChangeEvent } from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { OwstatsNS } from '../../../locales/en/owstats';
import {
  EMatchOverviewTabType,
  VIDEO_MATCH_PLAYER_STATS_PATH,
  VIDEO_MATCH_TEAMFIGHT_STATS_PATH,
  videoMatchOverviewRoute,
} from '../../../routes';
import { OnNavigate } from '../../../types/dispatch';
import { ID } from '../../../types/pigeon';
import { GeneratedOverwatchMatch } from '../../../types/pigeon/matches';
import OverwatchPlayerStatsTable from './overwatch-player-stats-table/OverwatchPlayerStatsTable';
import OverwatchTeamfightBreakdownTable from './overwatch-teamfight-breakdown-table/OverwatchTeamfightBreakdownTable';
import OverwatchTeamfightStatsTable from './overwatch-teamfight-stats-table/OverwatchTeamfightStatsTable';

interface MatchOverviewParams {
  videoId: ID;
  tab: EMatchOverviewTabType;
}

interface OverwatchMatchOverviewOwnProps {
  gameMatch: GeneratedOverwatchMatch;
}

export interface IOverwatchMatchOverviewDispatch {
  onNavigate: OnNavigate;
}

export type IOverwatchMatchOverviewProps = OverwatchMatchOverviewOwnProps &
  IOverwatchMatchOverviewDispatch &
  RouteComponentProps<MatchOverviewParams> &
  WithTranslation;

class OverwatchMatchOverview extends React.Component<IOverwatchMatchOverviewProps> {
  public render() {
    const { tab, videoId } = this.props.match.params;
    if (!tab) {
      return <Redirect to={videoMatchOverviewRoute(videoId, EMatchOverviewTabType.PLAYERS)} />;
    }
    return (
      <Box px={2} flex={1} overflow='auto'>
        <Box mb={2}>
          <Tabs value={tab} onChange={this.handleTabOnChange}>
            {this.renderTab(EMatchOverviewTabType.PLAYERS)}
            {this.renderTab(EMatchOverviewTabType.TEAMFIGHTS)}
          </Tabs>
        </Box>
        <Switch>
          <Route path={VIDEO_MATCH_PLAYER_STATS_PATH} render={this.renderPlayerStatsTab} />
          <Route path={VIDEO_MATCH_TEAMFIGHT_STATS_PATH} render={this.renderTeamfightStatsTab} />
        </Switch>
      </Box>
    );
  }

  private renderTab = (tab: EMatchOverviewTabType) => {
    return (
      <Tab key={tab} value={tab} label={tab} />
    );
  }

  private renderPlayerStatsTab = () => {
    const { gameMatch, t } = this.props;
    return (
      <React.Fragment>
        <OverwatchPlayerStatsTable players={gameMatch.players} stats={gameMatch.stats}/>
      </React.Fragment>
    );
  }

  private renderTeamfightStatsTab = () => {
    const { gameMatch, t, match: { params: { videoId } } } = this.props;
    const { teamfightStats, calculatedStats } = gameMatch;

    return (
      <div>
        <Box mb={2}>
          <OverwatchTeamfightStatsTable tfStats={teamfightStats} calculatedStats={calculatedStats} />
        </Box>
        <Box>
          <OverwatchTeamfightBreakdownTable match={gameMatch} videoId={videoId} />
        </Box>
      </div>
    );
  }

  private handleTabOnChange = (event: ChangeEvent, newValue: EMatchOverviewTabType) => {
    const { tab, videoId } = this.props.match.params;
    if (tab === newValue) { return; }
    this.props.onNavigate(videoMatchOverviewRoute(videoId, newValue));
  }
}

export default withTranslation(OwstatsNS)(OverwatchMatchOverview);
