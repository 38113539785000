import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React from 'react';

interface CircleInformationOwnProps {
  className?: string;
  size?: number;
  info: string;
  icon: JSX.Element;
  bgColor: string;
}

type CircleInformationProps = CircleInformationOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    opacity: 0.75,
    color: theme.palette.text.primary,
    width: ({ size = 104 }: CircleInformationOwnProps) => size,
    height: ({ size = 104 }: CircleInformationOwnProps) => size,
    backgroundColor: ({ bgColor }: CircleInformationOwnProps) => bgColor,
  },
}), {name: 'CircleInformation'});

function CircleInformation(props: CircleInformationProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <div className={classNames(classes.root, className)}>
      {props.icon}
      <Typography align='center'>
        {props.info}
      </Typography>
    </div>
  );
}

export default React.memo(CircleInformation);
