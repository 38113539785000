import { AsyncButton } from '@insights-gaming/material-components';
import { Theme, WithMakeStyles } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

interface AddLabelButtonOwnProps {
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  loading?: boolean;
}

type AddLabelButtonProps = AddLabelButtonOwnProps & WithMakeStyles<typeof useStyles>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  labelButton: {
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 0.5),
    maxWidth: theme.spacing(12.5),
  },
  labelText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hideButton: {},
}), {name: 'AddLabelButton'});

function AddLabelButton(props: AddLabelButtonProps) {
  const classes = useStyles(props);
  const { className, onClick, loading} = props;

  const { t } = useStrictTranslation(['video']);

  return (
    <AsyncButton
    onClick={onClick}
    className={classNames(classes.root, !loading && classes.hideButton)}
    buttonClasses={{
      root: classes.labelButton,
      label: classes.labelText,
    }}
    startIcon={<AddIcon />}
    variant='outlined'
    size='small'
    loading={loading}
    disabled={loading}
    >
      {t('video:commentlabel.addlabel')}
    </AsyncButton>
  );
}

export default React.memo(AddLabelButton);
