import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetGlobalRoleRecords } from './global-role-selector';
import { fetchGlobalRolesAC } from './global-role-slice';

export const useFetchGlobalRoles = createBidirectionalAutoFetcher({
  actionCreators: fetchGlobalRolesAC,
  selectorFactory: makeGetGlobalRoleRecords,
  createFetchId: ({deprecated}) => `${deprecated}`,
  pageSize: 10,
});

