import { FileSelectCardActionArea, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { Path } from 'history';
import React, { ChangeEvent, forwardRef, PropsWithChildren, Ref, useCallback } from 'react';
import { Link } from 'react-router-dom';

interface LinkCardActionAreaProps {
  link?: Path;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
  classes: any;
  children: React.ReactNode;
}

function LinkCardActionArea({
  link,
  disabled,
  onClick,
  classes: { linkCardActionArea, ...classes },
  children,
}: LinkCardActionAreaProps) {
  const area = (
    <CardActionArea
    disabled={disabled}
    onClick={onClick}
    classes={classes}
    >
      {children}
    </CardActionArea>
  );

  const handleonClick = useCallback((e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
    }
    onClick?.(e);
  }, [disabled, onClick]);

  if (!link) {
    return area;
  }

  return (
    <Link to={{
      pathname: link,
      state: {
        referPath: window.location.pathname,
      },
    }}
    className={linkCardActionArea}
    onClick={handleonClick}
    >
      {area}
    </Link>
  );
}

interface VideoCardOwnProps {
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
  name: React.ReactNode;
  thumbnail?: React.ReactNode | string;
  contextMenu?: React.ReactNode;
  overlay?: React.ReactNode;
  progress?: React.ReactNode;
  content?: React.ReactNode;
  resumableUpload?: boolean;
  newVideo?: React.ReactNode;
  handleFileInput?: (event: ChangeEvent<HTMLInputElement & HTMLButtonElement>) => void;
  acceptedVideoFormat?: string;
  selected?: boolean;
  link?: Path;
}

type VideoCardProps = PropsWithChildren<VideoCardOwnProps>;

const thumbnailAspectRatio = (9 / 16 * 100) + '%';
const lineHeight = 1.5;
const lineClamp = 2;
const height = `${lineHeight * lineClamp}rem`;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },
  thumbnail: {
    position: 'relative',
    height: 0,
    paddingBottom: thumbnailAspectRatio,
  },
  media: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  content: {
    padding: theme.spacing(1, 2),
    '&$disabled': {
      opacity: .3,
    },
  },
  titleContainer: {
    '&$contextMenu': {
      paddingRight: theme.spacing(3),
    },
  },
  title: {
    flex: 1,
    fontWeight: 'bold',
    display: 'box',
    height: createRemFromPx(42), //height of two text stacked vertically
    lineHeight,
    overflow: 'hidden',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    lineClamp,
    boxOrient: 'vertical',
    position: 'relative',
  },
  contextMenuActions: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    paddingTop: '56.25%', /* 16:9 Aspect Ratio */
    marginTop: theme.spacing(1),
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all',
    },
  },
  actionArea: {
    '&$isSelected $focusHighlight': {
      opacity: theme.palette.action.hoverOpacity,
      '&:hover': {
        opacity: 0,
      },
    },
  },
  linkCardActionArea: {
    textDecoration: 'initial',
    '&$disabled': {
      cursor: 'default',
    },
  },
  contextMenu: {},
  disabled: {},
  focusHighlight: {},
  isSelected: {},
}), {name: 'VideoCard'});

const VideoCard = forwardRef<
  HTMLDivElement,
  VideoCardProps
>(function VideoCard(props: VideoCardProps, ref: Ref<HTMLDivElement>) {
  const classes = useStyles(props);
  const {
    className,
    disabled,
    onClick,
    name,
    contextMenu,
    children,
    thumbnail,
    overlay,
    newVideo,
    progress,
    content,
    resumableUpload,
    handleFileInput,
    acceptedVideoFormat,
    selected,
    link,
  } = props;

  const renderVideoContent = useCallback(() => {
      return (
        <React.Fragment>
          <div className={classes.thumbnail}>
            {thumbnail && (
              typeof thumbnail === 'string'
                ? <CardMedia image={thumbnail} className={classes.media} />
                : thumbnail
            )}
          </div>
          <CardContent className={classNames(classes.content, {[classes.disabled]: disabled})}>
            <FlexSpacer className={classNames(classes.titleContainer, {[classes.contextMenu]: contextMenu})}>
              <div className={classes.title}>
                <Typography>
                  {name}
                </Typography>
              </div>
              {newVideo}
            </FlexSpacer>
            {content}
          </CardContent>
          {progress}
        </React.Fragment>
      );
  },[
    classes.content,
    classes.contextMenu,
    classes.disabled,
    classes.media,
    classes.thumbnail,
    classes.title,
    classes.titleContainer,
    content,
    contextMenu,
    disabled,
    name,
    newVideo,
    progress,
    thumbnail,
  ]);

  return (
    <Card ref={ref} className={classNames(classes.root, className)}>
      {resumableUpload ? (
        <FileSelectCardActionArea
        disabled={disabled}
        onChange={handleFileInput}
        accept={acceptedVideoFormat}
        classes={{
          root: classNames(classes.actionArea, {[classes.isSelected]: selected}),
          focusHighlight: classes.focusHighlight,
        }}
        >
          {renderVideoContent()}
        </FileSelectCardActionArea>
      ) : (
        <LinkCardActionArea
        disabled={disabled}
        onClick={onClick}
        link={link}
        classes={{
          root: classNames(classes.actionArea, {[classes.isSelected]: selected}),
          focusHighlight: classes.focusHighlight,
          linkCardActionArea: classNames(classes.linkCardActionArea, {[classes.disabled]: disabled}),
        }}
        >
          {renderVideoContent()}
        </LinkCardActionArea>
      )}
      {contextMenu && (
        <CardActions className={classes.contextMenuActions}>
          {contextMenu}
        </CardActions>
      )}
      {overlay && (
        <div className={classes.overlay}>
          {overlay}
        </div>
      )}
      {children}
    </Card>
  );
});
VideoCard.displayName = 'VideoCard';

export default React.memo(VideoCard);
