import { FlexSpacer } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import classNames from 'classnames';
import { makeGetTeamCommentLabelsByTeamId } from 'features/dashboard/tag/dashboard-tag-selector';
import { isExistent } from 'helpers';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useMemo } from 'react';
import { SearchResultType } from 'types/graphql';
import { ID } from 'types/pigeon';

import { useFetchTeamTags } from '../dashboard/tag/useFetchTeamTags';
import { makeGetTeamSearchQueryByTeamId } from './search-selectors';
import { ESearchFilterDateOptions } from './search-slice';
import SearchLabelButtonWrapper from './SearchLabelButtonWrapper';
import { useSearchHandlers } from './useSearchHandlers';

interface SearchFilterOwnProps {
  className?: string;
  teamId: ID;
}

type SearchFilterProps = SearchFilterOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: createRemFromPx(40),
  },
  filter: {
    flexShrink: 0,
  },
  tags: {
    overflowX: 'auto',
  },
}), {name: 'SearchFilter'});

function SearchFilter(props: SearchFilterProps) {
  const classes = useStyles(props);
  const { className, teamId } = props;

  const { t } = useStrictTranslation(['search', 'common']);

  const {
    handleFilterDateOnChange,
    addTagOnClick,
    removeTagOnClick,
    clearAllSelectedTags,
  } = useSearchHandlers(teamId);

  const teamSearchQuery = useCreateSelector(makeGetTeamSearchQueryByTeamId, teamId);

  useFetchTeamTags(teamId);

  const commentLabelTags = useCreateSelector(makeGetTeamCommentLabelsByTeamId, teamId);

  const allTags = useMemo(() => {
    if (!teamSearchQuery) {
      return [];
    }
    const { videoCommentTagIds } = teamSearchQuery;
    const tagDict = Object.fromEntries(commentLabelTags.map(t => [t.id, t]));

    return videoCommentTagIds.map(
      ({ tagId }) => tagId in tagDict ? tagDict[tagId] : undefined,
    ).filter(isExistent);
  }, [commentLabelTags, teamSearchQuery]);

  return (
    <FlexSpacer className={classNames(classes.root, className)} flexAlignItems='center'>
      <FlexSpacer flexAlignItems='center' className={classes.filter}>
        <Typography variant='caption'>
          {t('search:filterby')}
        </Typography>
        <Select
        value={teamSearchQuery?.filterDatePreset || 0}
        onChange={handleFilterDateOnChange}
        >
          {Object.keys(ESearchFilterDateOptions)
            .map((k: string) => ESearchFilterDateOptions[k])
            .filter(e => typeof e === 'number').map((option: number) => (
              <MenuItem
              key={option}
              value={option}
              >
                {t(option === 0 ? 'search:everything' : 'search:lastcountdays', { count: option })}
              </MenuItem>
            ))}
        </Select>
      </FlexSpacer>
      {teamSearchQuery && teamSearchQuery.tab === SearchResultType.COMMENT && (
        <FlexSpacer flexAlignItems='center'>
          <Typography>|</Typography>
          <FlexSpacer flexAlignItems='center' className={classes.tags}>
            {allTags.map((t) => {
              return (
                <SearchLabelButtonWrapper
                key={t.id}
                addTagOnClick={addTagOnClick}
                removeTagOnClick={removeTagOnClick}
                included={teamSearchQuery?.params?.tagIds?.includes(t.id)}
                tag={t}
                />
              );
            })}
            {Object.values(teamSearchQuery.params.tagIds || []).length > 0 && (
              <Button onClick={clearAllSelectedTags} size='small'>
                {t('common:clear')}
              </Button>
            )}
          </FlexSpacer>
        </FlexSpacer>
      )}
    </FlexSpacer>
  );
}

export default React.memo(SearchFilter);

function sortTagsByFrequency(arr: TagFragment[], selectedTags: Dictionary<TagFragment>): TagFragment[] {
  const frequency: Dictionary<number> = {};
  arr.forEach(v => v.id in frequency ? frequency[v.id]++ : frequency[v.id] = 1);
  return Object.entries(frequency).sort((a, b) => {
    if (a[0] in selectedTags) {
      return -1;
    }
    return b[1] - a[1];
  })
    .map(e => arr.find(t => t.id === e[0])).filter(isExistent);
}
