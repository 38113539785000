import { RootReducer } from 'rootReducer';

import { GetUserProfileQuery_me } from '../apollo/queries/types/GetUserProfileQuery';

export function getMe(state: RootReducer): GetUserProfileQuery_me | undefined {
  return state.authReducer.me;
}

export function getFetchingProfile(state: RootReducer): boolean {
  return !!state.authReducer.fetchingProfile;
}
