import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme, WithMakeStyles } from '@insights-gaming/theme';
import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FolderIcon from '@material-ui/icons/Folder';
import classNames from 'classnames';
import { MAX_NOTIFICATIONS } from 'components/settings/notifications/constants';
import React, { forwardRef, PropsWithChildren, Ref } from 'react';

interface DirectoryCardOwnProps {
  className?: string;
  disabled?: boolean;
  numOfNotifications?: number;
  onClick?: React.MouseEventHandler;
  name: React.ReactNode;
  contextMenu?: React.ReactNode;
  checkbox?: React.ReactNode;
}

type DirectoryCardProps = DirectoryCardOwnProps & WithMakeStyles<typeof useStyles>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },
  content: {
    '&$checkbox': {
      marginLeft: theme.spacing(4),
    },
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: createRemFromPx(400),
    flex: 0.9,
  },
  contextMenuActions: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1,
    marginTop: theme.spacing(.5),
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all',
    },
  },
  overlay: {
    position: 'absolute',
    top: theme.spacing(1),
    right: 0,
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all',
    },
  },
  checkbox: {},
}), {name: 'DirectoryCard'});

const DirectoryCard = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DirectoryCardProps>
>(function DirectoryCard(
  props: PropsWithChildren<DirectoryCardProps>,
  ref: Ref<HTMLDivElement>,
) {
  const classes = useStyles(props);
  const {
    className,
    classes: _classes,
    disabled,
    onClick,
    numOfNotifications,
    name,
    contextMenu,
    children,
    checkbox,
    ...rest
  } = props;
  return (
    <Card ref={ref} {...rest} className={classNames(classes.root, className)}>
      <CardActionArea disabled={disabled} onClick={onClick}>
        <CardContent className={classNames(classes.content, {[classes.checkbox]: checkbox})}>
          <FlexSpacer flexAlignItems='center'>
            <Badge badgeContent={numOfNotifications} color='error' max={MAX_NOTIFICATIONS}>
              <FolderIcon />
            </Badge>
            <Typography className={classes.title}>
              {name}
            </Typography>
          </FlexSpacer>
        </CardContent>
      </CardActionArea>
      {contextMenu && (
        <CardActions className={classes.contextMenuActions}>
          {contextMenu}
        </CardActions>
      )}
      {checkbox && (
        <div className={classes.overlay}>
          {checkbox}
        </div>
      )}
      {children}
    </Card>
  );
});
DirectoryCard.displayName = 'DirectoryCard';

export default React.memo(DirectoryCard);
