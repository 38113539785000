import { createStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

const styles = createStyles({
  root: {
    zIndex: 2, // So the text appears on top of the indicator
    maxWidth: 'unset',
  },
});

export default withStyles(styles, {name: 'SecondaryTab'})(Tab);
