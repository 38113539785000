import CssBaseline from '@material-ui/core/CssBaseline';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ConnectedRouter } from 'connected-react-router';
import { STRIPE_PUB_KEY } from 'constants/billing';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store, { browserHistory } from 'store';

import { DEFAULT_SNACKBAR_DURATION } from './constants/numbers';
import PreApp from './containers/PreApp';
import i18ninit from './i18n';
import register from './serviceworker/register';
import InsightsThemeProvider from './theme';
import updateLogger from './updateLogger.dev';

i18ninit();

const stripePromise = loadStripe(STRIPE_PUB_KEY);

ReactDOM.render(
  <InsightsThemeProvider>
    <CssBaseline />
    <Provider store={store}>
      <ConnectedRouter history={browserHistory}>
        <SnackbarProvider maxSnack={4} autoHideDuration={DEFAULT_SNACKBAR_DURATION}>
          <DndProvider backend={HTML5Backend}>
            <Elements stripe={stripePromise}>
              <PreApp />
            </Elements>
          </DndProvider>
        </SnackbarProvider>
      </ConnectedRouter>
    </Provider>
  </InsightsThemeProvider>,
  document.getElementById('root') as HTMLElement,
);

updateLogger();

register();
