import gql from 'graphql-tag';

export const DeleteRole_Mutation = gql`
    mutation DeleteRoleMutation(
        $input: DeleteRole2Input!
    ) {
        deleteRole2(
            input: $input
        ) {
            id
        }
    }
`;
