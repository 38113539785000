import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import { ENotificationState } from 'components/settings/notifications/constants';
import useUpdateNotification from 'components/settings/notifications/useUpdateNotifications';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useCallback } from 'react';

import NotificationMenu from './NotificationMenu';

interface NotificationMenuButtonOwnProps {
  className?: string;
  notifications: NotificationFragment[];
}

type NotificationMenuButtonProps = NotificationMenuButtonOwnProps;

function NotificationMenuButton({ notifications }: NotificationMenuButtonProps) {
  const popupState = usePopupState({popupId: 'navigation-menu', variant: 'popover'});
  const { onClick: onTriggerClick, ...trigger } = bindTrigger(popupState);

  const menu = bindMenu(popupState);

  const onClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onTriggerClick(e);
  }, [onTriggerClick]);

  const { handleUpdateNotifications } = useUpdateNotification();

  const handleMenuOnClick = useCallback((notifications: NotificationFragment[], status: ENotificationState) => {;
    handleUpdateNotifications(notifications, status);
    menu.onClose();
  }, [handleUpdateNotifications, menu]);

  return (
    <React.Fragment>
      <IconButton size='small' onClick={onClick} {...trigger}>
        <MoreVertIcon />
      </IconButton>
      <NotificationMenu
      notifications={notifications}
      handleMenuOnClick={handleMenuOnClick}
      {...menu}
      />
    </React.Fragment>
  );
}

export default React.memo(NotificationMenuButton);
