import { IPaginationOptions } from './createPaginationItems';

export function isSimplePagination({
  boundaryRange,
  hideEllipsis,
  siblingRange,
  totalPages,
}: IPaginationOptions): boolean {
  const boundaryRangeSize = 2 * boundaryRange;
  const ellipsisSize = hideEllipsis ? 0 : 2;
  const siblingRangeSize = 2 * siblingRange;
  return 1 + ellipsisSize + siblingRangeSize + boundaryRangeSize >= totalPages;
}
