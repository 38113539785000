import { Theme } from '@insights-gaming/theme';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

interface HeaderOwnProps {
  className?: string;
}

type HeaderProps = HeaderOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    zIndex: 0,
  },
  toolbar: {
    justifyContent: 'flex-end',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
}), {name: 'Header'});

function Header(props: PropsWithChildren<HeaderProps>) {
  const classes = useStyles(props);
  const { className, children } = props;
  return (
    <AppBar position='static' className={classNames(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        {children}
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(Header);
