import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { Trans } from 'react-i18next';

import AlertDialogContent from '../alert-dialog-content/AlertDialogContent';

interface RemoveKeyDialogOwnProps {
  className?: string;
  member: MemberFragment;
  removeKeyOnClick: VoidFunction;
  closeRemoveKeyDialog: VoidFunction;
  removeKeyDialogOpen: boolean;
  removeKeyLoading: boolean;
}

type RemoveKeyDialogProps = RemoveKeyDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'RemoveKeyDialog'});

function RemoveKeyDialog(props: RemoveKeyDialogProps) {
  const classes = useStyles(props);
  const { className, member, removeKeyOnClick, closeRemoveKeyDialog, removeKeyDialogOpen, removeKeyLoading } = props;
  const { t } = useStrictTranslation(['common', 'dialog']);

  return (
    <Dialog
    open={removeKeyDialogOpen}
    onClose={closeRemoveKeyDialog}
    >
      <AlertDialogContent
      titleText={t('dialog:removekey.title')}
      description={(
        <Trans
        ns={'dialog'}
        i18nKey={'dialog:removekey.desc'}
        values={{name: member.user.alias}}
        >
          Remove elite seat from <strong>{member.user.alias}</strong>?
        </Trans>
      )}
      confirm={{
        text: t('common:remove'),
        action: removeKeyOnClick,
        loading: removeKeyLoading,
        disabled: removeKeyLoading,
      }}
      cancel={{
        text: t('common:cancel'),
        action: closeRemoveKeyDialog,
      }}
      />
    </Dialog>
  );
}

export default React.memo(RemoveKeyDialog);
