import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

interface AllDoneOwnProps {
  className?: string;
  onClose: VoidFunction;
}

type AllDoneProps = AllDoneOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  title: {
    position: 'absolute',
    top: 28,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  header2: {
    marginBottom: theme.spacing(3),
  },
  button: {
    width: createRemFromPx(110),
    height: createRemFromPx(36),
  },
  doneText: {
    position: 'absolute',
    bottom: 104,
    left: '50%',
    transform: 'translate(-50%, 0)',
    whiteSpace: 'nowrap',
  },
  done: {
    position: 'absolute',
    bottom: 44,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
}), {name: 'AllDone'});

function AllDone(props: AllDoneProps) {
  const classes = useStyles(props);
  const { className, onClose } = props;
  const { t } = useStrictTranslation(['dialog', 'common']);

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.title}>
        <Typography variant='h3' className={classes.header2} align='center'>
          {t('dialog:onboarding.alldone')}
        </Typography>
      </div>
      <Typography variant='h6' className={classes.doneText} align='center'>
        {t('dialog:onboarding.alldoneText')}
      </Typography>
      <Button
      color='primary'
      variant='contained'
      className={classNames(classes.button, classes.done)}
      onClick={onClose}
      >
        {t('common:done')}
      </Button>
    </div>
  );
}

export default React.memo(AllDone);
