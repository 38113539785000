import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Invitation2Fragment } from 'apollo/fragments/types/Invitation2Fragment';
import classNames from 'classnames';
import formatDuration from 'date-fns/formatDuration';
import { parse as parseDuration } from 'duration-fns/dist/parse';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { invitationCodeRoute } from 'routes';
import CopyLink from 'subcomponents/copy-link/CopyLink';

import GuidedSetupActions from './GuidedSetupActions';

interface InviteOwnProps {
  className?: string;
  createdInvitation?: Invitation2Fragment;
  onClose: VoidFunction;
  stepBackward: VoidFunction;
  stepForward: VoidFunction;
  step: number;
}

type InviteProps = InviteOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  title: {
    position: 'absolute',
    top: 68,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  header2: {
    marginBottom: theme.spacing(3),
  },
  pre: {
    whiteSpace: 'pre',
  },
  copyLink: {
    flex: 2,
    position: 'absolute',
    bottom: '50%',
    left: '50%',
    transform: 'translate(-50%, 50%)',
    width: createRemFromPx(350),
  },
  skip: {
    position: 'absolute',
    bottom: 24,
    left: 24,
    cursor: 'pointer',
  },
}), {name: 'Invite'});

function Invite(props: InviteProps) {
  const classes = useStyles(props);
  const { className, createdInvitation, onClose, step, stepBackward, stepForward } = props;
  const { t } = useStrictTranslation(['dialog']);

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.title}>
        <Typography variant='h3' className={classes.header2} align='center'>
          {t('dialog:onboarding.inviteteam')}
        </Typography>
        <Typography variant='h6' className={classes.pre} align='center'>
          {t('dialog:onboarding.inviteteamtext')}
        </Typography>
      </div>
      <div className={classes.copyLink}>
        <Typography variant='caption'>
          {t(
            'dialog:inviteteammembers.linkcaption.unlimitedusewithexpiry',
            { expiry: formatDuration(parseDuration('P7D'))},
          )}
        </Typography>
        {createdInvitation && (
          <CopyLink textToCopy={window.location.origin + invitationCodeRoute(createdInvitation.code)} />
        )}
      </div>
      <Typography variant='caption' className={classes.skip} onClick={onClose}>
        {t('dialog:onboarding.skip')}
      </Typography>
      <GuidedSetupActions
      step={step}
      stepBackward={stepBackward}
      stepForward={stepForward}
      />
    </div>
  );
}

export default React.memo(Invite);
