import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import TeamBilling from 'components/dashboard/team-settings/team-billing/TeamBilling';
import React from 'react';

interface DashboardTeamSettingsBillingOwnProps {
  className?: string;
  team: TeamFragment;
}

type DashboardTeamSettingsBillingProps = DashboardTeamSettingsBillingOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DashboardTeamSettingsBilling'});

function DashboardTeamSettingsBilling(props: DashboardTeamSettingsBillingProps) {
  const classes = useStyles(props);
  const { className, team } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <TeamBilling team={team} />
    </div>
  );
}

export default React.memo(DashboardTeamSettingsBilling);
