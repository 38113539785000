import gql from 'graphql-tag';

import { InvitationTargetFragment } from '../fragments';

export const AcceptInvitation_Mutation = gql`
    mutation AcceptInvitationMutation(
        $input: AcceptInvitationInput!
    ) {
        acceptInvitation(
            input: $input
        ) {
            target {
                ...InvitationTargetFragment
            }
        }
    }
    ${InvitationTargetFragment}
`;
