import { UUID } from '@insights-gaming/video-upload-slice';
import { createSelector } from '@reduxjs/toolkit';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { RootReducer } from 'rootReducer';

export const getUploadState = (state: RootReducer) => state.uploadReducer;

export const getStartedUploadDict = (state: RootReducer) => state.uploadReducer.startedUploads;
export const getCurrentUpload = (state: RootReducer) => state.uploadReducer.currentUpload;

export const getShouldShowUploadQueue = (state: RootReducer) => {
  return (
    !!state.uploadReducer.currentUpload ||
    state.uploadReducer.uploadQueue.length > 0 ||
    Object.keys(state.uploadReducer.uploadedVideosTemp).length > 0
  );
};

export const getVideoUuidFromProps = (state: RootReducer, props: { videoUuid: UUID }): UUID => props.videoUuid;

export const getReverseLookup = createSelector(
  [getStartedUploadDict],
  (dict) => fromPairs(toPairs(dict).map(([, upload]) => [upload.videoUuid, upload])),
);

export const makeGetUploadByVideoUuid = () => createSelector(
  [getReverseLookup, getVideoUuidFromProps],
  (lookup, uuid) => lookup[uuid],
);
