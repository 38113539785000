import { DirectoryFragment } from 'apollo/fragments';
import gql from 'graphql-tag';

export const UpdateTeamDivision_Mutation = gql`
    mutation UpdateTeamDivisionMutation(
        $input: UpdateDivisionInput!
    ) {
        updateDivision(
            input: $input
        ) {
            division {
                ...DirectoryFragment
            }
        }
    }
    ${DirectoryFragment}
`;
