import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import BackButton from 'containers/BackButton';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import DashboardTeamSearchRouting from 'features/search/dashboard-team-search.routing';
import SearchBar from 'features/search/SearchBar';
import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { dashboardRoute, teamRoute } from 'routes';

import DashboardHeader from './DashboardHeader';

interface DashboardTeamHeaderOwnProps {
  className?: string;
  team?: TeamFragment;
}

type DashboardTeamHeaderProps = DashboardTeamHeaderOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  toolbarInner: {
    flex: 1,
    gridColumnStart: 2,
    alignItems: 'center',
  },
  mobileBackButton: {
    marginLeft: theme.spacing(1),
  },
}), {name: 'DashboardTeamHeader'});

function DashboardTeamHeader(props: DashboardTeamHeaderProps) {
  const classes = useStyles(props);
  const { className, team } = props;

  const backPath = useMemo(() => team ? teamRoute(team.id) : dashboardRoute(), [team]);

  const isSearchRoute = !!useRouteMatch(DashboardTeamSearchRouting.path);

  const isMobilePortrait = useIsMobilePortrait();

  return (
    <DashboardHeader className={classNames(classes.root, className)} team={team}>
      {team && isSearchRoute && (
        <React.Fragment>
          <Box className={classNames({[classes.mobileBackButton]: isMobilePortrait})}>
            <BackButton route={backPath}/>
          </Box>
          <FlexSpacer className={classes.toolbarInner} flexJustifyContent='center'>
            <SearchBar team={team}/>
          </FlexSpacer>
        </React.Fragment>
      )}
    </DashboardHeader>
  );
}

export default React.memo(DashboardTeamHeader);
