import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import React from 'react';
import { WithTranslation,withTranslation } from 'react-i18next';

import { AttackIcon } from '../../../../assets/overwatch-maptypes';
import { buildOWL2020PlayerHeadshotPath, combineIds, defaultIconOptions, overwatchHeroIconPath } from '../../../../helpers';
import { formatDuration } from '../../../../helpers/formatters';
import { OwstatsF, OwstatsNS } from '../../../../locales/en/owstats';
import Message from '../../../../material/message/Message';
import TableHeader, { ITableColumnHeader } from '../../../../material/table/TableHeader';
import UndraggableAvatar from '../../../../material/undraggable-avatar/UndraggableAvatar';
import { OWL2020Player } from '../../../../types/pigeon';
import {
  IOverwatchMatchDataPlayer,
  IOverwatchMatchDataScoreInfo,
  IOverwatchMatchDataStat,
  IPlayerHeroDetails,
} from '../../../../types/pigeon/overwatch-match-data';

const columns: ITableColumnHeader[] = [
  {
    title: OwstatsF('playerstats.playerName'),
  },
  {
    title: OwstatsF('playerstats.finalblows'),
    align: 'right',
  },
  {
    title: OwstatsF('playerstats.deaths'),
    align: 'right',
  },
  {
    title: OwstatsF('playerstats.ults'),
    align: 'right',
  },
  {
    title: OwstatsF('playerstats.assists'),
    align: 'right',
  },
  {
    title: OwstatsF('playerstats.ultcharge'),
    align: 'right',
  },
  {
    title: OwstatsF('playerstats.ultheld'),
    align: 'right',
  },
  {
    title: OwstatsF('playerstats.heroesplayed'),
  },
];

export interface IOverwatchPlayerStatsTableOwnProps {
  stats?: IOverwatchMatchDataStat[];
  players: IOverwatchMatchDataPlayer[];
  scoreInfo?: IOverwatchMatchDataScoreInfo;
}
export type IOverwatchPlayerStatsTableProps = IOverwatchPlayerStatsTableOwnProps &
  WithStyles<typeof styles> &
  WithTranslation;

interface IMergedOverwatchData {
  player      : string;
  finalBlows  : number;
  deaths      : number;
  ults        : number;
  assists     : number;
  ultCharge   : number;
  ultHeld     : number;
  heroesPlayed: IPlayerHeroDetails[];
}

const styles = (theme: Theme) => {
  return createStyles({
    redTeam: {
      color: theme.palette.overwatch.redTeam,
    },
    blueTeam: {
      color: theme.palette.overwatch.blueTeam,
    },
    playerImage: {
      backgroundColor: 'white',
    },
  });
};

class OverwatchPlayerStatsTable extends React.PureComponent<IOverwatchPlayerStatsTableProps> {
  public memoizedPlayerStats = memoizeOne((stats: IOverwatchMatchDataStat[], players: IOverwatchMatchDataPlayer[]) => {
    const merged: IMergedOverwatchData[] = stats.map((stat: IOverwatchMatchDataStat, i: number) => {
      return {
        player      : players[i].name,
        finalBlows  : stat.totalKills,
        deaths      : stat.totalDeaths,
        ults        : stat.totalUltsUsed,
        assists     : stat.totalAssists,
        ultCharge   : stat.averageTimeToChargeUlt,
        ultHeld     : stat.averageUltHoldTime,
        heroesPlayed: stat.heroPlaytimes,
      };
    });
    return merged;
  });

  public render() {
    const { stats, players, t } = this.props;
    if (!stats) {
      return (
        <Message message={t(OwstatsF('nodata'))} />
      );
    }
    const mergedData = this.memoizedPlayerStats(stats, players);
    return (
      <Table>
        <TableHeader headers={columns} t={t} />
        <TableBody>
          {mergedData.map(this.renderBodyRow)}
        </TableBody>
      </Table>
    );
  }

  private isControlMode = (): boolean => {
    const { scoreInfo } = this.props;
    if (scoreInfo && scoreInfo.gamemode !== 'control') { return false; }
    return true;
  }

  private displayAttack = (i: number): boolean => {
    const { scoreInfo } = this.props;
    if (!scoreInfo) { return false; }
    if (i < 6 && scoreInfo.color === 'blue') {
      return true;
    } if (i >= 6 && scoreInfo.color === 'red') {
      return true;
    }
    return false;
  }

  public renderBodyRow = (md: IMergedOverwatchData, i: number) => {
    const { classes } = this.props;
    const image = buildOWL2020PlayerHeadshotPath(md.player as OWL2020Player);
    return (
      <TableRow key={combineIds(md.player, i)}>
        <TableCell
        className={classNames(i < 6 ? classes.blueTeam : classes.redTeam)}
        >
          <FlexSpacer flexAlignItems='center'>
            <UndraggableAvatar src={image} className={classes.playerImage} />
            <div><strong>{md.player}</strong></div>
            {!this.isControlMode() ? (
              this.displayAttack(i) ? <AttackIcon /> : <FontAwesomeIcon icon={faShieldAlt} size='lg' />
            ) : null}
          </FlexSpacer>
        </TableCell>
        <TableCell align='right'>{md.finalBlows}</TableCell>
        <TableCell align='right'>{md.deaths}</TableCell>
        <TableCell align='right'>{md.ults}</TableCell>
        <TableCell align='right'>{md.assists}</TableCell>
        <TableCell align='right'>{formatDuration(md.ultCharge)}</TableCell>
        <TableCell align='right'>{formatDuration(md.ultHeld)}</TableCell>
        <TableCell>
          <Box display='flex'>
            {md.heroesPlayed.map(this.renderHeroesPlayed)}
          </Box>
        </TableCell>
      </TableRow>
    );
  }

  private renderHeroesPlayed = (hp: IPlayerHeroDetails) => {
    return (
      <Box mx={0.5} key={hp.hero}>
        <UndraggableAvatar src={overwatchHeroIconPath(hp.hero, defaultIconOptions)} size='sm' />
      </Box>
    );
  }
}

export default withTranslation([OwstatsNS])(withStyles(styles)(OverwatchPlayerStatsTable));
