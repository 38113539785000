import { Theme } from '@insights-gaming/theme';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import classNames from 'classnames';
import React, { useEffect,useState } from 'react';

export interface FlashFadeIconProps {
  className?: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  overlayIcon: {
    fontSize: '3rem',
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
    padding: theme.spacing(1),
    '&$fading': {
      opacity: 0,
      transform: 'scale(2)',
      transition: 'transform .7s, opacity .7s',
    },
  },
  fading: {},
}));

function FlashFadeIcon({
  className,
  children,
}: FlashFadeIconProps) {
  const classes = useStyles();

  const [fade, setFade] = useState(true);

  useEffect(() => {
    setFade(false);
    const immediate = setImmediate(setFade, true);
    return () => {
      clearImmediate(immediate);
    };
  }, [children]);

  return (
    <div className={className}>
      <SvgIcon className={classNames(classes.overlayIcon, {[classes.fading]: fade})}>
        {children}
      </SvgIcon>
    </div>
  );
}

export default React.memo(FlashFadeIcon);
