import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { EliteSeat, EliteStorage } from 'assets';
import classNames from 'classnames';
import { CustomPlanInfo } from 'features/dashboard/billing/useAvailableMetrics';
import { mobilePortrait } from 'features/media-queries';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import { darkPaletteGradient } from 'helpers/color';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { BillingInterval, ProductKind } from 'types/graphql';

interface CustomPlanCardOwnProps {
  className?: string;
  interval: BillingInterval;
  info: CustomPlanInfo;
  handleCustomPlanSelectOnChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  customPlanQuantities?: Partial<Record<ProductKind, number>>;
}

type CustomPlanCardProps = CustomPlanCardOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    background: '#0D0C26',
    borderRadius: 8,
    width: 582,
    height: 160,
    padding: theme.spacing(3),
    boxShadow: theme.shadows[4],
    [mobilePortrait(theme)]: {
      width: '100%',
      height: 'auto',
      padding: theme.spacing(3, 2),
    },
  },
  image: {
    width: 120,
  },
  titleWrapper: {
    display: 'grid',
    gridTemplateColumns: '3fr 2.5fr',
    alignItems: 'center',
    [mobilePortrait(theme)]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  title: {
    fontSize: createRemFromPx(20),
    fontStyle: 'italic',
    fontWeight: 900,
    background: darkPaletteGradient.gradient2.toBottom,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    [mobilePortrait(theme)]: {
      fontSize: createRemFromPx(16),
      marginBottom: theme.spacing(1),
    },
  },
  pricingText: {
    fontSize: createRemFromPx(18),
    fontWeight: 700,
    [mobilePortrait(theme)]: {
      fontSize: createRemFromPx(14),
    },
  },
  select: {
    textAlign: 'center',
    backgroundColor: theme.palette.background.container,
    borderRadius: 8,
    minWidth: 72,
  },
  description: {
    [mobilePortrait(theme)]: {
      fontSize: createRemFromPx(12),
    },
  },
}), {name: 'CustomPlanCard'});

const CardImages = {
  [ProductKind.PREMIUM_SEAT]: EliteSeat,
  [ProductKind.STORAGE_LIMIT]: EliteStorage,
  [ProductKind.STORAGE_LIMIT_BYTES]: EliteStorage,
};

function CustomPlanCard(props: CustomPlanCardProps) {
  const classes = useStyles(props);
  const {
    className,
    interval,
    info,
    handleCustomPlanSelectOnChange,
    customPlanQuantities,
  } = props;

  const { t } = useStrictTranslation(['dialog']);

  const kind = info.plan.kind as Exclude<ProductKind, 'RESOURCE_PLAN'>;
  const CardImage = CardImages[kind];
  const isMobilePortrait = useIsMobilePortrait();

  return (
    <FlexSpacer className={classNames(classes.root, className)}>
      {!isMobilePortrait && (
        <FlexSpacer flexAlignItems='center' flexJustifyContent='center'>
          {CardImage && (
            <CardImage className={classes.image} />
          )}
        </FlexSpacer>
      )}

      <FlexSpacer orientation='vertical' fullWidth={true} flexJustifyContent='center'>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title}>
            {t(`dialog:availableplans.customplan.${kind}`, {
              amount: info.unit / (info.plan.kind === ProductKind.STORAGE_LIMIT_BYTES ? 2**30 : 1),
            })}
          </Typography>
          <FlexSpacer flexJustifyContent='space-between' fullWidth={true} flexAlignItems='center'>
            <Typography variant='h3' className={classes.pricingText}>
              {t(`dialog:availableplans.customplan.price.${interval}`, { cost: info.plan.cost[interval] })}
            </Typography>
            <Select
            className={classes.select}
            value={customPlanQuantities?.[info.plan.kind] ?? 1}
            name={info.plan.kind}
            onChange={handleCustomPlanSelectOnChange}
            MenuProps= {{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
            }}
            >
              {Array.from({ length: 11 }).map((_, i) => (
                <MenuItem value={i} key={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FlexSpacer>
        </div>

        <Typography className={classes.description}>
          {t(`dialog:availableplans.customplan.${kind}_desc`)}
        </Typography>
      </FlexSpacer>
    </FlexSpacer>
  );
}

export default React.memo(CustomPlanCard);
