import { resetStatsAsyncAC, updateStatsAsyncAC } from 'features/statistics/statistics-slice';
import { promisifyDispatch } from 'hooks/usePromiseSagaDispatch';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import CreateCorrectionDialogContent, { ICreateCorrectionDialogContentDispatch } from '../../material/dialogs/create-correction-dialog-content/CreateCorrectionDialogContent';
import { ResetStatsInput, UpdateStatsInput } from '../../types/graphql';

function mapDispatchToProps(dispatch: Dispatch): ICreateCorrectionDialogContentDispatch {
  const promiseSagaDispatch = promisifyDispatch(dispatch);
  return {
    onResetStats: (input: ResetStatsInput) => promiseSagaDispatch(resetStatsAsyncAC, input),
    onUpdateStats: (input: UpdateStatsInput) => promiseSagaDispatch(updateStatsAsyncAC, input),
  };
}

export default connect(null, mapDispatchToProps)(CreateCorrectionDialogContent);
