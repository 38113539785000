import { TagFragment } from 'apollo/fragments/types/TagFragment';
import { TagType } from 'types/graphql';

export const isVideoTag = (tag: TagFragment) => {
  return tag.type === TagType.VIDEO;
};

export const isCommentTag = (tag: TagFragment) => {
  return tag.type === TagType.COMMENT;
};
