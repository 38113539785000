import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

interface UnreadNotificationIndicatorOwnProps {
  className?: string;
}

type UnreadNotificationIndicatorProps = UnreadNotificationIndicatorOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    backgroundColor: theme.palette.overwatch.redTeam,
    borderRadius: '50%',
  },
}), {name: 'UnreadNotificationIndicator'});

function UnreadNotificationIndicator(props: UnreadNotificationIndicatorProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <div className={classNames(classes.root, className)} />
  );
}

export default React.memo(UnreadNotificationIndicator);
