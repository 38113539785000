import { VideoFragment } from 'apollo/fragments';
import gql from 'graphql-tag';

export const CreateRemoteVideo2_Mutation = gql`
    mutation CreateRemoteVideo2Mutation(
        $input: CreateRemoteVideo2Input!
    ) {
        createRemoteVideo2(
            input: $input
        ) {
            video {
                ...VideoFragment
            }
        }
    }
    ${VideoFragment}
`;
