import { updatePlayerAsyncAC, updatePrimaryRosterAsyncAC, updateRosterAsyncAC } from 'features/statistics/statistics-slice';
import { promisifyDispatch } from 'hooks/usePromiseSagaDispatch';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import RosterCard, {
  IRosterCardDispatch,
  IRosterCardOwnProps,
} from '../components/dashboard/team-settings/team-roster-select/roster-card/RosterCard';
import { UpdatePlayerInput, UpdatePrimaryRosterInput, UpdateRosterInput } from '../types/graphql';

function mapDispatchToProps(dispatch: Dispatch): IRosterCardDispatch {
  const promiseSagaDispatch = promisifyDispatch(dispatch);
  return {
    onUpdatePlayerName: (input: UpdatePlayerInput) => promiseSagaDispatch(updatePlayerAsyncAC, input),
    onUpdateRosterName: (input: UpdateRosterInput) => promiseSagaDispatch(updateRosterAsyncAC, input),
    onUpdatePrimaryRoster: (input: UpdatePrimaryRosterInput) => promiseSagaDispatch(
      updatePrimaryRosterAsyncAC,
      input,
    ),
  };
}

export default connect<null, IRosterCardDispatch, IRosterCardOwnProps>(
  null, mapDispatchToProps,
)(RosterCard);
