import { selectTeamAsyncAC } from 'features/dashboard/team/dashboard-team-slice';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ID } from '../../types/pigeon';

export function useSelectTeam(): (
  teamId?: ID,
) => void {
  const dispatch = useDispatch();
  return useCallback(
    (teamId?: ID) => dispatch(selectTeamAsyncAC.started(teamId)),
    [dispatch],
  );
}
