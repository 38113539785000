import { FlexSpacer } from '@insights-gaming/material-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { RosterPlayersFragment } from 'apollo/fragments/types/RosterPlayersFragment';
import React, { useMemo } from 'react';

import { GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery } from '../../../../../apollo/queries/types/GetOverwatchAdvancedQueryQuery';
import { ID } from '../../../../../types/pigeon';
import OverwatchAdvancedQuerySummaryTable from '../overwatch-advanced-query-table/OverwatchAdvancedQuerySummaryTable';
import OverwatchAdvancedQueryTeamfightBreakdownTable from '../overwatch-advanced-query-table/OverwatchAdvancedQueryTeamfightBreakdownTable';

export interface IQueryResultsOwnProps {
  result: GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery;
  rosterCache?: Dictionary<RosterPlayersFragment>;
  primaryRosterId: ID;
}

type Props = IQueryResultsOwnProps;

const useStyles = makeStyles(() => createStyles({
  queryResults: {
    overflow: 'auto',
  },
}), {name: 'QueryResults'});

function QueryResults(props: Props) {
  const { result, rosterCache, primaryRosterId } = props;
  const classes = useStyles(props);

  const primaryRoster = useMemo(() => {
    if (!rosterCache) { return undefined; }
    return Object.values(rosterCache).find(r => r.id === primaryRosterId);
  }, [primaryRosterId, rosterCache]);

  return primaryRoster ? (
    <FlexSpacer orientation='vertical' className={classes.queryResults}>
      <OverwatchAdvancedQuerySummaryTable summary={result.teamFightSummary} primaryRoster={primaryRoster} />
      <OverwatchAdvancedQueryTeamfightBreakdownTable teamfights={result.teamFights} primaryRoster={primaryRoster} />
    </FlexSpacer>
  ) : null;
}

export default React.memo(QueryResults);
