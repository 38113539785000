import { NotificationFragment, NotificationFragment_CommentNotification, NotificationFragment_CommentNotification_comment, NotificationFragment_CommentNotification_comment_VideoComment } from 'apollo/fragments/types/NotificationFragment';
import { useGetComment } from 'hooks/useGetComment';

function isVideoComment(
  comment?: NotificationFragment_CommentNotification_comment,
): comment is NotificationFragment_CommentNotification_comment_VideoComment {
  return comment?.__typename === 'VideoComment';
}

function stubCommentNotification(
  notification: NotificationFragment,
): Partial<Pick<NotificationFragment_CommentNotification, 'video' | 'comment'>> {
  if (notification.__typename === 'CommentNotification') {
    return notification;
  }

  return {};
}

export function useGetNotificationComment(
  notification: NotificationFragment,
) {
  const { video, comment: partialComment } = stubCommentNotification(notification);

  const parent = isVideoComment(partialComment) ? undefined : partialComment?.parent;
  const [comment] = useGetComment(video?.id, parent || partialComment?.id, parent && partialComment?.id);

  return comment;
}
