import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TeamSubscriptionFragment } from 'apollo/fragments/types/TeamSubscriptionFragment';
import classNames from 'classnames';
import { CustomPlanInfo, CustomPlanProductKind, CustomPlans } from 'features/dashboard/billing/useAvailableMetrics';
import { mobilePortrait } from 'features/media-queries';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo } from 'react';
import { BillingInterval, ProductKind } from 'types/graphql';

interface CustomPlanSummaryOwnProps {
  className?: string;
  interval: BillingInterval;
  customPlans: CustomPlans;
  teamSubscription?: TeamSubscriptionFragment;
  openCustomPlanDetailsDialog?: VoidFunction;
  customPlanQuantities?: Partial<Record<Exclude<ProductKind, ProductKind.RESOURCE_PLAN>, number>>;
  setCustomPlanQuantities: React.Dispatch<React.SetStateAction<object>>;
  changePlan?: boolean;
}

type CustomPlanSummaryProps = CustomPlanSummaryOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    background: '#0D0C26',
    borderRadius: 8,
    width: 294,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[4],
    [mobilePortrait(theme)]: {
      width: '100%',
      height: 'auto',
      padding: theme.spacing(3, 2),
    },
  },
  summaryContents: {
    [mobilePortrait(theme)]: {
      marginBottom: theme.spacing(2),
    },
  },
  titleWrapper: {
    margin: theme.spacing(2, 0),
    [mobilePortrait(theme)]: {
      margin: theme.spacing(1, 0),
    },
  },
  title: {
    fontSize: createRemFromPx(16),
    fontWeight: 700,
  },
  planTitle: {
    fontSize: createRemFromPx(16),
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  subTotal: {
    fontSize: createRemFromPx(16),
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}), {name: 'CustomPlanSummary'});

function CustomPlanSummary(props: CustomPlanSummaryProps) {
  const classes = useStyles(props);
  const {
    className,
    interval,
    customPlans,
    teamSubscription,
    openCustomPlanDetailsDialog,
    customPlanQuantities,
    setCustomPlanQuantities,
    changePlan,
  } = props;

  const { t } = useStrictTranslation(['dialog']);

  const currentQuantities = useMemo(() => {
    if (!teamSubscription) {
      return {};
    }

    return Object.fromEntries(
      teamSubscription.products
        .map(({ kind, quantity }) => [kind, quantity]),
    );
  }, [teamSubscription]);

  const fixedCustomPlanQuantities = useMemo(() => {
    let quantities = { ...customPlanQuantities };

    if (!(ProductKind.STORAGE_LIMIT_BYTES in quantities)) {
      quantities[ProductKind.STORAGE_LIMIT_BYTES] = 1;
    }

    if (!(ProductKind.PREMIUM_SEAT in quantities)) {
      quantities[ProductKind.PREMIUM_SEAT] = 1;
    }

    return quantities;
  }, [customPlanQuantities]);

  const isSamePlan = useMemo(() => {
    if (!teamSubscription) {
      return false;
    }

    if (teamSubscription?.interval !== interval) {
      return false;
    }

    if (Object.keys(currentQuantities).length !== Object.keys(fixedCustomPlanQuantities).length) {
      return false;
    }

    return Array.from(new Set([...Object.keys(currentQuantities), ...Object.keys(fixedCustomPlanQuantities)]))
      .every((kind) => (fixedCustomPlanQuantities[kind] ?? 1) === (currentQuantities[kind] ?? 1));
  }, [currentQuantities, fixedCustomPlanQuantities, interval, teamSubscription]);

  const subTotal = useMemo(() => Object.entries(customPlans).reduce((acc, [kind, { plan: { cost } }]) => {
    return acc + cost[interval] * (customPlanQuantities?.[kind] ?? 1);
  }, 0), [customPlanQuantities, customPlans, interval]);

  const handleButtonOnClick = useCallback(() => {
    setCustomPlanQuantities(
      Object.fromEntries(
        Object.keys(customPlans).map((kind) => [kind, customPlanQuantities?.[kind] ?? 1]),
      ),
    );
    openCustomPlanDetailsDialog?.();
  }, [customPlanQuantities, customPlans, openCustomPlanDetailsDialog, setCustomPlanQuantities]);

  return (
    <FlexSpacer
    className={classNames(classes.root, className)}
    orientation='vertical'
    flexJustifyContent='space-between'
    >
      <FlexSpacer className={classes.summaryContents} orientation='vertical'>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title}>
            {t('dialog:availableplans.customplan.summary.title')}
          </Typography>
        </div>

        {(Object.entries(customPlans) as Array<[CustomPlanProductKind, CustomPlanInfo]>).map(
          ([kind, { unit, plan }]) => {
            const count = (
              unit / (kind === ProductKind.STORAGE_LIMIT_BYTES ? 2 ** 30 : 1) * (customPlanQuantities?.[kind] ?? 1)
            );
            return (
              <FlexSpacer key={kind} fullWidth={true} flexJustifyContent='space-between'>
                <Typography className={classes.planTitle}>
                  {t(`dialog:availableplans.customplan.summary.${kind}_${count === 1 ? 'one' : 'other'}` as any, {
                    count,
                  })}
                </Typography>
                <Typography variant='body1'>
                  {t('dialog:availableplans.customplan.summary.dollaramount', {
                    amount: plan.cost[interval] * (customPlanQuantities?.[kind] ?? 1),
                  })}
                </Typography>
              </FlexSpacer>
            );
          },
        )}

        <Divider className={classes.divider} />

        <FlexSpacer fullWidth={true} flexJustifyContent='space-between'>
          <Typography className={classes.subTotal}>
            {t('dialog:availableplans.customplan.summary.subtotal')}
          </Typography>
          <Typography variant='body1'>
            {t(`dialog:availableplans.customplan.price.${interval}`, { cost : subTotal })}
          </Typography>
        </FlexSpacer>
      </FlexSpacer>

      <FlexSpacer flexJustifyContent='center'>
        <Button variant='contained' color='primary' onClick={handleButtonOnClick} disabled={!subTotal || isSamePlan}>
          {t(`dialog:availableplans.${changePlan ? 'changeplan' : 'customplan.summary.proceed'}`)}
        </Button>
      </FlexSpacer>
    </FlexSpacer>
  );
}

export default React.memo(CustomPlanSummary);
