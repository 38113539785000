import { makeMatch } from 'factories/matchFactory';
import update from 'immutability-helper';
import { IOWLUserMetadata } from 'types/pigeon/owl-match';
import { Failure, isType, Success } from 'typescript-fsa';

import {
  fetchOWLStatisticsAsyncAC,
  fetchOWLVideosAsyncAC,
  PublicStatisticsAction,
} from '../actions/public-statistics-actions';
import {
  GetOWLStatisticsQuery_owlStatistics,
  GetOWLStatisticsQueryVariables,
} from '../apollo/queries/types/GetOWLStatisticsQuery';
import { GetOWLVideosQuery_queryOWLVideos, GetOWLVideosQueryVariables } from '../apollo/queries/types/GetOWLVideosQuery';
import { createOWLStatisticsCacheKey, createVideoStatsCacheKey } from '../helpers';
import { COMPLETE, FAILURE, IPublicStatisticsStoreState, myUpdate, PagedID, PagedSet, PENDING, ReduxCache } from '../types';
import { exid, GeneratedOverwatchMatch, ID } from '../types/pigeon';

const initialState = (): IPublicStatisticsStoreState => {
  return {
    owlVideoIdsSet: new Map(),
    owlVideoCache: new ReduxCache(),
    owlVideoMatches: new Map(),
    owlVideoResults: new Map(),
    pendingOwlStatistics: new Set(),
    owlStatisticsCache: new ReduxCache(),
    owlStatisticsErrors: new Map(),
  };
};

export function publicStatisticsReducer(
  state : IPublicStatisticsStoreState = initialState(),
  action: PublicStatisticsAction,
): IPublicStatisticsStoreState {
  if (isType(action, fetchOWLVideosAsyncAC.started)) {
    return fetchOWLVideosRequest(state, action.payload);
  }
  if (isType(action, fetchOWLVideosAsyncAC.done)) {
    return fetchOWLVideosSuccess(state, action.payload);
  }
  if (isType(action, fetchOWLVideosAsyncAC.failed)) {
    return fetchOWLVideosFailure(state, action.payload);
  }
  if (isType(action, fetchOWLStatisticsAsyncAC.started)) {
    return fetchOWLStatisticsRequest(state, action.payload);
  }
  if (isType(action, fetchOWLStatisticsAsyncAC.done)) {
    return fetchOWLStatisticsSuccess(state, action.payload);
  }
  if (isType(action, fetchOWLStatisticsAsyncAC.failed)) {
    return fetchOWLStatisticsFailure(state, action.payload);
  }
  return state;
}

function fetchOWLVideosRequest(
  state : IPublicStatisticsStoreState,
  params: GetOWLVideosQueryVariables,
): IPublicStatisticsStoreState {
  const { year, week } = params;
  const cacheKey = createOWLStatisticsCacheKey(year, week);
  let p: PagedID = state.owlVideoIdsSet.get(cacheKey) || new PagedSet();
  p = update(p, {
    status: {$set: PENDING},
    reasonForFailure: {$set: undefined},
  });
  return update(state, {
    owlVideoIdsSet: {
      $add: [[cacheKey, p]],
    },
  });
}

function fetchOWLVideosSuccess(
  state: IPublicStatisticsStoreState,
  success: Success<GetOWLVideosQueryVariables, GetOWLVideosQuery_queryOWLVideos>,
): IPublicStatisticsStoreState {
  const { params, result } = success;
  const { year, week } = params;
  const cacheKey = createOWLStatisticsCacheKey(year, week);
  let p: PagedID = state.owlVideoIdsSet.get(cacheKey) || new PagedSet();
  p = update(p, {
    status: {$set: COMPLETE},
    reasonForFailure: {$set: undefined},
    _innerSet: {$add: result.videos.map(exid)},
    pageInfo: {$set: result.pageInfo},
  });

  return myUpdate(state, {
    owlVideoIdsSet: {
      $add: [[cacheKey, p]],
    },
    owlVideoCache: {$customAdd: result.videos.map(v => ([v.id, v]))},
    owlVideoMatches: {$add: result.videos.map((v): [ID, GeneratedOverwatchMatch[]] => {
      const matches = v.latestAnalysis?.result?.matches || [];
      return [v.id, matches.map(makeMatch).filter((m): m is GeneratedOverwatchMatch => m.isGeneratedOverwatchMatch())];
    })},
    owlVideoResults: {$add: result.videos.map((v): [ID, IOWLUserMetadata] => {
      const result = v.userMetadata.find(m => m.name === '_results');
      return [v.id, result ? JSON.parse(result.value) : undefined];
    })},
  });
}

function fetchOWLVideosFailure(
  state: IPublicStatisticsStoreState,
  failure: Failure<GetOWLVideosQueryVariables, Error>,
): IPublicStatisticsStoreState {
  const { params, error } = failure;
  const { year, week } = params;
  const cacheKey = createOWLStatisticsCacheKey(year, week);

  let p: PagedID = state.owlVideoIdsSet.get(cacheKey) || new PagedSet();
  p = update(p, {
    status: {$set: FAILURE},
    reasonForFailure: {$set: error},
  });

  return update(state, {
    owlVideoIdsSet: {$add: [[cacheKey, p]]},
  });
}

function fetchOWLStatisticsRequest(
  state: IPublicStatisticsStoreState,
  payload: GetOWLStatisticsQueryVariables,
): IPublicStatisticsStoreState {
  const { videoIds } = payload;
  const cacheKey = createVideoStatsCacheKey(videoIds);
  return update(state, {
    pendingOwlStatistics: {$add: [cacheKey]},
    owlStatisticsErrors: {$remove: [cacheKey]},
  });
}

function fetchOWLStatisticsSuccess(
  state: IPublicStatisticsStoreState,
  success: Success<GetOWLStatisticsQueryVariables, GetOWLStatisticsQuery_owlStatistics>,
): IPublicStatisticsStoreState {
  const { params, result } = success;
  const { videoIds } = params;
  const cacheKey = createVideoStatsCacheKey(videoIds);
  return myUpdate(state, {
    pendingOwlStatistics: {$remove: [cacheKey]},
    owlStatisticsCache: {$customAdd: [[cacheKey, result]]},
    owlStatisticsErrors: {$remove: [cacheKey]},
  });
}

function fetchOWLStatisticsFailure(
  state: IPublicStatisticsStoreState,
  failure: Failure<GetOWLStatisticsQueryVariables, Error>,
): IPublicStatisticsStoreState {
  const { params, error } = failure;
  const { videoIds } = params;
  const cacheKey = createVideoStatsCacheKey(videoIds);
  return update(state, {
    pendingOwlStatistics: {$remove: [cacheKey]},
    owlStatisticsErrors: {$add: [[cacheKey, error]]},
  });
}
