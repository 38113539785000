import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import { useIsDesktop } from 'features/media-queries/hooks';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React from 'react';

interface InvitationTableRowOwnProps {
  className?: string;
  limit?: React.ReactNode;
  attachedRole?: React.ReactNode;
  expiry?: React.ReactNode;
  link?: React.ReactNode;
  action?: React.ReactNode;
  creator?: React.ReactNode;
  mobileAction?: React.ReactNode;
}

type InvitationTableRowProps = InvitationTableRowOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'InvitationTableRow'});

function InvitationTableRow(props: InvitationTableRowProps) {
  const classes = useStyles(props);
  const { className, limit, expiry, link, action, attachedRole, creator, mobileAction } = props;
  const isDesktop = useIsDesktop();
  return (
    <TableRow>
      {isDesktop && <FixedTableCell width={150}>{link}</FixedTableCell>}
      <FixedTableCell width={90} style={{textOverflow: 'unset'}}>{attachedRole}</FixedTableCell>
      <FixedTableCell width={100}>{limit}</FixedTableCell>
      <FixedTableCell width={110}>{expiry}</FixedTableCell>
      {isDesktop ? (
        <React.Fragment>
          <FixedTableCell width={70}>{creator}</FixedTableCell>
          <FixedTableCell width={70} align='center'>{action}</FixedTableCell>
        </React.Fragment>
      ) : (
        <FixedTableCell width={40} style={{ textOverflow: 'unset' }}>{mobileAction}</FixedTableCell>
      )}
    </TableRow>
  );
}

export default React.memo(InvitationTableRow);
