import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { ID, ITag } from 'types/pigeon';

import { deleteTagAC } from '../dashboard-tag-slice';

interface DeleteTagDialogOwnProps {
  teamId: ID;
  tag: ITag;
  onClose?: VoidFunction;
  isLabel?: boolean;
}

type DeleteTagDialogProps = DeleteTagDialogOwnProps & Pick<DialogProps, 'open'>;

function DeleteTagDialog(props: DeleteTagDialogProps) {
  const { tag, open, onClose, teamId, isLabel } = props;

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { t } = useStrictTranslation(['common', 'dialog']);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleDelete = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await promiseSagaDispatch(deleteTagAC, {id: tag.id, teamId});
      onClose?.();
      enqueueSnackbar(t(isLabel ? 'dialog:deletelabel.success' : 'dialog:deletetags.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t(isLabel ? 'dialog:deletelabel.failure' : 'dialog:deletetags.failure'), {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, loading, onClose, promiseSagaDispatch, t, tag.id, teamId, isLabel]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs'>
      <AlertDialogContent
      titleText={t(isLabel ? 'dialog:deletelabel.title' : 'dialog:deletetags.title')}
      description={t(isLabel ? 'dialog:deletelabel.description' : 'dialog:deletetags.description')}
      cancel={{
        text: t('common:cancel'),
        action: handleClose,
      }}
      confirm={{
        text: t(isLabel ? 'dialog:deletelabel.confirm' : 'dialog:deletetags.confirm'),
        loading,
        disabled: loading,
        action: handleDelete,
        negative: true,
      }}
      />
    </Dialog>
  );
}

export default React.memo(DeleteTagDialog);
