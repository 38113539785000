import { FlexSpacer } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import CardActionArea from '@material-ui/core/CardActionArea';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import BrushIcon from '@material-ui/icons/Brush';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteCommentAC } from 'actions/comment-actions';
import { CommentFragment, CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import classNames from 'classnames';
import { makeGetUnreadVideoCommentNotificationsByVideoId } from 'components/settings/notifications/notifications-selector';
import useUpdateNotification from 'components/settings/notifications/useUpdateNotifications';
import { COMMENT_ELEM_ID_PREFIX } from 'constants/strings';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { getLiveSessionIsHost, getLiveSessionStateType } from 'features/live-session/live-session-selector';
import { desktop } from 'features/media-queries';
import { VirtualizedCommentsContext } from 'features/video-replay/comment-panel/virtualized-comments/VirtualizedCommentsContext';
import { DrawingToolContext } from 'features/video-replay/DrawingToolContext';
import { formatDuration } from 'helpers/formatters';
import { usePopupStateKeybindingHelper } from 'hooks/usePopupStateKeybindingHelper';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import React, { Ref, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { JOIN_SESSION_PATH } from 'routes';
import { getMe } from 'selectors/getMe';
import { SerializedCanvas, Video } from 'types/pigeon';

import VideoCommentLayout from '../VideoCommentLayout';
import { AudioCommentContentRef } from './audio-comment/AudioCommentContent';
import CommentContent from './comment-content/CommentContent';
import { CommentHelper } from './comment-helpers';
import AppliedCommentLabels from './comment-label/applied-tag-labels/AppliedCommentLabels';
import CommentTagsFetcher from './comment-label/CommentTagsFetcher';
import ReplyArea from './comment-reply/ReplyArea';
import TimestampButton from './timestamp-button/TimestampButton';
import { makeGetHaveFetchedTagsByCommentId } from './video-comment-selector';

interface VideoCommentOwnProps {
  className?: string;
  video: Video;
  comment: CommentFragment;
  time?    : number;
  timeEnd?  : number | null;
  annotation?: SerializedCanvas;
  members: MemberFragment[];
  onViewAnnotation?: (e: React.SyntheticEvent, t: number, annotation?: SerializedCanvas) => void;
  onChangeCurrentComment?: (c: CommentFragment) => void;
  handleEditDrawingOnClick?: (comment: CommentFragment_VideoComment) => void;
}

type VideoCommentProps = VideoCommentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    [desktop(theme)]: {
      '&:hover $displayButton': {
        display: 'block',
      },
    },
  },
  annotationLabel: {
    display: 'flex',
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: theme.shape.borderRadius,
    whiteSpace: 'nowrap',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  commentMenu: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(1),
    zIndex: 1,
  },
  displayButton: {},
  hideButton: {
    [desktop(theme)]: {
      display: 'none',
      padding: 0,
    },
  },
  rangedTimestamp: {
    borderColor: theme.palette.tiers.gold,
  },
}), {name: 'VideoComment'});

export interface VideoCommentRef {
  readonly id: string;
  scrollIntoView: HTMLElement['scrollIntoView'];
  playAudioRecording?: (callback?: (paused: boolean) => void) => ((() => void ) | void);
}

function VideoComment(props: VideoCommentProps, ref: React.Ref<VideoCommentRef>) {
  const classes = useStyles(props);
  const {
    className,
    video,
    comment,
    onChangeCurrentComment,
    onViewAnnotation,
    time,
    timeEnd,
    annotation,
    members,
    handleEditDrawingOnClick,
  } = props;
  const { deleted } = comment;
  const me = useSelector(getMe);
  const { t } = useStrictTranslation(['video', 'notification']);
  const [ editing, setEditing ] = useState(false);
  const commentMenuPopupState = usePopupState({popupId: 'comment-menu', variant: 'popover'});
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    canCreateCommentReply,
    canUpdateVideoComment,
    canUpdateCommentReply,
    canDeleteVideoComment,
    canDeleteCommentReply,
    canModerateComment,
  } = useAccessControl();

  const {
    resetCanvas,
    resetTool,
    viewedComment,
  } = useContext(DrawingToolContext);

  const {
    resizeComment,
  } = useContext(VirtualizedCommentsContext);

  usePopupStateKeybindingHelper(commentMenuPopupState, 'menu.open');

  const isHost = useSelector(getLiveSessionIsHost);

  useEffect(() => {
    return () => resizeComment(comment);
  }, [resizeComment, comment]);

  const { state: { comment: commentFromLocation } = {} } = useLocation<{comment?: CommentFragment}>();

  const canReply = !!me && (video.openComments || canCreateCommentReply);
  const isReply = CommentHelper.isCommentReply(comment);
  const canUpdateComment = isReply ? canUpdateCommentReply : canUpdateVideoComment;
  const canDeleteComment = isReply ? canDeleteCommentReply : canDeleteVideoComment;

  const videoCommentNotifications = useCreateSelector(
    makeGetUnreadVideoCommentNotificationsByVideoId,
    {videoId: video.id},
  );

  const fetchedTags = useCreateSelector(
    makeGetHaveFetchedTagsByCommentId,
    {commentId: comment.id},
  );

  const commentNotification = useMemo(
    () => videoCommentNotifications.find(n => n.__typename === 'CommentNotification' && n.comment.id === comment.id),
    [comment.id, videoCommentNotifications],
  );

  const { handleUpdateNotifications } = useUpdateNotification();

  const markNotificationAsRead = useCallback(() => {
    if (!commentNotification) { return; }
    handleUpdateNotifications([commentNotification]);
  }, [commentNotification, handleUpdateNotifications]);

  const handleCommentOnClick = useCallback((e: React.SyntheticEvent) => {
    markNotificationAsRead();
    if (onViewAnnotation && time !== undefined && !editing) {
      onViewAnnotation(e, time, annotation);
    }
    onChangeCurrentComment?.(comment);
  }, [
    onViewAnnotation,
    onChangeCurrentComment,
    markNotificationAsRead,
    editing,
    comment,
    time,
    annotation,
  ]);

  const handleToggleEditing = useCallback(() => {
    if (commentMenuPopupState.isOpen) {
      commentMenuPopupState.close();
    }
    resizeComment(comment);
    setEditing(editing => !editing);
  }, [commentMenuPopupState, resizeComment, comment]);

  const deleteComment = useCallback(async () => {
    try {
      await promiseSagaDispatch(deleteCommentAC, {
        commentId: comment.id,
        videoId: video.id,
        parentId: CommentHelper.tryGetParentId(comment),
      });
      enqueueSnackbar(t('video:replay.commentdeletesuccess'), {variant: 'success'});
      if (commentMenuPopupState.isOpen) {
        commentMenuPopupState.close();
      }
      if (viewedComment?.comment.id === comment.id && annotation) {
        resetTool();
        setTimeout(() => resetCanvas(), 0);
      }
      resizeComment(comment);
  } catch (error) {
      enqueueSnackbar(t('video:replay.commentdeletefail'), {variant: 'error'});
    }
  }, [
    annotation,
    comment,
    commentMenuPopupState,
    video,
    viewedComment,
    enqueueSnackbar,
    promiseSagaDispatch,
    resetTool,
    resizeComment,
    t,
    resetCanvas,
  ]);

  const replyFromNotification = useMemo(() => {
    return commentFromLocation?.__typename === 'CommentReply' ? commentFromLocation : undefined;
  }, [commentFromLocation]);

  const editDrawingOnClick = useCallback(() => {
    if (commentMenuPopupState.isOpen) {
      commentMenuPopupState.close();
    }
    if (comment.__typename === 'VideoComment') {
      handleEditDrawingOnClick?.(comment);
    }
  }, [comment, commentMenuPopupState, handleEditDrawingOnClick]);

  const isCommentOwner = comment.user.id === me?.id;

  const liveSessionStateType = useSelector(getLiveSessionStateType);

  const isSessionRoute = !!useRouteMatch(JOIN_SESSION_PATH);

  const commentsDisabled = useMemo(() => {
    if (liveSessionStateType === 'joined' && isSessionRoute && !isHost) {
      return true;
    }
    return false;
  }, [isHost, isSessionRoute, liveSessionStateType]);

  const divElemRef = useRef<HTMLDivElement>(null);
  const [player, setPlayer] = useState<AudioCommentContentRef | null>(null);

  useImperativeHandle(ref, () => ({
    get id(): string {
      return divElemRef.current?.id || '';
    },
    scrollIntoView: (...args: Parameters<HTMLElement['scrollIntoView']>) => divElemRef.current?.scrollIntoView(...args),
    playAudioRecording: player ? (callback?: (paused: boolean) => void) => player.play(callback) : undefined,
  }), [player]);

  return (
    <div
    id={COMMENT_ELEM_ID_PREFIX + comment.id}
    ref={divElemRef}
    className={classNames(classes.root, className)}
    >
      <VideoCommentLayout
      timestampArea={time !== undefined && (
        <React.Fragment>
          <TimestampButton
          time={time}
          annotation={!deleted ? annotation : undefined}
          handleViewAnnotation={handleCommentOnClick}
          className={timeEnd ? classes.rangedTimestamp : undefined}
          >
            <FlexSpacer spacing={0.5}>
              <span>
                {formatDuration(time)}
              </span>
              {timeEnd && (
                <React.Fragment>
                  <span>-</span><span>{formatDuration(timeEnd)}</span>
                </React.Fragment>
              )}
            </FlexSpacer>
          </TimestampButton>
          {annotation && !deleted && (
            <div className={classes.annotationLabel}>
              <strong>{t('video:replay.annotated')}</strong>
            </div>
          )}
          {CommentHelper.isVideoComment(comment) && (
            <React.Fragment>
              {!fetchedTags && <CommentTagsFetcher commentId={comment.id} videoId={video.id} />}
              <AppliedCommentLabels
              className={classNames(
                classes.displayButton,
                classes.hideButton,
              )}
              comment={comment}
              video={video}
              isAnnotated={!!annotation}
              isRangedTimestamp={!!timeEnd}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      actions={
      (canUpdateComment || canDeleteComment) && (isCommentOwner || canModerateComment) && !comment.deleted && (
        <div className={classes.commentMenu}>
          <IconButton size='small' {...bindTrigger(commentMenuPopupState)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
          {...bindMenu(commentMenuPopupState)}
          >
            {canUpdateComment && !(comment.__typename === 'VideoComment' && comment.recordingUrl) && (
              <MenuItem onClick={handleToggleEditing}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary={t('video:replay.editcomment')} />
              </MenuItem>
            )}
            {canUpdateComment && comment.__typename === 'VideoComment' && (
              <MenuItem onClick={editDrawingOnClick}>
                <ListItemIcon>
                  <BrushIcon />
                </ListItemIcon>
                <ListItemText
                primary={t(annotation && !deleted ? 'video:replay.editdrawing' : 'video:replay.adddrawing')}
                />
              </MenuItem>
            )}
            {canDeleteComment && (
              <MenuItem onClick={deleteComment}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={t('video:replay.delete')} />
              </MenuItem>
            )}
          </Menu>
        </div>
      )}
      cardActionArea={editing ? (
        <CommentContent
        ref={setPlayer as Ref<AudioCommentContentRef>}
        comment={comment}
        commentNotification={commentNotification}
        editing={editing}
        videoId={video.id}
        members={members}
        handleToggleEditing={handleToggleEditing}
        />
      ) : (
        <CardActionArea onClick={handleCommentOnClick} disabled={commentsDisabled}>
          <CommentContent
          ref={setPlayer as Ref<AudioCommentContentRef>}
          comment={comment}
          commentNotification={commentNotification}
          editing={editing}
          videoId={video.id}
          members={members}
          handleToggleEditing={handleToggleEditing}
          />
        </CardActionArea>
      )}
      replyArea={(
        <ReplyArea
        canReply={(!comment.deleted && canReply)}
        comment={comment}
        video={video}
        members={members}
        replyFromNotification={replyFromNotification}
        />
      )}
      />
    </div>
  );
}

export default React.memo(React.forwardRef(VideoComment));
