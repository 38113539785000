import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { createControlHostTransferAction } from 'factories/kmsessionEventFactory';
import { liveSessionSendActionAC } from 'features/live-session/live-session-slice';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ISessionProfile } from 'types/pigeon/kmsession';

import { DialogNS } from '../../../locales/en/dialog';
import AlertDialogContent from '../alert-dialog-content/AlertDialogContent';

interface ChatMemberListHostTransferDialogOwnProps {
  onClose?: VoidFunction;
  member: ISessionProfile;
}

type ChatMemberListHostTransferDialogProps = ChatMemberListHostTransferDialogOwnProps & Pick<DialogProps, 'open'>;

function ChatMemberListHostTransferDialog(props: ChatMemberListHostTransferDialogProps) {
  const { open, onClose, member } = props;
  const { t } = useStrictTranslation(['dialog']);
  const dispatch = useDispatch();

  const [ loading, setLoading ] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const titleText = useMemo(() => {
    return (
      <Trans
      i18nKey='dialog:hosttransfer.chatmemberlist.title'
      ns={DialogNS}
      values={{ target: member.alias}}
      >
        <strong>target</strong>
      </Trans>
    );
  }, [member.alias]);

  const confirmTransfer = useCallback(() => {
    if (loading) { return; }
    setLoading(true);
    try {
      dispatch(liveSessionSendActionAC.started(createControlHostTransferAction(member.id)));
      setLoading(false);
      onClose?.();
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, {variant: 'error'});
    }
  }, [dispatch, enqueueSnackbar, loading, member.id, onClose]);

  return (
    <Dialog
    fullWidth={true}
    open={open}
    onClose={onClose}
    maxWidth='xs'
    >
      <AlertDialogContent
      titleText={titleText}
      description={t('dialog:hosttransfer.chatmemberlist.description')}
      cancel={{
        text: t('dialog:hosttransfer.chatmemberlist.cancel'),
        action: onClose,
      }}
      confirm={{
        text: t('dialog:hosttransfer.chatmemberlist.confirm'),
        loading,
        disabled: loading,
        action: confirmTransfer,
      }}
      />
    </Dialog>
  );
}

export default React.memo(ChatMemberListHostTransferDialog);
