import {
  GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery,
  GetOverwatchAdvancedQueryQueryVariables,
} from 'apollo/queries/types/GetOverwatchAdvancedQueryQuery';
import { emptyQuery, getLatestQuery } from 'components/dashboard/team-statistics/overwatch-custom-queries/create-query/QueryFormHelper';
import addAsyncCases from 'helpers/addAsyncCases';
import { createSlice } from 'helpers/createSlice';
import { IOverwatchAdvancedQueryStoreState, TeamQuery } from 'types';
import actionCreatorFactory, { Action, Failure, Success } from 'typescript-fsa';

const name = 'overwatch-advanced-query';

const actionCreator = actionCreatorFactory(name);

export const fetchOverwatchAdvancedQueryAsyncAC = actionCreator.async<
  GetOverwatchAdvancedQueryQueryVariables,
  GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery,
  Error
>('FETCH_OVERWATCH_ADVANCED_QUERY');
type FetchOWLStatistics =
  | Action<GetOverwatchAdvancedQueryQueryVariables>
  | Action<Success<GetOverwatchAdvancedQueryQueryVariables, GetOverwatchAdvancedQueryQuery_overwatchAdvancedQuery>>
  | Action<Failure<GetOverwatchAdvancedQueryQueryVariables, Error>>;

export type OverwatchAdvancedQueryActions = FetchOWLStatistics;

const createDefaultTeamQuery = (): TeamQuery => ({loading: false, latestQuery: emptyQuery});

const overwatchAdvancedQuerySlice = createSlice({
  name,
  initialState: {} as IOverwatchAdvancedQueryStoreState,
  reducers: {},
  extraReducers: builder => {
    addAsyncCases(builder, fetchOverwatchAdvancedQueryAsyncAC, {
      started: (state, { payload: { teamId } }) => {
        (state[teamId] = state[teamId] || createDefaultTeamQuery()).loading = true;
      },
      done: (state, { payload: { params, result } }) => {
        state[params.teamId] = state[params.teamId] || createDefaultTeamQuery();
        state[params.teamId] = {
          loading: false,
          result,
          latestQuery: getLatestQuery(params),
        };
      },
      failed: (state, { payload: { params, error } }) => {
        state[params.teamId] = state[params.teamId] || createDefaultTeamQuery();
        state[params.teamId] = { loading: false, error, latestQuery: emptyQuery };
      },
    });
  },
});

export const overwatchAdvancedQueryReducer = overwatchAdvancedQuerySlice.reducer;
