import { connect } from 'react-redux';
import { RootReducer } from 'rootReducer';

import PreApp, { IPreAppMappedProps } from '../PreApp';
import { getMe } from '../selectors/getMe';

function mapStateToProps(state: RootReducer): IPreAppMappedProps {
  return {
    authCheckFailed: state.errorReducer.authCheckFailed,
    me: getMe(state),
    session: state.kmsessionReducer.session,
  };
}

export default connect(mapStateToProps)(PreApp);
