import { CommentFragment } from 'apollo/fragments/types/CommentFragment';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import DashboardRouting from 'features/dashboard/dashboard.routing';
import DashboardTeamSettingsRouting from 'features/dashboard/team/dashboard-team-settings.routing';
import { useFetchVideoIfNecessary } from 'features/dashboard/video/useFetchVideoIfNecessary';
import { VideoHelper } from 'features/video-library/video-helpers';
import { useNavigate } from 'hooks/useNavigate';
import { useMemo } from 'react';
import { teamRoute, videoRoute } from 'routes';
import { ID } from 'types/pigeon';

import { NotificationsHelper } from '../notification-settings/notifications-helper';
import { ENotificationState } from './constants';
import { useGetNotificationComment } from './useGetNotificationComment';
import useUpdateNotification from './useUpdateNotifications';

export function useOnClickNotification(notifications: NotificationFragment[]) {
  const { handleUpdateNotifications } = useUpdateNotification();
  const navigate = useNavigate<{ comment?: CommentFragment }>();

  const [firstNotification] = notifications;

  const [video] = useFetchVideoIfNecessary((firstNotification as { video?: { id: ID } }).video?.id);
  const comment = useGetNotificationComment(firstNotification);

  const unreadNotifications = useMemo(() => {
    return notifications.filter(n => n.state !== ENotificationState.READ);
  }, [notifications]);

  const navigateParams = useMemo((): Parameters<typeof navigate> | undefined => {
    switch (firstNotification.__typename) {
      case 'CommentNotification':
        if (!video || !VideoHelper.isWatchable(video)) {
          return;
        }

        return [
          videoRoute(firstNotification.video.id),
          { state: { comment } },
        ];

      case 'MemberNotification':
        return [
          DashboardTeamSettingsRouting.createUrl(firstNotification.team.id, DashboardTeamSettingsRouting.Tab.MEMBERS),
        ];

      case 'VideoNotification':
        if (notifications.length > 1) {
          return [
            firstNotification.video.division
              ? DashboardRouting.createDirectoryUrl(firstNotification.video.division.id)
              : teamRoute(firstNotification.team.id),
          ];
        }
        if (!video || !VideoHelper.isWatchable(video)) {
          return;
        }
        return [
          videoRoute(firstNotification.video.id),
          {state: {referPath: window.location.pathname + window.location.search}},
        ];
    }
  }, [firstNotification, video, comment, notifications]);

  // NOTE: use useMemo instead of useCallback so that it can be undefined to signify unclickability
  return useMemo(() => {
    if (!navigateParams) {
      return;
    }

    return () => {
      if (unreadNotifications.length > 0) {
        const newState = NotificationsHelper.getNewState(unreadNotifications);
        handleUpdateNotifications(unreadNotifications, newState);
      }
      navigate(...navigateParams);
    };
  }, [handleUpdateNotifications, unreadNotifications, navigateParams, navigate]);
}
