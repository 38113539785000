import React from 'react';

export interface VideoNoteState {
  currentNote: string;
  setCurrentNote: (c: string) => void;
}

export const VideoNoteContext = React.createContext<VideoNoteState>({
  currentNote: '',
  setCurrentNote: () => {},
});

export const {
  Provider: VideoNoteProvider,
  Consumer: VideoNoteConsumer,
} = VideoNoteContext;
