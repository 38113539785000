import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

import { UserNotificationSettingsState } from './notification-settings-slice';

export const getUserNotificationSettingsState = (state: RootReducer): UserNotificationSettingsState => {
  return state.userNotificationSettings.userNotificationSettings;
};

const getTeamNotificationSettingsDictByTeamId = (state: RootReducer, teamId: ID) => {
  return state.userNotificationSettings.teamNotificationSettings[teamId];
};

const getChannelNotificationSettingsDictByChannelId = (state: RootReducer, channelId: ID) => {
  return state.userNotificationSettings.directoryNotificationSettings[channelId];
};


export const makeGetTeamNotificationSettingsDictByTeamId = () => createSelector(
  [getTeamNotificationSettingsDictByTeamId],
  (teamNotificaionSetting) => teamNotificaionSetting,
);

export const makeGetChannelNotificationSettingsDictByChannelId = () => createSelector(
  [getChannelNotificationSettingsDictByChannelId],
  (channelNotificaionSetting) => channelNotificaionSetting,
);
