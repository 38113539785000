import { BundleFragment } from 'apollo/fragments/types/BundleFragment';
import { PriceFragment } from 'apollo/fragments/types/PriceFragment';
import { BillingInterval, ProductKind } from 'types/graphql';
import { Billing, ID } from 'types/pigeon';

function getPrice(
  bundle: BundleFragment,
  priceId: ID | undefined,
  interval: BillingInterval,
): PriceFragment {
  if (priceId) {
    const price = bundle.prices.find((price) => price.id === priceId && price.interval === interval);
    if (price) {
      return price;
    }
  }

  const price = bundle.prices.find((price) => price.interval === interval);
  if (!price) {
    throw new Error('price for interval not found');
  }

  return price;
}

export function getBundlePrice(
  getBundle: Billing.BundleGetter,
  savedPrice: Partial<Billing.SavedPrice> | undefined,
  kind: ProductKind,
  interval: BillingInterval,
): PriceFragment {
  return getPrice(getBundle(savedPrice?.productId, kind), savedPrice?.priceId, interval);
}
