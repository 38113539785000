import { AsyncButton, EnhancedDialogTitle } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { RoleHelper } from 'components/role-table/role-helper';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { addMemberRolesAC, removeMemberRolesAC } from 'features/dashboard/team/dashboard-team-slice';
import { subtract } from 'helpers';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import MultiSelect, { OptionType } from 'material/multi-select/MultiSelect';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { RoleInput } from 'types/graphql';
import { ID } from 'types/pigeon';

function mapRoleToInput(role: RoleInterfaceFragment): RoleInput {
  return {
    name: role.name,
    global: RoleHelper.isGlobalRole(role),
  };
}

interface AssignRolesDialogOwnProps {
  className?: string;
  onClose?: VoidFunction
  team: TeamFragment;
  member: MemberFragment;
  roles: RoleInterfaceFragment[];
}

type AssignRolesDialogProps = AssignRolesDialogOwnProps & Pick<DialogProps, 'open'>;

function AssignRolesDialog(props: AssignRolesDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <AssignRolesDialogContent {...props} />
    </Dialog>
  );
}

function AssignRolesDialogContent(props: AssignRolesDialogProps) {
  const { onClose, team, roles, member } = props;
  const { t } = useStrictTranslation(['common', 'dialog']);
  const wt = t as TFunction;
  const [ loading, setLoading ] = useState(false);
  const [ isDirty, setIsDirty ] = useState(false);

  const mapRoleToOption = useCallback((role: RoleInterfaceFragment) => {
    return {
      label: RoleHelper.isGlobalRole(role) ? wt(`common:roles.global.${role.name}.title`) : role.name,
      value: role.name,
    };
  }, [wt]);

  const [ selected, setSelected ] = useState<OptionType[]>(() => (
    member.roles2
    ? member.roles2.map(role2 => roles.find(r => role2.name === r.name)).map(mapRoleToOption)
    : []
  ));

  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const accessControl = useAccessControl();

  const roleOptions = useMemo(() =>
    accessControl.filterRolesByUserPrivileges(roles).map(mapRoleToOption)
  , [roles, accessControl, mapRoleToOption]);

  const handleMultiRoleChange = useCallback((value?: OptionType[]) => {
    setSelected(value || []);
    setIsDirty(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (loading) { return; }
    setLoading(true);

    const values: ID[] = selected.map((option: OptionType) => option.value);
    const vSet: Set<ID> = new Set(values);
    const rSet: Set<ID> = new Set(member.roles2!.map(r => r.name));
    const toAdd: Set<ID> = subtract(vSet, rSet);
    const toRemove: Set<ID> = subtract(rSet, vSet);

    try {
      if (toRemove.size > 0) {
        await promiseSagaDispatch(removeMemberRolesAC, {
          teamId: team.id,
          memberId: member.id,
          roles: Array.from(toRemove).map(r => roles.find(role => r === role.name)).map(mapRoleToInput),
        });
      }
      if (toAdd.size > 0) {
        await promiseSagaDispatch(addMemberRolesAC, {
          teamId: team.id,
          memberId: member.id,
          roles: Array.from(toAdd).map(r => roles.find(role => r === role.name)).map(mapRoleToInput),
        });
      }
      enqueueSnackbar(t('dialog:assignroles.success'), {variant: 'success'});
      setLoading(false);
    } catch(error) {
      enqueueSnackbar(t('dialog:assignroles.failure'), {variant: 'error'});
      setLoading(false);
    } finally {
      onClose?.();
    }
  }, [
    loading,
    member,
    roles,
    selected,
    team,
    t,
    enqueueSnackbar,
    promiseSagaDispatch,
    onClose,
  ]);

  return (
    <React.Fragment>
      <EnhancedDialogTitle>
        {t('dialog:assignroles.title')}
      </EnhancedDialogTitle>
      <DialogContent>
        <MultiSelect
        id='role-multi-select'
        label={t('dialog:assignroles.label')}
        placeholder={t('dialog:assignroles.placeholder')}
        value={selected}
        options={roleOptions}
        isMulti={true}
        autoFocus={true}
        onChange={handleMultiRoleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button type='button' variant='outlined' onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <AsyncButton
        color='primary'
        variant='contained'
        loading={loading}
        disabled={loading || !isDirty}
        onClick={handleSubmit}
        >
          {t('dialog:assignroles.title')}
        </AsyncButton>
      </DialogActions>
    </React.Fragment>
  );
}

export default React.memo(AssignRolesDialog);
