import { addProtocolToUrlIfNecessary } from '../urlvalidator';

export interface IRemoteVideoNoembedResponse {
  // width          : number;
  // height         : number;
  // author_name    : string;
  // author_url     : string;
  // version        : string;
  // provider_url   : string;
  // provider_name  : string;
  // thumbnail_width: number;
  thumbnail_url  : string;
  title          : string;
  url            : string;
  error?         : string;
}

export async function fetchRemoteVideoNoembedData(remoteUrl: string): Promise<IRemoteVideoNoembedResponse> {
  // prepend with https:// if there isn't a protocol present
  // because noembed fails if there isn't a protocol
  remoteUrl = addProtocolToUrlIfNecessary(remoteUrl, 'https://');
  const response = await fetch(`https://noembed.com/embed?url=${encodeURIComponent(remoteUrl)}`);
  return await response.json();
}

export async function fetchInsightsEmbedData(remoteUrl: string): Promise<InsightsEmbedResponse> {
  const response = await fetch(`/external/embed?url=${encodeURIComponent(remoteUrl)}`);
  const json = await response.json();
  if (response.ok) {
    return json;
  }
  throw new Error(json.error);
}
