import { FlexSpacer, IconTab, IconTabs } from '@insights-gaming/material-components';
import { GameMetadata } from '@insights-gaming/statistics';
import { Theme } from '@insights-gaming/theme';
import Badge from '@material-ui/core/Badge';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CommentIcon from '@material-ui/icons/Comment';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SubjectIcon from '@material-ui/icons/Subject';
import TimelineIcon from '@material-ui/icons/Timeline';
import { CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import classNames from 'classnames';
import CommentsMenu from 'components/video/video-replay/video-comment/CommentsMenu';
import Statistics, { canRenderGameID } from 'features/capture-games-statistics/league-statistics/Statistics';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { useFetchDirectoryIfNecessary } from 'features/dashboard/directory/useFetchDirectoryIfNecessary';
import { useFetchVideoIfNecessary } from 'features/dashboard/video/useFetchVideoIfNecessary';
import { getIsJoinedLiveSession, getLiveSessionUnreadMessages } from 'features/live-session/live-session-selector';
import { useIsDesktop } from 'features/media-queries/hooks';
import { VideoHelper } from 'features/video-library/video-helpers';
import VideoMenuButton from 'features/video-menu/VideoMenuButton';
import MemberFetcher from 'fetchers/member-fetcher/MemberFetcher';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { JOIN_SESSION_PATH } from 'routes';
import { getMe } from 'selectors/getMe';
import { GeneratedOverwatchMatch, ID, TestingSSBUMatch } from 'types/pigeon';

import ChatPanel from '../chat-panel/ChatPanel';
import CommentPanel from '../comment-panel/CommentPanel';
import LabelSelector from '../comment-panel/label-selector/LabelSelector';
import NotePanel from '../note-panel/NotePanel';
import TimelinePanel from '../timeline-panel/TimelinePanel';
import { VideoCommentMenuContext } from '../VideoCommentMenuContext';

interface RightPanelOwnProps {
  className?: string;
  videoId?: ID;
  gameMatch?: GeneratedOverwatchMatch | TestingSSBUMatch;
  onExportKillfeed?: VoidFunction;
  onEditCommentDrawing?: (comment: CommentFragment_VideoComment) => void;
  commentBoxRefHandler?: ((el: HTMLElement | null) => void);
  commentLabelRefHandler?: ((el: HTMLElement | null) => void);
}

type RightPanelProps = RightPanelOwnProps;

type VideoReplayRightPanelTab = 'notes' | 'comments' | 'chat' | 'timeline' | 'ssbu' | 'stats';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  panel: {
    flex: 1,
    overflow: 'hidden',
    margin: theme.spacing(1),
  },
  commentMenu: {
    padding: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}), {name: 'RightPanel'});

interface RightPanelTabContentOwnProps {
  tab: string;
  showChat: boolean;
  teamId: ID | undefined;
  owInfo?: GameMetadata;
}

type RightPanelTabContentProps = RightPanelTabContentOwnProps & RightPanelProps;

function RightPanelTabContent(
  {
    className,
    videoId,
    gameMatch,
    onExportKillfeed,
    tab,
    showChat,
    onEditCommentDrawing,
    teamId,
    owInfo,
    commentBoxRefHandler,
    commentLabelRefHandler,
  }: RightPanelTabContentProps,
) {
  switch (tab) {
    case 'comments':
      return videoId ? (
        <CommentPanel
        videoId={videoId}
        className={className}
        onEditCommentDrawing={onEditCommentDrawing}
        commentBoxRefHandler={commentBoxRefHandler}
        commentLabelRefHandler={commentLabelRefHandler}
        />
      ) : null;

    case 'chat':
      return showChat ? (
        <ChatPanel className={className} teamId={teamId} />
      ) : null;

    case 'notes':
      return videoId ? (
        <NotePanel videoId={videoId} className={className} />
      ) : null;

    case 'timeline':
      return gameMatch?.isGeneratedOverwatchMatch() ? (
        <TimelinePanel gameMatch={gameMatch} onExportKillfeed={onExportKillfeed} className={className} />
      ) : null;

    case 'stats':
      return owInfo  && videoId ? (
        <Statistics videoId={videoId} className={className} owInfo={owInfo}/>
      ) : null;
  }

  return null;
}

function RightPanel(props: RightPanelProps) {
  const { className, videoId, gameMatch } = props;
  const classes = useStyles(props);

  const { t } = useStrictTranslation(['video', 'statistics']);

  const me = useSelector(getMe);
  const joinedLiveSession = useSelector(getIsJoinedLiveSession);
  const liveSessionUnreadMessages = useSelector(getLiveSessionUnreadMessages);
  const isDesktop = useIsDesktop();
  const isSessionRoute = !!useRouteMatch(JOIN_SESSION_PATH);

  const { canOpenVideoMenu, canAccessOverwatchStats, canAccessSSBUStats } = useAccessControl();

  const [video] = useFetchVideoIfNecessary(videoId);
  const [directory] = useFetchDirectoryIfNecessary(video?.directory?.id);

  const {
    autoPause,
    autoPauseTyping,
    autoScroll,
    autoPlayAudio,
    toggleAutoPause,
    toggleAutoPauseTyping,
    toggleAutoScroll,
    toggleAutoPlayAudio,
  } = useContext(VideoCommentMenuContext);

  const [tab, setTab] = useState<VideoReplayRightPanelTab>(
    isSessionRoute && joinedLiveSession ? 'chat' : 'comments',
  );
  const handleChange = useCallback((_: React.ChangeEvent<{}>, value: VideoReplayRightPanelTab) => {
    setTab(value);
  }, []);

  const isOWLVideo = !!video && VideoHelper.isOWLVideo(video);

  const owInfo = useMemo(
    (): GameMetadata | undefined => {
      try {
        return JSON.parse(video?.userMetadata?.find(metaData => metaData.name === '_overwolfGameInfo')?.value || '');
      } catch {
        return;
      }
    },
    [video],
  );

  return (
    <FlexSpacer orientation='vertical' className={classNames(classes.root, className)}>
      {directory?.team?.id && (
        <MemberFetcher teamId={directory.team.id} />
      )}
      <FlexSpacer flexJustifyContent='space-between' flexAlignItems='center'>
        <IconTabs
        value={tab}
        onChange={handleChange}
        >
          {isSessionRoute && joinedLiveSession && (
            <IconTab
            variant='containedIcon'
            color='primary'
            value='chat'
            icon={(
              <Badge color='secondary' badgeContent={liveSessionUnreadMessages}>
                <QuestionAnswerIcon />
              </Badge>
            )}
            label={tab === 'chat' ? t('video:replay.chat') : null}
            size='small'
            />
          )}
          <IconTab
          variant='containedIcon'
          color='primary'
          value='comments'
          icon={<CommentIcon />}
          label={tab === 'comments' ? t('video:replay.comment_plural') : null}
          size='small'
          />
          {me && !isOWLVideo && (
            <IconTab
            variant='containedIcon'
            color='primary'
            value='notes'
            icon={<AssignmentIcon />}
            label={tab === 'notes' ? t('video:replay.notepad') : null}
            size='small'
            />
          )}
          {(isOWLVideo || (canAccessOverwatchStats && gameMatch?.isGeneratedOverwatchMatch())) && (
            <IconTab
            variant='containedIcon'
            color='primary'
            value='timeline'
            icon={<SubjectIcon />}
            label={tab === 'timeline' ? t('video:timeline.title') : null}
            size='small'
            />
          )}
          {canAccessSSBUStats && gameMatch?.isTestingSSBUMatch() && (
            <IconTab
            variant='containedIcon'
            color='primary'
            value='ssbu'
            icon={<SubjectIcon />}
            label={tab === 'timeline' ? t('video:timeline.title') : null}
            size='small'
            />
          )}
          {owInfo && canRenderGameID(owInfo.gameClassId) && (
            <IconTab
            variant='containedIcon'
            color='primary'
            value='stats'
            icon={<TimelineIcon />}
            label={tab === 'stats' ? t('statistics:tabs.statistics') : null}
            size='small'
            />
          )}
        </IconTabs>
        {canOpenVideoMenu && video && (
          isDesktop ? (
            <VideoMenuButton size='small' video={video} />
          ) : (
            tab === 'comments' ? (
              <div className={classes.commentMenu}>
                {directory?.team?.id && (
                  <LabelSelector teamId={directory.team.id}/>
                )}
                <CommentsMenu
                autoPauseComment={autoPause}
                autoPauseTyping={autoPauseTyping}
                autoScrollComment={autoScroll}
                autoPlayAudio={autoPlayAudio}
                togglAutoPauseComment={toggleAutoPause}
                toggleAutoPauseTyping={toggleAutoPauseTyping}
                toggleAutoScrollComment={toggleAutoScroll}
                toggleAutoPlayAudio={toggleAutoPlayAudio}
                />
              </div>
            ) : null
          )
        )}
      </FlexSpacer>
      <RightPanelTabContent
      {...props}
      className={classes.panel}
      tab={tab}
      showChat={joinedLiveSession}
      teamId={directory?.team?.id}
      owInfo={owInfo}
      />
    </FlexSpacer>
  );
}

export default React.memo(RightPanel);
