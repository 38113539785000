import { GetTeamResourcesQuery_team_resources } from 'apollo/queries/types/GetTeamResourcesQuery';

export default function getResourceRemaining(resource: GetTeamResourcesQuery_team_resources | undefined): number {
    if (!resource) {
        return 0;
    }

    if (resource.limit === null) {
        return Infinity;
    }

    return resource.limit - resource.usage;
}
