import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { VideoHelper } from 'features/video-library/video-helpers';
import React, { useCallback, useMemo } from 'react';
import { useAutoControlled } from 'react-auto-controlled';
import { ValueType } from 'react-select/src/types';

import { ID, Match, Video } from '../../types/pigeon';
import MatchCard from './MatchCard';

interface MatchSelectorOwnProps {
  isMulti?     : boolean;
  matches      : Match[];
  value?       : ValueType<ID>;
  defaultValue?: ValueType<ID>;

  teamId?: ID;
  video: Video;
  videoTime?: number;

  onChange?: (value: ValueType<ID>) => void;
}

export type MatchSelectorProps = MatchSelectorOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    paddingRight: theme.spacing(0.5),
  },
}), {name: 'MatchSelector'});

function MatchSelector(props: MatchSelectorProps) {
  const { isMulti, matches, onChange, teamId, video, videoTime } = props;
  const classes = useStyles(props);
  const [ value,, trySetValue, getDerivedValueFromProps ] = useAutoControlled(undefined, {
    prop: props.value,
    defaultProp: props.defaultValue,
  });
  getDerivedValueFromProps();

  const handleValue = useCallback((value: ValueType<ID>) => {
    trySetValue(value);
    onChange?.(value);
  }, [onChange, trySetValue]);

  const isMatchSelected = useCallback((match: Match) => {
    if (typeof value === 'string') {
      return value === match.id;
    }
    if (Array.isArray(value)) {
      return value.find((id: ID) => id === match.id);
    }
    return false;
  }, [value]);

  const handleCardSelected = useCallback((match: Match) => {
    if (isMulti) {
      if (Array.isArray(value)) {
        handleValue(value.concat(match.id));
      }
    } else {
      handleValue(match.id);
    }
  }, [handleValue, isMulti, value]);

  const handleCardDeselected = useCallback((match: Match) => {
    if (isMulti) {
      if (Array.isArray(value)) {
        handleValue(value.filter(id => id !== match.id));
      }
    } else {
      handleValue(null);
    }
  }, [handleValue, isMulti, value]);

  const handleCardClicked = useCallback((selected?: boolean) => {
    return selected ? handleCardDeselected : handleCardSelected;
  }, [handleCardDeselected, handleCardSelected]);

  const teamIdentifier = useMemo(() => teamId || VideoHelper.getTeamId(video), [teamId, video]);

  return (
    <FlexSpacer className={classes.root} orientation='vertical'>
      {matches.sort((a, b) => a.startTime - b.startTime).map((match, i) => {
        const selected = isMatchSelected(match);
        return (
          <MatchCard
          key={match.id}
          selected={selected}
          match={match}
          index={i}
          onClick={handleCardClicked(selected)}
          videoId={video.id}
          teamId={teamIdentifier}
          />
        );
      })}
    </FlexSpacer>
  );
}

export default React.memo(MatchSelector);
