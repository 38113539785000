import gql from 'graphql-tag';

export const StopSession_Mutation = gql`
    mutation StopSessionMutation(
        $input: StopSessionInput!
    ) {
        stopSession(
            input: $input
        ) {
            teamId
        }
    }
`;
