import { VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import MicIcon from '@material-ui/icons/Mic';
import classNames from 'classnames';
import { EIntercomID } from 'constants/strings';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useContext } from 'react';

import { AudioCommentSourceContext } from './AudioCommentSourceContext';

interface AudioCommentTriggerOwnProps {
  className?: string;
  disabled: boolean;
  handleRecordOnClick: VoidFunction;
}

type AudioCommentTriggerProps = AudioCommentTriggerOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  micIcon: {
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      border: 'none',
    },
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  buttonIcons: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}), {name: 'AudioCommentTrigger'});

function AudioCommentTrigger(props: AudioCommentTriggerProps) {
  const classes = useStyles(props);
  const { className, disabled, handleRecordOnClick } = props;
  const { swapPlayer } = useContext(AudioCommentSourceContext);
  const { pause } = useContext(VideoReplayContext);
  const { t } = useStrictTranslation(['common']);

  const startRecording = useCallback(() => {
    pause();
    handleRecordOnClick();
    swapPlayer(undefined);
  }, [handleRecordOnClick, pause, swapPlayer]);

  return (
    <Tooltip title={t('common:audiocomment')}>
      <IconButton
      onClick={startRecording}
      size='small'
      className={classNames(className, classes.micIcon, classes.buttonIcons)}
      disabled={disabled}
      id={EIntercomID.AUDIO_RECORDING}
      >
        <MicIcon fontSize='small'/>
      </IconButton>
    </Tooltip>
  );
}

export default React.memo(AudioCommentTrigger);
