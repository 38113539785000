import actionCreatorFactory, { Action, Failure, Success } from 'typescript-fsa';

import {
  GetOWLStatisticsQuery_owlStatistics,
  GetOWLStatisticsQueryVariables,
} from '../apollo/queries/types/GetOWLStatisticsQuery';
import { GetOWLVideosQuery_queryOWLVideos, GetOWLVideosQueryVariables } from '../apollo/queries/types/GetOWLVideosQuery';

const actionCreator = actionCreatorFactory();

const FETCH_OWL_VIDEOS = 'FETCH_OWL_VIDEOS';
const FETCH_OWL_STATISTICS = 'FETCH_OWL_STATISTICS';

export const fetchOWLStatisticsAsyncAC = actionCreator.async<
  GetOWLStatisticsQueryVariables,
  GetOWLStatisticsQuery_owlStatistics,
  Error
>(FETCH_OWL_STATISTICS);
type FetchOWLStatistics =
  | Action<GetOWLStatisticsQueryVariables>
  | Action<Success<GetOWLStatisticsQueryVariables, GetOWLStatisticsQuery_owlStatistics>>
  | Action<Failure<GetOWLStatisticsQueryVariables, Error>>;

export const fetchOWLVideosAsyncAC = actionCreator.async<
  GetOWLVideosQueryVariables,
  GetOWLVideosQuery_queryOWLVideos,
  Error
>(FETCH_OWL_VIDEOS);
type FetchOWLVideos =
  | Action<GetOWLVideosQueryVariables>
  | Action<Success<GetOWLVideosQueryVariables, GetOWLVideosQuery_queryOWLVideos>>
  | Action<Failure<GetOWLVideosQueryVariables, Error>>;


export type PublicStatisticsAction =
  | FetchOWLStatistics
  | FetchOWLVideos;
