import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { desktop } from 'features/media-queries';
import React from 'react';

interface EmptyStateOwnProps {
  className?: string;
  mainTitle: React.ReactNode;
  subTitle?: React.ReactNode;
  img: React.ReactNode;
  action?: React.ReactNode;
}

type EmptyStateProps = EmptyStateOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    [desktop(theme)]: {
      margin: theme.spacing(1),
    },
  },
}), {name: 'EmptyState'});

function EmptyState(props: EmptyStateProps) {
  const classes = useStyles(props);
  const { className, mainTitle, subTitle, img, action } = props;
  return (
    <FlexSpacer
    className={classes.root}
    orientation='vertical'
    flexAlignItems='center'
    fullWidth={true}
    >
      <Typography variant='h5'>{mainTitle}</Typography>
      <Typography variant='h6'>{subTitle}</Typography>
      {img}
      {action}
    </FlexSpacer>
  );
}

export default React.memo(EmptyState);
