import gql from 'graphql-tag';

import { RosterFragment } from '../fragments';

export const UpdateRoster_Mutation = gql`
    mutation UpdateRosterMutation(
        $input: UpdateRosterInput!
    ) {
        updateRoster(
            input: $input
        ) {
            roster {
                ...RosterFragment
            }
        }
    }
    ${RosterFragment}
`;
