import { Theme } from '@insights-gaming/theme';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import TeamAvatarButton from 'features/dashboard/sidebar/TeamAvatarButton';
import React, { useCallback } from 'react';


interface TeamListItemOwnProps {
  className?: string;
  active: boolean;
  team: TeamFragment;
  setSelectedTeam: (team: TeamFragment) => void;
}

type TeamListItemProps = TeamListItemOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'TeamListItem'});

function TeamListItem(props: TeamListItemProps) {
  const classes = useStyles(props);
  const { className, team, active, setSelectedTeam } = props;

  const setTeamOnClick = useCallback(() => setSelectedTeam(team), [setSelectedTeam, team]);

  return (
    <ListItem button={true} onClick={setTeamOnClick} selected={active}>
      <ListItemIcon>
        <TeamAvatarButton team={team} size='sm'/>
      </ListItemIcon>
      <ListItemText primary={team.name} />
    </ListItem>
  );
}

export default React.memo(TeamListItem);
