import { GetOverwatchAdvancedQueryQueryVariables } from 'apollo/queries/types/GetOverwatchAdvancedQueryQuery';
import { getUuid } from 'helpers/uuidgenerator';
import { OverwatchAdvancedQueryFilter } from 'types/graphql';
import { ID } from 'types/pigeon';

export interface QueryForm {
  queryName: string;
  conditionIds: ID[]
  conditionMap: Map<ID, OverwatchAdvancedQueryFilter>;
}

const initialId = getUuid();

export const emptyQuery: QueryForm = {
  queryName: '',
  conditionIds: [initialId],
  conditionMap: new Map<ID, OverwatchAdvancedQueryFilter>([
    [
      initialId,
      {
        variablePath: '',
        value: '',
        operator: '=', // this will be always '=' for now until denning adds in more
      },
    ],
  ]),
};

function queryFormBuilder(queryVariable: GetOverwatchAdvancedQueryQueryVariables): QueryForm {
  const conditionMap = new Map<ID, OverwatchAdvancedQueryFilter>();
    for (let i = 0; i < queryVariable.filters.length; i++) {
      conditionMap.set(getUuid(), queryVariable.filters[i]);
    }
    const ids = Array.from(conditionMap.keys());
    return {
      queryName: queryVariable.queryName,
      conditionIds: ids,
      conditionMap,
    };
}

export function getLatestQuery(queryVariable?: GetOverwatchAdvancedQueryQueryVariables): QueryForm {
  return queryVariable ? queryFormBuilder(queryVariable) : emptyQuery;
}
