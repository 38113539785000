import gql from 'graphql-tag';

import { CommentFragment } from '../fragments';

export const UpdateVideoComment_Mutation = gql`
    mutation UpdateVideoCommentMutation(
        $input: UpdateVideoCommentInput!
    ) {
        updateVideoComment(
            input: $input
        ) {
            comment {
                ...CommentFragment
            }
        }
    }
    ${CommentFragment}
`;
