import { Theme } from '@insights-gaming/theme';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/Remove';
import { Skeleton } from '@material-ui/lab';
import { InvoiceFragment, InvoiceFragment_card } from 'apollo/fragments/types/InvoiceFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { formatDate } from 'helpers/formatters';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { IBillingCycle } from 'types/pigeon/billing';


interface PaymentHistoryOwnProps {
  className?: string;
  team: TeamFragment;
  billingCycle: IBillingCycle | undefined;
  invoices: InvoiceFragment[];
  hasPlan: boolean;
  displaySkeleton: boolean;
}

type PaymentHistoryProps = PaymentHistoryOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  heading: {
    fontWeight: 700,
    margin: theme.spacing(1, 0),
  },
  details: {
    textTransform: 'capitalize',
  },
}), { name: 'PaymentHistory' });

const calculateCost = (amount: number): string => {
  if (amount < 0) {
    return `(${calculateCost(-amount)})`;
  }

  return `$${(amount / 100).toFixed(2)}`;
};

function PaymentHistory(props: PaymentHistoryProps) {
  const classes = useStyles(props);
  const {
    className,
    team,
    billingCycle,
    invoices,
    hasPlan,
    displaySkeleton,
  } = props;

  const { t } = useStrictTranslation(['dashboard']);

  const link = (link: string) => {
    return(
      <a href={`${link}`}>{t('dashboard:billing.downloadinvoice')}</a>
    );
  };

  const cardDetail = (card: InvoiceFragment_card | null) => {
    if (card) {
      return (
        <Typography variant='body2' className={classes.details}>
          {t('dashboard:billing.cardending', { brand: card.brand, last4: card.last4 })}
        </Typography>
      );
    } else {
      return <RemoveIcon />;
    }
  };

  return (
    <div className={classNames(classes.root, className)}>
      {displaySkeleton ? (
        <Skeleton variant='rect' height={55}/>
      ) : (
        <TableContainer component={Paper}>
          <Table size='medium' aria-label='payment history table'>
            <TableHead>
              <TableRow>
                <TableCell>{t('dashboard:billing.invoicenumber')}</TableCell>
                <TableCell>{t('dashboard:billing.datepaid')}</TableCell>
                <TableCell>{t('dashboard:billing.amountpaid')}</TableCell>
                <TableCell>{t('dashboard:billing.paymentmethod')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.map((invoice, i) => (
                <TableRow key={i} hover={true}>
                  <TableCell>{invoice.number}</TableCell>
                  <TableCell>{formatDate(invoice.date)}</TableCell>
                  <TableCell>{calculateCost(invoice.amount)}</TableCell>
                  <TableCell>{cardDetail(invoice?.card)}</TableCell>
                  <TableCell>{link(invoice.pdf)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default React.memo(PaymentHistory);
