import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, fade, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import { desktop, mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { formatDuration } from 'helpers/formatters';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useEffect, useState } from 'react';

import AudioCommentWrapper from './AudioCommentWrapper';

interface RecordingIndicatorOwnProps {
  className?: string;
  saveRecording: VoidFunction;
  discardRecording: VoidFunction;
}

type RecordingIndicatorProps = RecordingIndicatorOwnProps;

function pulseAnimation(color: string) {
  return {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: `0 0 0 0 ${fade(color, 0.7)}`,
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: `0 0 0 10px ${fade(color, 0)}`,
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: `0 0 0 0 ${fade(color, 0)}`,
    },
  };
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  stopIcon: {
    backgroundColor: theme.palette.primary.main,
    [desktop(theme)]: {
      boxShadow: `0 0 0 0 ${theme.palette.primary.main}`,
      transform: 'scale(1)',
      animation: 'pulse 2s infinite',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        animation: 'none',
      },
    },
    [mobilePortrait(theme)]: {
      width: createRemFromPx(32),
      height: createRemFromPx(32),
    },
    [mobileLandscape(theme)]: {
      width: createRemFromPx(32),
      height: createRemFromPx(32),
    },
  },
  '@global @keyframes pulse': {
    ...pulseAnimation(theme.palette.primary.main),
  },
  closeIcon: {
    color: theme.palette.error.main,
    [desktop(theme)]: {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
  },
  indicatorRow: {
    flex: 1,
    position: 'relative',
  },
  recordIconContainer: {
    border: `2px solid ${theme.palette.grey[700]}`,
    borderRadius: createRemFromPx(5),
    padding: theme.spacing(1),
  },
}), {name: 'RecordingIndicator'});

function RecordingIndicator(props: RecordingIndicatorProps) {
  const classes = useStyles(props);
  const { className, saveRecording, discardRecording } = props;
  const { t } = useStrictTranslation(['video']);

  const [duration, setDuration] = useState(0);

  const isDesktop = useIsDesktop();

  useEffect(() => {
    const start = Date.now();
    const id = setInterval(() => setDuration((Date.now() - start)/1000), 1000);
    return () => clearInterval(id);
  }, []);

  if (isDesktop) {
    return (
      <AudioCommentWrapper coloured={true} fill={true} className={className}>
        <FlexSpacer flexJustifyContent='center' className={classes.indicatorRow}>
          <FlexSpacer orientation='vertical' flexAlignItems='center'>
            <Tooltip title={t('video:replay.stoprecording')}>
              <IconButton className={classes.stopIcon} onClick={saveRecording}>
                <StopIcon fontSize='large'/>
              </IconButton>
            </Tooltip>
            <div>{formatDuration(duration)}</div>
            <Tooltip title={t('video:replay.discardrecording')}>
              <IconButton
              onClick={discardRecording}
              size='small'
              className={classes.closeIcon}
              >
                <CloseIcon fontSize='small'/>
              </IconButton>
            </Tooltip>
          </FlexSpacer>
        </FlexSpacer>
      </AudioCommentWrapper>
    );
  }

  return (
    <AudioCommentWrapper className={className}>
      <FlexSpacer
      className={classes.indicatorRow}
      flexJustifyContent='center'
      fullWidth={true}
      >
        <FlexSpacer
        flexAlignItems='center'
        flexJustifyContent='space-between'
        fullWidth={true}
        >
          <IconButton
          className={classes.closeIcon}
          onClick={discardRecording}
          size='small'
          >
            <CloseIcon fontSize='small'/>
          </IconButton>

          <FlexSpacer
          className={classes.recordIconContainer}
          fullWidth={true}
          flexAlignItems='center'
          flexJustifyContent='center'
          >
            <FiberManualRecordIcon fontSize='small' color='error'/>
            <div>{formatDuration(duration)}</div>
          </FlexSpacer>

          <IconButton
          className={classes.stopIcon}
          onClick={saveRecording}
          >
            <StopIcon fontSize='small'/>
          </IconButton>
        </FlexSpacer>
      </FlexSpacer>
    </AudioCommentWrapper>
  );
}

export default React.memo(RecordingIndicator);
