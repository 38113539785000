import {
  buildLeagueCharacterIconKey,
  buildLeagueCharIconPath,
  buildOverwatchCharacterIconKey,
  buildOWCharIconPath,
  buildRainbowSixSiegeCharacterIconKey,
  buildRainbowSixSiegeCharIconPath,
  buildSSBUCharacterIconKey,
  buildSSBUCharIconPath,
  buildValorantCharacterIconKey,
  buildValorantCharIconPath,
} from '../../../helpers';
import { HeroesF, HeroesNS, THeroes } from '../../../locales/en/heroes';
import { LeaguechampionsF, LeaguechampionsNS, TLeaguechampions } from '../../../locales/en/leaguechampions';
import {
  RainbowSixSiegeOperatorsF,
  RainbowSixSiegeOperatorsNS,
  TRainbowSixSiegeOperators,
} from '../../../locales/en/rainbow-six-siege-operators';
import { SsbuCharactersF, SsbuCharactersNS, TSsbuCharacters } from '../../../locales/en/ssbu-characters';
import { TValorantagents, ValorantagentsF } from '../../../locales/en/valorantagents';
import { CategoryInput } from '../../../material/menus/image-picker-menu-content/ImagePickerMenuContent';

const overwatch: CategoryInput<THeroes> = {
  keyBuilder: buildOverwatchCharacterIconKey,
  categoryId: 'overwatch',
  categoryName: 'Overwatch 2',
  tNamespace: HeroesNS,
  haystack: [
    'ana',
    'ashe',
    'baptiste',
    'bastion',
    'bob',
    'brigitte',
    'cassidy',
    'dva',
    'doomfist',
    'echo',
    'genji',
    'hanzo',
    'kiriko',
    'junkrat',
    'junker_queen',
    'lucio',
    'lifeweaver',
    'mei',
    'mercy',
    'moira',
    'orisa',
    'payload',
    'pharah',
    'ramattra',
    'reaper',
    'reinhardt',
    'roadhog',
    'sigma',
    'sojourn',
    'soldier_76',
    'sombra',
    'symmetra',
    'torbjorn',
    'tracer',
    'widowmaker',
    'winston',
    'wrecking_ball',
    'zarya',
    'zenyatta',
  ],
  tKeyBuilder: HeroesF,
  cdnPathBuilder: buildOWCharIconPath,
};

const ssbu: CategoryInput<TSsbuCharacters> = {
  keyBuilder: buildSSBUCharacterIconKey,
  categoryId: 'ssbu',
  // categoryName: 'Super Smash Bros. Ultimate',
  categoryName: 'Smash Ultimate',
  tNamespace: SsbuCharactersNS,
  haystack: [
    'BanjoKazooie',
    'Bayonetta',
    'Bowser',
    'BowserJr',
    'Byleth',
    'CaptainFalcon',
    'Charizard',
    'Chrom',
    'Cloud',
    'Corrin',
    'Daisy',
    'DarkPit',
    'DarkSamus',
    'DiddyKong',
    'DonkeyKong',
    'DrMario',
    'DuckHunt',
    'Falco',
    'Fox',
    'Ganondorf',
    'Greninja',
    'Hero',
    'IceClimbers',
    'Ike',
    'Incineroar',
    'Inkling',
    'Isabelle',
    'Ivysaur',
    'Jigglypuff',
    'Joker',
    'Kazuya',
    'Ken',
    'KingDedede',
    'KingKRool',
    'Kirby',
    'Link',
    'LittleMac',
    'Lucario',
    'Lucas',
    'Lucina',
    'Luigi',
    'Mario',
    'Marth',
    'MegaMan',
    'MetaKnight',
    'Mewtwo',
    'Mii',
    'MiiBrawler',
    'MiiGunner',
    'MiiSwordfighter',
    'MinMin',
    'MrGameWatch',
    'MythraAegis',
    'Ness',
    'Olimar',
    'PacMan',
    'Palutena',
    'Peach',
    'Pichu',
    'Pikachu',
    'PiranhaPlant',
    'Pit',
    'PyraAegis',
    'PokemonTrainer',
    'Richter',
    'Ridley',
    'Robin',
    'ROB',
    'RosalinaLuma',
    'Roy',
    'Ryu',
    'Samus',
    'Sephiroth',
    'Sheik',
    'Shulk',
    'Simon',
    'Snake',
    'Sora',
    'Sonic',
    'Squirtle',
    'Steve',
    'Terry',
    'ToonLink',
    'Villager',
    'Wario',
    'WiiFitTrainer',
    'Wolf',
    'Yoshi',
    'YoungLink',
    'Zelda',
    'ZeroSuitSamus',
  ],
  tKeyBuilder: SsbuCharactersF,
  cdnPathBuilder: buildSSBUCharIconPath,
};

const leagueoflegends: CategoryInput<TLeaguechampions> = {
  keyBuilder: buildLeagueCharacterIconKey,
  categoryId: 'leagueoflegends',
  categoryName: 'League of Legends',
  tNamespace: LeaguechampionsNS,
  haystack: [
    'aatrox',
    'ahri',
    'akali',
    'alistar',
    'amumu',
    'anivia',
    'annie',
    'aphelios',
    'ashe',
    'aurelion-sol',
    'azir',
    'bard',
    'blitzcrank',
    'blue_caster_minion',
    'blue_melee_minion',
    'blue_siege_minion',
    'blue_super_minion',
    'brand',
    'braum',
    'caitlyn',
    'camille',
    'cassiopeia',
    'chogath',
    'control-ward',
    'corki',
    'darius',
    'diana',
    'draven',
    'drmundo',
    'ekko',
    'elise',
    'evelynn',
    'ezreal',
    'farsight_alteration_ward',
    'fiddlesticks',
    'fiora',
    'fizz',
    'galio',
    'gangplank',
    'garen',
    'ghost_poro_ward',
    'gnar',
    'gragas',
    'graves',
    'hecarim',
    'heimerdinger',
    'illaoi',
    'irelia',
    'ivern',
    'janna',
    'jarvaniv',
    'jax',
    'jayce',
    'jhin',
    'jinx',
    'kai-sa',
    'kalista',
    'karma',
    'karthus',
    'kassadin',
    'katarina',
    'kayle',
    'kayn',
    'kennen',
    'kha-zix',
    'kindred',
    'kled',
    'kog-maw',
    'leblanc',
    'lee-sin',
    'leona',
    'lillia',
    'lissandra',
    'lucian',
    'lulu',
    'lux',
    'malphite',
    'malzahar',
    'maokai',
    'master-yi',
    'mega-gnar',
    'miss-fortune',
    'mordekaiser',
    'morgana',
    'nami',
    'nasus',
    'nautilus',
    'neeko',
    'nidalee',
    'nocturne',
    'nunu',
    'olaf',
    'oracle_lens_sweeper',
    'orianna',
    'ornn',
    'pantheon',
    'poppy',
    'pyke',
    'qiyana',
    'quinn',
    'rakan',
    'rammus',
    'red_caster_minion',
    'red_melee_minion',
    'red_siege_minion',
    'red_super_minion',
    'rek-sai',
    'renekton',
    'rengar',
    'rhaast',
    'riven',
    'rumble',
    'ryze',
    'sakayn',
    'samira',
    'scrying-orb',
    'sejuani',
    'senna',
    'seraphine',
    'sett',
    'shaco',
    'shen',
    'shyvana',
    'singed',
    'sion',
    'sivir',
    'skaarl',
    'skarner',
    'sona',
    'soraka',
    'stealth_ward',
    'swain',
    'sweeper',
    'sylas',
    'syndra',
    'tahm-kench',
    'taliyah',
    'talon',
    'taric',
    'teemo',
    'thresh',
    'trinket-ward',
    'tristana',
    'trundle',
    'tryndamere',
    'twisted-fate',
    'twitch',
    'udyr',
    'urgot',
    'valor',
    'varus',
    'vayne',
    'veigar',
    'vel-koz',
    'viktor',
    'vi',
    'vladimir',
    'volibear',
    'warding_totem',
    'warwick',
    'willump',
    'wukong',
    'xayah',
    'xerath',
    'xin-zhao',
    'yasuo',
    'yone',
    'yorick',
    'yuumi',
    'zac',
    'zed',
    'ziggs',
    'zilean',
    'zoe',
    'zombie_ward',
    'zyra',
  ],
  tKeyBuilder: LeaguechampionsF,
  cdnPathBuilder: buildLeagueCharIconPath,
};

const valorant: CategoryInput<TValorantagents> = {
  keyBuilder: buildValorantCharacterIconKey,
  categoryId: 'valorant',
  categoryName: 'Valorant',
  tNamespace: HeroesNS,
  haystack: [
    'astra',
    'breach',
    'brimstone',
    'chamber',
    'clover',
    'cypher',
    'deadlock',
    'fade',
    'gekko',
    'harbor',
    'iso',
    'jett',
    'kayo',
    'killjoy',
    'neon',
    'omen',
    'phoenix',
    'raze',
    'reyna',
    'sage',
    'skye',
    'sova',
    'viper',
    'yoru',
  ],
  tKeyBuilder: ValorantagentsF,
  cdnPathBuilder: buildValorantCharIconPath,
};

const rainbowSixSiege: CategoryInput<TRainbowSixSiegeOperators> = {
  keyBuilder: buildRainbowSixSiegeCharacterIconKey,
  categoryId: 'rainbow-six-siege',
  categoryName: 'Rainbow Six Siege',
  tNamespace: RainbowSixSiegeOperatorsNS,
  haystack: [
    'ace',
    'alibi',
    'amaru',
    'ash',
    'bandit',
    'blackbeard',
    'blitz',
    'buck',
    'capitao',
    'castle',
    'caveira',
    'clash',
    'doc',
    'dokkaebi',
    'echo',
    'ela',
    'finka',
    'frost',
    'fuze',
    'glaz',
    'goyo',
    'gridlock',
    'hibana',
    'hole',
    'iana',
    'iq',
    'jackal',
    'jager',
    'kaid',
    'kali',
    'kapkan',
    'lesion',
    'lion',
    'maestro',
    'melusi',
    'mira',
    'montagne',
    'mozzie',
    'mute',
    'nokk',
    'nomad',
    'oryx',
    'pulse',
    'rook',
    'sledge',
    'smoke',
    'tachanka',
    'thatcher',
    'thermite',
    'twitch',
    'valkyrie',
    'vigil',
    'wamai',
    'warden',
    'ying',
    'zofia',
  ],
  tKeyBuilder: RainbowSixSiegeOperatorsF,
  cdnPathBuilder: buildRainbowSixSiegeCharIconPath,
};

export const heroIconCategories = [overwatch, valorant, leagueoflegends, ssbu, rainbowSixSiege] ;
