import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import classNames from 'classnames';
import DropIndicator from 'components/drop-indicator/DropIndicator';
import { canDropOnDirectory } from 'features/dashboard/directory/useDirectoryDropTarget';
import { DragItem, ItemTypes } from 'features/dashboard/dnd/item-types';
import React, { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { FileRejection, useDropzone } from 'react-dropzone';

import DirectoryLinkButton from './DirectoryLinkButton';

interface DirectoryLinkOwnProps {
  className?: string;
  directory: DirectoryFragment;
  active?: boolean;
  onItemDropped?: (directory: DirectoryFragment, item: DragItem) => void;
  onFilesDropped?: (directory: DirectoryFragment, acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
}

type DirectoryLinkProps = DirectoryLinkOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },
}), {name: 'DirectoryLink'});

function DirectoryLink(props: DirectoryLinkProps) {
  const classes = useStyles(props);
  const { className, active, directory, onItemDropped, onFilesDropped } = props;

  const [{canDrop, isOver}, drop] = useDrop({
    accept: [ItemTypes.DIRECTORY_CARD, ItemTypes.VIDEO_CARD],
    canDrop: canDropOnDirectory(directory),
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item: DragItem, monitor) => {
      onItemDropped?.(directory, item);
    },
  });

  const handleFilesDropped = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    onFilesDropped?.(directory, acceptedFiles, rejectedFiles);
  }, [directory, onFilesDropped]);

  const { getRootProps, rootRef, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'video/*',
    onDrop: handleFilesDropped,
    noClick: true,
    noKeyboard: true,
  });

  drop(rootRef);

  return (
    <div {...getRootProps()} className={classNames(classes.root, className)}>
      <DirectoryLinkButton
      key={directory.id}
      active={active}
      directoryId={directory.id}
      directoryName={directory.name}
      canDrop={canDrop}
      isOver={isOver}
      />
      <DropIndicator
      isDragActive={isDragActive}
      isDragAccept={isDragAccept}
      isDragReject={isDragReject}
      />
    </div>
  );
}

export default React.memo(DirectoryLink);
