import { Theme } from '@insights-gaming/theme';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

interface DeleteButtonOwnProps {
  className?: string;
}

type DeleteButtonProps = DeleteButtonOwnProps & ButtonProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.negative.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    '&&:hover': {
      backgroundColor: theme.palette.negative.light,
    },
  },
}), {name: 'DeleteButton'});

function DeleteButton(props: DeleteButtonProps) {
  const classes = useStyles(props);
  const { className, children, ...rest } = props;
  return (
    <Button className={classNames(classes.root, className)} {...rest}>
      {children}
    </Button>
  );
}

export default React.memo(DeleteButton);
