import gql from 'graphql-tag';

export const RateSession_Mutation = gql`
    mutation RateSessionMutation(
        $input: RateSessionInput!
    ) {
        rateSession(
            input: $input
        ) {
            teamId
        }
    }
`;
