import { BidirectionalIDRecord } from '@insights-gaming/redux-utils';
import { ID } from '@insights-gaming/video-upload-slice';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';

function pageIds(record: BidirectionalIDRecord | undefined, page: number, pageSize: number): ID[] {
  return (record?.ids || []).slice(page * pageSize).slice(0, pageSize);
}

export function usePage(
  record: BidirectionalIDRecord | undefined,
  page: number,
  pageSize: number,
): ID[] {
  const [ids, setIds] = useState<ID[]>([]);
  const ref = useRef(ids);

  useEffect(() => {
    const newIds = pageIds(record, page, pageSize);
    if (newIds !== ref.current && !isEqual(newIds, ref.current)) {
      setIds(ref.current = newIds);
    }
  }, [page, pageSize, record]);

  return ids;
}
