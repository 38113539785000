import CircularProgress from '@material-ui/core/CircularProgress';
import FloatingText from 'material/floating-text/FloatingText';
import React from 'react';

function Indeterminate() {
  return (
    <FloatingText>
      <CircularProgress variant='indeterminate' />
    </FloatingText>
  );
}

export default React.memo(Indeterminate);
