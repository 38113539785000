const compareFirstIndexString = ([a]: [string, string], [b]: [string, string]) => a.localeCompare(b);

export function sortParams(params: URLSearchParams): URLSearchParams {
    return new URLSearchParams(Array.from(params).sort(compareFirstIndexString));
}

export function sortStringParams(params: string): string {
    return sortParams(new URLSearchParams(params)).toString();
}

export function sortURLParams(url: string): string {
    const q = url.indexOf('?');
    if (q !== -1) {
        let params = url.slice(q + 1);
        if (params) {
            params = sortStringParams(params);
        }

        url = url.slice(0, q) + '?' + params;
    }

    return url;
}
