import gql from 'graphql-tag';

import { CommentFragment } from '../fragments';

export const AddCommentReply_Mutation = gql`
    mutation AddCommentReplyMutation(
        $input: AddCommentReplyInput!
    ) {
        addCommentReply(
            input: $input
        ) {
            comment {
                ...CommentFragment
                ... on CommentReply {
                    parentComment {
                        id
                        replyCount
                    }
                }
            }
        }
    }
    ${CommentFragment}
`;
