import { CreateTag2_Mutation, DeleteTag_Mutation,UpdateTag_Mutation } from 'apollo/mutations';
import { GetTags_Query } from 'apollo/queries';
import { watchAsyncMutation, watchBidirectionalAC2 } from 'helpers/saga/effects';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { makeGetTeamTagRecordsByTeamId } from './dashboard-tag-selector';
import { createTag2AC, deleteTagAC, fetchTagsAC, updateTagAC } from './dashboard-tag-slice';

export default function* dashboardTagSaga() {
  yield all([
    watchBidirectionalAC2(fetchTagsAC, GetTags_Query, ['queryTags']),
    watchAsyncMutation(createTag2AC, CreateTag2_Mutation, ['createTag2']),
    watchAsyncMutation(updateTagAC, UpdateTag_Mutation, ['updateTag']),
    watchAsyncMutation(deleteTagAC, DeleteTag_Mutation, ['deleteTag']),

    takeEvery(createTag2AC.done, function* ({ payload: { params: { teamId } } }) {
      try {
        // backward fetch to get the newly created tag.
        const selector: ReturnType<typeof makeGetTeamTagRecordsByTeamId> = yield call(
          makeGetTeamTagRecordsByTeamId,
        );
        const teamTagRecords: ReturnType<typeof selector> = yield select(selector, teamId);
        const cursor = teamTagRecords?.backward?.cursor;
        if (cursor) {
          yield put(fetchTagsAC.backward.started({teamId, before: cursor, limit: 2}));
        }
      } catch {
        // do nothing
      }
    }),
  ]);
}
