import React from 'react';
import { TeamInterface } from 'types/pigeon';

import AvatarWithLetterFallback from '../avatar-with-letter-fallback/AvatarWithLetterFallback';
import { AvatarSize } from '../undraggable-avatar/UndraggableAvatar';

export interface ITeamAvatarOwnProps {
  team : TeamInterface;
  size?: AvatarSize;
}

export type TeamAvatarProps = ITeamAvatarOwnProps;

function TeamAvatar({team, size}: TeamAvatarProps) {
  return (
    <AvatarWithLetterFallback alias={team.name} picture={team.picture} size={size} />
  );
}

export default React.memo(TeamAvatar);
