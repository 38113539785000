import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';

import VideoCard from './VideoCard';

interface VideoCardDragPreviewOwnProps {
  className?: string;
  name: string;
  thumbnailUrl?: string;
}

type VideoCardDragPreviewProps = VideoCardDragPreviewOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'VideoCardDragPreview'});

function VideoCardDragPreview(props: VideoCardDragPreviewProps) {
  const classes = useStyles(props);
  const { className, name, thumbnailUrl } = props;

  return (
    <VideoCard
    className={classNames(classes.root, className)}
    name={name}
    thumbnail={thumbnailUrl}
    disabled={true}
    />
  );
}

export default React.memo(VideoCardDragPreview);
