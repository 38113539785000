import { CardFragment } from 'apollo/fragments/types/CardFragment';
import { SavedCardFragment } from 'apollo/fragments/types/SavedCardFragment';
import { TeamSubscriptionFragment } from 'apollo/fragments/types/TeamSubscriptionFragment';
import { GetTeamSubscriptionDetailQuery_getTeamSubscriptionDetail } from 'apollo/queries/types/GetTeamSubscriptionDetailQuery';
import { isExistent } from 'helpers';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

const getTeamInvoiceRecordsByTeamId = (state: RootReducer, teamId: ID) =>
  state.billing.teamInvoiceRecords[teamId];

const getInvoiceDict = (state: RootReducer) => state.billing.invoiceDict;

export const makeGetTeamInvoiceRecordsByTeamId = () => createSelector(
  [getTeamInvoiceRecordsByTeamId],
  (records) => records,
);

export const makeGetTeamInvoicesByTeamId = () => createSelector(
  [getTeamInvoiceRecordsByTeamId, getInvoiceDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) {
      return [];
    }
    return ids.map(id => dict[id]).filter(isExistent);
  },
);

export const getProductDict = (state: RootReducer) => state.billing.productDict;

export const getAvailableBundles = (state: RootReducer) =>
  state.billing.availableBundles;

export const getAdditionalBundles = (state: RootReducer) =>
  state.billing.additionalBundles;

export const getCardDetailDict = (state: RootReducer) => state.billing.cardDetailDict;

export const getCardDetailByTeamId = (
  state: RootReducer,
  teamId: ID,
): [CardFragment | null | undefined, boolean] => [
  state.billing.cardDetailDict[teamId],
  teamId in state.billing.cardDetailDict,
];

export const getTeamCardsByTeamId = (
  state: RootReducer,
  teamId: ID,
): [SavedCardFragment[] | undefined, boolean] => [
  state.billing.teamCardsDict[teamId],
  teamId in state.billing.teamCardsDict,
];

export const makeGetCardDetailByTeamId = () => createSelector(
  [getCardDetailByTeamId],
  (cardDetail) => cardDetail,
);

export const makeGetTeamCardsByTeamId = () => createSelector(
  [getTeamCardsByTeamId],
  (cards) => cards,
);

export const getBillingCycleDict = (state: RootReducer) => state.billing.billingCycleDict;

export const getBillingCycleByTeamId = (
  state: RootReducer,
  teamId: ID,
): [GetTeamSubscriptionDetailQuery_getTeamSubscriptionDetail | undefined, boolean] => [
  state.billing.billingCycleDict[teamId],
  teamId in state.billing.billingCycleDict,
];

export const makeGetBillingCycleByTeamId = () => createSelector(
  [getBillingCycleByTeamId],
  (billingCycle) => billingCycle,
);

export const getTeamBalanceDict = (state: RootReducer) => state.billing.teamBalanceDict;

export const getTeamBalanceByTeamId = (state: RootReducer, teamId: ID): [number | undefined, boolean] => [
  state.billing.teamBalanceDict[teamId],
  teamId in state.billing.teamBalanceDict,
];

export const makeGetTeamBalanceByTeamId = () => createSelector(
  [getTeamBalanceByTeamId],
  (teamBalance) => teamBalance,
);

export const getTeamProrationDict = (state: RootReducer) => state.billing.teamProrationDict;

export const getTeamProrationByTeamId = (state: RootReducer, teamId: ID): [number | undefined, boolean] => [
  state.billing.teamProrationDict[teamId],
  teamId in state.billing.teamProrationDict,
];

export const makeGetTeamProrationByTeamId = () => createSelector(
  [getTeamProrationByTeamId],
  (teamProration) => teamProration,
);

export const getTeamSubscriptionDict = (state: RootReducer) => state.billing.teamSubscriptionDict;

const getTeamSubscriptionByTeamId = (
  state: RootReducer,
  teamId: ID,
): [TeamSubscriptionFragment | undefined, boolean] => [
  state.billing.teamSubscriptionDict[teamId] || undefined,
  teamId in state.billing.teamSubscriptionDict,
];

export const makeGetTeamSubscriptionByTeamId = () => createSelector(
  [getTeamSubscriptionByTeamId],
  (teamSubscription) => teamSubscription,
);

const getAvailableTeamCouponDict = (state: RootReducer) => state.billing.availableTeamCouponDict;
const getTeamIdFromProps = (_: RootReducer, props: { teamId: ID }) => props.teamId;
const getCouponCodeFromProps = (_: RootReducer, props: { couponCode?: string }) => props.couponCode;

export const makeGetAvailableTeamCouponByTeamIdAndCode = () => createSelector(
  [getAvailableTeamCouponDict, getTeamIdFromProps, getCouponCodeFromProps],
  (dict, teamId, couponCode) => {
    const teamCoupons = dict[teamId];
    if (teamCoupons && couponCode) {
      return teamCoupons[couponCode];
    }
    return undefined;
  },
);
