import { grantActionSuccessAC, grantEventReceivedAC, grantQueryAnswerReceivedAC, hostChangedEventAC, IReconnectSessionParams, IReconnectSessionSuccess, KMSessionAction, leaveSessionAsyncAC, reconnectSessionAsyncAC, registerSessionAsyncAC, sessionTerminatedAC, userConnectedEventAC, userDisconnectedEventAC, videoLoadedEventAC } from 'actions/kmsession-actions';
import { logoutAsyncAC } from 'features/auth/auth-slice';
import update, { Spec } from 'immutability-helper';
import { IKMSession, IKMSessionStoreState } from 'types';
import { ID } from 'types/pigeon';
import { IGrantAction, IGrantEvent, IHostChangedEvent, IQueryGrantsAnswerEvent, IRegisterSessionParams, IRegisterSessionSuccess, ISessionProfile, IUserConnectedEvent, IUserDisconnectedEvent, IVideoLoadedEvent } from 'types/pigeon/kmsession';
import { Capability } from 'types/pigeon/kmsession/capabilities';
import { isType, Success } from 'typescript-fsa';

const initialState: () => IKMSessionStoreState = (): IKMSessionStoreState => {
  return {
    session: undefined,
  };
};

export function kmsessionReducer(
  state : IKMSessionStoreState = initialState(),
  action: KMSessionAction,
): IKMSessionStoreState {
  if (isType(action, registerSessionAsyncAC.done)) {
    return registerSessionSuccess(state, action.payload);
  }
  if (isType(action, reconnectSessionAsyncAC.done)) {
    return reconnectSessionSuccess(state, action.payload);
  }
  if (isType(action, leaveSessionAsyncAC.done)) {
    return leaveSessionSuccess(state, action.payload);
  }
  if (isType(action, userConnectedEventAC)) {
    return userConnected(state, action.payload);
  }
  if (isType(action, userDisconnectedEventAC)) {
    return userDisconnected(state, action.payload);
  }
  if (isType(action, videoLoadedEventAC)) {
    return videoLoaded(state, action.payload);
  }
  if (isType(action, hostChangedEventAC)) {
    return hostChanged(state, action.payload);
  }
  if (isType(action, grantEventReceivedAC)) {
    return grantEventReceived(state, action.payload);
  }
  if (isType(action, grantQueryAnswerReceivedAC)) {
    return grantQueryAnswerReceived(state, action.payload);
  }
  if (isType(action, grantActionSuccessAC)) {
    return grantActionSuccess(state, action.payload);
  }
  if (isType(action, sessionTerminatedAC)) {
    return sessionTerminated(state);
  }
  if (isType(action, logoutAsyncAC.done)) {
    return logout();
  }
  return state;
}

function registerSessionSuccess(
  state: IKMSessionStoreState,
  success: Success<IRegisterSessionParams, IRegisterSessionSuccess>,
): IKMSessionStoreState {
  const { token, name, secret } = success.params;
  const { video, initialSessionState: is, websocket, host, team, colorIterator, grants } = success.result;
  const entries = success.result.users.map((p: ISessionProfile): [ID, ISessionProfile] => [p.id, p]);
  const userMap = new Map(entries);

  const session: IKMSession = {
    ws: websocket,
    fallback: video,
    host,
    team,
    userMap,
    usersInChat: new Set(userMap.keys()),
    colorIterator,
    initialSessionState: is,
    token,
    guestName: name,
    secret,
    grants,
  };
  return update(state, {
    session: {$set: session},
  });
}

function reconnectSessionSuccess(
  state  : IKMSessionStoreState,
  success: Success<IReconnectSessionParams, IReconnectSessionSuccess>,
): IKMSessionStoreState {
  const { ws } = success.result;
  return update(state, {session: {$merge: {ws}}});
}

function leaveSessionSuccess(
  state  : IKMSessionStoreState,
  success: Success<any, any>,
): IKMSessionStoreState {
  return update(state, {
    session: {$set: undefined},
  });
}

function userConnected(state: IKMSessionStoreState, payload: IUserConnectedEvent): IKMSessionStoreState {
  return update(state, {
    session: {
      userMap: {$add: [[payload.user.id, payload.user]]},
      usersInChat: {$add: [payload.user.id]},
    },
  });
}

function userDisconnected(state: IKMSessionStoreState, payload: IUserDisconnectedEvent): IKMSessionStoreState {
  if (!state.session) { return state; }
  return update(state, {
    session: {usersInChat: {$remove: [payload.user]}},
  });
}

function videoLoaded(state: IKMSessionStoreState, payload: IVideoLoadedEvent): IKMSessionStoreState {
  return update(state, {
    session: {
      fallback: {$set: payload.video},
    },
  });
}

function hostChanged(state: IKMSessionStoreState, payload: IHostChangedEvent): IKMSessionStoreState {
  return update(state, {
    session: {
      host: {$set: payload.host},
    },
  });
}

function grantEventReceived(state: IKMSessionStoreState, payload: IGrantEvent): IKMSessionStoreState {
  return update(state, {
    session: {
      grants: {
        $add: payload.granted ?? [],
        $remove: payload.revoked ?? [],
      },
    },
  });
}

function grantQueryAnswerReceived(state: IKMSessionStoreState, payload: IQueryGrantsAnswerEvent): IKMSessionStoreState {
  if (Array.isArray(payload.grants)) {
    return update(state, {
      session: {
        grants: {$set: new Set(payload.grants)},
      },
    });
  } else {
    const entries = Object.entries(payload.grants.users)
      .map(([id, cap]): [ID, Set<Capability>] => ([id, new Set(cap)]));
    return update(state, {
      session: {
        perUserGrants: {$set: new Map(entries)},
        globalGrants: {$set: new Set(payload.grants.global)},
      },
    });
  }
}

function grantActionSuccess(state: IKMSessionStoreState, payload: IGrantAction): IKMSessionStoreState {
  const setSpec: Spec<Set<Capability>> = payload.action === 'grant'
    ? {$add: payload.capabilities}
    : {$remove: payload.capabilities};

  const session: Spec<IKMSession> = {};
  if (Array.isArray(payload.targets)) {
    session.perUserGrants = {$add: payload.targets.map(t => ([
      t,
      update(state.session?.perUserGrants?.get(t) ?? new Set(), setSpec),
    ]))};
  } else {
    session.globalGrants = {$set: update(state.session?.globalGrants ?? new Set(), setSpec)};
  }

  return update(state, {session});
}

function sessionTerminated(state: IKMSessionStoreState): IKMSessionStoreState {
  return update(state, {
    session: {$set: undefined},
  });
}

function logout(): IKMSessionStoreState {
  return initialState();
}
