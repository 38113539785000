import StopIcon from '@material-ui/icons/Stop';
import { createColorChangedAction } from 'factories/kmsessionEventFactory';
import { liveSessionSendActionAC } from 'features/live-session/live-session-slice';
import { useIsMobileLandscape, useIsMobilePortrait } from 'features/media-queries/hooks';
import { hexToRGBA } from 'helpers';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import ControlsGenericButton from '../controls/ControlsGenericButton';
import { DrawingState } from './useDrawingState';

export interface DrawingToolbarColorButtonProps {
  state: DrawingState;
  color: string;
  title: string;
  disabled: boolean;
}

function DrawingToolbarColorButton({
  color,
  state: { setColor, color: currentColor },
  title,
  disabled,
}: DrawingToolbarColorButtonProps) {
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    setColor(color);
    dispatch(liveSessionSendActionAC.started(createColorChangedAction(color)));
  }, [setColor, color, dispatch]);

  const isMobilePortrait = useIsMobilePortrait();
  const isMobileLandscape = useIsMobileLandscape();

  const colorSelected = useMemo(() => {
    return (isMobilePortrait || isMobileLandscape) && color === currentColor;
  }, [color, currentColor, isMobileLandscape, isMobilePortrait]);

  return (
    <ControlsGenericButton disabled={disabled} onClick={onClick} title={title} colorSelected={colorSelected}>
      <StopIcon htmlColor={hexToRGBA(color, disabled ? 0.3 : 1)} />
    </ControlsGenericButton>
  );
}

export default React.memo(DrawingToolbarColorButton);
