import { DrawingTool } from '@insights-gaming/drawing-canvas';
import { FlexSpacer, StyledIconButton } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Divider from '@material-ui/core/Divider';
import Portal from '@material-ui/core/Portal';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import BrushIcon from '@material-ui/icons/Brush';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { getIsJoinedLiveSession } from 'features/live-session/live-session-selector';
import { desktop } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { MobileDrawingToolbarContext } from 'features/mobile-drawing-toolbar/MobileDrawingToolbarContext';
import { KeyCommand } from 'keybindings';
import { TVideoplayer, VideoplayerF, VideoplayerNS } from 'locales/en/videoplayer';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getDerivedKeybindingState } from 'selectors/keybinding';
import { Keybinding } from 'types';

import ControlsGenericButton from '../controls/ControlsGenericButton';
import DrawingToolbarActionButton from './DrawingToolbarActionButton';
import DrawingToolbarColorButton from './DrawingToolbarColorButton';
import DrawingToolbarCompositeButton from './DrawingToolbarCompositeButton';
import DrawingToolbarToolButton from './DrawingToolbarToolButton';
import { DrawingState } from './useDrawingState';

interface BaseButtonDefinition {
  titlekey?: TVideoplayer;
  icon?: React.ComponentType<any>;
  iconProps?: any;
  desktopOnly?: boolean;
  isDisabled?: (state: DrawingState) => boolean;
}

interface ToolButtonDefinition extends BaseButtonDefinition {
  type: 'tool';
  tool: DrawingTool;
}

interface ColorButtonDefinition extends BaseButtonDefinition {
  type: 'color';
  color: string;
}

interface ActionButtonDefinition extends BaseButtonDefinition {
  type: 'action';
  action: (controller: DrawingState) => void;
}

interface ComponentButtonDefinition extends BaseButtonDefinition {
  type: 'component';
  component: React.ComponentType<any>;
}

export type ToolButtonDefinitionWithRequiredTitleKey = ToolButtonDefinition & { titlekey: TVideoplayer };

interface CompositeButtonDefinition extends BaseButtonDefinition {
  type: 'composite';
  popupId: string;
  toolDefs: ToolButtonDefinitionWithRequiredTitleKey[];
}

export type ButtonDefinition =
  | ToolButtonDefinition
  | ColorButtonDefinition
  | ActionButtonDefinition
  | ComponentButtonDefinition
  | CompositeButtonDefinition;

export function tooool<TTitleKey extends TVideoplayer | undefined>(
  titlekey: TTitleKey,
  tool: DrawingTool,
  icon: React.ComponentType<any> | undefined,
  desktopOnly: boolean = false,
  iconProps?: any,
): ToolButtonDefinition & { titlekey: TTitleKey } {
  return { type: 'tool', titlekey, tool, icon, iconProps, desktopOnly };
}

export function colour(
  titlekey: TVideoplayer | undefined,
  color: string,
  desktopOnly: boolean = false,
): ColorButtonDefinition {
  return { type: 'color', titlekey, color, desktopOnly };
}

export function action(
  titlekey: TVideoplayer | undefined,
  action: ActionButtonDefinition['action'],
  isDisabled: ActionButtonDefinition['isDisabled'],
  icon: React.ComponentType<any> | undefined,
  iconProps?: any,
  desktopOnly: boolean = false,
): ActionButtonDefinition {
  return { type: 'action', titlekey, action, isDisabled, icon, iconProps, desktopOnly };
}

export function compnt(
  titlekey: TVideoplayer | undefined,
  component: React.ComponentType<any>,
  desktopOnly: boolean = false,
): ComponentButtonDefinition {
  return { type: 'component', titlekey, component, desktopOnly };
}

export function composite(
  titlekey: TVideoplayer | undefined,
  popupId: string,
  toolDefs: ToolButtonDefinitionWithRequiredTitleKey[],
  desktopOnly: boolean = false,
): CompositeButtonDefinition {
  return { type: 'composite', titlekey, popupId, toolDefs, desktopOnly };
};

function defComponent(def: Omit<ButtonDefinition, keyof BaseButtonDefinition>): React.ComponentType<any> {
  switch (def.type) {
    case 'tool': return DrawingToolbarToolButton;
    case 'color': return DrawingToolbarColorButton;
    case 'action': return DrawingToolbarActionButton;
    case 'composite': return DrawingToolbarCompositeButton;
    case 'component': return (def as ComponentButtonDefinition).component;
  }
}

const drawingToolTitlekeyMap = new Map<string | undefined, string>([
  ['tools.pen', 'drawing.tool.pen'],
  ['tools.eraser', 'drawing.tool.eraser'],
  ['tools.select', 'drawing.tool.select'],
  ['tools.rectangle', 'drawing.tool.rectangle'],
  ['tools.ellipse', 'drawing.tool.ellipse'],
  ['tools.line', 'drawing.tool.line'],
  ['tools.arrow', 'drawing.tool.arrow'],
  ['tools.textbox', 'drawing.tool.textbox'],
  ['tools.undo', 'drawing.tool.undo'],
  ['tools.redo', 'drawing.tool.redo'],
  ['tools.closetool', 'drawing.tool.toggle'],
]);

export const convertTitlekeyToHotkey = (keybindingMap: Map<KeyCommand, Keybinding[]>, titlekey?: TVideoplayer) => {
  if (drawingToolTitlekeyMap.has(titlekey)) {
    const keybindings = keybindingMap.get(drawingToolTitlekeyMap.get(titlekey) as KeyCommand);

    if (!keybindings) { return undefined; }

    if (keybindings.length > 1) {
      const hotkey = keybindings.map(keybinding => keybinding.key);
      return hotkey.join(') or (');
    }

    return keybindings[0].key;
  }
  return undefined;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'none',
    '&$enabled': {
      display: 'unset',
    },
    [desktop(theme)]: {
      display: 'unset',
    },
  },
  badge: {
    top: '75%',
  },
  badgeContent: {
    margin: -10,
  },
  drawingToolbar: {
    '&$showMobileDrawingToolbar': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: createRemFromPx(5),
      padding: theme.spacing(0.5, 0.5),
    },
  },
  toggleButton: {
    margin: createRemFromPx(1),
    color: theme.palette.grey[300],
    outline: `${createRemFromPx(1)} solid ${theme.palette.grey[300]}`,
    '&$enabled': {
      outline: 'none',
    },
  },
  enabled: {},
  showMobileDrawingToolbar: {},
}), {name: 'DrawingToolbar'});

export interface DrawingToolbarProps {
  state: DrawingState;
  disabled?: boolean;
  controllable?: boolean;
  buttonDefs: ButtonDefinition[];
  dividers?: boolean;
  displayClose?: boolean;
}

function DrawingToolbar({
  state,
  disabled,
  controllable,
  buttonDefs,
  dividers,
  displayClose = true,
}: DrawingToolbarProps) {
  const classes = useStyles();
  const { t } = useTranslation(VideoplayerNS);
  const isDesktop = useIsDesktop();
  const { mobileToggleElement } = useContext(MobileDrawingToolbarContext);

  const JoinedLiveSession = useSelector(getIsJoinedLiveSession);
  const { keybindingMap } = useSelector(getDerivedKeybindingState);

  const disableDrawing = useCallback(() => {
    state.reset();
    state.setTool(DrawingTool.NONE);
  }, [state]);

  const toggleDrawing = useCallback(() => {
    if (state.enabled) {
      state.reset();
      state.setTool(DrawingTool.NONE);
    } else {
      state.setTool(DrawingTool.DEFAULT);
    }
  }, [state]);

  const showMobileDrawingToolbar = useMemo(() => {
    if (JoinedLiveSession) {
      return state.ready;
    }

    return state.enabled;
  }, [JoinedLiveSession, state.enabled, state.ready]);

  const drawingToolbarButtons = useMemo(() => {
    return buttonDefs.map(({ desktopOnly, titlekey, icon: Icon, iconProps, isDisabled, ...def }, i) => {
      if (desktopOnly && !isDesktop) {
        return null;
      }

      return (
        <React.Fragment key={i}>
          {
            React.createElement(
              defComponent(def),
              {
                title: titlekey && t(VideoplayerF(titlekey), { key: convertTitlekeyToHotkey(keybindingMap, titlekey) }),
                state,
                disabled: disabled || isDisabled?.(state),
                ...def,
              },
              Icon && <Icon {...iconProps} />,
            )
          }
          {dividers && buttonDefs.length - 1 !== i && (
            <Divider flexItem={true} orientation='vertical' variant='fullWidth'/>
          )}
        </React.Fragment>
      );
    });
  }, [buttonDefs, isDesktop, t, keybindingMap, state, disabled, dividers]);

  const close = useMemo((): JSX.Element | void => {
    if (controllable) {
      if (isDesktop) {
        if (state.enabled) {
          return (
            <ControlsGenericButton
            onClick={disableDrawing}
            >
              <Tooltip
              title={t(
                VideoplayerF('tools.closetool'),
                { key: convertTitlekeyToHotkey(keybindingMap, 'tools.closetool') },
              ) || false}
              >
                <CloseIcon htmlColor='#f00' />
              </Tooltip>
            </ControlsGenericButton>
          );
        }
      } else if (mobileToggleElement) {
        return (
          <Portal container={mobileToggleElement}>
            <StyledIconButton
            className={classNames(classes.toggleButton, {[classes.enabled]: state.enabled})}
            size='small'
            onClick={toggleDrawing}
            >
              {state.enabled ? (
                <Tooltip title={t(VideoplayerF('tools.closetool')) || false}>
                  <CloseIcon color='error' />
                </Tooltip>
              ) : (
                <BrushIcon />
              )}
            </StyledIconButton>
          </Portal>
        );
      }
    }
  }, [
    classes.enabled,
    classes.toggleButton,
    controllable,
    disableDrawing,
    isDesktop,
    keybindingMap,
    mobileToggleElement,
    state.enabled,
    t,
    toggleDrawing,
  ]);

  if (isDesktop) {
    return (
      <FlexSpacer
      flexAlignItems='center'
      flexJustifyContent={'flex-start'}
      spacing={1}
      >
        {drawingToolbarButtons}
        {displayClose && close}
      </FlexSpacer>
    );
  }

  return (
    <FlexSpacer
    className={classNames(classes.drawingToolbar, {
      [classes.showMobileDrawingToolbar]: showMobileDrawingToolbar,
    })}
    flexAlignItems='center'
    flexJustifyContent={'space-between'}
    >
      {showMobileDrawingToolbar && drawingToolbarButtons}
      {displayClose && close}
    </FlexSpacer>
  );
}

export default React.memo(DrawingToolbar);
