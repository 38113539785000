import { EnhancedDialogTitle, Loader } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { getLegacyTeamEdgeDict } from 'features/dashboard/access-control/access-control-selector';
import AccessControlInjector from 'features/dashboard/access-control/AccessControlInjector';
import { getTeamSubscriptionDict } from 'features/dashboard/billing/dashboard-billing.selectors';
import { fetchTeamSubscriptionAC } from 'features/dashboard/billing/dashboard-billing-slice';
import useFetchTeamCards from 'features/dashboard/billing/useFetchTeamCards';
import useTeamAvailableBundles from 'features/dashboard/billing/useTeamAvailableBundles';
import useTeamBalance from 'features/dashboard/billing/useTeamBalance';
import useTeamProration from 'features/dashboard/billing/useTeamProration';
import { getTeamsFetchFetching } from 'features/dashboard/team/dashboard-team-selector';
import TeamFetcher from 'fetchers/team-fetcher/TeamFetcher';
import { isTruthy } from 'helpers';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { dashboardRoute, teamRoute } from 'routes';
import { BillingInterval, TeamPrivilege } from 'types/graphql';
import { ID } from 'types/pigeon';

import BillingContents, { BillingContentsLayoutProps } from '../BillingContents';
import SuccessDialog from '../SuccessDialog/SuccessDialog';
import TeamSelector from './TeamSelector';

interface TeamSelectorDialogOwnProps {
  className?: string;
}

type TeamSelectorDialogProps = TeamSelectorDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: createRemFromPx(960),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}), {name: 'TeamSelectorDialog'});

export const CHECKOUT_PATH = '/dashboard/checkout';

interface TeamSelectorDialogLayoutOwnProps {
  className?: string;
  onClose: VoidFunction;
}

function TeamSelectorDialogLayout({
  title,
  loading,
  children,
  className,
  onClose,
}: BillingContentsLayoutProps<TeamSelectorDialogLayoutOwnProps>) {
  return (
    <DialogContent className={className}>
      <EnhancedDialogTitle onClose={onClose}>
        {title}
      </EnhancedDialogTitle>
      {loading ? <div><Loader/></div> : children}
    </DialogContent>
  );
}

function isBillingInterval(interval: string | undefined): interval is BillingInterval {
  return Boolean(interval && BillingInterval[interval]);
};

const CAPTURE_SOURCE = 'insights_capture';
const PREMIUM_COMPLETE = 'insights-capture://premium-complete';

function TeamSelectorDialog(props: TeamSelectorDialogProps) {
  const classes = useStyles(props);
  const { className } = props;
  const onNavigate = useNavigate();
  const { t } = useStrictTranslation(['dialog']);

  const promiseSagaDispatch = usePromiseSagaDispatch();

  const [ step, setStep ] = useState(-1);

  const stepBackward = useCallback(() => setStep((prevStep) => prevStep - 1), []);
  const stepForward = useCallback(() => setStep((prevStep) => prevStep + 1), []);

  const [team, setTeam] = useState<TeamFragment>();

  // prefetch billing info as soon as a team is selected
  useTeamBalance(team?.id);
  useTeamProration(team?.id);
  useFetchTeamCards(team?.id);
  useTeamAvailableBundles(team?.id);

  const onClose = useCallback(() => {
    onNavigate(team ? teamRoute(team.id) : dashboardRoute());
  }, [onNavigate, team]);

  const [interval, productId, source] = useMemo(() => {
    const params = new URLSearchParams(window.location.hash.substring(1));
    const interval = params.get('interval') || undefined;

    return [
      isBillingInterval(interval) ? interval : undefined,
      params.get('productId') || undefined,
      params.get('source') || undefined,
    ];
  }, []);

  const [initializedPlan, setInitializedPlan] = useState(false);
  const shouldSkipPlan = useMemo(
    () => Boolean(!initializedPlan && productId),
    [initializedPlan, productId],
  );

  const teamsLoading = useSelector(getTeamsFetchFetching);
  const teamEdges = useSelector(getLegacyTeamEdgeDict);

  const filteredTeams = useMemo(() => {
    if (!teamEdges) {
      return null;
    }

    return Object.values(teamEdges)
      .filter(isTruthy)
      .filter((te) => te.team.owner.__typename === 'Self' || te.privileges.includes(TeamPrivilege.ACCESS_BILLING))
      .map((te) => te.team);
  }, [teamEdges]);

  const teamSubscriptions = useSelector(getTeamSubscriptionDict);
  const pendingTeamSubscriptions = useRef<Record<ID, true>>({});
  const eligibleTeams = useMemo(() => {
    if (!teamSubscriptions || !filteredTeams || teamsLoading) {
      return null;
    }

    const teamsWithNulls = filteredTeams.flatMap((team) => {
      if (team.id in teamSubscriptions) {
        if (teamSubscriptions[team.id]) {
          return [null];
        }

        return [team];
      }

      if (!pendingTeamSubscriptions.current[team.id]) {
        pendingTeamSubscriptions.current[team.id] = true;
        promiseSagaDispatch(fetchTeamSubscriptionAC, { teamId: team.id }).then(
          () => {
            delete pendingTeamSubscriptions.current[team.id];
          },
        );
      }

      return [];
    });

    if (teamsWithNulls.length !== filteredTeams.length) {
      // some subscriptions still being fetched
      return null;
    }

    return teamsWithNulls.filter(isTruthy);
  }, [filteredTeams, pendingTeamSubscriptions, promiseSagaDispatch, teamSubscriptions, teamsLoading]);

  const [isSuccessDialogOpen, _openSuccessDialog, _closeSuccessDialog] = useDialogState();

  useEffect(() => {
    if (eligibleTeams?.length === 1 && !team) {
      setTeam(eligibleTeams[0]);
      setStep(0);
    }
  }, [team, eligibleTeams]);

  useEffect(() => {
    if (step !== 0 || !shouldSkipPlan) {
      return;
    }

    setStep(1);
    setInitializedPlan(true);
  }, [shouldSkipPlan, step]);

  const openSuccessDialog = useCallback(() => {
    _openSuccessDialog();

    if (source === CAPTURE_SOURCE) {
      window.location.href = PREMIUM_COMPLETE;
    }
  }, [_openSuccessDialog, source]);

  const closeSuccessDialog = useCallback(() => {
    _closeSuccessDialog();
    onClose();
  }, [_closeSuccessDialog, onClose]);

  const overrideTitle = useCallback((step: number): React.ReactNode => {
    if (step === 0) {
      return (
        <Button
        startIcon={<ArrowBackIosIcon/>}
        variant='text'
        onClick={stepBackward}
        >
          {t('dialog:selectteam.title')}
        </Button>
      );
    }

    return null;
  }, [stepBackward, t]);

  return (
    <React.Fragment>
      <TeamFetcher />
      {
        !isSuccessDialogOpen && (
          <Dialog open={true} maxWidth='lg'>
            {
              (!team || step < 0) ? (
                <TeamSelectorDialogLayout
                className={classNames(classes.root, className)}
                title={t(
                  (eligibleTeams?.length ?? true)
                    ? 'dialog:selectteam.selectupgrade'
                    : 'dialog:selectteam.createupgrade',
                )}
                onClose={onClose}
                loading={!eligibleTeams}
                >
                  {
                    eligibleTeams && (
                      <TeamSelector
                      stepForward={stepForward}
                      selectedTeamId={team?.id}
                      teams={eligibleTeams}
                      setSelectedTeam={setTeam}
                      totalTeams={teamEdges && Object.keys(teamEdges).length}
                      />
                    )
                  }
                </TeamSelectorDialogLayout>
              ) : (
                <AccessControlInjector teamId={team.id}>
                  <BillingContents
                  Layout={TeamSelectorDialogLayout}
                  layoutProps={{
                    className: classNames(classes.root, className),
                    onClose,
                  }}
                  step={step}
                  onStepChange={setStep}
                  onSuccess={openSuccessDialog}
                  onClose={onClose}
                  overrideTitle={overrideTitle}
                  team={team}
                  interval={interval}
                  productId={productId}
                  source={source}
                  />
                </AccessControlInjector>
              )
            }
          </Dialog>
        )
      }
      {team && (
        <SuccessDialog
        open={isSuccessDialogOpen}
        onClose={closeSuccessDialog}
        update={false}
        team={team}
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(TeamSelectorDialog);
