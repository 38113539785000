import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import classNames from 'classnames';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DialogF, DialogNS } from '../../../locales/en/dialog';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(2, 1),
  },
  paper: {
    border: `1px solid ${theme.palette.grey[700]}`,
  },
}), {name: 'Requirements'});

function Requirements(props: {className?: string}) {
  const popupState = usePopupState({popupId: 'requirements', variant: 'popover'});
  const { t } = useTranslation(DialogNS);
  const classes = useStyles(props);
  return (
    <FlexSpacer flexJustifyContent='flex-end'>
      <div {...bindHover(popupState)}>
        <FlexSpacer>
          <Typography variant='caption'>
            {t(DialogF('upload.videorequirements'))}
          </Typography>
          <HelpIcon fontSize='small' />
        </FlexSpacer>
      </div>
      <Popover
      {...bindPopover(popupState)}
      style={{pointerEvents: 'none'}}
      classes={{
        paper: classes.paper,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      >
        <Contents className={classes.content} />
      </Popover>
    </FlexSpacer>
  );
};

const useContentsStyles = makeStyles((theme: Theme) => ({
  root: {},
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
}), {name: 'Contents'});

function Contents(props: {className?: string}) {
  const classes = useContentsStyles(props);
  const { className } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <ul className={classes.list}>
        <li>
          <Trans ns={DialogNS} i18nKey='upload.requirement1'>
            Point of View - <strong>Third/Spectator</strong>
          </Trans>
        </li>
        <li>
          <Trans ns={DialogNS} i18nKey='upload.requirement2'>
            Aspect Ratio - <strong>16:9</strong>
          </Trans>
        </li>
        <li>
          <Trans ns={DialogNS} i18nKey='upload.requirement3'>
            Video Quality - <strong>720p or higher</strong>
          </Trans>
        </li>
        <li>
          <Trans ns={DialogNS} i18nKey='upload.requirement4'>
            Team Colors - <strong>Default (Blue and Red)</strong>
          </Trans>
        </li>
      </ul>
    </div>
  );
}

export default React.memo(Requirements);
