import { subscribeToVideoEventsAC, unsubscribeToVideoEventsAC } from 'actions/event-actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

export function useSubscribeToVideoEvents(videoId: ID) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(subscribeToVideoEventsAC({videoId}));
    return () => {
      dispatch(unsubscribeToVideoEventsAC({videoId}));
    };
  }, [dispatch, videoId]);
}
