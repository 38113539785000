import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import { fetchUnreadFolderNotificationsAC } from '../notifications-slice';

interface UnreadFolderNotificationsFetcherOwnProps {
  folderId: ID;
  teamId: ID;
}

type UnreadFolderNotificationsFetcherProps = UnreadFolderNotificationsFetcherOwnProps;

function UnreadFolderNotificationsFetcher(props: UnreadFolderNotificationsFetcherProps) {
  const { folderId,teamId } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUnreadFolderNotificationsAC.started({folderId, teamId}));
  }, [dispatch, teamId, folderId]);

  return null;
}

export default React.memo(UnreadFolderNotificationsFetcher);
