import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import { OptionType } from 'material/multi-select/MultiSelect';
import React from 'react';
import { MultiValueProps } from 'react-select/src/components/MultiValue';

export default function MultiValueMemberChip(props: MultiValueProps<OptionType>) {
  return (
    <Chip
    tabIndex={-1}
    label={props.children}
    className={classNames(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused,
    })}
    onDelete={props.removeProps.onClick}
    deleteIcon={<CancelIcon {...props.removeProps} />}
    variant='outlined'
    />
  );
}
