import gql from 'graphql-tag';

import { RoleInterfaceFragment } from '../fragments';

export const UpdateCustomRole_Mutation = gql`
    mutation UpdateCustomRoleMutation(
        $input: UpdateCustomRoleInput!
    ) {
        updateCustomRole(
            input: $input
        ) {
            role {
                ...RoleInterfaceFragment
            }
        }
    }
    ${RoleInterfaceFragment}
`;
