import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

import InvitationTableRow from './InvitationTableRow';

interface InvitationTableRowSkeletonOwnProps {
  className?: string;
}

type InvitationTableRowSkeletonProps = InvitationTableRowSkeletonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'InvitationTableRowSkeleton'});

function InvitationTableRowSkeleton(props: InvitationTableRowSkeletonProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <InvitationTableRow
    link={<Skeleton variant='text' />}
    attachedRole={<Skeleton variant='text' />}
    expiry={<Skeleton variant='text' />}
    limit={<Skeleton variant='text' />}
    creator={<Skeleton variant='text' />}
    action={<Skeleton variant='text' />}
    mobileAction={<Skeleton variant='text' />}
    />
  );
}

export default React.memo(InvitationTableRowSkeleton);
