import { FlexSpacer, Loader } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import classNames from 'classnames';
import { ENotificationSelect, ENotificationState } from 'components/settings/notifications/constants';
import {
  makeGetAggregatedNotifications,
  makeGetAllNotifications,
  makeGetUnreadAggregatedNotifications,
  makeGetUnreadNotifications,
} from 'components/settings/notifications/notifications-selector';
import { resetUnreadNotificationsAC, updateNotificationsAC } from 'components/settings/notifications/notifications-slice';
import { useFetchNotifications } from 'components/settings/notifications/useFetchNotification';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ESettingsTabType, settingsRoute } from 'routes';
import { UpdateNotificationInput } from 'types/graphql';

import NotificationList from './NotificationList';

interface NotificationsListContainerOwnProps {
  className?: string;
}

type NotificationsListContainerProps = NotificationsListContainerOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  listHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    paddingTop: theme.spacing(1),
    alignItems: 'center',
  },
  notificationList: {
    maxHeight: createRemFromPx(400),
    maxWidth: createRemFromPx(350),
    minWidth: createRemFromPx(350),
    overflowY: 'auto',
  },
  listFooter: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1, 0),
  },
  viewNotificationsButton: {
    fontSize: createRemFromPx(14),
    fontWeight: 600,
  },
}), {name: 'NotificationsListContainer'});

function NotificationsListContainer(props: NotificationsListContainerProps) {
  const classes = useStyles(props);
  const { className } = props;

  const { t } = useStrictTranslation(['notification']);
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const onNavigate = useNavigate();
  const dispatch = useDispatch();

  const [ loading, setLoading ] = useState(false);

  const [ notificationForwardStatus ] = useFetchNotifications({});

  const unreadNotifications: NotificationFragment[] = useCreateSelector(makeGetUnreadNotifications, '');
  const aggregatedNotifications: NotificationFragment[][] = useCreateSelector(makeGetAggregatedNotifications, '');
  const notifications: NotificationFragment[] = useCreateSelector(makeGetAllNotifications, '');
  const unreadAggregatedNotifications: NotificationFragment[][] = useCreateSelector(
    makeGetUnreadAggregatedNotifications,
    '',
  );

  const initialFetching = notificationForwardStatus.fetching && !notificationForwardStatus.cursor;
  const [ notificationSelect, setNotificationSelect ] = useState<ENotificationSelect>(ENotificationSelect.ALL);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    setNotificationSelect(event.target.value as ENotificationSelect);
  }, []);

  const handleViewAllOnClick = useCallback(() => {
    onNavigate(settingsRoute(ESettingsTabType.NOTIFICATIONS));
  }, [onNavigate]);

  const handleMarkAllAsRead = useCallback(async () => {
    if (loading) { return; }
    setLoading(true);

    try {
      const inputs: UpdateNotificationInput[] = notifications.map(n => ({
        notificationId: n.id,
        notificationState: ENotificationState.READ,
      }));
      await promiseSagaDispatch(updateNotificationsAC, inputs);
      dispatch(resetUnreadNotificationsAC());
    } catch(error) {
    } finally {
      setLoading(false);
    }
  }, [loading, promiseSagaDispatch, dispatch, notifications]);

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.listHeader}>
        <Typography>{t('notification:notifications.title')}</Typography>
        <FlexSpacer>
          <Select
          value={notificationSelect}
          onChange={handleChange}
          >
            {Object.keys(ENotificationSelect).map((k) => (
              <MenuItem value={ENotificationSelect[k]} key={k}>{ENotificationSelect[k]}</MenuItem>
            ))}
          </Select>
          {loading ? (
            <Loader size={20} />
          ) : (
            <Tooltip title={t('notification:notifications.markallasread')} placement='top'>
              <IconButton size='small' onClick={handleMarkAllAsRead} disabled={!unreadNotifications.length}>
                <CheckIcon />
              </IconButton>
            </Tooltip>
          )}
        </FlexSpacer>
      </div>
      <div className={classes.notificationList}>
        <NotificationList
        aggregatedNotifications={
          notificationSelect === ENotificationSelect.ALL
          ? aggregatedNotifications
          : unreadAggregatedNotifications
        }
        displaySkeleton={initialFetching}
        />
      </div>
      <div className={classes.listFooter}>
        <Button onClick={handleViewAllOnClick} size='small'>
          <Typography className={classes.viewNotificationsButton}>
            {t('notification:notifications.viewallnotifications')}
          </Typography>
        </Button>
      </div>
    </div>
  );
}

export default React.memo(NotificationsListContainer);
