import { makeProductTourHooks } from '@insights-gaming/product-tour';

import { productTourSelectors } from './product-tour-selectors';

export const {
  useMultiStepProductTour,
  useProductTourOrder,
  useProductTourState,
  useSingleStepProductTour,
} = makeProductTourHooks(productTourSelectors);
