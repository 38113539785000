import { FlexSpacer, VerticalScroll } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import classNames from 'classnames';
import { desktop, mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import chunk from 'lodash/chunk';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { exid, ID } from 'types/pigeon';

import DirectoryGrid, { DirectoryGridProps } from './DirectoryGrid';

interface PagedDirectoryGridOwnProps {
  className?: string;
  onPageIdsChange?: (pageNumber: number, ids: ID[]) => void;
}

type PagedDirectoryGridProps = PagedDirectoryGridOwnProps & DirectoryGridProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    overflow: 'hidden',
    '&$hasContent': {
      [desktop(theme)]: {
        minHeight: 56,
      },
      [mobilePortrait(theme)]: {
        overflow: 'visible',
      },
      [mobileLandscape(theme)]: {
        minHeight: 96,
      },
    },
  },
  hasContent: {},
}), {name: 'PagedDirectoryGrid'});

function PagedDirectoryGrid(props: PagedDirectoryGridProps) {
  const classes = useStyles(props);
  const { className, directories, onPageIdsChange, ...rest } = props;

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const isDesktop = useIsDesktop();

  const directoriesPerPage = useMemo(() => isDesktop ? 12 : 4, [isDesktop]);
  const pages = useMemo(() => chunk(directories, directoriesPerPage), [directories, directoriesPerPage]);
  const [page, setPage] = useState(1);
  const directoriesOnPage = useMemo(() => pages[page - 1] || [], [pages, page]);

  const pageIds = useMemo(() => directoriesOnPage.map(exid), [directoriesOnPage]);

  const scrollToTop = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }
    scrollRef.current.scroll({top: 0});
  }, []);

  useEffect(() => {
    onPageIdsChange?.(page, pageIds);
  }, [onPageIdsChange, page, pageIds]);

  const handlePageChange = useCallback((__, value: number) => {
    setPage(value);
    scrollToTop();
  }, [scrollToTop]);

  return (
    <FlexSpacer
    orientation='vertical'
    className={classNames(
      classes.root,
      {
        [classes.hasContent]: rest.displaySkeleton || directoriesOnPage.length,
      },
      className,
    )}
    >
      <VerticalScroll ref={scrollRef}>
        <DirectoryGrid
        directories={directoriesOnPage}
        {...rest}
        />
      </VerticalScroll>
      {pages.length > 1 && (
        <Pagination page={page} count={pages.length} onChange={handlePageChange} />
      )}
    </FlexSpacer>
  );
}

export default React.memo(PagedDirectoryGrid);
