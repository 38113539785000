import { makeProductTourSlice } from '@insights-gaming/product-tour';

import { ProductTourId } from './product-tour-ids';

const {
  productTourActions,
  productTourSlice,
} = makeProductTourSlice<ProductTourId>({});

export const productTourReducer = productTourSlice.reducer;
export const { productTourViewed } = productTourSlice.actions;
export const { loadViewedProductTourDatabaseAC } = productTourActions;
