import React from 'react';
import { ID } from 'types/pigeon';

import { useFetchTeamVideos } from './useFetchTeamVideos';

interface TeamVideoFetcherOwnProps {
  teamId: ID;
}

type TeamVideoFetcherProps = TeamVideoFetcherOwnProps;

function TeamVideoFetcher(props: TeamVideoFetcherProps) {
  const { teamId } = props;

  useFetchTeamVideos(teamId);

  return null;
}

export default React.memo(TeamVideoFetcher);
