import gql from 'graphql-tag';

import { MemberFragment } from '../fragments';

export const AddMemberRoles_Mutation = gql`
    mutation AddMemberRolesMutation(
        $input: AddMemberRolesInput!
    ) {
        addMemberRoles(
            input: $input
        ) {
            member {
                ...MemberFragment
            }
        }
    }
    ${MemberFragment}
`;
