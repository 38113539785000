import { convertFromRaw, EditorState } from 'draft-js';
import React, { useMemo } from 'react';

import { GetMembersQuery_queryMembers_edges } from '../../../../apollo/queries/types/GetMembersQuery';
import { decorator } from '../../../../subcomponents/text-editor-with-tools/strategies/strategies';
import MDComment from './MDComment';
import WYSIComment from './WYSIComment';

export interface ICommentOwnProps {
  text: string;
  members?: GetMembersQuery_queryMembers_edges[];
}

type Props = ICommentOwnProps;

function Comment(props: Props) {
  const { members, text } = props;
  try {
    const initialEditorState = useMemo(
      () => EditorState.createWithContent(convertFromRaw(JSON.parse(text)), decorator),
      [text],
    );
    return (
      <WYSIComment initialEditorState={initialEditorState} />
    );
  } catch {
    return (
      <MDComment text={text} members={members} />
    );
  }
}

export default React.memo(Comment);
