import { AsyncButton } from '@insights-gaming/material-components';
import { GetTeamSubscriptionDetailQuery_getTeamSubscriptionDetail } from 'apollo/queries/types/GetTeamSubscriptionDetailQuery';
import { clearTeamBillingStateAC } from 'features/dashboard/billing/dashboard-billing-slice';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import { useSubscriptionMutationResultHandler } from '../useSubscriptionMutationResultHandler';

export interface AuthorizePaymentButtonOwnProps {
  teamId: ID;
  teamSubscriptionDetails: GetTeamSubscriptionDetailQuery_getTeamSubscriptionDetail;
};

export type AuthorizePaymentButtonProps = AuthorizePaymentButtonOwnProps;

export default function AuthorizePaymentButton(props: AuthorizePaymentButtonProps) {
  const {
    teamId,
    teamSubscriptionDetails,
  } = props;

  const [ loading, setLoading ] = useState(false);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useStrictTranslation(['dashboard']);

  const onSuccess = useCallback(() => {
    dispatch(clearTeamBillingStateAC({ teamId }));
  }, [dispatch, teamId]);

  const onError = useCallback((err?: { message?: string }) => {
    enqueueSnackbar(err?.message ?? 'failed to authorize payment', { variant: 'error' });
  }, [enqueueSnackbar]);

  const handleSubscriptionMutationResult = useSubscriptionMutationResultHandler(teamId, onSuccess, onError);

  const handleClick = useCallback(async () => {
    setLoading(true);
    handleSubscriptionMutationResult(teamSubscriptionDetails).finally(() => setLoading(false));
  }, [handleSubscriptionMutationResult, teamSubscriptionDetails]);

  return (
    <AsyncButton
    type='submit'
    color='primary'
    disabled={loading}
    variant='contained'
    loading={loading}
    onClick={handleClick}
    >
      {t('dashboard:billing.retrypayment')}
    </AsyncButton>
  );
}
