import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import classNames from 'classnames';
import { EIntercomID } from 'constants/strings';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { makeGetTeamCommentLabelsByTeamId } from 'features/dashboard/tag/dashboard-tag-selector';
import TagManagerDialog from 'features/dashboard/tag/tag-manager-dialog/TagManagerDialog';
import { mobilePortrait } from 'features/media-queries';
import { useDialogState } from 'hooks/useDialogState';
import { usePopupStateKeybindingHelper } from 'hooks/usePopupStateKeybindingHelper';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useCallback, useMemo } from 'react';
import { ID } from 'types/pigeon';

import CommentLabelListItem from '../comment-label-list-item/CommentLabelListItem';

interface CommentLabelEditorOwnProps {
  className?: string;
  teamId: ID;
  handleAddLabel: (label: TagFragment) => void;
  selectedLabelsToAdd: Set<TagFragment>;
}

type CommentLabelEditorProps = CommentLabelEditorOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  labelButton: {
    [mobilePortrait(theme)]: {
      padding: theme.spacing(0, 1.5),
    },
  },
  labelMenu: {
    maxWidth: theme.spacing(25),
  },
}), {name: 'CommentLabelEditor'});

function CommentLabelEditor(props: CommentLabelEditorProps) {
  const classes = useStyles(props);
  const { className, teamId, handleAddLabel, selectedLabelsToAdd } = props;
  const { t } = useStrictTranslation(['video']);
  const commentLabels = useCreateSelector(makeGetTeamCommentLabelsByTeamId, teamId);
  const { canAssignCommentLabel } = useAccessControl();
  const [ isLabelManagerDialogOpen, openLabelManagerDialog, closeLabelManagerDialog] = useDialogState();
  const popupState = usePopupState({popupId: 'add-label', variant: 'popover'});
  usePopupStateKeybindingHelper(popupState, 'menu.open');

  const handleChangeLabelOnClick = useCallback((label: TagFragment) => {
    handleAddLabel(label);
    popupState.close();
  }, [handleAddLabel, popupState]);

  const filteredLabels = useMemo(() => {
    const selectedIds = Array.from(selectedLabelsToAdd).map(l => l.id);
    return commentLabels.filter(l => !selectedIds.includes(l.id));
  }, [commentLabels, selectedLabelsToAdd]);

  return canAssignCommentLabel ? (
    <div className={classNames(classes.root, className)}>
      <React.Fragment>
        <Button
        className={classes.labelButton}
        id={EIntercomID.ADD_LABEL}
        {...bindTrigger(popupState)}
        startIcon={<AddIcon />}
        variant='outlined'
        >
          {t('video:commentlabel.addlabel')}
        </Button>
        <Menu
        {...bindMenu(popupState)}
        classes={{
          paper: classes.labelMenu,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        >
          {filteredLabels.map(label => (
            <CommentLabelListItem
            key={label.id}
            label={label}
            handleLabelOnChange={handleChangeLabelOnClick}
            />
          ))}
          <MenuItem onClick={openLabelManagerDialog}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={t('video:commentlabel.managelabels')} />
          </MenuItem>
        </Menu>
      </React.Fragment>
      <TagManagerDialog
      open={isLabelManagerDialogOpen}
      teamId={teamId}
      tags={commentLabels}
      onClose={closeLabelManagerDialog}
      isLabel={true}
      />
    </div>
  ) : null;
}

export default React.memo(CommentLabelEditor);
