import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { RoleInterfaceFragment_GlobalRole } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { EIntercomID } from 'constants/strings';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React from 'react';

import GlobalRoleRowSkeleton from './GlobalRoleRowSkeleton';
import GlobalRoleTableRowWrapper from './GlobalRoleTableRowWrapper';

interface GlobalRoleTableOwnProps {
  className?: string;
  displaySkeleton?: boolean;
  globalRoles: RoleInterfaceFragment_GlobalRole[];
  team: TeamFragment;
}

type GlobalRoleTableProps = GlobalRoleTableOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'GlobalRoleTable'});

function GlobalRoleTable(props: GlobalRoleTableProps) {
  const classes = useStyles(props);
  const { className, displaySkeleton, globalRoles, team } = props;
  const { t } = useStrictTranslation(['dashboard']);
  return (
    <Table id={EIntercomID.GLOBAL_ROLES_TABLE} className={classNames(classes.root, className)} size='small'>
      <TableHead>
        <TableRow>
          <FixedTableCell width={100}>{t('dashboard:team.roles.defaultrole')}</FixedTableCell>
          <FixedTableCell width={200}>{t('dashboard:team.roles.privileges')}</FixedTableCell>
          <FixedTableCell width={100}>{t('dashboard:team.roles.description')}</FixedTableCell>
          <FixedTableCell width={100} align='center'></FixedTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {displaySkeleton ? (
          Array.from({length: 2}).map((_, i) => (
            <GlobalRoleRowSkeleton key={i} />
          ))
        ) : (
          globalRoles.map((role, i) => (
            <GlobalRoleTableRowWrapper
            key={i}
            globalRole={role}
            team={team}
            />
          ))
        )}
      </TableBody>
    </Table>
  );
}

export default React.memo(GlobalRoleTable);
