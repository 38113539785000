import { VideoFragment_latestAnalysis } from 'apollo/fragments/types/VideoFragment';
import { isExistent } from 'helpers';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';

import { exid, ID, Match } from '../../types/pigeon';

export const getMatchCache = (state: RootReducer): Dictionary<Match> => state.statisticsReducer.matchCache;
export const getMatchById = (state: RootReducer, matchId?: ID) => matchId ? getMatchCache(state)[matchId] : undefined;

const getAnalysisFromProps = (
  _: RootReducer,
  props: {analysis: VideoFragment_latestAnalysis | null},
) => props.analysis;

export const makeGetAnalysisMatches = () => createSelector(
  [getMatchCache, getAnalysisFromProps],
  (matchCache, analysis) => {
    const { completed, result } = analysis || {};
    if (!(completed && result)) {
      return undefined;
    }
    return result.matches.filter(isExistent).map(exid).map((id) => matchCache[id]).filter(isExistent);
  },
);

export const makeGetMatchById = () => createSelector(
  [getMatchById],
  (match) => match,
);
