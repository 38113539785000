import gql from 'graphql-tag';

import { SelfFragment } from '../fragments';

export const UpdateUser_Mutation = gql`
    mutation UpdateUserMutation(
        $input: UpdateUser2Input!
    ) {
        updateUser2(
            input: $input
        ) {
            me {
                ...SelfFragment
            }
        }
    }
    ${SelfFragment}
`;
