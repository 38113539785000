import { callAsync, watchAsync } from '@insights-gaming/saga-utils';
import {
  addCommentReplyAC,
  addVideoCommentAC,
  deleteCommentAC,
  updateCommentLikedAC,
  updateCommentReplyAC,
  updateVideoCommentAC,
} from 'actions/comment-actions';
import { AddCommentReply_Mutation, AddVideoComment_Mutation, DeleteComment_Mutation, UpdateCommentLiked_Mutation,UpdateCommentReply_Mutation,UpdateVideoComment_Mutation } from 'apollo/mutations';
import { GetCommentReplies_Query, GetCommentRepliesByIds_Query, GetCommentTags_Query, GetLikedUsers_Query,GetVideoComments_Query,GetVideoCommentsByIds_Query } from 'apollo/queries';
import {
  fetchCommentRepliesAC,
  fetchCommentReplyByIdAC,
  fetchCommentReplyByIdsAC,
  fetchCommentTagsAC,
  fetchLikedUsersByCommentIdAC,
  fetchVideoCommentByIdAC,
  fetchVideoCommentByIdsAC,
  fetchVideoCommentByIdWithVideoAC,
  fetchVideoCommentsAC,
  fetchVideoCommentsByIdsWithVideoAC,
} from 'components/video/video-replay/video-comment/video-comment-slice';
import { fetchVideoByIdWithCommentsByIdsAC } from 'features/dashboard/video/dashboard-video-slice';
import { watchAsyncBatchedQuery, watchAsyncMutation, watchAsyncQuery, watchBidirectionalAC2 } from 'helpers/saga/effects';
import { SagaIterator } from 'redux-saga';
import { all } from 'redux-saga/effects';

export default function* commentSaga() {
  yield all([
    watchBidirectionalAC2(fetchVideoCommentsAC, GetVideoComments_Query, ['video', 'queryComments']),
    watchBidirectionalAC2(fetchCommentRepliesAC, GetCommentReplies_Query, ['video', 'comment', 'queryReplies']),
    watchBidirectionalAC2(fetchCommentTagsAC, GetCommentTags_Query, ['video', 'comment', 'queryTags']),
    watchAsyncQuery(fetchLikedUsersByCommentIdAC, GetLikedUsers_Query, ['video','comment','queryLikedUsers','users']),
    watchAsyncQuery(fetchVideoCommentByIdsAC, GetVideoCommentsByIds_Query, ['video','commentsByIds']),
    watchAsyncQuery(fetchCommentReplyByIdsAC, GetCommentRepliesByIds_Query, ['video','comment','repliesByIds']),
    watchAsyncBatchedQuery(
      100,
      fetchVideoCommentByIdAC,
      fetchVideoCommentByIdsAC,
      (params) => ({ videoId: params[0].videoId, commentIds: params.map(({ commentId }) => commentId) }),
      ({ payload }) => payload.videoId,
    ),
    watchAsyncBatchedQuery(
      100,
      fetchCommentReplyByIdAC,
      fetchCommentReplyByIdsAC,
      (params) => ({
        videoId: params[0].videoId,
        commentId: params[0].commentId,
        replyIds: params.map(({ replyId }) => replyId),
      }),
      ({ payload }) => payload.videoId + ':' + payload.commentId,
    ),
    watchAsyncBatchedQuery(
      100,
      fetchVideoCommentByIdWithVideoAC,
      fetchVideoCommentsByIdsWithVideoAC,
      (p) => ({ videoId: p[0].videoId, commentIds: p.map((v) => v.commentId) }),
      ({ payload }) => payload.videoId,
    ),
    watchAsyncMutation(addVideoCommentAC, AddVideoComment_Mutation, ['addVideoComment']),
    watchAsyncMutation(addCommentReplyAC, AddCommentReply_Mutation, ['addCommentReply']),
    watchAsyncMutation(updateVideoCommentAC, UpdateVideoComment_Mutation, ['updateVideoComment']),
    watchAsyncMutation(updateCommentReplyAC, UpdateCommentReply_Mutation, ['updateCommentReply']),
    watchAsyncMutation(deleteCommentAC, DeleteComment_Mutation, ['deleteComment']),
    watchAsyncMutation(updateCommentLikedAC, UpdateCommentLiked_Mutation, ['updateCommentLiked']),

    watchAsync(fetchVideoCommentsByIdsWithVideoAC, function* (params): SagaIterator {
      return (yield callAsync(fetchVideoByIdWithCommentsByIdsAC, params)).commentsByIds;
    }),
  ]);
}
