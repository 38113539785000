import { Theme } from '@insights-gaming/theme';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';
import React from 'react';

import { placeholder } from '../../assets';
import { AntiHeal, Freeze, Hack, Skull, Sleep, Stunned, UnknownStatus } from '../../assets/overwatch-status';
import { defaultIconOptions, overwatchHeroIconPath } from '../../helpers';
import UndraggableAvatar from '../../material/undraggable-avatar/UndraggableAvatar';
import ImageLoader from './image-loader/ImageLoader';

export enum Status {
  FROZEN = 'frozen',
  HACK = 'hack',
  SLEEP = 'sleep',
  STUNNED = 'stunned',
  ANTI_HEAL = 'Anti-Heal',
}

export interface IHeroImageOwnProps {
  team: 'blue' | 'red';
  dead?: boolean;
  ultimate?: 'ready' | 'charging';
  status?: Status;
  hero?: string;
  size?: 'xs' | 'sm' | 'default';
  copy?: string;
  variant?: 'circle' | undefined;
}

export type IHeroImageProps = IHeroImageOwnProps &
  WithStyles<typeof styles>;

const styles = (theme: Theme) => {
  return createStyles({
    sm: {
      width: '40px',
      height: '24px',
    },
    default: {
      width: '46px',
      height: '30px',
    },
    heroImage: {
      objectFit: 'cover',
    },
    blue: {
      border: `2px solid ${theme.palette.overwatch.blueTeam}`,
    },
    red: {
      border: `2px solid ${theme.palette.overwatch.redTeam}`,
    },
    noTeam: {
      border: '2px solid transparent',
    },
    dead: {
      opacity: 0.5,
    },
    status: {
      width: '100%',
      height: '100%',
      fill: theme.palette.text.primary,
    },
    skull: {
      width: '100%',
      height: '100%',
      fill: 'red',
    },
    '@keyframes fade': {
      from: {opacity: 1},
      to: {opacity: 0},
    },
    overlay: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: '50%',
      height: '100%',
      margin: 'auto',
    },
    poof: {
      position: 'relative',
      animation: '$puff-in-center 0.75s cubic-bezier(0.175, 0.885, 0.320, 1.275), $fade 0.5s ease-out both',
    },
    '@keyframes puff-in-center': {
      '0%': {
        transform: 'scale(2)',
        filter: 'blur(2px)',
        opacity: 0,
      },
      '100%': {
        transform: 'scale(1)',
        filter: 'blur(0px)',
        opacity: 1,
      },
    },
  });
};

class HeroImage extends React.Component<IHeroImageProps> {
  public render() {
    const { ultimate, dead, status, hero, classes, size = 'default', team } = this.props;
    return (
      <Box position='relative' display='flex' justifyContent='center'>
        { hero ?
          this.renderHero(ultimate)
        :
          <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          className={classNames(classes[team], classes[size])}
          >
            <ImageLoader />
          </Box>
        }
        <div className={classes.overlay}>
          {dead && this.renderSkull()}
          {!dead && status &&
            <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            >
              {this.renderStatus(status)}
            </Box>}
        </div>
      </Box>
    );
  }

  private renderHero = (status?: string) => {
    const { hero, classes, size = 'default', team, copy, variant } = this.props;
    const variant_shape = variant ? variant : 'rounded';
    const copy_team = team === 'red' ? 'blue' : 'red';
    return (
      <Badge
      badgeContent={
        copy ?
          <UndraggableAvatar
          variant='circle'
          size='xs'
          src={overwatchHeroIconPath(copy, defaultIconOptions)}
          className={classNames(classes.heroImage, classes[copy_team])}
          />
        :
          <Zoom in={!(status !== 'ready' && status !== 'using')}>
            <CheckCircleIcon
            fontSize='small'
            htmlColor='cyan'
            />
          </Zoom>
      }
      >
        <UndraggableAvatar
        src={hero ? overwatchHeroIconPath(hero, defaultIconOptions) : placeholder}
        className={classNames(classes[size], classes.heroImage, classes[team], this.deadStyle())}
        variant={variant_shape}
        size={size === 'default' ? 'md' : size}
        />
      </Badge>
    );
  }

  private renderSkull = () => {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.poof, classes.skull)}>
        <Skull />
      </div>
    );
  }

  private renderStatus = (status: Status) => {
    const { classes } = this.props;
    switch (status) {
      case Status.HACK:
        return <Hack className={classNames(classes.status, classes.poof)} style={this.glow('128,0,128')}/>;
      case Status.STUNNED:
        return <Stunned className={classNames(classes.status, classes.poof)} style={this.glow('255,215,0')}/>;
      case Status.FROZEN:
        return <Freeze className={classNames(classes.status, classes.poof)} style={this.glow('0,255,255')}/>;
      case Status.SLEEP:
        return <Sleep className={classNames(classes.status, classes.poof)} style={this.glow('0,139,139')}/>;
      case Status.ANTI_HEAL:
        return <AntiHeal className={classNames(classes.status, classes.poof)} style={this.glow('255,0,255')}/>;
      default:
        return <UnknownStatus className={classNames(classes.status, classes.poof)} style={this.glow('255,0,0')}/>;
    }
  }

  private glow = (color: string) => {
    return {background: `radial-gradient(ellipse at center, rgba(${color},1) 0%,transparent 69%)`};
  }

  private deadStyle = (): string | null => {
    const { dead, classes } = this.props;
    return dead ? classes.dead : null;
  }
}

export default withStyles(styles)(HeroImage);
