import { GetOWLStatistics_Query,GetOWLVideos_Query } from 'apollo/queries';
import { watchAsyncQuery } from 'helpers/saga/effects';
import { all } from 'redux-saga/effects';

import {
  fetchOWLStatisticsAsyncAC,
  fetchOWLVideosAsyncAC,
} from '../actions/public-statistics-actions';

export default function* publicStatisticsSaga() {
  yield all([
    watchAsyncQuery(fetchOWLVideosAsyncAC, GetOWLVideos_Query, ['queryOWLVideos']),
    watchAsyncQuery(fetchOWLStatisticsAsyncAC, GetOWLStatistics_Query, ['owlStatistics']),
  ]);
}
