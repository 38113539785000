import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

import MemberTableRow from './MemberTableRow';

interface MemberTableRowSkeletonOwnProps {
  className?: string;
}

type MemberTableRowSkeletonProps = MemberTableRowSkeletonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'MemberTableRowSkeleton'});

function MemberTableRowSkeleton(props: MemberTableRowSkeletonProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <MemberTableRow
    name={<Skeleton variant='text' />}
    roles={<Skeleton variant='text' />}
    action={<Skeleton variant='text' />}
    mobileAction={<Skeleton variant='text' />}
    />
  );
}

export default React.memo(MemberTableRowSkeleton);
