import gql from 'graphql-tag';

import { PlayerFragment } from '../fragments';

export const UpdatePlayer_Mutation = gql`
    mutation UpdatePlayerMutation(
        $input: UpdatePlayerInput!
    ) {
        updatePlayer(
            input: $input
        ) {
            player {
                ...PlayerFragment
            }
        }
    }
    ${PlayerFragment}
`;
