import { useCreateSelector } from '@insights-gaming/redux-utils';
import { BundleFragment } from 'apollo/fragments/types/BundleFragment';
import { PriceFragment } from 'apollo/fragments/types/PriceFragment';
import { getAdditionalBundles, getAvailableBundles, getProductDict, makeGetTeamSubscriptionByTeamId } from 'features/dashboard/billing/dashboard-billing.selectors';
import { fetchAvailableBundlesAC, fetchBundlesByIdsAC, fetchPricesByIdsAC } from 'features/dashboard/billing/dashboard-billing-slice';
import update from 'immutability-helper';
import groupBy from 'lodash/groupBy';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Billing, ID } from 'types/pigeon';

import createBundleGetter from './createBundleGetter';

export default function useTeamAvailableBundles(teamId?: ID): Billing.BundleGetter | undefined {
  const dispatch = useDispatch();
  const availableBundlesArray = useSelector(getAvailableBundles);
  const additionalBundles = useSelector(getAdditionalBundles);
  const additionalPrices = useSelector(getProductDict);
  const [teamSubscription] = useCreateSelector(makeGetTeamSubscriptionByTeamId, teamId);

  useEffect(() => {
    if (availableBundlesArray) {
      return;
    }

    dispatch(fetchAvailableBundlesAC.started(undefined));
  }, [availableBundlesArray, dispatch]);

  const availableBundles = useMemo((): Dictionary<BundleFragment> | undefined => {
    if (!availableBundlesArray) {
      return;
    }

    return Object.fromEntries(
      availableBundlesArray.map((bundle) => [bundle.productId, bundle]),
    );
  }, [availableBundlesArray]);

  const availablePrices = useMemo((): Dictionary<PriceFragment> | undefined => {
    if (!availableBundlesArray) {
      return;
    }

    return Object.fromEntries(
      availableBundlesArray.flatMap(
        (bundle) => bundle.prices.map((price) => [price.id, price]),
      ),
    );
  }, [availableBundlesArray]);

  const teamProducts = useMemo(() => teamSubscription?.products || [], [teamSubscription]);

  const extraProductIds = useMemo(() => {
    if (!teamProducts || !availableBundles) {
      return;
    }

    return Array.from(
      new Set(
        [
          ...teamProducts.map(({ id }) => id),
          ...Object.values(additionalPrices || {}).flatMap((price) => price?.productId || []),
        ],
      ),
    ).filter((id) => !(id in availableBundles));
  }, [teamProducts, availableBundles, additionalPrices]);

  const missingProductIds = useMemo(() => {
    return extraProductIds?.filter((id) => !(id in additionalBundles));
  }, [extraProductIds, additionalBundles]);

  useEffect(() => {
    if (!missingProductIds || !missingProductIds.length) {
      return;
    }

    dispatch(fetchBundlesByIdsAC.started({ ids: missingProductIds }));
  }, [missingProductIds, dispatch]);

  const extraPriceIds = useMemo(() => {
    if (!teamProducts || !availablePrices) {
      return;
    }

    return teamProducts.map((p) => p.priceId)
      .filter((id) => !(id in availablePrices));
  }, [teamProducts, availablePrices]);

  const missingPriceIds = useMemo(() => {
    return extraPriceIds?.filter((id) => !additionalPrices[id]);
  }, [extraPriceIds, additionalPrices]);

  useEffect(() => {
    if (!missingPriceIds || !missingPriceIds.length) {
      return;
    }

    dispatch(fetchPricesByIdsAC.started({ ids: missingPriceIds }));
  }, [missingPriceIds, dispatch]);

  return useMemo(() => {
    if (
      !availableBundles ||
      !additionalBundles ||
      !additionalPrices ||
      !extraProductIds ||
      !extraPriceIds ||
      !missingProductIds || missingProductIds.length ||
      !missingPriceIds || missingPriceIds.length
    ) {
      return;
    }

    const extraPricesByProductId = groupBy(
      extraPriceIds.map((id) => additionalPrices[id]!),
      (p) => p.productId,
    );

    return createBundleGetter(
      Object.fromEntries(
        [
          ...Object.entries(availableBundles),
          ...extraProductIds.map((id): [string, BundleFragment] => [id, additionalBundles[id]]),
        ].map(([productId, bundle]): [string, BundleFragment] => {
          const extraPrices = extraPricesByProductId[productId];
          if (!extraPrices) {
            return [productId, bundle];
          }

          return [
            productId,
            update(bundle, { prices: { $unshift: extraPrices } }),
          ];
        }),
      ),
    );
  }, [
    availableBundles,
    additionalBundles,
    additionalPrices,
    extraProductIds,
    extraPriceIds,
    missingProductIds,
    missingPriceIds,
  ]);
}
