import { partition } from '@insights-gaming/utils';
import update from 'immutability-helper';
import { useCallback, useMemo, useState } from 'react';
import { ID } from 'types/pigeon';

export const createSelectState = (): UseMultiSelectState => ({
  selected: new Set(),
  addToSelection: () => {},
  removeFromSelection: () => {},
  toggleSelection: () => {},
  deselectAll: () => {},
});

export interface UseMultiSelectState {
  selected: Set<ID>;
  addToSelection: (ids: ID[]) => void;
  removeFromSelection: (ids: ID[]) => void;
  toggleSelection: (ids: ID[]) => void;
  deselectAll: VoidFunction;
}

export function useMultiSelectState(initialValue?: ID[]): UseMultiSelectState {
  const [selected, setSelected] = useState(() => new Set<ID>(initialValue));

  const addToSelection = useCallback((ids: ID[]) => {
    setSelected(old => update(old, {$add: ids}));
  }, []);

  const removeFromSelection = useCallback((ids: ID[]) => {
    setSelected(old => update(old, {$remove: ids}));
  }, []);

  const toggleSelection = useCallback((ids: ID[]) => {
    setSelected(old => {
      const [$add, $remove] = partition(ids, id => !old.has(id));
      return update(old, {$add, $remove});
    });
  }, []);

  const deselectAll = useCallback(() => {
    setSelected(new Set());
  }, []);

  return useMemo(() => ({
    addToSelection,
    deselectAll,
    removeFromSelection,
    toggleSelection,
    selected,
  } as const), [addToSelection, deselectAll, removeFromSelection, selected, toggleSelection]);
}

function calculateOverlap<T>(A: Iterable<T>, B: Set<T>) {
  const arr = Array.from(A);
  const overlap = arr.filter(a => B.has(a)).length;
  return {
    overlap,
    total: arr.length,
  };
}

function multiSelectHelper<T>(pairs: Array<[Iterable<T>, Set<T>]>) {
  return pairs.reduce((p, [A, B]) => {
    const { overlap, total } =  calculateOverlap<T>(A, B);
    return {
      overlap: p.overlap + overlap,
      total: p.total + total,
    };
  }, {overlap: 0, total: 0});
}

export function multiSelectCheckboxProps<T>(pairs: Array<[Iterable<T>, Set<T>]>) {
  const { overlap, total } = multiSelectHelper(pairs);
  const some = overlap > 0;
  const all = some && overlap === total;
  const checked = some;
  const indeterminate = checked && !all;
  return {
    checked,
    indeterminate,
  };
}
