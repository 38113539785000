import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetTeamDirectoryFolderRecordsByDirectoryId } from './dashboard-directory-selector';
import { fetchTeamDirectoryFoldersAC } from './dashboard-directory-slice';

export const useFetchDirectoryFolders = createBidirectionalAutoFetcher({
  actionCreators: fetchTeamDirectoryFoldersAC,
  selectorFactory: makeGetTeamDirectoryFolderRecordsByDirectoryId,
  paramsTransform: fetchId => ({directoryId: fetchId}),
  pageSize: 12,
});
