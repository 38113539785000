import { LeagueMetadata, LoLHelper } from '@insights-gaming/statistics';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { makeGetLeagueStatsByVideoId } from 'features/capture-games-statistics/common';
import { useCreateSelector } from 'hooks/useCreateSelector';
import React, { useMemo } from 'react';
import { ID } from 'types/pigeon';

import FloatingTextCharacterIcon from './FloatingTextCharacterIcon';
import KDAFloatingText from './KDAFloatingText';

interface LeagueKDAFloatingTextOwnProps {
  className?: string;
  gameMetadata: LeagueMetadata;
  videoId: ID;
}

type LeagueKDAFloatingTextProps = LeagueKDAFloatingTextOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  championIcon: {
    width: 22,
    height: 22,
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.5),
  },
}), { name: 'LeagueKDAFloatingText' });

function LeagueKDAFloatingText(props: LeagueKDAFloatingTextProps) {
  const classes = useStyles(props);
  const { className, gameMetadata, videoId } = props;

  const leagueStats = useCreateSelector(makeGetLeagueStatsByVideoId, { videoId });
  const gameVersion = leagueStats ? LoLHelper.extractGameVersion(leagueStats.gameVersion) : undefined;

  const iconPath = useMemo(() => {
    if (gameMetadata.championId) {
      return LoLHelper.getChampionIconSrc(gameMetadata.championId, gameVersion);
    }
    return;
  }, [gameMetadata.championId, gameVersion]);

  return (
    <KDAFloatingText
    className={classNames(classes.root, className)}
    characterIcon={iconPath ? (
      <FloatingTextCharacterIcon
      src={iconPath}
      alt={gameMetadata.champion}
      />
    ) : undefined}
    kdaInfo={{
      kills: gameMetadata.kills,
      deaths: gameMetadata.deaths,
      assists: gameMetadata.assists,
    }}
    />
  );
}

export default React.memo(LeagueKDAFloatingText);
