import { FloatingText } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

export interface KDAData {
  kills?: number;
  assists?: number;
  deaths?: number;
}

interface KDAFloatingTextOwnProps {
  className?: string;
  characterIcon?: JSX.Element;
  kdaInfo: KDAData;
}

type KDAFloatingTextProps = KDAFloatingTextOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  victory: {
    color: '#00C92C',
  },
  defeat: {
    color: theme.palette.overwatch.redTeam,
  },
}), { name: 'KDAFloatingText' });

function KDAFloatingText(props: KDAFloatingTextProps) {
  const classes = useStyles(props);
  const { className, kdaInfo, characterIcon } = props;

  const { t } = useStrictTranslation(['statistics']);
  return (
    <FloatingText className={classNames(classes.root, className)} vertical='bottom' horizontal='left' spacing={1}>
      {characterIcon}
      {kdaInfo.kills ?? '-'} / {kdaInfo.deaths ?? '-'} / {kdaInfo.assists ?? '-'}
    </FloatingText>
  );
}

export default React.memo(KDAFloatingText);
