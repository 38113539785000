type TValorantagents =
|'astra'
|'breach'
|'brimstone'
|'chamber'
|'clover'
|'cypher'
|'deadlock'
|'fade'
|'gekko'
|'harbor'
|'iso'
|'jett'
|'kayo'
|'killjoy'
|'neon'
|'omen'
|'phoenix'
|'raze'
|'reyna'
|'sage'
|'skye'
|'sova'
|'viper'
|'yoru'
;
const ValorantagentsNS = 'valorantagents';
function ValorantagentsF(_: TValorantagents): string { return `${ValorantagentsNS}:${_}`; }
export {TValorantagents,ValorantagentsF,ValorantagentsNS};
