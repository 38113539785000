import gql from 'graphql-tag';

export const UpdateCommentLiked_Mutation = gql`
    mutation UpdateCommentLikedMutation(
        $input: UpdateCommentLikedInput!
    ) {
        updateCommentLiked(
            input: $input
        ) {
            comment {
                likes
                liked
            }
        }
    }
`;
