import { secondsToHours } from 'helpers/formatters';
import { useMemo } from 'react';
import { TeamResource } from 'types/graphql';

import useTeamAvailableResource from './useTeamAvailableResource';

export interface IResourceLimits {
  upload: number;
  storage: number;
  storageBytes: number;
}

export default function useTeamResourceLimits(teamId: string): IResourceLimits {
  const availableTeamResource = useTeamAvailableResource(teamId);

  return useMemo(() => {
    return {
      upload: secondsToHours(availableTeamResource?.[TeamResource.VIDEO_UPLOAD]?.limit),
      storage: secondsToHours(availableTeamResource?.[TeamResource.VIDEO_STORAGE]?.limit),
      storageBytes: availableTeamResource?.[TeamResource.VIDEO_STORAGE_BYTES]?.limit || 0,
    };
  }, [availableTeamResource]);
};
