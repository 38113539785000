import { AsyncButton, EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import SvgIcon from '@material-ui/core/SvgIcon';
import TextField from '@material-ui/core/TextField';
import LabelIcon from '@material-ui/icons/Label';
import PaletteIcon from '@material-ui/icons/Palette';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import { Color } from 'csstype';
import { generateRandomColor, presetColors } from 'helpers/color';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import SketchPicker from 'react-color/lib/components/sketch/Sketch';
import { TagType } from 'types/graphql';
import { ID } from 'types/pigeon';

import { createTag2AC, updateTagAC } from '../dashboard-tag-slice';

interface TagEditorDialogOwnProps {
  teamId: ID;
  tag?: TagFragment;
  onClose?: VoidFunction
  isLabel?: boolean;
}

type TagEditorDialogProps = TagEditorDialogOwnProps & Pick<DialogProps, 'open'>;

function TagEditorDialog(props: TagEditorDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog fullWidth={true} open={open} onClose={onClose} maxWidth='xs'>
      <TagEditorDialogContent {...props} />
    </Dialog>
  );
}

function TagEditorDialogContent(props: TagEditorDialogProps) {
  const { teamId, tag, onClose, isLabel } = props;

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useStrictTranslation(['common', 'dialog']);

  const [name, setName] = useState(tag?.name || '');
  const [color, setColor] = useState(tag?.color || generateRandomColor());
  const [loading, setLoading] = useState(false);

  const handleTextFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = e;
    setName(value);
  }, []);

  const handleColorChangeComplete = useCallback((color: {hex: string}) => {
    setColor(color.hex);
  }, []);

  const handleRandomizeColor = useCallback(() => {
    setColor(generateRandomColor());
  }, []);

  const handleSwatchClick = useCallback((color: Color) => () => {
    setColor(color);
  }, []);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await (
        tag
          ? promiseSagaDispatch(updateTagAC, {id: tag.id, name, color, teamId})
          : promiseSagaDispatch(createTag2AC, {name, color, teamId, type: isLabel ? TagType.COMMENT : TagType.VIDEO})
      );
      let msg: string;
      if (isLabel) {
        msg = t('dialog:createlabel.success');
      } else {
        if (tag) {
          msg = t('dialog:edittags.success');
        }
        else {
          msg = t('dialog:createtags.success');
        }
      }
      enqueueSnackbar(msg, {variant: 'success'});
      onClose?.();
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [color, enqueueSnackbar, loading, name, onClose, promiseSagaDispatch, t, tag, teamId, isLabel]);

  const popupState = usePopupState({popupId: 'swatchpicker', variant: 'popover'});

  const invalid = !name;

  return (
    <form onSubmit={handleSubmit}>
      <EnhancedDialogTitle>
        {t(isLabel
          ? (tag ? 'dialog:editlabel.title' : 'dialog:createlabel.title')
          : (tag ? 'dialog:edittags.title' : 'dialog:createtags.title'),
        )}
      </EnhancedDialogTitle>
      <DialogContent>
        <FlexSpacer orientation='vertical'>
          <TextField
          value={name}
          fullWidth={true}
          autoFocus={true}
          onChange={handleTextFieldChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SvgIcon>
                  <LabelIcon htmlColor={color} />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          />
          <FlexSpacer flexAlignItems='center'>
            {presetColors.map(color => (
              <IconButton key={color} style={{backgroundColor: color}} onClick={handleSwatchClick(color)} />
            ))}
            <IconButton {...bindTrigger(popupState)}>
              <PaletteIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <SketchPicker
              color={color}
              onChange={handleColorChangeComplete}
              disableAlpha={true}
              />
            </Menu>
            <Button onClick={handleRandomizeColor}>
              {t('dialog:createtags.randomize')}
            </Button>
          </FlexSpacer>
        </FlexSpacer>
      </DialogContent>
      <DialogActions>
        <Button type='button' variant='outlined' onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <AsyncButton type='submit' variant='contained' color='primary' loading={loading} disabled={invalid || loading}>
          {t(isLabel
            ? (tag ? 'dialog:editlabel.confirm' : 'dialog:createlabel.confirm')
            : (tag ? 'dialog:edittags.confirm' : 'dialog:createtags.confirm'),
          )}
        </AsyncButton>
      </DialogActions>
    </form>
  );
}

export default React.memo(TagEditorDialog);
