import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import bytes from 'bytes';
import classNames from 'classnames';
import { deleteVideoAC } from 'features/dashboard/video/dashboard-video-slice';
import { mobilePortrait } from 'features/media-queries';
import { secondsToHms } from 'helpers/formatters';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeGetStartedUploadByVideoId } from '../resumable-uploads-selector';
import { getUploadState } from '../upload-selector';
import { clearUploadedVideosTemp, dequeueVideoUploadAC, pauseVideoUploadAC, promoteVideoUploadAC } from '../upload-slice';
import AbortUploadDialog from './AbortUploadDialog';
import { UploadQueueControl } from './UploadQueueControl';

interface UploadQueueOwnProps {
  className?: string;
}

type UploadQueueProps = UploadQueueOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    [mobilePortrait(theme)]: {
      width: '290px',
      '&:last-child > div > div > div': {
        flexWrap: 'wrap',
        '& > p': {
          width: '100%',
          marginBottom: theme.spacing(1),
        },
      },
    },
  },
}), {name: 'UploadQueue'});

function UploadQueue(props: UploadQueueProps) {
  const classes = useStyles(props);
  const { className } = props;
  const { currentUpload, uploadQueue, startedUploads } = useSelector(getUploadState);
  const { t } = useStrictTranslation(['dialog', 'common']);
  const dispatch = useDispatch();

  const [isAbortUploadDialogOpen, openAbortUploadDialog, closeAbortUploadDialog] = useDialogState(false);

  const totalUploads = uploadQueue.length + (currentUpload ? 1 : 0);

  const handlePauseCurrentUpload = useMemo(() => {
    const id = currentUpload?.resumeInfo?.videoId;
    if (!id) {
      return undefined;
    }
    return () => {
      dispatch(pauseVideoUploadAC({ id }));
    };
  }, [currentUpload, dispatch]);

  const handleResumeUpload = useMemo(() => {
    if (currentUpload || uploadQueue.length === 0) {
      return;
    }
    const [firstUpload] = uploadQueue;
    const uuid = firstUpload.video.uuid;
    return () => {
      dispatch(promoteVideoUploadAC.started({ uuid }));
    };
  }, [currentUpload, dispatch, uploadQueue]);

  const startedUpload = useCreateSelector(makeGetStartedUploadByVideoId, { id: currentUpload?.resumeInfo?.videoId });

  const sumOfQueuedFilesizes = useMemo(() => sum(uploadQueue.map(({ video }) => video.file.size)), [uploadQueue]);

  const totalEta = useMemo(
    () => currentUpload && startedUpload && startedUpload.rate > 0
      ? secondsToHms(
        ((1 - startedUpload.progress) * currentUpload.video.file.size + sumOfQueuedFilesizes)
        / currentUpload.video.file.size
        /startedUpload.rate,
      )
      : undefined,
    [currentUpload, startedUpload, sumOfQueuedFilesizes],
  );

  const currentEta = useMemo(
    () => startedUpload && startedUpload.rate > 0
      ? secondsToHms((1 - startedUpload.progress) / startedUpload.rate)
      : undefined,
    [startedUpload],
  );

  const speed = useMemo(
    () => startedUpload && currentUpload && bytes(startedUpload.rate * currentUpload.video.file.size),
    [currentUpload, startedUpload],
  );

  const abortAllUploads = useCallback(() => {
    for (const { video } of uploadQueue) {
      dispatch(dequeueVideoUploadAC.started({ video }));
    }

    if (currentUpload && currentUpload.resumeInfo) {
      dispatch(deleteVideoAC.started({ id: currentUpload.resumeInfo.videoId }));
    }

    for (const { id } of Object.values(startedUploads)) {
      dispatch(deleteVideoAC.started({ id }));
    }
    closeAbortUploadDialog();
    dispatch(clearUploadedVideosTemp());
  }, [closeAbortUploadDialog, currentUpload, dispatch, startedUploads, uploadQueue]);

  const handleAbortUpload = useCallback(() => {
    if (currentUpload || !isEmpty(startedUploads)) {
      openAbortUploadDialog();
    } else {
      dispatch(clearUploadedVideosTemp());
    }
  }, [currentUpload, dispatch, openAbortUploadDialog, startedUploads]);

  return (
    <UploadQueueControl
    abortUploadDialog={(
      <AbortUploadDialog
      open={isAbortUploadDialogOpen}
      onClose={closeAbortUploadDialog}
      onAbort={abortAllUploads}
      videoNames={
        currentUpload
          ? [currentUpload.video.file.name, ...uploadQueue.map(upload => upload.video.file.name)]
          : uploadQueue.map(upload => upload.video.file.name)
      }
      />
    )}
    openAbortUploadDialog={handleAbortUpload}
    totalEtaText={t('common:timeleft', { eta: totalEta })}
    etaText={t('common:timeleft', {eta: currentEta})}
    uploadSpeedText={t('common:uploadspeed', { speed })}
    uploadFileText={t('dialog:uploadqueue.uploadingxfile', { count: totalUploads })}
    completedText={t('common:completed')}
    handlePauseCurrentUpload={handlePauseCurrentUpload}
    handleResumeUpload={handleResumeUpload}
    className={classNames(className, classes.root)}
    />
  );
}

export default React.memo(UploadQueue);
