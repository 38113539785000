import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import classNames from 'classnames';
import { mobileLandscape, mobilePortrait } from 'features/media-queries';
import React, { useCallback } from 'react';

interface SelectedLabelToAddOwnProps {
  className?: string;
  label: TagFragment;
  handleRemoveLabel: (id: TagFragment) => void;
}

type SelectedLabelToAddProps = SelectedLabelToAddOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(0.5),
    border: '2px solid',
    borderRadius: '5px',
    color: (props: SelectedLabelToAddProps) => props.label.color,
    maxWidth: theme.spacing(20),
    overflow: 'hidden',
    [mobilePortrait(theme)]: {
      padding: theme.spacing(0, 0.5),
      maxWidth: theme.spacing(15),
    },
    [mobileLandscape(theme)]: {
      padding: theme.spacing(0, 0.5),
      maxWidth: theme.spacing(15),
    },
  },
  labelName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: createRemFromPx(12),
  },
  removeBtn: {
    padding: 0,
  },
}), {name: 'SelectedLabelToAdd'});

function SelectedLabelToAdd(props: SelectedLabelToAddProps) {
  const classes = useStyles(props);
  const { className, handleRemoveLabel, label } = props;

  const removeOnClick = useCallback(() => {
    handleRemoveLabel(label);
  }, [handleRemoveLabel, label]);

  return (
    <FlexSpacer className={classNames(classes.root, className)} flexAlignItems='center' spacing={0.5}>
      <Typography className={classes.labelName}>{label.name}</Typography>
      <IconButton size='small' onClick={removeOnClick} className={classes.removeBtn}>
        <CloseIcon htmlColor={red[700]} fontSize='small' />
      </IconButton>
    </FlexSpacer>
  );
}

export default React.memo(SelectedLabelToAdd);
