import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { createRemoteVideo2AC } from 'features/dashboard/video/dashboard-video-slice';
import { isVideoFragment } from 'helpers/isVideoFragment';
import { useCallback } from 'react';
import { ID } from 'types/pigeon';

import { usePromiseSagaDispatch } from './usePromiseSagaDispatch';

export function useRealizeSelectedVideo(teamId: ID, directoryId: ID) {
  const promiseSagaDispatch = usePromiseSagaDispatch();

  return useCallback(async (video: VideoFragment | InsightsEmbedResponse): Promise<VideoFragment> => {
    if (isVideoFragment(video)) {
      return video;
    }

    return (
      await promiseSagaDispatch(createRemoteVideo2AC, {
        teamId,
        remoteUrl: video.url,
        directoryId,
      })
    ).video;
  }, [directoryId, promiseSagaDispatch, teamId]);
}
