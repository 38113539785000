import { Theme } from '@insights-gaming/theme';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { GetVideosByIdQuery_videos_latestAnalysis_progress } from 'apollo/queries/types/GetVideosByIdQuery';
import { affineTransform1D } from 'helpers/math';
import React from 'react';

interface AnalysisProgressOwnProps {
  progress: GetVideosByIdQuery_videos_latestAnalysis_progress;
}

type AnalysisProgressProps = AnalysisProgressOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
}), {name: 'AnalysisProgress'});

function AnalysisProgress(props: AnalysisProgressProps) {
  const classes = useStyles(props);
  const { progress } = props;

  const progressPos = affineTransform1D(progress.current, [0, .5], [0, 100]);

  return (
    <LinearProgress
    className={classes.root}
    value={progressPos}
    variant='determinate'
    color= {'primary'}
    />
  );
}

export default React.memo(AnalysisProgress);
