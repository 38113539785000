import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { SavedCardFragment } from 'apollo/fragments/types/SavedCardFragment';
import classNames from 'classnames';
import { mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

import PaymentCardSelector from './PaymentCardSelector';
import { usePaymentCardSelectorHandlers } from './usePaymentCardSelectorHandlers';

interface PaymentCardsOwnProps {
  className?: string;
  teamCards?: SavedCardFragment[];
  stepBackward?: VoidFunction;
  teamId: string;
}

type PaymentCardsProps = PaymentCardsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: createRemFromPx(600),
    [mobilePortrait(theme)]: {
      width: 'auto',
    },
    [mobileLandscape(theme)]: {
      width: 'auto',
    },
    margin: theme.spacing(4),
  },
  previousButton: {
    marginTop: theme.spacing(1),
  },
}), {name: 'PaymentCards'});

function PaymentCards(props: PaymentCardsProps) {
  const classes = useStyles(props);
  const { className, teamCards, teamId, stepBackward } = props;
  const { t } = useStrictTranslation(['dialog']);

  const {
    selectedCard,
    handleAddCard,
    handleChangeCard,
    renderTail,
    renderEnd,
    renderMobileCardDetails,
    renderMobileAddCard,
    switchCardDialog,
    removeCardDialog,
    showInputs,
    handleEnableShowInputs,
    disableShowInputs,
  } = usePaymentCardSelectorHandlers(teamId, teamCards);

  return (
    <div className={classNames(classes.root, className)}>
      {teamCards && (
        <PaymentCardSelector
        cards={teamCards}
        card={selectedCard}
        renderMobileCardDetails={renderMobileCardDetails}
        renderMobileAddCard={renderMobileAddCard}
        onChange={handleChangeCard}
        onAddCard={handleAddCard}
        renderTail={renderTail}
        renderEnd={renderEnd}
        collapsed={true}
        showInputs={showInputs}
        handleEnableShowInputs={handleEnableShowInputs}
        disableShowInputs={disableShowInputs}
        />
      )}
      {stepBackward && (
        <Button
        onClick={stepBackward}
        type='button'
        startIcon={<ArrowBackIosIcon/>}
        className={classes.previousButton}
        >
          {t('dialog:plandetails.backtosummary')}
        </Button>
      )}
      {switchCardDialog()}
      {removeCardDialog()}
    </div>
  );
}

export default React.memo(PaymentCards);
