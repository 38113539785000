import { UserNotificationSettingFragment } from 'apollo/fragments/types/UserNotificationSettingFragment';
import { GetDirectoryNotificationSettingsQueryVariables } from 'apollo/queries/types/GetDirectoryNotificationSettingsQuery';
import { GetNotificationSettingsQueryVariables } from 'apollo/queries/types/GetNotificationSettingsQuery';
import { GetTeamNotificationSettingsQueryVariables } from 'apollo/queries/types/GetTeamNotificationSettingsQuery';
import addAsyncCases from 'helpers/addAsyncCases';
import { createSlice } from 'helpers/createSlice';
import update from 'immutability-helper';
import { UpdateUserNotificationSettingsInput } from 'types/graphql';
import actionCreatorFactory from 'typescript-fsa';

const name = 'notification-settings';
const actionCreator = actionCreatorFactory(name);

export const fetchUserNotificationSettingsAC = actionCreator.async<
  GetNotificationSettingsQueryVariables,
  UserNotificationSettingFragment[],
  Error
>('FETCH_USER_NOTIFICATION_SETTINGS');

export const fetchTeamNotificationSettingsAC = actionCreator.async<
  GetTeamNotificationSettingsQueryVariables,
  UserNotificationSettingFragment[],
  Error
>('FETCH_TEAM_NOTIFICATION_SETTINGS');

export const fetchDirectoryNotificationSettingsAC = actionCreator.async<
  GetDirectoryNotificationSettingsQueryVariables,
  UserNotificationSettingFragment[],
  Error
>('FETCH_DIRECTORY_NOTIFICATION_SETTINGS');

export const updateUserNotificationSettingsAC = actionCreator.async<
  UpdateUserNotificationSettingsInput[],
  UserNotificationSettingFragment[],
  Error
>('UPDATE_USER_NOTIFICATION_SETTINGS');

export interface UserNotificationSettingsState {
  loading?: boolean;
  settings?: UserNotificationSettingFragment[];
  error?: Error;
}

export interface NotificationSettingsState {
  userNotificationSettings: UserNotificationSettingsState;
  teamNotificationSettings: Partial<Dictionary<UserNotificationSettingsState>>;
  directoryNotificationSettings: Partial<Dictionary<UserNotificationSettingsState>>;
}

const initialState: NotificationSettingsState = {
  userNotificationSettings: {},
  teamNotificationSettings: {},
  directoryNotificationSettings: {},
};

const userNotificationSettingsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    addAsyncCases(builder, fetchUserNotificationSettingsAC, {
      started: (state, action) => {
        return update(state, {
          userNotificationSettings: {
            loading: {$set: true},
          },
        });
      },
      done: (state, action) => {
        const { result } = action.payload;
        return update(state, {
          userNotificationSettings: {
            loading: {$set: false},
            settings: {$set: result},
          },
        });
      },
      failed: (state, action) => {
        const { error } = action.payload;
        return update(state, {
          userNotificationSettings: {
            loading: {$set: false},
            error: {$set: error},
          },
        });
      },
    });

    addAsyncCases(builder, fetchTeamNotificationSettingsAC, {
      started: (state, action) => {
        const { teamId } = action.payload;
        return update(state, {
          teamNotificationSettings: {
            [teamId] : settings => settings ? update(settings, {loading: {$set: true}}) : {loading: true},
          },
        });
      },
      done: (state, action) => {
        const { result, params: { teamId } } = action.payload;
        return update(state, {
          teamNotificationSettings: {
            [teamId] : {
              loading: {$set: false},
              settings: {$set: result},
            },
          },
        });
      },
      failed: (state, action) => {
        const { error, params: { teamId } } = action.payload;
        return update(state, {
          teamNotificationSettings: {
            [teamId] : {
              loading: {$set: false},
              error: {$set: error},
            },
          },
        });
      },
    });

    addAsyncCases(builder, fetchDirectoryNotificationSettingsAC, {
      started: (state, action) => {
        const { directoryId } = action.payload;
        return update(state, {
          directoryNotificationSettings: {
            [directoryId] : settings => settings ? update(settings, {loading: {$set: true}}) : {loading: true},
          },
        });
      },
      done: (state, action) => {
        const { result, params: { directoryId } } = action.payload;
        return update(state, {
          directoryNotificationSettings: {
            [directoryId] : {
              loading: {$set: false},
              settings: {$set: result},
            },
          },
        });
      },
      failed: (state, action) => {
        const { error, params: { directoryId } } = action.payload;
        return update(state, {
          directoryNotificationSettings: {
            [directoryId] : {
              loading: {$set: false},
              error: {$set: error},
            },
          },
        });
      },
    });

    addAsyncCases(builder, updateUserNotificationSettingsAC, {
      done: (state, action) => {
        const { result, params } = action.payload;
        const teamId = params.find(r => r.teamId)?.teamId;
        const channelId = params.find(r => r.teamId)?.folderId;
        if (channelId) {
          return update(state, {
            directoryNotificationSettings: {
              [channelId]: settings => settings ? update(settings, {settings: {$set: result}}) : {settings: result},
            },
          });
        } else if (teamId) {
          return update(state, {
            teamNotificationSettings: {
              [teamId]: settings => settings ? update(settings, {settings: {$set: result}}) : {settings: result},
            },
          });
        } else {
          return update(state, {
            userNotificationSettings: {
              settings: {$set: result},
              error: {$set: undefined},
              loading: {$set: false},
            },
          });
        }
      },
    });
  },
});

export const userNotificationSettingsReducer = userNotificationSettingsSlice.reducer;
