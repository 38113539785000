import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetTeamMemberRecordsByTeamId } from './dashboard-member-selector';
import { fetchTeamMembersAC } from './dashboard-member-slice';

export const useFetchTeamMembers = createBidirectionalAutoFetcher({
  actionCreators: fetchTeamMembersAC,
  selectorFactory: makeGetTeamMemberRecordsByTeamId,
  paramsTransform: fetchId => ({teamId: fetchId}),
  pageSize: 10,
});
