import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { TeamInterface } from 'types/pigeon';

function isTeamFragment(team: TeamFragment | TeamInterface)
: team is TeamFragment {
  return team.__typename === 'Team';
}

export const TeamHelper = {
  isTeamFragment,
};
