import addAsyncCases from 'helpers/addAsyncCases';
import { createSlice } from 'helpers/createSlice';
import { HideTipsInput } from 'types/graphql';
import actionCreatorFactory from 'typescript-fsa';

import { GetTipsQueryVariables } from './../../apollo/queries/types/GetTipsQuery';

const name = 'tips';

const actionCreator = actionCreatorFactory(name);

export const fetchTipsAsyncAC = actionCreator.async<GetTipsQueryVariables, boolean[], Error>('FETCH_TIPS');

export const hideTipsAsyncAC = actionCreator.async<HideTipsInput, string[], Error>('HIDE_TIPS');

export const FIRST_TIME_USER = 'USER';

export const FIRST_TIME_DASHBOARD = 'DASHBOARD';

export const FIRST_TIME_REPLAY = 'REPLAY';

export const ALL_TIPS_TO_FETCH = [
  FIRST_TIME_USER,
  FIRST_TIME_DASHBOARD,
  FIRST_TIME_REPLAY,
];

export interface ITipsState {
  TipsDict: Dictionary<boolean>;
}

const initialState = {
  TipsDict: {},
};

const authSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    addAsyncCases(builder, fetchTipsAsyncAC, {
      done: (state, { payload: { params, result } }) => {
        for (const [i, name] of params.names.entries()) {
          state.TipsDict[name] = result[i];
        }
      },
    });

    addAsyncCases(builder, hideTipsAsyncAC, {
      done: (state, { payload: { params } }) => {
        for (const [, name] of params.names.entries()) {
          state.TipsDict[name] = false;
        }
      },
    });
  },
});

export const tipsReducer = authSlice.reducer;
