import { createAutoCompleteMatcher } from '@insights-gaming/material-components';
import { RichTextAutoCompleteOption } from '@insights-gaming/material-components/RichTextEditor/auto-complete-matcher';
import { useMemo } from 'react';

import { GetMembersQuery_queryMembers_edges_user } from '../apollo/queries/types/GetMembersQuery';

export function useMentionAutoCompleteMatcher(users: GetMembersQuery_queryMembers_edges_user[]) {
  return useMemo(() => {
    const options: RichTextAutoCompleteOption[] = users.map((u): RichTextAutoCompleteOption => ({
      displayName: u.alias,
      id: u.id,
      picture: u.picture || undefined,
      payload: u,
    }));
    return createAutoCompleteMatcher(options, {trigger: '@', entityType: 'MENTION'});
  }, [users]);
}
