import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

interface NoCardOwnProps {
  className?: string;
}

type NoCardProps = NoCardOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  glue: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    margin: theme.spacing(1, 0),
  },
  headerText: {
    fontWeight: 600,
  },
}), {name: 'NoCard'});

function NoCard(props: NoCardProps) {
  const classes = useStyles(props);
  const { className } = props;

  const { t } = useStrictTranslation(['dashboard']);

  return (
    <FlexSpacer
    flexJustifyContent='space-between'
    flexAlignItems='center'
    fullWidth={true}
    className={classNames(classes.root, className)}
    >
      <div className={classes.glue}>
        <Typography>
          {t('dashboard:billing.nocard')}
        </Typography>
      </div>
    </FlexSpacer>
  );
}

export default React.memo(NoCard);
