import { AsyncButton, EnhancedDialogTitle } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { updateVideoTagsAsyncAC } from 'actions/video-actions';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import MultiSelect, { OptionType } from 'material/multi-select/MultiSelect';
import MultiValueTagChip from 'material/multi-value-tag-chip/MultiValueTagChip';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { exid, ID, ITag, mapTagToOption } from 'types/pigeon';

import { useTagIdsOnVideos } from '../useTagIdsOnVideos';

interface MultiVideoTaggerDialogOwnProps {
  onClose?: VoidFunction;
  teamId: ID;
  videos: VideoFragment[];
  tags: ITag[];
}

type MultiVideoTaggerDialogProps = MultiVideoTaggerDialogOwnProps & Pick<DialogProps, 'open'>;

function MultiVideoTaggerDialog(props: MultiVideoTaggerDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <MultiVideoTaggerDialogContent {...props} />
    </Dialog>
  );
}

function MultiVideoTaggerDialogContent(props: MultiVideoTaggerDialogProps) {
  const { onClose, videos, tags, teamId } = props;

  const promiseSagaDispatch = usePromiseSagaDispatch();

  const { t } = useStrictTranslation(['common', 'dialog']);

  const { enqueueSnackbar } = useSnackbar();

  const { tagIdsOnAllVideos, tagIdsOnAtLeastOneVideo } = useTagIdsOnVideos(videos);

  const addOptions = useMemo(() => {
    return tags.filter(tag => !tagIdsOnAllVideos.has(tag.id)).map(mapTagToOption);
  }, [tagIdsOnAllVideos, tags]);

  const removeOptions = useMemo(() => {
    return tags.filter(tag => tagIdsOnAtLeastOneVideo.has(tag.id)).map(mapTagToOption);
  }, [tagIdsOnAtLeastOneVideo, tags]);

  const [tagsToBeAdded, setTagsToBeAdded] = useState<OptionType[]>([]);
  const [tagsToBeRemoved, setTagsToBeRemoved] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  const handleAddTagsOnChange = useCallback((value?: OptionType[]) => {
    setTagsToBeAdded(value || []);
    setDirty(true);
  }, []);

  const handleRemoveTagsOnChange = useCallback((value?: OptionType[]) => {
    setTagsToBeRemoved(value || []);
    setDirty(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      await promiseSagaDispatch(updateVideoTagsAsyncAC, {
        teamId,
        videoIds: videos.map(exid),
        tagIds: tagsToBeAdded.map(tag => tag.value),
        untagIds: tagsToBeRemoved.map(tag => tag.value),
      });
      enqueueSnackbar(t('dialog:videotagger.successcount', {count: videos.length}), {variant: 'success'});
      onClose?.();
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, loading, onClose, promiseSagaDispatch, t, tagsToBeAdded, tagsToBeRemoved, teamId, videos]);

  return (
    <React.Fragment>
      <EnhancedDialogTitle>
        {t('dialog:videotagger.titlecount', {count: videos.length})}
      </EnhancedDialogTitle>
      <DialogContent>
        <Grid container={true} alignItems='flex-end' spacing={1}>
          <Grid item={true} xs={12} md={6}>
            <MultiSelect
            id='addtoallvideos'
            label={t('dialog:videotagger.addlabel')}
            placeholder={t('dialog:videotagger.placeholder')}
            options={addOptions}
            onChange={handleAddTagsOnChange}
            isMulti={true}
            components={{MultiValue: MultiValueTagChip}}
            />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <MultiSelect
            id='removefromallvideos'
            label={t('dialog:videotagger.removelabel')}
            placeholder={t('dialog:videotagger.placeholder')}
            options={removeOptions}
            onChange={handleRemoveTagsOnChange}
            isMulti={true}
            components={{MultiValue: MultiValueTagChip}}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <AsyncButton
        variant='contained'
        color='primary'
        loading={loading}
        disabled={loading || !dirty}
        onClick={handleSubmit}
        >
          {t('dialog:videotagger.confirm')}
        </AsyncButton>
      </DialogActions>
    </React.Fragment>
  );
}

export default React.memo(MultiVideoTaggerDialog);
