import { Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { AuthenticationMethodFragment } from 'apollo/fragments/types/AuthenticationMethodFragment';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback } from 'react';

import RemoveSigninMethodDialog from './RemoveSigninMethodDialog';
import SigninMethodsTableRow from './SigninMethodsTableRow';

interface SigninMethodsTableRowWrapperOwnProps {
  className?: string;
  provider: AuthenticationMethodFragment;
  preventDelete?: boolean;
}

type SigninMethodsTableRowWrapperProps = SigninMethodsTableRowWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  currentSession: {
    fontStyle: 'italic',
    color: theme.palette.grey[400],
  },
}), {name: 'SigninMethodsTableRowWrapper'});

function SigninMethodsTableRowWrapper(props: SigninMethodsTableRowWrapperProps) {
  const classes = useStyles(props);
  const { className, provider, preventDelete } = props;

  const { t } = useStrictTranslation(['common', 'settings']);

  const [
    isRemoveSigninMethodDialogOpen,
    openRemoveSigninMethodDialog,
    closeRemoveSigninMethodDialog,
  ] = useDialogState();

  const renderAction = useCallback(() => {
    return (
      <Tooltip title={t('settings:signinmethods.removedialog.remove')}>
        <IconButton onClick={openRemoveSigninMethodDialog} size='small' disabled={preventDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  }, [openRemoveSigninMethodDialog, preventDelete, t]);

  return (
    <React.Fragment>
      <SigninMethodsTableRow
      {...provider}
      action={renderAction()}
      />
      <RemoveSigninMethodDialog
      open={isRemoveSigninMethodDialogOpen}
      onClose={closeRemoveSigninMethodDialog}
      provider={provider.name}
      id={provider.externalId}
      name={provider.externalName}
      />
    </React.Fragment>
  );
}

export default React.memo(SigninMethodsTableRowWrapper);
