import ShowLoadingScreen from 'features/loading-screen/ShowLoadingScreen';
import React from 'react';

import { useFetchSharedDivisions } from './useFetchSharedDivisions';

function SharedDivisionFetcher() {
  const [status] = useFetchSharedDivisions('');
  return status.fetching && !status.cursor ? <ShowLoadingScreen /> : null;
}

export default React.memo(SharedDivisionFetcher);
