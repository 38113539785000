import { useFetchTeamTags } from 'features/dashboard/tag/useFetchTeamTags';
import React from 'react';

import { ID } from '../../types/pigeon';

interface TagFetcherOwnProps {
  teamId: ID;
}

type TagFetcherProps = TagFetcherOwnProps;

function TagFetcher({ teamId }: TagFetcherProps) {
  useFetchTeamTags(teamId);
  return null;
}

export default React.memo(TagFetcher);
