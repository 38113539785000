import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import classNames from 'classnames';
import React from 'react';

interface ProgressIndicatorOwnProps {
  className?: string;
  step: number;
}

type ProgressIndicatorProps = ProgressIndicatorOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    top: 24,
    left: '50%',
    transform: 'translate(-50%, 0)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dot: {
    fontSize: createRemFromPx(8),
    margin: theme.spacing(0, 0.5),
  },
}), {name: 'ProgressIndicator'});

function ProgressIndicator(props: ProgressIndicatorProps) {
  const classes = useStyles(props);
  const { className, step } = props;
  return (
    <div className={classNames(classes.root, className)}>
      {Array.from({ length: 5 }).map((_, i) => {
        return <FiberManualRecordIcon key={i} className={classes.dot} color={step === i+1 ? 'primary' : undefined}/>;
      })}
    </div>
  );
}

export default React.memo(ProgressIndicator);
