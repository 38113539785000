import { AsyncButton, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { mobilePortrait } from 'features/media-queries';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useRef, useState } from 'react';
import { ID } from 'types/pigeon';

import CardInputs from './CardInputs';
import CouponInput from './CouponInput';

export interface CardFormOwnProps {
  className?: string;
  teamId?: ID;
  onSuccess?: () => void;
  onSubmit: (paymentId: string) => any;
  buttonText?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  bordered?: boolean;
  previousButton?: JSX.Element;
};

export type CardFormProps = CardFormOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    [mobilePortrait(theme)]: {
      width: 'auto',
      padding: theme.spacing(1),
    },
    padding: theme.spacing(1),
    width: createRemFromPx(600),
  },
  border: {
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: theme.palette.background.container,
  },
  expandedAction: {
    marginTop: theme.spacing(1),
  },
  buttonLabel: {
    margin: theme.spacing(1, 0),
  },
  confirm:{
    width: createRemFromPx(156),
  },
  fullWidth: {
    width: '100%',
  },
}), { name: 'CardForm' });

function CardForm(props: CardFormProps) {
  const classes = useStyles(props);
  const {
    className,
    teamId,
    onSuccess,
    onSubmit,
    disabled,
    fullWidth,
    buttonText,
    bordered,
    previousButton,
  } = props;

  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState<string | boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const stripe = useStripe();
  const elements = useElements();

  const { t } = useStrictTranslation(['dashboard']);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    if (!stripe || !elements) {
      return;
    }

    e.preventDefault();
    setLoading(true);

    const cardNum = elements.getElement(CardNumberElement);
    const cardExp = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);
    if (!cardNum || !cardExp || !cardCvc) {
      return;
    }

    const clearCardInput = () => {
      cardNum.clear();
      cardExp.clear();
      cardCvc.clear();
    };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNum,
    });
    if (error && error.message) {
      setLoading(false);
      setError(error.message);
      if (ref && ref.current) {
        ref.current.classList.add('error');
        setTimeout(() => ref.current?.classList.remove('error'), 500);
      }
    } else {
      if (paymentMethod && paymentMethod.id) {
        Promise.resolve(onSubmit(paymentMethod.id)).then(clearCardInput);
      }
    }
  }, [stripe, elements, onSubmit]);

  const [isFormComplete, setIsFormComplete] = useState(false);

  return (
    <div className={classNames(classes.root, className, {[classes.border]: bordered})}>
      <CardInputs error={error} ref={ref} onCompleteStateChange={setIsFormComplete}/>
      <CouponInput />
      <FlexSpacer
      flexAlignItems='center'
      flexJustifyContent={fullWidth ? 'center' : 'space-between'}
      className={classNames({[classes.expandedAction]: fullWidth})}
      >
        {previousButton && previousButton}
        <AsyncButton
        type='submit'
        color='primary'
        disabled={!stripe || !isFormComplete || disabled || loading}
        variant='contained'
        className={classes.buttonLabel}
        buttonClasses={fullWidth ? {} : {contained: classes.confirm}}
        loading={loading}
        fullWidth={fullWidth}
        onClick={handleSubmit}
        >
          {buttonText || t('dashboard:billing.addnewcard')}
        </AsyncButton>
      </FlexSpacer>
    </div>
  );
}

export default React.memo(CardForm);
