
import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetCommentRepliesRecordsByCommentId } from '../video-comment-selector';
import { fetchCommentRepliesAC } from '../video-comment-slice';

export const useFetchCommentReplies = createBidirectionalAutoFetcher({
  actionCreators: fetchCommentRepliesAC,
  selectorFactory: makeGetCommentRepliesRecordsByCommentId,
  createFetchId: ({videoId, commentId}) => [videoId, commentId].join(':'),
  pageSize: 10,
});
