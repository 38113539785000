import { createRemFromPx, mobilePortrait, Theme } from '@insights-gaming/theme';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { createStyles, makeStyles } from '@material-ui/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import DashboardTeamSettingsRouting from 'features/dashboard/team/dashboard-team-settings.routing';
import { desktop } from 'features/media-queries';
import { useNavigate } from 'hooks/useNavigate';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { useParams } from 'react-router';

interface TeamSettingsTabsOwnProps {
  className?: string;
  team: TeamFragment;
}

type TeamSettingsTabsProps = TeamSettingsTabsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  tabsContainer: {
    display:'flex',
    justifyContent:'space-between',
    [desktop(theme)]: {
      marginBottom: theme.spacing(5),
    },
    marginBottom: theme.spacing(2),
    flexWrap: 'wrap',
  },
  tabContainer: {
    fontSize: createRemFromPx(14),
    [mobilePortrait(theme)]: {
      flex: 1,
    },
  },
}), {name: 'TeamSettings'});

function TeamSettingsTabs(props: TeamSettingsTabsProps) {
  const classes = useStyles(props);
  const { className, team } = props;

  const { t } = useStrictTranslation(['dashboard']);

  const onNavigate = useNavigate();

  const { tab } = useParams<DashboardTeamSettingsRouting.Params>();

  const handleTabsChange = (event: React.ChangeEvent, newValue: DashboardTeamSettingsRouting.Tab) => {
    if (newValue) {
      onNavigate(DashboardTeamSettingsRouting.createUrl(
        team.id,
        newValue,
      ));
    }
  };

  return (
    <div>
      <Tabs
      value={tab}
      onChange={handleTabsChange}
      indicatorColor='primary'
      textColor='inherit'
      className={classes.tabsContainer}
      variant='scrollable'
      >
        <Tab
        label={t('dashboard:team.settings.overview')}
        value={DashboardTeamSettingsRouting.Tab.OVERVIEW}
        className={classes.tabContainer}
        />
        <Tab
        label={t('dashboard:team.settings.members')}
        value={DashboardTeamSettingsRouting.Tab.MEMBERS}
        className={classes.tabContainer}
        />
        <Tab
        label={t('dashboard:team.settings.roles')}
        value={DashboardTeamSettingsRouting.Tab.ROLES}
        className={classes.tabContainer}
        />
      </Tabs>
    </div>
  );
}

export default React.memo(TeamSettingsTabs);
