import { pubgMapsF, TpubgMaps } from 'locales/en/pubg-maps';

import { buildMapImagePath, GameType, getOverwatchGamemode, MapImageType } from '../../../helpers';
import { getLeagueGamemode } from '../../../helpers/leaguehelper';
import { BoardsF, TBoards } from '../../../locales/en/boards';
import { FortniteF, TFortnite } from '../../../locales/en/fortnite';
import { LeagueoflegendsF, TLeagueoflegends } from '../../../locales/en/leagueoflegends';
import { OwmapsF, TOwmaps } from '../../../locales/en/owmaps';
import { RainbowSixSiegeMapsF, TRainbowSixSiegeMaps } from '../../../locales/en/rainbow-six-siege-maps';
import { RocketleagueF, TRocketleague } from '../../../locales/en/rocketleague';
import { SsbuStagesF, TSsbuStages } from '../../../locales/en/ssbu-stages';
import { TValorantmaps, ValorantmapsF } from '../../../locales/en/valorantmaps';

export interface AnnotationMap {
  game    : GameType;
  gamemode: string | null;
  key     : string;
  trans   : string;
  image   : string;
  thumb   : string;
}

const leaguemap = (map: string): AnnotationMap => {
  return {
    game    : 'leagueoflegends',
    gamemode: getLeagueGamemode(map),
    key     : buildMapKey('map-overhead', 'leagueoflegends', map),
    trans   : LeagueoflegendsF(`maps.${map}` as TLeagueoflegends),
    image   : buildMapImagePath('map-overhead', 'leagueoflegends', map),
    thumb   : buildMapImagePath('map-overhead', 'leagueoflegends', map),
  };
};

const rocketleaguemap = (map: string): AnnotationMap => {
  return {
    game    : 'rocket-league',
    gamemode: 'rocket-league',
    key     : buildMapKey('map-overhead', 'rocket-league', map),
    trans   : RocketleagueF(`maps.${map}` as TRocketleague),
    image   : buildMapImagePath('map-overhead', 'rocket-league', map),
    thumb   : buildMapImagePath('map-overhead', 'rocket-league', map),
  };
};

const fortnitemap = (map: string): AnnotationMap => {
  return {
    game    : 'fortnite',
    gamemode: 'fortnite',
    key     : buildMapKey('map-overhead', 'fortnite', map),
    trans   : FortniteF(`maps.${map}` as TFortnite),
    image   : buildMapImagePath('map-overhead', 'fortnite', map),
    thumb   : buildMapImagePath('map-overhead', 'fortnite', map),
  };
};

const owmap = (map: TOwmaps): AnnotationMap => {
  return {
    game    : 'overwatch',
    gamemode: getOverwatchGamemode(map),
    key     : buildMapKey('map-overhead', 'overwatch', map),
    trans   : OwmapsF(map),
    image   : buildMapImagePath('map-overhead', 'overwatch', map),
    thumb   : buildMapImagePath('map-thumbnail', 'overwatch', map),
  };
};

const ssbumap = (map: TSsbuStages): AnnotationMap => {
  return {
    game: 'ssbu',
    gamemode: 'ssbu',
    key: buildMapKey('map-overhead', 'ssbu', map),
    trans: SsbuStagesF(map),
    image: buildMapImagePath('map-overhead', 'ssbu', map),
    thumb: buildMapImagePath('map-thumbnail', 'ssbu', map),
  };
};

const r6map = (map: TRainbowSixSiegeMaps): AnnotationMap => {
  return {
    game: 'rainbow-six-siege',
    gamemode: 'rainbow-six-siege',
    key: buildMapKey('map-overhead', 'rainbow-six-siege', map),
    trans: RainbowSixSiegeMapsF(map),
    image: buildMapImagePath('map-overhead', 'rainbow-six-siege', map),
    thumb: buildMapImagePath('map-overhead', 'rainbow-six-siege', map),
  };
};

const valorantmap = (map: TValorantmaps): AnnotationMap => {
  return {
    game: 'valorant',
    gamemode: 'valorant',
    key: buildMapKey('map-overhead', 'valorant', map),
    trans: ValorantmapsF(map),
    image: buildMapImagePath('map-overhead', 'valorant', map),
    thumb: buildMapImagePath('map-overhead', 'valorant', map),
  };
};

const pubgmap = (map: TpubgMaps): AnnotationMap => {
  return {
    game: 'pubg',
    gamemode: 'pubg',
    key: buildMapKey('map-overhead', 'pubg', map),
    trans: pubgMapsF(map),
    image: buildMapImagePath('map-overhead', 'pubg', map),
    thumb: buildMapImagePath('map-overhead', 'pubg', map),
  };
};

const buildMapKey = (imageType: MapImageType, game: GameType, mapName: string) => {
  return [
    imageType,
    game,
    mapName,
  ].join('.');
};

const board = (map: TBoards): AnnotationMap => {
  return {
    game    : 'board',
    gamemode: null,
    key     : buildMapKey('map-overhead', 'board', map),
    trans   : BoardsF(map),
    image   : buildMapImagePath('map-overhead', 'board', map),
    thumb   : buildMapImagePath('map-overhead', 'board', map),
  };
};

const maps: AnnotationMap[] = [
  // League
  leaguemap('summonersrift'),

  // Rocket League
  rocketleaguemap('dfh-stadium'),

  // Overwatch
  owmap('blizzardWorld'),
  owmap('busan'),
  owmap('dorado'),
  owmap('eichenwalde'),
  owmap('hanamura'),
  owmap('havana'),
  owmap('hollywood'),
  owmap('horizonLunarColony'),
  owmap('ilios'),
  owmap('junkertown'),
  owmap('kingsRow'),
  owmap('lijiangTower'),
  owmap('nepal'),
  owmap('numbani'),
  owmap('oasis'),
  owmap('paris'),
  owmap('rialto'),
  owmap('route66'),
  owmap('templeOfAnubis'),
  owmap('volskayaIndustries'),
  owmap('watchpointGibraltar'),

  ssbumap('SmallBattlefield'),
  ssbumap('Battlefield'),
  ssbumap('FinalDestination'),
  ssbumap('Smashville'),
  ssbumap('TownandCity'),
  ssbumap('HollowBastion'),
  ssbumap('KalosPokemonLeague'),
  ssbumap('PokemonStadium2'),
  ssbumap('YoshisStory'),
  ssbumap('LylatCruise'),
  ssbumap('DreamLand'),
  ssbumap('NorthernCave'),
  ssbumap('PokemonStadium'),
  ssbumap('UnovaPokemonLeague'),
  ssbumap('FountainOfDreams'),
  ssbumap('WarioWareInc'),
  ssbumap('CastleSiege'),
  ssbumap('YoshisIsland'),

  valorantmap('bind'),
  valorantmap('breeze'),
  valorantmap('haven'),
  valorantmap('split'),
  valorantmap('ascent'),
  valorantmap('icebox'),
  valorantmap('fracture'),
  valorantmap('lotus'),

  // Rainbow Six Siege
  r6map('bank1F'),
  r6map('bank2F'),
  r6map('bankBF'),
  r6map('bankRF'),
  r6map('border1F'),
  r6map('border2F'),
  r6map('borderRF'),
  r6map('chaletBF'),
  r6map('chalet1F'),
  r6map('chalet2F'),
  r6map('chaletRF'),
  r6map('clubhouseBF'),
  r6map('clubhouse1F'),
  r6map('clubhouse2F'),
  r6map('clubhouseRF'),
  r6map('coastline1F'),
  r6map('coastline2F'),
  r6map('coastlineRF'),
  r6map('consulateBF'),
  r6map('consulate1F'),
  r6map('consulate2F'),
  r6map('consulateRF'),
  r6map('favela1F'),
  r6map('favela2F'),
  r6map('favela3F'),
  r6map('favelaRF'),
  r6map('fortress1F'),
  r6map('fortress2F'),
  r6map('fortressRF'),
  r6map('herefordBF'),
  r6map('hereford1F'),
  r6map('hereford2F'),
  r6map('hereford3F'),
  r6map('herefordRF'),
  r6map('houseBF'),
  r6map('house1F'),
  r6map('house2F'),
  r6map('houseRF'),
  r6map('kafe1F'),
  r6map('kafe2F'),
  r6map('kafe3F'),
  r6map('kafeRF'),
  r6map('kanal1F'),
  r6map('kanal2F'),
  r6map('kanal3F'),
  r6map('kanalRF'),
  r6map('oregonBF'),
  r6map('oregon1F'),
  r6map('oregon2F'),
  r6map('oregon3F'),
  r6map('oregonRF'),
  r6map('outback1F'),
  r6map('outback2F'),
  r6map('outbackRF'),
  r6map('plane1F'),
  r6map('plane2F'),
  r6map('plane3F'),
  r6map('skyscraper1F'),
  r6map('skyscraper2F'),
  r6map('skyscraperRF'),
  r6map('themepark1F'),
  r6map('themepark2F'),
  r6map('themeparkRF'),
  r6map('tower1F'),
  r6map('tower2F'),
  r6map('villaBF'),
  r6map('villa1F'),
  r6map('villa2F'),
  r6map('villaRF'),
  r6map('yachtBF'),
  r6map('yacht1F'),
  r6map('yacht2F'),
  r6map('yacht3F'),
  r6map('yachtRF'),

  // Fortnite
  fortnitemap('c2_s6_fortnite_map'),

  // PUBG
  pubgmap('camp_jackal'),
  pubgmap('erangel'),
  pubgmap('haven'),
  pubgmap('karakin'),
  pubgmap('miramar'),
  pubgmap('paramo'),
  pubgmap('sanhok'),
  pubgmap('vikendi'),

  board('black'),
];

export const ANNOTATION_MAPS: AnnotationMap[] = maps;
