import { emptyQuery } from 'components/dashboard/team-statistics/overwatch-custom-queries/create-query/QueryFormHelper';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

const getOverwatchAdvancedQueryByTeamId = (
  state: RootReducer,
  teamId: ID,
) => state.overwatchAdvancedQueryReducer[teamId];

export const makeGetOverwatchAdvancedByTeamId = () => createSelector(
  [getOverwatchAdvancedQueryByTeamId],
  (advancedQuery) => advancedQuery || {loading: false, latestQuery: emptyQuery},
);
