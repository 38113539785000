import gql from 'graphql-tag';

import { RoleInterfaceFragment } from '../fragments';

export const CreateCustomRole_Mutation = gql`
    mutation CreateCustomRoleMutation(
        $input: CreateCustomRoleInput!
    ) {
        createCustomRole(
            input: $input
        ) {
            role {
                ...RoleInterfaceFragment
            }
        }
    }
    ${RoleInterfaceFragment}
`;
