import {
  bidirectionalActionCreatorsFactory,
  BidirectionalIDRecord,
} from '@insights-gaming/redux-utils';
import { RoleInterfaceFragment_GlobalRole } from 'apollo/fragments/types/RoleInterfaceFragment';
import {
  GetGlobalRolesQuery_queryGlobalRoles,
  GetGlobalRolesQueryVariables,
} from 'apollo/queries/types/GetGlobalRolesQuery';
import { addBidirectionalCases } from 'helpers/addBidirectionalCases';
import { createSlice } from 'helpers/createSlice';
import actionCreatorFactory from 'typescript-fsa';

const name = 'global-roles';
const actionCreator = actionCreatorFactory(name);

const bidirectionalActionCreators = bidirectionalActionCreatorsFactory(actionCreator);

export const fetchGlobalRolesAC = bidirectionalActionCreators<
  GetGlobalRolesQueryVariables,
  GetGlobalRolesQuery_queryGlobalRoles,
  Error
>('FETCH_GLOBAL_ROLES');

export interface GlobalRoleState {
  globalRoleRecords?: BidirectionalIDRecord;
  globalRoleDict: Partial<Dictionary<RoleInterfaceFragment_GlobalRole>>;
}

const initialState: GlobalRoleState = {
  globalRoleDict: {},
};

const globalRoleSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    addBidirectionalCases(builder, fetchGlobalRolesAC, {
      key: (item: RoleInterfaceFragment_GlobalRole) => item.name,
      records: (tp) => tp.globalRoleRecords,
      dict: (tp) => tp.globalRoleDict,
      values: (result) => result.roles,
    });
  },
});

export const globalRoleReducer = globalRoleSlice.reducer;

