import { DrawingTool } from '@insights-gaming/drawing-canvas';
import { VideoReplayActions, videoReplayActions, VideoReplayContext, VideoReplayState } from '@insights-gaming/material-components';
import { ActionFromGuard, ActionGuard, ReducerChannel, useChannelTake, UseTakeFnCallback } from '@insights-gaming/use-take';
import { withActionMetadata } from 'helpers/withActionMetadata';
import { useHackRef } from 'hooks/useHackRef';
import React, { DependencyList, useContext, useEffect } from 'react';

import { DrawingToolContext } from './DrawingToolContext';

type Value = React.ContextType<typeof VideoReplayContext>;

const ignore = Symbol();

function useFilteredChannelTake<Guard extends ActionGuard<VideoReplayActions, any>>(
  channel: ReducerChannel<VideoReplayActions, VideoReplayState>,
  guard: Guard,
  callback: UseTakeFnCallback<Guard, VideoReplayState>,
  deps: DependencyList,
) {
  useChannelTake(channel, guard, (action: ActionFromGuard<Guard>, prevState: VideoReplayState) => {
    if (action.meta?.[ignore] || action.meta?.initialization || action.meta?.raw) {
      return;
    }

    callback(action, prevState);
  }, deps);
}

export function useVideoReplayDrawingToolBindings(
  videoReplay: Value,
): Value {
  const { drawingState, resetTool, viewedComment } = useContext(DrawingToolContext);
  const drawingStateRef = useHackRef(drawingState);

  const { dispatch, channel } = videoReplay;

  useEffect(() => {
    const { comment } = viewedComment || {};
    if (!comment) {
      return;
    }

    dispatch(withActionMetadata(
      videoReplayActions.seekTo({ amount: comment.time, type: 'seconds', seekCommitted: true }),
      { [ignore]: true },
    ));

    if (comment.annotation) {
      drawingStateRef.current.loadJSON(comment.annotation);
    } else {
      drawingStateRef.current.reset();
      drawingStateRef.current.setTool(DrawingTool.NONE);
    }
  }, [dispatch, drawingStateRef, viewedComment]);

  useFilteredChannelTake(
    channel,
    [
      videoReplayActions.play,
      videoReplayActions.seekTo,
      videoReplayActions.togglePlayState,
    ],
    () => {
      if (viewedComment) {
        resetTool?.();
      }
    },
    [viewedComment],
  );

  return videoReplay;
}
