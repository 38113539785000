import { FlexSpacer } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import classNames from 'classnames';
import { MAX_LABEL_TO_SHOW } from 'constants/numbers';
import { EIntercomID } from 'constants/strings';
import { makeGetTeamCommentLabelsByTeamId } from 'features/dashboard/tag/dashboard-tag-selector';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { bindHover, bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useCallback, useContext, useMemo } from 'react';
import TagChip from 'subcomponents/tag-chip/TagChip';
import { ID } from 'types/pigeon';

import { VideoReplaySelectedLabelsContext } from '../VideoReplaySelectedLabelsContext';
import LabelSelectorListItem from './LabelSelectorListItem';

interface LabelSelectorOwnProps {
  className?: string;
  teamId: ID;
}

type LabelSelectorProps = LabelSelectorOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelList: {
    width: createRemFromPx(300),
  },
  labelChip: {
    maxWidth: createRemFromPx(100),
  },
  noLabels: {
    textAlign: 'center',
  },
  clearBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
  },
  labelChipContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  moreLabelGrid: {
    padding: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: theme.spacing(0.5),
  },
}), {name: 'LabelSelector'});

function LabelSelector(props: LabelSelectorProps) {
  const classes = useStyles(props);
  const { className, teamId } = props;

  const {
    labels: {
      selected: selectedLabelIds,
      removeFromSelection: removeLabelFromSelection,
      deselectAll: removeAllLabelsFromSelection,
    },
  } = useContext(VideoReplaySelectedLabelsContext);

  const { t } = useStrictTranslation([ 'common', 'video' ]);

  const isDesktop = useIsDesktop();

  const teamLabels = useCreateSelector(makeGetTeamCommentLabelsByTeamId, teamId);
  const popupState = usePopupState({popupId: 'label-filter', variant: 'popover'});
  const moreLabelsPopupState = usePopupState({popupId: 'more-labels', variant: 'popover'});

  const labelsToDisplay = useMemo(() => {
    const labelIds = Array.from(selectedLabelIds.values());
    return teamLabels.filter(tl => {
      return labelIds.some(id => tl.id === id);
    });
  }, [teamLabels, selectedLabelIds]);

  const handleTagOnDelete = useCallback((e: React.SyntheticEvent, label: TagFragment) => {
    removeLabelFromSelection([label.id]);
  }, [removeLabelFromSelection]);

  const handleRemoveAllSelectedLabels = useCallback(() => {
    removeAllLabelsFromSelection();
  }, [removeAllLabelsFromSelection]);

  return (
    <div className={classNames(classes.root, className)}>
      {isDesktop && (
        <FlexSpacer flexAlignItems='center'>
          {labelsToDisplay.map((l, i) => i < MAX_LABEL_TO_SHOW && (
            <TagChip
            key={l.id}
            className={classes.labelChip}
            tag={l}
            handleTagOnDelete={handleTagOnDelete}
            />
          ))}
          {labelsToDisplay.length > MAX_LABEL_TO_SHOW && (
            <div {...bindHover(moreLabelsPopupState)}>
              {t('common:extranumber', {number: labelsToDisplay.length - MAX_LABEL_TO_SHOW})}
            </div>
          )}
        </FlexSpacer>
      )}
      <Tooltip title={t('common:filterlabels')}>
        <IconButton {...bindTrigger(popupState)} size='small' id={EIntercomID.FILTER_LABEL}>
          {labelsToDisplay.length && !isDesktop ? (
            <Badge
            color='error'
            badgeContent=' '
            variant='dot'
            overlap='circle'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            >
              <FilterListIcon fontSize='small' />
            </Badge>
          ) : (
            <FilterListIcon fontSize='small' />
          )}
        </IconButton>
      </Tooltip>
      <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      >
        <List className={classes.labelList}>
          {!teamLabels.length ? (
            <div className={classes.noLabels}>
              <Typography variant='caption'>
                {t(('video:commentlabel.nolabels'))}
              </Typography>
            </div>
          ) : (
            <React.Fragment>
              <div className={classes.clearBtn}>
                <Button size='small' onClick={handleRemoveAllSelectedLabels}>
                  {t('common:clear')}
                </Button>
              </div>
              {teamLabels.map(tl => <LabelSelectorListItem key={tl.id} label={tl} />)}
            </React.Fragment>
          )}
        </List>
      </Popover>
      <Popover
      {...bindPopover(moreLabelsPopupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      >
        <div className={classes.moreLabelGrid}>
          {labelsToDisplay.map((l, i) => i > MAX_LABEL_TO_SHOW - 1 && (
            <div key={l.id} className={classes.labelChipContainer} >
              <TagChip
              className={classes.labelChip}
              tag={l}
              handleTagOnDelete={handleTagOnDelete}
              />
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
}

export default React.memo(LabelSelector);
