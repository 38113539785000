import { useCreateSelector } from '@insights-gaming/redux-utils';
import { CommentFragment } from 'apollo/fragments/types/CommentFragment';
import { ProfileFragment } from 'apollo/fragments/types/ProfileFragment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import { makeGetLikedUsersByCommentId } from '../video-comment-selector';
import { fetchLikedUsersByCommentIdAC } from '../video-comment-slice';

export default function useFetchLikedUsers(
  comment: CommentFragment,
  videoId: ID,
): [ProfileFragment[] | undefined, boolean] {
  const dispatch = useDispatch();

  const [likedUsers, likedUsersFetched] = useCreateSelector(makeGetLikedUsersByCommentId, comment.id);

  useEffect(() => {
    if (comment.likes > 0 && !likedUsersFetched) {
      dispatch(fetchLikedUsersByCommentIdAC.started({ videoId, commentId: comment.id}));
    }
  }, [comment.id, comment.likes, dispatch, likedUsersFetched, videoId]);

  return [likedUsers, likedUsersFetched];
};
