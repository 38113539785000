import { EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import SuccessDialog from 'components/dashboard/team-settings/team-billing/SuccessDialog/SuccessDialog';
import { numToDollarAmount } from 'helpers/formatters';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo, useState } from 'react';

import ContributeCustomTextField from './ContributeCustomTextField';
import ContributeOptionButton from './ContributeOptionButton';

interface ContributeToTeamDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
  team: TeamFragment;
}

type ContributeToTeamDialogProps = ContributeToTeamDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  optContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  options: {
    maxWidth: createRemFromPx(100),
    maxHeight: createRemFromPx(40),
    flex: '1 0 30%',
    margin: theme.spacing(1, 2),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}), {name: 'ContributeToTeamDialog'});

const presetContributions = [500, 1000, 2500, 5000, 10000]; //amount in cents

function ContributeToTeamDialog(props: ContributeToTeamDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs'>
      <ContributeToTeamDialogContent {...props} />
    </Dialog>
  );
}

interface ContributionAmount {
  type: 'preset' | 'custom';
  amount: number;
}

function presetContributionAmount(amount: ContributionAmount | undefined): number | undefined {
  if (amount === undefined || amount.type !== 'preset') {
    return;
  }

  return amount.amount;
}

function ContributeToTeamDialogContent(props: ContributeToTeamDialogProps) {
  const classes = useStyles(props);
  const { className, onClose, team } = props;
  const { t } = useStrictTranslation(['dashboard']);

  const [isCardDialogOpen, openCardDialog, closeCardDialog] = useDialogState();
  const [isSuccessDialogOpen, openSuccessDialog, closeSuccessDialog] = useDialogState();

  const [amount, setAmount] = useState<ContributionAmount | undefined>({
    type: 'preset',
    amount: presetContributions[0],
  });

  const optionOnClick = useCallback((amount: number) => setAmount({ type: 'preset', amount }), []);

  const setCustomValue = useCallback(
    (value: number | undefined) => setAmount(value === undefined ? undefined : { type: 'custom', amount: value * 100 }),
    [],
  );

  const onFocus = useCallback(() => setAmount((amount) => amount?.type !== 'custom' ? undefined : amount), []);

  const error = useMemo(() => amount === undefined, [amount]);

  return (
    <div className={classNames(classes.root, className)}>
      <EnhancedDialogTitle onClose={onClose}>
        {t('dashboard:contribute.title')}
      </EnhancedDialogTitle>
      <DialogContent>
        <Typography>
          {t('dashboard:contribute.showsupport')}
        </Typography>
        <div className={classes.optContainer}>
          {
            presetContributions.map((contribution) => {
              return (
                <ContributeOptionButton
                key={contribution}
                contribution={contribution}
                onClick={optionOnClick}
                selected={presetContributionAmount(amount) === contribution}
                />
              );
            })
          }
          <ContributeCustomTextField
          error={error}
          cleared={presetContributionAmount(amount) === undefined}
          setCustomValue={setCustomValue}
          onFocus={onFocus}
          className={classes.options}
          min={5}
          max={1000}
          />
        </div>
        {error && (
          <Typography variant='caption' color='error'>
            {t('dashboard:contribute.helpertext')}
          </Typography>
        )}
        <Divider className={classes.divider}/>
        <FlexSpacer flexAlignItems='center' flexJustifyContent='space-between'>
          <Typography>
            {t('dashboard:contribute.giftamountto', { team: team.name })}
          </Typography>
          {amount && (
            <Typography>
              {t('dashboard:contribute.dollaramount', { amount: numToDollarAmount(amount.amount / 100) })}
            </Typography>
          )}
        </FlexSpacer>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          {t('dashboard:contribute.cancel')}
        </Button>
        <Button
        variant='contained'
        color='primary'
        disabled={amount === undefined}
        onClick={openCardDialog}
        >
          {t('dashboard:contribute.topayment')}
        </Button>
      </DialogActions>
      <SuccessDialog open={isSuccessDialogOpen} onClose={closeSuccessDialog} update={false} team={team}/>
    </div>
  );
}

export default React.memo(ContributeToTeamDialog);
