import { AsyncButton } from '@insights-gaming/material-components/AsyncButton';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import { INSIGHTS_CAPTURE_DOWNLOAD, oauthEndpoint, OAuthProvider } from 'constants/index';
import { ACTION_SNACKBAR_DURATION, MAX_EMAIL_LENGTH } from 'constants/numbers';
import { ILoginInput, loginAsyncAC, sendVerificationEmailAC } from 'features/auth/auth-slice';
import { createTitle, prepareEmail } from 'helpers';
import { createUTMParams } from 'helpers/createUTMParams';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TLogin } from 'locales/en/login';
import IconTextField from 'material/icon-text-field/IconTextField';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { FORGOT_PASSWORD_PATH, REGISTER_PATH } from 'routes';
import DiscordButton from 'subcomponents/discord-button/DiscordButton';

import GoogleButton from './content/GoogleButton';
import { LoginDialogContext } from './LoginDialogContext';

declare var PublicKeyCredential: {
  prototype: PublicKeyCredential;
  new(): PublicKeyCredential;
  isConditionalMediationAvailable(): Promise<boolean>;
  isUserVerifyingPlatformAuthenticatorAvailable(): Promise<boolean>;
};

function errorMsg(error: Error): TLogin | void {
  switch (error.message.split(':')[0]) {
    case 'FORM_INCOMPLETE'            : return 'errors.formincomplete';
    case 'BAD_CREDENTIALS'            : return 'errors.badcredentials';
    case 'NOT_VERIFIED'               : return 'errors.notverified';
    case 'TOO_MANY_ATTEMPTS_TRY_LATER': return 'errors.toomanyattempts';
  }
}

interface LoginVerifyActionButtonOwnProps {
  email: string;
  snackbarKey: string | number | undefined;
}

type LoginVerifyActionButtonProps = LoginVerifyActionButtonOwnProps;

const signInUTM = '&' + createUTMParams('insightsgg', 'signin', 'ic_download');

function LoginVerifyActionButton({ email, snackbarKey }: LoginVerifyActionButtonProps) {
  const { t } = useStrictTranslation('login');

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const [emailSent, setEmailSent] = React.useState(false);
  const [sendingEmail, setSendingEmail] = React.useState(false);

  const handleSendEmail = useCallback(async () => {
    if (!email) { return; }
    if (sendingEmail || emailSent) { return; }
    setSendingEmail(true);

    try {
      await promiseSagaDispatch(sendVerificationEmailAC, prepareEmail(email));
      setEmailSent(true);
      if (snackbarKey) {
        closeSnackbar(snackbarKey);
      }
    } catch (error) {
      const tkey = errorMsg(error);
      const msg = tkey ? t(tkey) : error.message;
      enqueueSnackbar(msg, { variant: 'error' });
    } finally {
      setSendingEmail(false);
    }
  }, [closeSnackbar, email, emailSent, enqueueSnackbar, promiseSagaDispatch, sendingEmail, snackbarKey, t]);

  return (
    <AsyncButton
    onClick={handleSendEmail}
    loading={sendingEmail}
    type='button'
    disabled={emailSent || sendingEmail}
    >
      {t('resendemail')}
    </AsyncButton>
  );
}

export interface LoginDialogContentOwnProps {}

export type LoginDialogContentProps = LoginDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  link: {
    textDecoration: 'none',
  },
  thirdPartyAuthButton: {
    margin: theme.spacing(1, 0),
  },
  footerButton: {
    marginLeft: theme.spacing(1),
  },
  readyToRecordText: {
    fontSize: createRemFromPx(16),
  },
  footerAnchor: {
    marginLeft: theme.spacing(1),
    fontSize: createRemFromPx(16),
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: createRemFromPx(24),
  },
  titleText: {
    fontWeight: 600,
  },
}), {name: 'LoginDialogContent'});

function LoginDialogContent(props: LoginDialogContentProps) {
  const classes = useStyles(props);

  const { t } = useStrictTranslation(['login', 'common']);

  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const { loginState } = useContext(LoginDialogContext);

  const [error, setError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [formFields, setFormFields] = React.useState<{ username?: string; password?: string }>({});

  const handleOAuthClick = useCallback((provider: OAuthProvider) => {
    window.location.href = oauthEndpoint(provider, loginState);
  }, [loginState]);

  const handleGoogleOAuthClick = useCallback(() => handleOAuthClick('google-oauth2'), [handleOAuthClick]);

  const handleDiscordOAuthClick = useCallback(() => handleOAuthClick('discordapp'), [handleOAuthClick]);

  const handleLogin = useCallback(async (input: ILoginInput) => {
    try {
      setLoading(true);
      if (!(await promiseSagaDispatch(loginAsyncAC, input))) {
        enqueueSnackbar(t('login:errors.loginfailed'), { variant: 'error' });
      }
    } catch (error) {
      setError(true);
      const tkey = errorMsg(error);
      const msg = tkey ? t(`login:${tkey}`) : error.message;
      if (tkey === 'errors.notverified' && 'username' in input) {
        enqueueSnackbar(msg, {
          variant: 'error',
          autoHideDuration: ACTION_SNACKBAR_DURATION,
          // eslint-disable-next-line react/display-name
          action: (key) => <LoginVerifyActionButton email={input.username} snackbarKey={key} />,
        });
      } else {
        enqueueSnackbar(msg, { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, promiseSagaDispatch, t]);

  const handlePasswordLogin = useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }

    const { username, password } = formFields;
    if (!(username && password)) {
      setError(true);
      enqueueSnackbar(t('login:errors.formincomplete'), { variant: 'error' });
      return;
    }

    handleLogin({ username: prepareEmail(username), password });
  }, [enqueueSnackbar, formFields, loading, handleLogin, t]);

  const handleInputOnChange = useCallback((e: React.SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormFields((prevState) => ({...prevState, [name]: value}));
  }, []);

  useEffect(() => {
    const effectAborter = new AbortController();
    let nonceAborter = new AbortController();
    let timeout: ReturnType<typeof setTimeout>;

    if (window.PublicKeyCredential && PublicKeyCredential.isConditionalMediationAvailable) {
      PublicKeyCredential.isConditionalMediationAvailable().then(async (available) => {
        if (!available) return;

        let nonce: string | null = null;
        while (!effectAborter.signal.aborted) {
          if (!nonce) {
            let expires: number;
            ({ nonce, expires } = await (await fetch('/oauth/nonce', { method: 'POST' })).json());
            timeout = setTimeout(
              () => {
                nonceAborter.abort('timeout');
                nonceAborter = new AbortController();
              },
              expires * 1000 - Date.now(),
            );
          }

          try {
            const cred = await navigator.credentials.get({
              publicKey: {
                  challenge: Uint8Array.from(nonce!, (c) => c.charCodeAt(0)),
                  rpId: window.location.hostname,
              },
              signal: (AbortSignal as any).any([effectAborter.signal, nonceAborter.signal]),
              mediation: 'conditional' as any,
            });
            if (
              !(cred instanceof PublicKeyCredential) ||
              cred.type !== 'public-key' ||
              !(cred.response instanceof AuthenticatorAssertionResponse) ||
              !cred.response.userHandle
            ) {
              continue;
            }

            nonce = null;

            await handleLogin({
              user_id: new TextDecoder().decode(cred.response.userHandle),
              key_id: cred.id,
              client_data: new TextDecoder().decode(cred.response.clientDataJSON),
              authenticator_data: cred.response.authenticatorData,
              signature: cred.response.signature,
            });
          } catch (err) {
            if (err.name === 'AbortError') {
              if (effectAborter.signal.aborted) {
                return;
              }

              nonce = null;
              continue;
            }

            throw err;
          }
        }
      });
    }

    return () => {
      if (timeout) clearTimeout(timeout);
      effectAborter.abort('cleanup');
      nonceAborter.abort('cleanup');
    };
  }, [handleLogin]);

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>{createTitle(t('common:appname'), t('login:login'))}</title>
      </Helmet>
      <DialogTitle className={classes.title} disableTypography={true}>
        <Typography variant='h2' className={classes.titleText}>
          {t('login:login')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.thirdPartyAuthButton}>
          <GoogleButton
          type='light'
          onClick={handleGoogleOAuthClick}
          label={t('login:signinwithgoogle')}
          />
        </Box>
        <Box className={classes.thirdPartyAuthButton}>
          <DiscordButton onClick={handleDiscordOAuthClick}>
            {t('login:signinwithdiscord')}
          </DiscordButton>
        </Box>
        <Box my={2}>
          <Divider variant='fullWidth' />
        </Box>
        <form onSubmit={handlePasswordLogin}>
          <IconTextField
          icon={<EmailIcon />}
          name='username'
          type='email'
          label={t('login:email')}
          placeholder={t('login:email')}
          autoComplete='username webauthn'
          fullWidth={true}
          onChange={handleInputOnChange}
          error={!!error || (formFields.username?.length ?? 0) > MAX_EMAIL_LENGTH}
          required={true}
          autoFocus={true}
          />
          <IconTextField
          icon={<LockIcon />}
          name='password'
          type='password'
          label={t('login:password')}
          placeholder={t('login:password')}
          fullWidth={true}
          onChange={handleInputOnChange}
          error={!!error}
          required={true}
          />
          {/* <Form.Checkbox label={t(LoginF('rememberme'))} className={styles.rememberme}/> */}
          {/* NOTE: Code below is Material UI version of USELESS Checkbox */}
          {/* <FormControlLabel control={<Checkbox />} label={t(LoginF('rememberme'))} /> */}
          <Box display='flex' justifyContent='flex-end' my={2.25}>
            <Link
            to={{ pathname: FORGOT_PASSWORD_PATH, state: loginState }}
            className={classes.link}
            >
              <Button
              type='button'
              variant='text'
              >
                {t('login:forgotpw')}
              </Button>
            </Link>
          </Box>
          <Box mb={3}>
            <AsyncButton
            type='submit'
            variant='contained'
            color='primary'
            fullWidth={true}
            loading={loading}
            disabled={loading}
            >
              {t('login:login')}
            </AsyncButton>
          </Box>
        </form>
        <Box className={classes.footer}>
          <Typography>
            {t('login:noaccount')}
          </Typography>
          <Link
          to={{ pathname: REGISTER_PATH, state: loginState }}
          className={classes.link}
          >
            <Button
            className={classes.footerButton}
            >
              {t('login:signup')}
            </Button>
          </Link>
        </Box>
        <Box mt={2} className={classes.footer}>
          <Typography className={classes.readyToRecordText}>
            {t('login:readytorecord')}
          </Typography>
          <a
          className={classes.footerAnchor}
          href={INSIGHTS_CAPTURE_DOWNLOAD.url+signInUTM}
          download={INSIGHTS_CAPTURE_DOWNLOAD.name}
          >
            {t('login:download')}
          </a>
        </Box>
      </DialogContent>
    </Box>
  );
}

export default React.memo(LoginDialogContent);
