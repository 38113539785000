import Button from '@material-ui/core/Button';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import { EIntercomID } from 'constants/strings';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import ChangeVideoDialog from 'material/dialogs/change-video-dialog/ChangeVideoDialog';
import React, { useCallback } from 'react';
import { EVideoViewerTabType, videoRoute } from 'routes';
import { ID } from 'types/pigeon';

interface ChangeVideoButtonOwnProps {
  teamId?: ID;
  directoryId?: ID;
}

type ChangeVideoButtonProps = ChangeVideoButtonOwnProps;

function ChangeVideoButton({ teamId, directoryId }: ChangeVideoButtonProps) {
  const { t } = useStrictTranslation(['common']);
  const [isOpen, open, onClose] = useDialogState();

  const onNavigate = useNavigate();

  const handleVideoSelected = useCallback((video: VideoFragment) => {
    onNavigate(videoRoute(video.id, EVideoViewerTabType.VIDEO_REPLAY));
    onClose();
  }, [onClose, onNavigate]);

  return (
    <React.Fragment>
      <Button
      id={EIntercomID.CHANGE_VIDEOS}
      startIcon={<SwitchVideoIcon />}
      onClick={open}
      >
        {t('common:session.changevideo')}
      </Button>
      {teamId && directoryId && (
        <ChangeVideoDialog
        open={isOpen}
        onClose={onClose}
        fullWidth={true}
        onVideoChange={handleVideoSelected}
        teamId={teamId}
        directoryId={directoryId}
        teamPicker={true}
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(ChangeVideoButton);
