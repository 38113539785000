import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import React from 'react';

type ValidateFn = (s: string) => boolean;

export interface IValidationEvent {
  name: string;
  value: string;
  valid: boolean;
}

interface IWithValidationProps {
  suppressErrorOnEmptyString?: boolean;
  validate?: ValidateFn | ValidateFn[];
  onValidityChange?: (res: IValidationEvent) => void;
  validateOnEveryChange?: boolean; // DANGER: HIGH VOLTAGE
}

type SupportedInputProps = TextFieldProps;

type EnhancedMethods = 'onChange' | 'onBlur';
export type WithValidation = EnhancedMethods;

interface IWithValidationOptions {
  trim?: boolean;
}

export function withValidation(
  options: IWithValidationOptions | null | undefined | void,
) {
  const {
    trim = false,
  } = options || {};
  return function <P extends IWithValidationProps & WithValidation & SupportedInputProps>(
    Component: React.ComponentType<Omit<P, keyof IWithValidationProps | keyof WithValidation>>,
  ): React.ComponentType<Omit<P, keyof WithValidation>> {
    type Props = P & IWithValidationProps;
    interface State {
      error: boolean;
    }
    return class extends React.PureComponent<Props, State> {
      public static displayName = `Validation(${Component.displayName || Component.name})`;

      constructor(props: Props) {
        super(props);
        this.state = {error: false};
      }

      public render() {
        const {
          onChange,
          onBlur,
          onValidityChange,
          validate,
          suppressErrorOnEmptyString,
          validateOnEveryChange,
          ...rest
        } = this.props;
        const { error } = this.state;
        return (
          <Component
          {...rest as P}
          error={error}
          onChange={this.handleOnChange}
          onBlur={this.handleOnBlur}
          />
        );
      }

      private validateValue = (v: string): boolean => {
        const { validate, suppressErrorOnEmptyString } = this.props;
        if (!validate) { return true; }
        if (suppressErrorOnEmptyString && v === '') { return true; }
        if (Array.isArray(validate)) {
          return validate.every(fn => fn(v));
        }
        return validate(v);
      }

      private handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onValidityChange, onChange, validateOnEveryChange } = this.props;
        const { error } = this.state;
        if (trim) {
          event.target.value = event.target.value.trim();
        }
        const { name, value } = event.target;
        if (onChange) { onChange(event); }

        if (validateOnEveryChange) {
          const valid = this.validateValue(value);
          if (onValidityChange) { onValidityChange({name, valid, value}); }
          this.setState({error: false});
        } else if (error) {
          const valid = this.validateValue(value);
          if (valid) {
            if (onValidityChange) { onValidityChange({name, valid, value}); }
            this.setState({error: false});
          }
        }
      }

      private handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { onValidityChange, onBlur } = this.props;
        const { name, value } = event.target;
        const valid = this.validateValue(value);
        if (onValidityChange) { onValidityChange({name, valid, value}); }
        this.setState({error: !valid});
        if (onBlur) { onBlur(event); }
      }
    };
  };
};

export const ValidateTextField = withValidation({trim: true})(TextField);
