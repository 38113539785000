import { FlexSpacer, Loader } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { Invitation2Fragment } from 'apollo/fragments/types/Invitation2Fragment';
import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import { getGlobalRolesDict, makeGetGlobalRoles } from 'components/global-roles/global-role-selector';
import formatDuration from 'date-fns/formatDuration';
import { parse as parseDuration } from 'duration-fns/dist/parse';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import {  makeGetTeamInvitationsByTeamId } from 'features/dashboard/invitation/dashboard-invitation.selector';
import { makeGetTeamCustomRolesByTeamId } from 'features/dashboard/role/dashboard-role-selector';
import { createInvitationAC } from 'features/dashboard/team/dashboard-team-slice';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { invitationCodeRoute } from 'routes';
import CopyLink from 'subcomponents/copy-link/CopyLink';
import { ID } from 'types/pigeon';

import LinkSettingsDialogContent, {
  defaultExpiryDuration,
  neverexpires,
} from '../link-settings-dialog-content/LinkSettingsDialogContent';
import { EGlobalRoles } from './role-helper';

interface InviteTeamMembersDialogContentOwnProps {
  className?: string;
  teamId: ID;
  onClose: VoidFunction;
  directory?: DirectoryFragment;
}

type InviteTeamMembersDialogContentProps = InviteTeamMembersDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  roleSelectFormControl: {
    justifyContent: 'center',
    flex: 1,
  },
  copyLink: {
    flex: 2,
  },
  desc: {
    marginBottom: theme.spacing(3),
  },
  inviteText: {
    opacity: 0.5,
  },
}), {name: 'InviteTeamMembersDialogContent'});

const noRole = 'none';

function InviteTeamMembersDialogContent(props: InviteTeamMembersDialogContentProps) {
  const classes = useStyles(props);
  const { className, teamId, onClose, directory } = props;

  const { t } = useStrictTranslation(['common', 'dialog', 'dashboard']);
  const wt = t as TFunction;
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const accessControl = useAccessControl();

  const [ createInvitationLoading, setCreateInvitationLoading ] = useState(false);
  const [ isLinkSettingsDialogOpen, openLinkSettingsDialog, closeLinkSettingsDialog ] = useDialogState();

  const globalRoles = useCreateSelector(makeGetGlobalRoles, '');
  const globalRolesDict = useSelector(getGlobalRolesDict);
  const customRoles = useCreateSelector(makeGetTeamCustomRolesByTeamId, {teamId});

  const filteredCombinedRoles = useMemo(() => {
    const filteredGlobalRoles = accessControl.filterRolesByUserPrivileges(globalRoles);
    const filteredCustomRoles = accessControl.filterRolesByUserPrivileges(customRoles);
    return [...filteredGlobalRoles, ...filteredCustomRoles];
  }, [accessControl, globalRoles, customRoles]);

  const invitations = useCreateSelector(makeGetTeamInvitationsByTeamId, teamId);

  const [ createdInvitation, setCreatedInvitation ] = useState<Invitation2Fragment>();
  const [ role, setRole ] = useState<RoleInterfaceFragment | undefined>(
    () => !directory
      && filteredCombinedRoles.find(r => r.__typename === 'GlobalRole' && r.name === EGlobalRoles.MEMBER)
      || filteredCombinedRoles.find(r => r.__typename === 'GlobalRole' && r.name === EGlobalRoles.GUEST),
  );

  const [creationError, setCreationError] = useState<any>();

  const [ expiry, setExpiry ] = useState(defaultExpiryDuration);
  const [ useLimit, setUseLimit ] = useState(-1);

  const handleCreateInvitationLink = useCallback(async () => {
    if (createInvitationLoading || creationError) { return; }
    closeLinkSettingsDialog();
    setCreateInvitationLoading(true);
    try {
      let roleField;
      if (role) {
        switch (role.__typename) {
          case 'GlobalRole':
            roleField = {
              globalRoles: [role.name],
            };
            break;
          case 'CustomRole':
            roleField = {
              roles: [role.id],
            };
            break;
          default:
            break;
        }
      }
      const createdInvitation = await promiseSagaDispatch(createInvitationAC, {
        teamId,
        expiry: expiry === neverexpires ? undefined : expiry,
        limit: useLimit > 0 ? useLimit : undefined,
        folderId: directory ? directory.id : undefined,
        ...roleField,
      });
      setCreatedInvitation(createdInvitation.invitation);
    } catch (error) {
      setCreationError(error);
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setCreateInvitationLoading(false);
    }
  }, [
    createInvitationLoading,
    creationError,
    closeLinkSettingsDialog,
    role,
    promiseSagaDispatch,
    teamId,
    expiry,
    useLimit,
    directory,
    enqueueSnackbar,
  ]);

  useEffect(() => {
    if (createdInvitation) {
      return;
    }
    handleCreateInvitationLink();
  }, [
    createdInvitation,
    handleCreateInvitationLink,
  ]);

  useEffect(() => {
    if (createdInvitation) {
      let existingLink;
      if (role) {
        switch (role.__typename) {
          case 'CustomRole':
            existingLink = createdInvitation.roles
              ? createdInvitation.roles.find(r => r === role.id)
              : undefined;
            break;
          case 'GlobalRole':
            existingLink = createdInvitation.globalRoles
              ? createdInvitation.globalRoles.find(r => r === role.name)
              : undefined;
            break;
          default:
            break;
        }
      } else {
        existingLink = !createdInvitation.globalRoles && !createdInvitation.roles;
      }
      if (!existingLink) {
        handleCreateInvitationLink();
      }
    }
  }, [role, handleCreateInvitationLink, createdInvitation]);

  const handleRoleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target: { value } } = e;
    const role = globalRolesDict[value] || customRoles.find(cr => cr.id === value);
    let existingLink;
    if (role) {
      switch (role.__typename) {
        case 'CustomRole':
          existingLink = invitations.find(i => i.roles ? i.roles.find(r => r === value) : undefined);
          break;
        case 'GlobalRole':
          existingLink = invitations.find(i => i.globalRoles ? i.globalRoles.find(r => r === value) : undefined);
          break;
        default:
          break;
      }
    } else {
      existingLink = invitations.find(i => !i.globalRoles && !i.roles);
    }
    if (existingLink) {
      setCreatedInvitation(existingLink);
    }
    setRole(role);
  }, [customRoles, globalRolesDict, invitations]);

  const handleExpiryChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setExpiry(value);
  }, []);

  const handleUseLimitChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    setUseLimit(+value);
  }, []);

  const renderLinkCaption = useCallback(() => {
    let caption = null;
    if (expiry === neverexpires && useLimit === -1) {
      caption = t('dialog:inviteteammembers.linkcaption.neverexpiresandunlimited');
    }
    if (expiry === neverexpires && useLimit !== -1) {
      caption = t('dialog:inviteteammembers.linkcaption.noexpiryandlimiteduse', { count: useLimit });
    }
    if (expiry !== neverexpires && useLimit === -1) {
      caption = t(
        'dialog:inviteteammembers.linkcaption.unlimitedusewithexpiry',
        { expiry: formatDuration(parseDuration(expiry))},
      );
    }
    if (expiry !== neverexpires && useLimit !== -1) {
      caption = t(
        'dialog:inviteteammembers.linkcaption.expiryandlimiteduse',
        { expiry: formatDuration(parseDuration(expiry)), count: useLimit },
      );
    }
    return caption;
  }, [expiry, t, useLimit]);

  return (
    <React.Fragment>
      <BetterDialogTitle>
        {directory ? t('dialog:invitechannel.invitecollaborators') : t('dialog:inviteteammembers.title')}
      </BetterDialogTitle>
      <DialogContent>
        <FlexSpacer orientation='vertical'>
          <Collapse in={!createInvitationLoading}>
            <FlexSpacer orientation='vertical'>
              <Typography className={classes.desc}>
                {directory ? t('dialog:invitechannel.description') : t('dialog:inviteteammembers.assignarole')}
              </Typography>
              <FlexSpacer>
                {accessControl.canAssignRole && !directory && (
                  <FormControl className={classes.roleSelectFormControl}>
                    <InputLabel id='invite-link-role'>{t('dashboard:team.roles.role')}</InputLabel>
                    <Select
                    labelId='invite-link-role'
                    value={!role ? noRole : role.__typename === 'CustomRole' ? role.id : role.name}
                    onChange={handleRoleChange}
                    disabled={!!directory}
                    >
                      <MenuItem value={noRole}>{t('common:none')}</MenuItem>
                      {filteredCombinedRoles.map(r => r.__typename === 'CustomRole' ? (
                        <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>
                      ) : r.__typename === 'GlobalRole' ? (
                        <MenuItem key={r.name} value={r.name}>{wt(`common:roles.global.${r.name}.title`)}</MenuItem>
                      ) : null)}
                    </Select>
                  </FormControl>
                )}
                <div className={classes.copyLink}>
                  <Typography variant='caption'>
                    {renderLinkCaption()}
                  </Typography>
                  {createdInvitation && (
                    <CopyLink textToCopy={window.location.origin + invitationCodeRoute(createdInvitation.code)} />
                  )}
                </div>
              </FlexSpacer>
            </FlexSpacer>
          </Collapse>
          {creationError ? (
            <Typography variant='caption'>
              {creationError.message}
            </Typography>
          ) : createInvitationLoading && (
            <Loader>
              {t('dialog:inviteteammembers.creatinglink')}
            </Loader>
          )}
        </FlexSpacer>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
        onClick={openLinkSettingsDialog}
        startIcon={<SettingsIcon />}
        size='small'
        >
          {t('dialog:inviteteammembers.linksettings')}
        </Button>
        <Button
        type='button'
        variant='outlined'
        onClick={onClose}
        >
          {t('common:cancel')}
        </Button>
      </DialogActions>
      <Dialog open={isLinkSettingsDialogOpen} onClose={closeLinkSettingsDialog} fullWidth={true}>
        <LinkSettingsDialogContent
        handleCreateInvitationLink={handleCreateInvitationLink}
        onClose={closeLinkSettingsDialog}
        expiry={expiry}
        useLimit={useLimit}
        handleExpiryChange={handleExpiryChange}
        handleUseLimitChange={handleUseLimitChange}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default React.memo(InviteTeamMembersDialogContent);
