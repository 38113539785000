import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

import { ASSETS_ENDPOINT } from '../../constants';
import GuidedSetupActions from './GuidedSetupActions';

interface WelcomeOwnProps {
  className?: string;
  onClose: VoidFunction;
  stepForward: VoidFunction;
  step: number;
}

type WelcomeProps = WelcomeOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  noPendingInvites: {
    width: 170,
    height: 170,
    backgroundSize: 'cover',
    backgroundImage: () =>
    `url(${ASSETS_ENDPOINT}/guided-setup/no-pending-invites.svg)`,
    position: 'absolute',
    top: 121,
    right: 98,
  },
  intro: {
    position: 'absolute',
    top: 176,
    left: 60,
  },
  header: {
    marginBottom: theme.spacing(1.5),
  },
  skip: {
    position: 'absolute',
    bottom: 24,
    left: 24,
    cursor: 'pointer',
  },
}), {name: 'Welcome'});

function Welcome(props: WelcomeProps) {
  const classes = useStyles(props);
  const { className, onClose, stepForward, step } = props;
  const { t } = useStrictTranslation(['dialog']);

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.noPendingInvites}/>
      <div className={classes.intro}>
        <Typography variant='h3' className={classes.header}>
          {t('dialog:onboarding.welcome')}
        </Typography>
        <Typography variant='h6'>
          {t('dialog:onboarding.welcometext')}
        </Typography>
      </div>
      <Typography variant='caption' className={classes.skip} onClick={onClose}>
        {t('dialog:onboarding.skip')}
      </Typography>
      <GuidedSetupActions stepForward={stepForward} step={step}/>
    </div>
  );
}

export default React.memo(Welcome);
