import { ReactComponent as AntiHeal } from './anti-heal.svg';
import { ReactComponent as Freeze } from './freeze.svg';
import { ReactComponent as Hack } from './hack.svg';
import { ReactComponent as MechKill } from './mech-kill.svg';
import { ReactComponent as Skull } from './skull.svg';
import { ReactComponent as Sleep } from './sleep.svg';
import { ReactComponent as Stunned } from './stunned.svg';
import { ReactComponent as UnknownStatus } from './unknown.svg';

export { AntiHeal, Freeze, Hack, MechKill, Skull, Sleep, Stunned, UnknownStatus };
