import { useStripe } from '@stripe/react-stripe-js';
import { retryTeamSubscriptionAC } from 'features/dashboard/billing/dashboard-billing-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useCallback } from 'react';

export interface SubscriptionMutationResult {
  invoiceStatus?: string | null;
  secret?: string | null;
};

export function useSubscriptionMutationResultHandler(
  teamId: string,
  onSuccess: () => void,
  onError: (err?: { message?: string }) => void,
) {
  const stripe = useStripe();
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const retrySubscription = useCallback(
    (paymentId: string): Promise<SubscriptionMutationResult> => {
      return promiseSagaDispatch(retryTeamSubscriptionAC, {
        paymentId,
        teamId,
      });
    },
    [promiseSagaDispatch, teamId],
  );

  const handleSubscriptionMutationResult = useCallback(
    async (p: SubscriptionMutationResult | undefined): Promise<void> => {
      if (!stripe || !p || p.invoiceStatus !== 'requires_payment_method' ) {
        onSuccess();
        return;
      }

      try {
        const { invoiceStatus, secret } = (p || {}) as SubscriptionMutationResult;

        if (!(invoiceStatus === 'requires_action' || invoiceStatus === 'requires_payment_method') || !secret) {
          onSuccess();
          return;
        }

        const { error, paymentIntent } = await stripe.confirmCardPayment(secret);
        if (error && error.message) {
          onError(error);
        } else if (paymentIntent?.payment_method) {
          return handleSubscriptionMutationResult(await retrySubscription(paymentIntent.payment_method));
        }
      } catch (error) {
        onError(error);
      }
    },
    [onError, onSuccess, retrySubscription, stripe],
  );

  return handleSubscriptionMutationResult;
}
