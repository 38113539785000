import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { mobilePortrait } from 'features/media-queries';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { ComponentProps, Ref, useCallback, useEffect, useMemo, useState } from 'react';

interface CardInputsOwnProps {
  className?: string;
  error: string | boolean;
  onCompleteStateChange?: (complete: boolean) => void;
}

type CardInputsProps = CardInputsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: createRemFromPx(586),
    [mobilePortrait(theme)]: {
      width: '100%',
    },
    alignSelf: 'center',
  },
  input: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: theme.spacing(1, 0),
    [mobilePortrait(theme)]: {
      flexDirection: 'column',
    },
  },
  longLabel: {
    flex: '1 1 60%',
    [mobilePortrait(theme)]: {
      margin: theme.spacing(1),
    },
  },
  shortLabel: {
    flex: '1 1 20%',
    [mobilePortrait(theme)]: {
      margin: theme.spacing(1),
    },
  },
  field: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    [mobilePortrait(theme)]: {
      padding: theme.spacing(0.5),
    },
  },
  divider: {
    margin: theme.spacing(0, 1),
  },
  error: {
    flex: 1,
  },
}), {name: 'CardInputs'});

function CardInputs(props: CardInputsProps, ref: Ref<HTMLDivElement>) {
  const classes = useStyles(props);
  const { className, error, onCompleteStateChange } = props;

  const { t } = useStrictTranslation(['dashboard']);

  const theme = useTheme();

  const options = useMemo(() => ({
    style: {
      margin: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      height: 30,
      base: {
        color: theme.palette.text.primary,
        fontWeight: '400',
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: theme.palette.text.disabled,
          backgroundColor: theme.palette.background.default,
        },
      },
      invalid: {
        color: theme.palette.error.main,
      },
    } as Required<ComponentProps<typeof CardNumberElement>>['options']['style'],
  }), [theme]);

  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [cardCvcComplete, setCardCvcComplete] = useState(false);

  useEffect(() => {
    onCompleteStateChange?.(cardNumberComplete && cardExpiryComplete && cardCvcComplete);
  }, [onCompleteStateChange, cardNumberComplete, cardExpiryComplete, cardCvcComplete]);

  const handleCardNumberChange = useCallback((e: { complete: boolean }) => setCardNumberComplete(e.complete), []);
  const handleCardExpiryChange = useCallback((e: { complete: boolean }) => setCardExpiryComplete(e.complete), []);
  const handleCardCvcChange = useCallback((e: { complete: boolean }) => setCardCvcComplete(e.complete), []);

  return (
    <div className={classes.root}>
      <div className={classes.input} ref={ref}>
        <label className={classes.longLabel}>
          <Typography>
            {t('dashboard:billing.cardnumber')}
          </Typography>
          <div className={classes.field}>
            <CardNumberElement options={options} onChange={handleCardNumberChange} />
          </div>
        </label>
        <Divider flexItem={true} orientation='vertical' className={classes.divider}/>
        <FlexSpacer flexAlignItems='center' fullWidth={true}>
          <label className={classes.shortLabel}>
            <Typography>
              {t('dashboard:billing.expiry')}
            </Typography>
            <div className={classes.field}>
              <CardExpiryElement options={options} onChange={handleCardExpiryChange} />
            </div>
          </label>
          <Divider flexItem={true} orientation='vertical' className={classes.divider}/>
          <label className={classes.shortLabel}>
            <Typography>{t('dashboard:billing.cvc')}</Typography>
            <div className={classes.field}>
              <CardCvcElement options={options} onChange={handleCardCvcChange}/>
            </div>
          </label>
        </FlexSpacer>
      </div>
      {error && (
        <FlexSpacer flexJustifyContent='flex-end' flexAlignItems='center' fullWidth={true}>
          <Typography variant='subtitle2' color='error' className={classes.error}>
            {error}
          </Typography>
        </FlexSpacer>
      )}
    </div>
  );
};

export default React.memo(React.forwardRef(CardInputs));
