import Button from '@material-ui/core/Button';
import React from 'react';

import { IPageItem } from './itemFactory';

export interface IPaginationOwnProps {
  onClick: (e: React.SyntheticEvent) => void;
}

export default class PaginationItem extends React.Component<IPageItem & IPaginationOwnProps> {
  public get innerText(): string | number {
    switch (this.props.type) {
      case 'ellipsisItem': return '...';
      case 'firstItem'   : return '«';
      case 'lastItem'    : return '»';
      case 'prevItem'    : return '‹';
      case 'nextItem'    : return '›';
           default       : return this.props.value;
    }
  }

  public render() {
    const { active } = this.props;
    return (
      <Button variant={active ? 'contained' : undefined} onClick={this.props.onClick}>
        {this.innerText}
      </Button>
    );
  }
}
