import { useCreateSelector } from 'hooks/useCreateSelector';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import { makeGetVideoById, makeGetVideoFetchStatusById } from './dashboard-video-selector';
import { fetchVideosByIdAC } from './dashboard-video-slice';

export function useFetchVideoIfNecessary(videoId?: ID, nobatch = false) {
  const video = useCreateSelector(makeGetVideoById, videoId);
  const status = useCreateSelector(makeGetVideoFetchStatusById, videoId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!videoId) {
      return;
    }
    if (video) {
      return;
    }
    if (!status || (!status.fetching && !status.done && !status.error)) {
      dispatch(fetchVideosByIdAC.started({ id: videoId }));
    }
  }, [dispatch, nobatch, status, video, videoId]);

  return [video, status] as const;
}
