import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useCallback } from 'react';

import { IPrivilegeGroup } from './privilege';

interface SelectPrivilegeGroupCheckBoxOwnProps {
  className?: string;
  group: IPrivilegeGroup;
  label: React.ReactNode;
  checked: boolean;
  indeterminate: boolean;
  selectAllPrivileges: (g: IPrivilegeGroup, deselect?: boolean) => void;
}

type SelectPrivilegeGroupCheckBoxProps = SelectPrivilegeGroupCheckBoxOwnProps;

function SelectPrivilegeGroupCheckBox(props: SelectPrivilegeGroupCheckBoxProps) {
  const { group, label, selectAllPrivileges, checked, indeterminate } = props;

  const handleSelectAllCheckBoxOnToggle = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    selectAllPrivileges(group, checked);
  }, [checked, selectAllPrivileges, group]);

  return (
    <FormControlLabel
    onClick={handleSelectAllCheckBoxOnToggle}
    control={<Checkbox indeterminate={indeterminate && !checked} />}
    label={label}
    checked={checked}
    />
  );
}

export default React.memo(SelectPrivilegeGroupCheckBox);
