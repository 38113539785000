import { ContentState } from 'draft-js';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

export interface ICustomTimestampProps {
  contentState: ContentState;
  entityKey: string;
  decoratedText: string;
}

export type CustomTimestampProps = PropsWithChildren<ICustomTimestampProps>;

const CustomTimestamp: React.FC<CustomTimestampProps> = (props: CustomTimestampProps) => {
  const { decoratedText, children } = props;

  const handleLinkClicked = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  const formattedText = useMemo(() => {
    return decoratedText.split(':')
      .reverse()
      .reduce((p: number, c: any, i: number) => p + c * Math.pow(60, i), 0);
  }, [decoratedText]);

  return (
    <Link to={`?t=${formattedText}s`} onClick={handleLinkClicked}>
      {children}
    </Link>
  );
};

export default CustomTimestamp;
