import gql from 'graphql-tag';

export const UpdateAttacker_Mutation = gql`
    mutation UpdateAttackerMutation(
        $input: UpdateAttackerInput!
    ) {
        updateAttacker(
            input: $input
        ) {
            requested
        }
    }
`;
