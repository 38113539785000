import { enterTheaterModeAC, toggleTheaterModeAC } from 'features/video-player/video-player-slice';
import { SagaIterator } from 'redux-saga';
import { all, call, select, takeEvery } from 'redux-saga/effects';
import screenfull from 'screenfull';

import { getIsTheaterMode } from '../selectors/video-player';

function* watchEnterTheaterMode(): SagaIterator {
  yield takeEvery(enterTheaterModeAC, function* () {
    yield call(() => {
      return screenfull.isEnabled && screenfull.exit();
    });
  });
}

function* watchToggleTheaterMode(): SagaIterator {
  yield takeEvery(toggleTheaterModeAC, function* () {
    yield call(function*() : SagaIterator {
      const isTheaterMode = yield select(getIsTheaterMode);
      if (isTheaterMode) {
        return screenfull.isEnabled && screenfull.exit();
      }
    });
  });
}

export default function* videoPlayerSaga() {
  yield all([
    watchEnterTheaterMode(),
    watchToggleTheaterMode(),
  ]);
}
