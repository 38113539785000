import { FlexSpacer } from '@insights-gaming/material-components';
import { LeagueOfLegendsTableHeader, Riot } from '@insights-gaming/statistics';
import { Theme } from '@insights-gaming/theme';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useMemo } from 'react';

import LeagueOfLegendsTeamStatsTableRow from './LeagueOfLegendsTeamStatsTableRow';

interface LeagueOfLegendsTeamStatsTableOwnProps {
  className?: string;
  participantStats: Riot.League.RawDataInfoParticipant[];
  mostDmgDoneToChamp: number;
  gameVersion: string;
  isUserTeam: boolean;
}

type LeagueOfLegendsTeamStatsTableProps = LeagueOfLegendsTeamStatsTableOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), { name: 'LeagueOfLegendsTeamStatsTable' });

function LeagueOfLegendsTeamStatsTable(props: LeagueOfLegendsTeamStatsTableProps) {
  const classes = useStyles(props);
  const { className, participantStats, mostDmgDoneToChamp, gameVersion, isUserTeam } = props;

  const { t } = useStrictTranslation(['statistics']);

  const headers = useMemo(() => {
    return [
      isUserTeam ? t('statistics:stats.lol.blue') : t('statistics:stats.lol.red'),
      t('statistics:stats.lol.killdeathassist'),
      t('statistics:stats.lol.creepscorelabel'),
      t('statistics:stats.lol.dmgdealt'),
      t('statistics:stats.lol.items'),
    ];
  }, [t, isUserTeam]);

  return (
    <FlexSpacer
    orientation='vertical'
    className={classNames(classes.root, className)}
    >
      <TableContainer component={Paper}>
        <Table>
          <LeagueOfLegendsTableHeader headers={headers} isUserTeam={isUserTeam} />
          <TableBody>
            {participantStats.map((ps) => (
              <LeagueOfLegendsTeamStatsTableRow
              key={ps.participantId}
              participant={ps}
              mostDmgDoneToChamp={mostDmgDoneToChamp}
              gameVersion={gameVersion}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </FlexSpacer>
  );
}

export default React.memo(LeagueOfLegendsTeamStatsTable);
