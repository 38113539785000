import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useMemo } from 'react';
import remark from 'remark';
import ping from 'remark-ping';
import remark2react from 'remark-react';

import { GetMembersQuery_queryMembers_edges } from '../../../../apollo/queries/types/GetMembersQuery';
import { createTimestamp } from '../../../../helpers/math';
import MyLink, { IMyLinkOwnProps } from '../../../../subcomponents/my-link/MyLink';

export interface IMDCommentOwnProps {
  text: string;
  members?: GetMembersQuery_queryMembers_edges[];
}

type Props = IMDCommentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  listText: {
    whiteSpace: 'normal',
    margin: 0,
  },
}), {name: 'MDComment'});

function MDComment(props: Props) {
  const { members, text } = props;
  const classes = useStyles(props);

  const pingUsername = useCallback((userId: string) =>
    !!(members
      && members.find((member: GetMembersQuery_queryMembers_edges) => member.user.id === userId)),
    [members],
  );

  const formattedText = useMemo(() => {
    const reg = /(?:^|\s)(\d+(?::\d{2}){1,2})(?:\s|$)/g;
    let formattedText = text;
    if (text && reg.test(text)) {
      formattedText = text.replace(reg, (match: string, p1: string) => {
        const seconds = p1
          .split(':')
          .reverse()
          .reduce((p: number, c: any, i: number) => p + c * Math.pow(60, i), 0);
        return `[${p1}](?t=${createTimestamp(seconds)})`;
      });
    }
    return formattedText;
  }, [text]);

  const renderMyLink = useCallback((props: IMyLinkOwnProps) => <MyLink {...props} members={members} />, [members]);

  const renderParagraph = useCallback(({children}: HTMLParagraphElement) => {
    return (
      <Typography variant='body2' component='p'>
        {children}
      </Typography>
    );
  }, []);

  const renderUnorderedList = useCallback(({children}: HTMLUListElement) => {
    return (
      <ul className={classes.listText}>
        {children}
      </ul>
    );
  }, [classes.listText]);

  const renderOrderedList = useCallback(({children}: HTMLOListElement) => {
    return (
      <ol className={classes.listText}>
        {children}
      </ol>
    );
  }, [classes.listText]);

  return (
    remark()
      .use(ping, {
        pingUsername,
        userURL: (username: string) => `/user/${username}`,
      })
      .use(remark2react, {
        remarkReactComponents: {
          a: renderMyLink,
          p: renderParagraph,
          ul: renderUnorderedList,
          ol: renderOrderedList,
        },
      })
      .processSync(formattedText).contents
  );
}

export default React.memo(MDComment);
