import { createRemFromPx, insightsTheme, Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { withStyles } from '@material-ui/styles';
import { mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { formatDuration } from 'helpers/formatters';
import React, { Ref, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { AudioCommentSourceContext } from './AudioCommentSourceContext';
import AudioCommentWrapper from './AudioCommentWrapper';

interface AudioCommentContentOwnProps {
  className?: string;
  audioSrc: string;
  discardRecording?: VoidFunction;
  fill?: boolean;
  children?: React.ReactNode;
}

type AudioCommentContentProps = AudioCommentContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  duration: {
    marginRight: createRemFromPx(8),
  },
}));

const AudioSlider = withStyles({
  root: {
    margin: insightsTheme.spacing(0, 1.5),
    [mobilePortrait(insightsTheme)]: {
      padding: insightsTheme.spacing(2, 0),
    },
    [mobileLandscape(insightsTheme)]: {
      padding: insightsTheme.spacing(2, 0),
    },
  },
  thumb: {
    backgroundColor: 'white',
  },
})(Slider);

export interface AudioCommentContentRef {
  play: (callback?: (paused: boolean) => void) => (void | (() => void));
}

function AudioCommentContent(props: AudioCommentContentProps, ref?: Ref<AudioCommentContentRef>) {
  const { className, audioSrc, fill, children } = props;

  const classes = useStyles(props);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [ready, setReady] = useState(false);
  const isDesktop = useIsDesktop();

  const audioPlayerRef = useRef<HTMLAudioElement>();

  const { swapPlayer } = useContext(AudioCommentSourceContext);

  const callbacksRef = useRef<Record<symbol, (paused: boolean) => void>>({});

  useEffect(() => {
    const player = audioPlayerRef.current = new Audio();
    player.ondurationchange = () => setDuration(player.duration);
    player.onplay = () => setPlaying(true);
    player.onpause = () => {
      setPlaying(false);

      setImmediate(() => {
        Object.getOwnPropertySymbols(callbacksRef.current)
          .map((s) => callbacksRef.current[s])
          .forEach((callback) => callback(true));
      });
    };
    player.onended = () => {
      setPlaying(false);

      Object.getOwnPropertySymbols(callbacksRef.current)
        .map((s) => callbacksRef.current[s])
        .forEach((callback) => callback(false));
    };

    // HACK: seek unreasonably far ahead to force the entire clip to be loaded so that the duration is known
    player.onloadedmetadata = () => {
      player.currentTime = Number.MAX_SAFE_INTEGER;
    };

    player.ontimeupdate = () => {
      player.onloadedmetadata = null;
      player.ontimeupdate = () => {
        player.ontimeupdate = () => {
          setReady(true);
          (player.ontimeupdate = () => setCurrentTime(player.currentTime))();
        };
        player.currentTime = 0;
      };

      // directly seeking back to 0 is inconsistent, seek near 0 first to improve success rate
      player.currentTime = 0.1;
    };

    player.src = audioSrc;

    return () => {
      player.ondurationchange = null;
      player.onplay = null;
      player.onpause = null;
      player.onended = null;
      player.ontimeupdate = null;
      player.pause();
      audioPlayerRef.current = undefined;
    };
  }, [audioSrc]);

  const seekOnChange = useCallback((e: React.ChangeEvent<{}>, time: number) => {
    if (!audioPlayerRef.current || !duration) {
      return;
    }

    audioPlayerRef.current.currentTime = time;
  }, [duration]);

  const setPlayback = useCallback((play: boolean) => {
    const { current: player } = audioPlayerRef;
    if (!player) {
      return;
    }

    if (play) {
      swapPlayer(player);
      player.play();
    } else {
      player.pause();
    }
  }, [swapPlayer]);

  const togglePlayback = useCallback(() => setPlayback(!playing), [playing, setPlayback]);

  useImperativeHandle(ref, () => ({
    play: (callback?: (paused: boolean) => void): (() => void) | void => {
      setPlayback(true);

      if (callback) {
        const sym = Symbol();
        callbacksRef.current[sym] = callback;
        return () => {
          delete callbacksRef.current[sym];
        };
      }
    },
  }), [setPlayback]);

  if (isDesktop) {
    return (
      <AudioCommentWrapper coloured={playing} fill={fill} className={className}>
        <IconButton size='small' onClick={togglePlayback} disabled={!ready} component='div'>
          {playing ? <PauseIcon fontSize='small'/> : <PlayArrowIcon fontSize='small'/>}
        </IconButton>
        {duration && (
          <AudioSlider
          onChange={seekOnChange}
          value={currentTime}
          max={duration}
          disabled={!ready}
          />
        )}
        <Typography className={classes.duration}>
          {formatDuration(currentTime) + '/' + formatDuration(duration)}
        </Typography>
        {children}
      </AudioCommentWrapper>
    );
  }

  return (
    <AudioCommentWrapper coloured={true} fill={fill} className={className}>
      <IconButton size='small' onClick={togglePlayback} disabled={!ready} component='div'>
        {playing ? <PauseIcon fontSize='small'/> : <PlayArrowIcon fontSize='small'/>}
      </IconButton>
      {duration && (
        <AudioSlider
        onChange={seekOnChange}
        value={currentTime}
        max={duration}
        disabled={!ready}
        />
      )}
      <Typography className={classes.duration}>
        {formatDuration(currentTime) + '/' + formatDuration(duration)}
      </Typography>
    </AudioCommentWrapper>
  );
}

export default React.memo(React.forwardRef(AudioCommentContent));

