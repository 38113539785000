import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import classNames from 'classnames';
import { DirectoryHelper } from 'features/dashboard/directory/dashboard-directory-helper';
import { useDirectoryContextMenu } from 'features/dashboard/directory/directory-context-menu/useDirectoryContextMenu';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import GuidedSetupActions from './GuidedSetupActions';

interface FirstChannelOwnProps {
  className?: string;
  onClose: VoidFunction;
  directory: DirectoryFragment;
  stepBackward: VoidFunction;
  stepForward: VoidFunction;
  step: number;
}

type FirstChannelProps = FirstChannelOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  title: {
    position: 'absolute',
    top: 68,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  header2: {
    marginBottom: theme.spacing(3),
  },
  pre: {
    whiteSpace: 'pre',
  },
  channel: {
    position: 'absolute',
    top: 228,
    left: 114,
  },
  channelName: {
    width: createRemFromPx(340),
    marginLeft: theme.spacing(1.5),
  },
  skip: {
    position: 'absolute',
    bottom: 24,
    left: 24,
    cursor: 'pointer',
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  switchBase: {
    position: 'absolute',
  },
}), {name: 'FirstChannel'});

function FirstChannel(props: FirstChannelProps) {
  const classes = useStyles(props);
  const {
    className,
    onClose,
    step,
    stepBackward,
    stepForward,
    directory,
  } = props;

  const { t } = useStrictTranslation(['dialog', 'dashboard-directory']);
  const [ channelName, setChannelName ] = useState(directory.name);
  const [ privateMode, setPrivateMode ] = useState(DirectoryHelper.isPrivate(directory));
  const { enqueueSnackbar } = useSnackbar();
  const [ loading, setLoading ] = useState(false);
  const { updateDivision } = useDirectoryContextMenu<DirectoryFragment>(directory);

  const handleChannelNameOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setChannelName(e.target.value);
  },[]);

  const handlePrivateModeOnChange = useCallback(() => setPrivateMode((prevMode) => !prevMode), []);

  const handleUpdateChannelOnClick = useCallback(async () => {
    if (loading || (channelName === directory.name && privateMode === DirectoryHelper.isPrivate(directory))) {
      return;
    }

    setLoading(true);

    try {
      await updateDivision({
        divisionId: directory.id,
        teamId: directory.team.id,
        private: privateMode,
        name: channelName.length ? channelName : undefined,
      });
      setLoading(false);
      enqueueSnackbar(t('dashboard-directory:updatechannelprivacy.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [
    channelName,
    directory,
    enqueueSnackbar,
    loading,
    privateMode,
    t,
    updateDivision,
  ]);

  const onNextClick = useCallback(async () => {
    await handleUpdateChannelOnClick();
    stepForward();
  }, [handleUpdateChannelOnClick, stepForward]);

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.title}>
        <Typography variant='h3' className={classes.header2} align='center'>
          {t('dialog:onboarding.createchannel')}
        </Typography>
        <Typography variant='h6' className={classes.pre} align='center'>
          {t('dialog:onboarding.createchanneltext')}
        </Typography>
      </div>
      <FlexSpacer className={classes.channel} spacing={2} orientation='vertical'>
        <FlexSpacer flexAlignItems='center' flexJustifyContent='flex-end'>
          <FontAwesomeIcon icon={faHashtag} fixedWidth={true} size='lg' />
          <TextField
          name='teamName'
          placeholder={t('dialog:onboarding.channelname')}
          value={channelName}
          onChange={handleChannelNameOnChange}
          disabled={loading}
          className={classes.channelName}
          />
        </FlexSpacer>
        <FlexSpacer>
          <Switch
          checked={privateMode}
          onChange={handlePrivateModeOnChange}
          name='makeprivate'
          color='primary'
          size='small'
          classes={{ switchBase: classes.switchBase }}
          />
          <FlexSpacer orientation='vertical' className={classes.channelName}>
            <Typography className={classNames({[classes.disabled]: !privateMode})}>
              {t('dashboard-directory:menuItems.makeprivate')}
            </Typography>
            <Typography>
              {t(privateMode
                ? 'dashboard-directory:makeprivatedescription'
                : 'dashboard-directory:makepublicdescription',
              )}
            </Typography>
          </FlexSpacer>
        </FlexSpacer>
      </FlexSpacer>
      <Typography variant='caption' className={classes.skip} onClick={onClose}>
        {t('dialog:onboarding.skip')}
      </Typography>
      <GuidedSetupActions
      step={step}
      stepBackward={stepBackward}
      stepForward={onNextClick}
      loading={loading}
      />
    </div>
  );
}

export default React.memo(FirstChannel);
