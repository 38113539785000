import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreVert from '@material-ui/icons/MoreVert';
import { MemberFragment } from 'apollo/fragments/types/MemberFragment';
import { RoleInterfaceFragment } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TeamFragment, TeamFragment_features } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import { RoleHelper } from 'components/role-table/role-helper';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { leaveTeamAC, removeMemberAC, removeMemberRolesAC, updateTeamFeatureAssignmentsAC } from 'features/dashboard/team/dashboard-team-slice';
import { isExistent } from 'helpers';
import { Seat } from 'helpers/hasSeats';
import { useDialogState } from 'hooks/useDialogState';
import { useNavigate } from 'hooks/useNavigate';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import AssignRolesDialog from 'material/dialogs/assign-roles-dialog/AssignRolesDialog';
import NoAvailableKeyDialog from 'material/dialogs/noAvailableKeyDialog/NoAvailableKeyDialog';
import RemoveKeyDialog from 'material/dialogs/removeKeyDialog/RemoveKeyDialog';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { dashboardRoute } from 'routes';
import { RemoveMemberRolesInput, RoleInput, TeamFeature } from 'types/graphql';

import MemberTableRow from './MemberTableRow';
import RoleChip from './RoleChip';

interface MemberTableRowWrapperOwnProps {
  className?: string;
  avatar?: React.ReactNode;
  roles: RoleInterfaceFragment[];
  member: MemberFragment;
  team: TeamFragment;
  adFreeFeature?: TeamFragment_features;
}

type MemberTableRowWrapperProps = MemberTableRowWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  role: {
    maxWidth: '100%',
  },
  customFlexSpacer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: theme.spacing(-1),
    '& > *': {
      marginTop: theme.spacing(1),
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
  },
  menuList: {
    padding: 0,
  },
  keyless: {
    color: theme.palette.text.deemphasized,
  },
  keyColor: {
    color: '#F2C94C',
  },
}), {name: 'MemberTableRowWrapper'});

function MemberTableRowWrapper(props: MemberTableRowWrapperProps) {
  const classes = useStyles(props);
  const {
    className,
    member,
    roles,
    avatar,
    team,
    adFreeFeature,
  } = props;
  const accessControl = useAccessControl();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useStrictTranslation(['common', 'dialog', 'dashboard']);
  const onNavigate = useNavigate();
  const [ revokeLoading, setRevokeLoading ] = useState(false);
  const [ removeLoading, setRemoveLoading ] = useState(false);
  const [ removeKeyLoading, setRemoveKeyLoading ] = useState(false);
  const [ assignKeyLoading, setAssignKeyLoading ] = useState(false);
  const [ leaveLoading, setLeaveLoading ] = useState(false);
  const [ removeMemberDialogOpen, openRemoveMemberDialog, closeRemoveMemberDialog ] = useDialogState();
  const [ assignRolesDialogOpen, openAssignRolesDialog, closeAssignRolesDialog ] = useDialogState();
  const [ leaveTeamDialogOpen, openLeaveTeamDialog, closeLeaveTeamDialog ] = useDialogState();
  const [ removeKeyDialogOpen, openRemoveKeyDialog, closeRemoveKeyDialog ] = useDialogState();
  const [ noAvailableKeyDialogOpen, openNoAvailableKeyDialog, closeNoAvailableKeyDialog ] = useDialogState();
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const { canAssignFeatures } = useAccessControl();

  const moreOptionsPopupState = usePopupState({popupId: 'options', variant: 'popover'});

  const allowActions = (accessControl.canRemoveMember ||
    accessControl.canAssignRole || /** NOTE: Comment this line out to remove assigning roles stuff */
    member.user.__typename === 'Self') &&
    member.user.id !== team.owner.id;

  const filteredRoles = useMemo(() => {
    return member.roles2!.map(role2 => roles.find(role => role.name === role2.name))
      .filter(isExistent);
  }, [roles, member]);

  const seatStatus = useMemo(() => {
    if (!member.features) { return false; }
    const features = member.features;
    return Seat.PREMIUM.every((feature) => features.includes(feature));
  }, [member]);

  const allPrivileges = useMemo(() => filteredRoles.flatMap((role) => role.privileges), [filteredRoles]);

  const isOwner = useMemo(() => team.owner.id === member.user.id, [member.user.id, team.owner.id]);

  const globalAdFree = useMemo(() => adFreeFeature?.__typename === 'GlobalTeamFeature', [adFreeFeature?.__typename]);

  const handleRemoveMemberOnClick = useCallback(async () => {
    if (removeLoading) { return; }
    setRemoveLoading(true);
    try {
      const input = {teamId: team.id, memberId: member.id};
      const result = await promiseSagaDispatch(removeMemberAC, input);
      enqueueSnackbar(t('dialog:removemember.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('dialog:removemember.failure'), {variant: 'error'});
    } finally {
      setRemoveLoading(true);
    }
  }, [enqueueSnackbar, member.id, promiseSagaDispatch, removeLoading, t, team.id]);

  const handleRevokeRoleOnClick = useCallback(async (role: RoleInterfaceFragment) => {
    const roleInput: RoleInput = {
      name: role.name,
      global: RoleHelper.isGlobalRole(role),
    };
    const input: RemoveMemberRolesInput = { teamId: team.id, roles: [roleInput], memberId: member.id };
    if (revokeLoading) { return; }
    setRevokeLoading(true);
    try {
      const result = await promiseSagaDispatch(removeMemberRolesAC, input);
      enqueueSnackbar(t('dialog:revokerole.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setRevokeLoading(false);
    }
  }, [enqueueSnackbar, member.id, promiseSagaDispatch, revokeLoading, t, team.id]);

  const handleLeaveTeamOnClick = useCallback(async () => {
    if (leaveLoading) { return; }
    setLeaveLoading(true);
    try {
      const result = await promiseSagaDispatch(leaveTeamAC, {teamId: team.id});
      closeLeaveTeamDialog();
      onNavigate(dashboardRoute());
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLeaveLoading(false);
    }
  }, [closeLeaveTeamDialog, onNavigate, leaveLoading, team.id, promiseSagaDispatch, enqueueSnackbar]);

  const renderLeaveOrRemoveActions = useCallback(() => {
    if (isOwner) { return null; }
    if (member.user.__typename === 'Self') {
      return (
        <Tooltip title={t('common:leaveteam')}>
          <IconButton onClick={openLeaveTeamDialog} size='small'>
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      );
    }
    if (accessControl.canRemoveMember) {
      return (
        <Tooltip title={t('common:removemember')}>
          <IconButton onClick={openRemoveMemberDialog} size='small'>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      );
    }
    return null;
  }, [isOwner, member.user.__typename, accessControl.canRemoveMember, t, openLeaveTeamDialog, openRemoveMemberDialog]);

  const hasKey = useMemo(() => {
    if (adFreeFeature?.__typename === 'GlobalTeamFeature') {
      return true;
    }
    return adFreeFeature?.members.map((members) => members.id).includes(member.id);
  }, [adFreeFeature, member.id]);

  const noAvailableAdFreeKey = useMemo(() => {
    if (!adFreeFeature || adFreeFeature.__typename === 'GlobalTeamFeature') {
      return;
    }
    return adFreeFeature.members.length === adFreeFeature.limit;
  }, [adFreeFeature]);

  const assignKeyOnClick = useCallback(async () => {
    if (assignKeyLoading) { return; }
    setAssignKeyLoading(true);
    try {
      await promiseSagaDispatch(updateTeamFeatureAssignmentsAC, {
        memberIds: [member.id],
        teamId: team.id,
        assignFeatures: [TeamFeature.ADFREE],
      });
      enqueueSnackbar(t('dialog:assignKey.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('dialog:assignKey.failure'), {variant: 'error'});
    } finally {
      setAssignKeyLoading(false);
    }
  }, [assignKeyLoading, enqueueSnackbar, member.id, promiseSagaDispatch, t, team.id]);

  const removeKeyOnClick = useCallback(async () => {
    if (removeKeyLoading) { return; }
    setRemoveKeyLoading(true);
    try {
      await promiseSagaDispatch(updateTeamFeatureAssignmentsAC, {
        memberIds: [member.id],
        teamId: team.id,
        revokeFeatures: [TeamFeature.ADFREE],
      });
      enqueueSnackbar(t('dialog:removekey.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('dialog:removekey.failure'), {variant: 'error'});
    } finally {
      setRemoveKeyLoading(false);
      removeKeyDialogOpen && closeRemoveKeyDialog();
    }
  }, [
    closeRemoveKeyDialog,
    enqueueSnackbar,
    member.id,
    promiseSagaDispatch,
    removeKeyDialogOpen,
    removeKeyLoading,
    t,
    team.id,
  ]);

  const handleAssignKeyOnClick = useCallback(() => {
    if (hasKey) {
      return openRemoveKeyDialog?.();
    }
    return noAvailableAdFreeKey ? openNoAvailableKeyDialog?.() : assignKeyOnClick?.();
  }, [assignKeyOnClick, hasKey, noAvailableAdFreeKey, openNoAvailableKeyDialog, openRemoveKeyDialog]);

  const renderMobileActions = useCallback(() => {
    return (
      <React.Fragment>
        <IconButton
        {...bindTrigger(moreOptionsPopupState)}
        >
          <MoreVert />
        </IconButton>
        <Menu
        {...bindMenu(moreOptionsPopupState)}
        classes={{
          list: classes.menuList,
        }}
        >
          <MenuItem
          onClick={adFreeFeature?.__typename === 'GlobalTeamFeature' ? undefined : handleAssignKeyOnClick}
          disabled={!canAssignFeatures}
          >
            <ListItemIcon>
              <FontAwesomeIcon
              icon={faKey}
              className={classNames({[classes.keyless]: !hasKey, [classes.keyColor]: hasKey})}
              size='lg'
              />
            </ListItemIcon>
            <ListItemText>
              {t('common:assignkeys')}
            </ListItemText>
          </MenuItem>
          {team.owner.id !== member.user.id && member.user.__typename === 'Self' && (
            <MenuItem onClick={openLeaveTeamDialog}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText>
                {t('common:leaveteam')}
              </ListItemText>
            </MenuItem>
          )}
          {team.owner.id !== member.user.id && accessControl.canRemoveMember && (
            <MenuItem onClick={openRemoveMemberDialog}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>
                {t('common:remove')}
              </ListItemText>
            </MenuItem>
          )}
        </Menu>
      </React.Fragment>
    );
  }, [
    accessControl.canRemoveMember,
    adFreeFeature?.__typename,
    canAssignFeatures,
    classes.keyColor,
    classes.keyless,
    classes.menuList,
    handleAssignKeyOnClick,
    hasKey,
    member.user.__typename,
    member.user.id,
    moreOptionsPopupState,
    openLeaveTeamDialog,
    openRemoveMemberDialog,
    t,
    team.owner.id,
  ]);

  const renderAssignKey = useCallback(() => {
    return (
      <Tooltip
      title={t(globalAdFree ? 'dialog:paymentsuccess.enterprise' : 'dialog:paymentsuccess.assignkey')}
      >
        <IconButton
        onClick={globalAdFree ? undefined : handleAssignKeyOnClick}
        size='small'
        disabled={!canAssignFeatures}
        >
          <FontAwesomeIcon
          icon={faKey}
          className={classNames({[classes.keyless]: !hasKey, [classes.keyColor]: hasKey})}
          />
        </IconButton>
      </Tooltip>
    );
  }, [canAssignFeatures, classes.keyColor, classes.keyless, globalAdFree, handleAssignKeyOnClick, hasKey, t]);

  return (
    <React.Fragment>
      <MemberTableRow
      teamId={team.id}
      avatar={avatar}
      name={member.user.alias}
      userId={member.user.id}
      isOwner={isOwner}
      displayCrown={team.owner.__typename === 'Self' || isOwner}
      roles={(
        <div className={classes.customFlexSpacer}>
          {filteredRoles.map(role => (
            <RoleChip
            className={classes.role}
            key={role.name}
            role={role}
            canRevokeRole={accessControl.canAssignRole}
            revokeLoading={revokeLoading}
            revokeRole={handleRevokeRoleOnClick}
            />
          ))}
          {allowActions && accessControl.canAssignRole && (
            <Tooltip title={t('common:assignroles')}>
              <IconButton onClick={openAssignRolesDialog} size='small'>
                <AddCircleRoundedIcon/>
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
      action={renderLeaveOrRemoveActions()}
      mobileAction={renderMobileActions()}
      assignKey={renderAssignKey()}
      className={classNames(classes.root, className)}
      />
      <Dialog
      open={removeMemberDialogOpen}
      onClose={closeRemoveMemberDialog}
      >
        <AlertDialogContent
        titleText={t(('dialog:removemember.title'), {name: member.user.alias})}
        description={t('dialog:removemember.description')}
        confirm={{
          text: t('dialog:removemember.confirm'),
          action: handleRemoveMemberOnClick,
          loading: removeLoading,
          disabled: removeLoading,
          negative: true,
        }}
        cancel={{
          text: t('common:cancel'),
          action: closeRemoveMemberDialog,
        }}
        />
      </Dialog>
      <Dialog
      open={leaveTeamDialogOpen}
      onClose={closeLeaveTeamDialog}
      >
        <AlertDialogContent
        titleText={t('dialog:leaveteam.title', {team: team.name})}
        description={t('dialog:leaveteam.description')}
        confirm={{
          text: t('dialog:leaveteam.confirm'),
          action: handleLeaveTeamOnClick,
          loading: leaveLoading,
          disabled: leaveLoading,
        }}
        cancel={{
          text: t('common:cancel'),
          action: closeLeaveTeamDialog,
        }}
        />
      </Dialog>
      <AssignRolesDialog
      open={assignRolesDialogOpen}
      onClose={closeAssignRolesDialog}
      team={team}
      member={member}
      roles={roles}
      />
      <RemoveKeyDialog
      closeRemoveKeyDialog={closeRemoveKeyDialog}
      member={member}
      removeKeyDialogOpen={removeKeyDialogOpen}
      removeKeyLoading={removeKeyLoading}
      removeKeyOnClick={removeKeyOnClick}
      />
      <NoAvailableKeyDialog
      noAvailableKeyDialogOpen={noAvailableKeyDialogOpen}
      closeNoAvailableKeyDialog={closeNoAvailableKeyDialog}
      teamId={team.id}
      />
    </React.Fragment>
  );
}

export default React.memo(MemberTableRowWrapper);
