import { DirectoryFragment } from 'apollo/fragments';
import gql from 'graphql-tag';

export const CreateTeamFolder_Mutation = gql`
    mutation CreateTeamFolderMutation(
        $input: CreateFolderInput!
    ) {
        createFolder(
            input: $input
        ) {
            folder {
                ...DirectoryFragment
            }
        }
    }
    ${DirectoryFragment}
`;
