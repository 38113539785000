import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { usePopupStateKeybindingHelper } from 'hooks/usePopupStateKeybindingHelper';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useCallback } from 'react';

import { Video } from '../../types/pigeon';
import VideoMenu from './VideoMenu';

export interface VideoMenuButtonOwnProps {
  video: Video;
  size?: IconButtonProps['size'];
}

type Props = VideoMenuButtonOwnProps;

function VideoMenuButton(props: Props) {
  const { size, video } = props;
  const popupState = usePopupState({popupId: 'video-menu', variant: 'popover'});
  usePopupStateKeybindingHelper(popupState, 'menu.open');
  const { onClick: onTriggerClick, ...trigger } = bindTrigger(popupState);

  const onClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onTriggerClick(e);
  }, [onTriggerClick]);

  const menu = bindMenu(popupState);

  return (
    <div>
      <IconButton size={size} onClick={onClick} {...trigger}>
        <MoreVertIcon />
      </IconButton>
      <VideoMenu
      video={video}
      {...menu}
      />
    </div>
  );
}

export default React.memo(VideoMenuButton);
