
import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';

import { makeGetVideoCommentRecordsByVideoId } from './video-comment-selector';
import { fetchVideoCommentsAC } from './video-comment-slice';

export const useFetchComments = createBidirectionalAutoFetcher({
  actionCreators: fetchVideoCommentsAC,
  selectorFactory: makeGetVideoCommentRecordsByVideoId,
  createFetchId: ({videoId, tagIds}) => [videoId, tagIds].join(':'),
  pageSize: 100,
});
