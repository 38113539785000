import { VideoReplayContext } from '@insights-gaming/material-components';
import { CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import React, { useContext, useEffect } from 'react';

export interface ICurrentCommentFinderOwnProps {
  setCurrentComments?: (c: CommentFragment_VideoComment[]) => void;
  videoComments: CommentFragment_VideoComment[];
}

type Props = ICurrentCommentFinderOwnProps;

function CurrentCommentFinder(props: Props) {
  const { videoComments, setCurrentComments } = props;
  const { state: { progress: { playedSeconds: time } } } = useContext(VideoReplayContext);

  useEffect(() => {
    setCurrentComments?.(videoComments.filter(c => Math.floor(c.time) === Math.floor(time)));
  }, [setCurrentComments, time, videoComments]);

  return null;
}

export default React.memo(CurrentCommentFinder);
