import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, WithStyles,withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import update from 'immutability-helper';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { DialogF, DialogNS } from '../../../locales/en/dialog';
import { PrimaryRosterV2_Players } from '../../../types/pigeon/statistics';

export interface ICreateCorrectionDialogRowOwnProps {
  player       : PrimaryRosterV2_Players;
  playerOptions: PrimaryRosterV2_Players[];
  pos          : number;

  onChange: (
    player: PrimaryRosterV2_Players,
    pos: number,
  ) => void;
}

export type CreateCorrectionDialogRowProps = ICreateCorrectionDialogRowOwnProps &
  WithStyles<typeof styles> &
  WithTranslation;

const styles = (theme: Theme) => {
  return createStyles({
    currentName: {
      color: theme.palette.text.deemphasized,
    },
    nameVerification: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
  });
};

class CreateCorrectionDialogRow extends React.PureComponent<CreateCorrectionDialogRowProps> {
  public render() {
    const { player, playerOptions, t, classes, pos } = this.props;
    return (
      <Box>
        <Box className={classes.nameVerification} px={1}>
          <Box display='flex' alignItems='center' overflow='auto'>
            {playerOptions[pos].playerName}
          </Box>
          <Box>
            <Select
            value={player.playerId}
            onChange={this.handleSelectOnChange}
            fullWidth={true}
            >
              <MenuItem value='other'>
                {t(DialogF('editnames.enterplayername'))}
              </MenuItem>
              {playerOptions.map(option => (
                <MenuItem key={option.playerId} value={option.playerId}>
                  {option.playerName}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box mb={1}>
          {player.playerId ==='other' && (
            <TextField
            autoFocus={true}
            fullWidth={true}
            name='playerName'
            value={player.playerName}
            onChange={this.handleTextFieldOnChange}
            margin='dense'
            />
          )}
        </Box>
      </Box>
    );
  }

  private handleTextFieldOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { pos, player, onChange } = this.props;
    const { name, value } = e.currentTarget;
    onChange(update(player, {[name]: {$set: value}}), pos);
  }

  private handleSelectOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { pos, player, onChange } = this.props;
    onChange(update(player, {playerId: {$set: value}}), pos);
  }

}

export default withTranslation(DialogNS)(withStyles(styles)(CreateCorrectionDialogRow));
