import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import PageNotFound, { IPageNotFoundDispatch } from '../components/page-not-found/PageNotFound';
import { onNavigateFactory } from '../types/dispatch';

function mapDispatchToProps(dispatch: Dispatch): IPageNotFoundDispatch {
  return {
    onNavigate: onNavigateFactory(dispatch),
  };
}

export default connect<void, IPageNotFoundDispatch>(null, mapDispatchToProps)(PageNotFound);
