import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { revokeTokenAC } from 'features/auth/auth-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

interface RemoveSessionDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
  tokenId?: string;
}

type RemoveSessionDialogProps = RemoveSessionDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'RemoveSessionDialog'});

function RemoveSessionDialog(props: RemoveSessionDialogProps) {
  const classes = useStyles(props);
  const { className, open, onClose, tokenId } = props;

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useStrictTranslation(['common', 'settings']);
  const [loading, setLoading] = useState(false);

  const removeOnClick = useCallback(async () => {
    if (loading || !tokenId) {
      return;
    }

    setLoading(true);
    try {
      await promiseSagaDispatch(revokeTokenAC, { tokenId });
      enqueueSnackbar(t('settings:activesessions.removedialog.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('settings:activesessions.removedialog.failure'), {variant: 'error'});
    } finally {
      setLoading(false);
      onClose();
    }
  }, [enqueueSnackbar, loading, onClose, promiseSagaDispatch, t, tokenId]);

  return (
    <Dialog open={open} onClose={onClose}>
      <AlertDialogContent
      titleText={t('settings:activesessions.removedialog.title')}
      description={t('settings:activesessions.removedialog.description')}
      cancel={{
        text: t('common:cancel'),
        action: onClose,
      }}
      confirm={{
        text: t('settings:activesessions.removedialog.endsession'),
        loading,
        disabled: loading || !tokenId,
        action: removeOnClick,
      }}
      />
    </Dialog>
  );
}

export default React.memo(RemoveSessionDialog);
