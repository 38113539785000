import gql from 'graphql-tag';

import { CommentFragment } from '../fragments';

export const VideoEvents_Subscription = gql`
    subscription VideoEventsSubscription(
        $videoId: ID!
    ) {
        videoEvents(
            videoId: $videoId
        ) {
            __typename
            ...on CommentAddedEvent {
                commentAdded {
                    ...CommentFragment
                    ... on CommentReply {
                        parentComment {
                            id
                            replyCount
                        }
                    }
                }
            }
            ...on CommentUpdatedEvent {
                commentUpdated {
                    ...CommentFragment
                }
            }
        }
    }
    ${CommentFragment}
`;
