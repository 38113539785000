import { Loader } from '@insights-gaming/material-components';
import React from 'react';
import { ID } from 'types/pigeon';

import { useFetchCommentTags } from './useFetchCommentTags';

interface CommentTagsFetcherOwnProps {
  videoId: ID;
  commentId: ID;
  loader?: boolean;
}

type CommentTagsFetcherProps = CommentTagsFetcherOwnProps;

function CommentTagsFetcher({videoId, commentId, loader = true}: CommentTagsFetcherProps) {
  const [commentTagsForwardStatus] = useFetchCommentTags({videoId, commentId});
  const initialAppliedCommentLabelsFetch = commentTagsForwardStatus.fetching && !commentTagsForwardStatus.cursor;
  return initialAppliedCommentLabelsFetch && loader ? (
    <div>
      <Loader size={20} inline={true} />
    </div>
  ) : null;
}

export default React.memo(CommentTagsFetcher);
