import gql from 'graphql-tag';

export const LeaveTeam_Mutation = gql`
    mutation LeaveTeamMutation(
        $input: LeaveTeamInput!
    ) {
        leaveTeam(
            input: $input
        ) {
            teamId
        }
    }
`;
