import { isExistent } from 'helpers';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

import { isCommentTag, isVideoTag } from './tag-helper';

const getTeamTagRecordsByTeamId = (state: RootReducer, teamId: ID) =>
  state.dashboardTag.teamTagRecords[teamId];

const getTagDict = (state: RootReducer) => state.dashboardTag.tagDict;

const getTagsIdsFromProps = (state: RootReducer, props: {tagIds: ID[]}) => {
  return props.tagIds;
};

export const makeGetTeamTagRecordsByTeamId = () => createSelector(
  [getTeamTagRecordsByTeamId],
  (records) => records,
);

export const makeGetTeamVideoTagsByTeamId = () => createSelector(
  [getTeamTagRecordsByTeamId, getTagDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) {
      return [];
    }
    return ids.map(id => dict[id]).filter(isExistent).filter(isVideoTag);
  },
);

export const makeGetTagsByIds = () => createSelector(
  [getTagsIdsFromProps, getTagDict],
  (ids, dict) => {
    if (!ids) {
      return [];
    }
    return ids.map(id => dict[id]).filter(isExistent);
  },
);

export const makeGetTeamCommentLabelsByTeamId = () => createSelector(
  [getTeamTagRecordsByTeamId, getTagDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) {
      return [];
    }
    return ids.map(id => dict[id]).filter(isExistent).filter(isCommentTag);
  },
);
