import { useCreateSelector } from 'hooks/useCreateSelector';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import {
  makeGetDirectoryFetchStatusByDirectoryId,
  makeGetSharedDivisionByDirectoryId,
  makeGetTeamDirectoryByDirectoryId,
} from './dashboard-directory-selector';
import { fetchDirectoryByIdAC } from './dashboard-directory-slice';

export function useFetchDirectoryIfNecessary(directoryId?: ID) {
  const directory = useCreateSelector(makeGetTeamDirectoryByDirectoryId, directoryId);
  const sharedDirectory = useCreateSelector(makeGetSharedDivisionByDirectoryId, directoryId);
  const status = useCreateSelector(makeGetDirectoryFetchStatusByDirectoryId, directoryId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!directoryId) {
      return;
    }
    if (directory || sharedDirectory) {
      return;
    }
    if (!status || (!status.fetching && !status.done && !status.error)) {
      dispatch(fetchDirectoryByIdAC.started({directoryId}));
    }
  }, [directory, directoryId, dispatch, status, sharedDirectory]);

  return [directory, sharedDirectory, status] as const;
}
