import { fetchOverwatchPrimaryRosterTeamFightMapStatisticsAsyncAC } from 'features/statistics/statistics-slice';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootReducer } from 'rootReducer';

import { GetOverwatchPrimaryRosterTeamFightMapStatisticsV2QueryVariables } from '../apollo/queries/types/GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query';
import OverwatchPrimaryRosterTeamFightMapStatistics, {
  IOverwatchPrimaryRosterTeamFightMapStatisticsDispatch,
  IOverwatchPrimaryRosterTeamFightMapStatisticsMappedProps,
  IOverwatchPrimaryRosterTeamFightMapStatisticsOwnProps,
} from '../components/dashboard/team-statistics/overwatch-primary-roster-team-fight-map-statistics/OverwatchPrimaryRosterTeamFightMapStatistics';

function mapStateToProps(
  state: RootReducer,
  props: IOverwatchPrimaryRosterTeamFightMapStatisticsOwnProps,
): IOverwatchPrimaryRosterTeamFightMapStatisticsMappedProps {
  return {
    primaryRosterTeamFightMapStatisticsCache:
      state.statisticsReducer.teamFightMapStatistics.cache[props.teamId],
    primaryRosterTeamFightMapStatisticsError:
      state.statisticsReducer.teamFightMapStatistics.error,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IOverwatchPrimaryRosterTeamFightMapStatisticsDispatch {
  return {
    onFetchOverwatchPrimaryRosterTeamFightMapStatistics: (
      params: GetOverwatchPrimaryRosterTeamFightMapStatisticsV2QueryVariables,
    ) => dispatch(fetchOverwatchPrimaryRosterTeamFightMapStatisticsAsyncAC.started(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OverwatchPrimaryRosterTeamFightMapStatistics);
