import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import { RoleInterfaceFragment_GlobalRole } from 'apollo/fragments/types/RoleInterfaceFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { RoleHelper } from 'components/role-table/role-helper';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import RoleEditorDialogContent from 'material/dialogs/role-editor-dialog-content/RoleEditorDialogContent';
import React, { useMemo } from 'react';

import GlobalRoleTableRow from './GlobalRoleTableRow';

interface GlobalRoleTableRowWrapperOwnProps {
  className?: string;
  globalRole: RoleInterfaceFragment_GlobalRole;
  team: TeamFragment;
}

type GlobalRoleTableRowWrapperProps = GlobalRoleTableRowWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  columnText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}), {name: 'GlobalRoleTableRowWrapper'});

function GlobalRoleTableRowWrapper(props: GlobalRoleTableRowWrapperProps) {
  const classes = useStyles(props);
  const { className, globalRole, team } = props;
  const { t } = useStrictTranslation(['common', 'dashboard']);
  const wt = t as TFunction;
  const { canCreateRole } = useAccessControl();
  const [ roleEditorDialogOpen, openRoleEditorDialog, closeRoleEditorDialog ] = useDialogState();

  const privileges = useMemo(() => RoleHelper.joinPrivileges(globalRole, wt), [globalRole, wt]);

  return (
    <React.Fragment>
      <GlobalRoleTableRow
      name={(
        <div className={classes.columnText} title={globalRole.name}>
          {wt(`common:roles.global.${globalRole.name}.title`)}
        </div>
      )}
      privileges={(
        <Tooltip title={privileges} placement='top' arrow={true}>
          <div className={classes.columnText}>
            {privileges}
          </div>
        </Tooltip>
      )}
      description={(
        <Tooltip title={globalRole.description} placement='top' arrow={true}>
          <div className={classes.columnText}>
            {wt(`common:roles.global.${globalRole.name}.description`)}
          </div>
        </Tooltip>
      )}
      actions={canCreateRole && (
        <Tooltip title={t('dashboard:team.roles.clonerole')} placement='top' arrow={true}>
          <IconButton size='small' onClick={openRoleEditorDialog}>
            <LibraryAddIcon />
          </IconButton>
        </Tooltip>
      )}
      />
      <Dialog open={roleEditorDialogOpen} onClose={closeRoleEditorDialog}>
        <RoleEditorDialogContent
        onClose={closeRoleEditorDialog}
        team={team}
        role={globalRole}
        isPreset={true}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default React.memo(GlobalRoleTableRowWrapper);
