import { GetGlobalRoles_Query } from 'apollo/queries';
import { watchBidirectionalAC2 } from 'helpers/saga/effects';
import { all } from 'redux-saga/effects';

import { fetchGlobalRolesAC } from './global-role-slice';

export default function* globalRoleSaga() {
  yield all([
    watchBidirectionalAC2(fetchGlobalRolesAC, GetGlobalRoles_Query, ['queryGlobalRoles']),
  ]);
}

