import { Action, actionCreatorFactory } from 'typescript-fsa';

import { IVideoLocationFilterOption, IVideoMapTypeFilterOption } from '../types';
import { ID } from '../types/pigeon';

const CHANGE_MAP_TYPE_FILTERS = 'CHANGE_MAP_TYPE_FILTERS';
const CHANGE_LOCATION_FILTERS = 'CHANGE_LOCATION_FILTERS';
const CHANGE_TAG_FILTERS = 'CHANGE_TAG_FILTERS';
const CLEAR_FILTERS = 'CLEAR_FILTERS';

const actionCreator = actionCreatorFactory();

export const changeVideoMapTypeFiltersAC = actionCreator<IVideoMapTypeFilterOption[]>(CHANGE_MAP_TYPE_FILTERS);
type ChangeVideoMapTypeFilters = Action<IVideoMapTypeFilterOption[]>;

export const changeVideoLocationFiltersAC = actionCreator<IVideoLocationFilterOption[]>(CHANGE_LOCATION_FILTERS);
type ChangeVideoLocationFilters = Action<IVideoLocationFilterOption[]>;

export const changeVideoTagFiltersAC = actionCreator<ID[]>(CHANGE_TAG_FILTERS);
type ChangeVideoTagFilters = Action<ID[]>;

export const clearVideoFiltersAC = actionCreator(CLEAR_FILTERS);
type ClearVideoFilters = Action<void>;

export type VideoFilterAction =
  | ChangeVideoMapTypeFilters
  | ChangeVideoLocationFilters
  | ChangeVideoTagFilters
  | ClearVideoFilters;
