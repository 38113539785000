import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import useAvailableMetrics, { CustomPlanProductKind, IAvailablePlanData } from 'features/dashboard/billing/useAvailableMetrics';
import useTeamAvailableBundles from 'features/dashboard/billing/useTeamAvailableBundles';
import useTeamSubscription from 'features/dashboard/billing/useTeamSubscription';
import { useIsMobilePortrait } from 'features/media-queries/hooks';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import DeleteButton from 'material/delete-button/DeleteButton';
import React, { useMemo } from 'react';
import { BillingInterval, ProductKind } from 'types/graphql';
import { Billing } from 'types/pigeon';
import { ETeamBillingPlanType } from 'types/pigeon/billing';

import PlanCard from '../AvailablePlansDialog/PlanCard';

interface CancelSubscriptionOwnProps {
  className?: string;
  currentPlan?: IAvailablePlanData;
  team: TeamFragment;
  step: number;
  stepBackward?: VoidFunction;
  stepForward: VoidFunction;
  cancelPlan?: boolean;
  checkoutQuantities?: Billing.InputQuantities;
  customPlanQuantities?: Partial<Record<CustomPlanProductKind, number>>;
}

type CancelSubscriptionProps = CancelSubscriptionOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  dialogContent: {
    margin: theme.spacing(0, 2),
  },
  planText: {
    textTransform: 'uppercase',
    fontSize: createRemFromPx(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  currentPlan: {
    width: createRemFromPx(240),
    marginBottom: 0,
    pointerEvents: 'none',
  },
  basicPlan: {
    width: createRemFromPx(240),
    marginBottom: 0,
    pointerEvents: 'none',
    border: 'none',
    boxShadow: theme.shadows[4],
  },
  dialogActions: {
    margin: theme.spacing(2),
  },
  cancelButton: {
    backgroundColor: theme.palette.negative.main,
  },
  rotate: {
    transform: 'rotate(90deg)',
  },
}), {name: 'CancelSubscription'});

function CancelSubscription(props: CancelSubscriptionProps) {
  const classes = useStyles(props);
  const {
    className,
    currentPlan,
    team,
    step,
    stepBackward,
    stepForward,
    cancelPlan,
    checkoutQuantities,
    customPlanQuantities,
  } = props;

  const { t } = useStrictTranslation(['dashboard', 'dialog']);
  const bundles = useTeamAvailableBundles(team.id);
  const { metrics, customPlans } = useAvailableMetrics(bundles);
  const [teamSubscription] = useTeamSubscription(team.id);
  const isMobilePortrait = useIsMobilePortrait();

  const plan = useMemo(
    () => {
      if (!cancelPlan) {
        if (customPlanQuantities && Object.keys(customPlanQuantities).length) {
          return {
            name: 'custom_plan',
            cost: {
              [BillingInterval.MONTHLY]: Object.entries(customPlanQuantities).reduce((acc, [kind, quantity]) => {
                const price = customPlans[kind as CustomPlanProductKind]?.plan?.cost?.[BillingInterval.MONTHLY];
                if (price) {
                  acc += price * quantity;
                }

                return acc;
              }, 0),
              [BillingInterval.YEARLY]: Object.entries(customPlanQuantities).reduce((acc, [kind, quantity]) => {
                const price = customPlans[kind as CustomPlanProductKind]?.plan?.cost?.[BillingInterval.YEARLY];
                if (price) {
                  acc += price * quantity;
                }

                return acc;
              }, 0),
            },
            upload: (
              (customPlans[ProductKind.UPLOAD_LIMIT]?.unit ?? 0) *
                (customPlanQuantities[ProductKind.UPLOAD_LIMIT] ?? 0)
            ),
            storage: (
              (customPlans[ProductKind.STORAGE_LIMIT]?.unit ?? 0) *
                (customPlanQuantities[ProductKind.STORAGE_LIMIT] ?? 0)
            ),
            storageBytes: (
              (customPlans[ProductKind.STORAGE_LIMIT_BYTES]?.unit ?? 0) *
                (customPlanQuantities[ProductKind.STORAGE_LIMIT_BYTES] ?? 0)
            ),
            keys: (
              (customPlans[ProductKind.PREMIUM_SEAT]?.unit ?? 0) *
                (customPlanQuantities[ProductKind.PREMIUM_SEAT] ?? 0)
            ),
          };
        }

        if (checkoutQuantities?.productId) {
          return metrics.find((metric) => metric.productId === checkoutQuantities.productId);
        }
      }

      return {
        name: ETeamBillingPlanType.BASIC,
        upload: Infinity,
        storage: undefined,
        storageBytes: 5 * (2 ** 30),
        keys: undefined,
        cost: 'free',
        desc: 'Starter package',
      };
    },
    [cancelPlan, checkoutQuantities?.productId, customPlanQuantities, metrics, customPlans],
  );

  return (
    <div className={classNames(classes.root, className)}>
      <DialogContent className={classes.dialogContent}>
        <FlexSpacer fullWidth={true} flexAlignItems='center' orientation={isMobilePortrait ? 'vertical' : 'horizontal'}>
          <FlexSpacer orientation='vertical'>
            <Typography className={classes.planText} align='center'>
              {t('dialog:cancelsubscription.currentplan')}
            </Typography>
            <PlanCard
            className={cancelPlan ? classes.currentPlan : classes.basicPlan}
            interval={teamSubscription?.interval}
            keys={currentPlan?.keys}
            title={currentPlan?.name}
            desc={currentPlan?.desc}
            upload={currentPlan?.upload}
            storage={currentPlan?.storage}
            storageBytes={currentPlan?.storageBytes}
            cost={currentPlan?.cost}
            active={!!cancelPlan}
            hasPlan={true}
            />
          </FlexSpacer>
          <ArrowRightAltIcon className={classNames({[classes.rotate]: isMobilePortrait})} fontSize='large' />
          <FlexSpacer orientation='vertical'>
            <Typography className={classes.planText} align='center'>
              {t('dialog:cancelsubscription.after')}
            </Typography>
            <PlanCard
            className={cancelPlan ? classes.basicPlan : classes.currentPlan}
            interval={checkoutQuantities?.interval}
            title={plan?.name}
            desc={plan?.desc}
            upload={plan?.upload}
            storage={plan?.storage}
            storageBytes={plan?.storageBytes}
            keys={plan?.keys}
            cost={plan?.cost}
            active={!cancelPlan}
            hasPlan={false}
            />
          </FlexSpacer>
        </FlexSpacer>

      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <FlexSpacer
        flexAlignItems='center'
        flexJustifyContent='space-between'
        fullWidth={true}
        >
          {stepBackward && !!step && (
            <Button
            startIcon={<ArrowBackIosIcon/>}
            onClick={stepBackward}
            >
              {t('dialog:plandetails.backtoplans')}
            </Button>
          )}
          {cancelPlan ? (
            <DeleteButton
            variant='contained'
            onClick={stepForward}
            >
              {t('dialog:cancelsubscription.continuetocancel')}
            </DeleteButton>
          ) : (
            <Button
            color={'primary'}
            variant='contained'
            onClick={stepForward}
            >
              {t('dashboard:billing.changeplan')}
            </Button>
          )}
        </FlexSpacer>
      </DialogActions>
    </div>
  );
}

export default React.memo(CancelSubscription);
