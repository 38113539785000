import { useNavigate } from 'hooks/useNavigate';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { JOIN_SESSION_PATH,joinSessionRoute } from 'routes';
import { getMe } from 'selectors/getMe';

import { getLiveSessionState } from './live-session-selector';
import { liveSessionConnectAC, liveSessionDisconnectAC } from './live-session-slice';
import LiveSessionErrorDialog from './LiveSessionErrorDialog';
import LiveSessionTerminatedDialog from './LiveSessionTerminatedDialog';

function LiveSessionManager(): React.ReactElement<any, any> | null {
  const match = useRouteMatch<{ sessionToken: string }>(JOIN_SESSION_PATH);
  const dispatch = useDispatch();
  const onNavigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isSessionRoute = !!useRouteMatch(JOIN_SESSION_PATH);

  const isLoggedIn = !!useSelector(getMe);
  const state = useSelector(getLiveSessionState);
  const stateType = useMemo(() => state?.type, [state]);
  const currentToken = useMemo(() => state?.token, [state]);
  const errorMessage = useMemo(() => state?.type === 'error' ? state.message : undefined, [state]);

  const [dispatchedToken, setDispatchedToken] = React.useState<string>();

  useEffect(() => setDispatchedToken(undefined), [currentToken]);

  const localToken = useMemo(() => dispatchedToken || currentToken, [dispatchedToken, currentToken]);

  const [stateTypeHistory, setStateTypeHistory] = React.useState<Array<string | undefined>>([undefined, stateType]);

  useEffect(() => setStateTypeHistory((history) => [...history, stateType].slice(-2)), [stateType]);

  useEffect(() => {
    const token = match?.params?.sessionToken;
    if (!token || localToken === token) {
      return;
    }

    if (typeof localToken === 'string') {
      dispatch(liveSessionDisconnectAC());
    }

    setDispatchedToken(token);
    dispatch(liveSessionConnectAC.started(token));
  }, [dispatch, match, localToken, stateType]);

  useEffect(() => {
    const [prevStateType, stateType] = stateTypeHistory;
    if (
      !currentToken || // only navigate when there is a token
      prevStateType !== 'connecting' || // only navigate if state type changed
      stateType !== 'joined'
    ) {
      return;
    }

    // NOTE: the user must be navigated to the session page
    onNavigate(joinSessionRoute(currentToken));
  }, [currentToken, onNavigate, stateTypeHistory]);

  useEffect(() => {
    if (!match && errorMessage) {
      // only show a snackbar when outside of the session page
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  }, [enqueueSnackbar, errorMessage, match]);

  useEffect(() => {
    if (
      (!isSessionRoute && !isLoggedIn) ||         // mini session viewer is only available when logged in
      (!isSessionRoute && stateType !== 'joined') // clean up unjoined session connections
    ) {
      dispatch(liveSessionDisconnectAC());
    }
  }, [dispatch, isLoggedIn, isSessionRoute, stateType]);

  if (!isSessionRoute) {
    if (state?.type === 'terminated') {
      return <LiveSessionTerminatedDialog state={state} />;
    }

    if (state?.type === 'error') {
      return <LiveSessionErrorDialog state={state} />;
    }
  }

  return null;
}

export default React.memo(LiveSessionManager as React.FC);
