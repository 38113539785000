import gql from 'graphql-tag';

import { TeamFragment } from '../fragments';

export const CancelTeamSubscription_Mutation = gql`
    mutation CancelTeamSubscriptionMutation(
        $input: CancelSubscriptionInput!
    ) {
        cancelTeamSubscription(
            input: $input
        ) {
            team {
                ...TeamFragment
            }
        }
    }
    ${TeamFragment}
`;
