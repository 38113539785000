import { bidirectionalActionCreatorsFactory } from '@insights-gaming/redux-utils';
import { MatchFragment } from 'apollo/fragments/types/MatchFragment';
import { ResetStatsMutation_resetStats } from 'apollo/mutations/types/ResetStatsMutation';
import { UpdateAttackerMutation_updateAttacker } from 'apollo/mutations/types/UpdateAttackerMutation';
import { UpdatePlayerMutation_updatePlayer } from 'apollo/mutations/types/UpdatePlayerMutation';
import { UpdatePrimaryRosterMutation_updatePrimaryRoster_roster } from 'apollo/mutations/types/UpdatePrimaryRosterMutation';
import { UpdateRosterMutation_updateRoster } from 'apollo/mutations/types/UpdateRosterMutation';
import { UpdateStatsMapMutation_updateStatsMap } from 'apollo/mutations/types/UpdateStatsMapMutation';
import { UpdateStatsMutation_updateStats } from 'apollo/mutations/types/UpdateStatsMutation';
import { GetMatchesByIdQueryVariables } from 'apollo/queries/types/GetMatchesByIdQuery';
import { GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics,GetOverwatchPrimaryRosterPlayerStatisticsV2QueryVariables } from 'apollo/queries/types/GetOverwatchPrimaryRosterPlayerStatisticsV2Query';
import { GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query_statistics,GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2QueryVariables } from 'apollo/queries/types/GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query';
import { GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics,GetOverwatchPrimaryRosterTeamFightMapStatisticsV2QueryVariables } from 'apollo/queries/types/GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query';
import { GetPrimaryRosterQuery, GetPrimaryRosterQueryVariables } from 'apollo/queries/types/GetPrimaryRosterQuery';
import { GetTeamRostersQuery_queryTeamRosters,GetTeamRostersQueryVariables } from 'apollo/queries/types/GetTeamRostersQuery';
import { GetVideosByIdQuery_videos_latestAnalysis } from 'apollo/queries/types/GetVideosByIdQuery';
import { makeMatch } from 'factories/matchFactory';
import {createVideoStatsCacheKey } from 'helpers';
import addAsyncCases from 'helpers/addAsyncCases';
import { addBidirectionalCases } from 'helpers/addBidirectionalCases';
import { createSlice } from 'helpers/createSlice';
import { castDraft, Draft } from 'immer';
import { CustomCommands, Spec } from 'immutability-helper';
import { ICustomInsertSpec, IStatisticsStoreState, myUpdate, TeamRosterStatistics } from 'types';
import { ResetStatsInput, UpdateAttackerInput, UpdatePlayerInput, UpdatePrimaryRosterInput,UpdateRosterInput, UpdateStatsInput, UpdateStatsMapInput } from 'types/graphql';
import {ID } from 'types/pigeon';
import actionCreatorFactory, { Action } from 'typescript-fsa';

const name = 'statistics';

const actionCreator = actionCreatorFactory(name);

const bidirectionalActionCreators = bidirectionalActionCreatorsFactory(actionCreator);

export const fetchOverwatchPrimaryRosterPlayerStatisticsAsyncAC = actionCreator.async<
  GetOverwatchPrimaryRosterPlayerStatisticsV2QueryVariables,
  GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics,
  Error
>('FETCH_OVERWATCH_PRIMARY_ROSTER_PLAYER_STATISTICS');

export const fetchOverwatchPrimaryRosterTeamFightCompositionStatisticsAsyncAC = actionCreator.async<
  GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2QueryVariables,
  GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query_statistics,
  Error
>('FETCH_OVERWATCH_PRIMARY_ROSTER_TEAM_FIGHT_COMPOSITION_STATISTICS');

export const fetchOverwatchPrimaryRosterTeamFightMapStatisticsAsyncAC = actionCreator.async<
  GetOverwatchPrimaryRosterTeamFightMapStatisticsV2QueryVariables,
  GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics,
  Error
>('FETCH_OVERWATCH_PRIMARY_ROSTER_TEAM_FIGHT_MAP_STATISTICS');

export const updatePlayerAsyncAC = actionCreator.async<
  UpdatePlayerInput,
  UpdatePlayerMutation_updatePlayer,
  Error
>('UPDATE_PLAYER');

export const updateRosterAsyncAC = actionCreator.async<
  UpdateRosterInput,
  UpdateRosterMutation_updateRoster,
  Error
>('UPDATE_ROSTER');

export const updatePrimaryRosterAsyncAC = actionCreator.async<
  UpdatePrimaryRosterInput,
  UpdatePrimaryRosterMutation_updatePrimaryRoster_roster,
  Error
>('UPDATE_PRIMARY_ROSTER');

export const fetchTeamRostersAsyncAC = bidirectionalActionCreators<
  GetTeamRostersQueryVariables,
  GetTeamRostersQuery_queryTeamRosters,
  Error
>('FETCH_TEAM_ROSTERS');

export const resetStatsAsyncAC = actionCreator.async<
  ResetStatsInput,
  ResetStatsMutation_resetStats,
  Error
>('RESET_STATS');

export const updateStatsAsyncAC = actionCreator.async<
  UpdateStatsInput,
  UpdateStatsMutation_updateStats,
  Error
>('UPDATE_STATS');

export const updateStatsMapAsyncAC = actionCreator.async<
  UpdateStatsMapInput,
  UpdateStatsMapMutation_updateStatsMap,
  Error
>('UPDATE_STATS_MAP');

export const updateAttackerAsyncAC = actionCreator.async<
  UpdateAttackerInput,
  UpdateAttackerMutation_updateAttacker,
  Error
>('UPDATE_ATTACKER');

export const fetchPrimaryRosterAsyncAC = actionCreator.async<
  GetPrimaryRosterQueryVariables,
  GetPrimaryRosterQuery,
  Error
>('FETCH_PRIMARY_ROSTER');

export const fetchMatchesByIdAsyncAC = actionCreator.async<
  GetMatchesByIdQueryVariables,
  MatchFragment[],
  Error
>('FETCH_MATCHES_BY_ID');

export const fetchAnalysisMatchesAC = actionCreator<{
  analysis: GetVideosByIdQuery_videos_latestAnalysis;
}>('FETCH_ANALYSIS_MATCHES');

const initialState: IStatisticsStoreState = {
  teamRosterRecords: {},
  teamRosterDict: {},
  teamPrimaryRosterIds: {},

  playerStatistics: {
    cache: {},
    pending: {},
  },
  teamFightCompositionStatistics: {
    cache: {},
    pending: {},
  },
  teamFightMapStatistics: {
    cache: {},
    pending: {},
  },

  matchCache: {},
  pendingMatchIds: [],
};

interface StatisticsParams {
  teamId?: ID | null | undefined;
  videoIds?: ID[] | null | undefined;
}

function startTeamRosterStatisticsReducer<T>(
  state: Draft<TeamRosterStatistics<T>>,
  { teamId, videoIds }: StatisticsParams,
): void {
  if (!teamId) {
    return;
  }

  const cacheKey = createVideoStatsCacheKey(videoIds);
  if (!state.pending[teamId]?.includes(cacheKey)) {
    if (!state.pending[teamId]) {
      state.pending[teamId] = [cacheKey];
    } else {
      state.pending[teamId].push(cacheKey);
    }
  }

  state.error = undefined;
}

function doneTeamRosterStatisticsReducer<T>(
  state: Draft<TeamRosterStatistics<T>>,
  { params: { teamId, videoIds }, result }: { params: StatisticsParams, result: T },
): void {
  if (!teamId) {
    return;
  }

  const cacheKey = createVideoStatsCacheKey(videoIds);
  const index = state.pending[teamId]?.indexOf(cacheKey);
  if (index >= 0) {
    state.pending[teamId].splice(index, 1);
  }

  if (!state.cache[teamId]) {
    state.cache[teamId] = {};
  }

  state.cache[teamId][cacheKey] = castDraft(result);
  state.error = undefined;
}

function failedTeamRosterStatisticsReducer<T>(
  state: Draft<TeamRosterStatistics<T>>,
  { params: { teamId, videoIds }, error }: { params: StatisticsParams, error: Error },
): void {
  if (!teamId) {
    return;
  }

  const cacheKey = createVideoStatsCacheKey(videoIds);
  const index = state.pending[teamId]?.indexOf(cacheKey);
  if (index >= 0) {
    state.pending[teamId].splice(index, 1);
  }

  state.error = error;
}

const statisticSlice = createSlice({
  name,
  initialState,
  reducers: {
    invalidateTeamStatsCacheAC(state, { payload: { teamId } }: Action<{ teamId: ID }>) {
      delete state.playerStatistics.cache[teamId];
      delete state.teamFightCompositionStatistics.cache[teamId];
      delete state.teamFightMapStatistics.cache[teamId];
    },
  },
  extraReducers: builder => {
    addAsyncCases(builder, fetchOverwatchPrimaryRosterPlayerStatisticsAsyncAC, {
      started: (state, { payload }) => startTeamRosterStatisticsReducer(state.playerStatistics, payload),
      done: (state, { payload }) => doneTeamRosterStatisticsReducer(state.playerStatistics, payload),
      failed: (state, { payload }) => failedTeamRosterStatisticsReducer(state.playerStatistics, payload),
    });

    addAsyncCases(builder, fetchOverwatchPrimaryRosterTeamFightCompositionStatisticsAsyncAC, {
      started: (state, { payload }) => startTeamRosterStatisticsReducer(state.teamFightCompositionStatistics, payload),
      done: (state, { payload }) => doneTeamRosterStatisticsReducer(state.teamFightCompositionStatistics, payload),
      failed: (state, { payload }) => failedTeamRosterStatisticsReducer(state.teamFightCompositionStatistics, payload),
    });

    addAsyncCases(builder, fetchOverwatchPrimaryRosterTeamFightMapStatisticsAsyncAC, {
      started: (state, { payload }) => startTeamRosterStatisticsReducer(state.teamFightMapStatistics, payload),
      done: (state, { payload }) => doneTeamRosterStatisticsReducer(state.teamFightMapStatistics, payload),
      failed: (state, { payload }) => failedTeamRosterStatisticsReducer(state.teamFightMapStatistics, payload),
    });

    addBidirectionalCases(builder, fetchTeamRostersAsyncAC, {
      records: (tp, { teamId }) => tp.teamRosterRecords[teamId],
      dict: (tp, { teamId }) => tp.teamRosterDict[teamId],
      values: (result) => result.rosters,
    });

    addAsyncCases(builder, fetchPrimaryRosterAsyncAC, {
      done: (state, { payload: { params: { teamId }, result: { queryPrimaryRoster: roster } } }) => {
        if (!teamId || !roster) {
          return;
        }

        if (!state.teamRosterDict[teamId]) {
          state.teamRosterDict[teamId] = {};
        }

        state.teamRosterDict[teamId][roster.id] = roster;
        state.teamPrimaryRosterIds[teamId] = roster.id;
      },
    });

    addAsyncCases(builder, updateRosterAsyncAC, {
      done: (state, { payload: { params: { teamId }, result: { roster } } }) => {
        if (!roster) { return state; }

        const spec: Spec<IStatisticsStoreState, CustomCommands<ICustomInsertSpec>> = {};

        if (!teamId) { // user roster player
          return state;
        } else { // team roster players
          // TODO: FIXME: DO THIS PROPERLY
          // let p: RosterCache = state.teamRosterPlayersCache.get(teamId) || new ReduxCache();
          // let pp: Optional<GetAllRosterPlayersQuery_statistics_OverwatchStatistics_rosters> = p.get(roster.id);
          // if (!pp) { return state; }
          // pp = update(pp, {$merge: roster});
          // p = myUpdate(p, {$customAdd: [[roster.id, pp]]});
          // spec.teamRosterPlayersCache = {$add: [[teamId, p]]};
          // spec.pendingTeamRosterPlayers = {$remove: [teamId]};
        }

        return myUpdate(state, spec);
      },
    });

    addAsyncCases(builder, updatePrimaryRosterAsyncAC, {
      done: (state, { payload: { params: { teamId, id } } }) => {
        state.teamPrimaryRosterIds[teamId] = id;
      },
    });

    addAsyncCases(builder, fetchMatchesByIdAsyncAC, {
      started: (state, { payload: { ids } }) => {
        state.pendingMatchIds.push(...ids);
      },
    });

    addAsyncCases(builder, fetchMatchesByIdAsyncAC, {
      done: (state, { payload: { params, result } }) => {
        for (const input of result) {
          state.matchCache[input.id] = makeMatch(input);
          const idx = state.pendingMatchIds.indexOf(input.id);
          if (idx >= 0) {
            state.pendingMatchIds.splice(idx, 1);
          }
        }
      },
    });
  },
});

export const statisticsReducer = statisticSlice.reducer;
export const { invalidateTeamStatsCacheAC } = statisticSlice.actions;
