import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CardFragment } from 'apollo/fragments/types/CardFragment';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

import CardDetailsLayout, { CardDetailsLayoutProps } from './CardDetailsLayout';

type GenericCardFragment = Omit<CardFragment, '__typename'>;

interface CardDetailsOwnProps {
  className?: string;
  card: GenericCardFragment;
}

type CardDetailsProps = CardDetailsOwnProps & Omit<CardDetailsLayoutProps, 'children'>;

const trim = (x: number | undefined) => {
  if (x) {
    let str = String(x);
    return str.substring(2);
  } else {
    return null;
  }
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  exp: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
}), {name: 'CardDetails'});

function CardDetails({ card, ...props }: CardDetailsProps) {
  const classes = useStyles(props);
  const { t } = useStrictTranslation(['dashboard']);

  return (
    <CardDetailsLayout {...props}>
      <Typography variant='caption'>
        {t('dashboard:billing.cardending', { brand: card.brand, last4: card.last4 })}
      </Typography>
      <Typography variant='caption' className={classes.exp}>
        {t('dashboard:billing.expiresin', { month: card.month, year: trim(card.year) })}
      </Typography>
    </CardDetailsLayout>
  );
}

export default React.memo(CardDetails);
