import { TagFragment } from 'apollo/fragments';
import gql from 'graphql-tag';

export const CreateTag2_Mutation = gql`
    mutation CreateTag2Mutation(
        $input: CreateTag2Input!
    ) {
        createTag2(
            input: $input
        ) {
            tag {
                ...TagFragment
            }
        }
    }
    ${TagFragment}
`;
