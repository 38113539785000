import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import useRecurringCost from 'features/dashboard/billing/useRecurringCost';
import { unixSecondsToDate } from 'helpers/formatters';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';
import { IBillingCycle } from 'types/pigeon/billing';

interface CurrentCycleOwnProps {
  className?: string;
  teamId: string;
  billingCycle: IBillingCycle | undefined;
  enterprise?: boolean | null;
}

type CurrentCycleProps = CurrentCycleOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: createRemFromPx(416),
  },
  paper: {
    margin: theme.spacing(1.5, 0),
    padding: theme.spacing(1.5, 2.5),
  },
  cost: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
  },
}), {name: 'CurrentCycle'});

function CurrentCycle(props: CurrentCycleProps) {
  const classes = useStyles(props);
  const { className, billingCycle, enterprise, teamId } = props;

  const { t } = useStrictTranslation(['dashboard']);

  const [_, displayRecurringCost] = useRecurringCost(teamId);

  return (
    <div className={classNames(classes.root, className)}>
      <Paper className={classes.paper}>
        <FlexSpacer fullWidth={true} flexJustifyContent='space-between'>
          {enterprise ? (
            <Typography>
              {t('dashboard:billing.enterprisepayment')}
            </Typography>
          ) : (
            <FlexSpacer
            orientation='vertical'
            fullWidth={true}
            flexJustifyContent='space-between'
            spacing={1.5}
            >
              <Typography className={classes.cost} variant='h4'>
                {displayRecurringCost}
              </Typography>
              <Typography>
                {billingCycle && (billingCycle.cancels ? t(
                    'dashboard:billing.cancelat',
                    {
                      date: unixSecondsToDate(billingCycle.cancels),
                    },
                  ) : t(
                  'dashboard:billing.currentbillingcycle',
                  {
                    start: unixSecondsToDate(billingCycle.billingCycleStart),
                    end: unixSecondsToDate(billingCycle.billingCycleEnd),
                  },
                ))}
              </Typography>
            </FlexSpacer>
          )}
        </FlexSpacer>
      </Paper>
    </div>
  );
}

export default React.memo(CurrentCycle);
