import {
  bidirectionalActionCreatorsFactory,
  BidirectionalIDRecord,
} from '@insights-gaming/redux-utils';
import { RoleInterfaceFragment_CustomRole } from 'apollo/fragments/types/RoleInterfaceFragment';
import {
  GetTeamCustomRolesQuery_team_queryCustomRoles,
  GetTeamCustomRolesQueryVariables,
} from 'apollo/queries/types/GetTeamCustomRolesQuery';
import { deleteRoleAC, updateCustomRoleAC } from 'features/dashboard/team/dashboard-team-slice';
import addAsyncCases from 'helpers/addAsyncCases';
import { addBidirectionalCases } from 'helpers/addBidirectionalCases';
import { createSlice } from 'helpers/createSlice';
import update from 'immutability-helper';
import actionCreatorFactory from 'typescript-fsa';

const name = 'dashboard-role';
const actionCreator = actionCreatorFactory(name);
const bidirectionalActionCreator = bidirectionalActionCreatorsFactory(actionCreator);

export const fetchTeamCustomRolesAC = bidirectionalActionCreator<
  GetTeamCustomRolesQueryVariables,
  GetTeamCustomRolesQuery_team_queryCustomRoles,
  Error
>('FETCH_TEAM_ROLES');

interface DashboardRoleState {
  teamCustomRoleRecords: Partial<Dictionary<BidirectionalIDRecord>>;
  customRoleDict: Partial<Dictionary<RoleInterfaceFragment_CustomRole>>;
}

const initialState: DashboardRoleState = {
  teamCustomRoleRecords: {},
  customRoleDict: {},
};

const dashboardRoleSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    addBidirectionalCases(builder, fetchTeamCustomRolesAC, {
      records: (tp, { teamId }) => tp.teamCustomRoleRecords[teamId],
      dict: (tp) => tp.customRoleDict,
      values: (result) => result.roles,
    });

    addAsyncCases(builder, updateCustomRoleAC, {
      done: (state, action) => {
        const { result: { role } } = action.payload;
        return update(state, {
          customRoleDict: {
            [role.id]: {$set: role},
          },
        });
      },
    });

    addAsyncCases(builder, deleteRoleAC, {
      done: (state, action) => {
        const { params: { id: roleId, teamId } } = action.payload;
        return update(state, {
          teamCustomRoleRecords: {
            [teamId]: {
              ids: ids => ids.filter(id => id !== roleId),
            },
          },
          customRoleDict: {$unset: [roleId]},
        });
      },
    });
  },
});

export const dashboardRoleReducer = dashboardRoleSlice.reducer;
