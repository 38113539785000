import { DrawingTool } from '@insights-gaming/drawing-canvas/dist/tools/tool';
import { createRemFromPx,Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Menu from '@material-ui/core/Menu';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { VideoplayerNS } from 'locales/en/videoplayer';
import { bindMenu, bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useCallback, useEffect,useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDerivedKeybindingState } from 'selectors/keybinding';

import { convertTitlekeyToHotkey, ToolButtonDefinitionWithRequiredTitleKey } from './DrawingToolbar';
import ToolbarListItem from './ToolbarListItem';
import { DrawingState } from './useDrawingState';

interface DrawingToolbarCompositeButtonOwnProps {
  className?: string;
  state: DrawingState;
  disabled: boolean;
  popupId: string;
  toolDefs: ToolButtonDefinitionWithRequiredTitleKey[];
}

type DrawingToolbarCompositeButtonProps = DrawingToolbarCompositeButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&$selected': {
      color: theme.palette.primary.main,
    },
  },
  btnGrp: {
    '&:not(:last-child)': {
      borderRight: 'none',
    },
  },
  dropdown: {
    minWidth: theme.spacing(1),
    padding: theme.spacing(0.5, 0),
  },
  expand: {
    fontSize: createRemFromPx(16),
  },
  selected: {},
}), {name: 'DrawingToolbarCompositeButton'});

function DrawingToolbarCompositeButton(props: DrawingToolbarCompositeButtonProps) {
  const classes = useStyles(props);
  const { state: { tool: currentTool, setTool }, disabled, toolDefs, popupId } = props;
  const popupState = usePopupState({ popupId, variant: 'popover' });
  const { onClose } = bindPopover(popupState);
  const { t } = useStrictTranslation(VideoplayerNS);
  const { keybindingMap } = useSelector(getDerivedKeybindingState);

  const toolsArray = useMemo(
    () => toolDefs.map(({ icon: Icon, iconProps, ...rest }) => ({ icon: Icon && <Icon {...iconProps}/>, rest })),
    [toolDefs],
  );

  const toolIndexMap = useMemo(
    // NOTE: Map must be used here because the keys (DrawingTool) are objects and not strings.
    () => new Map(toolsArray.map(({ rest: { tool } }, index) => [tool, index])),
    [toolsArray],
  );

  const [promotedToolIndex, setPromotedToolIndex] = useState(0);

  useEffect(() => {
    const toolIndex = toolIndexMap.get(currentTool);
    if (toolIndex !== undefined) {
      setPromotedToolIndex(toolIndex);
    }
  }, [currentTool, toolIndexMap]);

  const setToolWrapped = useCallback((tool: DrawingTool) => {
    onClose();
    setTool(tool);
    const toolIndex = toolIndexMap.get(tool);
    if (toolIndex !== undefined) {
      setPromotedToolIndex(toolIndex);
    }
  }, [onClose, setTool, toolIndexMap]);

  const selectPromotedTool = useCallback(
    () => setTool(toolDefs[promotedToolIndex].tool),
    [promotedToolIndex, setTool, toolDefs],
  );

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <ButtonGroup size='small' variant='text' classes={{groupedTextHorizontal: classes.btnGrp}}>
        <Tooltip
        title={t(
          toolsArray[promotedToolIndex].rest.titlekey,
          { key: convertTitlekeyToHotkey(keybindingMap, toolsArray[promotedToolIndex].rest.titlekey) },
        )}
        PopperProps={{ container: ref.current }}
        >
          <Button
          disabled={disabled}
          onClick={selectPromotedTool}
          className={classNames(classes.root, {
            [classes.selected]: toolIndexMap.has(currentTool),
          })}
          >
            {toolsArray[promotedToolIndex].icon}
          </Button>
        </Tooltip>
        <Button
        {...bindTrigger(popupState)}
        className={classes.dropdown}
        disabled={disabled}
        >
          <ExpandMoreIcon className={classes.expand}/>
        </Button>
      </ButtonGroup>
      <Menu
      {...bindMenu(popupState)}
      disablePortal={true}
      style={{ cursor: 'default' }}
      >
        {
          toolsArray.map(({ icon, rest: { tool, titlekey } }, i) => (
            <ToolbarListItem
            key={titlekey}
            titlekey={titlekey}
            tool={tool}
            setTool={setToolWrapped}
            icon={icon}
            selected={i === promotedToolIndex}
            />
          ))
        }
      </Menu>
    </div>
  );
}

export default React.memo(DrawingToolbarCompositeButton);
