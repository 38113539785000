import { Theme } from '@insights-gaming/theme';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { removeAuthenticationMethodAC } from 'features/auth/auth-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { AuthenticationProvider } from 'types/graphql';

interface RemoveSigninMethodDialogOwnProps {
  className?: string;
  open: boolean;
  onClose: VoidFunction;
  provider: AuthenticationProvider;
  name?: string;
  id: string;
}

type RemoveSigninMethodDialogProps = RemoveSigninMethodDialogOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'RemoveSigninMethodDialog'});

function RemoveSigninMethodDialog(props: RemoveSigninMethodDialogProps) {
  const classes = useStyles(props);
  const { className, open, onClose, provider, id, name } = props;

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useStrictTranslation(['common', 'settings']);
  const [loading, setLoading] = useState(false);

  const removeOnClick = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      await promiseSagaDispatch(removeAuthenticationMethodAC, { name: provider, id });
      enqueueSnackbar(t('settings:signinmethods.removedialog.success'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('settings:signinmethods.removedialog.failure'), {variant: 'error'});
    } finally {
      setLoading(false);
      onClose();
    }
  }, [enqueueSnackbar, id, loading, onClose, promiseSagaDispatch, provider, t]);

  return (
    <Dialog open={open} onClose={onClose}>
      <AlertDialogContent
      titleText={t('settings:signinmethods.removedialog.title')}
      description={t('settings:signinmethods.removedialog.description', { provider, name })}
      cancel={{
        text: t('common:cancel'),
        action: onClose,
      }}
      confirm={{
        text: t('settings:signinmethods.removedialog.remove'),
        loading,
        disabled: loading,
        action: removeOnClick,
      }}
      />
    </Dialog>
  );
}

export default React.memo(RemoveSigninMethodDialog);
