import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import { AuthenticationMethodFragment } from 'apollo/fragments/types/AuthenticationMethodFragment';
import classNames from 'classnames';
import { formatDate } from 'helpers/formatters';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import FixedTableCell from 'material/fixed-table-cell/FixedTableCell';
import React from 'react';

interface SigninMethodsTableRowOwnProps extends Omit<AuthenticationMethodFragment, '__typename'> {
  className?: string;
  action?: React.ReactNode;
}

type SigninMethodsTableRowProps = SigninMethodsTableRowOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: createRemFromPx(48),
  },
}), {name: 'SigninMethodsTableRow'});

function SigninMethodsTableRow(props: SigninMethodsTableRowProps) {
  const classes = useStyles(props);
  const { className, created, name, externalId, externalName, action } = props;
  const { t } = useStrictTranslation(['settings']);
  const wt = t as TFunction;

  return (
    <TableRow className={classNames(classes.root, className)}>
      <FixedTableCell width={200}>{wt(`settings:signinmethods.providername.${name}`)}</FixedTableCell>
      <FixedTableCell width={150}>{formatDate(created)}</FixedTableCell>
      <FixedTableCell width={250}>{externalId}</FixedTableCell>
      <FixedTableCell width={180}>{externalName}</FixedTableCell>
      <FixedTableCell width={150}>{action}</FixedTableCell>
    </TableRow>
  );
}

export default React.memo(SigninMethodsTableRow);
