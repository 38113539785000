import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import DashboardTeamSettingsBillingRouting from 'features/dashboard/team/dashboard-team-settings-billing.routing';
import { hexToRGBA } from 'helpers';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import ButtonLink from 'material/button-link/ButtonLink';
import React from 'react';
import { ID, VideoError } from 'types/pigeon';

import GenericOverlay from './GenericOverlay';
import Indeterminate from './progress/Indeterminate';

interface VideoCardErrorOwnProps {
  videoError: VideoError;
  teamId?: ID;
}

type VideoCardErrorProps = VideoCardErrorOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
    '&$viewable': {
      backgroundColor: hexToRGBA(theme.palette.background.default, .85),
    },
  },
  viewable: {},
  button: {
    pointerEvents: 'auto',
  },
}), {name: 'VideoCardError'});

function VideoCardError(props: VideoCardErrorProps) {
  const classes = useStyles(props);
  const { videoError, teamId } = props;

  const { retrying, viewable, titleKey, descriptionKey, needsUpgrade } = videoError;

  const { t } = useStrictTranslation(['common']);
  const wt = t as TFunction;

  const { canAccessBilling } = useAccessControl();

  return retrying ? (
    <GenericOverlay
    errorText={t('common:retrying')}
    inner={<Indeterminate />}
    />
  ) : (
    <FlexSpacer
    orientation='vertical'
    spacing={0.5}
    flexJustifyContent='center'
    flexAlignItems='center'
    className={classNames(classes.root, {
      [classes.viewable]: viewable,
    })}
    >
      <WarningRoundedIcon fontSize='large'/>
      <FlexSpacer orientation='vertical' flexAlignItems='center'>
        {titleKey && (
          <Typography style={{textTransform: 'capitalize'}}>
            {wt(titleKey)}
          </Typography>
        )}
        {needsUpgrade ? (
          canAccessBilling && (
            <ButtonLink
            className={classes.button}
            to={DashboardTeamSettingsBillingRouting.createUrl(
              teamId || '',
              DashboardTeamSettingsBillingRouting.BillingTab.OVERVIEW,
            )}
            color='primary'
            variant='contained'
            >
              {wt('common:videocard.fix.changeplan')}
            </ButtonLink>
          )
        ) : descriptionKey && (
          <Typography variant='subtitle2' align='center'>
            {wt(descriptionKey)}
          </Typography>
        )}
      </FlexSpacer>
    </FlexSpacer>
  );
}

export default React.memo(VideoCardError);
