import { Theme } from '@insights-gaming/theme';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import React from 'react';

import MemberListItem from './MemberListItem';

interface MemberListItemSkeletonOwnProps {
  className?: string;
}

type MemberListItemSkeletonProps = MemberListItemSkeletonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'MemberListItemSkeleton'});

function MemberListItemSkeleton(props: MemberListItemSkeletonProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <MemberListItem
    className={classNames(classes.root, className)}
    avatar={(
      <ListItemAvatar>
        <Skeleton variant='circle' width={40} height={40} />
      </ListItemAvatar>
    )}
    name={(
      <React.Fragment>
        <Skeleton variant='text' />
        <Skeleton variant='text' />
      </React.Fragment>
    )}
    />
  );
}

export default React.memo(MemberListItemSkeleton);
