import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TeamSubscriptionFragment } from 'apollo/fragments/types/TeamSubscriptionFragment';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { IIntervalCosts } from 'features/dashboard/billing/useAvailableMetrics';
import { numToDollarAmount } from 'helpers/formatters';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { TFunction } from 'i18next';
import fromPairs from 'lodash/fromPairs';
import React, { useCallback, useMemo } from 'react';
import { BillingInterval } from 'types/graphql';
import { ETeamBillingPlanType } from 'types/pigeon/billing';

interface PlanCardOwnProps {
  className?: string;
  title?: string;
  upload?: number | null;
  storage?: number | null;
  storageBytes?: number | null;
  cost?: string | IIntervalCosts;
  interval?: BillingInterval;
  active: boolean;
  hasPlan: boolean;
  teamSubscription?: TeamSubscriptionFragment;
  openDefaultPlanDetailsDialog?: VoidFunction;
  openCustomPlanDetailsDialog?: VoidFunction;
  index?: number;
  productId?: string;
  handleCardOnClick?: (index: number) => void;
  desc?: string;
  keys?: number;
}

type PlanCardProps = PlanCardOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 205,
    '&$active': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.container,
      borderWidth: '2px',
    },
    marginBottom: theme.spacing(1.5),
    '&&:hover': {
      cursor: 'pointer',
    },
  },
  bottom: {
    backgroundColor: theme.palette.background.dialog,
    padding: theme.spacing(0.5, 0),
    '&$active': {
      backgroundColor: theme.palette.primary.main,
    },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  semiBold: {
    fontWeight: 600,
  },
  bold: {
    fontWeight: 700,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  strike: {
    textDecoration: 'line-through',
    opacity: 0.75,
  },
  even: {
    flex: 1,
  },
  active: {},
  subArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flexGrow: 0,
    paddingTop: '1rem',
  },
  basic: {
    borderColor: theme.palette.tiers.basic,
  },
  bronze: {
    borderColor: theme.palette.tiers.bronze,
  },
  silver: {
    borderColor: theme.palette.tiers.silver,
  },
  gold: {
    borderColor: theme.palette.tiers.gold,
  },
  platinum: {
    borderColor: theme.palette.tiers.platinum,
  },
  diamond: {
    borderColor: theme.palette.tiers.diamond,
  },
  challenger: {
    borderColor: theme.palette.tiers.challenger,
  },
  cancelButton: {
    borderColor: theme.palette.negative.main,
    color: theme.palette.negative.main,
    '&:hover': {
      borderColor: theme.palette.negative.main,
      backgroundColor: theme.palette.action.hover,
    },
  },
}), {name: 'PlanCard'});

function PlanCard(props: PlanCardProps) {
  const classes = useStyles(props);
  const {
    className,
    title,
    upload,
    storage,
    storageBytes,
    cost,
    interval,
    active,
    hasPlan,
    desc,
    openDefaultPlanDetailsDialog,
    openCustomPlanDetailsDialog,
    index,
    productId,
    handleCardOnClick,
    teamSubscription,
    keys,
  } = props;

  const { canUpdateTeamSubscription, canCancelTeamSubscription, canCreateTeamSubscription }  = useAccessControl();

  const { t } = useStrictTranslation(['dashboard', 'dialog']);
  const wt = t as TFunction;

  const isBasicPlan = useMemo(() => title === ETeamBillingPlanType.BASIC, [title]);
  const teamProductsIndex = useMemo(() => {
    if (!teamSubscription) {
      return;
    }

    return fromPairs(teamSubscription.products.map((product) => [product.id, product]));
  }, [teamSubscription]);

  const hasCurrentPlan = useMemo(() => {
    if (!hasPlan && isBasicPlan) {
      return true;
    }

    if (!productId || !teamProductsIndex) {
      return false;
    }

    return interval === teamSubscription?.interval && productId in teamProductsIndex;
  },[hasPlan, interval, isBasicPlan, productId, teamProductsIndex, teamSubscription]);

  const renderPlanDescription = useCallback(() => {
    return (
      <React.Fragment>
        {!!upload && isFinite(upload) && (
          <Typography>
            {t('dialog:availableplans.uploadhourscount', { count: upload })}
          </Typography>
        )}
        {!!storage && (
          <Typography>
            {t('dialog:availableplans.storedhourscount', { count: storage })}
          </Typography>
        )}
        {!!storageBytes && (
          <Typography>
            {t('dialog:availableplans.storedgbscount', { count: Math.floor(storageBytes / (2 ** 30)) })}
          </Typography>
        )}
        {!!keys && (
          <Typography>
            {t('dashboard:billing.adfreekeycount', { count: keys })}
          </Typography>
        )}
      </React.Fragment>
    );
  }, [keys, storage, storageBytes, t, upload]);

  const renderRecuringCost = useCallback(() => {
    if (typeof cost === 'object') {
      return interval === BillingInterval.MONTHLY ? (
        <Typography variant='h4'>
          {t('dialog:availableplans.recuringcost', { cost: cost.MONTHLY })}
        </Typography>
      ) : (
        <FlexSpacer flexAlignItems='center'>
          <Typography variant='body1' className={classes.strike}>
            {t('dialog:availableplans.recuringcost', { cost: cost.MONTHLY })}
          </Typography>
          <Typography variant='h3'>
            {t('dialog:availableplans.recuringcost', { cost: numToDollarAmount(cost.YEARLY / 12) })}
          </Typography>
        </FlexSpacer>
      );
    }
    return null;
  }, [classes.strike, cost, interval, t]);

  const renderButtonLabel = useCallback(() => {
    if (hasCurrentPlan) {
      return t('dialog:availableplans.cancelplan');
    }

    return t('dialog:availableplans.selectplan');
  }, [hasCurrentPlan, t]);

  const buttonDisabled = useMemo(() => {
    if (hasPlan) {
      if (isBasicPlan) {
        return !canCancelTeamSubscription;
      }

      return !canUpdateTeamSubscription;
    }

    if (!hasPlan && title === ETeamBillingPlanType.BASIC) {
      return true;
    }

    return !canCreateTeamSubscription;
  }, [canCancelTeamSubscription, canCreateTeamSubscription, canUpdateTeamSubscription, hasPlan, isBasicPlan, title]);

  const renderButtonAction = useCallback(() => {
    if (hasCurrentPlan) {
      return openDefaultPlanDetailsDialog;
    }
    return isBasicPlan ? openDefaultPlanDetailsDialog : openCustomPlanDetailsDialog;
  }, [hasCurrentPlan, isBasicPlan, openCustomPlanDetailsDialog, openDefaultPlanDetailsDialog]);

  const cardOnClick = useCallback((e: React.MouseEvent) => {
    if (!active && typeof index === 'number') {
      handleCardOnClick?.(index);
    }
  }, [active, handleCardOnClick, index]);

  return (
    <React.Fragment>
      <Box boxShadow={active ? 3 : 0}>
        <Card
        variant='outlined'
        className={classNames(className, classes.root, {
          [classes.active]: active,
          [classes.basic]: title === ETeamBillingPlanType.BASIC,
          [classes.bronze]: title === ETeamBillingPlanType.BRONZE,
          [classes.silver]: title === ETeamBillingPlanType.SILVER,
          [classes.gold]: title === ETeamBillingPlanType.GOLD,
          [classes.platinum]: title === ETeamBillingPlanType.PLATINUM,
          [classes.diamond]: title === ETeamBillingPlanType.DIAMOND,
          [classes.challenger]: title === ETeamBillingPlanType.CHALLENGER,
        })}
        onClick={cardOnClick}
        >
          <div className={classNames(classes.even, classes.subArea, classes.title)} >
            <Typography variant='h4' className={classes.semiBold}>
              {wt(`dialog:availableplans.${title}`)}
            </Typography>
            {desc && (
              <Typography variant='caption'>
                {desc}
              </Typography>
            )}
          </div>
          <div className={classNames(classes.even, classes.subArea)}>
            {renderPlanDescription()}
          </div>
          <div className={classNames(classes.bottom, classes.even, { [classes.active]: active })}>
            {isBasicPlan ? (
              <Typography variant='h2' className={classNames(classes.bold, classes.uppercase)}>
                {cost}
              </Typography>
            ) : renderRecuringCost()
            }
            {!isBasicPlan && (
              <Typography>
                {interval === BillingInterval.MONTHLY
                  ? t('dialog:availableplans.billedmonthly')
                  : typeof cost === 'object' && t('dialog:availableplans.billedannuallyat', { amount: cost.YEARLY })}
              </Typography>
            )}
          </div>
        </Card>
      </Box>
      {active && openDefaultPlanDetailsDialog && openCustomPlanDetailsDialog && (
        <Button
        variant={hasCurrentPlan ? 'outlined' : 'contained'}
        color='primary'
        className={classNames({[classes.cancelButton]: hasCurrentPlan})}
        fullWidth={true}
        onClick={renderButtonAction()}
        disabled={buttonDisabled}
        >
          {renderButtonLabel()}
        </Button>
      )}
    </React.Fragment>
  );
}

export default React.memo(PlanCard);
