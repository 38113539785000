import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/styles';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import UserAvatar from 'material/user-avatar/UserAvatar';
import React from 'react';

import NotificationMessage from './NotificationMessage';

interface NotificationMessageWithButtonProps {
  className?: string;
  notifications: NotificationFragment[];
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  message: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 3,
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
}), {name: 'NotificationMessageWithButton'});

function NotificationMessageWithButton(props: NotificationMessageWithButtonProps) {
  const classes = useStyles(props);
  const { className, notifications } = props;

  const [firstNotification] = notifications;

  return (
    <FlexSpacer className={className} flexAlignItems='center'>
      {
        firstNotification.actor && (
          <UserAvatar user={firstNotification.actor} size='sm' />
        )
      }
      <FlexSpacer orientation='vertical' spacing={0.5}>
        <Typography className={classes.message}>
          <NotificationMessage notifications={notifications} />
        </Typography>
      </FlexSpacer>
    </FlexSpacer>
  );
}

export default React.memo(NotificationMessageWithButton);
