import { AsyncButton } from '@insights-gaming/material-components';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { createControlUserKickAction } from 'factories/kmsessionEventFactory';
import { liveSessionSendActionAC } from 'features/live-session/live-session-slice';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import BetterDialogTitle from 'material/better-dialog-title/BetterDialogTitle';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ISessionProfile } from 'types/pigeon/kmsession';

import { DialogNS } from '../../../locales/en/dialog';

interface ChatMemberListRemoveMemberDialogOwnProps {
  onClose: VoidFunction;
  member: ISessionProfile;
}

type ChatMemberListRemoveMemberDialogProps = ChatMemberListRemoveMemberDialogOwnProps & Pick<DialogProps, 'open'>;

function ChatMemberListRemoveMemberDialog(props: ChatMemberListRemoveMemberDialogProps) {
  const { open, onClose, member } = props;
  const { t } = useStrictTranslation(['common', 'dialog']);
  const dispatch = useDispatch();

  const [ loading, setLoading ] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const titleText = useMemo(() => {
    return (
      <Trans
      i18nKey='dialog:removefromsession.title'
      ns={DialogNS}
      values={{ target: member.alias}}
      >
        <strong>target</strong>
      </Trans>
    );
  }, [member.alias]);

  const confirmRemove = useCallback(() => {
    if (loading) { return; }
    setLoading(true);
    try {
      dispatch(liveSessionSendActionAC.started(createControlUserKickAction(member.id)));
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLoading(false);
      onClose?.();
    }
  }, [dispatch, enqueueSnackbar, loading, member.id, onClose]);

  return (
    <Dialog
    fullWidth={true}
    open={open}
    onClose={onClose}
    maxWidth='xs'
    >
      <BetterDialogTitle>
        {titleText}
      </BetterDialogTitle>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <AsyncButton
        variant='contained'
        color='primary'
        loading={loading}
        disabled={loading}
        onClick={confirmRemove}
        >
          {t('dialog:removefromsession.confirm')}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ChatMemberListRemoveMemberDialog);
