import { FlexSpacer } from '@insights-gaming/material-components';
import { AvatarWithLetterFallback } from '@insights-gaming/material-components/AvatarWithLetterFallback/AvatarWithLetterFallback';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { placeholder } from 'assets';
import classNames from 'classnames';
import { MAX_FILE_SIZE } from 'constants/numbers';
import { makeGetTeamById } from 'features/dashboard/team/dashboard-team-selector';
import { updateTeamAC } from 'features/dashboard/team/dashboard-team-slice';
import { fileToBinaryString } from 'helpers';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedTeamId } from 'selectors/team';
import HiddenFileInput from 'subcomponents/hidden-file-input/HiddenFileInput';
import { UpdateTeamInput } from 'types/graphql';

import GuidedSetupActions from './GuidedSetupActions';

interface FirstTeamOwnProps {
  className?: string;
  onClose: VoidFunction;
  stepBackward: VoidFunction;
  stepForward: VoidFunction;
  step: number;
}

type FirstTeamProps = FirstTeamOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  title: {
    position: 'absolute',
    top: 68,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  header2: {
    marginBottom: theme.spacing(3),
  },
  pre: {
    whiteSpace: 'pre',
  },
  team: {
    position: 'absolute',
    top: 202,
    left: 115,
  },
  teamName: {
    width: createRemFromPx(240),
  },
  skip: {
    position: 'absolute',
    bottom: 24,
    left: 24,
    cursor: 'pointer',
  },
}), {name: 'FirstTeam'});

const TEAM_PIC = 'teampic';

function FirstTeam(props: FirstTeamProps) {
  const classes = useStyles(props);
  const { className, onClose, step, stepBackward, stepForward } = props;
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const selectedTeamId = useSelector(getSelectedTeamId);
  const team = useCreateSelector(makeGetTeamById, selectedTeamId);
  const { t } = useStrictTranslation(['dialog', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const [ loading, setLoading ] = useState(false);
  const [ teamName, setTeamName ] = useState(team?.name || '');
  const [ teamProfilePicture, setTeamProfilePicture ] = useState<File>();

  const handleTeamNameOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamName(e.target.value);
  },[]);

  const handleUpdateTeamOnClick = useCallback(async () => {
    if (
      loading
      || !team
      || (teamName === team.name && !teamProfilePicture)
    ) {
      return;
    }

    setLoading(true);

    const input: UpdateTeamInput = { id: team.id };
    if (teamName) { input.name = teamName; }

    try {
      if (teamProfilePicture) {
        input.picture = {
          name: teamProfilePicture.name,
          contentLength: teamProfilePicture.size,
          contentType: teamProfilePicture.type,
          data: await fileToBinaryString(teamProfilePicture),
        };
      }
      await promiseSagaDispatch(updateTeamAC, input);
      enqueueSnackbar(t('common:updateteamsuccess'), {variant: 'success'});
    } catch (error) {
      enqueueSnackbar(t('common:updateteamfailure'), {variant: 'error'});
    } finally {
      setLoading(false);
    }
  }, [
    enqueueSnackbar,
    loading,
    promiseSagaDispatch,
    t,
    team,
    teamName,
    teamProfilePicture,
  ]);

  const onNextClick = useCallback(async () => {
    await handleUpdateTeamOnClick();
    stepForward();
  }, [handleUpdateTeamOnClick, stepForward]);

  const handleFileChange = useCallback(async(event: any) => {
    if (loading) { return; }
    const file: File = event.target.files[0];
    if (!file) { return; }

    if (file.size > MAX_FILE_SIZE) {
      enqueueSnackbar(t('common:picexceedsizelimit', {bytes: MAX_FILE_SIZE}), {variant: 'warning'});
      return;
    }

    setLoading(true);

    try {
      setTeamProfilePicture(file);
    } catch {
      enqueueSnackbar(t('common:uploadfailure', {variant: 'error'}));
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, loading, t]);

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.title}>
        <Typography variant='h3' className={classes.header2} align='center'>
          {t('dialog:onboarding.createteam')}
        </Typography>
        <Typography variant='h6' className={classes.pre} align='center'>
          {t('dialog:onboarding.createteamtext')}
        </Typography>
      </div>
      <FlexSpacer flexAlignItems='center' className={classes.team} spacing={2}>
        <FlexSpacer orientation='vertical'>
          <AvatarWithLetterFallback
          name={teamName}
          src={teamProfilePicture ? URL.createObjectURL(teamProfilePicture) : team?.picture || placeholder}
          size='xl'
          />
          <HiddenFileInput
          id={TEAM_PIC}
          accept='image/*'
          trigger={(
            <Button
            component='label'
            disabled={loading}
            htmlFor={TEAM_PIC}
            variant='outlined'
            startIcon={<ArrowUpwardIcon/>}
            fullWidth={true}
            >
              {t('common:upload')}
            </Button>
          )}
          onChange={handleFileChange}
          />
        </FlexSpacer>
        <TextField
        name='teamName'
        placeholder={t('dialog:onboarding.myfirstteam')}
        value={teamName}
        onChange={handleTeamNameOnChange}
        disabled={loading}
        className={classes.teamName}
        />
      </FlexSpacer>
      <Typography variant='caption' className={classes.skip} onClick={onClose}>
        {t('dialog:onboarding.skip')}
      </Typography>
      <GuidedSetupActions
      step={step}
      stepBackward={stepBackward}
      stepForward={onNextClick}
      loading={loading}
      />
    </div>
  );
}

export default React.memo(FirstTeam);
