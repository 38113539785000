import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, fade, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Color } from 'csstype';
import React, { useCallback } from 'react';
import { useAutoControlled } from 'react-auto-controlled';

export interface CircleColorPickerOwnProps {
  colors: Color[];
  value?: Color;
  defaultValue?: Color;
  onColorChanged?: (c: Color) => void;
}

type Props = CircleColorPickerOwnProps;

function CircleColorPicker(props: Props) {
  const { colors, onColorChanged } = props;
  const [ value,, trySetValue, getDerivedValueFromProps ] = useAutoControlled(colors[0], {
    prop: props.value,
    defaultProp: props.defaultValue,
  });
  getDerivedValueFromProps();

  const handleColorChanged = useCallback((color: Color) => {
    trySetValue(color);
    onColorChanged?.(color);
  }, [onColorChanged, trySetValue]);

  const renderCircle = useCallback((color: Color, i: number) => {
    return (
      <Circle
      key={color}
      color={color}
      selected={color === value}
      onColorChanged={handleColorChanged}
      />
    );
  }, [handleColorChanged, value]);

  return (
    <FlexSpacer>
      {colors.map(renderCircle)}
    </FlexSpacer>
  );
}

interface CircleProps {
  className?: string;
  color: Color;
  selected?: boolean;
  onColorChanged: (c: Color) => void;
}

const useCircleStyles = makeStyles((theme: Theme) => createStyles({
  root: ({color}: CircleProps) => ({
    backgroundColor: fade(color, .75),
    border: '2px solid',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: color,
    },
    '&$selected': {
      backgroundColor: color,
      borderColor: theme.palette.text.primary,
    },
  }),
  selected: () => ({}),
}), {name: 'Circle'});

function Circle(props: CircleProps) {
  const { color, onColorChanged, selected } = props;
  const classes = useCircleStyles(props);
  const onClick = useCallback(() => {
    onColorChanged(color);
  }, [color, onColorChanged]);
  return (
    <IconButton className={classNames(classes.root, {
      [classes.selected]: selected,
    })}
    onClick={onClick}
    />
  );
}

export default React.memo(CircleColorPicker);
