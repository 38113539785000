import Dialog from '@material-ui/core/Dialog';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { useDialogState } from 'hooks/useDialogState';
import UploadDialogContent from 'material/dialogs/upload-dialog-content/UploadDialogContent';
import React, { useCallback, useState } from 'react';
import { ID } from 'types/pigeon';

export function useFileDropUploader(teamId?: ID) {
  const [targetDirectoryId, setTargetDirectoryId] = useState<ID>();
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const [isUploadVideoDialogOpen, openUploadVideoDialog, closeUploadVideoDialog] = useDialogState();

  const handleFilesDroppedOnDirectory = useCallback((directory: DirectoryFragment, acceptedFiles: File[]) => {
    setTargetDirectoryId(directory.id);
    setAcceptedFiles(acceptedFiles);
    openUploadVideoDialog();
  }, [openUploadVideoDialog]);

  if (!teamId) {
    return {};
  }

  const uploadDialog = targetDirectoryId && (
    <Dialog open={isUploadVideoDialogOpen} onClose={closeUploadVideoDialog} fullWidth={true}>
      <UploadDialogContent
      teamId={teamId}
      directoryId={targetDirectoryId}
      droppedFiles={acceptedFiles}
      onClose={closeUploadVideoDialog}
      />
    </Dialog>
  );

  return {
    handleFilesDroppedOnDirectory,
    uploadDialog,
  } as const;
}
