import { createRemFromPx } from '@insights-gaming/theme';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { desktop, mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

import UploadThingsListItem from './PendingUploadsListItem';
import { PendingUpload, PendingUploadChanges } from './types';

interface UploadThingsListOwnProps {
  items: PendingUpload[];
  onRemove: (uuid: string) => void;
  onChange: (thingToChange: PendingUploadChanges) => void;
  isOverLimit: boolean;
}

type Props = UploadThingsListOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  thingList: {
    display: 'grid',
    [desktop(theme)]: {
      gridTemplateColumns: '1fr repeat(3, 75px)',
    },
    [mobilePortrait(theme)]: {
      gridTemplateColumns: '1fr repeat(3, 50px)',
    },
    [mobileLandscape(theme)]: {
      gridTemplateColumns: '1fr repeat(3, 50px)',
    },
  },
  privacyText: {
    textAlign: 'center',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    maxHeight: createRemFromPx(190),
    overflowY: 'auto',
  },
}), {name: 'PendingUploadsList'});

function PendingUploadsList(props: Props) {
  const { onChange, items, onRemove, isOverLimit } = props;
  const classes = useStyles(props);
  const { t } = useStrictTranslation(['dashboard']);

  return items.length > 0 ? (
    <div>
      <div className={classes.thingList}>
        <div/>
        <div className={classes.privacyText}>{t('dashboard:teamaccess')}</div>
        <div className={classes.privacyText}>{t('dashboard:public')}</div>
      </div>
      <div className={classNames(classes.flex, {[classes.container]: items.length > 5})}>
        {items.map((item, index) => (
          <UploadThingsListItem
          item={item}
          key={item.uuid}
          onRemove={onRemove}
          onChange={onChange}
          isOverLimit={index === items.length - 1 && isOverLimit}
          />
        ))}
      </div>
    </div>
  ) : null;
}

export default React.memo(PendingUploadsList);
