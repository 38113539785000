import { Loader } from '@insights-gaming/material-components';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { InsightsLogo } from 'assets/logos';
import BackgroundImage from 'components/background-image/BackgroundImage';
import { mobilePortrait } from 'features/media-queries';
import { isTruthy } from 'helpers';
import { useNavigate } from 'hooks/useNavigate';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import Message from 'material/message/Message';
import React, { useCallback, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router';

import { useIsMobilePortrait } from '../../features/media-queries/hooks';
import { useAuthCheck } from '../../hooks/useAuthCheck';
import TransparentDialog from '../../material/transparent-dialog/TransparentDialog';
import { dashboardRoute, FORGOT_PASSWORD_PATH, invitationCodeRoute, REGISTER_EMAIL_PATH, REGISTER_PATH, REGISTER_VERIFY_PATH } from '../../routes';
import ForgotPasswordDialogContent from './ForgotPasswordDialogContent';
import LoginDialogContent from './LoginDialogContent';
import { LoginDialogContextProvider } from './LoginDialogContext';
import SidebarInformation from './SidebarInformation';
import SignupDialogContent from './SignupDialogContent';
import SignupFormDialogContent from './SignupFormDialogContent';
import SignupVerifyDialogContent from './SignupVerifyDialogContent';

export interface ILoginOwnProps {}

type Props = ILoginOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    [mobilePortrait(theme)]: {
      overflow: 'hidden',
    },
  },
  content: {
    alignSelf: 'center',
    background: theme.palette.background.dialog,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
  },
  inner: {
    margin: theme.spacing(3),
    padding: theme.spacing(0, 3),
    [mobilePortrait(theme)]: {
      width: 'unset',
      margin: theme.spacing(1),
      padding: theme.spacing(0, 1),
    },
  },
  paper: {
    background: 'transparent',
    height: '100%',
    flex: 1,
    maxWidth: 'initial',
    boxShadow: 'none',
  },
}), {name: 'Login'});

function Login(props: Props) {
  const classes = useStyles(props);
  const { isLoggedIn, isPastDelay } = useAuthCheck();
  const location = useLocation<{
    redirectPath?: string;
    referPath?: string;
    code?: string;
    reload?: string;
    verified?: boolean;
    referrer?: string;
  }>();
  const { t } = useStrictTranslation('login');
  const onNavigate = useNavigate();

  const isMobile = useIsMobilePortrait();

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const redirectReload = useMemo(
    () => searchParams.get('reload') === '1' || location.state?.reload === '1',
    [location.state?.reload, searchParams],
  );

  const code = useMemo(() => location.state?.code, [location.state]);

  const referPath: string | undefined = useMemo(() => {
    if (code) {
      return invitationCodeRoute(code);
    }

    return searchParams.get('referPath') || location.state?.redirectPath || location.state?.referPath;
  }, [code, location.state?.redirectPath, location.state?.referPath, searchParams]);

  // NOTE: referrer is the id of the user that referred the user to the login page
  const referrer = useMemo(
    () => searchParams.get('referrer') ?? location.state?.referrer,
    [location.state, searchParams],
  );

  const loginState = useMemo(() => Object.fromEntries<string>([
    !!referPath && ['referPath', referPath] as const,
    redirectReload && ['reload', '1'] as const,
    !!referrer && ['referrer', referrer] as const,
  ].filter(isTruthy)), [redirectReload, referPath, referrer]);

  const loginDialogContentValue = useMemo(() => ({
    loginState,
  }), [loginState]);

  const isRegisterRoute = !!useRouteMatch(REGISTER_PATH);
  const isRegisterFormRoute = !!useRouteMatch(REGISTER_EMAIL_PATH);

  const logoOnClick = useCallback(() => {
    window.location.href = '/';
  }, []);

  if (isLoggedIn) {
    if (redirectReload && referPath) {
      // HACK: this is probably the worst place to put this
      window.location.href = referPath;
    }

    return <Redirect to={referPath || dashboardRoute()} />;
  }

  if (isLoggedIn !== false) {
    if (!isPastDelay) {
      return null;
    }

    return (
      <TransparentDialog open={true}>
        <Loader />
      </TransparentDialog>
    );
  }

  return (
    <BackgroundImage className={classes.root}>
      <Dialog open={true} BackdropProps={{transitionDuration: 0}} fullWidth={isMobile} classes={{paper: classes.paper}}>
        <InsightsLogo onClick={logoOnClick} style={{ cursor: 'pointer' }}/>
        <div className={classes.container}>
          <Box className={classes.content}>
            <Box className={classes.inner}>
              {location.state?.verified && (
                <DialogContent>
                  <Message
                  variant='info'
                  message={t('emailverified')}
                  IconOverride={CheckCircleIcon}
                  />
                </DialogContent>
              )}
              <LoginDialogContextProvider value={loginDialogContentValue}>
                <Switch>
                  <Route path={REGISTER_PATH} component={SignupDialogContent} />
                  <Route path={REGISTER_EMAIL_PATH} component={SignupFormDialogContent} />
                  <Route path={REGISTER_VERIFY_PATH} component={SignupVerifyDialogContent} />
                  <Route path={FORGOT_PASSWORD_PATH} component={ForgotPasswordDialogContent} />
                  <Route component={LoginDialogContent} />
                </Switch>
              </LoginDialogContextProvider>
            </Box>
            {(isRegisterRoute || isRegisterFormRoute) && !isMobile && <SidebarInformation />}
          </Box>
        </div>
      </Dialog>
    </BackgroundImage>
  );
}

export default React.memo(Login);
