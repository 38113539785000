import { Loader } from '@insights-gaming/material-components';
import { createRemFromPx } from '@insights-gaming/theme';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import UnalignedContainer from 'components/unaligned-container/UnalignedContainer';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import React, { Suspense, useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Route, Switch, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { teamCustomQueries } from '../../../../helpers';
import { useLazyFetchTeamRosters } from '../../../../hooks/fetcher/useLazyFetchTeamRosters';
import { useCreateSelector } from '../../../../hooks/useCreateSelector';
import { useNavigate } from '../../../../hooks/useNavigate';
import { OwstatsF, OwstatsNS } from '../../../../locales/en/owstats';
import SecondaryTab from '../../../../material/secondary-tabs/SecondaryTab';
import SecondaryTabs from '../../../../material/secondary-tabs/SecondaryTabs';
import {
  buildDashboardRoute,
  ETeamCustomQueriesTabType,
  ETeamStatsTabType,
  TEAM_CREATE_CUSTOM_QUERIES_PATH,
  teamCustomQueriesRoute,
  teamOverwatchStatsRoute,
} from '../../../../routes';
import { makeGetTeamPrimaryRosterId } from '../../../../selectors/statistic';
import SuspenseLoader from '../../../../subcomponents/suspense-loader/SuspenseLoader';
import CreateQuery from './create-query/CreateQuery';

export interface IOverwatchCustomQueriesOwnProps {
  team: TeamFragment;
}

type Props = IOverwatchCustomQueriesOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flex: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(3),
    display: 'flex',
    [mobilePortrait(theme)]: {
      flexDirection: 'column',
    },
    '&$dense': {
      marginTop: 0,
    },
  },
  navigation: {
    padding: theme.spacing(0, 4),
    width: createRemFromPx(300),
    [mobilePortrait(theme)]: {
      width: '100%',
      padding: 0,
    },
  },
  content: {
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '75%',
    [mobilePortrait(theme)]: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  },
  fullWidthContent: {
    flex: 1,
  },
  queryTab: {
    overflowY: 'hidden',
  },
}), {name: 'OverwatchCustomQueries'});

function OverwatchCustomQueries(props: Props) {
  const { team } = props;
  const teamId = team.id;

  const accessControl = useAccessControl();

  const { queriesTab } = useParams<{ queriesTab?: string }>();
  const onNavigate = useNavigate();
  const classes = useStyles(props);
  const { t } = useTranslation(OwstatsNS);
  const verticalMode = useIsDesktop();

  useEffect(() => {
    if (!accessControl.canRequestOverwatchAnalysis) {
      onNavigate(buildDashboardRoute());
    }
  }, [onNavigate, accessControl.canRequestOverwatchAnalysis]);

  useEffect(() => {
    if (!queriesTab) {
      onNavigate(teamCustomQueriesRoute(teamId, ETeamCustomQueriesTabType.CREATE_QUERY));
    }
  }, [teamId, queriesTab, onNavigate]);

  const { fetch, more, loading, error } = useLazyFetchTeamRosters({teamId});

  const primaryRosterId = useCreateSelector(makeGetTeamPrimaryRosterId, teamId);

  useEffect(() => {
    if (more) {
      fetch();
    }
  }, [more, fetch]);

  const handleTabClick = useCallback((e: React.SyntheticEvent, tab: ETeamCustomQueriesTabType) => {
    onNavigate(teamCustomQueriesRoute(teamId, tab));
  }, [onNavigate, teamId]);

  const renderTab = useCallback((e: ETeamCustomQueriesTabType) => {
    return (
      <SecondaryTab
      key={e}
      value={e}
      label={t(OwstatsF(teamCustomQueries(e)))}
      id={e}
      />
    );
  }, [t]);

  const renderTabs = useCallback((vertical?: boolean) => {
    const orientation = vertical ? 'vertical' : 'horizontal';
    return (
      <SecondaryTabs
      indicatorColor='primary'
      orientation={orientation}
      value={ETeamCustomQueriesTabType.CREATE_QUERY}
      onChange={handleTabClick}
      >
        {Object.keys(ETeamCustomQueriesTabType)
          .map((k: string) => ETeamCustomQueriesTabType[k])
          .map(renderTab)}
      </SecondaryTabs>
    );
  }, [renderTab, handleTabClick]);

  const renderCreateQuery = useCallback(() => {
    if (loading || more) { return <Loader />; }
    if (!primaryRosterId) { return (
      <Trans ns={OwstatsNS} i18nKey={OwstatsF('norosterselected')}>
        No roster is selected, please select a roster
        <Link to={teamOverwatchStatsRoute(teamId, ETeamStatsTabType.SELECT_ROSTER)}>here</Link>.
      </Trans>
    ); }
    return (
      <CreateQuery teamId={teamId} rosterId={primaryRosterId}/>
    );
  }, [primaryRosterId, teamId, loading, more]);

  return (
    <div className={classNames(classes.root, classes.queryTab)}>
      <div className={classes.navigation}>
        {renderTabs(verticalMode)}
      </div>
      <div className={classNames(classes.content, classes.fullWidthContent)}>
        <UnalignedContainer maxWidth='lg'>
          <Suspense fallback={<SuspenseLoader delay={200} />}>
            <Switch>
              <Route route={TEAM_CREATE_CUSTOM_QUERIES_PATH} component={renderCreateQuery} />
            </Switch>
          </Suspense>
        </UnalignedContainer>
      </div>
    </div>
  );
}

export default React.memo(OverwatchCustomQueries);
