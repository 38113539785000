import { createAbsoluteUrl } from 'helpers/routing';
import { ID } from 'types/pigeon';

enum Game {
  OVERWATCH = 'overwatch',
  SSBU = 'ssbu',
}

enum Tab {
  PLAYERS = 'players',
  TEAMCOMPS = 'teamcomps',
  MAPS = 'maps',
  SELECT_ROSTER = 'selectroster',
  CUSTOM_QUERIES = 'customqueries',
}

type GameType = Game;
type TabType = Tab;

const createGameStatisticsPath = (teamid: string, game: string, tab?: string) => {
  return createAbsoluteUrl('dashboard', 'team', teamid, 'games', game, 'statistics', tab);
};
const createGameStatisticsTabPath = (game: string, tab?: string) => {
  return createGameStatisticsPath(':teamid', game, tab);
};
const path = createGameStatisticsTabPath(':game', ':tab?');
const playersPath = createGameStatisticsTabPath(':game', Tab.PLAYERS);
const teamcompsPath = createGameStatisticsTabPath(':game', Tab.TEAMCOMPS);
const mapsPath = createGameStatisticsTabPath(':game', Tab.MAPS);
const selectRosterPath = createGameStatisticsTabPath(':game', Tab.SELECT_ROSTER);
const customQueriesPath = createGameStatisticsTabPath(':game', Tab.CUSTOM_QUERIES);

const createUrl = (teamId: ID, game: Game, tab?: Tab): string => {
  return createGameStatisticsPath(teamId, game, tab);
};

const DashboardTeamStatisticsRouting = {
  Game,
  Tab,
  path,
  playersPath,
  teamcompsPath,
  mapsPath,
  selectRosterPath,
  customQueriesPath,
  createUrl,
};

// eslint-disable-next-line
declare namespace DashboardTeamStatisticsRouting {
  interface Params {
    teamid: ID;
    game: Game;
    tab?: Tab;
  }
  type Game = GameType;
  type Tab = TabType;
}

export default DashboardTeamStatisticsRouting;
