import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import { INSIGHTS_CAPTURE_DOWNLOAD, oauthEndpoint, OAuthProvider } from 'constants/index';
import { createUTMParams } from 'helpers/createUTMParams';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { REGISTER_EMAIL_PATH, SIGNIN_PATH } from 'routes';
import DiscordButton from 'subcomponents/discord-button/DiscordButton';

import GoogleButton from './content/GoogleButton';
import { LoginDialogContext } from './LoginDialogContext';
import SignupDialogContentLayout from './SignupDialogContentLayout';

export interface SignupDialogContentOwnProps {}

export type SignupDialogContentProps = SignupDialogContentOwnProps;

const signUpUTM = '&' + createUTMParams('insightsgg', 'signup', 'ic_download');

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  thirdPartyAuthButton: {
    margin: theme.spacing(1, 0),
  },
  dividerWithText: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    '&::after, &::before': {
      content: '""',
      border: `1px solid ${theme.palette.background.note}`,
      flex: 1,
    },
    '&:not(:empty)::before': {
      marginRight: theme.spacing(1),
    },
    '&:not(:empty)::after': {
      marginLeft: theme.spacing(1),
    },
  },
  link: {
    textDecoration: 'none',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  readyToRecordText: {
    fontSize: createRemFromPx(16),
    margin: theme.spacing(1, 0),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  anchor: {
    marginLeft: theme.spacing(1),
  },
}), {name: 'SignupDialogContent'});

function SignupDialogContent(props: SignupDialogContentProps) {
  const classes = useStyles(props);

  const { t } = useStrictTranslation(['common', 'login']);

  const { loginState } = useContext(LoginDialogContext);

  const handleOAuthClick = useCallback((provider: OAuthProvider) => {
    window.location.href = oauthEndpoint(provider, loginState);
  }, [loginState]);

  const handleGoogleOAuthClick = useCallback(() => handleOAuthClick('google-oauth2'), [handleOAuthClick]);

  const handleDiscordOAuthClick = useCallback(() => handleOAuthClick('discordapp'), [handleOAuthClick]);

  return (
    <SignupDialogContentLayout
    className={classes.root}
    >
      <Box my={2}>
        <Box className={classes.thirdPartyAuthButton}>
          <GoogleButton
          type='light'
          onClick={handleGoogleOAuthClick}
          label={t('login:signupwithgoogle')}
          />
        </Box>
        <Box className={classes.thirdPartyAuthButton}>
          <DiscordButton
          onClick={handleDiscordOAuthClick}
          >
            {t('login:signupwithdiscord')}
          </DiscordButton>
        </Box>
        <Box my={2}>
          <div className={classes.dividerWithText}>{t('common:conjunction.or')}</div>
        </Box>
        <Link
        to={{ pathname: REGISTER_EMAIL_PATH, state: loginState }}
        className={classes.link}
        >
          <Button
          type='button'
          fullWidth={true}
          variant='contained'
          color='primary'
          >
            {t('login:signupwithemail')}
          </Button>
        </Link>
      </Box>
      <Box className={classes.flex} mt={2} mb={4}>
        <span>{t('login:haveaccount')}</span>
        <Link
        to={{ pathname: SIGNIN_PATH, state: loginState }}
        className={classes.link}
        >
          <Button className={classes.button}>
            {t('login:login')}
          </Button>
        </Link>
      </Box>
      <Divider variant='fullWidth' />
      <Box className={classes.flex}>
        <Typography className={classes.readyToRecordText}>
          {t('login:readytorecord')}
        </Typography>
        <a
        className={classes.anchor}
        href={INSIGHTS_CAPTURE_DOWNLOAD.url+signUpUTM}
        download={INSIGHTS_CAPTURE_DOWNLOAD.name}
        >
          {t('login:download')}
        </a>
      </Box>
    </SignupDialogContentLayout>
  );
}

export default React.memo(SignupDialogContent);
