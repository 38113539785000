import { AddTeamCard_Mutation, CancelTeamSubscription_Mutation, CreateTeamSubscription_Mutation, GiftTeamCredits_Mutation, RemoveTeamCard_Mutation, RetryTeamSubscription_Mutation, UpdateTeamCard_Mutation, UpdateTeamSubscription_Mutation } from 'apollo/mutations';
import { GetAvailableBundles_Query, GetAvailableCoupon_Query, GetBundlesByIds_Query, GetPreviewProration_Query, GetPricesByIds_Query, GetTeamBalance_Query, GetTeamCard_Query, GetTeamCards_Query, GetTeamInvoices_Query, GetTeamSubscription_Query, GetTeamSubscriptionDetail_Query } from 'apollo/queries';
import { watchAsyncMutation, watchAsyncQuery, watchBidirectionalAC2 } from 'helpers/saga/effects';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { makeGetTeamInvoiceRecordsByTeamId } from './dashboard-billing.selectors';
import { addTeamCardAsyncAC, cancelTeamSubscriptionAC, clearTeamBillingStateAC, createTeamSubscriptionAC, fetchAvailableBundlesAC, fetchAvailableCouponAC, fetchBundlesByIdsAC, fetchPricesByIdsAC, fetchTeamBalanceAC, fetchTeamCardAC, fetchTeamCardsAC, fetchTeamInvoicesAC, fetchTeamProrationAC, fetchTeamSubscriptionAC, fetchTeamSubscriptionDetailAC, giftTeamCreditsAC, removeTeamCardAsyncAC, retryTeamSubscriptionAC, updateTeamCardAsyncAC, updateTeamSubscriptionAC } from './dashboard-billing-slice';

export default function* billingSaga() {
  yield all([
    watchBidirectionalAC2(fetchTeamInvoicesAC, GetTeamInvoices_Query, ['team', 'queryInvoices']),
    watchAsyncQuery(fetchTeamBalanceAC, GetTeamBalance_Query, ['getTeamBalance'], true),
    watchAsyncQuery(fetchTeamProrationAC, GetPreviewProration_Query, ['previewProration']),
    watchAsyncQuery(fetchTeamCardAC, GetTeamCard_Query, ['getTeamCard', 'card']),
    watchAsyncQuery(fetchTeamSubscriptionDetailAC, GetTeamSubscriptionDetail_Query, ['getTeamSubscriptionDetail']),
    watchAsyncQuery(fetchPricesByIdsAC, GetPricesByIds_Query, ['prices']),
    watchAsyncQuery(fetchAvailableBundlesAC, GetAvailableBundles_Query, ['availableBundles']),
    watchAsyncQuery(fetchTeamSubscriptionAC, GetTeamSubscription_Query, ['team', 'teamSubscription'], true),
    watchAsyncQuery(fetchBundlesByIdsAC, GetBundlesByIds_Query, ['bundles']),
    watchAsyncQuery(fetchTeamCardsAC, GetTeamCards_Query, ['team', 'queryCards', 'cards']),
    watchAsyncQuery(fetchAvailableCouponAC, GetAvailableCoupon_Query, ['coupon']),
    watchAsyncMutation(createTeamSubscriptionAC, CreateTeamSubscription_Mutation, ['createTeamSubscription']),
    watchAsyncMutation(updateTeamSubscriptionAC, UpdateTeamSubscription_Mutation, ['updateTeamSubscription']),
    watchAsyncMutation(cancelTeamSubscriptionAC, CancelTeamSubscription_Mutation, ['cancelTeamSubscription']),
    watchAsyncMutation(retryTeamSubscriptionAC, RetryTeamSubscription_Mutation, ['retryTeamSubscription']),
    watchAsyncMutation(addTeamCardAsyncAC, AddTeamCard_Mutation, ['addTeamCard']),
    watchAsyncMutation(giftTeamCreditsAC, GiftTeamCredits_Mutation, ['giftTeamCredits']),
    watchAsyncMutation(removeTeamCardAsyncAC, RemoveTeamCard_Mutation, ['removeTeamCard']),
    watchAsyncMutation(updateTeamCardAsyncAC, UpdateTeamCard_Mutation, ['updateTeamCard']),

    takeEvery(clearTeamBillingStateAC, function* ({ payload: { teamId } }) {
    }),

    takeEvery(cancelTeamSubscriptionAC.done, function* ({ payload: { params: { teamId } } }) {
      yield put(clearTeamBillingStateAC({ teamId }));
    }),

    takeEvery([
      createTeamSubscriptionAC.done,
      updateTeamSubscriptionAC.done,
      retryTeamSubscriptionAC.done,
    ], function* ({ payload: { params: { teamId }, result } }) {
      if (result.invoiceStatus === 'requires_action') {
        return;
      }

      yield put(clearTeamBillingStateAC({ teamId }));
    }),

    takeEvery(updateTeamSubscriptionAC.done, function* ({ payload: { params: { teamId } } }) {
      try {
        //  backward fetch to get the newly created subscription.
        const selector: ReturnType<typeof makeGetTeamInvoiceRecordsByTeamId> = yield call(
          makeGetTeamInvoiceRecordsByTeamId,
        );
        const teamInvoiceRecords: ReturnType<typeof selector> = yield select(selector, teamId);
        const cursor = teamInvoiceRecords?.backward?.cursor;
        if (cursor) {
          yield put(fetchTeamInvoicesAC.backward.started({ teamId, before: cursor, limit: 2 }));
        }
      } catch {
        // do nothing
      }
    }),

    takeEvery(addTeamCardAsyncAC.done, function* ({ payload: { params: { teamId } } }) {
      try {
        yield put(fetchTeamCardsAC.started({ teamId }));
      } catch {
        // do nothing
      }
    }),
  ]);
}
