import PageNotFound from 'containers/PageNotFound';
import { selectTeamAsyncAC } from 'features/dashboard/team/dashboard-team-slice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ID } from 'types/pigeon';

import AccessControlInjector from './access-control/AccessControlInjector';
import Dashboard from './Dashboard';
import { useFetchDirectoryIfNecessary } from './directory/useFetchDirectoryIfNecessary';

const directoryPath = '/dashboard/folder/:directoryid/:page?';
const teamPath = '/dashboard/team/:teamid';
const paths = [directoryPath, teamPath];

const createDirectoryUrl = (directoryId: ID) => `/dashboard/folder/${directoryId}`;
const createTeamUrl = (teamId: ID) => `/dashboard/team/${teamId}`;

function Component() {
  const dispatch = useDispatch();
  const { directoryid, teamid } = useParams<DashboardRouting.Params>();
  const [ directory, sharedDirectory, directoryStatus ] = useFetchDirectoryIfNecessary(directoryid);
  const teamId = directory?.team.id || teamid || sharedDirectory?.team.id;

  useEffect(() => {
    dispatch(selectTeamAsyncAC.started(teamId));
  }, [dispatch, teamId]);

  const inner = directoryid ? (
    <Dashboard directoryId={directoryid} />
  ) : teamId ? (
    <Dashboard teamId={teamId} />
  ) : (directoryStatus?.error) ? (
    <PageNotFound/> // TODO: show can't access component
  ) : null;

  return (
    <AccessControlInjector
    teamId={teamId}
    divisionId={sharedDirectory?.id}
    >
      {inner}
    </AccessControlInjector>
  );
}

const DashboardRouting = {
  directoryPath,
  teamPath,
  paths,
  createDirectoryUrl,
  createTeamUrl,
  Component,
};

// eslint-disable-next-line
declare namespace DashboardRouting {
  interface Params {
    directoryid?: ID;
    teamid?: ID;
  }
}

export default DashboardRouting;
