import gql from 'graphql-tag';

import { TagFragment } from '../fragments';

export const UpdateTag_Mutation = gql`
    mutation UpdateTagMutation(
        $input: UpdateTagInput!
    ) {
        updateTag(input: $input) {
            tag {
                ...TagFragment
            }
        }
    }
    ${TagFragment}
`;
