import gql from 'graphql-tag';

export const UpdateStats_Mutation = gql`
    mutation UpdateStatsMutation(
        $input: UpdateStatsInput!
    ) {
        updateStats(
            input: $input
        ) {
            requested
        }
    }
`;
