import { fetchOverwatchPrimaryRosterTeamFightCompositionStatisticsAsyncAC } from 'features/statistics/statistics-slice';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootReducer } from 'rootReducer';

import { GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2QueryVariables } from '../apollo/queries/types/GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query';
import OverwatchPrimaryRosterTeamFightCompositionStatistics, {
  IOverwatchPrimaryRosterTeamFightCompositionStatisticsDispatch,
  IOverwatchPrimaryRosterTeamFightCompositionStatisticsMappedProps,
  IOverwatchPrimaryRosterTeamFightCompositionStatisticsOwnProps,
} from '../components/dashboard/team-statistics/overwatch-primary-roster-team-fight-composition-statistics/OverwatchPrimaryRosterTeamFightCompositionStatistics';

function mapStateToProps(
  state: RootReducer,
  props: IOverwatchPrimaryRosterTeamFightCompositionStatisticsOwnProps,
): IOverwatchPrimaryRosterTeamFightCompositionStatisticsMappedProps {
  return {
    primaryRosterTeamFightCompositionStatisticsCache:
      state.statisticsReducer.teamFightCompositionStatistics.cache[props.team.id],
    primaryRosterTeamFightCompositionStatisticsError:
      state.statisticsReducer.teamFightCompositionStatistics.error,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IOverwatchPrimaryRosterTeamFightCompositionStatisticsDispatch {
  return {
    onFetchOverwatchPrimaryRosterTeamFightCompositionStatistics: (
      params: GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2QueryVariables,
    ) => dispatch(fetchOverwatchPrimaryRosterTeamFightCompositionStatisticsAsyncAC.started(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OverwatchPrimaryRosterTeamFightCompositionStatistics);
