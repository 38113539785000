import { UploadQueueControlProps } from '@insights-gaming/upload-queue';
import sum from 'lodash/sum';
import React, { forwardRef, Ref, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUploadState } from '../upload-selector';


export function withUploadQueueControl<P extends UploadQueueControlProps>(
  Component: React.ComponentType<P>,
) {
  const WithUploadQueueControl = forwardRef(function WithUploadQueueControl(
    props: P,
    forwardedRef: Ref<HTMLElement | null>,
  ) {

    const { currentUpload, uploadQueue } = useSelector(getUploadState);

    const video = currentUpload?.video;

    const sumOfQueuedFilesizes = useMemo(() => sum(uploadQueue.map(({ video }) => video.file.size)), [uploadQueue]);

    const currentUploadMetadata = useMemo(() => {
      return video
        ? {
          name: video.file.name,
          size: video.file.size,
        }
        : undefined;
    }, [video]);

    return (
      <Component
      ref={forwardedRef}
      {...props}
      currentUploadMetadata={currentUploadMetadata}
      sumOfQueuedFilesizes={sumOfQueuedFilesizes}
      />
    );
  });
  WithUploadQueueControl.displayName = `WithUploadQueueControl(${Component.displayName || ''})`;

  return WithUploadQueueControl;
}
