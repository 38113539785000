import gql from 'graphql-tag';

export const ResetStats_Mutation = gql`
    mutation ResetStatsMutation(
        $input: ResetStatsInput!
    ) {
        resetStats(input: $input) {
            requested
        }
    }
`;
