import getResourceRemaining from 'features/dashboard/billing/getResourceRemaining';
import useTeamAvailableResource from 'features/dashboard/billing/useTeamAvailableResource';
import sum from 'lodash/sum';
import { useMemo } from 'react';
import { TeamResource } from 'types/graphql';
import { ID } from 'types/pigeon';

import { FilePendingUpload, PendingUpload, UnprobedFilePendingUpload } from './types';

export function useCheckResourceLimit(
  teamId: ID,
  items: PendingUpload[],
  override?: boolean,
) {
  const resources = useTeamAvailableResource(teamId);
  return {
    isOverLimit: useMemo(() => {
      if (!resources || override) {
        return false;
      }

      const required = sum(
        items.filter((thing): thing is FilePendingUpload => thing.type === 'file')
          .map(({ duration }) => duration),
      );

      const requiredBytes = sum(
        items.filter((thing): thing is FilePendingUpload | UnprobedFilePendingUpload => thing.type === 'file')
          .map(({ file: { size } }) => size),
      );

      if (resources[TeamResource.VIDEO_STORAGE]) {
        return (
          getResourceRemaining(resources[TeamResource.VIDEO_UPLOAD]) < required ||
          getResourceRemaining(resources[TeamResource.VIDEO_STORAGE]) < required
        );
      }

      return (
        getResourceRemaining(resources[TeamResource.VIDEO_UPLOAD]) < required ||
        getResourceRemaining(resources[TeamResource.VIDEO_STORAGE_BYTES]) < requiredBytes
      );
    }, [resources, override, items]),
  };
}
