import { Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import FavoriteIcon from '@material-ui/icons/Favorite';
import classNames from 'classnames';
import { EIntercomID } from 'constants/strings';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useDialogState } from 'hooks/useDialogState';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

import FeedbackConfirmationDialog from './feedback-dialog/FeedbackConfirmationDialog';
import FeedbackDialog from './feedback-dialog/FeedbackDialog';

interface FeedbackButtonOwnProps {
  className?: string;
}

type FeedbackButtonProps = FeedbackButtonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'FeedbackButton'});

function FeedbackButton(props: FeedbackButtonProps) {
  const classes = useStyles(props);
  const { className } = props;
  const { t } = useStrictTranslation(['common']);
  const isDesktop = useIsDesktop();
  const [
    isFeedbackDialogOpen,
    openFeedbackDialog,
    closeFeedbackDialog,
  ] = useDialogState();

  const [
    isFeedbackConfirmationDialogOpen,
    openFeedbackConfirmationDialog,
    closeFeedbackConfirmationDialog,
  ] = useDialogState();

  return (
    <React.Fragment>
      {isDesktop ? (
        <div className={classNames(classes.root, className)}>
          <Tooltip title={t('common:feedback')}>
            <IconButton onClick={openFeedbackDialog} id={EIntercomID.FEEDBACK}>
              <FavoriteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ) : (
        <MenuItem onClick={openFeedbackDialog}>
          <ListItemIcon>
            <FavoriteIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:feedback')}
          </ListItemText>
        </MenuItem>
      )}
      <FeedbackDialog
      open={isFeedbackDialogOpen}
      onClose={closeFeedbackDialog}
      openConfirmation={openFeedbackConfirmationDialog}
      />
      <FeedbackConfirmationDialog open={isFeedbackConfirmationDialogOpen} onClose={closeFeedbackConfirmationDialog} />
    </React.Fragment>
  );
}

export default React.memo(React.forwardRef(FeedbackButton));
