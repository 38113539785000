import { AsyncButton } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React from 'react';

interface GuidedSetupActionsOwnProps {
  className?: string;
  step: number;
  stepBackward?: VoidFunction;
  stepForward: VoidFunction;
  loading?: boolean;
}

type GuidedSetupActionsProps = GuidedSetupActionsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    bottom: 24,
    right: 24,
    padding: 0,
  },
  button: {
    width: createRemFromPx(110),
    height: createRemFromPx(36),
  },
  full: {
    width: '100%',
  },
}), {name: 'GuidedSetupActions'});

function GuidedSetupActions(props: GuidedSetupActionsProps) {
  const classes = useStyles(props);
  const { className, stepBackward, stepForward, step, loading } = props;
  const { t } = useStrictTranslation(['common']);

  return (
    step !== 6 ? (
      <DialogActions className={classes.root}>
        {step > 1 && (
          <Button onClick={stepBackward} className={classes.button} variant='outlined'>
            {t('common:back')}
          </Button>
        )}
        <AsyncButton
        onClick={stepForward}
        className={classes.button}
        buttonClasses={{root: classes.full}}
        color='primary'
        variant='contained'
        loading={loading}
        disabled={loading}
        >
          {t(step === 0 ? 'common:okay' : 'common:next')}
        </AsyncButton>
      </DialogActions>
    ) : null
  );
}

export default React.memo(GuidedSetupActions);
