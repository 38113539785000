import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { DeleteTeamDirectoryMutation_deleteDirectory } from 'apollo/mutations/dashboard/types/DeleteTeamDirectoryMutation';
import { DeleteVideoMutation_deleteVideo } from 'apollo/mutations/types/DeleteVideoMutation';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { TKeysWithNS,useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { ID } from 'types/pigeon';

import { deleteTeamDirectoryAC } from '../directory/dashboard-directory-slice';
import { deleteVideoAC } from '../video/dashboard-video-slice';

interface DeleteSelectedDialogOwnProps {
  className?: string;
  teamId: ID;
  folderIds: Set<ID>;
  videoIds: Set<ID>;
  onDelete?: VoidFunction;
  onClose: VoidFunction;
}

type DeleteSelectedDialogProps = DeleteSelectedDialogOwnProps & Pick<DialogProps, 'open'>;

function DeleteSelectedDialog(props: DeleteSelectedDialogProps) {
  const { open, onClose, onDelete, folderIds, videoIds, teamId } = props;

  const promisifiedSagaDispatch = usePromiseSagaDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useStrictTranslation(['common', 'dashboard-main']);

  const [loading, setLoading] = useState(false);

  const action = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const promises: Array<Promise<DeleteTeamDirectoryMutation_deleteDirectory | DeleteVideoMutation_deleteVideo>> = [
      ...Array.from(folderIds).map(id => promisifiedSagaDispatch(deleteTeamDirectoryAC, {id, teamId})),
      ...Array.from(videoIds).map(id => promisifiedSagaDispatch(deleteVideoAC, {id})),
    ];

    const promise = Promise.all(promises);

    try {
      await promise;
      let tKey: TKeysWithNS<'dashboard-main'>, count: number;
      if (folderIds.size && videoIds.size) {
        tKey = 'dashboard-main:delete.deletesuccess';
        count = folderIds.size + videoIds.size;
      }
      else if (videoIds.size) {
        tKey = 'dashboard-main:delete.videodeletesuccess';
        count = videoIds.size;
      }
      else if (folderIds.size) {
        tKey = 'dashboard-main:delete.folderdeletesuccess';
        count = folderIds.size;
      } else {
        return; //this should never happen
      }
      enqueueSnackbar(t(tKey, { count }), {variant: 'success'});
      onDelete?.();
      onClose();
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLoading(false);
    }

  }, [enqueueSnackbar, folderIds, loading, onClose, onDelete, promisifiedSagaDispatch, t, teamId, videoIds]);

  return (
    <Dialog fullWidth={true} open={open} onClose={onClose}>
      <AlertDialogContent
      titleText={t('dashboard-main:delete.title')}
      description={t('dashboard-main:delete.description')}
      cancel={{
        text: t('common:cancel'),
        action: onClose,
      }}
      confirm={{
        text: t('common:delete'),
        action,
        loading,
        disabled: loading,
        negative: true,
      }}
      />
    </Dialog>
  );
}

export default React.memo(DeleteSelectedDialog);
