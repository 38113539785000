import {
  NotificationFragment,
  NotificationFragment_CommentNotification,
  NotificationFragment_CommentNotification_comment,
} from 'apollo/fragments/types/NotificationFragment';
import { isExistent } from 'helpers';
import { compareNotificationByDate } from 'helpers/comparators';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { NotificationState } from 'types/graphql';
import { ID } from 'types/pigeon';

import { NotificationsHelper } from '../notification-settings/notifications-helper';
import { ENotificationState } from './constants';

const getNotificationDict = (state: RootReducer) => state.notifications.notificationsDict;

const getNotificationRecords = (state: RootReducer) => state.notifications.notificationsRecords;

const getVideoIdFromProps = (state: RootReducer, props: {videoId: ID}) =>
  props.videoId;

export const getUnreadNotifications = (state: RootReducer) =>
  state.notifications.unreadNotifications;

const getUnreadTeamNotificationsByTeamId = (state: RootReducer, teamId: ID) =>
  state.notifications.unreadTeamNotifications[teamId];

const getUnreadFolderNotificationsByFolderId = (state: RootReducer, folderId: ID) =>
  state.notifications.unreadFolderNotifications[folderId];

export const makeGetNotificaionRecords = () => createSelector(
  [getNotificationRecords],
  (records) => records,
);

export const makeGetAllNotifications = () => createSelector(
  [getNotificationRecords, getNotificationDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) { return []; }
    return ids.map(id => dict[id]).filter(isExistent);
  },
);

export const makeGetUnreadNotifications = () => createSelector(
  [getNotificationDict],
  (dict) => {
    return Object.keys(dict).map(key => dict[key])
      .filter(isExistent)
      .filter(n => n.state !== ENotificationState.READ);
  },
);

export const makeGetUnreadVideoCommentNotificationsByVideoId = () => createSelector(
  [getVideoIdFromProps, getNotificationRecords, getNotificationDict],
  (videoId, records, dict) => {
    const ids = records?.ids;
    if (!ids) { return []; }
    const notifications = ids.map(id => dict[id]).filter(isExistent);
    const filteredNotifications = notifications
      .filter(NotificationsHelper.isCommentNotification)
      .filter(n => n.video.id === videoId && n.state !== NotificationState.READ);
    return filteredNotifications;
  },
);

export const makeGetUnreadVideoCommentNotificationsLength = () => createSelector(
  [makeGetUnreadVideoCommentNotificationsByVideoId()],
  (notifications) => {
    return notifications.length;
  },
);

export const makeGetUnreadVideoUploadedNotificationByVideoId = () => createSelector(
  [getVideoIdFromProps, getNotificationRecords, getNotificationDict],
  (videoId, records, dict) => {
    const ids = records?.ids;
    if (!ids) { return undefined; }
    const videoNotification = ids.map(id => dict[id])
      .filter(isExistent)
      .filter(n => n.state !== ENotificationState.READ)
      .find(n => n.__typename === 'VideoNotification' && n.video.id === videoId);

    return videoNotification;
  },
);

export const makeGetUnreadTeamNotificationsByTeamId = () => createSelector(
  [getUnreadTeamNotificationsByTeamId],
  (notifications) => notifications,
);

export const makeGetUnreadFolderNotificationsByFolderId = () => createSelector(
  [getUnreadFolderNotificationsByFolderId],
  (notifications) => notifications,
);

function isVideoComment(c: NotificationFragment_CommentNotification_comment) {
  return c.__typename === 'VideoComment';
}

function isCommentReply(c: NotificationFragment_CommentNotification_comment) {
  return c.__typename === 'CommentReply';
}

function isCommentNotification(
  n: NotificationFragment,
): n is NotificationFragment_CommentNotification {
  return NotificationsHelper.isCommentNotification(n) && isVideoComment(n.comment);
}

function isReplyNotification(
  n: NotificationFragment,
): n is NotificationFragment_CommentNotification {
  return NotificationsHelper.isCommentNotification(n) && isCommentReply(n.comment);
}

const makeGetAggregatedCommentNotifications = () => createSelector(
  [makeGetAllNotifications()],
  (notifications) => NotificationsHelper.groupNotifications(
    notifications,
    isCommentNotification,
    (n) => n.video.id,
  ),
);

const makeGetAggregatedReplyNotifications = () => createSelector(
  [makeGetAllNotifications()],
  (notifications) => NotificationsHelper.groupNotifications(
    notifications,
    isReplyNotification,
    (n) => n.comment.__typename === 'CommentReply' ? n.comment.parent : '',
  ),
);

const makeGetAggregatedMemberNotifications = () => createSelector(
  [makeGetAllNotifications()],
  (notifications) => NotificationsHelper.groupNotifications(
    notifications,
    NotificationsHelper.isMemberNotification,
    (n) => n.team.id,
  ),
);

const makeGetAggregatedVideoNotifications = () => createSelector(
  [makeGetAllNotifications()],
  (notifications) => NotificationsHelper.groupNotifications(
    notifications,
    NotificationsHelper.isVideoNotification,
    (n) => n.team.id,
  ),
);

export const makeGetAggregatedNotifications = () => createSelector(
  [
    makeGetAggregatedCommentNotifications(),
    makeGetAggregatedReplyNotifications(),
    makeGetAggregatedMemberNotifications(),
    makeGetAggregatedVideoNotifications(),
  ],
  (commentNotifs, replyNotifs, memberNotifs, videoNotifs) => {
    const aggregatedNotifs = [...commentNotifs, ...replyNotifs, ...memberNotifs, ...videoNotifs];
    return aggregatedNotifs.sort((n1, n2) => compareNotificationByDate(n1[0], n2[0]));
  },
);

const makeGetUnreadAggregatedCommentNotifications = () => createSelector(
  [makeGetUnreadNotifications()],
  (notifications) => NotificationsHelper.groupNotifications(
    notifications,
    isCommentNotification,
    (n) => n.video.id,
  ),
);

const makeGetUnreadAggregatedReplyNotifications = () => createSelector(
  [makeGetUnreadNotifications()],
  (notifications) => NotificationsHelper.groupNotifications(
    notifications,
    isReplyNotification,
    (n) => n.comment.__typename === 'CommentReply' ? n.comment.parent : '',
  ),
);

const makeGetUnreadAggregatedMemberNotifications = () => createSelector(
  [makeGetUnreadNotifications()],
  (notifications) => NotificationsHelper.groupNotifications(
    notifications,
    NotificationsHelper.isMemberNotification,
    (n) => n.team.id,
  ),
);

const makeGetUnreadAggregatedVideoNotifications = () => createSelector(
  [makeGetUnreadNotifications()],
  (notifications) => NotificationsHelper.groupNotifications(
    notifications,
    NotificationsHelper.isVideoNotification,
    (n) => n.team.id,
  ),
);

export const makeGetUnreadAggregatedNotifications = () => createSelector(
  [
    makeGetUnreadAggregatedCommentNotifications(),
    makeGetUnreadAggregatedReplyNotifications(),
    makeGetUnreadAggregatedMemberNotifications(),
    makeGetUnreadAggregatedVideoNotifications(),
  ],
  (commentNotifs, replyNotifs, memberNotifs, videoNotifs) => {
    const aggregatedNotifs = [...commentNotifs, ...replyNotifs, ...memberNotifs, ...videoNotifs];
    return aggregatedNotifs.sort((n1, n2) => compareNotificationByDate(n1[0], n2[0]));
  },
);
