import { EventEmitter as Impl } from 'events';

export type EventMap = Record<string, any>;

export type EventKey<T extends EventMap> = Exclude<keyof T, number>;

declare const tag: unique symbol;

export type EventArgs<T extends any[]> = T & { readonly [tag]: 'event_args' };

export type EventHandler<T> = T extends EventArgs<infer Args> ? (...args: Args) => void : (params: T) => void;

export type EventEmitterMap<T extends EventEmitter<any>> = T extends EventEmitter<infer TEventMap> ? TEventMap : never;

export type EventEmitterKeys<T extends EventEmitter<any>> = EventKey<EventEmitterMap<T>>;

type EmitterCallable<T extends EventMap> = <K extends EventKey<T>>(event: K, fn: EventHandler<T[K]>) => EventEmitter<T>;

export type NormalizedEventArgs<T> = T extends EventArgs<infer Args> ? Args : T extends void ? [] : [T];

export interface EventEmitter<T extends EventMap = any> {
  emit<K extends EventKey<T>>(event: K, ...args: NormalizedEventArgs<T[K]>): boolean;
  emit(event: string | symbol, ...args: NormalizedEventArgs<T[keyof T]>): boolean;

  once: EmitterCallable<T>;

  on: EmitterCallable<T>;
  off: EmitterCallable<T>;

  addListener: EmitterCallable<T>;
  removeListener: EmitterCallable<T>;
}

export const EventEmitter = Impl as new <
  T extends EventMap
>() => EventEmitter<T>;
