import { createAbsoluteUrl } from 'helpers/routing';
import { ID } from 'types/pigeon';

enum BillingTab {
  OVERVIEW = 'overview',
  PAYMENTMETHOD = 'paymentmethod',
  PAYMENTHISTORY = 'paymenthistory'
}

type BillingTabType = BillingTab;

const createPath = (teamid: string, billingTab?: string) => {
  return createAbsoluteUrl('dashboard', 'team', teamid, 'settings', 'billing', billingTab);
};
const createTabPath = (billingTab?: string) => {
  return createPath(':teamid', billingTab);
};
const path = createTabPath(':billingTab?');
const billingOverviewPath = createTabPath(BillingTab.OVERVIEW);
const billingPaymentMethodPath = createTabPath(BillingTab.PAYMENTMETHOD);
const billingPaymentHistoryPath = createTabPath(BillingTab.PAYMENTHISTORY);

const createUrl = (teamId: ID, billingTab?: BillingTab): string => {
  return createPath(teamId, billingTab);
};

const DashboardTeamSettingsBillingRouting = {
  BillingTab,
  path,
  billingOverviewPath,
  billingPaymentMethodPath,
  billingPaymentHistoryPath,
  createUrl,
};

// eslint-disable-next-line
declare namespace DashboardTeamSettingsBillingRouting {
  interface Params {
    teamid: ID;
    billingTab?: BillingTab;
  }
  type BillingTab = BillingTabType;
}

export default DashboardTeamSettingsBillingRouting;
