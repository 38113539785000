import { Loader } from '@insights-gaming/material-components';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import memoizeOne from 'memoize-one';
import React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query_statistics,GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2QueryVariables } from '../../../../apollo/queries/types/GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query';
import { NO_ROSTER_SELECTED } from '../../../../constants/strings';
import { createVideoStatsCacheKey } from '../../../../helpers';
import { OwstatsF, OwstatsNS } from '../../../../locales/en/owstats';
import Message from '../../../../material/message/Message';
import { ETeamStatsTabType, teamOverwatchStatsRoute } from '../../../../routes';
import { ID } from '../../../../types/pigeon';
import OverwatchPrimaryRosterTeamFightCompositionStatisticsTable from './overwatch-primary-roster-team-fight-composition-statistics-table/OverwatchPrimaryRosterTeamFightCompositionStatisticsTable';

export interface IOverwatchPrimaryRosterTeamFightCompositionStatisticsOwnProps {
  team: TeamFragment;
  videoIds?: ID[];
  csvName: string;
}

export interface IOverwatchPrimaryRosterTeamFightCompositionStatisticsMappedProps {
  primaryRosterTeamFightCompositionStatisticsCache?: Dictionary<
    GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query_statistics
  >;
  primaryRosterTeamFightCompositionStatisticsError?: Error;
}

export interface IOverwatchPrimaryRosterTeamFightCompositionStatisticsDispatch {
  onFetchOverwatchPrimaryRosterTeamFightCompositionStatistics: (
    params: GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2QueryVariables,
  ) => void;
}

export type OverwatchPrimaryRosterTeamFightCompositionStatisticsProps =
  IOverwatchPrimaryRosterTeamFightCompositionStatisticsOwnProps &
  IOverwatchPrimaryRosterTeamFightCompositionStatisticsMappedProps &
  IOverwatchPrimaryRosterTeamFightCompositionStatisticsDispatch &
  WithTranslation;

class OverwatchPrimaryRosterTeamFightCompositionStatistics extends React.Component<
  OverwatchPrimaryRosterTeamFightCompositionStatisticsProps
> {
  private memoizedCacheKey = memoizeOne(createVideoStatsCacheKey);

  public componentDidMount() {
    this.fetchStatistics();
  }

  public componentDidUpdate(prevProps: OverwatchPrimaryRosterTeamFightCompositionStatisticsProps) {
    const {
      videoIds,
      primaryRosterTeamFightCompositionStatisticsCache,
      primaryRosterTeamFightCompositionStatisticsError,
    } = this.props;
    if (videoIds !== prevProps.videoIds) {
      this.fetchStatistics();
    } else if ( // cache invalidated
      (
        !primaryRosterTeamFightCompositionStatisticsCache ||
        !(this.memoizedCacheKey(videoIds) in primaryRosterTeamFightCompositionStatisticsCache)
      ) &&
      !primaryRosterTeamFightCompositionStatisticsError
    ) {
      this.fetchStatistics();
    }
  }

  public render() {
    const {
      videoIds,
      primaryRosterTeamFightCompositionStatisticsCache,
      primaryRosterTeamFightCompositionStatisticsError,
      csvName,
    } = this.props;
    if (primaryRosterTeamFightCompositionStatisticsError) {
      return this.renderError(primaryRosterTeamFightCompositionStatisticsError);
    }
    if (!primaryRosterTeamFightCompositionStatisticsCache) {
      return this.renderLoader();
    }
    const cacheKey = this.memoizedCacheKey(videoIds);
    const statistics = primaryRosterTeamFightCompositionStatisticsCache[cacheKey];
    switch (statistics) {
      case null     : return this.renderNoStatistics();
      case undefined: return this.renderLoader();
    }
    if (statistics.__typename !== 'OverwatchStatistics') { return this.renderNoStatistics(); }
    return (
      <OverwatchPrimaryRosterTeamFightCompositionStatisticsTable
      key={cacheKey}
      csvName={csvName}
      statistics={statistics}
      />
    );
  }

  private renderError = (error: Error) => {
    const { team } = this.props;
    // No Roster Selected
    if (error.message.includes(NO_ROSTER_SELECTED) || error.message.includes('internal system error')) {
      return (
        <div>
          <Trans ns={OwstatsNS} i18nKey={OwstatsF('norosterselected')}>
            Unexpected error, you may need to select a roster
            <Link to={teamOverwatchStatsRoute(team.id, ETeamStatsTabType.SELECT_ROSTER)}>here</Link>.
          </Trans>
        </div>
      );
    }

    return (
      <Message message={error.message} variant='error' />
    );
  }

  private renderNoStatistics = () => {
    return (
      <Message message='no statistics' />
    );
  }

  private renderLoader = () => {
    const { t } = this.props;
    return (
      <Loader key='statsloading'>
        {t(OwstatsF('aggregatingstats'))}
      </Loader>
    );
  }

  private fetchStatistics = () => {
    const { team, videoIds, onFetchOverwatchPrimaryRosterTeamFightCompositionStatistics } = this.props;
    onFetchOverwatchPrimaryRosterTeamFightCompositionStatistics({
      teamId: team.id,
      videoIds,
    });
  }
}

export default withTranslation(OwstatsNS)(OverwatchPrimaryRosterTeamFightCompositionStatistics);
