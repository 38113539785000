import { createAbsoluteUrl } from 'helpers/routing';
import { ID } from 'types/pigeon';

const createPath = (teamid: string) => {
  return createAbsoluteUrl('dashboard', 'team', teamid, 'search');
};

const path = createPath(':teamid');

const createUrl = (teamId: ID): string => {
  return createPath(teamId);
};

const DashboardTeamSearchRouting = {
  path,
  createUrl,
};

export default DashboardTeamSearchRouting;
