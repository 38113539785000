import { Theme } from '@insights-gaming/theme';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';

export const CollapsibleGroup = withStyles((theme: Theme) => ({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}), {name: 'CollasibleGroup'})(Accordion);

export const CollapsibleGroupSummary = withStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}), {name: 'CollapsibleGroupSummary'})(AccordionSummary);

export const CollapsibleGroupDetails = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    flexDirection: 'column',
  },
}), {name: 'CollapsibleGroupDetails'})(AccordionDetails);
