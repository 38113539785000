import { Loader } from '@insights-gaming/material-components';
import React, { useEffect, useState } from 'react';

export interface ISuspenseLoaderOwnProps {
  delay: number;
}

type SuspenseLoaderProps = React.PropsWithChildren<ISuspenseLoaderOwnProps>;

const SuspenseLoader: React.FunctionComponent<SuspenseLoaderProps> = (props: SuspenseLoaderProps) => {
  const [ showLoader, setShowLoader ] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(true);
    }, props.delay);
    return () => {
      clearTimeout(timer);
    };
  });

  if (!showLoader) {
    return null;
  }

  return (
    <React.Fragment>
      {props.children || <Loader />}
    </React.Fragment>
  );
};

export default React.memo(SuspenseLoader);
