import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import LabelIcon from '@material-ui/icons/Label';
import classNames from 'classnames';
import React from 'react';

import EllipsisListItemText from '../../material/ellipsis-list-item-text/EllipsisListItemText';

interface TagListItemOwnProps {
  className?: string;
  color?: string | null;
  name: React.ReactNode;
  secondaryAction?: JSX.Element;
  showSecondaryAction?: boolean;

  selected? : boolean;

  onClick?: React.MouseEventHandler;
}

type Props = TagListItemOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  disableSecondaryAction: {
    opacity: 0,
    pointerEvents: 'none',
  },
}), {name: 'TagListItem'});


function TagListItem(props: Props) {
  const classes = useStyles(props);
  const {
    secondaryAction,
    className,
    color,
    name,
    onClick,
    selected,
    showSecondaryAction,
  } = props;

  return (
    <ListItem button={true} onClick={onClick} selected={selected} className={classNames(classes.root, className)}>
      <ListItemIcon>
        <LabelIcon htmlColor={color || undefined} />
      </ListItemIcon>
      <EllipsisListItemText primary={name} />
      {secondaryAction && (
        <ListItemSecondaryAction className={classNames(!showSecondaryAction && classes.disableSecondaryAction)}>
          {secondaryAction}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

export default React.memo(TagListItem);
