import React from 'react';
import { ID } from 'types/pigeon';

import { useFetchDirectoryVideos } from './useFetchDirectoryVideos';

interface DirectoryVideoFetcherOwnProps {
  directoryId: ID;
  tagIds?: ID[];
}

type DirectoryVideoFetcherProps = DirectoryVideoFetcherOwnProps;

function DirectoryVideoFetcher({ directoryId, tagIds }: DirectoryVideoFetcherProps) {
  useFetchDirectoryVideos({ directoryId, tagIds });

  return null;
}

export default React.memo(DirectoryVideoFetcher);
