import { BidirectionalIDRecord } from '@insights-gaming/redux-utils';
import { styles } from '@material-ui/lab/Pagination/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { createStyles, makeStyles, StyleRules } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';

declare module '@material-ui/lab/Pagination/Pagination' {
  export const styles: StyleRules;
}

export interface PaginationOwnProps {
  className?: string;
  record?: BidirectionalIDRecord & { count?: number };
  pageSize: number;
  page: number;
  onChange: (page: number) => void;
  middlePadding?: number;
}

export type PaginationProps = React.PropsWithChildren<PaginationOwnProps>;

const useStyles = makeStyles(() => createStyles(styles), { name: 'Pagination' });

const defaultMiddlePadding = 2;

function Pagination({
  record,
  pageSize,
  page,
  onChange,
  className,
  middlePadding,
}: PaginationProps) {
  const classes = useStyles();

  const safeGroupSize = useMemo(
    () => (middlePadding && middlePadding > 0 ? middlePadding : defaultMiddlePadding) * 2 + 1,
    [middlePadding],
  );
  const safePageSize = useMemo(() => pageSize > 0 ? pageSize : 1, [pageSize]);

  const totalPages = useMemo(() => {
    if (typeof record?.count !== 'number') {
      return undefined;
    }

    return Math.ceil(record.count / safePageSize);
  }, [record?.count, safePageSize]);

  const fetchedPages = useMemo(() => {
    if (!record) {
      return 0;
    }

    return Math.ceil(record.ids.length / safePageSize);
  }, [record, safePageSize]);

  const incomplete = useMemo(() => Boolean(record?.forward.fetching || record?.forward.more), [record]);

  const buttons = useMemo((): Array<React.ComponentProps<typeof PaginationItem>> => {
    if (totalPages === 0 || fetchedPages === 0) {
      return [{ page: 1, selected: true }];
    }

    const makePage = (
      n: number,
      additionalProps?: Omit<React.ComponentProps<typeof PaginationItem>, 'page' | 'selected'>,
    ): React.ComponentProps<typeof PaginationItem> => {
      const selected = page === n;
      return {
        ...additionalProps,
        page: n + 1,
        selected,
        onClick: selected ? undefined : () => onChange(n),
      };
    };

    let groupStart = Math.min(
      Math.max(0, page - Math.floor(safeGroupSize / 2)),
      Math.max(page, fetchedPages - safeGroupSize),
    );

    const lastPage = totalPages || fetchedPages;
    const groupEnd = Math.min(
      groupStart + safeGroupSize,
      lastPage,
    );

    groupStart = Math.max(0, groupEnd - safeGroupSize);

    const pages = Array.from(
      { length: groupEnd - groupStart },
      (_, i) => makePage(i + groupStart),
    );

    switch (pages[0].page) {
      case 1:
        // do nothing
        break;

      case 2:
        pages.unshift(makePage(0));
        break;

      default:
        pages.unshift(makePage(0), { type: 'start-ellipsis' });
    }

    switch (pages[pages.length - 1].page) {
      case lastPage: // last page
        // do nothing
        break;

      case lastPage - 1: // 2nd last page
        pages.push(makePage(lastPage - 1));
        break;

      default:
        pages.push({ type: 'start-ellipsis' }, makePage(lastPage - 1));
        break;
    }

    if (typeof totalPages !== 'number' && incomplete) {
      pages.push({ type: 'start-ellipsis' });
    }

    return pages;
  }, [page, safeGroupSize, fetchedPages, totalPages, incomplete, onChange]);

  return (
    <nav className={classNames(classes.root, className)}>
      <ul className={classes.ul}>
        <li>
          <PaginationItem
          type='previous'
          disabled={page <= 0}
          onClick={useCallback(() => onChange(page - 1), [onChange, page])}
          />
        </li>
        {
          buttons.length === 0 ? (
            <li><PaginationItem page={1} selected={true}/></li>
          ) : buttons.map((props, i) => (
            <li key={`${props.page ?? i}-${props.type}`}><PaginationItem {...props}/></li>
          ))
        }
        <li>
          <PaginationItem
          type='next'
          disabled={page >= fetchedPages - 1}
          onClick={useCallback(() => onChange(page + 1), [onChange, page])}
          />
        </li>
      </ul>
    </nav>
  );
}

export default React.memo(Pagination);

