import gql from 'graphql-tag';

import { MemberFragment } from '../fragments';

export const ConvertCollaboratorToMember_Mutation = gql`
    mutation ConvertCollaboratorToMemberMutation(
        $input: ConvertCollaboratorToMemberInput!
    ) {
        convertCollaboratorToMember(
            input: $input
        ) {
            member {
                ...MemberFragment
            }
        }
    }
    ${MemberFragment}
`;
