import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Skeleton } from '@material-ui/lab';
import { NotificationFragment_VideoNotification } from 'apollo/fragments/types/NotificationFragment';
import { makeGetTeamById } from 'features/dashboard/team/dashboard-team-selector';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { ID } from 'types/pigeon';

interface VideoNotificationMessageProps {
  notifications: NotificationFragment_VideoNotification[];
}

function VideoNotificationMessage({
  notifications,
}: VideoNotificationMessageProps) {
  const [firstNotification] = notifications;
  const { actor, team: { id: teamId }, video } = firstNotification;

  const team = useCreateSelector(makeGetTeamById, teamId);

  const numberOfExtraActors = useMemo(() => {
    const userIds = new Set<ID>();
    for (const n of notifications) {
      if (n.actor) {
        userIds.add(n.actor.id);
      }
    }
    return userIds.size - 1;
  }, [notifications]);

  if (!team) {
    return <Skeleton variant='text' />;
  }

  const creator = actor?.alias || 'somebody';

  return numberOfExtraActors > 0 ? (
    <Trans
    ns='notification'
    i18nKey='notifications.videosnotification'
    values={{
      creator,
      numberOfExtraActors,
      videos: notifications.length,
      team: team.name,
    }}
    >
      <strong>{creator} and {numberOfExtraActors} others</strong> uploaded in <strong>{team.name}</strong>
    </Trans>
  ) : notifications.length === 1 ? (
    <Trans
    ns='notification'
    i18nKey='notifications.videonotification'
    values={{
      creator,
      video: video.name,
      team: team.name,
    }}
    >
      <strong>{creator}</strong> uploaded <strong>{video.name}</strong> in <strong>{team.name}</strong>
    </Trans>
  ) : (
    <Trans
    ns='notification'
    i18nKey='notifications.actorvideosnotification'
    values={{
      creator,
      videos: notifications.length,
      team: team.name,
    }}
    >
      <strong>{creator}</strong> uploaded {notifications.length} videos in <strong>{team.name}</strong>
    </Trans>
  );
}

export default React.memo(VideoNotificationMessage);
