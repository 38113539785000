import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import TeamStatistics from 'components/dashboard/team-statistics/TeamStatistics';
import { useNavigate } from 'hooks/useNavigate';
import React, { useEffect } from 'react';
import { notFoundRoute } from 'routes';

import { useAccessControl } from '../access-control/useAccessControl';

interface DashboardTeamStatisticsOwnProps {
  className?: string;
  team: TeamFragment;
}

type DashboardTeamStatisticsProps = DashboardTeamStatisticsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DashboardTeamStatistics'});

function DashboardTeamStatistics(props: DashboardTeamStatisticsProps) {
  const classes = useStyles(props);
  const { className, team } = props;
  const onNavigate = useNavigate();
  const { canAccessOverwatchStats, canAggregateOverwatchStats } = useAccessControl();

  useEffect(() => {
    if (!canAccessOverwatchStats || !canAggregateOverwatchStats) { onNavigate(notFoundRoute()); }
  }, [canAccessOverwatchStats, onNavigate, canAggregateOverwatchStats, team.id]);

  return (
    <TeamStatistics
    team={team}
    source='dashboard'
    className={classNames(classes.root, className)}
    />
  );
}

export default React.memo(DashboardTeamStatistics);
