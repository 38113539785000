import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import classNames from 'classnames';
import NotificationMenuButton from 'components/status-bar/notifications-list/NotificationMenuButton';
import { makeGetTeamById } from 'features/dashboard/team/dashboard-team-selector';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo } from 'react';

import { ENotificationState } from '../constants';
import UnreadNotificationIndicator from '../unread-notifications/UnreadNotificationIndicator';
import { useOnClickNotification } from '../useOnClickNotification';
import useUpdateNotification from '../useUpdateNotifications';
import NotificationMessageWithButton from './NotificationMessageWithButton';
import NotificationsTableRow from './NotificationsTableRow';

interface NotificationsTableRowWrapperOwnProps {
  className?: string;
  notifications: NotificationFragment[];
}

type NotificationsTableRowWrapperProps = NotificationsTableRowWrapperOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&$unviewed': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
  },
  stateColumn: {
    display: 'grid',
    gridTemplateColumns: '5fr 0.5fr 3fr',
    '&$isMobile': {
      gridTemplateColumns: '5fr 1fr 1fr',
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  unviewed: {},
  isMobile: {},
}), {name: 'NotificationsTableRowWrapper'});

function NotificationsTableRowWrapper(props: NotificationsTableRowWrapperProps) {
  const classes = useStyles(props);
  const { className, notifications } = props;

  const [firstNotification] = notifications;
  const team = useCreateSelector(makeGetTeamById, firstNotification.team.id);
  const hasUnread = useMemo(() => {
    return !!notifications.find(n => n.state !== ENotificationState.READ);
  }, [notifications]);

  const hasUnviewed = useMemo(() => {
    return !!notifications.find(n => n.state === ENotificationState.UNREAD);
  }, [notifications]);

  const unreadNotifications = useMemo(() => {
    return notifications.filter(n => n.state !== ENotificationState.READ);
  }, [notifications]);

  const { t } = useStrictTranslation(['notification']);
  const onClick = useOnClickNotification(notifications);
  const isDesktop = useIsDesktop();

  const { handleUpdateNotifications } = useUpdateNotification();

  const handleMenuOnClick = useCallback((notifications: NotificationFragment[], status: ENotificationState) => {;
    handleUpdateNotifications(notifications, status);
  }, [handleUpdateNotifications]);

  const handleMarkAsRead = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    handleMenuOnClick(unreadNotifications, ENotificationState.READ);
  }, [handleMenuOnClick, unreadNotifications]);

  const handleDelete = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    handleMenuOnClick(notifications, ENotificationState.DELETED);
  }, [handleMenuOnClick, notifications]);

  if (!team) {
    return null;
  }

  return (
    <NotificationsTableRow
    className={classNames(
      classes.root,
      className,
      {[classes.unviewed]: hasUnviewed},
    )}
    onClick={onClick}
    message={(
      <NotificationMessageWithButton notifications={notifications} />
    )}
    team={team.name}
    action={(
      <div className={classNames(classes.stateColumn, {[classes.isMobile]: !isDesktop})}>
        <div>
          {t('notification:notifications.notificationrealtime', {date: firstNotification.created})}
        </div>
        <div>
          {hasUnread && <UnreadNotificationIndicator />}
        </div>
        {isDesktop ? (
          <div>
            {hasUnread &&
            <IconButton size='small' onClick={handleMarkAsRead}>
              <CheckIcon />
            </IconButton>
              }
            <IconButton size='small' onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </div>
        ) : (
          <div>
            <NotificationMenuButton notifications={notifications} />
          </div>
        )}
      </div>
    )}
    />
  );
}

export default React.memo(NotificationsTableRowWrapper);
