import { useContext, useEffect, useRef } from 'react';

import { LoadingContext } from './context';

export function useLoadingScreen(loading: boolean) {
  const controller = useContext(LoadingContext);

  const ref = useRef(controller);

  useEffect(() => {
    ref.current = controller;
  }, [controller]);

  const loadingRef = useRef(false);

  useEffect(() => {
    if (loadingRef.current !== loading) {
      (loading ? ref.current?.add : ref.current?.done)?.();
      loadingRef.current = loading;
    }

    return () => {
      if (loadingRef.current) {
        ref.current?.done?.();
      }
    };
  }, [loading]);
}
