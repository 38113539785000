import { Theme } from '@insights-gaming/theme';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import classNames from 'classnames';
import DirectoryLink from 'components/directory-link/DirectoryLink';
import DirectoryLinkSkeleton from 'components/directory-link/DirectoryLinkSkeleton';
import { DragItem } from 'features/dashboard/dnd/item-types';
import React, { PropsWithChildren } from 'react';
import { FileRejection } from 'react-dropzone';
import { ID } from 'types/pigeon';

import { useFetchDirectoryTree } from '../useFetchDirectoryTree';

interface DirectoryBreadcrumbsOwnProps {
  className?: string;
  directoryId: ID;
  onItemDroppedOnDirectory?: (directory: DirectoryFragment, item: DragItem) => void;
  onFilesDroppedOnDirectory?: (
    directory: DirectoryFragment,
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ) => void;
}

type DirectoryBreadcrumbsProps = DirectoryBreadcrumbsOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DirectoryBreadcrumbs'});

function DirectoryBreadcrumbs(props: PropsWithChildren<DirectoryBreadcrumbsProps>) {
  const classes = useStyles(props);
  const { children, className, directoryId, onItemDroppedOnDirectory, onFilesDroppedOnDirectory } = props;
  const [dirs, ancestor, status] = useFetchDirectoryTree(directoryId);

  return (
    <Breadcrumbs className={classNames(classes.root, className)}>
      {!ancestor && !!status?.fetching && (
        <DirectoryLinkSkeleton />
      )}
      {dirs.map(directory => (
        <DirectoryLink
        key={directory.id}
        directory={directory}
        active={directory.id === directoryId}
        onItemDropped={onItemDroppedOnDirectory}
        onFilesDropped={onFilesDroppedOnDirectory}
        />
      ))}
      {children}
    </Breadcrumbs>
  );
}

export default React.memo(DirectoryBreadcrumbs);
