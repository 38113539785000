import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexSpacer, Loader } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LabelIcon from '@material-ui/icons/Label';
import SettingsIcon from '@material-ui/icons/Settings';
import { updateVideoTagsAsyncAC } from 'actions/video-actions';
import { DirectoryFragment_Division } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import classNames from 'classnames';
import {
  CollapsibleGroup,
  CollapsibleGroupDetails,
  CollapsibleGroupSummary,
} from 'components/collapsible-group/CollapsibleGroup';
import DivisionListItemSkeleton from 'components/division-list-item/DivisionListItemSkeleton';
import DropDivisionListItem from 'components/division-list-item/DropDivisionListItem';
import { makeGetTeamNotificationSettingsDictByTeamId } from 'components/settings/notification-settings/notificaion-settings-selector';
import { fetchTeamNotificationSettingsAC } from 'components/settings/notification-settings/notification-settings-slice';
import TagList from 'components/tag-list/TagList';
import { EIntercomID } from 'constants/strings';
import { useIsDesktop } from 'features/media-queries/hooks';
import { useCreateSelector } from 'hooks/useCreateSelector';
import { useDialogState } from 'hooks/useDialogState';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import update from 'immutability-helper';
import TeamAvatar from 'material/team-avatar/TeamAvatar';
import { useSnackbar } from 'notistack';
import React, { PropsWithChildren,useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ID, ITag, TeamInterface } from 'types/pigeon';

import { useAccessControl } from '../access-control/useAccessControl';
import CreateDirectoryDialog from '../directory/create-division-dialog/CreateDirectoryDialog';
import { makeGetSharedDivisionsByTeamId, makeGetTeamDirectoriesByTeamId } from '../directory/dashboard-directory-selector';
import { useDirectoryDropTarget } from '../directory/useDirectoryDropTarget';
import { useFetchTeamDivisions } from '../directory/useFetchTeamDivisions';
import { useFileDropUploader } from '../directory/useFileDropUploader';
import { DashboardMultiSelectContext } from '../multi-select/DashboardMultiSelectContext';
import { useSelectedItems } from '../multi-select/useSelectedItems';
import { makeGetTeamVideoTagsByTeamId } from '../tag/dashboard-tag-selector';
import TagEditorDialog from '../tag/tag-editor-dialog/TagEditorDialog';
import TagManagerDialog from '../tag/tag-manager-dialog/TagManagerDialog';
import { useFetchTeamTags } from '../tag/useFetchTeamTags';
import { useTagIdsOnVideos } from '../tag/useTagIdsOnVideos';
import { getSortedTeams } from '../team/dashboard-team-selector';
import { TeamHelper } from '../team/team-helper';
import { makeGetTeamDirectoryVideosByDirectoryId, makeGetTeamVideosByTeamId } from '../video/dashboard-video-selector';

interface SidebarOwnProps {
  className?: string;
  team: TeamFragment | TeamInterface;
  teamPicker?: boolean;
  onDivisionClick?: (e: React.MouseEvent, division: DirectoryFragment_Division) => void
  actionButtons?: React.ReactNode;
  directoryId?: string | undefined;
  selectedTagIds?: Set<string>;
  onTagClick?: (e: React.MouseEvent, tag: ITag) => void;
  onTeamChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  tagExpanded?: boolean;
  handleTagExpansion?: () => void;
  shouldHideActionButtons?: boolean;
}

type SidebarProps = SidebarOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    overflow: 'hidden',
    transform: 'translate3d(0, 0, 0)',
  },
  listWrapper: {
    maxHeight: createRemFromPx(400),
    overflow: 'auto',
  },
  sublist: {
    '@global': {
      '.MuiListItem-root': {
        paddingLeft: theme.spacing(4),
      },
    },
    '&$isNotTag': {
      '@global': {
        '.Mui-selected': {
          paddingLeft: theme.spacing(3.5),
          borderLeft: `${createRemFromPx(4)} solid ${theme.palette.primary.main}`,
        },
      },
    },
  },
  selectRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  tabIndicator: {
    borderLeft: `${createRemFromPx(4)} solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(1.5),
  },
  isNotTag: {},
}), {name: 'Sidebar'});

function Sidebar(props: PropsWithChildren<SidebarProps>) {
  const classes = useStyles(props);
  const {
    className,
    children,
    team,
    teamPicker,
    onDivisionClick,
    directoryId,
    selectedTagIds,
    onTagClick,
    onTeamChange,
    actionButtons,
    tagExpanded,
    handleTagExpansion,
    shouldHideActionButtons,
  } = props;

  const dispatch = useDispatch();
  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { t } = useStrictTranslation(['common', 'dashboard', 'dashboard-directory', 'dashboard-settings']);
  const { enqueueSnackbar } = useSnackbar();

  const {
    canCreateTag,
    canUpdateTag,
    canDeleteTag,
    canCreateDivision,
  } = useAccessControl();

  const [divisionsForwardFetchStatus] = useFetchTeamDivisions(team.id);
  const initialDivisionFetch = divisionsForwardFetchStatus.fetching && !divisionsForwardFetchStatus.cursor;
  const divisions = useCreateSelector(makeGetTeamDirectoriesByTeamId, team.id);

  const sharedDivisions = useCreateSelector(makeGetSharedDivisionsByTeamId, {teamId: team.id});

  const factory = directoryId ? makeGetTeamDirectoryVideosByDirectoryId : makeGetTeamVideosByTeamId;
  const videos = useCreateSelector(factory, directoryId || team.id);

  const [tagsForwardFetchStatus] = useFetchTeamTags(team.id);
  const initialTagFetch = tagsForwardFetchStatus.fetching && !tagsForwardFetchStatus.cursor;
  const tags = useCreateSelector(makeGetTeamVideoTagsByTeamId, team.id);

  const { handleItemDroppedOnDirectory } = useDirectoryDropTarget();

  const {
    videos: {
      selected: selectedVideoIds,
    },
  } = useContext(DashboardMultiSelectContext);

  const selectedVideos = useSelectedItems(videos, selectedVideoIds);

  const { tagIdsOnAllVideos, tagIdsOnAtLeastOneVideo } = useTagIdsOnVideos(selectedVideos);
  const { checkedTagIds, indeterminateTagIds } = useMemo(() => {
    const checkedTagIds = new Set<ID>();
    const indeterminateTagIds = new Set<ID>();
    tags.forEach(tag => {
      if (tagIdsOnAtLeastOneVideo.has(tag.id)) {
        checkedTagIds.add(tag.id);
        if (!tagIdsOnAllVideos.has(tag.id)) {
          indeterminateTagIds.add(tag.id);
        }
      }
    });
    return { checkedTagIds, indeterminateTagIds };
  }, [tagIdsOnAllVideos, tagIdsOnAtLeastOneVideo, tags]);

  const [loadingTagIds, setLoadingTagIds] = useState(new Set<ID>());

  const handleTagCheckboxChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>, tag: ITag) => {
    if (loadingTagIds.has(tag.id)) {
      return;
    }
    setLoadingTagIds(tagIds => update(tagIds, {$add: [tag.id]}));
    try {
      await (
        !e.target.checked
          ? promiseSagaDispatch(updateVideoTagsAsyncAC, {
              teamId: team.id,
              videoIds: Array.from(selectedVideoIds),
              untagIds: [tag.id],
            })
          : promiseSagaDispatch(updateVideoTagsAsyncAC, {
              teamId: team.id,
              videoIds: Array.from(selectedVideoIds),
              tagIds: [tag.id],
            })
      );
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLoadingTagIds(tagIds => update(tagIds, {$remove: [tag.id]}));
    }
  }, [enqueueSnackbar, loadingTagIds, promiseSagaDispatch, selectedVideoIds, team.id]);

  const [isCreateDivisionDialogOpen, openCreateDivisionDialog, closeCreateDivisionDialog] = useDialogState();
  const [isTagEditorDialogOpen, openTagEditorDialog, closeTagEditorDialog] = useDialogState();
  const [isTagManagerDialogOpen, openTagManagerDialog, closeTagManagerDialog] = useDialogState();

  const isDesktop = useIsDesktop();
  const teams = useSelector(getSortedTeams);

  const { handleFilesDroppedOnDirectory, uploadDialog } = useFileDropUploader(team.id);

  const teamSettingState = useCreateSelector(makeGetTeamNotificationSettingsDictByTeamId, team.id);

  useEffect(() => {
    if (!teamSettingState) {
      dispatch(fetchTeamNotificationSettingsAC.started({ teamId: team.id }));
    }
  }, [teamSettingState, dispatch, team]);

  return (
    <div className={classNames(classes.root, className)}>
      <List>
        {isDesktop ? (
          <ListItem component='div'>
            <Typography variant='h2' noWrap={true} style={{ lineHeight: 'inherit' }}>
              {team.name}
            </Typography>
            {teamSettingState && (
              <ListItemSecondaryAction>
                {teamSettingState.loading ? (
                  <Loader size={25} />
                ) : (
                  actionButtons
                )}
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ) : (
          <ListItem component='div'>
            {teamPicker ? (
              <Select
              value={team.id}
              fullWidth={true}
              onChange={onTeamChange}
              classes={{select: classes.selectRoot}}
              >
                {teams.map(team => (
                  <MenuItem key={team.id} value={team.id} classes={{selected: classes.tabIndicator}}>
                    <ListItemIcon>
                      <TeamAvatar team={team} size='xs' />
                    </ListItemIcon>
                    <ListItemText primary={team.name} />
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography variant='h2' noWrap={true} style={{ lineHeight: 'inherit' }}>
                {team.name}
              </Typography>
            )}
          </ListItem>
        )}
      </List>
      <CollapsibleGroup id={EIntercomID.CHANNEL_SELECTOR} defaultExpanded={true}>
        <CollapsibleGroupSummary expandIcon={<ExpandMoreIcon />}>
          <FlexSpacer flexAlignItems='center'>
            <FontAwesomeIcon icon={faHashtag} fixedWidth={true} size='lg' />
            <Typography>
              {t('dashboard-directory:channel_plural')}
            </Typography>
          </FlexSpacer>
        </CollapsibleGroupSummary>
        <CollapsibleGroupDetails className={classes.listWrapper}>
          <List disablePadding={true} className={classNames(classes.sublist, classes.isNotTag)}>
            {!initialDivisionFetch && canCreateDivision && !shouldHideActionButtons && (
              <ListItem
              id={EIntercomID.CREATE_CHANNEL}
              button={true}
              onClick={openCreateDivisionDialog}
              >
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText primary={t('dashboard-directory:createchannel.title')} />
              </ListItem>
            )}
            {!TeamHelper.isTeamFragment(team) && sharedDivisions.map(division => (
              <DropDivisionListItem
              key={division.id}
              selected={directoryId === division.id}
              division={division}
              onClick={onDivisionClick}
              onItemDropped={handleItemDroppedOnDirectory}
              onFilesDropped={handleFilesDroppedOnDirectory}
              shouldHideMenu={shouldHideActionButtons}
              />
            ))}
            {initialDivisionFetch ? (
              Array.from({length: 2}).map((_, i) => (
                <DivisionListItemSkeleton key={i} />
              ))
            ) : (
              divisions.map(division => (
                <DropDivisionListItem
                key={division.id}
                selected={directoryId === division.id}
                division={division}
                onClick={onDivisionClick}
                onItemDropped={handleItemDroppedOnDirectory}
                onFilesDropped={handleFilesDroppedOnDirectory}
                shouldHideMenu={shouldHideActionButtons}
                />
              ))
            )}
          </List>
        </CollapsibleGroupDetails>
      </CollapsibleGroup>
      {TeamHelper.isTeamFragment(team) && (
        <CollapsibleGroup
        id={EIntercomID.TAG_SELECTOR}
        expanded={tagExpanded}
        >
          <CollapsibleGroupSummary expandIcon={<ExpandMoreIcon />} onClick={handleTagExpansion}>
            <FlexSpacer flexAlignItems='center'>
              <LabelIcon />
              <Typography>
                {t('common:tag_plural')}
              </Typography>
            </FlexSpacer>
          </CollapsibleGroupSummary>
          <CollapsibleGroupDetails className={classes.listWrapper}>
            {!shouldHideActionButtons && (
              <List disablePadding={true} className={classes.sublist}>
                {canCreateTag && (
                  <ListItem id={EIntercomID.CREATE_TAG} button={true} onClick={openTagEditorDialog}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('common:createtag')} />
                  </ListItem>
                )}
                {(canCreateTag || canUpdateTag || canDeleteTag) && (
                  <ListItem id={EIntercomID.MANAGE_TAG} button={true} onClick={openTagManagerDialog}>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary={t('common:managetags')} />
                  </ListItem>
                )}
              </List>
            )}
            <TagList
            tags={tags}
            selectedTagIds={selectedTagIds}
            checkedTagIds={checkedTagIds}
            indeterminateTagIds={indeterminateTagIds}
            loadingTagIds={loadingTagIds}
            displaySkeleton={initialTagFetch}
            onTagClick={onTagClick}
            onTagCheckBoxChange={handleTagCheckboxChange}
            showCheck={!shouldHideActionButtons && selectedVideoIds.size > 0}
            className={classes.sublist}
            />
          </CollapsibleGroupDetails>
        </CollapsibleGroup>
      )}
      {children}

      <CreateDirectoryDialog teamId={team.id} open={isCreateDivisionDialogOpen} onClose={closeCreateDivisionDialog} />
      <TagEditorDialog teamId={team.id} open={isTagEditorDialogOpen} onClose={closeTagEditorDialog} />
      <TagManagerDialog teamId={team.id} tags={tags} open={isTagManagerDialogOpen} onClose={closeTagManagerDialog} />
      {uploadDialog}
    </div>
  );
}

export default React.memo(Sidebar);
