import { createContext } from 'react';

export interface LoginDialogContextValue {
  loginState: {
    referPath?: string;
    referrer?: string;
    reload?: '1';
  };
}

export const LoginDialogContext = createContext<LoginDialogContextValue>({
  loginState: {},
});

export const { Provider: LoginDialogContextProvider, Consumer: LoginDialogContextConsumer } = LoginDialogContext;
