type TDashboard =
|'novideos'
|'uploadvideo'
|'noteam'
|'createorjointeam'
|'createteam'
|'teamaccess'
|'public'
|'team.addmember'
|'team.name'
|'team.email'
|'team.role'
|'team.itsbettertogether'
|'team.nomembers'
|'team.vods.title'
|'team.members.title'
|'team.roles.title'
|'team.games/overwatch/statistics.title'
|'team.games/ssbu/statistics.title'
|'team.statistics.title'
|'team.statistics.avgpermatch'
|'team.statistics.playerstats'
|'team.statistics.roster'
|'team.statistics.finalblows'
|'team.statistics.kills'
|'team.statistics.deaths'
|'team.statistics.ults'
|'team.statistics.ultcharge'
|'team.statistics.ultheld'
|'team.statistics.killsperult'
|'team.statistics.firstkills'
|'team.statistics.firstdeaths'
|'team.statistics.assists'
|'team.statistics.firstults'
|'team.statistics.herospecific'
|'team.statistics.playtime'
|'team.statistics.playrate'
|'team.statistics.winrate'
|'team.statistics.mapstats'
|'team.statistics.totalstats'
|'team.statistics.avgperteamfight'
|'team.statistics.map'
|'team.statistics.teamfights'
|'team.statistics.herocomp'
|'team.comparisons.title'
|'team.settings.title'
|'team.settings.overview'
|'team.settings.members'
|'team.settings.roles'
|'team.settings.invites'
|'team.settings.rosterselect'
|'team.rosters.title'
|'team.rosters.selectroster'
|'team.rosters.primaryroster'
|'team.rosters.otherrosters'
|'team.rosters.editnames'
|'team.rosters.savenames'
|'team.rosters.rostername'
|'team.rosters.playername'
|'team.rosters.saveunsuccessful'
|'team.rosters.savesuccessful'
|'team.rosters.rosterchangesuccessful'
|'team.rosters.rosterchangeunsuccessful'
|'invite.title'
|'invite.sentby'
|'invite.sentto'
|'invite.resendemail'
|'invite.revokeinvitation'
|'invite.emailresent'
|'invite.title2'
|'invite.link'
|'invite.limit'
|'invite.expiry'
|'invite.unlimited'
|'invite.deletesuccess'
|'invite.deletefailure'
|'sort.name!asc'
|'sort.name!desc'
|'sort.date!asc'
|'sort.date!desc'
|'sort.analysis'
|'player.name'
|'total.kills'
|'total.deaths'
|'selectvideos'
|'notags'
|'NO_STATISTICS'
;
const DashboardNS = 'dashboard';
function DashboardF(_: TDashboard): string { return `${DashboardNS}:${_}`; }
export {DashboardF,DashboardNS,TDashboard};
