import { SavedCardFragment } from 'apollo/fragments/types/SavedCardFragment';
import { TeamFragment } from 'apollo/fragments/types/TeamFragment';
import { TeamSubscriptionFragment } from 'apollo/fragments/types/TeamSubscriptionFragment';
import { CustomPlanProductKind, IAvailablePlanData } from 'features/dashboard/billing/useAvailableMetrics';
import useCheckoutQuantities from 'features/dashboard/billing/useCheckoutQuantities';
import useFetchTeamCards from 'features/dashboard/billing/useFetchTeamCards';
import useTeamAvailableBundles from 'features/dashboard/billing/useTeamAvailableBundles';
import useTeamBalance from 'features/dashboard/billing/useTeamBalance';
import useTeamProration from 'features/dashboard/billing/useTeamProration';
import useTeamSubscription from 'features/dashboard/billing/useTeamSubscription';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo } from 'react';
import { BillingInterval } from 'types/graphql';
import { InputQuantities } from 'types/pigeon/billing';

import CancelSubscription from './CancelSubsDialog/CancelSubscription';
import PlanDetails from './PlanDetailsDialog/PlanDetails';

interface ContentsProps {
  stepForward: VoidFunction;
  stepBackward: VoidFunction;
  step: number;
  onCancel: VoidFunction;
  onClose: VoidFunction;
  team: TeamFragment;
  checkoutQuantities: InputQuantities;
  cancelPlan: boolean;
  currentPlan?: IAvailablePlanData;
  currentProduct?: InputQuantities;
  teamSubscription?: TeamSubscriptionFragment;
  teamProration?: number;
  teamBalance?: number;
  teamCards?: SavedCardFragment[];
  customPlanQuantities?: Partial<Record<CustomPlanProductKind, number>>;
}

export type CancelSubsContentComponentType = React.ComponentType<ContentsProps>;

const Contents: readonly CancelSubsContentComponentType[] = [
  CancelSubscription,
  PlanDetails,
];

const Titles = [
  'dialog:cancelsubscription.title',
  'dialog:plandetails.title',
] as const;

export type CancelSubsContentsLayoutProps<T extends object | void = void> = React.PropsWithChildren<
  (T extends void ? {} : T) & {
    title: React.ReactNode;
    loading: boolean;
  }
>;

type CancelSubsContentsOwnProps<T extends object | void> = (T extends void ? {
  layoutProps?: any,
} : {
  layoutProps: T;
}) & {
  Layout: React.ComponentType<CancelSubsContentsLayoutProps<T>>;
  overrideTitle?: (step: number) => React.ReactNode;
  overrideContent?: (step: number) => CancelSubsContentComponentType | undefined;

  interval?: BillingInterval | undefined;
  team: TeamFragment;
  currentPlan?: IAvailablePlanData;

  step: number;
  onStepChange: (step: number) => void;

  onCancel: VoidFunction;
  onClose: VoidFunction;
}

type CancelSubsContentsProps<T extends object | void> = CancelSubsContentsOwnProps<T>;

export default function CancelSubsContents<T extends object | void = void>({
  Layout,
  layoutProps,
  overrideTitle,
  overrideContent,

  interval,
  team,
  currentPlan,

  step,
  onStepChange,

  onCancel,
  onClose,
}: CancelSubsContentsProps<T>) {
  const { t } = useStrictTranslation(['dialog', 'dashboard']);

  const bundles = useTeamAvailableBundles(team.id);
  const [teamSubscription] = useTeamSubscription(team.id);

  const {
    checkoutQuantities,
    prevQuantities,
    customPlanQuantities,
  } = useCheckoutQuantities(interval, currentPlan?.productId, bundles);

  const [teamBalance, teamBalanceFetched] = useTeamBalance(team.id);
  const [teamProration, teamProrationFetched] = useTeamProration(team.id);
  const [teamCards, teamCardsFetched] = useFetchTeamCards(team.id);

  const loading = useMemo(() => {
    if (!teamBalanceFetched || !teamCardsFetched || !bundles) {
      return true;
    }

    if (!teamSubscription) {
      return false;
    }

    return teamProrationFetched;
  }, [bundles, teamBalanceFetched, teamCardsFetched, teamProrationFetched, teamSubscription]);

  const stepForward = useCallback(() => onStepChange(step+1), [onStepChange, step]);
  const stepBackward = useCallback(() => onStepChange(step-1), [onStepChange, step]);

  const Content = useMemo(
    () => overrideContent?.(step) || Contents[step],
    [overrideContent, step],
  );

  return React.createElement(
    Layout,
    {
      ...(layoutProps || {}),
      title: overrideTitle?.(step) || t(Titles[step]),
      onForward: stepForward,
      onBackward: stepBackward,
      loading,
    },
    Content && React.createElement(Content, {
      step,
      stepForward,
      stepBackward,
      onClose,
      onCancel,
      team,
      checkoutQuantities,
      cancelPlan: true,
      currentPlan,
      currentProduct: prevQuantities,
      teamSubscription,
      teamBalance,
      teamProration,
      teamCards,
      customPlanQuantities,
    }),
  );
}
