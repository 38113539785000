import gql from 'graphql-tag';

import { VideoFragment } from '../fragments';

export const UpdateVideoTags_Mutation = gql`
    mutation UpdateVideoTagsMutation(
        $input: UpdateVideoTagsInput!
    ) {
        updateVideoTags(
            input: $input
        ) {
            videos {
                ...VideoFragment
            }
        }
    }
    ${VideoFragment}
`;
