import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import classNames from 'classnames';
import DropIndicator from 'components/drop-indicator/DropIndicator';
import { makeGetUnreadFolderNotificationsByFolderId } from 'components/settings/notifications/notifications-selector';
import UnreadFolderNotificationsFetcher from 'components/settings/notifications/unread-notifications/UnreadFolderNotificationsFetcher';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { DirectoryHelper } from 'features/dashboard/directory/dashboard-directory-helper';
import DirectoryContextMenu from 'features/dashboard/directory/directory-context-menu/DirectoryContextMenu';
import { canDropOnDirectory } from 'features/dashboard/directory/useDirectoryDropTarget';
import { DragItem, ItemTypes } from 'features/dashboard/dnd/item-types';
import { DashboardMultiSelectContext } from 'features/dashboard/multi-select/DashboardMultiSelectContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { FileRejection, useDropzone } from 'react-dropzone';
import ReactResizeDetector from 'react-resize-detector';

import DirectoryCard from './DirectoryCard';

interface DragDropDirectoryCardOwnProps {
  className?: string;
  directory: DirectoryFragment;
  selected?: boolean;
  canDrag?: boolean;
  shouldHideMenu?: boolean;

  onClick?: (e: React.MouseEvent, directory: DirectoryFragment) => void;
  onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>, directory: DirectoryFragment) => void;
  onItemDropped?: (directory: DirectoryFragment, item: DragItem) => void;
  onFilesDropped?: (directory: DirectoryFragment, acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
}

type DragDropDirectoryCardProps = DragDropDirectoryCardOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    '&$isOver$canDrop': {
      background: 'pink',
    },
    '&$isDragging': {
      opacity: .5,
    },
  },
  /* react-dnd related */
  isDragging: {},
  canDrop: {},
  isOver: {},
  /* dropzone related */
  isDragActive: {},
  isDragAccept: {},
  isDragReject: {},
}), {name: 'DragDropDirectoryCard'});

function DragDropDirectoryCard(props: DragDropDirectoryCardProps) {
  const classes = useStyles(props);
  const {
    className,
    directory,
    selected,
    canDrag = true,
    shouldHideMenu,
    onClick,
    onItemDropped,
    onCheckboxChange,
    onFilesDropped,
  } = props;

  const { videos: videoMultiSelect, folders: folderMultiSelect } = useContext(DashboardMultiSelectContext);
  const { canOpenDirectoryMenu } = useAccessControl();

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleClick = useCallback((e: React.MouseEvent) => {
    if (e.ctrlKey) {
      selected
      ? folderMultiSelect.removeFromSelection([directory.id])
      : folderMultiSelect.addToSelection([directory.id]);
    } else {
      onClick?.(e, directory);
    }
  }, [directory, folderMultiSelect, onClick, selected]);

  const handleCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange?.(e, directory);
  }, [directory, onCheckboxChange]);

  const handleResize = useCallback((width: number, height: number) => {
    setWidth(width);
    setHeight(height);
  }, []);

  const { layerIsDragging } = useDragLayer(monitor => ({
    layerIsDragging: monitor.isDragging(),
  }));

  const dragItem: DragItem = {
    type: ItemTypes.DIRECTORY_CARD,
    id: directory.id,
    name: directory.name,
    selection: {
      videos: videoMultiSelect.selected,
      folders: new Set([...folderMultiSelect.selected, directory.id]),
    },
    parentId: DirectoryHelper.tryGetParentId(directory),
    width,
    height,
  };
  const [{ isDragging }, drag, preview] = useDrag({
    item: dragItem,
    canDrag,
    begin: monitor => {
      folderMultiSelect?.addToSelection([directory.id]);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [ItemTypes.DIRECTORY_CARD, ItemTypes.VIDEO_CARD],
    canDrop: canDropOnDirectory(directory),
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item: DragItem, monitor) => {
      onItemDropped?.(directory, item);
    },
  });

  const handleFilesDropped = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    onFilesDropped?.(directory, acceptedFiles, rejectedFiles);
  }, [directory, onFilesDropped]);

  const { getRootProps, rootRef, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'video/*',
    onDrop: handleFilesDropped,
    noClick: true,
    noKeyboard: true,
  });

  drag(drop(rootRef));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const numOfNotifications = useCreateSelector(makeGetUnreadFolderNotificationsByFolderId, directory.id);

  return (
    <React.Fragment>
      {!numOfNotifications && <UnreadFolderNotificationsFetcher teamId={directory.team.id} folderId={directory.id} />}
      <DirectoryCard
      {...getRootProps()}
      className={classNames(
        classes.root,
        {
          [classes.isDragging]: isDragging || layerIsDragging && folderMultiSelect.selected.has(directory.id),
          [classes.canDrop]: canDrop,
          [classes.isOver]: isOver,
          [classes.isDragActive]: isDragActive,
          [classes.isDragAccept]: isDragAccept,
          [classes.isDragReject]: isDragReject,
        },
        className,
      )}
      name={directory.name}
      contextMenu={canOpenDirectoryMenu(directory) && !shouldHideMenu && (
        <DirectoryContextMenu directory={directory} />
      )}
      checkbox={onCheckboxChange && (
        <Checkbox checked={selected} onChange={handleCheckboxChange} />
      )}
      disabled={isDragging}
      onClick={handleClick}
      numOfNotifications={numOfNotifications}
      >
        <ReactResizeDetector handleWidth={true} handleHeight={true} onResize={handleResize} />
        <DropIndicator
        isDragActive={isDragActive}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        />
      </DirectoryCard>
    </React.Fragment>
  );
}

export default React.memo(DragDropDirectoryCard);
