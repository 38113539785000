import { Theme } from '@insights-gaming/theme';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { removeLocalStorage } from 'helpers/storage';
import { useSelector } from 'hooks/useSelector';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import AlertDialogContent from 'material/dialogs/alert-dialog-content/AlertDialogContent';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

import { getTeamDirectoryRecordsByTeamId } from '../dashboard-directory-selector';

interface DeleteDirectoryDialogOwnProps {
  className?: string;
  directoryName: string;
  directoryType: 'Division' | 'Folder';
  onDelete?: () => Promise<unknown>;
  onClose?: VoidFunction;
  directoryTeamId: string;
}

type DeleteDirectoryDialogProps = DeleteDirectoryDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'DeleteDirectoryDialog'});

function DeleteDirectoryDialog(props: DeleteDirectoryDialogProps) {
  const classes = useStyles(props);
  const { className, onDelete, directoryName, directoryType, directoryTeamId, ...rest } = props;
  const { onClose } = rest;

  const { t } = useStrictTranslation(['common', 'dashboard-directory']);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const teamDivisionRecords = useSelector(getTeamDirectoryRecordsByTeamId, directoryTeamId);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleDelete = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await onDelete?.();
      onClose?.();
      if (directoryType === 'Division') {
        enqueueSnackbar(t('dashboard-directory:deletechannel.success'), {variant: 'success'});
      } else {
        enqueueSnackbar(t('dashboard-directory:deletefolder.success'), {variant: 'success'});
      }

      if (directoryType === 'Division' && teamDivisionRecords?.ids?.length === 1) {
        removeLocalStorage('lastVisitedDirectory');
      }
    } catch (error) {
      enqueueSnackbar(error.message, {variant: 'error'});
    }
  }, [loading, onDelete, onClose, directoryType, teamDivisionRecords?.ids.length, enqueueSnackbar, t]);

  return (
    <Dialog className={classNames(classes.root, className)} {...rest}>
      <AlertDialogContent
      titleText={
        directoryType === 'Division'
        ? t('dashboard-directory:deletechannel.title')
        : t('dashboard-directory:deletefolder.title')
      }
      description={
        directoryType === 'Division'
        ? t('dashboard-directory:deletechannel.description')
        : t('dashboard-directory:deletefolder.description')
      }
      cancel={{
        text: t('common:cancel'),
        action: handleClose,
      }}
      confirm={{
        text: t('dashboard-directory:deletechannel.confirm'),
        loading,
        disabled: loading,
        action: handleDelete,
        negative: true,
      }}
      />
    </Dialog>
  );
}

export default React.memo(DeleteDirectoryDialog);
