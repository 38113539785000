function errorReporter(
  event: Event | string,
  // source?: string,
  // fileno?: number,
  // columnNumber?: number,
  // error?: Error,
): void {
  const error: Error = (event as ErrorEvent).error;
  if (window.location.hostname !== 'insights.gg') { return; }
  if (!error) { return; }
  const errorUrl = '';
  // fetch(errorUrl, {
  //   method: 'POST',
  //   mode: 'cors',
  //   cache: 'no-cache',
  //   credentials: 'same-origin',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   redirect: 'follow',
  //   referrer: 'no-referrer',
  //   body: error.stack,
  // });
}

export default errorReporter;
