import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';

export const getLiveSessionState = (state: RootReducer) => state.liveSessionReducer.state;

export const getLiveSessionStateType = (state: RootReducer) => state.liveSessionReducer.state?.type;

export const getIsJoinedLiveSession = (state: RootReducer) => state.liveSessionReducer.state?.type === 'joined';

export function getIsFreshLiveSession(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return;
  }

  return !Object.keys(state.liveSessionReducer.state.allUsers).length;
}

export function getLiveSessionErrorMessage(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'error') {
    return;
  }

  return state.liveSessionReducer.state.message;
}

export function getLiveSessionRequiresSecret(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'pending') {
    return;
  }

  return state.liveSessionReducer.state.requiresSecret;
}

export function getLiveSessionGuestName(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return;
  }

  return state.liveSessionReducer.state.guestName;
}

export function getLiveSessionColor(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return;
  }

  return state.liveSessionReducer.state.color;
}

export function getLiveSessionToken(state: RootReducer) {
  return state.liveSessionReducer.state?.token;
}

export function getLiveSessionHostId(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return;
  }

  return state.liveSessionReducer.state.host;
}

export function getLiveSessionVideoId(state: RootReducer) {
  if (
    state.liveSessionReducer.state?.type !== 'joined' &&
    state.liveSessionReducer.state?.type !== 'terminated'
  ) {
    return;
  }

  return state.liveSessionReducer.state.video?.id;
}

export function getLiveSessionGrantsType(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return;
  }

  return state.liveSessionReducer.state.grants.type;
}

export function getLiveSessionIsHost(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return false;
  }

  return state.liveSessionReducer.state.host === state.authReducer.me?.id;
}

export function getLiveSessionUserMap(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return {};
  }

  return state.liveSessionReducer.state.allUsers;
}

export function getLiveSessionHost(state: RootReducer) {
  const hostId = getLiveSessionHostId(state);
  const userMap = getLiveSessionUserMap(state);
  if (!hostId) {
    return;
  }

  return userMap[hostId];
}

export function getLiveSessionMessages(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return [];
  }

  return state.liveSessionReducer.state.logEvents;
}

export function getLiveSessionChatMembers(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return [];
  }

  const { connectedUsers, allUsers } = state.liveSessionReducer.state;
  return Array.from(connectedUsers, (userId) => allUsers[userId]);
}

export function getLiveSessionVideo(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return;
  }

  return state.liveSessionReducer.state.video;
}

export function getLiveSessionVideoState(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return;
  }

  const { playing, position, speed } = state.liveSessionReducer.state.videoState;
  return {
    playing: !!playing,
    position,
    speed,
  };
}

export function getLiveSessionRawVideoState(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return;
  }

  return state.liveSessionReducer.state.videoState;
}

export function getLiveSessionCanvasActivated(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return false;
  }

  return !!state.liveSessionReducer.state.canvas;
}

export function getLiveSessionCanvasObjects(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined' || !state.liveSessionReducer.state.canvas) {
    return [];
  }

  return Object.values(state.liveSessionReducer.state.canvas);
}

export function getLiveSessionCanDraw(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return false;
  }

  return (state.liveSessionReducer.state.grants.type === 'host' ||
    state.liveSessionReducer.state.grants.grants.includes('annotate')
  );
}

export function getLiveSessionIsDrawingAllowed(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return false;
  }

  return (
    state.liveSessionReducer.state.grants.type === 'host' &&
    !!state.liveSessionReducer.state.grants.globalGrants?.includes('annotate')
  );
}

export function getLiveSessionDrawingAllowedUsers(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined' || state.liveSessionReducer.state.grants.type !== 'host') {
    return [];
  }

  const grantedUsers = state.liveSessionReducer.state.grants.perUserGrants;

  return Object.keys(grantedUsers).filter(userId => grantedUsers[userId].includes('annotate'));
}

export function getLiveSessionUnreadMessages(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return 0;
  }

  return state.liveSessionReducer.state.logEvents.length - state.liveSessionReducer.state.readMessageCount;
}

export function getLiveSessionNonGuestUsers(state: RootReducer) {
  if (state.liveSessionReducer.state?.type !== 'joined') {
    return [];
  }

  const connectedUsers = new Set(state.liveSessionReducer.state.connectedUsers);
  return Object.values(state.liveSessionReducer.state.allUsers)
    .filter((user) => connectedUsers.has(user.id) && !user.guest);
}

function getLiveSessionUser(state: RootReducer, userId: string) {
  return getLiveSessionUserMap(state)[userId];
}

export const makeGetLiveSessionUser = () => createSelector(
  [getLiveSessionUser],
  (user) => user,
);
