import { Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { LoginNS } from 'locales/en/login';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FORGOT_PASSWORD_PATH, SIGNIN_PATH } from 'routes';

import { LoginDialogContext } from './LoginDialogContext';
import SignupAndVerifyDialogContentLayout from './SignupAndVerifyDialogContentLayout';

export interface SignupVerifyDialogContentOwnProps {}

export type SignupVerifyDialogContentProps = SignupVerifyDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  verifyEmailText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '&>svg': {
      marginRight: theme.spacing(1),
    },
  },
  emailExists: {
    fontSize: '12px',
    textAlign: 'center',
    color: theme.palette.text.deemphasized,
  },
  link: {
    textDecoration: 'none',
  },
}), {name: 'SignupVerifyDialogContent'});

function SignupVerifyDialogContent(props: SignupVerifyDialogContentProps) {
  const classes = useStyles(props);

  const { t } = useStrictTranslation('login');

  const { loginState } = useContext(LoginDialogContext);

  return (
    <SignupAndVerifyDialogContentLayout
    className={classes.root}
    title={t('verifyemail')}
    >
      <Box>
        <Box className={classes.verifyEmailText}>
          <CheckCircleIcon />{t('checkemail')}
        </Box>
        <Box className={classes.emailExists}>
          <Trans
          ns={LoginNS}
          i18nKey={'existingemail'}
          >
            <Link to={{ pathname: FORGOT_PASSWORD_PATH, state: loginState }}>here</Link>
          </Trans>
        </Box>
        <Box my={1}>
          <Link
          to={{ pathname: SIGNIN_PATH, state: loginState }}
          className={classes.link}
          >
            <Button
            fullWidth={true}
            variant='contained'
            color='primary'
            >
              {t('backtologin')}
            </Button>
          </Link>
        </Box>
      </Box>
    </SignupAndVerifyDialogContentLayout>
  );
}

export default React.memo(SignupVerifyDialogContent);
