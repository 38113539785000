import gql from 'graphql-tag';

export const UpdatePrimaryRoster_Mutation = gql`
    mutation UpdatePrimaryRosterMutation(
        $input: UpdatePrimaryRosterInput!
    ) {
        updatePrimaryRoster(
            input: $input
        ) {
            roster {
                id
            }
        }
    }
`;
