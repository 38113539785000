import { useCreateSelector } from '@insights-gaming/redux-utils';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ID } from 'types/pigeon';

import { SavedCardFragment } from './../../../apollo/fragments/types/SavedCardFragment';
import { makeGetTeamCardsByTeamId } from './dashboard-billing.selectors';
import { fetchTeamCardsAC } from './dashboard-billing-slice';

export default function useTeamCardDetails(
  teamId?: ID,
): [SavedCardFragment[] | undefined, boolean] {
  const dispatch = useDispatch();
  const [ teamCards, teamCardsFetched ] = useCreateSelector(makeGetTeamCardsByTeamId, teamId);

  useEffect(() => {
    if (!teamCardsFetched && teamId) {
      dispatch(fetchTeamCardsAC.started({ teamId }));
    }
  }, [dispatch, teamCardsFetched, teamId]);

  return [ teamCards, teamCardsFetched ];
};
