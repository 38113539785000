import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import { VideoplayerF, VideoplayerNS } from 'locales/en/videoplayer';
import { bindMenu, bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ControlsGenericButton from '../controls/ControlsGenericButton';
import { EToolSize } from './useDrawingOverlayController';
import { DrawingState } from './useDrawingState';

export interface DrawingToolbarSizePickerButtonProps {
  state: DrawingState;
  disabled: boolean;
}

function DrawingToolbarSizePickerButton({
  state: { setSize },
  disabled,
}: DrawingToolbarSizePickerButtonProps) {
  const { t } = useTranslation(VideoplayerNS);
  const popupState = usePopupState({ popupId: 'stroke-width', variant: 'popover' });
  const { onClose } = bindPopover(popupState);

  const setToolSize = useCallback((size: number) => {
    onClose();
    setSize(size);
  }, [onClose, setSize]);

  const sizeSmall   = useCallback(() => setToolSize(EToolSize.SMALL), [setToolSize]);
  const sizeMedium  = useCallback(() => setToolSize(EToolSize.MEDIUM), [setToolSize]);
  const sizeLarge   = useCallback(() => setToolSize(EToolSize.LARGE), [setToolSize]);

  return (
    <React.Fragment>
      <ControlsGenericButton disabled={disabled} {...bindTrigger(popupState)}>
        <LineWeightIcon />
      </ControlsGenericButton>
      <Menu
      {...bindMenu(popupState)}
      disablePortal={true}
      style={{ cursor: 'default' }}
      >
        <MenuItem onClick={sizeSmall}>{t(VideoplayerF('tools.small'))}</MenuItem>
        <MenuItem onClick={sizeMedium}>{t(VideoplayerF('tools.medium'))}</MenuItem>
        <MenuItem onClick={sizeLarge}>{t(VideoplayerF('tools.large'))}</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default React.memo(DrawingToolbarSizePickerButton);
