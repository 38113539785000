import { FlexSpacer } from '@insights-gaming/material-components';
import { AvatarWithLetterFallback } from '@insights-gaming/material-components/AvatarWithLetterFallback/AvatarWithLetterFallback';
import { Theme } from '@insights-gaming/theme';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import classNames from 'classnames';
import { MAX_FILE_SIZE } from 'constants/numbers';
import { updateUserAsyncAC } from 'features/auth/auth-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMe } from 'selectors/getMe';
import HiddenFileInput from 'subcomponents/hidden-file-input/HiddenFileInput';

import GuidedSetupActions from './GuidedSetupActions';

interface ProfilePicOwnProps {
  className?: string;
  onClose: VoidFunction;
  stepBackward: VoidFunction;
  stepForward: VoidFunction;
  step: number;
}

type ProfilePicProps = ProfilePicOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  title: {
    position: 'absolute',
    top: 68,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  header2: {
    marginBottom: theme.spacing(3),
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  upload: {
    position: 'absolute',
    top: 208,
    left: 180,
  },
  skip: {
    position: 'absolute',
    bottom: 24,
    left: 24,
    cursor: 'pointer',
  },
  outlined: {
    backgroundColor: theme.palette.background.dialog,
    '&&:hover': {
      backgroundColor: theme.palette.background.dialog,
    },
  },
}), {name: 'ProfilePic'});

const PROFILE_PIC = 'profilepic';

function ProfilePic(props: ProfilePicProps) {
  const classes = useStyles(props);
  const { className, onClose, stepBackward, stepForward, step } = props;
  const me = useSelector(getMe);
  const { t } = useStrictTranslation(['dialog', 'common']);
  const [ loading, setLoading ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const handleFileChange = useCallback(async(event: any) => {
    if (loading) { return; }
    const file: File = event.target.files[0];
    if (!file) { return; }

    if (file.size > MAX_FILE_SIZE) {
      enqueueSnackbar(t('common:picexceedsizelimit', {bytes: MAX_FILE_SIZE}), {variant: 'warning'});
      return;
    }

    setLoading(true);

    try {
      await promiseSagaDispatch(updateUserAsyncAC, { picture: file });
    } catch {
      enqueueSnackbar(t('common:uploadfailure', {variant: 'error'}));
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, loading, promiseSagaDispatch, t]);

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.title}>
        <Typography variant='h3' className={classes.header2} align='center'>
          {t('dialog:onboarding.upload')}
        </Typography>
        <Typography variant='h6' className={classes.nowrap}>
          {t('dialog:onboarding.uploadtext')}
        </Typography>
      </div>
      <FlexSpacer flexAlignItems='center' className={classes.upload} spacing={2}>
        <AvatarWithLetterFallback name={me?.name || 'new'} src={me?.picture || null} size='xl' />
        <HiddenFileInput
        id={PROFILE_PIC}
        accept='image/*'
        trigger={(
          <Button
          component='label'
          disabled={loading}
          htmlFor={PROFILE_PIC}
          variant='outlined'
          classes={{outlined: classes.outlined}}
          startIcon={<ArrowUpwardIcon/>}
          >
            {t('common:upload')}
          </Button>
        )}
        onChange={handleFileChange}
        />
      </FlexSpacer>
      <Typography variant='caption' className={classes.skip} onClick={onClose}>
        {t('dialog:onboarding.skip')}
      </Typography>
      <GuidedSetupActions
      loading={loading}
      stepBackward={stepBackward}
      stepForward={stepForward}
      step={step}
      />
    </div>
  );
}

export default React.memo(ProfilePic);
