import { NotificationFragment } from 'apollo/fragments/types/NotificationFragment';
import React from 'react';

import CommentNotificationMessage from './notification-message/CommentNotificationMessage';
import MemberNotificationMessage from './notification-message/MemberNotificationMessage';
import VideoNotificationMessage from './notification-message/VideoNotificationMessage';

interface NotificationMessageProps {
  notifications: NotificationFragment[];
}

function notificationMessageComponent(
  notifications: NotificationFragment[],
): React.ElementType<{ notifications: NotificationFragment[] }> {
  const [firstNotification] = notifications;
  switch (firstNotification.__typename) {
    case 'CommentNotification':
      return CommentNotificationMessage;

    case 'MemberNotification':
      return MemberNotificationMessage;

    case 'VideoNotification':
      return VideoNotificationMessage;
  }
}

function NotificationMessage({ notifications }: NotificationMessageProps) {
  return React.createElement(notificationMessageComponent(notifications), { notifications });
}

export default React.memo(NotificationMessage);
