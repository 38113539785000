import ShowLoadingScreen from 'features/loading-screen/ShowLoadingScreen';
import React from 'react';

import { useFetchGlobalRoles } from './useFetchGlobalRoles';

function GlobalRoleFetcher() {
  const [status] = useFetchGlobalRoles({deprecated: false});
  return status.fetching && !status.cursor ? <ShowLoadingScreen/> : null;
}

export default React.memo(GlobalRoleFetcher);
