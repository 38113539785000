import { Context, CustomCommands, Spec } from 'immutability-helper';

import { RosterPlayersFragment } from '../../apollo/fragments/types/RosterPlayersFragment';
import { GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics_OverwatchStatistics } from '../../apollo/queries/types/GetOverwatchPrimaryRosterPlayerStatisticsV2Query';
import { GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query_statistics } from '../../apollo/queries/types/GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query';
import { GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics } from '../../apollo/queries/types/GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query';
import { ID, Match, TeamEdge, Video } from '../pigeon';
import { PagedSet } from './paged-set';

export type VideoCache       = ReduxCache<ID, Video>;
export type MatchCache       = ReduxCache<ID, Match>;
export type TeamCache        = ReduxCache<ID, TeamEdge>;
export type RosterCache      = ReduxCache<ID, RosterPlayersFragment>;
export type PlayerStatsCache = ReduxCache<
  ID,
  GetOverwatchPrimaryRosterPlayerStatisticsV2Query_statistics_OverwatchStatistics
>;
export type CompositionStatsCache = ReduxCache<
  ID,
  GetOverwatchPrimaryRosterTeamFightCompositionStatisticsV2Query_statistics
>;
export type MapStatsCache = ReduxCache<
  ID,
  GetOverwatchPrimaryRosterTeamFightMapStatisticsV2Query_statistics
>;
export type CacheExtractor<T> = T extends ReduxCache<infer K, infer V> ? (cache: T, keys: K[]) => V[] : never;
export function getFromCacheWithKeys<K, V>(cache: ReduxCache<K, V>, keys: K[]): V[] {
  return cache.getMulti(keys);
}

export type CacheExtractor2<T> = T extends ReduxCache<infer K, infer V> ? (cache: T, keys: PagedSet<K>) => V[] : never;
export function getFromCacheWithPagedSet<K, V>(cache: ReduxCache<K, V>, keys: PagedSet<K>): V[] {
  return getFromCacheWithKeys(cache, Array.from(keys.values()));
}

export type CacheExpander<T> = T extends ReduxCache<infer _, infer V> ? (cache: T) => V[] : never;
export function getAllValuesFromCache<K, V>(cache: ReduxCache<K, V>): V[] {
  return Array.from(cache.values());
}

export class ReduxCache<K, V> implements Map<K, V> {
  private _dictionary: Map<K, V>;
  private _timeOfEntry: Map<K, number>;

  constructor(entries?: ReadonlyArray<[K, V]> | null) {
    this._dictionary = new Map(entries);
    this._timeOfEntry = new Map();
    if (entries) {
      const now = Date.now();
      entries.forEach(([k, v]: [K, V]) => {
        this._timeOfEntry.set(k, now);
      });
    }
  }

  public clone(): ReduxCache<K, V> {
    const c = new ReduxCache<K, V>();
    c._dictionary = new Map(this._dictionary);
    c._timeOfEntry = new Map(this._timeOfEntry);
    return c;
  }

  public clear(): void {
    this._dictionary.clear();
    this._timeOfEntry.clear();
  }

  public delete(key: K): boolean {
    const d = this._dictionary.delete(key);
    this._timeOfEntry.delete(key);
    return d;
  }

  public forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
    this._dictionary.forEach(callbackfn);
  }

  public get(key: K): V | undefined {
    return this._dictionary.get(key);
  }

  public getMulti(keys: K[]): V[] {
    return keys.map((k: K) => this.get(k)).filter((v?: V): v is V => !!v);
  }

  public has(key: K): boolean {
    return this._dictionary.has(key);
  }

  public set(key: K, value: V, time?: number): this {
    this._dictionary.set(key, value);
    time = typeof time === 'number' ? time : Date.now();
    this._timeOfEntry.set(key, time);
    return this;
  }

  public entries(): IterableIterator<[K, V]> {
    return this._dictionary.entries();
  }

  public keys(): IterableIterator<K> {
    return this._dictionary.keys();
  }

  public values(): IterableIterator<V> {
    return this._dictionary.values();
  }

  public get size(): number {
    return this._dictionary.size;
  }

  public isStale(key: K, ttl: number = 900000): boolean {
    const now = Date.now();
    const time = this._timeOfEntry.get(key);
    if (!time) { return true; }
    return (time + ttl) < now;
  }

  public [Symbol.iterator](): IterableIterator<[K, V]> {
    return this._dictionary[Symbol.iterator]();
  }

  public get [Symbol.toStringTag](): string {
    return 'ReduxCache';
  }
}

const cacheContext = new Context();
cacheContext.extend<ReduxCache<any, any>>('$customAdd', (value: [[any, any]], original: ReduxCache<any, any>) => {
  const clone = original.clone();
  value.forEach(([k, v]) => clone.set(k, v));
  return clone;
});

export interface ICustomInsertSpec<T = any> {
  $customAdd: Array<[ID, T]>;
}

cacheContext.extend<ReduxCache<any, any>>('$customRemove', (value: [any], original: ReduxCache<any, any>) => {
  const clone = original.clone();
  value.forEach((k) => clone.delete(k));
  return clone;
});

export interface ICustomDeleteSpec {
  $customRemove: ID[];
}

cacheContext.extend<ReduxCache<any, any>>('$update', (value: [[any, any]], original: ReduxCache<any, any>) => {
  const clone = original.clone();
  value.forEach(([k, v]) => {
    const o = clone.get(k);
    if (!o) { return; }
    const n = cacheContext.update(o, {$merge: v});
    clone.set(k, n);
  });
  return clone;
});

export interface ICustomUpdateSpec<T = any> {
  $update: Array<[ID, Partial<T>]>
}

type MyCustomCommands<U> = ICustomInsertSpec<U> | ICustomDeleteSpec | ICustomUpdateSpec<U>;

export function myUpdate<T, U>(object: T, spec: Spec<T, CustomCommands<MyCustomCommands<U>>>) {
  return cacheContext.update<T, CustomCommands<MyCustomCommands<U>>>(object, spec);
}
