import { FlexSpacer, StyledIconButton } from '@insights-gaming/material-components';
import Button, { ButtonProps } from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getMapTabSelection, setMapTabSelectionAC } from 'subcomponents/video-player-3/drawing-tools-2/drawing-tool-slice';

import { useIsDesktop } from '../../../../features/media-queries/hooks';
import { GameType } from '../../../../helpers';
import { OverwatchF, OverwatchNS, TOverwatch } from '../../../../locales/en/overwatch';
import DialogTitleWithOptionalCloseIcon from '../../../../material/dialog-title-with-optional-close-icon/DialogTitleWithOptionalCloseIcon';
import { ANNOTATION_MAPS, AnnotationMap } from '../map-images';
import MapCard, { getIconForGamemode } from './MapCard';

export interface MapDialogOwnProps {
  onClose: VoidFunction;
  onMapSelected: (map: AnnotationMap) => void;
}

type Props = MapDialogOwnProps;

function MapDialogContent(props: Props) {
  const { onClose, onMapSelected } = props;
  // @ts-ignore
  const { t } = useStrictTranslation([
    'videoplayer',
    'common',
    'overwatch',
    'leagueoflegends',
    'fortnite',
    'rocketleague',
    'rainbow-six-siege',
    'pubg',
    'boards',
  ]);

  const dispatch = useDispatch();

  const mapGame = useSelector(getMapTabSelection);
  const [ mapMode, _setMapMode ] = useState<string>('');

  const setMapMode = useCallback((mode: string) => {
    _setMapMode(m => m !== mode ? mode : '');
  }, []);

  const setMapGame = useCallback((e: React.ChangeEvent<HTMLButtonElement>, game: GameType) => {
    dispatch(setMapTabSelectionAC(game));
    setMapMode('');
  }, [dispatch, setMapMode]);

  const maps = useMemo(() => {
    return ANNOTATION_MAPS.filter(m => m.game === mapGame && (!mapMode || m.gamemode === mapMode));
  }, [mapGame, mapMode]);

  const handleOnMapSelected = (map: AnnotationMap) => {
    onMapSelected(map);
    onClose();
  };

  const renderMapGameModes = useCallback(() => {
    if (mapGame === 'overwatch') {
      return (
        gamemodes.map(m => (
          <MapFilterButton
          key={m}
          gamemode={m}
          selected={m === mapMode}
          onSetMapMode={setMapMode}
          />
        ))
      );
    } else if (mapGame === 'leagueoflegends') {
      return (
        <React.Fragment>
          <Button {...selectedProps}>{t('leagueoflegends:gamemodes.battlearena')}</Button>
        </React.Fragment>
      );
    } else {
      return <Button {...selectedProps}>{t('common:all')}</Button>;
    }
  }, [mapGame, mapMode, setMapMode, t]);

  return (
    <React.Fragment>
      <DialogTitleWithOptionalCloseIcon onClose={onClose}>
        {t(('videoplayer:maps.title'))}
      </DialogTitleWithOptionalCloseIcon>
      <DialogContent style={{height: '100vh' /* Keeps dialog same size regardless of content */}}>
        <FlexSpacer orientation='vertical'>
          <Tabs
          value={mapGame}
          onChange={setMapGame}
          variant='scrollable'
          >
            <Tab value='overwatch' label={t('overwatch:name')} />
            <Tab value='valorant' label={t('common:valorant')} />
            <Tab value='leagueoflegends' label={t('leagueoflegends:name')} />
            <Tab value='ssbu' label={t('common:ssbu')} />
            <Tab value='fortnite' label={t('fortnite:name')} />
            <Tab value='rocket-league' label={t('rocketleague:name')} />
            <Tab value='rainbow-six-siege' label={t('rainbow-six-siege:name')} />
            <Tab value='pubg' label={t('pubg:name')} />
            <Tab value='board' label={t('boards:name')} />
          </Tabs>
          <FlexSpacer>
            {renderMapGameModes()}
          </FlexSpacer>
          <div>
            <Grid container={true} spacing={2}>
              {maps.map(m => (
                <MapCard key={m.key} map={m} handleOnMapSelected={handleOnMapSelected} />
              ))}
            </Grid>
          </div>
        </FlexSpacer>
      </DialogContent>
    </React.Fragment>
  );
}

const selectedProps: ButtonProps = {variant: 'contained', color: 'primary'};
const regularProps: ButtonProps = {variant: 'outlined'};

export default React.memo(MapDialogContent);

const gamemodes: TOverwatch[] = [
  'gamemodes.assault',
  'gamemodes.control',
  'gamemodes.escort',
  'gamemodes.hybrid',
];

function MapFilterButton(props: {
  selected?: boolean,
  gamemode: TOverwatch;
  onSetMapMode: (gamemode: string) => void;
}) {
  const {
    selected = false,
    gamemode,
    onSetMapMode,
  } = props;

  const buttonProps = useMemo(() => selected ? selectedProps: regularProps, [selected]);

  const { t } = useTranslation(OverwatchNS);
  const isDesktop = useIsDesktop();
  const onClick = useCallback(() => {
    onSetMapMode(gamemode);
  }, [gamemode, onSetMapMode]);

  return isDesktop ? (
    <Button
    {...buttonProps}
    onClick={onClick}
    startIcon={(
      <SvgIcon>
        {getIconForGamemode(gamemode)}
      </SvgIcon>
    )}
    >
      {t(OverwatchF(gamemode))}
    </Button>
  ) : (
    <StyledIconButton
    variant={selected ? 'contained' : undefined}
    color={selected ? 'primary' : undefined}
    onClick={onClick}
    >
      <SvgIcon>
        {getIconForGamemode(gamemode)}
      </SvgIcon>
    </StyledIconButton>
  );
}
