import { CommentFragment_CommentReply, CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import { ProfileFragment } from 'apollo/fragments/types/ProfileFragment';
import { isExistent } from 'helpers';
import { compareCommentDateAscending, compareCommentsByTimestampAscending } from 'helpers/comparators';
import { createSelector } from 'reselect';
import { RootReducer } from 'rootReducer';
import { ID } from 'types/pigeon';

function getVideoCommentRecordsByVideoId(state: RootReducer, props: {videoId: ID}) {
  return state.commentReducer?.videoCommentRecords?.[props.videoId];
}

function getCommentRepliesRecordByCommentId(state: RootReducer, props: {videoId: ID, commentId: ID}) {
  return state.commentReducer.videoCommentRepliesRecords[props.commentId];
}

function getCommentTagsRecordsByCommentId(state: RootReducer, props: { commentId: ID }) {
  return state.commentReducer.videoCommentTagRecords[props.commentId];
}

function getVideoCommentDict(state: RootReducer) {
  return state.commentReducer?.videoCommentDict;
}

export function getVideoCommentsByIds(state: RootReducer, commentIds: ID[]) {
  return commentIds.map((id) => state.commentReducer.videoCommentDict[id]);
}

function getFilteredVideoCommentDict(state: RootReducer) {
  return state.commentReducer.filteredVideoCommentDict;
}

function getCommentRepliesDict(state: RootReducer) {
  return state.commentReducer.videoCommentRepliesDict;
}

function getCommentTagsDict(state: RootReducer) {
  return state.commentReducer.videoCommentTagDict;
}

function getHaveFetchedTagsByCommentId(state: RootReducer, props: {commentId: ID}) {
  return state.commentReducer.fetchedTagsCommentIdDict[props.commentId];
}

function getSavedCommentRepliesByCommentId(state: RootReducer, props: {commentId: ID}) {
  return state.commentReducer.savedReplies[props.commentId];
}

function getVideoCommentById(
  state: RootReducer,
  commentId: ID,
): [CommentFragment_VideoComment | undefined, boolean, boolean] {
  return [
    state.commentReducer.videoCommentDict[commentId],
    commentId in state.commentReducer.videoCommentDict,
    state.commentReducer.videoCommentFetching.includes(commentId),
  ];
}

function getCommentReplyById(
  state: RootReducer,
  replyId: ID,
): [CommentFragment_CommentReply | undefined, boolean, boolean] {
  return [
    state.commentReducer.videoCommentRepliesDict[replyId],
    replyId in state.commentReducer.videoCommentRepliesDict,
    state.commentReducer.videoCommentFetching.includes(replyId),
  ];
}

export function getCommentTagRecords(state: RootReducer) {
  return state.commentReducer.videoCommentTagRecords;
}

export const makeGetVideoCommentById = () => createSelector(
  [getVideoCommentById],
  (comment) => comment,
);

export const makeGetCommentReplyById = () => createSelector(
  [getCommentReplyById],
  (reply) => reply,
);

export const makeGetCommentRepliesRecordsByCommentId = () => createSelector(
  [getCommentRepliesRecordByCommentId],
  (records) => records,
);

export const makeGetCommentRepliesByCommentId = () => createSelector(
  [getCommentRepliesRecordByCommentId, getCommentRepliesDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) { return []; }
    return ids.map(id => dict[id]).filter(isExistent)
      .filter(r => !r.deleted).sort(compareCommentDateAscending);
  },
);

export const makeGetVideoCommentRecordsByVideoId = () => createSelector(
  [getVideoCommentRecordsByVideoId],
  (records) => records,
);

export const makeGetNotDeletedVideoCommentsByVideoId = () => createSelector(
  [getVideoCommentRecordsByVideoId, getVideoCommentDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) { return []; }
    return ids.map(id => dict[id]).filter(isExistent)
      .filter(c => !c.deleted).sort(compareCommentsByTimestampAscending);
  },
);

export const makeGetNotDeletedFilteredVideoCommentsByVideoId = () => createSelector(
  [getVideoCommentRecordsByVideoId, getFilteredVideoCommentDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) { return []; }
    return ids.map(id => dict[id]).filter(isExistent)
      .filter(c => !c.deleted).sort(compareCommentsByTimestampAscending);
  },
);

export const makeGetSavedCommentRepliesByCommentId = () => createSelector(
  [getSavedCommentRepliesByCommentId],
  (savedReplies) => savedReplies,
);

export const makeGetCommentTagsRecordsByCommentId = () => createSelector(
  [getCommentTagsRecordsByCommentId],
  (records) => records,
);

export const makeGetCommentTagIdsByCommentId = () => createSelector(
  [getCommentTagsRecordsByCommentId],
  (records) => records ? records.ids : [],
);

export const makeGetHaveFetchedTagsByCommentId = () => createSelector(
  [getHaveFetchedTagsByCommentId],
  (fetched) => fetched,
);

export const makeGetCommentTagsByCommentId = () => createSelector(
  [getCommentTagsRecordsByCommentId, getCommentTagsDict],
  (records, dict) => {
    const ids = records?.ids;
    if (!ids) { return []; }
    return ids.map(id => dict[id]).filter(isExistent);
  },
);

export const getLikedUsersByCommentId = (
  state: RootReducer,
  commentId: ID,
): [ProfileFragment[] | undefined, boolean] => [
  state.commentReducer.videoCommentLikedUsersDict[commentId],
  commentId in state.commentReducer.videoCommentLikedUsersDict,
];

export const makeGetLikedUsersByCommentId = () => createSelector(
  [getLikedUsersByCommentId],
  (likedUsers) => likedUsers,
);

export const makeGetCommentsByIds = () => createSelector(
  [getVideoCommentsByIds],
  (comments) => comments,
);
