import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import classNames from 'classnames';
import React from 'react';

import VideoCommentLayout from '../VideoCommentLayout';

interface VideoCommentSkeletonOwnProps {
  className?: string;
}

type VideoCommentSkeletonProps = VideoCommentSkeletonOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), {name: 'VideoCommentSkeleton'});

function VideoCommentSkeleton(props: VideoCommentSkeletonProps) {
  const classes = useStyles(props);
  const { className } = props;
  return (
    <VideoCommentLayout
    className={classNames(classes.root, className)}
    replyArea={<Skeleton variant='rect' width={62} height={22}/>}
    cardActionArea={(
      <React.Fragment>
        <FlexSpacer flexAlignItems='center' flexJustifyContent='space-between'>
          <FlexSpacer flexAlignItems='center'>
            <Skeleton variant='rect' width={16} height={16}/>
            <Skeleton variant='text' width={92}/>
          </FlexSpacer>
          <FlexSpacer flexAlignItems='center'>
            <Skeleton variant='text' width={62}/>
            <Skeleton variant='circle' width={16} height={16}/>
          </FlexSpacer>
        </FlexSpacer>
        <Skeleton variant='text' />
        <Skeleton variant='text' width={'80%'}/>
      </React.Fragment>
    )}
    timestampArea={<Skeleton variant='rect' height={22} width={62}/>}
    />
  );
}

export default React.memo(VideoCommentSkeleton);
