import { AsyncButton } from '@insights-gaming/material-components/AsyncButton';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EmailIcon from '@material-ui/icons/Email';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import { MAX_EMAIL_LENGTH } from 'constants/numbers';
import { sendPasswordResetEmailAC } from 'features/auth/auth-slice';
import { createTitle, prepareEmail } from 'helpers';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import IconTextField from 'material/icon-text-field/IconTextField';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useState } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { SIGNIN_PATH } from 'routes';

import { LoginDialogContext } from './LoginDialogContext';

export interface ForgotPasswordDialogContentOwnProps {}

export type ForgotPasswordDialogContentProps = ForgotPasswordDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  titleText: {
    fontSize: createRemFromPx(18),
  },
  dialogTitle: {
    padding: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    textDecoration: 'none',
  },
}), {name: 'ForgotPasswordDialogContent'});

function ForgotPasswordDialogContent(props: ForgotPasswordDialogContentProps) {
  const classes = useStyles(props);

  const { t } = useStrictTranslation(['common', 'login']);

  const { loginState } = useContext(LoginDialogContext);
  const { enqueueSnackbar } = useSnackbar();
  const promiseSagaDispatch = usePromiseSagaDispatch();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const handleForgotPassword = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault();
    if (loading || done) {
      return;
    }

    if (!email) {
      return;
    }

    setLoading(true);

    promiseSagaDispatch(sendPasswordResetEmailAC, prepareEmail(email))
      .then(
        () => {
          setDone(true);
          enqueueSnackbar(t('login:emailsent'), { variant: 'success' });
        },
        () => enqueueSnackbar(t('login:errors.resetfailed'), { variant: 'error' }),
      )
      .finally(() => setLoading(false));
  }, [done, email, enqueueSnackbar, loading, promiseSagaDispatch, t]);

  const handleInputOnChange = useCallback((e: React.SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEmail(value);
  }, []);

  return (
    <Box className={classes.root}>
      <Helmet>
        <title>{createTitle(t('common:appname'), t('login:forgotpw'))}</title>
      </Helmet>
      <DialogTitle disableTypography={true} className={classes.dialogTitle}>
        <Link
        to={{ pathname: SIGNIN_PATH, state: loginState }}
        className={classes.link}
        >
          <IconButton>
            <ArrowBackIosIcon fontSize='small' />
          </IconButton>
        </Link>
        <Typography className={classes.titleText}>
          {t('login:forgotpw')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box>{t('login:forgotpwDescription')}</Box>
        <form onSubmit={handleForgotPassword}>
          <IconTextField
          icon={<EmailIcon />}
          name='username'
          type='email'
          label={t('login:email')}
          placeholder={t('login:email')}
          fullWidth={true}
          onChange={handleInputOnChange}
          error={!!(email && email.length > MAX_EMAIL_LENGTH)}
          required={true}
          autoFocus={true}
          />
          <Box my={1.5} className={classes.flexEnd}>
            <AsyncButton
            type='submit'
            variant='contained'
            color='primary'
            loading={loading}
            disabled={loading || done}
            >
              {t('login:sendlink')}
            </AsyncButton>
          </Box>
          {/* <Box display='flex' justifyContent='flex-end'>
            <Link
            to={{ pathname: SIGNIN_PATH, state: loginState }}
            className={classes.link}
            >
              <Button size='small' startIcon={<ArrowBackIosIcon fontSize='small' />}>
                {t('login:goback')}
              </Button>
            </Link>
          </Box> */}
        </form>
      </DialogContent>
    </Box>
  );
}

export default React.memo(ForgotPasswordDialogContent);
