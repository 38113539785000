import { useMemo, useState } from 'react';

import { MobileDrawingToolbarContextState } from './MobileDrawingToolbarContext';

export function useMobileDrawingToolbarContext(): MobileDrawingToolbarContextState {
  const [ mobileToolbarElement, mobileToolbarRef] = useState<Element | null>(null);
  const [ mobileToggleElement, mobileToggleRef ] = useState<Element | null>(null);

  return useMemo(() => ({
    mobileToggleElement,
    mobileToolbarElement,
    mobileToggleRef,
    mobileToolbarRef,
  }), [mobileToggleElement, mobileToolbarElement]);
}
