import { DrawingTool } from '@insights-gaming/drawing-canvas';
import { AsyncButton, FlexSpacer, RichTextEditor, VideoReplayContext } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import { SecondsFormatter } from '@insights-gaming/utils';
import IconButton from '@material-ui/core/IconButton';
import { createStyles,makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import ShareIcon from '@material-ui/icons/Share';
import TimerIcon from '@material-ui/icons/Timer';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { addVideoCommentAC } from 'actions/comment-actions';
import { TagFragment } from 'apollo/fragments/types/TagFragment';
import classNames from 'classnames';
import { getAudioCommentRecording } from 'components/video/video-replay/video-comment/audio-comment/audio-comment-selectors';
import { resetAudioRecorder, startAudioRecordingAC, stopAudioRecordingAC } from 'components/video/video-replay/video-comment/audio-comment/audio-comment-slice';
import AudioCommentContent from 'components/video/video-replay/video-comment/audio-comment/AudioCommentContent';
import AudioCommentTrigger from 'components/video/video-replay/video-comment/audio-comment/AudioCommentTrigger';
import MicrophoneAccessDeniedDialog from 'components/video/video-replay/video-comment/audio-comment/MicrophoneAccessDeniedDialog';
import RecordingIndicator from 'components/video/video-replay/video-comment/audio-comment/RecordingIndicator';
import CommentLabelEditor from 'components/video/video-replay/video-comment/comment-label/comment-label-editor/CommentLabelEditor';
import SelectedLabelToAdd from 'components/video/video-replay/video-comment/comment-label/comment-label-editor/SelectedLabelToAdd';
import { MAX_LABEL_TO_SHOW_MOBILE } from 'constants/numbers';
import { convertToRaw, Editor, EditorState } from 'draft-js';
import { makeGetTeamMembersByTeamId } from 'features/dashboard/member/dashboard-member-selector';
import { makeGetVideoById } from 'features/dashboard/video/dashboard-video-selector';
import { getLiveSessionVideo } from 'features/live-session/live-session-selector';
import { mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useIsDesktop, useIsMobilePortrait } from 'features/media-queries/hooks';
import { MobileDrawingToolbarContext } from 'features/mobile-drawing-toolbar/MobileDrawingToolbarContext';
import { DrawingToolContext } from 'features/video-replay/DrawingToolContext';
import { VideoCommentMenuContext } from 'features/video-replay/VideoCommentMenuContext';
import { useDialogState } from 'hooks/useDialogState';
import { useKeybindings } from 'hooks/useKeybindings';
import { useMentionAutoCompleteMatcher } from 'hooks/useMentionAutoCompleteMatcher';
import { promisifyDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import update from 'immutability-helper';
import { buildKeystring } from 'keybindings';
import ShareVideoDialog from 'material/dialogs/share-video-dialog/ShareVideoDialog';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decorator } from 'subcomponents/text-editor-with-tools/strategies/strategies';
import { ID } from 'types/pigeon';

import { RangedTimestampContext } from '../ranged-timestamp/RangedTimestampContext';

interface MobileCommentBoxOwnProps {
  className?: string;
  teamId?: ID;
  videoId: ID;
}

type MobileCommentBoxProps = MobileCommentBoxOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  mobileCommentBox: {
    [mobilePortrait(theme)]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      padding: theme.spacing(1, 1.5),
      width: '100%',
      backgroundColor: theme.palette.background.container,
      zIndex: theme.zIndex.modal - 100,
    },
    [mobileLandscape(theme)]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      padding: theme.spacing(1, 1.5),
      width: '100%',
      backgroundColor: theme.palette.background.container,
      zIndex: theme.zIndex.modal - 100,
    },
  },
  mobileDrawingTools: {
    overflow: 'hidden',
  },
  mobileDrawingToggle: {
    marginRight: 'auto',
  },
  moreLabelsContainer: {
    padding: theme.spacing(1),
  },
  closeIcon: {
    color: theme.palette.error.main,
  },
  textFieldContainer: {
    position: 'relative',
  },
  audioButton: {
    position: 'absolute',
    right: theme.spacing(1),
  },
  audioCommentContent: {
    flexGrow: 1,
  },
  sendButton: {
    '& .MuiButton-root': {
      borderRadius: '50%',
      minWidth: theme.spacing(4),
      width: theme.spacing(4),
    },
  },
  mobileTimestamp: {
    opacity: 0.6,
  },
  hide: {
    display: 'none',
  },
}));

function MobileCommentBox(props: MobileCommentBoxProps) {
  const classes = useStyles(props);
  const { className, teamId, videoId } = props;

  const editorRef = useRef<Editor | null>(null);
  const { t } = useStrictTranslation(['video', 'common']);
  const dispatch = useDispatch();
  const promiseSagaDispatch = useMemo(() => promisifyDispatch(dispatch), [dispatch]);
  const { enqueueSnackbar } = useSnackbar();
  const { addContext, removeContext } = useKeybindings('editor.text.focus');
  const { mobileToggleRef, mobileToolbarRef } = useContext(MobileDrawingToolbarContext);

  const [isShareVideoDialogOpen, openShareVideoDialog, closeShareVideoDialog] = useDialogState();
  const video = useCreateSelector(makeGetVideoById, videoId);

  const {
    pause,
    state: {
      progress: { playedSeconds },
    },
  } = useContext(VideoReplayContext);

  const {
    isTimeInvalid,
    startTimeNumericValue,
  } = useContext(RangedTimestampContext);

  const {
    autoPauseTyping,
  } = useContext(VideoCommentMenuContext);

  const members = useCreateSelector(makeGetTeamMembersByTeamId, teamId);
  const memberUsers = useMemo(() => members.map(m => m.user), [members]);
  const mentionAutoCompleteMatcher = useMentionAutoCompleteMatcher(memberUsers);

  const liveSessionVideo = useSelector(getLiveSessionVideo);

  const autoCompleteMatchers = useMemo(() => {
    return [
      mentionAutoCompleteMatcher,
    ];
  }, [mentionAutoCompleteMatcher]);

  const { drawingState, resetTool } = useContext(DrawingToolContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedLabels, setSelectedLabels] = useState<Set<TagFragment>>(new Set<TagFragment>());

  const handleAddLabel = useCallback((label: TagFragment) => {
    setSelectedLabels(selectedLabels => update(selectedLabels, {$add: [label]}));
  }, []);

  const handleRemoveLabel = useCallback((label: TagFragment) => {
    setSelectedLabels(selectedLabels => update(selectedLabels, {$remove: [label]}));
  }, []);

  const recording = useSelector(getAudioCommentRecording);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const currentContent = useMemo(() => editorState.getCurrentContent(), [editorState]);
  const msg = useMemo(() => currentContent.getPlainText(), [currentContent]);
  const emptyMessage = !msg.length || !msg.trim().length;

  const handleEditorChange = useCallback((editorState: EditorState) => {
    setEditorState(editorState);
  }, []);

  const disabled = useMemo(() => {
    if (isTimeInvalid || recording?.state === 'recording') {
      return true;
    }
    if (recording?.state === 'saved') {
      return false;
    }
    return emptyMessage;
  }, [emptyMessage, isTimeInvalid, recording]);

  const handleSendComment = useCallback(async () => {
    if (disabled || loading) {
      return;
    }

    setLoading(true);

    try {
      const serializedCanvas = await drawingState.serializedCanvas();
      const labelIds = Array.from(selectedLabels).map(label => label.id);
      await promiseSagaDispatch(addVideoCommentAC, {
        videoId,
        time: startTimeNumericValue,
        message: !recording ? JSON.stringify(convertToRaw(currentContent)) : undefined,
        recording: recording?.state === 'saved' ? recording.recording : undefined,
        annotation: drawingState.hasObjects ? serializedCanvas : undefined,
        tagIds: labelIds,
      });
      editorRef.current!.blur();
      setEditorState(EditorState.createEmpty(decorator));
      resetTool();
      dispatch(resetAudioRecorder());
    } catch (error) {
      editorRef.current!.blur();
      setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty(decorator)));
      enqueueSnackbar(error.message, {variant: 'error'});
    } finally {
      setLoading(false);
      setSelectedLabels(new Set<TagFragment>());
      removeContext();
    }
  }, [
    currentContent,
    disabled,
    dispatch,
    drawingState,
    enqueueSnackbar,
    loading,
    promiseSagaDispatch,
    recording,
    removeContext,
    resetTool,
    selectedLabels,
    startTimeNumericValue,
    videoId,
  ]);

  const handleEditorOnFocus = useCallback(() => {
    addContext();
    if (autoPauseTyping) {
      pause();
    }
  }, [addContext, autoPauseTyping, pause]);

  const handleEditorOnBlur = useCallback(() => {
    removeContext();
  }, [removeContext]);

  const isDesktop = useIsDesktop();
  const isMobilePortrait = useIsMobilePortrait();

  const customKeyBindFn = useCallback((e: React.KeyboardEvent) => {
    switch (buildKeystring(e.nativeEvent)) {
      case 'ctrl+enter':
        return 'submit_comment';
    }
    return null;
  }, []);

  const customKeyCommands = useMemo(() => {
    return new Map([['submit_comment', handleSendComment]]);
  }, [handleSendComment]);

  const renderWarningMessage = useMemo(() => {
    return !recording && drawingState.hasObjects && emptyMessage;
  }, [drawingState.hasObjects, emptyMessage, recording]);

  const saveRecording = useCallback(() => dispatch(stopAudioRecordingAC.started()), [dispatch]);
  const discardRecording = useCallback(() => dispatch(stopAudioRecordingAC.started(true)), [dispatch]);
  const handleRecordOnClick = useCallback(() => {
    dispatch(startAudioRecordingAC.started());
    if (drawingState.enabled && !liveSessionVideo) {
      drawingState.reset();
      drawingState.setTool(DrawingTool.NONE);
    }
  }, [dispatch, drawingState, liveSessionVideo]);
  const resetAudioRecorderHandler = useCallback(() => dispatch(resetAudioRecorder()), [dispatch]);

  if (isMobilePortrait) {
    return (
      <FlexSpacer orientation='vertical' className={classes.mobileCommentBox}>
        {renderWarningMessage && (
          <FlexSpacer flexAlignItems='center'>
            <WarningRoundedIcon />
            <Typography variant='caption'>
              {t('video:replay.warnNoMessage')}
            </Typography>
          </FlexSpacer>
        )}

        <FlexSpacer
        flexAlignItems='center'
        flexJustifyContent='space-between'
        className={classNames({[classes.hide]: disabled})}
        >
          <FlexSpacer flexAlignItems='center'>
            {teamId && (
              <CommentLabelEditor
              teamId={teamId}
              handleAddLabel={handleAddLabel}
              selectedLabelsToAdd={selectedLabels}
              />
            )}
            {Array.from(selectedLabels).map((label, i) => i < MAX_LABEL_TO_SHOW_MOBILE && (
              <SelectedLabelToAdd
              key={label.id}
              label={label}
              handleRemoveLabel={handleRemoveLabel}
              />
            ))}
            {selectedLabels.size > MAX_LABEL_TO_SHOW_MOBILE && (
              <div>
                {t('common:extranumber', {number: selectedLabels.size - MAX_LABEL_TO_SHOW_MOBILE})}
              </div>
            )}
          </FlexSpacer>

          <FlexSpacer
          className={classes.mobileTimestamp}
          flexAlignItems='center'
          flexJustifyContent='center'
          >
            <TimerIcon />
            {SecondsFormatter.format(playedSeconds)}
          </FlexSpacer>
        </FlexSpacer>

        <div
        ref={mobileToolbarRef}
        className={classes.mobileDrawingTools}
        style={{ marginTop: drawingState.enabled ? undefined : 0 }}
        />

        {recording?.state === 'recording' ? (
          <RecordingIndicator saveRecording={saveRecording} discardRecording={discardRecording}/>
        ) : (
          recording?.state === 'saved' ? (
            <FlexSpacer
            fullWidth={true}
            flexAlignItems='center'
            flexJustifyContent='space-between'
            >
              <Tooltip title={t('video:replay.discardrecording')}>
                <IconButton
                onClick={resetAudioRecorderHandler}
                size='small'
                className={classes.closeIcon}
                >
                  <CloseIcon fontSize='small'/>
                </IconButton>
              </Tooltip>

              <AudioCommentContent
              className={classes.audioCommentContent}
              audioSrc={recording.audioSource}
              discardRecording={resetAudioRecorderHandler}
              fill={true}
              />

              <AsyncButton
              className={classes.sendButton}
              variant='contained'
              color='primary'
              disabled={disabled || loading}
              loading={loading}
              onClick={handleSendComment}
              >
                <SendIcon fontSize='small'/>
              </AsyncButton>
            </FlexSpacer>
        ) : (
          <FlexSpacer flexAlignItems='center'>

            <div ref={mobileToggleRef} className={classes.mobileDrawingToggle}/>

            <FlexSpacer
            className={classes.textFieldContainer}
            flexAlignItems='center'
            fullWidth={true}
            >
              <RichTextEditor
              ref={editorRef}
              editorState={editorState}
              onStateChange={handleEditorChange}
              onFocus={handleEditorOnFocus}
              onBlur={handleEditorOnBlur}
              autoCompleteMatchers={autoCompleteMatchers}
              customKeyBindFn={customKeyBindFn}
              customKeyCommands={customKeyCommands}
              compactTools={!isDesktop}
              size={!isDesktop ? 'small' : undefined}
              placeholder={t('video:replay.commentplaceholder')}
              />

              {disabled && (
                <AudioCommentTrigger
                className={classes.audioButton}
                disabled={false}
                handleRecordOnClick={handleRecordOnClick}
                />
              )}
            </FlexSpacer>

            {!disabled && (
              <AsyncButton
              className={classes.sendButton}
              variant='contained'
              color='primary'
              disabled={disabled || loading}
              loading={loading}
              onClick={handleSendComment}
              >
                <SendIcon fontSize='small'/>
              </AsyncButton>
            )}
          </FlexSpacer>
        ))}

        <MicrophoneAccessDeniedDialog
        open={recording?.state === 'failed'}
        onClose={resetAudioRecorderHandler}
        />

        {video && (
          <ShareVideoDialog
          video={video}
          onClose={closeShareVideoDialog}
          open={isShareVideoDialogOpen}
          />
        )}
      </FlexSpacer>
    );
  }

  return (
    <FlexSpacer orientation='vertical' className={classes.mobileCommentBox}>
      {renderWarningMessage && (
        <FlexSpacer flexAlignItems='center'>
          <WarningRoundedIcon />
          <Typography variant='caption'>
            {t('video:replay.warnNoMessage')}
          </Typography>
        </FlexSpacer>
      )}

      <FlexSpacer flexAlignItems='center'>
        {Array.from(selectedLabels).map((label, i) => i < MAX_LABEL_TO_SHOW_MOBILE && (
          <SelectedLabelToAdd
          key={label.id}
          label={label}
          handleRemoveLabel={handleRemoveLabel}
          />
        ))}
        {selectedLabels.size > MAX_LABEL_TO_SHOW_MOBILE && (
          <div>
            {t('common:extranumber', {number: selectedLabels.size - MAX_LABEL_TO_SHOW_MOBILE})}
          </div>
        )}
      </FlexSpacer>

      {recording?.state === 'recording' ? (
        <RecordingIndicator saveRecording={saveRecording} discardRecording={discardRecording}/>
      ) : (
        recording?.state === 'saved' ? (
          <FlexSpacer
          fullWidth={true}
          flexAlignItems='center'
          flexJustifyContent='space-between'
          >
            <Tooltip title={t('video:replay.discardrecording')}>
              <IconButton
              onClick={resetAudioRecorderHandler}
              size='small'
              className={classes.closeIcon}
              >
                <CloseIcon fontSize='small'/>
              </IconButton>
            </Tooltip>

            <AudioCommentContent
            className={classes.audioCommentContent}
            audioSrc={recording.audioSource}
            discardRecording={resetAudioRecorderHandler}
            fill={true}
            />

            {teamId && (
              <React.Fragment>
                <CommentLabelEditor
                teamId={teamId}
                handleAddLabel={handleAddLabel}
                selectedLabelsToAdd={selectedLabels}
                />
                <FlexSpacer
                className={classes.mobileTimestamp}
                flexAlignItems='center'
                flexJustifyContent='center'
                >
                  <TimerIcon />
                  {SecondsFormatter.format(playedSeconds)}
                </FlexSpacer>
              </React.Fragment>
            )}

            <AsyncButton
            className={classes.sendButton}
            variant='contained'
            color='primary'
            disabled={disabled || loading}
            loading={loading}
            onClick={handleSendComment}
            >
              <SendIcon fontSize='small'/>
            </AsyncButton>
          </FlexSpacer>
      ) : (
        <FlexSpacer flexAlignItems='center'>

          <FlexSpacer flexAlignItems='center'>
            {!drawingState.enabled && (
              <IconButton onClick={openShareVideoDialog} style={{ padding: 0 }}>
                <ShareIcon/>
              </IconButton>
            )}
            <div ref={mobileToggleRef} className={classes.mobileDrawingToggle}/>
            {drawingState.enabled && (
              <div ref={mobileToolbarRef} className={classes.mobileDrawingTools}/>
            )}
          </FlexSpacer>

          <FlexSpacer
          className={classes.textFieldContainer}
          flexAlignItems='center'
          fullWidth={true}
          >
            <RichTextEditor
            ref={editorRef}
            editorState={editorState}
            onStateChange={handleEditorChange}
            onFocus={handleEditorOnFocus}
            onBlur={handleEditorOnBlur}
            autoCompleteMatchers={autoCompleteMatchers}
            customKeyBindFn={customKeyBindFn}
            customKeyCommands={customKeyCommands}
            compactTools={!isDesktop}
            size={!isDesktop ? 'small' : undefined}
            placeholder={t('video:replay.commentplaceholder')}
            />

            {disabled && (
              <AudioCommentTrigger
              className={classes.audioButton}
              disabled={false}
              handleRecordOnClick={handleRecordOnClick}
              />
            )}
          </FlexSpacer>

          {teamId && (
            <FlexSpacer flexAlignItems='center' className={classNames({[classes.hide]: disabled})}>
              <CommentLabelEditor
              teamId={teamId}
              handleAddLabel={handleAddLabel}
              selectedLabelsToAdd={selectedLabels}
              />
              <FlexSpacer
              className={classes.mobileTimestamp}
              flexAlignItems='center'
              flexJustifyContent='center'
              >
                <TimerIcon />
                {SecondsFormatter.format(playedSeconds)}
              </FlexSpacer>
            </FlexSpacer>
          )}

          {!disabled && (
            <AsyncButton
            className={classes.sendButton}
            variant='contained'
            color='primary'
            disabled={disabled || loading}
            loading={loading}
            onClick={handleSendComment}
            >
              <SendIcon fontSize='small'/>
            </AsyncButton>
          )}
        </FlexSpacer>
      ))}

      <MicrophoneAccessDeniedDialog
      open={recording?.state === 'failed'}
      onClose={resetAudioRecorderHandler}
      />

      {video && (
        <ShareVideoDialog
        video={video}
        onClose={closeShareVideoDialog}
        open={isShareVideoDialogOpen}
        />
      )}
    </FlexSpacer>
  );
}

export default React.memo(MobileCommentBox);
