import gql from 'graphql-tag';

export const UpdateNotifications_Mutation = gql`
    mutation UpdateNotificationsMutation(
        $input: [UpdateNotificationInput!]!
    ) {
        updateNotifications(
            input: $input
        ) {
            notifications {
                id
            }
        }
    }
`;
