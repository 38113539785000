import { createBidirectionalAutoFetcher } from '@insights-gaming/redux-utils';
import { makeGetAuthenticationMethodRecords } from 'features/auth/auth-selector';
import { fetchAuthenticationMethodsAC } from 'features/auth/auth-slice';

export const useFetchAuthenticationMethods = createBidirectionalAutoFetcher({
  actionCreators: fetchAuthenticationMethodsAC,
  selectorFactory: makeGetAuthenticationMethodRecords,
  createFetchId: () => '',
  pageSize: 10,
});
