import { createUseTakeMiddleware } from '@insights-gaming/use-take';
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { gmid } from 'ga';
import { createBrowserHistory } from 'history';
import ReactPixel from 'react-facebook-pixel';
import { AnyAction } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootReducer, { RootReducer } from 'rootReducer';
import rootSaga from 'sagas';

import devware from './middleware.dev';

export const browserHistory = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware({
  context: {
    apollo: undefined,
  },
});

if (window.location.hostname === 'insights.gg') {
  ReactPixel.init('2055297061274496');
}

const [useTakeMiddleware, useTake] = createUseTakeMiddleware<AnyAction, RootReducer>();

const middleware = [
  routerMiddleware(browserHistory),
  sagaMiddleware,
  useTakeMiddleware,
  thunk,
  ...gmid,
  ...devware,
];

const store = configureStore({
  reducer: rootReducer(browserHistory),
  middleware,
  enhancers: [
    // devToolsEnhancer({
    //   name: 'Insights',
    //   hostname: 'localhost',
    //   port: 8888,
    //   maxAge: 200,
    //   actionsBlacklist: [
    //     addKeybindingContextAC,
    //     removeKeybindingContextAC,
    //     removeAllKeybindingContextsFromSourceAC,
    //   ].map(ac => ac.type),
    // }),
  ],
});

sagaMiddleware.run(rootSaga);

export default store;
export { useTake };
