type TSsbuStages =
|'Battlefield'
|'CastleSiege'
|'DreamLand'
|'FinalDestination'
|'FountainOfDreams'
|'HollowBastion'
|'KalosPokemonLeague'
|'LylatCruise'
|'NorthernCave'
|'Other'
|'PokemonStadium'
|'PokemonStadium2'
|'SmallBattlefield'
|'Smashville'
|'TownandCity'
|'UnovaPokemonLeague'
|'WarioWareInc'
|'YoshisIsland'
|'YoshisStory'
;
const SsbuStagesNS = 'ssbu-stages';
function SsbuStagesF(_: TSsbuStages): string { return `${SsbuStagesNS}:${_}`; }
export {SsbuStagesF,SsbuStagesNS,TSsbuStages};
