import { useFetchTeamInvoices } from 'features/dashboard/billing/useFetchTeamInvoices';
import React from 'react';

import { ID } from '../../types/pigeon';

interface InvoiceFetcherOwnProps {
  teamId: ID;
}

type InvoiceFetcherProps = InvoiceFetcherOwnProps;

function InvoiceFetcher({ teamId }: InvoiceFetcherProps) {
  useFetchTeamInvoices(teamId);
  return null;
}

export default React.memo(InvoiceFetcher);
