import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { deleteTemporaryCommentAC } from '../../actions/comment-actions';
import { ID } from '../../types/pigeon';

export function useDeleteTemporaryComment(): (
  id: ID,
) => void {
  const dispatch = useDispatch();
  return useCallback(
    (id: ID) => dispatch(deleteTemporaryCommentAC(id)),
    [dispatch],
  );
}
