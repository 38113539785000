import { deleteVideoAC } from 'features/dashboard/video/dashboard-video-slice';
import { usePromiseSagaDispatch } from 'hooks/usePromiseSagaDispatch';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';
import { matchPath } from 'react-router';

import { useNavigate } from '../../hooks/useNavigate';
import AlertDialogContent from '../../material/dialogs/alert-dialog-content/AlertDialogContent';
import { buildDashboardRoute, ETeamDashboardTabType, folderRoute, VIDEO_PATH } from '../../routes';
import { Video } from '../../types/pigeon';

export interface DeleteVideoDialogContentOwnProps {
  video: Video;
  onClose: VoidFunction;
  uploading?: boolean;
}

type Props = DeleteVideoDialogContentOwnProps;

function DeleteVideoDialogContent(props: Props) {
  const { video, onClose, uploading } = props;

  const { t } = useStrictTranslation(['common', 'dialog']);

  const [ loading, setLoading ] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const promisifiedSagaDispatch = usePromiseSagaDispatch();
  const onNavigate = useNavigate();

  const owner = video.owner;

  const action = useCallback(async () => {
    if (loading) { return; }
    setLoading(true);
    try {
      await promisifiedSagaDispatch(deleteVideoAC, {id: video.id});
      enqueueSnackbar(t('dialog:deletevideo.success'), {variant: 'success'});
      if (owner.__typename === 'Team') {
        let route = buildDashboardRoute(owner.id, ETeamDashboardTabType.VODS);
        setLoading(false);
        if (matchPath(window.location.pathname, {path: VIDEO_PATH})) {
          if (video.division) {
            route = folderRoute(video.division.id);
          }
          if (video.directory) {
            route = folderRoute(video.directory.id);
          }
          onNavigate(route);
        }
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message, {variant: 'error'});
    }
  }, [
    loading,
    promisifiedSagaDispatch,
    video.id,
    video.division,
    video.directory,
    enqueueSnackbar,
    t,
    owner,
    onNavigate,
  ]);

  const descText = uploading
    ? (
      <Trans i18nKey='dialog:uploadabort.desc' values={{ videoName: video.name }} ns='dialog'>
        Are you sure you want to abort uploading <strong>{{ videoName: video.name }}</strong>?
      </Trans>
    )
    : t('dialog:deletevideo.description', {text: video.name});

  return (
    <AlertDialogContent
    titleText={t(uploading ? 'dialog:uploadabort.title' : 'dialog:deletevideo.title')}
    description={descText}
    cancel={{
      text: t('common:cancel'),
      action: onClose,
    }}
    confirm={{
      text: t(uploading ? 'common:abort' : 'dialog:deletevideo.confirm'),
      action,
      loading,
      disabled: loading,
      negative: true,
    }}
    />
  );
}

export default React.memo(DeleteVideoDialogContent);
