import { FlexSpacer } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CommentFragment_VideoComment } from 'apollo/fragments/types/CommentFragment';
import classNames from 'classnames';
import { useAccessControl } from 'features/dashboard/access-control/useAccessControl';
import { useFetchVideoIfNecessary } from 'features/dashboard/video/useFetchVideoIfNecessary';
import { mobileLandscape, mobilePortrait } from 'features/media-queries';
import { useIsDesktop } from 'features/media-queries/hooks';
import { VideoHelper } from 'features/video-library/video-helpers';
import MemberFetcher from 'fetchers/member-fetcher/MemberFetcher';
import { VideoF, VideoNS } from 'locales/en/video';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { registerRoute, signinRoute } from 'routes';
import { getMe } from 'selectors/getMe';
import { ID } from 'types/pigeon';

import CommentBox from './comment-box/CommentBox';
import MobileCommentBox from './comment-box/MobileCommentBox';
import CommentListViewWrapper from './CommentListViewWrapper';
import { useVideoReplaySelectedLabelContext } from './useVideoReplaySelectedLabelContext';
import { VideoReplaySelectedLabelsProvider } from './VideoReplaySelectedLabelsContext';

interface CommentPanelOwnProps {
  className?: string;
  videoId: ID;
  onEditCommentDrawing?: (comment: CommentFragment_VideoComment) => void;
  commentBoxRefHandler?: ((el: HTMLElement | null) => void);
  commentLabelRefHandler?: ((el: HTMLElement | null) => void);
}

type CommentPanelProps = CommentPanelOwnProps;

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: { },
      cantComment: {
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.grey[700]}`,
        backgroundColor: theme.palette.background.paper,
        fontSize: '12px',
        color: theme.palette.text.deemphasized,
      },
      commentBoxPlaceholder: {
        [mobilePortrait(theme)]: {
          height: createRemFromPx(32),
        },
        [mobileLandscape(theme)]: {
          height: createRemFromPx(64),
        },
      },
    }),
  { name: 'CommentPanel' },
);

function CommentPanel(props: CommentPanelProps) {
  const { className, videoId, onEditCommentDrawing, commentBoxRefHandler, commentLabelRefHandler } = props;
  const classes = useStyles(props);

  const [video] = useFetchVideoIfNecessary(videoId);
  const videoReplayLabelFilterValue = useVideoReplaySelectedLabelContext();

  const { canCreateVideoComment } = useAccessControl();
  const isDesktop = useIsDesktop();

  const teamId = useMemo(() => video && VideoHelper.getTeamId(video), [video]);

  const me = useSelector(getMe);
  const canComment = me && (video?.openComments || canCreateVideoComment);

  return (
    <VideoReplaySelectedLabelsProvider value={videoReplayLabelFilterValue}>
      <FlexSpacer orientation='vertical' className={classNames(classes.root, className)}>
        {teamId && (
          <MemberFetcher teamId={teamId} />
        )}
        <CommentListViewWrapper
        videoId={videoId}
        teamId={teamId}
        onEditCommentDrawing={onEditCommentDrawing}
        />
        {canComment ? (
          isDesktop ? (
            <CommentBox
            teamId={teamId}
            videoId={videoId}
            commentBoxRefHandler={commentBoxRefHandler}
            commentLabelRefHandler={commentLabelRefHandler}
            />
          ) : (
            <React.Fragment>
              <div className={classes.commentBoxPlaceholder}/>
              <MobileCommentBox teamId={teamId} videoId={videoId} />
            </React.Fragment>
          )
        ) : (
          <NoCommentPrivilege className={classes.cantComment} isLoggedIn={!!me} />
        )}
      </FlexSpacer>
    </VideoReplaySelectedLabelsProvider>
  );
}

function NoCommentPrivilege({className, isLoggedIn}: {className?: string, isLoggedIn: boolean}) {
  const state = useMemo(() => ({referPath: window.location.pathname}), []);
  const { t } = useTranslation(VideoNS);
  return (
    <div className={className}>
      {isLoggedIn ? (
        t(VideoF('replay.viewonly'))
      ) : (
        <Trans ns={VideoNS} i18nKey='replay.publicviewonlycomment'>
          Want to comment on this video?
          Please
          <Link to={{pathname: registerRoute(), state}}>
            sign up
          </Link>
          or
          <Link to={{pathname: signinRoute(), state}}>
            sign in
          </Link>
          to Insights.
        </Trans>
      )}
    </div>
  );
}

export default React.memo(CommentPanel);
