import { EnhancedDialogTitle, FlexSpacer } from '@insights-gaming/material-components';
import { useBidirectionalFetchStatus } from '@insights-gaming/redux-utils';
import { createRemFromPx } from '@insights-gaming/theme';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import DragDropDirectoryCard from 'components/directory-card/DragDropDirectoryCard';
import PagedDirectoryGrid from 'components/directory-grid/PagedDirectoryGrid';
import DirectoryButton from 'components/directory-link/DirectoryButton';
import DirectoryLinkSkeleton from 'components/directory-link/DirectoryLinkSkeleton';
import { useCreateSelector, useParametricSelectorFactory } from 'hooks/useCreateSelector';
import { useStrictTranslation } from 'hooks/useStrictTranslation';
import { CommonNS } from 'locales/en/common';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ID } from 'types/pigeon';

import { DEFAULT_DIVISION_ID } from '../directory/dashboard-directory-constants';
import {
  makeGetTeamDirectoriesByTeamId,
  makeGetTeamDirectoryFolderRecordsByDirectoryId,
  makeGetTeamDirectoryFoldersByDirectoryId,
  makeGetTeamDirectoryRecordsByTeamId,
} from '../directory/dashboard-directory-selector';
import DirectoryFolderFetcher from '../directory/DirectoryFolderFetcher';
import { useFetchDirectoryTree } from '../directory/useFetchDirectoryTree';
import { DashboardMultiSelectContext, DashboardMultiSelectProvider } from '../multi-select/DashboardMultiSelectContext';
import { useDashboardMultiSelectContextValue } from '../multi-select/useDashboardMultiSelectContext';
import { multiSelectCheckboxProps } from '../multi-select/useMultiSelectState';
import { makeGetTeamById } from '../team/dashboard-team-selector';

interface DirectoryPickerDialogOwnProps {
  multiple?: boolean;
  teamId: ID;
  initialDirectoryId?: ID;
  initialValue?: ID[]; // only used if multiple === true
  filterFn?: (directory: DirectoryFragment) => boolean;
  onClose?: VoidFunction;
  onConfirm?: (directoryIds: ID | ID[]) => void;
  selectedVideoIds?: Set<string>;
}

type DirectoryPickerDialogProps = DirectoryPickerDialogOwnProps & Pick<DialogProps, 'open'>;

const useStyles = makeStyles({
  paper: {
    height: '100%',
  },
  videoSelected: {
    marginLeft: createRemFromPx(6),
  },
}, {name: 'DirectoryPickerDialog'});

function DirectoryPickerDialog(props: DirectoryPickerDialogProps) {
  const classes = useStyles(props);
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth={true} classes={{paper: classes.paper}}>
      <DirectoryPickerDialogContent {...props} />
    </Dialog>
  );
}

function DirectoryPickerDialogContent(props: DirectoryPickerDialogProps) {
  const classes = useStyles(props);
  const { teamId, multiple, initialDirectoryId, initialValue, filterFn, onClose, onConfirm, selectedVideoIds } = props;

  const team = useCreateSelector(makeGetTeamById, teamId);

  const { t } = useStrictTranslation(['common', 'dialog', 'dashboard-directory']);

  const dashboardMultiSelectValue = useDashboardMultiSelectContextValue({initialFolders: initialValue});
  const {
    folders: {
      selected: selectedFolderIds,
      addToSelection: addToFolderSelection,
      removeFromSelection: removeFromFolderSelection,
      toggleSelection: toggleFolderSelection,
      deselectAll: deselectAllFolders,
    },
  } = dashboardMultiSelectValue;

  const {
    videos: {
      deselectAll: deselectAllVideos,
    },
  } = useContext(DashboardMultiSelectContext);

  const [folderPageIds, setFolderPageIds] = useState<ID[]>([]);

  const handleFolderGridPageIdsChange = useCallback((pageNumber: number, ids: ID[]) => {
    setFolderPageIds(ids);
  }, []);

  const { checked, indeterminate } = multiSelectCheckboxProps([
    [folderPageIds, selectedFolderIds],
  ]);

  const handleSelectCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      removeFromFolderSelection(folderPageIds);
    } else {
      addToFolderSelection(folderPageIds);
    }
  }, [addToFolderSelection, folderPageIds, removeFromFolderSelection]);

  const [directoryId, setDirectoryId] = useState(initialDirectoryId);
  const getTeamDirectoryFolders = useParametricSelectorFactory(makeGetTeamDirectoryFoldersByDirectoryId, directoryId);
  const getTeamDivisions = useParametricSelectorFactory(makeGetTeamDirectoriesByTeamId, teamId);
  const directorySelector = directoryId && directoryId !== DEFAULT_DIVISION_ID
    ? getTeamDirectoryFolders
    : getTeamDivisions;
  const unfilteredDirectories = useSelector(directorySelector);
  const directories = useMemo(() => {
    let filtered = unfilteredDirectories.filter(directory => directory.id !== DEFAULT_DIVISION_ID);
    if (filterFn) {
      filtered = filtered.filter(filterFn);
    }
    return filtered;
  }, [filterFn, unfilteredDirectories]);

  const getTeamDirectoryFolderRecords = useParametricSelectorFactory(
    makeGetTeamDirectoryFolderRecordsByDirectoryId,
    directoryId,
  );
  const getTeamDivisionRecords = useParametricSelectorFactory(makeGetTeamDirectoryRecordsByTeamId, teamId);
  const directoryRecordSelector = directoryId && directoryId !== DEFAULT_DIVISION_ID
    ? getTeamDirectoryFolderRecords
    : getTeamDivisionRecords;

  const { forward: foldersForwardFetchStatus } = useBidirectionalFetchStatus(directoryRecordSelector);
  const initialFolderFetch = foldersForwardFetchStatus.fetching && !foldersForwardFetchStatus.cursor;

  const handleCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, directory: DirectoryFragment) => {
    if (e.target.checked) {
      addToFolderSelection([directory.id]);
    } else {
      removeFromFolderSelection([directory.id]);
    }
  }, [addToFolderSelection, removeFromFolderSelection]);

  const handleTeamClick = useCallback(() => {
    setDirectoryId(undefined);
  }, []);

  const handleDirectoryClick = useCallback((e: React.MouseEvent, directory: DirectoryFragment) => {
    setDirectoryId(directory.id);
  }, []);

  const handleConfirm = useCallback((e: React.MouseEvent) => {
    if (multiple) {
      onConfirm?.(Array.from(selectedFolderIds));
    } else if (directoryId) {
      onConfirm?.(directoryId);
    }
    deselectAllVideos();
    onClose?.();
  }, [deselectAllVideos, directoryId, multiple, onClose, onConfirm, selectedFolderIds]);

  const renderDirectoryCard = useCallback((directory: DirectoryFragment, i: number) => (
    <DragDropDirectoryCard
    directory={directory}
    canDrag={false}
    selected={selectedFolderIds.has(directory.id)}
    onClick={handleDirectoryClick}
    onCheckboxChange={multiple ? handleCheckboxChange : undefined}
    />
  ), [handleCheckboxChange, handleDirectoryClick, multiple, selectedFolderIds]);

  const [dirs, ancestor, status] = useFetchDirectoryTree(directoryId);
  const [directory] = dirs.slice(-1);

  let confirmText;
  if (multiple) {
    confirmText = t('dialog:directorypicker.confirmcount', {count: selectedFolderIds.size});
  } else if (directoryId) {
    confirmText = t('dialog:directorypicker.confirm', {name: directory.name});
  } else {
    confirmText = t('dialog:directorypicker.confirmdisabled');
  }

  return (
    <DashboardMultiSelectProvider value={dashboardMultiSelectValue}>
      <EnhancedDialogTitle onClose={onClose}>
        {multiple ? (
          t('dialog:directorypicker.title_plural')
        ) : (
          t('dialog:directorypicker.title')
        )}
      </EnhancedDialogTitle>
      <DialogContent>
        {directoryId && directoryId !== DEFAULT_DIVISION_ID && (
          <DirectoryFolderFetcher directoryId={directoryId} />
        )}
        {!!selectedVideoIds?.size && (
          <FlexSpacer className={classes.videoSelected}>
            <Trans
            i18nKey={'common:multivideoselect'}
            ns={CommonNS}
            values={{count: selectedVideoIds.size}}
            >
              {t('common:multivideodelete_plural')}
            </Trans>
          </FlexSpacer>
        )}
        <FlexSpacer orientation='vertical'>
          <Breadcrumbs>
            {team && (
              <Button onClick={handleTeamClick}>
                {team.name}
              </Button>
            )}
            {!ancestor && !!status?.fetching && (
              <DirectoryLinkSkeleton />
            )}
            {dirs.map(directory => (
              <DirectoryButton key={directory.id} directory={directory} onClick={handleDirectoryClick} />
            ))}
          </Breadcrumbs>
          {multiple && (
            <FlexSpacer flexAlignItems='center'>
              <Checkbox checked={checked} indeterminate={indeterminate} onChange={handleSelectCheckboxChange} />
            </FlexSpacer>
          )}
          {directoryId !== DEFAULT_DIVISION_ID && (
            <PagedDirectoryGrid
            directories={directories}
            displaySkeleton={initialFolderFetch}
            onPageIdsChange={handleFolderGridPageIdsChange}
            renderDirectoryCard={renderDirectoryCard}
            />
          )}
        </FlexSpacer>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <Button
        variant='contained'
        color='primary'
        onClick={handleConfirm}
        disabled={multiple ? !selectedFolderIds.size : !directoryId}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </DashboardMultiSelectProvider>
  );
}

export default React.memo(DirectoryPickerDialog);
