import {
  createFirstPage,
  createLastItem,
  createNextItem,
  createPageFactory,
  createPrevItem,
  IPageItem,
  PageFactory,
} from './itemFactory';
import { createComplexRange, createSimpleRange } from './rangeFactories';
import { isSimplePagination } from './utils';

export interface IPaginationOptions {
  activePage   : number;
  boundaryRange: number;
  hideEllipsis : boolean;
  siblingRange : number;
  totalPages   : number;
}

export function createPaginationItems(options: IPaginationOptions): Array<IPageItem | false> {
  const { activePage, totalPages } = options;
  const pageFactory: PageFactory = createPageFactory(activePage);
  const innerRange = isSimplePagination(options)
    ? createSimpleRange(1, totalPages, pageFactory)
    : createComplexRange(options, pageFactory);
  return [
    createFirstPage(),
    createPrevItem(activePage),
    ...innerRange,
    createNextItem(activePage, totalPages),
    createLastItem(totalPages),
  ];
}
