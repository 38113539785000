import { VideoReplayContext } from '@insights-gaming/material-components';
import { useVideoReplayLiveSessionBindings } from 'features/live-session/useVideoReplayLiveSessionBindings';
import React, { useContext } from 'react';

interface LiveSessionVideoReplayContextBindingsOwnProps {
  children?: React.ReactNode;
}

type LiveSessionVideoReplayContextBindingsProps = LiveSessionVideoReplayContextBindingsOwnProps;

function LiveSessionVideoReplayContextBindings({ children }: LiveSessionVideoReplayContextBindingsProps) {
  return (
    <VideoReplayContext.Provider value={useVideoReplayLiveSessionBindings(useContext(VideoReplayContext))}>
      {children}
    </VideoReplayContext.Provider>
  );
}

export default React.memo(LiveSessionVideoReplayContextBindings);
