import { Theme } from '@insights-gaming/theme';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DirectoryFragment } from 'apollo/fragments/dashboard/types/DirectoryFragment';
import classNames from 'classnames';
import DirectoryCardSkeleton from 'components/directory-card/DirectoryCardSkeleton';
import React from 'react';

interface DirectoryGridOwnProps {
  className?: string;
  directories: DirectoryFragment[];
  displaySkeleton?: boolean;
  renderDirectoryCard: (directory: DirectoryFragment, i: number) => React.ReactNode;
}

export type DirectoryGridProps = DirectoryGridOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    overflow: 'hidden',
  },
}), {name: 'DirectoryGrid'});

function DirectoryGrid(props: DirectoryGridProps) {
  const classes = useStyles(props);
  const {
    className,
    directories,
    displaySkeleton = false,
    renderDirectoryCard,
  } = props;

  return (
    <div className={classNames(classes.root, className)}>
      <Grid container={true} spacing={2}>
        {displaySkeleton ? (
          Array.from({length: 2}).map((_, i) => (
            <Grid key={i} item={true} xs={6} sm={6} md={4} lg={3} xl={2}>
              <DirectoryCardSkeleton />
            </Grid>
          ))
        ) : (
          directories.map((directory, i) => (
            <Grid key={directory.id} item={true} xs={6} sm={6} md={4} lg={3} xl={2}>
              {renderDirectoryCard(directory, i)}
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
}

export default React.memo(DirectoryGrid);
