import { fetchOverwatchPrimaryRosterPlayerStatisticsAsyncAC } from 'features/statistics/statistics-slice';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootReducer } from 'rootReducer';

import { GetOverwatchPrimaryRosterPlayerStatisticsV2QueryVariables } from '../apollo/queries/types/GetOverwatchPrimaryRosterPlayerStatisticsV2Query';
import OverwatchPrimaryRosterPlayerStatistics, {
  IOverwatchPrimaryRosterPlayerStatisticsDispatch,
  IOverwatchPrimaryRosterPlayerStatisticsMappedProps,
  IOverwatchPrimaryRosterPlayerStatisticsOwnProps,
} from '../components/dashboard/team-statistics/overwatch-primary-roster-player-statistics/OverwatchPrimaryRosterPlayerStatistics';

function mapStateToProps(
  state: RootReducer,
  props: IOverwatchPrimaryRosterPlayerStatisticsOwnProps,
): IOverwatchPrimaryRosterPlayerStatisticsMappedProps {
  return {
    primaryRosterPlayerStatisticsCache: state.statisticsReducer.playerStatistics.cache[props.team.id],
    primaryRosterPlayerStatisticsError: state.statisticsReducer.playerStatistics.error,
  };
}

function mapDispatchToProps(dispatch: Dispatch): IOverwatchPrimaryRosterPlayerStatisticsDispatch {
  return {
    onFetchOverwatchPrimaryRosterPlayerStatistics: (
      params: GetOverwatchPrimaryRosterPlayerStatisticsV2QueryVariables,
    ) => dispatch(fetchOverwatchPrimaryRosterPlayerStatisticsAsyncAC.started(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OverwatchPrimaryRosterPlayerStatistics);
