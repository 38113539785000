import { RootReducer } from 'rootReducer';

export const teamStateSelector = (state: RootReducer) => state.dashboardTeam;
export const uploadStateSelector = (state: RootReducer) => state.uploadReducer;
export const commentStateSelector = (state: RootReducer) => state.commentReducer;
export const statisticsStateSelector = (state: RootReducer) => state.statisticsReducer;
export const authStateSelector = (state: RootReducer) => state.authReducer;
export const kmsessionStateSelector = (state: RootReducer) => state.kmsessionReducer;
export const publicStatisticsStateSelector = (state: RootReducer) => state.publicStatisticsReducer;
export const billingStateSelector = (state: RootReducer) => state.billing;
export const audioRecordingStateSelector = (state: RootReducer) => state.audioRecorderReducer;
