import { VideoFragment } from 'apollo/fragments/types/VideoFragment';
import FileSaver from 'file-saver';
import { matches2Csv2 } from 'helpers/csv/match2';
import { useCallback } from 'react';
import sanitize from 'sanitize-filename';
import { GeneratedOverwatchMatch, Match } from 'types/pigeon';

export function useOnExportKillFeed(
  video?: VideoFragment,
  analysisMatches?: Match[],
) {
  return useCallback(() => {
    if (video && analysisMatches) {
      const generatedMatches = analysisMatches.filter(m => m.isGeneratedOverwatchMatch()) as GeneratedOverwatchMatch[];
      const blob = matches2Csv2(generatedMatches);
      const fileName = video.name + '_killfeed.csv';
      FileSaver.saveAs(blob, sanitize(fileName));
    }
  }, [analysisMatches, video]);
}
