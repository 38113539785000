import { Theme } from '@insights-gaming/theme';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, withStyles } from '@material-ui/styles';

export const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 5,
      borderRadius: theme.shape.borderRadius,
    },
    colorPrimary: {
      backgroundColor: theme.palette.text.secondary,
    },
    bar: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.primary.main,
    },
  }),
)(LinearProgress);
